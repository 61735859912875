import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';

import Input from '../UI/Input';
import Checkbox from '../UI/Checkbox';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import useForm from '../../hooks/useForm';
import firebase from '../../services/firebase';
import { NotificationContext, UserContext, GlobalContext, TeamContext } from '../../context';
import { errorMessage } from '../../i18n';
import { saveSelectedTeamToLS } from '../../utils';
import { invitationCodeRequired, sign_up } from '../../services/auth';

const SignUp = () => {
  const { joiningTeamOption, redirectToAfterAuthenticated, setRedirectToAfterAuthenticated, validate, t, selectedLang } = useContext(GlobalContext);
  const [initialData] = useState({
    firstName: {
      required: true,
      value: ''
    },
    lastName: {
      required: true,
      value: ''
    },
    email: {
      email: true,
      required: true,
      value: ''
    },
    password: {
      required: true,
      minLength: 8,
      minLengthMessage: t('notification.password_validation'),
      value: ''
    },
    validationPassword: {
      required: true,
      matchWith: 'password',
      matchWithMessage: t('error.password_mismatch'),
      value: ''
    },
    invitationCode: {
      required: invitationCodeRequired(),
      value: ''
    },
    terms: {
      required: true,
      value: false
    },
    privacy: {
      required: true,
      value: false
    }
  })
  const { formData, errors, changeHandler, setErrors, setFormData } = useForm(initialData, validate);
  const { setNotification } = useContext(NotificationContext);
  const { setAuthMode, setUser } = useContext(UserContext);
  const { setSelectedTeam, fetchTeams } = useContext(TeamContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setAuthMode('register');
  }, [setAuthMode]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      email: {
        ...prev.email,
        value: joiningTeamOption.email || ''
      },
      invitationCode: {
        ...prev.invitationCode,
        value: joiningTeamOption.invitationCode || '',
        disabled: Boolean(joiningTeamOption.invitationCode)
      }
    }));
  }, [joiningTeamOption, setFormData]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return
    }

    setLoading(true);

    try {
      const userData = {
        first_name: formData.firstName.value,
        last_name: formData.lastName.value,
        email: formData.email.value,
        terms: formData.terms.value,
        privacy: formData.privacy.value
      };
      const res = await sign_up(formData.email.value, formData.password.value, userData, formData.invitationCode.value, selectedLang)
      
      console.log(res)
      if(!res.success) {
        console.log(res)
        setLoading(false)
        setNotification({ msg: errorMessage(null, t), type: 'danger' })
        return
      }
      await firebase.auth().signInWithEmailAndPassword(formData.email.value, formData.password.value)
      setUser({
        first_name: formData.firstName.value,
        last_name: formData.lastName.value,
        email: formData.email.value,
        terms: formData.terms.value,
        privacy: formData.privacy.value,
        last_read_notification_timestamp: Date.now(),
        id: res.id
      });
      if(!joiningTeamOption.default) {
        const { teams } = await fetchTeams()
        const findTeam = [...teams].find(tm => tm.id === joiningTeamOption.team);
        setSelectedTeam(findTeam);
        saveSelectedTeamToLS(findTeam.id)
        history.push(joiningTeamOption.redirect);
      }
      if(redirectToAfterAuthenticated) {
        history.push(redirectToAfterAuthenticated)
        setRedirectToAfterAuthenticated(null)
      }
    } catch(err) {
      setLoading(false);
      console.log('error', err)
      let errorObject = err
      if(err.response?.data && err.response.data.error) {
        errorObject = err.response.data.error
      }
      setNotification({ msg: errorMessage(errorObject, t), type: 'danger' })
    }
  }

  return (
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box_main">
          <div className="auth__box_main-head">
            <p>{t('auth.create_account')}</p>
            <div className="icon"><LockIcon /></div>
          </div>
          <form className="form" onSubmit={submitHandler}>
            <div className="form__group form__group--auth">
              <Input 
                name="firstName"
                value={formData.firstName.value}
                onChange={changeHandler}
                error={errors.firstName}
                placeholder={t('auth.first_name')}
                whiteBackground
                iconSmall
                sizeMedium
              />
              <Input 
                name="lastName"
                value={formData.lastName.value}
                onChange={changeHandler}
                error={errors.lastName}
                placeholder={t('auth.last_name')}
                whiteBackground
                iconSmall
                sizeMedium
              />
            </div>
            <Input
              name="email"
              value={formData.email.value}
              onChange={changeHandler}
              error={errors.email}
              placeholder={t('auth.email')}
              whiteBackground
              iconSmall
              sizeMedium
            />
            <Input
              name="password"
              value={formData.password.value}
              onChange={changeHandler}
              error={errors.password}
              placeholder={t('auth.password')}
              type="password"
              whiteBackground
              iconSmall
              sizeMedium
              showEyeIcon
            />
            <Input
              name="validationPassword"
              value={formData.validationPassword.value}
              onChange={changeHandler}
              error={errors.validationPassword}
              placeholder={t('auth.validation_password')}
              type="password"
              whiteBackground
              iconSmall
              sizeMedium
            />
            { invitationCodeRequired() &&
            <Input
              name="invitationCode"
              value={formData.invitationCode.value}
              onChange={changeHandler}
              error={errors.invitationCode}
              placeholder={t('auth.invitation_code')}
              type="text"
              whiteBackground
              iconSmall
              sizeMedium
              disabled={formData.invitationCode.disabled}
            /> }
            <div className="form__group form__group--auth">
              <Checkbox 
                name="terms"
                label={<Fragment>
                  {t('auth.agree_to_terms')}
                  &nbsp;
                  <a href={"assets/CGU_FR_CARBON.pdf"} target="_blank" rel="noreferrer">{t('auth.agree_to_terms_link_text')}</a>
                </Fragment>}
                onChange={changeHandler}
                checked={formData.terms.value}
                error={errors.terms}
                />
            </div>
            <div className="form__group form__group--auth">
              <Checkbox 
                name="privacy"
                label={<Fragment>
                  {t('auth.agree_to_privacy')}
                  &nbsp;
                  <a href={"assets/Politique_Vie_Privée_FR_CARBON.pdf"} target="_blank" rel="noreferrer">{t('auth.agree_to_privacy_link_text')}</a>
                </Fragment>}
                onChange={changeHandler}
                checked={formData.privacy.value}
                error={errors.privacy}
              />
            </div>
            <Button
              text={!loading ? t('auth.free_registration') : <Loader small normalWhite />}
              type="submit"
              disabled={loading}
              fullWidth
              primary 
              medium
            />
          </form>
        </div>
        <div className="auth__note">
          {/* <p className="small">En créant un compte, vous acceptez les conditions d'utilisation</p> */}
          <p>{t('auth.already_have_account')} <Link to="/signin" className="text-link">{t('auth.login_here')}</Link></p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
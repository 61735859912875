import React, { useContext, useState, forwardRef, memo, useCallback } from 'react'
import { Document } from 'react-pdf'
import { ClickAwayListener } from '@material-ui/core' 
import { HelpOutline } from '@material-ui/icons'

import Loader from '../../UI/Loader'
import CustomFields2 from '../documents/detail/CustomFields2'
import DocumentDetailSidePanel from './DocumentDetailSidePanel'
import DocumentDetailSidePanelSmall from './DocumentDetailSidePanelSmall'
import DocumentDetailSPAlerts from './DocumentDetailSPAlerts'
import DocumentDetailSPAttachments from './DocumentDetailSpAttachments'
import DocumentDetailSPComments from './DocumentDetailSPComments'
import DocumentDetailSPDates from './DocumentDetailSPDates'
import DocumentDetailSPTags from './DocumentDetailSPTags'
import DocumentDetailSPTasks from './DocumentDetailSPTasks'
import DocumentDetailSPVariablesCopy from './DocumentDetailSPVariablesCopy'
import DocumentDetailVariables from './DocumentDetailVariables'
import DocumentDetailSPPreview from './DocumentDetailSPPreview'

import { LoaderContext, SignatureContext, GlobalContext } from '../../../context'

const DocumentDetailContent = forwardRef(({ sidePanelOpened, uploaded, format, docUrls, documentName, uploadedDocData, onDocumentLoadSuccess, uploadedDocPages, templateObjectWithUniqueVarIndexes, documentValues, documentValuesChangeHandler, documentCheckboxValues, documentCheckboxValuesChangeHandler, setEditStartTime, storeEditTime, handleContactsModalOpen, view, setView, setSidePanelOpened, documentAttachments, documentId, showBackToTop, documentObject, docTasks, newCommentsCount, isSharedTemplate, isSharedTemplateFromPage, onAddAttachment, extractVariableTypesFromSections, templateObject, totalProgress, variableItemClickHandler, synthesisClickHandler, activeVariable, scrollingPosition, setPrevScrollPosition, prevScrollPosition, sectionClickHandler, scrollingToItem, editAttachmentHandler, onDeleteAttachmentByName, docInformations, setDocInformations, docInformationsDefault, setUpdateSection, docDates, setDocDates, docDatesDefault, comments, setComments, userCustomFields, setUserCustomFields, userDefaultCustomFields, docCustomFieldsValues, setDocCustomFieldsValues, didCustomFieldsValuesChanged, makeContentEditable, onLinkVarsWithProfileData, onSectionContentChanged, onPageChange }, ref) => {
  const { t } = useContext(GlobalContext)
  const { loading, loadingOverlay } = useContext(LoaderContext)
  const { signatures } = useContext(SignatureContext)
  const [showUploadedDocInfo, setShowUploadedDocInfo] = useState(false)
  const [showUploadAttachments, setShowUploadAttachments] = useState(false)
  const [variableTypes] = useState(extractVariableTypesFromSections(templateObject?.sections))
  const [commentsBadge] = useState(newCommentsCount())
  const [numOfSignatures] = useState(signatures.filter(s => s.doc_id === documentId))
  const [numOfTasks] = useState(docTasks && documentId ? docTasks.filter(t => !t.completed) : [])
  const [numOfAlerts] = useState(documentObject && documentObject.doc_alerts && documentObject.doc_alerts.length > 0 ? documentObject.doc_alerts.length : 0)
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  // On set comments
  const handleSetComments = useCallback((c) => {
    setComments(c)
  }, [setComments])

  // On side panel open
  const handleSidePanelOpen = useCallback(() => {
    setSidePanelOpened(!sidePanelOpened)
  }, [setSidePanelOpened, sidePanelOpened])

  return (
    <div className={sidePanelOpened ? "document-detail__body opened" : "document-detail__body"} ref={ref}>
      {(loading || loadingOverlay) && <div className="overlay"></div>}
      <div className="document-detail__body_inner">
        <div className={uploaded ? "wrap wrap--uploaded-doc" : "wrap"} >
          {uploaded ? 
            <>
              <div className="document-detail__body_inner-title">
                <p>{documentName}.{format ? format : docUrls && docUrls.pdf ? 'pdf' : 'docx'}</p>
                <ClickAwayListener onClickAway={() => setShowUploadedDocInfo(false)}>
                  <div className={showUploadedDocInfo ? "help help--active" : "help"}>
                    <div className="help__icon" onClick={() => setShowUploadedDocInfo(!showUploadedDocInfo)}><HelpOutline /></div>
                    {showUploadedDocInfo && <div className="help__tooltip custom-tooltip custom-tooltip--top">
                      {t('general.uploaded_to_app')}
                      <div className="arrow"></div>
                    </div>}
                  </div>
                </ClickAwayListener>
              </div>
              <div className="preview-doc">
                {uploadedDocData ? 
                  <Document file={uploadedDocData} onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages, true)} renderMode="canvas" loading=''>
                    {/* {uploadedDocPages[0]} */}
                    {uploadedDocPages}
                  </Document>
                  : <div className="loader-wrapper"><Loader small normal /></div>
                }
              </div>
            </>
            : <DocumentDetailVariables
              templateData={templateObjectWithUniqueVarIndexes}
              documentValues={documentValues}
              onValuesChange={documentValuesChangeHandler}
              documentCheckboxValues={documentCheckboxValues}
              onCheckboxValuesChange={documentCheckboxValuesChangeHandler}
              onSetEditStartTime={setEditStartTime}
              onStoreEditTime={storeEditTime}
              onContactsModalOpen={handleContactsModalOpen}
              makeContentEditable={makeContentEditable}
              onLinkVarsWithProfileData={onLinkVarsWithProfileData}
              onSectionContentChanged={onSectionContentChanged}
            />
          }
        </div>
        <DocumentDetailSidePanelSmall
          onTriggerClick={handleSidePanelOpen}
          onSetView={setView}
          view={view}
          attachments={documentAttachments}
          signatures={numOfSignatures}
          showBackToTop={showBackToTop}
          docUploaded={documentObject?.uploaded}
          tasks={numOfTasks}
          commentsBadge={commentsBadge}
          isSharedTemplate={isSharedTemplate || isSharedTemplateFromPage}
          numOfAlerts={numOfAlerts}
        />
      </div>
      <DocumentDetailSidePanel 
        onTriggerClick={handleSidePanelOpen} 
        onSetView={setView} 
        view={view} 
        attachments={documentAttachments}
        signatures={numOfSignatures}
        showBackToTop={showBackToTop}
        showUploadAttachments={showUploadAttachments}
        closeUploadAttachments={() => setShowUploadAttachments(false)}
        onAddAttachment={onAddAttachment} 
        attachmentsNames={[...documentAttachments].map(a => a.name + '.' + a.format)}
        tasks={numOfTasks}
        docUploaded={documentObject?.uploaded}
        commentsBadge={commentsBadge}
        onSetEditStartTime={setEditStartTime}
        onStoreEditTime={storeEditTime}
        isSharedTemplate={isSharedTemplate || isSharedTemplateFromPage}
        numOfAlerts={numOfAlerts}
        documentId={documentId}
        uploading={uploading}
        setUploading={setUploading}
        progress={progress}
        setProgress={setProgress}
      >
        {view === 'variables' && !uploaded ?
          <DocumentDetailSPVariablesCopy
            documentValues={documentValues}
            variableTypes={variableTypes}
            totalProgress={totalProgress}
            onItemClick={variableItemClickHandler}
            onSynthesisClicked={synthesisClickHandler}
            activeVariable={activeVariable}
            scrollingPosition={scrollingPosition}
            onSetScrollPosition={setPrevScrollPosition}
            prevScrollPosition={prevScrollPosition}
            templateData={templateObjectWithUniqueVarIndexes}
            onSectionClick={sectionClickHandler}
            scrollingToItem={scrollingToItem}
            isSharedTemplate={isSharedTemplate || isSharedTemplateFromPage}
          />
          : null }
        {view === 'appendices' && <DocumentDetailSPAttachments 
          onAddAttachment={onAddAttachment} 
          documentAttachments={documentAttachments} 
          onSetShowUploadAttachments={setShowUploadAttachments}
          onEditAttachment={editAttachmentHandler}
          onDeleteAttachmentByName={onDeleteAttachmentByName}
          docId={documentId}
          onSetEditStartTime={setEditStartTime}
          onStoreEditTime={storeEditTime}
          setUploading={setUploading}
          setProgress={setProgress}
        />}
        {view === 'tags' && <DocumentDetailSPTags 
          docInformations={docInformations} 
          onSetDocInformations={setDocInformations} 
          doc={documentObject} 
          onSetView={setView} 
          docInformationsDefault={docInformationsDefault}
          onSetUpdateSection={setUpdateSection}
          onSetEditStartTime={setEditStartTime}
          onStoreEditTime={storeEditTime}
        />}
        {view === 'dates' && <DocumentDetailSPDates 
          docDates={docDates}
          onSetDocDates={setDocDates} 
          docDatesDefault={docDatesDefault} 
          doc={documentObject} 
          onSetView={setView} 
          onSetUpdateSection={setUpdateSection}
          onSetEditStartTime={setEditStartTime}
          onStoreEditTime={storeEditTime}
        />}
        {view === 'tasks' && <DocumentDetailSPTasks 
          docId={documentId} 
          doc={documentObject} 
          docTasks={docTasks} 
          onSetEditStartTime={setEditStartTime} 
          onStoreEditTime={storeEditTime} 
          onSetView={setView}
        />}
        {view === 'alert' && <DocumentDetailSPAlerts 
          doc={documentObject}
          onSetView={setView}
          onSetEditStartTime={setEditStartTime}
          onStoreEditTime={storeEditTime}
        />}
        {/* {view === 'comments' && <DocumentDetailSPComments 
          doc={documentObject}
          documentId={documentId}
          comments={comments}
          onSetComments={handleSetComments}
          onSetEditStartTime={setEditStartTime}
          onStoreEditTime={storeEditTime}
        />} */}
        {view === 'custom_fields' && <CustomFields2 
          doc={documentObject} 
          customFields={userCustomFields} 
          onSetCustomFields={setUserCustomFields} 
          onSetView={setView} 
          onSetEditStartTime={setEditStartTime}
          onStoreEditTime={storeEditTime}
          defaultFields={userDefaultCustomFields}
          customFieldsValues={docCustomFieldsValues}
          onSetCustomFieldsValues={setDocCustomFieldsValues}
          valuesChanged={didCustomFieldsValuesChanged}
        />}
        {view === 'preview' && <DocumentDetailSPPreview
          docPages={uploadedDocPages}
          onPageChange={onPageChange}
          uploadedDocData={uploadedDocData}
        />}
      </DocumentDetailSidePanel>
    </div>
  )
})

export default memo(DocumentDetailContent)
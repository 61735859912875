import { SET_USER, RESET_STATE, SET_MEMBERSHIP, SET_SUBSCRIPTION, SET_INVOICES } from '../types';

const userReducer = (state, action) => {
  switch(action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case SET_MEMBERSHIP:
      return {
        ...state,
        membership: action.payload
      }
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      }
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default userReducer
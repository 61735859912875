import { useContext, useState, useEffect, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { UploadDocumentsModal, TemplateSelectModal, WidgetDropdown } from '../'
import { Alert } from '../../new_ui'
import { GlobalContext, DocumentsContext, ConstantsContext, UserContext } from '../../../context'
import { sortArrayOfObjects } from '../../../utils'

const RecentDocsWidget = forwardRef(({ style, dragging, isSorting, ...props }, ref) => {
  const { t } = useContext(GlobalContext)
  const { STATUS_LABELS } = useContext(ConstantsContext)
  const { widgetsInfo } = useContext(UserContext)
  const { getTemplateById, documentsArr, documentsLoaded } = useContext(DocumentsContext)
  const [showUploadDocsModal, setShowUploadDocsModal] = useState(false)
  const [showDocumentCreateModal, setShowDocumentCreateModal] = useState(false)
  const [showNoTemplateAlert, setShowNoTemplateAlert] = useState(false)
  const [docs, setDocs] = useState([])
  const history = useHistory()

  // Set docs - last 5
  useEffect(() => {
    if(documentsLoaded) {
      setDocs(sortArrayOfObjects(documentsArr, 'meta.created').slice(0,5))
    }
  }, [documentsArr, documentsLoaded])

  // On doc click
  const handleDocClick = (doc) => {
    if(doc.template) {
      const docTemplate = getTemplateById(doc.template)
      if(!docTemplate) {
        return setShowNoTemplateAlert(true)
      }
    }
    if(doc.id) {
      history.push(`/document-detail/${doc.id}`, { openAlert: true })
    }
  }

  // Show all docs
  const handleShowAllDocs = () => {
    history.push('/documents')
  }

  // On open document create modal
  const handleOpenDocumentCreateModal = (e) => {
    e.preventDefault()
    setShowDocumentCreateModal(true)
  }

  // On open documents uploda modal
  const handleOpenDocumentsUploadModal = (e) => {
    e.preventDefault()
    setShowUploadDocsModal(true)
  }

  // On close upload documents modal
  const handleCloseUploadDocumentsModal = () => {
    setShowUploadDocsModal(false)
  }

  // On close upload documents modal
  const handleCloseDocumentCreateModal = () => {
    setShowDocumentCreateModal(false)
  }

  // On close no template alert
  const handleCloseNoTemplateAlert = () => {
    setShowNoTemplateAlert(false)
  }

  if(isSorting) {
    return (
      <div className={`widget widget--placeholder ${dragging ? 'widget--dragging' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4">{t('dashboard.recent_documents')}</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`widget ${widgetsInfo['item-recent'].size === 'half' ? 'widget--half' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4" {...props}>{t('dashboard.recent_documents')}</h4>
          <div className="widget__head_actions">
            <ActionsDropdown
              trigger={
                <a href="/#" onClick={(e) => e.preventDefault()} className="head-action-link"><span className="material-symbols-outlined size-24">add</span></a>
              }
              hideHeader
              width={300}
              dropdownClass="actions-dropdown-v2"
            >
              <ul className="default-list">
                <li>
                  <a href="/#" onClick={handleOpenDocumentCreateModal}>
                    <span className="icon"><span className="material-symbols-outlined size-24">add</span></span> 
                    {t('dashboard.create_from_template')}
                  </a>
                </li>
                <li>
                  <a href="/#" onClick={handleOpenDocumentsUploadModal}>
                    <span className="icon"><span className="material-symbols-outlined size-24">file_upload</span></span> 
                    {t('dashboard.import_document')}
                  </a>
                </li>
              </ul>
            </ActionsDropdown>
            <WidgetDropdown widget="item-recent" />
          </div>
        </div>
        <div className="widget__body">
          <div className="recent-docs-widget-content">
            <div className="recent-docs-table">
              <div className="recent-docs-table__row recent-docs-table__row--head">
                <div className="recent-docs-table__cell">
                  <span className="material-symbols-outlined">text_fields</span>
                  <p>{t('dashboard.title')}</p>
                </div>
                <div className="recent-docs-table__cell">
                  <span className="material-symbols-outlined">circle</span>
                  <p>{t('dashboard.status')}</p>
                </div>
                <div className="recent-docs-table__cell">
                  <span className="material-symbols-outlined">calendar_month</span>
                  <p>{t('dashboard.created_on')}</p>
                </div>
                <div className="recent-docs-table__cell">
                  <span className="material-symbols-outlined">calendar_month</span>
                  <p>{t('dashboard.updated_on')}</p>
                </div>
              </div>

              {docs.map((doc, i) => {
                const status = <div className={`status-v2 ${doc.status}`}>{ doc.status === 'empty' ? '' : STATUS_LABELS[doc.status] }</div>
                const created = moment(doc.meta.created).format('DD MMM')
                const createdTime = moment(doc.meta.created).format('HH:mm')
                const updated = moment(doc.meta.updated).format('DD MMM')
                const updatedTime = moment(doc.meta.updated).format('HH:mm')

                return (
                  <div className="recent-docs-table__row" key={i}>
                    <div 
                      className="recent-docs-table__cell title-cell u-truncate-text-wrapper" 
                      onClick={() => handleDocClick(doc)} 
                      data-label={`${t('dashboard.title')}:`}
                    >
                      <p className="text-style-1 u-truncate-text">{doc.name}</p>
                    </div>
                    <div 
                      className="recent-docs-table__cell" 
                      data-label={`${t('dashboard.status')}:`}
                    >
                      {status}
                    </div>
                    <div 
                      className="recent-docs-table__cell date-cell" 
                      data-label={`${t('dashboard.created_on')}:`}
                    >
                      <p className="date-style-1">{created} <span>{createdTime}</span></p>
                    </div>
                    <div 
                      className="recent-docs-table__cell date-cell" 
                      data-label={`${t('dashboard.updated_on')}:`}
                    >
                      <p className="date-style-1">{updated} <span>{updatedTime}</span></p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="widget__foot">
          <button className="btn btn--primary-light btn--full" onClick={handleShowAllDocs}>
            {t('dashboard.see_all_documents')} 
            <span className="material-symbols-outlined size-24">chevron_right</span>
          </button>
        </div>
      </div>

      {showUploadDocsModal && (
        <UploadDocumentsModal onClose={handleCloseUploadDocumentsModal} />
      )}
      {showDocumentCreateModal && (
        <TemplateSelectModal onClose={handleCloseDocumentCreateModal} />
      )}
      {showNoTemplateAlert && (
        <Alert 
          text={t('notification.doc_without_template')}
          onClose={handleCloseNoTemplateAlert} 
          showOk={true}
        />
      )}
    </>
  )
})

export default RecentDocsWidget 
import { SET_LOADING, RESET_STATE } from '../types';

export default (state, action) => {
  switch(action.type) {
    case SET_LOADING:
      return{
        ...state,
        loading: action.payload
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}
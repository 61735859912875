import { useContext, useEffect, useState, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { ExternalDocumentsView } from '../components/misc'
import { GlobalContext, SharedDocumentsContext, UserContext, TeamContext } from '../context'
import { firebaseEnvDoc } from '../services/firebase'
import { sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'

const ExternalDocuments = () => {
  const { t } = useContext(GlobalContext)
  const { sharedDocumentsFetched, setSharedDocumentsFetched, setSharedDocuments, sharedDocuments, sharedDocumentsUnsubscribeListener, sharedDocumentsSort, setSharedDocumentsSort, sharedDocumentsSelectedFilters } = useContext(SharedDocumentsContext)
  const { user } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)
  const [activeSharedDocs, setActiveSharedDocs] = useState([])
  const [currentSharedDocs, setCurrentSharedDocs] = useState([])
  const [paginatedData, setPaginatedData] = useState([])
  const [selectedDocs, setSelectedDocs] = useState([])
  const [allChecked, setAllChecked] = useState(false)

  // Listen for shared documents changes
  useEffect(() => {
    if(!sharedDocumentsUnsubscribeListener.current && !sharedDocumentsFetched) {
      sharedDocumentsUnsubscribeListener.current = firebaseEnvDoc.collection('shared_documents').where('shared_with', 'array-contains', user.email).onSnapshot(snapshot => {
        console.log('listen for shared doc changes***')
        if(snapshot.empty) {
          setSharedDocuments([])
          setSharedDocumentsFetched(true)
          return 
        }
        let arr = []
        for(let i = 0; i < snapshot.docs.length; i++) {
          const doc = snapshot.docs[i]
          let users = 0
          if(doc.data().team_members) {
            users += doc.data().team_members.length
          }
          if(doc.data().shared_with) {
            users += doc.data().shared_with.length
          }
          arr.push({ ...doc.data(), id: doc.id, users })
        }
        let sorted = sortArrayOfObjects(arr, sharedDocumentsSort.value, sharedDocumentsSort.order)
        setSharedDocuments(sorted)
        setSharedDocumentsFetched(true)
      })
    }
    // eslint-disable-next-line
  }, [sharedDocumentsFetched])

  useEffect(() => {
    setActiveSharedDocs(sharedDocuments)
    setCurrentSharedDocs(sharedDocuments)
  }, [sharedDocuments])

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedDocs([])
    }else {
      setSelectedDocs(paginatedData)
    }
  }

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeSharedDocs]

    for(let key in sharedDocumentsSelectedFilters) {
      if(key === 'search' && sharedDocumentsSelectedFilters[key] !== '') {
        filtered = filtered.filter(d => d.name.toLowerCase().includes(sharedDocumentsSelectedFilters[key].trim().toLowerCase()))
      }
    }

    let sorted = sortArrayOfObjects(filtered, sharedDocumentsSort.value, sharedDocumentsSort.order)
    setCurrentSharedDocs([...sorted])
  }, [activeSharedDocs, sharedDocumentsSelectedFilters, sharedDocumentsSort])

  // Filter templates when templatesSelectedFilters or templatesSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = sharedDocumentsSort.order === 'desc' ? 'asc' : 'desc'
    setSharedDocumentsSort({ value: sort_by, order: currentOrder })
    if(isCookieAccepted()) {
      saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'templates')
    }
  }

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setAllChecked(false)
    setSelectedDocs([])
  }, [])

  return (
    <MainLayout title={t('dashboard.external_documents')}>
      <ExternalDocumentsView 
        filteredDocs={currentSharedDocs}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        allChecked={allChecked}
        onAllCheckedChange={handleAllChecked}
        selectedDocuments={selectedDocs}
        onSetSelectedDocuments={setSelectedDocs}
        onSort={handleSort}
        ready={sharedDocumentsFetched}
        refreshDocs={refreshCollection}
        onSetAllChecked={setAllChecked}
      />
    </MainLayout>
  )
}

export default ExternalDocuments
import { firebaseEnvDoc } from './firebase'

const COLLECTION = 'signature_templates'

// Create 
const create_signature_template = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).add(data)
    return res.id
  } catch (err) {
    console.log('create signature template error', err)
  }
}

// Fetch 
const fetch_signature_templates = async (teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('get signature templates err', err)
  }
}

// Delete
const delete_signature_template = async (id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).delete()
  } catch (err) {
    console.log('delete signature template error', err)
  }
}

// Update
const update_signature_template = async (id, data) => {
  try { 
    data[`meta.updated`] = Date.now()
    await firebaseEnvDoc.collection(COLLECTION).doc(id).update(data)
  } catch (err) {
    console.log('update signature template error', err)
  }
}

export {
  create_signature_template,
  fetch_signature_templates,
  update_signature_template,
  delete_signature_template,
}
import React, { useState, useEffect, useContext, useCallback, useMemo, memo, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { Edit } from '@material-ui/icons'
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid'

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import CustomTooltip from '../../UI/CustomTooltip';
import Button from '../../UI/Button';
import Checkbox from '../../UI/Checkbox';
import Message from '../../UI/Message';
import ResponseLoader from '../../UI/ResponseLoader';
import IconButton from '../../UI/IconButton';
import Alert from '../../UI/Alert';
import Loader from '../../UI/Loader';
import Radio from '../../UI/Radio';
import Switch from '../../UI/Switch'
import SignaturesNotAvailable from '../../UI/SignaturesNotAvailable';
import PlaceSignatureInterfaceModalV2 from './PlaceSignatureInterfaceModalV2'
import OrderCustomersModal from './OrderCustomersModal'
import SignatureTemplatesModal from '../signatures/SignatureTemplatesModal'
import SaveUpdatedSignatureTemplateModal from '../signatures/SaveUpdatedSignatureTemplateModal'
import SaveTemplateSection from '../signatures/SaveTemplateSection'
import ChooseTemplateSection from '../signatures/ChooseTemplateSection'
import UpdateTemplateSection from '../signatures/UpdateTemplateSection'
import { convert_attachment, generate_document } from '../../../services/lawstudioApi';
import { fetch_signature_credits, get_file, update_document } from '../../../services/firestore';
import { validateEmail, validateMobileNumber } from '../../../helpers/validate';
import { SignatureContext, UserContext, NotificationContext, TeamContext, DocumentsContext, SignatureTemplatesContext, GlobalContext } from '../../../context';
import { readFileAsync, base64toBlob, availableOn, bytesToSize } from '../../../utils';
import { create_package } from '../../../services/onespan'
import { get_file_data } from '../../../services/storage'
import { useAttachmentsActions } from '../../../hooks'
import config from '../../../config.json'

const SignDocumentModalOnespan = ({ onClose, selectedDocument, onFetchDocumentData, documentName, docId, docAttachments, docSignatureRecipients, uploaded, documentUrls, onAddAttachment, attachmentsNames, onSetEditStartTime = () => {}, onStoreEditTime = () => {}, windowVisibility = 'hidden', editStartTime = null, documentObject, templateObject, documentValues }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { addSignature } = useContext(SignatureContext);
  const { user, canUseSignatures, siteConfigsLoaded } = useContext(UserContext);
  const { setNotification } = useContext(NotificationContext);
  const { selectedTeam, activeTeamMember, updateTeam } = useContext(TeamContext);
  const { templates, updateDocument } = useContext(DocumentsContext)
  const { signatureTemplates, createSignatureTemplate, fetchSignatureTemplates, signatureTemplatesFetched, updateSignatureTemplate } = useContext(SignatureTemplatesContext)
  const [loading, setLoading] = useState(false);
  const [emmeteur, setEmmeteur] = useState(user.email ? user.email : '');
  const [emmeteurError, setEmmeteurError] = useState('');
  const [customSigners, setCustomSigners] = useState([
    {
      id: 'cs-1',
      email: { name: `cs-1-email`, value: '' },
      name: { name: `cs-1-name`, value: '' },
      lastname: { name: `cs-1-lastname`, value: '' },
      mobile: { name: `cs-1-mobile`, value: '' },
      error: '',
      errors: [],
      errorMessages: []
    }
  ]);
  const [requestSent, setRequestSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [signatureErrors, setSignatureErrors] = useState([]);
  const [requestFailed, setRequestFailed] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [showPlaceSigntuareInterface, setShowPlaceSignatureInterface] = useState(false);
  const [signaturePlaced, setSignaturePlaced] = useState(false);
  const [mergedFilesBase64, setMergedFilesBase64] = useState(null);
  const [showResponseLoader, setShowResponseLoader] = useState(false);
  const [orderedSignatairesArr, setOrderedSignatairesArr] = useState([...customSigners]);
  const [sendInOrder, setSendInOrder] = useState(false);
  const [showOrderCustomersModal, setShowOrderCustomersModal] = useState(false);
  const [fieldsPositions, setFieldsPositions] = useState([]);
  const [showSendRequestAlert, setShowSendRequestAlert] = useState(false);
  const [orderRadioValue, setOrderRadioValue] = useState('no');
  const [boxes, setBoxes] = useState([])
  const [creditsLeft, setCreditsLeft] = useState(0)
  const [activeBox, setActiveBox] = useState(null)
  const [showSendInOrder, setShowSendInOrder] = useState(false)
  const [showSaveTemplate, setShowSaveTemplate] = useState(false)
  const [signatureTemplateName, setSignatureTemplateName] = useState('')
  const [signatureTemplateNameError, setSignatureTemplateNameError] = useState('')
  const [signaturePositions, setSignaturePositions] = useState([])
  const [showChooseTemplates, setShowChooseTemplates] = useState(false)
  const [signatureTemplatesOptions, setSignatureTemplatesOptions] = useState([
    { label: t('signature_templates.choose_template'), value: '' }
  ])
  const [activeSignatureTemplate, setActiveSignatureTemplate] = useState('')
  const [showSignatureTemplatesModal, setShowSignatureTemplatesModal] = useState(false)
  const [showSaveUpdatedTemplateModal, setShowSaveUpdatedTemplateModal] = useState(false)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [boxMode, setBoxMode] = useState('create')
  const [attsUpdated, setAttsUpdated] = useState(false)
  const [fileSize, setFileSize] = useState(0)

  // Fetch signature templates if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!signatureTemplatesFetched) {
        // console.log('fetch signature templates')
        fetchSignatureTemplates(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, signatureTemplatesFetched, fetchSignatureTemplates])

  // Refresh credits
  useEffect(() => {
    if(!selectedTeam) {
      return
      // setCreditsLeft(selectedTeam.signature_credits_left || 0)
    }
    refreshCredits()
  }, [selectedTeam])

  // Show only pdf and docx attachments
  useEffect(() => {
    // if attachment doesn't have size saved in db, update attachment
    const getAttsSizes = async (atts) => {
      let promises = []
      for(let i = 0; i < atts.length; i++) {
        if(!atts[i].size) {
          promises.push(get_file_data(atts[i].url))
        }
      }
      const data = await Promise.all(promises)
      if(data.length > 0) {
        // console.log('get sizes and update doc')
        let updatedFilteredAtts = []
        for(let i = 0; i < atts.length; i++) {
          let foundInData = data.find(a => a.url === atts[i].url)
          if(data.find(a => a.url === atts[i].url)) {
            updatedFilteredAtts.push({...atts[i], size: foundInData.size})
          }else {
            updatedFilteredAtts.push(atts[i])
          }
        }
        setAttachments(updatedFilteredAtts)
        let updatedDocAtts = []
        for(let i = 0; i < docAttachments.length; i++) {
          let att = docAttachments[i]
          let found = updatedFilteredAtts.find(a => a.url === att.url)
          if(found) {
            updatedDocAtts.push({...att, size: found.size})
          }else {
            updatedDocAtts.push(att)
          }
        }
        await updateDocument({ attachments: updatedDocAtts }, { id: docId, attachments: updatedDocAtts })
      }
      setAttsUpdated(true)
    }

    if(docAttachments && !attsUpdated) {
      const filteredAttachments = [...docAttachments].filter(att => (att.format === 'pdf' || att.format === 'docx') && att.url)
      setAttachments(filteredAttachments)
      getAttsSizes(filteredAttachments)
    }
  }, [docAttachments, attsUpdated]);

  // Get document size
  useEffect(() => {
    const getSize = async () => {
      if(!templateObject || !templateObject.id) return 
      const data = await generate_document(templateObject.id, documentValues)
      if(data) {
        const blob = base64toBlob(`${data}`)
        if(blob) {
          setFileSize(blob.size)
        }
      }
    }

    const getUploadedDocSize = async (url) => {
      const data = await get_file_data(url)
      if(data && data.size) {
        setFileSize(data.size)
      }
    }

    if(selectedDocument.uploaded) {
      let docUrl = null 
      if(!selectedDocument.size) {
        if(selectedDocument.documentUrls) {
          if(selectedDocument.documentUrls.pdf) {
            docUrl = selectedDocument.documentUrls.pdf
          }else if(selectedDocument.documentUrls.docx) {
            docUrl = selectedDocument.documentUrls.docx
          }
          getUploadedDocSize(docUrl)
        }
      }else {
        setFileSize(selectedDocument.size)
      }
    }else {
      getSize()
    }
    // eslint-disable-next-line
  }, [])

  // Populate signature templates options for select/dropdown field
  useEffect(() => {
    if(signatureTemplatesFetched) {
      const options = signatureTemplates.map(tmpl => ({
        label: tmpl.name,
        value: tmpl.id
      }))
      setSignatureTemplatesOptions([{ label: t('signature_templates.choose_template'), value: '' }, ...options])
    }
  }, [signatureTemplates, signatureTemplatesFetched])

  const refreshCredits = async () => {
    let creditsResponse = await fetch_signature_credits(selectedTeam.id)
    if(creditsResponse.error) {
      console.log(creditsResponse.error)
      setCreditsLeft(0)
      return
    }
    setCreditsLeft(creditsResponse.credits)
  }

  // Change handler for signers objects input fields
  const changeValueHandler = useCallback((e, id, change, type) => {
    const value = e.target.value;
    let signersCopy;
    if(type === 'manual') {
      signersCopy = [...customSigners];
    }
    const findSigner = signersCopy.find(s => s.id === id);
    findSigner[change].value = value;
    const arr = signersCopy.map(s => s.id === id ? findSigner : s);
    if(type === 'manual') {
      setCustomSigners(arr);
    }
  }, [customSigners])

  // Validate form
  const validateForm = (e) => {
    e.preventDefault();

    // Remove error message
    if(requestFailed) {
      setRequestFailed(false);
    }
    if(signatureErrors.length > 0) {
      setSignatureErrors([]);
    }
    if(generalError) {
      setGeneralError('');
    }

    // Validation
    let errors = {};

    if(emmeteur.trim() === '') {
      setEmmeteurError(t('notification.required_field'));
      errors.emmeteur = true;
    }else if(!validateEmail(emmeteur.trim())){
      setEmmeteurError(t('notification.invalid_email'));
      errors.emmeteur = true;
    }else {
      setEmmeteurError('');
      delete errors.emmeteur;
    }

    const cS = [];
    customSigners.forEach((signer) => {
      if(signer.email.value.trim() === '' && !signer.errors.includes('email')) {
        signer.errors.push('email')
        if(!signer.errorMessages.includes(t('notification.email_is_required'))) {
          signer.errorMessages.push(t('notification.email_is_required'))
        }
        errors[signer.id] = true
        signer.error = 'error'
      }else if(signer.email.value.trim() !== '' && signer.errors.includes('email')) {
        signer.errors = signer.errors.filter(err => err !== 'email')
        signer.errorMessages = signer.errorMessages.filter(m => m !== t('notification.email_is_required'))
      }

      if(signer.name.value.trim() === '' && !signer.errors.includes('name')) {
        signer.errors.push('name')
        if(!signer.errorMessages.includes(t('notification.lastname_is_required'))) {
          signer.errorMessages.push(t('notification.lastname_is_required'))
        }
        errors[signer.id] = true
        signer.error = 'error'
      }else if(signer.name.value.trim() !== '' && signer.errors.includes('name')) {
        signer.errors = signer.errors.filter(err => err !== 'name')
        signer.errorMessages = signer.errorMessages.filter(m => m !== t('notification.lastname_is_required'))
      }

      if(signer.lastname.value.trim() === '' && !signer.errors.includes('lastname')) {
        signer.errors.push('lastname')
        if(!signer.errorMessages.includes(t('notification.name_is_required'))) {
          signer.errorMessages.push(t('notification.name_is_required'))
        }
        errors[signer.id] = true
        signer.error = 'error'
      }else if(signer.lastname.value.trim() !== '' && signer.errors.includes('lastname')) {
        signer.errors = signer.errors.filter(err => err !== 'lastname')
        signer.errorMessages = signer.errorMessages.filter(m => m !== t('notification.name_is_required'))
      }

      if(signer.mobile.value.trim() === '' && !signer.errors.includes('mobile')) {
        signer.errors.push('mobile')
        if(!signer.errorMessages.includes(t('notification.mobile_is_required'))) {
          signer.errorMessages.push(t('notification.mobile_is_required'))
        }
        errors[signer.id] = true
        signer.error = 'error'
      }else if(signer.mobile.value.trim() !== '' && signer.errors.includes('mobile')) {
        signer.errors = signer.errors.filter(err => err !== 'mobile')
        signer.errorMessages = signer.errorMessages.filter(m => m !== t('notification.mobile_is_required'))
      }
      
      if(!validateEmail(signer.email.value.trim())) {
        signer.error = t('notification.invalid_email')
        errors[signer.id] = true
        if(!signer.errors.includes('email')) {
          signer.errors.push('email')
        }
        if(!signer.errorMessages.includes(t('notification.invalid_email'))) {
          signer.errorMessages.push(t('notification.invalid_email'))
        }
      }else {
        if(signer.errors.includes('email')) {
          signer.errors = signer.errors.filter(err => err !== 'email')
        }
        if(signer.errorMessages.includes(t('notification.invalid_email'))) {
          signer.errorMessages = signer.errorMessages.filter(m => m !== t('notification.invalid_email'))
        }
      }

      if(!validateMobileNumber(signer.mobile.value.trim())){
        signer.error = t('notification.phone_code')
        errors[signer.id] = true
        if(!signer.errors.includes('mobile')) {
          signer.errors.push('mobile')
        }
        if(!signer.errorMessages.includes(t('notification.phone_code'))) {
          signer.errorMessages.push(t('notification.phone_code'))
        }
      }else {
        if(signer.errors.includes('mobile')) {
          signer.errors = signer.errors.filter(err => err !== 'mobile')
        }
        if(signer.errorMessages.includes(t('notification.phone_code'))) {
          signer.errorMessages = signer.errorMessages.filter(m => m !== t('notification.phone_code'))
        }
      }
      
      if(!signer.error){
        signer.error = ''
        delete errors[signer.id]
        signer.errors = []
        signer.errorMessages = []
      }
      cS.push(signer);
    });
    setCustomSigners(cS);

    if(cS.length === 0) {
      setGeneralError(t('general.add_recipient'));
      return
    }
    
    let newPositions = []
    fieldsPositions.forEach(f => {
      if(f.label && f.label.value === '') {
        f.error = t('notification.empty_label');
        errors[f.id] = true;
      }else {
        f.error = '';
        delete errors[f.id];
      }
      newPositions.push(f)
    })
    setFieldsPositions(newPositions)

    let totalSize = getTotalSizeOfSelectedDocuments()
    if(totalSize > 16777216) { // 16MB
      errors.size = true
      return setNotification({ msg: t('signatures.total_files_size'), type: 'danger' })
    }
    
    // If no errors open alert
    if(Object.keys(errors).length === 0) {
      setShowSendRequestAlert(true);
    }else {
      let ids = Object.keys(errors)
      let errorMessages = []
      customSigners.forEach(c => {
        if(ids.includes(c.id) && c.errorMessages.length > 0) {
          c.errorMessages.forEach(m => {
            if(!errorMessages.includes(m)) {
              errorMessages.push(m)
            }
          })
        }
      })
      fieldsPositions.forEach(f => {
        if(f.error && !errorMessages.includes(f.error)) {
          errorMessages.push(f.error)
        }
      })
      
      if(errorMessages.length > 0) {
        setNotification({ msg: errorMessages.join(', '), type: 'danger' })
      }
    }
  }
  
  // Submit form
  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSendRequestAlert(false);
    if(creditsLeft < 1) return

    try {
      setLoading(true)
      setShowResponseLoader(true)
      let base64Merged;
      if(!mergedFilesBase64) {
        let pdfBase64;
        if(!uploaded) {
          pdfBase64 = await onFetchDocumentData(selectedDocument, 'pdf')
        }else {
          if(documentUrls.pdf) {
            const res = await get_file({ url: documentUrls.pdf })
            pdfBase64 = res.data;
          }else {
            const res = await convert_attachment(documentUrls.docx)
            pdfBase64 = res.data;
          }
        }
        const blob = base64toBlob(pdfBase64, 'application/pdf');
        const filesToMergeArr = [blob];

        // If attachments are selected merge pdfs
        if(attachments) {
          for(let i = 0; i < attachments.length; i++) {
            if(attachments[i].sign) {
              let res;
              if(attachments[i].format === 'pdf') {
                res = await get_file({ url: attachments[i].url });
              }else {
                res = await convert_attachment(attachments[i].url);
              }
              // console.log('FILE RES', res.data);
              const blob = base64toBlob(res.data, 'application/pdf');
              filesToMergeArr.push(blob);
            }
          }
        }

        const mergedPdf = await PDFDocument.create();
        for(let i = 0; i < filesToMergeArr.length; i++) {
          const bytes = await readFileAsync(filesToMergeArr[i]);
          const pdf = await PDFDocument.load(bytes);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page)); 
        }
        await mergedPdf.save();
        base64Merged = await mergedPdf.saveAsBase64();
      }
      await PDFDocument.load(mergedFilesBase64 ? mergedFilesBase64 : base64Merged)

      const fieldsArr = []
      boxes.forEach((box) => {
        if(box.type === 'checkbox') {
          let obj = {
            page: box.page,
            top: box.top,
            left: box.left,
            width: box.width,
            height: box.height,
            subtype: 'CHECKBOX',
            type: 'INPUT',
            validation: {
              required: box.required ? true : false
            }
          }
          fieldsArr.push(obj)
        }else if(box.type === 'label') {
          let obj = {
            page: box.page,
            top: box.top,
            left: box.left,
            width: box.width,
            height: box.height,
            subtype: 'LABEL',
            type: 'INPUT',
            value: box.value
          }
          fieldsArr.push(obj)
        }
      })

      const defaultSignaturePosition = {
        // page: pdfDoc.getPageCount(),
        page: 0,
        top: 300,
        left: 280,
        height: 100,
        width: 200
      };

      const sArr = customSigners;
      const signataireArr = !sendInOrder ? sArr.map((signer, index) => {
        let data = {
          id: `Signer${index+1}`,
          signers: [
            {
              email: signer.email.value,
              firstName: signer.name.value,
              lastName: signer.lastname.value
            }
          ],
          positions: signer.positions
        }
        if(signer.mobile.value) {
          data.signers[0] = {
            ...data.signers[0],
            auth: {
              scheme: "SMS",
              challenges: [{
                question: signer.mobile.value
              }]
            }
          }
        }
        return data
      }) : [...orderedSignatairesArr].map((signer, index) => {
        let data = {
          id: `Signer${index+1}`,
          index: index,
          signers: [
            {
              email: signer.email.value,
              firstName: signer.name.value,
              lastName: signer.lastname.value
            }
          ],
          positions: signer.positions
        }
        if(signer.mobile.value) {
          data.signers[0] = {
            ...data.signers[0],
            auth: {
              scheme: "SMS",
              challenges: [{
                question: signer.mobile.value
              }]
            }
          }
        }
        return data
      })

      let approvalsArr = []
      signataireArr.forEach((s) => {
        if(!s.positions || s.positions.length === 0) {
          approvalsArr.push({
            role: s.id,
            fields: [{
              ...defaultSignaturePosition,
              subtype: 'FULLNAME',
              type: 'SIGNATURE'
            }, ...fieldsArr]
          })
        }else if(s.positions.length === 1) {
          approvalsArr.push({
            role: s.id,
            fields: [{
              ...s.positions[0],
              subtype: 'FULLNAME',
              type: 'SIGNATURE'
            }, ...fieldsArr]
          })
        }else {
          s.positions.forEach((position, idx) => {
            let arr = []
            if(idx === 0) { // if more signatures add fields(checkbox, label) only once
              arr = [{
                ...position,
                subtype: 'FULLNAME',
                type: 'SIGNATURE'
              }, ...fieldsArr]
            }else {
              arr = [{
                ...position,
                subtype: 'FULLNAME',
                type: 'SIGNATURE'
              }]
            }
            approvalsArr.push({
              role: s.id,
              fields: arr
            })
          })
        }
      })

      const date_limit = moment().add(30, 'days').toISOString()
      const onespanDocId = `carbon-${selectedTeam?.id}-${Date.now()}`
      const data = {
        roles: signataireArr.map((signer) => {
          let data = {...signer}
          if(data.positions) delete data.positions
          return data
        }),
        documents: [
          {
            approvals: approvalsArr,
            name: `${documentName}.pdf`,
            id: onespanDocId
          }
        ],
        name: documentName,
        type: "PACKAGE",
        language: 'en',
        emailMessage: "",
        description: "",
        autocomplete: true,
        status: "SENT",
        due: date_limit
      }
      // console.log(data)
      // console.log('create package')
      const res = await create_package({
        payload: data,
        file: mergedFilesBase64 ? mergedFilesBase64 : base64Merged,
      })
      // console.log(res)
      
      if(res.success) {
        // Add signature to database
        const recipientsArr = [...signataireArr].map(s => ({ 
          name: s.signers[0].firstName, 
          lastname: s.signers[0].lastName, 
          email: s.signers[0].email, 
          mobile: s.signers[0].auth ? s.signers[0].auth.challenges[0].question : '',
          roleId: s.id
        }));
        let env = config.environment
        if(config.staging) {
          env = 'staging'
        }
        const signatureData = {
          title: documentName,
          recipients: recipientsArr,
          sentBy: emmeteur.trim(),
          createdAt: Date.now(),
          package_id: res.data.id,
          document_id: onespanDocId,
          // members_links: data.response.data.members_links,
          doc_id: docId,
          status: 'SIGNING_PENDING',
          date: date_limit,
          team: selectedTeam?.id,
          env
        };
        // console.log(signatureData)
        await addSignature(signatureData)
        // Save recipients to doc db to prefill data next time signature modal is opened
        await update_document(docId, { signatureRecipients: recipientsArr, last_modified_by: activeTeamMember.id, create_action: 'no' }, docAttachments)
        if(creditsLeft > 0) {
          setCreditsLeft(creditsLeft - 1)
          let creditsUsed = selectedTeam?.credits_used || {}
          if(creditsUsed[user.id]) {
            creditsUsed[user.id] = creditsUsed[user.id] + 1
          }else {
            creditsUsed[user.id] = 1
          }
          // todo update signature_credits doc in backend
          // await updateTeam({ signature_credits_left: creditsLeft - 1, credits_used: creditsUsed }, selectedTeam?.id, true)
        }

        setLoading(false)
        setRequestSent(true)
        // setUrlToDoc(data.response.data.members_links[0].url);
        setSuccessMessage(t('notification.document_sent'))
        setShowResponseLoader(false)
        setShowSendRequestAlert(false)
      }else {
        // error
        setRequestFailed(true)
        // console.log(data.response?.data?.message);
        // setSignatureErrors(data.response.message);
        console.log(data)
        setSignatureErrors([t('notification.something_went_wrong')])
        setLoading(false)
        setShowResponseLoader(false)
      }
    }catch(err) {
      console.log(err)
      setRequestFailed(true)
      setSignatureErrors([t('notification.something_went_wrong')])
      setLoading(false)
      setShowResponseLoader(false)
    }
  }

  // Add new user(new set of fields to form) when person icon is clicked
  const addNewUser = (e) => {
    e.preventDefault();
    const name = `signer-${new Date().getTime()}`;
    const data = {
      id: name,
      email: { name: `${name}-email`, value: '' },
      name: { name: `${name}-name`, value: '' },
      lastname: { name: `${name}-lastname`, value: '' },
      mobile: { name: `${name}-mobile`, value: '' },
      error: '',
      errors: [],
      errorMessages: []
    };
    const arr = [...customSigners];
    arr.push(data);
    setCustomSigners(arr);
    setOrderedSignatairesArr(arr);
    if(generalError) {
      setGeneralError('');
    }
  }

  // Delete user(set of input fields) if trash icon is clicked
  const deleteCustomUser = useCallback((e, index, email) => {
    e.preventDefault();
    const arr = [...customSigners];
    let boxesToDelete = []
    if(arr[index] && arr[index].positions) {
      arr[index].positions.forEach(p => {
        boxesToDelete.push(p.boxId)
      })
    }
    if(boxesToDelete.length > 0) {
      setBoxes(boxes.filter(b => !boxesToDelete.includes(b.id)))
    }
    const newArr = arr.filter((s, idx) => idx !== index);
    setCustomSigners(newArr);
    setOrderedSignatairesArr(prev => prev.filter((s) => s.email.value !== email));
  }, [boxes, customSigners])

  // Load pdf 
  const loadPdf = useCallback(async () => {
    try {
      let pdfBase64
      if(!uploaded) {
        pdfBase64 = await onFetchDocumentData(selectedDocument, 'pdf')
      }else {
        if(documentUrls.pdf) {
          const res = await get_file({ url: documentUrls.pdf })
          pdfBase64 = res.data;
        }else {
          const res = await convert_attachment(documentUrls.docx)
          pdfBase64 = res.data;
        }
      }
      const blob = base64toBlob(pdfBase64, 'application/pdf')
      const filesToMergeArr = [blob]
      let base64Merged = pdfBase64
      
      // If attachments are selected merge pdfs
      if(attachments) {
        for(let i = 0; i < attachments.length; i++) {
          if(attachments[i].sign) {
            let res
            if(attachments[i].format === 'pdf') {
              res = await get_file({ url: attachments[i].url })
            }else {
              res = await convert_attachment(attachments[i].url)
            }
            const blob = base64toBlob(res.data, 'application/pdf')
            filesToMergeArr.push(blob)
          }
        }
        
        const mergedPdf = await PDFDocument.create()
        for(let i = 0; i < filesToMergeArr.length; i++) {
          const bytes = await readFileAsync(filesToMergeArr[i])
          const pdf = await PDFDocument.load(bytes)
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
          copiedPages.forEach((page) => mergedPdf.addPage(page))
        }
        await mergedPdf.save()
        base64Merged = await mergedPdf.saveAsBase64()
        setMergedFilesBase64(base64Merged)
      }
      
      setShowPlaceSignatureInterface(true)
      setShowResponseLoader(false)
    } catch (err) {
      console.log(err)
      setShowResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }, [attachments, documentUrls, onFetchDocumentData, setNotification, uploaded, selectedDocument, t])

  // Place signature
  const placeSignatureHandler = useCallback(async (e, id, type, parentId) => {
    e.preventDefault()
    if(!id) return
    // setPositionMode(mode)
    // setCheckboxToUpdate(checkboxId)
    // console.log(id, type)

    const findBox = boxes.find(box => box.id === id)
    if(findBox) {
      setActiveBox(findBox)
      setBoxes(boxes.map(b => b.id === findBox.id ? {...findBox, disabled: false} : {...b, disabled: true}))
      setBoxMode('edit')
    }else {
      let box
      if(type === 'signature') {
        box = { id, parentId, type, top: 300, left: 280, width: 200, height: 100, disabled: false, saved: false, page: 0 }
      }else if(type === 'checkbox') {
        box = { id, type, top: 100, left: 100, width: 30, height: 30, disabled: false, saved: false, page: 0 }
      }else if(type === 'label') {
        box = { id, parentId, type, top: 100, left: 100, width: 150, height: 50, disabled: false, saved: false, page: 0 }
      }
      setBoxes([...boxes.map(b => ({...b, disabled: true})), box])
      setActiveBox(box)
      setBoxMode('create')
    }
    
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      loadPdf()
    }else {
      setShowPlaceSignatureInterface(true)
    }
  }, [boxes, loadPdf, mergedFilesBase64])

  // Attachment change handler
  const attachmentChangeHandler = useCallback((index, sign) => {
    let da = [...attachments]
    da[index].sign = sign
    setAttachments(da)
    if(mergedFilesBase64) {
      setMergedFilesBase64(null)
    }
  }, [attachments, mergedFilesBase64])

  // Set signers - add position helper
  // const setSignersAddPositionHelper = (prev, id, signaturePosition) => {
  //   const findUser = [...prev].find(c => c.id === id);
  //   const copyOfUser = {...findUser};
  //   if(findUser) {
  //     let positions = [];
  //     if(copyOfUser.position) {
  //       positions = [...copyOfUser.position];
  //     }
  //     positions.push(signaturePosition);
  //     copyOfUser.position = positions;
  //   }
  //   return [...prev].map(c => c.id === copyOfUser.id ? copyOfUser : c);
  // }

  // Signature position save handler
  const signaturePositionSaveHandler = useCallback(async (box) => {
    setActiveBox(null)
    setShowPlaceSignatureInterface(false)
    // console.log(boxes)
    const updatedSigners = [...customSigners]
    const find = updatedSigners.find(s => s.id === box.parentId)
    if(box.type === 'signature') {
      if(find) {
        let arr = []
        let sArr = [...signaturePositions]
        let newPos = {
          page: box.page,
          width: box.width,
          height: box.height,
          left: box.left,
          top: box.top,
          boxId: box.id,
          parentId: box.parentId
        }
        if(find.positions) {
          if(boxMode === 'create') {
            arr = [...find.positions, newPos]
            sArr.push(newPos)
          }else {
            arr = [...find.positions].map(p => p.boxId === box.id ? newPos : p) 
            sArr = sArr.map(p => p.boxId === box.id ? newPos : p)
          }
        }else {
          arr = [newPos]
          sArr.push(newPos)
        }
        find.positions = arr
        setSignaturePositions(sArr)
      }
      setCustomSigners(updatedSigners)
      setOrderedSignatairesArr(updatedSigners)
    }else if(box.type === 'checkbox') {
      if(boxMode === 'create') {
        let checkbox = {
          ...box,
          required: false,
          label: {}
        }
        setFieldsPositions([...fieldsPositions, checkbox])
      }else {
        setFieldsPositions([...fieldsPositions].map(f => f.id === box.id ? {...f, ...box} : f))
      }
    }else if(box.type === 'label') {
      const checkbox = fieldsPositions.find(f => f.id === box.parentId)
      if(checkbox) {
        if(boxMode === 'create') {
          checkbox.label = {
            ...box,
            value: ''
          }
        }else {
          checkbox.label = {
            ...box
          }
        }
      }
    }
  }, [customSigners, fieldsPositions, signaturePositions, boxMode])

  // Set signers - delete position helper
  const setSignersDeletePositionHelper = useCallback((prev, signer, index) => {
    const findUser = [...prev].find(c => c.id === signer.id)
    if(!findUser) return 

    const copyOfUser = {...findUser}
    const position = [...copyOfUser.positions]
    if(position[index].boxId) {
      setBoxes(boxes.filter(b => b.id !== position[index].boxId))
      setSignaturePositions(signaturePositions.filter(pos => pos.boxId !== position[index].boxId))
    }
    position.splice(index, 1)
    copyOfUser.positions = position

    return [...prev].map(c => c.id === copyOfUser.id ? copyOfUser : c);
  }, [boxes, signaturePositions])

  // Delete position handler
  const deletePositionHandler = useCallback((e, signer, type, index) => {
    e.preventDefault();
    if(type === 'manual') {
      setCustomSigners(prev => setSignersDeletePositionHelper(prev, signer, index));
    }
  }, [setSignersDeletePositionHelper])

  // On edit position
  const handleEditPosition = useCallback(async (e, signer, type, index) => {
    e.preventDefault()
    // console.log(signer.positions[index])
    let boxId = signer.positions[index].boxId 
    let box = boxes.find(b => b.id === boxId)
    if(!box) return console.log('no box found')
    setBoxMode('edit')
    setBoxes(boxes.map(b => b.id === box.id ? {...box, disabled: false} : {...b, disabled: true}))
    setActiveBox({...box, disabled: false})
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      await loadPdf()
    }else {
      setShowPlaceSignatureInterface(true)
    }
  }, [boxes, loadPdf, mergedFilesBase64])

  // On edit position
  const handleEditCheckboxPosition = useCallback(async (field) => {
    // console.log(field)
    let box = boxes.find(b => b.id === field.id)
    if(!box) return console.log('no box found')
    setBoxMode('edit')
    setBoxes(boxes.map(b => b.id === box.id ? {...box, disabled: false} : {...b, disabled: true}))
    setActiveBox({...box, disabled: false})
    if(!mergedFilesBase64) {
      setShowResponseLoader(true)
      await loadPdf()
    }else {
      setShowPlaceSignatureInterface(true)
    }
  }, [boxes, mergedFilesBase64, loadPdf])

  // Check if signature position is set, if not enable attachments 
  useEffect(() => {
    const isPositionSet = () => {
      // if(signer.position && signer.position.length > 0) return true;
      if(customSigners.length > 0) {
        for(let i = 0; i < customSigners.length; i++) {
          if(customSigners[i].position && customSigners[i].position.length > 0) return true;
        }
      }
      if(fieldsPositions.length > 0) return true; 
      return false;
    }
    if(!isPositionSet()) {
      setSignaturePlaced(false);
    }
  }, [customSigners, fieldsPositions]);

  // Update field required prop
  const updateFieldRequiredProp = useCallback((checked, id) => {
    const arr = [...fieldsPositions]
    const field = arr.find(f => f.id === id)
    const updatedField = { ...field, required: checked }
    setFieldsPositions(arr.map(f => f.id === id ? updatedField : f))
    setBoxes(boxes.map(box => box.id === id ? {...box, required: checked} : box))
  }, [boxes, fieldsPositions])

  // Update field label text
  const updateLabel = useCallback((e, id, labelId) => {
    const arr = [...fieldsPositions]
    const field = fieldsPositions.find(f => f.id === id)
    const updatedField = { ...field, label: { ...field.label, value: e.target.value } }
    setFieldsPositions(arr.map(f => f.id === id ? updatedField : f))
    setBoxes(boxes.map(box => box.id === labelId ? {...box, value: e.target.value} : box))
  }, [boxes, fieldsPositions])

  // Delete custom field
  const deleteCustomField = useCallback((id) => {
    let updatedBoxes = [...boxes].filter(box => box.id !== id)
    let field = fieldsPositions.find(f => f.id === id)
    if(field && field.label) {
      updatedBoxes = updatedBoxes.filter(box => box.id !== field.label.id)
    }
    setBoxes(updatedBoxes)
    setFieldsPositions(prev => prev.filter(f => f.id !== id))
  }, [boxes, fieldsPositions])

  // Remove label from custom field
  const removeLabel = useCallback((id) => {
    const arr = [...fieldsPositions]
    const field = arr.find(f => f.id === id)
    const boxId = field.label.id 
    setBoxes(boxes.filter(box => box.id !== boxId))
    const updatedField = { ...field, label: { } }
    if(updatedField.error === t('notification.empty_label')) {
      updatedField.error = ''
    }
    setFieldsPositions(arr.map(f => f.id === id ? updatedField : f))
  }, [boxes, fieldsPositions, t])

  // On place signature modal close
  const handleClosePlaceSignatureModal = useCallback(() => {
    setShowPlaceSignatureInterface(false)
    setActiveBox(null)
  }, [])

  // On order switch change
  const handleOrderSwitchChange = (e) => {
    setShowSendInOrder(e.target.checked)
    if(!e.target.checked) {
      setSendInOrder(false)
    }
  }

  // On save signature template
  const handleSaveSignatureTemplate = useCallback(async (e) => {
    e.preventDefault();
    if(signatureTemplateName.trim() === '') {
      return setSignatureTemplateNameError(t('notification.name_is_required'))
    }
    
    if(signatureTemplates.find(tmpl => tmpl.name === signatureTemplateName.trim())) {
      return setNotification({ msg: t('signature_templates.template_already_exists'), type: 'danger' })
    }
    
    if(fieldsPositions.length === 0 && signaturePositions.length === 0) {
      return setNotification({ msg: t('signature_templates.define_positions'), type: 'danger' })
    }

    try {
      const data = {
        name: signatureTemplateName,
        checkbox_positions: fieldsPositions,
        signature_positions: signaturePositions,
        owner: activeTeamMember.id,
        team: selectedTeam?.id,
        meta: {
          created: Date.now(),
          updated: Date.now()
        }
      }
      // console.log(data)

      setShowResponseLoader(true)
      const id = await createSignatureTemplate(data)
      setShowResponseLoader(false)
      setNotification({ msg: t('signature_templates.template_saved'), type: 'success' })
      setShowChooseTemplates(true)
      setActiveSignatureTemplate(id)
      return id
    } catch (err) { 
      console.log(err)
    }
  }, [activeTeamMember, createSignatureTemplate, fieldsPositions, selectedTeam, setNotification, signaturePositions, signatureTemplateName, signatureTemplates, t])

  // On choose template switch change
  const handleChooseTemplateSwitchChange = (e) => {
    setShowChooseTemplates(e.target.checked)
    if(!e.target.checked) {
      setActiveSignatureTemplate('')

      customSigners.forEach(s => {
        if(s.position) s.position = []
      })
      setFieldsPositions([])
      setSignaturePositions([])
      setSignaturePlaced(false)
    }
  }

  // Helper - add all positions to array, if same parentId add to same array
  const addPositionsToArrayHelper = (arr) => {
    let positionsArr = []
    arr.forEach((pos, idx) => {
      if(idx === 0) { 
        positionsArr.push([pos])
      }else {
        let added = false
        positionsArr.forEach(arr => {
          if(arr.find(p => p.parentId === pos.parentId)) {
            added = true
            arr.push(pos)
          }
        })
        if(!added) {
          positionsArr.push([pos])
        }
      }
    })
    return positionsArr
  }

  // Add empty customers
  const addEmptyCustomers = () => {
    const name = `signer-${uuidv4()}`
    const data = {
      id: name,
      email: { name: `${name}-email`, value: '' },
      name: { name: `${name}-name`, value: '' },
      lastname: { name: `${name}-lastname`, value: '' },
      mobile: { name: `${name}-mobile`, value: '' },
      error: '',
      errors: [],
      errorMessages: [],
      positions: []
    }
    return data
  }

  // Check if positions are not added
  const checkIfPositionsNotAdded = (signer, arr) => {
    let notAdded = true 
    // for(let i = 0; i < sArr.length; i++) {
    //   if(!sArr[i].positions[0]) {
    //     console.log('no first obj')
    //     break
    //   }
    //   for(let j = 0; j < arr.length; j++) {
    //     if(sArr[i].positions.find(p => p.id === arr[j].id)) {
    //       console.log('already added', sArr[i])
    //       notAdded = false
    //       break
    //     }
    //   }
    //   if(!notAdded) break
    // }
    if(signer.positions && signer.positions.length > 0) {
      notAdded = true
    }
    return notAdded
  }
  
  // On template select
  const handleTemplateSelect = (value) => {
    if(activeSignatureTemplate === value) return

    setActiveSignatureTemplate(value)
    let sArr = [...customSigners]

    sArr.forEach(s => {
      if(s.positions) s.positions = []
    });

    if(value === '') {
      setFieldsPositions([])
      setSignaturePositions([])
      setSignaturePlaced(false)
      setBoxes([])
      return
    }

    const foundTemplate = signatureTemplates.find(tmpl => tmpl.id === value)
    if(foundTemplate) {
      // console.log(foundTemplate)
      let boxes = []
      foundTemplate.checkbox_positions.forEach((pos) => {
        let box = {
          id: pos.id,
          top: pos.top,
          left: pos.left,
          width: pos.width,
          height: pos.height,
          page: pos.page,
          type: 'checkbox',
          disabled: !!pos.disabled,
          saved: true
        }
        boxes.push(box)
        if(pos.label) {
          let labelBox = {
            id: pos.label.id,
            top: pos.label.top,
            left: pos.label.left,
            width: pos.label.width,
            height: pos.label.height,
            page: pos.label.page,
            type: 'label',
            disabled: !!pos.label.disabled,
            saved: true,
            parentId: pos.label.parentId
          }
          boxes.push(labelBox)
        }
      })
      foundTemplate.signature_positions.forEach((pos) => {
        let box = {
          id: pos.boxId,
          top: pos.top,
          left: pos.left,
          width: pos.width,
          height: pos.height,
          page: pos.page,
          type: 'signature',
          disabled: !!pos.disabled,
          saved: true,
          parentId: pos.parentId
        }
        boxes.push(box)
      })
      
      setFieldsPositions(JSON.parse(JSON.stringify(foundTemplate.checkbox_positions)))
      setSignaturePositions(JSON.parse(JSON.stringify(foundTemplate.signature_positions)))
      let positionsArr = addPositionsToArrayHelper(foundTemplate.signature_positions)
      let customersArr = []
      if(customSigners.length < positionsArr.length) {
        let diff = positionsArr.length - customSigners.length
        for(let i = 0; i < diff; i++) {
          let data = addEmptyCustomers()
          customersArr.push({...data, positions: positionsArr[customSigners.length + i]})
          positionsArr[customSigners.length + i].forEach(pos => {
            let box = boxes.find(b => b.id === pos.boxId)
            if(box) box.parentId = data.id
          })
        }
        sArr = [...sArr, ...customersArr]
      }
      setCustomSigners(prev => [...prev, ...customersArr])
      setOrderedSignatairesArr(prev => [...prev, ...customersArr])
      setBoxes(boxes)
      
      if(positionsArr.length > 0) {
        sArr.forEach((p, idx) => {
          if((!p.positions || p.positions?.length === 0)) {
            if(!p.positions) p.positions = []
            let added = false
            if(!added && checkIfPositionsNotAdded(p, positionsArr[idx])) {
              if(positionsArr[idx]) {
                positionsArr[idx].forEach(item => p.positions.push(item))
                added = true
              }
            }
            // positionsArr.forEach((pos) => {
            //   if(!added && checkIfPositionsNotAdded(p, pos)) {
            //     pos.forEach(item => p.positions.push(item))
            //     added = true
            //   }
            // })
          }
        })
      }
      setSignatureTemplateName(foundTemplate.name)
      setSignaturePlaced(true)
    }
  }

  // Update signature template
  const handleUpdateSignatureTemplate = async () => {
    if(fieldsPositions.length === 0 && signaturePositions.length === 0) {
      return setNotification({ msg: t('signature_templates.define_positions'), type: 'danger' })
    }

    const foundSelectedTemplate = signatureTemplates.find(tmpl => tmpl.id === activeSignatureTemplate)
    if(foundSelectedTemplate) {
      setShowResponseLoader(true)
      try {
        await updateSignatureTemplate(foundSelectedTemplate.id, { checkbox_positions: fieldsPositions, signature_positions: signaturePositions })
        setNotification({ msg: t('signature_templates.template_updated'), type: 'success' })
      } catch (err) {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
      setShowResponseLoader(false);
    }else {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }

  // Check if signature template changed
  const didSignatureTemplateChanged = useMemo((sPositions = null, cPositions = null) => {
    const foundTemplate = signatureTemplates.find(tmpl => tmpl.id === activeSignatureTemplate);
    let changed = false;
    let changes = {};
    if(foundTemplate) {
      const c_positions = foundTemplate.checkbox_positions;
      const s_positions = foundTemplate.signature_positions;
      let changed_c_positions = [];
      let changed_s_positions = [];
      let cPositionsCheck = cPositions || fieldsPositions;
      let sPositionsCheck = sPositions || signaturePositions;
      // console.log(c_positions)
      // console.log(cPositionsCheck)
      cPositionsCheck.forEach(pos => {
        let foundPos = c_positions.find(p => p.id === pos.id);
        if(!foundPos) {
          changed = true;
          changed_c_positions.push({...pos, action: 'added'});
        }else {
          // check if updated
          // console.log(foundPos)
          // console.log(pos)
          let updated = false;
          for(let key in pos) {
            if(!updated && key !== 'error') {
              if(key !== 'label' && pos[key] !== foundPos[key]) {
                changed = true;
                changed_c_positions.push({...pos, action: 'updated'});
                updated = true;
              }else if(key === 'label') {
                if(!updated && Object.keys(pos.label).length !== Object.keys(foundPos.label).length) {
                  changed = true;
                  changed_c_positions.push({...pos, action: 'updated'});
                  updated = true;
                }else {
                  for(let labelKey in pos.label) {
                    if(!updated && pos.label[labelKey] !== foundPos.label[labelKey]) {
                      changed = true;
                      changed_c_positions.push({...pos, action: 'updated'});
                      updated = true;
                    }
                  }
                }
              }
            }
          }
        }
      });
      c_positions.forEach(pos => {
        if(!cPositionsCheck.find(p => p.id === pos.id)) {
          changed = true;
          changed_c_positions.push({...pos, action: 'removed'});
        }
      });

      sPositionsCheck.forEach(pos => {
        const foundPos = s_positions.find(p => p.boxId === pos.boxId);
        // console.log(foundPos)
        if(!foundPos) {
          changed = true;
          changed_s_positions.push({...pos, action: 'added'});
        }else {
          // console.log(changed_s_positions)
          if(!changed_s_positions.find(p => p.boxId === pos.boxId)) {
            let notEqual = false
            for(let key in pos) {
              if(pos[key] !== foundPos[key]) {
                changed = true 
                notEqual = true
              }
            }
            if(notEqual) {
              changed_s_positions.push({...pos, action: 'updated'})
            }
          }
        }
      });
      s_positions.forEach(pos => {
        if(!sPositionsCheck.find(p => p.boxId === pos.boxId)) {
          changed = true;
          changed_s_positions.push({...pos, action: 'removed'});
        }
      });

      changes.checkbox_positions = changed_c_positions;
      changes.signature_positions = changed_s_positions;
    }
    // console.log(changes)
    return {
      changed,
      changes
    }
  }, [activeSignatureTemplate, fieldsPositions, signaturePositions, signatureTemplates])

  // Get total size of selected documents
  const getTotalSizeOfSelectedDocuments = useCallback(() => {
    let total = 1
    for(let i = 0; i < attachments.length; i++) {
      if(attachments[i].sign && attachments[i].size) {
        total += attachments[i].size
      }
    }
    total += fileSize
    return total 
  }, [attachments, fileSize])
  
  return(
    <Modal onClose={onClose} className="signature-modal" useEscBtn={!showPlaceSigntuareInterface && !showOrderCustomersModal && !showSendRequestAlert && !showSignatureTemplatesModal && !showSaveUpdatedTemplateModal}>
      {!requestSent && canUseSignatures ? 
        <div className="signature-modal-inner">
          <h2>{documentName}</h2>
          <div className="signature-modal-inner__head">
            <ul>
              <li className="active no-cursor">{t('sign_document.request_signature')}</li>
            </ul>
          </div>
          <div className="signature-modal-inner__body">
            <form className="form" onSubmit={submitHandler}>
              {/* Signature templates */}
              {availableOn(['development']) && signatureTemplates && signatureTemplates.length > 0 && <ChooseTemplateSection showChooseTemplates={showChooseTemplates} chooseTemplateSwitchChangeHandler={handleChooseTemplateSwitchChange} signatureTemplatesOptions={signatureTemplatesOptions} templateSelectedHandler={handleTemplateSelect} activeSignatureTemplate={activeSignatureTemplate} setShowSignatureTemplatesModal={setShowSignatureTemplatesModal} />}

              <div className="signature-modal-inner__section">
                <p className="form__subtitle">{t('sign_document.emitter')}</p>
                <div className="form__group">
                  <Input 
                    name="emmeteur"
                    value={emmeteur}
                    onChange={(e) => setEmmeteur(e.target.value)}
                    label={t('auth.email')}
                    formEl 
                    error={emmeteurError}
                    onFocus={() => onSetEditStartTime(Date.now())}
                    onBlur={onStoreEditTime}
                  />
                </div>
              </div>

              <div className="signature-modal-inner__section">
                <p className="form__subtitle">
                  {t('sign_document.send_requests_to')}
                  <CustomTooltip content={t('general.add_recipient')}>
                    <span>
                      <IconButton 
                        onButtonClick={addNewUser}
                        icon={<PersonAddIcon />}
                        lightGray
                      />
                    </span>
                  </CustomTooltip>
                </p>
                {customSigners.map((signer, index) => <Fields 
                  key={index}
                  index={index}
                  signer={signer}
                  changeValueHandler={changeValueHandler}
                  type={'manual'}
                  onSetEditStartTime={onSetEditStartTime}
                  onStoreEditTime={onStoreEditTime}
                  handleEditPosition={handleEditPosition} 
                  deletePositionHandler={deletePositionHandler} 
                  placeSignatureHandler={placeSignatureHandler} 
                  deleteCustomUser={deleteCustomUser}
                />)}
                {generalError && <p className="u-error">{generalError}</p>}
              </div>

              <div className="signature-modal-inner__section">
                <p className="form__subtitle form__subtitle--2">{t('sign_document.attachments_title')}</p>
                <Attachments 
                  attachments={attachments} 
                  onStoreEditTime={onStoreEditTime} 
                  onSetEditStartTime={onSetEditStartTime} 
                  attachmentChangeHandler={attachmentChangeHandler} 
                  signaturePlaced={signaturePlaced}
                  getTotalSizeOfSelectedDocuments={getTotalSizeOfSelectedDocuments}
                  setAttachments={setAttachments}
                  documentId={docId}
                />
              </div>

              <div className="signature-modal-inner__section">
                <p className="form__subtitle form__subtitle--3">{t('sign_document.checkboxes')}</p>
                <CustomFields 
                  fieldsPositions={fieldsPositions}
                  placeSignatureHandler={placeSignatureHandler}
                  updateFieldRequiredProp={updateFieldRequiredProp}
                  handleEditCheckboxPosition={handleEditCheckboxPosition}
                  updateLabel={updateLabel}
                  removeLabel={removeLabel}
                  deleteCustomField={deleteCustomField}
                />
              </div>

              <div className="signature-modal-inner__section">
                <p className="form__subtitle form__subtitle--2">{t('sign_document.sending_order')}</p>
                <div className="switch-wrapper">
                  <Switch checked={showSendInOrder} disabled={customSigners.length < 2} onChange={handleOrderSwitchChange} />
                </div>
                {showSendInOrder && (
                  <>
                    <div className="checkbox-wrapper" onMouseEnter={() => onSetEditStartTime(Date.now())} onMouseLeave={onStoreEditTime}>
                      <Radio 
                        choices={[
                          { value: 'no', label: t('general.no') },
                          { value: 'yes', label: t('general.yes') },
                        ]} 
                        onChange={(e) => {
                          setOrderRadioValue(e.target.value)
                          if(e.target.value === 'no') {
                            setSendInOrder(false)
                          }else {
                            setSendInOrder(true)
                          }
                        }}
                        selectedValue={orderRadioValue}
                        name="order_radio"
                        style2
                      />
                    </div>
                    {orderRadioValue === 'yes' && <Button 
                      text={t('sign_document.define_order')} 
                      primary
                      disabled={customSigners.length < 2}
                      onButtonClick={() => setShowOrderCustomersModal(true)} 
                    />}
                  </>
                )}
              </div>
              {/* Signature templates */}
              {availableOn(['development']) && activeSignatureTemplate === '' && <SaveTemplateSection 
                showSaveTemplate={showSaveTemplate} 
                setShowSaveTemplate={setShowSaveTemplate} 
                signatureTemplateName={signatureTemplateName} 
                signatureTemplateNameError={signatureTemplateNameError} 
                setSignatureTemplateName={setSignatureTemplateName} 
                setSignatureTemplateNameError={setSignatureTemplateNameError} 
                onSaveSignatureTemplate={handleSaveSignatureTemplate} 
              />}

              {availableOn(['development']) && didSignatureTemplateChanged.changed && <UpdateTemplateSection 
                didSignatureTemplateChanged={didSignatureTemplateChanged} 
                updateSignatureTemplateHandler={handleUpdateSignatureTemplate} 
                setShowSaveUpdatedTemplateModal={setShowSaveUpdatedTemplateModal} 
              />}
              
              {requestFailed && <Message type="error" msg={signatureErrors.join('')} />}
              <div className="form__actions">
                <Button 
                  text={t('general.cancel')} 
                  transparent
                  onButtonClick={onClose}
                  medium
                />
                <Button 
                  // text={loading ? "Envoi…" : "Envoyer"}
                  // type="submit"
                  text={t('general.send_to')}
                  primary
                  disabled={creditsLeft < 1}
                  onButtonClick={validateForm}
                  medium
                />
                <p>{t('general.credits_left')}: {creditsLeft}</p>
              </div>
            </form>
          </div>
        </div>
        :
        !siteConfigsLoaded 
          ? <div className="loader-wrapper"><Loader primary normal /> </div>
          : !canUseSignatures && <SignaturesNotAvailable />
      }

      {requestSent && 
        <div className="signatures-success">
          <div className="signatures-success-top">
            <h2 className="">{t('dashboard.signatures_2')}</h2>
          </div>
          <Message type="success" msg={successMessage} />
          <div className="buttons-wrapper">
            <Button text={t('general.back')} onButtonClick={() => setRequestSent(false)} />
            {/* <a href={urlToDoc} className="button" target="_blank" rel="noopener noreferrer">{t('general.check_document')}</a> */}
          </div>
        </div>
      }

      {showPlaceSigntuareInterface && <PlaceSignatureInterfaceModalV2 
        onClose={handleClosePlaceSignatureModal} 
        onSignaturePositionSave={signaturePositionSaveHandler}
        // onSetEditStartTime={onSetEditStartTime}
        // onStoreEditTime={onStoreEditTime}
        // windowVisibility={windowVisibility}
        // editStartTime={editStartTime}
        base64Data={mergedFilesBase64}
        boxes={boxes}
        onSetBoxes={setBoxes}
        currentBox={activeBox}
      />}

      {showOrderCustomersModal && <OrderCustomersModal 
        onClose={() => setShowOrderCustomersModal(false)} 
        orderedSignatairesArr={orderedSignatairesArr}
        onSetOrderedSignatairesArr={setOrderedSignatairesArr}
        onSetEditStartTime={onSetEditStartTime}
        onStoreEditTime={onStoreEditTime}
        windowVisibility={windowVisibility}
        editStartTime={editStartTime}
      />}

      {showSendRequestAlert && <Alert 
        text={t('sign_document.confirm')}
        onClose={() => setShowSendRequestAlert(false)} 
        onSubmit={submitHandler}
      />}

      {showSignatureTemplatesModal && <SignatureTemplatesModal 
        onClose={() => setShowSignatureTemplatesModal(false)} 
        templates={signatureTemplates}
        doc={{...documentObject, id: docId}}
        activeTemplate={activeSignatureTemplate}
        onSetActiveTemplate={setActiveSignatureTemplate}
        customSigners={customSigners}
        onSetFieldsPositions={setFieldsPositions}
        onSetSignaturePositions={setSignaturePositions}
        onSetSignaturePlaced={setSignaturePlaced}
        onSetBoxes={setBoxes}
      />}

      {showSaveUpdatedTemplateModal && <SaveUpdatedSignatureTemplateModal 
        onClose={() => setShowSaveUpdatedTemplateModal(false)}
        onTemplateSave={handleSaveSignatureTemplate}
        name={signatureTemplateName}
        onSetName={setSignatureTemplateName}
        onSetActiveTemplate={setActiveSignatureTemplate}
      />}

      {showResponseLoader && <ResponseLoader />}
    </Modal>
  );
}

const Attachments = memo(({ attachments, onStoreEditTime, onSetEditStartTime, attachmentChangeHandler, signaturePlaced, getTotalSizeOfSelectedDocuments, setAttachments, documentId }) => {
  const { t } = useContext(GlobalContext)
  const { uploadAttachments } = useAttachmentsActions()
  const [uploading, setUploading] = useState(false)
  const [errors, setErrors] = useState([])
  const [progress, setProgress] = useState(0)
  const fileInput = useRef()

  // On open upload atts modal
  const handleSelectBtnClick = () => {
    if(!fileInput.current) return
    fileInput.current.click()
  }

  // On file select - from file window
  const handleChange = async (e) => {
    uploadAttachments({ e, errors, setErrors, attachmentsNames: [...attachments].map(a => a.name + '.' + a.format), attachments, setUploading, setProgress, setAttachments, documentId, onlyPdfAndDocx: true })
  }

  return (
    <div className="share-attachments-wrapper">
      <div className="share-attachments-wrapper__box">
        { attachments.map((att, i) => {
          return (
            <div 
              className="share-attachments-wrapper__box_item" 
              key={i} 
              onMouseEnter={() => onSetEditStartTime(Date.now())}
              onMouseLeave={onStoreEditTime}
            >
              <Checkbox 
                onChange={(e) => attachmentChangeHandler(i, e.target.checked)} 
                checked={!!att.sign} 
                disabled={signaturePlaced}
              />
              <span>{ `${att.name} (${att.type}) ${att.size ? `(${bytesToSize(att.size)})` : ''}` }</span>
            </div>
          )
        })}
        {attachments.length === 0 && <p>{t('general.no_attachments')}</p>}
      </div>
      {signaturePlaced && <p className="attachments-disabled-msg">{t('sign_document.remove_signatures_to_add_attachments')}</p>}
      <div className="attachments-size-wrapper">
        <p className="attachments-size-msg">{t('signatures.current_total_size')}: <span>{bytesToSize(getTotalSizeOfSelectedDocuments())}</span></p>
        <p className="attachments-size-msg">{t('signatures.max_allowed_size')}: <span>16MB</span></p>
      </div>
      <div className="attachments-upload-trigger">
        <Button text={t('general.select_file')} primaryLight type="button" onButtonClick={handleSelectBtnClick} />
        <input 
          type="file" 
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
          onChange={handleChange} 
          className="file-input" 
          ref={fileInput} 
          multiple  
        />
        {uploading && (
          <div className="attachments-upload-trigger__uploading">
            <div className="progress"><div style={{width: `${progress}%`}}></div><p>{progress}%</p></div>
            <Loader normal primary />
          </div>
        )}
      </div>
    </div>
  )
})

const Fields = memo(({ index, signer, changeValueHandler, type, onSetEditStartTime, onStoreEditTime, handleEditPosition, deletePositionHandler, placeSignatureHandler, deleteCustomUser }) => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="form__fields-group" key={index}>
      <div className="form__group form__group--signature-modal">
        <Input 
          name={signer.name.name}
          value={signer.name.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'name', type)}
          label={`${t('general.name')} *`}
          formEl 
          disabled={type === 'customers' || type === 'admins'}
          onFocus={() => onSetEditStartTime(Date.now())}
          onBlur={onStoreEditTime}
          className={`${signer.errors.includes('name') ? 'error' : ''}`}
        />
        <Input 
          name={signer.lastname.name}
          value={signer.lastname.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'lastname', type)}
          label={`${t('auth.first_name')} *`}
          formEl 
          disabled={type === 'customers' || type === 'admins'}
          onFocus={() => onSetEditStartTime(Date.now())}
          onBlur={onStoreEditTime}
          className={`${signer.errors.includes('lastname') ? 'error' : ''}`}
        />
      </div> 
      <div className="form__group form__group--signature-modal">
        <Input 
          name={signer.email.name}
          value={signer.email.value}
          onChange={(e) => changeValueHandler(e, signer.id, 'email', type)}
          label={`${t('auth.email')} *`}
          formEl 
          disabled={type === 'customers' || type === 'admins'}
          onFocus={() => onSetEditStartTime(Date.now())}
          onBlur={onStoreEditTime}
          className={`${signer.errors.includes('email') ? 'error' : ''}`}
        />
        {type === 'manual' ? <Input 
            name={signer.mobile.name}
            value={signer.mobile.value}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label={`${t('general.mobile')} *`}
            formEl 
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
            className={`${signer.errors.includes('mobile') ? 'error' : ''}`}
          />
        : signer.mobile.value && <Input 
            name={signer.mobile.name}
            value={signer.mobile.value}
            onChange={(e) => changeValueHandler(e, signer.id, 'mobile', type)}
            label={`${t('general.mobile')} *`}
            formEl 
            disabled
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
            className={`${signer.errors.includes('mobile') ? 'error' : ''}`}
          />
        }
      </div> 
      {/* {signer.error && <div className="form__error"><p className="form-input__error">* {signer.error}</p></div>} */}
      <div className="form__group_actions">
        {signer.positions && signer.positions.map((pos, i) => (
          <div className="signature-positions" key={i}>
            <div className="signature-positions__page">{t('sign_document.page')} {+pos.page + 1}</div>
            <CustomTooltip content={t('sign_document.change_signature_position')}>
              <div>
                <IconButton icon={<Edit />} onButtonClick={(e) => handleEditPosition(e, signer, type, i)} className="edit-btn" />
              </div>
            </CustomTooltip>
            <CustomTooltip content={t('sign_document.remove_signature')}>
              <div>
                <IconButton icon={<DeleteIcon />} onButtonClick={(e) => deletePositionHandler(e, signer, type, i)} className="delete-btn" />
              </div>
            </CustomTooltip>
          </div>
        ))}
        <div className="form__group_actions-bottom">
          <Button 
            text={t('sign_document.set_signature_position')}
            onButtonClick={(e) => placeSignatureHandler(e, `signature-${Date.now()}`, 'signature', signer.id)} 
            primaryLight 
            type="button"
          />
          {index !== 0 && <CustomTooltip content={t('general.delete_user')} position="left">
            <span>
              <IconButton 
                onButtonClick={(e) => deleteCustomUser(e, index, signer.email.value)}
                icon={<DeleteIcon />}
                light
                className="delete-btn"
              />
            </span>
          </CustomTooltip>}
        </div>
      </div>
    </div>
  )
})

const CustomFields = memo(({ fieldsPositions, placeSignatureHandler, updateFieldRequiredProp, handleEditCheckboxPosition, updateLabel, removeLabel, deleteCustomField }) => {
  const { t } = useContext(GlobalContext)
  
  return (
    <div className="fields-wrapper fields-wrapper--2">
      <Button text={t('sign_document.add_checkbox')} onButtonClick={(e) => placeSignatureHandler(e, `checkbox-${Date.now()}`, 'checkbox')} primaryLight />
      <div>
        {fieldsPositions.map((field, i) => {
          return (
            <div className="field-positions" key={i}>
              <div className="field-positions__page">{t('sign_document.page')} {+field.page + 1}</div>
              <div className="checkbox-wrapper">
                <div className="checkbox-wrapper__field">
                  <div className="checkbox-wrapper__field_fix">
                    <Checkbox 
                      onChange={(e) => updateFieldRequiredProp(e.target.checked, field.id)}
                      checked={field.required}
                    />
                  </div>
                  <p>{t('sign_document.required')}</p>
                </div>
                <CustomTooltip content={t('sign_document.change_checkbox_position')}>
                  <div className="eidt-btn-wrapper">
                    <IconButton icon={<Edit />} onButtonClick={() => handleEditCheckboxPosition(field)} light className="edit-btn" />
                  </div>
                </CustomTooltip>
              </div>
              <div className="field-positions__divider"></div>
              {(!field.label || (field.label && Object.keys(field.label).length === 0)) && <Button 
                text={t('sign_document.add_label')} 
                // onButtonClick={(e) => placeSignatureHandler(e, Object.keys(field.label).length > 0 ? field.label.id : `label-${Date.now()}`, 'label', field.id)} 
                onButtonClick={(e) => placeSignatureHandler(e, `label-${Date.now()}`, 'label', field.id)} 
                className="label-btn" 
                primaryLight 
              />}
              {field.label && Object.keys(field.label).length > 0 && 
                <div className="checkbox-label">
                  <Input 
                    value={field.label.value} 
                    onChange={(e) => updateLabel(e, field.id, field.label.id)} 
                    className={`label-input ${field.error ? 'error' : ''}`}
                    placeholder={t('sign_document.label_placeholder')} 
                  />
                  <CustomTooltip content={t('sign_document.change_label')}>
                    <div className="edit-btn-wrapper">
                      <IconButton icon={<Edit />} onButtonClick={(e) => placeSignatureHandler(e, field.label.id, 'label', field.id)} light className="edit-btn" />
                    </div>
                  </CustomTooltip>
                  <CustomTooltip content={t('sign_document.remove_label')}>
                    <div>
                      <IconButton icon={<DeleteIcon />} light onButtonClick={() => removeLabel(field.id)} className="delete-btn" />
                    </div>
                  </CustomTooltip>
                </div>
              }
              {/* {field.error && <p className="field-positions__error">{field.error}</p>} */}
              <CustomTooltip content={t('sign_document.remove_checkbox')}>
                <div className="field-positions__remove">
                  <IconButton icon={<DeleteIcon />} onButtonClick={(e) => deleteCustomField(field.id)} className="delete-btn delete-btn--2" />
                </div>
              </CustomTooltip>
            </div>
          )
        })}
      </div>
    </div>
  )
})

export default SignDocumentModalOnespan
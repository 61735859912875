import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router"
import IconButton from "../UI/IconButton"
import Loader from "../UI/Loader"
import ClosedIcon from "@material-ui/icons/Close"
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ContactsContext, DocumentsContext, LoaderContext, SignatureContext, TaskContext, TeamContext } from "../../context"

const SearchResults = () => {


  const { setLoading, loading } = useContext(LoaderContext);
  const { setFilteredDocuments, documents, setShowFilteredDocs, setCurrentFilter, currentFilter, setArchivedDocuments, setDeletedDocuments, archivedDocuments, deletedDocuments, setActiveFilterColumn, setDateFilters, fetchDocuments, getTemplateById, updateDocument, updateMultipleDocuments, templates, documentsLoaded, fetchDocs, templatesLoaded, getTemplates } = useContext(DocumentsContext);
  const { signatures, signaturesFetched, fetchSignatures, deleteSignature, updateSignature } = useContext(SignatureContext);
  const { companies, contacts, fetchContacts, contactsFetched } = useContext(ContactsContext);
  const { tasks, tasksFetched, fetchTasks } = useContext(TaskContext);
  const { selectedTeam, teamChanged, setTeamChanged } = useContext(TeamContext)


  const history = useHistory()
  const [query, setQuery] = useState('')
  const [areas, setAreas] = useState([])
  const [selectedArea, setSelectedArea] = useState('')
  const [results, setResults] = useState({})

  const validAreas = [
    { label: 'Documents', key: 'documents', icon: <DescriptionIcon /> },
    { label: 'Modèles', key: 'templates', icon: <i className="custom-icon-template" /> },
    { label: 'Signatures', key: 'signatures', icon: <i className="custom-icon-create" /> },
    { label: 'Tâches', key: 'tasks', icon: <CheckCircleOutlineIcon /> },
    { label: 'Personnes', key: 'contacts', icon: <PersonIcon />  },
  ]
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
    }
  }, [teamChanged, setTeamChanged])

  // Fetch collections if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!documentsLoaded) {
        // console.log('fetch documents')
        fetchDocs(teamId)
      }
      if(!templatesLoaded) {
        // console.log('fetch templates')
        getTemplates(teamId)
      }
      if(!signaturesFetched) {
        // console.log('fetch signatures')
        fetchSignatures(teamId)
      }
      if(!tasksFetched) {
        // console.log('fetch tasks')
        fetchTasks(teamId)
      }
      if(!contactsFetched) {
        // console.log('fetch contacts')
        fetchContacts(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, tasksFetched, fetchTasks, documentsLoaded, fetchDocs, templatesLoaded, getTemplates, signaturesFetched, fetchSignatures, contactsFetched, fetchContacts])

  useEffect(() => {
    if(!areas || areas.length === 0) {
      setAreas(validAreas.map(a => a.key))
      setSelectedArea(validAreas[0].key)
    }
    if(query && areas.length > 0) {
      search()
    }
  }, [query, areas, documents, templates, signatures, contacts, tasks])

  useEffect(() => {
    if(!history.location.search) {
      return
    }
    let qString = history.location.search.split('?')[1]
    let paramStrings = qString.split('&')
    for(let i in paramStrings) {
      let components = paramStrings[i].split('=')
      if(components[0] === 'area') {
        setAreas()
        let ars = components[1].split(',')
        let sAreas = []
        for(let i in validAreas) {
          if( ars.includes(validAreas[i].key)) {
            sAreas.push(validAreas[i].key)
          }
        }
        setAreas([...sAreas])
        setSelectedArea(sAreas[0])
      } else if(components[0] === 'query') {
        setQuery(decodeURI(components[1].toLowerCase()))
      }
    }
  }, [history.location.search])

  const closeHandler = () => {
    history.goBack()
  }

  const selectArea = (area) => {
    setSelectedArea(area)
  }

  const search = () => {
    setLoading(true)
    let subRes
    let res = {}
    for(let i in areas) {
      switch(areas[i]) {
        case 'documents': {
          subRes = searchDocuments()
          break
        }
        case 'templates': {
          subRes = searchTemplates()
          break
        }
        case 'signatures': {
          subRes = searchSignatures()
          break
        }
        case 'tasks': {
          subRes = searchTasks()
          break
        }
        case 'contacts': {
          subRes = searchContacts()
          break
        }
        default:
          break
      }
      res[areas[i]] = subRes
    }
    setResults({...res})
    setLoading(false)
  }

  const searchDocuments = () => {
    let dResults = []
    for(let key in documents) {
      let lastSearchString = ''
      const searchedDocument = documents[key]
      const name = (searchedDocument.name || '').toLowerCase();
      let includes = name.includes(query)
      if(!includes && searchedDocument.scanned_content) {
        lastSearchString = searchedDocument.scanned_content
        includes = searchedDocument.scanned_content.toLowerCase().includes(query)
      }
      if(includes) {
        dResults.push({ object: {...searchedDocument, id: key}, label: lastSearchString })
        continue
      }
      let added = false
      for(let valueKey in searchedDocument.values) {
        lastSearchString = `${valueKey}: ${searchedDocument.values[valueKey]}`
        if(typeof searchedDocument.values[valueKey] === 'string' && searchedDocument.values[valueKey].toLowerCase().includes(query)) {
          dResults.push({ object: {...searchedDocument, id: key}, label: lastSearchString })
          added = true
          break
        }
      }
      if(!added) {
        let tResult = templateIncludes(searchedDocument.template, query)
        if(tResult.includes) {
          dResults.push({ object: {...searchedDocument, id: key}, label: tResult.lastSearchString })
        }
      }
      
    }
    return dResults
  }

  const searchTemplates = () => {
    let tResults = []
    for(let key in templates) {
      let result = templateIncludes(key, query)
      if(result.includes) {
        tResults.push({ object: {...templates[key], id: key}, label: result.lastSearchString })
      }
    }
    return tResults
  }

  const templateIncludes = (templateId, searchPhrase) => {
    let searchedTemplate = templates[templateId]
    if(!searchedTemplate) {
      return false
    }
    let lastSearchString = ''
    let includes = (searchedTemplate.name || '').toLowerCase().includes(searchPhrase)
    if(includes) {
      return { includes, lastSearchString }
    }
    for(let i in searchedTemplate.sections) {
      let section = searchedTemplate.sections[i]
      if(section.content) {
        lastSearchString = section.content
        includes = section.content.toLowerCase().includes(searchPhrase)
      } else if(section.items) {
        for(let j in section.items) {
          if(section.items[j].content) {
            lastSearchString = section.items[j].content
            includes = section.items[j].content.toLowerCase().includes(searchPhrase)
          }
          if(includes) {
            break
          }
        }
      }
      if(includes) {
        break
      }
    }
    return { includes, lastSearchString }
  }

  const searchSignatures = () => {
    let sResults = []
    let lastSearchString
    for(let i in signatures) {
      const sig = signatures[i]
      let found = false
      if(documents[sig.doc_id]) {
        let name = documents[sig.doc_id].name || ''
        lastSearchString = `Document: ${name}`
        if(name.toLowerCase().includes(query)) {
          found = true
        }
      }
      if(!found) {
        for(let r in sig.recipients) {
          const rec = sig.recipients[r]
          if(rec.email.toLowerCase().includes(query)) {
            found = true
            lastSearchString = `Recipient: ${rec.email}`
          } else if(rec.lastname.toLowerCase().includes(query) || rec.name.toLowerCase().includes(query)) {
            found = true
            lastSearchString = `Recipient: ${rec.name} ${rec.lastname}`
          }
          if(found) {
            break
          }
        }
      }
      if(found) {
        sResults.push({
          object: sig,
          label: lastSearchString
        })
      }
    }
    return sResults
  }

  const searchTasks = () => {
    let tResults = []
    for(let i in tasks) {
      let lastSearchString = ''
      let found = false
      const t = tasks[i]
      if(t.name.toLowerCase().includes(query)) {
        // lastSearchString = t.name
        found = true
      }
      if(!found) {
        if(documents[t.relatedDocument] && (documents[t.relatedDocument].name || '').toLowerCase().includes(query)) {
          lastSearchString = `Document: ${(documents[t.relatedDocument].name || '')}`
          found = true
        }
      }
      if(found) {
        tResults.push({
          object: t,
          label: lastSearchString
        })
      }
    }
    return tResults
  }

  const searchContacts = () => {
    let cResults = []
    let lastSearchString = ''
    for(let i in contacts) {
      let contact = contacts[i]
      let found = false
      if(contact.first_name && contact.first_name.toLowerCase().includes(query)) {
        // lastSearchString = contact.name
        found = true
      }
      if(!found && contact.last_name && contact.last_name.toLowerCase().includes(query)) {
        found = true
      }
      if(!found && contact.email && contact.email.toLowerCase().includes(query)) {
        found = true
      }
      if(!found && contact.companies) {
        for(let c in contact.companies) {
          if(contact.companies[c].toLowerCase().includes(query)) {
            lastSearchString = `Company: ${contact.companies[c]}`
            found = true
            break
          }
        }
      }
      if(found) {
        cResults.push({
          object: contact,
          label: lastSearchString
        })
      }
    }
    return cResults
  }

  const onRowClick = (area, object) => {
    if(area === 'documents') {
      history.push({
        pathname: `/document-detail/${object.id}`
      })

    } else if(area === 'templates') {
      history.push({
        pathname: `/template/${object.id}`
      })
    } else if(area === 'tasks') {
      if(object.relatedDocument) {
        history.push({
          pathname: `/document-detail/${object.relatedDocument}`,
          state: {
            openTasks: true
          }
        })
      } else {
        history.push({
          pathname: '/dashboard',
          state: {
            showTasksModal: true
          }
        })
      }
    } else if(area === 'signatures') {
      history.push({
        pathname: '/signatures',
        state: {
          openSignaturePreviewObject: object
        }
      })
    } else if(area === 'contacts') {
      // history.push({
      //   pathname: `/document-detail/${object.documents[0]}`, // todo enable contacts with multiple documents
      //   state: {
      //     openContacts: true
      //   }
      // })
    }
  }

  const labelWithHighlight = (s, name = false) => {
    let maxOffset = 100
    let start = s.toLowerCase().indexOf(query)
    if(!name && s.length > query.length + maxOffset) {
      s = `...${s.substring(start - maxOffset / 2, start + query.length + maxOffset / 2)}...`
      start = s.toLowerCase().indexOf(query)
    }
    if(start === -1) {
      start = s.length
    }
    let end = start + query.length
    let prefix = s.substring(0, start)
    let highlight = s.substring(start, end)
    let suffix = s.substring(end, s.length)
    return <span className={`label ${name ? 'name' : ''}`}>{prefix}<span className="highlight">{highlight}</span>{suffix}</span>
  }

  const ResultRow = ({ area, result, onClick }) => {
    return (
      <div className="result-row">
        <span className="content" onClick={onClick}>
          <i className="icon">{ validAreas.filter(a => a.key === area)[0].icon}</i>
          { Boolean(result.object.name) && labelWithHighlight(result.object.name || `Document ${result.object.id}`, true) }
          { Boolean(result.label) && labelWithHighlight(result.label, false) }
          { !(Boolean(result.label) || Boolean(result.object.name)) && labelWithHighlight(`Document ${result.object.id}`, true) }
        </span>
      </div>
    )
  }

  return (
    <div className="search-results">
      <div className="header">
        <h3>{`Résultats de recherches pour '${query}'`}</h3>
        <IconButton dark icon={<ClosedIcon />} onButtonClick={closeHandler} />
      </div>
      {(!documentsLoaded || !templatesLoaded || !signaturesFetched || !tasksFetched || !contactsFetched) 
        ? (
          <div className="loader-wrapper"><Loader normal primary small /></div>
        ) : (
          <>
            <div className="tabs-header">
              { areas.map((area, ai) => {
                return  (
                  <div key={`searcharea_${area}`} onClick={() => selectArea(area)} className={`tab-button ${selectedArea === area ? 'selected' : ''}`}>
                    <p>{ `${validAreas.filter(a => a.key === area)[0].label} ${results[area] ? results[area].length : 0}` }</p>
                  </div>
                )
              })}
            </div>
            <div className="results-container">
              { Boolean(results[selectedArea]) && results[selectedArea].map((res, ri) => {
                return (
                  <ResultRow key={`res_row_${ri}}`} area={selectedArea} result={res} onClick={() => onRowClick(selectedArea, res.object)} />
                )
              })}
            </div>
          </>
        )
      }
    </div>
  )
}

export default SearchResults
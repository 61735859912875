import React, { useContext } from 'react'

import Modal from '../../UI/Modal'
import Button from '../../UI/Button'
import Input from '../../UI/Input'
import { NotificationContext, GlobalContext } from '../../../context'

const SaveUpdatedSignatureTemplateModal = ({ onClose, onTemplateSave, name, onSetName, onSetActiveTemplate }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)

  const submitHandler = async (e) => {
    if(name.trim() === '') {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }
    try {
      const id = await onTemplateSave(e)
      if(id) {
        onSetActiveTemplate(id)
        onClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal onClose={onClose} medium>
      <div className="signature-templates-modal"> 
        <h3>{t('signature_templates.save_updated_template')}</h3>
        <form onSubmit={submitHandler}>
          <Input value={name} onChange={(e) => onSetName(e.target.value)} formEl placeholder={t('signature_templates.template_name')} />
          <Button type="submit" text={t('general.save')} medium primary />
        </form>
      </div>
    </Modal>
  )
}

export default SaveUpdatedSignatureTemplateModal
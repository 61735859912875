import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { UserContext, TeamContext, NotificationContext, GlobalContext, LoaderContext, DocumentsContext, SignatureContext, ContactsContext, TagContext, FolderContext, DocumentsFoldersContext, AlertContext, TaskContext, ContactGroupsContext } from '../context'
import { teamInvite, joinTeam } from '../services/functions'
import { upload_file, delete_file, update_team, fetch_actions, fetch_alerts, fetch_tasks, delete_document, delete_signature, delete_folder, delete_documents_folder, delete_contact, delete_alert, delete_tag, delete_task, delete_action, delete_team } from '../services/firestore'
import { delete_contact_group } from '../services/contact_groups'
import { validateEmail } from '../helpers/validate'
import { saveSelectedTeamToLS, hasSufficientMembership } from '../utils'
import { useAuth } from './'

const useTeamActions = () => {
  const { selectedTeam, fetchTeams, setSelectedTeam, createTeam, updateTeam, setFetchCollections, activeTeamMember, setActiveTeamMember } = useContext(TeamContext)
  const { user, membership } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { t, selectedLang, resetGlobalState } = useContext(GlobalContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { documentsArr, resetDocState } = useContext(DocumentsContext) 
  const { signatures, resetSignatureState } = useContext(SignatureContext)
  const { contacts, resetContactState } = useContext(ContactsContext)
  const { contactGroups, resetContactGroupsState } = useContext(ContactGroupsContext)
  const { tags, resetTagState } = useContext(TagContext)
  const { folders, resetFolderState } = useContext(FolderContext)
  const { docFolders, resetDocFoldersState } = useContext(DocumentsFoldersContext)
  const { resetAlertState } = useContext(AlertContext)
  const { resetTaskState } = useContext(TaskContext)
  const { resetState } = useAuth()
  const history = useHistory()

  // Invite users
  const inviteUsers = async({ value, onClose }) => {
    const emails = value.split('\n')
    const filtered = emails.filter(email => email !== '') // remove empty values
    // Check if field empty
    if(filtered.length === 0) {
      return setNotification({ msg: t('notification.required_field'), type: 'danger' })
    }
    // Check if emails are valid
    let valid = true
    for(let i = 0; i < filtered.length; i++) {
      if(!validateEmail(filtered[i])) {
        valid = false
        break
      }
    }
    // Check if membership is not premium, in this case only one user can be invited
    // if(selectedTeam?.membership === 'free' && (selectedTeam.users.length > 1 || filtered.length > 1)) {
    //   return setNotification({ msg: t('notification.limited_invitations'), type: 'danger' });
    // }
    // If any email is not valid show notification/error
    if(!valid) return setNotification({ msg: t('notification.invalid_email'), type: 'danger' })
    // Check if users with added email is already in team
    let alreadyTeamMember = ''
    for(let i = 0; i < filtered.length; i++) {
      if(selectedTeam.users_emails.includes(filtered[i])) {
        alreadyTeamMember = filtered[i]
        break
      }
    }
    if(alreadyTeamMember) return setNotification({ msg: t('notification.user_already_team_member', { email: alreadyTeamMember }), type: 'info' })
    
    // send message to valid email addresses
    if(filtered.length > 0) {
      try {
        setShowGlobalResponseLoader(true)
        await teamInvite({ 
          teamId: selectedTeam.id, 
          emails: filtered
        }, selectedLang)
        await fetchTeams(selectedTeam.id, user, membership)
        setShowGlobalResponseLoader(false)
        onClose()
        setNotification({ msg: t('notification.message_sent'), type: 'success' })
      } catch (err) {
        console.log(err)
      }
    }
  }

  // Create new team
  const createNewTeam = async ({ teamName, setTeamNameError, teamLogoFile, setTeamStep }) => {
    if(teamName.trim() === '') {
      return setTeamNameError(t('notification.field_required'))
    }

    setShowGlobalResponseLoader(true)
    let teamData ={
      name: teamName,
      users: [{ email: user.email, role: 'admin', status: 'active' }],
      users_emails: [user.email],
      created_at: Date.now(),
      membership: membership
    }

    // Upload logo
    if(teamLogoFile) {
      try {
        const { data, name, format, type } = teamLogoFile
        const imageName = `${name}-${Date.now()}`
        const res = await upload_file(data, imageName, format, type, `users/${user.id}`)
        teamData.logo_url = res.url
        teamData.logo_path = `users/${user.id}/${imageName}.${format}`
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.logo_not_uploaded'), type: 'danger' })
      }
    }

    // Create team
    createTeam(teamData, (newTeam) => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.team_created'), type: 'success' })
      setSelectedTeam(newTeam)
      saveSelectedTeamToLS(newTeam.id)
      resetState('team-add')
      if(hasSufficientMembership(teamData.membership, 'premium')) {
        setTeamStep(2)
      }else {
        history.push('/settings/team')
      }
    }, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  // Invite users after team is created
  const inviteUsersAfterTeamCreation = async ({ emails }) => {
    const sendTo = emails.map(em => em.value).filter(em => em !== '')
    // Check if emails are added
    if(sendTo.length === 0) {
      return setNotification({ msg: t('notification.add_emails'), type: 'danger' })
    }

    // Check if emails are valid
    let valid = true
    for(let i = 0; i < sendTo.length; i++) {
      if(!validateEmail(sendTo[i])) {
        valid = false
        break
      }
    }
    // If any email is not valid show notification/error
    if(!valid) return setNotification({ msg: t('notification.invalid_email'), type: 'danger' })
    // Check if users with added email is already in team
    let alreadyTeamMember = ''
    for(let i = 0; i < sendTo.length; i++) {
      if(selectedTeam?.users_emails.includes(sendTo[i])) {
        alreadyTeamMember = sendTo[i]
        break
      }
    }
    if(alreadyTeamMember) return setNotification({ msg: t('notification.user_already_team_member', { email: alreadyTeamMember }), type: 'info' })

    // send message to valid email addresses
    try {
      setShowGlobalResponseLoader(true)
      await teamInvite({ 
        teamId: selectedTeam.id, 
        emails: sendTo
      }, selectedLang)
      await fetchTeams(selectedTeam?.id, user, membership)
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.message_sent'), type: 'success' })
      history.push('/settings/team')
    } catch (err) {
      console.log(err)
    }
  }

  // Upload team image
  const uploadTeamImage = async ( image ) => {
    setShowGlobalResponseLoader(true)
    try {
      let paths = []
      // Delete prev image first
      if(selectedTeam.logo_path) {
        const splitted = selectedTeam.logo_path.split('.')
        const path = [...splitted].slice(0, splitted.length - 1).join('.')
        const format = splitted[splitted.length - 1]
        paths = [selectedTeam.logo_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        setGlobalResponseLoaderText(t('general.deleting_prev_image'))
      }
      if(paths.length > 0) {
        for(let i = 0; i < paths.length; i++) {
          await delete_file(paths[i])
        }
      }
      setGlobalResponseLoaderText(t('general.uploading_image'))
      const { data, name, format, type } = image
      const imageName = `${name}-${Date.now()}`
      const res = await upload_file(data, imageName, format, type, `users/${user.id}`)
      const imageUrl = res.url
      const imagePath = `users/${user.id}/${imageName}.${format}`
      const dataToUpdate = { logo_url: imageUrl, logo_path: imagePath }
      const teamRes = await update_team(dataToUpdate, selectedTeam.id) // in db
      if(teamRes?.success) {
        updateTeam(dataToUpdate, selectedTeam.id) // in memory - team state/context
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      }else {
        throw new Error('update failed')
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Remove team image
  const removeTeamImage = async () => {
    if(selectedTeam.logo_path) {
      const splitted = selectedTeam.logo_path.split('.')
      const path = [...splitted].slice(0, splitted.length - 1).join('.')
      const format = splitted[splitted.length - 1]
      const paths = [selectedTeam.logo_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
      setGlobalResponseLoaderText(t('general.deleting_image'))
      setShowGlobalResponseLoader(true)
      try {
        for(let i = 0; i < paths.length; i++) {
          await delete_file(paths[i])
        }
        const dataToUpdate = { logo_url: '', logo_path: '' }
        const res = await update_team(dataToUpdate, selectedTeam.id) // in db
        if(res?.success) {
          updateTeam(dataToUpdate, selectedTeam.id) // in memory - team state/context
          setShowGlobalResponseLoader(false)
          setGlobalResponseLoaderText('')
        }
      } catch (err) {
        console.log(err)
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }

  // Rename team
  const renameTeam = async ({ name, setShowRenameTeamPopup }) => {
    try {
      setShowGlobalResponseLoader(true)
      const res = await update_team({ name }, selectedTeam.id) // in db
      if(res?.success) {
        updateTeam({ name }, selectedTeam.id) // in memory - team state/context
        setNotification({ msg: t('notification.team_updated'), type: 'success' })
        setShowGlobalResponseLoader(false)
        setShowRenameTeamPopup(false)
      }else {
        throw new Error('update failed')
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    }
  }

  // Delete team
  const deleteTeam = async ({ setShowDeleteTeamAlert, setShowTeamHeadDropdown }) => {
    // TODO - if some collections are not fetched, fetch them otherwise they will not be deleted from db
    setShowDeleteTeamAlert(false)
    setShowGlobalResponseLoader(true)
    let docPromises = []
    let signaturePromises = []
    let contactPromises = []
    let tagPromises = []
    let folderPromises = []
    let docFolderPromises = []
    let alertPromises = []
    let taskPromises = []
    let actionsPromises = []
    let contactGroupsPromises = []
    try { 
      // Fetch alerts, tasks and actions because context of alerts & tasks have only users alerts & tasks
      // and actions can be empty if notification icon wasn't clicked or some could be created after
      // notification icon is clicked and thus they won't be included in actions from context
      setGlobalResponseLoaderText(t('general.fetching_data'))
      const alertsRes = await fetch_alerts(selectedTeam?.id, true)
      const tasksRes = await fetch_tasks(selectedTeam?.id, true)
      const actionsRes = await fetch_actions(selectedTeam?.id)
      // If documents exist delete theme
      if(documentsArr.length > 0) {
        documentsArr.forEach(doc => docPromises.push(delete_document(doc.id)))
      }
      if(docPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_documents'))
        await Promise.all(docPromises)
        // Reset doc state after documents are deleted just in case some other step fails, use 'team-add' as mode to prevent templates reset and 
        // infinite loading
        resetDocState('team-add')
      }
      // If signatures exist delete them
      if(signatures.length > 0) {
        signatures.forEach(sign => signaturePromises.push(delete_signature(sign.id)))
      }
      if(signaturePromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_signatures'))
        await Promise.all(signaturePromises)
        resetSignatureState('team-add')
      }
      // If folders(template) exist delete them
      if(folders.length > 0) {
        folders.forEach(fol => folderPromises.push(delete_folder(fol.id)))
      }
      if(folderPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_folders'))
        await Promise.all(folderPromises)
        resetFolderState('team-add')
      }
      // If folders(documents) exist delete them
      if(docFolders.length > 0) {
        docFolders.forEach(fol => docFolderPromises.push(delete_documents_folder(fol.id)))
      }
      if(docFolderPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_folders'))
        await Promise.all(docFolderPromises)
        resetDocFoldersState('team-add')
      }
      // If contacts exist delete them
      if(contacts.length > 0) {
        contacts.forEach(contact => contactPromises.push(delete_contact(contact.id)))
      }
      if(contactPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_contacts'))
        await Promise.all(contactPromises)
        resetContactState('team-add')
      }
      // If contact groups exist delete them
      if(contactGroups.length > 0) {
        contactGroups.forEach(group => contactGroupsPromises.push(delete_contact_group(group.id)))
      }
      if(contactGroupsPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_contact_groups'))
        await Promise.all(contactGroupsPromises)
        resetContactGroupsState('team-add')
      }
      // If tags exist delete them
      if(tags.length > 0) {
        tags.forEach(tag => tagPromises.push(delete_tag(tag.id)))
      }
      if(tagPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_tags'))
        await Promise.all(tagPromises)
        resetTagState('team-add')
      }
      // If alerts exist delete them
      if(Object.keys(alertsRes).length > 0) {
        for(let key in alertsRes) {
          alertPromises.push(delete_alert(key))
        }
      }
      if(alertPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_alerts'))
        await Promise.all(alertPromises)
        resetAlertState('team-add')
      }
      // If tasks exist delete them
      if(Object.keys(tasksRes).length > 0) {
        for(let key in tasksRes) {
          taskPromises.push(delete_task(key))
        }
      }
      if(taskPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_tasks'))
        await Promise.all(taskPromises)
        resetTaskState('team-add')
      }
      // If actions exist delete them
      if(Object.keys(actionsRes).length > 0) {
        for(let key in actionsRes) {
          actionsPromises.push(delete_action(key))
        }
      }
      if(actionsPromises.length > 0) {
        setGlobalResponseLoaderText(t('general.deleting_actions'))
        await Promise.all(actionsPromises)
      }
      // Finally delete team after all collections are deleted
      setGlobalResponseLoaderText(t('general.deleting_team'))
      await delete_team(selectedTeam?.id)
      resetGlobalState()
      // Fetch teams to change selected team
      await fetchTeams(null, user, membership)
      setFetchCollections(true)
      setGlobalResponseLoaderText('')
      setShowTeamHeadDropdown(false)
    } catch (err) {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setShowGlobalResponseLoader(false)
  }

  // Join team
  const joinSingleTeam = async () => {
    try {
      setShowGlobalResponseLoader(true)
      const updatedMember = await joinTeam({ teamId: selectedTeam.id, token: activeTeamMember.invitation_token, userEmail: activeTeamMember.email })
      await fetchTeams(selectedTeam.id, user, membership)
      setFetchCollections(true)
      setActiveTeamMember({...activeTeamMember, ...updatedMember.data})
      setShowGlobalResponseLoader(false)
    } catch (err) {
      console.log(err)
    }
  }

  // Delete user from team
  const deleteUserFromTeam = async (email) => {
    try {
      setShowGlobalResponseLoader(true)
      const teamUsers = filterTeamUserProperties()
      const filteredTeamUsers = teamUsers.filter(u => u.email !== email)
      const filteredTeamUsersInMemory = [...selectedTeam.users].filter(u => u.email !== email)
      const teamUsersEmails = [...selectedTeam.users_emails].filter(em => em !== email)
      const res = await update_team({ users: filteredTeamUsers, users_emails: teamUsersEmails }, selectedTeam.id) // in db
      if(res?.success) {
        updateTeam({ users: filteredTeamUsersInMemory, users_emails: teamUsersEmails }, selectedTeam.id) // in memory - team state/context
        setNotification({ msg: t('notification.user_deleted_from_team'), type: 'success' })
        setShowGlobalResponseLoader(false)
      }else {
        throw new Error('update failed')
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    }
  }

  // Resend invitation 
  const resendTeamInvitation = async (email) => {
    try {
      setShowGlobalResponseLoader(true)
      await teamInvite({ 
        teamId: selectedTeam.id, 
        emails: [email]
      }, selectedLang)
      await fetchTeams(selectedTeam.id, user, membership)
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.message_sent'), type: 'success' })
    } catch (err) {
      console.log(err)
    }
  }

  // Check if user is team admin
  const isTeamAdmin = () => {
    return activeTeamMember.role === 'admin'
  }

  // Filter team user properties helper
  const filterTeamUserProperties = () => {
    return [...selectedTeam.users].map(u => {
      const user = {
        email: u.email,
        role: u.role,
        status: u.status
      }
      if(u.invitation_token) {
        user.invitation_token = u.invitation_token
      }
      return user
    })
  }

  return {
    inviteUsers,
    createNewTeam,
    inviteUsersAfterTeamCreation,
    uploadTeamImage,
    removeTeamImage,
    renameTeam,
    deleteTeam,
    isTeamAdmin,
    joinSingleTeam,
    deleteUserFromTeam,
    resendTeamInvitation,
  }
}

export default useTeamActions
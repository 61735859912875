import { useContext, useState, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Collapse,  Dialog, IconButton } from '@material-ui/core'

import { SettingsLayout } from '../layouts'
import { Input } from '../components/new_ui'
import { InvoicesList } from '../components/misc'
import { GlobalContext, UserContext, NotificationContext, LoaderContext } from '../context'
import { validateEmail } from '../helpers/validate'
import { capitalizeWord } from '../utils'
import { handlePaymentThatRequiresCustomerAction, upgradeMembership, payInvoiceManually, fetch_invoice_detail } from '../services/billing'

const Invoices = () => {
  const { t } = useContext(GlobalContext)
  const { subscription, user, setUser, invoices } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { setLoading } = useContext(LoaderContext)
  const [cardValid, setCardValid] = useState(false)
  const [cardError, setCardError] = useState(null)
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [checkout, setCheckout] = useState({
    isOpen: false,
    name: '',
    email: '',
    paymentMethod: null,
    useNewPaymentMethod: true
  })
  const stripe = useStripe()
  const elements = useElements()

  // Set checkout
  useEffect(() => {
    if(!user) {
      return
    }
    if(!checkout.name) {
      setCheckout({
        ...checkout,
        name: `${user.first_name || ''}${user.first_name ? ' ' : ''}${user.last_name || ''}`,
        email: user.email,
        paymentMethod: user.payment_method,
        useNewPaymentMethod: !Boolean(user.payment_method)
      })
    }
  }, [user, checkout, setCheckout])

  // On payment cancel
  const onPaymentCancel = () => {
    closeCheckout()
  }

  // On payment submit
  const onPaymentSubmit = async () => {
    console.log('on payment submit')
    
    setLoadingCheckout(true)


    if(checkout.invoice) {
      console.log('handle invoice payment')
      await handleInvoicePayment()
      setLoadingCheckout(false)
      return
    }

    const cardElement = elements.getElement(CardElement);

    if(checkout.useNewPaymentMethod && !cardElement) {
      console.log('card element error')
      setLoadingCheckout(false)
      return
    }


    const subscriptionResponse = await upgradeMembership(
      stripe,
      user,
      setUser,
      checkout.name,
      checkout.email,
      cardElement,
      checkout.paymentMethod?.id
    )

    console.log(subscriptionResponse)

    setLoadingCheckout(false)

    const { status, error } = subscriptionResponse

    if(error) {
      // todo apply invoice to checkout if subscription is already created
      setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
      if(status === 'incomplete' && subscriptionResponse.latest_invoice) {
        setCheckout({
          ...checkout,
          invoice: subscriptionResponse.latest_invoice
        })
      }
      console.log('subscription error')
      console.log(error)
      return
    }

    closeCheckout()
  }

  // handle invoice action
  const handleInvoiceAction = async (invoice) => {
    setLoading(true)
    const invoiceDetailResponse = await fetch_invoice_detail(invoice.id)
    if(!invoiceDetailResponse.invoice?.payment_intent?.payment_method) {
      setLoading(false)
      setCheckout({
        ...checkout,
        isOpen: true,
        invoice: invoice
      })
      return
    }
    const actionResponse = await handlePaymentThatRequiresCustomerAction(stripe, checkout.name, checkout.email, invoiceDetailResponse.invoice.payment_intent)
    console.log(actionResponse)
    if(actionResponse.error) {
      const { error } = actionResponse
      console.log('customer action error')
      console.log(error)
      // setNotification({ msg: t(`billing_error.${actionResponse.error.code}`), type: 'danger' })
      setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
    }
    setLoading(false)
  }

  // handle invoice payment
  const handleInvoicePayment = async () => {
    const invoice = checkout.invoice || subscription.latest_invoice

    const cardElement = checkout.useNewPaymentMethod ? elements.getElement(CardElement) : null

    if(checkout.useNewPaymentMethod && !cardElement) {
      console.log('card element error')
      setLoadingCheckout(false)
      return
    }

    console.log('handle invoice', invoice)
    const paymentReponse = await payInvoiceManually(
      invoice,      
      stripe,
      user,
      setUser,
      checkout.name,
      checkout.email,
      cardElement,
      checkout.paymentMethod?.id
    )
    const { error } = paymentReponse
    console.log('payment response', paymentReponse)
    if(error) {
      // todo handle error
      if(error.code === 'invoice_payment_intent_requires_action') {
        const actionResponse = await handlePaymentThatRequiresCustomerAction(stripe, checkout.name, checkout.email, invoice.payment_intent, cardElement)
        console.log(actionResponse)
        if(actionResponse.error) {
          const { error } = actionResponse
          console.log('customer action error')
          console.log(error)
          // setNotification({ msg: t(`billing_error.${actionResponse.error.code}`), type: 'danger' })
          setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
          return
        }
      } else {
      setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
      console.log('payment error')
      console.log(error)
      
      return
      }
    }
    closeCheckout()
  }

  // Close checkout
  const closeCheckout = () => {
    setCheckout({
      ...checkout,
      isOpen: false
    })
  }

  // On card change
  const onCardChange = (event) => {
    setCardError(event.error)
    setCardValid(event.complete)
  }

  // Check if checkout is valid
  const isCheckoutValid = () => {
    if(!checkout.name.trim()) {
      return false
    }
    if(!validateEmail(checkout.email)) {
      return false
    }
    if(checkout.useNewPaymentMethod && !cardValid) {
      return false
    }
    return true
  }

  // Print price
  const printPrice = (amount) => {
    if(!amount) {
      return ''
    }
    amount = amount / 100
    // print two decimal places if amount is not a whole number
    if(amount % 1 !== 0) {
      return `${(amount).toFixed(2)}€`
    }
    return `${amount}€`
  }

  // Render checkout
  const renderCheckout = () => {
    return (
      <Dialog scroll="body" className="checkout-dialog" open={checkout.isOpen}>
        <IconButton className="checkout-close-button" onClick={onPaymentCancel}>
          <span className="material-symbols-outlined">close</span>
        </IconButton>
        <div className="dialog-body">
          <div className="checkout-header">
            <h2>{ t('membership.checkout') }</h2>
            <h1>{ t('membership.premium_title') }</h1>
          </div>
          <div className="checkout-content">
            <p className="row v-centered"><span className="material-symbols-outlined">arrow_right</span> { `${t('membership.due_now')}: ${printPrice(checkout.invoice?.amount_due || 2500)}` }</p>
            { (!checkout.invoice || checkout.invoice.billing_reason === 'subscription_create') && <p className="mt-5 row v-centered"><span className="material-symbols-outlined">arrow_right</span>{ t('membership.checkout_subtitle') }</p> }
            <div className="mt-20">
              <Input 
                label={t('membership.full_name')} 
                whiteBackground
                disabled={loadingCheckout}
                formEl
                value={checkout.name}
                invalid={!checkout.name}
                onChange={(e) => { setCheckout({...checkout, name: e.target.value })}}/>
              <Input 
                label={t('auth.email')} 
                whiteBackground
                disabled={loadingCheckout}
                formEl
                value={checkout.email}
                invalid={!checkout.email}
                onChange={(e) => { setCheckout({...checkout, email: e.target.value })}}/>
            </div>
            <div className="form-input form-input--white-background form-input--form-el">
              <div className="form-input__inner">
                <div className="form-input__label-wrapper">
                  <label className={"form-input__label"}>{ t('membership.payment_method') }</label>
                </div>
                { checkout.useNewPaymentMethod ? (
                  <div>
                    <div className="card-input">
                      <CardElement
                        disabled={loadingCheckout}
                        onChange={onCardChange}
                        options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              color: '#000',
                              fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif',
                              fontSmoothing: 'antialiased'
                            },
                          }
                        }}/>
                    </div>
                    { Boolean(checkout.paymentMethod) && <button className="btn btn--primary-light btn--small mt-10" onClick={() => { setCheckout({...checkout, useNewPaymentMethod: false }) }}>{t('membership.use_current')}</button> }
                  </div>
                ) : (
                  <div>
                    <span>{ `${capitalizeWord(checkout.paymentMethod.brand)} **${checkout.paymentMethod.last4}` }</span>
                    <button className="btn btn--primary-light btn--small" onClick={() => { setCheckout({...checkout, useNewPaymentMethod: true }) }} disabled={loadingCheckout}>{t('membership.use_new')}</button>
                  </div>
                )}
                { cardError && <div className="mt-5"><span className="card-error-label">{ cardError.message }</span></div> }
              </div>
            </div>
            <Collapse in={isCheckoutValid()}>
              <div className="mt-30">              
                <button className="btn btn--large btn--full" onClick={onPaymentSubmit} disabled={!isCheckoutValid()}>
                  {loadingCheckout ? <div className="loader-v2"></div> : (
                    t('membership.checkout_pay', { amount: printPrice(checkout.invoice?.amount_due || 2500) }).toUpperCase()
                  )}
                </button>
              </div>
            </Collapse>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <SettingsLayout title={t('invoices.title')}>
      <div className="settings-pages-wrapper">
        <InvoicesList 
          invoices={invoices}
          onPayNow={(invoice) => setCheckout({ ...checkout, invoice: invoice, isOpen: true })}
          onInvoiceAction={handleInvoiceAction} />
        { renderCheckout() }
      </div>
    </SettingsLayout>
  )
}

export default Invoices
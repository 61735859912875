import React, { useState, useContext, useEffect } from 'react'
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons'

import { Modal, Input, LetterCircle } from '../../new_ui'
import CustomTooltip from '../../UI/CustomTooltip'
import { TeamContext, GlobalContext } from '../../../context'

const StepMembersModal = ({ onClose, currentStep, onSaveSelectedStepMembers }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam, getUserNameById, getTeamMemberById } = useContext(TeamContext)
  const [search, setSearch] = useState('')
  const [allChecked, setAllChecked] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [currentStepMembers, setCurrentStepMembers] = useState([])
  const [loading, setLoading] = useState(true)

  // Set users
  useEffect(() => {
    if(selectedTeam) {
      let activeUsers = selectedTeam.users.filter(u => u.status === 'active')
      setUsers(activeUsers || [])
      setFilteredUsers(activeUsers || [])
      setLoading(false)
    }
  }, [selectedTeam, loading])

  // Set current step members
  useEffect(() => {
    if(currentStep) {
      setCurrentStepMembers(currentStep.members)
      setSelectedUsers(currentStep.members)
      if(currentStep.members.length > 0) {
        setAllChecked(true)
      }
    }
  }, [currentStep])

  // Update all checkbox
  useEffect(() => {
    if(selectedUsers.length === 0 && allChecked) {
      setAllChecked(false)
    }
  }, [selectedUsers, allChecked])

  // On search
  const handleSearchChange = (e) => {
    let val = e.target.value
    setSearch(val)
    if(val.trim()) {
      let filtered = []
      users.forEach(u => {
        let name = getUserNameById(u.id)
        if(name.toLowerCase().includes(val.trim().toLowerCase())) {
          filtered.push(u)
        }
      })
      setFilteredUsers(filtered)
    }else {
      setFilteredUsers(users)
    }
  }

  // On select all
  const handleAllChecked = () => {
    if(!allChecked && selectedUsers.length === 0) {
      setAllChecked(true)
      setSelectedUsers(filteredUsers.map(u => u.id))
    }
    if(allChecked) {
      setAllChecked(false)
      setSelectedUsers([])
    }
  }

  // On remove user
  const handleRemoveUser = (uid) => {
    setSelectedUsers(prev => prev.filter(id => id !== uid))
  }

  // On save
  const handleSaveSelectedUsers = () => {
    onSaveSelectedStepMembers(currentStep, selectedUsers)
    onClose()
  }

  // Check if save disabled
  const isSaveDisabled = () => {
    let disabled = false
    if(currentStepMembers.length !== selectedUsers.length) {
      disabled = true
    }else {
      for(let i = 0; i < selectedUsers.length; i++) {
        if(!currentStepMembers.includes(selectedUsers[i])) {
          disabled = true 
          break
        }
      }
    }
    return disabled
  }

  return (
    <Modal 
      onClose={onClose}
      title={t('approvals.select_users')}
      className="centered-head"
      onSave={handleSaveSelectedUsers}
      saveBtnDisabled={!isSaveDisabled()}
    >
      <div className="step-members-modal">
        <div className="step-members-modal__body">
          <div className="search-wrapper">
            <Input value={search} onChange={handleSearchChange} placeholder={t('dashboard.search')} />
          </div>
          {selectedUsers.length > 0 && <div className="selected-users">
            {selectedUsers.map((id) => {
              let name = getUserNameById(id)
              let user = getTeamMemberById(id)
              return (
                <CustomTooltip content={user ? user.email : ''} key={id}>
                  <button className="btn btn--primary-light btn--small" onClick={() => handleRemoveUser(id)}>
                    {name} <span className="material-symbols-outlined">close</span>
                  </button>
                </CustomTooltip>
              )
            })}
          </div>}
          {loading ? (
            <div className="loader-wrapper"><div className="loader-v2"></div></div>
          ) : (
            <div className="list-of-members-wrapper">
              <div className="list-of-members-wrapper__head" onClick={handleAllChecked}>
                {allChecked 
                  ? <span className="material-symbols-outlined checked">indeterminate_check_box</span>
                  : <span className="material-symbols-outlined unchecked">check_box_outline_blank</span>
                }
                <p>{t('general.select_all')}</p>
              </div>
              <ul className="u-custom-scrollbar">
                {filteredUsers.map((user, idx) => (
                  <UserItem key={idx} user={user} getUserNameById={getUserNameById} onSetSelectedUsers={setSelectedUsers} selectedUsers={selectedUsers} allChecked={allChecked} onSetAllChecked={setAllChecked} />
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

const UserItem = ({ user, getUserNameById, onSetSelectedUsers, selectedUsers, onSetAllChecked }) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if(selectedUsers.includes(user.id) && !selected) {
      setSelected(true)
    }else if(!selectedUsers.includes(user.id) && selected) {
      setSelected(false)
    }
  }, [selectedUsers, user, selected])

  // On checkbox change
  const handleCheckboxChange = () => {
    setSelected(!selected)
    if(!selected) {
      onSetSelectedUsers(prev => [...prev, user.id])
      if(selectedUsers.length === 0) {
        onSetAllChecked(true)
      }
    }else {
      onSetSelectedUsers(prev => prev.filter(id => id !== user.id))
      if(selectedUsers.length === 1) {
        onSetAllChecked(false)
      }
    }
  }

  let name = getUserNameById(user.id)

  return (
    <li onClick={handleCheckboxChange}>
      <div className="checkboxes">
        {selected ? <span className="material-symbols-outlined checked">check_box</span> : <span className="material-symbols-outlined unchecked">check_box_outline_blank</span>}
      </div>
      <LetterCircle data={name} hideTooltip />
      <p>{name} ({user.email})</p>
    </li>
  )
}

export default StepMembersModal 
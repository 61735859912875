import { useContext } from 'react'

import { GlobalContext } from '../../../context'
import { useSharedDocsActions } from '../../../hooks'

const ExternalDocumentsActions = ({ allChecked, onAllCheckedChange, selectedItems, setSelectedItems }) => {
  const { t } = useContext(GlobalContext)
  const { downloadSharedDocs } = useSharedDocsActions()

  // On download
  const handleDownload = async (e) => {
    e.preventDefault()
    await downloadSharedDocs({ selectedDocuments: selectedItems, setSelectedDocuments: setSelectedItems })
  }

  return (
    <div className="list-view-v2__actions">
      <div className="checkbox">
        {!allChecked 
          ? <span className="material-symbols-outlined" onClick={onAllCheckedChange}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled" onClick={onAllCheckedChange}>check_box</span>
        }
      </div>
      <p className="text-style-1">{t('dashboard.num_of_selected_items', { number: selectedItems.length })}</p>
      <div className="buttons">
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDownload}>
          <span className="material-symbols-outlined icon">download</span> 
          <span className="text">{t('general.download')}</span>
        </a>
      </div>
    </div>
  )
}

export default ExternalDocumentsActions
import React, { useContext, useState, useEffect, memo } from 'react'
import moment from 'moment'

import { LetterCircle } from '../../new_ui'
import { GlobalContext } from '../../../context'

const ExternalDocumentsRow = ({ selectedDocuments, onSetSelectedDocuments, id, doc, columns, allChecked, onTitleClick }) => {
  const { t } = useContext(GlobalContext)
  const [checked, setChecked] = useState(false)

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedDocuments.length === 0) {
      setChecked(false)
    }
  }, [selectedDocuments, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // On document select
  const handleSelectDocument = () => {
    setChecked(!checked)
    if(checked) {
      onSetSelectedDocuments(prev => prev.filter(d => d.id !== id))
    }else {
      onSetSelectedDocuments(prev => [...prev, doc])
    }
  }

  // Render checkbox column
  const renderCheckboxColumn = (col, i) => {
    return (
      <div className="list-view-table__cell checkbox">
        {!checked 
          ? <span className="material-symbols-outlined checkbox-icon" onClick={handleSelectDocument}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled checkbox-icon" onClick={handleSelectDocument}>check_box</span>
        }
        {doc.folderRow 
          ? <span className="material-symbols-outlined row-icon filled">folder</span>
          : <span className="material-symbols-outlined row-icon">{doc.uploaded ? 'upload_file' : 'description'}</span>
        }
      </div>
    )
  }

  // Render title column
  const renderTitleColumn = (col, i) => {

    return (
      <div 
        className="list-view-table__cell u-truncate-text-wrapper title-col" 
        key={i}
      >
        <p className="title text-style-1 u-truncate-text" onClick={() => onTitleClick(doc)}>{doc.name}</p>
      </div>
    )

    // return (
    //   <div key={i} className={`dashboard-column col-font-small ${col.cssClass}`}>
    //     <div className="dashboard-column__inner-wrapper">
    //       <div className="dashboard-column__inner">
    //         <div className="dashboard-column__main">
    //           <p className="title" onClick={() => onDetailView(doc)}>{doc.name}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // )
  }

  // Render date column
  const renderDateColumn = (col, i, label) => {
    let timestamp 
    if(col.value === 'created') {
      timestamp = doc.created 
    }else if(col.value === 'updated_at') {
      if(doc.updated_at) {
        timestamp = doc.updated_at
      }else {
        timestamp = doc.created
      }
    }
    const date = moment(timestamp).format('DD MMM YYYY (HH') + 'h' + moment(timestamp).format('mm)')

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper date-col" key={i}>
        { date }
      </div>  
    )
  }

  // Render users column
  const renderUsersColumn = (col, i, label) => {
    let users = []
    if(doc.team_members) {
      users = [...doc.team_members]
    }
    if(doc.shared_with) {
      users = [...users, ...doc.shared_with]
    }
    const content = users.map((u, idx) => (
      <LetterCircle key={idx} data={u} />
    ))

    return (
      <div className="list-view-table__cell" key={i}>
        <div className="u-truncate-text-wrapper">
          <div className="u-truncate-text">
            <div style={{display:'flex', justifyContent:'center'}}>{content}</div>
          </div>
        </div>
      </div>
    )
  }

  // Render helper column
  const renderHelperColumn = (col, i) => {
    return (
      <div className="list-view-table__cell" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'checkbox_col') {
      content = renderCheckboxColumn(col, i)
    }else if(col.value === 'name'){ 
      content = renderTitleColumn(col, i)
    }else if(col.value === 'users') {
      content = renderUsersColumn(col, i, t('dashboard.recipients'))
    }else if(col.value === 'created') {
      content = renderDateColumn(col, i, t('dashboard.created_on'))
    }else if(col.value === 'updated_at') {
      content = renderDateColumn(col, i, t('dashboard.updated_on'))
    }else if(col.value === 'helper') {
      content = renderHelperColumn(col, i)
    }

    return content
  }

  return(
    <div 
      className={`list-view-table__row ${checked ? 'selected' : ''}`}
    >
      {columns.filter(c => c.checked).map((col, i) => {
        return renderColumnContent(col, i)
      })} 
      {renderCheckboxColumn()}   
    </div>
  )
}

export default memo(ExternalDocumentsRow)
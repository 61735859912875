const WidgetInfoTask = () => {
  return (
    <span className="svg-icon">
      <svg width="64px" height="44px" viewBox="0 0 64 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Path Copy 2</title>
        <defs>
          <linearGradient x1="50%" y1="26.311351%" x2="38.7784091%" y2="83.8464143%" id="linearGradient-3">
            <stop stopColor="#FAD961" offset="0%"></stop>
            <stop stopColor="#FFA06B" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="v5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.7">
          <path d="M21.2021472,44 C20.6939453,44 20.2220437,43.8910891 19.7864421,43.6732673 C19.3508405,43.4554455 18.9878392,43.1650165 18.6974382,42.8019802 L0.728874032,24.9405941 C0.293272476,24.5775578 0.0754716981,24.1237624 0.0754716981,23.5792079 C0.0754716981,23.0346535 0.293272476,22.5445545 0.728874032,22.1089109 C1.09187533,21.8184818 1.52747688,21.6732673 2.0356787,21.6732673 C2.54388051,21.6732673 3.0157822,21.8184818 3.45138375,22.1089109 L21.2021472,40.0792079 L60.6240879,0.544554455 C61.0596895,0.181518152 61.5134411,0 61.9853428,0 C62.4572445,0 62.9109961,0.181518152 63.3465977,0.544554455 C63.7821992,0.98019802 64,1.4339934 64,1.90594059 C64,2.37788779 63.7821992,2.83168317 63.3465977,3.26732673 L23.8157565,42.8019802 C23.4527552,43.1650165 23.0353037,43.4554455 22.563402,43.6732673 C22.0915003,43.8910891 21.6377487,44 21.2021472,44 Z" id="Path-Copy-2" fill="url(#linearGradient-3)" fillRule="nonzero"></path>
        </g>
      </svg>
    </span>
  )
}

export default WidgetInfoTask
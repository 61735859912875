import React, { useEffect, useState, useContext, useRef } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { LinkOff, Code, HelpOutline, ChevronLeft } from '@material-ui/icons'
import { MdOutlineContentCopy } from 'react-icons/md'

import ActionsDropdown from '../UI/ActionsDropdown'
import Button from '../UI/Button'
import Input from '../UI/Input'
import Loader from '../UI/Loader'
import ResponseLoader from '../UI/ResponseLoader'
import TemplateShareModal from '../sections/templates/TemplateShareModal'
import logoNew from '../../assets/images/carbon_logo_new.png'

import { TeamContext, SharedTemplatePagesContext, NotificationContext, GlobalContext } from '../../context'
import { get_shared_template_page_by_id, update_shared_template_page, create_shared_template_page, delete_shared_template_page } from '../../services/shared_template_pages'
import { get_shared_templates_by_page_id, get_shared_templates_by_ids, update_shared_template } from '../../services/shared_templates'
import CustomTooltip from '../UI/CustomTooltip'

const TemplatePage = ({ isPublic = false }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { setGlobalSharedPageTemplates, globalSharedPageTemplates, renewSharedTemplatePageUrl, sharedTemplatePagesFetched, updateSharedTemplatePage } = useContext(SharedTemplatePagesContext)
  const { selectedTeam } = useContext(TeamContext)
  const [page, setPage] = useState({})
  const [pageMounted, setPageMounted] = useState(false)
  const [isLinkInvalid, setIsLinkInvalid] = useState(false)
  const [isPageDisabled, setIsPageDisabled] = useState(false)
  const [pageTemplates, setPageTemplates] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showTemplateShareModal, setShowTemplateShareModal] = useState(false)
  const [isGenerated, setIsGenerated] = useState(false)
  const [generatedLink, setGeneratedLink] = useState('')
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const params = useParams()
  const history = useHistory()
  const embedCodeCopyRef = useRef()

  // Get page by id 
  useEffect(() => {
    if(params.id && !pageMounted) {
      if(isPublic) {
        setPageMounted(true)
      }else if(!isPublic && selectedTeam) {
        setPageMounted(true)
      }
      const getPage = async () => {
        let res 
        if(!isPublic) {
          if(!selectedTeam) return
          res = await get_shared_template_page_by_id(params.id, selectedTeam?.id)
        }else {
          res = await get_shared_template_page_by_id(params.id, null, true)
        }
        if(!res.exists) {
          setIsLinkInvalid(true)
          setIsLoading(false)
          return  
        }

        const pageData = res.data()
        if(pageData.disabled && isPublic) {
          setIsPageDisabled(true)
          setIsLoading(false)
          return
        }
        setPage({ ...pageData, id: res.id })

        if(globalSharedPageTemplates[params.id]) {
          // console.log('templates already fetched***', globalSharedPageTemplates)
          setPageTemplates(globalSharedPageTemplates[params.id])
          setIsLoading(false)
          return
        }

        if(pageData.shared_templates) {
          const tmpltsRes = await get_shared_templates_by_ids(pageData.shared_templates)
          if(Object.keys(tmpltsRes).length > 0) {
            let tmpltsArr = []
            for(let key in tmpltsRes) {
              tmpltsArr.push({...tmpltsRes[key], id: key})
            }
            let arr = []
            if(pageData.templates_order) {
              pageData.templates_order.forEach((tmpltId) => {
                const findTmplt = tmpltsArr.find(tmplt => tmplt.template_id === tmpltId)
                if(findTmplt) {
                  arr.push(findTmplt)
                }
              })
              setPageTemplates(arr)
              setGlobalSharedPageTemplates({
                ...globalSharedPageTemplates,
                [params.id]: arr
              })
            }else {
              setPageTemplates(tmpltsArr)
              setGlobalSharedPageTemplates({
                ...globalSharedPageTemplates,
                [params.id]: tmpltsArr
              })
            }
          }
        }
        
        setIsLoading(false)
      }

      getPage()
    }
  }, [params, pageMounted, selectedTeam, globalSharedPageTemplates, setGlobalSharedPageTemplates, isPublic])

  // On share button click
  const handleGenerateLink = async () => {
    setGeneratedLink(`${window.location.origin}/shared-template-page/${params.id}`)
    if(page.share) return setIsGenerated(true)

    // Set share property to true and generate link: /shared-page-template/:id which is public, it will use this same component with isPublic set to true
    if(sharedTemplatePagesFetched) {
      await updateSharedTemplatePage({ share: true }, params.id)
    }else {
      await update_shared_template_page(params.id, { share: true })
    }
    setIsGenerated(true)
  }

  // On copy link button click
  const handleCopyUrlToClipboard = () => {    
    const input = document.createElement('textarea')
    input.innerHTML = `${window.location.origin}/shared-template-page/${params.id}`
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setNotification({ msg: t('share_email.link_copied'), type: 'info' })
    document.body.click()
  }

  // On renew link button click
  const handleRenewUrl = async () => {
    let data = {...page}
    if(data.id) delete data.id 
    data.meta = {
      ...data.meta,
      updated: Date.now(),
    }
    data.ids_before_renew = [...data.ids_before_renew, params.id]
    data.renewed = true 

    setShowResponseLoader(true)
    if(sharedTemplatePagesFetched) {
      renewSharedTemplatePageUrl(page, (id) => {
        data.id = id 
        setPage(data)
        setGeneratedLink(`${window.location.origin}/shared-template-page/${id}`)
        history.replace(`/shared-template-page/${id}`)
        setNotification({ msg: t('share_template.page_renewed_successfully'), type: 'success' })
        setShowResponseLoader(false)
        document.body.click()
      }, (err) => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
      })
    }else {
      try {
        await delete_shared_template_page(params.id)
        const newId = await create_shared_template_page(data)
        data.id = newId 
        setPage(data)
        setGeneratedLink(`${window.location.origin}/shared-template-page/${newId}`)
        history.replace(`/shared-template-page/${newId}`)
        setNotification({ msg: t('share_template.page_renewed_successfully'), type: 'success' })
        setShowResponseLoader(false)
        document.body.click()
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
      }
    }
  }

  // On embed template button click
  const handleEmbedTemplate = () => {
    console.log('embed template***')
  }

  // On disable link button click
  const handleDisableLink = async () => {
    if(sharedTemplatePagesFetched) {
      updateSharedTemplatePage({ disabled: !page.disabled }, params.id, () => {
        setPage({...page, disabled: !page.disabled })
        if(!page.disabled) {
          setNotification({ msg: t('share_template.link_disabled'), type: 'info' })
        }else {
          setNotification({ msg: t('share_template.link_enabled'), type: 'info' })
        }
        document.body.click()
      }, (err) => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }else {
      try {
        await update_shared_template_page(params.id, { disabled: !page.disabled })
        setPage({...page, disabled: !page.disabled })
        if(!page.disabled) {
          setNotification({ msg: t('share_template.link_disabled'), type: 'info' })
        }else {
          setNotification({ msg: t('share_template.link_enabled'), type: 'info' })
        }
        document.body.click()
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }

  // On edit page button click
  const handleEditPageTrigger = () => {
    setShowTemplateShareModal(true)
  }

  // On edit page - update page and templates in memory
  const handleEditPage = (changes, templateChanges, titles, descriptions, templates, newTemplates) => {
    setPage({...page, ...changes})
    let newTemplatesIds = newTemplates.map(tmplt => tmplt.template_id)
    let updatedTemplates = [...templates].filter(tmplt => !newTemplatesIds.includes(tmplt.template_id))
    if(templateChanges.templatesToRemove.length > 0) {
      updatedTemplates = updatedTemplates.filter(tmplt => !templateChanges.templatesToRemove.includes(tmplt.id))
    }
    if(templateChanges.templatesToEdit.length > 0) {
      templateChanges.templatesToEdit.forEach(id => {
        const find = updatedTemplates.find(tmplt => tmplt.id === id)
        if(find) {
          if(titles[find.template_id]) find.name = titles[find.template_id]
          if(descriptions[find.template_id]) find.description = descriptions[find.template_id]
          find.meta.updated = Date.now()
          updatedTemplates = updatedTemplates.map(tmplt => tmplt.id === find.id ? find : tmplt)
        }
      })
    }
    if(templateChanges.newTemplates.length > 0) {
      updatedTemplates = [...updatedTemplates, ...newTemplates]
    }
    if(changes.order) {
      let arr = []
      changes.templates_order.forEach(id => {
        const find = updatedTemplates.find(tmplt => tmplt.template_id === id)
        if(find) {
          arr.push(find)
        }
      })
      updatedTemplates = arr
    }
    setPageTemplates(updatedTemplates)
  }

  // Copy embed code to clipboard
  const handleCopyEmbedCodeToClipboard = () => {
    if(!embedCodeCopyRef.current) return 

    const input = document.createElement('textarea')
    input.innerHTML = embedCodeCopyRef.current.textContent
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setNotification({ msg: t('general.embedded_code_copied'), type: 'info' })
    document.body.click()
  } 

  if(isLoading) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="loader-wrapper"><Loader small normal /></div>
        </div>
      </div>
    )
  }

  if(isLinkInvalid) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="icon"><LinkOff /></div>
          <h1>{t('share.link_not_valid')}</h1>
        </div>
      </div>
    )
  }

  if(isPublic && isPageDisabled) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="icon"><LinkOff /></div>
          <h1>{t('share_template.page_disabled')}</h1>
        </div>
      </div>
    )
  }

  return (
    <div className="template-page">
      <div className="template-page__container">
        {!isPublic && (
          <div className="template-page__edit">
            <div className="template-page__edit_logo">
              <img src={logoNew} alt="Carbon"/>
              <p>Carbon</p>
            </div>
            <div className="template-page__edit_embed">
              <Link to={'/shared-templates'} className="button button--outline-primary"><ChevronLeft /> {t('general.return_to_shared_templates')}</Link>
              <div className="embed-wrapper">
                <div className="embed-wrapper__top">
                  <p><Code /> {t('general.embed_code')}</p>
                  <CustomTooltip content={'some text here'}>
                    <div className="info"><HelpOutline /></div>
                  </CustomTooltip>
                </div>
                <div className="embed-wrapper__box">
                  <p ref={embedCodeCopyRef}>{`<iframe src="${`${window.location.origin}/shared-template-page/${params.id}`}" width="100%" height="500" style="border: 1px solid #eee;"></iframe>`}</p>
                  <div className="copy-to-clipboard" onClick={handleCopyEmbedCodeToClipboard}><MdOutlineContentCopy /></div>
                </div>
              </div>
            </div>
            <div className="template-page__edit_actions">
              <p>{t('general.page_preview')}</p>
              <div className="actions">
                <ActionsDropdown
                  trigger={
                    <button className="button button--primary button--medium share-btn">
                      {t('share_template.share_btn_text')}
                    </button>
                  }
                  onTriggerClick={handleGenerateLink}
                  hideHeader
                  dropdownClass="template-share-actions-dropdown"
                  width={350}
                >
                  <div className="template-share-actions-dropdown__body">
                    {isGenerated ? (
                      <>
                        <div className="generated-link">
                          <Input value={generatedLink} disabled />
                        </div>
                        <div className="share-actions">
                          <Button text={t('share_template.copy_url')} primaryLight onButtonClick={handleCopyUrlToClipboard} />
                          <Button text={t('share_template.renew_url')} primaryLight onButtonClick={handleRenewUrl} />
                          {/* <Button text={t('share_template.embed_template')} primaryLight fullWidth onButtonClick={handleEmbedTemplate} /> */}
                          <div>
                            <Button text={page.disabled ? t('share_template.enable_link') : t('share_template.disable_link')} primaryLight onButtonClick={handleDisableLink} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="loader-wrapper"><Loader small normal /></div>
                    )}
                  </div>
                </ActionsDropdown>
                <Button text={t('general.edit')} onButtonClick={handleEditPageTrigger} primary medium />
              </div>
            </div>
          </div>
        )}
        <div className={`template-page__content-wrapper ${!isPublic ? 'template-page__content-wrapper--with-border' : ''}`}>
          <div className="template-page__head">
            <div className="template-page__head_inner">
              <div className="logo">
                {page.logo_url && <img src={page.logo_url} alt="" />}
              </div>
            </div>
            <div className="template-page__head_info">
              <h1>{page.title}</h1>  
              <p>{page.description}</p>
            </div>
          </div>

          <div className="template-page__body">
            {/* <h2>{t('dashboard.templates')}</h2> */}
            <div className="list-of-templates">
              <ul>
                {pageTemplates.map((tmplt) => {
                  return (
                    <li key={tmplt.id}>
                      <h2><Link to={`/shared-templates/${page.id}/${tmplt.id}`} target="_blank" rel="noopener noreferrer">{tmplt.name}</Link></h2>
                      {tmplt.description && <p>{tmplt.description}</p>}
                    </li>
                  )
                })}
              </ul>
            </div>

          </div>
        </div>
        {!isPublic && <div className="go-back link-wrapper">
          {/* <Link to="/dashboard">{t('share_template.back_link')}</Link> */}
        </div>}
      </div>

      {!isPublic && showTemplateShareModal && <TemplateShareModal 
        onClose={() => setShowTemplateShareModal(false)}
        mode="edit"
        sharedPageId={params.id}
        isTemplatePage
        onEditTemplatePage={handleEditPage}
        sharedPage={page}
        pageTemplates={pageTemplates}
      />}
      {showResponseLoader && <ResponseLoader />}
    </div>
  )
}

export default TemplatePage 
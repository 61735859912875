import React, { createContext, useReducer, useState } from 'react';

import folderReducer from './folderReducer';
import { GET_FOLDERS, SET_FOLDERS, SET_FOLDERS_LOADING, RESET_STATE } from '../types';
import { fetch_folders, create_folder, update_folder, delete_folder } from '../../services/firestore';
import { sortArrayOfObjects } from '../../utils'

export const FolderContext = createContext();

const FolderState = ({ children }) => {
  const initialState = {
    loading: true,
    folders: [],
    foldersFetched: false
  }

  const [parentFolder, setParentFolder] = useState(null);
  const [folderToMove, setFolderToMove] = useState(null);
  const [folderBreadcrumbs, setFolderBreadcrumbs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [currentlyActiveFolder, setCurrentlyActiveFolder] = useState(null);
  const [state, dispatch] = useReducer(folderReducer, initialState);
  // console.log('PARENT FOLDER:', parentFolder);

  // Fetch folder
  const fetchFolders = async (teamId) => {
    try {
      const res = await fetch_folders(teamId);
      if(res.error) {
        dispatch({
          type: GET_FOLDERS,
          payload: []
        })
        throw new Error(res.error?.message || 'folders cannot be fetched')
      }
      const arr = [];
      for(let id in res) {
        const folder = {...res[id]};
        folder.id = id;
        folder.folderRow = true;
        arr.push(folder);
      }
      const sortedArr = [...arr].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: GET_FOLDERS,
        payload: sortedArr
      });
    }catch(err) {
      console.log(err);
    }
  }

  // Create folder
  const createFolder = async (data) => {
    setFoldersLoading(true)
    try {
      const res = await create_folder(data)
      console.log(res)
      if(res.success) {
        const newFolder = {...data, id: res.folderId, meta: { created: Date.now(), updated: Date.now() }, folderRow: true}
        const folders = [...state.folders, newFolder]
        setFolders(sortArrayOfObjects(folders, 'name', 'desc'))
        setFoldersLoading(false)
        return 
      }
      console.log('throw error')
      throw new Error('')
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Set folders
  const setFolders = (value) => {
    dispatch({
      type: SET_FOLDERS,
      payload: value
    });
  }

  // Update folder
  const updateFolder = async (data, folderId, single = true, fetch = false) => {
    setFoldersLoading(true);
    try {
      await update_folder(data, folderId);
      if(single) {
        const folders = [...state.folders];
        let foundFolder = folders.find(f => f.id === folderId);
        foundFolder = {...foundFolder, ...data};
        const updatedFolders = folders.map(f => f.id === folderId ? foundFolder : f);
        setFolders(updatedFolders);
      }
      // if(!fetch) {
      //   await fetchFolders();
      // }
      setFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Update multiple folders
  const updateMultipleFolders = (arr) => {
    const folders = [...state.folders];
    const updatedFolders = [];
    arr.forEach(f => {
      const foundFolder = folders.find(fol => fol.id === f.id);
      let updatedFolder = {};
      if(foundFolder) {
        updatedFolder = {...foundFolder, ...f.data};
        updatedFolders.push(updatedFolder);
      }
    });
    const f = [...folders].map(fol => {
      const foundF = updatedFolders.find(f => f.id === fol.id);
      if(foundF) {
        return foundF;
      }else {
        return fol;
      }
    });
    setFolders(f);
  }

  // Delete folder
  const deleteFolder = async (folderId, fetch = false, teamId) => {
    setFoldersLoading(true);
    try {
      await delete_folder(folderId);
      if(!fetch) {
        await fetchFolders(teamId);
      }
      setFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Set loading
  const setFoldersLoading = (value) => {
    dispatch({
      type: SET_FOLDERS_LOADING,
      payload: value
    });
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, foldersFetched: true, loading: false } : initialState,
      payload: initialState,
    });
    setFolderBreadcrumbs([])
    setCurrentlyActiveFolder(null)
    setParentFolder(null)
    setFolderToMove(null)
    setSelectedFilter('all')
  }

  return <FolderContext.Provider value={{
    folders: state.folders,
    foldersLoading: state.loading,
    foldersFetched: state.foldersFetched,
    parentFolder,
    folderToMove,
    folderBreadcrumbs,
    selectedFilter,
    currentlyActiveFolder,
    setFoldersLoading,
    fetchFolders,
    createFolder,
    updateFolder,
    deleteFolder,
    setParentFolder,
    setFolderToMove,
    setFolderBreadcrumbs,
    setSelectedFilter,
    updateMultipleFolders,
    setCurrentlyActiveFolder,
    resetFolderState: resetState,
  }}>
    {children}
  </FolderContext.Provider>
}

export default FolderState;
import { post_request, baseUrl } from './firestore';

const gdriveConfig = {
  apiKey: 'AIzaSyCHm35ZGVkt_qKT7tcdp8e252KvVQp4VmM',
  clientId: '24604967994-qmqsc5dbfjtiq2b07nostlbha59b7oq5.apps.googleusercontent.com',
  scope: 'https://www.googleapis.com/auth/drive',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
}

const gdrive_get_items = async (data) => {
  return await post_request(`${baseUrl}/gdrive_get_items`, data);
}

const gdrive_upload_file = async (data) => {
  return await post_request(`${baseUrl}/gdrive_upload_file`, data);
}

export {
  gdriveConfig,
  gdrive_get_items,
  gdrive_upload_file,
}
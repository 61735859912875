import { useContext, useState } from 'react'

import { Header, SideMenu } from '../components/shared'
import { UserContext } from '../context'

const MainLayout = ({ children, sidebar = null, title, page = '', refreshCollection, showSidebarTrigger = false, hideSearch, hideCreateDoc, className = '' }) => {
  const { customizations } = useContext(UserContext)
  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [showToggle, setShowToggle] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)

  // On sidebar toggle
  const handleToggleSidebar = () => {
    setIsSidebarClosed(!isSidebarClosed)
  }

  // On sidebar mouse enter
  const handleSidebarMouseEnter = () => {
    setShowToggle(true)
  }

  // On sidebar mouse leave
  const handleSidebarMouseLeave = () => {
    setShowToggle(false)
  }

  return (
    <div className={`main-layout ${className}`} style={{ backgroundColor: customizations.background }}>
      {sidebar && (
        <div className={`main-layout__sidebar-toggle ${showToggle ? 'show' : ''} ${isSidebarClosed ? 'position-2' : ''}`} onClick={handleToggleSidebar}>
          <span className="material-symbols-outlined">{isSidebarClosed ? 'arrow_forward_ios' : 'arrow_back_ios'}</span>
        </div>
      )}
      {showSidebarTrigger && (
        <>
          <div className={`main-layout__sidebar-overlay ${toggleSidebar ? 'show' : ''}`} onClick={() => setToggleSidebar(false)}></div>
          <div className={`main-layout__sidebar-trigger`} onClick={() => setToggleSidebar(true)}>
            <span className="material-symbols-outlined size-24">menu_open</span>
          </div>
        </>
      )}
      <div className="main-layout__side-menu">
        <SideMenu />
      </div>
      <div className={`main-layout__content ${sidebar ? 'with-sidebar' : ''} ${isSidebarClosed ? 'sidebar-closed' : ''}`}>
        {sidebar && (
          <div className={`main-layout__content_sidebar ${toggleSidebar ? 'sidebar-mobile' : ''}`} onMouseEnter={handleSidebarMouseEnter} onMouseLeave={handleSidebarMouseLeave}>
            {sidebar}
          </div>
        )}
        <div className="main-layout__content_top">
          <Header 
            title={title} 
            page={page} 
            refreshCollection={refreshCollection} 
            hideSearch={hideSearch}
            hideCreateDoc={hideCreateDoc}
          />
        </div>
        <div className="main-layout__content_body u-custom-scrollbar">
          {children}
        </div>
      </div>
    </div>
  )
}

export default MainLayout
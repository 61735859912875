import React, { useContext, useState, useEffect, Fragment, useRef } from 'react';
import { Person, PersonOutline } from '@material-ui/icons'

import VariablesInner from './VariablesInner';
import Input from '../../UI/Input';
import Radio from '../../UI/Radio';
import Checkbox from '../../UI/Checkbox'
import Textarea from '../../UI/Textarea'
import CustomTooltip from '../../UI/CustomTooltip'
import moment from 'moment'
// import Tooltip from '@material-ui/core/Tooltip'
import Tooltip from '../../UI/Tooltip';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { dateValueFormat, availableOn, formulaResult } from '../../../utils';
import { TeamContext, UserContext, GlobalContext, VariablesContext } from '../../../context'

import fr from 'date-fns/locale/fr';
import Button from '../../UI/Button';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
registerLocale('fr', fr);

const DocumentSectionContent = ({
  type,
  content,
  bulletListItems,
  variable,
  variables,
  data_type,
  question,
  options,
  tooltip,
  condition,
  sections,
  values,
  onValuesChange,
  checkboxValues,
  onCheckboxValuesChange,
  index,
  varIndex,
  repeatableSectionId,
  repeatableIndex,
  onSetEditStartTime,
  onStoreEditTime,
  onContactsModalOpen,
  makeContentEditable = false,
  onLinkVarsWithProfileData = () => {},
  onSectionContentChanged,
  head,
  row,
  repeatableRowId
}) => {
  const { selectedTeam } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { t } = useContext(GlobalContext)
  const { variables: collectionVars } = useContext(VariablesContext)
  const [combinedLinkedVars, setCombinedLinkedVars] = useState([])

  useEffect(() => {
    let linkedVars = selectedTeam?.contacts_linked_variables || {}
    let combinedVars = []
    if(Object.keys(linkedVars).length) {
      for(let group in linkedVars) {
        let groupVars = linkedVars[group]
        for(let key in groupVars) {
          combinedVars.push(groupVars[key])
        }
      }
      if(combinedVars.length) {
        setCombinedLinkedVars([...new Set(combinedVars)])
      }
    }
  }, [selectedTeam])

  const isHandleLocked = (handle) => {
    return handle && handle.includes(':locked')
  }

  const renderHeading1 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h1>
            { renderContent() }
          </h1>
        </div>
      </div>
    )
  }

  const renderHeading2 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h2>
            { renderContent() }
          </h2>
        </div>
      </div>
    )
  }

  const renderHeading3 = () => {
    return (
      <div className="document-detail-variables__head">
        <h3>
          { renderContent() }
        </h3>
      </div>
    )
  }

  const renderQuestion = (fieldVariable, handle, itemIndex, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    let q
    let o
    let v
    let dt
    let t
    let idx
    let lckd = isHandleLocked(handle)
    if (fieldVariable) {
      t = fieldVariable.tooltip
      q = fieldVariable.tooltip
      o = fieldVariable.options || []
      v = fieldVariable.variable
      dt = fieldVariable.type
      idx = fieldVariable.idx
    } else {
      q = question
      o = options || []
      v = variable
      dt = data_type
      t = tooltip
      idx = varIndex
    }    
    if(dt === 'empty_lines') {
      return renderEmptyLinesInput({ question: q, options: o, variable: v, data_type: dt, type: dt, varIndex: idx, tooltip: t}, handle, repId, repIndex)
    } else if(dt === 'select') {
      return <VariablesInner 
        title={q}
        choices={o}
        choicesName={v}
        documentValues={values}
        onValueChange={val => onValuesChange(v, val)}
        tooltip={t}
        variable={v}
        id={v}
        index={idx}
        onSetEditStartTime={onSetEditStartTime}
        onStoreEditTime={onStoreEditTime}
        disabled={lckd}
      />;
    } else if(dt === 'multiselect') {
      return (
        <div key={`${itemIndex}${repeatableSectionId ? `-${repeatableIndex}` : ''}`} className="tooltip-wrapper doc-variable-el" data-id={idx}>
          <Tooltip text={t} nowrap />
          <div className="variables__head">
            <div className="variables__head_text">
              <div className="fields-opened">
                <p>{ `${q}:`} </p>
                <div className="select_input_wrapper multiselect" id={v}>
                  { o.map((option, optionIndex) => {
                    return (
                      <div key={`option_${optionIndex}`}>
                        <Checkbox disabled={lckd} name={option.label} onChange={e => onMultiselectChanged(v, value(v), option.value, e.target.checked)} checked={value(v) && value(v).includes(option.value)} />
                        <span style={{ marginLeft: 5 }}>{ option.label }</span>
                      </div>
                    )
                  }) }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if(dt === 'number' || dt === 'string') {
      return (
        <div key={`${itemIndex}${repeatableSectionId ? `-${repeatableIndex}` : ''}`} className="tooltip-wrapper doc-variable-el" data-id={idx}>
          {/* <Tooltip text={t} nowrap /> */}
          <div className="variables__head_text">
            <div className="fields-opened">
              <p>{ `${q}:`} </p>
              {/* <Input disabled={isInputDisabled(v)} id={v} value={value(v) || ''} type={dt} editMode onChange={(e) => onInputChange(v, e.target.value)} />  */}
              <TextInput isInputDisabled={lckd ? () => true : isInputDisabled} value={value} variable={v}  type={dt} onInputChange={onInputChange} tooltip={t} onSetEditStartTime={onSetEditStartTime} onStoreEditTime={onStoreEditTime} />
            </div>
          </div>
        </div>
      )
    } else if(dt === 'textarea') {
      return (
        <div key={`${itemIndex}${repeatableSectionId ? `-${repeatableIndex}` : ''}`} className="tooltip-wrapper doc-variable-el" data-id={idx}>
          <Tooltip text={t} nowrap />
          <div className="variables__head_text">
            <div className="fields-opened">
              <p>{ `${q}:`} </p>
              <Textarea id={v} disabled={lckd} value={value(v) || ''} type={dt} editMode onChange={onTextAreaInputChange(v)} className={value(v) ? 'not-empty' : ''} onFocus={() => onSetEditStartTime(Date.now())} onBlur={onStoreEditTime} />
            </div>
          </div>
        </div>
      )
    } else if(dt === 'date') {
      return (
        <div key={`${itemIndex}${repeatableSectionId ? `-${repeatableIndex}` : ''}`} className="tooltip-wrapper doc-variable-el" data-id={idx}>
          <Tooltip text={t} nowrap />
          <div className="variables__head_text">
            <div className="fields-opened">
              <p>{ `${q}:`} </p>
              <div className={value(v) ? 'date_picker not-empty' : 'date_picker'}>
                <DatePicker
                  // id={variable}
                  selected={!value(v) ? null : new Date(moment(value(v), dateValueFormat).valueOf())}
                  // selected={new Date()}
                  onChange={date => date ? onInputChange(v, moment(date.valueOf()).format(dateValueFormat)) : ''}
                  peekNextMonth
                  dateFormat={'dd/MM/yyyy'}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale="fr"
                  onCalendarOpen={() => onSetEditStartTime(Date.now())}
                  onCalendarClose={onStoreEditTime}
                  popperModifiers={{preventOverflow: { enabled: true }}} 
                  portalId="modal-root"
                  disabled={lckd}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const fieldKeyFromHandle = (handle) => {
    return handle.substring(3, handle.length - 1)
  }

  const fieldRegex = /\{d\.([^}]*)\}|\{f\(([^}]*)\}|\{checkbox\.([^}]*)\}/g
  const variableRegex = /\{d\.([^}]*)\}/g
  const formulaRegex = /\{f\(([^}]*)\}/g
  const checkboxRegex = /\{checkbox\.([^}]*)\}/g

  const contentItems = (cntnt = content, vrbls = variables, listIndex = -1) => {
    if(cntnt === null || cntnt === undefined) {
      return []
    }
    let source = cntnt
    let fields = source.match(fieldRegex)
    if(fields?.length > 0) {
      const selectVariables = vrbls ? [...vrbls].filter(v => v.type === 'select').map(v => (`{d.${v.variable}}`)) : [];
      fields.forEach(f => {
        if(selectVariables.includes(f)) {
          if(source.includes(`${f}.`)) {
            source = source.replace(`${f}.`, f);
          }
        }
      });
    }

    let items = []
    for(let i in fields) {
      if(!source) {
        continue
      }
      let components = source.split(fields[i])
      items.push(components[0])
      items.push(fields[i])
      components.splice(0, 1)
      source = components.join(fields[i])
    }
    if(source) {
      items.push(source)
    }
    return items
  }

  const renderEmptyLinesInput = (fieldVariable, handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const { variable } = fieldVariable
    let val = value(variable, repId, repIndex)
    let innerClasses = 'variables doc-variable-el question-el';

    if (val) {
      innerClasses += ' selected';
    }
    return (
      <div className={innerClasses}>
      <div className={"variables__head select"}>
        <div className={"variables__head_text tooltip-wrapper"} onClick={null}>
          <span className="radio"></span>
          <p>{ t('document_detail.empty_lines_label') }</p>
          <p className="selected">{emptyLinesLabelForValue(val)}</p>
        </div>
        <div className="variables__head_buttons">
          <Button text={1} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_4')} />
          <Button text={2} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_8')} />
          <Button text={3} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_12')} />
          <Button text={4} outlinePink onButtonClick={() => onInputChange(variable, 'empty_lines_16')} />
        </div>
        <div className="variables__head_selected">
          <div className="tooltip-wrapper">
            <Tooltip text={t('general.reset')} nowrap />
            <Button icon={<MoreHoriz />} outlinePink onButtonClick={() => onInputChange(variable, '')} />
          </div>
        </div>
      </div>
      { renderEmptyLinesContent(val) }
      </div>
    )
  }

  const emptyLinesLabelForValue = (value) => {
    if(!value) {
      return ''
    }
    let amountComponents = value.split('_')
    let amount = parseInt(amountComponents[amountComponents.length - 1])
    let blocks = amount / 4 
    return blocks
  }

  const renderEmptyLinesContent = (value) => {
    if(!value) {
      return null
    }
    let amountComponents = value.split('_')
    let amount = parseInt(amountComponents[amountComponents.length - 1])
    let blocks = amount / 4
    return (
      <div>
        {[...Array(blocks)].map((elementInArray, bi) => ( 
          <div key={`lines_block_${bi}`} className="manual-lines-block">
            {[...Array(4)].map((e, li) => ( 
              <div key={`line_${bi}_${li}`} className="manual-line"></div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  const renderContent = (cntnt = content, vrbls = variables, listIndex = -1, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const items = contentItems(cntnt, vrbls, listIndex)
    // const updatedItems = contentItems(updatedContent, vrbls, listIndex)
    return items.map((item, i) => {
      if(item.match(variableRegex)) {
        let field = (fieldKeyFromHandle(item) || '').split(':')[0]
        // input field
        let fieldVariable = variableWithField(field, vrbls)
        if(!fieldVariable) {
          if(isRepeatableBlockHandle(field)) {
            return ''
          } else {
            return item
          }
        }
        if(fieldVariable.type === 'string' || fieldVariable.type === 'number') {
          return renderTextInput(fieldVariable, item, i, repId, repIndex)
        } else if(fieldVariable.type === 'select') {
          return renderSelectInput(fieldVariable, item, i, repId, repIndex)
        } else if(fieldVariable.type === 'date') {
          return renderDateInput(fieldVariable, item, i, repId, repIndex)
        } else if(fieldVariable.type === 'multiselect') {
          return renderQuestion(fieldVariable, item, i, repId, repIndex)
        } else if(fieldVariable.type === 'textarea') {
          return renderTextarea(fieldVariable, item, i, repId, repIndex)
        } else if(fieldVariable.type === 'empty_lines') {
          return renderEmptyLinesInput(fieldVariable, item, repId, repIndex)
        } else {
          return null
        }
      } else if(item.match(formulaRegex)) {
        return renderFormulaInput(item, repId, repIndex)
      } else if(item.match(checkboxRegex)) {
        return renderCheckboxInput(checkboxIdFromHandle(item), repId, repIndex)
      }  else {
        return type.includes('heading') 
          ? <span key={i} className="doc-variable-el doc-variable-el-section" data-id={`section-${index}`}>{item}</span> 
          // : <span key={i} contentEditable={makeContentEditable} onInput={onContentChanged(item, listIndex)} suppressContentEditableWarning={true} className="content-to-edit">{makeContentEditable ? updatedItems ? updatedItems[i] : item : item}</span>
          : <span key={i} contentEditable={makeContentEditable} onInput={onContentChanged(item, listIndex)} suppressContentEditableWarning={true} className="content-to-edit">{item}</span>
      }
    })
  }

  const checkboxIdFromHandle = (handle) => {
    return handle.split('.')[1].split(':')[0]
  }

  const decodeHtml = (html) => {
    let txt = document.createElement('textarea')
    txt.innerHTML = html
    let val = txt.value

    val = val.replace(/\u0002/g, '')
    val = val.replace(//g, '')
    return val
  }

  const onContentChanged = (originalContent, listIndex) => (e) => {
    if(!e.target) {
      return
    }

    const editedContent = decodeHtml(e.target.innerHTML)

    onSectionContentChanged({
      sectionIndex: index,
      listIndex: listIndex,
      editedContent,
      originalContent
    })
  }

  const renderText = () => {
    return (
      <div className="variables__text">
        { renderContent(content) }
      </div>
    )
  }

  const renderBulletList = () => {
    return (
      <ul className="variables__text">
        { bulletListItems.map(renderBulletListItem)}
      </ul>
    )
  }

  const renderBulletListItem = (item, itemIndex) => {
    return (
      <li key={`section${index}-item${itemIndex}`} className="bullet-list-item" style={{ marginLeft: `${1*((item.level + 1) || 1)}rem` }}>
        { renderContent(item.content, item.variables, itemIndex) }
      </li>
    )
  }

  const onTableRowCountChange = (newRowCount) => {
    if(newRowCount < 1) {
      return
    }
    let rowCount = 0
    if(!values[repeatableRowId]) {
      rowCount = 1
    } else {
      rowCount = values[repeatableRowId].length
    }
    if(newRowCount === rowCount) {
      return
    }
    let vals = values[repeatableRowId]
    if(!vals) {
      vals = [{}]
    }
    if(vals.length > newRowCount) {
      vals.splice(newRowCount, vals.length - newRowCount)
    } else {
      while(vals.length < newRowCount) {
        vals.push({})
      }
    }
    onValuesChange(repeatableRowId, vals)
  }

  const renderTable = () => {
    let rowCount = 0
    if(!repeatableRowId) {
      rowCount = 1
    } else if(!values[repeatableRowId]) {
      rowCount = 1
    } else {
      rowCount = values[repeatableRowId].length
    }
    let rowsObject = []
    for(let i = 0; i < rowCount; i++) {
      rowsObject.push(i)
    }
    return (
      <div>
        { repeatableRowId && <RowCountInput label="Nombre de lignes" onValueChange={onTableRowCountChange} value={!repeatableRowId ? 1 : !values[repeatableRowId] ? 1 : values[repeatableRowId].length} />}
        <table>
          {(head && head.length > 0) && <thead>
            <tr>
              { head.map((cell, ci) => <td className="variables__text">{ renderContent(cell.content, cell.variables) }</td> )}
            </tr>
          </thead> }
          {(row && row.length > 0) && <tbody>
            { rowsObject.map((r, ri) => <tr key={`table_row_${ri}`}>
              { row.map((cell, ci) => <td className="variables__text">{ renderContent(cell.content, cell.variables, 0, repeatableRowId, ri) }</td>)}
            </tr> )}
          </tbody>}
        </table>
      </div>
    )
  }

  const RowCountInput = ({ label, value, onValueChange }) => {

    const onButtonClick = (diff) => {
      let newValue = value + diff
      if(newValue < 1) {
        newValue = 1
      }
      onValueChange(newValue)
    }

    // const onInputChange = (e) => {
    //   let value = e.target.value
    //   onValueChange(value)
    // }

    return (
      <div className="row-count-input-container">
        <p className="row-count-input-label">
          { label }
        </p>
        <Button primary className="row-count-button" text="-" small onButtonClick={() => onButtonClick(-1)} />
        {/* <Input className="row-count-input" type="number" value={value} onChange={onInputChange} /> */}
        <p className="row-count-input">{value}</p>
        <Button primary className="row-count-button" text="+" small onButtonClick={() => onButtonClick(1)} />
      </div>
    )
  }

  const variableWithField = (handle, vrbls = variables) => {
    let variableName
    if(repeatableSectionId) {
      let components = handle.split('.')
      variableName = components[components.length - 1]
    } else {
      variableName = handle
    }
    for(let i in vrbls) {
      if(vrbls[i].variable === variableName) {
        return vrbls[i]
      }
    }
    return null
  }

  const isRepeatableBlockHandle = (handle) => {
    return ( handle === `${repeatableSectionId}[i]` || handle === `${repeatableSectionId}[i+1]` )
  }

  const onInputChange = (variable, value, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    if(repId) {
      let val = duplicatableValuesArray(repId)
      // to enable updating multiple variables at once
      if(typeof variable === 'object' && Array.isArray(variable)) {
        for(let i in variable) {
          val[repIndex][variable[i]] = value[i]
        }
      } else {
        val[repIndex][variable] = value
      }
      onValuesChange(repId, val)
    } else {
      onValuesChange(variable, value)
    }
  }
  

  const value = (variable, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    let val
    if(repId && repIndex !== -1) {
      val = duplicatableValuesArray(repId)[repIndex][variable]
      if(!val) {
        val = values[variable] // in case condition was outside of duplicatable block
      }
    } else {
      val = values[variable]
    }
    return val
  }

  const isInputDisabled = (variableId) => {
    return false
  }

  const renderTextInput = (fieldVariable, handle, itemIndex, repId, repIndex) => {
    const { variable, tooltip, type, idx} = fieldVariable
    let classes = "tooltip-wrapper doc-variable-el"
    let userLinkedVars = []
    let variableExists = collectionVars.find(v => v.variable === variable) // if variable(from lawstudio api) does not exists, do not show icons to prefill values with profile data or contacts
    if(user && user.profile_linked_vars) {
      userLinkedVars = Object.values(user.profile_linked_vars)
    }
    if(variableExists && (userLinkedVars.length > 0 || combinedLinkedVars.length > 0)) {
      classes += ' doc-variable-el--with-icon'
    }

    const locked = isHandleLocked(handle)

    return (
      <div key={`${itemIndex}${repeatableSectionId ? `-${repeatableIndex}` : ''}`} className={classes} data-id={`${idx}${repeatableSectionId ? `-${repeatableIndex}` : ''}`}>
        {/* <Tooltip text={tooltip} nowrap /> */}
        <div style={{padding: '2px 0'}}>
          {/* <Input disabled={isInputDisabled(variable)} id={variable} value={value(variable) || ''} type={type} editMode onChange={ (e) => onInputChange(variable, e.target.value) } />  */}
          <TextInput value={value} variable={variable} isInputDisabled={locked ? () => true : isInputDisabled} type={type} onInputChange={onInputChange} tooltip={tooltip} onSetEditStartTime={onSetEditStartTime} onStoreEditTime={onStoreEditTime} repId={repId} repIndex={repIndex} />
        </div>
        {/* Show this only if variable is saved to contacts_linked_variables in current team */}
        {variableExists && combinedLinkedVars.includes(variable) && <CustomTooltip content={t('general.contacts_directory')}>
          <span className="icon" onClick={() => onContactsModalOpen(variable)}><Person /></span>
        </CustomTooltip>}
        {/* Show this only if variable is saved to profile_linked_vars in current user */}
        {variableExists && userLinkedVars.includes(variable) && <CustomTooltip content={t('general.prefill_with_profile_data')}>
          <span className="icon" onClick={onLinkVarsWithProfileData}><PersonOutline /></span>
        </CustomTooltip>}
      </div>
    )
  }

  const renderTextarea = (fieldVariable, handle, itemIndex, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const { variable, tooltip, type, idx} = fieldVariable
    const locked = isHandleLocked(handle)
    return (
      <div key={`${itemIndex}${repId ? `-${repIndex}` : ''}`} className="tooltip-wrapper textarea-el doc-variable-el" data-id={idx}>
        <Tooltip text={tooltip} nowrap />
        <div style={{padding: '2px 0'}}>
          <Textarea id={variable} disabled={locked} value={value(variable, repId, repIndex) || ''} type={type} editMode onChange={onTextAreaInputChange(variable, repId, repIndex)} className={value(variable, repId, repIndex) ? 'not-empty' : ''} onFocus={() => onSetEditStartTime(Date.now())} onBlur={onStoreEditTime} /> 
        </div>
      </div>
    )
  }

  const onTextAreaInputChange = (variable, repId = repeatableSectionId, repIndex = repeatableIndex) => (e) => {
    onInputChange(variable, e.target.value, repId, repIndex)
  }

  const renderSelectInput = (fieldVariable, handle, itemIndex) => {
    const locked = isHandleLocked(handle)
    return <VariablesInner 
      title={fieldVariable.tooltip}
      choices={fieldVariable.options}
      choicesName={fieldVariable.variable}
      documentValues={values}
      onValueChange={val => onInputChange(fieldVariable.variable, val)}
      tooltip={fieldVariable.tooltip}
      select={true}
      id={fieldVariable.variable}
      index={fieldVariable.idx}
      onSetEditStartTime={onSetEditStartTime}
      onStoreEditTime={onStoreEditTime}
      elementKey={`${itemIndex}${repeatableSectionId ? `-${repeatableIndex}` : ''}`}
      disabled={locked}
    />;
  }

  const onMultiselectChanged = (variable, prevState, value, checked) => {
    let nextState = prevState
    if(!nextState) {
      nextState = []
    }
    if(checked && !nextState.includes(value)) {
      nextState.push(value)
    } else if(!checked && nextState.includes(value)) {
      nextState.splice(nextState.indexOf(value), 1)
    }
    onInputChange(variable, nextState)
  }

  const renderDateInput = (fieldVariable, handle, itemIndex, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    const { variable, idx } = fieldVariable
    const locked = isHandleLocked(handle)
    return (
      <div key={`${itemIndex}${repId ? `-${repIndex}` : ''}`} className="tooltip-wrapper doc-variable-el" data-id={idx}>
        {tooltip && <Tooltip text={tooltip} nowrap />}
        <div className={value(variable, repId, repIndex) ? 'date_picker not-empty' : 'date_picker'}>
          <DatePicker
            // id={variable}
            selected={!value(variable, repId, repIndex) ? null : new Date(moment(value(variable, repId, repIndex), dateValueFormat).valueOf())}
            // selected={new Date()}
            onChange={date => date ? onInputChange(variable, moment(date.valueOf()).format(dateValueFormat), repId, repIndex) : ''}
            peekNextMonth
            dateFormat={'dd/MM/yyyy'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="fr"
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
            popperModifiers={{preventOverflow: { enabled: true }}} 
            portalId="modal-root"
            disabled={locked}
          />
        </div>
      </div>
    )
  }

  const renderFormulaInput = (handle, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    // const val = value(variable, handle, repId, repIndex)
    const result = formulaResult(values, handle, repId, repIndex)
    const { value } = result
    let classes = "tooltip-wrapper doc-variable-el"
    
    return (
      <div className={classes} data-id={`${handle}${repId ? `-${repIndex}` : ''}`}>
        <div style={{padding: '2px 0'}}>
          <TextInput
            value={() => value}
            onInputChange={() => {}}
            onSetEditStartTime={onSetEditStartTime}
            onStoreEditTime={onStoreEditTime}
            isInputDisabled={() => true} />
        </div>
      </div>
    )
  }

  const renderCheckboxInput = (id, repId = repeatableSectionId, repIndex = repeatableIndex) => {
    return (
      <div className="checkbox-inline">
        <Checkbox onChange={e => onCheckboxValuesChange(id, e.target.checked) } checked={checkboxValues[id] || false} />
      </div>
    )
  }

  const renderSection = () => {
    switch(type) {
      case 'heading1':
        return renderHeading1()
      case 'heading2':
        return renderHeading2()
      case 'heading3':
        return renderHeading3()
      case 'question':
        return renderQuestion()
      case 'text':
        return renderText()
      case 'bullet_list':
        return renderBulletList()
      case 'table':
        return renderTable()
      default:
        return null
    }
  }

  const duplicatableValuesArray = (repId = repeatableSectionId) => {
    if(values[repId]) {
      return values[repId]
    } else {
      return [{}]
    }
  }

  const removeRepetition = () => {
    let vals = values[repeatableSectionId]
    vals.splice(repeatableIndex, 1)
    onValuesChange(repeatableSectionId, vals)
  }

  return (
    <div className="variables__flex">
      { renderSection() }
      { repeatableSectionId && duplicatableValuesArray().length > 1 ? (
        <span className="u-cursor--pointer" onClick={removeRepetition}>{ 'Supprimer' }</span>
      ) : null }      
    </div>
  )
}

const TextInput = ({ value, variable, isInputDisabled, onInputChange, type, tooltip, onSetEditStartTime, onStoreEditTime, repId, repIndex, locked = false }) => {
  const spanEl = useRef();
  let textInputClass = 'doc-variable-el__span';
  if(type === 'number') {
    textInputClass += ' doc-variable-el__span--number';
  }
  const onChange = (e) => {
    let value = e.target.value
    if(type === 'number') {
      value = parseFloat(e.target.value)
    }
    onInputChange(variable, value, repId, repIndex)
  }
  return (
    <Fragment>
      <span className={textInputClass} ref={spanEl}>{value(variable, repId, repIndex)}</span>
      <Input 
        disabled={isInputDisabled(variable)} 
        id={variable} 
        value={value(variable, repId, repIndex) || ''} 
        type={type} 
        editMode 
        onChange={onChange} 
        className={value(variable) ? 'not-empty' : ''} 
        tooltip={tooltip} 
        inputStyle={
          value(variable) 
          ? { width: spanEl.current?.offsetWidth || '140px', maxWidth: '100% !important' } 
          : { maxWidth: '100% !important' }
        }
        onFocus={() => onSetEditStartTime(Date.now())}
        onBlur={onStoreEditTime}
      /> 
    </Fragment>
  );
}

export default DocumentSectionContent;
import { useContext, useEffect, useState } from 'react'

import { Modal } from '../new_ui'
import Loader from '../UI/Loader'
import { GlobalContext, DocumentsContext, TeamContext, FolderContext } from '../../context'
import { sortedArrayFromObject, sorterWithPathAndOrder } from '../../utils'
import { useSingleDocumentActions } from '../../hooks'

const TemplateSelectModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { templatesLoaded, getTemplates, templates } = useContext(DocumentsContext)
  const { selectedTeam } = useContext(TeamContext)
  const { folders, foldersFetched, fetchFolders } = useContext(FolderContext)
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const [templatesArray, setTemplatesArray] = useState([])
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [filteredFolders, setFilteredFolders] = useState([])
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fetch templates if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!templatesLoaded) {
        getTemplates(teamId)
      }
      if(!foldersFetched) {
        fetchFolders(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, templatesLoaded, getTemplates, foldersFetched, fetchFolders])

  // Set templates
  useEffect(() => {
    const filtered = filterNonDeletedTemplates(templates)
    processTemplates(filtered)
  }, [templates])

  // Set folders
  useEffect(() => {
    if(foldersFetched) {
      let arr = folders.filter(f => !f.parentFolder)
      setFilteredFolders(arr)
    }
  }, [foldersFetched, folders])

  // Process templates
  const processTemplates = async (t) => {
    let tmplts = sortedArrayFromObject(t, sorterWithPathAndOrder('name', 'desc'))
    for(let i in tmplts) {
      tmplts[i].title = tmplts[i].name
    }
    setTemplatesArray(tmplts)
    setFilteredTemplates(tmplts.filter(tmplt => !tmplt.folderId || (tmplt.folderId && tmplt.folderId.length === 0)))
  }

  // Filter non deleted templates
  const filterNonDeletedTemplates = (tmplts) => {
    const filtered = {}
    for(let key in tmplts) {
      if(!tmplts[key].deleted) {
        filtered[key] = tmplts[key]
      }
    }
    return filtered
  }

  // Create document
  const handleCreateDocument = async (template) => {
    await createDocumentFromTemplate({ template })
  }

  // Filter folders
  const handleFilterFolders = (folder) => {
    const subfolders = folders.filter(f => f.parentFolder === folder.id)
    setFilteredFolders(subfolders)
    setBreadcrumbs([...breadcrumbs, folder])
    setFilteredTemplates(templatesArray.filter(tmplt => (tmplt.folderId && tmplt.folderId.includes(folder.id))))
  }

  // On breadcrumb click
  const handleBreadcrumbClick = (e, b) => {
    e.preventDefault()
    const subfolders = folders.filter(f => f.parentFolder === b.id)
    setFilteredFolders(subfolders)
    setBreadcrumbs(breadcrumbs.slice(0, breadcrumbs.indexOf(b) + 1))
    setFilteredTemplates(templatesArray.filter(tmplt => (tmplt.folderId && tmplt.folderId.includes(b.id))))
  }

  // On clear breadcrumbs
  const handleClearBreadcrumbs = (e) => {
    e.preventDefault()
    setBreadcrumbs([])
    setFilteredTemplates(templatesArray.filter(tmplt => !tmplt.folderId || (tmplt.folderId && tmplt.folderId.length === 0)))
    setFilteredFolders(folders.filter(f => !f.parentFolder))
  }
  
  return (
    <Modal onClose={onClose} title={t('general.create_document')} hideFooter={true}>
      {(!templatesLoaded || !foldersFetched) && <div className="loader-wrapper"><Loader normal primary small /></div>}
      {templatesLoaded && foldersFetched && templatesArray.length > 0 && (
        <>
          <div className="modal-v2__section-with-border modal-v2__out-of-box">
            <ul className="breadcrumbs-v2">
              <li><a href="#/" onClick={handleClearBreadcrumbs}>Home</a></li>
              {breadcrumbs.map((b, i) => {
                if(i < breadcrumbs.length - 1) {
                  return (
                    <li key={i}><a href="#/" onClick={(e) => handleBreadcrumbClick(e, b)}>{b.name}</a></li>
                  )
                }
                return (
                  <li key={i}><span>{b.name}</span></li>
                )
              })}
            </ul>
          </div>
          <ul className="select-list">
            {filteredFolders.map((folder) => {
              return (
                <li key={folder.id} onClick={() => handleFilterFolders(folder)} className="folder-item">
                  <span className="material-symbols-outlined size-24">folder</span>
                  <p className="u-truncate-text">{folder.name}</p>
                </li>
              )
            })}
            {filteredTemplates.map((template) => {
              return (
                <li key={template.id} onClick={() => handleCreateDocument(template)}>
                  <p className="u-truncate-text">{template.name}</p>
                  <button className="btn btn--outline btn--small-2 btn--with-letter-spacing btn--thick-border">{t('general.create_document')}</button>
                </li>
              )
            })}
          </ul>
          {templatesLoaded && foldersFetched && filteredFolders.length === 0 && filteredTemplates.length === 0 && (
            <p>{t('folder.empty')}</p>
          )}
        </>
      )}
    </Modal>
  )
}

export default TemplateSelectModal
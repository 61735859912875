import React, { createContext, useReducer, useState } from 'react'

import approvalsReducer from './approvalsReducer'
import { GET_APPROVALS, SET_APPROVALS, RESET_STATE } from '../types'
import { create_approval, fetch_approvals, delete_approval, update_approval, fetch_approval_by_id, fetch_other_approvals } from '../../services/approvals'
import { sortArrayOfObjects, getSelectedTeamFromLS, getTeamSortingData } from '../../utils'

export const ApprovalsContext = createContext()

const selectedTeam = getSelectedTeamFromLS()
const sortingData = getTeamSortingData()

let defaultSort = { value: 'name', order: 'desc' }
if(selectedTeam && sortingData && sortingData[selectedTeam]) {
  if(sortingData[selectedTeam].approvals) {
    defaultSort= { value: sortingData[selectedTeam].approvals.value || 'name', order: sortingData[selectedTeam].approvals.order || 'desc' }
  }
}

const ApprovalsState = ({ children }) => {
  const initialState = {
    approvals: [],
    approvalsFetched: false
  }
  const [singleApprovalFetched, setSingleApprovalFetched] = useState(null)
  const [approvalsSort, setApprovalsSort] = useState(defaultSort)
  const [approvalsSelectedFilters, setApprovalsSelectedFilters] = useState({
    search: ''
  })
  const [state, dispatch] = useReducer(approvalsReducer, initialState)

  // Create approval
  const createApproval = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const id = await create_approval(data)
      let item = {...data, id}
      let newArr = [...state.approvals]
      newArr.push(item)
      setApprovals(sortArrayOfObjects(newArr, approvalsSort.value, approvalsSort.order))
      onSuccess(id)
    } catch (err) {
      console.log(err)
      onError(err)
    }
  }

  // Fetch approvals
  const fetchApprovals = async (teamId) => {
    try {
      const res = await fetch_approvals(teamId)
      const arr = []
      for(let id in res) {
        const item = {...res[id]}
        item.id = id
        arr.push(item)
      }
      dispatch({
        type: GET_APPROVALS,
        payload: sortArrayOfObjects(arr, approvalsSort.value, approvalsSort.order),
      })
    }catch(err) {
      console.log(err)
    }
  }

  // Fetch approval by id
  const fetchApprovalById = async (id, teamId) => {
    try {
      const res = await fetch_approval_by_id(id, teamId)
      const arr = []
      for(let id in res) {
        const item = {...res[id]}
        item.id = id
        arr.push(item)
      }
      setApprovals(arr)
      return res[id]
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch other approvals, without the fetched one
  const fetchOtherApprovals = async (teamId) => {
    try {
      const res = await fetch_other_approvals(teamId, singleApprovalFetched)
      const arr = [...state.approvals]
      for(let id in res) {
        const item = {...res[id]}
        item.id = id
        arr.push(item)
      }
      dispatch({
        type: GET_APPROVALS,
        payload: sortArrayOfObjects(arr, approvalsSort.value, approvalsSort.order),
      })
    }catch(err) {
      console.log(err)
    }
  }

  // Delete approval
  const deleteApproval = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.approvals]
      await delete_approval(id)
      const updatedArr = [...items].filter(item => item.id !== id)
      setApprovals(updatedArr)
      onSuccess()
    } catch (err) {
      console.log(err)
      onError(err)
    }
  }

  // Update approval
  const updateApproval = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.approvals]
      await update_approval(id, data)
      const toUpdate = items.find(item => item.id === id)
      const updatedObj = {
        ...toUpdate,
        ...data
      }
      const updatedArr = [...items].map(item => item.id === id ? updatedObj : item)
      setApprovals(sortArrayOfObjects(updatedArr, approvalsSort.value, approvalsSort.order))
      onSuccess()
    }catch(err) {
      console.log(err)
      onError(err)
    }
  }

  // Delete multiple approvals 
  const deleteMultipleApprovals = (arr) => {
    const approvals = [...state.approvals] 
    let updatedArr = []
    approvals.forEach(approval => {
      if(!arr.includes(approval.id)) {
        updatedArr.push(approval)
      }
    })
    setApprovals(sortArrayOfObjects(updatedArr, approvalsSort.value, approvalsSort.order))
  }

  // Reset state
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
      payload: initialState
    })
  }

  // Set approvals
  const setApprovals = (arr) => {
    dispatch({
      type: SET_APPROVALS,
      payload: arr
    })
  }

  return (
    <ApprovalsContext.Provider
      value={{
        approvals: state.approvals,
        approvalsFetched: state.approvalsFetched,
        singleApprovalFetched,
        approvalsSort,
        createApproval,
        fetchApprovals,
        fetchApprovalById,
        fetchOtherApprovals,
        deleteApproval,
        updateApproval,
        setSingleApprovalFetched,
        deleteMultipleApprovals,
        setApprovalsSort,
        resetApprovalsState: resetState,
        approvalsSelectedFilters,
        setApprovalsSelectedFilters,
      }}
    >
      { children }
    </ApprovalsContext.Provider>
  )
}

export default ApprovalsState
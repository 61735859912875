import { get_request, baseUrl, post_request } from './firestore' 

const upgradeMembership = async (stripe, user, setUser, name, email, cardElement, paymentMethodId) => {

  // console.log('upgrade membership', { user, name, email, cardElement, paymentMethodId })
  try {
    let customer_id = user.stripe_customer_id
    if(!customer_id) {
      const customerResponse = await create_customer()
      if(customerResponse.error) {
        console.log("create customer error")
        return { error: customerResponse.error }
      }
      customer_id = customerResponse.customer_id
      setUser({
        ...user,
        stripe_customer_id: customer_id
      })
    }

    // todo use existing if present in user data

    if(cardElement) {

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email
        }
      });

      if (error) {
        console.log('create payment method error', error);
        return { error }
      }

      paymentMethodId = paymentMethod.id
    // } else {
    //   paymentMethodId = paymentMethodId// checkout.paymentMethod.id
    } else if(!paymentMethodId && !cardElement) {
      return { error: { message: 'Please enter a valid card' } }
    }
    
    const subscriptionResponse = await create_subscription({
      customer_id,
      product: 'carbon_premium',
      payment_method_id: paymentMethodId
    })
    console.log('create subscription response', subscriptionResponse)

    if(subscriptionResponse.status === 'incomplete' && subscriptionResponse.latest_invoice?.payment_intent?.status === 'requires_action') {
      console.log('requires action')
      const actionResponse = await handlePaymentThatRequiresCustomerAction(stripe, name, email, subscriptionResponse.latest_invoice.payment_intent)
      console.log(actionResponse)
      if(actionResponse.error) {
        subscriptionResponse.error = actionResponse.error
        // return { error: actionResponse.error }
      }
    }

    return subscriptionResponse
  } catch(error) {
    return { error }
  }
}

const handlePaymentThatRequiresCustomerAction = async (stripe, name, email, paymentIntent, cardElement) => {

  const { client_secret } = paymentIntent

  let paymentMethodId

  if(cardElement) {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        email
      }
    });

    if (error) {
      console.log('create payment method error', error);
      return { error }
    }

    paymentMethodId = paymentMethod.id
  }

  if(!paymentMethodId) {
    paymentMethodId = paymentIntent.payment_method
  }

  return await stripe.confirmCardPayment(client_secret, {
    payment_method: paymentMethodId,
    return_url: window.location.href // todo change to current url
  })
  // return await stripe.handleCardAction(client_secret)
}

const fetch_membership_plans = async () => {
  return await get_request(`${baseUrl}/membership_plans`)
}

const payInvoiceManually = async (invoice, stripe, user, setUser, name, email, cardElement, paymentMethodId) => {
  try {
    let customer_id = user.stripe_customer_id
    if(!customer_id) {
      const customerResponse = await create_customer()
      if(customerResponse.error) {
        console.log("create customer error")
        return { error: customerResponse.error }
      }
      customer_id = customerResponse.customer_id
      setUser({
        ...user,
        stripe_customer_id: customer_id
      })
    }

    // todo use existing if present in user data

    if(cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email
        }
      });

      if (error) {
        console.log('create payment method error', error);
        return { error }
      }

      paymentMethodId = paymentMethod.id
    // } else {
    //   paymentMethodId = paymentMethodId// checkout.paymentMethod.id
    } else if(!paymentMethodId && !cardElement) {
      return { error: { message: 'Please enter a valid card' } }
    }

    const paymentResponse = await pay_invoice({ invoice, payment_method_id: paymentMethodId })
    return paymentResponse
  } catch (error) {
    return { error }
  }
}

// update stripe subscription payment method with stripe and cardElement objects from @stripe/react-stripe-js
const updatePaymentMethod = async (stripe, user, name, email, cardElement) => {
  // console.log('update payment method', { user, name, email, cardElement })
  try {
    const customer_id = user.stripe_customer_id
    if(!customer_id) {
      console.error("No customer id, cannot update method")
      return { error: { code: 'no_customer_id', message: 'No customer ID for user' } }
    }
    if(!cardElement) {
      console.error("No card element, cannot update method")
      return { error: { code: 'no_card', message: 'Please enter a valid card' } }
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        email
      }
    })
    if(error) {
      console.error(error)
      return { error }
    } 
    const payment_method_id = paymentMethod.id
    const updateResponse = await update_payment_method({ customer_id, payment_method_id })
    return updateResponse
  } catch(err) {
    console.error(err)
    return
  }
}

const pay_invoice = async (data) => {
  return await post_request(`${baseUrl}/pay_invoice`, data)
}

const create_customer = async () => {
  return await post_request(`${baseUrl}/create_customer`)
}

const create_subscription = async (data) => {
  return await post_request(`${baseUrl}/create_subscription`, data)
}

const update_payment_method = async (data) => {
  return await post_request(`${baseUrl}/update_payment_method`, data)
}

const fetch_invoice_detail = async (invoice_id) => {
  return await get_request(`${baseUrl}/invoice_detail/${invoice_id}`)
}

const cancel_subscription = async (subscription_id) => {
  return await post_request(`${baseUrl}/cancel_subscription`, {
    subscription_id
  })
}

const create_payment_intent = async (data) => {
  return await post_request(`${baseUrl}/create_payment_intent`, data)
}

const fetch_upcoming_invoice = async (params) => {
  let url = `${baseUrl}/upcoming_invoice`
  if(params) {
    url += `?${new URLSearchParams(params)}`
  }
  return await get_request(url)
}

export {
  upgradeMembership,
  fetch_membership_plans,
  handlePaymentThatRequiresCustomerAction,
  fetch_invoice_detail,
  create_customer,
  create_subscription,
  cancel_subscription,
  create_payment_intent,
  fetch_upcoming_invoice,
  payInvoiceManually,
  updatePaymentMethod
}
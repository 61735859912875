import { useContext, useState, useEffect, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { VariablesSidebar, VariablesView } from '../components/misc'
import { GlobalContext, TeamContext, VariablesContext } from '../context'
import { sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'

const Variables = () => {
  const { t } = useContext(GlobalContext)
  const { teamChanged, setTeamChanged, selectedTeam } = useContext(TeamContext)
  const { fetchVariables, variablesFetched, varsCategoriesFetched, variables, varsSort, variablesSelectedFilters, setVarsSort } = useContext(VariablesContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [activeVariables, setActiveVariables] = useState([])
  const [currentVariables, setCurrentVariables] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedVariables, setSelectedVariables] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [paginatedData, setPaginatedData] = useState([])
  const [mainTitle, setMainTitle] = useState(t('general.all'))

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch variables and variable categories if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async () => {
      if(!variablesFetched) {
        fetchVariables(selectedTeam) // categories are also fetched in this function
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections()
    }
  }, [selectedTeam, fetchingCollections, variablesFetched, fetchVariables])

  // Set variables
  const setupVariables = useCallback(async () => {
    let active = [...variables]
    let sorted = await sortArrayOfObjects(active, varsSort.value, varsSort.order)
    setActiveVariables([...sorted])
    setCurrentVariables([...sorted])
    setReady(true)
  }, [variables, varsSort])

  // Setup variables when all data is fetched - only once, on component mount
  useEffect(() => {
    if(variablesFetched && varsCategoriesFetched && !ready) {
      setupVariables()
    }
  }, [variablesFetched, varsCategoriesFetched, setupVariables, ready])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
    setAllChecked(false)
    setSelectedVariables([])
  }, [])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeVariables]

    for(let key in variablesSelectedFilters) {
      if(key === 'search' && variablesSelectedFilters[key] !== '') {
        filtered = filtered.filter(v => v.variable.toLowerCase().includes(variablesSelectedFilters[key].trim().toLowerCase()))
      }
      if(key === 'category' && variablesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(v => v.category === variablesSelectedFilters[key])
      }
    }

    let sorted = sortArrayOfObjects(filtered, varsSort.value, varsSort.order)
    setCurrentVariables([...sorted])
  }, [activeVariables, variablesSelectedFilters, varsSort])

  // Filter templates when templatesSelectedFilters or templatesSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = varsSort.order === 'desc' ? 'asc' : 'desc'
    setVarsSort({ value: sort_by, order: currentOrder })
    if(isCookieAccepted()) {
      saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'variables')
    }
  }

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedVariables([])
    }else {
      setSelectedVariables(paginatedData)
    }
  }

  return (
    <MainLayout
      title={mainTitle}
      page="variables"
      showSidebarTrigger={true}
      sidebar={
        <VariablesSidebar 
          setMainTitle={setMainTitle}
        />
      }
    >
      <VariablesView 
        filteredVariables={currentVariables}
        onSort={handleSort}
        selectedVariables={selectedVariables}
        setSelectedVariables={setSelectedVariables}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        onAllCheckedChange={handleAllChecked}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        refreshVariables={refreshCollection}
        ready={ready}
      />
    </MainLayout>
  )
}

export default Variables
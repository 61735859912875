import React, { useState, useContext, useEffect } from 'react' 
import { useParams, Link } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'
import { ChevronLeft } from '@material-ui/icons'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext, GlobalContext } from '../../../context'

const WhatsNewArticle = ({ v2 = false }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { articles, articlesFetched } = useContext(HelpContext)
  const { id } = useParams()
  const [article, setArticle] = useState({})
  const [articleDoesNotExist, setArticleDoesNotExist] = useState(false)

  // Set article from id
  useEffect(() => {
    if(articlesFetched) {
      if(articles[id] && articles[id].category === 'whats_new') {
        setArticle(articles[id])
      }else {
        setArticleDoesNotExist(true)
      }
    }
  }, [id, articles, articlesFetched])

  return (
    <SupportPageLayout title={t('support.whats_new')}>
      {!articlesFetched 
        ? <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div> 
        : Object.keys(article).length > 0 && 
        <div className="help-article">
          <div className="help-article__head">
            <div className="link-wrapper">
              <Link to={"/what-is-new"}><ChevronLeft />{t('general.go_back')}</Link>
            </div>
            <h2>{article.title[selectedLang] || 'Missing translation'}</h2>
            {article.date && <p>{moment(article.date).format('DD MMM YYYY @ HH:mm')}</p>}
          </div>
          <div className="help-article__body">
            <div dangerouslySetInnerHTML={article.content[selectedLang] ? { __html: draftToHtml(article.content[selectedLang]) } : { __html: 'Missing translation' }}></div>
          </div>
        </div>
      }
      {articleDoesNotExist &&
        <div className="support-page__no-data">
          <p>{t('support.article_doesnt_exist', { id })}</p>
        </div>
      }
    </SupportPageLayout>
  )
}

export default WhatsNewArticle
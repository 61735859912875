import { useContext } from 'react'

import { GlobalContext } from '../../context'

const WidgetPlaceholderPeople = () => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="customize-panel-widget-placeholder">
      <div className="customize-panel-widget-placeholder__head">
        <p>{t('dashboard.people')}</p>
      </div>
      <div className="customize-panel-widget-placeholder__body">
        <ul className="people-placeholder">
          <li>
            <span className="material-symbols-outlined">account_circle</span>
            <div className="line"></div>
          </li>
          <li>
            <span className="material-symbols-outlined">account_circle</span>
            <div className="line"></div>
          </li>
          <li>
            <span className="material-symbols-outlined">account_circle</span>
            <div className="line"></div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default WidgetPlaceholderPeople
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import ResponseLoader from '../../UI/ResponseLoader';
import { DocumentsContext, NotificationContext, GlobalContext } from '../../../context';
import { upload_file, create_document, upload_file_with_progress } from '../../../services/firestore';
import { TeamContext } from '../../../context';
import { getFileData } from '../../../helpers/files'

const UploadDocumentsModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { updateMultipleDocuments } = useContext(DocumentsContext);
  const { setNotification } = useContext(NotificationContext);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0)
  const history = useHistory();
  const dropEl = useRef();
  const fileInput = useRef();

  const allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  const formatFromType = {
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx'
  }

  // On file input change or on drop
  const changeHandler = async (e) => {
    const files = e.target.files || e.dataTransfer.files;
    let error = false;
    for(let i = 0; i < files.length; i++) {
      if(!allowedFileTypes.includes(files[i].type)) {
      // if(!files[i].name.includes('pdf') && !files[i].name.includes('docx')) {
        error = true;
        break;
      }
    }

    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type'), type: 'danger' });
    }

    // If only 1 file
    if(files.length === 1) {
      const file = files[0];
      
      setUploading(true);
      
      // Upload file
      const data = await getFileData(file);
      const fileName = `${data.name}-${Date.now()}`;
      let docData = {
        name: file.name.split('.')[0],
        folderId: [],
        status: 'empty',
        uploaded: true,
        values: {},
        filePath: `documents/${fileName}.${data.format}`,
        uploadedFormat: data.format,
        team: selectedTeam.id,
        size: data.size
      };
      const fileUrlRes = await upload_file_with_progress(file, fileName, data.format, data.type, 'documents', (p) => {
        setProgress(Math.floor(p))
      });
      if(data.format === 'pdf') {
        docData.documentUrls = { pdf: fileUrlRes.url }
      }else {
        docData.documentUrls = { docx: fileUrlRes.url }
      }
      
      // Save document
      let response = await create_document(docData, []);
      
      // Redirect to document-detail
      if(response.success) {
        setUploading(false);
        // Update documents
        const newDoc = {...response.docData, owner: activeTeamMember.id };
        updateMultipleDocuments([{ data: newDoc, id: response.documentId}]);
        history.push(`/document-detail/${response.documentId}`);
      }
      
    }else { // if multiple files
      const filesArr = Array.from(files);
      const addedFiles = [];
      const promises = [];
      setUploading(true)

      for(let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i];
        promises.push(getFileData(file));
      }

      let mProgress = 0
      let total = 0
      
      // Upload file and create document in db
      Promise.all(promises).then(async data => {
        for(let i = 0; i < data.length; i++) {
          const fileName = `${data[i].name}-${Date.now()}`;
          const file = filesArr[i]
          let docData = {
            name: data[i].name,
            folderId: [],
            status: 'empty',
            uploaded: true,
            values: {},
            filePath: `documents/${fileName}.${data[i].format}`,
            uploadedFormat: data[i].format,
            team: selectedTeam.id,
            size: data[i].size
          };
          // eslint-disable-next-line no-loop-func
          const fileUrl = await upload_file_with_progress(file, fileName, data[i].format, data[i].type, 'documents', (p) => {
            let current = (Math.floor(p) / 100) * (100 / data.length)
            mProgress = total + current
            if(current >= Math.floor(100 / data.length)) {
              total += current
            }
            setProgress(Math.floor(mProgress))
          });
          if(data[i].format === 'pdf') {
            docData.documentUrls = { pdf: fileUrl.url }
          }else {
            docData.documentUrls = { docx: fileUrl.url }
          }
          let response = await create_document(docData, []);

          if(response.success) {
            const newDoc = {...response.docData, owner: activeTeamMember.id};
            addedFiles.push({ data: newDoc, id: response.documentId });
          }
        }

        setUploading(false);
        updateMultipleDocuments(addedFiles)
        onClose()
        setNotification({ msg: t('notification.num_files_uploaded', { number: addedFiles.length}), type: 'default' });
      });
    }
  }

  // On drag enter
  const dragEnterHandler = (e) => {
    e.preventDefault();
    dropEl.current.classList.add('active');
  }
  
  // On drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    dropEl.current.classList.remove('active');
  }
  
  // On drag over
  const dragOverHandler = (e) => {
    e.preventDefault();
  }
  
  // On drop
  const dropHandler = (e) => {
    e.preventDefault();
    changeHandler(e);
    dropEl.current.classList.remove('active');
  }

  return (
    <Modal onClose={onClose} medium2>
      <div className="v2-modal">
        <div className="v2-modal__head">
          <h5>{t('general.download')}</h5>
        </div>
        <div className="v2-modal__body">
          <div className="upload-or-drop"
            onDragEnter={dragEnterHandler}
            onDragLeave={dragLeaveHandler}
            onDragOver={dragOverHandler}
            onDrop={dropHandler}
            ref={dropEl}
          >
            <p className="upload-or-drop__text-1">{t('general.upload_here_3')}</p>
            <div className="upload-or-drop__or">
              <div className="line"></div>
              <p>{t('general.or')}</p>
              <div className="line"></div>
            </div>
            <div className="btn-wrapper">
              <Button 
                text={t('general.select_files')}
                icon={<i className="custom-icon-upload"></i>} 
                onButtonClick={() => fileInput.current.click()}
                medium 
                primary 
              />
              <input type="file" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={changeHandler} className="hidden" ref={fileInput} multiple />
            </div>
            <p className="upload-or-drop__note">{t('general.uploaded_to_app')}</p>
          </div>
        </div>
      </div>

      {uploading && <ResponseLoader progress={progress} />}
    </Modal>
  );
}

export default UploadDocumentsModal;
import { useContext, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { SettingsLayout } from '../layouts'
import { Input } from '../components/new_ui'
import { GlobalContext, NotificationContext, TeamContext, UserContext } from '../context'
import { getFileData } from '../utils'
import { validateEmail } from '../helpers/validate'
import { useTeamActions } from '../hooks'

const AddTeam = () => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { createNewTeam, inviteUsersAfterTeamCreation } = useTeamActions()
  const [teamName, setTeamName] = useState('')
  const [teamNameError, setTeamNameError] = useState('')
  const [teamLogoUrl, setTeamLogoUrl] = useState('')
  const [teamLogoFile, setTeamLogoFile] = useState(null)
  const [emails, setEmails] = useState([
    { name: 'add-email-1', value: '', placeholder: t('general.email_placeholder'), error: '' },
    { name: 'add-email-2', value: '', placeholder: t('general.email_placeholder'), error: '' },
    { name: 'add-email-3', value: '', placeholder: t('general.email_placeholder'), error: '' },
  ])
  const [teamStep, setTeamStep] = useState(1)
  const fileInput = useRef()
  const history = useHistory()

  // File change handler 
  const handleFileChange = (e) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/gif']
    const file = e.target.files[0]
    if(file) {
      if(!allowedTypes.includes(file.type)) {
        return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      }
      getFileData(file, uploadImage)
      const url = URL.createObjectURL(file)
      setTeamLogoUrl(url)
    }
  }

  // Upload image
  const uploadImage = async (image) => {
    setTeamLogoFile(image)
  }

  // On name change
  const handleNameChange = (e) => {
    setTeamName(e.target.value)
    if(teamNameError) {
      setTeamNameError('')
    }
  }

  // Remove image 
  const handleRemoveImage = async (e) => {
    e.preventDefault()
    setTeamLogoUrl('')
    setTeamLogoFile(null)
  }

  // On email change
  const handleEmailChange = (e) => {
    const { name, value } = e.target
    setEmails(prev => {
      const current = prev.find(em => em.name === name)
      current.value = value
      if(!validateEmail(value.trim()) && current.value !== '') {
        current.error = t('notification.invalid_email')
      }else {
        if(current.error !== '') {
          current.error = ''
        }
      }
      return prev.map(em => em.name === name ? current : em)
    })
  }

  // Send later
  const handleSendLaterClick = (e) => {
    e.preventDefault()
    history.push('/settings/team')
  }

  // On step 1 submit
  const handleSubmitStepOne = async (e) => {
    e.preventDefault()
    createNewTeam({ teamName, setTeamNameError, teamLogoFile, setTeamStep })
  }

  // On step 2 submit
  const handleSubmitStepTwo = async (e) => {
    e.preventDefault()
    inviteUsersAfterTeamCreation({ emails })
  }

  // Render content - add mode step 1
  const renderAddTeamStepOne = () => {
    return (
      <div className=" team-add--step-1">
        <form className="profile-form form" onSubmit={handleSubmitStepOne}>
          <Input 
            value={teamName} 
            onChange={handleNameChange} 
            label={t('team.name_field_label')}  
            formEl
            error={teamNameError}
          />
          <p className="form__label">{t('general.logo')}</p>
          <div className="form__group form__group--image">
            <div className="image-box image-box--sm"
              style={teamLogoUrl ? { backgroundImage: `url(${teamLogoUrl})`} : {}}
            ></div>
            <div className="actions">
              <input type="file" accept="image/png,image/jpg,image/jpeg" onChange={handleFileChange} className="hidden" ref={fileInput} />
              <button type="button" className="btn btn--small btn--outline" onClick={() => fileInput.current.click()}>{t('general.upload_image')}</button>
              {teamLogoUrl && <a href="#/" onClick={(e) => handleRemoveImage(e)}>{t('general.delete_image')}</a>}
            </div>
          </div>
          <button type="submit" className="btn submit-btn">{t('general.save')}</button>
        </form>
      </div>
    )
  }

  // Render content - add mode step 2
  const renderAddTeamStepTwo = () => {
    return (
      <div className="profile-form team-add--step-2">
        <h2 className="heading-2 team__subtitle">{t('team.invite_to_team', { name: selectedTeam?.name })}</h2>
        <form className="form" onSubmit={handleSubmitStepTwo}>
          {emails.map((email, i) => (
            <div className="team-add__email-wrapper" key={i}>
              <Input 
                name={email.name} 
                value={email.value} 
                placeholder={email.placeholder} 
                error={email.error} 
                onChange={handleEmailChange}
                formEl
              />
            </div>
          ))}
          <div className="actions">
            <button type="submit" className="btn">{t('team.send_invitation')}</button>
            <a href="#/" className="send-later-link" onClick={handleSendLaterClick}>{t('team.send_later')}</a>
          </div>
        </form>
      </div>
    )
  }

  // Render content - add mode
  const renderAddTeamContent = () => {
    if(teamStep === 2) {
      return renderAddTeamStepTwo();
    }
    return renderAddTeamStepOne();
  }

  return (
    <SettingsLayout title={t('team.title')}>
      {user && Object.keys(user).length > 0 
        ? renderAddTeamContent() 
        : <div className="loader-v2"></div>
      }
    </SettingsLayout>
  )
}

export default AddTeam
import { useContext } from 'react'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { LoaderContext } from '../context'
import { generate_document_for_shared_templates, convert_attachment } from '../services/lawstudioApi'
import { get_file_base64_data } from '../services/functions'
import { base64toBlob } from '../utils'

const useSharedDocsActions = () => {
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)

  // Download
  const downloadSharedDocs = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Preparing documents for download')

    try {
      if(selectedDocuments.length === 1) { // if only one document/folder selected
        const doc = selectedDocuments[0] 
        if(doc.doc_uploaded) {
          let href = '';
          let ext = '';
          if(doc.doc_format === 'pdf') {
            const res = await get_file_base64_data({ url: doc.templateUrl }); 
            href = res.data;
            ext = 'pdf';
          }else if(doc.doc_format === 'docx') {
            const res = await convert_attachment(doc.templateUrl); 
            href = res.data;
            ext = 'docx';
          }
          if(href) {
            if(isIOS) {
              const blob = base64toBlob(href)
              const a = document.createElement('a')
              a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
            }else {
              const a = document.createElement("a")
              a.href = `data:application/pdf;base64,${href}`
              a.download = `${doc.name || 'document'}.${ext}`
              a.click()
            }
          }
        }else {
          const res = await generate_document_for_shared_templates(doc.templateUrl, doc.values || {}, 'pdf', doc.content_editable ? doc.content_changes || [] : [])
          const documentData = res.data
          if(isIOS) {
            const blob = base64toBlob(documentData)
            const a = document.createElement('a')
            a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
          }else {
            const a = document.createElement("a")
            a.href = `data:application/pdf;base64,${documentData}` 
            a.download = `${doc.name || 'document'}.pdf`
            a.click()
          } 
        }
      }else { // if multiple documents/folders selected
        const zip = new JSZip()

        for(let i in selectedDocuments) {
          const doc = selectedDocuments[i]
          let name = `${doc.name}.pdf`
          if(doc.doc_uploaded) { // if document was uploaded
            let href = ''
            let ext = ''
            if(doc.doc_format === 'pdf') {
              const res = await get_file_base64_data({ url: doc.templateUrl })
              href = res.data
              ext = 'pdf'
            }else if(doc.documentUrls?.docx) {
              const res = await convert_attachment(doc.templateUrl)
              href = res.data
              ext = 'docx'
            }
            if(href) {
              name = `${doc.name}_${doc.id}.${ext}`
              zip.file(`${name}`, href, {base64: true})
            }
          }else { // if document was created from template
            const res = await generate_document_for_shared_templates(doc.templateUrl, doc.values || {}, 'pdf', doc.content_editable ? doc.content_changes || [] : [])
            const documentData = res.data
            zip.file(`${doc.name}_${doc.id}.pdf`, documentData, {base64: true})
          }
        }

        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, "documents.zip")
        })

      }

      setSelectedDocuments([])

    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  return {
    downloadSharedDocs,
  }
}

export default useSharedDocsActions
import React, { memo } from 'react';
import DocumentSection from './DocumentSection';


const DocumentDetailVariables = ({ documentValues, onValuesChange, templateData, type = '', onSetEditStartTime, onStoreEditTime, onContactsModalOpen, makeContentEditable, onLinkVarsWithProfileData, onSectionContentChanged, documentCheckboxValues, onCheckboxValuesChange }) => {

  if(!templateData) {
    return null
  }

  return(
    <div className="document-detail-variables">

      <div className="document-detail-variables__body">
        { templateData.sections.map((section, sectionIndex) => {
          return <DocumentSection
            key={sectionIndex}
            values={documentValues}
            onValuesChange={onValuesChange}
            checkboxValues={documentCheckboxValues}
            onCheckboxValuesChange={onCheckboxValuesChange}
            sections={section.sections}
            type={section.type}
            content={section.content}
            items={section.items}
            question={section.question}
            variable={section.variable}
            variables={section.variables}
            data_type={section.data_type}
            options={section.options}
            condition={section.condition}
            conditions={section.conditions}
            tooltip={section.tooltip}
            index={sectionIndex}
            varIndex={section.idx}
            repeatableSectionId={section.repeatable_section_id}
            onSetEditStartTime={onSetEditStartTime}
            onStoreEditTime={onStoreEditTime} 
            onContactsModalOpen={onContactsModalOpen}
            makeContentEditable={makeContentEditable}
            onLinkVarsWithProfileData={onLinkVarsWithProfileData}
            onSectionContentChanged={onSectionContentChanged}
            head={section.head}
            row={section.row}
            repeatableRowId={section.repeatableRowId}
          />
        })}
      
      </div>
      {/* {templateData.footer && <div className="document-detail-variables__foot">
        <h4><span>{ t('document_detail.footer') }</span></h4>
        <DocumentSection values={documentValues} onValuesChange={onValuesChange} documentCheckboxValues={documentCheckboxValues} onCheckboxValueChange={onCheckboxValuesChange} sections={[templateData.footer]} type={templateData.footer.type || 'text'} content={templateData.footer.content} items={templateData.footer.items || []} question={templateData.footer.question} variable={templateData.footer.variable} variables={templateData.footer.variables} data_type={templateData.footer.data_type} options={templateData.footer.options} condition={templateData.footer.condition} conditions={templateData.footer.conditions} tooltip={templateData.footer.tooltip}  locked={templateData.footer.locked} index={'footer'} varIndex={''} repeatableSectionId={templateData.footer.repeatable_section_id} />
      </div>} */}
    </div>
  );
}

export default memo(DocumentDetailVariables);
import { useContext, useState, useEffect } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import ActionsDropdown from '../UI/ActionsDropdown'
import LetterCircle from '../UI/LetterCircle'
import { Input } from '.'
import { GlobalContext, TeamContext } from '../../context'

const MemberSelect = ({ label, chooseText = '', value, onChange }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const [filteredMembers, setFilteredMembers] = useState([])
  const [members, setMembers] = useState([])
  const [taskAssignedTo, setTaskAssignedTo] = useState(null)
  const [search, setSearch] = useState('')

  // Add users to members arrays 
  useEffect(() => {
    if(selectedTeam) {
      const activeMembers = selectedTeam.users.filter(u => u.status === 'active')
      setMembers(activeMembers)
      setFilteredMembers(activeMembers)
    }
  }, [selectedTeam])

  // Set selected member if value is set
  useEffect(() => {
    if(value) {
      const selected = selectedTeam.users.find(u => u.id === value)
      if(selected) {
        setTaskAssignedTo(selected)
      }
    }
  }, [value, selectedTeam])

  // On memmber select
  const handleAssignTo = (e, m) => {
    e.preventDefault()
    setTaskAssignedTo(m)
    onChange(m.id)
    window.document.body.click()
    console.log(m.id)
  }

  // On search change
  const handleSearchChange = (e) => {
    const val = e.target.value
    setSearch(val)
    const newMembers = members.filter((m) => {
      const firstName = m.first_name || ''
      const lastName = m.last_name || ''
      const name = firstName + ' ' + lastName
      const email = m.email
      if(name.toLowerCase().includes(val.trim().toLowerCase()) || email.toLowerCase().includes(val.trim().toLowerCase())) {
        return m
      }else {
        return null
      }
    })
    setFilteredMembers(newMembers)
  }

  // Render member
  const renderMember = () => {
    let name = ''
    if(taskAssignedTo.first_name) {
      name += taskAssignedTo.first_name
    }
    if(taskAssignedTo.last_name) {
      name += ` ${taskAssignedTo.last_name}`
    }
    if(!name) {
      name = taskAssignedTo.email
    }

    return (
      <>
        <div className="user-thumb" style={taskAssignedTo.user_image ? {backgroundImage: `url(${taskAssignedTo.user_image})`} : {}}>
          {!taskAssignedTo.user_image && <LetterCircle data={name} hideTooltip />}
        </div>
        <div className="user-name u-truncate-text">{name}</div>
      </>
    )
  }

  return (
    <div className="member-select">
      {label && <div className="member-select__label">{label}</div>}
      <ActionsDropdown
        trigger={
          <div className="member-select__selected u-truncate-text-wrapper">
            {!taskAssignedTo ? (
              <p className="u-truncate-text">{chooseText || t('general.choose_team_member')}</p>
            ) : (
              <div className="member-select__selected_member u-truncate-text-wrapper">
                {renderMember()}
              </div>
            )}
          </div>
        }
        hideHeader
        width={300}
        dropdownClass="actions-dropdown-v2 member-select-dropdown"
      >
        <div className="search-wrapper">
          <Input value={search} onChange={handleSearchChange} />
        </div>
        <ul className="default-list u-custom-scrollbar">
          {filteredMembers.map((user, idx) => {
            let name = ''
            if(user.first_name) {
              name += user.first_name
            }
            if(user.last_name) {
              name += ` ${user.last_name}`
            }
            if(!name) {
              name = user.email
            }
            return (
              <li key={idx}>
                <a href="/#" onClick={(e) => handleAssignTo(e, user)}>
                  <div className="user-thumb" style={user.user_image ? {backgroundImage: `url(${user.user_image})`} : {}}>
                    {!user.user_image && <LetterCircle data={name} hideTooltip />}
                  </div>
                  <div className="user-name u-truncate-text">{name}</div>
                  {taskAssignedTo && taskAssignedTo.id === user.id && <span className="material-symbols-outlined size-24">done</span>}
                </a>
              </li>
            )
          })}
        </ul>
      </ActionsDropdown>
    </div>
  )
}

export default MemberSelect
import { useContext } from 'react'

import { ContactsContext, LoaderContext, GlobalContext, NotificationContext, TeamContext } from '../context'
import { create_contact, update_contact, delete_contact } from '../services/contacts'
import { validateEmail } from '../helpers/validate'

const useContactsActions = () => {
  const { contacts, deleteMultipleContacts, fetchContacts } = useContext(ContactsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useContext(GlobalContext)
  const { selectedTeam, updateTeam } = useContext(TeamContext)

  // Filter contacts by group
  const filterContactsByGroup = ({ group, setSelectedTitle, setSelectedContacts, setFilteredContacts }) => {
    const c = [...contacts]
    
    const filtered = []

    let idx = 1
    c.forEach((contact) => {
      if(contact.group === group.id) {
        filtered.push({...contact, index: idx})
        idx++
      }
    })

    setSelectedTitle(group.name)
    setFilteredContacts(filtered)
    setSelectedContacts([])
  }

  // Delete contacts
  const deleteContacts = async ({ selectedContacts, setShowDeleteContactsAlert, setSelectedContacts }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Deleting contacts')
    
    let promises = []
    let contactsToDelete = []
    for(let i in selectedContacts) {
      const id = selectedContacts[i].id
      // update before delete to show correct author in action - this update won't be shown in actions
      // await update_contact({ deleting: true, last_modified_by: activeTeamMember.id }, selectedContacts[i].id);
      promises.push(delete_contact(id))
      contactsToDelete.push(id)
    }

    let arr = []
    if(promises.length > 0) {
      const data = await Promise.all(promises)
      data.forEach((c, i) => {
        if(c && c.success) {
          arr.push(contactsToDelete[i])
        }
      })
    }
    if(arr.length > 0) {
      deleteMultipleContacts(arr)
    }
    
    setShowDeleteContactsAlert(false)
    setSelectedContacts([])
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Import contacts
  const importContacts = async ({ contactsToCreate, contactsToUpdate, linkedVariables, group, customColumnsToSave, mappedFields, onClose, refreshContacts }) => {
    let promises = []

    // limit number of contacts that can be created
    let combinedArr = [...contactsToCreate, ...Object.keys(contactsToUpdate)]
    if(combinedArr.length > 10) {
      return setNotification({ msg: t('contacts.create_update_limit', { number: 10 }), type: 'info' })
    }

    // validate fields(email etc.)
    let emailValid = true
    for(let i = 0; i < contactsToCreate.length; i++) {
      const c = contactsToCreate[i]
      if(c.hasOwnProperty('email') && !validateEmail(c.email)) {
        emailValid = false 
        break
      }
    }
    if(!emailValid) {
      let msg = contactsToCreate.length === 1 ? t('contacts.invalid_email') : t('contacts.invalid_email_2')
      return setNotification({ msg, type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('contacts.importing'))

    // add create_contact function to promises array
    contactsToCreate.forEach((c) => {
      promises.push(create_contact({...c, meta: { created: Date.now(), updated: Date.now() }}))
    })

    // add update_contact function to promises array
    if(Object.keys(contactsToUpdate).length > 0) {
      for(let id in contactsToUpdate) {
        let contact = contactsToUpdate[id]
        if(contact.meta) contact.meta = {...contact.meta, updated: Date.now()}
        else contact.meta = { created: Date.now(), updated: Date.now() }
        promises.push(update_contact(contact, id))
      }
    }

    try {
      // save contacts to db and fetch them
      await Promise.all(promises)
      await fetchContacts(selectedTeam.id)
  
      let teamObj = {}
      // save linked variables for contact group to teams collection of selected team document
      if(Object.keys(linkedVariables).length > 0) {
        let linkedVars
        if(selectedTeam.contacts_linked_variables) {
          linkedVars = JSON.parse(JSON.stringify(selectedTeam.contacts_linked_variables))
          if(linkedVars[group]) {
            linkedVars[group] = {...linkedVars[group], ...linkedVariables}
          }else {
            linkedVars[group] = linkedVariables
          }
        }else {
          linkedVars = { [group]: linkedVariables }
        }
        teamObj.contacts_linked_variables = linkedVars
      }
      // check if custom column labels should be saved
      if(Object.keys(customColumnsToSave).length > 0) {
        let cols = {}
        for(let col in customColumnsToSave) {
          if(mappedFields.find(f => f.key === col)) {
            cols[col] = customColumnsToSave[col]
          }
        }
        if(selectedTeam.contacts_custom_columns) {
          teamObj.contacts_custom_columns = {...selectedTeam.contacts_custom_columns, ...cols}
        }else {
          teamObj.contacts_custom_columns = cols
        }
      }
      // check if team should be updated
      if(Object.keys(teamObj).length > 0) {
        // console.log(teamObj)
        await updateTeam(teamObj, selectedTeam.id, true)
      }
      setNotification({ msg: t('contacts.imported_successfully'), type: 'success' })
      refreshContacts && refreshContacts()
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
      onClose()
    }
  }

  return {
    filterContactsByGroup,
    deleteContacts,
    importContacts,
  }
}

export default useContactsActions
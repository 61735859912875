import { useRef, useEffect } from 'react'

const SidePanel = ({ show, onClose, children, title, icon, afterClose }) => {
  const panelEl = useRef()
  const panelInnerEl = useRef()

  // when panel is visible 
  useEffect(() => {
    if(show) {
      document.body.style.overflow = 'hidden'
      panelEl.current.style.zIndex = 1005
      panelInnerEl.current.scrollTop = 0
    }
  }, [show])

  // On panel close
  const handlePanelClose = () => {
    onClose()
    setTimeout(() => {
      document.body.style.overflow = null
      panelEl.current.style.zIndex = -1
      afterClose && afterClose()
    }, 300)
  }

  return (
    <div className={`side-panel-v2 ${show ? 'active' : ''}`} ref={panelEl}>
      <div className="side-panel-v2__overlay" onClick={handlePanelClose}></div>
      <div className="side-panel-v2__inner" ref={panelInnerEl}>
        {title && <div className="side-panel-v2__inner_head">
          {icon && icon}
          <h5 className="heading-5">{title}</h5>
        </div>}
        <div className="side-panel-v2__inner_body">
          {children}
        </div>
      </div>
    </div>
  );
}

export default SidePanel
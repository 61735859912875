import { useState, useEffect, useContext, memo } from 'react'
import moment from 'moment'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { CreateEditContactModal } from '../'
import { Alert } from '../../new_ui'
import { GlobalContext, ContactsContext } from '../../../context'
import { useSingleContactActions } from '../../../hooks'

const ContactsRow = ({ doc, id, selectedContacts, setSelectedContacts, allChecked, columns, refreshContacts }) => {
  const { t } = useContext(GlobalContext)
  const { contacts } = useContext(ContactsContext)
  const { deleteSingleContact } = useSingleContactActions()
  const [checked, setChecked] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedContacts.length === 0) {
      setChecked(false)
    }
  }, [selectedContacts, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked, doc])

  // On select
  const handleSelect = () => {
    setChecked(!checked)
    if(checked) {
      setSelectedContacts(prev => prev.filter(d => d.id !== id))
    }else {
      setSelectedContacts(prev => [...prev, doc])
    }
  }

  // On edit click
  const handleEditClick = (e) => {
    e.preventDefault()
    setShowEditModal(true)
  }

  // On edit modal close
  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  // On delete click
  const handleDeleteClick = async (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteSingleContact({ contact: doc, refreshContacts })
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // Render checkbox column
  const renderCheckboxColumn = () => {
    return (
      <div className={`list-view-table__cell checkbox`}>
        {!checked 
          ? <span className="material-symbols-outlined checkbox-icon" onClick={handleSelect}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled checkbox-icon" onClick={handleSelect}>check_box</span>
        }
        <span className="material-symbols-outlined row-icon">description</span>
      </div>
    )
  }

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined">more_horiz</span></button>}
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/" onClick={handleEditClick}>
              <span className="icon"><span className="material-symbols-outlined">edit</span></span>
              {t('general.edit')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleDeleteClick} className="delete">
              <span className="icon"><span className="material-symbols-outlined">delete</span></span>
              {t('general.remove')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render category column
  const renderFirstNameColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div 
        className={`list-view-table__cell u-truncate-text-wrapper title-col`} 
        key={i}
      >
        <p className={`text-style-1 u-truncate-text`}>{content}</p>
        <div className="show-on-hover">
          {renderSingleDocActions()}
        </div>
      </div>
    )
  }

  // Render date column
  const renderDateColumn = (col, i) => {
    let content = null 
    let value = doc[col.value]
    if(value) {
      content = typeof value === 'string' ? value : moment(value).format('DD/MM/YYYY')
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper date-col" key={i}>
        <div className="text-smaller u-truncate-text">{content}</div>
      </div>  
    )
  }

  // Render custom column
  const renderCustomColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text">{content}</div>
      </div>
    )
  }

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell helper" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'first_name') {
      content = renderFirstNameColumn(col, i)
    }else if(col.value === 'dob') {
      content = renderDateColumn(col, i)
    }else if(col.value === 'helper') {
      content = renderHelperColumn(col, i)
    }else {
      content = renderCustomColumn(col, i)
    }

    return content
  }

  return (
    <>
      <div 
        className={`list-view-table__row ${checked ? 'selected' : ''}`}
      >
        {columns.filter(c => c.checked).map((col, i) => {
          return renderColumnContent(col, i)
        })} 
        {renderCheckboxColumn()}   
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_this_contact')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}

      {showEditModal && (
        <CreateEditContactModal 
          onClose={handleCloseEditModal}
          mode="edit"
          contactToEdit={doc}
          refreshContacts={refreshContacts}
        />
      )}
    </>
  )
}

export default memo(ContactsRow)
import { useContext, useState } from 'react'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import { Alert } from '../../new_ui'
import { CreateEditContactGroupModal } from '../'
import { GlobalContext, ContactGroupsContext, ContactsContext } from '../../../context'
import { useContactsGroupsActions } from '../../../hooks'

const ContactsSidebar = ({ setMainTitle, refreshContacts }) => {
  const { t } = useContext(GlobalContext)
  const { contactsSelectedFilters, setContactsSelectedFilters } = useContext(ContactsContext)
  const { contactGroups } = useContext(ContactGroupsContext)
  const { deleteGroup } = useContactsGroupsActions()
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false)
  const [showEditGroupModal, setShowEditGroupModal] = useState(false)
  const [groupToEdit, setGroupToEdit] = useState(null)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [groupToDelete, setGroupToDelete] = useState(null)

  // On open create group modal
  const handleOpenCreateGroupModal = () => {
    setShowCreateGroupModal(true)
  }

  // On close create group modal
  const handleCloseCreateGroupModal = () => {
    setShowCreateGroupModal(false)
  }

  // On category filter
  const handleGroupChange = (group) => {
    setContactsSelectedFilters(prev => ({...prev, group: group.id}))
    setMainTitle(group.name)
  }

  // On edit click
  const handleEditClick = (e, group) => {
    e.preventDefault()
    setGroupToEdit(group)
    setShowEditGroupModal(true)
  }

  // On edit modal close
  const handleCloseEditGroupModal = () => {
    setGroupToEdit(null)
    setShowEditGroupModal(false)
  }

  // On delete click
  const handleDeleteClick = async (e, group) => {
    e.preventDefault()
    setGroupToDelete(group)
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
    setGroupToDelete(null)
  }

  // On delete
  const handleDelete = async () => {
    await deleteGroup({ groupToDelete, activeGroup: '', setActiveGroup: () => {}, setSelectedTitle: setMainTitle, setShowDeleteGroupAlert: setShowDeleteAlert, refreshContacts })
  }

  return (
    <div className="collection-sidebar">
      <h1 className="heading-1">{t('general.contacts')}</h1>

      <section className="folders">
        <h5 className="heading-5 with-action">
          {t('contacts.groups')}
          <CustomTooltip content={t('contacts.create_group')}>
            <div className="icon" onClick={handleOpenCreateGroupModal}><span className="material-symbols-outlined">group_add</span></div>
          </CustomTooltip>
        </h5>
        <ul className="filters-list">
          {contactGroups.map((group, idx) => (
            <li key={idx} className={`filters-list__item ${contactsSelectedFilters.group === group.id ? 'active' : ''}`}>
              <div className="item-inner">
                <p onClick={() => handleGroupChange(group)}><span className="name">{group.name}</span></p> 
                <div className="icon-right">
                  <ActionsDropdown
                    trigger={<span className="material-symbols-outlined">more_horiz</span>}
                    hideHeader
                    dropdownClass="actions-dropdown-v2"
                    width={150}
                  >
                    <ul className="default-list default-list--small">
                      <li>
                        <a href="/" onClick={(e) => handleEditClick(e, group)}>
                          <span className="icon"><span className="material-symbols-outlined">edit</span></span>
                          {t('general.edit')}
                        </a>
                      </li>
                      <li>
                        <a href="/" onClick={(e) => handleDeleteClick(e, group)} className="delete">
                          <span className="icon"><span className="material-symbols-outlined">delete</span></span>
                          {t('general.remove')}
                        </a>
                      </li>
                    </ul>
                  </ActionsDropdown>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>

      {showCreateGroupModal && (
        <CreateEditContactGroupModal 
          onClose={handleCloseCreateGroupModal} 
          setMainTitle={setMainTitle}
          refreshContacts={refreshContacts}
        />
      )}

      {showEditGroupModal && (
        <CreateEditContactGroupModal 
          onClose={handleCloseEditGroupModal} 
          setMainTitle={setMainTitle}
          refreshContacts={refreshContacts}
          mode="edit"
          selectedGroup={groupToEdit}
        />
      )}

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert}
          text={t('contacts.delete_this_group')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
    </div>
  )
}


export default ContactsSidebar
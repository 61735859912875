import { useContext } from 'react'
import { LinearProgress } from '@material-ui/core'

import { UserContext } from '../../../context'

const PlaceholderWidget = ({ title, widget }) => {
  const { widgetsInfo } = useContext(UserContext)

  return (
    <div className={`widget ${widgetsInfo[widget].size === 'half' ? 'widget--half' : ''}`}>
      <div className="widget__head">
        <h4 className="heading-4">{title}</h4>
      </div>
      <div className="widget__body widget__body--placeholders">
        <LinearProgress className="widget__body_placeholder" />
        <LinearProgress className="widget__body_placeholder widget__body_placeholder--2" />
        <LinearProgress className="widget__body_placeholder widget__body_placeholder--3" />
      </div>
    </div>
  )
}

export default PlaceholderWidget
import React, { useEffect, useContext, useCallback } from 'react';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import useForm from '../../../hooks/useForm';
import { GlobalContext } from '../../../context'

const EditAttachmentModal = ({ onClose, onEdit, attachment, loading, attachmentIndex, onSetEditStartTime, onStoreEditTime }) => {
  const { t, validate } = useContext(GlobalContext)
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      name: {
        ...prev.name,
        value: attachment.name
      }
    }));
    // eslint-disable-next-line
  }, [attachment]);

  const submitHandler = useCallback((e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      onEdit(formData.name.value.trim(), attachmentIndex);
    }
  }, [attachmentIndex, formData, onEdit, setErrors, validate])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        submitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [submitHandler])

  return(
    <Modal onClose={onClose} small>
      <div className="folder-modal">
        <h2>{t('dashboard.modify_attachment')}</h2>
        <form onSubmit={submitHandler} className="form">
          <Input 
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            placeholder={t('dashboard.attachment_name')}
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
          />
          <div className="form__actions">
            <Button text={t('general.cancel')} transparent onButtonClick={onClose} />
            <Button 
              type="submit" 
              text={loading ? t('dashboard.updating') : t('general.save')} 
              primary 
              disabled={loading} 
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default EditAttachmentModal;
import { useEffect, useState, useContext } from 'react'

import { Modal } from '../new_ui'
import { CreateEditFolderModal } from '.'
import { getAllParentFolders, getFirstLevelSubfolders } from '../../utils'
import { GlobalContext } from '../../context'

const MoveSelectedItemsToFolderModal = ({ onClose, folders, items, currentFolder, loading, onMove, view = 'documents' }) => {
  const { t } = useContext(GlobalContext)
  const [activeFolder, setActiveFolder] = useState(currentFolder)
  const [subfolders, setSubfolders] = useState(getFirstLevelSubfolders(folders, activeFolder))
  const [parentFolders, setParentFolders] = useState(getAllParentFolders(folders, currentFolder).reverse())
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [newFolderCreated, setNewFolderCreated] = useState(false)

  // Set subfolders if new folder created
  useEffect(() => {
    if(newFolderCreated) {
      setSubfolders(getFirstLevelSubfolders(folders, activeFolder))
      setNewFolderCreated(false)
    }
  }, [newFolderCreated, activeFolder, folders])

  // On item click
  const handleItemClick = (folder) => {
    setActiveFolder(folder)
    if(folder) {
      setParentFolders([...getAllParentFolders(folders, folder).reverse(), folder])
    }else {
      setParentFolders([])
    }
    setSubfolders(getFirstLevelSubfolders(folders, folder))
  }

  // Render breadcrumbs
  const renderBreadcrumbs = () => {
    let content 

    if(parentFolders.length > 0) {
      content = (
        <>
          <li className="breadcrumbs-v2__item" onClick={() => handleItemClick(null)}><p>{t('general.all')}</p></li>
          {parentFolders.map((f, i, arr) => {
            if(i < arr.length - 1) {
              return <li className="breadcrumbs-v2__item u-truncate-text-wrapper" key={i} onClick={() => handleItemClick(f)}>
                <p className="u-truncate-tex">{f.name}</p>
              </li>
            }else {
              return <li className="breadcrumbs-v2__item active u-truncate-text-wrapper" key={i}>
                <span className="u-truncate-tex">{f.name}</span>
              </li>
            }
          })}
        </>
      )
    }else {
      content = activeFolder ? (
        <>
          <li className="breadcrumbs-v2__item" onClick={() => handleItemClick(null)}><p>{t('general.all')}</p></li>
          <li className="breadcrumbs-v2__item active u-truncate-text-wrapper">
            <span className="u-truncate-tex">{currentFolder.name}</span>
          </li>
        </>
      ) : (
        <li className="breadcrumbs-v2__item active">{t('general.all')}</li>
      )
    }

    return content
  }

  // On move click
  const handleMove = () => {
    onMove(activeFolder, currentFolder)
  }

  // On open create folder modal
  const handleOpenCreateFolderModal = () => {
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  return (
    <Modal 
      onClose={onClose}
      title={<>{t('general.move')} <strong>{t('general.num_elements', { number: items.length })}</strong> {t('general.to')}</>}
      onSave={handleMove}
      saveBtnText={t('general.move_here')}
      className="modal-v2--medium"
    >
      <div className="move-to-folder-v2">
        <div className="move-to-folder-v2__body">
          <div className="move-to-folder-v2__body_top">
            <ul className="breadcrumbs-v2">
              {renderBreadcrumbs()}
            </ul>
            <button className="icon-btn" onClick={handleOpenCreateFolderModal}><span className="material-symbols-outlined">create_new_folder</span></button>
          </div>
          <div className="move-to-folder-v2__body_main">
            {subfolders.map((f, i) => (
              <div className="folder-inner u-truncate-text-wrapper" key={i} onClick={() => handleItemClick(f)}><span className="material-symbols-outlined">folder</span> <span className="u-truncate-text">{f.name}</span></div>
            ))}
            {subfolders.length === 0 && <div className="no-folders">
              <h3>{t('folder.no_folders')}</h3>
              <p>{t('folder.create')}</p> 
            </div>}
          </div>
        </div>
      </div>

      {showCreateFolderModal && (
        <CreateEditFolderModal 
          onClose={handleCloseCreateFolderModal}
          currentFolder={activeFolder}
          setCurrentFolder={setActiveFolder}
          setFolderCreated={setNewFolderCreated}
          view={view}
        />
      )}
    </Modal>
  )
}

export default MoveSelectedItemsToFolderModal
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment'
import PropTypes from 'prop-types';
import HistoryIcon from '@material-ui/icons/History';

import Modal from '../../UI/Modal';
import HistoryBox from '../../UI/HistoryBox';
import LineLoader from '../../UI/LineLoader';
import Message from '../../UI/Message';
import { TagContext, GlobalContext } from '../../../context';
import { availableOn } from '../../../utils';

const dayFormat = 'DD/MM/YYYY'

const HistoryModal = ({ onClose, loading, history, onRestore, uploaded }) => {
  const { t } = useContext(GlobalContext)
  const [days, setDays] = useState([])
  const { getTagById } = useContext(TagContext)

  useEffect(() => {
    // process history into an array of days
    let daysMap = {}
    let daysArray = []
    for(let key in history) {
      let version = history[key]
      let dayValue = moment(version.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          versions: []
        }
      }
      extractVersionsFromDay(version, daysMap[dayValue].versions)
    }
    for(let d in daysMap) {
      daysMap[d].versions.sort((a, b) => {
        if(a.created > b.created) return -1
        else if(a.created < b.created) return 1
        return 0
      })
      daysArray.push(daysMap[d])
    }
    // console.log(daysArray)
    setDays([...daysArray])

  }, [history])

  // Extract versions from day
  const extractVersionsFromDay = (version, versions) => {
    let valuesAdded = version.changed_values?.added && Object.keys(version.changed_values.added).length > 0
    let valuesUpdated = version.changed_values?.updated && Object.keys(version.changed_values.updated).length > 0
    let alertAdded = version.changed_alert?.added && Object.keys(version.changed_alert.added).length > 0
    let alertUpdated = version.changed_alert?.updated && Object.keys(version.changed_alert.updated).length > 0
    let alertRemoved = version.changed_alert?.removed && Object.keys(version.changed_alert.removed).length > 0
    let datesAdded = version.changed_dates?.added && Object.keys(version.changed_dates.added).length > 0
    let datesUpdated = version.changed_dates?.updated && Object.keys(version.changed_dates.updated).length > 0
    let infoAdded = version.changed_info?.added && Object.keys(version.changed_info.added).length > 0
    let infoUpdated = version.changed_info?.updated && Object.keys(version.changed_info.updated).length > 0
    let contactsAdded = version.changed_contacts?.added && Object.keys(version.changed_contacts.added).length > 0
    let contactsUpdated = version.changed_contacts?.updated && Object.keys(version.changed_contacts.updated).length > 0
    let tasksAdded = version.changed_tasks?.added && Object.keys(version.changed_tasks.added).length > 0
    let tasksUpdated = version.changed_tasks?.updated && Object.keys(version.changed_tasks.updated).length > 0
    let tasksRemoved = version.changed_tasks?.removed && Object.keys(version.changed_tasks.removed).length > 0
    let attachmentsAdded = version.changed_attachments?.added && Object.keys(version.changed_attachments.added).length > 0
    let attachmentsUpdated = version.changed_attachments?.updated && Object.keys(version.changed_attachments.updated).length > 0
    let attachmentsRemoved = version.changed_attachments?.removed && Object.keys(version.changed_attachments.removed).length > 0
    if(valuesAdded || valuesUpdated) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'values'))
    }
    if(alertAdded || alertUpdated || alertRemoved) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'alert'))
    }
    if(datesAdded || datesUpdated) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'dates'))
    }
    if(infoAdded || infoUpdated) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'info'))
    }
    if(contactsAdded || contactsUpdated) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'contacts'))
    }
    if(tasksAdded || tasksUpdated || tasksRemoved) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'tasks'))
    }
    if(attachmentsAdded || attachmentsUpdated || attachmentsRemoved) {
      versions.push(deleteOtherPropsFromVersion({...version}, 'attachments'))
    }
  }

  // Delete props from version
  const deleteOtherPropsFromVersion = (version, activeProp) => {
    const props = ['alert', 'dates', 'info', 'contacts', 'tasks', 'values', 'attachments']
    props.forEach(prop => {
      if(prop !== activeProp) {
        delete version[prop]
        delete version[`changed_${prop}`]
      }
    })
    version.type = activeProp
    return version
  }

  const isToday = (dayValue) => {
    return moment().format(dayFormat) === dayValue
  }

  const dayTitle = (day) => {
    if(isToday(day)) {
      return t('general.today')
    }
    return day
  }
  
  const renderTime = (time) => {
    return moment(time).format('HH[h]mm')
  }

  const versionVariablesArray = (variables) => {
    let varArr = []
    for(let key in variables) {
      varArr.push({ label: key, input: variables[key] })
    }
    return varArr
  }

  const getTagNameById = (id) => {
    const found = getTagById(id)
    return found ? found.name : id
  }

  const versionDataArray = (data, exclude = []) => {
    let dataArr = []
    for(let key in data) {
      if(!exclude.includes(key)) {
        let input
        if(Array.isArray(data[key])) {
          input = data[key].join(', ')
          if(key === 'keywords') {
            const keywords = []
            data[key].forEach(kw => keywords.push(getTagNameById(kw)))
            input = keywords.join(', ')
          }
          else if(key === 'categories') {
            const categories = []
            data[key].forEach(cat => categories.push(t(`categories.${cat}`)))
            input = categories.join(', ')
          }
        }else {
          if(data[key] === Object(data[key])) {
            if(key.includes('_date')) {
              input = data[key].timestamp
            }else {
              input = data[key]
            }
          }else {
            if(data[key] === 'yes' || data[key] === 'no') {
              input = t(`general.${data[key]}`)
            }else {
              input = data[key]
            }
          }
        }
        if(key.toLowerCase().includes('timestamp') || key.toLowerCase().includes('_date')) {
          dataArr.push({ label: key, input, timestamp: true })
        }else {
          dataArr.push({ label: key, input })
        }
      }
    }
    return dataArr
  }

  const renderDay = (day, dayIndex) => {
    // console.log(day)
    return (
      <div key={`day_${dayIndex}`} className="history__section">
        <h3 className={isToday(day.dayValue) ? 'today' : ''}>{ dayTitle(day.dayValue) }</h3>
        { day.versions.map((version, versionIndex) => {
          let numberOfEntries
          let added = 0
          let updated = 0
          let removed = 0
          let infoText = ''
          if(version[`changed_${version.type}`]?.added) {
            if(version.type === 'alert' && Object.keys(version[`changed_${version.type}`].added).length > 0) { 
              added = 1 
            }
            else {
              added = Object.keys(version[`changed_${version.type}`].added).length
            }
          } 
          if(version[`changed_${version.type}`]?.updated) {
            if(version.type === 'alert' && Object.keys(version[`changed_${version.type}`].updated).length > 0) { 
              updated = 1 
            }else {
              updated = Object.keys(version[`changed_${version.type}`].updated).length
            }
          } 
          if(version[`changed_${version.type}`]?.removed) {
            if(version.type === 'alert' && Object.keys(version[`changed_${version.type}`].removed).length > 0) { 
              removed = 1 
            }else {
              removed = Object.keys(version[`changed_${version.type}`].removed).length
            }
          } 
          if(added > 0) {
            infoText = `You have added ${added} ${version.type}`
          }
          if(updated > 0) {
            if(added > 0) {
              infoText += ` & updated ${updated} ${version.type}`
            }else {
              infoText += `You have updated ${updated} ${version.type}`
            }
          }
          if(removed > 0) {
            if (added > 0 || updated > 0) {
              infoText += ` & removed ${removed} ${version.type}`
            }else {
              infoText += `You have removed ${removed} ${version.type}`
            }
          }
          const valuesData1 = versionDataArray(version.changed_values?.added || {})
          const valuesData2 = versionDataArray(version.changed_values?.updated || {})
          const valuesData = [...valuesData1, ...valuesData2]
          const alertData1 = versionDataArray(version.changed_alert?.added || {}, ['documentId', 'sent', 'date', 'id', 'team', 'selectedVal', 'owner'])
          const alertData2 = versionDataArray(version.changed_alert?.updated || {}, ['documentId', 'sent', 'date', 'id', 'team', 'selectedVal', 'owner'])
          const alertData3 = versionDataArray(version.changed_alert?.removed || {}, ['documentId', 'sent', 'date', 'id', 'team', 'selectedVal', 'owner'])
          const alertData = [...alertData1, ...alertData2, ...alertData3]
          const infoData1 = versionDataArray(version.changed_info?.added || {})
          const infoData2 = versionDataArray(version.changed_info?.updated || {})
          const infoData = [...infoData1, ...infoData2]
          const datesData1 = versionDataArray(version.changed_dates?.added || {})
          const datesData2 = versionDataArray(version.changed_dates?.updated || {})
          const datesData = [...datesData1, ...datesData2]
          const contactsData1 = versionDataArray(version.changed_contacts?.added || {})
          const contactsData2 = versionDataArray(version.changed_contacts?.updated || {})
          const contactsData = [...contactsData1, ...contactsData2]
          const tasksData1 = versionDataArray(version.changed_tasks?.added || [])
          const tasksData2 = versionDataArray(version.changed_tasks?.updated || [])
          const tasksData3 = versionDataArray(version.changed_tasks?.removed || [])
          const tasksData = [...tasksData1, ...tasksData2, ...tasksData3]
          const attachmentsData1 = versionDataArray(version.changed_attachments?.added || [])
          const attachmentsData2 = versionDataArray(version.changed_attachments?.updated || [])
          const attachmentsData3 = versionDataArray(version.changed_attachments?.removed || [])
          const attachmentsData = [...attachmentsData1, ...attachmentsData2, ...attachmentsData3]
          // console.log(version.changed_attachments?.added)
          // console.log(version.changed_attachments?.updated)
          // if(availableOn(['development'])) {
          //   numberOfEntries = Object.keys(version.values).length + alertData.length + infoData.length + datesData.length + contactsData.length + tasksData.length
          // }else {
          //   numberOfEntries = Object.keys(version.values).length
          // }
          
          return (
            <HistoryBox 
              key={`version_${dayIndex}_${versionIndex}`}
              time={renderTime(version.created)}
              progress={version.progress}
              numberOfEntries={numberOfEntries}
              onRestoreClick={() => onRestore(version) }
              inputsData={valuesData}
              alertData={alertData}
              infoData={infoData}
              datesData={datesData}
              contactsData={contactsData}
              tasksData={tasksData}
              attachmentsData={attachmentsData}
              uploaded={uploaded}
              version="2"
              infoText={infoText}
              type={version.type}
            />
          )
        })}
      </div>
    )
  }

  return(
    <Modal onClose={onClose} bg2>
      <div className="history">
        <h2><HistoryIcon /> {t('dashboard.document_history')}</h2>

        { loading ? (
          <LineLoader />
        ) : (
          <div>
            { days.length > 0 ? days.map(renderDay) : <Message type="primary" msg={t('dashboard.no_history')} />}
          </div> ) }
      </div>
    </Modal>
  );
}

HistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default HistoryModal;
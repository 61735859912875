import React, { useState, useContext } from 'react';
import HistoryIcon from '@material-ui/icons/History';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AlarmIcon from '@material-ui/icons/Alarm';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayTwoTone';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import moment from 'moment';

import Button from './Button';
import { ContactsContext, GlobalContext } from '../../context';
import { availableOn } from '../../utils';

const HistoryBox = ({ time, progress, numberOfEntries, onRestoreClick, inputsData, uploaded, alertData, infoData, datesData, contactsData, tasksData, version = '1', infoText, type, attachmentsData, collectionType = 'document', description }) => {
  const { t } = useContext(GlobalContext)
  const [showBody, setShowBody] = useState(false);
  const { getContactById } = useContext(ContactsContext);

  const getContact = (id) => {
    const found = getContactById(id)
    return found ? found.name : id
  }

  const getLabel = (label, group) => {
    let text 
    if(group === 'alert') {
      switch(label) {
        case 'dateTimestamp':
          text = t('general.alert_date')
          break 
        case 'emails':
          text = t('general.recipients_emails')
          break 
        case 'title':
          text = t('general.alert_title')
          break 
        case 'message':
          text = t('general.message')
          break 
        default:
          text = label
      }
    }else if(group === 'info') {
      switch(label) {
        case 'amount':
          text = t('dashboard.amount')
          break
        case 'categories':
          text = t('dashboard.contract_category')
          break
        case 'currency':
          text = t('dashboard.currency')
          break
        case 'keywords':
          text = t('dashboard.keywords')
          break
        case 'language':
          text = t('dashboard.contract_lang')
          break
        case 'personal_note':
          text = t('dashboard.personal_note')
          break
        default:
          text = label
      }
    }else if(group === 'dates') {
      switch(label) {
        case 'signature_date':
          text = t('dashboard.signature')
          break
        case 'start_date':
          text = t('dashboard.contract_start_date')
          break
        case 'end_date':
          text = t('dashboard.contract_end_date')
          break
        case 'notice_period':
          text = t('dashboard.notice_period')
          break
        case 'tacit_renewal':
          text = t('dashboard.tacit_renewal')
          break
        default:
          text = label
      }
    }else if(group === 'contacts') {
      switch(label) {
        case 'company':
          text = t('general.company_name')
          break
        case 'manager':
          text = t('general.purchasing_manager')
          break
        case 'responsible_person':
          text = t('general.responsible_for_document')
          break
        case 'stakeholder':
          text = t('general.stakeholder_name')
          break
        default:
          text = label
      }
    }
    return text
  }

  let historyCssClasses = 'history-box'
  if(version === '2') {
    historyCssClasses += ' history-box--v2'
  }
  if(showBody) {
    historyCssClasses += ' opened'
  }

  if(collectionType === 'template') {
    return (
      <div className="history-box history-box--template">
        <header>
          <div className="history-box__time">{time}</div>
          <div className="history-box__progress">
            <p className="main">{description}</p>
          </div>
        </header>
      </div>
    )
  }

  return(
    <div className={historyCssClasses}>
      {version === '1' ?
        <header>
          <div className="history-box__time">{time}</div>
          <div className="history-box__progress">
            {!uploaded && <p className="main">{t('general.progress')}: {(progress * 100).toFixed(0)}%</p>}
            {!uploaded && <div className="progress-bar"><div style={{width: `${progress * 100}%`}}></div></div>}
            <p className="number-of-entries">{t('general.num_of_entries', { number: numberOfEntries })}</p>
          </div>
          <div className="history-box__actions">
            <Button text={!showBody ? t('general.preview') : t('general.hide')} icon={<RemoveRedEyeIcon />} primaryLight onButtonClick={() => setShowBody(!showBody)} />
            <Button text={t('general.restore')} icon={<HistoryIcon />} primaryLight onButtonClick={onRestoreClick} />
          </div>
        </header>
        :
        <header>
          <div className="history-box__time">{time}</div>
          <div className="history-box__info">
            <div className="history-box__info_top">
              <div className="circle">
                <div className={`icon ${type}`}>
                  {type === 'values' && <DescriptionOutlinedIcon />}
                  {type === 'alert' && <AlarmIcon />}
                  {type === 'dates' && <CalendarTodayIcon />}
                  {type === 'info' && <LabelOutlinedIcon />}
                  {type === 'tasks' && <CheckIcon />}
                  {type === 'contacts' && <PersonIcon />}
                  {type === 'attachments' && <AttachFileIcon />}
                </div>
              </div>
              <p>{infoText}</p>
            </div>
            {type === 'values' && <div className="history-box__info_bottom">
              {!uploaded && <p>{t('general.progress')}: {(progress * 100).toFixed(0)}%</p>}
              {!uploaded && <div className="progress-bar"><div style={{width: `${progress * 100}%`}}></div></div>}
            </div>}
          </div>
          <div className="history-box__actions">
            {type === 'values' && <Button text={t('general.restore')} icon={<HistoryIcon />} primaryLight onButtonClick={onRestoreClick} />}
            <Button text={!showBody ? t('general.preview') : t('general.hide')} icon={<RemoveRedEyeIcon />} primaryLight onButtonClick={() => setShowBody(!showBody)} />
          </div>
        </header>
      }
      {showBody && 
        <div className={`history-box__body ${type}`}>
          {inputsData.length > 0 && 
            <div className="group-wrapper">
              {/* {availableOn(['development']) && <p className="group-wrapper__title">{t('dashboard.variables')}</p>} */}
              {inputsData.map((item, index) => (
                <div className="group" key={index}>
                  <p className="label">{item.label}</p>
                  <p className="input">{item.input}</p>
                </div>
              ))}
            </div>
          }
          {version === '2' && infoData.length > 0 && 
            <div className="group-wrapper">
              <p className="group-wrapper__title">{t('dashboard.contract_info')}</p>
              {infoData.map((item, index) => {
                let input = item.input 
                if(item.label === 'language') input = t(`lang.${input}`)
                if(item.label === 'currency') {
                  if(input === 'euro') input = '€'
                  else if(input === 'dollar') input = '$'
                  else if(input === 'pound') input = '€£'
                }
                return <div className="group" key={index}>
                  <p className="label">{getLabel(item.label, 'info')}</p>
                  <p className="input">{input}</p>
                </div>
              })}
            </div>
          }
          {version === '2' && tasksData.length > 0 && 
            <div className="group-wrapper">
              <p className="group-wrapper__title">{t('dashboard.tasks')}</p>
              {tasksData.map((item, index) => (
                <div className="group" key={index}>
                  <p className="label">{item.input.name}</p>
                  <p className="input">{item.input.completed ? t('general.completed') : t('general.not_completed')}</p>
                </div>
              ))}
            </div>
          }
          {version === '2' && datesData.length > 0 && 
            <div className="group-wrapper">
              <p className="group-wrapper__title">{t('dashboard.dates')}</p>
              {datesData.map((item, index) => (
                <div className="group" key={index}>
                  <p className="label">{getLabel(item.label, 'dates')}</p>
                  <p className="input">{!item.timestamp ? item.input : item.input !== '' ? moment(item.input).format('DD MMM YYYY') : ''}</p>
                </div>
              ))}
            </div>
          }
          {version === '2' && alertData.length > 0 && 
            <div className="group-wrapper">
              <p className="group-wrapper__title">{t('general.alert')}</p>
              {alertData.map((item, index) => (
                <div className="group" key={index}>
                  <p className="label">{getLabel(item.label, 'alert')}</p>
                  <p className="input">{!item.timestamp ? item.input : moment(item.input).format('DD MMM YYYY - HH[h]mm')}</p>
                </div>
              ))}
            </div>
          }
          {version === '2' && contactsData.length > 0 && 
            <div className="group-wrapper">
              <p className="group-wrapper__title">{t('general.associated_contacts')}</p>
              {contactsData.map((item, index) => (
                <div className="group" key={index}>
                  <p className="label">{getLabel(item.label, 'contacts')}</p>
                  <p className="input">{item.label !== 'company' ? getContact(item.input) : item.input}</p>
                </div>
              ))}
            </div>
          }
          {version === '2' && attachmentsData.length > 0 && 
            <div className="group-wrapper">
              <p className="group-wrapper__title">{t('dashboard.tasks')}</p>
              {attachmentsData.map((item, index) => (
                <div className="group" key={index}>
                  <p className="label">{item.input.name}</p>
                  <p className="input">{item.input.type}</p>
                </div>
              ))}
            </div>
          }
        </div>
      }
    </div>
  );
}

export default HistoryBox;
import { useContext, memo } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

import Loader from '../../UI/Loader'
import { AlertsWidget, DocsWidget, RecentDocsWidget, TasksWidget, PlaceholderWidget, PeopleWidget, PrivateNotepadWidget } from '../'
import { UserContext, DocumentsContext, AlertContext, TaskContext, GlobalContext } from '../../../context'
import carbonLogo from '../../../assets/images/carbon_logo_new.png'

const WidgetsContainer = ({ activeId, isSorting, itemsObj }) => {
  const { user, widgets } = useContext(UserContext)
  const { t } = useContext(GlobalContext)
  const { setNodeRef } = useDroppable({ id: 'main' })

  return (
    <SortableContext id={'main'} items={widgets.main} strategy={verticalListSortingStrategy}>
      <div className={`dashboard-page-v2__widgets ${isSorting ? 'sorting' : ''}`} ref={setNodeRef}>
        <div className="dashboard-page-v2__widgets_inner">
          {widgets.main.length === 0 && (
            <div className='no-widgets'>{t('widgets.drag_to_dashboard')}</div>
          )}

          {(user && Object.keys(user).length > 0) ? widgets.main.map((item, index) => {
            const itemObj = itemsObj.find(i => i.id === item)
            if(!itemObj) return null
            return <SortableItem 
              key={item} 
              item={itemObj} 
              index={index}
              view={itemObj.view} 
              dragging={itemObj.id === activeId} 
              isSorting={isSorting}
            />
          }) : (
            <div className="no-widgets loader-wrapper">
              <Loader normal primary small />
              <div className="logo-wrapper">
                <img src={carbonLogo} alt="" />
                <p>Carbon</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </SortableContext>
  )
}

const SortableItem = memo(({ item, view, dragging, isSorting }) => {
  const { t } = useContext(GlobalContext)
  const { documentsLoaded } = useContext(DocumentsContext)
  const { tasksFetched } = useContext(TaskContext)
  const { alertsFetched } = useContext(AlertContext)
  const sortable = useSortable({ id: item.id })
  const { attributes, listeners, setNodeRef, transform, transition } = sortable
  let style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  if(dragging) {
    style.zIndex = 10
  }

  return (
    <>
      {view === 'alerts' && 
        (alertsFetched ? <AlertsWidget ref={setNodeRef} style={style} dragging={dragging} isSorting={isSorting} {...attributes} {...listeners} />
        : <PlaceholderWidget title={t('dashboard.alerts')} widget="item-alerts" /> )
      }
      {view === 'tasks' && 
        (tasksFetched ? <TasksWidget ref={setNodeRef} style={style} dragging={dragging} isSorting={isSorting} {...attributes} {...listeners} />
        : <PlaceholderWidget title={t('dashboard.tasks')} widget="item-tasks" /> )
      }
      {view === 'recent' && 
        (documentsLoaded ? <RecentDocsWidget ref={setNodeRef} style={style} dragging={dragging} isSorting={isSorting} {...attributes} {...listeners} />
        : <PlaceholderWidget title={t('dashboard.recent_documents')} widget="item-recent" /> )
      }
      {view === 'contracts' &&
        (documentsLoaded ? <DocsWidget ref={setNodeRef} style={style} dragging={dragging} isSorting={isSorting} {...attributes} {...listeners} />
        : <PlaceholderWidget title={t('dashboard.documents')} widget="item-contracts" /> )
      }
      {view === 'private-notepad' && (
        <PrivateNotepadWidget ref={setNodeRef} style={style} dragging={dragging} isSorting={isSorting} {...attributes} {...listeners} />
      )}
      {view === 'people' && (
        <PeopleWidget ref={setNodeRef} style={style} dragging={dragging} isSorting={isSorting} {...attributes} {...listeners} />
      )}
    </>
  )
})

export default WidgetsContainer
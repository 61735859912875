import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const CustomPopup = ({ children, position = 'bottom-left', fixed, elPagePosition }) => {
  const [elPosition, setElPosition] = useState({ top: 0, left: 0 });
  const popupEl = useRef();
  let popupClass = 'custom-popup';

  useEffect(() => {
    if(elPagePosition) {
      const pos = elPagePosition;
      if(position === 'bottom-left') {
        let top = fixed ? pos.top + pos.height + 10 : pos.top + window.pageYOffset + pos.height + 10;
        setElPosition({ top, left: pos.left - (pos.width / 2) + window.pageXOffset });
      }
    }
  }, [position, elPagePosition, fixed]);

  if(position === 'bottom-left') {
    popupClass += ' custom-popup--bottom-left';
  }

  return ReactDOM.createPortal(
    <div className={popupClass} ref={popupEl} style={{top: elPosition.top, left: elPosition.left}}>
      {children}
    </div>,
    document.getElementById('root')
  );
}

export default CustomPopup;
import { useContext, useState, useEffect } from 'react'
import { setHours, setMinutes, isSameDay } from 'date-fns'
import moment from 'moment'

import { Modal, Input, DateInput, DocumentSelect, Textarea, TagsInput } from '../../new_ui'
import { GlobalContext, UserContext, DocumentsContext, TeamContext } from '../../../context'
import { useAlertActions } from '../../../hooks'

const defaultExcludedTimes = [
  setHours(setMinutes(new Date(), 0), 0),
  setHours(setMinutes(new Date(), 0), 1),
  setHours(setMinutes(new Date(), 0), 2),
  setHours(setMinutes(new Date(), 0), 3),
  setHours(setMinutes(new Date(), 0), 4),
  setHours(setMinutes(new Date(), 0), 5),
  setHours(setMinutes(new Date(), 0), 6),
  setHours(setMinutes(new Date(), 0), 7),
  setHours(setMinutes(new Date(), 0), 20),
  setHours(setMinutes(new Date(), 0), 21),
  setHours(setMinutes(new Date(), 0), 22),
  setHours(setMinutes(new Date(), 0), 23),
]

const notExcludedTimes = [
  setHours(setMinutes(new Date(), 0), 8),
  setHours(setMinutes(new Date(), 0), 9),
  setHours(setMinutes(new Date(), 0), 10),
  setHours(setMinutes(new Date(), 0), 11),
  setHours(setMinutes(new Date(), 0), 12),
  setHours(setMinutes(new Date(), 0), 13),
  setHours(setMinutes(new Date(), 0), 14),
  setHours(setMinutes(new Date(), 0), 15),
  setHours(setMinutes(new Date(), 0), 16),
  setHours(setMinutes(new Date(), 0), 17),
  setHours(setMinutes(new Date(), 0), 18),
  setHours(setMinutes(new Date(), 0), 19),
]

const getExcludeTimesForDate = (date) => {
  let selectedDate = date
  if(!date) selectedDate = new Date()
  return notExcludedTimes.filter((time) => {
    if(isSameDay(selectedDate, time)) {
      const timestamp = new Date(time).getTime()
      return Date.now() > timestamp
    }
    return false
  })
}

const CreateEditAlertModal = ({ onClose, mode = 'create', selectedAlert = null, selectedTimestamp = null, refreshCollection }) => {
  const { t } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { getDocumentById, documentsLoaded, fetchDocs } = useContext(DocumentsContext)
  const { selectedTeam } = useContext(TeamContext)
  const { createNewAlert, updateSingleAlert } = useAlertActions()
  const [selectedDocument, setSelectedDocument] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertTime, setAlertTime] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [timeError, setTimeError] = useState(false)
  const [contractError, setContractError] = useState(false)
  const [emails, setEmails] = useState(user ? [user.email] : [])
  const [emailsError, setEmailsError] = useState(false)
  const [excludedTimes, setExcludedTimes] = useState([...defaultExcludedTimes, ...getExcludeTimesForDate(alertTime)])
  const [showAlertNotification, setShowAlertNotification] = useState(true)
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fetch documents if necessary 
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!documentsLoaded) {
        // console.log('fetch documents')
        fetchDocs(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [documentsLoaded, fetchDocs, fetchingCollections, selectedTeam])

  // If selectedTimestamp update alertTime state variable
  useEffect(() => {
    if(selectedTimestamp) {
      let time = selectedTimestamp * 1000
      setAlertTime(time)
      setExcludedTimes([...defaultExcludedTimes, ...getExcludeTimesForDate(time)])
    }
  }, [selectedTimestamp])

  // If edit mode populate fields
  useEffect(() => {
    if(selectedAlert && mode === 'edit') {
      setEmails(selectedAlert.emails)
      setAlertTitle(selectedAlert.title)
      setAlertTime(selectedAlert.dateTimestamp)
      setAlertMessage(selectedAlert.message)
      setSelectedDocument(selectedAlert.documentId)
    }
  }, [selectedAlert, mode])

  // On document change
  const handleDocumentChange = (value) => {
    setSelectedDocument(value)
    if(alertTitle === '' || alertTitle.startsWith('Alerte:')) {
      const doc = getDocumentById(value)
      if(doc) {
        setAlertTitle(t('doc_alert.default_title', { title: doc.name }))
      }
    }
    if(contractError) {
      setContractError(false)
    }
  }

  // On date change
  const handleDateChange = (date) => {
    setAlertTime(date)
    if(new Date(date).getHours() === 0) {
      date.setHours(9)
    }
    if(moment(date).isValid()) {
      setAlertTime(date)
      if(timeError) {
        setTimeError(false)
      }
    }
  }

  // On update excluded times
  const handleUpdateExcludedTimes = (date) => {
    setExcludedTimes(getExcludeTimesForDate(date))
  }

  // On tags change
  const handleTagsChange = (tags) => {
    setEmails(tags)
    if(emailsError) {
      setEmailsError(false)
    }
  }

  // On title change
  const handleTitleChange = (e) => {
    const val = e.target.value
    setAlertTitle(val)
    if(titleError && val.trim() !== '') {
      setTitleError(false)
    }
  }

  // On save
  const handleSave = async () => {
    if(mode === 'create') {
      await createNewAlert({ 
        selectedDocument: getDocumentById(selectedDocument) || {},
        setContractError,
        setEmailsError,
        setTimeError,
        alertTime,
        alertTitle,
        alertMessage,
        emails,
        onClose,
        refreshCollection
      })
    }else if(mode === 'edit') {
      await updateSingleAlert({
        alertTime, 
        selectedDocument: getDocumentById(selectedDocument) || {},
        selectedAlert,
        emails,
        alertMessage,
        alertTitle,
        onClose,
        setContractError,
        setEmailsError,
        setTimeError,
      })
    }
  }
  
  return (
    <Modal 
      title={mode === 'create' ? t('dashboard.create_new_alert') : t('dashboard.edit_alert')}
      className="centered-head"
      onClose={onClose}
      onSave={handleSave}
      setBodyOverflow={mode === 'edit' ? false : true}
    >
      {!documentsLoaded && (
        <div className="loader-wrapper"><div className="loader-v2"></div></div>
      )}
      {documentsLoaded && (
        <div className="create-task">
          {showAlertNotification && mode === 'create' && (
            <div className="create-task__notification" onClick={() => setShowAlertNotification(false)}>
              <div className="create-task__notification_close">
                <span className="material-symbols-outlined">close</span>
              </div>
              <p>{t('notification.alert_notification')}</p>
            </div>
          )}
          <div className="create-task__field-group create-task__field-group--2">
            <DocumentSelect 
              label={t('general.related_document') + ' *'} 
              onChange={handleDocumentChange} 
              value={selectedDocument} 
              invalid={contractError}
            />
            <DateInput 
              label={t('general.alert_date')  + ' *'} 
              onChange={handleDateChange} 
              value={alertTime} 
              withTime={true} 
              minDate={Date.now()}
              excludeTimes={[
                ...defaultExcludedTimes,
                ...excludedTimes
              ]}
              dateFormat="d MMMM yyyy - HH'h'mm"
              invalid={timeError}
              onSelect={handleUpdateExcludedTimes}
            />
          </div>
          <div className="create-task__field">
            <TagsInput 
              label={t('general.recipients_emails_2') + ' *'} 
              onChange={handleTagsChange}
              emailValidation 
              defaultTags={emails}
              thickBorder
              invalid={emailsError}
            />
          </div>
          <div className="create-task__field">
            <Input 
              label={t('general.alert_title') + ` (${t('general.optional')})`}
              value={alertTitle}
              onChange={handleTitleChange}
              invalid={titleError}
            />
          </div>
          <div className="create-task__field">
            <Textarea 
              label={t('general.message') + ` (${t('general.optional')})`}
              value={alertMessage}
              onChange={(e) => setAlertMessage(e.target.value)}
              className="label-normal"
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CreateEditAlertModal
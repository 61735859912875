import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loader from './Loader';

const Button = ({ disabled, text, icon, dropArrow, primary, onButtonClick, type, outlinePrimary, outlinePrimaryLight, bold, small, xSmall, regular, medium, fullWidth, white, primaryLight, smallerIconMargin, transparent, style, secondaryLight, txtSmall, withPaddingY, danger, className, outlineLight, large, tertiary, round, primaryLight2, textSmall, lightGreen, outlinePink, dull, iconRight, loading }) => {
  let btnClasses = 'button';

  if(className) {
    btnClasses += ` ${className}`;
  }

  if(dropArrow) {
    btnClasses += ' button--drop-down';
  }
  if(primary) {
    btnClasses += ' button--primary';
  }
  if(outlinePrimary) {
    btnClasses += ' button--outline-primary';
  }
  if(outlinePrimaryLight) {
    btnClasses += ' button--outline-primary-light';
  }
  if(bold) {
    btnClasses += ' button--bold';
  }
  if(xSmall) {
    btnClasses += ' button--x-small';
  }
  if(small) {
    btnClasses += ' button--small';
  }
  if(regular) {
    btnClasses += ' button--regular';
  }
  if(medium) {
    btnClasses += ' button--medium';
  }
  if(large) {
    btnClasses += ' button--large';
  }
  if(fullWidth) {
    btnClasses += ' button--full-width';
  }
  if(white) {
    btnClasses += ' button--white';
  }
  if(primaryLight) {
    btnClasses += ' button--primary-light';
  }
  if(primaryLight2) {
    btnClasses += ' button--primary-light-2';
  }
  if(smallerIconMargin) {
    btnClasses += ' button--smaller-icon-margin';
  }
  if(transparent) {
    btnClasses += ' button--transparent';
  }
  if(secondaryLight) {
    btnClasses += ' button--secondary-light';
  }
  if(txtSmall) {
    btnClasses += ' button--txt-small';
  }
  if(withPaddingY) {
    btnClasses += ' button--with-padding-y';
  }
  if(danger) {
    btnClasses += ' button--danger';
  }
  if(outlineLight) {
    btnClasses += ' button--outline-light';
  }
  if(tertiary) {
    btnClasses += ' button--tertiary';
  }
  if(round) {
    btnClasses += ' button--round';
  }
  if(textSmall) {
    btnClasses += ' button--text-small';
  }
  if(lightGreen) {
    btnClasses += ' button--light-green';
  }
  if(outlinePink) {
    btnClasses += ' button--outline-pink';
  }
  if(dull) {
    btnClasses += ' button--dull'
  }
  if(disabled) {
    btnClasses += ' button--disabled'
  }

  return(
    <button className={btnClasses} disabled={disabled} onClick={onButtonClick} type={type ? type : 'button'} style={style || {}}>
      {icon && <span className="icon">{icon}</span>}
      {!loading && <span className="text">{text}</span>}
      {iconRight && <span className="icon-right">{iconRight}</span>}
      {dropArrow && <span className="arrow"><ArrowDropDownIcon /></span>}
      {loading && <Loader small />}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  dropArrow: PropTypes.bool,
  primary: PropTypes.bool,
  onButtonClick: PropTypes.func,
  type: PropTypes.string,
  outlinePrimary: PropTypes.bool,
  bold: PropTypes.bool,
  small: PropTypes.bool,
  regular: PropTypes.bool,
  medium: PropTypes.bool,
  fullWidth: PropTypes.bool,
  white: PropTypes.bool,
  primaryLight: PropTypes.bool,
  smallerIconMargin: PropTypes.bool,
  style: PropTypes.object,
  dull: PropTypes.bool
}

export default Button;
import { useContext } from 'react'

import { GlobalContext } from '../../context'

const WidgetPlaceholderDocs = () => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="customize-panel-widget-placeholder">
      <div className="customize-panel-widget-placeholder__head">
        <p>{t('dashboard.documents')}</p>
      </div>
      <div className="customize-panel-widget-placeholder__body">
        <div className="docs-placeholder">
          <div className="bar bar--1"></div>
          <div className="bar bar--2"></div>
          <div className="bar bar--3"></div>
          <div className="bar bar--4"></div>
          <div className="bar bar--5"></div>
          <div className="bar bar--6"></div>
          <div className="bar bar--7"></div>
          <div className="bar bar--8"></div>
        </div>
      </div>
    </div>
  )
}

export default WidgetPlaceholderDocs
import { useContext, useState } from 'react'

import { Alert } from '../../new_ui'
import { MoveSelectedItemsToFolderModal } from '../'
import { GlobalContext, FolderContext } from '../../../context'
import { useTemplatesActions } from '../../../hooks'

const TemplatesActions = ({ allChecked, onAllCheckedChange, selectedItems, setSelectedItems, refreshTemplates }) => {
  const { t } = useContext(GlobalContext)
  const { folders, selectedFilter } = useContext(FolderContext)
  const { moveTemplatesToFolder, downloadTemplates, deleteTemplates } = useTemplatesActions()
  const [showMoveMultipleDocsToFolderModal, setShowMoveMultipleDocsToFolderModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  // On move click
  const handleMoveClick = (e) => {
    e.preventDefault()
    setShowMoveMultipleDocsToFolderModal(true)
  }

  // On move
  const handleMove = async (moveTo, current) => {
    const moved = await moveTemplatesToFolder({ moveTo, current, selectedDocuments: selectedItems, setSelectedDocuments: setSelectedItems, setShowMoveMultipleDocsToFolderModal })
    if(moved) {
      refreshTemplates()
    }
  }

  // On close move modal
  const handleCloseMoveModal = () => {
    setShowMoveMultipleDocsToFolderModal(false)
  }

  // On download
  const handleDownload = async (e) => {
    e.preventDefault()
    await downloadTemplates({ selectedDocuments: selectedItems, setSelectedDocuments: setSelectedItems })
  }

  // On delete click
  const handleDeleteClick = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteTemplates({ selectedDocuments: selectedItems, setSelectedDocuments: setSelectedItems, setShowDeleteSelectedTemplatesAlert: setShowDeleteAlert })
    refreshTemplates()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  return (
    <div className="list-view-v2__actions">
      <div className="checkbox">
        {!allChecked 
          ? <span className="material-symbols-outlined" onClick={onAllCheckedChange}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled" onClick={onAllCheckedChange}>check_box</span>
        }
      </div>
      <p className="text-style-1">{t('dashboard.num_of_selected_items', { number: selectedItems.length })}</p>
      <div className="buttons">
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleMoveClick}>
          <span className="material-symbols-outlined icon">drive_file_move_outline</span> 
          <span className="text">{t('general.move')}</span>
        </a>
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDownload}>
          <span className="material-symbols-outlined icon">download</span> 
          <span className="text">{t('general.download')}</span>
        </a>
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDeleteClick}>
          <span className="material-symbols-outlined icon">delete</span> 
          <span className="text">{t('general.remove')}</span>
        </a>
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_selected_documents')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}

      {showMoveMultipleDocsToFolderModal && (
        <MoveSelectedItemsToFolderModal  
          onClose={handleCloseMoveModal} 
          folders={folders}
          items={selectedItems}
          onMove={handleMove}
          currentFolder={selectedFilter === 'all' ? null : [...folders].find(f => f.id === selectedFilter)}
        />
      )}
    </div>
  )
}

export default TemplatesActions
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';
import {
  RemoveRedEyeOutlined,
  CommentOutlined,
  EditOutlined,
  DeleteOutlined,
  Close,
  LinkOff,
  Check,
  PersonAdd,
} from '@material-ui/icons';
import { ClickAwayListener } from '@material-ui/core';
import moment from 'moment';

import Button from '../UI/Button';
import CustomTooltip from '../UI/CustomTooltip';
import IconButton from '../UI/IconButton';
import Loader from '../UI/Loader';
import Modal from '../UI/Modal';
import Textarea from '../UI/Textarea';
import Checkbox from '../UI/Checkbox';
import LetterCircle from '../UI/LetterCircle';
import { AuthModal } from '../misc';
import {
  generate_document_for_shared_templates,
  convert_attachment,
} from '../../services/lawstudioApi';
import { get_file_base64_data } from '../../services/functions';
import { firebaseEnvDoc } from '../../services/firebase';
import { update_shared_document } from '../../services/shared_documents';
import { update_user } from '../../services/firestore';
import {
  GlobalContext,
  NotificationContext,
  UserContext,
  TeamContext,
} from '../../context';
import carbonLogo from '../../assets/images/carbon_logo_new.png';

const SharedPdf = ({ isAuthenticated }) => {
  const { setRedirectToAfterAuthenticated, t } = useContext(GlobalContext);
  const { setNotification } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const { selectedTeam, teamMembers } = useContext(TeamContext);
  const { id } = useParams();
  const [sharedDoc, setSharedDoc] = useState(null);
  const [isLinkInvalid, setIsLinkInvalid] = useState(false);
  const [data, setData] = useState(null);
  const [mode, setMode] = useState('preview');
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const [shouldMove, setShouldMove] = useState(false);
  const [moving, setMoving] = useState(false);
  const [showNotLoggedInModal, setShowNotLoggedInModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('login');
  const [sharedWith, setSharedWith] = useState([]);
  const overlayRef = useRef(null);
  const updateTimeout = useRef(null);
  const unsubscribeListener = useRef(null);
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (unsubscribeListener.current) {
        unsubscribeListener.current();
      }
    };
  }, []);

  // Get shared document with id
  useEffect(() => {
    if (id && !unsubscribeListener.current) {
      unsubscribeListener.current = firebaseEnvDoc
        .collection('shared_documents')
        .doc(id)
        .onSnapshot((snapshot) => {
          if (!snapshot.exists) {
            setLoading(false);
            setIsLinkInvalid(true);
            return;
          }
          const docData = snapshot.data();
          setSharedDoc(docData);
          setComments(docData.comments || []);
          let sharedWithEmails = [];
          if (docData.shared_with) {
            sharedWithEmails = [...docData.shared_with];
          }
          if (docData.team_members) {
            sharedWithEmails = [...sharedWithEmails, ...docData.team_members];
          }
          let sharedWithArr = [];
          for (let i = 0; i < sharedWithEmails.length; i++) {
            let foundUser = teamMembers.find(
              (u) => u.email === sharedWithEmails[i]
            );
            if (foundUser) {
              sharedWithArr.push(foundUser);
            } else {
              sharedWithArr.push({
                email: sharedWithEmails[i],
                id: '',
                name: sharedWithEmails[i],
                image_url: '',
                search: sharedWithEmails[i],
              });
            }
          }
          setSharedWith(sharedWithArr);
        });
    }
  }, [id, teamMembers]);

  // Get pdf data when doc is ready
  useEffect(() => {
    const getPdfData = async () => {
      let documentData;
      const { templateUrl, doc_uploaded, doc_format } = sharedDoc;
      try {
        if (templateUrl) {
          if (!doc_uploaded) {
            const res = await generate_document_for_shared_templates(
              sharedDoc.templateUrl,
              sharedDoc.values || {},
              'pdf',
              sharedDoc.content_editable ? sharedDoc.content_changes || [] : []
            );
            documentData = res.data;
          } else {
            if (doc_format === 'pdf' || doc_format === '') {
              const res = await get_file_base64_data({ url: templateUrl });
              documentData = res.data;
            } else {
              const res = await convert_attachment(templateUrl);
              documentData = res.data;
            }
          }
        }
        setLoading(false);
        if (!documentData) {
          return setNotification({
            msg: t('notification.something_went_wrong'),
            type: 'danger',
          });
        }
        setData(`data:application/pdf;base64,${documentData}`);
        if (history.location?.search === '?mode=comments') {
          setMode('comment');
        }
      } catch (err) {
        console.log(err);
        setNotification({
          msg: t('notification.something_went_wrong'),
          type: 'danger',
        });
        setData(null);
      }
    };

    if (loading && sharedDoc && !data) {
      getPdfData();
    }
  }, [loading, data, setNotification, sharedDoc, history]);

  // Check if comments allowed is changed
  useEffect(() => {
    if (!sharedDoc?.comments_allowed && mode === 'comment') {
      setMode('preview');
    }
  }, [sharedDoc?.comments_allowed, mode]);

  // On document/pdf load success
  const onDocumentLoadSuccess = (numPages) => {
    const arr = [];
    for (let i = 1; i < numPages + 1; i++) {
      arr.push(
        <Page
          key={i}
          pageNumber={i}
          width={870}
          renderTextLayer={true}
          loading=""
        />
      );
    }
    setPages(arr);
  };

  // On overlay click
  const captureOverlayClick = (e) => {
    if (e.target && e.target.classList.contains('overlay')) {
      if (!isAuthenticated) {
        return setShowNotLoggedInModal(true);
      }
      const { left, top } = e.target.getBoundingClientRect();
      let id = uuidv4();
      let newComments = [...comments];
      let lastComment = newComments[newComments.length - 1];
      if (lastComment && lastComment.messages.length === 0) {
        newComments[newComments.length - 1] = {
          ...lastComment,
          top: e.pageY - top - window.scrollY,
          left: e.pageX - left - window.scrollX,
        };
        id = lastComment.id;
        setActiveComment(lastComment);
      } else {
        let newComment = {
          id,
          top: e.pageY - top - window.scrollY,
          left: e.pageX - left - window.scrollX,
          messages: [],
        };
        newComments.push(newComment);
        setActiveComment(newComment);
      }
      setComments(newComments);
    }
  };

  // On mouse move - only when there is active resizable column
  const mouseMoveHandler = useCallback(
    (e) => {
      if (activeComment !== null && shouldMove && isAuthenticated) {
        if (updateTimeout.current) {
          clearTimeout(updateTimeout.current);
        }
        const el = overlayRef.current;
        if (el) {
          const { left, top, width, height } = el.getBoundingClientRect();
          let commentsCopy = [...comments];
          const current = commentsCopy.find((c) => c.id === activeComment.id);
          if (current) {
            // top position
            let topPos;
            if (e.pageY - top - window.scrollY < 0) topPos = 0;
            else if (e.pageY - top - window.scrollY > height) topPos = height;
            else topPos = e.pageY - top - window.scrollY;
            current.top = topPos;

            // left position
            let leftPos;
            if (e.pageX - left - window.scrollX < 0) leftPos = 0;
            else if (e.pageX - left - window.scrollX > width) leftPos = width;
            else leftPos = e.pageX - left - window.scrollX;
            current.left = leftPos;
          }
          const updatedComments = commentsCopy.map((c) =>
            c.id === activeComment.id ? current : c
          );
          setComments(updatedComments);
          let updateInDb = true;
          updatedComments.forEach((c) => {
            if (c.messages.length === 0) updateInDb = false;
          });
          if (!updateInDb) return;
          updateTimeout.current = setTimeout(() => {
            let data = {
              comments: updatedComments,
              updated_at: Date.now(),
            };
            update_shared_document(id, data);
          }, 1000);
        }
      }
    },
    [activeComment, shouldMove, isAuthenticated, comments, id]
  );

  // Remove mouse event listeners
  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMoveHandler);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMoveHandler]);

  // On mouse up - reset active resizable column
  const mouseUpHandler = useCallback(() => {
    setShouldMove(false);
    setMoving(false);
    removeListeners();
  }, [removeListeners]);

  // On mouse down - set active resizable column
  const mouseDownHandler = (e, comment, idx) => {
    setShouldMove(true);
    setMoving(true);
    setActiveComment({ ...comment, index: idx });

    // if last comment is not clicked comment and last comment does not have any message remove it from comments array
    let lastComment = comments[comments.length - 1];
    if (
      lastComment &&
      lastComment.id !== comment.id &&
      lastComment.messages.length === 0
    ) {
      setComments(comments.filter((c) => c.id !== lastComment.id));
    }
  };

  // Add mouse event listeners
  useEffect(() => {
    if (activeComment !== null) {
      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
    }

    return () => {
      removeListeners();
    };
  }, [activeComment, mouseMoveHandler, mouseUpHandler, removeListeners]);

  // Redirect to login
  const redirectToLoginScreen = () => {
    setRedirectToAfterAuthenticated(
      history.location.pathname + '?mode=comments'
    );
    history.push('/login');
  };

  // Refresh comments
  const refreshComments = () => {
    setComments(sharedDoc?.comments || []);
  };

  // Open login modal
  const handleOpenLoginModal = () => {
    setShowAuthModal(true);
    setAuthMode('login');
  };

  // Open register modal
  const handleOpenRegisterModal = () => {
    setShowAuthModal(true);
    setAuthMode('register');
  };

  // On close auth modal
  const handleCloseAuthModal = () => {
    setShowAuthModal(false);
    setShowNotLoggedInModal(true);
  };

  // Render not logged in modal
  const renderNotLoggedInModal = () => {
    return (
      <Modal onClose={() => setShowNotLoggedInModal(false)} small>
        <div className="not-authenticated-modal">
          <div className="not-authenticated-modal__head">
            <h4>{t('share.need_to_be_logged_in')}</h4>
          </div>
          <div className="not-authenticated-modal__body">
            <Button
              text={t('auth.login')}
              onButtonClick={handleOpenLoginModal}
              primary
              medium
            />
            <Button
              text={t('auth.free_registration')}
              onButtonClick={handleOpenRegisterModal}
              primary
              medium
            />
          </div>
        </div>
      </Modal>
    );
  };

  if (isLinkInvalid) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="icon">
            <LinkOff />
          </div>
          <h1>{t('share.link_not_valid')}</h1>
          <p>{t('share.contact_owner')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pdf-share">
      <div
        className={`pdf-share__body ${
          mode === 'comment' ? 'pdf-share__body--comments' : ''
        }`}
      >
        <div className="top-border"></div>
        <div className="right-border"></div>
        <div className="bottom-border"></div>
        <div className="left-border"></div>
        <div className="close-comment-mode" onClick={() => setMode('preview')}>
          <Close />
        </div>
        <div className="preview">
          <div className="preview__inner">
            {sharedDoc?.comments_allowed && mode === 'comment' && (
              <div
                className="overlay"
                onClick={captureOverlayClick}
                ref={overlayRef}
              >
                {comments.map((comment, idx) => {
                  let newMessages = 0;
                  if (user && Object.keys(user).length > 0 && isAuthenticated) {
                    let dateOfUpdate = 0;
                    if (
                      user.last_read_shared_comments &&
                      user.last_read_shared_comments[id] &&
                      user.last_read_shared_comments[id][comment.id]
                    ) {
                      dateOfUpdate =
                        user.last_read_shared_comments[id][comment.id];
                    }
                    const messages = comment.messages;
                    if (messages && Array.isArray(messages)) {
                      for (let j = 0; j < messages.length; j++) {
                        const msg = messages[j];
                        if (
                          msg.author_email !== user.email &&
                          msg.created > dateOfUpdate
                        ) {
                          newMessages += 1;
                        }
                      }
                    }
                  }

                  return (
                    <Comment
                      key={idx}
                      index={idx}
                      comment={comment}
                      comments={comments}
                      onMouseDown={mouseDownHandler}
                      active={activeComment?.id === comment.id && !moving}
                      onSetComments={setComments}
                      onSetActive={setActiveComment}
                      shareId={id}
                      isAuthenticated={isAuthenticated}
                      onShowNotLoggedInModal={setShowNotLoggedInModal}
                      overlayEl={overlayRef.current}
                      newMessages={newMessages}
                      refreshComments={refreshComments}
                      sharedWith={sharedWith}
                    />
                  );
                })}
              </div>
            )}
            {data ? (
              <Document
                file={data}
                onLoadSuccess={({ numPages }) =>
                  onDocumentLoadSuccess(numPages)
                }
                renderMode="canvas"
                loading=""
              >
                {pages}
              </Document>
            ) : (
              <div className="loader-wrapper">
                <Loader small normal />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pdf-share__foot">
        <CustomTooltip content={t('share.preview_mode')}>
          <button
            className={mode === 'preview' ? 'active' : ''}
            onClick={() => setMode('preview')}
          >
            <RemoveRedEyeOutlined />
          </button>
        </CustomTooltip>
        {sharedDoc?.comments_allowed && (
          <CustomTooltip content={t('share.comment_mode')}>
            <button
              className={mode === 'comment' ? 'active' : ''}
              onClick={() => setMode('comment')}
            >
              <CommentOutlined />
            </button>
          </CustomTooltip>
        )}
      </div>

      {showNotLoggedInModal && renderNotLoggedInModal()}

      {showAuthModal && (
        <AuthModal
          onClose={handleCloseAuthModal}
          mode={authMode}
          onOpenRegisterModal={handleOpenRegisterModal}
          onOpenLoginModal={handleOpenLoginModal}
          setShowNotLoggedInModal={setShowNotLoggedInModal}
        />
      )}

      <Link to="/" className="pdf-share__made-in">
        <img src={carbonLogo} alt="logo" />
        Made in Carbon
      </Link>
    </div>
  );
};

export const Comment = ({
  comment,
  comments,
  index,
  onMouseDown,
  active,
  onSetComments,
  onSetActive,
  shareId,
  isAuthenticated,
  onShowNotLoggedInModal,
  overlayEl,
  newMessages,
  refreshComments,
  sharedWith = [],
}) => {
  const { t, selectedLang } = useContext(GlobalContext);
  const { activeTeamMember, teamMembers } = useContext(TeamContext);
  const { setUser, user } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [showLeaveComment, setShowLeaveComment] = useState(false);
  const [approved, setApproved] = useState(false);
  const [showTeamMembersPopup, setShowTeamMembersPopup] = useState(false);
  const [mentionText, setMentionText] = useState('');
  const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
  const [userUpdated, setUserUpdated] = useState(false);
  const [focusMsgField, setFocusMsgField] = useState(false);

  useEffect(() => {
    if (comment.approved) {
      setApproved(true);
    }
  }, [comment]);

  useEffect(() => {
    if (!active) {
      setMessage('');
      setShowLeaveComment(false);
      if (userUpdated) setUserUpdated(false);
    } else {
      if (newMessages > 0 && !userUpdated) {
        const updatedUser = { ...user };
        if (updatedUser.last_read_shared_comments) {
          let sharedDocLastReadComments = {
            [shareId]: {
              [comment.id]: Date.now(),
            },
          };
          if (updatedUser.last_read_shared_comments[shareId]) {
            sharedDocLastReadComments = {
              [shareId]: {
                ...updatedUser.last_read_shared_comments[shareId],
                [comment.id]: Date.now(),
              },
            };
          }
          updatedUser.last_read_shared_comments = {
            ...updatedUser.last_read_shared_comments,
            ...sharedDocLastReadComments,
          };
        } else {
          updatedUser.last_read_shared_comments = {
            [shareId]: {
              [comment.id]: Date.now(),
            },
          };
        }
        setUser(updatedUser);
        update_user(updatedUser, user.id);
        refreshComments();
        setUserUpdated(true);
      }
    }
    // eslint-disable-next-line
  }, [active, newMessages, user, userUpdated]);

  // Send comment
  const sendCommentHandler = async () => {
    let id = uuidv4();
    let mentions = [];
    for (let member of teamMembers) {
      if (message.trim().includes(`@${member.name.replace(' ', '')}`)) {
        mentions.push(member.email);
      }
    }
    for (let sharedEmail of sharedWith) {
      // console.log(sharedEmail.email, message.trim().includes(`@${sharedEmail.email}`))
      let handle = sharedEmail.email.split('@')[0];
      // console.log(sharedEmail.email.split('@')[0], message.trim().includes(`@${handle}`))
      if (
        message.trim().includes(`@${handle}`) &&
        !mentions.includes(sharedEmail.email)
      ) {
        mentions.push(sharedEmail.email);
      }
    }

    let newMessage = {
      id,
      text: message.trim(),
      author: user.first_name || activeTeamMember.first_name || '',
      author_email: user.email || activeTeamMember.email,
      created: Date.now(),
    };

    // save comment to db
    let commentsCopy = [...comments];
    let current = { ...comment };
    current.messages.push(newMessage);
    const updatedComments = commentsCopy.map((c) =>
      c.id === current.id ? current : c
    );
    let data = {
      comments: updatedComments,
      updated_at: Date.now(),
    };
    await update_shared_document(shareId, data);
    onSetComments(updatedComments);
    setMessage('');
    setShowLeaveComment(false);
  };

  // On message save
  const messageSaveHandler = async (id, text) => {
    let commentsCopy = [...comments];
    let current = { ...comment };
    let newMessages = current.messages.map((m) =>
      m.id === id ? { ...m, text } : m
    );
    const updatedComments = commentsCopy.map((c) =>
      c.id === current.id ? { ...current, messages: newMessages } : c
    );
    let data = {
      comments: updatedComments,
      updated_at: Date.now(),
    };
    await update_shared_document(shareId, data);
    onSetComments(updatedComments);
  };

  // On message delete
  const messageDeleteHandler = async (id) => {
    let commentsCopy = [...comments];
    let current = { ...comment };
    let newMessages = current.messages.filter((m) => m.id !== id);
    const updatedComments = commentsCopy.map((c) =>
      c.id === current.id ? { ...current, messages: newMessages } : c
    );
    let data = {
      comments: updatedComments,
      updated_at: Date.now(),
    };
    await update_shared_document(shareId, data);
    onSetComments(updatedComments);
  };

  // On comment delete
  const commentDeleteHandler = async () => {
    let commentsCopy = [...comments];
    let current = { ...comment };
    const updatedComments = commentsCopy.filter((c) => c.id !== current.id);
    let data = {
      comments: updatedComments,
      updated_at: Date.now(),
    };
    await update_shared_document(shareId, data);
    onSetComments(updatedComments);
  };

  // Remove comment
  const removeComment = () => {
    onSetActive(null);
    if (comment.messages.length === 0) {
      onSetComments((prev) => prev.filter((c) => c.id !== comment.id));
    }
  };

  // On approved change
  const handleApprovedChange = async (e) => {
    let checked = e.target.checked;
    let commentsCopy = [...comments];
    let current = { ...comment };
    const updatedComments = commentsCopy.map((c) =>
      c.id === current.id ? { ...current, approved: checked } : c
    );
    let data = {
      comments: updatedComments,
      updated_at: Date.now(),
    };
    await update_shared_document(shareId, data);
    onSetComments(updatedComments);
    setApproved(checked);
    window.document.body.click();
  };

  // On message change
  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    const splitted = value.split(' ');
    if (splitted[splitted.length - 1].includes('@')) {
      setShowTeamMembersPopup(true);
      let text = splitted[splitted.length - 1].replace('@', '');
      setMentionText(text);
      setFilteredTeamMembers(
        sharedWith.filter((m) => m.search.includes(text.toLowerCase()))
      );
    } else {
      setShowTeamMembersPopup(false);
      setFilteredTeamMembers([]);
    }
  };

  // On member click
  const handleMemberClick = (member) => {
    let r = new RegExp(`@${mentionText}$`);
    setMessage(message.replace(r, '@' + member.name.replace(' ', '')) + ' ');
    setMentionText('');
    setFilteredTeamMembers([]);
    setShowTeamMembersPopup(false);
  };

  // On add new message button click
  const handleAddNewMessageClick = () => {
    if (isAuthenticated) {
      setShowLeaveComment(true);
      let messages = comment.messages;
      let lastMsg = messages[messages.length - 1];
      if (lastMsg && lastMsg.author_email) {
        const authorFound = sharedWith.find(
          (a) => a.email === lastMsg.author_email
        );
        if (authorFound) {
          if (authorFound.email !== user.email) {
            setMessage(`@${authorFound.name} `);
          }
          setFocusMsgField(true);
        }
      }
    } else {
      onShowNotLoggedInModal(true);
    }
  };

  // Render messages
  const renderMessages = () => {
    return (
      <div className="messages-wrapper u-custom-scrollbar">
        {comment.messages.map((message, idx) => (
          <Message
            key={idx}
            index={idx}
            message={message}
            onSave={messageSaveHandler}
            onDelete={messageDeleteHandler}
            onCommentDelete={commentDeleteHandler}
            user={user}
            isAuthenticated={isAuthenticated}
          />
        ))}
      </div>
    );
  };

  // Render add new message
  const renderAddNewMessage = () => {
    return (
      <Button
        className="add-new-comment"
        onButtonClick={handleAddNewMessageClick}
        text={t('share.add_new_comment')}
        outlineLight
        medium
        fullWidth
      />
    );
  };

  // Render leave a comment
  const renderLeaveComment = (messages) => {
    return (
      <>
        {messages.length === 0 ? (
          <h5>{t('share.leave_comment')}</h5>
        ) : !showLeaveComment ? (
          renderAddNewMessage()
        ) : null}
        {(messages.length === 0 || showLeaveComment) && (
          <div className="message-field-wrapper">
            {showTeamMembersPopup && (
              <div className="team-members-popup u-custom-scrollbar u-custom-scrollbar--3">
                {!mentionText ? (
                  <div className="team-members-popup__inner">
                    <div className="icon">
                      <PersonAdd />
                    </div>
                    <p className="info">{t('external_comments.info')}</p>
                  </div>
                ) : (
                  <div className="team-members-popup__inner">
                    <ul>
                      {filteredTeamMembers.map((m, idx) => {
                        let thumbStyle = {};
                        if (m.image_url) {
                          thumbStyle = {
                            backgroundImage: `url(${m.image_url})`,
                          };
                        }
                        return (
                          <li key={idx} onClick={() => handleMemberClick(m)}>
                            <div className="thumb" style={thumbStyle}>
                              {!m.image_url && (
                                <LetterCircle
                                  data={m.name}
                                  hideTooltip={true}
                                />
                              )}
                            </div>
                            <p>{m.name}</p>
                            <p className="email">{m.email}</p>
                          </li>
                        );
                      })}
                      {filteredTeamMembers.length === 0 && (
                        <li className="no-matches">
                          {t('external_comments.no_matches')}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}
            <Textarea
              value={message}
              onChange={handleMessageChange}
              dashboardModal
              focusField={focusMsgField}
              onSetFocusField={setFocusMsgField}
            />
            <div className="btn-wrapper">
              <Button
                primary
                text={t('general.send_to')}
                onButtonClick={sendCommentHandler}
                disabled={message.trim() === ''}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  let dropdownStyle = {
    left: 0,
    top: 'calc(100% + 15px)',
    right: 'auto',
    bottom: 'auto',
  };
  if (comment.left > 435) {
    dropdownStyle.left = 'auto';
    dropdownStyle.right = 0;
  }
  if (overlayEl && comment.top + 400 > overlayEl.offsetHeight) {
    dropdownStyle.top = 'auto';
    dropdownStyle.bottom = 'calc(100% + 15px)';
  }

  return (
    <div
      className={`comment ${active ? 'active' : ''}`}
      style={{
        top: comment.top,
        left: comment.left,
        zIndex: active ? 9999 : index + 1,
      }}
    >
      <div
        className="comment__box"
        onMouseDown={(e) => onMouseDown(e, comment, index)}
      >
        {!approved ? index + 1 : <Check />}
        {newMessages > 0 && (
          <span className="messages-badge">{newMessages}</span>
        )}
      </div>
      {active && (
        <div className="comment__dropdown" style={dropdownStyle}>
          <IconButton
            round
            small
            light
            icon={<Close />}
            className="close-btn"
            onButtonClick={removeComment}
          />
          {comment.messages.length > 0 && renderMessages()}
          {renderLeaveComment(comment.messages)}
        </div>
      )}
    </div>
  );
};

export const Message = ({
  message,
  user,
  onSave,
  onDelete,
  onCommentDelete,
  index,
  isAuthenticated,
}) => {
  const { t } = useContext(GlobalContext);
  const [text, setText] = useState(message.text || '');
  const [showEditText, setShowEditText] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  // On save click
  const saveClickHandler = () => {
    setShowEditText(false);
    onSave(message.id, text.trim());
  };

  // On delete click
  const deleteClickHandler = () => {
    setShowConfirm(true);
    setShowEditText(false);
  };

  // On click away
  const clickAwayHandler = () => {
    setShowConfirm(false);
    setShowEditText(false);
  };

  // Render edit message
  const renderEditMessage = () => {
    return (
      <div className="msg__edit">
        <Textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          dashboardModal
        />
        <div className="btn-wrapper--2">
          <Button
            primary
            text={t('general.save')}
            onButtonClick={saveClickHandler}
            disabled={text.trim() === '' || text.trim() === message.text}
          />
          <Button
            transparent
            text={t('general.cancel')}
            onButtonClick={() => setShowEditText(false)}
          />
        </div>
      </div>
    );
  };

  let firstLetter;
  if (message.author) firstLetter = message.author.slice(0, 1);
  else if (message.author_email) firstLetter = message.author_email.slice(0, 1);
  let splittedMessage = message.text.split(/\r\n|\r|\n/);

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className="msg">
        <div className="msg__thumb">{firstLetter}</div>
        <div className="msg__info">
          <div className="msg__info_head">
            <p className="author">{message.author}</p>
            <p className="time">{moment(message.created).fromNow()}</p>
            {isAuthenticated && user.email === message.author_email && (
              <div className="actions">
                <CustomTooltip content={t('general.edit')} spaceSm>
                  <button onClick={() => setShowEditText(true)}>
                    <EditOutlined />
                  </button>
                </CustomTooltip>
                <CustomTooltip content={t('general.delete')} spaceSm>
                  <button onClick={deleteClickHandler}>
                    <DeleteOutlined />
                  </button>
                </CustomTooltip>
              </div>
            )}
          </div>
          <div className="msg__info_body">
            {showEditText ? (
              renderEditMessage()
            ) : (
              <p>
                {splittedMessage.map((msg, idx) => (
                  <span key={idx}>{msg}</span>
                ))}
              </p>
            )}
          </div>
        </div>
        <div>
          {showConfirm && (
            <div className="msg__confirm">
              <p>
                {index === 0
                  ? t('share.delete_conversation')
                  : t('share.delete_comment')}
              </p>
              <div className="actions">
                <Button
                  danger
                  small
                  text={t('general.yes')}
                  onButtonClick={() =>
                    index === 0 ? onCommentDelete() : onDelete(message.id)
                  }
                />
                <Button
                  transparent
                  small
                  text={t('general.cancel')}
                  onButtonClick={() => setShowConfirm(false)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SharedPdf;

import React, { createContext, useReducer, useState } from 'react';

import loaderReducer from './loaderReducer';
import { SET_LOADING, RESET_STATE } from '../types';

export const LoaderContext = createContext();

const LoaderState = ({ children }) => {
  const initialState = {
    loading: false
  }

  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [showGlobalResponseLoader, setShowGlobalResponseLoader] = useState(false)
  const [globalResponseLoaderText, setGlobalResponseLoaderText] = useState('')
  const [state, dispatch] = useReducer(loaderReducer, initialState);

  const setLoading = (value) => {
    dispatch({
      type: SET_LOADING,
      payload: value
    });
  }

  // Reset state
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
      payload: initialState
    });
  }

  return <LoaderContext.Provider value={{
    loading: state.loading,
    loadingOverlay,
    setLoading,
    setLoadingOverlay,
    resetLoaderState: resetState,
    showGlobalResponseLoader,
    setShowGlobalResponseLoader,
    globalResponseLoaderText,
    setGlobalResponseLoaderText,
  }}>
    {children}
  </LoaderContext.Provider>
}

export default LoaderState;
import React, { createContext, useReducer, useState } from 'react'

import reducer from './variablesReducer'
import { GET_VARIABLES, GET_VARIABLE_CATEGORIES, SET_VARIABLES, SET_VARIABLE_CATEGORIES, RESET_STATE } from '../types'
import { fetch_variables, fetch_categories, create_variable, create_category, update_variable, update_category, delete_variable, delete_category } from '../../services/variables_and_categories'
import { sortArrayOfObjects, getSelectedTeamFromLS, getTeamSortingData } from '../../utils'

export const VariablesContext = createContext();

const selectedTeam = getSelectedTeamFromLS()
const sortingData = getTeamSortingData()

let defaultSort = { value: 'variable', order: 'asc' }
if(selectedTeam && sortingData && sortingData[selectedTeam] && sortingData[selectedTeam].variables) {
  defaultSort = { value: sortingData[selectedTeam].variables.value || 'variable', order: sortingData[selectedTeam].variables.order || 'asc' }
}

const VariablesState = ({ children }) => {
  const initialState = {
    variables: [],
    variablesFetched: false, 
    varsCategories: [],
    varsCategoriesFetched: false, 
  }

  const [selectedCategory, setSelectedCategory] = useState('all')
  const [varsSort, setVarsSort] = useState(defaultSort)
  const [varsCategoriesSort, setVarsCategoriesSort] = useState({ sort: 'display_name', order: 'asc' })
  const [varsReset, setVarsReset] = useState(false)
  const [variablesSelectedFilters, setVariablesSelectedFilters] = useState({
    category: 'all',
    search: ''
  })
  const [state, dispatch] = useReducer(reducer, initialState);

  // Fetch variables
  const fetchVariables = async (team, sort = null) => {
    // console.log('fetch variables', team)
    try {
      let categories = []
      if(!state.varsCategoriesFetched) {
        categories = await fetchVarsCategories(team) 
      }else {
        categories = state.varsCategories
      }
      const res = await fetch_variables(team)
      if(res.error) {
        dispatch({
          type: GET_VARIABLES,
          payload: []
        })
        throw new Error(res.error?.message || 'variables cannot be fetched')
      }
      let arr = []
      if(sort) {
        arr = sortArrayOfObjects(res.map((v, idx) => {
          let cat = categories.find(c => c.id === v.category)
          return {
            ...v, 
            category_sort: cat ? cat.display_name : '', 
            // index: idx + 1
          }
        }), sort.by, sort.order === 'desc' ? 'asc' : 'desc') 
      }else {
        arr = sortArrayOfObjects(res.map((v, idx) => {
          let cat = categories.find(c => c.id === v.category)
          return {
            ...v, 
            category_sort: cat ? cat.display_name : '', 
            // index: idx + 1
          }
        }), varsSort.value, varsSort.order) 
      }
      dispatch({
        type: GET_VARIABLES,
        payload: arr
      })
    }catch(err) {
      console.log('Variables err', err);
    }
  }

  // Set variables
  const setVariables = (value) => {
    dispatch({
      type: SET_VARIABLES,
      payload: value
    });
  }

  // Fetch variable categories
  const fetchVarsCategories = async (team, order = null) => {
    try {
      const res = await fetch_categories(team)
      if(res.error) {
        dispatch({
          type: GET_VARIABLE_CATEGORIES,
          payload: []
        })
        throw new Error(res.error?.message || 'variable categories cannot be fetched')
      }
      dispatch({
        type: GET_VARIABLE_CATEGORIES,
        payload: sortArrayOfObjects(res.map((c, idx) => ({...c, index: idx + 1})), 'display_name', order ? order === 'desc' ? 'asc' : 'desc' : 'desc') 
      })
      return res
    }catch(err) {
      console.log('Categories err', err);
    }
  }

  // Set variable categories
  const setVarsCategories = (value) => {
    dispatch({
      type: SET_VARIABLE_CATEGORIES,
      payload: value
    });
  }

  // Create variable 
  const createVariable = async (team, data) => {
    try {
      const res = await create_variable(team, data)
      // console.log(res)
      if(res.success) {
        let updated = [...state.variables]
        let cat = state.varsCategories.find(c => c.id === data.category)
        updated.push({ 
          ...data, 
          id: res.data.id, 
          category_sort: cat ? cat.display_name : '', 
          meta: { created: Date.now(), updated: Date.now() } 
        })
        setVariables(sortArrayOfObjects(updated.map((v, idx) => ({...v, index: idx + 1})), varsSort.value, varsSort.order) )
      }
    } catch (err) {
      console.log('create variable err', err)
    }
  }

  // Update variable
  const updateVariable = async (team, data, id) => {
    try {
      const res = await update_variable(team, data, id)
      // console.log(res)
      if(res.success) {
        let updated = [...state.variables].map(v => v.id === id ? {...v, ...data, meta: {...v.meta, updated: Date.now()}} : v)
        setVariables(sortArrayOfObjects(updated.map((v, idx) => ({...v, index: idx + 1})), varsSort.value, varsSort.order) )
      }
    } catch (err) {
      console.log('update variable err', err)
    }
  }

  // Delete variable
  const deleteVariable = async (team, id) => {
    try {
      const res = await delete_variable(team, id)
      // console.log(res)
      if(res) {
        let updated = [...state.variables].filter(v => v.id !== id)
        setVariables(sortArrayOfObjects(updated.map((v, idx) => ({...v, index: idx + 1})), varsSort.value, varsSort.order) )
      }
    } catch (err) {
      console.log('delete variable err', err)
    }
  }

  // Create category 
  const createCategory = async (team, data, order = null) => {
    try {
      const res = await create_category(team, data)
      // console.log(res)
      if(res.success) {
        let updated = [...state.varsCategories]
        updated.push({ ...data, id: res.data.id })
        setVarsCategories(sortArrayOfObjects(updated.map((c, idx) => ({...c, index: idx + 1})), varsCategoriesSort.sort, varsCategoriesSort.order === 'desc' ? 'asc' : 'desc') )
      }
    } catch (err) {
      console.log('create category err', err)
    }
  }

  // Update category 
  const updateCategory = async (team, data, id, order = null) => {
    try {
      const res = await update_category(team, data, id)
      // console.log(res)
      if(res.success) {
        let updated = [...state.varsCategories].map(c => c.id === id ? {...c, ...data} : c)
        setVarsCategories(sortArrayOfObjects(updated.map((c, idx) => ({...c, index: idx + 1})), varsCategoriesSort.sort, varsCategoriesSort.order === 'desc' ? 'asc' : 'desc') )
      }
    } catch (err) {
      console.log('update category err', err)
    }
  }

  // Delete category 
  const deleteCategory = async (team, id, order = null) => {
    try {
      const res = await delete_category(team, id)
      // console.log(res)
      if(res) {
        let updated = [...state.varsCategories].filter(c => c.id !== id)
        setVarsCategories(sortArrayOfObjects(updated.map((c, idx) => ({...c, index: idx + 1})), varsCategoriesSort.sort, varsCategoriesSort.order === 'desc' ? 'asc' : 'desc') )
      }
    } catch (err) {
      console.log('delete category err', err)
    }
  }

  // Delete multiple vars
  const deleteMultipleVars = (ids) => {
    const vars = [...state.variables]
    const updated = vars.filter(v => !ids.includes(v.id))
    setVariables(updated)
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, variablesFetched: true } : initialState,
      payload: initialState,
    });
    setSelectedCategory('all')
    setVarsSort({ value: 'variable', order: 'asc' })
    setVarsCategoriesSort({ sort: 'display_name', order: 'asc' })
    setVarsReset(true)
  }

  return(
    <VariablesContext.Provider value={{
      variables: state.variables,
      variablesFetched: state.variablesFetched,
      varsCategories: state.varsCategories,
      varsCategoriesFetched: state.varsCategoriesFetched,
      fetchVariables,
      fetchVarsCategories,
      setVariables,
      setVarsCategories,
      createVariable,
      updateVariable,
      deleteVariable,
      createCategory,
      updateCategory,
      deleteCategory,
      resetVarsState: resetState,
      selectedCategory,
      setSelectedCategory,
      varsSort,
      setVarsSort,
      varsCategoriesSort,
      setVarsCategoriesSort,
      deleteMultipleVars,
      varsReset,
      setVarsReset,
      variablesSelectedFilters,
      setVariablesSelectedFilters
    }}>
      {children}
    </VariablesContext.Provider>
  );
};

export default VariablesState;
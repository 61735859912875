import { useContext } from 'react'

import { GlobalContext, LoaderContext, NotificationContext, ApprovalsContext } from '../context'
import { delete_approval } from '../services/approvals'

const useApprovalsActions = () => {
  const { t } = useContext(GlobalContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { deleteMultipleApprovals } = useContext(ApprovalsContext)

  // Delete approvals
  const deleteApprovals = async ({ selectedApprovals, setSelectedApprovals, setAllChecked, setShowDeleteApprovalsAlert }) => {
    setShowGlobalResponseLoader(true)
    let promises = []
    for(let i = 0; i < selectedApprovals.length; i++) {
      promises.push(delete_approval(selectedApprovals[i].id))
    } 

    if(promises.length > 0) {
      try {
        await Promise.all(promises)
        await deleteMultipleApprovals(selectedApprovals.map(a => a.id))
        setNotification({ msg: t('approvals.deleted'), type: 'success' })
        setSelectedApprovals([])
        setAllChecked(false)
        setShowDeleteApprovalsAlert(false)
      } catch (err) {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
    setShowGlobalResponseLoader(false)
  }

  return {
    deleteApprovals,
  }
}

export default useApprovalsActions
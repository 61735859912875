import { useContext, useCallback } from 'react'

import { TaskContext, DocumentsContext, TeamContext, GlobalContext, NotificationContext, LoaderContext } from '../context'

const useTaskActions = () => {
  const { updateTask, deleteTask, createTask, tasks } = useContext(TaskContext)
  const { getDocumentById, updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { activeTeamMember, selectedTeam } = useContext(TeamContext)
  const { t } = useContext(GlobalContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)

  // Change task status
  const changeTaskStatus = async ({ task }) => {
    const { id, completed } = task
    const relatedDocument = getDocumentById(task.relatedDocument)
    setShowGlobalResponseLoader(true)
    if(!completed) {
      await updateTask({ completed: true, last_modified_by: activeTeamMember.id }, id, async () => {
        if(relatedDocument && relatedDocument.tasks) {
          const updatedTasks = relatedDocument.tasks.map(task => task.id === id ? { ...task, completed: true } : task )
          await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, relatedDocument)
        }
        setNotification({ msg: t('notification.task_completed'), type: 'success' })
      }, (err) => {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }else {
      await updateTask({ completed: false, last_modified_by: activeTeamMember.id }, id, async () => {
        if(relatedDocument && relatedDocument.tasks) {
          const updatedTasks = relatedDocument.tasks.map(task => task.id === id ? { ...task, completed: false } : task )
          await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, relatedDocument)
        }
        setNotification({ msg: t('notification.task_updated'), type: 'success' })
      }, (err) => {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }
    setShowGlobalResponseLoader(false)
  }

  // Delete task
  const deleteSingleTask = async ({ task, refreshTasks }) => {
    setShowGlobalResponseLoader(true)
    deleteTask(task.id, async () => {
      if(task.relatedDocument){
        const relatedDocument = getDocumentById(task.relatedDocument)
        if(relatedDocument) {
          const docTasks = relatedDocument.tasks || []
          const updatedTasks = docTasks.filter(tsk => tsk.id !== task.id)
          await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, relatedDocument)
        }
      }
      setNotification({ msg: t('notification.task_deleted'), type: 'success' })
      setShowGlobalResponseLoader(false)
      refreshTasks && refreshTasks()
    }, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  // Create new task
  const createNewTask = useCallback(async ({ taskName, setTaskNameError, selectedDocument, taskAssignedTo, selectedDate, onClose, refreshTasks }) => {
    if(taskName === '') {
      setTaskNameError(true)
      return setNotification({ msg: t('notification.fill_in_all_required_fields'), type: 'danger' })
    }
    // Check if task with this name already exist for this document
    if(selectedDocument && Object.keys(selectedDocument).length > 0) {
      let selectedDocTasks
      if(selectedDocument.id) selectedDocTasks = selectedDocument.tasks || [];
      const findTask = selectedDocument.id 
        ? selectedDocTasks.find(t => t.name.toLowerCase() === taskName.trim().toLowerCase())
        : tasks.find(t => t.name.toLowerCase() === taskName.trim().toLowerCase() && t.relatedDocument === null)
      if(findTask) {
        return selectedDocument.id 
          ? setNotification({ msg: t('notification.task_exists_for_selected_document'), type: 'warning' })
          : setNotification({ msg: t('notification.task_already_exists'), type: 'warning' })
      }
    }

    const data = {
      relatedDocument: selectedDocument?.id ? selectedDocument.id : null,
      name: taskName,
      completed: false,
      meta: {
        created: Date.now(),
        updated: Date.now()
      },
      team: selectedTeam?.id
    }
    if(taskAssignedTo) {
      data.assigned_to = taskAssignedTo.id
    }
    if(selectedDate) {
      data.due_date = selectedDate
    }
    setShowGlobalResponseLoader(true)
    
    await createTask(data, selectedTeam?.id, async (taskId) => {
      if(selectedDocument?.id) {
        data.id = taskId
        await updateDocument({ tasks: selectedDocument.tasks ? [...selectedDocument.tasks, data] : [data], last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDocument)
      }
      setNotification({ msg: t('notification.task_created'), type: 'success' })
      setShowGlobalResponseLoader(false)
      refreshTasks && refreshTasks()
      onClose()
    }, (err) => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }, [activeTeamMember, createTask, selectedTeam, setNotification, setShowGlobalResponseLoader, t, tasks, updateDocument])

  // Update task
  const updateSingleTask = async ({ data, task, doc, removeFromPrev = false }) => {
    data.last_modified_by = activeTeamMember.id
    await updateTask(data, task.id, async () => {
      if(doc && doc.id) { // add task to document
        const docTasks = doc.tasks ? doc.tasks : []
        let updatedTasks = [...docTasks]
        if(docTasks.find(tsk => tsk.id === task.id)) {
          updatedTasks = docTasks.map(tsk => tsk.id === task.id ? {...tsk, ...data} : tsk)
        }else {
          docTasks.push({...task, ...data})
          updatedTasks = docTasks
        }
        await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, doc)
      }
      if(removeFromPrev && task.relatedDocument) { // remove task from prev document
        const relatedDoc = getDocumentById(task.relatedDocument)
        if(relatedDoc && relatedDoc.tasks?.find(tsk => tsk.id === task.id)) {
          const docTasks = relatedDoc.tasks
          const updatedTasks = docTasks.filter(tsk => tsk.id !== task.id)
          await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, relatedDoc)
        }
      }
      setNotification({ msg: t('notification.task_updated'), type: 'success' })
    }, (err) => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  return {
    changeTaskStatus,
    deleteSingleTask,
    createNewTask,
    updateSingleTask,
  }
}

export default useTaskActions
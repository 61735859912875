import { useState, useContext } from 'react'

import { Modal, Textarea } from '../new_ui'
import { GlobalContext } from '../../context'
import { useTeamActions } from '../../hooks'

const InviteUsersModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { inviteUsers } = useTeamActions()
  const [value, setValue] = useState('')

  // On submit/save button click
  const handleSubmit = async () => {
    await inviteUsers({ value, onClose })
  }

  return (
    <Modal 
      onClose={onClose}
      title={t('team.modal_title')}
      saveBtnText={t('general.send_to')}
      onSave={handleSubmit}
      className="centered-head"
    >
      <div className="invite-users">
        <p>{t('team.to')}</p>
        <Textarea value={value} onChange={(e) => setValue(e.target.value)} formEl thickBorder />
      </div>
    </Modal>
  )
}

export default InviteUsersModal
import { useEffect, useState } from 'react'

const ContactBox = ({ contact, mappedFields, onSetSelectedContacts, selectedContacts, index }) => {
  const [active, setActive] = useState(false)
  
  // If contact is selected make it active
  useEffect(() => {
    if(selectedContacts.includes(index)) {
      setActive(true)
    }else {
      setActive(false)
    }
  }, [selectedContacts, index])

  // On box click
  const handleBoxClick = () => {
    setActive(!active)
    let contacts = [...selectedContacts]
    if(selectedContacts.indexOf(index) !== -1) {
      contacts = contacts.filter(i => i !== index)
    }else {
      contacts.push(index)
    }
    onSetSelectedContacts(contacts)
  }

  return (
    <div className={`contact-box-v2 ${active ? 'active' : ''}`} onClick={handleBoxClick}>
      <div className="icon">
        {active ? <span className="material-symbols-outlined filled">check_box</span> : <span className="material-symbols-outlined">check_box_outline_blank</span>}
      </div>
      {contact.map((item, idx) => {
        if(mappedFields.find(f => f.column === idx && f.active)) {
          return <p key={idx}>{item}</p>
        }
        return null
      })}
      {/* <p className="contact-box__name">Name: {mappedFields.find(f => f.key === 'first_name') &&}</p>
      <p className="contact-box__email">damir@appvelvet.com</p> */}
    </div>
  )
}

export default ContactBox
import React, { createContext, useReducer, useState } from 'react'

import sharedTemplatePagesReducer from './sharedTemplatePagesReducer'
import { GET_SHARED_TEMPLATE_PAGES, SET_SHARED_TEMPLATE_PAGES, GET_SINGLE_SHARED_TEMPLATES, SET_SINGLE_SHARED_TEMPLATES, RESET_STATE } from '../types'
import { fetch_shared_template_pages, create_shared_template_page, update_shared_template_page, delete_shared_template_page } from '../../services/shared_template_pages'
import { get_single_shared_templates, update_shared_template, delete_shared_template, create_shared_template } from '../../services/shared_templates'
import { sortArrayOfObjects } from '../../utils'

export const SharedTemplatePagesContext = createContext()

const SharedTemplatePagesState = ({ children }) => {
  const initialState = {
    sharedTemplatePages: [],
    sharedTemplatePagesFetched: false,
    singleSharedTemplates: [],
    singleSharedTemplatesFetched: false,
  }
  const [globalSharedPageTemplates, setGlobalSharedPageTemplates] = useState({})
  const [state, dispatch] = useReducer(sharedTemplatePagesReducer, initialState)

  // Fetch template pages
  const fetchSharedTemplatePages = async (teamId) => {
    try {
      const res = await fetch_shared_template_pages(teamId)
      const arr = []
      for(let id in res) {
        const item = {...res[id]}
        item.id = id
        arr.push(item)
      }
      dispatch({
        type: GET_SHARED_TEMPLATE_PAGES,
        payload: sortArrayOfObjects(arr, 'meta.created', 'asc'),
      })
    }catch(err) {
      console.log('SHARED TEMPLATE PAGES FETCH err', err)
    }
  }

  // Fetch single shared templates
  const fetchSingleSharedTemplates = async (teamId) => {
    try {
      const res = await get_single_shared_templates(teamId)
      const arr = []
      for(let id in res) {
        const item = {...res[id]}
        item.id = id
        arr.push(item)
      }
      dispatch({
        type: GET_SINGLE_SHARED_TEMPLATES,
        payload: sortArrayOfObjects(arr, 'meta.created', 'asc'),
      })
    }catch(err) {
      console.log('SINGLE SHARED TEMPLATES FETCH err', err)
    }
  }

  // Set shared template pages
  const setSharedTemplatePages = (arr) => {
    dispatch({
      type: SET_SHARED_TEMPLATE_PAGES,
      payload: arr
    })
  }

  // Set single shared templates
  const setSingleSharedTemplates = (arr) => {
    dispatch({
      type: SET_SINGLE_SHARED_TEMPLATES,
      payload: arr
    })
  }

  // Create shared template page 
  const createSharedTemplatePage = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const id = await create_shared_template_page(data)
      let item = {...data, id}
      let newArr = [...state.sharedTemplatePages]
      newArr.push(item)
      setSharedTemplatePages(sortArrayOfObjects(newArr, 'meta.created', 'asc'))
      onSuccess(id)
    }catch(err) {
      console.log('CREATE SHARED TEMPLATE PAGE err', err)
      onError(err)
    }
  }

  // Create shared template page 
  const createSingleSharedTemplate = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const id = await create_shared_template(data)
      let item = {...data, id}
      let newArr = [...state.singleSharedTemplates]
      newArr.push(item)
      setSingleSharedTemplates(sortArrayOfObjects(newArr, 'meta.created', 'asc'))
      onSuccess(id)
    }catch(err) {
      console.log('CREATE SHARED TEMPLATE PAGE err', err)
      onError(err)
    }
  }

  // Update shared template page 
  const updateSharedTemplatePage = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.sharedTemplatePages]
      await update_shared_template_page(id, data)
      const toUpdate = items.find(item => item.id === id)
      const updatedObj = {
        ...toUpdate,
        ...data
      }
      const updatedArr = [...items].map(item => item.id === id ? updatedObj : item)
      setSharedTemplatePages(sortArrayOfObjects(updatedArr, 'meta.created', 'asc'))
      onSuccess()
    }catch(err) {
      console.log('UPDATE SHARED TEMPLATE PAGE err', err)
      onError(err)
    }
  }

  // Update single shared template 
  const updateSingleSharedTemplate = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.singleSharedTemplates]
      await update_shared_template(id, data)
      const toUpdate = items.find(item => item.id === id)
      const updatedObj = {
        ...toUpdate,
        ...data
      }
      const updatedArr = [...items].map(item => item.id === id ? updatedObj : item)
      setSingleSharedTemplates(sortArrayOfObjects(updatedArr, 'meta.created', 'asc'))
      onSuccess()
    }catch(err) {
      console.log('UPDATE SINGLE SHARED TEMPLATE err', err)
      onError(err)
    }
  }

  // Delete shared template page
  const deleteSharedTemplatePage = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.sharedTemplatePages]
      await delete_shared_template_page(id)
      const updatedArr = [...items].filter(item => item.id !== id)
      setSharedTemplatePages(updatedArr)
      onSuccess()
    } catch (err) {
      console.log('DELETE SHARED TEMPLATE PAGES err', err)
      onError(err)
    }
  }

  // Delete single shared template
  const deleteSingleSharedTemplate = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.singleSharedTemplates]
      await delete_shared_template(id)
      const updatedArr = [...items].filter(item => item.id !== id)
      setSingleSharedTemplates(updatedArr)
      onSuccess()
    } catch (err) {
      console.log('DELETE SINGLE SHARED TEMPLATE err', err)
      onError(err)
    }
  }

  // Renew page url
  const renewSharedTemplatePageUrl = async (page, onSuccess = () => {}, onError = () => {}) => {
    try {
      const items = [...state.sharedTemplatePages]
      await delete_shared_template_page(page.id)
      let data = {...page}
      if(data.id) delete data.id 
      data.meta = {
        ...data.meta,
        updated: Date.now(),
      }
      data.ids_before_renew = [...data.ids_before_renew, page.id]
      data.renewed = true 
      const id = await create_shared_template_page(data)
      data.id = id
      const updatedItems = items.map(item => item.id === page.id ? data : item)
      setSharedTemplatePages(updatedItems)
      onSuccess(id)
    } catch (err) {
      console.log('RENEW SHARED TEMPLATE PAGE URL error', err)
      onError(err)
    }
  }

  // Renew single template url
  const renewSingleTemplateUrl = async (template, onSuccess = () => {}, onError = () => {}) => {
    try {
      let data = {
        ...template,
        meta: {
          ...template.meta,
          updated: Date.now()
        },
        renewed: true,
      }
      if(data.id) delete data.id
      const items = [...state.singleSharedTemplates]
      await delete_shared_template(template.id)
      const id = await create_shared_template(data)
      if(id) {
        data.id = id
        const updatedItems = items.map(item => item.id === template.id ? data : item)
        setSingleSharedTemplates(updatedItems)
        onSuccess(id)
      }
    } catch (err) {
      console.log('RENEW SHARED TEMPLATE PAGE URL error', err)
      onError(err)
    }
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, sharedTemplatePagesFetched: true } : initialState,
      payload: initialState,
    })
  }

  return(
    <SharedTemplatePagesContext.Provider value={{
      sharedTemplatePages: state.sharedTemplatePages,
      sharedTemplatePagesFetched: state.sharedTemplatePagesFetched,
      singleSharedTemplates: state.singleSharedTemplates,
      singleSharedTemplatesFetched: state.singleSharedTemplatesFetched,
      fetchSharedTemplatePages,
      fetchSingleSharedTemplates,
      createSharedTemplatePage,
      deleteSharedTemplatePage,
      updateSharedTemplatePage,
      renewSharedTemplatePageUrl,
      createSingleSharedTemplate,
      updateSingleSharedTemplate,
      deleteSingleSharedTemplate,
      renewSingleTemplateUrl,
      resetSharedTemplatePagesState: resetState,
      globalSharedPageTemplates,
      setGlobalSharedPageTemplates,
    }}>
      {children}
    </SharedTemplatePagesContext.Provider>
  )
}

export default SharedTemplatePagesState
import React, { useState, useRef, useContext, useEffect } from 'react' 
import { useHistory } from 'react-router-dom'
import { DeleteOutlineOutlined, DragIndicatorOutlined } from '@material-ui/icons'
import { ReactSortable } from 'react-sortablejs'

import ActionsDropdown from '../../UI/ActionsDropdown'
import Alert from '../../UI/Alert'
import Button from '../../UI/Button'
import CustomTooltip from '../../UI/CustomTooltip'
import IconButton from '../../UI/IconButton'
import Input from '../../UI/Input'
import Loader from '../../UI/Loader'
import Modal from '../../UI/Modal'
import ResponseLoader from '../../UI/ResponseLoader'
import Textarea from '../../UI/Textarea'
import ListOfTemplatesModal from './ListOfTemplatesModal'

import { NotificationContext, DocumentsContext, TeamContext, SharedTemplatePagesContext, GlobalContext } from '../../../context'
import { upload_file, delete_file } from '../../../services/firestore'
import { create_shared_template, delete_shared_template, get_shared_templates_by_ids, update_shared_template } from '../../../services/shared_templates'
import { create_shared_template_page, update_shared_template_page, delete_shared_template_page } from '../../../services/shared_template_pages'
import { getFileData, divideArrayToMultipleArrays } from '../../../utils'

const TemplateShareModal = ({ onClose, template, mode = 'create', sharedPageId, isTemplatePage = false, onEditTemplatePage = () => {}, sharedPage, pageTemplates }) => {
  const { t } = useContext(GlobalContext)
  const { templatesArr, getSingleTemplate } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { createSharedTemplatePage, sharedTemplatePagesFetched, sharedTemplatePages, deleteSharedTemplatePage, updateSharedTemplatePage, setGlobalSharedPageTemplates, globalSharedPageTemplates, renewSharedTemplatePageUrl } = useContext(SharedTemplatePagesContext)
  const [logo, setLogo] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isGenerated, setIsGenerated] = useState(false)
  const [generatedLink, setGeneratedLink] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const [templatesToAdd, setTemplatesToAdd] = useState([])
  const [templateDescriptions, setTemplateDescriptions] = useState({})
  const [templateTitles, setTemplateTitles] = useState({})
  const [showListOfTemplatesModal, setShowListOfTemplatesModal] = useState(false)
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [showDeletePageAlert, setShowDeletePageAlert] = useState(false)
  const [shouldFetchPageTemplates, setShouldFetchPageTemplates] = useState(false)
  const [pageTemplatesToFetch, setPageTemplatesToFetch] = useState([])
  const [fetchedPageTemplates, setFetchedPageTemplates] = useState([])
  const [templatesFetched, setTemplatesFetched] = useState(false)
  const [page, setPage] = useState({})
  const [showTemplateRemoveAlert, setShowTemplateRemoveAlert] = useState(false)
  const [templateToRemove, setTemplateToRemove] = useState(null)
  const dropzoneFileRef = useRef()
  const history = useHistory()

  // If mode is create add template to templatesToAdd
  useEffect(() => {
    const fetchSingle = async () => {
      const res = await getSingleTemplate(template.id, true)
      if(!res) return 
      let tmplt = {...res}
      setTemplatesToAdd([{ ...res }])
      setTemplateDescriptions({ [template.id]: '' })
      setTemplateTitles({ [template.id]: tmplt.name })
    }
    
    if(mode === 'create' && template) {
      fetchSingle()
    }else if(mode === 'edit') {
      let p = sharedTemplatePages.find(p => p.id === sharedPageId)
      if(isTemplatePage) {
        p = sharedPage
      }
      setPage(p)
      if(p) {
        setTitle(p.title)
        setDescription(p.description)
        if(p.logo_url && p.logo_path) {
          setLogoPreview(p.logo_url)
          const logoName = getLogoNameFromPath(p.logo_path)
          setLogo({ name: logoName, logo_path: p.logo_path, logo_url: p.logo_url })
        }
        if(p.shared_templates) {
          setShouldFetchPageTemplates(true)
          setPageTemplatesToFetch(p.shared_templates)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  // Fetch page templates if in edit mode 
  useEffect(() => {
    if(shouldFetchPageTemplates && !templatesFetched) {
      const fetchTmplts = async () => {
        // console.log('fetch templates')
        try {
          const tmpltsRes = await get_shared_templates_by_ids(pageTemplatesToFetch)
          setTemplatesFetched(true)
          if(Object.keys(tmpltsRes).length > 0) {
            let tmpltsArr = []
            let descriptions = {}
            let titles = {}
            for(let key in tmpltsRes) {
              tmpltsArr.push({...tmpltsRes[key], id: key})
              descriptions[tmpltsRes[key].template_id] = tmpltsRes[key].description
              titles[tmpltsRes[key].template_id] = tmpltsRes[key].name
            }
            setGlobalSharedPageTemplates({
              ...globalSharedPageTemplates,
              [sharedPageId]: tmpltsArr
            })
            let arr = []
            if(page.templates_order) {
              page.templates_order.forEach((tmpltId) => {
                const findTmplt = tmpltsArr.find(tmplt => tmplt.template_id === tmpltId)
                if(findTmplt) {
                  arr.push(findTmplt)
                }
              })
              // Add current template if not previously added - the one from the template row where share button was clicked 
              if(template && !arr.find(tmplt => tmplt.template_id === template.id)) {
                arr.push({ ...template, template_id: template.id })
                descriptions[template.id] = ''
                titles[template.id] = template.name
              }
              setTemplatesToAdd(arr)
              setFetchedPageTemplates(arr)
            }else {
              // Add current template if not previously added - the one from the template row where share button was clicked 
              if(template && !tmpltsArr.find(tmplt => tmplt.template_id === template.id)) {
                tmpltsArr.push({ ...template, template_id: template.id })
                descriptions[template.id] = ''
                titles[template.id] = template.name
              }
              setTemplatesToAdd(tmpltsArr)
              setFetchedPageTemplates(tmpltsArr)
            }
            setTemplateDescriptions(descriptions)
            setTemplateTitles(titles)
          }
          setShouldFetchPageTemplates(false)
        } catch (err) {
          console.log(err)
          setShouldFetchPageTemplates(false)
        }
      }

      if(isTemplatePage && pageTemplates && !globalSharedPageTemplates[sharedPageId]) {
        setTemplatesFetched(true)
        let tmpltsArr = []
        let descriptions = {}
        let titles = {}
        pageTemplates.forEach(tmplt => {
          tmpltsArr.push(tmplt)
          descriptions[tmplt.template_id] = tmplt.description
          titles[tmplt.template_id] = tmplt.name
        })
        let arr = []
        if(page.templates_order) {
          page.templates_order.forEach((tmpltId) => {
            const findTmplt = tmpltsArr.find(tmplt => tmplt.template_id === tmpltId)
            if(findTmplt) {
              arr.push(findTmplt)
            }
          })
          // Add current template if not previously added - the one from the template row where share button was clicked 
          if(template && !arr.find(tmplt => tmplt.template_id === template.id)) {
            arr.push({ ...template, template_id: template.id })
            descriptions[template.id] = ''
            titles[template.id] = template.name
          }
          setTemplatesToAdd(arr)
          setFetchedPageTemplates(arr)
        }else {
          // Add current template if not previously added - the one from the template row where share button was clicked 
          if(template && !tmpltsArr.find(tmplt => tmplt.template_id === template.id)) {
            tmpltsArr.push({ ...template, template_id: template.id })
            descriptions[template.id] = ''
            titles[template.id] = template.name
          }
          setTemplatesToAdd(tmpltsArr)
          setFetchedPageTemplates(tmpltsArr)
        }
        setGlobalSharedPageTemplates({
          ...globalSharedPageTemplates,
          [sharedPageId]: tmpltsArr
        })
        setTemplateDescriptions(descriptions)
        setTemplateTitles(titles)
        setShouldFetchPageTemplates(false)
      }else if(globalSharedPageTemplates[sharedPageId]) {
        setTemplatesFetched(true)
        // console.log('no need to fetch***')
        let tmpltsArr = globalSharedPageTemplates[sharedPageId]
        let descriptions = {}
        let titles = {}
        tmpltsArr.forEach(tmplt => {
          descriptions[tmplt.template_id] = tmplt.description
          titles[tmplt.template_id] = tmplt.name
        })
        let arr = []
        if(page.templates_order) {
          page.templates_order.forEach((tmpltId) => {
            const findTmplt = tmpltsArr.find(tmplt => tmplt.template_id === tmpltId)
            if(findTmplt) {
              arr.push(findTmplt)
            }
          })
          // Add current template if not previously added - the one from the template row where share button was clicked 
          if(template && !arr.find(tmplt => tmplt.template_id === template.id)) {
            arr.push({ ...template, template_id: template.id })
            descriptions[template.id] = ''
            titles[template.id] = template.name
          }
          setTemplatesToAdd(arr)
          setFetchedPageTemplates(arr)
        }else {
          // Add current template if not previously added - the one from the template row where share button was clicked 
          if(template && !tmpltsArr.find(tmplt => tmplt.template_id === template.id)) {
            tmpltsArr.push({ ...template, template_id: template.id })
            descriptions[template.id] = ''
            titles[template.id] = template.name
          }
          setTemplatesToAdd(tmpltsArr)
          setFetchedPageTemplates(tmpltsArr)
        }
        setTemplateDescriptions(descriptions)
        setTemplateTitles(titles)
        setShouldFetchPageTemplates(false)
      }else {
        fetchTmplts()
      }
    }
  }, [shouldFetchPageTemplates, pageTemplatesToFetch, sharedPageId, sharedTemplatePages, template, isTemplatePage, sharedPage, pageTemplates, globalSharedPageTemplates, setGlobalSharedPageTemplates, page, templatesFetched])

  const getLogoNameFromPath = (path) => {
    const splitted = path.split('/')
    const lastSplitted = splitted[splitted.length - 1].split('.')
    const nameWithTimestamp = lastSplitted.slice(0, splitted.length - 2)
    const nameWithTimestampSplitted = nameWithTimestamp.join(',').split('-')
    const name = nameWithTimestampSplitted.slice(0, nameWithTimestampSplitted.length - 1).join('-')
    return name
  }

  // On share button click
  const handleGenerateLink = async () => {
    setGeneratedLink(`${window.location.origin}/shared-template-page/${sharedPageId}`)
    if(page.share) return setIsGenerated(true)

    if(sharedTemplatePagesFetched) {
      await updateSharedTemplatePage({ share: true }, sharedPageId)
    }else {
      await update_shared_template_page(sharedPageId, { share: true })
    }
    setIsGenerated(true)
  }

  // On copy link button click
  const handleCopyUrlToClipboard = () => {
    const input = document.createElement('textarea')
    input.innerHTML = `${window.location.origin}/shared-template-page/${sharedPageId}`
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setNotification({ msg: t('share_email.link_copied'), type: 'info' })
    document.body.click()
  }

  // On renew link button click
  const handleRenewUrl = async () => {
    setShowResponseLoader(true)
    let data = {...page}
    if(data.id) delete data.id 
    data.meta = {
      ...data.meta,
      updated: Date.now(),
    }
    data.ids_before_renew = [...data.ids_before_renew, sharedPageId]
    data.renewed = true 
    if(sharedTemplatePagesFetched) {
      renewSharedTemplatePageUrl(page, (id) => {
        data.id = id 
        setPage(data)
        setGeneratedLink(`${window.location.origin}/shared-template-page/${id}`)
        if(isTemplatePage) {
          history.replace(`/shared-template-page/${id}`)
          onClose()
        }else {
          history.push(`/shared-template-page/${id}`)
        }
        setNotification({ msg: t('share_template.page_renewed_successfully'), type: 'success' })
        setShowResponseLoader(false)
      }, (err) => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
      })
    }else {
      try {
        await delete_shared_template_page(sharedPageId)
        const newId = await create_shared_template_page(data)
        data.id = newId 
        setPage(data)
        setGeneratedLink(`${window.location.origin}/shared-template-page/${newId}`)
        if(isTemplatePage) {
          history.replace(`/shared-template-page/${newId}`)
          onClose()
        }else {
          history.push(`/shared-template-page/${newId}`)
        }
        setNotification({ msg: t('share_template.page_renewed_successfully'), type: 'success' })
        setShowResponseLoader(false)
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
      }
    }
  }

  // On embed template button click
  const handleEmbedTemplate = () => {
    console.log('embed template***')
  }

  // On disable link button click
  const handleDisableLink = async () => {
    if(sharedTemplatePagesFetched) {
      updateSharedTemplatePage({ disabled: !page.disabled }, sharedPageId, () => {
        setPage({...page, disabled: !page.disabled })
        if(!page.disabled) {
          setNotification({ msg: t('share_template.link_disabled'), type: 'info' })
        }else {
          setNotification({ msg: t('share_template.link_enabled'), type: 'info' })
        }
        document.body.click()
      }, (err) => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }else {
      try {
        await update_shared_template_page(sharedPageId, { disabled: !page.disabled })
        setPage({...page, disabled: !page.disabled })
        if(!page.disabled) {
          setNotification({ msg: t('share_template.link_disabled'), type: 'info' })
        }else {
          setNotification({ msg: t('share_template.link_enabled'), type: 'info' })
        }
        document.body.click()
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }

  // On file drag enter
  const handleFileDragEnter = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  // On file drag leave
  const handleFileDragLeave = (e) => {
    e.preventDefault()
  }

  // On file drag 
  const handleFileDragOver = (e) => {
    e.preventDefault()
  }

  // On file drop
  const handleFileDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if(!file) return

    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
    if(!file.type || !allowedTypes.includes(file.type)) {
      setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      return
    }
    setLogo(file)
    getFileData(file, (image) => {
      setLogo(image)
      setLogoPreview(image.data)
    })
  }

  // On dropzone click
  const handleDropzoneClick = () => {
    if(dropzoneFileRef.current) {
      dropzoneFileRef.current.click()
    }
  }

  // On file change
  const handleFileChange = (file) => {
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
    if(!file.type || !allowedTypes.includes(file.type)) {
      setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      return
    }
    setLogo(file)
    getFileData(file, (image) => {
      setLogo(image)
      setLogoPreview(image.data)
    })
  }

  // On logo remove
  const handleRemoveLogo = (e) => {
    e.preventDefault()
    setLogoPreview(null)
    setLogo(null)
  }

  // On save page
  const handleSavePage = async () => {
    if(title.trim() === '') {
      return 
    }
    // console.log('saving page***')
    // console.log(templatesToAdd)
    // console.log(logo)
    setShowResponseLoader(true)
    try {
      // Upload logo
      let logo_path = ''
      let logo_url = ''
      if(logo) {
        setResponseMessage(t('general.uploading_image'))
        const { data, name, format, type } = logo
        const imageName = `${name}-${Date.now()}`
        const res = await upload_file(data, imageName, format, type, `shared-pages-logo/${selectedTeam.id}`)
        logo_url = res.url
        logo_path = `shared-pages-logo/${selectedTeam.id}/${imageName}.${format}`
      }

      // Create templates
      let promises = []
      let templateIds = []
      let newTemplates = []
      templatesToAdd.forEach(tmpl => {
        let data = {
          name: templateTitles[tmpl.id].trim() || tmpl.name,
          template_id: tmpl.id,
          sections: tmpl.sections,
          templateUrl: tmpl.templateUrl,
          meta: {
            created: Date.now(),
            updated: Date.now()
          },
          team: selectedTeam?.id,
          owner: activeTeamMember.id,
          disabled: false,
          single: false,
          description: templateDescriptions[tmpl.id].trim() || ''
        }
        newTemplates.push(data)
        promises.push(create_shared_template(data))
      })
      if(promises.length > 0) {
        setResponseMessage(t('share_template.creating_templates'))
        templateIds = await Promise.all(promises)
        newTemplates = newTemplates.map((tmplt, idx) => ({...tmplt, id: templateIds[idx]}))
      }

      // Create page
      let pageData = {
        title: title.trim(),
        description: description.trim(),
        logo_url,
        logo_path,
        meta: {
          created: Date.now(),
          updated: Date.now()
        },
        team: selectedTeam?.id,
        owner: activeTeamMember.id,
        disabled: false,
        renewed: false,
        share: false,
        templates_order: templatesToAdd.map(tmpl => tmpl.id), // order templates in a page based on template_id
        ids_before_renew: [], // when page is renewed save old page id to this array in case we will need it for some reason
        shared_templates: templateIds
      }
      let pageId
      setResponseMessage(t('share_template.creating_page'))
      if(sharedTemplatePagesFetched) {
        await createSharedTemplatePage(pageData, (id) => {
          pageId = id
        })
      }else {
        const id = await create_shared_template_page(pageData)
        pageId = id
      }
      setShowResponseLoader(false)
      setResponseMessage('')
      setNotification({ msg: t('share_template.page_successfully_created'), type: 'success' })
      setGlobalSharedPageTemplates({
        ...globalSharedPageTemplates,
        [pageId]: newTemplates
      })
      history.push(`/shared-template-page/${pageId}`)
    } catch (err) {
      console.log('creating shared page failed', err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowResponseLoader(false)
      setResponseMessage('')
    } 
  }

  // On template remove button click
  const handleTemplateRemoveTrigger = (id) => {
    setTemplateToRemove(id)
    setShowTemplateRemoveAlert(true)
  }

  // On template remove from list of templates
  const handleTemplateRemove = () => {
    let id = templateToRemove
    if(!id) return

    if(mode === 'create') {
      setTemplatesToAdd(templatesToAdd.filter(tmpl => tmpl.id !== id))
    }else {
      setTemplatesToAdd(templatesToAdd.filter(tmpl => tmpl.template_id !== id))
    }
    let descriptions = {...templateDescriptions}
    if(descriptions.hasOwnProperty(id)) delete descriptions[id]
    setTemplateDescriptions(descriptions)
    let titles = {...templateTitles}
    if(titles.hasOwnProperty(id)) delete titles[id]
    setTemplateTitles(titles)
    setTemplateToRemove(null)
    setShowTemplateRemoveAlert(false)
  }

  // On template description change
  const handleTemplateDescriptionChange = (id, value) => {
    let descriptions = {...templateDescriptions}
    descriptions[id] = value
    setTemplateDescriptions(descriptions)
  }

  // On template title change
  const handleTemplateTitleChange = (id, value) => {
    let titles = {...templateTitles}
    titles[id] = value
    setTemplateTitles(titles)
  }

  // On add new template button click
  const handleAddNewTemplate = () => {
    setShowListOfTemplatesModal(true)
  }

  // On add new templates 
  const handleAddNewTemplates = (newTemplates) => {
    if(mode === 'create') {
      setTemplatesToAdd([...templatesToAdd, ...newTemplates])
    }else {
      const arr = newTemplates.map(tmpl => {
        if(page.templates_order.includes(tmpl.id)) {
          return {...tmpl, template_id: tmpl.id, type: 'edit' }
        }
        return {...tmpl, template_id: tmpl.id, type: 'new' }
      })
      setTemplatesToAdd([...templatesToAdd, ...arr])
    }
    let descriptions = {...templateDescriptions}
    let titles = {...templateTitles}
    newTemplates.forEach((tmpl) => {
      descriptions[tmpl.id] = ''
      titles[tmpl.id] = tmpl.name
    })
    setTemplateDescriptions(descriptions)
    setTemplateTitles(titles)
  }

  // On save changes - when in edit mode
  const handleSaveChanges = async () => {
    const pageChanges = didPageChanged()
    // console.log(pageChanges)
    if(!page) return
    if(!pageChanges.changed) return console.log('nothing changed')
    // console.log('what changed***', pageChanges)

    setShowResponseLoader(true)
    let changes = {}
    // Check if page title should be updated
    if(pageChanges.title) {
      changes.title = title.trim()
    }

    // Check if page description should be updated
    if(pageChanges.description) {
      changes.description = description.trim()
    }

    // Check if page logo should be updated
    if(pageChanges.logo) {
      if(pageChanges.logo_action === 'add') {
        setResponseMessage(t('general.uploading_image'))
        const { data, name, format, type } = logo
        const imageName = `${name}-${Date.now()}`
        const res = await upload_file(data, imageName, format, type, `shared-pages-logo/${selectedTeam.id}`)
        changes.logo_url = res.url
        changes.logo_path = `shared-pages-logo/${selectedTeam.id}/${imageName}.${format}`
      }else if(pageChanges.logo_action === 'delete') {
        let paths = []
        if(page.logo_path) {
          const splitted = page.logo_path.split('.')
          const path = [...splitted].slice(0, splitted.length - 1).join('.')
          const format = splitted[splitted.length - 1]
          paths = [page.logo_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        }
        if(paths.length > 0) {
          setResponseMessage(t('general.deleting_image'))
          for(let i = 0; i < paths.length; i++) {
            await delete_file(paths[i])
          }
          changes.logo_url = ''
          changes.logo_path = ''
        }
      }else if(pageChanges.logo_action === 'replace') {
        // First delete old logo
        let paths = []
        if(page.logo_path) {
          const splitted = page.logo_path.split('.')
          const path = [...splitted].slice(0, splitted.length - 1).join('.')
          const format = splitted[splitted.length - 1]
          paths = [page.logo_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        }
        if(paths.length > 0) {
          setResponseMessage(t('general.deleting_image'))
          for(let i = 0; i < paths.length; i++) {
            await delete_file(paths[i])
          }
          changes.logo_url = ''
          changes.logo_path = ''
        }
        // After that add new logo
        setResponseMessage(t('general.uploading_image'))
        const { data, name, format, type } = logo
        const imageName = `${name}-${Date.now()}`
        const res = await upload_file(data, imageName, format, type, `shared-pages-logo/${selectedTeam.id}`)
        changes.logo_url = res.url
        changes.logo_path = `shared-pages-logo/${selectedTeam.id}/${imageName}.${format}`
      }
    }

    changes.shared_templates = [...page.shared_templates]
    // Check if templates should be removed
    if(pageChanges.templatesToRemove.length > 0) {
      let promises = []
      let idsToDelete = []
      pageChanges.templatesToRemove.forEach(id => {
        idsToDelete.push(id)
        promises.push(delete_shared_template(id))
      })
      setResponseMessage(t('share_template.deleting_templates'))
      changes.shared_templates = changes.shared_templates.filter(id => !idsToDelete.includes(id))
      await Promise.all(promises)
    }

    // Check if templates should be updated
    if(pageChanges.templatesToEdit.length > 0) {
      let promises = []
      pageChanges.templatesToEdit.forEach(id => {
        const tmplt = templatesToAdd.find(tmpl => tmpl.id === id)
        if(tmplt) {
          promises.push(
            update_shared_template(id, { 
              name: templateTitles[tmplt.template_id],
              description: templateDescriptions[tmplt.template_id],
              meta: {
                ...tmplt.meta,
                updated: Date.now()
              }
            })
          )
        }
      })
      setResponseMessage(t('share_template.updating_templates'))
      await Promise.all(promises)
    }

    // Check if templates should be added
    let newTemplates = []
    if(pageChanges.newTemplates.length > 0) {
      let promises = []
      for(let i = 0; i < pageChanges.newTemplates.length; i++) {
        const tmplt = pageChanges.newTemplates[i]
        let data = {
          ...tmplt,
          meta: {
            created: Date.now(),
            updated: Date.now()
          }
        }
        newTemplates.push(data)
        promises.push(create_shared_template(data))
      }
      setResponseMessage(t('share_template.creating_templates'))
      let newTemplatesIds = await Promise.all(promises)
      newTemplates = newTemplates.map((tmplt, idx) => ({...tmplt, id: newTemplatesIds[idx]}))
      changes.shared_templates = [...changes.shared_templates, ...newTemplatesIds]
    }

    // Check if templates order should be updated
    if(pageChanges.order) {
      changes.templates_order = templatesToAdd.map(tmplt => tmplt.template_id)
    }

    let updatedTemplates = []
    let order = changes.templates_order || page.templates_order
    if(order) {
      order.forEach((id) => {
        const find = templatesToAdd.find(tmplt => tmplt.template_id === id)
        if(find && find.id !== find.template_id) {
          updatedTemplates.push({...find, name: templateTitles[find.template_id], description: templateDescriptions[find.template_id]})
        }else {
          const findNew = newTemplates.find(tmplt => tmplt.template_id === id)
          if(findNew) {
            updatedTemplates.push({...findNew, name: templateTitles[findNew.template_id], description: templateDescriptions[findNew.template_id]})
          }
        }
      })
      setGlobalSharedPageTemplates({
        ...globalSharedPageTemplates,
        [sharedPageId]: updatedTemplates
      })
    }

    // Check if there are any page changes made - if yes update page
    if(Object.keys(changes).length > 0) {
      setResponseMessage(t('share_template.updating_page'))
      changes.meta = {
        ...page.meta,
        updated: Date.now()
      }
      // console.log('save changes', changes)
      updateSharedTemplatePage(changes, sharedPageId, () => {
        setShowResponseLoader(false)
        setResponseMessage('')
        setNotification({ msg: t('share_template.page_successfully_updated'), type: 'success' })
        if(isTemplatePage) {
          onEditTemplatePage(changes, pageChanges, templateTitles, templateDescriptions, templatesToAdd, newTemplates)
          onClose()
        }else {
          history.push(`/shared-template-page/${sharedPageId}`)
        }
      }, () => {
        setShowResponseLoader(false)
        setResponseMessage('')
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }
  }

  const didPageChanged = () => {
    let changes = {
      changed: false,
      logo: false,
      logo_action: '',
      title: false,
      description: false,
      newTemplates: [],
      templatesToEdit: [],
      templatesToRemove: [],
      order: false
    }
    if(page.title !== title.trim()) {
      changes.changed = true 
      changes.title = true
    }
    if(page.description !== description.trim()) {
      changes.changed = true 
      changes.description = true
    }
    if(page.logo_url && !logo) {
      changes.changed = true 
      changes.logo = true
      changes.logo_action = 'delete'
    }
    if(!page.logo_url && logo) {
      changes.changed = true 
      changes.logo = true
      changes.logo_action = 'add'
    }
    if(page.logo_url && logo && logo.data) {
      changes.changed = true 
      changes.logo = true
      changes.logo_action = 'replace'
    }
    if(page.templates_order.length !== templatesToAdd.length) {
      changes.changed = true 
      changes.order = true
      templatesToAdd.forEach(tmpl => {
        if(page.templates_order.includes(tmpl.template_id)) {
          if(tmpl.name !== templateTitles[tmpl.template_id] || tmpl.description !== templateDescriptions[tmpl.template_id]) {
            if(tmpl.id !== tmpl.template_id) {
              changes.templatesToEdit.push(tmpl.id)
            }else {
              const find = fetchedPageTemplates.find(tmplt => tmplt.template_id === tmpl.id) 
              if(find) {
                changes.templatesToEdit.push(find.id)
              }
            }
          }
        }else {
          changes.newTemplates.push({
            name: templateTitles[tmpl.template_id].trim() || tmpl.name,
            template_id: tmpl.template_id,
            sections: tmpl.sections,
            templateUrl: tmpl.templateUrl,
            team: selectedTeam?.id,
            owner: activeTeamMember.id,
            disabled: false,
            single: false,
            description: templateDescriptions[tmpl.template_id].trim() || ''
          })
        }
      })
      page.templates_order.forEach(id => {
        if(!templatesToAdd.find(tmpl => tmpl.template_id === id)) {
          const find = fetchedPageTemplates.find(tmpl => tmpl.template_id === id)
          if(find) {
            changes.templatesToRemove.push(find.id)
          }
        }
      })
    }else { 
      const currentOrder = templatesToAdd.map(tmpl => tmpl.template_id)

      templatesToAdd.forEach(tmpl => {
        if(page.templates_order.includes(tmpl.template_id)) {
          if(tmpl.name !== templateTitles[tmpl.template_id] || tmpl.description !== templateDescriptions[tmpl.template_id]) {
            if(tmpl.id !== tmpl.template_id) {
              changes.changed = true
              changes.templatesToEdit.push(tmpl.id)
            }else {
              const find = fetchedPageTemplates.find(tmplt => tmplt.template_id === tmpl.id) 
              if(find) {
                changes.changed = true
                changes.templatesToEdit.push(find.id)
              }
            }
          }
        }else {
          changes.changed = true
          changes.newTemplates.push({
            name: templateTitles[tmpl.template_id].trim() || tmpl.name,
            template_id: tmpl.template_id,
            sections: tmpl.sections,
            templateUrl: tmpl.templateUrl,
            team: selectedTeam?.id,
            owner: activeTeamMember.id,
            disabled: false,
            single: false,
            description: templateDescriptions[tmpl.template_id].trim() || ''
          })
        }
      })
      page.templates_order.forEach((id, index) => {
        if(currentOrder[index] !== id) {
          changes.changed = true
          changes.order = true
        }

        if(!templatesToAdd.find(tmpl => tmpl.template_id === id)) {
          const find = fetchedPageTemplates.find(tmpl => tmpl.template_id === id)
          if(find) {
            changes.changed = true
            changes.templatesToRemove.push(find.id)
          }
        }
      })
    }
    
    return changes
  }

  // On delete page button click
  const handleDeletePageTrigger = () => {
    setShowDeletePageAlert(true)
  }

  // On delete page
  const handleDeletePage = async () => {
    if(!sharedPageId) return 

    setShowResponseLoader(true)
    setResponseMessage(t('share_template.deleting_page'))
    deleteSharedTemplatePage(sharedPageId, async () => {
      try {
        if(page && page.shared_templates) {
          let promises = []
          let templateIds = page.shared_templates 
          if(Array.isArray(templateIds)) {
            templateIds.forEach(id => {
              promises.push(delete_shared_template(id))
            })
            setResponseMessage(t('share_template.deleting_templates'))
            await Promise.all(promises)
          }
        }
        let paths = [];
        // Delete logo
        if(page.logo_path) {
          const splitted = page.logo_path.split('.')
          const path = [...splitted].slice(0, splitted.length - 1).join('.')
          const format = splitted[splitted.length - 1]
          paths = [page.logo_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        }
        if(paths.length > 0) {
          setResponseMessage(t('general.deleting_image'))
          for(let i = 0; i < paths.length; i++) {
            await delete_file(paths[i])
          }
        }
        setNotification({ msg: t('share_template.page_successfully_deleted'), type: 'success' })
        setResponseMessage('')
        history.push('/shared-templates')
        onClose()
      } catch(err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
        setResponseMessage('')
        onClose()
      }
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowResponseLoader(false)
      setResponseMessage('')
    })
  }

  // Render templates to add to page
  const renderTemplatesToAddToPage = () => {
    return (
      <ul className="list">
        <ReactSortable list={templatesToAdd} setList={setTemplatesToAdd} animation={200} delayOnTouchStart={true} delay={2} handle=".draggable-item">
          {templatesToAdd.map((tmpl) => {
            return (
              <li key={tmpl.id} className="list__item">
                <div className="list__item_draggable draggable-item"><DragIndicatorOutlined /></div>
                <div className="list__item_name">
                  <h3><input value={templateTitles[tmpl.id] || ''} onChange={(e) => handleTemplateTitleChange(tmpl.id, e.target.value)} /></h3>
                  <CustomTooltip content={t('share_template.remove_template')}>
                    <div className="delete-item">
                      <IconButton icon={<DeleteOutlineOutlined />} onButtonClick={() => handleTemplateRemoveTrigger(tmpl.id)} danger medium />
                    </div>
                  </CustomTooltip>
                </div>
                <div className="list__item_description">
                  <Textarea label={t('share_template.add_description')} value={templateDescriptions[tmpl.id] || ''} onChange={(e) => handleTemplateDescriptionChange(tmpl.id, e.target.value)} />
                </div>
              </li>
            )
          })}
        </ReactSortable>
      </ul>
    )
  }

  // Render templates to edit 
  const renderTemplatesToEdit = () => {
    return (
      <ul className="list">
        <ReactSortable list={templatesToAdd} setList={setTemplatesToAdd} animation={200} delayOnTouchStart={true} delay={2} handle=".draggable-item">
          {templatesToAdd.map((tmpl) => {
            return (
              <li key={tmpl.id} className="list__item">
                <div className="list__item_draggable draggable-item"><DragIndicatorOutlined /></div>
                <div className="list__item_name">
                  <h3><input value={templateTitles[tmpl.template_id] || ''} onChange={(e) => handleTemplateTitleChange(tmpl.template_id, e.target.value)} /></h3>
                  <CustomTooltip content={t('share_template.remove_template')}>
                    <div className="delete-item">
                      <IconButton icon={<DeleteOutlineOutlined />} onButtonClick={() => handleTemplateRemoveTrigger(tmpl.template_id)} danger medium />
                    </div>
                  </CustomTooltip>
                </div>
                <div className="list__item_description">
                  <Textarea label={t('share_template.add_description')} value={templateDescriptions[tmpl.template_id] || ''} onChange={(e) => handleTemplateDescriptionChange(tmpl.template_id, e.target.value)} />
                </div>
              </li>
            )
          })}
        </ReactSortable>
      </ul>
    )
  }

  return (
    <Modal onClose={onClose}>
      <div className="template-share-modal">
        <div className="template-share-modal__head">
          <h2>{mode === 'create' ? t('share_template.creating_a_page') : t('share_template.editing_a_page')}</h2>
        </div>
        <div className="template-share-modal__body">
          <div className="template-share-modal__body_actions">
            {mode === 'create' && <Button text={t('general.save')} onButtonClick={handleSavePage} className="save-btn" primary medium />}
            {mode === 'edit' && (
              <>
                <Button text={t('general.delete')} onButtonClick={handleDeletePageTrigger} className="delete-btn" danger medium />
                <ActionsDropdown
                  trigger={
                    <button className="button button--primary button--medium share-btn">
                      {t('share_template.share_btn_text')}
                    </button>
                  }
                  onTriggerClick={handleGenerateLink}
                  hideHeader
                  dropdownClass="template-share-actions-dropdown"
                  width={350}
                >
                  <div className="template-share-actions-dropdown__body">
                    {isGenerated ? (
                      <>
                        <div className="generated-link">
                          <Input value={generatedLink} disabled />
                        </div>
                        <div className="share-actions">
                          <Button text={t('share_template.copy_url')} primaryLight onButtonClick={handleCopyUrlToClipboard} />
                          <Button text={t('share_template.renew_url')} primaryLight onButtonClick={handleRenewUrl} />
                          {/* <Button text={t('share_template.embed_template')} primaryLight fullWidth onButtonClick={handleEmbedTemplate} /> */}
                          <div>
                            <Button text={page.disabled ? t('share_template.enable_link') : t('share_template.disable_link')} primaryLight onButtonClick={handleDisableLink} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="loader-wrapper"><Loader small normal /></div>
                    )}
                  </div>
                </ActionsDropdown>
                <Button text={t('general.save')} onButtonClick={handleSaveChanges} secondaryLight medium />
              </>
            )}
          </div>
          <div className="template-share-modal__body_fields">
            <div className="upload-file">
              <div className="upload-file__label">{t('share_template.add_logo')}</div>
              {!logo ? (
                <div 
                  className="upload-file__dropzone" 
                  onClick={isDragging ? () => {} : handleDropzoneClick}
                  onDragEnter={handleFileDragEnter}
                  onDragLeave={handleFileDragLeave}
                  onDragOver={handleFileDragOver}
                  onDrop={handleFileDrop}
                >
                  <p>{t('contacts.select_file_to_upload')}</p>
                  <p>{t('contacts.dnd_file')}</p>
                  <input type="file" onChange={(e) => handleFileChange(e.target.files[0])} className="hidden" ref={dropzoneFileRef} />
                </div>
                ) : (
                <div className="upload-file__dropzone">
                  {logoPreview && <div className="preview-box">
                    <img src={logoPreview} alt="" />
                  </div>}
                  <p>{logo.name}</p>
                  <p><a href="/#" onClick={handleRemoveLogo}>{t('general.delete_image')}</a></p>
                </div>
                )
              }
            </div>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} label={t('share_template.add_title')} formEl />
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} label={t('share_template.add_description')} formEl />
          </div>

          <div className="template-share-modal__body_list-of-templates">
            <h2>{t('dashboard.templates')}</h2>
            {mode === 'create' && renderTemplatesToAddToPage()}
            {mode === 'edit' && renderTemplatesToEdit()}
            <div className="add-new-template">
              <Button text={t('share_template.add_new_template')} onButtonClick={handleAddNewTemplate} primary medium />
            </div>
          </div>
        </div>
        {/* <div className="template-share-modal__foot">
        </div> */}
      </div>

      {showListOfTemplatesModal && <ListOfTemplatesModal 
        onClose={() => setShowListOfTemplatesModal(false)}
        addedTemplates={mode === 'create' ? templatesToAdd.map(tmpl => tmpl.id) : templatesToAdd.map(tmpl => tmpl.template_id)}
        onAddNewTemplates={handleAddNewTemplates}
      />}
      {showDeletePageAlert && <Alert 
        onClose={() => setShowDeletePageAlert(false)}
        text={t('share_template.delete_page')}
        onSubmit={handleDeletePage}
        deleteAlert
      />}
      {showTemplateRemoveAlert && <Alert 
        onClose={() => setShowTemplateRemoveAlert(false)}
        text={t('share_template.remove_template_from_page')}
        onSubmit={handleTemplateRemove}
        deleteAlert
      />}
      {showResponseLoader && <ResponseLoader text={responseMessage} />}
    </Modal>
  )
}

export default TemplateShareModal
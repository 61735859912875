import React, { useState, useContext } from 'react'
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded'
import EditIcon from '@material-ui/icons/EditOutlined'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import CloseIcon from '@material-ui/icons/CloseOutlined'

import Modal from '../../UI/Modal'
import IconButton from '../../UI/IconButton'
import Input from '../../UI/Input'
import ResponseLoader from '../../UI/ResponseLoader'
import Alert from '../../UI/Alert'
import { NotificationContext, SignatureTemplatesContext, GlobalContext } from '../../../context'

const SignatureTemplatesModal = ({ onClose, templates = [], doc, activeTemplate, onSetActiveTemplate, customSigners, onSetFieldsPositions, onSetSignaturePositions, onSetSignaturePlaced, onSetBoxes }) => {
  const { t } = useContext(GlobalContext)

  return (
    <Modal onClose={onClose} medium>
      <div className="signature-templates-modal"> 
        <h3>{t('signature_templates.templates')}</h3>
        <ul>
          {templates.map(tmpl => (
            <TemplateItem 
              key={tmpl.id} 
              tmpl={tmpl} 
              activeTemplate={activeTemplate} 
              onSetActiveTemplate={onSetActiveTemplate} 
              customSigners={customSigners}
              onSetFieldsPositions={onSetFieldsPositions}
              onSetSignaturePositions={onSetSignaturePositions}
              onSetSignaturePlaced={onSetSignaturePlaced}
              onSetBoxes={onSetBoxes}
            />
          ))}
        </ul>
      </div>
    </Modal>
  )
}

const TemplateItem = ({ tmpl, activeTemplate, onSetActiveTemplate, customSigners, onSetFieldsPositions, onSetSignaturePositions, onSetSignaturePlaced, onSetBoxes }) => {
  const { t } = useContext(GlobalContext)
  const { updateSignatureTemplate, deleteSignatureTemplate } = useContext(SignatureTemplatesContext)
  const { setNotification } = useContext(NotificationContext)
  const [name, setName] = useState('')
  const [mode, setMode] = useState('view')
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false)

  // Edit template - on edit icon click
  const editTemplate = () => {
    setMode('edit')
    setName(tmpl.name)
  }

  // Delete template
  const deleteTemplate = async () => {
    setShowResponseLoader(true)
    try {
      await deleteSignatureTemplate(tmpl.id)
      setNotification({ msg: t('signature_templates.template_deleted'), type: 'success' })
      if(activeTemplate === tmpl.id) {
        onSetActiveTemplate('')
        const sArr = [...customSigners]
  
        sArr.forEach(s => {
          if(s.position) s.position = []
        })
        onSetFieldsPositions([])
        onSetSignaturePositions([])
        onSetBoxes([])
        onSetSignaturePlaced(false)
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setShowResponseLoader(false)
  }

  // Update template
  const updateTemplate = async () => {
    if(name.trim() === '') {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }
    if(name.trim() === tmpl.name) {
      return setNotification({ msg: t('signature_templates.update_template_name'), type: 'warning' })
    }
    setShowResponseLoader(true)
    try {
      await updateSignatureTemplate(tmpl.id, { name })
      setNotification({ msg: t('signature_templates.template_updated'), type: 'success' })
      setMode('view')
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setShowResponseLoader(false)
  }

  // Back to view mode
  const backToViewMode = () => {
    setName('')
    setMode('view')
  }

  return (
    <li>
      <div className="name">
        {mode === 'view' ? 
          <p>{tmpl.name}</p> 
          : 
          <div className="input-wrapper">
            <Input value={name} onChange={(e) => setName(e.target.value)} formEl />
          </div>
        }                
      </div>
      {mode === 'view' ? 
        <div className="actions">
          <IconButton icon={<EditIcon />} onButtonClick={editTemplate} primaryLight />
          <IconButton icon={<DeleteIcon />} onButtonClick={() => setShowDeleteTemplateAlert(true)} light className="delete-btn" />
        </div>
        :
        <div className="actions actions--2">
          <IconButton icon={<SaveIcon />} onButtonClick={updateTemplate} primaryLight />
          <IconButton icon={<CloseIcon />} onButtonClick={backToViewMode} light className="delete-btn" />
        </div>
      }

      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text={t('signature_templates.delete_this_template')}
        onSubmit={deleteTemplate}
        deleteAlert
      />}
      {showResponseLoader && <ResponseLoader />}
    </li>
  );
}

export default SignatureTemplatesModal
import { useState, useContext } from 'react'
import { useHistory } from 'react-router'
import { HelpOutline, ThumbUpAltOutlined, StarOutlineOutlined, BugReportOutlined } from '@material-ui/icons'

import ActionsDropdown from '../UI/ActionsDropdown'
import CustomTooltip from '../UI/CustomTooltip'
import { GiveFeedbackModal, ReportABugModal, VoteUpcomingFeaturesModal } from '../misc'
import { GlobalContext } from '../../context'

const SupportMenu = () => {
  const { t } = useContext(GlobalContext)
  const [showBugReportModal, setShowBugReportModal] = useState(false)
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false)
  const [showVoteModal, setShowVoteModal] = useState(false)
  const history = useHistory()

  // Support menu item click
  const supportMenuItemClickHandler = (e, route) => {
    e.preventDefault()
    history.push(`/${route}`)
    document.body.click()
  }

  // On open bug modal
  const handleOpenReportBugModal = (e) => {
    e.preventDefault()
    setShowBugReportModal(true)
  }

  // On open bug modal
  const handleOpenFeedbackModal = (e) => {
    e.preventDefault()
    setShowGiveFeedbackModal(true)
  }

  // On open bug modal
  const handleOpenVoteModal = (e) => {
    e.preventDefault()
    setShowVoteModal(true)
  }

  return (
    <>
      <ActionsDropdown
        // trigger={<button className={`icon-button support-menu-btn ${!v2 ? 'icon-button--light-blue' : ''}`}>{v2 ? <ChatOutlined /> : <InfoOutlined />}</button>}
        trigger={
          <div>
            <CustomTooltip content={t('dashboard.support_info')} position="left">
              <button className="icon-btn"><span className="material-symbols-outlined">help</span></button>
            </CustomTooltip>
          </div>
        }
        // headTitle={t('support.information')}
        hideHeader
        dropdownClass="actions-dropdown-v2"
        width={300}
      >
        <ul className="default-list">
          <li>
            <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'what-is-new')}>
              <span className="icon"><span className="material-symbols-outlined size-24">campaign</span></span> 
              {t('support.whats_new')}
            </a>
          </li>
          <li>
            <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'help')}>
              <span className="icon"><span className="material-symbols-outlined size-24">support</span></span> 
              {t('support.help_center')}
            </a>
          </li>
          {/* <li>
            <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'documentation')}><i className="custom-icon-file-download"></i> {t('support.documentation_docs')}</a>
          </li> */}
          <li>
            <a href="/#" onClick={handleOpenReportBugModal}>
              <span className="icon"><span className="material-symbols-outlined size-24">bug_report</span></span> 
              {t('support.report_bug')}
            </a>
          </li>
          {/* <li>
            <div className="link">
              <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'request-demo')}>{t('support.request_demo')}</a>
            </div>
          </li> */}
          <li>
            <a href="/#" onClick={handleOpenFeedbackModal}>
              <span className="icon"><span className="material-symbols-outlined size-24">grade</span></span> 
              {t('support.give_feedback')}
            </a>
          </li>
          <li>
            <a href="/#" onClick={handleOpenVoteModal}>
              <span className="icon"><span className="material-symbols-outlined size-24">thumb_up</span></span> 
              {t('support.vote_features')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>

      {showBugReportModal && <ReportABugModal onClose={() => setShowBugReportModal(false)} />}
      {showGiveFeedbackModal && <GiveFeedbackModal onClose={() => setShowGiveFeedbackModal(false)} />}
      {showVoteModal && <VoteUpcomingFeaturesModal onClose={() => setShowVoteModal(false)} />}
    </>
  )
}

export default SupportMenu
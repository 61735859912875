import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import SecurityIcon from '@material-ui/icons/Security'
import qs from 'qs'

import Input from '../UI/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import Checkbox from '../UI/Checkbox'
import useForm from '../../hooks/useForm';
import firebase from '../../services/firebase';
import { NotificationContext, GlobalContext, TeamContext, UserContext } from '../../context';
import { fetch_user } from '../../services/firestore';
import { saveSelectedTeamToLS, availableOn } from '../../utils'
import { errorMessage } from '../../i18n';

const SignIn = () => {
  const initialData = {
    email: {
      email: true,
      value: ''
    },
    password: {
      required: true,
      value: ''
    }
  }
  const { joiningTeamOption, redirectToAfterAuthenticated, setRedirectToAfterAuthenticated, t, validate, setSelectedLang } = useContext(GlobalContext);
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);
  const { setNotification } = useContext(NotificationContext);
  const { setSelectedTeam, teams } = useContext(TeamContext);
  const { setUser, user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [multiFactorResolver, setMultiFactorResolver] = useState(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [captchaCompleted, setCaptchaCompleted] = useState(false)
  const [verificationId, setVerificationId] = useState('')
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false)
  const [verificationCodeExpired, setVerificationCodeExpired] = useState(false)
  const recaptchaVerifierRef = useRef(null)
  const [phoneAuthProvider, setPhoneAuthProvider] = useState(null)
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false)

  const history = useHistory();

  // Check if lang is in url and set language based on that value
  useEffect(() => {
    const query = qs.parse(history.location.search)
    const lang = query['?lang']
    const langs = ['fr', 'en', 'nl']
    if(lang && Object.keys(user).length === 0 && langs.includes(lang)) {
      setSelectedLang(query['?lang'])
    }
  }, [history, user, setSelectedLang])

  useEffect(() => {
    if(joiningTeamOption.email) {
      setFormData(prev => ({
        ...prev,
        email: {
          ...prev.email,
          value: joiningTeamOption.email
        }
      }));
    }
  }, [joiningTeamOption, setFormData]);

  useEffect(() => {
    if(multiFactorResolver) {
      setupVerification()
    }
  }, [multiFactorResolver])

  // Sign in with email and password
  const submitHandler = async (e) => {
    if(e) {
      e.preventDefault();
    }
    
    const formErrors = validate(formData, true);
    setErrors(formErrors);
    
    if(Object.keys(formErrors).length === 0) {
      setLoading(true);
      try {
        await firebase.auth().setPersistence(keepMeLoggedIn ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
        await firebase.auth().signInWithEmailAndPassword(formData.email.value, formData.password.value);
        if(!joiningTeamOption.default) {
          const findTeam = [...teams].find(tm => tm.id === joiningTeamOption.team);
          setSelectedTeam(findTeam);
          saveSelectedTeamToLS(findTeam.id)
          history.push(joiningTeamOption.redirect);
        }
        if(redirectToAfterAuthenticated) {
          history.push(redirectToAfterAuthenticated)
          setRedirectToAfterAuthenticated(null)
        }
      } catch (err) {
        setLoading(false);
        // console.log('sign in error', err)
        if(err.code === 'auth/multi-factor-auth-required') {
          console.log(err)
          setMultiFactorResolver(err.resolver)
        } else if(err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
          setNotification({ msg: t('auth.invalid_credentials'), type: 'danger' });
        }else if(err.code === 'auth/user-disabled') {
          setNotification({ msg: t('auth.user_disabled'), type: 'danger' });
        }else if(err.code === 'auth/too-many-requests') {
          setNotification({ msg: t('auth.too_many_requests'), type: 'danger' });
        }
      }
    }
  }

  // Sign in with google
  const googleSignInHandler = async () => {
    // try {
    //   firebase.auth().languageCode = 'fr';
    //   const provider = new firebase.auth.GoogleAuthProvider();
    //   const res = await firebase.auth().signInWithPopup(provider);
    //   const user = res.user;
    //   const userFromDb = await fetch_user(user.uid);
    //   // console.log(userFromDb)
    //   let userData = {};
    //   if(!userFromDb) {
    //     userData = {
    //       first_name: user.displayName.split(' ')[0],
    //       last_name: user.displayName.split(' ')[1],
    //       email: user.email 
    //     };
    //     // create user
    //     await create_user({ data: userData, id: user.uid });
    //     setUser({...userData, id: user.uid});
    //   }else {
    //     if(!joiningTeamOption.default) {
    //       const findTeam = [...teams].find(tm => tm.id === joiningTeamOption.team);
    //       setSelectedTeam(findTeam);
    //       saveSelectedTeamToLS(findTeam.id)
    //       history.push(joiningTeamOption.redirect);
    //     }
    //   }
    // }catch(err) {
    //   console.log(err);
    //   if(err.code === 'auth/account-exists-with-different-credential') {
    //     setNotification({ msg: t('auth.account_exists'), type: 'danger' });
    //   }else if(err.code === 'auth/popup-closed-by-user') {
    //     setNotification({ msg: t('auth.popup_closed'), type: 'danger' });
    //   }
    // }
  }

  // Sign in with facebook
  const facebookSignInHandler = async () => {
    // try {
    //   firebase.auth().languageCode = 'fr';
    //   const provider = new firebase.auth.FacebookAuthProvider();
    //   const res = await firebase.auth().signInWithPopup(provider);
    //   const user = res.user;
    //   const userFromDb = await fetch_user(user.uid);
    //   let userData = {};
    //   if(!userFromDb) {
    //     userData = {
    //       first_name: user.displayName.split(' ')[0],
    //       last_name: user.displayName.split(' ')[1],
    //       email: user.email 
    //     }
    //     // create user
    //     await create_user({ data: userData, id: res.user.uid });
    //   }
    // }catch(err) {
    //   console.log(err);
    //   if(err.code === 'auth/account-exists-with-different-credential') {
    //     setNotification({ msg: t('auth.account_exists'), type: 'danger' });
    //   }else if(err.code === 'auth/popup-closed-by-user') {
    //     setNotification({ msg: t('auth.popup_closed'), type: 'danger' });
    //   }
    // }
  }

  useEffect(() => {
    setupCaptchaComponent()
  }, [])

  const setupCaptchaComponent = async () => {
    recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal'
    });
    const widgetId = await recaptchaVerifierRef.current.render()    
    window.recaptchaWidgetId = widgetId;
  }

  const setupVerification = async () => {
    setShowCaptcha(true)

    try {

      var phoneInfoOptions = {
        multiFactorHint: multiFactorResolver.hints[0],
        session: multiFactorResolver.session
      };
      let provider
      if(phoneAuthProvider) {
        provider = phoneAuthProvider
      } else {
        provider = new firebase.auth.PhoneAuthProvider()
      }
      setPhoneAuthProvider(provider)
      // Send SMS verification code.
      // provider.
      let vId = await provider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current)        
      // verificationId will be needed for enrollment completion.
      console.log('verificationId', vId)
      setVerificationId(vId)
      setCaptchaCompleted(true)
      setShowCaptcha(false)
      setPhoneVerificationSent(true)

    } catch(err) {
      console.log(err)
      setNotification({ msg: err.message || err.code,  type: 'danger' });

      // setLoading(false)
      setShowCaptcha(false)
    }    
  }

  const resendCodeHandler = () => {
    setPhoneVerificationSent(true)
    setVerificationCodeExpired(false)
    setShowCaptcha(false)
    setCaptchaCompleted(false)

    return submitHandler()
  }

  const verifyCode = async () => {
    var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    multiFactorResolver.resolveSignIn(multiFactorAssertion)
    .then(function(userCredential) {
      console.log(userCredential)
      if(!joiningTeamOption.default) {
        const findTeam = [...teams].find(tm => tm.id === joiningTeamOption.team);
        setSelectedTeam(findTeam);
        saveSelectedTeamToLS(findTeam.id)
        history.push(joiningTeamOption.redirect);
      }
      if(redirectToAfterAuthenticated) {
        history.push(redirectToAfterAuthenticated)
        setRedirectToAfterAuthenticated(null)
      }
    }).catch(err => {
      console.log(err)
      setNotification({ msg: errorMessage(err, t), type: 'danger' })
      if(err.code === 'auth/code-expired') {
        setVerificationCodeExpired(true)
      }
    });

  }

  // go to login page
  const handleGoToLogin = (e) => {
    e.preventDefault()
    setShowCaptcha(false)
    setCaptchaCompleted(false)
    setPhoneVerificationSent(false)
    setMultiFactorResolver(null)
  }

  // On keep me logged in change
  const handleKeepMeLoggedIn = (e) => {
    setKeepMeLoggedIn(e.target.checked)
  }

  return(
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box_main">
          <div className="auth__box_main-head">
            <p>{t('auth.login_title')}</p>
            {showCaptcha || phoneVerificationSent
              ? (<a href="/#" className="text-link" onClick={handleGoToLogin}>{t('auth.login_2')}</a>) 
              : (<Link to="/forgot-password" className="text-link">{t('auth.forgot_password')}</Link>)
            }
          </div>
          <form className="form" onSubmit={submitHandler}>

            { (!showCaptcha && !phoneVerificationSent) && <div>
              <Input 
                name="email"
                value={formData.email.value}
                onChange={changeHandler}
                placeholder={t('auth.email')}
                error={errors.email}
                iconEl={<MailIcon />}
                whiteBackground
                iconSmall
                sizeMedium
              />
              <Input 
                type="password"
                name="password"
                value={formData.password.value}
                onChange={changeHandler}
                placeholder={t('auth.password')}
                error={errors.password}
                iconEl={<LockIcon />}
                whiteBackground
                iconSmall
                sizeMedium
                showEyeIcon
              />
              <Checkbox label={t('auth.keep_me_signed_in')} checked={keepMeLoggedIn} onChange={handleKeepMeLoggedIn} className="mb-10" />
            </div> }
            <div className={`${(!showCaptcha || captchaCompleted) ? 'visibility-hidden-no-height' : ''}`}>
              <span className="hint">{t('auth.two_factor_auth')}</span>
              <div className="recaptcha-container" ref={recaptchaVerifierRef} id="recaptcha-container"></div>
            </div>
            { phoneVerificationSent  && <span className="hint">{t('auth.verification_code_sent')}</span> }
            { phoneVerificationSent &&
            <div className="mt-10">
              <Input 
                type="text"
                name="verification_code"
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
                placeholder={t('auth.verification_code')}
                iconEl={<SecurityIcon />}
                whiteBackground
                iconSmall
                sizeMedium
              />
            </div> }
            { (phoneVerificationSent && verificationCodeExpired) &&
            <div className="mb-10">
              <span className="hint error mr-10">{ t('auth.code_expired') }</span>
              <Button small primary text={t('auth.resend_code')}  onButtonClick={resendCodeHandler} />
            </div> }
            { multiFactorResolver 
              ? phoneVerificationSent 
                ? <Button onButtonClick={verifyCode} text={t('general.confirm')} disabled={loading} secondaryLight fullWidth medium /> 
                : null 
              : <Button type="submit" text={!loading ? t('auth.login') : <Loader small normalWhite />} disabled={loading} primary fullWidth medium /> 
            }
          </form>
        </div>
        {availableOn(['development']) && <div className="auth__buttons">
          <Button 
            text={t('auth.connect_with_google')}
            onButtonClick={googleSignInHandler}
            outlineLight
            icon={
              <i className="custom-icon-google">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
            </i>}
          />
          {/* <Button 
            text={t('auth.connect_with_fb)}
            onButtonClick={facebookSignInHandler}
            outlineLight
            icon={
              <i className="custom-icon-facebook">
                <span className="path1"></span>
                <span className="path2"></span>
            </i>}
          /> */}
        </div>}
        <div className="auth__note">
          <p>{t('auth.dont_have_account')} <Link to="/signup" className="text-link">{t('auth.sign_up_here')}</Link></p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
import React, { useEffect, useState, useCallback, useRef, useContext } from 'react'
import { Document, Page } from 'react-pdf'
import { ArrowRight, ArrowLeft, FirstPage, LastPage } from '@material-ui/icons'

import Button from '../../UI/Button'
import IconButton from '../../UI/IconButton'
import Modal from '../../UI/Modal'
import { GlobalContext } from '../../../context'

const PlaceSignatureInterfaceModalV2 = ({ onClose, onSignaturePositionSave, onSetEditStartTime, onStoreEditTime, windowVisibility, editStartTime, base64Data, boxes, onSetBoxes, currentBox = null }) => {
  const { t } = useContext(GlobalContext)
  const [docPages, setDocPages] = useState([])
  const [selectedPage, setSelectedPage] = useState(0)
  const [activeBox, setActiveBox] = useState(null)
  const [shouldMove, setShouldMove] = useState(false)
  const [moving, setMoving] = useState(false)
  const [activeBoxOffsetX, setActiveBoxOffsetX] = useState(null)
  const [activeBoxOffsetY, setActiveBoxOffsetY] = useState(null)
  const [actionType, setActionType] = useState('move')
  const [boxesCopy, setBoxesCopy] = useState(JSON.parse(JSON.stringify(boxes)))
  const overlayRef = useRef()

  // Store time when visibility changes to hidden and track it again when it is visible
  // useEffect(() => {
  //   if(windowVisibility === 'hidden' && editStartTime) {
  //     onStoreEditTime()
  //   }else if(windowVisibility === 'visible' && !editStartTime) {
  //     onSetEditStartTime(Date.now())
  //   }
  // }, [windowVisibility, editStartTime])

  // Set active box from outside
  useEffect(() => {
    if(currentBox) {
      setActiveBox(currentBox)
      setSelectedPage(currentBox.page || 0)
    }
  }, [currentBox])

  // Close handler
  const closeHandler = () => {
    // onStoreEditTime()
    if(boxesCopy.find(box => !box.saved)) {
      onSetBoxes(boxesCopy.filter(box => box.saved))
    }
    onClose()
  }

  // Save handler 
  const saveHandler = () => {
    if(activeBox) {
      let active = {...activeBox, page: selectedPage} 
      // active = boxes.find(b => !b.disabled)
      // active = {...active, page: selectedPage}
      onSetBoxes(boxesCopy.map(box => box.id === active.id ? {...active, saved: true} : box))
      onSignaturePositionSave({...active, saved: true})
    }
    // onStoreEditTime()
    // setActiveBox(null)
  }

  // On document load success
  const handleDocumentLoadSuccess = (numPages) => {
    let arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page key={i} pageNumber={i} width={774} height={1094} renderTextLayer={true} loading={t('general.loading')} />)
    }
    setDocPages(arr)
  }

  // On show first page
  const handleShowFirstPage = () => {
    setSelectedPage(0)
  }

  // On show prev page
  const handleShowPrevPage = () => {
    setSelectedPage(selectedPage - 1)
  }

  // On show next page
  const handleShowNextPage = () => {
    setSelectedPage(selectedPage + 1)
  }

  // On show first page
  const handleShowLastPage = () => {
    setSelectedPage(docPages.length - 1)
  }

  // On page change
  const handleSelectPageChange = (e) => {
    setSelectedPage(+e.target.value)
  }

  // On mouse move - only when there is active resizable column
  const handleMouseMove = useCallback((e) => {
    if(activeBox !== null && shouldMove) {
      const el = overlayRef.current
      if(el) {
        const { left, top, width, height } = el.getBoundingClientRect()
        const current = boxesCopy.find(item => item.id === activeBox.id)
        if(actionType === 'move') { // when moving box
          if(current) {
            // top position
            let topPos
            if(e.pageY - top - activeBoxOffsetY - window.scrollY < 0) topPos = 0
            else if(e.pageY - top - activeBoxOffsetY - window.scrollY > height - activeBox.height - 2) topPos = height - activeBox.height - 2
            else topPos = e.pageY - top - activeBoxOffsetY - window.scrollY
            current.top = topPos
  
            // left position
            let leftPos
            if(e.pageX - left - activeBoxOffsetX - window.scrollX < 0) leftPos = 0
            else if(e.pageX - left - activeBoxOffsetX - window.scrollX > width - activeBox.width) leftPos = width - activeBox.width
            else leftPos = e.pageX - left - activeBoxOffsetX - window.scrollX
            current.left = leftPos
  
            // set page
            current.page = selectedPage
          }
        } else if(actionType === 'change-w') { // when changing width
          if(current) {
            // width
            let boxWidth
            let cWidth = current.width
            if(e.pageX - left - window.scrollX <= current.left + current.width){ 
              boxWidth = cWidth - ((current.left + current.width) - (e.pageX - left - window.scrollX))
              if(boxWidth < 100) {
                boxWidth = 100
              }
            }else if(e.pageX - left - window.scrollX > current.left + current.width) {
              boxWidth = cWidth + ((e.pageX - left - window.scrollX) - (current.left + current.width))
            }
            current.width = boxWidth
          }
        } else if(actionType === 'change-h') { // when changing height
          if(current) {
            // height
            let boxHeight
            let cHeight = current.height
            if(e.pageY - top - window.scrollY <= current.top + current.height){ 
              boxHeight = cHeight - ((current.top + current.height) - (e.pageY - top - window.scrollY))
              if(boxHeight < 50) {
                boxHeight = 50
              }
            }else if(e.pageY - top - window.scrollY > current.top + current.height) {
              boxHeight = cHeight + ((e.pageY - top - window.scrollY) - (current.top + current.height))
            }
            current.height = boxHeight
          }
        }
        setActiveBox(current)
        const updatedItems = boxesCopy.map(item => item.id === activeBox.id ? current : item)
        setBoxesCopy(updatedItems)
      }
    }
  }, [activeBox, shouldMove, boxesCopy, activeBoxOffsetX, activeBoxOffsetY, selectedPage, actionType])

  // Remove mouse event listeners
  // const removeListeners = useCallback(() => {
  //   console.log('remove listeners')
  //   window.removeEventListener("mousemove", handleMouseMove)
  //   window.removeEventListener("mouseup", removeListeners)
  // }, [handleMouseMove])

  // On mouse up - reset active resizable column
  const handleMouseUp = useCallback(() => {
    setShouldMove(false)
    setMoving(false)
    // removeListeners()
  }, [])

  // On mouse down - set active resizable column
  const handleMouseDown = (e, box, idx, type = 'move') => {
    // x and y position where the click happened within a box - for example in top left corner of a box position is 0,0
    setActiveBoxOffsetX(e.nativeEvent.offsetX > 0 ? e.nativeEvent.offsetX : 0)
    setActiveBoxOffsetY(e.nativeEvent.offsetY > 0 ? e.nativeEvent.offsetY : 0)

    setActionType(type)
    setShouldMove(true)
    setMoving(true)
    setActiveBox({ ...box, index: idx })
  }

  // Add mouse event listeners
  // useEffect(() => {
  //   if (activeBox !== null) {
  //     window.addEventListener("mousemove", handleMouseMove)
  //     window.addEventListener("mouseup", handleMouseUp)
  //   }

  //   return () => {
  //     if(!activeBox) {
  //       console.log('remove listeners***')
  //       window.removeEventListener("mousemove", handleMouseMove)
  //       window.removeEventListener("mouseup", handleMouseUp)
  //     }
  //   }
  // }, [activeBox, handleMouseMove, handleMouseUp])

  return (
    <Modal onClose={closeHandler} className="onespan-placement-modal">
      <div className="choose-signature-position">
        <div className="choose-signature-position__head">
          <IconButton primaryLight medium icon={<FirstPage />} onButtonClick={handleShowFirstPage} disabled={selectedPage === 0} />
          <IconButton primaryLight medium icon={<ArrowLeft />} onButtonClick={handleShowPrevPage} disabled={selectedPage === 0} />
          <select onChange={handleSelectPageChange} value={selectedPage}>
            {Array.from(Array(docPages.length).keys()).map((_, idx) => (
              <option key={idx} value={idx}>{idx + 1}</option>
            ))}
          </select>
          <IconButton primaryLight medium icon={<ArrowRight />} onButtonClick={handleShowNextPage} disabled={selectedPage === docPages.length - 1} />
          <IconButton primaryLight medium icon={<LastPage />} onButtonClick={handleShowLastPage} disabled={selectedPage === docPages.length - 1} />
        </div>
        <div className="choose-signature-position__body">
          <div className="doc-preview">
            <Document 
              file={`data:application/pdf;base64,${base64Data}`} 
              onLoadSuccess={({ numPages }) => handleDocumentLoadSuccess(numPages)} 
              renderMode="canvas" 
              loading={t('general.loading')}
            >
              {docPages[selectedPage]}
            </Document>
          </div>
          <div className="doc-overlay" ref={overlayRef} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
            {boxesCopy.map((box, idx) => {
              let boxStyle = {
                top: box.top, 
                left: box.left, 
                width: box.width, 
                height: box.height, 
                zIndex: box.disabled ? 5 : 10
              }
              if(box.disabled) {
                if(+box.page === +selectedPage) {
                  return (
                    <div 
                      key={box.id} 
                      className={"box disabled"} 
                      style={boxStyle} 
                      onMouseDown={() => {}}
                    >
                    </div>
                  )
                }else {
                  return null
                }
              }else {
                return (
                  <div 
                    key={box.id} 
                    className={"box"} 
                    style={boxStyle} 
                    // onMouseDown={(e) => handleMouseDown(e, box, idx)}
                  >
                    <div className={moving ? "box__move moving" : "box__move"} onMouseDown={(e) => handleMouseDown(e, box, idx)}></div>
                    {box.type !== 'checkbox' && (
                      <>
                        <div className="box__expand-width" onMouseDown={(e) => handleMouseDown(e, box, idx, 'change-w')}></div>
                        <div className="box__expand-height" onMouseDown={(e) => handleMouseDown(e, box, idx, 'change-h')}></div>
                      </>
                    )}
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
      <div className="buttons-wrapper">
        <Button text={t('general.save')} onButtonClick={saveHandler} primary medium />
      </div>
    </Modal>
  )
}

export default PlaceSignatureInterfaceModalV2
import React, { useState, useEffect, useContext } from 'react'
import { SearchOutlined, PersonAddOutlined, Home, Phone, AlternateEmail, Edit } from '@material-ui/icons'

import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import Loader from '../../UI/Loader'
import CustomTooltip from '../../UI/CustomTooltip'
import { NotariesContext, TeamContext, GlobalContext } from '../../../context'

const NotariesModal = ({ onClose, onOpenAddEditNotaryModal, onSetSelectedNotary }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { fetchNotaries, notariesFetched, notaries } = useContext(NotariesContext)
  const [search, setSearch] = useState('')
  const [filteredNotaries, setFilteredNotaries] = useState([])
  const [loading, setLoading] = useState(true)

  // Fetch notaries if not fetched yet
  useEffect(() => {
    if(selectedTeam?.id && !notariesFetched) {
      fetchNotaries(selectedTeam.id)
    }
    if(notariesFetched) {
      setLoading(false)
      setFilteredNotaries(notaries)
    }
  }, [selectedTeam, notariesFetched, fetchNotaries, notaries])

  // On search
  const searchChangeHandler = (e) => {
    const { value } = e.target
    setSearch(value)
    let filtered = []
    if(value.trim() !== '') {
      const val = value.trim().toLowerCase()
      notaries.forEach(n => {
        if(n.name.toLowerCase().includes(val) || n.email.toLowerCase().includes(val)) {
          filtered.push(n)
        }
      })
      setFilteredNotaries(filtered)
      return 
    }
    setFilteredNotaries(notaries)
  }

  // On notary select
  const notarySelectHandler = (notary) => {
    console.log(notary)
  }

  // On edit button click
  const notaryEditClickHandler = (notary) => {
    onOpenAddEditNotaryModal('edit')
    onSetSelectedNotary(notary)
  }

  return (
    <Modal onClose={onClose}>
      <div className="notaries-modal">
        <div className="notaries-modal__head">
          <h2>{t('notaries.search_notary')}</h2>
        </div>
        <div className="notaries-modal__search">
          <Input iconEl={<SearchOutlined />} value={search} onChange={searchChangeHandler} placeholder={t('notaries.search_placeholder')} formEl />
          <CustomTooltip content={t('notaries.add')} position="left">
            <button className="icon-button" onClick={() => onOpenAddEditNotaryModal('add')}>
              <PersonAddOutlined />
            </button>
          </CustomTooltip>
        </div>
        {loading 
          ? <div className="loader-wrapper"><Loader small primary normal /></div>
          : 
          <div className="notaries-modal__body">
            <div className="notaries-modal__body_boxes">
              {filteredNotaries.map((notary, i) => {
                return (
                  <div className="notary-box" key={i}>
                    <h4>{t(`roles.${notary.role}`)} {notary.name}</h4>
                    <div className="address"><Home /> {notary.street} {notary.postal_code} {notary.city}</div>
                    <div className="phone-email">
                      <p><Phone /> {notary.phone}</p>
                      <p><AlternateEmail /> {notary.email}</p>
                    </div>
                    <div className="overlay" onClick={() => notarySelectHandler(notary)}></div>
                    <CustomTooltip content={t('general.modify')} position="left">
                      <button className="icon-button edit-btn" onClick={() => notaryEditClickHandler(notary)}>
                        <Edit />
                      </button>
                    </CustomTooltip>
                  </div>
                )
              })}
              {notariesFetched && filteredNotaries.length === 0 && <p className="no-data">{t('notaries.no_data')}</p>}
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default NotariesModal
import { useContext, forwardRef } from 'react'

import { SharedDocumentsContext, ColumnsContext } from '../../../context'

const ResizableExternalDocumentColumn = forwardRef(({ column, onSort, idx, onMouseDown }, ref) => {
  const { sharedDocumentsSort } = useContext(SharedDocumentsContext)
  const { sharedDocsTableColumnsWidths } = useContext(ColumnsContext)

  let columnStyle = {
    width: column.value === 'helper' ? '100%' : sharedDocsTableColumnsWidths[column.value] || 200 
  }

  return (
    <div 
      className={`list-view-table__cell ${column.value === 'name' ? 'title-col' : ''}`} 
      key={idx} 
      ref={ref}
      style={columnStyle}
    >
      {column.value !== 'helper' ? (
        <p onClick={() => onSort(column.value)} className={sharedDocumentsSort.value === column.value ? 'sort active-sort' : 'sort'}>
          <span className="material-symbols-outlined icon">{column.icon}</span>
          <span className="text">{column.label}</span>
          {sharedDocumentsSort.value === column.value && (
            <>
              {sharedDocumentsSort.order === 'desc' && <span className="material-symbols-outlined arrow">arrow_upward</span>}
              {sharedDocumentsSort.order === 'asc' && <span className="material-symbols-outlined arrow">arrow_downward</span>}
            </>
          )}
        </p>
      ) : (
        <div>&nbsp;</div>
      )}
      {idx !== 0 && column.value !== 'helper' && <div className="resize-handle" onMouseDown={() => onMouseDown(idx, column.value)}></div>}
      {idx === 1 && <div className="resize-handle resize-handle--before" onMouseDown={() => onMouseDown(0, 'name')}></div>}
    </div>
  )
})

export default ResizableExternalDocumentColumn
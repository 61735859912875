import React, { createContext, useReducer, useState, useContext } from 'react';

import signatureReducer from './signaturesReducer';
import { GET_SIGNATURES, ADD_SIGNATURE, DELETE_SIGNATURE, RESET_STATE, SET_SIGNATURES, SET_SIGNATURES_OBJ } from '../types';
import { fetch_signatures, create_signature, update_signature } from '../../services/firestore';
import { sortArrayOfObjects, getTeamSortingData, getSelectedTeamFromLS } from '../../utils';
import { ConstantsContext } from '../'

export const SignatureContext = createContext();

const selectedTeam = getSelectedTeamFromLS()
const sortingData = getTeamSortingData()

let defaultSort = { value: 'title', order: 'desc' }
if(selectedTeam && sortingData && sortingData[selectedTeam] && sortingData[selectedTeam].signatures) {
  defaultSort = { value: sortingData[selectedTeam].signatures.value || 'title', order: sortingData[selectedTeam].signatures.order || 'desc' }
}

const SignatureState = ({ children }) => {
  const { SIGNATURE_STATUS_PROVIDERS } = useContext(ConstantsContext)
  const initialState = {
    signaturesObj: {},
    signatures: [],
    signaturesFetched: false
  }
  const [signaturesWithStatus, setSignaturesWithStatus] = useState([]);
  const [signaturesSort, setSignaturesSort] = useState(defaultSort);
  const [signaturesReset, setSignaturesReset] = useState(false)
  const [signaturesSelectedFilters, setSignaturesSelectedFilters] = useState({
    status: 'all',
    search: ''
  })
  const [state, dispatch] = useReducer(signatureReducer, initialState);


  // Fetch signatures
  const fetchSignatures = async (teamId, onSuccess = () => {}) => {
    try {
      const res = await fetch_signatures(teamId);
      if(res.error) {
        dispatch({
          type: GET_SIGNATURES,
          payload: [],
          obj: {}
        });
        throw new Error(res.error?.message || 'tasks cannot be fetched')
      }
      const arr = [];
      for(let id in res) {
        const signature = {...res[id]};
        signature.id = id;
        signature.status_label = SIGNATURE_STATUS_PROVIDERS[res[id].status] 
        signature.sort_recipients = signature.recipients ? signature.recipients.length : 0
        arr.push(signature);
      }
      let sortedArr = sortArrayOfObjects(arr, signaturesSort.value, signaturesSort.order);
      sortedArr.forEach((item, idx) => item.index = idx + 1)
      dispatch({
        type: GET_SIGNATURES,
        payload: sortedArr,
        obj: res
      });
      onSuccess();
    }catch(err) {
      console.log(err);
    }
  }

  // Add signature
  const addSignature = async (data) => {
    try {
      const res = await create_signature(data);

      if(state.signaturesFetched) {
        const signature = {...data, id: res.id, status_label: SIGNATURE_STATUS_PROVIDERS[data.status]};
        const signaturesCopy = [...state.signatures];
        signaturesCopy.unshift(signature);
        dispatch({
          type: ADD_SIGNATURE,
          payload: signaturesCopy
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Update signature
  const updateSignature = async (id, data) => {
    try {
      await update_signature(id, data);
      const signatures = [...state.signatures];
      const findS = signatures.find(s => s.id === id);
      const updatedS = {...findS, ...data};
      const updatedSignatures = signatures.map(s => s.id === id ? updatedS : s);

      dispatch({
        type: SET_SIGNATURES,
        payload: sortArrayOfObjects(updatedSignatures, signaturesSort.value, signaturesSort.order)
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Delete signature
  const deleteSignature = async (ids) => {
    try {
      const signatures = [...state.signatures];
      const filtered = signatures.filter(s => !ids.includes(s.id));
      dispatch({
        type: DELETE_SIGNATURE,
        payload: filtered
      });
    } catch (err) {
      console.log(err);
    }
  } 

  // Set signatures
  const setSignatures = (value) => {
    dispatch({
      type: SET_SIGNATURES,
      payload: sortArrayOfObjects(value, 'title', 'asc')
    });
  }

  // Set tags
  const setSignaturesObj = (value) => {
    let arr = []
    for(let id in value) {
      arr.push({...value[id], id})
    }
    setSignatures(arr)
    dispatch({
      type: SET_SIGNATURES_OBJ,
      payload: value
    })
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, signaturesFetched: true } : initialState,
      payload: initialState,
    });
    const selectedTeam = getSelectedTeamFromLS()
    const sortingData = getTeamSortingData()

    let defaultSort = { value: 'title', order: 'desc' }
    if(selectedTeam && sortingData && sortingData[selectedTeam] && sortingData[selectedTeam].signatures) {
      defaultSort = { value: sortingData[selectedTeam].signatures.value || 'title', order: sortingData[selectedTeam].signatures.order || 'desc' }
    }
    setSignaturesSort(defaultSort)
    setSignaturesReset(true)
  }

  return <SignatureContext.Provider value={{
    signatures: state.signatures,
    signaturesFetched: state.signaturesFetched,
    signaturesWithStatus,
    signaturesSort,
    fetchSignatures,
    addSignature,
    deleteSignature,
    setSignaturesWithStatus,
    resetSignatureState: resetState,
    updateSignature,
    setSignaturesObj,
    setSignaturesSort,
    signaturesReset,
    setSignaturesReset,
    signaturesSelectedFilters,
    setSignaturesSelectedFilters,
  }}>
    {children}
  </SignatureContext.Provider>
}

export default SignatureState;
import { GET_APPROVALS, SET_APPROVALS, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_APPROVALS:
      return {
        ...state,
        approvals: action.payload,
        approvalsFetched: true,
      }
    case SET_APPROVALS:
      return {
        ...state,
        approvals: action.payload,
      }
    case RESET_STATE:
      return action.payload
    default:
      return state
  }
}

export default reducer
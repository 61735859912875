import { useContext, useCallback } from 'react'

import { NotificationContext, LoaderContext, GlobalContext, ContactsContext, TeamContext } from '../context'
import { update_contact } from '../services/contacts'
import { validateEmail } from '../helpers/validate'

const useSingleContactActions = () => {
  const { deleteContact, createContact, updateContact } = useContext(ContactsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useContext(GlobalContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)

  // Create contact
  const createSingleContact = async ({ group, firstName, lastName, email, title, address, streetNumber, zipCode, city, country, phoneNumber, dob, nationality, website, customContactFields, onClose, refreshContacts }) => {
    if(!group || !firstName.trim() || !lastName.trim() || !email.trim()) {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }

    if(email && email.trim() && !validateEmail(email.trim())) {
      return setNotification({ msg: t('notification.invalid_email'), type: 'danger' })
    }

    let data = {
      email: email.trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      title: title.trim(),
      address: address.trim(),
      street_number: streetNumber.trim(),
      zip_code: zipCode.trim(),
      city: city.trim(),
      country: country.trim(),
      phone_number: phoneNumber.trim(),
      dob: new Date(dob).getTime(),
      nationality: nationality.trim(),
      website: website.trim(),
    }

    if(customContactFields.length > 0) {
      customContactFields.forEach(f => {
        data[f.key] = f.value 
      })
    }

    let processedData = { 
      team: selectedTeam.id, 
      owner: activeTeamMember.id, 
      meta: { 
        created: Date.now(), 
        updated: Date.now() 
      },
      group
    }
    for(let key in data) {
      if(data[key]) {
        processedData[key] = data[key]
      }
    }

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('contacts.creating_contact'))
    await createContact(processedData, selectedTeam.id, () => {
      setNotification({ msg: t('contacts.contact_created'), type: 'success' })
      refreshContacts && refreshContacts()
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
    onClose()
  }

  // Update contact
  const updateSingleContact = async ({ group, firstName, lastName, email, title, address, streetNumber, zipCode, city, country, phoneNumber, dob, nationality, website, customContactFields, contactToEdit, onClose, refreshContacts }) => {
    if(!group || !firstName.trim() || !lastName.trim() || !email.trim()) {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }
    
    if(email && email.trim() && !validateEmail(email.trim())) {
      return setNotification({ msg: t('notification.invalid_email'), type: 'danger' })
    }

    let data = {
      email: email.trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      title: title.trim(),
      address: address.trim(),
      street_number: streetNumber.trim(),
      zip_code: zipCode.trim(),
      city: city.trim(),
      country: country.trim(),
      phone_number: phoneNumber.trim(),
      dob: new Date(dob).getTime(),
      nationality: nationality.trim(),
      website: website.trim(),
    }

    if(customContactFields.length > 0) {
      customContactFields.forEach(f => {
        data[f.key] = f.value 
      })
    }

    let processedData = {...contactToEdit}
    if(processedData.id) delete processedData.id
    processedData.group = group
    if(!processedData.meta) {
      processedData.meta = { updated: Date.now() }
    }else {
      processedData.meta = { ...processedData.meta, updated: Date.now() }
    }
    for(let key in data) {
      if(data[key] || contactToEdit[key]) {
        processedData[key] = data[key]
      }
    }

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('contacts.updating_contact'))
    await updateContact(processedData, contactToEdit.id, () => {
      setNotification({ msg: t('contacts.contact_updated'), type: 'success' })
      refreshContacts && refreshContacts()
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
    onClose()
  }

  // Delete single contact
  const deleteSingleContact = useCallback(async ({ contact, refreshContacts }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Deleting contact')

    await update_contact({ deleting: true }, contact.id)
    await deleteContact(contact.id, () => {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
      refreshContacts && refreshContacts()
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    })
  }, [deleteContact, setGlobalResponseLoaderText, setShowGlobalResponseLoader, setNotification, t])

  return {
    createSingleContact,
    updateSingleContact,
    deleteSingleContact,
  }
}

export default useSingleContactActions
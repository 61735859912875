import { useState, useContext, useEffect, useCallback, useRef } from 'react' 
import { ReactSortable } from 'react-sortablejs'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { ResizableSignatureColumn, SignaturesRow, SignaturesActions, ColumnsFilter } from '../'
import { GlobalContext, SignatureContext, TeamContext, ColumnsContext } from '../../../context'
import { getTeamPaginationData, getTeamColumnsOrderData, getTeamColumnsData, isCookieAccepted, saveTeamPaginationDataToLS, saveTeamColumnsOrderDataToLS, removeTeamColumnsOrderData, saveTeamSignaturesColumnWidthsToLS } from '../../../utils'
import { perPageOptions } from '../../../constants'

const SignaturesView = ({ filteredSignatures, onSort, selectedSignatures, setSelectedSignatures, allChecked, onAllCheckedChange, paginatedData, setPaginatedData, refreshSignatures, ready, setAllChecked, onStatusClick }) => {
  const { t } = useContext(GlobalContext)
  const { setSignaturesSelectedFilters } = useContext(SignatureContext)
  const { selectedTeam } = useContext(TeamContext)
  const { signaturesTableWidth, setSignaturesTableWidth, signaturesTableColumnsWidths, setSignaturesTableColumnsWidths } = useContext(ColumnsContext)
  const [defaultFields, setDefaultFields] = useState([])
  const [columns, setColumns] = useState([
    { label: t('dashboard.title'), value: 'title', icon: 'text_fields', checked: true },
    { label: t('dashboard.recipients'), value: 'recipients.email__arr', icon: 'group', checked: true },
    { label: t('dashboard.sent_by'), value: 'sentBy', icon: 'group', checked: true },
    { label: t('dashboard.created_on'), value: 'createdAt', icon: 'calendar_month', checked: true },
    { label: t('dashboard.status'), value: 'status_label', icon: 'circle', checked: true },
  ])
  const [search, setSearch] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [paginationReady, setPaginationReady] = useState(false)
  const [columnsUpdated, setColumnsUpdated] = useState(false)
  const [activeResizableColumn, setActiveResizableColumn] = useState(null)
  const [tableWidthReady, setTableWidthReady] = useState(false)
  const colRefs = useRef([])

  // Check in LS to see if pagination data was saved and if it is saved update pagination state variables
  useEffect(() => {
    const paginationData = getTeamPaginationData()
    if(selectedTeam && paginationData && paginationData[selectedTeam.id] && paginationData[selectedTeam.id].signatures) {
      const data = paginationData[selectedTeam.id].signatures
      let all = Math.ceil(filteredSignatures.length / data.items || 10)
      data.items && setPerPage(data.items)
      data.current && setCurrentPage(data.current <= all ? data.current : 1)
      data.all && setAllPages(data.all)
    }
  }, [filteredSignatures, selectedTeam])

  // order columns...
  useEffect(() => {
    if(selectedTeam && !columnsUpdated) {
      let shouldUpdate = false
      let newColumns = [...columns]
      setDefaultFields([...columns, { label: '', value: 'helper', icon: '', checked: true }])

      // Show only checked columns
      const teamColData = getTeamColumnsData()
      if(teamColData && teamColData[selectedTeam.id]) {
        const signaturesColsValues = teamColData[selectedTeam.id].signatures
        let arr = []
        if(signaturesColsValues) {
          arr = newColumns.map(c => signaturesColsValues.includes(c.value) ? {...c, checked: true} : {...c, checked: c.value !== 'title' ? false : true})
          newColumns = arr
        }
      }

      // Change order
      const colOrderValues = getTeamColumnsOrderData()
      if(colOrderValues && colOrderValues[selectedTeam.id]) {
        const collectionColOrderValues = colOrderValues[selectedTeam.id].signatures 
        if(collectionColOrderValues) {
          let orderedCols = [] 
          collectionColOrderValues.forEach(val => {
            const col = newColumns.find(c => c.value === val)
            col && orderedCols.push(col)
          })
          // Check if there are some columns that were not saved to columns order array, if so add them 
          const filterHiddenValues = [] 
          newColumns.forEach(c => {
            if(!collectionColOrderValues.includes(c.value)) {
              filterHiddenValues.push(c)
            }
          })
          if(filterHiddenValues.length > 0) {
            orderedCols.push(...filterHiddenValues)
          }
          newColumns = orderedCols
          shouldUpdate = true
        }
      }

      // Add helper column
      newColumns.push({ label: '', value: 'helper', icon: '', checked: true })
      shouldUpdate = true

      // Update only if new columns are added or order changed
      if(shouldUpdate) {
        setColumns(newColumns)
      }
      setColumnsUpdated(true)
    }
  }, [selectedTeam, columnsUpdated, columns, t])

  // Calculate table width
  const calculateTableWidth = useCallback(() => {
    let width = 0
    let columnsSaved = false
    let savedColumns = []
    const carbonColumns = getTeamColumnsData()
    if(carbonColumns && carbonColumns[selectedTeam?.id]) {
      const cols = carbonColumns[selectedTeam?.id].signatures
      if(cols) {
        columnsSaved = true
        savedColumns = cols
      }
    }
    
    columns.forEach(col => {
      if(col.value !== 'helper') {
        if(columnsSaved) {
          if(savedColumns.includes(col.value)) {
            width += signaturesTableColumnsWidths[col.value] || 200
          }
        }else {
          if(col.checked) {
            width += signaturesTableColumnsWidths[col.value] || 200
          }
        }
      }
    })
    setSignaturesTableWidth(width)
  }, [columns, selectedTeam, setSignaturesTableWidth, signaturesTableColumnsWidths])

  // Set width to each column and calculate table width
  useEffect(() => {
    if(!tableWidthReady) {
      calculateTableWidth()
      setTableWidthReady(true)
    }
  }, [calculateTableWidth, tableWidthReady])

  // Change paginated data arr when filtered arr changes
  useEffect(() => {
    let current = currentPage
    let sliceFrom = current === 1 ? 0 : (current - 1) * perPage
    let slicedData
    if(sliceFrom < filteredSignatures.length) {
      slicedData = filteredSignatures.slice(sliceFrom, sliceFrom + perPage)
    }else {
      slicedData = filteredSignatures.slice(0, perPage)
    }
    setPaginatedData(slicedData)
    let all = Math.ceil(filteredSignatures.length / perPage)
    setAllPages(all === 0 ? 1 : all)
    setTimeout(() => setPaginationReady(true), 200)
  }, [filteredSignatures, perPage, currentPage, allPages, setPaginatedData])

  // On search change
  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setSignaturesSelectedFilters(prev => ({...prev, search: e.target.value}))
    setSelectedSignatures([])
  }

  // On prev button click 
  const handlePrevButtonClick = (e) => {
    e.preventDefault()
    if(currentPage === 1) return 
    setCurrentPage(currentPage - 1)
    setSelectedSignatures([])
    setAllChecked(false)
    if(isCookieAccepted()) {
      saveTeamPaginationDataToLS(selectedTeam?.id, perPage, currentPage - 1, allPages, 'signatures')
    }
  }

  // On next button click 
  const handleNextButtonClick = (e) => {
    e.preventDefault()
    if(currentPage === allPages) return 
    setCurrentPage(currentPage + 1)
    setSelectedSignatures([])
    setAllChecked(false)
    if(isCookieAccepted()) {
      saveTeamPaginationDataToLS(selectedTeam?.id, perPage, currentPage + 1, allPages, 'signatures')
    }
  }

  // On go to specific page
  const handleGoToPage = (e, page) => {
    e.preventDefault()
    if(currentPage === page) return 
    setCurrentPage(page)
    setSelectedSignatures([])
    setAllChecked(false)
    if(isCookieAccepted()) {
      saveTeamPaginationDataToLS(selectedTeam?.id, perPage, page, allPages, 'signatures')
    }
  }

  // Per page change
  const handlePerPageChange = (e, num) => {
    e.preventDefault()
    setPerPage(num)
    setSelectedSignatures([])
    setAllChecked(false)
    if(Math.ceil(filteredSignatures.length / num) < currentPage) {
      setCurrentPage(1)
    }
    window.document.body.click()
    if(isCookieAccepted()) {
      saveTeamPaginationDataToLS(selectedTeam?.id, num, currentPage, allPages, 'signatures')
    }
  }

  // Save columns order
  const handleSaveColumnsOrder = useCallback(() => {
    const colOrderValues = getTeamColumnsOrderData()
    if(isCookieAccepted()) {
      let arr = columns.map(f => f.value)
      saveTeamColumnsOrderDataToLS(selectedTeam?.id, arr, 'signatures')
    }else {
      if(colOrderValues) {
        removeTeamColumnsOrderData()
      } 
    }
  }, [columns, selectedTeam])

  // On mouse down - set active resizable column
  const handleMouseDown = useCallback((idx, val) => {
    setActiveResizableColumn({ index: idx, value: val })
  }, [])

  // On mouse move - only when there is active resizable column
  const handleMouseMove = useCallback((e) => {
    if(selectedTeam && activeResizableColumn !== null) {
      const el = colRefs.current[activeResizableColumn.index]
      if(!el) return
      const rect = el.getBoundingClientRect()
      const width = e.clientX - rect.x

      if(width > 200) {
        el.style.width = width + 'px'
        let newWidths
        newWidths = {...signaturesTableColumnsWidths}
        newWidths[activeResizableColumn.value] = width
        setSignaturesTableColumnsWidths(newWidths)

        let totalWidth = 0
        columns.forEach(col => {
          if(col.value !== 'helper') {
            totalWidth += newWidths[col.value] || 200
          }
        })
        setSignaturesTableWidth(totalWidth)
        if(isCookieAccepted()) {
          saveTeamSignaturesColumnWidthsToLS(selectedTeam.id, newWidths)
        }
      }
    }
    // eslint-disable-next-line
  }, [activeResizableColumn, columns, selectedTeam])

  // Add mouse event listeners
  useEffect(() => {
    const handleMouseUp = () => {
      setActiveResizableColumn(null)
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }

    if (activeResizableColumn !== null) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }
  }, [activeResizableColumn, handleMouseMove])

  return (
    <div className="list-view-v2">
      <div className="list-view-v2__head">
        <div className="search">
          <div className="icon">
            <span className="material-symbols-outlined weight-300">search</span>
          </div>
          <input type="text" value={search} onChange={handleSearchChange} />
        </div>
        <div className="per-page">
          <ActionsDropdown
            trigger={<a href="#/" className="text-style-4">{perPage} {t('general.per_page')} <span className="material-symbols-outlined">expand_more</span></a>}
            hideHeader
            dropdownClass="pagination-dropdown"
            width={120}
          >
            <ul>
              {perPageOptions.map((num, i) => (
                <li key={i}>
                  <div className="link">
                    <a href="/#" onClick={(e) => handlePerPageChange(e, num)}>{num}</a>
                  </div>
                </li>
              ))}
            </ul>
          </ActionsDropdown>
        </div>
        <div className="filter">
          <ColumnsFilter 
            columns={columns} 
            setColumns={setColumns} 
            onOrderSave={handleSaveColumnsOrder} 
            view="signatures" 
            defaultFields={defaultFields}
            calculateTableWidth={calculateTableWidth}
          />
        </div>
      </div>

      <div className="list-view-v2__body">
        <div className="list-view-table-wrapper u-custom-scrollbar u-custom-scrollbar--3">
          {selectedSignatures.length > 0 && (
            <SignaturesActions allChecked={allChecked} onAllCheckedChange={onAllCheckedChange} setSelectedItems={setSelectedSignatures} selectedItems={selectedSignatures} refreshSignatures={refreshSignatures} />
          )}
          <div className="list-view-table" style={{minWidth: signaturesTableWidth}}>
            <div className="list-view-table__head">
              <ReactSortable 
                list={columns} 
                setList={setColumns} 
                animation={200} 
                delayOnTouchStart={true} 
                delay={2} 
                dragClass="dragged-field--2"
                ghostClass="selected-field"
                filter=".filtered-out"
                draggable=".draggable-item"
                onEnd={handleSaveColumnsOrder}
                handle=".sort"      
                className="sortable-row-items"  
              >
                {columns.filter(c => c.checked).map((column, idx) => {
                  return (
                    <ResizableSignatureColumn column={column} idx={idx} onMouseDown={handleMouseDown} onSort={onSort} ref={(item) => colRefs.current[idx] = item} key={idx} />
                  )
                })}
              </ReactSortable>
              <div className="list-view-table__cell checkbox">
                {!allChecked 
                  ? <span className="material-symbols-outlined" onClick={onAllCheckedChange}>check_box_outline_blank</span>
                  : <span className="material-symbols-outlined filled" onClick={onAllCheckedChange}>check_box</span>
                }
              </div>
            </div>
            <div className="list-view-table__body">
              {(!ready || !paginationReady) && (
                <div className="loader-wrapper"><div className="loader-v2"></div></div>
              )}
              {ready && paginationReady && paginatedData.map((doc, idx) => {

                return (
                  <SignaturesRow 
                    key={idx} 
                    doc={doc}
                    id={doc.id}
                    selectedSignatures={selectedSignatures}
                    setSelectedSignatures={setSelectedSignatures}
                    allChecked={allChecked}
                    columns={columns}
                    refreshSignatures={refreshSignatures}
                    onStatusClick={onStatusClick}
                  />
                )
              })}
              {ready && paginationReady && paginatedData.length === 0 && (
                <div className="no-data">
                  <p className="text-style-3">{t('folder.empty')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="list-view-v2__foot">
        <div className="pagination-v2">
          <a href="/#" className="prev" onClick={handlePrevButtonClick}><span className="material-symbols-outlined weight-300">arrow_back</span></a>
          {Array(allPages).fill().map((_, idx) => {
            return (
              <a href="/#" key={idx} className={`${idx+1 === currentPage ? 'active' : ''}`} onClick={(e) => handleGoToPage(e, idx+1)}>
                {idx+1}
              </a>
            )
          })}
          <a href="/#" className="next" onClick={handleNextButtonClick}><span className="material-symbols-outlined weight-300">arrow_forward</span></a>
        </div>
      </div>
    </div>
  )
}

export default SignaturesView
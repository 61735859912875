import React from 'react';
import ReactDOM from 'react-dom';

import Loader from './Loader';

const ResponseLoader = ({ text, progress }) => {
  const content = (
    <div className="response-loader">
      {text && <p className="response-loader__text">{text}</p>}
      {progress !== undefined && <div className="response-loader__progress"><div style={{width: `${progress}%`}}></div><p>{progress}%</p></div>}
      <Loader normal primary />
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById('page-loader'));
};

export default ResponseLoader;
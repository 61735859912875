import React, { useState, useEffect, useContext, useCallback } from 'react'
import BallotIcon from '@material-ui/icons/BallotOutlined'

import Modal from '../../UI/Modal'
import Checkbox from '../../UI/Checkbox'
import Button from '../../UI/Button'
import ResponseLoader from '../../UI/ResponseLoader'
import Loader from '../../UI/Loader'
import { fetchFeatures, updateFeature } from '../../../services/db_features'
import { update_user } from '../../../services/firestore'
import { GlobalContext, UserContext } from '../../../context'

const VoteUpcomingFeaturesModal = ({ onClose }) => {
  const { newFeatures, setNewFeatures, featuresFetched, setFeaturesFetched, t } = useContext(GlobalContext)
  const { user, setUser } = useContext(UserContext)
  const [features, setFeatures] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  // Get features
  useEffect(() => {
    const fetchData = async () => {
      try {
        const arr = await fetchFeatures()
        if(Array.isArray(arr)) {
          setNewFeatures(arr)
          const formattedFeatures = arr.map(f => ({ name: f.description['en'], checked: false, id: f.id }))
          setFeatures(formattedFeatures)
          setFeaturesFetched(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if(!featuresFetched) {
      fetchData()
    }else {
      const formattedFeatures = newFeatures.map(f => ({ name: f.description['en'], checked: false, id: f.id }))
      setFeatures(formattedFeatures)
    }
  }, [featuresFetched, setFeaturesFetched, setNewFeatures, newFeatures])

  // Checkbox change handler
  const checkboxChangeHandler = (e, id) => {
    setFeatures(prev => {
      const current = prev.find(f => f.id === id)
      current.checked = e.target.checked 
      return prev.map(f => f.id === id ? current : f)
    })
  }

  // Submit votes handler
  const submitHandler = useCallback(async (e) => {
    e.preventDefault()
    let checkedItems = features.filter(f => f.checked)
    if(checkedItems.length) {
      setShowLoader(true)
      let promises = []

      checkedItems.forEach(item => {
        const { id } = item
        let feature = newFeatures.find(f => f.id === id)
        if(feature) {
          let votes = feature.votes || []
          if(!votes.includes(user.id)) {
            votes.push(user.id)
            promises.push(updateFeature(id, votes))
          }
        }
      })

      if(promises.length) {
        await Promise.all(promises) 
        const votedFeatures = newFeatures.map(f => f.id)
        await update_user({ featuresWhenVoted: votedFeatures }, user.id)
        setUser({ ...user, featuresWhenVoted: votedFeatures })
      }
      setShowLoader(false)
      onClose()
    }
  }, [features, newFeatures, onClose, setUser, user])

  // Check if already voted
  const alreadyVoted = () => {
    let voted = true
    const { featuresWhenVoted } = user
    let featureIds = []
    let myVotes = 0
    newFeatures.forEach(f => {
      featureIds.push(f.id)
      if(f.votes.includes(user.id)) {
        myVotes++
      }
    })
    
    // If no user votes show form
    if(myVotes === 0) {
      voted = false
    }

    if(voted && featuresWhenVoted) {
      if(featuresWhenVoted.length === featureIds.length || featuresWhenVoted.length > featureIds.length) {
        for(let i = 0; i < featureIds.length; i++) {
          // show form if there is new feature id 
          if(!featuresWhenVoted.includes(featureIds[i])) {
            voted = false
            break
          }
        }
      }else{
        voted = false
      }
    }

    return voted
  }

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        submitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [submitHandler])

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><BallotIcon /> {t('support.vote_features')}</h3>
        </div>
        <div className="report-a-bug__body">
          <p>{t('support.next_features')}</p>
          {!featuresFetched 
            ? <div className="loader-wrapper"><Loader primary normal small /></div>
            : !alreadyVoted() 
              ? 
              <form onSubmit={submitHandler}>
                <ul className="features u-custom-scrollbar">
                  {features.map((feature, idx) => (
                    <li className="features__item" key={idx}>
                      <Checkbox 
                        name={feature.id}
                        checked={features.find(f => f.id === feature.id).checked} 
                        onChange={(e) => checkboxChangeHandler(e, feature.id)} 
                        label={feature.name}
                      />
                    </li>
                  ))}
                </ul>
                <div className="btn-wrapper">
                  <Button type="submit" text={t('support.submit_your_vote')} primary medium disabled={features.filter(f => f.checked).length === 0} />
                </div>
              </form>
              :
              <p className="already-voted">{t('support.already_voted')}</p>
          }
        </div>
      </div>

      {showLoader && <ResponseLoader text={t('support.submitting_votes')} />}
    </Modal>
  )
}

export default VoteUpcomingFeaturesModal
import { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { SettingsLayout } from '../layouts'
import { LinkUserFieldToVariable } from '../components/misc'
import { Input, Select, Option } from '../components/new_ui'
import { GlobalContext, UserContext, TeamContext, VariablesContext, NotificationContext } from '../context'
import { useForm, useUserActions } from '../hooks'
import { appLanguages, getFileData } from '../utils'

const initialData = {
  first_name: {
    value: '',
    required: true,
  },
  last_name: {
    value: '',
    required: true,
  },
  email: {
    value: '',
    required: true,
  },
  job_position: {
    value: '',
  },
  phone: {
    value: '',
  },
  mobile: {
    value: ''
  },
}

const MyProfile = () => {
  const { t, validate, selectedLang } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)
  const { variables, variablesFetched, fetchVariables } = useContext(VariablesContext)
  const { setNotification } = useContext(NotificationContext)
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate)
  const { uploadUserImage, removeUserImage, saveUser, updateLanguage, linkVariableToUserField, removeVariableFromUserField } = useUserActions()
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [filteredVariables, setFilteredVariables] = useState([])
  const [filteredVarsSet, setFilteredVarsSet] = useState(false)
  const [linkedVariables, setLinkedVariables] = useState({})
  const [imageUrl, setImageUrl] = useState('')
  const fileInput = useRef()
  const history = useHistory()

  // Fetch variables if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async () => {
      if(!variablesFetched) {
        // console.log('fetch variables')
        fetchVariables(selectedTeam)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, variablesFetched, fetchVariables])

  // Set filtered variables
  useEffect(() => {
    if(variablesFetched && !filteredVarsSet) {
      setFilteredVariables(variables)
      setFilteredVarsSet(true)
    }
  }, [variablesFetched, variables, filteredVarsSet])

  // Prefill user data
  useEffect(() => {
    if(user) {
      setFormData(prev => ({
        ...prev,
        first_name: {
          ...prev.first_name,
          value: user.first_name ? user.first_name : '',
        },
        last_name: {
          ...prev.last_name,
          value: user.last_name ? user.last_name : '',
        },
        email: {
          ...prev.email,
          value: user.email ? user.email : '',
        },
        job_position: {
          ...prev.job_position,
          value: user.job_position ? user.job_position : '',
        },
        phone: {
          ...prev.phone,
          value: user.phone ? user.phone : '',
        },
        mobile: {
          ...prev.mobile,
          value: user.mobile ? user.mobile : '',
        },
      }));
      if(user.image_url) {
        setImageUrl(user.image_url);
      }
      if(user.profile_linked_vars) {
        setLinkedVariables(user.profile_linked_vars)
      }
    }
  }, [user, setFormData])

  // On image change
  const handleImageChange = useCallback(async (e) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/gif']
    const file = e.target.files[0]
    if(file) {
      if(!allowedTypes.includes(file.type)) {
        return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      }
      getFileData(file, uploadUserImage)
    }
  }, [setNotification, uploadUserImage, t])

  // On image remove
  const handleRemoveImage = useCallback((e) => {
    e.preventDefault()
    removeUserImage({ setImageUrl })
  }, [removeUserImage])

  // On submit
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    saveUser({ formData, setErrors })
  }, [formData, saveUser, setErrors])

  // On link variable
  const handleLinkVariable = useCallback((fieldKey, selected) => {
    linkVariableToUserField({ fieldKey, selected, linkedVariables, setLinkedVariables })
  }, [linkVariableToUserField, linkedVariables])

  // On remove linked variable
  const handleRemoveLinkedVariable = useCallback((fieldKey) => {
    removeVariableFromUserField({ fieldKey, linkedVariables, setLinkedVariables })
  }, [linkedVariables, removeVariableFromUserField])

  // On language change
  const handleLanguageChange = useCallback(async (lang) => {
    updateLanguage({ lang })
  }, [updateLanguage])

  // On cancel
  const handleCancel = useCallback(() => {
    history.goBack()
  }, [history])

  // Render user fields
  const renderUserFields = useMemo(() => {
    return (
      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="profile-form__group profile-form__group--image">
          <div className="image-box"
            style={imageUrl ? { backgroundImage: `url(${imageUrl})`} : {}}
          ></div>
          <div className="actions">
            <input type="file" accept="image/png,image/jpg,image/jpeg" onChange={handleImageChange} className="hidden" ref={fileInput} />
            <button type="button" className="btn btn--small btn--outline" onClick={() => fileInput.current.click()}>
              <span className="material-symbols-outlined">file_upload</span>
              {t('general.upload_image')}
            </button>
            {imageUrl && <a href="#/" onClick={handleRemoveImage} className="btn btn--small btn--danger-light">
              <span className="material-symbols-outlined">delete_forever</span>
              {t('general.delete_image')}
            </a>}
          </div>
        </div>
        <div className="profile-form__group">
          <Input 
            name="first_name"
            label={t('auth.first_name')}
            value={formData.first_name.value}
            onChange={changeHandler}
            error={errors.first_name}
            formEl
            labelDropdown={<LinkUserFieldToVariable 
              fieldName={t('auth.first_name')}
              fieldKey={'first_name'}
              filteredVariables={filteredVariables}
              linkedVariables={linkedVariables}
              onLinkVariable={handleLinkVariable}
              onRemoveLinkedVariable={handleRemoveLinkedVariable} 
              onSetFilteredVariables={setFilteredVariables}
              variables={variables}
            />}
          />
          <Input 
            name="last_name"
            label={t('auth.last_name')}
            value={formData.last_name.value}
            onChange={changeHandler}
            error={errors.last_name}
            formEl
            labelDropdown={<LinkUserFieldToVariable 
              fieldName={t('auth.last_name')}
              fieldKey={'last_name'}
              filteredVariables={filteredVariables}
              linkedVariables={linkedVariables}
              onLinkVariable={handleLinkVariable}
              onRemoveLinkedVariable={handleRemoveLinkedVariable} 
              onSetFilteredVariables={setFilteredVariables}
              variables={variables}
            />}
          />
        </div>
        <div className="profile-form__group">
          <Input 
            name="email"
            label={t('auth.email')}
            value={formData.email.value}
            onChange={changeHandler}
            error={errors.email}
            formEl
            disabled
            labelDropdown={<LinkUserFieldToVariable 
              fieldName={t('auth.email')}
              fieldKey={'email'}
              filteredVariables={filteredVariables}
              linkedVariables={linkedVariables}
              onLinkVariable={handleLinkVariable}
              onRemoveLinkedVariable={handleRemoveLinkedVariable} 
              onSetFilteredVariables={setFilteredVariables}
              variables={variables}
            />}
          />
          <Input 
            name="job_position"
            label={t('general.job_position')}
            value={formData.job_position.value}
            onChange={changeHandler}
            error={errors.job_position}
            formEl
            labelDropdown={<LinkUserFieldToVariable 
              fieldName={t('general.job_position')}
              fieldKey={'job_position'}
              filteredVariables={filteredVariables}
              linkedVariables={linkedVariables}
              onLinkVariable={handleLinkVariable}
              onRemoveLinkedVariable={handleRemoveLinkedVariable} 
              onSetFilteredVariables={setFilteredVariables}
              variables={variables}
            />}
          />
        </div>
        <div className="profile-form__group">
          <Input 
            name="phone"
            label={t('general.phone')}
            value={formData.phone.value}
            onChange={changeHandler}
            error={errors.phone}
            formEl
            labelDropdown={<LinkUserFieldToVariable 
              fieldName={t('general.phone')}
              fieldKey={'phone'}
              filteredVariables={filteredVariables}
              linkedVariables={linkedVariables}
              onLinkVariable={handleLinkVariable}
              onRemoveLinkedVariable={handleRemoveLinkedVariable} 
              onSetFilteredVariables={setFilteredVariables}
              variables={variables}
            />}
          />
          <Input 
            name="mobile"
            label={t('general.mobile_phone')}
            value={formData.mobile.value}
            onChange={changeHandler}
            error={errors.mobile}
            formEl
            labelDropdown={<LinkUserFieldToVariable 
              fieldName={t('general.mobile_phone')}
              fieldKey={'mobile'}
              filteredVariables={filteredVariables}
              linkedVariables={linkedVariables}
              onLinkVariable={handleLinkVariable}
              onRemoveLinkedVariable={handleRemoveLinkedVariable} 
              onSetFilteredVariables={setFilteredVariables}
              variables={variables}
            />}
          />
        </div>
        <div className="profile-form__group">
          <Select 
            label={`${t('general.change_language')}`}
            selected={selectedLang} 
            onChange={handleLanguageChange} 
            alignDropdownRight={true}
            className="select-v2--new"
            useSymbolDropdownIcon={true}
          >
            { appLanguages.map((lang, li) => 
              <Option key={`lang_option_${li}`} value={lang.languageCode}>{lang.labelShort.toUpperCase()}</Option>
            )}
          </Select>
        </div>
        <div className="profile-form__actions">
          <button type="button" className="btn btn--gray btn--fw-500 btn--with-letter-spacing" onClick={handleCancel}>{t('general.cancel')}</button>
          <button type="submit" className="btn btn--fw-500 btn--with-letter-spacing">{t('general.save')}</button>
        </div>
      </form>
    )
  }, [errors, changeHandler, handleImageChange, formData, filteredVariables, variables, handleSubmit, handleLinkVariable, handleRemoveLinkedVariable, imageUrl, linkedVariables, handleRemoveImage, handleLanguageChange, selectedLang, t, handleCancel])

  return (
    <SettingsLayout title={t('dashboard.my_profile')}>
      {user && Object.keys(user).length > 0 ? renderUserFields : <div className="loader-v2"></div>}
    </SettingsLayout> 
  )
}

export default MyProfile 
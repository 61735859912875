import { useContext, useState } from 'react';

import CustomTooltip from '../../UI/CustomTooltip';
import { Alert } from '../../new_ui';
import { FolderListItem, CreateEditFolderModal } from '../';
import {
  GlobalContext,
  DocumentsContext,
  DocumentsFoldersContext,
  NotificationContext,
} from '../../../context';
import {
  useDocumentsFoldersActions,
  useSingleDocumentActions,
} from '../../../hooks';
import { folderHasTemplates } from '../../../utils';

const DocumentsSidebar = ({
  onFilter,
  currentFolder,
  setCurrentFolder,
  setFolderCreated,
  resetFolders,
  setResetFolders,
  setSelectedDocuments,
  refreshDocs,
}) => {
  const { t } = useContext(GlobalContext);
  const {
    docNumbers,
    documents,
    documentsLoaded,
    documentsArr,
    setDocsSelectedFilters,
    docsSelectedFilters,
    currentFilter,
  } = useContext(DocumentsContext);
  const {
    docFolders,
    docFoldersLoading,
    setDocParentFolder,
    docFolderToMove,
    setDocFoldersSelectedFilter,
  } = useContext(DocumentsFoldersContext);
  const { setNotification } = useContext(NotificationContext);
  const { deleteDocumentFolder, moveDocumentsFolder, updateBreadcrumbs } =
    useDocumentsFoldersActions();
  const {
    moveDocumentToFolder,
    archiveDocument,
    deleteDocument,
    makeDocumentFavorite,
  } = useSingleDocumentActions();
  const [selectedDocItem, setSelectedDocItem] = useState('active');
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showDeleteFolderAlert, setShowDeleteFolderAlert] = useState(false);

  // On filter documents
  const handleFilter = (value, label) => {
    setSelectedDocItem(value);
    onFilter(value, label);
  };

  // On filter folders
  const handleFilterFolders = (f) => {
    if (!f && docsSelectedFilters.folder === 'all') return;
    if (f && f.id === docsSelectedFilters.folder) return;
    setDocsSelectedFilters((prev) => ({ ...prev, folder: f ? f.id : 'all' }));
    setSelectedDocuments([]);
    setDocFoldersSelectedFilter(f ? f.id : 'all');
    updateBreadcrumbs({ folder: f });
  };

  // On create folder btn click
  const handleCreateFolderBtnClick = () => {
    setShowCreateFolderModal(true);
  };

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false);
  };

  // On create folder - from another folder
  const handleCreateFolderFromAnotherFolder = (e, folder = null) => {
    setDocParentFolder(folder);
    setShowCreateFolderModal(true);
  };

  // On open edit folder modal
  const handleOpenEditFolderModal = (folder) => {
    setShowEditFolderModal(true);
    setCurrentFolder(folder);
  };

  // On close edit folder modal
  const handleCloseEditFolderModal = () => {
    setShowEditFolderModal(false);
    setCurrentFolder(null);
  };

  // On open delete folder alert
  const handleOpenDeleteFolderAlert = (folder, reset) => {
    if (folderHasTemplates(documents, folder)) {
      return setNotification({
        msg: t('folder.not_empty_templates', { folder: folder.name }),
        type: 'warning',
      });
    }
    setCurrentFolder(folder);
    setShowDeleteFolderAlert(true);
    if (reset) {
      setResetFolders(true);
    }
  };

  // On close delete folder alert
  const handleCloseDeleteFolderAlert = () => {
    setShowDeleteFolderAlert(false);
  };

  // On folder delete
  const handleFolderDelete = async () => {
    await deleteDocumentFolder({
      currentFolder,
      setShowDeleteFolderAlert,
      setCurrentFolder,
      resetFolders,
      setResetFolders,
    });
    refreshDocs();
  };

  // On move folder to folder
  const handleMoveToFolder = async (selectedFolder) => {
    await moveDocumentsFolder({ selectedFolder, docFolderToMove });
    refreshDocs();
  };

  // On move document to folder
  const handleMoveDocumentToFolder = async (document, folder) => {
    await moveDocumentToFolder({
      document,
      folder,
      setSelectedDocuments: () => {},
    });
    refreshDocs();
  };

  // Drag over - all
  const handleAllDragOver = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if (!folderBox) return;
    if (!folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover');
    }
  };

  // Drag leave - all
  const handleAllDragLeave = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if (!folderBox) return;
    if (folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
  };

  // On drop - all
  const handleDropToAll = async (e) => {
    const folderBox = e.target.closest('.item-inner');
    if (!folderBox) return;
    if (folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }

    const data = e.dataTransfer.getData('folder')
      ? JSON.parse(e.dataTransfer.getData('folder'))
      : null;

    if (data) {
      await moveDocumentsFolder({ docFolderToMove: data });
      refreshDocs();
      return;
    }

    const documentData = e.dataTransfer.getData('document')
      ? JSON.parse(e.dataTransfer.getData('document'))
      : null;

    if (documentData) {
      await moveDocumentToFolder({
        document: documentData,
        folder: null,
        setSelectedDocuments,
      });
      refreshDocs();
    }
  };

  // On archived/deleted drag over
  const handleDocDragOver = (e) => {
    e.preventDefault();
    const item = e.target.closest('.documents__item');
    if (!item.classList.contains('hover')) {
      item.classList.add('hover');
    }
  };

  // On archived/deleted drag leave
  const handleDocDragLeave = (e) => {
    e.preventDefault();
    const item = e.target.closest('.documents__item');
    if (item.classList.contains('hover')) {
      item.classList.remove('hover');
    }
  };

  // On archived/deleted drop
  const handleDocDrop = async (e, mode) => {
    const documentData = e.dataTransfer.getData('document')
      ? JSON.parse(e.dataTransfer.getData('document'))
      : null;
    if (!documentData) return;
    if (mode === currentFilter) return;
    if (mode === 'archived') {
      await archiveDocument({ doc: documentData });
    } else if (mode === 'deleted') {
      await deleteDocument({
        doc: documentData,
        setShowDeleteSingleDocAlert: () => {},
        setSingleDocToDelete: () => {},
      });
    } else if (mode === 'favorites') {
      await makeDocumentFavorite({ doc: documentData });
    }
    refreshDocs();
    const item = e.target.closest('.documents__item');
    if (item.classList.contains('hover')) {
      item.classList.remove('hover');
    }
  };

  return (
    <div className="collection-sidebar">
      <h1 className="heading-1">{t('dashboard.documents')}</h1>
      <section className="documents">
        <div
          className={`documents__item active ${
            selectedDocItem === 'active' ? 'selected' : ''
          } u-truncate-text-wrapper`}
          onClick={() => {
            handleFilterFolders(null);
            handleFilter('active', t('dashboard.mailbox'));
          }}
        >
          <span className="material-symbols-outlined filled">folder</span>
          <span className="text u-truncate-text">{t('dashboard.mailbox')}</span>
          <span className="num">{docNumbers.active}</span>
        </div>
        <div
          className={`documents__item active ${
            selectedDocItem === 'favorites' ? 'selected' : ''
          } u-truncate-text-wrapper`}
          onClick={() => handleFilter('favorites', t('general.favorites'))}
          onDragOver={handleDocDragOver}
          onDragLeave={handleDocDragLeave}
          onDrop={(e) => handleDocDrop(e, 'favorites')}
        >
          <span className="material-symbols-outlined">star_border</span>
          <span className="text u-truncate-text">{t('general.favorites')}</span>
          <span className="num">{docNumbers.favorites}</span>
        </div>
        <div
          className={`documents__item active ${
            selectedDocItem === 'archived' ? 'selected' : ''
          } u-truncate-text-wrapper`}
          onClick={() => handleFilter('archived', t('general.archived'))}
          onDragOver={handleDocDragOver}
          onDragLeave={handleDocDragLeave}
          onDrop={(e) => handleDocDrop(e, 'archived')}
        >
          <span className="material-symbols-outlined">inventory_2</span>
          <span className="text u-truncate-text">{t('general.archived')}</span>
          <span className="num">{docNumbers.archived}</span>
        </div>
        <div
          className={`documents__item active ${
            selectedDocItem === 'deleted' ? 'selected' : ''
          } u-truncate-text-wrapper`}
          onClick={() => handleFilter('deleted', t('general.deleted'))}
          onDragOver={handleDocDragOver}
          onDragLeave={handleDocDragLeave}
          onDrop={(e) => handleDocDrop(e, 'deleted')}
        >
          <span className="material-symbols-outlined">delete</span>
          <span className="text u-truncate-text">{t('general.deleted')}</span>
          <span className="num">{docNumbers.deleted}</span>
        </div>
      </section>

      {currentFilter === 'all' && (
        <section className="folders">
          <h5 className="heading-5">{t('dashboard.folders')}</h5>
          <ul className="filters-list">
            <li
              className={`filters-list__item ${
                docsSelectedFilters.folder === 'all' ? 'active' : ''
              }`}
            >
              <div
                className="item-inner u-truncate-text-wrapper"
                onDrop={handleDropToAll}
                onDragOver={handleAllDragOver}
                onDragLeave={handleAllDragLeave}
              >
                <p
                  onClick={() => handleFilterFolders(null)}
                  className="u-truncate-text"
                >
                  {t('general.all_folders')}
                </p>
                <CustomTooltip content={t('folder.create')}>
                  <div
                    className="icon-right"
                    onClick={handleCreateFolderBtnClick}
                  >
                    <span className="material-symbols-outlined">
                      create_new_folder
                    </span>
                  </div>
                </CustomTooltip>
              </div>
            </li>
            {docFolders.map((folder) => {
              if (!folder.parentFolder) {
                return (
                  <FolderListItem
                    key={folder.id}
                    folder={folder}
                    onCreateFolder={handleCreateFolderFromAnotherFolder}
                    onEdit={handleOpenEditFolderModal}
                    onDelete={handleOpenDeleteFolderAlert}
                    onFilter={handleFilterFolders}
                    selectedFilter={docsSelectedFilters.folder}
                    onMoveToFolder={handleMoveToFolder}
                    folders={docFolders}
                    foldersLoading={docFoldersLoading}
                    collection={documentsArr}
                    collectionLoading={documentsLoaded}
                    // onMoveTemplateToFolder={onMoveTemplateToFolder}
                    view="documents"
                    onMoveDocumentToFolder={handleMoveDocumentToFolder}
                    refreshDocs={refreshDocs}
                  />
                );
              }
              return null;
            })}
          </ul>
        </section>
      )}

      {showCreateFolderModal && (
        <CreateEditFolderModal
          onClose={handleCloseCreateFolderModal}
          view="documents"
          setFolderCreated={setFolderCreated}
          setCurrentFolder={setCurrentFolder}
          refreshCollection={refreshDocs}
        />
      )}
      {showEditFolderModal && (
        <CreateEditFolderModal
          onClose={handleCloseEditFolderModal}
          view="documents"
          mode="edit"
          currentFolder={currentFolder}
          setFolderCreated={setFolderCreated}
          setCurrentFolder={setCurrentFolder}
          refreshCollection={refreshDocs}
        />
      )}
      {showDeleteFolderAlert && (
        <Alert
          onClose={handleCloseDeleteFolderAlert}
          text={t('alert.delete_folder')}
          onSubmit={handleFolderDelete}
          deleteAlert
        />
      )}
    </div>
  );
};

export default DocumentsSidebar;

import { useContext } from "react"

import { MainLayout } from '../layouts'
import { TeamContext, GlobalContext } from "../context"
import { saveSelectedTeamToLS } from "../utils"

const UpgradeTeam = () => {
  const { selectedTeam, setSelectedTeam, teams } = useContext(TeamContext)
  const { t } = useContext(GlobalContext)

  const teamOwnerEmail = () => {
    if(!selectedTeam) {
      return ''
    }
    for(let u of selectedTeam.users) {
      if(u.id === selectedTeam.owner) {
        return u.email
      }
    }
    return ''
  }

  const handleTeamChange = (team) => {
    setSelectedTeam(team)
    saveSelectedTeamToLS(team.id)
  }

  const renderAdditionalTeams = () => {
    const additionalTeams = teams.filter(t => t.id !== selectedTeam.id)
    if(additionalTeams.length === 0) {
      return null
    }
    return (
      <div className="mt-50">
        <h4>{ t('team.other_teams') }</h4>
        { additionalTeams.map(team => (
          <div key={team.id} className="row mt-10">
            <h3>{ team.name }</h3>
            <button className="btn ml-20" onClick={() => handleTeamChange(team)}>{t('upgrade_team.switch_to_team')}</button>
          </div>
        ))}
      </div>
    )
  }


  return (
    <MainLayout>
      <div className="upgrade-team-v2">
        <h2 className="heading-2">{ t('upgrade_team.team_disabled', { name: selectedTeam?.name || '' }) }</h2>
        <h4 className="heading-4 mt-30">{ t('upgrade_team.notify_owner_description', { email: teamOwnerEmail() }) }</h4>
        { renderAdditionalTeams() }
      </div>
    </MainLayout>
  )
}

export default UpgradeTeam
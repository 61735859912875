import React, { useContext, useEffect } from 'react';

import Modal from './Modal';
import Button from './Button';
import { GlobalContext } from '../../context'

const Alert = ({ onClose, text, onSubmit, loading, deleteAlert = false, onOkClose, okBtnText = 'Ok', showOk = false, useEnterBtn = true, useEscBtn = true }) => {
  const { t } = useContext(GlobalContext)

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter' && onSubmit && useEnterBtn) {
        onSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [onSubmit, useEnterBtn])
  
  return(
    <Modal onClose={onClose} medium noPadding useEscBtn={useEscBtn}>
      <div className="popup">
        <div className="popup__head">
          <h3>{text}</h3>
        </div>
        {!showOk ? <div className="popup__foot">
          <Button text={t('general.cancel')} transparent onButtonClick={onClose} medium />
          <Button text={loading ? "Supression..." : "Confirmer"} primary={!deleteAlert} danger={deleteAlert} onButtonClick={onSubmit} disabled={loading} medium />
        </div>
        : <div className="popup__foot">
          <Button text={okBtnText} primaryLight onButtonClick={onOkClose ? onOkClose : onClose} />
        </div>}
      </div>
    </Modal>
  );
}

export default Alert;
import { firebaseEnvDoc } from './firebase'

const COLLECTION = 'contact_groups'

// Fetch contact groups
const fetch_contact_groups = async (teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch contact groups error', err)
  }
}

// Create contact group
const create_contact_group = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).add(data)
    return res.id
  } catch (err) {
    console.log('create contact group error', err)
  }
}

// Delete contact group
const delete_contact_group = async (id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).delete()
  } catch (err) {
    console.log('delete contact group error', err)
  }
}

// Update contact group
const edit_contact_group = async (id, data) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).update(data)
  } catch (err) {
    console.log('update contact group error', err)
  }
}

export {
  fetch_contact_groups,
  create_contact_group,
  delete_contact_group,
  edit_contact_group,
}
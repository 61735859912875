import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ label, name, id, value, onChange, placeholder, error, dashboardModal, editMode, formEl, thickBorder, className, formElOptional, style = {}, onFocus, onBlur, focusField, onSetFocusField = () => {}, disabled = false }) => {
  const fieldRef = useRef()

  // Focus field 
  useEffect(() => {
    if(focusField && fieldRef.current) {
      fieldRef.current.focus()
      fieldRef.current.setSelectionRange(fieldRef.current.value.length, fieldRef.current.value.length)
      onSetFocusField(false)
    }
  }, [focusField, onSetFocusField])

  let cssClasses = 'textarea';

  if(dashboardModal) {
    cssClasses += ' textarea--dashboard-modal';
  }
  if(editMode) {
    cssClasses += ' textarea--edit-mode';
  }
  if(formEl) {
    cssClasses += ' textarea--form-el';
  }
  if(formElOptional) {
    cssClasses += ' textarea--form-el-optional';
  }
  if(thickBorder) {
    cssClasses += ' textarea--thick-border';
  }
  if(className) {
    cssClasses += ` ${className}`;
  }

  return(
    <div className={cssClasses}>
      {label && <label htmlFor={id ? id : name} className="textarea__label">{label}</label>}
      <textarea 
        name={name} 
        id={id ? id : name} 
        className="textarea__field"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={fieldRef}
        disabled={disabled}
      ></textarea>
      {error && <div className="textarea__error">{error}</div>}
    </div>
  );
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  dashboardModal: PropTypes.bool
}

export default Textarea;
import { useContext } from 'react';
import { UserContext, TeamContext } from '../../context'
import { hasSufficientMembership } from '../../utils';

export const LimitedFeatureWrapper = ({ children, origin, type, requirement, altContent }) => {

  const { membership } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)

  const isAvailable = () => {
    return hasSufficientMembership(type === 'team' ? selectedTeam?.membership : membership, requirement)
  }

  if(isAvailable()) {
    return (
      <>
        { children }
      </>
    )
  }
    // return null
  if(altContent) {
    return altContent
  }
  return null 
  // return (
  //   <div onClick={onClick}>
  //     { children }
  //   </div>
  // )
}
import { useContext } from 'react'
import { NavLink, useHistory, Link } from 'react-router-dom'

import CustomTooltip from '../../UI/CustomTooltip'
import { LetterCircle } from '../../new_ui'
import { LimitedFeatureWrapper } from '../../utils/LimitedFeatureWrapper'
import { GlobalContext, TeamContext, UserContext } from '../../../context'
import { useAuth } from '../../../hooks'
import { availableOn, saveSelectedTeamToLS } from '../../../utils'

const SettingsSidebar = () => {
  const { t } = useContext(GlobalContext)
  const { teams, selectedTeam, setSelectedTeam, setActiveTeamMember, activeTeamMember, setFetchCollections } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { logout, resetState } = useAuth()
  const history = useHistory()

  // On team click
  const handleTeamClick = (team) => {
    setSelectedTeam(team)
    saveSelectedTeamToLS(team.id)
    const activeMember = team.users.find(u => u.email === activeTeamMember.email)
    setActiveTeamMember({...activeTeamMember, ...activeMember})
    if(selectedTeam?.id !== team.id) {
      resetState('team-change')
      setFetchCollections(true)
    }
    history.push('/settings/team', { mode: 'edit' })
  }

  // On logout
  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }

  // Go to team page
  const handleGoToAddTeamPage = () => {
    history.push('/settings/add-team')
  }

  return (
    <div className="collection-sidebar">
      <h1 className="heading-1">{t('dashboard.settings')}</h1>
      <section className="documents settings">
        <NavLink className={`documents__item u-truncate-text-wrapper`} to="/settings/my-profile" activeClassName="selected">
          <span className="icon">
            {user.image_url ? (
              <span className="thumb" style={{backgroundImage: `url(${user.image_url})`}}></span>
            ) : (
              <LetterCircle data={user.first_name} />
            )}
          </span>
          <span className="text u-truncate-text">{t('dashboard.my_profile')}</span> 
        </NavLink>
        {/* <NavLink className={`documents__item u-truncate-text-wrapper`} to="/settings/security" activeClassName="selected">
          <span className="icon">
            <span className="material-symbols-outlined">security</span>
          </span>
          <span className="text u-truncate-text">{t('auth.security')}</span> 
        </NavLink> */}
        <NavLink className={`documents__item u-truncate-text-wrapper`} to="/settings/change-password" activeClassName="selected">
          <span className="icon">
            <span className="material-symbols-outlined">key</span>
          </span>
          <span className="text u-truncate-text">{t('auth.password')}</span> 
        </NavLink>
        {availableOn(['development', 'staging']) && <NavLink className={`documents__item u-truncate-text-wrapper`} to="/settings/membership" activeClassName="selected">
          <span className="icon">
            <span className="material-symbols-outlined">credit_card</span>
          </span>
          <span className="text u-truncate-text">{t('membership.title')}</span> 
        </NavLink>}
        {availableOn(['development', 'staging']) && <NavLink className={`documents__item u-truncate-text-wrapper`} to="/settings/invoices" activeClassName="selected">
          <span className="icon">
            <span className="material-symbols-outlined">receipt</span>
          </span>
          <span className="text u-truncate-text">{t('invoices.title')}</span> 
        </NavLink>}
        <NavLink className={`documents__item u-truncate-text-wrapper`} to="/settings/email-upload" activeClassName="selected">
          <span className="icon">
            <span className="material-symbols-outlined">alternate_email</span>
          </span>
          <span className="text u-truncate-text">{t('email_upload.nav_label')}</span> 
        </NavLink>
      </section>

      <section className="documents workspaces">
        <h5 className="heading-5">
          <Link to={'/settings/teams'}>
            {t('settings.workspaces')} 
          </Link>
          <LimitedFeatureWrapper type="account" requirement="premium">
            <CustomTooltip content={t('team.add')}>
              <span className="icon" onClick={handleGoToAddTeamPage}>
                <span className="material-symbols-outlined">add</span>
              </span>
            </CustomTooltip>
          </LimitedFeatureWrapper>
        </h5>
        <ul className="teams">
          {teams.map((team, i) => {
            return <CustomTooltip content={team.id === selectedTeam?.id ? t('team.connected_to') : t('team.connect')} key={i}>
              <li className={`documents__item teams__item ${team.id === selectedTeam?.id ? 'selected' : ''}`} onClick={() => handleTeamClick(team)}>
                {team.logo_url 
                  ? <div className="thumb" style={{backgroundImage: `url(${team.logo_url})`}}></div>
                  : <LetterCircle data={team.name} hideTooltip useOnlyFirstLetter={true} />
                }
                <span className="text u-truncate-text">{team.name}</span>
              </li>
            </CustomTooltip>
          })}
        </ul>
      </section>

      <div className="collection-sidebar__foot">
        <a href="/" onClick={handleLogout}><span className="material-symbols-outlined">logout</span> {t('auth.logout')}</a>
      </div>
    </div>
  )
}

export default SettingsSidebar
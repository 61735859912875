import firebase, { firebaseEnvDoc } from './firebase'
import { divideArrayToMultipleArrays } from '../utils'

const COLLECTION = 'shared_templates'

// Get shared template
const get_single_shared_template = async (id) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where(firebase.firestore.FieldPath.documentId(), '==', id).where('single', '==', true).get()
    let result = null
    if(res.docs.length === 1) {
      result = res.docs[0].data()
    }
    return result
  } catch (err) {
    console.log('get shared template error', err)
  }
}

// Get shared template
const get_shared_template = async (id) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).doc(id).get()
    return res.data()
  } catch (err) {
    console.log('get shared template error', err)
  }
}

// Get shared template 
const shared_template_exists = async (id, owner, team) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION)
      .where('template_id', '==', id).where('team', '==', team).where('single', '==', true).get()
    let sharedId = null
    let sharedTemplate = null
    res.docs.forEach(doc => {
      if(doc.data()?.template_id === id) {
        sharedId = doc.id
        sharedTemplate = { ...doc.data(), id: doc.id }
      }
    })
    return {
      num: res.docs.length,
      id: sharedId,
      template: sharedTemplate
    }
  } catch (err) {
    console.log('get shared template error', err)
  }
}

const get_shared_templates_by_ids_helper = async (ids) => {
  const res = await firebaseEnvDoc.collection(COLLECTION).where(firebase.firestore.FieldPath.documentId(), 'in', ids).get()
  let result = {}
  if(res && res.docs) {
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
  }
  return result
}

// Get shared templates by ids
const get_shared_templates_by_ids = async (ids) => {
  try {
    const combinedArrays = divideArrayToMultipleArrays(ids)
    let promises = []
    let combinedObj = {}
    for(let i = 0; i < combinedArrays.length; i++) {
      let sharedTmplts = combinedArrays[i]
      promises.push(get_shared_templates_by_ids_helper(sharedTmplts))
    }
    // console.log(combinedArrays)
    const result = await Promise.all(promises)
    if(result && Object.keys(result).length > 0) {
      for(let key in result) {
        combinedObj = {...combinedObj, ...result[key]}
      }
    }
    return combinedObj
    // const res = await firebaseEnvDoc.collection(COLLECTION).where(firebase.firestore.FieldPath.documentId(), 'in', ids).get()
    // let result = {}
    // res.docs.forEach(doc => {
    //   result[doc.id] = doc.data()
    // })
    // return result
  } catch (err) {
    console.log('get shared templates by ids error', err)
  }
}

// Get single shared templates - those are template that do not belong to a page
const get_single_shared_templates = async (teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).where('single', '==', true).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('get shared templates by page id error', err)
  }
}

// Create new shared template
const create_shared_template = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).add(data)
    return res.id
  } catch (err) {
    console.log('create shared template error', err)
  }
}

// Delete shared template
const delete_shared_template = async (id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).delete()
  } catch (err) {
    console.log('delete shared template error', err)
  }
}

// Update shared template
const update_shared_template = async (id, data) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).update(data)
  } catch (err) {
    console.log('update shared template error', err)
  }
}

export {
  get_single_shared_template,
  get_shared_template,
  shared_template_exists,
  get_shared_templates_by_ids,
  get_single_shared_templates,
  create_shared_template,
  delete_shared_template,
  update_shared_template,
}
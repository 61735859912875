import { useContext, useState, useEffect, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { SignaturesSidebar, SignaturesView, SignatureStatusSidePanel, BuySignatureCreditsModal } from '../components/misc'
import { GlobalContext, TeamContext, SignatureContext } from '../context'
import { sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'
import { useSingleSignatureActions } from '../hooks'
import { fetch_signature_credits } from '../services/firestore'

const Signatures = () => {
  const { t } = useContext(GlobalContext)
  const { teamChanged, setTeamChanged, selectedTeam } = useContext(TeamContext)
  const { fetchSignatures, signaturesFetched, signatures, signaturesSort, signaturesSelectedFilters, setSignaturesSort } = useContext(SignatureContext)
  const { getSingleSignatureStatus } = useSingleSignatureActions()
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [activeSignatures, setActiveSignatures] = useState([])
  const [currentSignatures, setCurrentSignatures] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedSignatures, setSelectedSignatures] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [paginatedData, setPaginatedData] = useState([])
  const [mainTitle, setMainTitle] = useState(t('general.all'))
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [activeSignature, setActiveSignature] = useState(null)
  const [singleStatusLoading, setSingleStatusLoading] = useState(false)
  const [creditsLeft, setCreditsLeft] = useState(0)
  const [creditsLoading, setCreditsLoading] = useState(false)
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false)

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch signatures if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!signaturesFetched) {
        fetchSignatures(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, signaturesFetched, fetchSignatures])

  // Set signatures
  const setupSignatures = useCallback(async (signature) => {
    let active = [...signatures]
    if(signature) {
      active =  [...signatures].map(s => s.id === signature.id ? {...s, refreshed: true} : s )
    }
    let sorted = await sortArrayOfObjects(active, signaturesSort.value, signaturesSort.order)
    setActiveSignatures([...sorted])
    setCurrentSignatures([...sorted])
    setReady(true)
  }, [signatures, signaturesSort])

  // Setup signatures when all data is fetched - only once, on component mount
  useEffect(() => {
    if(signaturesFetched && !ready) {
      setupSignatures()
    }
  }, [signaturesFetched, setupSignatures, ready])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
    setAllChecked(false)
    setSelectedSignatures([])
  }, [])

  // Refresh credits
  const refreshCredits = useCallback(async () => {
    setCreditsLoading(true)
    let creditsResponse = await fetch_signature_credits(selectedTeam.id)
    if(creditsResponse.error) {
      console.log(creditsResponse.error)
      setCreditsLeft(0)
      return
    }
    setCreditsLeft(creditsResponse.credits)
    setCreditsLoading(false)
  }, [selectedTeam])

  useEffect(() => {
    if(!selectedTeam) {
      return
    }
    refreshCredits()
  }, [selectedTeam, refreshCredits])

  // useEffect(() => {
  //   if(history.location.state && Boolean(history.location.state.openSignaturePreviewObject)) {
  //     previewSignatureHandler(history.location.state.openSignaturePreviewObject)
  //   }
  // }, [history.location])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeSignatures]

    for(let key in signaturesSelectedFilters) {
      if(key === 'search' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.title.toLowerCase().includes(signaturesSelectedFilters[key].trim().toLowerCase()))
      }
      if(key === 'status' && signaturesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(s => s.status === signaturesSelectedFilters[key])
      }
    }

    let sorted = sortArrayOfObjects(filtered, signaturesSort.value, signaturesSort.order)
    setCurrentSignatures([...sorted])
  }, [activeSignatures, signaturesSelectedFilters, signaturesSort])

  // Filter templates when templatesSelectedFilters or templatesSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = signaturesSort.order === 'desc' ? 'asc' : 'desc'
    setSignaturesSort({ value: sort_by, order: currentOrder })
    if(isCookieAccepted()) {
      saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'templates')
    }
  }

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedSignatures([])
    }else {
      setSelectedSignatures(paginatedData)
    }
  }

  // On status click
  const handleStatusClick = useCallback(async (signature) => {
    await getSingleSignatureStatus({ signature, setShowSidePanel, setSingleStatusLoading, setActiveSignature })
  }, [getSingleSignatureStatus])

  // On signature side panel close
  const handleSignatureSidePanelClose = () => {
    setShowSidePanel(false)
  }

  // After signature status side panel close
  const handleAfterStatusSidepanelClose = () => {
    setActiveSignature(null)
  } 

  // On open buy credits modal
  const handleOpenBuyCreditsModal = () => {
    setShowBuyCreditsModal(true)
  }

  // On close buy credits modal
  const handleCloseBuyCreditsModal = () => {
    setShowBuyCreditsModal(false)
  }

  // On buy credits success
  const handleBuyCreditsSuccess = (increment) => {
    setShowBuyCreditsModal(false)
    setCreditsLeft(creditsLeft + increment)
  }

  return (
    <MainLayout
      title={mainTitle}
      page="signatures"
      showSidebarTrigger={true}
      sidebar={
        <SignaturesSidebar 
          creditsLeft={creditsLeft} 
          creditsLoading={creditsLoading} 
          onBuyMoreCredits={handleOpenBuyCreditsModal} 
          setMainTitle={setMainTitle}
        />
      }
    >
      <SignaturesView 
        filteredSignatures={currentSignatures}
        onSort={handleSort}
        selectedSignatures={selectedSignatures}
        setSelectedSignatures={setSelectedSignatures}
        allChecked={allChecked}
        onAllCheckedChange={handleAllChecked}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        refreshSignatures={refreshCollection}
        ready={ready}
        setAllChecked={setAllChecked}
        onStatusClick={handleStatusClick}
      />

      <SignatureStatusSidePanel 
        show={showSidePanel} 
        onClose={handleSignatureSidePanelClose} 
        afterClose={handleAfterStatusSidepanelClose}
        title={t('dashboard.signature_status')}
        loading={singleStatusLoading}
        signature={activeSignature}
      />

      {showBuyCreditsModal && (
        <BuySignatureCreditsModal 
          onClose={handleCloseBuyCreditsModal}
          onSuccess={handleBuyCreditsSuccess}
        />
      )}
    </MainLayout>
  )
}

export default Signatures
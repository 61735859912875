import { GET_SIGNATURES, ADD_SIGNATURE, DELETE_SIGNATURE, RESET_STATE, SET_SIGNATURES, SET_SIGNATURES_OBJ } from '../types';

const signatureReducer = (state, action) => {
  switch(action.type) {
    case GET_SIGNATURES:
      return {
        ...state,
        signatures: action.payload,
        signaturesFetched: true,
        signaturesObj: action.obj,
      }
    case SET_SIGNATURES:
      return {
        ...state,
        signatures: action.payload
      }
    case SET_SIGNATURES_OBJ:
      return {
        ...state,
        signaturesObj: action.payload
      }
    case ADD_SIGNATURE:
      return {
        ...state,
        signatures: action.payload
      }
    case DELETE_SIGNATURE:
      return {
        ...state,
        signatures: action.payload
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default signatureReducer;
const Notepad = () => {
  return (
    <span className="svg-icon">
      <svg width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="v" overflow="visible">
        <path d="m0.8125-0.046875c-0.042969 0.023438-0.089844 0.039062-0.14062 0.046875-0.042969 0.0078125-0.089844 0.015625-0.14062 0.015625-0.14844 0-0.26172-0.039062-0.34375-0.125-0.085938-0.082031-0.125-0.19141-0.125-0.32812 0-0.13281 0.039062-0.24219 0.125-0.32812 0.082031-0.082031 0.19531-0.125 0.34375-0.125 0.050781 0 0.097656 0.007813 0.14062 0.015625 0.050781 0.011719 0.097656 0.027344 0.14062 0.046875v0.1875c-0.042969-0.03125-0.085938-0.050781-0.125-0.0625-0.042969-0.019531-0.089844-0.03125-0.14062-0.03125-0.074219 0-0.13672 0.027344-0.1875 0.078125-0.042969 0.054688-0.0625 0.125-0.0625 0.21875s0.019531 0.16797 0.0625 0.21875c0.050781 0.054688 0.11328 0.078125 0.1875 0.078125 0.050781 0 0.097656-0.003906 0.14062-0.015625 0.039062-0.019531 0.082031-0.046875 0.125-0.078125z"/>
        </symbol>
        <symbol id="c" overflow="visible">
        <path d="m0.59375-0.48438h-0.0625c-0.023438-0.007813-0.039062-0.015625-0.046875-0.015625-0.054687 0-0.09375 0.015625-0.125 0.046875s-0.046875 0.085937-0.046875 0.15625v0.29688h-0.21875v-0.65625h0.21875v0.10938c0.03125-0.039063 0.0625-0.070313 0.09375-0.09375 0.03125-0.019531 0.070312-0.03125 0.125-0.03125h0.015625 0.046875z"/>
        </symbol>
        <symbol id="b" overflow="visible">
        <path d="m0.76562-0.32812v0.0625h-0.5c0.007813 0.042969 0.023437 0.078125 0.046875 0.10938 0.03125 0.023438 0.078125 0.03125 0.14062 0.03125 0.039063 0 0.082031-0.003906 0.125-0.015625 0.050781-0.007813 0.10156-0.03125 0.15625-0.0625v0.15625c-0.054687 0.023438-0.10547 0.039062-0.15625 0.046875-0.054687 0.0078125-0.10547 0.015625-0.15625 0.015625-0.11719 0-0.21094-0.03125-0.28125-0.09375-0.0625-0.0625-0.09375-0.14453-0.09375-0.25 0-0.10156 0.03125-0.1875 0.09375-0.25 0.070313-0.0625 0.16406-0.09375 0.28125-0.09375 0.10156 0 0.1875 0.03125 0.25 0.09375s0.09375 0.14844 0.09375 0.25zm-0.21875-0.078125c0-0.03125-0.015625-0.054688-0.046875-0.078125-0.023438-0.03125-0.046875-0.046875-0.078125-0.046875-0.042969 0-0.078125 0.011719-0.10938 0.03125-0.023438 0.023438-0.039062 0.054688-0.046875 0.09375z"/>
        </symbol>
        <symbol id="a" overflow="visible">
        <path d="m0.39062-0.29688c-0.042969 0-0.074219 0.011719-0.09375 0.03125-0.023437 0.011719-0.03125 0.03125-0.03125 0.0625 0 0.023437 0.003906 0.042969 0.015625 0.0625 0.019531 0.011719 0.046875 0.015625 0.078125 0.015625 0.039063 0 0.070313-0.007812 0.09375-0.03125 0.03125-0.03125 0.046875-0.066406 0.046875-0.10938v-0.03125zm0.32812-0.078125v0.375h-0.21875v-0.09375c-0.023438 0.042969-0.054688 0.074219-0.09375 0.09375-0.03125 0.0078125-0.074219 0.015625-0.125 0.015625-0.074219 0-0.13281-0.019531-0.17188-0.0625-0.042969-0.039062-0.0625-0.09375-0.0625-0.15625 0-0.070313 0.023438-0.125 0.078125-0.15625 0.050781-0.039063 0.13281-0.0625 0.25-0.0625h0.125v-0.015625c0-0.03125-0.015625-0.050781-0.046875-0.0625-0.023437-0.019531-0.058594-0.03125-0.10938-0.03125-0.042969 0-0.085938 0.007812-0.125 0.015625-0.042969 0.011719-0.078125 0.023437-0.10938 0.03125v-0.15625c0.039063-0.007813 0.082031-0.015625 0.125-0.015625 0.050781-0.007812 0.097656-0.015625 0.14062-0.015625 0.125 0 0.21094 0.027344 0.26562 0.078125 0.050781 0.042969 0.078125 0.11719 0.078125 0.21875z"/>
        </symbol>
        <symbol id="d" overflow="visible">
        <path d="m0.32812-0.84375v0.1875h0.21875v0.15625h-0.21875v0.26562c0 0.03125 0.003906 0.054687 0.015625 0.0625 0.007812 0.011719 0.035156 0.015625 0.078125 0.015625h0.10938v0.15625h-0.1875c-0.085938 0-0.14062-0.015625-0.17188-0.046875-0.03125-0.039062-0.046875-0.10156-0.046875-0.1875v-0.26562h-0.10938v-0.15625h0.10938v-0.1875z"/>
        </symbol>
        <symbol id="i" overflow="visible">
        <path d="m0.54688-0.5625v-0.35938h0.21875v0.92188h-0.21875v-0.09375c-0.03125 0.042969-0.0625 0.074219-0.09375 0.09375-0.03125 0.0078125-0.074219 0.015625-0.125 0.015625-0.085937 0-0.15234-0.03125-0.20312-0.09375-0.054688-0.0625-0.078125-0.14453-0.078125-0.25 0-0.10156 0.023438-0.1875 0.078125-0.25 0.050781-0.0625 0.11719-0.09375 0.20312-0.09375 0.050781 0 0.09375 0.011719 0.125 0.03125 0.03125 0.011719 0.0625 0.039063 0.09375 0.078125zm-0.14062 0.42188c0.050781 0 0.085938-0.015625 0.10938-0.046875 0.019531-0.03125 0.03125-0.078125 0.03125-0.14062s-0.011719-0.10938-0.03125-0.14062c-0.023437-0.03125-0.058594-0.046875-0.10938-0.046875-0.042969 0-0.078125 0.015625-0.10938 0.046875-0.023437 0.03125-0.03125 0.078125-0.03125 0.14062s0.007813 0.10938 0.03125 0.14062c0.03125 0.03125 0.066406 0.046875 0.10938 0.046875z"/>
        </symbol>
        <symbol id="h" overflow="visible">
        <path d="m0.45312-0.14062c0.039063 0 0.070313-0.015625 0.09375-0.046875 0.03125-0.03125 0.046875-0.078125 0.046875-0.14062s-0.015625-0.10938-0.046875-0.14062c-0.023437-0.03125-0.054687-0.046875-0.09375-0.046875-0.042969 0-0.078125 0.015625-0.10938 0.046875-0.023438 0.03125-0.03125 0.078125-0.03125 0.14062s0.007812 0.10938 0.03125 0.14062c0.03125 0.03125 0.066406 0.046875 0.10938 0.046875zm-0.14062-0.42188c0.03125-0.039062 0.0625-0.066406 0.09375-0.078125 0.03125-0.019531 0.070312-0.03125 0.125-0.03125 0.082031 0 0.14844 0.03125 0.20312 0.09375 0.050781 0.0625 0.078125 0.14844 0.078125 0.25 0 0.10547-0.027344 0.1875-0.078125 0.25-0.054687 0.0625-0.12109 0.09375-0.20312 0.09375-0.054688 0-0.09375-0.0078125-0.125-0.015625-0.03125-0.019531-0.0625-0.050781-0.09375-0.09375v0.09375h-0.21875v-0.92188h0.21875z"/>
        </symbol>
        <symbol id="l" overflow="visible">
        <path d="m0.015625-0.65625h0.20312l0.1875 0.4375 0.14062-0.4375h0.21875l-0.28125 0.71875c-0.03125 0.070312-0.070313 0.125-0.10938 0.15625-0.03125 0.03125-0.078125 0.046875-0.14062 0.046875h-0.10938v-0.14062h0.0625c0.03125 0 0.050781-0.007812 0.0625-0.015625 0.019531-0.011719 0.035156-0.03125 0.046875-0.0625l0.015625-0.015625z"/>
        </symbol>
        <symbol id="k" overflow="visible">
        <path d="m0.10938-0.875h0.28125l0.20312 0.46875 0.20312-0.46875h0.29688v0.875h-0.21875v-0.64062l-0.20312 0.46875h-0.14062l-0.20312-0.46875v0.64062h-0.21875z"/>
        </symbol>
        <symbol id="j" overflow="visible">
        <path d="m0.09375-0.25v-0.40625h0.21875v0.0625c0 0.042969-0.007812 0.089844-0.015625 0.14062v0.10938 0.10938c0.007813 0.023437 0.019531 0.039063 0.03125 0.046875 0 0.011719 0.003906 0.023438 0.015625 0.03125 0.019531 0.011719 0.039062 0.015625 0.0625 0.015625 0.039062 0 0.070312-0.015625 0.09375-0.046875 0.03125-0.039062 0.046875-0.085938 0.046875-0.14062v-0.32812h0.20312v0.65625h-0.20312v-0.09375c-0.03125 0.042969-0.070313 0.074219-0.10938 0.09375-0.03125 0.0078125-0.070312 0.015625-0.10938 0.015625-0.074219 0-0.13281-0.019531-0.17188-0.0625-0.042969-0.050781-0.0625-0.11719-0.0625-0.20312z"/>
        </symbol>
        <symbol id="g" overflow="visible">
        <path d="m0.76562-0.40625v0.40625h-0.21875v-0.3125-0.10938c0-0.019531-0.007813-0.035156-0.015625-0.046875-0.011719-0.019531-0.023438-0.03125-0.03125-0.03125-0.011719-0.007812-0.027344-0.015625-0.046875-0.015625-0.042969 0-0.078125 0.023437-0.10938 0.0625-0.023438 0.03125-0.03125 0.074219-0.03125 0.125v0.32812h-0.21875v-0.92188h0.21875v0.35938c0.03125-0.039062 0.0625-0.066406 0.09375-0.078125 0.039062-0.019531 0.082031-0.03125 0.125-0.03125 0.070312 0 0.12891 0.027344 0.17188 0.078125 0.039063 0.042969 0.0625 0.10547 0.0625 0.1875z"/>
        </symbol>
        <symbol id="f" overflow="visible">
        <path d="m0.70312-0.54688c0.03125-0.039063 0.0625-0.070313 0.09375-0.09375 0.039063-0.019531 0.082031-0.03125 0.125-0.03125 0.082031 0 0.14062 0.027344 0.17188 0.078125 0.039062 0.042969 0.0625 0.10547 0.0625 0.1875v0.40625h-0.20312v-0.34375-0.03125c0-0.050781-0.011719-0.085938-0.03125-0.10938-0.011719-0.019531-0.03125-0.03125-0.0625-0.03125-0.042969 0-0.074219 0.015625-0.09375 0.046875-0.023437 0.03125-0.03125 0.078125-0.03125 0.14062v0.32812h-0.21875v-0.34375c0-0.070312-0.007813-0.11719-0.015625-0.14062-0.011719-0.019531-0.03125-0.03125-0.0625-0.03125-0.042969 0-0.074219 0.015625-0.09375 0.046875-0.023438 0.03125-0.03125 0.078125-0.03125 0.14062v0.32812h-0.21875v-0.65625h0.21875v0.09375c0.019531-0.039062 0.046875-0.066406 0.078125-0.078125 0.039063-0.019531 0.082031-0.03125 0.125-0.03125 0.039063 0 0.078125 0.011719 0.10938 0.03125 0.039062 0.023437 0.066406 0.054687 0.078125 0.09375z"/>
        </symbol>
        <symbol id="u" overflow="visible">
        <path d="m0.71875-0.84375v0.1875c-0.042969-0.03125-0.089844-0.050781-0.14062-0.0625-0.042969-0.007812-0.085937-0.015625-0.125-0.015625-0.054687 0-0.09375 0.011719-0.125 0.03125-0.023437 0.011719-0.03125 0.03125-0.03125 0.0625 0 0.023437 0.003906 0.042969 0.015625 0.0625 0.019531 0.011719 0.050781 0.023437 0.09375 0.03125l0.10938 0.015625c0.09375 0.023438 0.16016 0.054688 0.20312 0.09375 0.039062 0.042969 0.0625 0.10156 0.0625 0.17188 0 0.09375-0.03125 0.16797-0.09375 0.21875-0.054688 0.042969-0.14062 0.0625-0.26562 0.0625-0.054687 0-0.10938-0.0078125-0.17188-0.015625-0.054688-0.0078125-0.10547-0.023438-0.15625-0.046875v-0.1875c0.050781 0.03125 0.10156 0.058594 0.15625 0.078125 0.050781 0.011719 0.10156 0.015625 0.15625 0.015625 0.050781 0 0.085938-0.007813 0.10938-0.03125 0.03125-0.019531 0.046875-0.039063 0.046875-0.0625 0-0.03125-0.011719-0.050781-0.03125-0.0625-0.023438-0.019531-0.058594-0.035156-0.10938-0.046875l-0.078125-0.015625c-0.09375-0.019531-0.16406-0.050781-0.20312-0.09375-0.03125-0.039063-0.046875-0.097656-0.046875-0.17188 0-0.082031 0.023438-0.14453 0.078125-0.1875 0.050781-0.050781 0.13281-0.078125 0.25-0.078125 0.039063 0 0.085937 0.007813 0.14062 0.015625 0.050781 0 0.10156 0.011719 0.15625 0.03125z"/>
        </symbol>
        <symbol id="t" overflow="visible">
        <path d="m0.09375-0.65625h0.21875v0.65625h-0.21875zm0-0.26562h0.21875v0.17188h-0.21875z"/>
        </symbol>
        <symbol id="s" overflow="visible">
        <path d="m0.0625-0.65625h0.57812v0.14062l-0.34375 0.35938h0.34375v0.15625h-0.59375v-0.14062l0.35938-0.35938h-0.34375z"/>
        </symbol>
        <symbol id="r" overflow="visible">
        <path d="m0.53125-0.92188v0.14062h-0.10938c-0.03125 0-0.054687 0.007812-0.0625 0.015625-0.011719 0.011719-0.015625 0.03125-0.015625 0.0625v0.046875h0.17188v0.15625h-0.17188v0.5h-0.21875v-0.5h-0.10938v-0.15625h0.10938v-0.046875c0-0.070313 0.019531-0.125 0.0625-0.15625 0.039062-0.039063 0.10156-0.0625 0.1875-0.0625z"/>
        </symbol>
        <symbol id="e" overflow="visible">
        <path d="m0.42188-0.51562c-0.054687 0-0.09375 0.015625-0.125 0.046875-0.023437 0.03125-0.03125 0.078125-0.03125 0.14062s0.007813 0.10938 0.03125 0.14062c0.03125 0.03125 0.070313 0.046875 0.125 0.046875 0.039063 0 0.070313-0.015625 0.09375-0.046875 0.03125-0.03125 0.046875-0.078125 0.046875-0.14062s-0.015625-0.10938-0.046875-0.14062c-0.023437-0.03125-0.054687-0.046875-0.09375-0.046875zm0-0.15625c0.10156 0 0.1875 0.03125 0.25 0.09375 0.070313 0.0625 0.10938 0.14844 0.10938 0.25 0 0.10547-0.039062 0.1875-0.10938 0.25-0.0625 0.0625-0.14844 0.09375-0.25 0.09375-0.11719 0-0.21094-0.03125-0.28125-0.09375-0.0625-0.0625-0.09375-0.14453-0.09375-0.25 0-0.10156 0.03125-0.1875 0.09375-0.25 0.070313-0.0625 0.16406-0.09375 0.28125-0.09375z"/>
        </symbol>
        <symbol id="q" overflow="visible">
        <path d="m0.10938-0.875h0.25l0.32812 0.59375v-0.59375h0.20312v0.875h-0.25l-0.3125-0.60938v0.60938h-0.21875z"/>
        </symbol>
        <symbol id="p" overflow="visible">
        <path d="m0.76562-0.40625v0.40625h-0.21875v-0.3125-0.10938c0-0.019531-0.007813-0.035156-0.015625-0.046875-0.011719-0.019531-0.023438-0.03125-0.03125-0.03125-0.011719-0.007812-0.027344-0.015625-0.046875-0.015625-0.042969 0-0.078125 0.023437-0.10938 0.0625-0.023438 0.03125-0.03125 0.074219-0.03125 0.125v0.32812h-0.21875v-0.65625h0.21875v0.09375c0.03125-0.039062 0.0625-0.066406 0.09375-0.078125 0.039062-0.019531 0.082031-0.03125 0.125-0.03125 0.070312 0 0.12891 0.027344 0.17188 0.078125 0.039063 0.042969 0.0625 0.10547 0.0625 0.1875z"/>
        </symbol>
        <symbol id="o" overflow="visible">
        <path d="m0.10938-0.875h0.375c0.11328 0 0.19531 0.027344 0.25 0.078125 0.0625 0.042969 0.09375 0.10938 0.09375 0.20312s-0.03125 0.16797-0.09375 0.21875c-0.054687 0.054688-0.13672 0.078125-0.25 0.078125h-0.14062v0.29688h-0.23438zm0.23438 0.15625v0.25h0.125c0.039062 0 0.070312-0.007812 0.09375-0.03125 0.019531-0.019531 0.03125-0.050781 0.03125-0.09375 0-0.039062-0.011719-0.070312-0.03125-0.09375-0.023438-0.019531-0.054688-0.03125-0.09375-0.03125z"/>
        </symbol>
        <symbol id="n" overflow="visible">
        <path d="m0.09375-0.65625h0.21875v0.64062c0 0.09375-0.023438 0.16016-0.0625 0.20312-0.042969 0.050781-0.10547 0.078125-0.1875 0.078125h-0.10938v-0.14062h0.046875c0.039062 0 0.066406-0.011719 0.078125-0.03125 0.0078125-0.023438 0.015625-0.058594 0.015625-0.10938zm0-0.26562h0.21875v0.17188h-0.21875z"/>
        </symbol>
        <symbol id="m" overflow="visible">
        <path d="m0.625-0.64062v0.17188c-0.023438-0.019531-0.046875-0.03125-0.078125-0.03125-0.03125-0.007812-0.0625-0.015625-0.09375-0.015625-0.054687 0-0.10156 0.015625-0.14062 0.046875-0.03125 0.03125-0.046875 0.078125-0.046875 0.14062s0.015625 0.10938 0.046875 0.14062c0.039062 0.03125 0.085938 0.046875 0.14062 0.046875h0.09375c0.03125-0.007813 0.054687-0.023437 0.078125-0.046875v0.17188c-0.03125 0.011719-0.0625 0.015625-0.09375 0.015625-0.03125 0.0078125-0.0625 0.015625-0.09375 0.015625-0.125 0-0.22656-0.03125-0.29688-0.09375-0.0625-0.0625-0.09375-0.14453-0.09375-0.25 0-0.10156 0.03125-0.1875 0.09375-0.25 0.070313-0.0625 0.17188-0.09375 0.29688-0.09375 0.03125 0 0.0625 0.007813 0.09375 0.015625 0.03125 0 0.0625 0.007812 0.09375 0.015625z"/>
        </symbol>
      </defs>
      <g>
        <path d="m358.28 153.14c-11.523 0-20.883-14.16-20.883-32.164 0-17.762 8.8828-31.445 20.883-31.445s21.363 13.684 21.363 31.445c0 3.3594-2.6406 6-6 6s-6-2.6406-6-6c0-11.281-5.0391-19.922-9.6016-19.922-3.6016 0-9.3633 7.6797-9.3633 19.922 0 12.48 5.2812 20.402 9.3633 20.402 3.3594 0 6 2.6406 6 6s-2.3984 5.7617-5.7617 5.7617z" fillRule="evenodd"/>
        <path d="m309.31 153.14c-12 0-21.121-14.16-21.121-32.164 0-17.523 9.1211-31.445 21.121-31.445s21.363 13.684 21.363 31.445c0 3.3594-2.6406 6-6 6s-6-2.6406-6-6c0-11.281-5.0391-19.922-9.6016-19.922-4.082 0-9.6016 7.6797-9.6016 19.922 0 12.48 5.5195 20.402 9.6016 20.402 3.3594 0 6 2.6406 6 6s-2.3984 5.7617-5.7617 5.7617z" fillRule="evenodd"/>
        <path d="m260.83 153.14c-12 0-21.363-14.16-21.363-32.164 0-17.523 9.3633-31.445 21.363-31.445s21.363 13.922 21.363 31.445c0 3.3594-2.6406 6-6 6s-6-2.6406-6-6c0-12.242-5.7617-19.922-9.6016-19.922-4.082 0-9.8398 7.6797-9.8398 19.922 0 12.48 5.7617 20.402 9.8398 20.402 3.1211 0 5.7617 2.6406 5.7617 6s-2.6406 5.7617-5.5195 5.7617z" fillRule="evenodd"/>
        <path d="m211.86 153.14c-12 0-21.363-14.16-21.363-32.164 0-17.523 9.3633-31.445 21.363-31.445s21.363 13.922 21.363 31.445c0 3.3594-2.6406 6-6 6s-6-2.6406-6-6c0-12.242-5.7617-19.922-9.6016-19.922-4.082 0-9.3633 7.6797-9.3633 19.922 0 12.48 5.5195 20.402 9.6016 20.402 3.3594 0 6 2.6406 6 6s-2.8789 5.7617-6 5.7617z" fillRule="evenodd"/>
        <path d="m402.69 410.94h-185.55c-1.6797 0-2.8789-0.48047-3.8398-1.6797-1.1992-0.96094-1.6797-2.6406-1.6797-3.8398l0.23828-49.926c0-5.7617-5.0391-10.32-10.562-10.32h-49.926c-3.3594 0-6-2.6406-6-6v-201.87c0-12.242 9.8398-22.324 22.324-22.324h234.51c12.48 0 22.324 10.082 22.324 22.324v251.07c0.48047 12.723-9.6016 22.562-21.844 22.562zm-179.55-11.523h179.55c6 0 10.562-4.8008 10.562-10.801v-251.32c0-6-4.5625-10.562-10.562-10.562h-234.99c-6 0-10.801 4.5625-10.801 10.562v195.87h43.926c12.48 0 22.324 9.8398 22.324 21.844v44.406z" fillRule="evenodd"/>
        <path d="m217.14 410.94c-1.1992 0-2.8789-0.48047-3.8398-1.6797l-66.25-66.25c-2.3984-2.1602-2.3984-5.7617 0-8.1602 2.1602-2.3984 6-2.3984 8.1602 0l66.25 66.25c2.3984 2.1602 2.3984 6 0 8.1602-1.1992 1.1992-2.6406 1.6797-4.3203 1.6797z" fillRule="evenodd"/>
        <path d="m383 181.23h-195.39c-3.3594 0-6-2.6406-6-6 0-3.1211 2.6406-5.7617 6-5.7617h195.39c3.3594 0 6 2.6406 6 5.7617 0 3.3594-3.1211 6-6 6z" fillRule="evenodd"/>
        <path d="m383 216.27h-195.39c-3.3594 0-6-2.6406-6-6s2.6406-6 6-6h195.39c3.3594 0 6 2.6406 6 6s-3.1211 6-6 6z" fillRule="evenodd"/>
        <path d="m383 251.55h-195.39c-3.3594 0-6-2.6406-6-6 0-3.3594 2.6406-6 6-6h195.39c3.3594 0 6 2.6406 6 6 0 3.3594-3.1211 6-6 6z" fillRule="evenodd"/>
        <path d="m383 286.6h-195.39c-3.3594 0-6-2.6406-6-5.7617 0-2.8789 2.6406-6 6-6h195.39c3.3594 0 6 2.6406 6 6s-3.1211 5.7617-6 5.7617z" fillRule="evenodd"/>
        <path d="m383 321.89h-155.78c-3.3594 0-6-2.6406-6-6s2.6406-6 6-6h155.78c3.3594 0 6 2.6406 6 6s-3.1211 6-6 6z" fillRule="evenodd"/>
        <path d="m383 356.69h-134.9c-3.3594 0-6-2.6406-6-6 0-3.1211 2.6406-6 6-6h134.9c3.3594 0 6 2.8789 6 6 0 3.3594-3.1211 6-6 6z" fillRule="evenodd"/>
        <path d="m378.68 470.71c-0.71875 0-0.96094 0-1.6797-0.48047-1.4414-0.23828-2.6406-1.1992-3.3594-2.6406l-24.004-43.688c-1.1992-2.1602-3.6016-4.3203-6.2422-4.8008-2.6406-0.71875-5.2812-0.48047-7.9219 0.96094l-43.688 24.242c-2.6406 1.6797-6.2422 0.71875-7.9219-2.3984l-18.961-33.844c-1.6797-2.6406-0.71875-6.2422 2.1602-7.9219 2.6406-1.6797 6.4805-0.71875 8.1602 2.1602l15.844 29.043 38.645-21.363c5.0391-2.8789 11.043-3.6016 17.043-1.9219 5.5195 1.6797 10.32 5.2812 13.203 10.32l21.363 38.645 156.74-87.371c5.0391-2.8789 6.9609-9.6016 4.3203-14.641l-122.9-219.39c-1.1992-2.8789-4.3203-4.5625-7.4414-5.2812-3.3594-0.23828-5.5195-3.3594-5.0391-6.4805 0.23828-3.3594 3.3594-5.5195 6.4805-5.0391 7.1992 0.96094 13.203 5.2812 16.082 11.281l122.42 219.39c6 10.562 2.1602 24.242-8.6406 30.246l-162.02 90.254c-0.71875 0.71875-1.6797 0.71875-2.6406 0.71875z" fillRule="evenodd"/>
        <path d="m378.68 470.71c-0.71875 0-0.96094 0-1.6797-0.48047l-90.012-25.684c-2.8789-0.96094-4.8008-3.8398-3.8398-7.1992 0.96094-3.1211 3.8398-5.0391 7.1992-4.082l90.012 25.684c2.8789 0.96094 5.0391 4.082 3.8398 7.4414-0.96094 2.6406-3.1211 4.3203-5.5195 4.3203z" fillRule="evenodd"/>
        <path d="m419.01 224.91c-2.1602 0-4.082-0.96094-5.0391-2.8789-1.6797-3.1211-0.48047-6.4805 2.3984-8.1602l9.1211-4.8008c3.1211-1.6797 6.4805-0.48047 8.1602 2.1602 1.6797 3.1211 0.23828 6.4805-2.3984 8.1602l-9.3633 4.8008c-0.96094 0.71875-1.9219 0.71875-2.8789 0.71875z" fillRule="evenodd"/>
        <path d="m419.01 265.24c-2.1602 0-4.082-0.96094-5.0391-3.1211-1.6797-2.6406-0.71875-6.2422 2.3984-7.9219l26.164-14.641c2.6406-1.6797 6.2422-0.71875 7.9219 2.3984 1.6797 2.6406 0.71875 6.2422-2.1602 7.9219l-26.402 14.641c-0.96094 0.71875-1.9219 0.71875-2.8789 0.71875z" fillRule="evenodd"/>
        <path d="m419.01 305.56c-2.1602 0-4.082-0.96094-5.0391-3.1211-1.6797-2.6406-0.71875-6.2422 2.3984-7.9219l43.445-24.242c2.6406-1.6797 6.4805-0.71875 8.1602 2.1602 1.4414 2.8789 0.48047 6.4805-2.3984 8.1602l-43.688 24.242c-0.96094 0.71875-1.9219 0.71875-2.8789 0.71875z" fillRule="evenodd"/>
        <path d="m419.01 345.89c-2.1602 0-4.082-1.1992-5.0391-3.1211-1.6797-2.6406-0.71875-6.2422 2.3984-7.9219l60.488-34.086c2.6406-1.6797 6.2422-0.48047 7.9219 2.3984 1.6797 2.6406 0.71875 6.2422-2.1602 7.9219l-60.73 34.086c-0.96094 0.71875-1.9219 0.71875-2.8789 0.71875z" fillRule="evenodd"/>
        <path d="m419.01 385.98c-2.1602 0-4.082-0.96094-5.0391-2.8789-1.6797-2.6406-0.71875-6.2422 2.3984-7.9219l77.531-43.445c2.6406-1.6797 6.2422-0.48047 7.9219 2.3984 1.6797 2.6406 0.71875 6.2422-2.3984 7.9219l-77.531 43.445c-0.96094 0.23828-1.9219 0.48047-2.8789 0.48047z" fillRule="evenodd"/>
        <use x="70" y="563.601562" xlinkHref="#v"/>
        <use x="70.878906" y="563.601562" xlinkHref="#c"/>
        <use x="71.472656" y="563.601562" xlinkHref="#b"/>
        <use x="72.285156" y="563.601562" xlinkHref="#a"/>
        <use x="73.097656" y="563.601562" xlinkHref="#d"/>
        <use x="73.671875" y="563.601562" xlinkHref="#b"/>
        <use x="74.484375" y="563.601562" xlinkHref="#i"/>
        <use x="75.761719" y="563.601562" xlinkHref="#h"/>
        <use x="76.621094" y="563.601562" xlinkHref="#l"/>
        <use x="77.820312" y="563.601562" xlinkHref="#k"/>
        <use x="79.015625" y="563.601562" xlinkHref="#j"/>
        <use x="79.871094" y="563.601562" xlinkHref="#g"/>
        <use x="80.722656" y="563.601562" xlinkHref="#a"/>
        <use x="81.535156" y="563.601562" xlinkHref="#f"/>
        <use x="82.785156" y="563.601562" xlinkHref="#f"/>
        <use x="84.035156" y="563.601562" xlinkHref="#a"/>
        <use x="84.84375" y="563.601562" xlinkHref="#i"/>
        <use x="86.121094" y="563.601562" xlinkHref="#u"/>
        <use x="86.984375" y="563.601562" xlinkHref="#g"/>
        <use x="87.839844" y="563.601562" xlinkHref="#a"/>
        <use x="88.648438" y="563.601562" xlinkHref="#h"/>
        <use x="89.507812" y="563.601562" xlinkHref="#c"/>
        <use x="90.101562" y="563.601562" xlinkHref="#a"/>
        <use x="90.910156" y="563.601562" xlinkHref="#t"/>
        <use x="91.324219" y="563.601562" xlinkHref="#s"/>
        <use x="70" y="564.800781" xlinkHref="#r"/>
        <use x="70.523438" y="564.800781" xlinkHref="#c"/>
        <use x="71.113281" y="564.800781" xlinkHref="#e"/>
        <use x="71.9375" y="564.800781" xlinkHref="#f"/>
        <use x="73.605469" y="564.800781" xlinkHref="#d"/>
        <use x="74.179688" y="564.800781" xlinkHref="#g"/>
        <use x="75.035156" y="564.800781" xlinkHref="#b"/>
        <use x="76.265625" y="564.800781" xlinkHref="#q"/>
        <use x="77.269531" y="564.800781" xlinkHref="#e"/>
        <use x="78.097656" y="564.800781" xlinkHref="#j"/>
        <use x="78.949219" y="564.800781" xlinkHref="#p"/>
        <use x="80.222656" y="564.800781" xlinkHref="#o"/>
        <use x="81.101562" y="564.800781" xlinkHref="#c"/>
        <use x="81.695312" y="564.800781" xlinkHref="#e"/>
        <use x="82.519531" y="564.800781" xlinkHref="#n"/>
        <use x="82.929688" y="564.800781" xlinkHref="#b"/>
        <use x="83.742188" y="564.800781" xlinkHref="#m"/>
        <use x="84.457031" y="564.800781" xlinkHref="#d"/>
      </g>
      </svg>
    </span>
  )
}

export default Notepad
import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

const NotificationState = ({ children }) => {
  const [notification, setNotification] = useState({ msg: '', type: 'default' });

  return <NotificationContext.Provider value={{
    notification,
    setNotification
  }}>
    {children}
  </NotificationContext.Provider>
}

export default NotificationState;
import React, { Fragment, useState, useContext } from 'react'
import { useHistory } from 'react-router'
import { InfoOutlined, ChatOutlined, ThumbUpAltOutlined, StarOutlineOutlined, BugReportOutlined } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import ReportABugModal from './ReportABugModal'
import GiveFeedbackModal from './GiveFeedbackModal'
import VoteUpcomingFeaturesModal from './VoteUpcomingFeaturesModal'
import { GlobalContext } from '../../../context'

const SupportMenu = ({ v2 = false }) => {
  const { t } = useContext(GlobalContext)
  const [showBugReportModal, setShowBugReportModal] = useState(false)
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false)
  const [showVoteModal, setShowVoteModal] = useState(false)
  const history = useHistory()

  // Support menu item click
  const supportMenuItemClickHandler = (e, route) => {
    e.preventDefault()
    history.push(`/${route}`)
    document.body.click()
  }

  return (
    <Fragment>
      <ActionsDropdown
        // trigger={<button className={`icon-button support-menu-btn ${!v2 ? 'icon-button--light-blue' : ''}`}>{v2 ? <ChatOutlined /> : <InfoOutlined />}</button>}
        trigger={
          <div>
            <CustomTooltip content={t('dashboard.support_info')} position="left">
              <div className="dashboard-btn-wrapper">
                <button className={`icon-button support-menu-btn ${!v2 ? 'icon-button--light-blue' : ''}`}>{v2 ? <ChatOutlined /> : <InfoOutlined />}</button>
              </div>
            </CustomTooltip>
          </div>
        }
        headTitle={t('support.information')}
      >
        <ul>
          <li>
            <div className="link">
              <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'what-is-new')}><i className="custom-icon-news"></i> {t('support.whats_new')}</a>
            </div>
          </li>
          <li>
            <div className="link">
              <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'help')}><i className="custom-icon-support"></i> {t('support.help_center')}</a>
            </div>
          </li>
          {/* <li>
            <div className="link">
              <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'documentation')}><i className="custom-icon-file-download"></i> {t('support.documentation_docs')}</a>
            </div>
          </li> */}
          <li>
            <div className="link">
              <a href="/#" onClick={(e) => { e.preventDefault(); setShowBugReportModal(true) }}><BugReportOutlined /> {t('support.report_bug')}</a>
            </div>
          </li>
          {/* <li>
            <div className="link">
              <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'request-demo')}>{t('support.request_demo')}</a>
            </div>
          </li> */}
          <li>
            <div className="link">
              <a href="/#" onClick={(e) => {e.preventDefault(); setShowGiveFeedbackModal(true)}}><StarOutlineOutlined /> {t('support.give_feedback')}</a>
            </div>
          </li>
          <li>
            <div className="link">
              <a href="/#" onClick={(e) => {e.preventDefault(); setShowVoteModal(true)}}><ThumbUpAltOutlined /> {t('support.vote_features')}</a>
            </div>
          </li>
        </ul>
      </ActionsDropdown>

      {showBugReportModal && <ReportABugModal onClose={() => setShowBugReportModal(false)} />}
      {showGiveFeedbackModal && <GiveFeedbackModal onClose={() => setShowGiveFeedbackModal(false)} />}
      {showVoteModal && <VoteUpcomingFeaturesModal onClose={() => setShowVoteModal(false)} />}
    </Fragment>
  )
}

export default SupportMenu
import { useContext, useState, useEffect } from 'react'

import { SettingsLayout } from '../layouts'
import { Input } from '../components/new_ui'
import { GlobalContext, UserContext, TeamContext, NotificationContext } from '../context'
import { useUserActions } from '../hooks'

const EmailUpload = () => {
  const { t } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)
  const { setNotification } = useContext(NotificationContext)
  const { checkUserEmailAddress } = useUserActions()
  const [uploadAddress, setUploadAddress] = useState('')

  // Check for user email address on mount
  useEffect(() => {
    checkUserEmailAddress({ setUploadAddress })
    // eslint-disable-next-line
  }, [])

  // On copy to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(user.upload_addresses_map && user.upload_addresses_map[selectedTeam.id] ?  user.upload_addresses_map[selectedTeam.id] : uploadAddress)
    setNotification({ msg: t('general.email_copied'), type: 'info' })
  }

  // Render content
  const renderContent = () => {
    return (
      <div className="profile-form">
        <div className="profile-form__group">
          <h2 className="heading-2">{ t('email_upload.description_header') }</h2>
          <p className="text-style-1">{ t('email_upload.description') }</p>
          <Input
            id="upload_address_input"
            name="upload_address"
            label={t('email_upload.address_label', { name: selectedTeam.name })}
            value={user.upload_addresses_map && user.upload_addresses_map[selectedTeam?.id] ? user.upload_addresses_map[selectedTeam?.id] : uploadAddress}
            type="text"
            disabled={true}
            onIconClick={handleCopyToClipboard}
            iconLocation="end"
            iconEl={<span className="material-symbols-outlined">assignment_returned</span>}
          />
          <p className="text-style-1">
            <strong>{ t('email_upload.warning') }</strong>{ ` ${t('email_upload.warning_description')}`}
          </p>
        </div>
     </div>
    )
  }

  return (
    <SettingsLayout title={t('email_upload.nav_label')}>
      {user && Object.keys(user).length > 0 ? renderContent() : <div className="loader-v2"></div>}
    </SettingsLayout> 
  )
}

export default EmailUpload 
import firebase from './firebase'
import { post_request } from './firestore'
import { urlSuffixForEnvironment } from '../utils'
import config from '../config.json'

// const baseUrl = `http://localhost:5001/carbon-contract-management/europe-west1/api${urlSuffixForEnvironment(config.environment, config.staging)}`
const baseUrl = `https://europe-west1-carbon-contract-management.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment, config.staging)}`


const sign_up = async (email, password, userData, invitationCode, selectedLang) => {
  return await post_request(`${baseUrl}/sign_up`, {
    email,
    password,
    userData,
    invitationCode
  }, '', selectedLang)
}

const free_sign_up = async (email, password, userData, selectedLang) => {
  return await post_request(`${baseUrl}/free_sign_up`, {
    email,
    password,
    userData
  }, '', selectedLang)
}

const currentUser = () => {
  if(!firebase.auth().currentUser) {
    return null
  } else {
    return firebase.auth().currentUser
  }
}

const invitationCodeRequired = () => {
  return true
}

export {
  currentUser,
  sign_up,
  free_sign_up,
  invitationCodeRequired
}
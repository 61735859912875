import { useState, useEffect, useContext, memo } from 'react'
import moment from 'moment'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import { CreateEditVariableModal } from '../'
import { Alert } from '../../new_ui'
import { GlobalContext, VariablesContext } from '../../../context'
import { useSingleVariableActions } from '../../../hooks'

const VariablesRow = ({ doc, id, selectedVariables, setSelectedVariables, allChecked, columns, refreshVariables }) => {
  const { t } = useContext(GlobalContext)
  const { variables } = useContext(VariablesContext)
  const { deleteSingleVariable } = useSingleVariableActions()
  const [checked, setChecked] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showEditVariableModal, setShowEditVariableModal] = useState(false)

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedVariables.length === 0) {
      setChecked(false)
    }
  }, [selectedVariables, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked, doc])

  // On select
  const handleSelect = () => {
    setChecked(!checked)
    if(checked) {
      setSelectedVariables(prev => prev.filter(d => d.id !== id))
    }else {
      setSelectedVariables(prev => [...prev, doc])
    }
  }

  // Print date
  const printDate = (date) => {
    if(!date) return ''
    return moment(date).format('DD MMM YY')
  }

  // Print time
  const printTime = (date) => {
    if(!date) return ''
    return moment(date).format('HH:mm')
  }

  // On edit click
  const handleEditClick = (e) => {
    e.preventDefault()
    setShowEditVariableModal(true)
  }

  // On edit modal close
  const handleCloseEditVariableModal = () => {
    setShowEditVariableModal(false)
  }

  // On delete click
  const handleDeleteClick = async (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteSingleVariable({ data: doc, onClose: () => {} })
    refreshVariables()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // Render checkbox column
  const renderCheckboxColumn = () => {
    return (
      <div className={`list-view-table__cell checkbox`}>
        {!checked 
          ? <span className="material-symbols-outlined checkbox-icon" onClick={handleSelect}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled checkbox-icon" onClick={handleSelect}>check_box</span>
        }
        <span className="material-symbols-outlined row-icon">description</span>
      </div>
    )
  }

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined">more_horiz</span></button>}
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/" onClick={handleEditClick}>
              <span className="icon"><span className="material-symbols-outlined">edit</span></span>
              {t('general.edit')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleDeleteClick} className="delete">
              <span className="icon"><span className="material-symbols-outlined">delete</span></span>
              {t('general.remove')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render category column
  const renderVariableColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div 
        className={`list-view-table__cell u-truncate-text-wrapper title-col`} 
        key={i}
      >
        <p className={`text-style-1 u-truncate-text`}>{content}</p>
        <div className="show-on-hover">
          {renderSingleDocActions()}
        </div>
      </div>
    )
  }

  // Render date column
  const renderDateColumn = (col, i) => {
    const date = col.value === 'meta.created' ? printDate(doc.meta.created) : printDate(doc.meta.updated)
    const time = col.value === 'meta.created' ? printTime(doc.meta.created) : printTime(doc.meta.updated)
    const content = (
      <div className="u-truncate-text">
        <CustomTooltip content={<span>{time}</span>}>
          <span className="normal">{ date }</span>
        </CustomTooltip>
      </div>
    )

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper date-col" key={i}>
        { content }
      </div>  
    )
  }

  // Render custom column
  const renderCustomColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text">{content}</div>
      </div>
    )
  }

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell helper" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'variable'){ 
      content = renderVariableColumn(col, i)
    }else if(col.value === 'meta.created') {
      content = renderDateColumn(col, i)
    }else if(col.value === 'meta.updated') {
      content = renderDateColumn(col, i)
    }else if(col.value === 'helper') {
      content = renderHelperColumn(col, i)
    }else {
      content = renderCustomColumn(col, i)
    }

    return content
  }

  return (
    <>
      <div 
        className={`list-view-table__row ${checked ? 'selected' : ''}`}
      >
        {columns.filter(c => c.checked).map((col, i) => {
          return renderColumnContent(col, i)
        })} 
        {renderCheckboxColumn()}   
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_variable')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}

      {showEditVariableModal && (
        <CreateEditVariableModal 
          onClose={handleCloseEditVariableModal}
          mode="edit"
          data={doc}
          refreshVariables={refreshVariables}
          lastSelectedCategory=""
          onCategorySelected={() => {}}
        />
      )}
    </>
  )
}

export default memo(VariablesRow)
import { useContext } from 'react'

import { Notepad } from '../../assets/icon_components'
import { GlobalContext } from '../../context'

const WidgetPlaceholderPrivateNotepad = () => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="customize-panel-widget-placeholder">
      <div className="customize-panel-widget-placeholder__head">
        <p>{t('dashboard.private_notepad')}</p>
      </div>
      <div className="customize-panel-widget-placeholder__body">
        <div className="notepad-placeholder">
          <Notepad />
        </div>
      </div>
    </div>
  )
}

export default WidgetPlaceholderPrivateNotepad
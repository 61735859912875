import React, { useState, useRef, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import PersonIcon from '@material-ui/icons/Person'
import DescriptionIcon from '@material-ui/icons/Description'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import SearchIcon from '@material-ui/icons/Search'

import Input from './Input'
import Button from './Button'
import { SEARCH_HISTORY_LS_KEY } from '../../constants'
import { TeamContext, GlobalContext } from '../../context'
import { hasSufficientMembership } from '../../utils'

const SearchBoxModal = ({ onClose, position, onSearch, setSearch, onSearchFormClear, setViewTitle = () => {}, setShowSuggestions, version = 'v1' }) => {
  const { selectedTeam } = useContext(TeamContext)
  const { t } = useContext(GlobalContext)
  const [searchValue, setSearchValue] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])
  const [suggestionsArr, setSuggestionsArr] = useState(localStorage.getItem(SEARCH_HISTORY_LS_KEY) ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY)) : [])
  const formEl = useRef()
  const history = useHistory()
  const searchCategories = [
    { label: t('dashboard.documents'), key: 'documents', icon: <DescriptionIcon /> },
    { label: t('dashboard.templates'), key: 'templates', icon: <i className="custom-icon-template" /> },
    { label: t('dashboard.signatures_2'), key: 'signatures', icon: <i className="custom-icon-create" /> },
    { label: t('dashboard.tasks'), key: 'tasks', icon: <CheckCircleOutlineIcon /> },
    { label: t('dashboard.persons'), key: 'contacts', icon: <PersonIcon />  },
  ]

  // On component mount
  useEffect(() => {
    if(formEl.current) {
      formEl.current.querySelector('input').focus()
    }
  }, [])

  // On form submit
  const searchFormSubmitHandler = (e) => {
    e.preventDefault()
    if(!searchValue.trim()) {
      return
    }
    // if(hasSufficientMembership(selectedTeam?.membership, 'beta_partner')) {
    //   if(version === 'v1') {
    //     history.push(`/search?query=${searchValue}${selectedCategories.length > 0 ? `&area=${selectedCategories.join(',')}` : ''}`)
    //   }else {
    //     history.push(`/search-v2?query=${searchValue}${selectedCategories.length > 0 ? `&area=${selectedCategories.join(',')}` : ''}`)
    //   }
    //   onClose()
    //   return
    // }
    // onSearch(e)
    const suggestions = localStorage.getItem(SEARCH_HISTORY_LS_KEY) ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY)) : []
    if(searchValue !== '') {
      if(!suggestions.includes(searchValue)) {
        suggestions.unshift(searchValue)
      }else {
        const index = suggestions.indexOf(searchValue);
        suggestions.splice(index, 1)
        suggestions.unshift(searchValue)
      }
      localStorage.setItem(SEARCH_HISTORY_LS_KEY, JSON.stringify(suggestions.slice(0,6)))
    }
    setSuggestionsArr(suggestions)

    if(hasSufficientMembership(selectedTeam?.membership, 'beta_partner')) {
      if(version === 'v1') {
        history.push(`/search?query=${searchValue}${selectedCategories.length > 0 ? `&area=${selectedCategories.join(',')}` : ''}`)
      }else {
        history.push(`/search-v2?query=${searchValue}${selectedCategories.length > 0 ? `&area=${selectedCategories.join(',')}` : ''}`)
      }
      onClose()
      return
    }
    onSearch(e)
    onClose()
  }

  // Close modal when x icon is clicked
  const closeSearchModal = () => {
    onClose()
  }

  // Toggle category
  const toggleCategory = (categoryKey) => {
    let sCategories = selectedCategories
    if(isCategorySelected(categoryKey)) {
      sCategories.splice(sCategories.indexOf(categoryKey), 1)
    } else {
      sCategories.push(categoryKey)
    }
    setSelectedCategories([...sCategories])
  }

  // Check if category selected
  const isCategorySelected = (categoryKey) => {
    return selectedCategories.includes(categoryKey)
  }

  // Delete search history - suggestions
  const deleteSearchHistory = (e) => {
    e.preventDefault()
    localStorage.removeItem(SEARCH_HISTORY_LS_KEY)
    setSuggestionsArr([])
  }

  // On suggestion click
  const searchTermClick = (e, term) => {
    e.preventDefault()
    setSearchValue(term)
    setSearch && setSearch(term)
    setViewTitle(term)
    setTimeout(() => {
      formEl.current.querySelector('button').click()
    }, 50)
  }

  return ReactDOM.createPortal(
    <div className="search-box-modal">
      <div className="search-box-modal__overlay" onClick={onClose}></div>
      {/* <div className="search-box-modal__body" style={{left: position.left - 10}}> */}
      <div className="search-box-modal__body">
        <div className="search-wrap">
          <form onSubmit={searchFormSubmitHandler} ref={formEl}>
            <Input
              name="search"
              placeholder={t('dashboard.search')}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="close" onClick={closeSearchModal}><CloseIcon /></div>
            <Button text={t('dashboard.search_2')} type="submit" primary />
          </form>
        </div>

        {hasSufficientMembership(selectedTeam?.membership, 'beta_partner') && <div className="select-categories">
          <p>{t('dashboard.looking_for')}</p>
          <div className="select-categories__body">
            { searchCategories.map((cat, ci) => (
              <Button 
                key={ci}
                onButtonClick={() => toggleCategory(cat.key)} 
                primary={isCategorySelected(cat.key)}
                primaryLight={!isCategorySelected(cat.key)}
                text={cat.label}
                icon={cat.icon} /> 
              )
            )}
          </div>
        </div>}

        <div className="search-history">
          {suggestionsArr.length > 0 && <p>{t('general.your_search_history')} <a href="/#" onClick={deleteSearchHistory}>{t('general.delete_search_history')}</a></p>}
            {suggestionsArr.length > 0 ? 
              <ul className="search-history__list">
                {[...suggestionsArr].filter((h, i) => i < 6).map((h, i) => (
                  <li key={i}><SearchIcon /> <a href="/" onClick={(e) => searchTermClick(e, h)}>{h}</a></li>
                ))}
              </ul>
              :
            <p>{t('general.no_search_history')}</p>
          }
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default SearchBoxModal
import { useContext } from 'react'

import { GlobalContext } from '../../context'

const WidgetPlaceholderAlerts = () => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="customize-panel-widget-placeholder">
      <div className="customize-panel-widget-placeholder__head">
        <p>{t('dashboard.alerts')}</p>
      </div>
      <div className="customize-panel-widget-placeholder__body">
        <div className="alerts-placeholder">
          <div className="alerts-placeholder__col">
            <p>Lun</p>
            <p className="num">1</p>
          </div>
          <div className="alerts-placeholder__col">
            <p>Mar</p>
            <p className="num">2</p>
          </div>
          <div className="alerts-placeholder__col">
            <p>Mer</p>
            <p className="num active">3</p>
          </div>
          <div className="alerts-placeholder__col">
            <p>Jeu</p>
            <p className="num">4</p>
          </div>
          <div className="alerts-placeholder__col">
            <p>Ven</p>
            <p className="num">5</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WidgetPlaceholderAlerts
import React, { useState, useEffect, cloneElement, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { ClickAwayListener } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded'

const ActionsDropdown = ({ className, trigger, onTriggerClick = () => {}, headTitle, dropdownClass, withBackArrow, children, onBackButtonClick = () => {}, width = 240, hide, hideHeader = false, onClose = () => {}, noPadding, centered, trackTime, onSetEditStartTime, onStoreEditTime, fixed, hideClose = false, menuId, show, onClickAway }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: -10000, left: -10000 })
  const [isReady, setIsReady] = useState(false)
  const triggerEl = useRef() 
  const dropdownEl = useRef()

  // On resize
  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line
  }, [])

  // Hide from outside
  useEffect(() => {
    if(hide) {
      setShowDropdown(false)
    }
  }, [hide])

  // Show from outside
  useEffect(() => {
    if(show) {
      setShowDropdown(true)
    }
  }, [show])

  // Track time
  useEffect(() => {
    if(trackTime) {
      if(showDropdown) {
        onSetEditStartTime(Date.now())
      }else{
        onStoreEditTime()
      }
    }
    // eslint-disable-next-line
  }, [trackTime, showDropdown])

  // CSS classes for dropdown
  let dropdownCssClasses = 'actions-dropdown'
  if(noPadding) {
    dropdownCssClasses += ' actions-dropdown--no-padding'
  }
  if(dropdownClass) {
    dropdownCssClasses += ` ${dropdownClass}` 
  }
  if(fixed) {
    dropdownCssClasses += ' fixed'
  }

  // On back button click
  const backButtonClickHandler = () => {
    setShowDropdown(false)
    onBackButtonClick()
  }

  // Calculate dropdown position
  const calculateDropdownPosition = useCallback(() => {
    const { top, left, height, width: elWidth } = triggerEl.current.getBoundingClientRect()
    if(height === 0 && elWidth === 0) return

    const dropdownHeight = dropdownEl.current?.getBoundingClientRect().height
    let posLeft = left
    let posTop = top + height + 5 + window.scrollY
    
    if(fixed) {
      posTop = top + height + 5
    }

    if(centered) {
      posLeft = left - elWidth / 2
    }
    
    if(left + width > window.innerWidth - 20) {
      posLeft = window.innerWidth - width - 20
    }
    if(dropdownHeight && window.innerHeight < top + height + dropdownHeight + 20) {
      posTop = window.innerHeight + window.scrollY - dropdownHeight - 20
    }
    if(dropdownHeight && window.innerHeight < dropdownHeight + 40) {
      posTop = 20
    }
    if(window.innerWidth <= width + 40) {
      posLeft = 20
    }
    if(dropdownPosition.top !== posTop || dropdownPosition.left !== posLeft) {
      setDropdownPosition({
        top: posTop,
        left: posLeft
      })
    }
    setIsReady(true)
  }, [dropdownPosition, width, centered, fixed])

  // Set dropdown positon
  useEffect(() => {
    if(dropdownEl.current && showDropdown) {
      calculateDropdownPosition()
    }
    // eslint-disable-next-lin
  }, [showDropdown, calculateDropdownPosition])

  // Every time window resizes update dropdown position 
  const onResize = () => {
    setShowDropdown(false)
    // calculateDropdownPosition()
  }

  // On click away
  const handleClickAway = (e) => {
    setShowDropdown(false)
    onClose()
    setIsReady(false)
    if(onClickAway) {
      onClickAway(e)
    }
  }

  // Render dropdown
  const renderDropdown = () => {
    if(!showDropdown || !isReady) {
      dropdownCssClasses += ' actions-dropdown--hide'
    }
    return (
      <div id={menuId} className={dropdownCssClasses} ref={dropdownEl} style={{top: dropdownPosition.top, left: dropdownPosition.left, width}}>
        {!hideHeader && <div className="actions-dropdown__head">
          {withBackArrow && <ChevronLeftIcon className="back" onClick={backButtonClickHandler} />}
          <p>{headTitle}</p>
          {!hideClose && <CloseIcon onClick={() => setShowDropdown(false)} />}
        </div>}
        <div className="actions-dropdown__body u-custom-scrollbar">
          {children}
        </div>
      </div>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={className}>
        {trigger && cloneElement(trigger, {...trigger.props, ref: triggerEl, onClick: (e) => {
          e.preventDefault()
          onTriggerClick()
          setShowDropdown(!showDropdown)
        }})}
        {showDropdown && createPortal(renderDropdown(), document.getElementById('modal-root'))}
      </div>
    </ClickAwayListener>
  )
}

export default ActionsDropdown
import { useCallback, useContext } from 'react'

import { GlobalContext, LoaderContext, NotificationContext, UserContext } from '../context'
import { getFileData } from '../helpers/files'
import { base64toBlob, blobToFile } from '../utils'
import { get_file_from_external_url, update_user } from '../services/firestore'
import { bug_report, send_feedback } from '../services/functions'
import { updateFeature } from '../services/db_features'

const useSupportActions = () => {
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { user, setUser } = useContext(UserContext)

  // Report a bug
  const reportBug = useCallback(async ({ bugMsg, url, setUrl, files, onClose }) => {
    if(bugMsg.trim() === '') {
      return 
    }

    let data = []

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('support.sending_message'))
    if(url.trim() !== '') {
      const res = await get_file_from_external_url({ url, getType: true })
      if(!res.success) {
        setUrl('')
        if(res.type === 'file-too-big') {
          return setNotification({ msg: t('notification.file_too_big'), type: 'danger' })
        }
        return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }

      const blob = base64toBlob(res.data)
      let fileName = `file-${Date.now()}`
      let fileType = ''
      let fileExt = ''
      if(res.file_type) {
        fileExt = res.file_type.ext
        fileName = `${fileName}.${fileExt}`
        fileType = res.file_type.mime
      }else {
        return setNotification({ msg: t('notification.no_file_extension'), type: 'danger' })
      }

      const allowedTypes = ['png', 'jpeg', 'jpg', 'gif']
      if(!allowedTypes.includes(fileExt)) {
        return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      }

      const file = blobToFile(blob, fileName)
      const fileData = await getFileData(file, fileType)
      data = [fileData]
    }else {
      // promises array for files
      let filesData = []
      files.forEach(file => filesData.push(getFileData(file)))
      data = await Promise.all(filesData)
    }

    let content = bugMsg.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')

    // email html template
    let emailHtml = `
      <div>${content}</div>
    `

    try {
      await bug_report(user.email, emailHtml, 'Carbon Bug Report', data.length > 0 ? data : [])
    } catch (err) {
      console.log(err)
    }
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
    setNotification({ msg: t('notification.message_sent'), type: 'success' })
    onClose()
  }, [setNotification, setShowGlobalResponseLoader, t, user, setGlobalResponseLoaderText])

  // Give feedback
  const giveFeedback = useCallback(async ({ selectedRating, message, onClose }) => {
    if(selectedRating === '') return 

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('support.sending_feedback'))
    let content = null
    if(message.trim() !== '') {
      content = message.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')
    }

    // email html template
    let emailTop = `
      <div style="background-color: #2663EB; color: #ffffff; margin-bottom: 20px; padding: 10px; font-size: 16px;">Rating: <span style="font-weight: bold;">${selectedRating}</span></div>
    `
    let emailHtml = emailTop

    if(content) {
      emailHtml += `
        <div>${content}</div>
      `
    }

    try {
      await send_feedback(user.email, emailHtml, 'Carbon App - User Feedback')
    } catch (err) {
      console.log(err)
    }
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
    setNotification({ msg: t('support.feedback_sent'), type: 'success' })
    onClose()
  }, [setNotification, setShowGlobalResponseLoader, t, user, setGlobalResponseLoaderText])

  // Vote upcoming features
  const voteUpcomingFeatures = useCallback(async ({ features, newFeatures, onClose }) => {
    let checkedItems = features.filter(f => f.checked)
    if(checkedItems.length) {
      setShowGlobalResponseLoader(true)
      setGlobalResponseLoaderText(t('support.submitting_votes'))
      let promises = []

      checkedItems.forEach(item => {
        const { id } = item
        let feature = newFeatures.find(f => f.id === id)
        if(feature) {
          let votes = feature.votes || []
          if(!votes.includes(user.id)) {
            votes.push(user.id)
            promises.push(updateFeature(id, votes))
          }
        }
      })

      if(promises.length) {
        await Promise.all(promises) 
        const votedFeatures = newFeatures.map(f => f.id)
        await update_user({ featuresWhenVoted: votedFeatures }, user.id)
        setUser({ ...user, featuresWhenVoted: votedFeatures })
      }
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
      onClose()
    }
  }, [setShowGlobalResponseLoader, setUser, user, setGlobalResponseLoaderText, t])

  return {
    reportBug,
    giveFeedback,
    voteUpcomingFeatures,
  }
}

export default useSupportActions
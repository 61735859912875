import { useState, useEffect, useContext, memo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import ActionsDropdown from '../../UI/ActionsDropdown';
import CustomTooltip from '../../UI/CustomTooltip';
import { LetterCircle, Alert } from '../../new_ui';
import { MoveSelectedItemsToFolderModal, InjectModal } from '../';
import { LimitedFeatureWrapper } from '../../utils/LimitedFeatureWrapper';
import {
  GlobalContext,
  DocumentsContext,
  ConstantsContext,
  TagContext,
  DocumentsFoldersContext,
} from '../../../context';
import {
  useDocumentsFoldersActions,
  useSingleDocumentActions,
  useDocumentsActions,
} from '../../../hooks';
import { numberOfValuesInObj } from '../../../utils';
import { TAGS_COLORS } from '../../../constants';

const DocumentsRow = ({
  id,
  doc,
  selectedDocuments,
  onSetSelectedDocuments,
  allChecked,
  columns,
  refreshDocs,
}) => {
  const { t } = useContext(GlobalContext);
  const {
    getTemplateById,
    updateDocument,
    setDocsSelectedFilters,
    currentFilter,
  } = useContext(DocumentsContext);
  const { STATUS_LABELS } = useContext(ConstantsContext);
  const { tags } = useContext(TagContext);
  const { setDocFoldersSelectedFilter, docFolders, docFoldersSelectedFilter } =
    useContext(DocumentsFoldersContext);
  const { moveDocumentsFolder, updateBreadcrumbs } =
    useDocumentsFoldersActions();
  const {
    moveDocumentToFolder,
    downloadDocument,
    duplicateDocument,
    archiveDocument,
    deleteDocument,
    restoreDocument,
    deleteDocumentPermanently,
  } = useSingleDocumentActions();
  const { moveDocumentsToFolder } = useDocumentsActions();
  const [checked, setChecked] = useState(false);
  const [documentTags, setDocumentTags] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showInjectModal, setShowInjectModal] = useState(false);
  const [showDeletePermanentlyAlert, setShowDeletePermanentlyAlert] =
    useState(false);
  const history = useHistory();

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if (checked && selectedDocuments.length === 0) {
      setChecked(false);
    }
  }, [selectedDocuments, checked]);

  // Set checked to true if allChecked is true
  useEffect(() => {
    if (allChecked) {
      setChecked(true);
    }
  }, [allChecked]);

  // Set document tags
  useEffect(() => {
    if (doc.info?.keywords && tags.length > 0) {
      let arr = [];
      tags.forEach((tag) => {
        if (doc.info.keywords.includes(tag.id)) {
          arr.push(tag);
        }
      });
      setDocumentTags(arr);
    } else {
      setDocumentTags([]);
    }
  }, [doc, tags]);

  // On document select
  const handleSelectDocument = () => {
    setChecked(!checked);
    if (checked) {
      onSetSelectedDocuments((prev) => prev.filter((d) => d.id !== id));
    } else {
      onSetSelectedDocuments((prev) => [...prev, doc]);
    }
  };

  // On go to doc
  const handleGoToDoc = () => {
    if (doc.folderRow) {
      setDocsSelectedFilters((prev) => ({ ...prev, folder: doc.id }));
      onSetSelectedDocuments([]);
      setDocFoldersSelectedFilter(doc.id);
      updateBreadcrumbs({ folder: doc });
      return;
    }
    history.push(`/document-detail/${id}`);
  };

  // Update doc status
  const updateDocStatus = async (st) => {
    await updateDocument({ status: st }, doc);
    document.body.click();
  };

  // Check if object is empty
  const isObjectEmpty = (obj, exclude = []) => {
    let empty = true;
    let breakLoop = false;
    for (let key in obj) {
      if (!exclude.includes(key) && !breakLoop) {
        if (obj[key] === Object(obj[key])) {
          for (let innerKey in obj[key]) {
            if (obj[key][innerKey] && obj[key][innerKey] !== '') {
              empty = false;
              breakLoop = true;
            }
          }
        } else {
          if (obj[key] && obj[key] !== '') {
            empty = false;
            breakLoop = true;
          }
        }
      }
    }
    return empty;
  };

  // Print date
  const printDate = (date) => {
    if (!date) return '';
    return moment(date).format('DD MMM YY');
  };

  // Print time
  const printTime = (date) => {
    if (!date) return '';
    return moment(date).format('HH:mm');
  };

  // On doc duplicate
  const handleDocDuplicate = async (e) => {
    e.preventDefault();
    await duplicateDocument({ doc });
    refreshDocs();
  };

  // On doc archive
  const handleDocArchive = async (e) => {
    e.preventDefault();
    await archiveDocument({ doc });
    refreshDocs();
  };

  // On doc download
  const handleDocDownload = async (e, type) => {
    e.preventDefault();
    await downloadDocument({ doc, type });
    window.document.body.click();
  };

  // On doc move click
  const handleDocMoveClick = async (e) => {
    e.preventDefault();
    setShowMoveModal(true);
  };

  // On close move modal
  const handleCloseMoveModal = () => {
    setShowMoveModal(false);
  };

  // On doc move
  const handleDocMove = async (moveTo, current) => {
    await moveDocumentsToFolder({
      moveTo,
      current,
      selectedDocuments: [doc],
      setSelectedDocuments: onSetSelectedDocuments,
      setShowMoveMultipleDocsToFolderModal: setShowMoveModal,
    });
    refreshDocs();
  };

  // On doc delete click
  const handleDocDeleteClick = async (e) => {
    e.preventDefault();
    setShowDeleteAlert(true);
  };

  // On doc delete
  const handleDocDelete = async () => {
    await deleteDocument({
      doc,
      singleDocToDelete: null,
      setShowDeleteSingleDocAlert: setShowDeleteAlert,
      setSingleDocToDelete: () => {},
    });
    refreshDocs();
    refreshDocs();
  };

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  // On doc inject click
  const handleDocInjectClick = (e) => {
    e.preventDefault();
    setShowInjectModal(true);
  };

  // On inject modal close
  const handleCloseInjectModal = () => {
    setShowInjectModal(false);
  };

  // On restore
  const handleRestore = async (e) => {
    e.preventDefault();
    await restoreDocument({ doc, currentFilter, setRestored: () => {} });
    refreshDocs();
  };

  // On delete permanently click
  const handleDeletePermanentlyClick = (e) => {
    e.preventDefault();
    setShowDeletePermanentlyAlert(true);
  };

  // On close delete permanently alert
  const handleCloseDeletePermanentlyAlert = () => {
    setShowDeletePermanentlyAlert(false);
  };

  // On delete documents permanently
  const handleDeleteDocumentPermanently = async () => {
    await deleteDocumentPermanently({ doc });
    refreshDocs();
  };

  // Render checkbox column
  const renderCheckboxColumn = () => {
    return (
      <div className="list-view-table__cell checkbox">
        {!checked ? (
          <span
            className="material-symbols-outlined checkbox-icon"
            onClick={handleSelectDocument}
          >
            check_box_outline_blank
          </span>
        ) : (
          <span
            className="material-symbols-outlined filled checkbox-icon"
            onClick={handleSelectDocument}
          >
            check_box
          </span>
        )}
        {doc.folderRow ? (
          <span className="material-symbols-outlined row-icon filled">
            folder
          </span>
        ) : (
          <span className="material-symbols-outlined row-icon">
            {doc.uploaded ? 'upload_file' : 'description'}
          </span>
        )}
      </div>
    );
  };

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={
          <button className="icon-btn icon-btn--small">
            <span className="material-symbols-outlined">more_horiz</span>
          </button>
        }
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          {currentFilter === 'all' && (
            <>
              <li>
                <a href="/" onClick={handleDocDuplicate}>
                  <span className="icon">
                    <span className="material-symbols-outlined">
                      content_copy
                    </span>
                  </span>
                  {t('dashboard.duplicate')}
                </a>
              </li>
              <li>
                <a href="/" onClick={handleDocArchive}>
                  <span className="icon">
                    <span className="material-symbols-outlined">archive</span>
                  </span>
                  {t('general.archive')}
                </a>
              </li>
              <li>
                <div>
                  <span className="icon">
                    <span className="material-symbols-outlined">download</span>
                  </span>
                  {t('general.download')}
                  <div>
                    <button onClick={(e) => handleDocDownload(e, 'pdf')}>pdf</button>
                    <button onClick={(e) => handleDocDownload(e, 'docx')}>docx</button>
                  </div>
                </div>
                {/*<a href="/" onClick={handleDocDownload}>*/}
                {/*</a>*/}
              </li>
              <li>
                <a href="/" onClick={handleDocMoveClick}>
                  <span className="icon">
                    <span className="material-symbols-outlined">
                      drive_file_move_outline
                    </span>
                  </span>
                  {t('general.move')}
                </a>
              </li>
              {!doc.uploaded && (
                <li>
                  <a href="/" onClick={handleDocInjectClick}>
                    <span className="icon">
                      <span className="material-symbols-outlined">cached</span>
                    </span>
                    {t('inject.title')}
                  </a>
                </li>
              )}
              <li>
                <a href="/" onClick={handleDocDeleteClick} className="delete">
                  <span className="icon">
                    <span className="material-symbols-outlined">delete</span>
                  </span>
                  {t('general.remove')}
                </a>
              </li>
            </>
          )}
          {currentFilter === 'archived' && (
            <>
              <li>
                <a href="/" onClick={handleRestore}>
                  <span className="icon">
                    <span className="material-symbols-outlined">restore</span>
                  </span>
                  {t('general.restore')}
                </a>
              </li>
              <li>
                <a href="/" onClick={handleDocDeleteClick} className="delete">
                  <span className="icon">
                    <span className="material-symbols-outlined">delete</span>
                  </span>
                  {t('general.remove')}
                </a>
              </li>
            </>
          )}
          {currentFilter === 'deleted' && (
            <>
              <li>
                <a href="/" onClick={handleRestore}>
                  <span className="icon">
                    <span className="material-symbols-outlined">restore</span>
                  </span>
                  {t('general.restore')}
                </a>
              </li>
              <li>
                <a
                  href="/"
                  onClick={handleDeletePermanentlyClick}
                  className="delete"
                >
                  <span className="icon">
                    <span className="material-symbols-outlined">delete</span>
                  </span>
                  {t('general.delete_permanently')}
                </a>
              </li>
            </>
          )}
        </ul>
      </ActionsDropdown>
    );
  };

  // Render title column
  const renderTitleColumn = (col, i) => {
    return (
      <div
        className="list-view-table__cell u-truncate-text-wrapper title-col"
        key={i}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <p
          className="title text-style-1 u-truncate-text"
          onClick={handleGoToDoc}
        >
          {doc.name || `Document ${doc.id}`}
        </p>
        {!doc.folderRow && (
          <div className="show-on-hover">{renderSingleDocActions()}</div>
        )}
      </div>
    );
  };

  // Render status column
  const renderStatusColumn = (col, i) => {
    const statuses = [
      'empty',
      'draft',
      'to_validate',
      'validated',
      'ready',
    ].filter((s) => s !== doc.status);
    const arr = statuses;

    // const status = doc.status !== 'empty' ? <span className={`status-el ${doc.status}`}>{ STATUS_LABELS[doc.status] }</span> : <span>&nbsp;</span>
    const status = (
      <span className={`status-v2 u-cursor--pointer ${doc.status}`}>
        {doc.status === 'empty' ? '-' : STATUS_LABELS[doc.status]}
      </span>
    );

    let content = null;

    if (!doc.folderRow) {
      content = (
        <ActionsDropdown
          trigger={status}
          hideHeader
          dropdownClass="doc-row-statuses"
          className="doc-row-status-wrapper"
          width={120}
          // centered
        >
          <ul>
            {arr.map((st, i) => (
              <li key={i}>
                <span
                  onClick={() => updateDocStatus(st)}
                  className={`status-v2 ${st}`}
                >
                  {st === 'empty' ? '' : STATUS_LABELS[st]}
                </span>
              </li>
            ))}
          </ul>
        </ActionsDropdown>
      );
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        {content}
      </div>
    );
  };

  // Render user column
  const renderUserColumn = (col, i) => {
    let image_url = '';
    let content = null;

    if (doc.user) {
      content = <LetterCircle data={doc.user} />;
    }

    return (
      <div
        className="list-view-table__cell u-truncate-text-wrapper user-col"
        key={i}
      >
        <div className="u-truncate-text">{content}</div>
      </div>
    );
  };

  // Render date column
  const renderDateColumn = (col, i) => {
    const date =
      col.value === 'meta.created'
        ? printDate(doc.meta.created)
        : printDate(doc.meta.updated);
    const time =
      col.value === 'meta.created'
        ? printTime(doc.meta.created)
        : printTime(doc.meta.updated);
    const content = (
      <div className="u-truncate-text">
        <CustomTooltip content={<span>{time}</span>}>
          <span className="normal">{date}</span>
        </CustomTooltip>
      </div>
    );

    return (
      <div
        className="list-view-table__cell u-truncate-text-wrapper date-col"
        key={i}
      >
        {content}
      </div>
    );
  };

  // Render progress column
  const renderProgressColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content = !doc.uploaded ? (
        `${(doc.progress * 100).toFixed(0)}%`
      ) : (
        <span>&nbsp;</span>
      );
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text">{content}</div>
      </div>
    );
  };

  // Render progress column
  const renderTemplateColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content = (
        <>
          <div className="template u-truncate-text">
            {getTemplateById(doc.template) ? t('general.yes') : t('general.no')}
          </div>
          <div className="hover">
            {getTemplateById(doc.template) ? (
              <span className="material-symbols-outlined on">link</span>
            ) : (
              <span className="material-symbols-outlined off">link_off</span>
            )}
          </div>
        </>
      );
    }
    return (
      <div className="list-view-table__cell" key={i}>
        <div className="text-smaller u-truncate-text-wrapper">{content}</div>
      </div>
    );
  };

  // Render attachments column
  const renderAttachmentsColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content = doc?.attachments?.length ? (
        <span className="num num--1">{doc.attachments.length}</span>
      ) : (
        <span>&nbsp;</span>
      );
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="u-truncate-text">{content}</div>
      </div>
    );
  };

  // Render tasks column
  const renderTasksColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content = doc.tasks?.length ? (
        <span className="num num--2">{doc.tasks.length}</span>
      ) : (
        <span>&nbsp;</span>
      );
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="u-truncate-text">{content}</div>
      </div>
    );
  };

  // Render dates column
  const renderDatesColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content =
        doc.dates && !isObjectEmpty(doc.dates) ? (
          <span className="num num--3">{numberOfValuesInObj(doc.dates)}</span>
        ) : (
          <span>&nbsp;</span>
        );
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="u-truncate-text">{content}</div>
      </div>
    );
  };

  // Render alerts column
  const renderAlertsColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content =
        doc.doc_alerts && doc.doc_alerts.length > 0 ? (
          <span className="num num--4">{doc.doc_alerts.length}</span>
        ) : (
          <span>&nbsp;</span>
        );
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="u-truncate-text">{content}</div>
      </div>
    );
  };

  // Render tags column
  const renderTagsColumn = (col, i) => {
    let content = null;

    if (!doc.folderRow) {
      content = documentTags.length ? (
        <div className="tags tags--normal u-truncate-text">
          {documentTags.map((tag, idx) => (
            <span
              key={idx}
              className="tag"
              style={{
                backgroundColor: tag.color
                  ? tag.color
                  : TAGS_COLORS[TAGS_COLORS.length - 1],
              }}
            >
              {tag.name}
            </span>
          ))}
        </div>
      ) : (
        <span>&nbsp;</span>
      );
    }

    return (
      <div className="list-view-table__cell" key={i}>
        <div className="u-truncate-text-wrapper">{content}</div>
      </div>
    );
  };

  // Render custom column
  const renderCustomColumn = (col, i) => {
    let content = null;
    if (col.custom) {
      let val = doc[col.value] || '';
      if (col.customType === 'date' && moment(val).isValid()) {
        content = printDate(val);
      } else if (col.customType === 'select' && col.customOptions) {
        const findOption = col.customOptions.find((opt) => opt.value === val);
        if (findOption) {
          content = findOption.label;
        } else {
          content = val;
        }
      } else {
        content = val;
      }
    }

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text">
          {content ? content : <span>&nbsp;</span>}
        </div>
      </div>
    );
  };

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell" key={i}>
        <span>&nbsp;</span>
      </div>
    );
  };

  // Render column content
  const renderColumnContent = (col, i) => {
    let content = null;
    const premiums = ['user'];

    if (col.value === 'name') {
      content = renderTitleColumn(col, i);
    } else if (col.value === 'progress') {
      content = renderProgressColumn(col, i);
    } else if (col.value === 'sort_template') {
      content = renderTemplateColumn(col, i);
    } else if (col.value === 'status_label') {
      content = renderStatusColumn(col, i);
    } else if (col.value === 'sort_attachments') {
      content = renderAttachmentsColumn(col, i);
    } else if (col.value === 'sort_tasks') {
      content = renderTasksColumn(col, i);
    } else if (col.value === 'sort_dates') {
      content = renderDatesColumn(col, i);
    } else if (col.value === 'sort_alerts') {
      content = renderAlertsColumn(col, i);
    } else if (col.value === 'sort_tags') {
      content = renderTagsColumn(col, i);
    } else if (col.value === 'user') {
      content = renderUserColumn(col, i);
    } else if (col.value === 'meta.created') {
      content = renderDateColumn(col, i, t('dashboard.created_on'));
    } else if (col.value === 'meta.updated') {
      content = renderDateColumn(col, i, t('dashboard.updated_on'));
    } else if (col.value === 'helper') {
      content = renderHelperColumn(i);
    } else {
      content = renderCustomColumn(col, i);
    }

    // if(premiums.includes(col.value)) {
    //   content = <LimitedFeatureWrapper key={i} type="team" requirement="beta_partner">{content}</LimitedFeatureWrapper>
    // }

    return content;
  };

  // Drag start
  const handleDragStart = (e) => {
    const div = document.createElement('div');
    if (doc.folderRow) {
      div.id = 'draggable-folder-el';
    } else {
      div.id = 'draggable-document-el';
    }
    div.className = 'draggable-ghost-el-v2';
    div.innerText = t('folder.move', { folder: doc.name });
    document.getElementById('root').appendChild(div);
    if (doc.folderRow) {
      e.dataTransfer.setData('folder', JSON.stringify(doc));
    } else {
      e.dataTransfer.setData('document', JSON.stringify(doc));
    }
    e.dataTransfer.setDragImage(div, 0, 0);
  };

  // Drag end
  const handleDragEnd = (e) => {
    if (doc.folderRow) {
      document.getElementById('draggable-folder-el').remove();
    } else {
      document.getElementById('draggable-document-el').remove();
    }
  };

  // Drag over
  const handleDragOver = (e) => {
    e.preventDefault();
    if (!doc.folderRow) return;
    const box = e.target.closest('.folder-row');
    if (!box) return;
    if (!box.classList.contains('hover')) {
      box.classList.add('hover');
    }
  };

  // Drag Leave
  const handleDragLeave = (e) => {
    e.preventDefault();
    if (!doc.folderRow) return;
    const box = e.target.closest('.folder-row');
    if (!box) return;
    if (box.classList.contains('hover')) {
      box.classList.remove('hover');
    }
  };

  // On drop
  const handleDrop = async (e) => {
    if (!doc.folderRow) return;

    const data = e.dataTransfer.getData('folder')
      ? JSON.parse(e.dataTransfer.getData('folder'))
      : null;

    const folderBox = e.target.closest('.folder-row');
    if (folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }

    if (data) {
      await moveDocumentsFolder({ docFolderToMove: data, selectedFolder: doc });
      refreshDocs();
      return;
    }

    const documentData = e.dataTransfer.getData('document')
      ? JSON.parse(e.dataTransfer.getData('document'))
      : null;

    if (documentData) {
      await moveDocumentToFolder({
        document: documentData,
        folder: doc,
        setSelectedDocuments: onSetSelectedDocuments,
      });
      refreshDocs();
    }
  };

  return (
    <>
      <div
        className={`list-view-table__row ${checked ? 'selected' : ''} ${
          doc.folderRow ? 'folder-row' : ''
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {columns
          .filter((c) => c.checked)
          .map((col, i) => {
            return renderColumnContent(col, i);
          })}
        {renderCheckboxColumn()}
      </div>

      {showDeleteAlert && (
        <Alert
          onClose={handleCloseDeleteAlert}
          text={t('alert.delete_document')}
          deleteAlert={true}
          onSubmit={handleDocDelete}
        />
      )}

      {showDeletePermanentlyAlert && (
        <Alert
          onClose={handleCloseDeletePermanentlyAlert}
          text={t('alert.delete_document')}
          deleteAlert={true}
          onSubmit={handleDeleteDocumentPermanently}
        />
      )}

      {showMoveModal && (
        <MoveSelectedItemsToFolderModal
          onClose={handleCloseMoveModal}
          folders={docFolders}
          items={[doc]}
          onMove={handleDocMove}
          currentFolder={
            docFoldersSelectedFilter === 'all'
              ? null
              : [...docFolders].find((f) => f.id === docFoldersSelectedFilter)
          }
        />
      )}

      {showInjectModal && (
        <InjectModal
          onClose={handleCloseInjectModal}
          doc={doc}
          template={getTemplateById(doc?.template)}
          refresh={false}
          // allVariables={() => {}} // TODO - when new document detail page is added update this
        />
      )}
    </>
  );
};

export default memo(DocumentsRow);

import React, { useState, useEffect, Fragment, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Radio from '../../UI/Radio';
import Button from '../../UI/Button';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'
import { dateValueFormat, areSectionConditionsMet } from '../../../utils';
import { GlobalContext } from '../../../context'

const SynthesisModal = ({ onClose, data, getVarKeysFromContent, documentValues, onSetDocumentValues, onSave, onSetEditStartTime, onStoreEditTime }) => {
  const { t } = useContext(GlobalContext)
  const [activeFilter, setActiveFilter] = useState('all');
  const [variableSections, setVariableSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [sectionsCreated, setSectionsCreated] = useState(false);

  useEffect(() => {
    if(!data) {
      return;
    }
    if(!sectionsCreated) {
      const sections = [...data.sections];
      const sectionsHeadings = sections.filter(s => s.type.startsWith('heading'));
      const sectionsUpdated = [];
      if(sectionsHeadings.length === 0) {
        sectionsUpdated.push(sections);
      }else if(sections.indexOf(sectionsHeadings[0]) > 0) {
        sectionsUpdated.push(sections.slice(0, sections.indexOf(sectionsHeadings[0])));
      }
      sectionsHeadings.forEach((s, i, allArr) => {
        const sectionIndex = sections.indexOf(s);
        const arr = sections.indexOf(allArr[i+1]) !== -1 ? sections.slice(sectionIndex, sections.indexOf(allArr[i+1])) : sections.slice(sectionIndex);
        arr[0].sectionIndex = `section-${sectionIndex}`;
        sectionsUpdated.push(arr);
      });
      const sectionsArr = [];
      
      sectionsUpdated.forEach((s) => {
        const vars = [];
        let obj = { title: '', vars: [] };
  
        let idx = 0;
        for(let secField in s) {
          const f = s[secField];
          if(idx === 0 && f.type.startsWith('heading')) {
            const fields = f.content.match(/\{d\.([^}]*)\}/g);
            const title = fields ? fields.length > 0 ? f.content.replace(fields[0], '') : f.content : f.content;
            obj.title = title;
            obj.index = f.sectionIndex;
          }
          if(f.variables || f.variable){
            if(f.variable) {
              if(f.repeatable_section_id) {
                (documentValues[f.repeatable_section_id] || []).forEach((repeatableData, repeatableIndex) => {
                  if(f.conditions) {
                    obj.vars.push({...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatableId: f.repeatable_section_id, repeatableIndex });
                  }else if(f.condition) {
                    obj.vars.push({...f, conditions: [f.condition], belongs_to: f.condition.variable, repeatableId: f.repeatable_section_id, repeatableIndex })
                  }else {
                    obj.vars.push({...f, repeatableId: f.repeatable_section_id, repeatableIndex });
                  }
                  if(!vars.includes(f.variable)) {
                    vars.push(f.variable);
                  }
                })
              } else {
                if(f.conditions) {
                  obj.vars.push({...f, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable})
                }else if(f.condition) {
                  obj.vars.push({...f, conditions: [f.condition], belongs_to: f.condition.variable })
                }else {
                  obj.vars.push(f);
                }
                if(!vars.includes(f.variable)) {
                  vars.push(f.variable);
                }
              }
            }else {
              if(f.repeatable_section_id) {
                (documentValues[f.repeatable_section_id] || []).forEach((repeatableData, repeatableIndex) => {
                  f.variables.forEach((v) => {
                    if(!vars.includes(v.variable)) {
                      vars.push(v.variable);
                    }
                    if(obj.vars.indexOf(f) === -1) {
                      if(f.conditions) {
                        obj.vars.push({...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable, repeatableId: f.repeatable_section_id, repeatableIndex });
                      }else if(f.condition) {
                        obj.vars.push({...v, conditions: [f.condition], belongs_to: f.condition.variable, repeatableId: f.repeatable_section_id, repeatableIndex })
                      }else {
                        obj.vars.push({...v, repeatableId: f.repeatable_section_id, repeatableIndex });
                      }
                    }
                  });
                })
              } else {
                f.variables.forEach((v) => {
                  if(!vars.includes(v.variable)) {
                    vars.push(v.variable);
                  }
                  if(obj.vars.indexOf(f) === -1) {
                    if(f.conditions) {
                      obj.vars.push({...v, conditions: f.conditions, belongs_to: f.conditions[f.conditions.length - 1].variable });
                    }else if(f.condition) {
                      obj.vars.push({...v, conditions: [f.condition], belongs_to: f.condition.variable })
                    }else {
                      obj.vars.push(v);
                    }
                  }
                });
              }
            }
          }
          idx++;
        }
        if(obj.vars.length > 0) {
          sectionsArr.push(obj);
        }
      });
  
      setVariableSections(sectionsArr);
      setFilteredSections(sectionsArr);
      setSectionsCreated(true);
    }
    // eslint-disable-next-line
  }, [data, documentValues]);

  const submitHandler = useCallback((e) => {
    e.preventDefault();
    onSave(true);
  }, [onSave])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        submitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [submitHandler])

  const onValueChange = (handle, value, repeatableId, repeatableIndex) => {
    let dv = {...documentValues};
    if(repeatableId && !dv[repeatableId]) {
      dv[repeatableId] = [];
    }
    if(repeatableId) {
      if(!dv[repeatableId][repeatableIndex]) {
        dv[repeatableId][repeatableIndex] = {};
      }
      dv[repeatableId][repeatableIndex][handle] = value;
    } else {
      dv[handle] = value;
    }
    onSetDocumentValues(dv);
  }

  const variableKeysInSection = (sec) => {
    let vars = []
    for(let i in sec.variables) {
      vars.push(sec.variables[i].variable)
    }
    return vars
  }

  const variableForKeyInSection = (key, sec) => {
    for(let i in sec.variables) {
      if(sec.variables[i].variable === key) {
        return sec.variables[i]
      }
    }
  }

  const valueForVariable = (variable, repeatableId, repeatableIndex) => {
    if(repeatableId) {
      return documentValues[repeatableId]?.[repeatableIndex]?.[variable] || ""
    }
    return documentValues[variable] || ""
  }
  
  const renderTextInput = (variable, type = "text", label, locked = false, repeatableId, repeatableIndex) => {
    return ( 
      <div className="input-wrapper">
        <Input disabled={locked} small label={label} value={valueForVariable(variable, repeatableId, repeatableIndex)} type={type} onChange={(e) => onValueChange(variable, e.target.value, repeatableId, repeatableIndex) } className={!!valueForVariable(variable, repeatableId, repeatableIndex) ? 'not-empty' : ''} onFocus={() => onSetEditStartTime(Date.now())} onBlur={onStoreEditTime} /> 
      </div>
    )
  }

  const renderTextArea = (variable, label, locked = false, repeatableId, repeatableIndex) => {
    return ( 
      <div className="textarea-wrapper">
        <p className="label">{label}</p>
        <textarea disabled={locked} value={valueForVariable(variable, repeatableId, repeatableIndex) || ''} onChange={(e) => onValueChange(variable, e.target.value, repeatableId, repeatableIndex) } className={!!valueForVariable(variable, repeatableId, repeatableIndex) ? 'not-empty' : ''} onFocus={() => onSetEditStartTime(Date.now())} onBlur={onStoreEditTime}></textarea>
      </div>
    )
  }

  const renderSelectInput = (variable, options, label, locked = false, repeatableId, repeatableIndex) => {
    return (
      <div className="input-wrapper">
        <div className={valueForVariable(variable, repeatableId, repeatableIndex) ? "select_input_wrapper not-empty" : "select_input_wrapper"}>
          <p className="label">{label}</p>
          <select name="status" id="status" value={valueForVariable(variable, repeatableId, repeatableIndex)} onChange={e => onValueChange(variable, e.target.value, repeatableId, repeatableIndex)} onFocus={() => onSetEditStartTime(Date.now())} onBlur={onStoreEditTime}>
            <option disabled selected value> {t('dashboard.select_option')} </option>
            { options.map((option, optionIndex) => {
              return (
                <option disabled={locked} key={`option_${optionIndex}`} value={option.value}>{ option.label }</option>
              )
            }) }
          </select>
        </div>
      </div>
    )
  }

  const renderDateInput = (variable, label, locked = false, repeatableId, repeatableIndex) => {
    return (
      <div className="input-wrapper">
        <div className={valueForVariable(variable, repeatableId, repeatableIndex) ? 'date_picker not-empty' : 'date_picker'}>
          <p className="label">{label}</p>
          <DatePicker
            selected={!valueForVariable(variable, repeatableId, repeatableIndex) ? '' : new Date(moment(valueForVariable(variable, repeatableId, repeatableIndex), dateValueFormat).valueOf())}
            onChange={date => onValueChange(variable, moment(date.valueOf()).format(dateValueFormat), repeatableId, repeatableIndex)}
            peekNextMonth
            dateFormat={'eee dd/MM/yyyy'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="fr"
            // onChangeRaw={(e) => e.preventDefault()}
            // isClearable={documentValues[variable]}
            onCalendarOpen={() => onSetEditStartTime(Date.now())}
            onCalendarClose={onStoreEditTime}
            disabled={locked}
          />
        </div>
      </div>
    )
  }

  const filterItemClickHandler = (e, value) => {
    e.preventDefault();
    setActiveFilter(value);
    let filteredSectionArr = [];
    let varSectionsCopy = [...variableSections];
    if(value === 'all') {
      filteredSectionArr = varSectionsCopy;
    }else if(value === 'completed') {
      varSectionsCopy.forEach((s) => {
        let vars = [];
        let section = {...s};
        section.vars.forEach((v) => {
          if(valueForVariable(v.variable, v.repeatableId, v.repeatableIndex)) {
            vars.push(v);
          }
        });
        if(vars.length > 0) {
          section.vars = vars;
          filteredSectionArr.push(section);
        }
      });
    }else if(value === 'empty'){
      varSectionsCopy.forEach((s) => {
        let vars = [];
        let section = {...s};
        section.vars.forEach((v) => {
          if(v.type !== 'question' && !valueForVariable(v.variable, v.repeatableId, v.repeatableIndex) ) {
            vars.push(v);
          }else if(v.type === 'question') {
            if(valueForVariable(v.variable, v.repeatableId, v.repeatableIndex)) {
              vars.push({...v, hide: true});
            }else {
              vars.push(v);
            }
          }
        });
        if(vars.length > 0) {
          section.vars = vars;
          filteredSectionArr.push(section);
        }
      });
    }
    setFilteredSections(filteredSectionArr);
  }
  
  const renderInput = (v, arr, repeatableId, repeatableIndex) => {
    if(!repeatableId && v.repeatableId) {
      repeatableId = v.repeatableId;
    }
    if(isNaN(repeatableIndex) && !isNaN(v.repeatableIndex)) {
      repeatableIndex = v.repeatableIndex;
    }
    let input
    if(v.type === 'string'  && areSectionConditionsMet(v, documentValues)) {
      input = renderTextInput(v.variable, 'text', v.tooltip, v.locked, repeatableId, repeatableIndex)
    } else if(v.type === 'number' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextInput(v.variable, 'number', v.tooltip, v.locked, repeatableId, repeatableIndex) // change to number
    } else if(v.type === 'textarea' && areSectionConditionsMet(v, documentValues)) {
      input = renderTextArea(v.variable, v.tooltip, v.locked, repeatableId, repeatableIndex) 
    }else if(v.type === 'select' && areSectionConditionsMet(v, documentValues)) {
      input = renderSelectInput(v.variable, v.options, v.tooltip, v.locked, repeatableId, repeatableIndex)
    } else if(v.type === 'date' && areSectionConditionsMet(v, documentValues)) {
      input = renderDateInput(v.variable, v.tooltip, v.locked, repeatableId, repeatableIndex)
    }else if(v.type === 'question' && valueForVariable(v.variable, v.repeatableId, v.repeatableIndex) && v.options && areSectionConditionsMet(v, documentValues)) {
      const answer = v.options.find(a => a.value === valueForVariable(v.variable, v.repeatableId, v.repeatableIndex))
      const children = arr.filter(item => item.belongs_to === v.variable)

      let childInput = []
      children.forEach(child => {
        if(child) {
          childInput.push(renderInput(child, arr, repeatableId, repeatableIndex))
        }
      });
      input = <div className="question-container">
        <div className={v.hide ? "question-wrapper hide" : "question-wrapper"}>
          <p>{v.question} <span>{answer && answer.label}</span></p>
        </div>
        {childInput.length > 0 && 
          <div className="inputs-wrapper">
            {childInput.map((ch, index) => ch && <Fragment key={index}>{ch}</Fragment>)}
          </div>
        }
      </div>
    }
    return input
  }

  return(
    <Modal onClose={onClose} className="modal--v2">
      <div className="modal-inner">
        <h2>{data.name}</h2>
        <div className="modal-inner__head">
          <ul>
            <li className="active no-cursor">{t('dashboard.synthesis')}</li>
          </ul>
        </div>
        <div className="modal-inner__body">
          <div className="synthesis">
            <ul className="synthesis__filters">
              <li>
                <a href="/#" className={activeFilter === 'all' ? 'active' : ''} onClick={(e) => filterItemClickHandler(e, 'all')}>
                  {t('synthesis.all_fields')}
                </a>
              </li>
              <li>
                <a href="/#" className={activeFilter === 'empty' ? 'active' : ''} onClick={(e) => filterItemClickHandler(e, 'empty')}>
                  {t('synthesis.empty_fields')}
                </a>
              </li>
              <li>
                <a href="/#" className={activeFilter === 'completed' ? 'active' : ''} onClick={(e) => filterItemClickHandler(e, 'completed')}>
                  {t('synthesis.filled_fields')}
                </a>
              </li>
            </ul>
            <form className="form" onSubmit={submitHandler}>
              {filteredSections.map((section) => {
                return (
                  <div className="synthesis__section">
                    {section.title && <h3>{section.title}</h3>}
                    <div className="synthesis__section_fields">
                      <div className="inputs-wrapper">
                        { section.vars.map((v, idx, arr) => {
                          if(v.belongs_to) return null
                          return renderInput(v, arr, v.repeatableId, v.repeatableIndex)
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="form__actions">
                <Button 
                  text={t('general.cancel')} 
                  onButtonClick={onClose}
                  medium
                  transparent
                />
                <Button 
                  text={t('general.save')}
                  type="submit"
                  primary
                  medium
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SynthesisModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default SynthesisModal;
import { baseUrl, post_request } from './firestore';

const dropboxConfig = {
  clientId: 'sc8rr2948dse2pc'
}

const dropbox_get_items = async (data) => {
  return await post_request(`${baseUrl}/dropbox_get_items`, data);
}

const dropbox_get_temporary_link = async (data) => {
  return await post_request(`${baseUrl}/dropbox_get_temporary_link`, data);
}

const dropbox_upload = async (data) => {
  return await post_request(`${baseUrl}/dropbox_upload`, data);
}

const dropbox_get_token = async (data) => {
  return await post_request(`${baseUrl}/dropbox_get_token`, data);
}

export {
  dropboxConfig,
  dropbox_get_items,
  dropbox_get_temporary_link,
  dropbox_upload,
  dropbox_get_token,
}
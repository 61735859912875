import React, { useContext } from 'react' 
import { useHistory } from 'react-router-dom'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import { sortArrayOfObjects } from '../../../utils'
import { GlobalContext } from '../../../context'

const MostPopularArticles = ({ articles, v2 = false }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const history = useHistory()
  
  // Article click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-article/${id}`)
  }
  
  return (
    <div className="most-popular-articles">
      <div className="most-popular-articles__head">
        <h2>{t('support.most_popular_articles')}</h2>
      </div>
      <div className="most-popular-articles__body">
        <ul>
          {sortArrayOfObjects(articles, 'readCount', 'asc').slice(0, 10).map((article, idx) => (
            <li key={idx}>
              <a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title[selectedLang] || 'Missing translation'}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MostPopularArticles
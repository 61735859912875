import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'

import { HelpContext, GlobalContext } from '../../../context'

const SearchForm = ({ type = 'default', v2 = false }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { articlesArr } = useContext(HelpContext)
  const [search, setSearch] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)
  const history = useHistory()
  let inputTimeout = null

  // On unmount
  useEffect(() => {
    if(inputTimeout) {
      clearTimeout(inputTimeout)
    }
  }, [])

  // Input change handler
  const inputChangeHandler = (e) => {
    const value = e.target.value
    setSearch(value)
    if(value !== '') {
      let arr = []
      // Check if search term is inside article title or content
      articlesArr.forEach(article => {
        let added = false
        if(article.title[selectedLang] && article.title[selectedLang].toLowerCase().includes(value.trim().toLowerCase())) {
          arr.push(article)
          added = true
        }
        if(!added && article.content[selectedLang]) {
          const blocks = article.content[selectedLang].blocks
          for(let i = 0; i < blocks.length; i++) {
            if(blocks[i].text && blocks[i].text.toLowerCase().includes(value.trim().toLowerCase())) {
              arr.push(article)
              break
            }
          }
        }
      })
      setSuggestions(arr)
      setShowSuggestions(true)
    }else {
      setSuggestions([])
      setShowSuggestions(false)
    }
  }

  // On form submit
  const submitHandler = (e) => {
    e.preventDefault()
    if(search.trim() === '') return 
    history.push(`/help-search-results?query=${search.trim()}`)
  }

  // Suggestion click handler
  const suggestionClickHandler = (e, id) => {
    e.preventDefault()
    if(inputTimeout) {
      clearTimeout(inputTimeout)
    }
    history.push(`/help-article/${id}`)
    
    setSearch('')
    setInputFocused(false)
  }

  // On input blur
  const blurClickHandler = () => {
    inputTimeout = setTimeout(() => setInputFocused(false), 500)
  }

  return (
    <form onSubmit={submitHandler} className={type === 'small' ? "help-search__form help-search__form--small" : "help-search__form"}>
      <input 
        type="text" 
        placeholder={type === 'small' ? t('dashboard.search_2') : t('support.search_knowledge_base')} 
        value={search} 
        onChange={inputChangeHandler} 
        onFocus={() => setInputFocused(true)}
        onBlur={blurClickHandler}
      />
      <button className="button button--primary" type="submit">{type === 'small' ? <SearchIcon /> : t('dashboard.search_2')}</button>

      {showSuggestions && inputFocused && <div className="search-suggestions">
        {suggestions.length > 0 ? 
          <ul>
            {suggestions.map((item, idx) => (
              <li key={idx}><a href="/#" onClick={(e) => suggestionClickHandler(e, item.id)}>{item.title[selectedLang] || 'Missing translation'}</a></li>
            ))}
          </ul>
          :
          <p>{t('support.no_results')}</p>
        }
      </div>}
    </form>
  )
}

export default SearchForm
import { createContext, useState, useRef } from 'react'

import { getTeamSortingData, getSelectedTeamFromLS } from '../../utils'

export const SharedDocumentsContext = createContext()

const selectedTeam = getSelectedTeamFromLS()
const sortingData = getTeamSortingData()

let defaultSort = { value: 'name', order: 'desc' }
if(selectedTeam && sortingData && sortingData[selectedTeam] && sortingData[selectedTeam].shared_documents) {
  defaultSort = { value: sortingData[selectedTeam].shared_documents.value || 'name', order: sortingData[selectedTeam].shared_documents.order || 'desc' }
}

const SharedDocumentsState = ({ children }) => {
  const [sharedDocuments, setSharedDocuments] = useState([])
  const [sharedDocumentsFetched, setSharedDocumentsFetched] = useState(false)
  const [sharedDocumentsSort, setSharedDocumentsSort] = useState(defaultSort)
  const [sharedDocumentsSelectedFilters, setSharedDocumentsSelectedFilters] = useState({
    search: ''
  })
  const sharedDocumentsUnsubscribeListener = useRef()

  // Reset state
  const resetState = () => {
    setSharedDocuments([])
    setSharedDocumentsFetched(false)
    if(sharedDocumentsUnsubscribeListener.current) {
      sharedDocumentsUnsubscribeListener.current()
    }
  }

  return (
    <SharedDocumentsContext.Provider
      value={{
        sharedDocuments,
        setSharedDocuments,
        sharedDocumentsFetched,
        setSharedDocumentsFetched,
        sharedDocumentsSort,
        setSharedDocumentsSort,
        resetSharedDocumentsState: resetState,
        sharedDocumentsUnsubscribeListener,
        sharedDocumentsSelectedFilters,
        setSharedDocumentsSelectedFilters,
      }}
    >
      {children}
    </SharedDocumentsContext.Provider>
  )
}

export default SharedDocumentsState
import React, { Fragment, cloneElement, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Content
const TooltipContent = ({ tooltipClass, content, position, tooltipPosition, duration, fixed, spaceSm }) => {
  const tooltipEl = useRef();
  const arrowEl = useRef()
  const targetEl = document.getElementById('root');

  useEffect(() => {
    const el = tooltipEl.current;
    const arrow = arrowEl.current

    if(el) {
      const pos = el.getBoundingClientRect();
      el.style.transitionDuration = duration + 'ms';
      if(fixed) {
        el.style.position = 'fixed';
      }

      setTimeout(() => {
        if(tooltipPosition === 'top') {
          el.style.top = `${position.top}px`;
          if((position.left - (pos.width / 2)) < 0) {
            el.style.left = `${position.left + ((pos.width / 2) - position.left)}px`
            arrow.style.left = `calc(50% - ${((pos.width / 2) - position.left)}px)`
          }else if(window.innerWidth < (position.left + pos.width / 2)) {
            el.style.left = `${position.left + window.innerWidth - (position.left + pos.width / 2)}px`
            arrow.style.left = `calc(50% - ${window.innerWidth - (position.left + pos.width / 2)}px)`
          }else {
            el.style.left = `${position.left}px`
          }
          el.style.transform = `translate(-50%, calc(-100% - ${spaceSm ? '10px' : '15px'})) scale(1)`;
        }
        else if(tooltipPosition === 'bottom') {
          el.style.top = `${position.top}px`;
          if((position.left - (pos.width / 2)) < 0) {
            el.style.left = `${position.left + ((pos.width / 2) - position.left)}px`
            arrow.style.left = `calc(50% - ${((pos.width / 2) - position.left)}px)`
          }else if(window.innerWidth < (position.left + pos.width / 2)) {
            el.style.left = `${position.left + window.innerWidth - (position.left + pos.width / 2)}px`
            arrow.style.left = `calc(50% - ${window.innerWidth - (position.left + pos.width / 2)}px)`
          }else {
            el.style.left = `${position.left}px`
          }
          el.style.transform = `translate(-50%, ${spaceSm ? '10px' : '15px'}) scale(1)`;
        }
        else if(tooltipPosition === 'left') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left - el.clientWidth}px`;
          el.style.transform = `translate(${spaceSm ? '-10px' : '-15px'}, -50%) scale(1)`;
        }
        else if(tooltipPosition === 'right') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(${spaceSm ? '10px' : '15px'}, -50%) scale(1)`;
        }
        else if(tooltipPosition === 'top-left') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(0, calc(-100% - ${spaceSm ? '10px' : '15px'})) scale(1)`;
        }
        else if(tooltipPosition === 'top-right') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(-100%, calc(-100% - ${spaceSm ? '10px' : '15px'})) scale(1)`;
        }
        else if(tooltipPosition === 'bottom-left') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(0, ${spaceSm ? '10px' : '15px'}) scale(1)`;
        }
        else if(tooltipPosition === 'bottom-right') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(-100%, ${spaceSm ? '10px' : '15px'}) scale(1)`;
        }
        else if(tooltipPosition === 'left-top') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left - el.clientWidth}px`;
          el.style.transform = `translate(${spaceSm ? '-10px' : '-15px'}, 0) scale(1)`;
        }
        else if(tooltipPosition === 'left-bottom') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left - el.clientWidth}px`;
          el.style.transform = `translate(${spaceSm ? '-10px' : '-15px'}, -100%) scale(1)`;
        }
        else if(tooltipPosition === 'right-top') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(${spaceSm ? '10px' : '15px'}, 0) scale(1)`;
        }
        else if(tooltipPosition === 'right-bottom') {
          el.style.top = `${position.top}px`;
          el.style.left = `${position.left}px`;
          el.style.transform = `translate(${spaceSm ? '10px' : '15px'}, -100%) scale(1)`;
        }

        el.style.opacity = '1';
      }, 20);
    }
  }, []);

  const output = <div className={tooltipClass} ref={tooltipEl}><div className="arrow" ref={arrowEl}></div><p>{content}</p></div>

  return targetEl ? ReactDOM.createPortal(output, targetEl) : output;
}

TooltipContent.propTypes = {
  content: PropTypes.any.isRequired,
  tooltipClass: PropTypes.string.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired,
  tooltipPosition: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired
}

// Tooltip
const CustomTooltip = ({ children, position = 'top', content, animationDuration = 500, fixed, hideTooltip = false, spaceSm, multiline = false }) => {
  const [elPosition, setElPosition] = useState({ top: 0, left: 0 });
  const [show, setShow] = useState(false);
  let tooltipClass = 'custom-tooltip';

  const getPosition = (e) => {
    const pos = e.currentTarget.getBoundingClientRect();

    if(position === 'top') {
      let top = fixed ? pos.top : pos.top + window.pageYOffset;
      setElPosition({ top, left: pos.left + (pos.width / 2) + window.pageXOffset });
    }
    else if(position === 'bottom') {
      setElPosition({ top: pos.bottom + window.pageYOffset, left: pos.left + (pos.width / 2) + window.pageXOffset });
    }
    else if(position === 'left') {
      let top = fixed ? pos.top + (pos.height / 2) : pos.top + (pos.height / 2) + window.pageYOffset;
      setElPosition({ top, left: pos.left + window.pageXOffset });
    }
    else if(position === 'right') {
      setElPosition({ top: pos.top + (pos.height / 2) + window.pageYOffset, left: pos.left + pos.width + window.pageXOffset });
    }
    else if(position === 'top-left') {
      let top = fixed ? pos.top : pos.top + window.pageYOffset;
      setElPosition({ top, left: pos.left + window.pageXOffset });
    }
    else if(position === 'top-right') {
      let top = fixed ? pos.top : pos.top + window.pageYOffset;
      setElPosition({ top, left: pos.left + pos.width + window.pageXOffset });
    }
    else if(position === 'bottom-left') {
      setElPosition({ top: pos.top + pos.height + window.pageYOffset, left: pos.left + window.pageXOffset });
    }
    else if(position === 'bottom-right') {
      setElPosition({ top: pos.top + pos.height + window.pageYOffset, left: pos.left + pos.width + window.pageXOffset });
    }
    else if(position === 'left-top') {
      setElPosition({ top: pos.top + window.pageYOffset, left: pos.left + window.pageXOffset });
    }
    else if(position === 'left-bottom') {
      setElPosition({ top: pos.bottom + window.pageYOffset, left: pos.left + window.pageXOffset });
    }
    else if(position === 'right-top') {
      setElPosition({ top: pos.top + window.pageYOffset, left: pos.left + pos.width + window.pageXOffset });
    }
    else if(position === 'right-bottom') {
      setElPosition({ top: pos.bottom + window.pageYOffset, left: pos.left + pos.width + window.pageXOffset });
    }

    setShow(true);
  }

  if(position === 'top') {
    tooltipClass += ' custom-tooltip--top';
  }
  else if(position === 'bottom') {
    tooltipClass += ' custom-tooltip--bottom';
  }
  else if(position === 'left') {
    tooltipClass += ' custom-tooltip--left';
  }
  else if(position === 'right') {
    tooltipClass += ' custom-tooltip--right';
  }
  else if(position === 'top-left') {
    tooltipClass += ' custom-tooltip--top-left';
  }
  else if(position === 'top-right') {
    tooltipClass += ' custom-tooltip--top-right';
  }
  else if(position === 'bottom-left') {
    tooltipClass += ' custom-tooltip--bottom-left';
  }
  else if(position === 'bottom-right') {
    tooltipClass += ' custom-tooltip--bottom-right';
  }
  else if(position === 'left-top') {
    tooltipClass += ' custom-tooltip--left-top';
  }
  else if(position === 'left-bottom') {
    tooltipClass += ' custom-tooltip--left-bottom';
  }
  else if(position === 'right-top') {
    tooltipClass += ' custom-tooltip--right-top';
  }
  else if(position === 'right-bottom') {
    tooltipClass += ' custom-tooltip--right-bottom';
  }
  if(multiline) {
    tooltipClass += ' custom-tooltip--multiline';
  }

  return(
    <Fragment>
      {!hideTooltip && show && <TooltipContent tooltipClass={tooltipClass} position={elPosition} content={content} tooltipPosition={position} duration={animationDuration} fixed={fixed} spaceSm={spaceSm} />}
      {cloneElement(children, {...children.props, onMouseOver: getPosition, onMouseLeave: () => setShow(false)})}
    </Fragment>
  );
}

CustomTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.any.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'left-top', 'left-bottom', 'right-top', 'right-bottom']),
  animationDuration: PropTypes.number,
  hideTooltip: PropTypes.bool
}

export default CustomTooltip;
import React, { useEffect, useContext, useState, useCallback } from 'react'

import { Modal, Input } from '../../new_ui'
import { GlobalContext } from '../../../context'
import { useContactsGroupsActions } from '../../../hooks'

const CreateEditContactGroupModal = ({ onClose, mode = 'create', selectedGroup, refreshContacts, setMainTitle }) => {
  const { t } = useContext(GlobalContext)
  const { createSingleContactGroup, updateSingleContactGroup } = useContactsGroupsActions()
  const [name, setName] = useState('')
  const [nameBeforeEdit, setNameBeforeEdit] = useState('')

  useEffect(() => {
    if(mode === 'edit' && selectedGroup) {
      setName(selectedGroup.name)
      setNameBeforeEdit(selectedGroup.name)
    }
  }, [mode, selectedGroup])

  // On submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    
    if(mode === 'create') {
      await createSingleContactGroup({ name, onClose, setMainTitle, refreshContacts })
    }else if(mode === 'edit') {
      await updateSingleContactGroup({ name, nameBeforeEdit, selectedGroup, onClose, setMainTitle, refreshContacts })
    }
  }, [mode, name, createSingleContactGroup, updateSingleContactGroup, refreshContacts, onClose, nameBeforeEdit, selectedGroup, setMainTitle])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return(
    <Modal 
      onClose={onClose} 
      title={mode === 'create' ? t('contacts.create_group') : t('contacts.edit_group')}
      onSave={handleSubmit}
      className="centered-head"
    >
      <div className="folder-modal">
        <Input 
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={t('contacts.group_name')}
        />
      </div>
    </Modal>
  )
}

export default CreateEditContactGroupModal
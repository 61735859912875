import { useContext, useEffect } from 'react'

import { Modal } from './'
import { GlobalContext } from '../../context'

const Alert = ({ onClose, text, onSubmit, deleteAlert = false, onOkClose, okBtnText = 'Ok', showOk = false, useEnterBtn = true, useEscBtn = true }) => {
  const { t } = useContext(GlobalContext)

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter' && onSubmit && useEnterBtn) {
        onSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [onSubmit, useEnterBtn])
  
  return(
    <Modal onClose={onClose} useEscBtn={useEscBtn} className="centered-head" hideFooter={true} title={text}>
      <div className="popup-v2">
        {!showOk ? <div className="popup-v2__foot">
          <button className="btn btn--outline" onClick={onClose}>{t('general.cancel')}</button>
          <button className={`btn ${deleteAlert ? 'btn--danger' : ''}`} onClick={onSubmit}>{"Confirmer"}</button>
        </div>
        : <div className="popup-v2__foot">
          <button className="btn" onClick={onOkClose ? onOkClose : onClose}>{okBtnText}</button>
        </div>}
      </div>
    </Modal>
  )
}

export default Alert
import firebase, { firebaseEnvDoc } from './firebase'
import { post_request, baseUrl } from './firestore'

const COLLECTION = 'approvals'

// Create new approval
const create_approval = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).add(data)
    return res.id
  } catch (err) {
    console.log('create approval error', err)
  }
}

// Fetch approvals
const fetch_approvals = async (teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('get approvals err', err)
  }
}

// Fetch approval by id
const fetch_approval_by_id = async (approvalId, teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).where(firebase.firestore.FieldPath.documentId(), '==', approvalId).get()
    if(res.docs.length === 0) {
      return {}
    }
    return {
      [res.docs[0].id]: { ...res.docs[0].data() }
    }
  } catch (err) {
    console.log('get approval err', err)
  }
}

// Fetch approvals without one approval which was fetched already
const fetch_other_approvals = async (teamId, fetchedApprovalId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).where(firebase.firestore.FieldPath.documentId(), '!=', fetchedApprovalId).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch approvals err', err)
  }
}

// Delete approval
const delete_approval = async (id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).delete()
  } catch (err) {
    console.log('delete approval error', err)
  }
}

// Update approval
const update_approval = async (id, data) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).update(data)
  } catch (err) {
    console.log('update approval error', err)
  }
}

// Send approval request
const send_approval_request = async (data) => {
  return await post_request(`${baseUrl}/send_approval_request`, data)
}

// Send approval request
const update_approval_steps = async (data) => {
  return await post_request(`${baseUrl}/update_approval_steps`, data)
}

export {
  create_approval,
  fetch_approvals,
  fetch_approval_by_id,
  fetch_other_approvals,
  delete_approval,
  update_approval,
  send_approval_request,
  update_approval_steps,
}
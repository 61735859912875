import React, { useState, useEffect, useContext } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';

import CustomTooltip from './CustomTooltip'
import CustomTimeInput from './CustomTimeInput';
import { dateTimeValueFormat } from '../../utils';
import { GlobalContext } from '../../context'

const CustomSelect = ({ options, onChange, active, statusEl, statusEl2, formEl, label, className, dropdownClassToCheck, showArrow, disabled = false, selectedDate, onDateChange, maxDate, onClick, fullWidth, onSetSelectedDate, thickBorder, invalid, animationClass, onFocus, onBlur, tooltip, tooltipPosition }) => {
  const { t } = useContext(GlobalContext)
  const [selected, setSelected] = useState(active ? active.label : '');
  const [selectedVal, setSelectedVal] = useState(active ? active.value : '');
  const [showDropdown, setShowDropdown] = useState(false);
  const [focused, setFocused] = useState(false)
  const [timeSelected, setTimeSelected] = useState(false);
  /* To exclude time that passed */
  const [startDate, setStartDate] = useState(new Date());

  const isSelectedDateInFuture = +startDate > Date.now();

  const date = new Date();
  let currentMins = date.getMinutes();
  let currentHour = date.getHours();
  if (isSelectedDateInFuture) {
    currentHour = 0;
    currentMins = 0;
  }
  /* END exclude time */

  useEffect(() => {
    if(options.length > 0) {
      const findActive = options.find(o => o.active);
      if(findActive) {
        setSelected(findActive ? findActive.label : '');
        setSelectedVal(findActive ? findActive.value : '');
      }
    }
  }, [options]);

  useEffect(() => {
    setSelected(active ? active.label : '');
    setSelectedVal(active ? active.value : '');
  }, [active]);

  // On focus/blur - when dropdown is opened/closed
  useEffect(() => {
    if(showDropdown && onFocus && !focused) {
      onFocus()
      setFocused(true)
    }
    if(!showDropdown && onBlur && focused) {
      onBlur()
      setFocused(false)
    }
  }, [showDropdown, onFocus, onBlur, focused])

  // On option change
  const optionChangeHandler = (option) => {
    setSelected(option.label);
    setSelectedVal(option.value);
    setShowDropdown(false);
    onChange(option);
    if(selectedDate) {
      onSetSelectedDate('');
    }
  }

  const dateChangeHandler = (date, option) => {
    setTimeSelected(true);
    if(!timeSelected) {
      date.setHours(9);
    }
    if(moment(date).isValid()) {
      const alertTime = moment(date, dateTimeValueFormat).clone().format(dateTimeValueFormat);
      setSelected(alertTime);
      setSelectedVal(alertTime);
      onDateChange(date);
      onChange({...option, label: alertTime, timestamp: moment(date, dateTimeValueFormat).clone().valueOf()});
      if(moment(date).format('MM/DD/YYYY') === moment(new Date()).format('MM/DD/YYYY')) {
        setStartDate(new Date());
      }else {
        setStartDate(date);
      }
    }
  }

  let selectClass = 'custom-select';

  if(formEl) {
    selectClass += ' custom-select--form';
  }
  if(disabled) {
    selectClass += ' custom-select--disabled';
  }
  if(fullWidth) {
    selectClass += ' custom-select--full-width';
  }
  if(className) {
    selectClass += ` ${className}`;
  }

  let selectedOptClass = 'custom-select__selected';

  if(statusEl) {
    selectedOptClass += ` ${selectedVal}`;
  }
  if(selectedVal === '') {
    selectedOptClass += ' not-selected';
  }
  if(showDropdown && !disabled) {
    selectedOptClass += ' opened';
  }
  if(thickBorder) {
    selectedOptClass += ' custom-select__selected--thick-border';
  }
  if(invalid) {
    selectedOptClass += ' custom-select__selected--error';
  }

  return(
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className={selectClass} onClick={onClick}>
        {formEl && label && <div className="custom-select__label">{label}</div>}
        <div className={selectedOptClass} onClick={() => setShowDropdown(!showDropdown)}>
          {statusEl ? tooltip ? <CustomTooltip content={tooltip} position={tooltipPosition}><span></span></CustomTooltip> : <span></span> : null}<div className="text">{selected}</div>
          {formEl && <div className="icon"><KeyboardArrowDownIcon /></div>}
          {showArrow && <div className="custom-select__selected_arrow"><ArrowDropDownIcon /></div>}
        </div>
        {showDropdown && !disabled && <div className="dropdown-el select-dropdown">
          <ul>
            {options.map((opt, i) => {
              let liClass = '';

              if(opt.active) {
                liClass = 'active';
              }
              if(statusEl || statusEl2) {
                liClass += ` ${opt.value}`;
              }
              if(opt.withBorder) {
                liClass += ' with-border';
              }

              if(opt.date) {
                return <li className="default" key={i}>
                  {opt.icon && <div className="icon">{opt.icon}</div>}
                  <div className="date-picker-field date-picker-field--small">
                    <DatePicker 
                      selected={selectedDate} 
                      onChange={(date) => dateChangeHandler(date, opt)} 
                      dateFormat="MMMM d, yyyy HH:mm"
                      locale="fr"
                      popperModifiers={{preventOverflow: { enabled: true, boundariesElement: 'window' }}} 
                      calendarClassName="dont-hide-dropdown"
                      // showTimeSelect
                      // timeIntervals={5}
                      placeholderText={t('general.select_alert_time')}
                      timeCaption={t('general.time')}
                      showTimeSelect
                      timeIntervals={60}
                      customTimeInput={<CustomTimeInput />}
                      minDate={new Date()}
                      maxDate={new Date(maxDate)}
                      minTime={new Date(new Date().setHours(currentHour, currentMins, 0, 0))}
                      maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                      startDate={startDate}
                      // onChangeRaw={(e) => e.preventDefault()}
                      // isClearable={selectedDate !== ''}
                    />
                  </div>
                </li>
              }

              return <li key={i} onClick={() => optionChangeHandler(opt)} className={liClass}>
                {(statusEl || statusEl2) && <span></span>}
                {opt.icon && <div className="icon">{opt.icon}</div>}
                {opt.label}
              </li>
            }
            )}
          </ul>
        </div>}
      </div>
    </ClickAwayListener>
  );
}

export default CustomSelect;
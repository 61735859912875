import React, { useState, useEffect, useContext, useCallback } from 'react'
import { BallotOutlined } from '@material-ui/icons'

import { Modal, Checkbox } from '../new_ui'
import Loader from '../UI/Loader'
import { fetchFeatures, updateFeature } from '../../services/db_features'
import { update_user } from '../../services/firestore'
import { GlobalContext, UserContext } from '../../context'
import { useSupportActions } from '../../hooks'

const VoteUpcomingFeaturesModal = ({ onClose }) => {
  const { newFeatures, setNewFeatures, featuresFetched, setFeaturesFetched, t } = useContext(GlobalContext)
  const { user, setUser } = useContext(UserContext)
  const { voteUpcomingFeatures } = useSupportActions()
  const [features, setFeatures] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  // Get features
  useEffect(() => {
    const fetchData = async () => {
      try {
        const arr = await fetchFeatures()
        if(Array.isArray(arr)) {
          setNewFeatures(arr)
          const formattedFeatures = arr.map(f => ({ name: f.description['en'], checked: false, id: f.id }))
          setFeatures(formattedFeatures)
          setFeaturesFetched(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if(!featuresFetched) {
      fetchData()
    }else {
      const formattedFeatures = newFeatures.map(f => ({ name: f.description['en'], checked: false, id: f.id }))
      setFeatures(formattedFeatures)
    }
  }, [featuresFetched, setFeaturesFetched, setNewFeatures, newFeatures])

  // Checkbox change handler
  const checkboxChangeHandler = (e, id) => {
    setFeatures(prev => {
      const current = prev.find(f => f.id === id)
      current.checked = e.target.checked 
      return prev.map(f => f.id === id ? current : f)
    })
  }

  // Submit votes handler
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    await voteUpcomingFeatures({ features, newFeatures, onClose })
  }, [features, newFeatures, onClose, voteUpcomingFeatures])

  // Check if already voted
  const alreadyVoted = () => {
    let voted = true
    const { featuresWhenVoted } = user
    let featureIds = []
    let myVotes = 0
    newFeatures.forEach(f => {
      featureIds.push(f.id)
      if(f.votes.includes(user.id)) {
        myVotes++
      }
    })
    
    // If no user votes show form
    if(myVotes === 0) {
      voted = false
    }

    if(voted && featuresWhenVoted) {
      if(featuresWhenVoted.length === featureIds.length || featuresWhenVoted.length > featureIds.length) {
        for(let i = 0; i < featureIds.length; i++) {
          // show form if there is new feature id 
          if(!featuresWhenVoted.includes(featureIds[i])) {
            voted = false
            break
          }
        }
      }else{
        voted = false
      }
    }

    return voted
  }

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return (
    <Modal 
      onClose={onClose}
      title={t('support.vote_features')}
      titleIcon={<span className="material-symbols-outlined">ballot</span>}
      saveBtnText={t('support.submit_your_vote')}
      saveBtnDisabled={features.filter(f => f.checked).length === 0}
      onSave={handleSubmit}
    >
      <div className="report-a-bug-v2">
        <div className="report-a-bug-v2__body">
          <p>{t('support.next_features')}</p>
          {!featuresFetched 
            ? <div className="loader-wrapper"><Loader primary normal small /></div>
            : !alreadyVoted() 
              ? 
              <form onSubmit={handleSubmit}>
                <ul className="features u-custom-scrollbar">
                  {features.map((feature, idx) => (
                    <li className="features__item" key={idx}>
                      <Checkbox 
                        name={feature.id}
                        checked={features.find(f => f.id === feature.id).checked} 
                        onChange={(e) => checkboxChangeHandler(e, feature.id)} 
                        label={feature.name}
                      />
                    </li>
                  ))}
                </ul>
              </form>
              :
              <p className="already-voted">{t('support.already_voted')}</p>
          }
        </div>
      </div>
    </Modal>
  )
}

export default VoteUpcomingFeaturesModal
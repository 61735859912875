import { useState, useCallback } from 'react';

const useForm = (initialState, validate) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const isValuesTouched = useCallback((name) => {
    for(let key in formData) {
      if(!formData[key].touched && key !== name) {
        return false;
      }
    }
    return true;
  }, [formData]);

  const setDataAndErrors = useCallback((data, name) => {
    setFormData(data);
    const errors = validate(data, false);
    if(Object.keys(errors).length === 0 && isValuesTouched(name)) {
      setIsValid(true);
    }else {
      setIsValid(false);
    }
    setErrors(errors);
  }, [validate, isValuesTouched]);

  const changeHandler = useCallback((e) => {
    let updatedData;

    if(e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.checked,
          touched: true
        }
      }
    }else if(e.target.tagName === 'INPUT' && e.target.type === 'file') {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: Object.keys(e.target.files).length > 0 ? e.target.files : {},
          touched: true
        }
      }
    }else if(e.target.closest('.file-upload')) {
      updatedData = {
        ...formData,
        [e.target.closest('.file-upload').querySelector('input').name]: {
          ...formData[e.target.closest('.file-upload').querySelector('input').name],
          value: Object.keys(e.dataTransfer.files).length > 0 ? e.dataTransfer.files : {},
          touched: true
        }
      }
    }else {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.value,
          touched: true
        }
      }
    }

    setDataAndErrors(updatedData, e.target.name);
  }, [setDataAndErrors, formData]);

  const customSelectChangeHandler = useCallback((name, value) => {
    const updatedData = {
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        touched: true
      }
    }
    
    setDataAndErrors(updatedData, name);
  }, [setDataAndErrors, formData]);

  return { formData, errors, changeHandler, customSelectChangeHandler, setErrors, isValid, setFormData };
}

export default useForm;
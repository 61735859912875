import { baseUrl, get_request, post_request, patch_request, delete_request } from './firestore'

// Fetch
const fetch_variables = async (team) => {
  return await get_request(`${baseUrl}/variables`, team.id, lawstudioCredentialsParams(team))
}

const fetch_categories = async (team) => {
  return await get_request(`${baseUrl}/categories`, team.id, lawstudioCredentialsParams(team))
}

// Create
const create_variable = async (team, data) => {
  return await post_request(`${baseUrl}/variables`, data, lawstudioCredentialsParams(team))
}

const create_category = async (team, data) => {
  return await post_request(`${baseUrl}/categories`, data, lawstudioCredentialsParams(team))
}

// Update
const update_variable = async (team, data, id) => {
  return await patch_request(`${baseUrl}/variables/${id}`, data, lawstudioCredentialsParams(team))
}

const update_category = async (team, data, id) => {
  return await patch_request(`${baseUrl}/categories/${id}`, data, lawstudioCredentialsParams(team))
}

// Delete
const delete_variable = async (team, id) => {
  return await delete_request(`${baseUrl}/variables/${id}`, lawstudioCredentialsParams(team))
}

const delete_category = async (team, id) => {
  try {
    return await delete_request(`${baseUrl}/categories/${id}`, lawstudioCredentialsParams(team))
  } catch(err) {
    console.log(err)
    return { error: err }
  }
}

const lawstudioCredentialsParams = (team) => {
  if(team.lawstudio_api_key && team.lawstudio_secret) {
    return `&lawstudio_api_key=${team.lawstudio_api_key}&lawstudio_secret=${team.lawstudio_secret}`
  } else {
    return ''
  }
}

export {
  fetch_variables,
  fetch_categories,
  create_variable,
  create_category,
  update_variable,
  update_category,
  delete_variable,
  delete_category,
}
import React, { createContext, useReducer, useState } from 'react';

import alertReducer from './alertReducer';
import { GET_ALERTS, SET_ALERTS, RESET_STATE } from '../types';
import { create_alert, fetch_alerts, update_alert, delete_alert } from '../../services/firestore';
import { sortArrayOfObjects } from '../../utils';

export const AlertContext = createContext();

const AlertsState = ({ children }) => {
  const initialState = {
    alerts: [],
    alertsFetched: false,
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // Fetch alerts
  const fetchAlerts = async (teamId) => {
    try {
      const res = await fetch_alerts(teamId, true);
      if(res.error) {
        dispatch({
          type: GET_ALERTS,
          payload: []
        })
        throw new Error(res.error?.message || 'alerts cannot be fetched')
      }
      const arr = [];
      for(let id in res) {
        const alert = {...res[id]};
        alert.id = id;
        arr.push(alert);
      }
      const sortedArr = sortArrayOfObjects(arr, 'dateTimestamp', 'desc');
      dispatch({
        type: GET_ALERTS,
        payload: sortedArr
      });
    }catch(err) {
      console.log('ALERTS err', err);
    }
  }

  // Set alerts
  const setAlerts = (alerts) => {
    dispatch({
      type: SET_ALERTS,
      payload: alerts
    });
  }

  // Create alert 
  const createAlert = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await create_alert(data)
      const arr = [...state.alerts]
      arr.push({ ...data, id: res.id })
      setAlerts(sortArrayOfObjects(arr, 'dateTimestamp', 'desc'))
      onSuccess(res.id)
    }catch(err) {
      console.log('CREATE ALERT err', err)
      onError(err)
    }
  }

  // Update alert 
  const updateAlert = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const alerts = [...state.alerts];
      await update_alert(data, id);
      const alertToUpdate = alerts.find(a => a.id === id);
      const updatedAlert = {
        ...alertToUpdate,
        ...data
      };
      const updatedAlerts = [...alerts].map(a => a.id === id ? updatedAlert : a);
      setAlerts(updatedAlerts);
      onSuccess();
    }catch(err) {
      console.log('UPDATE ALERT err', err);
      onError(err);
    }
  }

  // Delete alert 
  const deleteAlert = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const alerts = [...state.alerts];
      await delete_alert(id);
      const updatedAlerts = [...alerts].filter(t => t.id !== id);
      setAlerts(updatedAlerts);
      onSuccess();
    } catch (err) {
      console.log('DELETE ALERT err', err);
      onError(err);
    }
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, alertsFetched: true } : initialState,
      payload: initialState
    });
  }

  // Get alert by id
  const getAlertById = (id) => {
    const alert = [...state.alerts].find(a => a.id === id);
    return alert;
  }

  // Check if document already have alert
  const documentHaveAlert = (docId) => {
    const alerts = state.alerts;
    return alerts.find(a => a.documentId === docId);
  }

  return(
    <AlertContext.Provider value={{
      alerts: state.alerts,
      alertsFetched: state.alertsFetched,
      createAlert,
      fetchAlerts,
      updateAlert,
      deleteAlert,
      resetAlertState: resetState,
      getAlertById,
      documentHaveAlert,
    }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertsState;
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CheckCircleOutlineOutlined, AutorenewOutlined, LinkOffOutlined, LinkOutlined, DeleteForeverOutlined } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import Alert from '../../UI/Alert'
import Button from '../../UI/Button'
import Modal from '../../UI/Modal'
import ResponseLoader from '../../UI/ResponseLoader'
import { NotificationContext, SharedTemplatePagesContext, GlobalContext } from '../../../context'
import { update_shared_template, delete_shared_template, create_shared_template } from '../../../services/shared_templates'

const TemplateSharedSuccessfullyModal = ({ onClose, templateId, alreadyShared = false, sharedTemplate, onSetSharedTemplate, onSetSharedTemplateId }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { singleSharedTemplatesFetched, updateSingleSharedTemplate, deleteSingleSharedTemplate, renewSingleTemplateUrl } = useContext(SharedTemplatePagesContext)
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const history = useHistory()

  // On view template
  const handleViewTemplate = () => {
    history.push(`/shared-template/${templateId}`)
  }

  // On copy url
  const handleCopyUrl = () => {
    const input = document.createElement('textarea')
    input.innerHTML = `${window.location.origin}/shared-template/${templateId}`
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setNotification({ msg: t('share_email.link_copied'), type: 'info' })
  }

  // On shared template renew
  const handleSharedTemplateRenew = async (e) => {
    e.preventDefault()
    if(!sharedTemplate) return
    
    let data = {
      ...sharedTemplate,
      meta: {
        ...sharedTemplate.meta,
        updated: Date.now()
      },
      renewed: true,
    }
    if(data.id) delete data.id
    setShowResponseLoader(true)

    if(singleSharedTemplatesFetched) {
      renewSingleTemplateUrl(sharedTemplate, (id) => {
        document.body.click()
        if(id) {
          onSetSharedTemplateId(id)
          onSetSharedTemplate({ ...data, id })
          setNotification({ msg: t('share_template.renewed_successfully'), type: 'success' })
          setShowResponseLoader(false)
        }
      }, (err) => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
      })
    }else {
      try {
        document.body.click()
        // delete current template
        await delete_shared_template(sharedTemplate.id)
        // create new template to change id so that url is changed
        const id = await create_shared_template(data)
        if(id) {
          onSetSharedTemplateId(id)
          onSetSharedTemplate({ ...data, id })
          setNotification({ msg: t('share_template.renewed_successfully'), type: 'success' })
          setShowResponseLoader(false)
        }
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowResponseLoader(false)
      }
    }

  }

  // On shared template disable
  const handleSharedTemplateDisable = async (e) => {
    e.preventDefault()
    if(!sharedTemplate) return

    if(singleSharedTemplatesFetched) {
      updateSingleSharedTemplate({ disabled: !sharedTemplate.disabled }, templateId, () => {
        onSetSharedTemplate({ ...sharedTemplate, disabled: !sharedTemplate.disabled })
        setNotification({ msg: t(`share_template.${sharedTemplate.disabled ? 'enabled' : 'disabled'}_successfully`), type: 'success' })
      }, (err) => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }else {
      try {
        await update_shared_template(templateId, { disabled: !sharedTemplate.disabled })
        onSetSharedTemplate({ ...sharedTemplate, disabled: !sharedTemplate.disabled })
        setNotification({ msg: t(`share_template.${sharedTemplate.disabled ? 'enabled' : 'disabled'}_successfully`), type: 'success' })
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }

  // On open template delete alert
  const handleOpenTemplateDeleteAlert = (e) => {
    e.preventDefault()
    setShowDeleteTemplateAlert(true)
    document.body.click()
  }

  // On shared template delete
  const handleSharedTemplateDelete = async (e) => {
    e.preventDefault()
    
    setDeleting(true)
    if(singleSharedTemplatesFetched) {
      deleteSingleSharedTemplate(templateId, () => {
        setDeleting(false)
        setNotification({ msg: t('share_template.deleted_successfully'), type: 'success' })
        onClose()
      }, () => {
        setDeleting(false)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      })
    }else {
      try {
        await delete_shared_template(templateId)
        setNotification({ msg: t('share_template.deleted_successfully'), type: 'success' })
        setDeleting(false)
        onClose()
      } catch (err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setDeleting(false)
      }
    }
  }

  return (
    <Modal onClose={onClose} medium2>
      <div className="template-shared-modal">
        <div className="template-shared-modal__icon">
          <CheckCircleOutlineOutlined />
        </div>
        <div className="template-shared-modal__message">
          <p>{alreadyShared ? t('share_template.already_shared') : t('share_template.successfully_shared')}</p>
        </div>
        <div className="template-shared-modal__actions">
          <Button text={t('share_template.view')} onButtonClick={handleViewTemplate} primary />
          <Button text={t('share_template.copy_url')} onButtonClick={handleCopyUrl} primary />
          <ActionsDropdown
            trigger={<a href="/#" className="link">{t('share_template.more_options')}</a>}
            dropdownClass="more-actions more-actions--3"
            className="link-wrapper"
            hideHeader
            noPadding
            width={200}
          >
            <ul className="dropdown">
              <li>
                <a href="/#" onClick={handleSharedTemplateRenew}><AutorenewOutlined /> {t('share_template.renew_url')}</a>
              </li>
              <li>
                <a href="/#" onClick={handleSharedTemplateDisable}>
                  {sharedTemplate?.disabled 
                    ? (<><LinkOutlined /> {t('share_template.enable_link')}</>)
                    : (<><LinkOffOutlined /> {t('share_template.disable_link')}</>)
                  }
                </a>
              </li>
              <li>
                <a href="/#" className="delete" onClick={handleOpenTemplateDeleteAlert}><DeleteForeverOutlined /> {t('general.delete')}</a>
              </li>
            </ul>
          </ActionsDropdown>
        </div>
      </div>

      {showResponseLoader && <ResponseLoader />}
      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text={t('share_template.delete_template')}
        onSubmit={handleSharedTemplateDelete}
        loading={deleting}
        deleteAlert
      />}
    </Modal>
  )
}

export default TemplateSharedSuccessfullyModal
import React, { useEffect, useState, useContext, memo } from 'react'

import Loader from '../../UI/Loader'
import TasksList from '../TasksList'

import { TaskContext, TeamContext, GlobalContext } from '../../../context'

const DocumentDetailSPTasks = ({ docId, doc, docTasks, onSetEditStartTime, onStoreEditTime, onSetView }) => { 
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { tasksFetched, fetchTasks } = useContext(TaskContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fetch tasks if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!tasksFetched) {
        // console.log('fetch tasks')
        fetchTasks(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, tasksFetched, fetchTasks])

  return(
    <div className="document-detail-sp-section">
      <h4 className="with-border">{t('dashboard.tasks')}</h4>
      {!tasksFetched 
        ? (
          <div className="loader-wrapper loader-wrapper--no-padding">
            <Loader small normal />
          </div>
        ) : (
        <div className="document-detail-sp-section__fields">
          <TasksList 
            showCompletedTasks 
            docId={docId} 
            currentDocument={doc} 
            view="document"
            tasks={docTasks} 
            onFocus={() => onSetEditStartTime(Date.now())} 
            onBlur={onStoreEditTime}
            onSetView={onSetView}
            fromSidePanel={true}
          />
        </div>
        )}
    </div>
  );
}

export default memo(DocumentDetailSPTasks);
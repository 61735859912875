import { useContext } from 'react'

import { VariablesContext, GlobalContext, LoaderContext, TeamContext, UserContext, NotificationContext } from '../context'
import { delete_variable, create_variable } from '../services/variables_and_categories'
import { update_user, update_team } from '../services/firestore'

const useVariablesActions = () => {
  const { deleteMultipleVars, fetchVariables } = useContext(VariablesContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { selectedTeam, setSelectedTeam } = useContext(TeamContext)
  const { user, setUser } = useContext(UserContext)
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)

  // Export
  const exportVariables = async ({ selectedVars, setSelectedVars }) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('variables.exporting'))

    let exportVariables = []
    for(let i in selectedVars) {
      let variable = selectedVars[i]
      let eVariable = {
        variable: variable.variable,
        display_name: variable.display_name || '',
        tooltip: variable.tooltip || '',
        question: variable.question || '',
        type: variable.type || '',
        category: variable.category || ''
      }
      if(variable.options) {
        eVariable.options = variable.options
      }
      exportVariables.push(eVariable)
    }
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportVariables, null, 2))
    var a = document.createElement('a') 
    a.setAttribute("href", dataStr)
    a.setAttribute("download", `${t('variables.export')} (${Date.now()}).json`)
    a.click()
    a.remove()
    
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
    setSelectedVars([])
  }

  // Delete
  const deleteVariables = async ({ selectedVars, setShowDeleteVarsAlert, setSelectedVars }) => {
    let promises = []
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('variables.deleting'))
    let varsToDelete = []
    let varsToUnlink = []
    selectedVars.forEach(variable => {
      varsToDelete.push(variable.id)
      varsToUnlink.push(variable.variable)
      promises.push(delete_variable(selectedTeam, variable.id))
    })
    // Unlink variables if they are linked to profile or contact
    if(varsToUnlink.length > 0) {
      let isVariableLinkedWithProfile = false 
      if(user && user.profile_linked_vars && Object.keys(user.profile_linked_vars).length > 0) {
        let profileLinkedVars = {...user.profile_linked_vars}
        let values = Object.values(profileLinkedVars)
        let keys = Object.keys(profileLinkedVars)
        for(let i = 0; i < values.length; i++) {
          if(varsToUnlink.includes(values[i])) {
            delete profileLinkedVars[keys[i]]
            isVariableLinkedWithProfile = true
          }
        }
        if(isVariableLinkedWithProfile) { // update user profile_linked_vars prop
          setUser({ ...user, profile_linked_vars: profileLinkedVars })
          update_user({ profile_linked_vars: profileLinkedVars }, user.id)
        }
      }

      let isVariableLinkedWithContact = false 
      if(selectedTeam && selectedTeam.contacts_linked_variables && Object.keys(selectedTeam.contacts_linked_variables).length > 0) {
        let values = Object.values(selectedTeam.contacts_linked_variables)
        let keys = Object.keys(selectedTeam.contacts_linked_variables)
        let updates = JSON.parse(JSON.stringify(selectedTeam.contacts_linked_variables))
        values.forEach((vals, idx) => {
          if(vals) {
            let innnerValues = Object.values(vals)
            let innnerKeys = Object.keys(vals)
            let group = {...updates[keys[idx]]}
            innnerValues.forEach((val, i) => {
              if(varsToUnlink.includes(val)) {
                delete group[innnerKeys[i]]
                updates[keys[idx]] = group
                isVariableLinkedWithContact = true
              }
            })
          }
        })
        if(isVariableLinkedWithContact) { // update selectedTeam contacts_linked_variables prop
          setSelectedTeam({ ...selectedTeam, contacts_linked_variables: updates })
          update_team({ contacts_linked_variables: updates }, selectedTeam.id)
        }
      }
    }
    
    await Promise.all(promises)
    deleteMultipleVars(varsToDelete)
    setShowDeleteVarsAlert(false)
    setSelectedVars([])
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Import variables
  const importVariables = async ({ data, onClose }) => {
    let success = false
    // validate
    if(data.trim() === '') {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }

    // import variables
    if(isJson(data)) {
      const variables = JSON.parse(data)
      if(variables.length > 0) {
        let promises = []
        let error = false
        for(let i = 0; i < variables.length; i++) {
          let { variable, tooltip, category, type, display_name, options } = variables[i]
          let requiredProps = []
          if(!variable) requiredProps.push('variable')
          if(!tooltip) requiredProps.push('tooltip')
          if(!category) requiredProps.push('category')
          if(!type) requiredProps.push('type')
          if(!display_name) requiredProps.push('display_name')
          if(type && (type === 'select' || type === 'multiselect') && (!options || options.length === 0)) requiredProps.push('options')

          if(requiredProps.length > 0) {
            error = true
            setNotification({ msg: `${t('variables.properties_required', { i })} ${requiredProps.join(',')}`, type: 'danger' })
            break
          }

          if((type === 'select' || type === 'multiselect') && options.length === 0) {
            error = true
            setNotification({ msg: t('variables.add_at_least_one_option', { i }), type: 'danger' })
            break
          }

          if((type === 'select' || type === 'multiselect') && options.length > 0) {
            let values = []
            let innerError = false
            for(let j = 0; j < options.length; j++) {
              if(options[j].label === undefined || options[j].value === undefined) {
                setNotification({ msg: t('variables.label_value_in_array_required', { i }), type: 'danger' })
                innerError = true
                break
              }
              if(options[j].label.trim() === '' || options[j].value.trim() === '') {
                setNotification({ msg: t('variables.label_value_in_array_empty', { i }), type: 'danger' })
                innerError = true
                break
              }
              values.push(options[j].value.trim())
            }

            if(innerError) {
              break
            }else {
              if(values.length > 0) {
                const duplicates = values.some((item, index) => index !== values.indexOf(item))
                if(duplicates) {
                  error = true
                  setNotification({ msg: t('variables.option_values_in_array_unique', { i }), type: 'danger' })
                  break
                }
              }
            }
          }
        }

        if(error) return

        setShowGlobalResponseLoader(true)
        variables.forEach(variable => promises.push(create_variable(selectedTeam, variable)))

        try {
          await Promise.all(promises)
          await fetchVariables(selectedTeam) // TODO - create updateMultipleVars in context and use it instead of fetching all vars again
          success = true
          onClose()
        } catch (err) {
          console.log(err)
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        }
        setShowGlobalResponseLoader(false)
      }
    }else {
      setNotification({ msg: t('variables.invalid_json_data'), type: 'danger' })
    }
    return success
  }

  // Check if input is json 
  function isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  return {
    exportVariables,
    deleteVariables,
    importVariables,
  }
}

export default useVariablesActions
import {
  useContext,
  useState,
  useEffect,
  useCallback,
  forwardRef,
} from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
} from 'recharts';
import moment from 'moment';

import { WidgetDropdown } from '../';
import { CustomDatepicker, ChartTooltip } from '../../new_ui';
import {
  GlobalContext,
  DocumentsContext,
  TeamContext,
  UserContext,
} from '../../../context';
import {
  sortArrayOfObjects,
  getLastXDaysArr,
  getAllMonths,
  getDatesBetween,
  getDaysInMonthArr,
} from '../../../utils';

let dateFormat = 'MMM DD';

const DocsWidget = forwardRef(
  ({ style, dragging, isSorting, ...props }, ref) => {
    const { t } = useContext(GlobalContext);
    const { documentsArr, documentsLoaded } = useContext(DocumentsContext);
    const { fetchCollections } = useContext(TeamContext);
    const { widgetsInfo } = useContext(UserContext);
    const [docChartData, setDocChartData] = useState(
      getLastXDaysArr(30).map((d, i) => ({
        label: d.format(dateFormat),
        name: d.format(dateFormat),
        count: 0,
        show: d.day() === 1,
      }))
    );
    const [selectedDate, setSelectedDate] = useState(['last_30_days']);
    const [docEditTimeChartData, setDocEditTimeChartData] = useState([]);
    const [numberOfDocuments, setNumberOfDocuments] = useState(0);
    const [docsDataAdded, setDocsDataAdded] = useState(false);

    // Update chart data on documents change
    useEffect(() => {
      if (documentsLoaded) {
        let arr = [...docChartData];
        let timeDocsArr = [...docChartData];
        let tasksArr = [];
        let numOfDocs = 0;
        const filteredDocuments = [];

        for (let i = 0; i < documentsArr.length; i++) {
          const doc = documentsArr[i];
          const createdAt = moment(doc.meta.created).format(dateFormat);
          const find = arr.find((d) => d.name === createdAt);
          if (find) {
            const updatedObj = { ...find };
            const indexOfObj = arr.indexOf(find);
            updatedObj.count = updatedObj.count + 1;
            numOfDocs++;
            arr[indexOfObj] = updatedObj;
            filteredDocuments.push(doc);
            if (doc.total_edit_time) {
              const find2 = timeDocsArr.find((d) => d.name === createdAt);
              if (find2) {
                find2.label = doc.name;
                find2.time = doc.total_edit_time;
                find2.time_label = doc.total_edit_time / 1000 / 60;
              }
            }
          }
          if (doc.tasks && doc.tasks.length > 0) {
            tasksArr.push({ label: doc.name, tasks: doc.tasks.length });
          }
        }

        setDocChartData(arr);
        setDocEditTimeChartData(timeDocsArr.filter((d) => d.time));
        setNumberOfDocuments(numOfDocs);
        setDocsDataAdded(true);
      }
      // eslint-disable-next-line
    }, [documentsLoaded, documentsArr]);

    // Reset chart data when team is changed
    useEffect(() => {
      if (fetchCollections) {
        setDocChartData(
          getLastXDaysArr(30).map((d, i) => ({
            label: d.format(dateFormat),
            name: d.format(dateFormat),
            count: 0,
            show: d.day() === 1,
          }))
        );
      }
    }, [fetchCollections]);

    // On date filter change - documents
    const dateFilterChangeDocuments = useCallback(
      (date) => {
        if (!date) return;

        setSelectedDate(date);
        if (date.length === 1) {
          const dateFilter = date[0];
          let daysFilter = null;
          let monthFilter = false;
          let yearFilter = false;
          let lastYearFilter = false;

          if (dateFilter === 'last_7_days') {
            daysFilter = 7;
          } else if (dateFilter === 'last_30_days') {
            daysFilter = 30;
          } else if (dateFilter === 'last_90_days') {
            daysFilter = 90;
          }

          // Days filter
          if (daysFilter) {
            let arr;
            let timeDocsArr;
            if (daysFilter === 7) {
              // if last 7 days selected
              arr = getLastXDaysArr(daysFilter).map((d, i) => {
                return {
                  name: d.format(dateFormat),
                  label: d.format(dateFormat),
                  count: 0,
                  show: true,
                };
              });
            } else if (daysFilter === 30) {
              // if last 30 days selected
              arr = getLastXDaysArr(daysFilter).map((d, i) => {
                return {
                  name: d.format(dateFormat),
                  label: d.format(dateFormat),
                  count: 0,
                  show: d.day() === 1,
                };
              });
            } else if (daysFilter === 90) {
              // if last 90 days selected
              const last90daysArr = getLastXDaysArr(daysFilter);
              // separate by weeks
              arr = last90daysArr
                .filter((d, i) => d.day() === 1)
                .map((d, i) => {
                  const label = d.clone().format(dateFormat);
                  const labelEndDate = d
                    .clone()
                    .add(6, 'days')
                    .format(dateFormat);
                  const startDate = d.clone();
                  const endDate = d.clone().add(6, 'days');
                  return {
                    name: label,
                    label: `${label} - ${labelEndDate}`,
                    startDate: startDate,
                    endDate: endDate,
                    count: 0,
                    show: true,
                  };
                });

              // add new item at the beginning of the array if first day is not on monday
              if (last90daysArr[0].isBefore(arr[0].startDate)) {
                const label = last90daysArr[0].clone().format(dateFormat);
                const labelEndDate = arr[0].startDate
                  .clone()
                  .subtract(1, 'days')
                  .format(dateFormat);
                const startDate = last90daysArr[0].clone();
                const endDate = arr[0].startDate.clone().subtract(1, 'days');
                arr.unshift({
                  name: label,
                  label: `${label} - ${labelEndDate}`,
                  startDate: startDate,
                  endDate: endDate,
                  count: 0,
                  show: false,
                });
              }

              // update last item of the array if endDate is after current date
              if (arr[arr.length - 1].endDate.isAfter(moment())) {
                const lastEl = arr.pop();
                lastEl.endDate = moment();
                if (lastEl.startDate.isSame(moment(), 'day')) {
                  lastEl.label = `${lastEl.startDate
                    .clone()
                    .format(dateFormat)}`;
                } else {
                  lastEl.label = `${lastEl.startDate
                    .clone()
                    .format(dateFormat)} - ${moment().format(dateFormat)}`;
                }
                arr.push(lastEl);
              }
              let timeDocsArr = getLastXDaysArr(daysFilter).map((d, i) => {
                return {
                  name: d.format(dateFormat),
                  label: d.format(dateFormat),
                  count: 0,
                  show: d.day() === 1,
                };
              });

              // update chart data and number of documents for last 90 days
              let numOfDocs = 0;
              documentsArr.forEach((doc) => {
                const createdAt = moment(doc.meta.created);
                const find = arr.find((d, i) => {
                  return createdAt.isBetween(
                    d.startDate,
                    d.endDate,
                    undefined,
                    '[]'
                  );
                });
                if (find) {
                  find.count = find.count + 1;
                  numOfDocs++;
                }
                if (doc.total_edit_time) {
                  const find2 = timeDocsArr.find(
                    (d) =>
                      d.name === moment(doc.meta.created).format(dateFormat)
                  );
                  if (find2) {
                    find2.label = doc.name;
                    find2.time = doc.total_edit_time;
                    find2.time_label = doc.total_edit_time / 1000 / 60;
                  }
                }
              });
              setDocChartData(arr);
              setDocEditTimeChartData(timeDocsArr.filter((d) => d.time));
              setNumberOfDocuments(numOfDocs);
              return;
            }

            timeDocsArr = [...arr];

            // update chart data and number of documents for last 7 or last 30 days
            let numOfDocs = 0;
            documentsArr.forEach((doc) => {
              const createdAt = moment(doc.meta.created).format(dateFormat);
              const find = arr.find((d) => {
                return d.name === createdAt;
              });
              if (find) {
                find.count = find.count + 1;
                numOfDocs++;
              }
              if (doc.total_edit_time) {
                const find2 = timeDocsArr.find((d) => d.name === createdAt);
                if (find2) {
                  find2.label = doc.name;
                  find2.time = doc.total_edit_time;
                  find2.time_label = doc.total_edit_time / 1000 / 60;
                }
              }
            });
            setDocChartData(arr);
            setDocEditTimeChartData(timeDocsArr.filter((d) => d.time));
            setNumberOfDocuments(numOfDocs);
            return;
          }

          // Month filter
          if (dateFilter === 'this_month') {
            monthFilter = true;
          }

          if (monthFilter) {
            const arr = getDaysInMonthArr().map((d, i) => {
              const showDay = d.day() === 1;
              return {
                name: d.format(dateFormat),
                label: d.format(dateFormat),
                count: 0,
                show: showDay,
              };
            });
            let timeDocsArr = [...arr];
            let numOfDocs = 0;
            documentsArr.forEach((doc) => {
              const createdAt = moment(doc.meta.created).format(dateFormat);
              const find = arr.find((d) => {
                return d.name === createdAt;
              });
              if (find) {
                find.count = find.count + 1;
                numOfDocs++;
              }
              if (doc.total_edit_time) {
                const find2 = timeDocsArr.find((d) => d.name === createdAt);
                if (find2) {
                  find2.label = doc.name;
                  find2.time = doc.total_edit_time;
                  find2.time_label = doc.total_edit_time / 1000 / 60;
                }
              }
            });
            setDocChartData(arr);
            setDocEditTimeChartData(timeDocsArr.filter((d) => d.time));
            setNumberOfDocuments(numOfDocs);
            return;
          }

          // Years filter
          if (dateFilter === 'this_year') {
            yearFilter = true;
          }
          if (dateFilter === 'last_year') {
            lastYearFilter = true;
          }

          if (yearFilter || lastYearFilter) {
            const arr = [...getAllMonths()].map((m, i) => {
              const idx = i + 1;
              const show = idx % 3 === 1;
              const year = yearFilter
                ? moment().year().toString()
                : moment().subtract(1, 'year').year().toString();
              return {
                name: moment(m).clone().format('MMM'),
                label: moment(m).clone().format('MMM'),
                count: 0,
                show: show,
                startDate: moment().set({
                  month: i,
                  year: year,
                  date: '01',
                  hour: '00',
                  minutes: '00',
                  seconds: '00',
                }),
                endDate: moment().set({
                  month: i,
                  year: year,
                  date: moment(m).clone().endOf('month').date(),
                  hour: '23',
                  minutes: '59',
                  seconds: '59',
                }),
              };
            });
            let timeDocsArr = [];
            let timeDocsLastYearArr = [];
            let numOfDocs = 0;
            documentsArr.forEach((doc) => {
              const createdAt = moment(doc.meta.created);
              const find = arr.find((d) => {
                return (
                  createdAt.isSameOrAfter(d.startDate) &&
                  createdAt.isSameOrBefore(d.endDate)
                );
              });
              if (find) {
                find.count = find.count + 1;
                numOfDocs++;
              }

              if (
                doc.total_edit_time &&
                yearFilter &&
                +moment(doc.meta.created).format('YYYY') ===
                  new Date().getFullYear()
              ) {
                timeDocsArr.push({
                  created: doc.meta.created,
                  label: doc.name,
                  time: doc.total_edit_time,
                  time_label: doc.total_edit_time / 1000 / 60,
                });
              } else if (
                doc.total_edit_time &&
                lastYearFilter &&
                moment(doc.meta.created).format('YYYY') ===
                  new Date().getFullYear() - 1
              ) {
                timeDocsLastYearArr.push({
                  created: doc.meta.created,
                  label: doc.name,
                  time: doc.total_edit_time,
                  time_label: doc.total_edit_time / 1000 / 60,
                });
              }
            });
            setDocChartData(arr);
            setDocEditTimeChartData(
              yearFilter
                ? sortArrayOfObjects(timeDocsArr, 'created', 'desc')
                : sortArrayOfObjects(timeDocsLastYearArr, 'created', 'asc')
            );
            setNumberOfDocuments(numOfDocs);
            return;
          }
        } else if (date.length === 2) {
          // if 2 dates are selected - range
          const daysArr = getDatesBetween(date[0], date[1]);

          let arr;
          if (daysArr.length < 31) {
            arr = daysArr.map((d, i) => {
              return {
                name: d.clone().format(dateFormat),
                label: d.clone().format(dateFormat),
                count: 0,
                show: daysArr.length < 15 ? true : d.day() === 1,
              };
            });

            let timeDocsArr = [...arr];

            // update chart data and number of documents
            let numOfDocs = 0;
            documentsArr.forEach((doc) => {
              const createdAt = moment(doc.meta.created).format(dateFormat);
              const find = arr.find((d) => {
                return d.name === createdAt;
              });
              if (find) {
                find.count = find.count + 1;
                numOfDocs++;
              }
              if (doc.total_edit_time) {
                const find2 = timeDocsArr.find((d) => d.name === createdAt);
                if (find2) {
                  find2.label = doc.name;
                  find2.time = doc.total_edit_time;
                  find2.time_label = doc.total_edit_time / 1000 / 60;
                }
              }
            });
            setDocChartData(arr);
            setDocEditTimeChartData(timeDocsArr.filter((d) => d.time));
            setNumberOfDocuments(numOfDocs);
            return;
          } else if (daysArr.length > 30 && daysArr.length < 121) {
            // separate by weeks
            arr = daysArr
              .filter((d, i) => d.day() === 1)
              .map((d, i) => {
                const label = d.clone().format(dateFormat);
                const labelEndDate = d
                  .clone()
                  .add(6, 'days')
                  .format(dateFormat);
                console.log(labelEndDate);
                const startDate = d.clone();
                const endDate = d.clone().add(6, 'days');
                return {
                  name: label,
                  label: `${label} - ${labelEndDate}`,
                  startDate: startDate,
                  endDate: endDate,
                  count: 0,
                  show: true,
                };
              });

            // add new item at the beginning of the array if first day is not on monday
            if (daysArr[0].isBefore(arr[0].startDate)) {
              const label = daysArr[0].clone().format(dateFormat);
              const labelEndDate = arr[0].startDate
                .clone()
                .subtract(1, 'days')
                .format(dateFormat);
              const startDate = daysArr[0].clone();
              const endDate = arr[0].startDate.clone().subtract(1, 'days');
              arr.unshift({
                name: label,
                label: `${label} - ${
                  label !== labelEndDate ? labelEndDate : ''
                }`,
                startDate: startDate,
                endDate: endDate,
                count: 0,
                show: false,
              });
            }

            // change last item of the array if end date is not current date
            if (arr[arr.length - 1].endDate.isAfter(moment())) {
              const lastEl = arr.pop();
              lastEl.endDate = moment(date[1]);
              lastEl.label = `${lastEl.startDate
                .clone()
                .format(dateFormat)} - ${
                !lastEl.startDate.isSame(lastEl.endDate)
                  ? lastEl.endDate.format(dateFormat)
                  : ''
              }`;
              arr.push(lastEl);
            }

            let timeDocsArr = [];

            // update chart data and number of documents
            let numOfDocs = 0;
            documentsArr.forEach((doc) => {
              const createdAt = moment(doc.meta.created);
              const find = arr.find((d, i) => {
                return createdAt.isBetween(
                  d.startDate,
                  d.endDate,
                  undefined,
                  '[]'
                );
              });
              if (find) {
                find.count = find.count + 1;
                numOfDocs++;
              }
              if (
                doc.total_edit_time &&
                doc.meta.created > new Date(date[0]).getTime() &&
                doc.meta.created < new Date(date[1]).getTime()
              ) {
                timeDocsArr.push({
                  created: doc.meta.created,
                  label: doc.name,
                  time: doc.total_edit_time,
                  time_label: doc.total_edit_time / 1000 / 60,
                });
              }
            });
            setDocChartData(arr);
            setDocEditTimeChartData(timeDocsArr);
            setNumberOfDocuments(numOfDocs);
            return;
          } else {
            // more than 120 days selected
            // separate by months
            arr = daysArr
              .filter((d, i) => d.date() === 1)
              .map((d, i) => {
                const label = d.clone().format('MMM YYYY');
                const labelStartDate = d.clone().format(dateFormat);
                const labelEndDate = d
                  .clone()
                  .endOf('month')
                  .format(dateFormat);
                const startDate = d.clone();
                const endDate = moment(d).endOf('month');
                return {
                  name: label,
                  label: `${labelStartDate} - ${labelEndDate}`,
                  startDate: startDate,
                  endDate: endDate,
                  count: 0,
                  show: true,
                };
              });

            // Add new item at the beginning of the array if first item is not first day of the month
            if (daysArr[0].isBefore(arr[0].startDate)) {
              const d = daysArr[0];
              const label = d.clone().format('MMM YYYY');
              const labelStartDate = d.clone().format(dateFormat);
              const labelEndDate = d.clone().endOf('month').format(dateFormat);
              const startDate = d.clone();
              const endDate = moment(d).endOf('month');
              arr.unshift({
                name: label,
                label: `${labelStartDate} - ${labelEndDate}`,
                startDate: startDate,
                endDate: endDate,
                count: 0,
                show: true,
              });
            }

            // change last item of the array if endDate is not last day of the month
            if (
              daysArr[daysArr.length - 1].isBefore(arr[arr.length - 1].endDate)
            ) {
              const lastEl = arr.pop();
              lastEl.endDate = moment(date[1]);
              lastEl.label = `${lastEl.startDate
                .clone()
                .format(dateFormat)} - ${
                !lastEl.startDate.isSame(lastEl.endDate)
                  ? lastEl.endDate.format(dateFormat)
                  : ''
              }`;
              arr.push(lastEl);
            }

            let timeDocsArr = [];

            // update chart data and number of documents
            let numOfDocs = 0;
            documentsArr.forEach((doc) => {
              const createdAt = moment(doc.meta.created);
              const find = arr.find((d, i) => {
                return createdAt.isBetween(
                  d.startDate,
                  d.endDate,
                  undefined,
                  '[]'
                );
              });
              if (find) {
                find.count = find.count + 1;
                numOfDocs++;
              }

              if (
                doc.total_edit_time &&
                doc.meta.created > new Date(date[0]).getTime() &&
                doc.meta.created < new Date(date[1]).getTime()
              ) {
                timeDocsArr.push({
                  created: doc.meta.created,
                  label: doc.name,
                  time: doc.total_edit_time,
                  time_label: doc.total_edit_time / 1000 / 60,
                });
              }
            });
            setDocChartData(arr);
            setDocEditTimeChartData(timeDocsArr);
            setNumberOfDocuments(numOfDocs);
            return;
          }
        }
      },
      [documentsArr]
    );

    if (isSorting) {
      return (
        <div
          className={`widget widget--placeholder ${
            dragging ? 'widget--dragging' : ''
          }`}
          ref={ref}
          style={style}
        >
          <div className="widget__head">
            <h4 className="heading-4">{t('dashboard.documents')}</h4>
          </div>
        </div>
      );
    }

    return (
      <>
        <div
          className={`widget ${
            widgetsInfo['item-contracts'].size === 'half' ? 'widget--half' : ''
          }`}
          ref={ref}
          style={style}
        >
          <div className="widget__head">
            <h4 className="heading-4" {...props}>
              {t('dashboard.documents')}
            </h4>
            <div className="widget__head_actions">
              <CustomDatepicker
                onDateChange={dateFilterChangeDocuments}
                options={[
                  {
                    value: 'last_7_days',
                    label: t('dashboard.last_x_days', { number: 7 }),
                  },
                  {
                    value: 'last_30_days',
                    label: t('dashboard.last_x_days', { number: 30 }),
                  },
                  {
                    value: 'last_90_days',
                    label: t('dashboard.last_x_days', { number: 90 }),
                  },
                  {
                    value: 'this_month',
                    label: `${t('dashboard.this_month')} (${moment().format(
                      'MMM'
                    )} ${new Date().getFullYear()})`,
                  },
                  {
                    value: 'this_year',
                    label: `${t(
                      'dashboard.this_year'
                    )} (${new Date().getFullYear()})`,
                  },
                  {
                    value: 'last_year',
                    label: `${t('dashboard.last_year')} (${
                      new Date().getFullYear() - 1
                    })`,
                  },
                ]}
                defaultOption={{
                  value: 'last_30_days',
                  label: t('dashboard.last_x_days', { number: 30 }),
                }}
                minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                maxDate={new Date()}
              />
              <WidgetDropdown widget="item-contracts" />
            </div>
          </div>
          <div className="widget__body">
            <div className="docs-widget-content">
              <ResponsiveContainer width={'99%'} height={280}>
                <BarChart
                  data={docChartData}
                  margin={{ right: 20, left: -35, top: 5 }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="#DFE1E6"
                  />
                  <XAxis
                    dataKey={(data) => {
                      return data.show ? data.name : null;
                    }}
                    interval={'preserveStartEnd'}
                    stroke={''}
                    tickMargin={10}
                  />
                  <YAxis allowDecimals={false} stroke={''} />
                  <Tooltip content={<ChartTooltip />} />
                  <Bar
                    dataKey="count"
                    stroke="#2C7BE5"
                    fill="#2C7BE5"
                    radius={[5, 5, 0, 0]}
                    barSize={9}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default DocsWidget;

import React, { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { DescriptionOutlined, ShareOutlined, List, CalendarToday, Check, Group } from '@material-ui/icons'
import { MdApproval } from 'react-icons/md'

let allGuides = [
  { section: 'side-menu', css: 'guides__guide-1 guide-side-menu', icon: <i className="custom-icon-dashboard"></i>, num: 1, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-2 guide-side-menu', icon: <DescriptionOutlined />, num: 2, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-3 guide-side-menu', icon: <i className="custom-icon-template"></i>, num: 3, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-4 guide-side-menu', icon: <ShareOutlined />, num: 4, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-5 guide-side-menu', icon: <CalendarToday />, num: 5, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-6 guide-side-menu', icon: <Check />, num: 6, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-7 guide-side-menu', icon: <Group />, num: 7, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-8 guide-side-menu', icon: <i className="custom-icon-create"></i>, num: 8, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-9 guide-side-menu', icon: <MdApproval />, num: 9, arrowPos: 'left' },
  { section: 'side-menu', css: 'guides__guide-10 guide-side-menu', icon: <List />, num: 10, arrowPos: 'left' },
]

const Guides = ({ onSkip }) => {
  const [popupPosition, setPopupPosition] = useState({ top: 48, left: 56, arrowPos: 'left' })
  const [activeGuide, setActiveGuide] = useState(1)
  const guidesRef = useRef()
  const location = useLocation()

  // Text for each guide
  const guidesText = {
    1: 'Lorem ipsum dolor sit amet',
    2: 'Quisque sit amet tortor vestibulum, vestibulum urna ut, tempor lectus. Integer urna ipsum, sodales ac odio id, sodales tincidunt lacus.',
    3: 'Mauris eget nulla ut augue pellentesque pellentesque tincidunt eu tellus. Donec eget ornare quam.',
    4: 'Nunc mollis eu tellus condimentum pharetra. Pellentesque in finibus tortor.',
    5: 'Cras et ex ac lorem faucibus dictum. Praesent sit amet egestas mi, et suscipit est. Duis sed cursus sem. ',
    6: 'Integer quis tellus nec augue semper tincidunt eu et massa. Aenean commodo lectus metus, at cursus felis sodales a.',
    7: 'Praesent sit amet pharetra quam. Suspendisse eget tortor quam. Morbi sollicitudin mauris sed nisi fringilla aliquet.',
    8: 'Vivamus cursus luctus posuere. Nunc in purus ut ipsum eleifend vestibulum in quis turpis. Etiam elit arcu, pretium eu rhoncus ac, congue nec est. ',
    9: 'Mauris iaculis lacinia lorem, nec pharetra odio. Mauris at pulvinar orci. Integer nec convallis ligula. Sed id tristique quam.',
    10: 'Nullam congue venenatis dapibus. Mauris at venenatis est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse a urna turpis. Vivamus tristique molestie hendrerit. Morbi venenatis faucibus lectus, a tempor eros hendrerit vel. Nam eget vulputate tellus.',
  }

  // On next
  const handleNext = () => {
    if(activeGuide < allGuides.length) {
      let active = activeGuide + 1
      setActiveGuide(active)
      if(guidesRef.current) {
        const el = guidesRef.current.querySelector(`[data-num="${active}"]`)
        if(el) {
          const { left, top, width } = el.getBoundingClientRect()
          const findItem = allGuides.find(g => g.num === active)
          setPopupPosition({ left: left + width + 8, top: top, arrowPos: findItem?.arrowPos || 'left' })
        }
      }
    } 
  }

  // On prev
  const handlePrev = () => {
    if(activeGuide > 1 && activeGuide < allGuides.length + 1) {
      let active = activeGuide - 1
      setActiveGuide(active)
      if(guidesRef.current) {
        const el = guidesRef.current.querySelector(`[data-num="${active}"]`)
        if(el) {
          const { left, top, width } = el.getBoundingClientRect()
          const findItem = allGuides.find(g => g.num === active)
          setPopupPosition({ left: left + width + 8, top: top, arrowPos: findItem?.arrowPos || 'left' })
        }
      }
    }
  }

  // On skip
  const handleSkip = () => {
    onSkip()
  }

  if(!location.pathname.includes('/dashboard')) return null

  return (
    <div className="guides-wrapper">
      <div className="guides" ref={guidesRef}>
        <div 
          className={`guides__popup guides__popup--arrow-${popupPosition.arrowPos}`} 
          style={{top: popupPosition.top, left: popupPosition.left}}
        >
          <p>{guidesText[activeGuide]}</p>
          <div className="guides__popup_foot">
            {activeGuide !== 1 && <button className="button button--secondary prev" type="button" onClick={handlePrev}>Prev</button>}
            {activeGuide < allGuides.length && <button className="button button--primary" type="button" onClick={handleNext}>Next</button>}
            <button className="button button--danger" type="button" onClick={handleSkip}>Skip</button>
          </div>
        </div>
        <div className="guides__side-menu">
          {allGuides.filter(g => g.section === 'side-menu').map((guide, idx) => {
            return (
              <div 
                className={`guides__guide ${guide.css} ${activeGuide === guide.num ? 'active' : ''}`} 
                data-num={guide.num}
                key={idx}
              >
                {guide.icon}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Guides
import React, { useRef, useState, useEffect, useCallback, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined'
import { v4 as uuidv4} from 'uuid'

import Tooltip from '../../UI/Tooltip';
import CustomTooltip from '../../UI/CustomTooltip';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader'
import ChatIcon from '@material-ui/icons/Chat';
import { LimitedFeatureWrapper } from '../../utils/LimitedFeatureWrapper'
import { TeamContext, GlobalContext, NotificationContext, DocumentsContext } from '../../../context'
import { availableOn } from '../../../utils'
import { upload_file_with_progress } from '../../../services/firestore'

const DocumentDetailSidePanel = ({ onTriggerClick, children, onSetView, view, signatures, attachments, showBackToTop, showUploadAttachments, closeUploadAttachments, onAddAttachment, attachmentsNames, tasks, docUploaded, commentsBadge, onSetEditStartTime, onStoreEditTime, isSharedTemplate, numOfAlerts, documentId, uploading, setUploading, progress, setProgress }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { setNotification } = useContext(NotificationContext)
  const { updateDocument } = useContext(DocumentsContext)
  const [errors, setErrors] = useState([]);
  const fileInput = useRef();
  const dropEl = useRef();

  // On file submitted
  const getFileData = (file) => new Promise((resolve, reject) => {
    if (file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = file.name.split('.')
        const format = components[components.length - 1]
        components.splice(components.length - 1, 1)
        const name = components.join('.')
        const type = file.type
        // addFile({ data: e.target.result, name: name, format: format })
       
        // fileInputRef.current.value = ""
        // console.log('add file', { data: e.target.result, name: name, format: format })
        // onAddAttachment({ data: e.target.result, name: name, format: format, type: type })

        const data = { data: e.target.result, name: name, format: format, type: type, size: file.size }
        resolve(data)
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
        reject(err)
      }
      reader.readAsDataURL(file);
    } else {
      // no files
    }
  })

  // On file select - from file window
  const changeHandler = async (e) => {
    const allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg', 'image/gif']

    if(errors.length > 0) {
      setErrors([]);
    }
    const files = e.target.files || e.dataTransfer.files;
    let error = false
    let nameAlreadyExistsErr = ''
    let sizeErr = ''
    const maxSize = 10485760
    for(let i = 0; i < files.length; i++) {
      if(!allowedFileTypes.includes(files[i].type)) {
      // if(!files[i].name.includes('pdf') && !files[i].name.includes('docx')) {
        error = true;
        break;
      }
      if(attachmentsNames.includes(files[i].name)) {
        setErrors(prev => [...prev, ]);
        nameAlreadyExistsErr = t('notification.file_already_exists', { file: files[i].name }) 
        break
      }
      if(files[i].size > maxSize) {
        sizeErr = t('notification.file_too_big')
      }
    }

    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type'), type: 'danger' });
    }

    if(nameAlreadyExistsErr) {
      return setNotification({ msg: nameAlreadyExistsErr, type: 'danger' })
    }
    
    if(sizeErr) {
      return setNotification({ msg: sizeErr, type: 'danger' })
    }

    let docAttachments = [...attachments]

    // If only 1 file
    if(files.length === 1) {
      setUploading(true)
      const file = files[0]
      const data = await getFileData(file)
      const created = Date.now()
      let nameWithTimestamp = `${data.name}-${created}`
      let att = {
        name: data.name,
        att_path: `attachments/${nameWithTimestamp}.${data.format}`,
        created,
        format: data.format,
        type: data.type,
        id: uuidv4(),
        url: '',
        size: data.size
      }
      
      const fileUrlRes = await upload_file_with_progress(file, nameWithTimestamp, att.format, att.type, 'attachments', (p) => {
        setProgress(Math.floor(p))
      })

      if(fileUrlRes.url) {
        att.url = fileUrlRes.url
        docAttachments.push(att)
        await updateDocument({ attachments: docAttachments }, { id: documentId })
      }
      
    }else { // if multiple files
      const filesArr = Array.from(files)
      const promises = []
      setUploading(true)

      for(let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i]
        promises.push(getFileData(file))
      }

      let mProgress = 0
      let total = 0

      let docAttachments = [...attachments]
      await Promise.all(promises).then(async data => {
        for(let i = 0; i < data.length; i++) {
          let created = Date.now()
          const file = filesArr[i]
          let attData = data[i]
          let nameWithTimestamp = `${attData.name}-${created}`
          let att = {
            name: attData.name,
            att_path: `attachments/${nameWithTimestamp}.${attData.format}`,
            created,
            format: attData.format,
            type: attData.type,
            id: uuidv4(),
            url: '',
            size: attData.size
          }
          // eslint-disable-next-line no-loop-func
          const fileUrlRes = await upload_file_with_progress(file, nameWithTimestamp, att.format, att.type, 'attachments', (p) => {
            let current = (Math.floor(p) / 100) * (100 / data.length)
            mProgress = total + current
            if(current >= Math.floor(100 / data.length)) {
              total += current
            }
            setProgress(Math.floor(mProgress))
          });
          if(fileUrlRes.url) {
            att.url = fileUrlRes.url
          }
          docAttachments.push(att)
        }
      })

      if(promises.length > 0) {
        await updateDocument({ attachments: docAttachments }, { id: documentId })
      }

    }

    setUploading(false)
    setProgress(0)
    closeUploadAttachments()

    // Array.from(files).forEach(file => {
    //   if(attachmentsNames.includes(file.name)) {
    //     setErrors(prev => [...prev, t('notification.file_already_exists', { file: file.name })]);
    //   }else {
    //     onFileSubmitted(file);
    //     closeUploadAttachments();
    //   }
    // });

    onStoreEditTime()
  }

  const viewSetHandler = (view, e) => {
    e.preventDefault();
    onSetView(view);
  }

  const dragEnterHandler = (e) => {
    e.preventDefault();
    dropEl.current.classList.add('active');
  }
  
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    dropEl.current.classList.remove('active');
  }
  
  const dragOverHandler = (e) => {
    e.preventDefault();
  }

  const dropHandler = (e) => {
    e.preventDefault();
    changeHandler(e);
    dropEl.current.classList.remove('active');
  }

  // On select files btn click
  const handleSelectFiles = () => {
    fileInput.current.click()
    onSetEditStartTime(Date.now())
  }
  
  return(
    <div className={`document-detail__body_side-panel side-panel ${isSharedTemplate ? `side-panel--shared-template${availableOn(['development'] ? '' : '-2')}` : ''} ${availableOn(['production', 'staging']) ? 'side-panel--prod' : ''}`}>
      <div className="side-panel__menu">
        <div className="tooltip-wrapper">
          <CustomTooltip content={t('general.hide')} position="left">
            <div className="trigger" onClick={onTriggerClick}>
              <i className="custom-icon-close-panel"></i>
            </div>
          </CustomTooltip>
        </div>

        <ul>
          {!docUploaded ? (
            <li>
              <div className="tooltip-wrapper">
                <CustomTooltip content={t('dashboard.variables')} position="left">
                  <div>
                    <Link 
                      to="/" 
                      onClick={(e) => viewSetHandler('variables', e)} 
                      className={view === 'variables' ? 'active' : ''}
                    >
                      <DescriptionOutlinedIcon />
                    </Link>
                  </div>
                </CustomTooltip>
              </div>
            </li>
            ) : (
            <li>
              <div className="tooltip-wrapper">
                <CustomTooltip content={t('dashboard.document')} position="left">
                  <div>
                    <Link 
                      to="/" 
                      onClick={(e) => viewSetHandler('preview', e)} 
                      className={view === 'preview' ? 'active' : ''}
                    >
                      <DescriptionOutlinedIcon />
                    </Link>
                  </div>
                </CustomTooltip>
              </div>
            </li>
          )}
          {!isSharedTemplate && <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('dashboard.dates')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('dates', e)}
                    className={view === 'dates' ? 'active' : ''}
                  >
                    <CalendarTodayIcon />
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('general.alert')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('alert', e)}
                    className={view === 'alert' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <AlarmIcon />
                      {numOfAlerts && numOfAlerts > 0 ? <span className="badge">{numOfAlerts}</span> : null}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('dashboard.tags')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('tags', e)}
                    className={view === 'tags' ? 'active' : ''}
                  >
                    <LabelOutlinedIcon />
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('dashboard.tasks')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('tasks', e)}
                    className={view === 'tasks' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <CheckCircleOutlineIcon />
                      {tasks.length > 0 && <span className="badge">{tasks.length}</span>}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('general.appendices')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('appendices', e)}
                    className={view === 'appendices' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <AttachFileIcon />
                      {attachments.length > 0 && <span className="badge">{attachments.length}</span>}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {/* <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('general.contacts')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('contacts', e)}
                    className={view === 'contacts' ? 'active' : ''}
                  >
                    <i className="custom-icon-contact-page"></i>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li> */}
          {/* <LimitedFeatureWrapper type="team" requirement="beta_partner"> */}
            {availableOn(['development']) && !isSharedTemplate && <li>
              <div className="tooltip-wrapper">
                <CustomTooltip content={t('general.messages')} position="left">
                  <div>
                    {selectedTeam?.membership !== 'free' ? <Link 
                      to="/" 
                      onClick={(e) => viewSetHandler('comments', e)}
                      className={view === 'comments' ? 'active' : ''}
                    >
                      {/* <i className="custom-icon-contact-page"></i> */}
                      {commentsBadge > 0 && <span className="badge">{commentsBadge}</span>}
                      <ChatIcon />
                    </Link>
                    : <div className="disabled"><ChatIcon /></div>}
                  </div>
                </CustomTooltip>
              </div>
            </li>}
          {/* </LimitedFeatureWrapper> */}
          {!isSharedTemplate && <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('custom_fields.title')} position="left">
                <div>
                  {selectedTeam?.membership !== 'free' ? <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('custom_fields', e)}
                    className={view === 'custom_fields' ? 'active' : ''}
                  >
                    <AssignmentIcon />
                  </Link>
                  : <div className="disabled"><AssignmentIcon /></div>}
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {/* <li>
            <div className="tooltip-wrapper">
              <Link 
                to="/" 
                onClick={(e) => e.preventDefault()}
                className={view === '4' ? 'active' : ''}
              >
                <span className="badge-wrap">
                  <i className="custom-icon-create"></i>
                  {signatures.length > 0 && <span className="badge">{signatures.length}</span>}
                </span>
              </Link>
              <Tooltip text={t('dashboard.signatures')} position="left" />
            </div>
          </li>
          <li>
            <div className="tooltip-wrapper">
              <Link 
                to="/" 
                onClick={(e) => e.preventDefault()}
                className={view === '3' ? 'active' : ''}
              >
                <i className="custom-icon-mark-email-read"></i>
              </Link>
              <Tooltip text={t('dashboard.recommended')} position="left" />
            </div>
          </li> */}
        </ul>

        <div className={showBackToTop ? "back-to-top active" : "back-to-top"} onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
          <CustomTooltip content={t('general.to_top')} position="left" fixed>
            <i className="custom-icon-arrow-circle-up"></i>
          </CustomTooltip>
        </div>
      </div>
      <div className="side-panel__main u-custom-scrollbar">
        {children}
      </div>
      {showUploadAttachments && <div className="side-panel__upload"
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
        ref={dropEl}
      >
        <div className="side-panel__upload_inner">
          <div className="side-panel__upload_close" onClick={closeUploadAttachments}><CancelIcon /></div>
          <p className="info">{t('general.upload_here')}</p>
          <div className="or">
            <div className="line"></div>
            <p>{t('general.or')}</p>
            <div className="line"></div>
          </div>
          <div className="btn-wrapper">
            <Button 
              text={t('general.select_file')}
              icon={<i className="custom-icon-upload"></i>} 
              onButtonClick={handleSelectFiles}
              medium 
              primary 
            />
          </div>
          <input 
            type="file" 
            accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpg,image/jpeg,image/gif" 
            onChange={changeHandler} 
            className="file-input" 
            ref={fileInput} 
            multiple  
          />
          {errors.length > 0 && <div className="side-panel__upload_error">{errors.join('')}</div>}
          {/* <div className="drag-n-drop-wrapper"
            onDragEnter={dragEnterHandler}
            onDragLeave={dragLeaveHandler}
            onDragOver={dragOverHandler}
            onDrop={dropHandler}
          ></div> */}
        </div>
      </div>}

      {uploading && (
        <div className="side-panel__uploading">
          <div className="side-panel__uploading_progress"><div style={{width: `${progress}%`}}></div><p>{progress}%</p></div>
          <Loader normal primary />
        </div>
      )}
    </div>
  );
}

DocumentDetailSidePanel.propTypes = {
  onTriggerClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  onSetView: PropTypes.func,
  view: PropTypes.string
}

export default memo(DocumentDetailSidePanel)
import { useEffect, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'

import { GlobalContext } from '../../context'

const Modal = ({ onClose, children, className, hideCloseBtn = false, useEscBtn = true, hideFooter = false, title = '', titleIcon = null, onSave, saveBtnText = '', saveBtnDisabled = false, customHeader = null, setBodyOverflow = true, hideHeader = false }) => {
  const { t } = useContext(GlobalContext)
  const modalContent = useRef()

  let modalClasses = 'modal-v2'

  if(className) {
    modalClasses += ` ${className}`
  }
  if(hideFooter) {
    modalClasses += ' no-footer'
  }

  // Remove scrollbar when modal is opened
  useEffect(() => {
    if(setBodyOverflow) {
      document.body.style.overflowY = 'hidden'
    }
    
    return () => {
      if(setBodyOverflow) {
        document.body.style.overflowY = null
      }
    }
  }, [setBodyOverflow])

  // Close or submit on keys(esc, enter) press
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Escape' && useEscBtn) {
        onClose()
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [onClose, useEscBtn])

  return ReactDOM.createPortal(
    <div className={modalClasses}>
      <div className="modal-v2__inner">
        <div className="modal-v2__content-wrapper">
          <div className="modal-v2__bg" onClick={onClose}></div>
          <div className="modal-v2__content" ref={modalContent}>
            {!hideHeader && (
              <div className="modal-v2-header">
                {!customHeader ? (
                  <h5>{titleIcon}{title}</h5>
                ) : (
                  <div className="custom-header-content">
                    {customHeader}
                  </div>
                )}
                {!hideCloseBtn && (
                  <div className="modal-v2-close" onClick={onClose}>
                    <span className="material-symbols-outlined size-24">close</span> 
                  </div>
                )}
              </div>
            )}
            <div className="modal-v2-body">
              { children }
            </div>
            {!hideFooter && (
              <div className="modal-v2-foot">
                <button className="btn btn--gray btn--with-letter-spacing" onClick={onClose}>{t('general.cancel')}</button>
                <button className="btn btn--with-letter-spacing" onClick={onSave} disabled={saveBtnDisabled}>{saveBtnText || t('general.save')}</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default Modal
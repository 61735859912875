import React, { useState, useContext, useEffect, useCallback } from 'react' 
import { MessageOutlined, SentimentVeryDissatisfiedOutlined, SentimentDissatisfiedOutlined, SentimentSatisfied, SentimentSatisfiedOutlined, SentimentVerySatisfiedOutlined } from '@material-ui/icons'

import { Modal, Textarea } from '../new_ui'
import { GlobalContext } from '../../context'
import { useSupportActions } from '../../hooks'

const GiveFeedbackModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { giveFeedback } = useSupportActions()
  const [message, setMessage] = useState('')
  const [selectedRating, setSelectedRating] = useState('')

  // Change rating
  const changeRatingHandler = (e, rating) => {
    e.preventDefault()
    setSelectedRating(rating)
  }

  // Send feedback
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    await giveFeedback({ selectedRating, message, onClose })
  }, [message, onClose, selectedRating, giveFeedback])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return (
    <Modal 
      onClose={onClose} 
      title={t('support.send_feedback')} 
      titleIcon={<span className="material-symbols-outlined size-24">chat</span>} 
      onSave={handleSubmit}
      saveBtnText={t('support.send_feedback')}
      saveBtnDisabled={selectedRating === ''}
    >
      <div className="report-a-bug-v2">
        <div className="report-a-bug-v2__body">
          <p>{t('support.how_would_you_rate_app')}</p>
          <form onSubmit={handleSubmit}>
            <div className="emojis">
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'terrible')} className={selectedRating === 'terrible' ? 'active' : ''}>
                <span className="material-symbols-outlined">sentiment_very_dissatisfied</span>
                <span>{t('support.terrible')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'bad')} className={selectedRating === 'bad' ? 'active' : ''}>
                <span className="material-symbols-outlined">sentiment_dissatisfied</span>
                <span>{t('support.bad')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'okay')} className={selectedRating === 'okay' ? 'active' : ''}>
                <span className="material-symbols-outlined">sentiment_neutral</span>
                <span>{t('support.okay')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'great')} className={selectedRating === 'great' ? 'active' : ''}>
                <span className="material-symbols-outlined">sentiment_satisfied</span> 
                <span>{t('support.great')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'excellent')} className={selectedRating === 'excellent' ? 'active' : ''}>
                <span className="material-symbols-outlined">sentiment_very_satisfied</span>
                <span>{t('support.excellent')}</span>
              </a>
            </div>
            <Textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('support.leave_us_message')} />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default GiveFeedbackModal
import { useContext } from 'react' 

import { NotificationContext, GlobalContext, SharedTemplatePagesContext, LoaderContext } from '../context'

const useSingleSharedTemplateActions = () => {
  const { renewSingleTemplateUrl, updateSingleSharedTemplate, deleteSingleSharedTemplate } = useContext(SharedTemplatePagesContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { t } = useContext(GlobalContext)

  // Copy template url
  const copySharedTemplateUrl = ({ template }) => {
    const input = document.createElement('textarea')
    input.innerHTML = `${window.location.origin}/shared-template/${template.id}`
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setNotification({ msg: t('share_email.link_copied'), type: 'info' })
    document.body.click()
  }

  // Renew shared template
  const renewSharedTemplate = async ({ template }) => {
    setShowGlobalResponseLoader(true)
    renewSingleTemplateUrl(template, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('share_template.renewed_successfully'), type: 'success' })
      document.body.click()
    }, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  // Disable shared template
  const disableSharedTemplate = async ({ template }) => {
    setShowGlobalResponseLoader(true)
    updateSingleSharedTemplate({ disabled: !template.disabled }, template.id, () => {
      if(!template.disabled) {
        setNotification({ msg: t('share_template.link_disabled'), type: 'info' })
      }else {
        setNotification({ msg: t('share_template.link_enabled'), type: 'info' })
      }
      document.body.click()
      setShowGlobalResponseLoader(false)
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  // Delete shared template
  const deleteSharedTemplate = async ({ singleTemplateIdToDelete, handleCloseTemplateDeleteAlert }) => {
    setShowGlobalResponseLoader(true)
    deleteSingleSharedTemplate(singleTemplateIdToDelete, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('share_template.deleted_successfully'), type: 'success' })
      if(handleCloseTemplateDeleteAlert) {
        handleCloseTemplateDeleteAlert()
      }
    }, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      if(handleCloseTemplateDeleteAlert) {
        handleCloseTemplateDeleteAlert()
      }
    })
  }

  const updateSharedTemplateAlias = async ({ template, alias }) => {
    setShowGlobalResponseLoader(true)
    updateSingleSharedTemplate({ alias }, template.id, () => {
      setNotification({ msg: t('share_template.alias_updated'), type: 'success' })
      setShowGlobalResponseLoader(false)
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  return {
    copySharedTemplateUrl,
    renewSharedTemplate,
    disableSharedTemplate,
    deleteSharedTemplate,
    updateSharedTemplateAlias
  }
}

export default useSingleSharedTemplateActions
import React, { useContext, useEffect, useState } from 'react'
import { MoreHorizOutlined, AutorenewOutlined, LinkOffOutlined, LinkOutlined, DeleteOutlined, RemoveRedEyeOutlined, EditOutlined, Add, Assessment, AssessmentOutlined } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import ActionsDropdown from '../UI/ActionsDropdown'
import Alert from '../UI/Alert'
import Button from '../UI/Button'
import CustomTooltip from '../UI/CustomTooltip'
import IconButton from '../UI/IconButton'
import Loader from '../UI/Loader'
import TemplateShareModal from '../sections/templates/TemplateShareModal'
import PageOrTemplateShareModal from '../sections/templates/PageOrTemplateShareModal'
import TemplateSharedSuccessfullyModal from '../sections/templates/TemplateSharedSuccessfullyModal'

import { SharedTemplatePagesContext, TeamContext, GlobalContext } from '../../context'
import { useSingleSharedTemplateActions, useSharedTemplatePageActions, useSingleTemplateActions } from '../../hooks'
import { Input, Modal } from '../new_ui'

const SharedTemplates = () => {
  const { t } = useContext(GlobalContext)
  const { sharedTemplatePages, sharedTemplatePagesFetched, fetchSharedTemplatePages, singleSharedTemplates, singleSharedTemplatesFetched, fetchSingleSharedTemplates } = useContext(SharedTemplatePagesContext)
  const { selectedTeam, teamChanged, setTeamChanged } = useContext(TeamContext)
  const { copySharedTemplateUrl, renewSharedTemplate, disableSharedTemplate, deleteSharedTemplate, updateSharedTemplateAlias } = useSingleSharedTemplateActions()
  const { copySharedTemplatePageUrl, renewSharedTemplatePage, disableSharedTemplatePage, deleteSharedTmpltPage, updateSharedTemplatePageAlias } = useSharedTemplatePageActions()
  const { shareTemplate } = useSingleTemplateActions()
  const [showPageEditModal, setShowPageEditModal] = useState(false)
  const [pageToEditId, setPageToEditId] = useState(null)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [showPageShareModal, setShowPageShareModal] = useState(false)
  const [showTemplateShareModal, setShowTemplateShareModal] = useState(false)
  const [templateSharedSuccessfully, setTemplateSharedSuccessfully] = useState(false)
  const [alreadyShared, setAlreadyShared] = useState(false)
  const [sharedTemplateId, setSharedTemplateId] = useState('')
  const [sharedTemplate, setSharedTemplate] = useState(null)
  const [templateActionPrompt, setTemplateActionPrompt] = useState({
    isOpen: false,
    loading: false,
    text: '',
    action: '',
    onConfirm: () => {},
    isDeleteAlert: false
  })
  const [editAliasModal, setEditAliasModal] = useState({
    isOpen: false,
    page: null,
    template: null,
    alias: ''
  })

  const history = useHistory()

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
    }
  }, [teamChanged, setTeamChanged])

  // Fetch pages and single templates if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!sharedTemplatePagesFetched) {
        // console.log('fetch pages')
        fetchSharedTemplatePages(teamId)
      }
      if(!singleSharedTemplatesFetched) {
        // console.log('fetch single templates')
        fetchSingleSharedTemplates(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [sharedTemplatePagesFetched, fetchSharedTemplatePages, selectedTeam, singleSharedTemplatesFetched, fetchSingleSharedTemplates, fetchingCollections])

  // Fetch single templates
  useEffect(() => {
  }, [singleSharedTemplatesFetched, fetchSingleSharedTemplates, selectedTeam])

  // On view page
  const handleViewPage = (e, page) => {
    e.preventDefault()
    history.push(`/shared-template-page/${page.id}`)
  }

  // On edit page trigger
  const handleEditPageTrigger = (e, page) => {
    e.preventDefault()
    setPageToEditId(page.id)
    setShowPageEditModal(true)
  }

  // On page edit modal close 
  const handleClosePageEditModal = () => {
    setShowPageEditModal(false)
    setPageToEditId(null)
  }

  // On page url copy
  const handleCopyPageUrl = (e, page) => {
    e.preventDefault()
    copySharedTemplatePageUrl({ page })
  }

  // On page renew
  const handleSharedPageRenew = async (e, page) => {
    e.preventDefault()
    promptTemplateAction(t('share_template.renew_page'), async () => await renewSharedTemplatePage({ page }), 'renew')
  }

  // On page disable
  const handleSharedPageDisable = async (e, page) => {
    e.preventDefault()
    promptTemplateAction(page.disabled ? t('share_template.enable_page') : t('share_template.disable_page'), async () => await disableSharedTemplatePage({ page }), 'disable')
  }

  // On open page delete alert
  const handleOpenPageDeleteAlert = (e, page) => {
    e.preventDefault()
    promptTemplateAction(t('share_template.delete_page'), async () => await deleteSharedTmpltPage({ pageToDelete: page.id }), 'delete')
  }

  // On view template
  const handleViewTemplate = (e, template) => {
    e.preventDefault()
    history.push(`/shared-template/${template.id}`)
  }

  // On copy template url
  const handleCopyTemplateUrl = (e, template) => {
    e.preventDefault()
    copySharedTemplateUrl({ template })
  }

  // On template renew
  const handleSharedTemplateRenew = (e, template) => {
    e.preventDefault()
    promptTemplateAction(t('share_template.renew_template'), async () => await renewSharedTemplate({ template }), 'renew')
  }

  // On template disable
  const handleSharedTemplateDisable = (e, template) => {
    e.preventDefault()
    promptTemplateAction(template.disabled ? t('share_template.enable_template') : t('share_template.disable_template'), async () => await disableSharedTemplate({ template }), 'disable')
  }

  // On delete single template
  const handleSharedTemplateDelete = (e, template) => {
    e.preventDefault()
    promptTemplateAction(t('share_template.delete_template'), async () => await deleteSharedTemplate({ singleTemplateIdToDelete: template.id }), 'delete')
  }

  // On open page share modal
  const handleOpenPageShareModal = () => {
    setShowPageShareModal(true)
  }

  // On close page share modal
  const handleClosePageShareModal = () => {
    setShowPageShareModal(false)
  }

  // On open page share modal
  const handleOpenTemplateShareModal = () => {
    setShowTemplateShareModal(true)
  }

  // On close page share modal
  const handleCloseTemplateShareModal = () => {
    setShowTemplateShareModal(false)
  }

  // On single template share
  const handleSingleTemplateShare = async (templateToShare) => {
    await shareTemplate({ templateToShare, setTemplateSharedSuccessfully, setShowPageOrTemplateShareModal: setShowTemplateShareModal, setAlreadyShared, setSharedTemplateId, setSharedTemplate, setTemplateToShare: () => {} })
  }

  // On shared successfully modal close
  const handleSharedSuccessfullyModalClose = () => {
    setTemplateSharedSuccessfully(false)
    setAlreadyShared(false)
    setSharedTemplate(null)
    setSharedTemplateId('')
  }

  const promptTemplateAction = (text, onConfirm, action) => {
    setTemplateActionPrompt({
      isOpen: true,
      loading: false,
      text: text,
      isDeleteAlert: action === 'delete',
      onConfirm: async () => {
        setTemplateActionPrompt({ ...templateActionPrompt, loading: true })
        await onConfirm()
        setTemplateActionPrompt({ isOpen: false, loading: false, text: '', action: '', onConfirm: () => {} })
      }
    })
  }

  const cancelTemplateAction = () => {
    setTemplateActionPrompt({ isOpen: false, loading: false, text: '', action: '', onConfirm: () => {} })
  }

  const handlePromptEditAlias = (e, { template, page }) => {
    e.preventDefault()
    setEditAliasModal({ isOpen: true, page: page, template: template, alias: (page || template).alias || '' })
  }

  const handleUpdateAlias = async () => {
    if(editAliasModal.page) {
      await updateSharedTemplatePageAlias({ page: editAliasModal.page, alias: editAliasModal.alias })
    }else if(editAliasModal.template) {
      await updateSharedTemplateAlias({ template: editAliasModal.template, alias: editAliasModal.alias })
    }
    setEditAliasModal({ isOpen: false, page: null, template: null, alias: '' })
  }

  return (
    <div className="shared-templates-page">
      {!sharedTemplatePagesFetched || !singleSharedTemplatesFetched 
        ? (
          <div className="loader-wrapper"><Loader small primary normal /></div>
        )
        : (
          <>
            <section>
              <div className="section-head">
                <h2>{t('share_template.pages')}</h2>
                <Button primaryLight text={t('share_template.add_new_page')} icon={<Add />} onButtonClick={handleOpenPageShareModal} />
              </div>
              {sharedTemplatePages.length > 0 ? (
                <ul className="list">
                  {sharedTemplatePages.map(page => {
                    return (
                      <PageRow 
                        key={page.id} 
                        t={t}
                        page={page}
                        handleCopyPageUrl={handleCopyPageUrl}
                        handleEditPageTrigger={handleEditPageTrigger}
                        handleOpenPageDeleteAlert={handleOpenPageDeleteAlert}
                        handleSharedPageDisable={handleSharedPageDisable}
                        handleSharedPageRenew={handleSharedPageRenew}
                        handleViewPage={handleViewPage}
                        handlePromptEditAlias={handlePromptEditAlias}
                      />
                    )
                  })}
                </ul>
              ): (
                <div className="no-data">
                  <p>{t('share_template.no_pages')}</p>
                </div>
              )}
            </section>

            <section>
              <div className="section-head">
                <h2>{t('share_template.single_templates')}</h2>
                <Button primaryLight text={t('dashboard.add_template')} icon={<Add />} onButtonClick={handleOpenTemplateShareModal} />
              </div>
              {singleSharedTemplates.length > 0 ? (
                <ul className="list">
                  {singleSharedTemplates.map(template => {
                    return (
                      <SingleTemplateRow 
                        key={template.id}
                        t={t}
                        template={template}
                        handleCopyTemplateUrl={handleCopyTemplateUrl}
                        handleOpenTemplateDeleteAlert={handleSharedTemplateDelete}
                        handleSharedTemplateDisable={handleSharedTemplateDisable}
                        handleSharedTemplateRenew={handleSharedTemplateRenew}
                        handleViewTemplate={handleViewTemplate}
                        handlePromptEditAlias={handlePromptEditAlias}
                      />
                    )
                  })}
                </ul>
              ) : (
                <div className="no-data">
                  <p>{t('share_template.no_templates')}</p>
                </div>
              )}
            </section>
          </>
        )
      }
      {showPageEditModal && <TemplateShareModal 
        onClose={handleClosePageEditModal} 
        mode={'edit'}
        sharedPageId={pageToEditId}
      />}
      {showTemplateShareModal && <PageOrTemplateShareModal 
        mode="template" 
        onClose={handleCloseTemplateShareModal} 
        onSingleTemplateShare={handleSingleTemplateShare}
      />}
      {showPageShareModal && <TemplateShareModal 
        onClose={handleClosePageShareModal} 
      />}
      {templateSharedSuccessfully && <TemplateSharedSuccessfullyModal 
        onClose={handleSharedSuccessfullyModalClose} 
        templateId={sharedTemplateId}
        alreadyShared={alreadyShared}
        sharedTemplate={sharedTemplate}
        onSetSharedTemplate={setSharedTemplate}
        onSetSharedTemplateId={setSharedTemplateId}
      />}
      {templateActionPrompt.isOpen && <Alert 
        onClose={cancelTemplateAction}
        text={templateActionPrompt.text}
        onSubmit={templateActionPrompt.onConfirm}
        loading={templateActionPrompt.loading}
        deleteAlert={templateActionPrompt.isDeleteAlert}
      />}
      {editAliasModal.isOpen && <Modal
        open={editAliasModal.isOpen}
        title={t('share_template.edit_alias')}
        onSave={handleUpdateAlias}
        onClose={() => setEditAliasModal({ ...editAliasModal, isOpen: false })}
      >
        <div className="modal-content">
          <Input label={t('share_template.alias')} type="text" value={editAliasModal.alias || ''} onChange={(e) => setEditAliasModal({ ...editAliasModal, alias: e.target.value })} />
        </div>
      </Modal>}
    </div>
  )
}

const PageRow = ({ page, handleViewPage, handleEditPageTrigger, handleCopyPageUrl, handleSharedPageRenew, handleSharedPageDisable, handleOpenPageDeleteAlert, t, handlePromptEditAlias}) => {
  return (
    <li className="list__item">
      <div className="list__item_name">
        <h3>{page.title}</h3>
        <p>{moment(page.meta.created).format('DD/MM/YY')}</p>
      </div>
      <div className="list__item_actions-1">
        <a href="/#" onClick={(e) => handleViewPage(e, page)}>
          <CustomTooltip content={t('general.preview')}>
            <RemoveRedEyeOutlined /> 
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleEditPageTrigger(e, page)}>
          <CustomTooltip content={t('general.edit')}>
            <EditOutlined /> 
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleCopyPageUrl(e, page)}>
          <CustomTooltip content={t('share_template.copy_url')}>
            <i className="custom-icon-content-copy"></i>
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleSharedPageRenew(e, page)}>
          <CustomTooltip content={t('share_template.renew_url')}>
            <AutorenewOutlined />
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleSharedPageDisable(e, page)}>
          <CustomTooltip content={page.disabled ? t('share_template.enable_link') : t('share_template.disable_link')}>
            {page.disabled ? <LinkOutlined /> : <LinkOffOutlined /> }
          </CustomTooltip>
        </a>
      </div>
      <div className="list__item_actions-2">
        <a href="/#" className="delete" onClick={(e) => handleOpenPageDeleteAlert(e, page)}>
          <CustomTooltip content={t('general.delete')}>
            <DeleteOutlined /> 
          </CustomTooltip>
        </a>
      </div>
      <div className="list__item_actions-mobile">
        <ActionsDropdown
          trigger={<div><IconButton icon={<MoreHorizOutlined />} medium primaryLight /></div>}
          dropdownClass="more-actions more-actions--3"
          hideHeader
          noPadding
        >
          <ul className="dropdown">
            <li>
              <a href="/#" onClick={(e) => handleViewPage(e, page)}><RemoveRedEyeOutlined /> {t('general.preview')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleEditPageTrigger(e, page)}><EditOutlined /> {t('general.edit')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleCopyPageUrl(e, page)}><i className="custom-icon-content-copy"></i> {t('share_template.copy_url')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleSharedPageRenew(e, page)}><AutorenewOutlined /> {t('share_template.renew_url')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleSharedPageDisable(e, page)}>
                {page.disabled 
                  ? (<><LinkOutlined /> {t('share_template.enable_link')}</>)
                  : (<><LinkOffOutlined /> {t('share_template.disable_link')}</>)
                }
              </a>
            </li>
            <li>
              <a href="/#" className="delete" onClick={(e) => handleOpenPageDeleteAlert(e, page)}><DeleteOutlined /> {t('general.delete')}</a>
            </li>
          </ul>
        </ActionsDropdown>
      </div>
    </li>
  )
}

const SingleTemplateRow = ({ template, handleViewTemplate, handleCopyTemplateUrl, handleSharedTemplateRenew, handleSharedTemplateDisable, handleOpenTemplateDeleteAlert, t, handlePromptEditAlias }) => {
  return (
    <li className="list__item">
      <div className="list__item_name">
        <h3>{template.name}</h3>
        <p>{moment(template.meta.created).format('DD/MM/YY')}</p>
        <div className='row v-centered'>
          <p>Alias: {template.alias || ''}</p>
          <a href="/#" onClick={(e) => handlePromptEditAlias(e, { template })} className='ml-10'>
            <CustomTooltip content={t('general.edit')}>
              <EditOutlined /> 
            </CustomTooltip>
          </a>
        </div>
      </div>
      <div className="list__item_actions-1">
        <a href="/#" onClick={(e) => handleViewTemplate(e, template)}>
          <CustomTooltip content={t('general.preview')}>
            <RemoveRedEyeOutlined />
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleCopyTemplateUrl(e, template)}>
          <CustomTooltip content={t('share_template.copy_url')}>
            <i className="custom-icon-content-copy"></i>
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleSharedTemplateRenew(e, template)}>
          <CustomTooltip content={t('share_template.renew_url')}>
            <AutorenewOutlined />
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => handleSharedTemplateDisable(e, template)}>
          <CustomTooltip content={template.disabled ? t('share_template.enable_link') : t('share_template.disable_link')}>
            { template.disabled ? <LinkOutlined /> : <LinkOffOutlined />}
          </CustomTooltip>
        </a>
        <a href="/#" onClick={(e) => e.preventDefault()}>
          <CustomTooltip content={`Download docx: ${template.downloads?.docx || 0}\nDownload pdf: ${template.downloads?.pdf || 0}`} multiline>
            <AssessmentOutlined />
          </CustomTooltip>
        </a>
        <span className="fix"></span>
      </div>
      <div className="list__item_actions-2">
        <a href="/#" className="delete" onClick={(e) => handleOpenTemplateDeleteAlert(e, template)}>
          <CustomTooltip content={t('general.delete')}>
            <DeleteOutlined />
          </CustomTooltip>
        </a>
      </div>
      <div className="list__item_actions-mobile">
        <ActionsDropdown
          trigger={<div><IconButton icon={<MoreHorizOutlined />} medium primaryLight /></div>}
          dropdownClass="more-actions more-actions--3"
          hideHeader
          noPadding
        >
          <ul className="dropdown">
            <li>
              <a href="/#" onClick={(e) => handleViewTemplate(e, template)}><RemoveRedEyeOutlined /> {t('general.preview')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleCopyTemplateUrl(e, template)}><i className="custom-icon-content-copy"></i> {t('share_template.copy_url')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleSharedTemplateRenew(e, template)}><AutorenewOutlined /> {t('share_template.renew_url')}</a>
            </li>
            <li>
              <a href="/#" onClick={(e) => handleSharedTemplateDisable(e, template)}>
                {template.disabled 
                  ? (<><LinkOutlined /> {t('share_template.enable_link')}</>)
                  : (<><LinkOffOutlined /> {t('share_template.disable_link')}</>)
                }
              </a>
            </li>
            <li>
              <a href="/#" className="delete" onClick={(e) => handleOpenTemplateDeleteAlert(e, template)}><DeleteOutlined /> {t('general.delete')}</a>
            </li>
          </ul>
        </ActionsDropdown>
      </div>
    </li>
  )
}

export default SharedTemplates
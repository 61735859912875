import { useContext } from 'react'

import { SettingsLayout } from '../layouts'
import { Input } from '../components/new_ui'
import { GlobalContext, UserContext } from '../context'
import { useForm, useUserActions } from '../hooks'

const ChangePassword = () => {
  const { t, validate } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { changeUserPassword } = useUserActions()
  const initialData = {
    old_password: {
      value: '',
      required: true,
    },
    new_password: {
      value: '',
      required: true,
      minLength: 8,
      minLengthMessage: t('notification.password_validation'),
    },
    confirm_new_password: {
      value: '',
      required: true,
      matchWith: 'new_password',
      matchWithMessage: t('auth.passwords_not_equal')
    },
  }
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate)

  // On submit
  const handleSubmit = async (e) => {
    e.preventDefault()
    await changeUserPassword({ formData, setFormData, setErrors, initialData })
  }

  // Render content
  const renderContent = () => {
    return (
      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="profile-form__group">
          <Input 
            name="old_password"
            label={t('auth.old_password')}
            value={formData.old_password.value}
            onChange={changeHandler}
            error={errors.old_password}
            type="password"
          />
        </div>
        <div className="profile-form__group">
          <Input 
            name="new_password"
            label={t('auth.new_password')}
            value={formData.new_password.value}
            onChange={changeHandler}
            error={errors.new_password}
            type="password"
            showEyeIcon
          />
        </div>
        <div className="profile-form__group">
          <Input 
            name="confirm_new_password"
            label={t('auth.confirm_new_password')}
            value={formData.confirm_new_password.value}
            onChange={changeHandler}
            error={errors.confirm_new_password}
            type="password"
          />
        </div>
        <div className="profile-form__actions">
          <button type="submit" className="btn btn--fw-500 btn--with-letter-spacing">{t('general.save')}</button>
        </div>
      </form>
    )
  }

  return (
    <SettingsLayout title={t('auth.password')}>
      {user ? renderContent() : <div className="loader-v2"></div>}
    </SettingsLayout> 
  )
}

export default ChangePassword 
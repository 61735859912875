import React, { useState, useEffect, useContext, Fragment, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import QRCode from 'qrcode.react'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';

import useForm from '../../../hooks/useForm';
import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import IconButton from '../../UI/IconButton';
import { sendEmail } from '../../../services/functions';
import { update_document, get_file } from '../../../services/firestore';
import { convert_attachment } from '../../../services/lawstudioApi';
import LineLoader from '../../UI/LineLoader';
import CustomTooltip from '../../UI/CustomTooltip';
import Checkbox from '../../UI/Checkbox';
import { NotificationContext, UserContext, TeamContext, DocumentsContext, GlobalContext } from '../../../context';
import { urlSuffixForEnvironment, sortArrayOfObjects } from '../../../utils';
import { process_attachments } from '../../../services/firestore';
import config from '../../../config.json'
import { email_template } from '../../../helpers/email_templates'

const SendByEmailModal = ({ onClose, documentId, fetchDocumentData, documentName, attachments, doc, fetchDocuments, onAddAttachment, attachmentsNames }) => {
  const { t, validate } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext);
  const { templates, updateDocument } = useContext(DocumentsContext)
  const [isLoading, setIsLoading] = useState(false)
  const [sentEmailId] = useState(uuidv4());
  const [emailAttachments, setEmailAttachments] = useState(attachments ? [...attachments] : []);
  const initialData = {
    'recipientEmail-1': {
      value: '',
      email: true,
      name: 'recipientEmail-1'
    },
    subject: {
      value: ''
    },
    message: {
      value: `
        <p>${t('share_email.head')},</p>

        <p>${t('share_email.text', { document: documentName })}</p>
        
        <p>${t('share_email.text_2')}</p>
        
        <p>${t('share_email.foot')},</p>
      `
    }
  }
  const { changeHandler, formData, setFormData, errors, setErrors } = useForm(initialData, validate);
  const [view, setView] = useState('email');
  const [recipientEmails, setRecipientEmails] = useState({});
  const [emailsHistory, setEmailsHistory] = useState([]);
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [addWatermark, setAddWatermark] = useState(false)
  const [addWatermarkToLink, setAddWatermarkToLink] = useState(doc.link_watermark || false)
  const fileInput = useRef();
  const watermarkLinkRef = useRef()

  const handleEditorChange = (content, editor) => {
    setFormData(prevState => ({
      ...prevState,
      message: {
        value: content,
        touched: true
      }
    }))
  }

  useEffect(() => {
    const copyOfData = { ...formData };
    const recipients = {};
    for (let key in copyOfData) {
      if (key.includes('recipientEmail-')) {
        recipients[key] = copyOfData[key];
      }
    }
    setRecipientEmails(recipients);
  }, [formData]);

  // Check if emails were already sent and if they have recipients and timestamp fieldsa and in this case add them to emailsHistory array
  useEffect(() => {
    const arr = [];
    if(doc.sentEmails) {
      doc.sentEmails.forEach(email => {
        if(email.recipients && email.timestamp) {
          arr.push(email);
        }
      });
      setEmailsHistory(sortArrayOfObjects(arr, 'timestamp', 'desc'));
    }
  }, [doc]);

  // On attachments change
  const attachmentsChangeHandler = (index, share) => {
    let ea = [...emailAttachments];
    ea[index].share = share;
    setEmailAttachments(ea);
  }

  // Send email on submit
  const submitHandler = async e => {
    e.preventDefault();
    const newErrors = validate(formData, true);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true)
      try {
        let documentData;
        if(doc.uploaded) {
          if(doc.documentUrls.pdf) {
            const res = await get_file({ url: doc.documentUrls.pdf })
            documentData = res.data;
          }else {
            const res = await convert_attachment(doc.documentUrls.docx)
            documentData = res.data;
          }
        }else {
          documentData = await fetchDocumentData(doc, 'pdf')
        }
        const emails = Object.values({ ...recipientEmails }).map(email => email.value);
        const message = formData.message.value;
        const sharedAttachments = [...emailAttachments].filter(att => att.share);
        const attachmentsToSend = await process_attachments(sharedAttachments);
        // return console.log(sharedAttachments);
        let emailHtml = email_template(message, `https://europe-west1-carbon-contract-management.cloudfunctions.net/document_v2${urlSuffixForEnvironment(config.environment, config.staging)}/${documentId}/${sentEmailId}`, t('share_email.download'), 'share-doc')

        await sendEmail(user.email, emails, formData.subject.value, emailHtml, documentName, documentData, attachmentsToSend)
        const sentEmails = doc.sentEmails ? [...doc.sentEmails] : [];
        sentEmails.push({ 
          id: sentEmailId, 
          sender: user.email, 
          attachments: attachmentsToSend,
          timestamp: Date.now(),
          recipients: emails,
          watermark: addWatermark,
        });
        await update_document(documentId, { sentEmails, attachments: emailAttachments, last_modified_by: activeTeamMember.id, create_action: 'yes' }, emailAttachments);
        await fetchDocuments(selectedTeam?.id)
        setIsLoading(false)
        onClose()
        setNotification({ msg: t('notification.message_sent'), type: 'success' })
      } catch (err) {
        console.log(err)
        setIsLoading(false)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }

  // Copy to clipboard
  const copyToClipboardHandler = (text) => {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    setIsCopiedToClipboard(true);
    setTimeout(() => {
      setIsCopiedToClipboard(false);
    }, 3000);
    return result;
  }

  // Add email
  const addEmailHandler = (e) => {
    e.preventDefault();
    const copyOfData = { ...formData };
    const name = `recipientEmail-${Date.now()}`;
    copyOfData[name] = { value: '', email: true, name };
    setFormData(copyOfData);
  }

  // Delete email
  const deleteEmailHandler = (name, e) => {
    e.preventDefault();
    const copyOfData = { ...formData };
    delete copyOfData[name];
    setFormData(copyOfData);
  }

  // Open file browser
  const openFileBrowser = (e) => {
    e.preventDefault();
    fileInput.current.click();
  }

  // Wrap text with link
  const textToReplace = (text) => {
    // TODO - change to use other languages or even better implement in t function from i18n 
    const textToReplace = 'ajoutez';
    const replaceWith = <a href="#/" onClick={openFileBrowser}>{textToReplace}</a>;
    const splitText = text.split(textToReplace);
    return <Fragment>{ splitText[0]} {replaceWith} {splitText[1]}</Fragment>;
  }

  // File change handler - upload attachment
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if(attachmentsNames.includes(file.name)) {
      setNotification({ msg: t('notification.file_already_exists', { file: file.name }), type: 'warning' });
    }else {
      onFileSubmitted(file);
    }
  }

  // On file submitted
  const onFileSubmitted = (file) => {
    if (file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = file.name.split('.')
        const format = components[components.length - 1]
        components.splice(components.length - 1, 1)
        const name = components.join('.')
        const type = file.type
        // addFile({ data: e.target.result, name: name, format: format })
       
        // fileInputRef.current.value = ""
        // console.log('add file', { data: e.target.result, name: name, format: format })
        onAddAttachment({ data: e.target.result, name: name, format: format, type: type, share: false })
        setEmailAttachments(prev => ([
          ...prev,
          { data: e.target.result, name: name, format: format, type: type, share: false }
        ]))
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(file);
    } else {
      // no files 
    }
  }

  // Add watermark to link change handler
  const addWatermarkToLinkChangeHandler = (e) => {
    let checked = e.target.checked
    if(watermarkLinkRef.current) {
      clearTimeout(watermarkLinkRef.current)
    }
    setAddWatermarkToLink(checked)
    watermarkLinkRef.current = setTimeout(() => {
      if(doc.link_watermark && !checked) {
        updateDocument({ create_action: 'no', link_watermark: false }, doc);
      }else if(!doc.link_watermark && checked){
        updateDocument({ create_action: 'no', link_watermark: true }, doc);
      }
    }, 1000)
  }

  const renderAttachments = () => {
    return (
      <div className="share-attachments-wrapper">
        <p className="share-attachments-wrapper__label">{textToReplace(t('general.attachments_to_share_2'))}</p>
        <input type="file" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpg,image/jpeg,image/gif" onChange={onFileChange} ref={fileInput} className="hidden" />
        <div className="share-attachments-wrapper__box">
          { emailAttachments.map((att, i) => {
            return (
              <div className="share-attachments-wrapper__box_item" key={i}>
                <Checkbox 
                  onChange={(e) => attachmentsChangeHandler(i, e.target.checked)} 
                  checked={att.share} 
                />
                <span>{ `${att.name} (${att.type})` }</span>
              </div>
            )
          })}
          {emailAttachments.length === 0 && <p>{t('general.no_attachments')}</p>}
        </div>
      </div>
    )
  }

  return (
    <Modal onClose={onClose} className="send-by-email-modal">
      { isLoading ? <LineLoader /> : null}
      <div className="send-by-email">
        <h2>{documentName}</h2>
        <div className="send-by-email__head">
          <ul>
            <li className={view === 'email' ? 'active' : null} onClick={() => setView('email')}>{t('general.send_by_email')}</li>
            <li className={view === 'link' ? 'active' : null} onClick={() => setView('link')}>{t('general.show_link')}</li>
          </ul>
        </div>
        <div className="send-by-email__body">
          {view === 'email' &&
            <form className="form" onSubmit={submitHandler}>
              {Object.values(recipientEmails).map((email, i) => (
                <div className="form__group recipients" key={email.name}>
                  <Input
                    name={email.name}
                    value={email.value}
                    onChange={changeHandler}
                    label={t('general.recipient_email')}
                    formEl
                    error={errors[email.name]}
                  />
                  {i === 0 ? 
                    <CustomTooltip content={t('general.add_recipient')}>
                      <IconButton 
                        onButtonClick={addEmailHandler}
                        icon={<PersonAddIcon />}
                        lightGray
                      />
                    </CustomTooltip>
                    : 
                    <CustomTooltip content={t('general.delete_recipient')}>
                      <IconButton 
                        onButtonClick={(e) => deleteEmailHandler(email.name, e)}
                        icon={<DeleteIcon />}
                        lightGray
                      />
                    </CustomTooltip>
                  }
                </div>
              ))}
              <div className="form__group">
                <Input
                  name="subject"
                  value={formData.subject.value}
                  onChange={changeHandler}
                  label={t('general.message_subject')}
                  formEl
                />
              </div>
              <div className="editor">
                <p>{t('general.your_message')}</p>
                <Editor
                  initialValue={formData.message.value}
                  tagName="editor"
                  init={{
                    height: 317,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help'
                  }}
                  onEditorChange={handleEditorChange}
                />
              </div>

              { renderAttachments() }

              {/* <div className="checkbox-add-watermark">
                <Checkbox 
                  onChange={(e) => setAddWatermark(e.target.checked)} 
                  checked={addWatermark} 
                  label={t('share_email.add_watermark')}
                />
              </div> */}

              <div className="form__actions">
                <Button
                  text={t('general.cancel')}
                  transparent
                  onButtonClick={onClose}
                  medium
                />
                <Button
                  text={t('general.send_to')}
                  type="submit"
                  primary
                  medium
                  bold
                />
              </div>

              {emailsHistory.length > 0 && <div className="send-by-email__body_history">
                <h4>{t('share_email.document_shared_with')}</h4>
                <ul>
                  {emailsHistory.map((sentEmail, i) => (
                    <li key={i}>
                      <div className="recipients">
                        {sentEmail.recipients.map((r) => <p key={r}>
                          <span className="thumb"><img src={`http://www.google.com/s2/favicons?domain=www.${r.split('@')[1]}`} alt="" /></span>{r}</p>
                        )}
                      </div>
                      <p className="date">{moment(sentEmail.timestamp).format('DD MMM HH:mm')}</p>
                    </li>
                  ))}
                </ul>
              </div>
              }
            </form>
          }

          {view === 'link' &&
            <div className="qr-code-section">
              <div className="qr-code-section__inner">
                <div className="qr-code-section__left">
                  <div className="qr-code-section__left_text">
                    <p>{`https://europe-west1-carbon-contract-management.cloudfunctions.net/document${urlSuffixForEnvironment(config.environment, config.staging)}/${documentId}`}</p>
                  </div>
                  <Button
                    text={isCopiedToClipboard ? t('share_email.link_copied') : t('share_email.copy_to_clipboard')}
                    onButtonClick={() => copyToClipboardHandler(`https://europe-west1-carbon-contract-management.cloudfunctions.net/document${urlSuffixForEnvironment(config.environment, config.staging)}/${documentId}`)}
                    primary
                    icon={isCopiedToClipboard ? <DoneIcon /> : <i className="custom-icon-content-copy"></i>}
                  />
                </div>
                <div className="qr-code-section__right">
                  <QRCode 
                    value={`https://europe-west1-carbon-contract-management.cloudfunctions.net/document${urlSuffixForEnvironment(config.environment, config.staging)}/${documentId}`}
                    size={102} 
                    imageSettings={{ 
                      width: 102, 
                      height: 102,
                      src: `https://europe-west1-carbon-contract-management.cloudfunctions.net/document${urlSuffixForEnvironment(config.environment, config.staging)}/${documentId}`
                    }}
                  />
                  <div className="qr-code-section__right_text">
                    <i className="custom-icon-qr-code-scanner"></i>
                    <p>{t('share_email.scan_qr_code')}</p>  
                  </div>
                </div>
              </div>
              {/* <div className="checkbox-add-watermark">
                <Checkbox 
                  onChange={addWatermarkToLinkChangeHandler} 
                  checked={addWatermarkToLink} 
                  label={t('share_email.add_watermark')}
                />
              </div> */}
            </div>
          }
        </div>
      </div>
    </Modal>
  );
}

export default SendByEmailModal;
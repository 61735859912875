import { useContext, useState, useEffect, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import CustomTooltip from '../../UI/CustomTooltip'
import LetterCircle from '../../UI/LetterCircle'
import { AllTasksModal, CreateTaskModal, EditTaskModal, WidgetDropdown } from '../'
import { GlobalContext, TaskContext, TeamContext, DocumentsContext, UserContext } from '../../../context'
import { useTaskActions } from '../../../hooks'

const TasksWidget = forwardRef(({ style, dragging, isSorting, ...props }, ref) => {
  const { t } = useContext(GlobalContext)
  const { notCompletedTasks, completedTasks } = useContext(TaskContext)
  const { getUserNameById } = useContext(TeamContext)
  const { getDocumentById } = useContext(DocumentsContext)
  const { widgetsInfo } = useContext(UserContext)
  const { changeTaskStatus, deleteSingleTask } = useTaskActions()
  const [tasksArr, setTasksArr] = useState([])
  const [progress, setProgress] = useState(0)
  const [showAllTasks, setShowAllTasks] = useState(false)
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false)
  const [showEditTaskModal, setShowEditTaskModal] = useState(false)
  const [taskToEdit, setTaskToEdit] = useState(null)
  const [showPanel, setShowPanel] = useState(false)
  const history = useHistory()

  // Set tasks arr
  useEffect(() => {
    const tasks = [...notCompletedTasks, ...completedTasks]
    setTasksArr(tasks)
    if(completedTasks.length > 0 && tasks.length > 0) {
      setProgress(Math.round((completedTasks.length / tasks.length) * 100))
    }
  }, [notCompletedTasks, completedTasks])

  // On doc click
  const handleDocClick = async (e, docId) => {
    e.preventDefault()
    if(docId) {
      history.push(`/document-detail/${docId}`, { openTasks: true })
    }
  }

  // On task delete
  const handleTaskDelete = async (e, task) => {
    e.preventDefault()
    await deleteSingleTask({ task })
  }

  // On status change
  const handleChangeStatus = async (task) => {
    await changeTaskStatus({ task })
  }

  // On open all tasks modal
  const handleOpenAllTasksModal = () => {
    setShowAllTasks(true)
  }

  // On close all tasks modal
  const handleCloseAllTasksModal = () => {
    setShowAllTasks(false)
  }

  // On open create task modal 
  const handleOpenCreateTaskModal = (e) => {
    e.preventDefault()
    setShowCreateTaskModal(true)
  }

  // On close create task modal
  const handleCloseCreateTaskModal = () => {
    setShowCreateTaskModal(false)
  }

  // On open edit task modal 
  const handleOpenEditTaskModal = (task) => {
    setShowEditTaskModal(true)
    setTaskToEdit(task)
  }

  // On close edit task modal
  const handleCloseEditTaskModal = () => {
    setShowEditTaskModal(false)
  }

  // On open widget panel
  const handleOpenPanel = () => {
    setShowPanel(true)
  }

  // On close widget panel
  const handleClosePanel = () => {
    setShowPanel(false)
  }

  // Render task
  const renderTask = (task, idx) => {
    let assignedTo = ''
    if(task.assigned_to) {
      assignedTo = getUserNameById(task.assigned_to)
    }

    let dateClass = ''

    if(!task.completed && task.due_date) {
      if(moment(task.due_date).isSame(new Date(new Date().setHours(0,0,0,0)).toString())) {
        dateClass = 'current-date'
      }else if(moment(task.due_date).isBefore(new Date(new Date().setHours(0,0,0,0)).toString())) {
        dateClass = 'already-passed'
      }
    }else {
      if(task.due_date) {
        dateClass = 'done'
      }
    }

    return (
      <li key={idx} className="task-item">
        <div className="task-item__left u-truncate-text-wrapper">
          <div className={`task-item__left_status ${task.completed ? 'completed' : ''}`} onClick={() => handleChangeStatus(task)}>
            {task.completed && <span className="material-symbols-outlined">done</span>}
          </div>
          <p className={`u-truncate-text ${task.completed ? 'completed' : ''}`} onClick={() => handleOpenEditTaskModal(task)}>{task.name}</p>
        </div>
        <div className="task-item__right">
          <div className="task-item__right_default">
            {assignedTo && (
              <div className="task-item__right_assigned-to">
                <CustomTooltip content={assignedTo}>
                  <LetterCircle data={assignedTo} size={24} />
                </CustomTooltip>
              </div>
            )}

            {task.due_date && (
              <div className={`task-item__right_date ${dateClass}`}>
              {moment(task.due_date).format(`DD MMM`)}{moment().year() !== moment(task.due_date).year() && `, ${moment(task.due_date).year()}`}
              </div>
            )}
          </div>
          <div className="task-item__right_hover">
            {task.relatedDocument && getDocumentById(task.relatedDocument) && (
              <CustomTooltip content={t('dashboard.see_document')}>
                <a href="/#" className="doc" onClick={(e) => handleDocClick(e, task.relatedDocument)}>
                  <span className="material-symbols-outlined">description</span>
                  <span className="text">{getDocumentById(task.relatedDocument)?.name || ''}</span>
                </a>
              </CustomTooltip>
            )}
            <CustomTooltip content={t('dashboard.delete_task')}>
              <a href="/#" className="delete" onClick={(e) => handleTaskDelete(e, task)}>
                <span className="material-symbols-outlined">delete</span>
              </a>
            </CustomTooltip>
          </div>
        </div>
      </li>
    )
  }

  // Render task
  const renderPanelTask = (task) => {
    let dateClass = ''

    if(!task.completed && task.due_date) {
      if(moment(task.due_date).isSame(new Date(new Date().setHours(0,0,0,0)).toString())) {
        dateClass = 'current-date'
      }else if(moment(task.due_date).isBefore(new Date(new Date().setHours(0,0,0,0)).toString())) {
        dateClass = 'already-passed'
      }
    }else {
      if(task.due_date) {
        dateClass = 'done'
      }
    }

    return (
      <li key={task.id}>
        <p className={`u-truncate-text ${task.completed ? 'u-text-linethrough' : ''}`}>{task.name}</p>
        {task.due_date && <div className={`date ${dateClass}`}>
        {moment(task.due_date).format(`DD MMM`)}{moment().year() !== moment(task.due_date).year() && `, ${moment(task.due_date).year()}`}
        </div>}
      </li>
    )
  }

  if(isSorting) {
    return (
      <div className={`widget widget--placeholder ${dragging ? 'widget--dragging' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4">{t('dashboard.tasks')}</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`widget ${widgetsInfo['item-tasks'].size === 'half' ? 'widget--half' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4" {...props}>{t('dashboard.tasks')}</h4>
          <div className="widget__head_actions">
            <a href="/#" onClick={handleOpenCreateTaskModal}><span className="material-symbols-outlined size-24">add</span></a>
            <WidgetDropdown widget="item-tasks" />
          </div>
        </div>
        <div className="widget__body">
          <div className="task-widget-content">
            <div className="task-widget-content__progress">
              <p className="progress-num">{progress}%</p>
              <div className="progress"><div className="progress__inner" style={{width: `${progress}%`}}></div></div>
            </div>
            <ul className="task-widget-content__list">
              {[...tasksArr].slice(0,5).map((task, idx) => renderTask(task, idx))}
            </ul>
          </div>
        </div>
        <div className="widget__foot">
          <button className="btn btn--primary-light btn--full" onClick={handleOpenPanel}>
            {t('dashboard.see_all_tasks')} 
            {/* <span className="material-symbols-outlined size-24">chevron_right</span> */}
          </button>
        </div>
        <div className={`widget__overlay ${showPanel ? 'show' : ''}`} onClick={handleClosePanel}></div>
        <div className={`widget__panel ${showPanel ? 'opened' : ''}`}>
          <div className="widget__panel_head">
            <h4 className="heading-4">{t('dashboard.tasks')}</h4>
            <div className="close" onClick={handleClosePanel}>
              <span className="material-symbols-outlined size-24">close</span>
            </div>
          </div>
          <div className="widget__panel_body u-custom-scrollbar">
            <ul className="select-list">
              {notCompletedTasks.map((task) => renderPanelTask(task))}
              {completedTasks.map((task) => renderPanelTask(task))}
            </ul>
          </div>
        </div>
      </div>

      {showCreateTaskModal && <CreateTaskModal onClose={handleCloseCreateTaskModal} />}
      {showEditTaskModal && <EditTaskModal onClose={handleCloseEditTaskModal} taskToEdit={taskToEdit} />}
    </>
  )
})

export default TasksWidget 
import React, { useState, useContext, useRef, useEffect, memo } from 'react'
import DatePicker from 'react-datepicker'
import { TodayOutlined, Check } from '@material-ui/icons'

import Input from '../../../UI/Input'
import CustomSelect from '../../../UI/CustomSelect'
import CustomMultipleSelect from '../../../UI/CustomMultipleSelect'
import Button from '../../../UI/Button'
import Textarea from '../../../UI/Textarea'
import AddCustomFields from './AddCustomFields'
import Loader from '../../../UI/Loader'
import { NotificationContext, LoaderContext, DocumentsContext, GlobalContext } from '../../../../context'

const CustomFields = ({ customFields, onSetCustomFields, doc, onSetView, onSetEditStartTime, onStoreEditTime, defaultFields, onSetCustomFieldsValues, customFieldsValues, valuesChanged = false }) => {
  const { t } = useContext(GlobalContext)
  const { updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { setLoadingOverlay } = useContext(LoaderContext)
  const [showAddCustomFieldsModal, setShowAddCustomFieldsModal] = useState(false)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const savedTimeout = useRef()

  // On mount/cleanup
  useEffect(() => {

    return () => {
      if(savedTimeout.current) {
        clearTimeout(savedTimeout.current);
      }
    }
  }, [])

  // On custom date fields change
  const customDateChangeHandler = (key, value) => {
    let values = {...customFieldsValues}
    if(value) {
      values[key] = value.valueOf()
    }else {
      if(values[key]) delete values[key] 
    }
    onSetCustomFieldsValues(values)
  }

  // On custom field change
  const customFieldChangeHandler = (key, value) => {
    let values = {...customFieldsValues}
    if(value) {
      values[key] = value 
    }else {
      if(values[key]) delete values[key] 
    }
    onSetCustomFieldsValues(values)
  }

  // On custom select field change
  const customFieldSelectChangeHandler = (opt, id) => {
    let values = {...customFieldsValues}
    if(opt.value) {
      values[id] = opt.value 
    }else {
      if(values[id]) delete values[id] 
    }
    onSetCustomFieldsValues(values)
  }

  // Custom multiple select change handler
  const customFieldMultipleSelectChangeHandler = (values, id, opt) => {
    const valuesArr = [...values].filter(v => v !== '')
    let cValues = {...customFieldsValues}
    if(valuesArr.length > 0) {
      cValues[id] = valuesArr
    }else {
      if(cValues[id]) delete cValues[id] 
    }
    onSetCustomFieldsValues(cValues)
  }

  // Save click handler - save custom fields to document 
  const saveClickHandler = async () => {
    setSaving(true)
    setLoadingOverlay(true)
    try {
      await updateDocument({ custom_fields_values: customFieldsValues }, doc)
      setSaved(true)
      savedTimeout.current = setTimeout(() => setSaved(false), 2000)
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setLoadingOverlay(false)
    setSaving(false)
    onSetView('custom_fields')
  }

  return (
    <div className="document-detail-sp-section">
      <h4 className="with-border">{t('custom_fields.title')}</h4>
      <div className="document-detail-sp-section__fields">
        <div className="document-popup">
          {customFields.length > 0 && <div className="user-custom-fields-wrapper">
            <div className="custom-fields">
              {customFields.map(cf => {
                if(cf.type === 'date') {
                  return (
                    <div className="date-picker-field date-picker-field--thick-border" key={cf.id}>
                      <div className="date-picker-field__label">{cf.label}</div>
                      <div className="date-picker-field__input">
                        <TodayOutlined className="date-icon" />
                        <DatePicker 
                          selected={customFieldsValues[cf.id] ? new Date(customFieldsValues[cf.id]) : ''} 
                          onChange={(date) => customDateChangeHandler(cf.id, date)} 
                          // onChangeRaw={(e) => e.preventDefault()}
                          isClearable={customFieldsValues[cf.value]}
                          dateFormat={'dd/MM/yyyy'}
                          locale="fr"
                          popperPlacement="top-end"
                          popperModifiers={{preventOverflow: { enabled: true }}} 
                          minDate={new Date()}
                          onCalendarOpen={() => onSetEditStartTime(Date.now())}
                          onCalendarClose={onStoreEditTime}
                        />
                      </div>
                    </div>
                  )
                }else if (cf.type === 'text') { 
                  return <Input 
                    key={cf.id}
                    value={customFieldsValues[cf.id] || ''} 
                    onChange={(e) => customFieldChangeHandler(cf.id, e.target.value)} 
                    type="text"
                    label={cf.label}
                    formEl
                    thickBorder
                    onFocus={() => onSetEditStartTime(Date.now())}
                    onBlur={onStoreEditTime}
                  />
                }else if (cf.type === 'textarea') {
                  return <Textarea 
                    key={cf.id}
                    value={customFieldsValues[cf.id] || ''}
                    onChange={(e) => customFieldChangeHandler(cf.id, e.target.value)}
                    label={cf.label}
                    formEl
                    thickBorder
                    onFocus={() => onSetEditStartTime(Date.now())}
                    onBlur={onStoreEditTime}
                  />
                }else if (cf.type === 'number') {
                  return <Input 
                    key={cf.id}
                    value={customFieldsValues[cf.id] || ''} 
                    onChange={(e) => customFieldChangeHandler(cf.id, e.target.value)} 
                    type="number"
                    label={cf.label}
                    formEl
                    thickBorder
                    onFocus={() => onSetEditStartTime(Date.now())}
                    onBlur={onStoreEditTime}
                  />
                }else if(cf.type === 'select') {
                  let options = cf.options ? [...cf.options] : [] 
                  options.unshift({ value: '', label: 'Please select one option' })
                  return <CustomSelect 
                    key={cf.id}
                    label={cf.label}
                    options={options}
                    onChange={(opt) => customFieldSelectChangeHandler(opt, cf.id)}
                    active={options.find(o => o.value === customFieldsValues[cf.id]) || options[0]}
                    fullWidth
                    thickBorder
                    formEl
                    onFocus={() => onSetEditStartTime(Date.now())}
                    onBlur={onStoreEditTime}
                  />
                }
                else if(cf.type === 'multiselect') {
                  let options = cf.options ? [...cf.options] : [] 
                  return <CustomMultipleSelect 
                    key={cf.id}
                    label={cf.label}
                    options={options}
                    onChange={(arr, opt) => customFieldMultipleSelectChangeHandler(arr, cf.id, opt)}
                    active={customFieldsValues[cf.id] ? options.filter(o => customFieldsValues[cf.id].includes(o.value)) : []}
                    fullWidth
                    thickBorder
                    formEl
                    multipleSelectedText={t('custom_fields.number_of_options_selected')}
                    onFocus={() => onSetEditStartTime(Date.now())}
                    onBlur={onStoreEditTime}
                  />
                }
                return null
              })}
            </div>
          </div>}

          {/* <div className="link-wrapper">
            <a href="/#" className="add" onClick={(e) => { e.preventDefault(); setShowAddCustomFieldsModal(true) }}>{t('custom_fields.add_edit')}</a>
          </div> */}
          <Button  
            text={t('custom_fields.add_edit')}
            onButtonClick={() => setShowAddCustomFieldsModal(true)}
            primaryLight
            className="add-btn"
            fullWidth
          />

          <Button 
            text={saving ? <Loader mini normalWhite /> : saved ? <Check /> : t('general.save')} 
            onButtonClick={saveClickHandler} 
            primary 
            className="save"
            disabled={!valuesChanged}
          />

        </div>
      </div>
      {showAddCustomFieldsModal && <AddCustomFields 
        onClose={() => setShowAddCustomFieldsModal(false)} 
        doc={doc} 
        data={customFields}
        onSetData={onSetCustomFields} 
        onSetEditStartTime={onSetEditStartTime}
        onStoreEditTime={onStoreEditTime}
        customFieldsValues={customFieldsValues}
      />}
    </div>
  )
}

export default memo(CustomFields)
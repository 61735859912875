import React, { Fragment, useRef, useState, useCallback, useEffect, useContext } from 'react';
// import SearchIcon from '@material-ui/icons/Search';
// import BookmarkIcon from '@material-ui/icons/BookmarkBorderOutlined';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolderOutlined';

// import Input from '../../UI/Input';
import FolderListItem from '../../UI/FolderListItem';
import CustomTooltip from '../../UI/CustomTooltip';
import FoldersPopup from '../FoldersPopup';
import Loader from '../../UI/Loader';

import { FolderContext, LoaderContext, DocumentsContext, NotificationContext, GlobalContext } from '../../../context'

const TemplatesSidebar = ({ folders, onFolderEdit, onFolderDelete, onFolderCreate, onFolderFilter, selectedFilter, onOpenFolderModal, onMoveToFolder, foldersLoading, templatesLoading, templates, onMoveTemplateToFolder, disableFolders }) => {
  const { t } = useContext(GlobalContext)
  // const [search, setSearch] = useState('');
  const { currentlyActiveFolder, updateFolder } = useContext(FolderContext);
  const { setLoading } = useContext(LoaderContext);
  const { updateTemplate } = useContext(DocumentsContext);
  const { setNotification } = useContext(NotificationContext);

  // Drag over - all
  const dragOverHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(!folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover');
    }
  }

  // Drag leave - all
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
  }

  // On drop - all
  const dropHandler = async(e) => {
    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }

    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null;
    
    if(data) {
      if(!data.parentFolder) 
        return setNotification({ msg: t('folder.unable_to_move_to_root', { folder: data.name }), type: 'warning' });
  
      setLoading(true);
      await updateFolder({ parentFolder: null }, data.id);
      setLoading(false);
      setNotification({ msg: t('folder.moved_to_root', { folder: data.name }), type: 'default' }); 
      return;
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null;
    
    if(templateData) {
      let template = {...templateData};
      if(!template.folderId) {
        template = {...templateData, folderId: []};
      }

      if(template.folderId.length === 0)
        return setNotification({ msg: t('folder.unable_to_move_template_to_root', { template: template.name }), type: 'warning' });

      const foldersArr = [...template.folderId];
      if(currentlyActiveFolder && foldersArr.includes(currentlyActiveFolder.id)) {
        const updatedFoldersArr = foldersArr.filter(fId => fId !== currentlyActiveFolder.id);
        setLoading(true);
        await updateTemplate({ folderId: updatedFoldersArr }, template.id);
        setLoading(false);
        setNotification({ msg: t('folder.template_moved_to_root', { template: template.name }), type: 'default' });
      }    
    }
  }

  return(
    <Fragment>
      <h3 className="mb-40">{t('dashboard.templates')}</h3>
      {/* <form className="form form--search">
        <Input
          name="search-sidebar"
          placeholder="Rechercher"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          iconEl={<SearchIcon />}
        />
      </form>
      <div className="current">
        <BookmarkIcon />
        <p>Mes favoris</p>
      </div> */}
      <section className="filters">
        <h6>{t('dashboard.templates')}</h6>
        <ul className="filters__list folders">
          <li className={selectedFilter === 'all' ? 'filters__list_item active' : 'filters__list_item'}>
            <div className="item-inner" onDrop={dropHandler} onDragLeave={dragLeaveHandler} onDragOver={dragOverHandler}>
              <p className="all" onClick={() => onFolderFilter(null)}>{t('general.all_templates')}</p> 
              {!disableFolders && <CustomTooltip content={t('folder.create')}><span className="icon-right" onClick={(e) => onFolderCreate(e)}><CreateNewFolderIcon /></span></CustomTooltip>}
            </div>
          </li>
          {folders.map(folder => {
            if(!folder.parentFolder) {
              return <FolderListItem 
                key={folder.id} 
                folder={folder} 
                onCreateFolder={onFolderCreate}
                onEdit={onFolderEdit} 
                onDelete={onFolderDelete} 
                onFilter={onFolderFilter}
                selectedFilter={selectedFilter} 
                onOpenFolderModal={onOpenFolderModal}
                onMoveToFolder={onMoveToFolder}
                folders={folders}
                foldersLoading={foldersLoading}
                templates={templates}
                templatesLoading={templatesLoading}
                onMoveTemplateToFolder={onMoveTemplateToFolder}
                disableFolders={disableFolders}
              />;
            }
            return null;
          })}
        </ul>
      </section>
    </Fragment>
  );
}

export default TemplatesSidebar;
import { useContext } from 'react'

import ActionsDropdown from "../../UI/ActionsDropdown"
import { GlobalContext, UserContext } from '../../../context'
import { useWidgetActions } from '../../../hooks'

const WidgetDropdown = ({ widget }) => {
  const { t } = useContext(GlobalContext)
  const { widgetsInfo } = useContext(UserContext)
  const { changeWidgetSize, removeWidget } = useWidgetActions()

  // On change to half size
  const handleChangeToHalfSize = (e) => {
    e.preventDefault()
    changeWidgetSize({ size: 'half', widget })
  }

  // On change to half size
  const handleChangeToFullSize = (e) => {
    e.preventDefault()
    changeWidgetSize({ size: 'full', widget })
  }

  // On remove widget
  const handleRemoveWidget = (e) => {
    e.preventDefault()
    removeWidget({ widget })
  }

  return (
    <ActionsDropdown
      trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined size-24">more_horiz</span></button>}
      hideHeader
      width={160}
      dropdownClass="actions-dropdown-v2"
    >
      <ul className="default-list">
        <li>
          <a href="/#" onClick={handleChangeToHalfSize}>
            {widgetsInfo[widget].size === 'half' 
              ? <span className="icon"><span className="material-symbols-outlined size-24">done</span></span>
              : <span className="icon">&nbsp;</span>} {t('widgets.half_size')}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleChangeToFullSize}>
            {widgetsInfo[widget].size === 'full' 
              ? <span className="icon"><span className="material-symbols-outlined size-24">done</span></span>
              : <span className="icon">&nbsp;</span>} {t('widgets.full_size')}
          </a>
        </li>
        <li>
          <a href="/#" onClick={handleRemoveWidget} className="delete">
            <span className="icon"><span className="material-symbols-outlined size-24">delete</span></span>
            {t('general.remove')}
          </a>
        </li>
      </ul>
    </ActionsDropdown>
  )
}

export default WidgetDropdown
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
  memo,
} from 'react';
import CheckIcon from '@material-ui/icons/Check';

import Input from '../../UI/Input';
import CustomSelect from '../../UI/CustomSelect';
import CustomMultipleSelect from '../../UI/CustomMultipleSelect';
import TagsInput from '../../UI/TagsInput';
import Textarea from '../../UI/Textarea';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader';
import TagsModal from '../TagsModal';
import {
  TagContext,
  DocumentsContext,
  NotificationContext,
  LoaderContext,
  TeamContext,
  GlobalContext,
  ConstantsContext,
} from '../../../context';
import { didObjectChanged } from '../../../utils';
import { create_tag, fetch_tags } from '../../../services/firestore';

const DocumentDetailSPTags = ({
  docInformations,
  onSetDocInformations,
  docInformationsDefault,
  doc,
  onSetView,
  onSetUpdateSection,
  onSetEditStartTime,
  onStoreEditTime,
}) => {
  const { t } = useContext(GlobalContext);
  const { LANGUAGES, DOCUMENT_TYPES } = useContext(ConstantsContext);
  const { tags, getTagById, setTags, tagsFetched, fetchTags } =
    useContext(TagContext);
  const { updateDocument } = useContext(DocumentsContext);
  const { setNotification } = useContext(NotificationContext);
  const { setLoadingOverlay } = useContext(LoaderContext);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext);
  const [languages, setLanguages] = useState([
    {
      value: '',
      label: t('general.choose'),
      active: !docInformations.language,
    },
    ...[...LANGUAGES].map((lang) => ({
      value: lang.key,
      label: lang.value,
      active: docInformations.language === lang.key,
      withBorder: lang.key === 'NL_Dutch',
    })),
  ]);
  const [categories, setCategories] = useState([
    {
      value: '',
      label: t('general.choose'),
      active:
        docInformations.categories && docInformations.categories.length > 0
          ? false
          : true,
    },
    ...[...DOCUMENT_TYPES].map((docT) => ({
      value: docT.key,
      label: docT.value,
      active: docInformations.categories?.includes(docT.key) ? true : false,
    })),
  ]);
  const [currencies, setCurrencies] = useState([
    {
      value: 'euro',
      label: '€',
      active: !docInformations.currency || docInformations.currency === 'euro',
    },
    {
      value: 'dollar',
      label: '$',
      active: docInformations.currency === 'dollar',
    },
    {
      value: 'pound',
      label: '£',
      active: docInformations.currency === 'pound',
    },
  ]);
  const [amount, setAmount] = useState(
    docInformations.amount ? docInformations.amount : ''
  );
  const [personalNote, setPersonalNote] = useState(
    docInformations.personal_note ? docInformations.personal_note : ''
  );
  const [defaultTags, setDefaultTags] = useState(
    docInformations.keywords
      ? [...docInformations.keywords]
          .filter((k) => getTagById(k))
          .map((k) => getTagById(k))
      : []
  );
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [fetchingCollections, setFetchingCollections] = useState(false);
  const savedTimeout = useRef();

  // Fetch tags if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if (!tagsFetched) {
        // console.log('fetch tags')
        fetchTags(teamId);
      }
    };
    if (selectedTeam && !fetchingCollections) {
      setFetchingCollections(true);
      fetchCollections(selectedTeam.id);
    }
  }, [selectedTeam, fetchingCollections, tagsFetched, fetchTags]);

  // On mount/cleanup
  useEffect(() => {
    return () => {
      if (savedTimeout.current) {
        clearTimeout(savedTimeout.current);
      }
    };
  }, []);

  // Disable/enable save button and cancel button on data change
  useEffect(() => {
    if (didObjectChanged(docInformations, docInformationsDefault, ['tags'])) {
      setFieldsChanged(true);
      onSetUpdateSection((prev) => ({
        ...prev,
        info: true,
      }));
    } else {
      setFieldsChanged(false);
      onSetUpdateSection((prev) => ({
        ...prev,
        info: false,
      }));
    }
  }, [docInformations, docInformationsDefault, onSetUpdateSection]);

  // currency
  useEffect(() => {
    onSetDocInformations((prev) => ({
      ...prev,
      currency: currencies.find((c) => c.active).value,
    }));
  }, []);

  // Custom select change handler
  const customSelectChangeHandler = (option, setOptions, name) => {
    onSetDocInformations((prev) => ({
      ...prev,
      [name]: option.value,
    }));
    setOptions((prev) => {
      const copyOfOptions = [...prev];
      const updatedOptions = copyOfOptions.map((opt) =>
        opt.value === option.value
          ? { ...opt, active: true }
          : { ...opt, active: false }
      );
      return updatedOptions;
    });
  };

  // Custom multiple select change handler
  const customMultipleSelectChangeHandler = (
    values,
    option,
    setOptions,
    name
  ) => {
    const valuesArr = [...values].filter((v) => v !== '');
    onSetDocInformations((prev) => ({
      ...prev,
      [name]: valuesArr,
    }));
    setOptions((prev) => {
      const copyOfOptions = [...prev];
      const updatedOptions =
        valuesArr.length > 0
          ? copyOfOptions.map((opt, i) =>
              opt.value === option.value
                ? { ...opt, active: option.active }
                : { ...opt, active: i === 0 ? false : opt.active }
            )
          : copyOfOptions.map((opt, i) =>
              i === 0 ? { ...opt, active: true } : { ...opt, active: false }
            );
      return updatedOptions;
    });
  };

  // Tags change handler
  const tagsChangeHandler = (name, tags) => {
    let arr = [];
    if (tags.length > 0) {
      if (typeof tags[0] === 'string') {
        arr = [...tags];
      } else {
        arr = [...tags].map((k) => k.id);
      }
    }
    onSetDocInformations((prev) => ({
      ...prev,
      [name]: arr,
      tags: tags,
    }));
  };

  // Input change handler
  const inputChangeHandler = (name, value, setValue) => {
    setValue(value);
    onSetDocInformations((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // On cancel - restore prev data
  const cancelClickHandler = () => {
    onSetDocInformations(docInformationsDefault);
    restoreData();
  };

  // Restore data
  const restoreData = () => {
    setLanguages([
      {
        value: '',
        label: t('general.choose'),
        active: !docInformationsDefault.language,
      },
      ...[...LANGUAGES].map((lang) => ({
        value: lang.key,
        label: lang.value,
        active: docInformationsDefault.language === lang.key,
        withBorder: lang.key === 'NL_Dutch',
      })),
    ]);
    setCategories([
      {
        value: '',
        label: t('general.choose'),
        active:
          docInformationsDefault.categories &&
          docInformationsDefault.categories.length > 0
            ? false
            : true,
      },
      ...[...DOCUMENT_TYPES].map((docT) => ({
        value: docT.key,
        label: docT.value,
        active: docInformationsDefault.categories?.includes(docT.key)
          ? true
          : false,
      })),
    ]);
    setCurrencies([
      {
        value: 'euro',
        label: '€',
        active:
          !docInformationsDefault.currency ||
          docInformationsDefault.currency === 'euro',
      },
      {
        value: 'dollar',
        label: '$',
        active: docInformationsDefault.currency === 'dollar',
      },
      {
        value: 'pound',
        label: '£',
        active: docInformationsDefault.currency === 'pound',
      },
    ]);
    setAmount(
      docInformationsDefault.amount ? docInformationsDefault.amount : ''
    );
    setPersonalNote(
      docInformationsDefault.personal_note
        ? docInformationsDefault.personal_note
        : ''
    );
    setDefaultTags(
      docInformationsDefault.keywords
        ? [...docInformationsDefault.keywords]
            .filter((k) => getTagById(k))
            .map((k) => getTagById(k))
        : []
    );
  };

  // Save click handler
  const saveClickHandler = async () => {
    let tagsCreated = false;
    let keywordsAdded = false;
    const keywords = [];

    setLoadingOverlay(true);
    setSaving(true);
    try {
      // Check if tags were added
      if (docInformations.tags) {
        if (docInformations.tags.length > 0) {
          for (let i = 0; i < docInformations.tags.length; i++) {
            const tagId = docInformations.tags[i].id;
            if (!tags.find((t) => t.id === tagId)) {
              const res = await create_tag({
                name: docInformations.tags[i].name,
                team: selectedTeam?.id,
              });
              tagsCreated = true;
              keywords.push({
                name: docInformations.tags[i].name,
                id: res.taskId,
              });
            } else {
              keywords.push({ name: docInformations.tags[i].name, id: tagId });
              keywordsAdded = true;
            }
          }
        } else {
          docInformations.keywords = [];
        }
      }

      // Check if new tags were created or if they exist for this document
      if (tagsCreated || keywordsAdded) {
        const res = await fetch_tags(selectedTeam?.id);
        const tagsArr = [];
        for (let id in res) {
          const tag = { ...res[id] };
          tag.id = id;
          tagsArr.push(tag);
        }
        setTags(tagsArr);
        if (keywords.length > 0) {
          docInformations.keywords = [...keywords].map((k) => k.id);
        }
      }
      if (docInformations.tags) {
        delete docInformations.tags;
      }
      await updateDocument(
        {
          info: docInformations,
          last_modified_by: activeTeamMember.id,
          create_action: 'yes',
        },
        doc
      );
      setSaved(true);
      savedTimeout.current = setTimeout(() => setSaved(false), 2000);
    } catch (err) {
      console.log(err);
      setNotification({
        msg: t('notification.something_went_wrong'),
        type: 'danger',
      });
    }
    onSetView('tags');
    setLoadingOverlay(false);
    setSaving(false);
  };

  // On enter or escape save document or reset data
  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (fieldsChanged && !e.target.closest('.dont-save-on-enter')) {
          saveClickHandler();
          // console.log('save')
        }
      } else if (e.key === 'Escape') {
        if (fieldsChanged) {
          cancelClickHandler();
        }
      }
      // eslint-disable-next-line
    },
    [fieldsChanged, docInformations]
  );

  // Add event keydown event listener on component mount
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <div className="document-detail-sp-section">
      <h4 className="with-border">{t('dashboard.contract_info')}</h4>
      {!tagsFetched ? (
        <div className="loader-wrapper loader-wrapper--no-padding">
          <Loader small normal />
        </div>
      ) : (
        <>
          <div className="document-detail-sp-section__fields">
            <CustomSelect
              options={languages}
              active={languages.find((lang) => lang.active)}
              onChange={(opt) =>
                customSelectChangeHandler(opt, setLanguages, 'language')
              }
              formEl
              label={t('dashboard.contract_lang')}
              thickBorder
              onFocus={() => onSetEditStartTime(Date.now())}
              onBlur={onStoreEditTime}
            />
            <CustomMultipleSelect
              options={categories}
              active={categories.filter((c) => c.active)}
              onChange={(values, opt) =>
                customMultipleSelectChangeHandler(
                  values,
                  opt,
                  setCategories,
                  'categories'
                )
              }
              formEl
              label={t('dashboard.contract_category')}
              multipleSelectedText={t('dashboard.selected_categories')}
              thickBorder
              onFocus={() => onSetEditStartTime(Date.now())}
              onBlur={onStoreEditTime}
            />
            <TagsInput
              label={t('dashboard.keywords')}
              placeholder={t('dashboard.separate_keywords')}
              name="keywords"
              onChange={tagsChangeHandler}
              // defaultTags={docInformations.keywords || []}
              defaultTags={defaultTags}
              editLink={tags.length !== 0}
              onEditLinkClick={() => setShowTagsModal(true)}
              showSuggestions
              suggestions={tags}
              thickBorder
              className="dont-save-on-enter"
              onFocus={() => onSetEditStartTime(Date.now())}
              onBlur={onStoreEditTime}
            />
            <div className="fields-wrapper--tags">
              <Input
                label={t('dashboard.amount')}
                placeholder={t('dashboard.value')}
                type="number"
                name="amount"
                value={amount}
                onChange={(e) =>
                  inputChangeHandler(e.target.name, e.target.value, setAmount)
                }
                formEl
                thickBorder
                onFocus={() => onSetEditStartTime(Date.now())}
                onBlur={onStoreEditTime}
              />
              <CustomSelect
                options={currencies}
                active={currencies.find((c) => c.active)}
                onChange={(opt) =>
                  customSelectChangeHandler(opt, setCurrencies, 'currency')
                }
                thickBorder
                formEl
                onFocus={() => onSetEditStartTime(Date.now())}
                onBlur={onStoreEditTime}
              />
            </div>
            <Textarea
              label={t('dashboard.personal_note')}
              name="personal_note"
              value={personalNote}
              onChange={(e) =>
                inputChangeHandler(
                  e.target.name,
                  e.target.value,
                  setPersonalNote
                )
              }
              thickBorder
              formEl
              className="dont-save-on-enter"
              onFocus={() => onSetEditStartTime(Date.now())}
              onBlur={onStoreEditTime}
            />
          </div>
          <div className="document-detail-sp-section__actions">
            <Button
              text={
                saving ? (
                  <Loader mini normalWhite />
                ) : saved ? (
                  <CheckIcon />
                ) : (
                  t('general.save')
                )
              }
              primary
              onButtonClick={saveClickHandler}
              disabled={!fieldsChanged}
            />
            {fieldsChanged && (
              <Button
                text={t('general.cancel')}
                onButtonClick={cancelClickHandler}
              />
            )}
          </div>
        </>
      )}

      {showTagsModal && (
        <TagsModal
          onClose={() => setShowTagsModal(false)}
          doc={doc}
          onSetDocInformations={onSetDocInformations}
          onSetView={onSetView}
        />
      )}
    </div>
  );
};

export default memo(DocumentDetailSPTags);

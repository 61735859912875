import { useContext } from 'react'

import { GlobalContext } from '../../../context'

const DraggedWidget = ({ item }) => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="dragged-widget">
      <p>{item?.title && t(item.title)}</p>
    </div>
  )
}

export default DraggedWidget
import { useState, useContext, useEffect, useCallback } from 'react' 

import { Modal, Input, Select, Option, DateInput } from '../../new_ui'
import { ContactsContext, TeamContext, ContactGroupsContext, GlobalContext } from '../../../context'
import { sortArrayOfObjects } from '../../../utils'
import { useSingleContactActions } from '../../../hooks'

const CreateEditContactModal = ({ onClose, mode = 'create', contactToEdit, refreshContacts }) => {
  const { t } = useContext(GlobalContext)
  const { contactsSelectedFilters } = useContext(ContactsContext)
  const { contactGroups } = useContext(ContactGroupsContext)
  const { selectedTeam } = useContext(TeamContext)
  const { createSingleContact, updateSingleContact } = useSingleContactActions()
  const [group, setGroup] = useState(contactsSelectedFilters.group)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState('')
  const [address, setAddress] = useState('')
  const [streetNumber, setStreetNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [dob, setDob] = useState('')
  const [nationality, setNationality] = useState('')
  const [website, setWebsite] = useState('')
  const [customContactFields, setCustomContactFields] = useState([])

  // If in edit mode prefill fields when modal is opened
  useEffect(() => {
    if(mode === 'edit' && contactToEdit) {
      contactToEdit.first_name && setFirstName(`${contactToEdit.first_name}`)
      contactToEdit.last_name && setLastName(`${contactToEdit.last_name}`)
      contactToEdit.email && setEmail(`${contactToEdit.email}`)
      contactToEdit.title && setTitle(`${contactToEdit.title}`)
      contactToEdit.address && setAddress(`${contactToEdit.address}`)
      contactToEdit.street_number && setStreetNumber(`${contactToEdit.street_number}`)
      contactToEdit.zip_code && setZipCode(`${contactToEdit.zip_code}`)
      contactToEdit.city && setCity(`${contactToEdit.city}`)
      contactToEdit.country && setCountry(`${contactToEdit.country}`)
      contactToEdit.phone_number && setPhoneNumber(`${contactToEdit.phone_number}`)
      contactToEdit.dob && setDob(typeof contactToEdit.dob === 'number' ? new Date(contactToEdit.dob) : contactToEdit.dob)
      contactToEdit.nationality && setNationality(`${contactToEdit.nationality}`)
      contactToEdit.website && setWebsite(`${contactToEdit.website}`)
      setGroup(contactToEdit.group)
    }
  }, [mode, contactToEdit, contactGroups])

  // Set custom contact fields when team is ready
  useEffect(() => {
    if(selectedTeam) {
      const teamContactsCustomColumns = selectedTeam.contacts_custom_columns
      if(teamContactsCustomColumns && Object.keys(teamContactsCustomColumns).length) {
        let arr = []
        for(let key in teamContactsCustomColumns) {
          if(contactToEdit) {
            arr.push({ key, label: teamContactsCustomColumns[key], value: contactToEdit[key] || '' })
          }else {
            arr.push({ key, label: teamContactsCustomColumns[key], value: '' })
          }
        }
        setCustomContactFields(sortArrayOfObjects(arr, 'label', 'desc'))
      }
    }
  }, [selectedTeam, contactToEdit])

  // On group change
  const handleGroupChange = (value) => {
    setGroup(value)
  }

  // On date change
  const handleDateChange = (date) => {
    setDob(date)
  }

  // On custom field change
  const handleCustomFieldChange = (value, field) => {
    let fields = customContactFields.map(f => f.key === field.key ? {...f, value } : f)
    setCustomContactFields(fields)
  }

  // On submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    if(mode === 'create') {
      await createSingleContact({ group, firstName, lastName, email, title, address, streetNumber, zipCode, city, country, phoneNumber, dob, nationality, website, customContactFields, onClose, refreshContacts })
    }else if(mode === 'edit') {
      await updateSingleContact({ group, firstName, lastName, email, title, address, streetNumber, zipCode, city, country, phoneNumber, dob, nationality, website, customContactFields, contactToEdit, onClose, refreshContacts })
    }
  }, [address, city, contactToEdit, country, customContactFields, dob, email, firstName, group, lastName, mode, nationality, onClose, phoneNumber, streetNumber, title, website, zipCode, createSingleContact, updateSingleContact, refreshContacts])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return (
    <Modal 
      onClose={onClose}
      title={mode === 'create' ? t('contacts.create_new') : t('contacts.edit_contact')}
      saveBtnText={mode === 'edit' ? t('general.edit') : t('dashboard.create')}
      onSave={handleSubmit}
      className="modal-v2--large"
    >
      <div className="add-contacts-modal-v2">
        <div className="add-contacts-modal-v2__body">
          <div className="input-group">
            <Select 
              label={`${t('contacts.group')} *`}
              selected={group} 
              onChange={handleGroupChange} 
              className="select-v2--new"
            >
              {contactGroups.map((group, idx) => {
                return (
                  <Option key={idx} value={group.id}>{group.name}</Option>
                )
              })}
            </Select>
          </div>
          <div className="input-group">
            <Input id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} label={`${t('contacts.first_name')} *`} />
            <Input id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} label={`${t('contacts.last_name')} *`} />
          </div>
          <div className="input-group">
            <Input value={email} onChange={(e) => setEmail(e.target.value)} label={`${t('contacts.email')} *`} />
            <Input value={title} onChange={(e) => setTitle(e.target.value)} label={t('contacts.title')} />
          </div>
          <div className="input-group">
            <Input value={address} onChange={(e) => setAddress(e.target.value)} label={t('contacts.address')} />
            <Input value={streetNumber} onChange={(e) => setStreetNumber(e.target.value)} label={t('contacts.street_number')} />
          </div>
          <div className="input-group">
            <Input value={zipCode} onChange={(e) => setZipCode(e.target.value)} label={t('contacts.postcode')} />
            <Input value={city} onChange={(e) => setCity(e.target.value)} label={t('contacts.city')} />
          </div>
          <div className="input-group">
            <Input value={country} onChange={(e) => setCountry(e.target.value)} label={t('contacts.country')} />
            <Input value={nationality} onChange={(e) => setNationality(e.target.value)} label={t('contacts.nationality')} />
          </div>
          <div className="input-group">
            <Input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} label={t('contacts.phone_number')} />
            <DateInput 
              label={t('contacts.dob')}
              value={dob}
              onChange={handleDateChange}
              dateFormat="d MMMM yyyy"
              maxDate={new Date()}
              dropdownMode="select"
              showMonthDropdown={true}
              showYearDropdown={true}
            />
          </div>
          <div className="input-group">
            <Input value={website} onChange={(e) => setWebsite(e.target.value)} label={t('contacts.website')} />
          </div>
          {customContactFields.length > 0 && (
            <>
              <h2 className="heading-2">{t('contacts.custom_fields')}</h2>
              <div className="input-group-custom-fields">
                {customContactFields.map((field, idx) => {
                  return (
                    <Input key={idx} label={field.label} value={field.value} onChange={(e) => handleCustomFieldChange(e.target.value, field)} formEl />
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CreateEditContactModal
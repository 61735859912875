import { GET_TASKS, SET_TASKS, SET_TASKS_OBJ, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_TASKS:
      return {
        ...state,
        tasksObj: action.payload.obj,
        tasks: action.payload.tasks,
        tasksFetched: true,
        completedTasks: action.payload.completed,
        notCompletedTasks: action.payload.notCompleted,
      }
    case SET_TASKS:
      return {
        ...state,
        tasks: action.payload.tasks,
        completedTasks: action.payload.completed,
        notCompletedTasks: action.payload.notCompleted,
      }
    case SET_TASKS_OBJ:
      return {
        ...state,
        tasksObj: action.payload,
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
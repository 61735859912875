import { firebaseEnvDoc } from './firebase'

// Create new shared document
const create_shared_document = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection('shared_documents').add(data)
    return res.id
  } catch (err) {
    console.log('create shared document error', err)
  }
}

// Delete shared document
const delete_shared_document = async (id) => {
  try { 
    await firebaseEnvDoc.collection('shared_documents').doc(id).delete()
  } catch (err) {
    console.log('delete shared document error', err)
  }
}

// Update shared document
const update_shared_document = async (id, data) => {
  try { 
    await firebaseEnvDoc.collection('shared_documents').doc(id).update(data)
  } catch (err) {
    console.log('update shared document error', err)
  }
}

export {
  create_shared_document,
  delete_shared_document,
  update_shared_document,
}
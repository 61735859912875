import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import Loader from '../../UI/Loader'
import Modal from '../../UI/Modal';
import ModelsRow from '../../UI/ModelsRow';
import ResponseLoader from '../../UI/ResponseLoader'
import TemplatesSidebar from '../templates/TemplatesSidebar';
import { sortedArrayFromObject, sorterWithPathAndOrder } from '../../../utils'
import { DocumentsContext, TeamContext, GlobalContext } from '../../../context';

const DocumentCreateModal = ({ onClose, folders, onFolderCreate, onFolderDelete, onFolderEdit, type = 'document', onDocCreate }) => {
  let history = useHistory();
  const { t } = useContext(GlobalContext)
  const { templates, createDocument, templatesLoaded, getTemplates } = useContext(DocumentsContext);
  const { selectedTeam } = useContext(TeamContext)
  const [templatesArray, setTemplatesArray] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedTitle, setSelectedTitle] = useState(t('general.all'));
  const [filteredTemplates, setFilteredTemplates] = useState({});
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fetch templates if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!templatesLoaded) {
        // console.log('fetch templates')
        getTemplates(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, templatesLoaded, getTemplates])

  useEffect(() => {
    const filtered = filterNonDeletedTemplates(templates);
    setFilteredTemplates(filtered);
    processTemplates(filtered);
  }, [templates]);

  const processTemplates = async (t) => {
    let tmplts = sortedArrayFromObject(t, sorterWithPathAndOrder('name', 'desc'))
    for(let i in tmplts) {
      tmplts[i].title = tmplts[i].name
    }
    setTemplatesArray(tmplts)
  }

  const filterNonDeletedTemplates = (tmplts) => {
    const filtered = {};
    for(let key in tmplts) {
      if(!tmplts[key].deleted) {
        filtered[key] = tmplts[key];
      }
    }
    return filtered;
  }

  const handleCreateDocument = (template) => () => {
    let data = {
      name: template.title,
      status: 'empty',
      doc_alerts: [],
      progress: 0,
      template: template.id,
      values: {},
      team: selectedTeam?.id 
    }
    setShowResponseLoader(true)
    createDocument(data, (id) => {
      setShowResponseLoader(false)
      history.push({
        pathname: `/document-detail/${id}`,
      })
    }, () => {
      setShowResponseLoader(false)
    }, false)
    // if(type === 'doc-create') {
    //   onDocCreate(template)
    // }else {
    //   history.push({
    //     pathname: `/template/${template.id}`,
    //     state: { templateId: template.id }
    //   })
    // }
  }

  // Filter templates by folders
  const filterByFoldersHandler = (folder) => {
    const tmplts = {...filteredTemplates};
    const filtered = {};
    if(folder) {
      for(let key in tmplts) {
        if(tmplts[key].folderId.includes(folder.id)) {
          filtered[key] = tmplts[key];
        }
      }
      processTemplates(filtered);
      setSelectedFilter(folder.id);
      setSelectedTitle(folder.name);
    }else {
      processTemplates(tmplts);
      setSelectedFilter('all');
      setSelectedTitle(t('general.all'));
    }
  }

  return(
    <Modal onClose={onClose} noPadding>
      <div className="document-create-modal">
        <div className="document-create-modal__left">
          <TemplatesSidebar 
            folders={folders}
            onFolderEdit={onFolderEdit} 
            onFolderCreate={onFolderCreate}
            onFolderDelete={onFolderDelete}
            onFolderFilter={filterByFoldersHandler}
            selectedFilter={selectedFilter}
            disableFolders
          />
        </div>
        <div className="document-create-modal__right">
          {!templatesLoaded 
            ? (
              <div className="loader-wrapper"><Loader normal primary small /></div>
            )
            :(
              <div className="dashboard-view">
                <div className="dashboard-view__head static">
                  <div className="dashboard-view__head_top">
                    <h4>{selectedTitle}</h4>
                  </div>
                </div>

                <div className="dashboard-view__body no-padding-top">
                  { templatesArray.map((template, templateIndex) => {
                    return <ModelsRow 
                      key={`template_${template.id}`}
                      title={template.title} 
                      onDetailView={handleCreateDocument(template)}
                      onButtonClick={handleCreateDocument(template)}
                    />
                  })}              
                </div>
              </div>
            )
          }
        </div>
      </div>

      {showResponseLoader && <ResponseLoader />}
    </Modal>
  );
}

DocumentCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default DocumentCreateModal;
import { useContext } from 'react'

import { ContactsContext, LoaderContext, GlobalContext, TeamContext, ContactGroupsContext, NotificationContext } from '../context'
import { update_team , delete_contact} from '../services/firestore'

const useContactsGroupsActions = () => {
  const { contacts, setContacts, setContactsSelectedFilters, contactsSelectedFilters } = useContext(ContactsContext)
  const { contactGroups, deleteContactGroup, createContactGroup, updateContactGroup } = useContext(ContactGroupsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { selectedTeam, setSelectedTeam, activeTeamMember } = useContext(TeamContext)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useContext(GlobalContext)

  // Create contact group
  const createSingleContactGroup = async ({ name, onClose, setMainTitle, refreshContacts }) => {
    if(name.trim() === '') {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }

    let data = {
      meta: {
        created: Date.now(),
        updated: Date.now()
      },
      team: selectedTeam?.id,
      owner: activeTeamMember.id,
      name: name.trim()
    }
    // console.log(data)
    setShowGlobalResponseLoader(true)
    await createContactGroup(data, (id) => {
      setNotification({ msg: t('contacts.group_created'), type: 'success' })
      setMainTitle(name.trim())
      setContactsSelectedFilters(prev => ({ ...prev, group: id }))
      setShowGlobalResponseLoader(false)
      onClose()
      refreshContacts && refreshContacts()
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  // Update contact group
  const updateSingleContactGroup = async ({ name, nameBeforeEdit, selectedGroup, onClose, setMainTitle, refreshContacts }) => {
    if(name.trim() === '') {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }

    if(name.trim() === nameBeforeEdit) {
      return setNotification({ msg: t('contacts.name_has_not_changed'), type: 'info' })
    }
    let data = {
      name: name.trim()
    }
    if(selectedGroup.meta) {
      data.meta = { ...selectedGroup.meta, updated: Date.now() }
    }else {
      data.meta = { created: Date.now(), updated: Date.now() }
    }
    // console.log(data)
    setShowGlobalResponseLoader(true)
    await updateContactGroup(data, selectedGroup.id, () => {
      setNotification({ msg: t('contacts.group_updated'), type: 'success' })
      setShowGlobalResponseLoader(false)
      if(contactsSelectedFilters.group === selectedGroup.id) {
        setMainTitle(name.trim())
      }
      onClose()
      refreshContacts && refreshContacts()
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  // Delete contact group
  const deleteGroup = async ({ groupToDelete, activeGroup, setActiveGroup, setSelectedTitle, setShowDeleteGroupAlert, refreshContacts }) => {
    if(!groupToDelete.id) return 
    // console.log(groupToDelete)
    // console.log(contacts.filter(c => c.group === groupToDelete.id))

    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Deleting contact group')

    await deleteContactGroup(groupToDelete.id, async () => {
      // Unlink variables 
      if(selectedTeam && selectedTeam.contacts_linked_variables && selectedTeam.contacts_linked_variables[groupToDelete.id]) {
        let linkedVars = {...selectedTeam.contacts_linked_variables}
        delete linkedVars[groupToDelete.id] 
        setSelectedTeam({...selectedTeam, contacts_linked_variables: linkedVars})
        update_team({ contacts_linked_variables: linkedVars }, selectedTeam.id)
      }

      // Delete contacts from this group
      const contactsToDelete = contacts.filter(c => c.group === groupToDelete.id)
      let ids = []
      if(contactsToDelete.length > 0) {
        let promises = []
        contactsToDelete.forEach(c => {
          promises.push(delete_contact(c.id))
          ids.push(c.id)
        })
        if(promises.length > 0) {
          await Promise.all(promises)
          const updatedContacts = contacts.filter(c => !ids.includes(c.id))
          setContacts(updatedContacts)
        }
      }
      if(activeGroup === groupToDelete.id || contactsSelectedFilters.group === groupToDelete.id) {
        const otherGroups = contactGroups.filter(g => g.id !== groupToDelete.id) 
        if(otherGroups.length > 0) {
          setActiveGroup(otherGroups[0].id)
          setContactsSelectedFilters(prev => ({...prev, group: otherGroups[0].id}))
          setSelectedTitle(otherGroups[0].name)
        }else {
          setSelectedTitle(' ')
          setActiveGroup('')
          setContactsSelectedFilters(prev => ({...prev, group: 'all'}))
        }
      }

      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
      setShowDeleteGroupAlert(false)
      setNotification({ msg: t('contacts.group_deleted'), type: 'success' })
      refreshContacts && refreshContacts()
    })
  }

  return {
    createSingleContactGroup,
    updateSingleContactGroup,
    deleteGroup,
  }
}

export default useContactsGroupsActions
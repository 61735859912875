import ReactDOM from 'react-dom'

import carbonLogo from '../../assets/images/carbon_logo_new.png'

const PageLoader = ({ hidden, fadeOut = false }) => {
  return ReactDOM.createPortal(
    <div className={`page-loader ${hidden ? 'hidden' : ''} ${fadeOut ? 'fading' : ''}`}>
      <div className="logo-wrapper">
        <img src={carbonLogo} alt="" />
        <p>Carbon</p>
      </div>
      <div className="page-loader__loader"></div>
    </div>,
    document.getElementById('page-loader')
  )
}

export default PageLoader
import { useState, forwardRef, useEffect, useContext } from 'react'

import Loader from '../UI/Loader'

import { FolderContext, NotificationContext, GlobalContext } from '../../context'
import { getAllParentFolders } from '../../utils'

const FoldersPopup = forwardRef(({ onCancel, folders, foldersLoading, onMoveToFolder, loading, data, resource, fromSidebar, onMoveResourceToFolder }, ref) => {
  const { t } = useContext(GlobalContext)
  const [level, setLevel] = useState('root')
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [dataArr, setDataArr] = useState([])
  const { setParentFolder, updateFolder } = useContext(FolderContext)
  const { setNotification } = useContext(NotificationContext)

  useEffect(() => {
    setDataArr(data)
  }, [data])

  const folderSelectHandler = (selected, level, e) => {
    setSelectedFolder(selected)
    setLevel(level)
  }

  const goToPrevFolderHandler = () => {
    if(selectedFolder.parentFolder) {
      const folder = [...folders].filter(folder => folder.id === selectedFolder.parentFolder)[0]
      setSelectedFolder(folder)
    }else {
      setLevel('root')
      setSelectedFolder(null)
    }
  }

  // Drag start
  const dragStartHandler = (e, folder) => {
    // const div = document.createElement('div')
    // div.id = 'draggable-folder-el'
    // div.className = 'draggable-ghost-el'
    // div.innerText = t('folder.move', { folder: folder.name })
    // document.getElementById('root').appendChild(div)
    // e.dataTransfer.setDragImage(div, 0, 0)
    // e.dataTransfer.setData('folder', JSON.stringify(folder))
  }
  
  // Drag over
  const dragOverHandler = (e) => {
    e.preventDefault()
    // const folderBox = e.target.closest('.folder-list-item')
    // if(!folderBox.classList.contains('hover')) {
    //   folderBox.classList.add('hover')
    // }
  }

  // Drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault()
    // const folderBox = e.target.closest('.folder-list-item')
    // if(folderBox.classList.contains('hover')) {
    //   folderBox.classList.remove('hover')
    // }
  }
  
  // Drop
  const dropHandler = async (e, folder) => {
    // const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null;

    // const folderBox = e.target.closest('.folder-list-item');
    // if(folderBox.classList.contains('hover')) {
    //   folderBox.classList.remove('hover');
    // }

    // if(data) {
    //   const parentFolders = getAllParentFolders(folders, folder);
    //   if(folder.id === data.id) 
    //     return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: folder.name}), type: 'warning' });
    //   if(folder.parentFolder === data.id) 
    //     return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: [...folders].find(f => f.id === folder.parentFolder).name}), type: 'warning' });
    //   if(data.parentFolder === folder.id) 
    //     return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: [...folders].find(f => f.id === data.parentFolder).name}), type: 'warning' });
    //   if(parentFolders.find(f => f.id === data.id)) 
    //     return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: parentFolders.find(f => f.id === data.id).name}), type: 'warning' });
  
    //   console.log(`Move ${data.name} to ${folder.name}`);
    //   await updateFolder({ parentFolder: folder.id }, data.id);
    //   setNotification({ msg: t('folder.moved_to_folder', { folder1: data.name, folder2: folder.name }), type: 'default' });
    //   return;
    // }

    // const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null;

    // if(templateData) {
    //   onMoveResourceToFolder(templateData, folder);
    // }
  }

  // Drag end 
  const dragEndHandler = (e) => {
    document.getElementById('draggable-folder-el').remove()
  }

  return (
    <div className="folders-popup-v2" ref={ref}>
      <div className={level === 'root' ? "folders-popup-v2__head root" : "folders-popup-v2__head inner"}>
        <div className="main">
          <span className="icon">
            <span className="material-symbols-outlined filled">home</span>
          </span>
          <h5>{t('dashboard.library')}</h5>
        </div>
        <div className="back">
          <span className="icon" onClick={goToPrevFolderHandler}>
            <span className="material-symbols-outlined">arrow_back</span> 
          </span>
          <h5>{selectedFolder && selectedFolder.name}</h5>
        </div>
      </div>
      <div className="folders-popup-v2__body u-custom-scrollbar">
        {foldersLoading ? 
          <div style={{padding: '10px'}}><Loader normal primary small /></div> : 
          <ul className={level === 'root' ? "folders-popup-v2__body_list root" : "folders-popup-v2__body_list inner"}>
          {folders.length > 0 && folders.map((folder, i) => {
            if(!folder.parentFolder) {
              return <li 
                key={i} 
                className="root folder-list-item" 
                onDragStart={(e) => dragStartHandler(e, folder)} 
                onDragOver={dragOverHandler} 
                onDragLeave={dragLeaveHandler}
                onDrop={(e) => dropHandler(e, folder)} 
                onDragEnd={dragEndHandler}
                draggable
              >
                <div className="root u-truncate-text-wrapper" onClick={(e) => folderSelectHandler(folder, 'inner', e)}>
                  <span className="material-symbols-outlined">folder</span>
                  <p className="u-truncate-text">{folder.name}</p>
                  <span className="material-symbols-outlined right">keyboard_arrow_right</span>
                </div>
              </li>
            }else {
              return null
            }
          })}
          {selectedFolder && dataArr.length > 0 && folders.map((folder, i) => {
            if(folder.parentFolder === selectedFolder.id) {
              return <li 
                key={i} 
                className="inner dont-hide-dropdown folder-list-item" 
                onClick={(e) => folderSelectHandler(folder, 'inner', e)}
                onDragStart={(e) => dragStartHandler(e, folder)} 
                onDragOver={dragOverHandler} 
                onDragLeave={dragLeaveHandler}
                onDrop={(e) => dropHandler(e, folder)} 
                onDragEnd={dragEndHandler}
                draggable
              >
              <div className="root dont-hide-dropdown">
                <span className="material-symbols-outlined">folder</span>
                <p className="dont-hide-dropdown">{folder.name}</p>
              </div>
            </li>
            }
            return null
          })}
          {selectedFolder && dataArr.length > 0 && dataArr.map(resource => {
            if(resource.folderId && resource.folderId.length > 0 && resource.folderId.includes(selectedFolder.id) ) {
              return <li key={resource.id} className="inner">
              <div className="root">
                <span className="material-symbols-outlined">description</span>
                <p>{resource.name}</p>
              </div>
            </li>
            }
            return null
          })}
        </ul>}
      </div>
      <div className="folders-popup-v2__foot">
        <button className="btn btn--outline btn--small" onClick={onCancel}>{t('general.cancel')}</button>
        <button className="btn btn--small" onClick={() => fromSidebar ? onMoveToFolder(selectedFolder) : onMoveToFolder(resource, selectedFolder)}>{t('general.move_here')}</button>
      </div>
    </div>
  )
})

export default FoldersPopup
import { useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { DocumentsContext, LoaderContext, DocumentsFoldersContext, UserContext, GlobalContext, TeamContext, NotificationContext } from '../context'
import { delete_documents_folder, update_document, get_file, upload_file, create_document, delete_document, upload_file_with_progress } from '../services/firestore'
import { generate_document, convert_attachment } from '../services/lawstudioApi'
import { folderHasSubfolders, folderHasTemplates, base64toBlob, getAllParentFolders, filterDocumentsHelper, filterFoldersHelper } from '../utils'
import { getFileData } from '../helpers/files'

const useDocumentsActions = () => {
  const { updateMultipleDocuments, documentsArr, getTemplateById, documents, deleteMultipleDocuments, updateDocument, docFilters, setDocFilters, setFilteredDocuments, currentFilter, templates, addMultipleDocuments, docsSelectedFilters } = useContext(DocumentsContext)
  const { deleteMultipleDocFolders, docFolders, updateDocFolder, updateMultipleDocFolders, setCurrentlyActiveDocFolder, setDocFolderBreadcrumbs, docFoldersFilters, setDocFoldersFilters, setDocFoldersSelectedFilter } = useContext(DocumentsFoldersContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useContext(GlobalContext)
  const history = useHistory()

  // Delete documents
  const deleteDocuments = async ({ selectedDocuments, setSelectedDocuments, setShowDeleteDocAlert }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText(t('general.deleting_documents'))

    let docsPromises = []
    let foldersPromises = []
    let docsArr = []
    let docFoldersArr = []
    
    try {
      for(let i in selectedDocuments) {
        const doc = selectedDocuments[i]
        if(doc.docFolder) {
          if(!folderHasSubfolders(docFolders, doc) && !folderHasTemplates(documentsArr, doc)) {
            foldersPromises.push(delete_documents_folder(doc.id))
            docFoldersArr.push(doc.id)
          }
        }else {
          docsPromises.push(update_document(doc.id, { 
            deleted: true, 
            archived: false, 
            deleteAfter: new Date(Date.now() + 60 * 60 * 24 * 30 * 1000).getTime(),
            last_modified_by: user.id,
            create_action: 'no'
          }, []))
          docsArr.push({ 
            data: { 
              deleted: true, 
              archived: false, 
              deleteAfter: new Date(Date.now() + 60 * 60 * 24 * 30 * 1000).getTime(),
              last_modified_by: user.id,
              create_action: 'no'
            },
            id: doc.id
          })
        }
      }
      if(docsPromises.length > 0) {
        await Promise.all(docsPromises)
        await updateMultipleDocuments(docsArr)
      }
      if(foldersPromises.length > 0) {
        await Promise.all(foldersPromises)
        await deleteMultipleDocFolders(docFoldersArr)
      }
  
      setShowDeleteDocAlert(false)
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Restore documents
  const restoreDocuments = async ({ selectedDocuments, setSelectedDocuments, setRestored }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Restoring documents')

    let docsPromises = []
    let docsArr = []

    try {
      for(let i in selectedDocuments) {
        const doc = selectedDocuments[i]
        docsPromises.push(update_document(doc.id, { deleted: false, deleteAfter: null, archived: false, last_modified_by: user.id, create_action: 'no' }, []))
        docsArr.push({ data: { deleted: false, deleteAfter: null, archived: false, last_modified_by: user.id, create_action: 'no' }, id: doc.id })
      }
      
      if(docsPromises.length > 0) {
        await Promise.all(docsPromises)
        await updateMultipleDocuments(docsArr)
      }
      setRestored(true)
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Archive documents
  const archiveDocuments = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Archiving documents')

    let docsPromises = []
    let docsArr = []

    try {
      for(let i in selectedDocuments) {
        const doc = selectedDocuments[i]
        if(!doc.docFolder) {
          docsPromises.push(update_document(doc.id, { archived: true, last_modified_by: user.id, create_action: 'yes' }, []))
          docsArr.push({ data: { archived: true, last_modified_by: user.id, create_action: 'yes' }, id: doc.id })
        }
      }
      if(docsPromises.length > 0) {
        await Promise.all(docsPromises)
        await updateMultipleDocuments(docsArr)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }

    setSelectedDocuments([])
  }

  // Download documents
  const downloadDocuments = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Preparing documents for download')

    try {
      if(selectedDocuments.length === 1) { // if only one document/folder selected
        const doc = selectedDocuments[0] 
        if(doc.uploaded) {
          let href = '';
          let ext = '';
          if(doc.documentUrls?.pdf) {
            const res = await get_file({ url: doc.documentUrls.pdf }); 
            href = res.data;
            ext = 'pdf';
          }else if(doc.documentUrls?.docx) {
            const res = await get_file({ url: doc.documentUrls.docx }); 
            href = res.data;
            ext = 'docx';
          }
          if(href) {
            if(isIOS) {
              const blob = base64toBlob(href)
              const a = document.createElement('a')
              a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
            }else {
              const a = document.createElement("a")
              a.href = `data:application/pdf;base64,${href}`
              a.download = `${doc.name || 'document'}.${ext}`
              a.click()
            }
          }
        }else {
          if(!doc.folderRow) { // if document
            const tmplt = getTemplateById(doc.template)
            if(tmplt && Object.keys(tmplt).length > 0) {
              // const templateUrl = tmplt.templateUrl
              let documentData = await fetchDocumentData(doc, 'pdf')
              if(isIOS) {
                const blob = base64toBlob(documentData)
                const a = document.createElement('a')
                a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
              }else {
                const a = document.createElement("a")
                a.href = `data:application/pdf;base64,${documentData}` 
                a.download = `${doc.name || 'document'}.pdf`
                a.click()
              }
            }
          }else { // if folder
            const folder = selectedDocuments[0]
            const zip = new JSZip()
            let docsInFolder = {}
            for(let id in documents) {
              if(documents[id].folderId?.includes(folder.id)) {
                docsInFolder[id] = documents[id]
              }
            }
            if(Object.keys(docsInFolder).length > 0) {
              for(let docId in docsInFolder) {
                if(docsInFolder[docId].uploaded){
                  let href = ''
                  let ext = ''
                  if(docsInFolder[docId].documentUrls?.pdf) {
                    const res = await get_file({ url: docsInFolder[docId].documentUrls.pdf })
                    href = res.data
                    ext = 'pdf'
                  }else if(docsInFolder[docId].documentUrls?.docx) {
                    const res = await get_file({ url: docsInFolder[docId].documentUrls.docx })
                    href = res.data
                    ext = 'docx'
                  }
                  if(href) {
                    const folderName = getFolderPath(folder, folder.name)
                    let name = `${docsInFolder[docId].name}_${docsInFolder[docId].id}.${ext}`
                    zip.file(`${folderName}/${name}`, href, {base64: true})
                  }
                }else {
                  const tmplt = getTemplateById(docsInFolder[docId].template)
                  if(tmplt && Object.keys(tmplt).length > 0) {
                    // const templateUrl = tmplt.templateUrl
                    let documentData = await fetchDocumentData(docsInFolder[docId], 'pdf')
                    const name = docsInFolder[docId].name
                    const folderName = getFolderPath(folder, folder.name)
                    zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
                  }
                }
              }
            }
            let foldersInFolder = {}
            docFolders.forEach(f => {
              if(f.parentFolder === folder.id) {
                foldersInFolder[f.id] = f
              }
            })
            if(Object.keys(foldersInFolder).length > 0) {
              await checkForFoldersInFolderHelper(foldersInFolder, zip)
            }

            if(Object.keys(docsInFolder).length === 0 && Object.keys(foldersInFolder).length === 0) {
              return setNotification({ msg: t('folder.empty'), type: 'info' })
            }

            zip.generateAsync({type:"blob"}).then(function(content) {
              saveAs(content, `${folder.name}.zip`)
            })
          }
        }
      }else { // if multiple documents/folders selected
        const zip = new JSZip()

        for(let i in selectedDocuments) {
          const doc = selectedDocuments[i]
          let name = `${doc.name}.pdf`
          if(doc.uploaded) { // if document was uploaded
            let href = ''
            let ext = ''
            if(doc.documentUrls?.pdf) {
              const res = await get_file({ url: doc.documentUrls.pdf })
              href = res.data
              ext = 'pdf'
            }else if(doc.documentUrls?.docx) {
              const res = await get_file({ url: doc.documentUrls.docx })
              href = res.data
              ext = 'docx'
            }
            if(href) {
              name = `${doc.name}_${doc.id}.${ext}`
              zip.file(`${name}`, href, {base64: true})
            }
          }else { // if document was created from template
            if(!doc.folderRow) { // if document
              const tmplt = getTemplateById(doc.template)
              if(tmplt && Object.keys(tmplt).length > 0) {
                // const templateUrl = tmplt.templateUrl
                let documentData = await fetchDocumentData(doc, 'pdf')
                zip.file(`${doc.name}_${doc.id}.pdf`, documentData, {base64: true})
              }
            }else { // if folder
              let docsInFolder = {}
              let folder = doc
              for(let docId in documents) {
                if(documents[docId].folderId?.includes(folder.id)) {
                  docsInFolder[docId] = documents[docId]
                }
              }
              if(Object.keys(docsInFolder).length > 0) {
                for(let docId in docsInFolder) {
                  if(docsInFolder[docId].uploaded){
                    let href = ''
                    let ext = ''
                    if(docsInFolder[docId].documentUrls?.pdf) {
                      const res = await get_file({ url: docsInFolder[docId].documentUrls.pdf })
                      href = res.data
                      ext = 'pdf'
                    }else if(docsInFolder[docId].documentUrls?.docx) {
                      const res = await get_file({ url: docsInFolder[docId].documentUrls.docx })
                      href = res.data
                      ext = 'docx'
                    }
                    if(href) {
                      const folderName = getFolderPath(folder, folder.name)
                      let name = `${docsInFolder[docId].name}_${docsInFolder[docId].id}.${ext}`
                      zip.file(`${folderName}/${name}`, href, {base64: true})
                    }
                  }else {
                    const tmplt = getTemplateById(docsInFolder[docId].template)
                    if(tmplt && Object.keys(tmplt).length > 0) {
                      // const templateUrl = tmplt.templateUrl
                      let documentData = await fetchDocumentData(docsInFolder[docId], 'pdf')
                      const name = docsInFolder[docId].name
                      const folderName = getFolderPath(folder, folder.name)
                      zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
                    }
                  }
                }
              }else {
                zip.folder(`${folder.name}`)
              }
              let foldersInFolder = {}
              docFolders.forEach(f => {
                if(f.parentFolder === folder.id) {
                  foldersInFolder[f.id] = f
                }
              })
              if(Object.keys(foldersInFolder).length > 0) {
                await checkForFoldersInFolderHelper(foldersInFolder, zip)
              }
            }
          }
        }

        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, "documents.zip")
        })

      }

      setSelectedDocuments([])

    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Duplicate documents
  const duplicateDocuments = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Duplicating documents')
    let promises = []
    let docsArr = []
    for(let i in selectedDocuments) {
      let doc = selectedDocuments[i]
      if(!doc.docFolder) {
        let docCopy = {
          name: t('general.copy_of', { document: doc.name }),
          progress: doc.progress,
          status: doc.status,
          template: doc.template,
          values: doc.values,
          team: selectedTeam?.id
        }
        if(doc.uploaded) {
          delete docCopy.template
          delete docCopy.progress
          docCopy.uploaded = true
          docCopy.documentUrls = {}
          let documentData
          let ext
          if(doc.documentUrls.pdf) {
            const res = await get_file({ url: doc.documentUrls.pdf })
            documentData = res.data
            ext = 'pdf'
          }else {
            const res = await convert_attachment(doc.documentUrls.docx)
            documentData = res.data
            ext = 'docx'
          }
          const fileName = `${doc.name}-${Date.now()}`
          let uploadResult = await upload_file(documentData, fileName, ext, mimeTypeForExtension(ext), 'documents')
          docCopy.documentUrls[ext] = uploadResult.url
          docCopy.filePath = `documents/${fileName}`
          docCopy.documentUrls = doc.documentUrls
        }
        promises.push(create_document(docCopy))
        docsArr.push(docCopy)
      }
    }
    if(promises.length > 0) {
      const promisesData = await Promise.all(promises)
      const arr = docsArr.map((d, i) => ({...d, id: promisesData[i].documentId}))
      addMultipleDocuments(arr)
    }
    setSelectedDocuments([])
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Delete documents permanently
  const deleteDocumentsPermanently = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Deleting documents permanently')

    const promises = []
    const docs = []
    
    selectedDocuments.forEach(doc => {
      promises.push(delete_document(doc.id))
      docs.push(doc.id)
    })

    await Promise.all(promises)
      .then(async (data) => {
        deleteMultipleDocuments(docs)
        setNotification({ msg: t('notification.elements_removed', { number: data.length }), type: 'success' })
        setSelectedDocuments([])
      })
      .catch(err => {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }).finally(() => {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      })
  }

  // Move documents to folder
  const moveDocumentsToFolder = async ({ moveTo, current, selectedDocuments, setSelectedDocuments, setShowMoveMultipleDocsToFolderModal }) => {
    const promises = []
    const docsArr = []
    let foldersArr = []
    for(let i = 0; i < selectedDocuments.length; i++) {
      const document = selectedDocuments[i]
      let doc = document
      if(!document.folderId) {
        doc = {...document, folderId: []}
      }
      if(doc.folderRow) {
        if(moveTo) {
          const parentFolders = getAllParentFolders(docFolders, moveTo)

          if(moveTo.id === doc.parentFolder) continue
          if(moveTo.id === doc.id) continue
          if(moveTo.parentFolder === doc.id) continue
          if(parentFolders.find(f => f.id === doc.id)) continue

          promises.push(updateDocFolder({ parentFolder: moveTo.id }, doc.id, false))
          foldersArr.push({ data: { parentFolder: moveTo.id }, id: doc.id })
        }else {
          if(doc.parentFolder === null || doc.parentFolder === undefined) continue

          promises.push(updateDocFolder({ parentFolder: null }, doc.id, false))
          foldersArr.push({ data: { parentFolder: null }, id: doc.id })
        }

      }else {
        if(moveTo) {
          if(!doc.folderId.includes(moveTo.id)) {
            let fArr = [...doc.folderId]
            fArr = fArr.filter(fId => current ? fId !== current.id : fId)
            fArr.push(moveTo.id)
            promises.push(updateDocument({ folderId: fArr, last_modified_by: user.id, create_action: 'no' }, doc, false))
            docsArr.push({ data: { folderId: fArr }, id: doc.id })
          }
        }else {
          let fArr = [...doc.folderId];
          fArr = fArr.filter(fId => current ? fId !== current.id : fId)
          promises.push(updateDocument({ folderId: fArr, last_modified_by: user.id, create_action: 'no' }, doc, false))
          docsArr.push({ data: { folderId: fArr }, id: doc.id })
        }
      }
    }

    if(promises.length === 0) return

    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Moving documents')

    await Promise.all(promises)
      .then(async () => {
        updateMultipleDocFolders(foldersArr)
        updateMultipleDocuments(docsArr)
        setSelectedDocuments([])
        setShowMoveMultipleDocsToFolderModal(false)
        setNotification({ msg: t('folder.elements_moved_to_folder', { number: promises.length, folder: moveTo ? moveTo.name : t('general.root') }), type: 'default' })
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      })
  }

  // Template includes - helper
  const templateIncludes = useCallback((templateId, searchPhrase) => {
    let searchedTemplate = templates[templateId]
    if(!searchedTemplate) {
      return false
    }
    let includes = false
    for(let i in searchedTemplate.sections) {
      let section = searchedTemplate.sections[i]
      if(section.content) {
        includes = section.content.toLowerCase().includes(searchPhrase)
      } else if(section.items) {
        for(let j in section.items) {
          if(section.items[j].content) {
            includes = section.items[j].content.toLowerCase().includes(searchPhrase)
          }
          if(includes) {
            break
          }
        }
      }
      if(includes) {
        break
      }
    }
    return includes
  }, [templates])

  // Filter documents by folders
  const filterDocumentsByFolders = useCallback(({ folder, setSelectedDocItem, setSelectedDocuments, setFilteredFolders }) => {
    if(folder) {
      setSelectedDocItem('')
    }else {
      setSelectedDocItem('active')
    }
    setCurrentlyActiveDocFolder(folder)
    const parentFolders = getAllParentFolders(docFolders, folder)
    const reverseParentFolders = parentFolders.reverse()
    if(parentFolders.length > 0) {
      setDocFolderBreadcrumbs([...reverseParentFolders, folder])
    }else {
      if(folder) {
        setDocFolderBreadcrumbs([folder])
      }else {
        setDocFolderBreadcrumbs([])
      }
    }
    
    const docs = {...documents}
    const docFiltersCopy = {...docFilters}
    docFiltersCopy.folder = folder ? folder.id : ''
    setDocFilters(docFiltersCopy)
    const filteredDocs = filterDocumentsHelper(docs, docFiltersCopy, templateIncludes)
    const docFoldersFiltersCopy = {...docFoldersFilters}
    docFoldersFiltersCopy.parentFolder = folder ? folder.id : null
    setDocFoldersFilters(docFoldersFiltersCopy)
    const filteredF = filterFoldersHelper(docFolders, docFoldersFiltersCopy)
    if(folder) {
      setDocFoldersSelectedFilter(folder.id)
    }else {
      setDocFoldersSelectedFilter('all')
    }
    setFilteredDocuments(filteredDocs)
    setFilteredFolders(currentFilter === 'all' ? filteredF : [])
    setSelectedDocuments([])
  }, [currentFilter, docFilters, docFolders, docFoldersFilters, documents, setCurrentlyActiveDocFolder, setDocFilters, setDocFolderBreadcrumbs, setDocFoldersFilters, setDocFoldersSelectedFilter, setFilteredDocuments, templateIncludes])

  // Upload documents 
  const uploadDocuments = async (e, { allowedFileTypes, setUploading, setProgress, onClose }) => {
    const files = e.target.files || e.dataTransfer.files
    let error = false
    for(let i = 0; i < files.length; i++) {
      if(!allowedFileTypes.includes(files[i].type)) {
      // if(!files[i].name.includes('pdf') && !files[i].name.includes('docx')) {
        error = true
        break
      }
    }

    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type'), type: 'danger' })
    }

    // If only 1 file
    if(files.length === 1) {
      const file = files[0]
      
      setUploading(true)
      
      // Upload file
      const data = await getFileData(file)
      const fileName = `${data.name}-${Date.now()}`
      let docData = {
        name: file.name.split('.')[0],
        folderId: [],
        status: 'empty',
        uploaded: true,
        values: {},
        filePath: `documents/${fileName}.${data.format}`,
        uploadedFormat: data.format,
        team: selectedTeam.id
      };
      const fileUrlRes = await upload_file_with_progress(file, fileName, data.format, data.type, 'documents', (p) => {
        setProgress(Math.floor(p))
      })
      if(data.format === 'pdf') {
        docData.documentUrls = { pdf: fileUrlRes.url }
      }else {
        docData.documentUrls = { docx: fileUrlRes.url }
      }
      
      // Save document
      let response = await create_document(docData, [])
      
      // Redirect to document-detail
      if(response.success) {
        setUploading(false)
        // Update documents
        const newDoc = {...response.docData, owner: activeTeamMember.id }
        updateMultipleDocuments([{ data: newDoc, id: response.documentId}])
        history.push(`/document-detail/${response.documentId}`)
      }
      
    }else { // if multiple files
      const filesArr = Array.from(files)
      const addedFiles = []
      const promises = []
      setUploading(true)

      for(let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i]
        promises.push(getFileData(file))
      }

      let mProgress = 0
      let total = 0
      
      // Upload file and create document in db
      await Promise.all(promises).then(async data => {
        for(let i = 0; i < data.length; i++) {
          const fileName = `${data[i].name}-${Date.now()}`
          const file = filesArr[i]
          let docData = {
            name: data[i].name,
            folderId: [],
            status: 'empty',
            uploaded: true,
            values: {},
            filePath: `documents/${fileName}.${data[i].format}`,
            uploadedFormat: data[i].format,
            team: selectedTeam.id
          }
          // eslint-disable-next-line no-loop-func
          const fileUrl = await upload_file_with_progress(file, fileName, data[i].format, data[i].type, 'documents', (p) => {
            let current = (Math.floor(p) / 100) * (100 / data.length)
            mProgress = total + current
            if(current >= Math.floor(100 / data.length)) {
              total += current
            }
            setProgress(Math.floor(mProgress))
          })
          if(data[i].format === 'pdf') {
            docData.documentUrls = { pdf: fileUrl.url }
          }else {
            docData.documentUrls = { docx: fileUrl.url }
          }
          let response = await create_document(docData, [])

          if(response.success) {
            const newDoc = {...response.docData, owner: activeTeamMember.id}
            addedFiles.push({ data: newDoc, id: response.documentId })
          }
        }

        setUploading(false)
        updateMultipleDocuments(addedFiles)
        onClose()
        setNotification({ msg: t('notification.num_files_uploaded', { number: addedFiles.length}), type: 'default' })
      })
    }
  }

  // Upload documents by dropping them to the list view
  const uploadDocumentsByDrop = useCallback(async ({ e, setShowDroppableHover, droppableCounter, setUploading, setProgress }) => {
    if(!e.target) return 
    // if(e.target.closest('.dashboard-view__head_bottom')) return 
    const files = e.dataTransfer.files
    if(!files || files.length === 0) return 

    setShowDroppableHover(false)
    droppableCounter = 0

    const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

    // Check if type is valid
    let error = false
    for(let i = 0; i < files.length; i++) {
      if(!allowedTypes.includes(files[i].type)) {
        error = true
        break
      }
    }

    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type'), type: 'danger' });
    }

    let folderId = docsSelectedFilters.folder !== 'all' ? [docsSelectedFilters.folder] : []
    // const folderRow = e.target.closest('.folder-row')
    // if(folderRow && folderRow.getAttribute('data-id')) {
    //   folderId = [folderRow.getAttribute('data-id')]
    // }

    try {

      if(files.length === 1) {
        const file = files[0]
        
        setUploading(true)
  
        // Upload file
        const data = await getFileData(file)
        const fileName = `${data.name}-${Date.now()}`
        let docData = {
          name: file.name.split('.')[0],
          folderId,
          status: 'empty',
          uploaded: true,
          values: {},
          filePath: `documents/${fileName}.${data.format}`,
          uploadedFormat: data.format,
          team: selectedTeam.id
        }
        
        const fileUrlRes = await upload_file_with_progress(file, fileName, data.format, data.type, 'documents', (p) => {
          setProgress(Math.floor(p))
        })

        if(data.format === 'pdf') {
          docData.documentUrls = { pdf: fileUrlRes.url }
        }else {
          docData.documentUrls = { docx: fileUrlRes.url }
        }
        
        // Save document
        let response = await create_document(docData, [])
        
        // Redirect to document-detail
        if(response.success) {
          setUploading(false)
          setProgress(0)
          // Update documents
          const newDoc = {...response.docData, owner: activeTeamMember.id }
          updateMultipleDocuments([{ data: newDoc, id: response.documentId}])
          history.push(`/document-detail/${response.documentId}`)
          return
        }

      }else { // if multiple files
  
        const filesArr = Array.from(files)
        const addedFiles = []
        const promises = []
        setUploading(true)

        for(let i = 0; i < filesArr.length; i++) {
          const file = filesArr[i]
          promises.push(getFileData(file))
        }

        let mProgress = 0
        let total = 0
        let success = false

        // Upload file and create document in db
        await Promise.all(promises).then(async data => {
          for(let i = 0; i < data.length; i++) {
            const fileName = `${data[i].name}-${Date.now()}`
            const file = filesArr[i]
            let docData = {
              name: data[i].name,
              folderId,
              status: 'empty',
              uploaded: true,
              values: {},
              filePath: `documents/${fileName}.${data[i].format}`,
              uploadedFormat: data[i].format,
              team: selectedTeam.id
            }

            // eslint-disable-next-line no-loop-func
            const fileUrl = await upload_file_with_progress(file, fileName, data[i].format, data[i].type, 'documents', (p) => {
              let current = (Math.floor(p) / 100) * (100 / data.length)
              mProgress = total + current
              if(current >= Math.floor(100 / data.length)) {
                total += current
              }
              setProgress(Math.floor(mProgress))
            })

            if(data[i].format === 'pdf') {
              docData.documentUrls = { pdf: fileUrl.url }
            }else {
              docData.documentUrls = { docx: fileUrl.url }
            }

            let response = await create_document(docData, [])

            if(response.success) {
              const newDoc = {...response.docData, owner: activeTeamMember.id}
              addedFiles.push({ data: newDoc, id: response.documentId })
            }
          }

          setUploading(false)
          setProgress(0)
          updateMultipleDocuments(addedFiles)
          setNotification({ msg: t('notification.num_files_uploaded', { number: addedFiles.length}), type: 'default' })
          success = true
        })

        return success

      }
      
    } catch (err) {
      console.log(err)
      setUploading(false)
      setProgress(0)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }, [activeTeamMember, docsSelectedFilters, history, selectedTeam, setNotification, t, updateMultipleDocuments])

  /* Helpers */
  // Get folder path
  const getFolderPath = useCallback((folder, path) => {
    if(!folder.parentFolder) {
      return folder.name
    }else {
      const fldr = docFolders.find(f => f.id === folder.parentFolder)
      if(fldr) {
        const folderPath = `${fldr.name}/${path}`
        if(!fldr.parentFolder) {
          return folderPath
        }else {
          return getFolderPath(fldr, folderPath)
        }
      }
    }
  }, [docFolders])

  // Fetch document data
  const fetchDocumentData = useCallback(async (document, extension) => {
    let doc = await generate_document(document.template, document.values, { convertTo: extension }, document.content_editable ? document.content_changes : [], selectedTeam)
    if(doc.error) {
      return null
    }
    return doc
  }, [selectedTeam])

  // Check if there are folders in folder - helper
  const checkForFoldersInFolderHelper = useCallback(async (fldrs, zip) => {
    for(let id in fldrs) {
      const folder = docFolders.find(f => f.id === id)
      if(folder) {
        let docsInFolder = {}
        for(let docId in documents) {
          if(documents[docId].folderId?.includes(id)) {
            docsInFolder[docId] = documents[docId]
          }
        }
        if(Object.keys(docsInFolder).length > 0) {
          for(let docId in docsInFolder) {
            if(docsInFolder[docId].uploaded){
              let href = ''
              let ext = ''
              if(docsInFolder[docId].documentUrls?.pdf) {
                const res = await get_file({ url: docsInFolder[docId].documentUrls.pdf })
                href = res.data
                ext = 'pdf'
              }else if(docsInFolder[docId].documentUrls?.docx) {
                const res = await get_file({ url: docsInFolder[docId].documentUrls.docx })
                href = res.data
                ext = 'docx'
              }
              if(href) {
                const folderName = getFolderPath(folder, folder.name)
                let name = `${docsInFolder[docId].name}_${docsInFolder[docId].id}.${ext}`
                zip.file(`${folderName}/${name}`, href, {base64: true})
              }
            }else {
              const tmplt = getTemplateById(docsInFolder[docId].template)
              if(tmplt && Object.keys(tmplt).length > 0) {
                // const templateUrl = tmplt.templateUrl
                let documentData = await fetchDocumentData(docsInFolder[docId], 'pdf')
                const name = docsInFolder[docId].name
                const folderName = getFolderPath(folder, folder.name)
                zip.file(`${folderName}/${name}_${docId}.pdf`, documentData, {base64: true})
              }
            }
          }
        }else {
          const folderName = getFolderPath(folder, folder.name)
          // console.log(folderName)
          zip.folder(`${folderName}`)
        }
        let foldersInFolder = {}
        docFolders.forEach(f => {
          if(f.parentFolder === folder.id) {
            foldersInFolder[f.id] = f
          }
        })
        if(Object.keys(foldersInFolder).length > 0) {
          await checkForFoldersInFolderHelper(foldersInFolder, zip)
        }
      }
    }
  }, [docFolders, documents, getFolderPath, getTemplateById, fetchDocumentData])

  const mimeTypeForExtension = (extension) => {
    switch(extension) {
      case 'pdf':
        return 'application/pdf'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      default:
        return ''
    }
  }
  /* END Helpers */ 

  return {
    deleteDocuments,
    restoreDocuments,
    archiveDocuments,
    downloadDocuments,
    duplicateDocuments,
    deleteDocumentsPermanently,
    moveDocumentsToFolder,
    filterDocumentsByFolders,
    uploadDocuments,
    uploadDocumentsByDrop,
  }
}

export default useDocumentsActions
import { SET_TEMPLATES, SET_DOCUMENTS, SET_FETCH_DOCUMENTS, SET_FILTERED_DOCUMENTS, SET_SHOW_FILTERED_DOCS, SET_CURRENT_FILTER, SET_ARCHIVED_DOCUMENTS, SET_DELETED_DOCUMENTS, SET_ACTIVE_FILTER_COLUMN, SET_DATE_FILTERS, SET_FILTERED_TEMPLATES, SET_TEMPLATES_LOADING, SET_ACTIVE_VARS_SECTION, SET_ACTIONS, SET_FETCH_ACTIONS, SET_TEMPLATES_ARR, SET_DOCUMENTS_ARR, RESET_STATE, SET_DOCUMENT_LISTS, SET_FAVORITE_DOCUMENTS } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case SET_TEMPLATES:
      return{
        ...state,
        templates: action.payload,
        templatesLoaded: action.fetched ? true : state.templatesLoaded
      }
    case SET_TEMPLATES_ARR:
      return{
        ...state,
        templatesArr: action.payload,
      }
    case SET_DOCUMENTS:
      return{
        ...state,
        documents: action.payload,
        documentsLoaded: action.fetched ? true : state.documentsLoaded
      }
    case SET_DOCUMENTS_ARR:
      return {
        ...state,
        documentsArr: action.payload.docs,
        archivedDocsArr: action.payload.archivedDocs,
        deletedDocsArr: action.payload.deletedDocs,
        favoriteDocsArr: action.payload.favoriteDocs,
        categories: action.payload.categories,
        tags: action.payload.tags,
        prices: action.payload.prices,
        pricesArr: action.payload.pricesArr,
        totalAmount: action.payload.totalAmount,
      }
    case SET_FETCH_DOCUMENTS:
      return{
        ...state,
        fetchDocuments: action.payload
      }
    case SET_ACTIONS:
      return{
        ...state,
        actions: action.payload,
        actionsFetched: true
      }
    case SET_FETCH_ACTIONS:
      return{
        ...state,
        fetchActions: action.payload
      }
    case SET_FILTERED_DOCUMENTS:
      return {
        ...state,
        filteredDocuments: action.payload,
        showFilteredDocs: true
      }
    case SET_ARCHIVED_DOCUMENTS:
      return {
        ...state,
        archivedDocuments: action.payload
      }
    case SET_DELETED_DOCUMENTS:
      return {
        ...state,
        deletedDocuments: action.payload
      }
    case SET_FAVORITE_DOCUMENTS:
      return {
        ...state,
        favoriteDocuments: action.payload
      }
    case SET_SHOW_FILTERED_DOCS: {
      return {
        ...state,
        showFilteredDocs: action.payload
      }
    }
    case SET_CURRENT_FILTER:
      return {
        ...state,
        currentFilter: action.payload
      }
    case SET_ACTIVE_FILTER_COLUMN:
      return {
        ...state,
        activeFilterColumn: action.payload
      }
    case SET_DATE_FILTERS:
      return {
        ...state,
        dateFilters: action.payload
      }
    case SET_FILTERED_TEMPLATES:
      return {
        ...state,
        filteredTemplates: action.payload,
        showFilteredTemplates: true
      }
    case SET_TEMPLATES_LOADING:
      return {
        ...state,
        templatesLoading: action.payload
      }
    case SET_ACTIVE_VARS_SECTION:
      return {
        ...state,
        activeVarsSection: action.payload
      }
    case SET_DOCUMENT_LISTS:
      return {
        ...state,
        documentLists: action.payload,
        documentListsArr: action.arr,
        documentListsFetched: true
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer
import { useContext, useEffect, useState, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { TemplatesSidebar, TemplatesView } from '../components/misc'
import { GlobalContext, TeamContext, DocumentsContext, FolderContext } from '../context'
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'

const Templates = () => {
  const { t } = useContext(GlobalContext)
  const { teamChanged, setTeamChanged, selectedTeam } = useContext(TeamContext)
  const { templatesLoaded, getTemplates, templates, templatesSort, templatesSelectedFilters, setTemplatesSort } = useContext(DocumentsContext)
  const { foldersFetched, fetchFolders, folders } = useContext(FolderContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [activeTemplates, setActiveTemplates] = useState([])
  const [currentTemplates, setCurrentTemplates] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [paginatedData, setPaginatedData] = useState([])
  const [mainTitle, setMainTitle] = useState(t('general.all'))
  const [currentFolder, setCurrentFolder] = useState(null)
  const [resetFolders, setResetFolders] = useState(false)

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch templates and folders if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!templatesLoaded) {
        // console.log('fetch templates')
        getTemplates(teamId)
      }
      if(!foldersFetched) {
        fetchFolders(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, templatesLoaded, getTemplates, foldersFetched, fetchFolders])

  // Set templates
  const setupTemplates = useCallback(async () => {
    let tmplts = {...templates}
    let active = {}

    for(let key in tmplts) {
      if(!tmplts[key].deleted) {
        active[key] = tmplts[key]
      }
    }

    let activeTmplts = await sortedArrayFromObject(active, sorterWithPathAndOrder(templatesSort.value, templatesSort.order))
    setActiveTemplates([...folders, ...activeTmplts])
    let current = [...folders, ...activeTmplts].filter((item) => {
      if(item.folderRow) {
        return item.parentFolder === undefined || item.parentFolder === null 
      }else {
        return !item.folderId || (item.folderId && item.folderId.length === 0)
      }
    })
    setCurrentTemplates(current)
    setReady(true)
  }, [templates, templatesSort, folders])

  // Setup templates when all data is fetched - only once, on component mount
  useEffect(() => {
    if(templatesLoaded && foldersFetched && !ready) {
      setupTemplates()
    }
  }, [templatesLoaded, foldersFetched, ready, setupTemplates])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
    setAllChecked(false)
    setSelectedTemplates([])
  }, [])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeTemplates]

    for(let key in templatesSelectedFilters) {
      if(key === 'folder' && templatesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(tmplt => {
          if(tmplt.folderRow) {
            return tmplt.parentFolder === templatesSelectedFilters[key]
          }else {
            return tmplt.folderId && tmplt.folderId.includes(templatesSelectedFilters[key])
          }
        })
      }else if(key === 'folder' && templatesSelectedFilters[key] === 'all'){
        filtered = filtered.filter(tmplt => {
          if(tmplt.folderRow) {
            return tmplt.parentFolder === undefined || tmplt.parentFolder === null
          }else {
            return !tmplt.folderId || (tmplt.folderId && tmplt.folderId.length === 0)
          }
        })
      }
      if(key === 'search' && templatesSelectedFilters[key] !== '') {
        filtered = filtered.filter(tmplt => tmplt.name.toLowerCase().includes(templatesSelectedFilters[key].trim().toLowerCase()))
      }
    }

    let folders = filtered.filter(item => item.folderRow)
    let tmplts = filtered.filter(item => !item.folderRow)
    let sortedFolders = sortArrayOfObjects(folders, templatesSort.value, templatesSort.order)
    let sortedTmplts = sortArrayOfObjects(tmplts, templatesSort.value, templatesSort.order)
    setCurrentTemplates([...sortedFolders, ...sortedTmplts])
  }, [activeTemplates, templatesSelectedFilters, templatesSort])

  // Filter templates when templatesSelectedFilters or templatesSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = templatesSort.order === 'desc' ? 'asc' : 'desc'
    setTemplatesSort({ value: sort_by, order: currentOrder })
    if(isCookieAccepted()) {
      saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'templates')
    }
  }

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedTemplates([])
    }else {
      setSelectedTemplates(paginatedData.filter(tmplt => tmplt.owner !== 'carbon'))
    }
  }

  return (
    <MainLayout
      title={mainTitle}
      page="templates"
      refreshCollection={refreshCollection}
      showSidebarTrigger={true}
      sidebar={
        <TemplatesSidebar 
          setSelectedTemplates={setSelectedTemplates}
          refreshTemplates={refreshCollection}
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          resetFolders={resetFolders}
          setResetFolders={setResetFolders}
        />
      }
    >
      <TemplatesView 
        filteredTemplates={currentTemplates}
        onSort={handleSort}
        selectedTemplates={selectedTemplates}
        setSelectedTemplates={setSelectedTemplates}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        onAllCheckedChange={handleAllChecked}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        refreshTemplates={refreshCollection}
        ready={ready}
      />
    </MainLayout>
  )
}

export default Templates
import { useContext, useCallback, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { ArrowRight, ArrowLeft, FirstPage, LastPage, Publish } from '@material-ui/icons'

import CustomTooltip from '../UI/CustomTooltip'
import IconButton from '../UI/IconButton'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import { GlobalContext } from '../../context'
import { useSingleSignatureActions } from '../../hooks'

const PreviewModal = ({ onClose, data, loading, error, view = 'signatures', doc, v2 = false }) => {
  const { t } = useContext(GlobalContext)
  const { downloadSignature } = useSingleSignatureActions()
  const [pages, setPages] = useState(0)
  const [selectedPage, setSelectedPage] = useState(0)

  const handleDocumentLoadSuccess = useCallback(({ numPages }) => {
    const arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page key={i} pageNumber={i} width={870} renderTextLayer={true} loading="" />)
    }
      setPages(arr)
  }, [])

  // On show first page
  const handleShowFirstPage = () => {
    setSelectedPage(0)
  }

  // On show prev page
  const handleShowPrevPage = () => {
    setSelectedPage(selectedPage - 1)
  }

  // On show next page
  const handleShowNextPage = () => {
    setSelectedPage(selectedPage + 1)
  }

  // On show first page
  const handleShowLastPage = () => {
    setSelectedPage(pages.length - 1)
  }

  // On page change
  const handleSelectPageChange = (e) => {
    setSelectedPage(+e.target.value)
  }

  // On download click
  const handleDownload = () => {
    if(view === 'signatures') {
      downloadSignature({ doc })
    }
  }

  return (
    <Modal onClose={onClose} className="modal--padding-sm">
      <div className={`preview-modal choose-signature-position ${v2 ? 'preview-modal--v2' : ''}`}>
        {!loading && (
          <div className="preview-modal__head choose-signature-position__head">
            <IconButton primaryLight medium icon={<FirstPage />} onButtonClick={handleShowFirstPage} disabled={selectedPage === 0} />
            <IconButton primaryLight medium icon={<ArrowLeft />} onButtonClick={handleShowPrevPage} disabled={selectedPage === 0} />
            <select onChange={handleSelectPageChange} value={selectedPage}>
              {Array.from(Array(pages.length).keys()).map((_, idx) => (
                <option key={idx} value={idx}>{idx + 1}</option>
              ))}
            </select>
            <IconButton primaryLight medium icon={<ArrowRight />} onButtonClick={handleShowNextPage} disabled={selectedPage === pages.length - 1} />
            <IconButton primaryLight medium icon={<LastPage />} onButtonClick={handleShowLastPage} disabled={selectedPage === pages.length - 1} />
            <CustomTooltip content={t('general.download')}>
              <div>
                <IconButton primaryLight medium icon={<Publish />} onButtonClick={handleDownload} className="rotate-180" />
              </div>
            </CustomTooltip>
          </div>
        )}
        {loading ? (
          <div className="loader-wrapper"><Loader primary small normal /></div>
        ) : (
          <Document file={data} onLoadSuccess={handleDocumentLoadSuccess} renderMode="canvas" loading={t('general.loading')}>
            {pages[selectedPage]}
          </Document>
        )}
        {error && <p>{error}</p>}
      </div>
    </Modal>
  )
}

export default PreviewModal
import React, { useEffect, useState, useContext } from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Modal from '../../UI/Modal';
import Loader from '../../UI/Loader';
import Button from '../../UI/Button';
import ResponseLoader from '../../UI/ResponseLoader';
import { update_document, upload_file } from '../../../services/firestore';
import { dropbox_get_items, dropbox_get_temporary_link, dropbox_upload } from '../../../services/dropbox';
import { NotificationContext, TeamContext, DocumentsContext, GlobalContext } from '../../../context';

const DropboxModal = ({ onClose, token, doc, onFetchDocumentData, documentAttachments, type = 'document', onAttachmentUpload, onDocUpload }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext);
  const { activeTeamMember } = useContext(TeamContext);
  const { templates } = useContext(DocumentsContext)
  const [folders, setFolders] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [level, setLevel] = useState(0);
  const [dropboxFoldersPath, setDropboxFoldersPath] = useState(['']);

  // Get items
  useEffect(() => {
    const getItems = async () => {
      try {
        const res = await dropbox_get_items({ token: token, folderPath: '' });
        setFolders(res.data.entries);
        setLoadingFolders(false);
        // console.log(res);
      } catch (error) {
        console.log(error);
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        setLoadingFolders(false);
      }
    }
    getItems();
    // eslint-disable-next-line
  }, [token]);

  // Chage folder on click
  const itemClickHandler = async (f) => {
    setLoadingFolders(true);
    setLevel(prev => prev + 1);
    setDropboxFoldersPath(prev => [...prev, f.path_display]);
    try {
      const res = await dropbox_get_items({ token: token, folderPath: f.path_display });
      setFolders(res.data.entries);
      setLoadingFolders(false);
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  // Download file on click
  const downloadFileHandler = async (f) => {
    // console.log(f)
    if(type === 'attachment') {
      onAttachmentUpload(f)
    }else if(type === 'doc-upload') {
      onDocUpload(f)
    }else {
      if(f.is_downloadable) {
        try {
          const res = await dropbox_get_temporary_link({ token: token, filePath: f.path_display });
          const a = document.createElement("a"); 
          a.href = res.data.link;
          a.download = res.data.metadata.name;
          a.click(); 
        } catch (err) {
          console.log(err)
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        }
      }else {
        setNotification({ msg: t('notification.file_cannot_be_downloaded'), type: 'info' });
      }
    }
  }

  // Upload document
  const uploadDocHandler = async () => {
    try {
      setUploading(true);
      let url, format, name;
      if(!doc.documentUrls) {
        const documentData = await onFetchDocumentData(doc, 'pdf');
        let uploadResult = await upload_file(documentData, `${doc.id}`, 'pdf', 'application/pdf', 'documents')
        if(!uploadResult.url) {
          return
        }
        let documentUpdates = {}   
        documentUpdates[`documentUrls.pdf`] = uploadResult.url
        documentUpdates.last_modified_by = activeTeamMember.id
        documentUpdates.create_action = 'no'
        await update_document(doc.id, documentUpdates, documentAttachments)
        url = uploadResult.url;
        format = 'pdf';
        name = `${doc.name}.${format}`;
      }else {
        url = doc.documentUrls.pdf ? doc.documentUrls.pdf : doc.documentUrls.docx;
        format = doc.documentUrls.pdf ? 'pdf' : 'docx';
        name = `${doc.name}.${format}`;
      }
      await dropbox_upload({ fileUrl: url, fileName: name, token, folderPath: dropboxFoldersPath[dropboxFoldersPath.length - 1] });
      const fRes = await dropbox_get_items({ token: token, folderPath: dropboxFoldersPath[dropboxFoldersPath.length - 1] });
      setFolders(fRes.data.entries);
      setUploading(false);
      setNotification({ msg: t('notification.file_uploaded'), type: 'success' });
    } catch (err) {
      console.log(err)
      setUploading(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    }
  }

  // Go back
  const goBackHandler = async () => {
    setLevel(prev => prev - 1);
    setLoadingFolders(true);
    try {
      const res = await dropbox_get_items({ token: token, folderPath: level === 1 ? '' : dropboxFoldersPath[dropboxFoldersPath.length - 2] });
      setFolders(res.data.entries);
      setLoadingFolders(false);
      const copyPaths = [...dropboxFoldersPath];
      copyPaths.pop();
      setDropboxFoldersPath(copyPaths);
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="move-to-folder">
        <div className="move-to-folder__head">
          <h4>{t('general.upload_document_to', { driveName: 'Dropbox' })}</h4>
        </div>
        <div className="move-to-folder__body">
          {level > 0 && !loadingFolders && <Button text={t('general.go_back')} onButtonClick={() => goBackHandler()} disabled={loadingFolders} />}
          <div className="move-to-folder__body_main">
            {!loadingFolders ? 
              <ul>
                {folders.map((f, i) => {
                  if(f['.tag'] === 'folder') {
                    return <li className="folder-inner" key={i} onClick={() => itemClickHandler(f)}><FolderIcon /> {f.name}</li>
                  }else {
                    return <li className="folder-inner" key={i} onClick={() => downloadFileHandler(f)}><DescriptionOutlinedIcon /> {f.name}</li>
                  }
                })}
              </ul> 
                :
              <div className="loader-wrapper"><Loader small normal /></div>
            }
          </div>
        </div>
        <div className="move-to-folder__foot">
          <Button text={t('general.cancel')} onButtonClick={onClose} />
          {type === 'document' && <Button 
            text={t('general.upload_here_2')}
            onButtonClick={uploadDocHandler} 
            primary 
            disabled={loadingFolders}
          />}
        </div>
      </div>

      {uploading && <ResponseLoader text={t('general.uploading_file_to', { driveName: 'Dropbox' })} />}
    </Modal>
  );
}

export default DropboxModal;
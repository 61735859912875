import firebase from './firebase'

const get_file_data = async (attUrl) => {
  const ref = firebase.storage().refFromURL(attUrl)
  const fileRef = firebase.storage().ref(ref.fullPath)
  try {
    const data = await fileRef.getMetadata()
    return {...data, url: attUrl}
  } catch (err) {
    return {}
  }
}

export {
  get_file_data,
}
import { firebaseEnvDoc } from './firebase'

const COLLECTION = 'team_contacts'

const fetch_contacts = async (teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('fetch team contacts error', err)
  }
}

const create_contact = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).add(data)
    return res.id
  } catch (err) {
    console.log('create team contact error', err)
  }
}

const update_contact = async (data, id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).update(data)
  } catch (err) {
    console.log('update team contact error', err)
  }
}

const delete_contact = async (id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).delete()
    return {
      success: true
    }
  } catch (err) {
    console.log('delete team contact error', err)
  }
}

export {
  fetch_contacts,
  create_contact,
  update_contact,
  delete_contact,
}
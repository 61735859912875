import { useContext, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import { Template } from '../../assets/icon_components'
import CustomTooltip from '../UI/CustomTooltip'
import { LetterCircle } from '../new_ui'
import { LimitedFeatureWrapper } from '../utils/LimitedFeatureWrapper'
import { InviteUsersModal } from '../misc'
import { GlobalContext, TeamContext, UserContext } from '../../context'
import { saveSelectedTeamToLS } from '../../utils'
import { useAuth } from '../../hooks'

const SideMenu = () => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam, teams, setSelectedTeam, setFetchCollections, activeTeamMember, setActiveTeamMember, setTeamChanged } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { logout, resetState } = useAuth()
  const [showLongMenu, setShowLongMenu] = useState(false)
  const [showTeamDropdown, setShowTeamDropdown] = useState(false)
  const [showTeamInnerDropdown, setShowTeamInnerDropdown] = useState(false)
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false)
  const history = useHistory()

  // On open long menu
  const handleToggleLongMenu = (e) => {
    e.preventDefault()
    setShowLongMenu(!showLongMenu)
    setShowTeamDropdown(false)
    setShowTeamInnerDropdown(false)
  }

  // Menu item helper
  const menuItemHelper = (type, to, text, icon) => {
    if(type === 'short') {
      return (
        <NavLink to={to}>
          <CustomTooltip content={text} position="right">
            <div>
              {icon}
            </div>
          </CustomTooltip>
        </NavLink>
      )
    }

    return (
      <NavLink to={to}>
        <span className="icon">{icon}</span>
        <span className="text">{text}</span>
      </NavLink>
    )
  }

  // On toggle team dropdown
  const handleToggleTeamDropdown = (e) => {
    e.preventDefault()
    setShowTeamDropdown(!showTeamDropdown)
  }

  // On invite modal open
  const handleOpenInviteModal = () => {
    setShowLongMenu(false)
    setShowTeamDropdown(false)
    setShowInviteUsersModal(true)
  }

  // On go to team settings page
  const handleGoToTeamSettings = () => {
    setShowLongMenu(false)
    setShowTeamDropdown(false)
    history.push('/settings/team', { mode: 'edit' })
  }

  // On team change
  const handleTeamChange = (team) => {
    setSelectedTeam(team)
    saveSelectedTeamToLS(team.id)
    setShowTeamDropdown(false)
    setShowTeamInnerDropdown(false)
    const activeMember = team.users.find(u => u.email === activeTeamMember.email)
    setActiveTeamMember({...activeTeamMember, ...activeMember})
    setShowLongMenu(false)
    resetState('team-change')
    setFetchCollections(true)
    setTeamChanged(true)
  }

  // On go to add team
  const handleGoToAddTeam = () => {
    setShowLongMenu(false)
    setShowTeamDropdown(false)
    history.push('/settings/add-team', { mode: 'add' })
  }

  // On logout
  const handleLogout = () => {
    logout()
  }

  // On close invite users modal
  const handleCloseInviteUsersModal = () => {
    setShowInviteUsersModal(false)
  }

  // Render team dropdown
  const renderTeamDropdown = () => {
    return (
      <div className="team-dropdown-v2">
        <div className="team-dropdown-v2__head">
          {selectedTeam.logo_url
            ? <div className="team-dropdown-v2__head_image-box" style={{ backgroundImage: `url(${selectedTeam.logo_url})` }}></div>
            : <LetterCircle data={selectedTeam.name} hideTooltip />
          }
          <div className="team-dropdown-v2__head_info-box">
            <h5>{selectedTeam?.name}</h5>
          </div>
        </div>
        <div className="team-dropdown-v2__body">
          <LimitedFeatureWrapper requirement="premium" type="team">
            {user.id === selectedTeam.owner && <div className="team-dropdown-v2__link-item" onClick={handleOpenInviteModal}>
              <span className="material-symbols-outlined size-24">person_add</span>
              <p>{t('team.invite')}</p>
            </div>}
          </LimitedFeatureWrapper>
          <div className="team-dropdown-v2__link-item" onClick={handleGoToTeamSettings}>
            <span className="material-symbols-outlined size-24">settings</span>
            <p>{t('team.settings')}</p>
          </div>
        </div>
        { teams.length > 1 &&
        <div className="team-dropdown-v2__body team-dropdown-v2__body--no-border-top">
          <div 
            className="team-dropdown-v2__link-item" 
            onMouseOver={() => setShowTeamInnerDropdown(true)} 
            onMouseLeave={() => setShowTeamInnerDropdown(false)}
          >
            <span className="material-symbols-outlined size-24">shuffle</span>
            <p>{t('team.change')}</p>
            <div className="icon-right"><span className="material-symbols-outlined size-24">keyboard_arrow_right</span></div>
            {showTeamInnerDropdown && <div className="inner-dropdown">
              <p className="inner-dropdown__title">{t('team.other_teams')}</p>
              <ul className="inner-dropdown__list">
                {teams.filter(team => team.id !== selectedTeam?.id).map(team => (
                  <li key={team.id} onClick={() => handleTeamChange(team)}>
                    <div className="logo-box" style={team.logo_url ? { backgroundImage: `url(${team.logo_url})` } : {}}></div>
                    <p>{team.name}</p>
                  </li>
                ))}
              </ul>
            </div>}
          </div>
          <LimitedFeatureWrapper type="account" requirement="premium">
          <div className="team-dropdown-v2__link-item" onClick={handleGoToAddTeam}>
            <span className="material-symbols-outlined size-24">add</span>
            <p>{t('team.add')}</p>
          </div>
          </LimitedFeatureWrapper>
        </div>
        }
        <div className="team-dropdown-v2__foot">
          <div className="team-dropdown-v2__link-item" onClick={handleLogout}>
            <span className="material-symbols-outlined size-24">logout</span>
            <p>{t('team.logout', { name: selectedTeam?.name })}</p>
          </div>
        </div>
      </div>
    )
  }

  // Render list
  const renderList = (type = 'short') => {
    return (
      <ul className="side-menu-v2__list">
        <li className="menu">
          {type === 'short' ? (
            <a href="/#" onClick={handleToggleLongMenu}>
              <span className="material-symbols-outlined size-24">menu</span>
            </a>
          ) : (
            <div className="team-menu-wrapper">
              <span className="icon icon-left" onClick={handleToggleLongMenu}><span className="material-symbols-outlined size-24">menu_open</span></span>
              <div className="team-menu" onClick={handleToggleTeamDropdown}>
                <span className="text">{selectedTeam?.name}</span>
                <span className="material-symbols-outlined size-24">expand_more</span>
                {selectedTeam && showTeamDropdown && renderTeamDropdown()} 
              </div>
            </div>
          )}
        </li>
        <li>
          {menuItemHelper(type, '/dashboard', t('dashboard.main_title'), <span className="material-symbols-outlined size-24">team_dashboard</span>)}
        </li>
        <li>
          {menuItemHelper(type, '/documents', t('dashboard.documents'), <span className="material-symbols-outlined size-24">description</span>)}
        </li>
        <li>
          {menuItemHelper(type, '/templates', t('dashboard.templates'), <Template />)}
        </li>
        <li>
          {menuItemHelper(type, '/signatures', t('dashboard.signatures'), <span className="material-symbols-outlined size-24">history_edu</span>)}
        </li>
        <li>
          {menuItemHelper(type, '/approvals', t('approvals.title'), <span className="material-symbols-outlined size-24">approval</span>)}
        </li>
        <li>
          {menuItemHelper(type, '/shared-templates', t('dashboard.shared_templates'), <span className="material-symbols-outlined size-24">share</span>)}
        </li>
        <li className="divider"></li>
        <li>
          {menuItemHelper(type, '/contacts', t('general.contacts'), <span className="material-symbols-outlined size-24">contact_page</span>)}
        </li>
        <li>
          {menuItemHelper(type, '/variables', t('dashboard.variables'), <span className="material-symbols-outlined size-24">integration_instructions</span>)}
        </li>
        <li className="divider"></li>
        <li>
          {menuItemHelper(type, '/calendar', t('dashboard.alerts'), <span className="material-symbols-outlined size-24">calendar_month</span>)}
        </li>
        <li>
          {menuItemHelper(type, '/tasks', t('dashboard.tasks'), <span className="material-symbols-outlined size-24">done</span>)}
        </li>
        <li className="divider"></li>
        <li>
          {menuItemHelper(type, '/external-documents', t('dashboard.external_documents'), <span className="material-symbols-outlined size-24">edit_document</span>)}
        </li>
      </ul>
    )
  }

  return (
    <>
      <div className={`side-menu-v2__overlay ${showLongMenu ? 'active' : ''}`} onClick={handleToggleLongMenu}></div>
      <div className="side-menu-v2">
        {renderList()}
      </div>
      <div className={`side-menu-v2 side-menu-v2--long ${showLongMenu ? 'opened' : ''}`}>
        {renderList('long')}
      </div>

      {showInviteUsersModal && (
        <InviteUsersModal 
          onClose={handleCloseInviteUsersModal} 
        />
      )}
    </>
  )
}

export default SideMenu
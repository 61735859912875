import React, { useState, useEffect, useContext } from 'react'
import { SearchOutlined, Home, Phone, AlternateEmail } from '@material-ui/icons'

import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import Loader from '../../UI/Loader'
import { ContactsContext, TeamContext, GlobalContext } from '../../../context'

const ContactsModal = ({ onClose, onContactSelect, currentVariable }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { fetchContacts, contactsFetched, contacts } = useContext(ContactsContext)
  const [search, setSearch] = useState('')
  const [filteredContacts, setFilteredContacts] = useState([])
  const [defaultContacts, setDefaultContacts] = useState([])
  const [loading, setLoading] = useState(true)

  // Fetch notaries if not fetched yet
  useEffect(() => {
    if(selectedTeam?.id && !contactsFetched) {
      fetchContacts(selectedTeam.id)
    }
    if(contactsFetched && selectedTeam) {
      let arr = []
      const groupsLinkedVars = selectedTeam.contacts_linked_variables
      if(groupsLinkedVars) {
        let linkedVarsObj = {}
        for(let key in groupsLinkedVars) {
          const vars = groupsLinkedVars[key]
          let groupVars = []
          for(let field in vars) {
            groupVars.push(vars[field])
          }
          linkedVarsObj[key] = groupVars
        }
        contacts.forEach(c => {
          if(selectedTeam.contacts_linked_variables[c.group]) {
            if(linkedVarsObj[c.group] && linkedVarsObj[c.group].includes(currentVariable)) {
              arr.push(c)
            }
          }
        })
      }
      setLoading(false)
      setFilteredContacts(arr)
      setDefaultContacts(arr)
    }
  }, [selectedTeam, contactsFetched, fetchContacts, contacts, currentVariable])

  // On search
  const searchChangeHandler = (e) => {
    const { value } = e.target
    setSearch(value)
    let filtered = []
    if(value.trim() !== '') {
      const val = value.trim().toLowerCase()
      defaultContacts.forEach(n => {
        let name = ''
        let email = n.email || ''
        if(n.first_name) name = n.first_name
        if(n.last_name) name = name ? ` ${n.last_name}` : n.last_name
        if(name.toLowerCase().includes(val) || email.toLowerCase().includes(val)) {
          filtered.push(n)
        }
      })
      setFilteredContacts(filtered)
      return 
    }
    setFilteredContacts(defaultContacts)
  }

  // On contact select
  const handleContactSelect = (contact) => {
    onContactSelect(contact)
  }

  return (
    <Modal onClose={onClose}>
      <div className="notaries-modal">
        <div className="notaries-modal__head">
          <h2>{t('contacts.search_contact')}</h2>
        </div>
        <div className="notaries-modal__search notaries-modal__search--2">
          <Input iconEl={<SearchOutlined />} value={search} onChange={searchChangeHandler} placeholder={t('dashboard.search')} formEl />
        </div>
        {loading 
          ? <div className="loader-wrapper"><Loader small primary normal /></div>
          : 
          <div className="notaries-modal__body">
            <div className="notaries-modal__body_boxes">
              {filteredContacts.map((contact, i) => {
                return (
                  <div className="notary-box" key={i}>
                    <h4>{contact.first_name} {contact.last_name}</h4>
                    <div className="address"><Home /> {contact.address} {contact.street_number} {contact.zip_code} {contact.city}</div>
                    <div className="phone-email">
                      <p><Phone /> {contact.phone}</p>
                      <p><AlternateEmail /> {contact.email}</p>
                    </div>
                    <div className="overlay" onClick={() => handleContactSelect(contact)}></div>
                  </div>
                )
              })}
              {contactsFetched && filteredContacts.length === 0 && <p className="no-data">{t('contacts.no_data')}</p>}
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default ContactsModal
import { useContext, useEffect, useState, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { ApprovalsView } from '../components/misc'
import { GlobalContext, ApprovalsContext, TeamContext } from '../context'
import { sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'

const Approvals = () => {
  const { t } = useContext(GlobalContext)
  const { approvalsFetched, fetchOtherApprovals, fetchApprovals, singleApprovalFetched, approvals, approvalsSort, setApprovalsSort, approvalsSelectedFilters } = useContext(ApprovalsContext)
  const { teamChanged, setTeamChanged, selectedTeam } = useContext(TeamContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [activeApprovals, setActiveApprovals] = useState([])
  const [currentApprovals, setCurrentApprovals] = useState([])
  const [ready, setReady] = useState(false)
  const [paginatedData, setPaginatedData] = useState([])
  const [selectedApprovals, setSelectedApprovals] = useState([])
  const [allChecked, setAllChecked] = useState(false)

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch approvals if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!approvalsFetched) {
        // console.log('fetch approvals')
        if(!singleApprovalFetched) {
          fetchApprovals(teamId)
        }else {
          fetchOtherApprovals(teamId)
        }
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, approvalsFetched, singleApprovalFetched, fetchApprovals, fetchOtherApprovals])

  // Set approvals
  const setupApprovals = useCallback(async () => {
    let active = [...approvals]
    active = active.map(a => ({...a, sort_steps: a.steps.length}))
    let sorted = await sortArrayOfObjects(active, approvalsSort.value, approvalsSort.order)
    setActiveApprovals([...sorted])
    setCurrentApprovals([...sorted])
    setReady(true)
  }, [approvals, approvalsSort])

  // Setup tasks when all data is fetched - only once, on component mount
  useEffect(() => {
    if(approvalsFetched && !ready) {
      setupApprovals()
    }
  }, [approvalsFetched, setupApprovals, ready])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
  }, [])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeApprovals]

    for(let key in approvalsSelectedFilters) {
      if(key === 'search' && approvalsSelectedFilters[key] !== '') {
        filtered = filtered.filter(c => c.name.toLowerCase().includes(approvalsSelectedFilters[key].trim().toLowerCase()))
      }
    }

    let sorted = sortArrayOfObjects(filtered, approvalsSort.value, approvalsSort.order)
    setCurrentApprovals([...sorted])
  }, [activeApprovals, approvalsSort, approvalsSelectedFilters])

  // Filter tasks when tasksSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = approvalsSort.order === 'desc' ? 'asc' : 'desc'
    setApprovalsSort({ value: sort_by, order: currentOrder })
    // if(isCookieAccepted()) {
    //   saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'approvals')
    // }
  }

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedApprovals([])
    }else {
      setSelectedApprovals(paginatedData)
    }
  }

  return (
    <MainLayout
      title={t('approvals.title')}
    >
      <ApprovalsView 
        filteredApprovals={currentApprovals}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        refreshApprovals={refreshCollection}
        ready={ready}
        onSort={handleSort}
        setSelectedApprovals={setSelectedApprovals}
        allChecked={allChecked}
        selectedApprovals={selectedApprovals}
        onAllCheckedChange={handleAllChecked}
        setAllChecked={setAllChecked}
      />
    </MainLayout>
  )
}

export default Approvals
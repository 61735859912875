import { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { GlobalContext, NotificationContext, DocumentsContext } from '../context'
import { getFileData } from '../helpers/files'
import { upload_file_with_progress } from '../services/firestore'

const useAttachmentsActions = () => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { updateDocument } = useContext(DocumentsContext)

  // Upload attachments
  const uploadAttachments = async ({ e, errors, setErrors, attachmentsNames, attachments, setUploading, setProgress, setAttachments, documentId, onlyPdfAndDocx }) => {
    let allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg', 'image/gif']

    if(onlyPdfAndDocx) {
      allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    }

    if(errors.length > 0) {
      setErrors([])
    }
    const files = e.target.files || e.dataTransfer.files
    let error = false
    let nameAlreadyExistsErr = ''
    let sizeErr = ''
    const maxSize = 10485760
    for(let i = 0; i < files.length; i++) {
      if(!allowedFileTypes.includes(files[i].type)) {
      // if(!files[i].name.includes('pdf') && !files[i].name.includes('docx')) {
        error = true;
        break;
      }
      if(attachmentsNames.includes(files[i].name)) {
        setErrors(prev => [...prev, ]);
        nameAlreadyExistsErr = t('notification.file_already_exists', { file: files[i].name }) 
        break
      }
      if(files[i].size > maxSize) {
        sizeErr = t('notification.file_too_big')
      }
    }

    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type'), type: 'danger' });
    }

    if(nameAlreadyExistsErr) {
      return setNotification({ msg: nameAlreadyExistsErr, type: 'danger' })
    }
    
    if(sizeErr) {
      return setNotification({ msg: sizeErr, type: 'danger' })
    }

    let docAttachments = [...attachments]

    // If only 1 file
    if(files.length === 1) {
      setUploading(true)
      const file = files[0]
      const data = await getFileData(file)
      const created = Date.now()
      let nameWithTimestamp = `${data.name}-${created}`
      let att = {
        name: data.name,
        att_path: `attachments/${nameWithTimestamp}.${data.format}`,
        created,
        format: data.format,
        type: data.type,
        id: uuidv4(),
        url: '',
        size: data.size
      }
      
      const fileUrlRes = await upload_file_with_progress(file, nameWithTimestamp, att.format, att.type, 'attachments', (p) => {
        setProgress(Math.floor(p))
      })

      if(fileUrlRes.url) {
        att.url = fileUrlRes.url
        docAttachments.push(att)
        await updateDocument({ attachments: docAttachments }, { id: documentId })
        setAttachments(docAttachments)
      }
      
    }else { // if multiple files
      const filesArr = Array.from(files)
      const promises = []
      setUploading(true)

      for(let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i]
        promises.push(getFileData(file))
      }

      let mProgress = 0
      let total = 0

      let docAttachments = [...attachments]
      await Promise.all(promises).then(async data => {
        for(let i = 0; i < data.length; i++) {
          let created = Date.now()
          const file = filesArr[i]
          let attData = data[i]
          let nameWithTimestamp = `${attData.name}-${created}`
          let att = {
            name: attData.name,
            att_path: `attachments/${nameWithTimestamp}.${attData.format}`,
            created,
            format: attData.format,
            type: attData.type,
            id: uuidv4(),
            url: '',
            size: attData.size
          }
          // eslint-disable-next-line no-loop-func
          const fileUrlRes = await upload_file_with_progress(file, nameWithTimestamp, att.format, att.type, 'attachments', (p) => {
            let current = (Math.floor(p) / 100) * (100 / data.length)
            mProgress = total + current
            if(current >= Math.floor(100 / data.length)) {
              total += current
            }
            setProgress(Math.floor(mProgress))
          });
          if(fileUrlRes.url) {
            att.url = fileUrlRes.url
          }
          docAttachments.push(att)
        }
      })

      if(promises.length > 0) {
        await updateDocument({ attachments: docAttachments }, { id: documentId })
        setAttachments(docAttachments)
      }

    }

    setUploading(false)
    setProgress(0)
  }

  return {
    uploadAttachments,
  }
}

export default useAttachmentsActions
import { GET_CONTACTS, SET_CONTACTS, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload.contacts,
        companies: action.payload.companies,
        contactsFetched: true,
      }
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
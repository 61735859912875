export const SET_LOADING = 'SET_LOADING';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_TEMPLATES_ARR = 'SET_TEMPLATES_ARR';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_DOCUMENTS_ARR = 'SET_DOCUMENTS_ARR';
export const SET_FETCH_DOCUMENTS = 'SET_FETCH_DOCUMENTS';
export const SET_FILTERED_DOCUMENTS = 'SET_FILTERED_DOCUMENTS';
export const SET_ARCHIVED_DOCUMENTS = 'SET_ARCHIVED_DOCUMENTS';
export const SET_DELETED_DOCUMENTS = 'SET_DELETED_DOCUMENTS';
export const SET_FAVORITE_DOCUMENTS = 'SET_FAVORITE_DOCUMENTS';
export const SET_SHOW_FILTERED_DOCS = 'SET_SHOW_FILTERED_DOCS';
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const SET_ACTIVE_FILTER_COLUMN = 'SET_ACTIVE_FILTER_COLUMN';
export const SET_DATE_FILTERS = 'SET_DATE_FILTERS';
export const SET_FILTERED_TEMPLATES = 'SET_FILTERED_TEMPLATES';
export const SET_TEMPLATES_LOADING = 'SET_TEMPLATES_LOADING';
export const SET_ACTIVE_VARS_SECTION = 'SET_ACTIVE_VARS_SECTION';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_FETCH_ACTIONS = 'SET_FETCH_ACTIONS';
export const RESET_STATE = 'RESET_STATE';
export const SET_DOCUMENT_LISTS = 'SET_DOCUMENT_LISTS'

// Folders
export const GET_FOLDERS = 'GET_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';
export const SET_FOLDERS_LOADING = 'SET_FOLDERS_LOADING';

export const GET_DOCUMENTS_FOLDERS = 'GET_DOCUMENTS_FOLDERS';
export const SET_DOCUMENTS_FOLDERS = 'SET_DOCUMENTS_FOLDERS';
export const SET_DOCUMENTS_FOLDERS_LOADING = 'SET_DOCUMENTS_FOLDERS_LOADING';

// Signatures
export const GET_SIGNATURES = 'GET_SIGNATURES';
export const SET_SIGNATURES = 'SET_SIGNATURES';
export const SET_SIGNATURES_OBJ = 'SET_SIGNATURES_OBJ';
export const ADD_SIGNATURE = 'ADD_SIGNATURE';
export const DELETE_SIGNATURE = 'DELETE_SIGNATURE';

// User
export const SET_USER = 'SET_USER';
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_INVOICES = 'SET_INVOICES'

// Tasks
export const GET_TASKS = 'GET_TASKS';
export const SET_TASKS = 'SET_TASKS';
export const SET_TASKS_OBJ = 'SET_TASKS_OBJ';

// Alerts
export const GET_ALERTS = 'GET_ALERTS';
export const SET_ALERTS = 'SET_ALERTS';

// Tags
export const GET_TAGS = 'GET_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const SET_TAGS_OBJ = 'SET_TAGS_OBJ';

// Contacts
export const GET_CONTACTS = 'GET_CONTACTS';
export const SET_CONTACTS = 'SET_CONTACTS';

// Teams
export const GET_TEAMS = 'GET_TEAMS';
export const SET_TEAMS = 'SET_TEAMS';

// Variables
export const GET_VARIABLES = 'GET_VARIABLES'
export const GET_VARIABLE_CATEGORIES = 'GET_VARIABLE_CATEGORIES'
export const SET_VARIABLES = 'SET_VARIABLES'
export const SET_VARIABLE_CATEGORIES = 'SET_VARIABLE_CATEGORIES'

// Help
export const GET_HELP_ARTICLES = 'GET_HELP_ARTICLES'
export const GET_HELP_CATEGORIES = 'GET_HELP_CATEGORIES'

// Notaries
export const GET_NOTARIES = 'GET_NOTARIES'
export const SET_NOTARIES = 'SET_NOTARIES'

// Contact groups
export const GET_CONTACT_GROUPS = 'GET_CONTACT_GROUPS'
export const SET_CONTACT_GROUPS = 'SET_CONTACT_GROUPS'

// Shared template pages
export const GET_SHARED_TEMPLATE_PAGES = 'GET_SHARED_TEMPLATE_PAGES'
export const SET_SHARED_TEMPLATE_PAGES = 'SET_SHARED_TEMPLATE_PAGES'

// Single shared templates
export const GET_SINGLE_SHARED_TEMPLATES = 'GET_SINGLE_SHARED_TEMPLATES'
export const SET_SINGLE_SHARED_TEMPLATES = 'SET_SINGLE_SHARED_TEMPLATES'

// Approvals
export const GET_APPROVALS = 'GET_APPROVALS'
export const SET_APPROVALS = 'SET_APPROVALS'

// Signature templates
export const GET_SIGNATURE_TEMPLATES = ''
export const SET_SIGNATURE_TEMPLATES = ''

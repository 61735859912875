import { useContext, useRef, useEffect } from 'react'

import { DocumentsContext, TeamContext, GlobalContext, TagContext, SignatureContext, TaskContext, UserContext } from '../context'
import { firebaseEnvDoc } from '../services/firebase'

const useRealTimeData = () => {
  const { documents, setDocuments, actions, setActions, documentLists, setDocumentLists, templates, setTemplates } = useContext(DocumentsContext)
  const { selectedTeam, setSelectedTeam } = useContext(TeamContext)
  const { docComments, setDocComments } = useContext(GlobalContext)
  const { tagsObj, setTagsObj } = useContext(TagContext)
  const { signaturesObj, setSignaturesObj } = useContext(SignatureContext)
  const { tasksObj, setTasksObj } = useContext(TaskContext)
  const { setInvoices, setSubscription, setMembership } = useContext(UserContext)
  const a = useRef(null)
  const c = useRef(null)
  const d = useRef(null)
  const dl = useRef(null)
  const dt = useRef(null)
  const s = useRef(null)
  const tg = useRef(null)
  const tm = useRef(null)

  // If team changed reset refs
  useEffect(() => {
    if(a.current) {
      a.current = null
    }
    if(c.current) {
      c.current = null
    }
    if(d.current) {
      d.current = null
    }
    if(dl.current) {
      dl.current = null
    }
    if(s.current) {
      s.current = null
    }
    if(tg.current) {
      tg.current = null
    }
    if(tm.current) {
      tm.current = null
    }
  }, [selectedTeam?.id])

  // Listen to document changes
  const listenToDocsChanges = () => {
    return firebaseEnvDoc.collection('documents').where('team', '==', selectedTeam.id).onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let docs = d.current ? {...d.current} : {...documents}
      console.log('**real time documents changes**', changes.length)

      // loop through changed(added, modified, removed) documents
      changes.forEach((change) => {
        // console.log(change.type, change.doc.id)
        updateCollectionHelper(docs, change) 
      })

      if(changes.length === 0) {
        setDocuments({})
        d.current = null
      } else {
        setDocuments(docs)
        d.current = docs
      }
    })
  }

  // Listen to team changes
  const listenToTeamChanges = () => {
    return firebaseEnvDoc.collection('teams').doc(selectedTeam.id).onSnapshot(snapshot => {
      console.log('**real time team changes**')
      let data = snapshot.data()
      delete data.users // do not update users array otherwise each user will have only email, role and status props, update this so that this doesn't have to be deleted 
      setSelectedTeam({...selectedTeam, ...data})
    })
  }

  // Listen to comments changes
  const listenToCommentsChanges = (docId) => {
    return firebaseEnvDoc.collection('documents').doc(docId).collection('comments').onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let comments = c.current ? {...c.current} : {...docComments}
      console.log('**real time comments changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data(), change.doc.id)
        updateCollectionHelper(comments, change)
      })

      if(changes.length === 0) {
        setDocComments({})
        c.current = null
      } else {
        setDocComments(comments)
        c.current = comments
      }
    })
  }

  // Listen to actions changes
  const listenToActionsChanges = () => {
    return firebaseEnvDoc.collection('actions').where('team', '==', selectedTeam.id).limit(30).onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let actns = a.current ? {...a.current} : {...actions}
      console.log('**real time actions changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data())
        updateCollectionHelper(actns, change)
      })

      if(changes.length === 0) {
        setActions({})
        a.current = null
      } else {
        setActions(actns)
        a.current = actns
      }
    })
  }

  // Listen to doc lists changes
  const listenToDocListsChanges = () => {
    return firebaseEnvDoc.collection('teams').doc(selectedTeam.id).collection('document_lists').onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let docLists = dl.current ? {...dl.current} : {...documentLists}
      console.log('**real time doc lists changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data(), change.doc.id)
        updateCollectionHelper(docLists, change)
      })

      if(changes.length === 0) {
        setDocumentLists({})
        dl.current = null
      } else {
        setDocumentLists(docLists)
        dl.current = docLists
      }
    })
  }

  // Listen to tags changes
  const listenToTagsChanges = () => {
    return firebaseEnvDoc.collection('tags').where('team', '==', selectedTeam.id).onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let tags = tg.current ? {...tg.current} : {...tagsObj}
      console.log('**real time tags changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data(), change.doc.id)
        updateCollectionHelper(tags, change)
      })
      
      if(changes.length === 0) {
        setTagsObj({})
        tg.current = null
      } else {
        setTagsObj(tags)
        tg.current = tags
      }
    })
  }

  // Listen to signatures changes
  const listenToSignaturesChanges = () => {
    return firebaseEnvDoc.collection('signatures').where('team', '==', selectedTeam.id).onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let signatures = s.current ? {...s.current} : {...signaturesObj}
      console.log('**real time signatures changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data(), change.doc.id)
        updateCollectionHelper(signatures, change)
      })
      
      if(changes.length === 0) {
        setSignaturesObj({})
        s.current = null
      } else {
        setSignaturesObj(signatures)
        s.current = signatures
      }
    })
  }

  // Listen to templates changes
  const listenToTemplatesChanges = () => {
    return firebaseEnvDoc.collection('templates').where('owner', '==', 'carbon').onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let tmplts = tm.current ? {...tm.current} : {...templates}
      console.log('**real time templates changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data(), change.doc.id)
        updateCollectionHelper(tmplts, change)
      })
      
      if(changes.length === 0) {
        setTemplates({})
        tm.current = null
      } else {
        setTemplates(tmplts)
        tm.current = tmplts
      }
    })
  }

  // Listen to tags changes
  const listenToTasksChanges = (docId) => {
    return firebaseEnvDoc.collection('tasks').where('team', '==', selectedTeam.id).where('relatedDocument', '==', docId).onSnapshot(snapshot => {
      let changes = snapshot.docChanges()
      let tasks = dt.current ? {...dt.current} : {...tasksObj}
      console.log('**real time task changes**', changes.length)

      changes.forEach((change) => {
        // console.log(change.type, change.doc.data(), change.doc.id)
        updateCollectionHelper(tasks, change)
      })
      
      if(changes.length === 0) {
        setTasksObj({})
        dt.current = null
      } else {
        setTasksObj(tasks)
        dt.current = tasks
      }
    })
  }

  // Helper - update collection 
  const updateCollectionHelper = (obj, change) => {
    if(change.type === 'added') {
      if(obj[change.doc.id]) {
        obj[change.doc.id] = {...obj[change.doc.id], ...change.doc.data()}
      }else {
        obj[change.doc.id] = {...change.doc.data()}
      }
    }else if(change.type === 'modified') {
      obj[change.doc.id] = {...obj[change.doc.id], ...change.doc.data()}
    }else if(change.type === 'removed') {
      delete obj[change.doc.id]
    } 
  }

  // Listen to subscription changes
  const listenToSubscriptionChanges = (user) => {
    return firebaseEnvDoc.collection('subscriptions').where('user_id', '==', user.uid).onSnapshot(snapshot => {
      // console.log('on subscription changes', snapshot.docChanges().length)
      let subscription = null
      let membership = 'free'

      for(let doc of snapshot.docs) { 
        let subscriptionData = doc.data()
        const { status } = subscriptionData
        if(status === 'active' || status === 'past_due' || status === 'incomplete') {
          subscription = subscriptionData
          membership = subscriptionData.membership
        }
      }
      // console.log(subscription)
      // console.log(membership)
      // if(snapshot.docs.length > 0) {
      //   subscription = snapshot.docs[0].data()
      // }
      const isSubscriptionActive = subscription && subscription.status === 'active'
      setMembership(isSubscriptionActive ? membership : 'free')
      setSubscription(subscription)
    })
  }

   // Listen to invoices changes
   const listenToInvoicesChanges = (user) => {
    return firebaseEnvDoc.collection('invoices').where('user_id', '==', user.uid).onSnapshot(snapshot => {
      // console.log('on invoices changes', snapshot.docChanges().length)
      let invoices = []
      snapshot.docs.forEach(doc => {
        invoices.push({...doc.data(), id: doc.id})
      })
      // console.log(invoices)
      setInvoices(invoices)
    })
  }

  return { 
    listenToDocsChanges, 
    listenToTeamChanges, 
    listenToCommentsChanges, 
    listenToActionsChanges,
    listenToDocListsChanges, 
    listenToTagsChanges,
    listenToSignaturesChanges,
    listenToTemplatesChanges,
    listenToTasksChanges,
    listenToSubscriptionChanges,
    listenToInvoicesChanges
  }
}

export default useRealTimeData
import cookie from 'react-cookies'

const CARBON_LANG_KEY = 'carbon_selected_lang';

// Current language
const currentLanguage = () => {
  let language = cookie.load(CARBON_LANG_KEY)
  if(cookie.load('carbon_cookie_consent')) {
    if(!language) {
      language = languageFromLocale('fr_BE').code
    }
  }else {
    language = languageFromLocale('fr_BE').code
  }
  return language
}

// Language from locale
const languageFromLocale = (locale) => {
  switch(locale) {
    case 'fr_FR':
      return { long: 'Français', code: 'fr' }
    case 'fr_CM':
      return { long: 'Français', code: 'fr' }
    case 'en_CM':
      return { long: 'English', code: 'en' }
    case 'en_GH':
      return { long: 'English', code: 'en' }
    case 'fr_BE':
      return { long: 'Français', code: 'fr' }
    case 'nl_BE':
      return { long: 'Nederlands', code: 'nl' }
    default:
      return { long: '', code: '' }
  }
}

const errorCodeToKey = (c) => {
  return c.replace('/', '.').replace(/-/g, '_')
}

const errorMessage = (err, t) => {
  if(!err || (!err.code && !err.message)) { 
    return t('notification.something_went_wrong')
  } else if(!err.code) {
    return err.message
  }
  const key = errorCodeToKey(err.code)
  let message = t(key)
  if(message === key) {
    return err.message || message
  }
  return message
}

// THIS FUNCTION SHOULD NOT BE USED ANYMORE - use the one from GlobalContext
// const t = (key, params, defaultValue = key) => {
//   const language = currentLanguage()
//   const components = key.split('.')
//   if(!source[language]) {
//     return defaultValue
//   }
//   const section = source[language][components[0]]
//   if(!section) {
//     return defaultValue
//   }
//   let value = section[components[1]]
//   if(!value) {
//     return defaultValue
//   }
//   if(!!params) {
//     for(let p in params) {
//       value = value.replace(`{{${p}}}`, params[p])
//     }
//   }
//   return value
// }

const setLanguage = (l) => {
  cookie.save(CARBON_LANG_KEY, l, { path: '/' })
}

export {
  setLanguage,
  currentLanguage,
  CARBON_LANG_KEY,
  errorMessage
}
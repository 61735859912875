import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from './Button';
import { GlobalContext } from '../../context'

const NoAccess = ({ message, showBtn }) => {
  const { t } = useContext(GlobalContext)
  const history = useHistory();

  return (
    <div className="no-access">
      <h2>{message}</h2>
      {showBtn && <Button text={t('general.go_back')} primary medium onButtonClick={() => history.push('/settings/team')} />}
    </div>
  );
}

export default NoAccess;
import React, { useState, useContext, useEffect } from 'react' 
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons'

import Button from '../../UI/Button'
import Modal from '../../UI/Modal'
import ResponseLoader from '../../UI/ResponseLoader'

import { DocumentsContext, GlobalContext, TeamContext } from '../../../context'

const ListOfTemplatesModal = ({ onClose, addedTemplates, onAddNewTemplates }) => {
  const { t } = useContext(GlobalContext)
  const { templatesArr, templatesLoaded, getTemplates, getTemplatesByIds } = useContext(DocumentsContext)
  const { selectedTeam } = useContext(TeamContext)
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [showResponseLoader, setShowResponseLoader] = useState(false)

  // Fetch documents and folders if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!templatesLoaded) {
        // console.log('fetch templates')
        getTemplates(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, templatesLoaded, getTemplates])

  // Set filtered templates
  useEffect(() => {
    if(addedTemplates) {
      setFilteredTemplates(templatesArr.filter(tmpl => !addedTemplates.includes(tmpl.id)))
    }
  }, [addedTemplates, templatesArr])

  // On check/uncheck all templates
  const handleCheckAllTemplates = () => {
    setAllChecked(!allChecked)
    if(!allChecked) {
      setSelectedTemplates(filteredTemplates.map(tmpl => tmpl.id))
    }else {
      setSelectedTemplates([])
    }
  }

  // On check/uncheck template
  const handleCheckTemplate = (id) => {
    if(selectedTemplates.includes(id)) {
      setSelectedTemplates(selectedTemplates.filter(tmplId => tmplId !== id))
    }else {
      setSelectedTemplates([...selectedTemplates, id])
    }
  }

  // On add selected templates
  const handleAddSelectedTemplates = async () => {
    const fullTemplates = templatesArr.filter(t => selectedTemplates.includes(t.id))
    let templateThumbnails = fullTemplates.filter(t => !t.sections)
    let arr = []
    if(templateThumbnails.length > 0) {
      setShowResponseLoader(true)
      arr = await getTemplatesByIds(selectedTeam.id, templateThumbnails.map(t => t.id))
      setShowResponseLoader(false)
    }
    let newTemplates = []
    selectedTemplates.forEach(t => {
      let tmplFound = arr.find(tmpl => tmpl.id === t)
      if(tmplFound) {
        newTemplates.push({ id: tmplFound.id, ...tmplFound.data })
      }else if(templatesArr.find(tmpl => tmpl.id === t)){
        newTemplates.push(templatesArr.find(tmpl => tmpl.id === t))
      }
    })
    onAddNewTemplates(newTemplates)
    onClose()
  }
  // console.log(templatesArr)

  return (
    <Modal onClose={onClose}>
      <div className="list-of-templates-modal">
        <div className="list-of-templates-modal__head">
          <h2>{t('share_template.choose_templates')}</h2>
        </div>
        <div className="list-of-templates-modal__body">
          <header>
            <div className={`checkbox-all ${allChecked ? 'checked' : ''}`} onClick={handleCheckAllTemplates}>
              {allChecked ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
              <p>Select/unselect all</p>
            </div>
          </header>
          <ul className="list u-custom-scrollbar">
            {filteredTemplates.map((tmpl, idx) => {
              return (
                <li key={idx} className={selectedTemplates.includes(tmpl.id) ? 'active' : ''} onClick={() => handleCheckTemplate(tmpl.id)}>
                  <div className="checkboxes">
                    <CheckBox className="checked" />
                    <CheckBoxOutlineBlankOutlined className="unchecked" />
                  </div>
                  {tmpl.name}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="list-of-templates-modal__foot">
          <Button 
            text={t('general.cancel')}
            onButtonClick={onClose}
            transparent
            medium
          />
          <Button 
            text={t('share_template.add_selected_templates')} 
            onButtonClick={handleAddSelectedTemplates} 
            primary 
            medium 
            disabled={selectedTemplates.length === 0} 
          />
        </div>
      </div>

      {showResponseLoader && <ResponseLoader />}
    </Modal>
  )
}

export default ListOfTemplatesModal
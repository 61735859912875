import { useContext, useCallback } from 'react'

import { UserContext } from '../context'
import { update_user } from '../services/firestore'

let customizationsTimeout = null

const useWidgetActions = () => {
  const { widgetsInfo, setWidgetsInfo, setWidgets, user, widgets, setUser, widgetsInfoTimeout, widgetsTimeout, customizations, setCustomizations } = useContext(UserContext)

  // Change size
  const changeWidgetSize = useCallback(async ({ size, widget }) => {
    if(widgetsInfoTimeout.current) {
      clearTimeout(widgetsInfoTimeout.current)
    }
    let newInfo = {
      ...widgetsInfo,
      [widget]: {
        ...widgetsInfo[widget],
        size
      }
    }
    setWidgetsInfo(newInfo)
    widgetsInfoTimeout.current = setTimeout(() => {
      if(!user.customizations || !user.customizations.widgets_info || !user.customizations.widgets_info[widget] || user.customizations.widgets_info[widget].size !== size) {
        // console.log('save widget size***')
        let data = {
          customizations: {
            ...customizations,
            widgets: widgets,
            widgets_info: newInfo
          }
        }
        // console.log(data, size)
        update_user(data, user.id)
        setUser({...user, ...data})
      }
    }, 3000)
    document.body.click()
  }, [customizations, setUser, setWidgetsInfo, user, widgets, widgetsInfo, widgetsInfoTimeout])

  // Remove widget
  const removeWidget = useCallback(({ widget }) => {
    if(widgetsTimeout.current) {
      clearTimeout(widgetsTimeout.current)
    }
    let newWidgets = {
      ...widgets,
      main: widgets.main.filter(item => item !== widget),
      side_panel: [...widgets.side_panel, widget]
    }
    setWidgets(newWidgets)
    widgetsTimeout.current = setTimeout(() => {
      // console.log('save widgets***')
      let data = {
        customizations: {
          ...customizations,
          widgets: newWidgets,
          widgets_info: widgetsInfo
        }
      }
      // console.log(data)
      update_user(data, user.id)
      setUser({...user, ...data})
    }, 3000)
  }, [customizations, setUser, setWidgets, user, widgets, widgetsInfo, widgetsTimeout])

  // Check if order changed
  const didWidgetsOrderChanged = (newWidgets) => {
    if(!user.customizations?.widgets) return true

    let changed = false
    let oldWidgets = user.customizations.widgets 

    if(!newWidgets.main || !oldWidgets.main) return true

    if(newWidgets.main.length !== oldWidgets.main.length) {
      changed = true
    }else {
      for(let i = 0; i < newWidgets.main.length; i++) {
        if(newWidgets.main[i] !== oldWidgets.main[i]) {
          changed = true 
          break
        }
      }
    }

    return changed
  }

  // Change background
  const changeBackground = async ({ c }) => {
    if(customizationsTimeout) {
      clearTimeout(customizationsTimeout)
    }
    setCustomizations({
      ...customizations,
      background: c
    })
    customizationsTimeout = setTimeout(() => {
      if(!user.customizations || user.customizations.background !== c) {
        // console.log('save customizations***')
        let data = {
          customizations: {
            ...customizations,
            background: c,
            widgets: widgets,
            widgets_info: widgetsInfo
          }
        }
        // console.log(data)
        update_user(data, user.id)
        setUser({...user, ...data})
      }
    }, 3000)
  }

  // Bg color
  const bgColors = [
    '#fff', '#F9FBFD', '#F8F9FA', '#EEEEEE', '#D0F0FD', '#CFDEFF',
    '#EDE2FE', '#FFDCE5', '#FEE2D5', '#FFEAB6', '#D1F7C4', '#C1F5E9',
    '#5B073A', '#E1A039', '#AECF55', '#285B52', '#36B8B1', '#A8DCD9',
    '#19BFFF', '#938CE1', '#AD7CC4', '#F69FA3', '#C9C9C9', '#BFB9B9',
  ]

  return {
    changeWidgetSize,
    removeWidget,
    didWidgetsOrderChanged,
    changeBackground,
    bgColors,
  }
}

export default useWidgetActions
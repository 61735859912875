import React, { useState, useContext, useRef, useEffect } from 'react'
import { Close, Minimize, CalendarTodayOutlined } from '@material-ui/icons'
import { ClickAwayListener } from '@material-ui/core'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { MdOpenInFull } from 'react-icons/md'

import ActionsDropdown from '../../UI/ActionsDropdown'
import Button from '../../UI/Button'
import CustomTooltip from '../../UI/CustomTooltip'
import IconButton from '../../UI/IconButton'
import ResponseLoader from '../../UI/ResponseLoader'

import { GlobalContext, TeamContext, DocumentsContext, NotificationContext, TaskContext } from '../../../context'
import { isOverflown } from '../../../utils'

const NewTaskPopup = ({ onClose, assignee }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { documentsArr, updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { createTask, tasks, setShowTaskPopup, setSelectedAssignee } = useContext(TaskContext)
  const [taskName, setTaskName] = useState('')
  const [assigneeMode, setAssigneeMode] = useState('button')
  const [documentMode, setDocumentMode] = useState('button')
  const [assigneeSearch, setAssigneeSearch] = useState('')
  const [documentSearch, setDocumentSearch] = useState('')
  const [teamMembers, setTeamMembers] = useState([])
  const [filteredTeamMembers, setFilteredTeamMembers] = useState([])
  const [selectedTeamMember, setSelectedTeamMember] = useState(null)
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const [popupMode, setPopupMode] = useState('full')
  const assigneeSearchRef = useRef()
  const documentSearchRef = useRef()

  // Set active team members
  useEffect(() => {
    if(selectedTeam && selectedTeam.users) {
      setTeamMembers(selectedTeam.users.filter(u => u.status === 'active'))
      setFilteredTeamMembers(selectedTeam.users.filter(u => u.status === 'active'))
    }
  }, [selectedTeam])

  // Set filtered documents
  useEffect(() => {
    setFilteredDocuments(documentsArr)
  }, [documentsArr])

  // Set selected team member
  useEffect(() => {
    if(assignee) {
      setSelectedTeamMember(assignee)
    }
  }, [assignee])

  // On assignee btn click
  const handleAssigneeBtnClick = () => {
    setAssigneeMode('input')
    setTimeout(() => {
      if(assigneeSearchRef.current) {
        assigneeSearchRef.current.focus()
      }
    }, 50)
  }

  // On assignee btn click
  const handleDocumentBtnClick = () => {
    setDocumentMode('input')
    setTimeout(() => {
      if(documentSearchRef.current) {
        documentSearchRef.current.focus()
      }
    }, 50)
  }

  // On assignee search
  const handleAssigneeSearch = (e) => {
    const value = e.target.value 
    setAssigneeSearch(value)
    if(!value) {
      setFilteredTeamMembers(teamMembers)
    }else {
      const newMembers = teamMembers.filter((m) => {
        const firstName = m.first_name || ''
        const lastName = m.last_name || ''
        const name = firstName + ' ' + lastName
        const email = m.email
        if(name.toLowerCase().includes(value.trim().toLowerCase()) || email.toLowerCase().includes(value.trim().toLowerCase())) {
          return m
        }else {
          return null
        }
      })
      setFilteredTeamMembers(newMembers)
    }
  }

  // On document search
  const handleDocumentSearch = (e) => {
    const value = e.target.value 
    setDocumentSearch(value)
    if(!value) {
      setFilteredDocuments(documentsArr)
    }else {
      const newDocs = documentsArr.filter((doc) => {
        if(doc.name?.toLowerCase().includes(value.trim().toLowerCase())) {
          return doc
        }else {
          return null
        }
      })
      setFilteredDocuments(newDocs)
    }
  }

  // On team member select
  const handleSelectTeamMember = (tm) => {
    setSelectedTeamMember(tm)
    setAssigneeMode('button')
  }

  // On document select
  const handleSelectDocument = (doc) => {
    setSelectedDocument(doc)
    setDocumentMode('button')
  }

  // On remove assignee
  const handleRemoveAssignee = () => {
    setSelectedTeamMember(null)
  }

  // On remove document
  const handleRemoveDocument = () => {
    setSelectedDocument(null)
  }

  // On assignee click away 
  const handleAssigneeClickAway = () => {
    setAssigneeMode('button')
    setAssigneeSearch('')
  }

  // On document click away 
  const handleDocumentClickAway = () => {
    setDocumentMode('button')
    setDocumentSearch('')
  }

  // Render chosen member
  const renderChosenMember = () => {
    if(!selectedTeamMember) return
    let name = ''
    if(selectedTeamMember.first_name) {
      name = selectedTeamMember.first_name
    }
    if(selectedTeamMember.last_name) {
      if(name) name += ` ${selectedTeamMember.last_name}`
      else name = selectedTeamMember.last_name
    }
    if(!name && selectedTeamMember.email) {
      name = selectedTeamMember.email
    }
    return (
      <div className="chosen" onClick={handleRemoveAssignee}>
        <p>{name}</p>
        <span><Close /></span>
      </div>
    )
  }

  // Render chosen document
  const renderChosenDocument = () => {
    if(!selectedDocument) return 
    return (
      <div className="chosen" onClick={handleRemoveDocument}>
        <p>{selectedDocument.name}</p>
        <span><Close /></span>
      </div>
    )
  }

  // On date fields change
  const handleDateChange = async (date) => {
    setSelectedDate(date)
    document.body.click()
  }

  // On remove date
  const handleRemoveDate = async () => {
    setSelectedDate('')
    document.body.click()
  }

  // On task create
  const handleCreateTask = async () => {
    if(taskName.trim() === '') {
      return setNotification({ msg: t('notification.task_name_required'), type: 'danger' })
    }

    // Check if task with this name already exist for this document
    let selectedDocTasks
    if(selectedDocument && selectedDocument.id) selectedDocTasks = selectedDocument.tasks || []
    const findTask = selectedDocument && selectedDocument.id 
      ? selectedDocTasks.find(t => t.name.toLowerCase() === taskName.trim().toLowerCase())
      : tasks.find(t => t.name.toLowerCase() === taskName.trim().toLowerCase() && t.relatedDocument === null)
    if(findTask) {
      return selectedDocument && selectedDocument.id 
        ? setNotification({ msg: t('notification.task_exists_for_selected_document'), type: 'warning' })
        : setNotification({ msg: t('notification.task_already_exists'), type: 'warning' })
    }

    setShowResponseLoader(true)

    const data = {
      relatedDocument: selectedDocument ? selectedDocument.id : null,
      name: taskName.trim(),
      completed: false,
      meta: {
        created: Date.now(),
        updated: Date.now()
      },
      team: selectedTeam?.id
    }

    if(selectedTeamMember) {
      data.assigned_to = selectedTeamMember.id
    }

    if(selectedDate) {
      data.due_date = selectedDate
    }
    // console.log(data)
    createTask(data, selectedTeam?.id, async (taskId) => {
      if(selectedDocument && selectedDocument.id) {
        data.id = taskId
        await updateDocument({ tasks: selectedDocument.tasks ? [...selectedDocument.tasks, data] : [data], last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDocument)
      }
      setNotification({ msg: t('notification.task_created'), type: 'success' })
      setShowResponseLoader(false)
      setShowTaskPopup(false)
      setSelectedAssignee(null)
      setSelectedDocument(null)
      setSelectedTeamMember(null)
    }, (err) => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowResponseLoader(false)
    })
  }

  if(popupMode === 'minimized') {
    return (
      <div className="new-task-popup new-task-popup--minimized" onClick={() => setPopupMode('full')}>
        <div className="new-task-popup__head">
          <p>{taskName.trim() || t('general.new_task')}</p>
          <div className="new-task-popup__head_actions">
            <IconButton icon={<MdOpenInFull />} onButtonClick={() => setPopupMode('full')} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="new-task-popup">
      <div className="new-task-popup__head">
        <p>{t('general.new_task')}</p>
        <div className="new-task-popup__head_actions">
          <IconButton icon={<Minimize />} onButtonClick={() => setPopupMode('minimized')} />
          <IconButton icon={<Close />} onButtonClick={onClose} />
        </div>
      </div>
      <div className="new-task-popup__body">
        <div className="task-name-wrapper">
          <input type="text" placeholder={t('general.task_name')} value={taskName} onChange={(e) => setTaskName(e.target.value)} />
        </div>
        <div className="assigned-to-wrapper">
          <p>{t('general.for')}</p>

          <ClickAwayListener onClickAway={handleAssigneeClickAway}>
            <div className="choose">
              {assigneeMode === 'button' && (
                <>
                  {selectedTeamMember 
                    ? renderChosenMember()
                    : (
                      <button onClick={handleAssigneeBtnClick}>{t('general.assignee')}</button>
                    )}
                </>
              )}
              
              <div className={assigneeMode === 'input' ? 'choose__input' : 'choose__input hide'}>
                <input type="text" value={assigneeSearch} onChange={handleAssigneeSearch} ref={assigneeSearchRef} />
                <div className="choose__input_suggestions u-custom-scrollbar">
                  {filteredTeamMembers.length > 0 ? <ul>
                    {filteredTeamMembers.map((tm, idx) => {
                      let name = ''
                      if(tm.first_name) {
                        name = tm.first_name
                      }
                      if(tm.last_name) {
                        if(name) name += ` ${tm.last_name}`
                        else name = tm.last_name
                      }
                      if(tm.email) {
                        if(name) name += ` (${tm.email})`
                        else name = tm.email
                      }
                      return (
                        <li key={idx} onClick={() => handleSelectTeamMember(tm)}>{name}</li>
                      )
                    })}
                  </ul> : <p>{t('general.no_results')}</p>}
                </div>
              </div>
              
            </div>
          </ClickAwayListener>

          <p>{t('general.in')}</p>

          <ClickAwayListener onClickAway={handleDocumentClickAway}>
            <div className="choose">
              {documentMode === 'button' && (
                <>
                  {selectedDocument 
                    ? renderChosenDocument()
                    : (
                      <button onClick={handleDocumentBtnClick}>{t('dashboard.document')}</button>
                    )}
                </>
              )}
              
              <div className={documentMode === 'input' ? 'choose__input' : 'choose__input hide'}>
                <input type="text" value={documentSearch} onChange={handleDocumentSearch} ref={documentSearchRef} />
                <div className="choose__input_suggestions u-custom-scrollbar">
                  <ul style={{ display: filteredDocuments.length > 0 ? 'block' : 'none' }}>
                    {filteredDocuments.map((doc, idx) => {
                      return (
                        <Name key={idx} doc={doc} onClick={handleSelectDocument} />
                      )
                    })}
                  </ul>
                  <p style={{ display: filteredDocuments.length > 0 ? 'none' : 'block' }}>{t('general.no_results')}</p>
                </div>
              </div>
              
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="new-task-popup__foot">
        <div className="new-task-popup__foot_left">
          <ActionsDropdown
            headTitle={t('general.due_date')}
            trigger={
              <div>
                <CustomTooltip content={t('general.add_due_date')}>
                  <button className={selectedDate ? "text-btn" : "icon-btn"}>
                    {!selectedDate && <CalendarTodayOutlined />}
                    {selectedDate && (
                      <span>{moment(selectedDate.valueOf()).format(`DD MMM`)}{moment().year() !== moment(selectedDate.valueOf()).year() && `, ${moment(selectedDate.valueOf()).year()}`}</span>
                    )}
                  </button>
                </CustomTooltip>
              </div>
            }
          >
            <DatePicker 
              selected={selectedDate} 
              onChange={handleDateChange} 
              // onChangeRaw={(e) => e.preventDefault()}
              minDate={Date.now()}
              locale="fr"
              calendarClassName="calendar"
              inline
            />
            <Button text={t('general.remove_date')} fullWidth disabled={selectedDate === ''} onButtonClick={handleRemoveDate} primaryLight />
          </ActionsDropdown> 
        </div>
        <div className="new-task-popup__foot_right">
          <Button text={t('dashboard.create_task')} primary disabled={taskName.trim() === ''} onButtonClick={handleCreateTask} />
        </div>
      </div>

      {showResponseLoader && <ResponseLoader />}
    </div>
  )
}

const Name = ({ doc, onClick }) => {
  const elRef = useRef()

  return (
    <CustomTooltip content={doc.name} position="top-right" hideTooltip={!isOverflown(elRef.current)}>
      <li onClick={() => onClick(doc)} ref={elRef}>{doc.name}</li>
    </CustomTooltip>
  )
}

export default NewTaskPopup
import React, { useState, useContext, useEffect } from 'react' 

import Button from '../../UI/Button'
import CustomSelect from '../../UI/CustomSelect'
import Modal from '../../UI/Modal'
import Loader from '../../UI/Loader'
import { Select, Option } from '../../new_ui'

import { SharedTemplatePagesContext, TeamContext, GlobalContext, DocumentsContext } from '../../../context'

const PageOrTemplateShareModal = ({ onClose, onCreateNewPage, onEditPage, onSingleTemplateShare, mode = 'all' }) => {
  const { t } = useContext(GlobalContext)
  const { sharedTemplatePages, sharedTemplatePagesFetched, fetchSharedTemplatePages } = useContext(SharedTemplatePagesContext)
  const { selectedTeam } = useContext(TeamContext)
  const { templatesArr, templatesLoaded, getTemplates } = useContext(DocumentsContext)
  const [pageOptions, setPageOptions] = useState([
    { value: '', label: t('share_template.page_or_template'), active: true },
  ])
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templatesFetched, setTemplatesFetched] = useState(false)

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(!templatesLoaded && mode === 'template' && selectedTeam && !templatesFetched) {
      getTemplates(selectedTeam.id)
      setTemplatesFetched(true)
    }
  }, [templatesLoaded, selectedTeam, getTemplates, mode, templatesFetched])

  // Fetch template pages 
  useEffect(() => {
    if(!sharedTemplatePagesFetched && selectedTeam) {
      fetchSharedTemplatePages(selectedTeam.id)
    }
  }, [sharedTemplatePagesFetched, selectedTeam, fetchSharedTemplatePages])

  // Add page options
  useEffect(() => {
    if(sharedTemplatePages.length > 0) {
      let options = [...pageOptions]
      sharedTemplatePages.forEach((tmpl) => {
        options.push({ value: tmpl.id, label: tmpl.title, active: false })
      })
      setPageOptions(options)
    }
    // eslint-disable-next-line
  }, [sharedTemplatePages])

  // On page change
  const handlePageChange = (opt) => {
    if(opt.value === '') return
    onEditPage(opt)
  }

  // On template select
  const handleSelectTemplate = (template) => {
    setSelectedOption(template)
    setSelectedTemplate(templatesArr.find(tmplt => tmplt.id === template))
  }

  // On share template
  const handleShareSingleTemplate = () => {
    onSingleTemplateShare(selectedTemplate)
  }

  const renderContent = () => {
    if(mode === 'all') {
      return (
        <div className="page-or-template-share-modal__body">
          <div className="page-share">
            <p className="page-share__info-msg">{t('share_template.share_in_webpage')}</p>
            <CustomSelect options={pageOptions} onChange={handlePageChange} active={pageOptions.find(o => o.active)} formEl />
            <Button text={t('share_template.create_new_page')} onButtonClick={onCreateNewPage} primary fullWidth medium />
          </div>
          <div className="or">{t('general.or')}</div>
          <div className="template-share">
            <Button text={t('share_template.share_alone')} onButtonClick={onSingleTemplateShare} primary large fullWidth />
          </div>
        </div>
      )
    }else if(mode === 'template') {
      return (
        <div className="page-or-template-share-modal__body">
          <div className="page-share-body">
            {!templatesLoaded && <div className="loading-wrapper"><Loader primary normal /></div>}
            {templatesLoaded && <div className="template-select">
              {!selectedOption ? (
                <Select onChange={handleSelectTemplate} selected={selectedOption} label={t('share_template.select_a_template')}>
                  <Option value={''} hide="true">{t('share_template.select_a_template_2')}</Option>
                  {templatesArr.map((template) => {
                    return (
                      <Option value={template.id} key={template.id}>{template.name}</Option>
                    )
                  })}
                </Select>
              ) : (
                <Select onChange={handleSelectTemplate} selected={selectedOption} label={t('share_template.select_a_template')}>
                  {templatesArr.map((template) => {
                    return (
                      <Option value={template.id} key={template.id}>{template.name}</Option>
                    )
                  })}
                </Select>
              )}
            </div>}
            {selectedTemplate && (
              <div className="template-share">
                <Button text={t('share_template.share_alone')} onButtonClick={handleShareSingleTemplate} primary large fullWidth />
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="page-or-template-share-modal">
        <div className="page-or-template-share-modal__head">
          {mode === 'all' && <h2>{t('share_template.page_or_template')}</h2>}
          {mode === 'page' && <h2>{t('share_template.add_new_page')}</h2>}
          {mode === 'template' && <h2>{t('dashboard.add_template')}</h2>}
        </div>
        {renderContent()}
      </div>
    </Modal>
  )
}

export default PageOrTemplateShareModal
import { PublicClientApplication } from '@azure/msal-browser';
import { post_request, baseUrl } from './firestore';

const oneDriveConfig = {
  appId: 'a63aa8fd-a078-43aa-8ba1-e53e67ae2e06',
  redirectUri: `${window.location.origin}/dashboard`,
  scopes: [
    'files.readwrite',
  ]
}

const msalClient = new PublicClientApplication({
  auth: {
    clientId: oneDriveConfig.appId,
    redirectUri: oneDriveConfig.redirectUri
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
});

const onedrive_signin = async () => {
  try {
    const authResult = await msalClient.loginPopup({ scopes: oneDriveConfig.scopes });
    localStorage.setItem('msalAccount', authResult.account.username);
    return authResult.accessToken;
  } catch (err) {
    console.log(err);
  }
}

const get_onedrive_token = async () => {
  let account = localStorage.getItem('msalAccount');
  try {
    const silentRequest = {
      scopes: oneDriveConfig.scopes,
      account: msalClient.getAccountByUsername(account)
    };

    const silentResult = await msalClient.acquireTokenSilent(silentRequest);
    return silentResult.accessToken;
  } catch (err) {
    // console.log(err);
    if (err.toString().includes('no_account_error')) {
      return onedrive_signin();
    } else {
      throw err;
    }
  }
}

const onedrive_get_items = async (data) => {
  return await post_request(`${baseUrl}/onedrive_get_items`, data);
}

const onedrive_get_folder_items = async (data) => {
  return await post_request(`${baseUrl}/onedrive_get_folder_items`, data);
}

const onedrive_get_folder = async (data) => {
  return await post_request(`${baseUrl}/onedrive_get_folder`, data);
}

const onedrive_upload = async (data) => {
  return await post_request(`${baseUrl}/onedrive_upload`, data);
} 

export {
  oneDriveConfig,
  onedrive_signin,
  get_onedrive_token,
  onedrive_get_items,
  onedrive_get_folder_items,
  onedrive_get_folder,
  onedrive_upload,
}
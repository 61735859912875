import { useContext, useCallback } from 'react'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { DocumentsContext, LoaderContext, GlobalContext, FolderContext, NotificationContext } from '../context'
import { get_file_from_external_url, delete_folder, delete_template, update_folder, update_template } from '../services/firestore'
import { folderHasSubfolders, folderHasTemplates, getAllParentFolders } from '../utils'

const useTemplatesActions = () => {
  const { templates, deleteMultipleTemplates, updateMultipleTemplates } = useContext(DocumentsContext)
  const { folders, deleteMultipleFolders, updateMultipleFolders, setCurrentlyActiveFolder, setFolderBreadcrumbs, setSelectedFilter } = useContext(FolderContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useContext(GlobalContext)

  // Download templates
  const downloadTemplates = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Preparing templates for download')

    try {
      if(selectedDocuments.length === 1) {
        const tmplt = selectedDocuments[0]
        if(!tmplt.folderRow) {
          if(isIOS) {
            const a = document.createElement('a')
            a.onclick = saveAs(tmplt.templateUrl, `${tmplt.name || 'template'}.docx`)
          }else {
            const a = document.createElement("a")
            a.href = `${tmplt.templateUrl}`
            a.download = `${tmplt.name || 'document'}.docx`
            a.click()
          }
        }else {
          const folder = selectedDocuments[0]
          const zip = new JSZip()
          let tmpltsInFolder = {}
          for(let id in templates) {
            if(templates[id].folderId.includes(folder.id)) {
              tmpltsInFolder[id] = templates[id]
            }
          }
          if(Object.keys(tmpltsInFolder).length > 0) {
            for(let id in tmpltsInFolder) {
              const url = tmpltsInFolder[id].templateUrl
              const data = await get_file_from_external_url({ url })
              zip.file(`${folder.name}/${tmpltsInFolder[id].name}_${id}.docx`, data.data, { base64: true })
            }
          }
          let foldersInFolder = {}
          folders.forEach(f => {
            if(f.parentFolder === tmplt.id) {
              foldersInFolder[f.id] = f
            }
          })
          if(Object.keys(foldersInFolder).length > 0) {
            await checkForFoldersInFolderHelper(foldersInFolder, zip)
          }
  
          if(Object.keys(tmpltsInFolder).length === 0 && Object.keys(foldersInFolder).length === 0) {
            return setNotification({ msg: t('folder.empty'), type: 'info' })
          }
  
          zip.generateAsync({type:"blob"}).then(function(content) {
            saveAs(content, `${folder.name}.zip`)
          })
        }
      }else {
        const zip = new JSZip()
        for(let i = 0; i < selectedDocuments.length; i++) {
          const tmplt = selectedDocuments[i]
          if(!tmplt.folderRow)  {
            const url = tmplt.templateUrl
            const data = await get_file_from_external_url({ url })
            zip.file(`${tmplt.name}_${tmplt.id}.docx`, data.data, { base64: true })
          }else {
            let tmpltsInFolder = {}
            for(let id in templates) {
              if(templates[id].folderId.includes(tmplt.id)) {
                tmpltsInFolder[id] = templates[id]
              }
            }
            if(Object.keys(tmpltsInFolder).length > 0) {
              for(let id in tmpltsInFolder) {
                const url = tmpltsInFolder[id].templateUrl
                const data = await get_file_from_external_url({ url })
                zip.file(`${tmplt.name}/${tmpltsInFolder[id].name}_${id}.docx`, data.data, { base64: true })
              }
            }else {
              zip.folder(`${tmplt.name}`)
            }
            let foldersInFolder = {}
            folders.forEach(f => {
              if(f.parentFolder === tmplt.id) {
                foldersInFolder[f.id] = f
              }
            })
            if(Object.keys(foldersInFolder).length > 0) {
              await checkForFoldersInFolderHelper(foldersInFolder, zip)
            }
          }
        }
  
        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, "templates.zip")
        })
      }
  
      setSelectedDocuments([])
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Delete templates
  const deleteTemplates = async ({ selectedDocuments, setSelectedDocuments, setShowDeleteSelectedTemplatesAlert }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Deleting templates')

    const promises = []
    let foldersToDelete = []
    let templatesToDelete = []
    for(let i = 0; i < selectedDocuments.length; i++) {
      if(selectedDocuments[i].folderRow) {
        if(folderHasSubfolders(folders, selectedDocuments[i])) continue
        if(folderHasTemplates(templates, selectedDocuments[i])) continue
        promises.push(delete_folder(selectedDocuments[i].id))
        foldersToDelete.push(selectedDocuments[i].id)
      }else {
        promises.push(delete_template(selectedDocuments[i].id))
        templatesToDelete.push(selectedDocuments[i].id)
      }
    }

    let success = true
    
    await Promise.all(promises)
      .then(async (data) => {
        let error = false 
        let errorMsg = ''
        for(let i = 0; i < data.length; i++) {
          if(data[i].error) {
            error = true
            if(data[i].error.message) {
              errorMsg = data[i].error.message
            }
            break 
          }
        }
        if(error) {
          success = false
          return setNotification({ msg: errorMsg || t('notification.something_went_wrong'), type: 'danger' })
        }
        if(templatesToDelete.length > 0) {
          deleteMultipleTemplates(templatesToDelete)
        }
        if(foldersToDelete.length > 0) {
          deleteMultipleFolders(foldersToDelete)
        }
        setSelectedDocuments([])
        setShowDeleteSelectedTemplatesAlert(false)
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      })

    return success
  }

  // Move templates to folder
  const moveTemplatesToFolder = async ({ moveTo, current, selectedDocuments, setSelectedDocuments, setShowMoveMultipleDocsToFolderModal }) => {
    const promises = []
    const templatesArr = []
    let foldersArr = []

    for(let i = 0; i < selectedDocuments.length; i++) {
      const doc = selectedDocuments[i]
      if(doc.folderRow) {
        if(moveTo) {
          const parentFolders = getAllParentFolders(folders, moveTo)

          if(moveTo.id === doc.parentFolder) continue
          if(moveTo.id === doc.id) continue
          if(moveTo.parentFolder === doc.id) continue
          if(parentFolders.find(f => f.id === doc.id)) continue

          promises.push(update_folder({ parentFolder: moveTo.id }, doc.id))
          foldersArr.push({ data: { parentFolder: moveTo.id }, id: doc.id })
        }else {
          if(doc.parentFolder === null || doc.parentFolder === undefined) continue

          promises.push(update_folder({ parentFolder: null }, doc.id))
          foldersArr.push({ data: { parentFolder: null }, id: doc.id })
        }

      }else {
        if(moveTo) {
          if(!doc.folderId.includes(moveTo.id)) {
            let fArr = [...doc.folderId]
            fArr = fArr.filter(fId => current ? fId !== current.id : fId)
            fArr.push(moveTo.id)
            promises.push(update_template({ folderId: fArr }, doc.id))
            templatesArr.push({ data: { folderId: fArr }, id: doc.id })
          }
        }else {
          let fArr = [...doc.folderId]
          fArr = fArr.filter(fId => current ? fId !== current.id : fId)
          promises.push(update_template({ folderId: fArr }, doc.id))
          templatesArr.push({ data: { folderId: fArr }, id: doc.id })
        }
      }
    }

    if(promises.length === 0) return

    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Moving templates')
    let success = true

    await Promise.all(promises)
      .then(async (data) => {
        let error = false 
        let errorMsg = ''
        for(let i = 0; i < data.length; i++) {
          if(data[i].error) {
            error = true
            if(data[i].error.message) {
              errorMsg = data[i].error.message
            }
            break 
          }
        }
        if(error) {
          success = false
          return setNotification({ msg: errorMsg || t('notification.something_went_wrong'), type: 'danger' })
        }
        if(foldersArr.length > 0) {
          updateMultipleFolders(foldersArr)
        }
        if(templatesArr.length > 0) {
          updateMultipleTemplates(templatesArr)
        }
        setSelectedDocuments([])
        setShowMoveMultipleDocsToFolderModal(false)
        setNotification({ msg: t('folder.elements_moved_to_folder', { number: promises.length, folder: moveTo ? moveTo.name : t('general.root') }), type: 'default' })
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      })

    return success
  }

  // Filter templates by folders
  const filterTemplatesByFolders = useCallback(async ({ folder, filterNonDeletedTemplates, setFilteredTemplates, setSelectedTitle, setSelectedDocuments }) => {
    setCurrentlyActiveFolder(folder)
    const parentFolders = getAllParentFolders(folders, folder)
    const reverseParentFolders = parentFolders.reverse()
    if(parentFolders.length > 0) {
      setFolderBreadcrumbs([...reverseParentFolders, folder])
    }else {
      if(folder) {
        setFolderBreadcrumbs([folder])
      }else {
        setFolderBreadcrumbs([])
      }
    }
    
    const filtered = {};
    const nonDeletedTemplates = filterNonDeletedTemplates({...templates})

    if(folder) {
      for(let key in nonDeletedTemplates) {
        if(nonDeletedTemplates[key].folderId.includes(folder.id)) {
          filtered[key] = nonDeletedTemplates[key];
        }
      }
      setFilteredTemplates(filtered)
      setSelectedFilter(folder.id)
      setSelectedTitle(folder.name)
    }else {
      setFilteredTemplates(nonDeletedTemplates)
      setSelectedFilter('all')
      setSelectedTitle(t('general.all'))
    }
    setSelectedDocuments([])
  }, [folders, setCurrentlyActiveFolder, setFolderBreadcrumbs, setSelectedFilter, t, templates])

  /* Helpers */
  // Check if there are folders in folder - helper
  const checkForFoldersInFolderHelper = async (fldrs, zip) => {
    for(let id in fldrs) {
      const folder = folders.find(f => f.id === id)
      if(folder) {
        let tmpltsInFolder = {}
        for(let tmplId in templates) {
          if(templates[tmplId].folderId.includes(id)) {
            tmpltsInFolder[tmplId] = templates[tmplId]
          }
        }
        if(Object.keys(tmpltsInFolder).length > 0) {
          for(let tmplId in tmpltsInFolder) {
            const url = tmpltsInFolder[tmplId].templateUrl
            const name = tmpltsInFolder[tmplId].name
            const data = await get_file_from_external_url({ url })
            const folderName = getFolderPath(folder, folder.name)
            zip.file(`${folderName}/${name}_${id}.docx`, data.data, { base64: true })
          }
        }else {
          const folderName = getFolderPath(folder, folder.name)
          // console.log(folderName)
          zip.folder(`${folderName}`)
        }
        let foldersInFolder = {}
        folders.forEach(f => {
          if(f.parentFolder === folder.id) {
            foldersInFolder[f.id] = f
          }
        })
        if(Object.keys(foldersInFolder).length > 0) {
          await checkForFoldersInFolderHelper(foldersInFolder, zip)
        }
      }
    }
  }

  // Get folder path
  const getFolderPath = (folder, path) => {
    if(!folder.parentFolder) {
      return folder.name
    }else {
      const fldr = folders.find(f => f.id === folder.parentFolder)
      if(fldr) {
        const folderPath = `${fldr.name}/${path}`
        if(!fldr.parentFolder) {
          return folderPath
        }else {
          return getFolderPath(fldr, folderPath)
        }
      }
    }
  }
  /* END Helpers */

  return {
    downloadTemplates,
    deleteTemplates,
    moveTemplatesToFolder,
    filterTemplatesByFolders,
  }
}

export default useTemplatesActions
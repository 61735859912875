
import NotificationsIcon from "@material-ui/icons/Notifications"
import { useContext, useEffect, useState } from "react"
import { DocumentsContext, UserContext } from "../../context"
import IconButton from "./IconButton"

const NotificationsButton = ({ onClick, className }) => {

  const { user } = useContext(UserContext)
  const { actions } = useContext(DocumentsContext)
  const [showBadge, setShowBadge] = useState(false)

  useEffect(() => {
    setShowBadge(shouldShowBadge())
  }, [user, actions])

  const shouldShowBadge = () => {
    if(!user) {
      return false
    }
    if(isNaN(user.last_read_notification_timestamp)) {
      return true
    }
    for(let key in actions) {
      if(actions[key].timestamp > user.last_read_notification_timestamp) {
        return true
      } 
    }
    return false
  }

  return (
    <div className={`notification-button-wrapper`}>
      <IconButton className={className || ''} icon={<NotificationsIcon />} onButtonClick={onClick} light />
      { showBadge && <div className="notification-button-badge"/> }
    </div>
  )
}

export default NotificationsButton
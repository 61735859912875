import React, { useState, useContext, useRef, useCallback, useEffect, useMemo } from 'react' 
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { DeleteOutlined, Check } from '@material-ui/icons'
import { setHours, setMinutes, isSameDay } from 'date-fns'

import Alert from '../../UI/Alert'
import Button from '../../UI/Button'
import IconButton from '../../UI/IconButton'
import Input from '../../UI/Input'
import Loader from '../../UI/Loader'
import TagsInput from '../../UI/TagsInput'
import Textarea from '../../UI/Textarea'

import { UserContext, LoaderContext, NotificationContext, TeamContext, AlertContext, DocumentsContext, GlobalContext } from '../../../context'

const defaultExcludedTimes = [
  setHours(setMinutes(new Date(), 0), 0),
  setHours(setMinutes(new Date(), 0), 1),
  setHours(setMinutes(new Date(), 0), 2),
  setHours(setMinutes(new Date(), 0), 3),
  setHours(setMinutes(new Date(), 0), 4),
  setHours(setMinutes(new Date(), 0), 5),
  setHours(setMinutes(new Date(), 0), 6),
  setHours(setMinutes(new Date(), 0), 7),
  setHours(setMinutes(new Date(), 0), 20),
  setHours(setMinutes(new Date(), 0), 21),
  setHours(setMinutes(new Date(), 0), 22),
  setHours(setMinutes(new Date(), 0), 23),
]

const notExcludedTimes = [
  setHours(setMinutes(new Date(), 0), 8),
  setHours(setMinutes(new Date(), 0), 9),
  setHours(setMinutes(new Date(), 0), 10),
  setHours(setMinutes(new Date(), 0), 11),
  setHours(setMinutes(new Date(), 0), 12),
  setHours(setMinutes(new Date(), 0), 13),
  setHours(setMinutes(new Date(), 0), 14),
  setHours(setMinutes(new Date(), 0), 15),
  setHours(setMinutes(new Date(), 0), 16),
  setHours(setMinutes(new Date(), 0), 17),
  setHours(setMinutes(new Date(), 0), 18),
  setHours(setMinutes(new Date(), 0), 19),
]

const getExcludeTimesForDate = (date) => {
  let selectedDate = date
  if(!date) selectedDate = new Date()
  return notExcludedTimes.filter((time) => {
    if(isSameDay(selectedDate, time)) {
      const timestamp = new Date(time).getTime()
      return Date.now() > timestamp
    }
    return false
  })
}

const SingleAlert = ({ onSetEditStartTime, onStoreEditTime, doc, currentAlert = null, onSetUpdating, onSetView, onSetSelectedAlert = () => {}, inList = false }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { setLoadingOverlay } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { createAlert, updateAlert, deleteAlert } = useContext(AlertContext)
  const { updateDocument } = useContext(DocumentsContext)
  const [alertEmails, setAlertEmails] = useState([user.email])
  const [defaultEmails, setDefaultEmails] = useState([user.email])
  const [emailsError, setEmailsError] = useState(false)
  const [alertTime, setAlertTime] = useState('')
  const [defaultTime, setDefaultTime] = useState('')
  const [timeError, setTimeError] = useState(false)
  const [alertTitle, setAlertTitle] = useState('')
  const [defaultTitle, setDefaultTitle] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [defaultMessage, setDefaultMessage] = useState('')
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)
  const [excludedTimes, setExcludedTimes] = useState([...defaultExcludedTimes, ...getExcludeTimesForDate(alertTime)])
  const datepickerElWrapper = useRef()
  const savedTimeout = useRef()

  // On mount/cleanup
  useEffect(() => {
    const savedTimeoutCurrent = savedTimeout.current

    return () => {
      if(savedTimeoutCurrent) {
        clearTimeout(savedTimeoutCurrent);
      }
    }
  }, [])

  // Populate fields if currentAlert prop is not null
  useEffect(() => {
    if(currentAlert) {
      if(currentAlert.emails) {
        setAlertEmails(currentAlert.emails)
        setDefaultEmails(currentAlert.emails)
      }
      if(currentAlert.title) {
        setAlertTitle(currentAlert.title)
        setDefaultTitle(currentAlert.title)
      }
      if(currentAlert.message) {
        setAlertMessage(currentAlert.message)
        setDefaultMessage(currentAlert.message)
      }
      if(currentAlert.dateTimestamp) {
        setAlertTime(moment.unix(currentAlert.dateTimestamp / 1000).toDate())
        setDefaultTime(moment.unix(currentAlert.dateTimestamp / 1000).toDate())
      }
    }
  }, [currentAlert])

  // Tags input change handler
  const handleTagsChange = (name, tags) => {
    setAlertEmails(tags)
    if(emailsError) {
      setEmailsError(false)
    }
  }

  // On date change - for datepicker
  const handleDateChange = (date, setValue) => {
    if(new Date(date).getHours() === 0) {
      date.setHours(9)
    }
    if(moment(date).isValid()) {
      setValue(date)
      if(timeError) {
        setTimeError(false)
      }
    }
  }

  // Change time caption when datepicker calendar opens - it doesn't work with timeCaption property
  const onCalendarOpen = (date) => {
    if(datepickerElWrapper.current) {
      const timeLabel = datepickerElWrapper.current.querySelector('.react-datepicker-time__caption')
      if(timeLabel && timeLabel.innerText !== t('general.time')) {
        timeLabel.innerText = t('general.time')
      }
    }
    onSetEditStartTime(Date.now())
  }

  // On input change
  const handleInputChange = (name, value, setValue) => {
    setValue(value)
    if(name === 'title' && titleError) {
      setTitleError(false)
    }
  }

  // On save
  const handleSave = async () => {
    if(!alertTime || alertEmails.length === 0) {
      if(alertTitle.trim() === '') setTitleError(true)
      if(!alertTime) setTimeError(true)
      if(alertEmails.length === 0) setEmailsError(true)
      return setNotification({ msg: t('notification.fill_in_all_required_fields'), type: 'danger' })
    }
    const timestamp = moment(alertTime).clone().valueOf()
    if(timestamp <= Date.now()) {
      return setNotification({ msg: t('notification.time_in_past'), type: 'danger' })
    }
    setLoadingOverlay(true)
    setSaving(true)
    try {
      if(currentAlert) { // update
        const data = {
          title: alertTitle.trim() ? alertTitle.trim() : t('doc_alert.default_title', { title: doc.name }),
          message: alertMessage.trim(),
          emails: alertEmails,
          dateTimestamp: timestamp, 
          date: moment(alertTime).format('DD/MM/YYYY HH:mm')
        }
        // console.log(data, currentAlert.id)
        await updateAlert(data, currentAlert.id, async () => {
          let docAlerts = doc.doc_alerts || []
          if(!docAlerts.includes(currentAlert.id)) {
            docAlerts.push(currentAlert.id)
            await updateDocument({ doc_alerts: docAlerts, create_action: 'no' }, doc)
          }
        }, () => {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        })
      }else{ // create new
        const data = { 
          title: alertTitle.trim() ? alertTitle.trim() : t('doc_alert.default_title', { title: doc.name }),
          message: alertMessage.trim(), 
          emails: alertEmails,
          dateTimestamp: timestamp,
          date: moment(alertTime).format('DD/MM/YYYY HH:mm'),
          selectedVal: 'custom',
          sent: false,
          documentId: doc.id,
          team: selectedTeam?.id,
          owner: activeTeamMember.id,
          lang: selectedLang
        }
        await createAlert(data, async (id) => {
          // console.log(data, id)
          if(!id) return 
          let docAlerts = doc.doc_alerts || []
          docAlerts.push(id)
          await updateDocument({ doc_alerts: docAlerts, create_action: 'no' }, doc)
        }, (err) => {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        })
      }
      setSaved(true)
      savedTimeout.current = setTimeout(() => setSaved(false), 2000)
    } catch (err) {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setLoadingOverlay(false)
    setSaving(false)
    onSetView('alert')
    onSetSelectedAlert(null)
  }

  // On cancel
  const handleCancel = () => {
    restoreData()
    onSetSelectedAlert(null)
  }

  // On delete
  const handleDelete = () => {
    onSetUpdating(true)
    deleteAlert(currentAlert.id, async () => {
      let docAlerts = doc.doc_alerts || []
      let filteredAlerts = docAlerts.filter(alertId => alertId !== currentAlert.id)
      await updateDocument({ doc_alerts: filteredAlerts, create_action: 'no' }, doc)
      setNotification({ msg: t('notification.alert_deleted'), type: 'success' })
      onSetUpdating(false)
      setShowDeleteAlertModal(false)
      setAlertTitle('')
      setAlertMessage('')
      setAlertTime('')
      setAlertEmails([user.email])
      onSetView('alert')
      onSetSelectedAlert(null)
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      onSetUpdating(false)
      setShowDeleteAlertModal(false)
    })
  }

  // Restore data
  const restoreData = () => {
    setAlertEmails(defaultEmails)
    setAlertTime(defaultTime)
    setAlertTitle(defaultTitle)
    setAlertMessage(defaultMessage)
  }

  // Check if fields changed
  const didValuesChanged = useMemo(() => {
    let changed = false 
    if(defaultTime.toString() !== alertTime.toString()) {
      changed = true 
    }
    if(defaultTitle !== alertTitle.trim()) {
      changed = true
    }
    if(defaultMessage !== alertMessage.trim()) {
      changed = true
    }
    if(defaultEmails.length !== alertEmails.length) {
      changed = true 
    }else {
      for(let i = 0; i < defaultEmails.length; i++) {
        if(!alertEmails.includes(defaultEmails[i])) {
          changed = true 
          break
        }
      }
    }
    return changed
  }, [alertTitle, alertTime, alertMessage, alertEmails, defaultTime, defaultTitle, defaultMessage, defaultEmails])

  // On enter or escape save document or reset data 
  const onKeyDown = useCallback((e) => {
    if(e.key === 'Enter') {
      if(didValuesChanged && !e.target.closest('.dont-save-on-enter')) {
        handleSave()
      }
    }else if(e.key === 'Escape') {
      if(didValuesChanged) {
        handleCancel()
      }
    }
    // eslint-disable-next-line
  }, [didValuesChanged, alertTitle, alertEmails])

  // Add event keydown event listener on component mount
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <div className={!inList ? "single-alert" : "single-alert single-alert--in-list"}>
      <div className="document-detail-sp-section__fields">
        <TagsInput 
          label={`${t('general.recipients_emails')}*`} 
          name="emails"
          onChange={handleTagsChange}
          defaultTags={alertEmails} 
          emailValidation 
          thickBorder
          invalid={emailsError}
          className="dont-save-on-enter"
          onFocus={() => onSetEditStartTime(Date.now())}
          onBlur={onStoreEditTime}
        />
        <div className={`date_picker date_picker--2 date_picker--thick-border ${timeError ? 'date_picker--error' : ''}`} ref={datepickerElWrapper}>
          <p className="date_picker__label">{t('general.alert_date')}*</p>
          <DatePicker
            selected={alertTime}
            onChange={date => handleDateChange(date, setAlertTime)}
            onCalendarOpen={onCalendarOpen}
            dateFormat="d MMMM yyyy - HH'h'mm"
            dropdownMode="select"
            locale="fr"
            // onChangeRaw={(e) => e.preventDefault()}
            // isClearable={alertTime !== ''}
            minDate={new Date()}
            popperModifiers={{preventOverflow: { enabled: true, boundariesElement: 'window' }}}
            portalId="modal-root"
            timeCaption={t('general.time')}
            // popperPlacement="top"
            // showTimeInput
            showTimeSelect
            timeIntervals={60}
            excludeTimes={[
              ...defaultExcludedTimes,
              ...excludedTimes
            ]}
            onSelect={(date) => {
              setExcludedTimes(getExcludeTimesForDate(date))
            }}
            onCalendarClose={onStoreEditTime}
          />
        </div>
        <Input 
          label={`${t('general.alert_title')}`}
          name="title"
          value={alertTitle}
          onChange={(e) => handleInputChange(e.target.name, e.target.value, setAlertTitle)}
          formEl
          thickBorder
          invalid={titleError}
          onFocus={() => onSetEditStartTime(Date.now())}
          onBlur={onStoreEditTime}
        />
        <Textarea 
          label={t('general.message')}
          name="message"
          value={alertMessage}
          onChange={(e) => handleInputChange(e.target.name, e.target.value, setAlertMessage)}
          formEl
          thickBorder
          className="dont-save-on-enter"
          onFocus={() => onSetEditStartTime(Date.now())}
          onBlur={onStoreEditTime}
        />
      </div>
      <div className="document-detail-sp-section__actions">
        <Button 
          text={saving ? <Loader mini normalWhite /> : saved ? <Check /> : t('general.save')} 
          primary 
          onButtonClick={handleSave} 
          disabled={!didValuesChanged || alertEmails.length === 0 || !alertTime} 
        />
        {(didValuesChanged || inList) && <Button text={t('general.cancel')} onButtonClick={handleCancel} />}
        {currentAlert && <IconButton icon={<DeleteOutlined />} light onButtonClick={() => setShowDeleteAlertModal(true)} className="alert-delete" />}
      </div>

      {showDeleteAlertModal && <Alert 
        onClose={() => setShowDeleteAlertModal(false)} 
        text={t('alert.delete_alert')}
        onSubmit={handleDelete}
      />}
    </div>
  )
}

export default SingleAlert 
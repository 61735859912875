import axios from 'axios'
import { urlSuffixForEnvironment, blobToBase64 } from '../utils'
import firebase from './firebase'
import config from '../config.json'
import { v4 as uuidv4} from 'uuid'
import { currentLanguage } from '../i18n'

// const baseUrl = `http://localhost:5001/carbon-contract-management/europe-west1/api${urlSuffixForEnvironment(config.environment, config.staging)}`
const baseUrl = `https://europe-west1-carbon-contract-management.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment, config.staging)}`

const fetch_templates = async (teamId, exclude = null) => {
  return await get_request(`${baseUrl}/templates`, teamId, `&exclude=${exclude}&thumbnail=true`)
}

const fetch_templates_by_ids = async (teamId, ids = []) => {
  return await get_request(`${baseUrl}/templates_by_ids`, teamId, `&ids=${ids}`)
}

const fetch_single_template = async (id) => {
  return await get_request(`${baseUrl}/template/${id}`)
}

const fetch_documents = async (teamId) => {
  return await get_request(`${baseUrl}/documents`, teamId)
}

const fetch_single_document = async (id) => {
  return await get_request(`${baseUrl}/document/${id}`)
}

const fetch_folders = async (teamId) => {
  return await get_request(`${baseUrl}/folders`, teamId)
}

const fetch_documents_folders = async (teamId) => {
  return await get_request(`${baseUrl}/documents_folders`, teamId)
}

const fetch_document_history = async (documentId) => {
  return await get_request(`${baseUrl}/document_history/${documentId}`)
}

const fetch_actions = async (teamId, before, after) => {
  let params = ''
  if(before) {
    params += `&before=${before}`
  }
  if(after) {
    params += `&after=${after}`
  }
  return await get_request(`${baseUrl}/actions`, teamId, params)
}

const fetch_tasks = async (teamId, fetchAll = false) => {
  let additionalParams = ''
  if(fetchAll) {
    additionalParams = '&fetch=all'
  }
  return await get_request(`${baseUrl}/tasks`, teamId, additionalParams)
}

const fetch_alerts = async (teamId, fetchAll = false) => {
  let additionalParams = ''
  if(fetchAll) {
    additionalParams = '&fetch=all'
  }
  return await get_request(`${baseUrl}/alerts`, teamId, additionalParams)
}

const fetch_tags = async (teamId) => {
  return await get_request(`${baseUrl}/tags`, teamId)
}

const fetch_contacts = async (teamId) => {
  return await get_request(`${baseUrl}/contacts`, teamId)
}

const fetch_user = async (id) => {
  return await get_request(`${baseUrl}/users/${id}`)
}

const fetch_teams = async () => {
  return await get_request(`${baseUrl}/teams`)
}

const create_document = async (documentData, attachments) => {
  documentData.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  documentData.archived = false;
  documentData.deleted = false;
  let atts = await process_attachments(attachments)
  documentData.attachments = atts
  const response = await post_request(`${baseUrl}/document`, documentData)
  return { ...response, docData: documentData }
}

const update_document = async (documentId, documentData, attachments, addTimestampToAttName = false) => {
  documentData[`meta.updated`] = Date.now()
  if(attachments) {
    let atts = await process_attachments(attachments, addTimestampToAttName)
    documentData.attachments = atts
  }
  return await patch_request(`${baseUrl}/document/${documentId}`, documentData)
}

const process_attachments = async (attachments, addTimestampToAttName) => {
  let atts = []
  if(attachments) {
    for(let i in attachments) {
      let created = Date.now()
      let att = {
        name: attachments[i].name,
        format: attachments[i].format,
        type: attachments[i].type,
        share: attachments[i].share || false,
        id: attachments[i].id || uuidv4(),
      }
      if(!attachments[i].created) {
        att.created = created
      }else {
        att.created = attachments[i].created
      }
      if(!attachments[i].att_path) {
        att.att_path = `attachments/${attachments[i].name + '-' + created}.${attachments[i].format}`
      }else {
        att.att_path = attachments[i].att_path
      }
      if(attachments[i].data) {
        let uploadResult = await upload_file(
          attachments[i].data, 
          addTimestampToAttName ? attachments[i].name + '-' + created : attachments[i].name, 
          attachments[i].format, 
          attachments[i].type, 
          'attachments'
        )
        if(!uploadResult.url) {
          continue
        }
        att.url = uploadResult.url
      } else if(attachments[i].url) {
        att.url = attachments[i].url
      }
      atts.push(att)
    }
  }
  return atts
}

const delete_document = async (documentId) => {
  return await delete_request(`${baseUrl}/document/${documentId}`)
}

const update_template = async (data, templateId) => {
  // return await patch_request(`${baseUrl}/template/${templateId}`, data);
  return await patch_request(`${baseUrl}/template/${templateId}`, data);
}

const delete_template = async (templateId) => {
  return await delete_request(`${baseUrl}/template/${templateId}`)
}

const create_folder = async (folderData) => {
  folderData.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/folders`, folderData)
}

const create_documents_folder = async (folderData) => {
  folderData.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/documents_folders`, folderData)
}

const create_task = async (data) => {
  return await post_request(`${baseUrl}/tasks`, data)
}

const create_task_with_specific_id = async (data, id) => {
  return await post_request(`${baseUrl}/tasks_2`, { data, id })
}

const create_alert = async (data) => {
  return await post_request(`${baseUrl}/alerts`, data)
}

const create_tag = async (data) => {
  return await post_request(`${baseUrl}/tags`, data)
}

const create_contact = async (data) => {
  return await post_request(`${baseUrl}/contacts`, data)
}

const create_team = async (data) => {
  return await post_request(`${baseUrl}/teams`, data)
}

const update_folder = async (folderData, folderId) => {
  folderData[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/folders/${folderId}`, folderData);
}

const update_documents_folder = async (folderData, folderId) => {
  folderData[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/documents_folders/${folderId}`, folderData);
}

const update_task = async (data, id) => {
  return await patch_request(`${baseUrl}/tasks/${id}`, data);
}

const update_alert = async (data, id) => {
  return await patch_request(`${baseUrl}/alerts/${id}`, data);
}

const update_tag = async (data, id) => {
  return await patch_request(`${baseUrl}/tags/${id}`, data);
}

const update_contact = async (data, id) => {
  return await patch_request(`${baseUrl}/contacts/${id}`, data);
}

const update_user = async (data, id) => {
  return await patch_request(`${baseUrl}/users/${id}`, data);
}

const update_team = async (data, id) => {
  return await patch_request(`${baseUrl}/teams/${id}`, data);
}

const delete_folder = async (folderId) => {
  return await delete_request(`${baseUrl}/folders/${folderId}`);
}

const delete_documents_folder = async (folderId) => {
  return await delete_request(`${baseUrl}/documents_folders/${folderId}`);
}

const delete_task = async (id) => {
  return await delete_request(`${baseUrl}/tasks/${id}`);
}

const delete_alert = async (id) => {
  return await delete_request(`${baseUrl}/alerts/${id}`);
}

const delete_tag = async (id) => {
  return await delete_request(`${baseUrl}/tags/${id}`);
}

const delete_contact = async (id) => {
  return await delete_request(`${baseUrl}/contacts/${id}`);
}

const delete_team = async (id) => {
  return await delete_request(`${baseUrl}/teams/${id}`);
}

const delete_action = async (id) => {
  return await delete_request(`${baseUrl}/actions/${id}`);
}

const authenticate_user = async (data) => {
  return await post_request(`${baseUrl}/authenticate`, data);
}

const create_signature = async (data) => {
  return await post_request(`${baseUrl}/signatures`, data);
}

const fetch_signatures = async (teamId) => {
  return await get_request(`${baseUrl}/signatures`, teamId)
}

const get_signature_status = async (data) => {
  return await post_request(`${baseUrl}/signature_status`, data);
}

const get_signature_document = async (data) => {
  return await post_request(`${baseUrl}/signature_document`, data);
}

const delete_signature = async (id) => {
  return await delete_request(`${baseUrl}/signatures/${id}`);
}

const update_signature = async (id, data) => {
  return await patch_request(`${baseUrl}/signatures/${id}`, data);
}

const place_signature = async (data) => {
  return await post_request(`${baseUrl}/place_signature`, data);
}

const get_signature_placement_interface = async (fileId) => {
  return await get_request(`${baseUrl}/signature_placement_interface/${fileId}`);
}

const get_signature_position = async (fileId) => {
  return await get_request(`${baseUrl}/get_signature_position/${fileId}`);
}

const fetch_document_comments = async (documentId, after) => {
  let query = ''
  if(after) {
    query = `&after=${after}`
  }
  return await get_request(`${baseUrl}/document_comments/${documentId}`, null, query)
}

const create_document_comment = async (documentId, data) => {
  return await post_request(`${baseUrl}/document_comments/${documentId}`, data)
}

const update_document_comment = async (documentId, commentId, data) => {
  return await patch_request(`${baseUrl}/document_comments/${documentId}/${commentId}`, data)
}

const delete_document_comment = async (documentId, commentId) => {
  return await delete_request(`${baseUrl}/document_comments/${documentId}/${commentId}`)
}

const fetch_document_lists = async (teamId) => {
  return await get_request(`${baseUrl}/document_lists`, teamId)
}

const create_document_list = async (data) => {
  return await post_request(`${baseUrl}/document_lists`, data)
}

const update_document_list = async (listId, data) => {
  return await patch_request(`${baseUrl}/document_lists/${listId}`, data)
}

const delete_document_list = async (teamId, listId) => {
  return await delete_request(`${baseUrl}/document_lists/${teamId}/${listId}`)
}

const fetch_signature_credits = async (teamId) => {
  // If staging don't use credits from prod
  if(config.environment === 'production' && config.staging) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ credits: 20 })
      }, 500)
    })
  }
  return await get_request(`${baseUrl}/signature_credits`, teamId)
}

// const get_request = async (url, teamId = null, additionalParams = '') => {
//   const token = await firebase.auth().currentUser.getIdToken()
//   let response = await axios.get(`${url}?id_token=${token}&team=${teamId}${additionalParams}`)
//   if(response.data.error) {
//     console.log("firestore error")
//     console.log(response.data.error)
//     return response.data
//   }
//   return response.data.data
// }

const get_request = async (url, teamId = null, additionalParams = '') => {
  try {
    let token = ''
    if(firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true)
    }
    const headers = {
      "Token": token,
      "Language": currentLanguage()
    }
    if(url.includes('onespan')) {
      headers["Staging-Environment"] = config.staging
    }
    let response = await axios.get(`${url}?&team=${teamId}${additionalParams}`, {
      headers
    })
    if(response.data.error) {
      console.log("firestore error")
      console.log(response.data.error)
      return response.data
    }
    return response.data.data
  } catch(err) {
    return err.response.data
  }
}

// const patch_request = async (url, data, additionalParams = '') => {
//   const token = await firebase.auth().currentUser.getIdToken()
//   let response = await axios.patch(`${url}?id_token=${token}${additionalParams}`, data, {
//     headers: {
//       "Content-Type": "application/json"
//     }
//   })
//   if(response.data.error) {
//     console.log("firestore error")
//     console.log(response.data.error)
//     return response.data
//   } 
//   return response.data
// }

const patch_request = async (url, data, additionalParams = '') => {
  try {
    let token = ''
    if(firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true)
    }
    const headers = {
      "Content-Type": "application/json",
      "Token": token,
      "Language": currentLanguage()
    }
    let response = await axios.patch(`${url}?${additionalParams}`, data, {
      headers
    })
    if(response.data.error) {
      console.log("firestore error")
      console.log(response.data.error)
      return response.data
    } 
    return response.data
  } catch(err) {
    return err.response.data
  }
}

// const post_request = async (url, data, additionalParams = '') => {
//   const token = await firebase.auth().currentUser.getIdToken()
//   let headers = {
//     "Content-Type": "application/json"
//   }
//   if(url.includes('onespan')) {
//     headers = {
//       ...headers,
//       "Staging-Environment": config.staging
//     }
//   }
//   let response = await axios.post(`${url}?id_token=${token}${additionalParams}`, data, {
//     headers: headers
//   })
//   if(response.data.error) {
//     console.log("firestore error")
//     console.log(response.data.error)
//     return response.data
//   } 
//   return response.data
// }

const post_request = async (url, data, additionalParams = '', lang = null) => {
  try {
    let token = ''
    if(firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true)
    }
    let headers = {
      "Content-Type": "application/json",
      "Language": lang ? lang : currentLanguage(),
      "Token": token
    }
    if(url.includes('onespan')) {
      headers = {
        ...headers,
        "Staging-Environment": config.staging
      }
    }
    let response = await axios.post(`${url}?${additionalParams}`, data, {
      headers: headers
    })
    if(response.data.error) {
      console.log("firestore error")
      console.log(response.data.error)
      return response.data
    } 
    return response.data
  } catch(err) {
    console.log(err)

    if(err.response?.data?.error) {
      throw err.response.data.error
    } else {
      throw err
    }
  }
}

// const delete_request = async (url, additionalParams = '') => {
//   const token = await firebase.auth().currentUser.getIdToken()
//   let response = await axios.delete(`${url}?id_token=${token}${additionalParams}`)
//   if(response.data.error) {
//     console.log("firestore error")
//     console.log(response.data.error)
//     return response.data
//   } 
//   return response.data.data || response.data
// }

const delete_request = async (url, additionalParams = '') => {
  try {
    let token = ''
    if(firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true)
    }
    const headers = {
      "Token": token,
      "Language": currentLanguage()
    }
    let response = await axios.delete(`${url}?${additionalParams}`, {
      headers
    })
    if(response.data.error) {
      console.log("firestore error")
      console.log(response.data.error)
      return response.data
    } 
    return response.data.data || response.data
  } catch(err) {
    return err.response.data
  }
}

const upload_file = async (data, name, format, contentType, path) => {
  try {
    let fileRef = firebase.storage().ref().child(`${path}/${name}.${format}`)
    // let fileRef = firebase.storage().ref().child(`/${handle}/${entityId}_logo.png`)
    let parts = data.split(',')
    let base64data
    if(parts.length === 2) {
      base64data = parts[1]
    } else {
      base64data = data
    }
    await fileRef.putString(base64data, 'base64', { contentType: contentType })
    let downloadUrl = await fileRef.getDownloadURL()
    return { url: downloadUrl }
  } catch(err) {
    console.log('image upload error', err)
    return { error: err }
  }
}

const upload_helper = async (path, file, metadata, onProgress = () => {}) => {
  return new Promise((resolve, reject) => {
    let fileRef = firebase.storage().ref().child(path)
    const uploadTask = fileRef.put(file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        onProgress(progress)
      },
      (err) => {
        console.log(err)
        reject(err)
      },
      async () => {
        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
        resolve(downloadUrl)
      }
    )
  })
}

const upload_file_with_progress = async (file, name, format, contentType, path, onProgress = () => {}) => {
  try {
    const downloadUrl = await upload_helper(`${path}/${name}.${format}`, file, { contentType: contentType }, (progress) => {
      onProgress(progress)
    })
    return { url: downloadUrl }
  } catch(err) {
    console.log('image upload error', err)
    return { error: err }
  }
}

const delete_file = async (path) => {
  try {
    let ref = firebase.storage().ref().child(path);
    await ref.delete();
    return true;
  } catch (err) {
    console.log('file delete error', err)
    return { error: err }
  }
}

const get_file_from_external_url = async (data) => {
  return await post_request(`${baseUrl}/file_base64`, data);
}

const get_file = async (data) => {
  try {
    const res = await axios.get(data.url, { responseType: 'blob' })
    const base64Data = await blobToBase64(res.data)
    let dataToRemove = base64Data.split(',')[0]
    
    return {
      success: true,
      data: base64Data.replace(`${dataToRemove},`, '')
    }
  } catch (err) {
    console.log('get file error', err)
  }
}

const filter_documents = async (data) => {
  return await post_request(`${baseUrl}/filter-documents`, data);
}

const firebaseTimeStamp = firebase.firestore.FieldValue.serverTimestamp();

export {
  baseUrl,
  fetch_templates,
  fetch_templates_by_ids,
  fetch_single_template,
  fetch_documents,
  fetch_single_document,
  fetch_document_history,
  update_document,
  create_document,
  delete_document,
  filter_documents,
  update_template,
  delete_template,
  fetch_folders,
  create_folder,
  update_folder,
  delete_folder,
  authenticate_user,
  firebaseTimeStamp,
  upload_file,
  upload_file_with_progress,
  post_request,
  get_request,
  patch_request,
  delete_request,
  fetch_actions,
  delete_action,
  create_signature,
  fetch_signatures,
  get_signature_status,
  delete_signature,
  update_signature,
  get_signature_document,
  place_signature,
  fetch_documents_folders,
  create_documents_folder,
  update_documents_folder,
  delete_documents_folder,
  fetch_tasks,
  create_task,
  create_task_with_specific_id,
  update_task,
  delete_task,
  create_alert,
  fetch_alerts,
  update_alert,
  delete_alert,
  get_file_from_external_url,
  get_file,
  fetch_tags,
  create_tag,
  update_tag,
  delete_tag,
  fetch_contacts,
  create_contact,
  update_contact,
  delete_contact,
  fetch_user,
  update_user,
  get_signature_placement_interface,
  get_signature_position,
  process_attachments,
  delete_file,
  create_team,
  fetch_teams,
  update_team,
  delete_team,
  fetch_document_comments,
  create_document_comment,
  update_document_comment,
  delete_document_comment,
  fetch_document_lists,
  create_document_list,
  update_document_list,
  delete_document_list,
  fetch_signature_credits
}
import React, { useContext } from 'react';

import Modal from './Modal';
import Button from './Button';
import { GlobalContext } from '../../context'

const Popup = ({ onClose, onSubmit, children, title, cancelBtnText, submitBtnText, onCancel, submitBtnDisabled, hideCancelBtn = false, alignFootRight, className = '' }) => {
  const { t } = useContext(GlobalContext)

  return(
    <Modal onClose={onClose} medium noPadding>
      <div className={`popup ${className}`}>
        <div className="popup__head">
          <h3>{title}</h3>
        </div>
        <div className="popup__body">
          {children}
        </div>
        <div className={alignFootRight ? "popup__foot popup__foot--align-right" : "popup__foot"}>
          {!hideCancelBtn && <Button text={cancelBtnText || t('general.cancel')} onButtonClick={onCancel ? onCancel : onClose} transparent medium />}
          <Button text={submitBtnText} onButtonClick={onSubmit} primary medium disabled={submitBtnDisabled} />
        </div>
      </div>
    </Modal>
  );
}

export default Popup;
import React, { useState, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons'

import CustomTooltip from './CustomTooltip';

const Input = ({ label, type, placeholder, value, onChange, name, error, iconEl, id, disabled, medium, colorLight, editMode, dashboardModal, editOnHover, onBlur, alignHoriz, small, labelBelow, profileMode, whiteBackground, iconSmall, sizeMedium, onFocus, alternativeIcon, onIconLabelClick, formEl, transparent, popupMode, showSuggestions, suggestions = [], onSuggestionSelect, maxLength, min, max, thickBorder, invalid, iconLocation, autoResize, endButton, formElOptional, tooltip, inputStyle, className, infoText, labelDropdopwn = null, showEyeIcon }) => {
  const [inputActive, setInputActive] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState({});
  const [suggestionsArr, setSuggestionsArr] = useState([...suggestions].filter(s => s.id !== selectedSuggestion.id));
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [showInputValue, setShowInputValue] = useState(false)

  useEffect(() => {
    if(dashboardModal && value !== '') {
      setInputActive(true);
    }
  }, [value]);

  // On change
  const changeHandler = (e) => {
    onChange(e);
    if(showSuggestions) {
      setSuggestionsArr(prev => {
        if(e.target.value.trim() !== '') {
          setSuggestionsActive(true);
          return [...suggestions].filter(s => s.id !== selectedSuggestion.id && s.name?.toLowerCase().includes(e.target.value.trim().toLowerCase()))
        }else {
          setSuggestionsActive(false);
          return [...suggestions].filter(s => s.id !== selectedSuggestion.id);
        }
      });
    }
  }

  // On blur
  const inputBlurHandler = (e) => {
    if(dashboardModal && value === '') {
      setInputActive(false);
    }
    if(onBlur) {
      onBlur(e);
    }
    // if(showSuggestions) {
    //   setSuggestionsActive(false);
    // }
  }

  // On focus
  const inputFocusHandler = (e) => {
    if(dashboardModal) {
      setInputActive(true);
    }
    if(onFocus) {
      onFocus(e);
    }
  }

  // On suggestion click
  const suggestionClickHandler = (s) => {
    onChange(null, s.name, name)
    setSelectedSuggestion(s);
    onSuggestionSelect(s, name);
    setSuggestionsArr(prev => [...prev].filter(item => item.id !== s.id));
    setSuggestionsActive(false);
  }

  // Input classes
  let inputClasses = 'form-input';

  if(error) {
    inputClasses += ' error';
  }

  if(iconEl) {
    inputClasses += ' with-icon';
  }

  if(medium) {
    inputClasses += ' form-input--medium';
  }

  if(colorLight) {
    inputClasses += ' form-input--color-light';
  }

  if(editMode) {
    inputClasses += ' form-input--edit-mode';
  }

  if(dashboardModal) {
    inputClasses += ' form-input--dashboard-modal';
  }

  if(editOnHover) {
    inputClasses += ' form-input--editable-on-hover';
  }

  if(small) {
    inputClasses += ' form-input--small';
  }

  if(profileMode) {
    inputClasses += ' form-input--profile-mode';
  }

  if(whiteBackground) {
    inputClasses += ' form-input--white-background';
  }

  if(iconSmall) {
    inputClasses += ' form-input--icon-small';
  }

  if(sizeMedium) {
    inputClasses += ' form-input--size-medium';
  }

  if(formEl) {
    inputClasses += ' form-input--form-el';
  }
  if(formElOptional) {
    inputClasses += ' form-input--form-el-optional';
  }

  if(transparent) {
    inputClasses += ' form-input--transparent';
  }

  if(popupMode) {
    inputClasses += ' form-input--popup-mode';
  }

  if(thickBorder) {
    inputClasses += ' form-input--thick-border';
  }

  if(invalid) {
    inputClasses += ' form-input--error';
  }
  if(className) {
    inputClasses += ` ${className}`;
  }
  if(disabled) {
    inputClasses += ' disabled'
  }

  // On toggle input value
  const handleToggleInputValue = () => {
    setShowInputValue(!showInputValue)
  }

  return(
    <div className={inputClasses}>
      <div className={alignHoriz ? "form-input__inner form-input__inner--align-horiz" : "form-input__inner"}>
        {iconEl && <label className={`form-input__icon ${iconLocation ? `${iconLocation}` : ''}`} onClick={onIconLabelClick}>{alternativeIcon}{iconEl}</label>}
        {label && 
          dashboardModal 
            ? (
              <div className={`form-input__label-wrapper ${labelDropdopwn ? 'with-dropdown' : ''}`}>
                <label htmlFor={id ? id : name} className={inputActive ? "form-input__label active" : "form-input__label"}>{label}</label>
                {labelDropdopwn && <div className="label-dropdown-wrapper">{labelDropdopwn}</div>}
              </div>
              )
            : label && (
              <div className={`form-input__label-wrapper ${labelDropdopwn ? 'with-dropdown' : ''}`}>
                <label htmlFor={id ? id : name} className="form-input__label">{label}</label>
                {labelDropdopwn && <div className="label-dropdown-wrapper">{labelDropdopwn}</div>}
              </div>
            )
        }
        {tooltip ? <CustomTooltip content={tooltip} className="custom-tooltip--top-input">
          {showEyeIcon && type === 'password'
            ? (
              <div className="input-wrapper">
                <input 
                  type={showInputValue ? "text" : "password"}
                  className="form-input__field"
                  placeholder={placeholder}
                  name={name}
                  id={id ? id : name}
                  value={value}
                  onChange={changeHandler}
                  autoComplete="off"
                  disabled={disabled}
                  onBlur={inputBlurHandler}
                  onFocus={inputFocusHandler}
                  maxLength={maxLength}
                  min={min}
                  max={max}
                  size={autoResize ? value.length : 'auto'}
                  style={inputStyle}
                />
                <div className="input-wrapper__icon" onClick={handleToggleInputValue}>
                  {showInputValue ? <VisibilityOff /> : <Visibility />}
                </div>
              </div>
            )
            : (
              <input 
                type={type ? type : "text"}
                className="form-input__field"
                placeholder={placeholder}
                name={name}
                id={id ? id : name}
                value={value}
                onChange={changeHandler}
                autoComplete="off"
                disabled={disabled}
                onBlur={inputBlurHandler}
                onFocus={inputFocusHandler}
                maxLength={maxLength}
                min={min}
                max={max}
                size={autoResize ? value.length : 'auto'}
                style={inputStyle}
              />
            )}
        </CustomTooltip>
        : showEyeIcon && type === 'password'
          ? (
            <div className="input-icon-wrapper">
              <input 
                type={showInputValue ? "text" : "password"}
                className="form-input__field"
                placeholder={placeholder}
                name={name}
                id={id ? id : name}
                value={value}
                onChange={changeHandler}
                autoComplete="off"
                disabled={disabled}
                onBlur={inputBlurHandler}
                onFocus={inputFocusHandler}
                maxLength={maxLength}
                min={min}
                max={max}
                size={autoResize ? value.length : 'auto'}
                style={inputStyle}
              />
              <div className="input-icon-wrapper__icon" onClick={handleToggleInputValue}>
                {showInputValue ? <VisibilityOff /> : <Visibility />}  
              </div>
            </div>
          )
          : (
            <input 
              type={type ? type : "text"}
              className="form-input__field"
              placeholder={placeholder}
              name={name}
              id={id ? id : name}
              value={value}
              onChange={changeHandler}
              autoComplete="off"
              disabled={disabled}
              onBlur={inputBlurHandler}
              onFocus={inputFocusHandler}
              maxLength={maxLength}
              min={min}
              max={max}
              size={autoResize ? value.length : 'auto'}
              style={inputStyle}
            />
          )
        }
        
        { Boolean(endButton) && endButton }
        {labelBelow && <label htmlFor={id} className="form-input__label">{labelBelow}</label>}
        {showSuggestions && suggestionsArr.length > 0 && value.trim() !== '' && suggestionsActive && <div className="suggestions">
          <ul className="suggestions__list">
            {suggestionsArr.map((s, i) => (
              <li className="suggestions__list_item" key={i} onClick={() => suggestionClickHandler(s)}>{s.name}</li>
            ))}
          </ul>
        </div>}
        {infoText && <p className="form-input__info">{infoText}</p>}
      </div>
      {error && <div className="form-input__error">{error}</div>}
    </div>
  );
}

// Input.propTypes = {
//   label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   type: PropTypes.string,
//   placeholder: PropTypes.string,
//   value: PropTypes.string,
//   onChange: PropTypes.func,
//   name: PropTypes.string,
//   error: PropTypes.string,
//   iconEl: PropTypes.node,
//   disabled: PropTypes.bool,
//   medium: PropTypes.bool,
//   colorLight: PropTypes.bool,
//   editMode: PropTypes.bool,
//   dashboardModal: PropTypes.bool,
//   editOnHover: PropTypes.bool,
//   onBlur: PropTypes.func,
//   onFocus: PropTypes.func,
//   alignHoriz: PropTypes.bool,
//   small: PropTypes.bool,
//   whiteBackground: PropTypes.bool,
//   iconSmall: PropTypes.bool,
//   sizeMedium: PropTypes.bool,
// }

export default Input;
import React, { useEffect , useContext, useState} from 'react'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import DashboardLayout from '../components/layouts/Dashboard'
import DashboardLayoutV2 from '../components/layouts/DashboardLayoutV2'
import AuthLayout from '../components/layouts/AuthLayout'
import DocumentDetail from '../components/pages/DocumentDetail'
import SignIn from '../components/pages/SignIn'
import SignUp from '../components/pages/SignUp'
import ForgotPassword from '../components/pages/ForgotPassword'
import SearchResults from '../components/pages/SearchResults'
import NotFound from '../components/pages/NotFound'
import WhatsNew from '../components/pages/support/WhatsNew'
import Help from '../components/pages/support/Help'
import HelpCategory from '../components/pages/support/HelpCategory'
import HelpArticle from '../components/pages/support/HelpArticle'
import HelpSearchResults from '../components/pages/support/HelpSearchResults'
import WhatsNewArticle from '../components/pages/support/WhatsNewArticle'
import SharedPdf from '../components/pages/SharedPdf'
import TemplatePage from '../components/pages/TemplatePage'
import SharedTemplates from '../components/pages/SharedTemplates'
import ResponseLoader from '../components/UI/ResponseLoader'
import { Dashboard, Documents as DocumentsPage, Templates as TemplatesPage, Signatures as SignaturesPage, Contacts as ContactsPage, Variables as VariablesPage, AlertsPage, TasksPage as TasksPage2, MyProfile, ChangePassword, EmailUpload, SecuritySettings, AddTeam, Team, Teams, Invoices, Membership, UpgradeTeam, AddEditApproval, Approvals, ExternalDocuments } from '../pages'
import { availableOn } from '../utils'
import { GlobalContext, TeamContext, UserContext, LoaderContext } from '../context'
import { MainLayout } from '../layouts'
import config from '../config.json'

const stripeKeys = {
  test: 'pk_test_51J0QFhAqb91BRy57j2YjyJOEliHOXMhNgann3ToeeCtHIEsBwMDDU82qgSjdgIrLfuT3xADWPfJs1a1g1uD04lwP00rSnvBc0q',
  live: 'pk_test_51J0QFhAqb91BRy57j2YjyJOEliHOXMhNgann3ToeeCtHIEsBwMDDU82qgSjdgIrLfuT3xADWPfJs1a1g1uD04lwP00rSnvBc0q' // for demo we use test key in production
}

const stripePromise = loadStripe(stripeKeys[config.environment === 'production' && !config.staging ? 'live' : 'test'])

const Routes = ({ isAuthenticated }) => {
  const { setPrevPaths, t, selectedLang } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { showGlobalResponseLoader, globalResponseLoaderText, loading } = useContext(LoaderContext)
  const location = useLocation()
  const history = useHistory()
  // const [stripePromise, setStripePromise] = useState(loadStripe(stripeKeys[config.environment === 'production' ? 'live' : 'test'], { locale: selectedLang }))

  useEffect(() => {
    // stripePromise.
    // console.log('update stripe promise')
    // setStripePromise(loadStripe(stripeKeys[config.environment === 'production' ? 'live' : 'test'], { locale: selectedLang }))
  }, [selectedLang])



  // Listen for route changes
  useEffect(() => {
    if(location.pathname) {
      setPrevPaths(prev => {
        let paths = [...prev] 
        if(paths.length < 2) {
          paths.unshift(location.pathname)
        }else {
          paths.pop()
          paths.unshift(location.pathname)
        }
        return paths 
      })
    }
  }, [location, setPrevPaths])

  useEffect(() => {
    if(!selectedTeam || !user || (config.environment === 'production' && !config.staging)) {
      return
    }
    if(selectedTeam.owner !== user.id && (!selectedTeam.membership || selectedTeam.membership === 'free')) {
      // redirect to upgrade team page
      if(location.pathname !== '/upgrade-team') {
        history.replace('/upgrade-team')
      }
    } else if(location.pathname === '/upgrade-team') {
      history.replace('/')
    }
  }, [selectedTeam, user, location, history])

  return (
    <>
      {showGlobalResponseLoader && <ResponseLoader text={globalResponseLoaderText} />}
      {loading && <div className="overlay"></div>}
      <Switch>
        {/* New UI routes */}
        <PrivateRoute path="/dashboard" authenticated={isAuthenticated} exact>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/documents" authenticated={isAuthenticated} exact>
          <DocumentsPage />
        </PrivateRoute>
        <PrivateRoute path="/templates" authenticated={isAuthenticated} exact>
          <TemplatesPage />
        </PrivateRoute>
        <PrivateRoute path="/signatures" authenticated={isAuthenticated} exact>
          <Elements stripe={stripePromise} locale={selectedLang}>
            <SignaturesPage />
          </Elements>
        </PrivateRoute>
        <PrivateRoute path="/contacts" authenticated={isAuthenticated} exact>
          <ContactsPage />
        </PrivateRoute>
        <PrivateRoute path="/variables" authenticated={isAuthenticated} exact>
          <VariablesPage />
        </PrivateRoute>
        <PrivateRoute path="/calendar" authenticated={isAuthenticated} exact>
          <AlertsPage />
        </PrivateRoute>
        <PrivateRoute path="/tasks" authenticated={isAuthenticated} exact>
          <TasksPage2 />
        </PrivateRoute>
        <PrivateRoute path="/settings/my-profile" authenticated={isAuthenticated} exact>
          <MyProfile />
        </PrivateRoute>
        <PrivateRoute path="/settings/change-password" authenticated={isAuthenticated} exact>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute path="/settings/security" authenticated={isAuthenticated} exact>
          <SecuritySettings />
        </PrivateRoute>
        <PrivateRoute path="/settings/email-upload" authenticated={isAuthenticated} exact>
          <EmailUpload />
        </PrivateRoute>
        <PrivateRoute path="/settings/add-team" authenticated={isAuthenticated} exact limited={{ requirement: 'premium', type: 'account' }}>
          <AddTeam />
        </PrivateRoute>
        <PrivateRoute path="/settings/team" authenticated={isAuthenticated} exact>
          <Team />
        </PrivateRoute>
        <PrivateRoute path="/settings/teams" authenticated={isAuthenticated} exact>
          <Teams />
        </PrivateRoute>
        {availableOn(['development', 'staging']) && <PrivateRoute path="/settings/membership" authenticated={isAuthenticated} exact>
          <Elements stripe={stripePromise} locale={selectedLang}>
            <Membership />
          </Elements>
        </PrivateRoute> }
        {availableOn(['development', 'staging']) && <PrivateRoute path="/settings/invoices" authenticated={isAuthenticated} exact>
          <Elements stripe={stripePromise} locale={selectedLang}>
            <Invoices />
          </Elements>
        </PrivateRoute>}
        {availableOn(['development', 'staging']) && <PrivateRoute path="/upgrade-team" authenticated={isAuthenticated} exact>
          <UpgradeTeam />
        </PrivateRoute>}
        <PrivateRoute path="/approvals" authenticated={isAuthenticated} exact>
          <Approvals />
        </PrivateRoute>
        <PrivateRoute path="/approvals/new" authenticated={isAuthenticated} exact>
          <AddEditApproval />
        </PrivateRoute>
        <PrivateRoute path="/approval/:id" authenticated={isAuthenticated} exact>
          <AddEditApproval edit={true} />
        </PrivateRoute>
        <PrivateRoute path="/shared-templates" authenticated={isAuthenticated} exact>
          <MainLayout>
            <SharedTemplates />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/search" authenticated={isAuthenticated} exact>
          <MainLayout>
            <SearchResults />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/help" authenticated={isAuthenticated} exact>
          <MainLayout>
            <Help v2={true} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/what-is-new" authenticated={isAuthenticated} exact>
          <MainLayout>
            <WhatsNew v2={true} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/help-category/:id" authenticated={isAuthenticated} exact>
          <MainLayout>
            <HelpCategory v2={true} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/help-article/:id" authenticated={isAuthenticated} exact>
          <MainLayout>
            <HelpArticle v2={true} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/help-search-results" authenticated={isAuthenticated} exact>
          <MainLayout>
            <HelpSearchResults v2={true} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/what-is-new/:id" authenticated={isAuthenticated} exact>
          <MainLayout>
            <WhatsNewArticle v2={true} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/external-documents" authenticated={isAuthenticated} exact>
          <ExternalDocuments />
        </PrivateRoute>
        {/* END New UI routes */}

        {/* <PrivateRoute path="/dashboard" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2 dashboard={true}>
            <DashboardV2DnD />
          </DashboardLayoutV2>
        </PrivateRoute>
        <PrivateRoute path="/documents" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Documents />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/external-documents" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <ExternalDocuments />
          </DashboardLayout>
        </PrivateRoute> */}
        <PrivateRoute path="/document-detail/:documentId" authenticated={isAuthenticated} exact>
          <DocumentDetail />
        </PrivateRoute>
        {/* <PrivateRoute path="/templates" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Templates />
          </DashboardLayout>
        </PrivateRoute> */}
        {/* <PrivateRoute path="/shared-templates" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2 title={t('dashboard.shared_templates')}>
            <SharedTemplates />
          </DashboardLayoutV2>
        </PrivateRoute> */}
        <PrivateRoute path="/template/:templateId" authenticated={isAuthenticated} exact>
          <DocumentDetail />
        </PrivateRoute>
        <Route path="/shared-template/:templateId" authenticated={isAuthenticated} exact>
          <DocumentDetail isSharedTemplate={true} />
        </Route>
        <Route path="/shared-templates/:pageId/:templateId" authenticated={isAuthenticated} exact>
          <DocumentDetail isSharedTemplateFromPage={true} />
        </Route>
        <Route path="/shared-template-page/:id" authenticated={isAuthenticated} exact>
          <TemplatePage isPublic={true} />
        </Route>
        {/* <PrivateRoute path="/contacts" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Contacts />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/signatures" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Elements stripe={stripePromise}>
              <SignaturesOnespan />
            </Elements>
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/my-profile" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <MyProfile />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/change-password" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <ChangePassword />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/security" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <SecuritySettings />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/email-upload" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <EmailUpload />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/team" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Team />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/teams" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Teams />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/settings/add-team" authenticated={isAuthenticated} exact limited={{ requirement: 'premium', type: 'account' }}>
          <DashboardLayout>
            <AddTeam />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/calendar" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2 title={t('dashboard.alerts')}>
            <Calendar />
          </DashboardLayoutV2>
        </PrivateRoute>
        <PrivateRoute path="/tasks" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2 title={t('dashboard.tasks')}>
            <TasksPage />
          </DashboardLayoutV2>
        </PrivateRoute> */}
        <PublicRoute path="/signin" authenticated={isAuthenticated} exact>
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        </PublicRoute>
        <PublicRoute path="/signup" authenticated={isAuthenticated} exact>
          <AuthLayout>
            <SignUp />
          </AuthLayout>
        </PublicRoute>
        <PublicRoute path="/forgot-password" authenticated={isAuthenticated} exact>
          <AuthLayout>
            <ForgotPassword />
          </AuthLayout>
        </PublicRoute>
        <Route path="/share/:id" authenticated={isAuthenticated} exact>
          <SharedPdf isAuthenticated={isAuthenticated} />
        </Route>
        {/* <PrivateRoute path="/search" authenticated={isAuthenticated} exact limited={{ requirement: 'beta_partner', type: 'team' }}>
          <DashboardLayout>
            <SearchResults />
          </DashboardLayout>
        </PrivateRoute> */}
        {/* {config.environment === 'development' && <PrivateRoute path="/search-v2" authenticated={isAuthenticated} exact limited={{ requirement: 'premium', type: 'team' }}>
          <DashboardLayoutV3>
            <SearchResults />
          </DashboardLayoutV3>
        </PrivateRoute>}
        {config.environment === 'development' && <PrivateRoute path="/documents-v2" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <Documents />
          </DashboardLayoutV3>
        </PrivateRoute>}
        {config.environment === 'development' && <PrivateRoute path="/templates-v2" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <Templates />
          </DashboardLayoutV3>
        </PrivateRoute>}
        {config.environment === 'development' && <PrivateRoute path="/signatures-v2" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <Signatures />
          </DashboardLayoutV3>
        </PrivateRoute>}
        {config.environment === 'development' && <PrivateRoute path="/variables-v2" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <Variables />
          </DashboardLayoutV3>
        </PrivateRoute>}
        {config.environment === 'development' && <PrivateRoute path="/kpi" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <KPIDraggable />
          </DashboardLayoutV3>
        </PrivateRoute>}
        <PrivateRoute path="/approvals" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2>
            <Approvals />
          </DashboardLayoutV2>
        </PrivateRoute>
        <PrivateRoute path="/approvals/new" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2>
            <AddNewApproval />
          </DashboardLayoutV2>
        </PrivateRoute>
        <PrivateRoute path="/approval/:id" authenticated={isAuthenticated} exact>
          <DashboardLayoutV2>
            <AddNewApproval edit={true} />
          </DashboardLayoutV2>
        </PrivateRoute>
        <PrivateRoute path="/variables" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <Variables />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute path="/template-page/:id" authenticated={isAuthenticated} exact>
          <TemplatePage />
        </PrivateRoute>
        <Route path="/shared-template-page/:id" authenticated={isAuthenticated} exact>
          <TemplatePage isPublic={true} />
        </Route> */}

        {/* {config.environment === 'development' && <PrivateRoute path="/request-demo" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <RequestDemo />
          </DashboardLayoutV3>
        </PrivateRoute>} */}
        
        {/* <PrivateRoute path="/documentation" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><Documentation /></DashboardLayoutV2>
            : <DashboardLayoutV3><Documentation /></DashboardLayoutV3>
          }
        </PrivateRoute> */}
        {/* <PrivateRoute path="/documentation/:id" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><DocumentationArticle /></DashboardLayoutV2>
            : <DashboardLayoutV2><DocumentationArticle /></DashboardLayoutV2>
          }
        </PrivateRoute> */}
        <Redirect from="/" to="/dashboard" />
        <Route path="*">
          <NotFound />
        </Route>
        <PrivateRoute path="/what-is-new" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><WhatsNew /></DashboardLayoutV2>
            : <DashboardLayoutV2><WhatsNew /></DashboardLayoutV2>
          }
        </PrivateRoute>
        <PrivateRoute path="/help" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><Help /></DashboardLayoutV2>
            : <DashboardLayoutV2><Help /></DashboardLayoutV2>
          }
        </PrivateRoute>
        {/* {config.environment === 'development' && <PrivateRoute path="/request-demo" authenticated={isAuthenticated} exact>
          <DashboardLayoutV3>
            <RequestDemo />
          </DashboardLayoutV3>
        </PrivateRoute>} */}
        {/* <PrivateRoute path="/help-category/:id" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><HelpCategory /></DashboardLayoutV2>
            : <DashboardLayoutV2><HelpCategory /></DashboardLayoutV2>
          }
        </PrivateRoute>
        <PrivateRoute path="/help-article/:id" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><HelpArticle /></DashboardLayoutV2>
            : <DashboardLayoutV2><HelpArticle /></DashboardLayoutV2>
          }
        </PrivateRoute>
        <PrivateRoute path="/help-search-results" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><HelpSearchResults /></DashboardLayoutV2>
            : <DashboardLayoutV2><HelpSearchResults /></DashboardLayoutV2>
          }
        </PrivateRoute>
        <PrivateRoute path="/what-is-new/:id" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><WhatsNewArticle /></DashboardLayoutV2>
            : <DashboardLayoutV2><WhatsNewArticle /></DashboardLayoutV2>
          }
        </PrivateRoute> */}
        {/* <PrivateRoute path="/documentation" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><Documentation /></DashboardLayoutV2>
            : <DashboardLayoutV3><Documentation /></DashboardLayoutV3>
          }
        </PrivateRoute> */}
        {/* <PrivateRoute path="/documentation/:id" authenticated={isAuthenticated} exact>
          {config.environment === 'production'
            ? <DashboardLayoutV2><DocumentationArticle /></DashboardLayoutV2>
            : <DashboardLayoutV2><DocumentationArticle /></DashboardLayoutV2>
          }
        </PrivateRoute> */}
        <PrivateRoute path="/upgrade-team" authenticated={isAuthenticated} exact>
          <DashboardLayout>
            <UpgradeTeam />
          </DashboardLayout>
        </PrivateRoute>
        <Redirect from="/" to="/dashboard" />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  )
}

export default Routes
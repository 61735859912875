import React, { useContext, useEffect, useState } from 'react'

import ActionsDropdown from '../../UI/ActionsDropdown'
import Switch from '../../UI/Switch'
import { VariablesContext, GlobalContext } from '../../../context'

const FieldMapping = ({ field, columns, firstRowHeaders, mappedFields, onSetMappedFields, reset, matchedColumnsAdded, linkedVariables, onSetLinkedVariables, className, custom = false, onSetCustomColumnsToSave, customColumnsToSave, customCsvColumns, onSetCustomCsvColumns }) => {
  const { t } = useContext(GlobalContext)
  const { variables, variablesFetched } = useContext(VariablesContext)
  const [checked, setChecked] = useState(false)
  const [hideDropdown, setHideDropdown] = useState(false)
  const [hideVarsDropdown, setHideVarsDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedCol, setSelectedCol] = useState(t('contacts.choose_csv_column'))
  const [selectedVariable, setSelectedVariable] = useState(t('contacts.select_variable'))
  const [filteredVariables, setFilteredVariables] = useState([])
  const [customLabel, setCustomLabel] = useState(field.label || '')

  useEffect(() => {
    if(variablesFetched) {
      setFilteredVariables(variables)
    }
  }, [variables, variablesFetched])

  // If any csv columns match with default fields, make the fields active
  useEffect(() => {
    if(matchedColumnsAdded && mappedFields.length > 0) {
      if(mappedFields.find(f => f.key === field.value && f.active)) {
        setChecked(true)
      }
    }
  }, [mappedFields, matchedColumnsAdded, field])

  // Reset
  useEffect(() => {
    if(reset) {
      setChecked(false)
      setSelectedCol(t('contacts.choose_csv_column'))
      setSelectedVariable(t('contacts.select_variable'))
    }
  }, [reset])

  // First row header change
  useEffect(() => {
    let fieldExists = mappedFields.find(f => f.key === field.value)
    if(fieldExists) {
      if(fieldExists.column !== undefined) {
        setSelectedCol(firstRowHeaders ? columns[fieldExists.column] : `${t('contacts.column')} ${fieldExists.column + 1}`)
      }else {
        setSelectedCol(t('contacts.choose_csv_column'))
      }
    }else {
      setSelectedCol(t('contacts.choose_csv_column'))
    }
    // eslint-disable-next-line
  }, [firstRowHeaders])

  // On switch change
  const handleSwitchChange = () => {
    setChecked(!checked)
    let fields = [...mappedFields]
    let fieldExists = mappedFields.find(f => f.key === field.value)
    if(fieldExists) {
      fields = mappedFields.map(f => f.key === field.value ? {...fieldExists, active: !checked} : f)
    }else {
      fields.push({ key: field.value, active: !checked })
    }
    onSetMappedFields(fields)
    if(custom) {
      if(!checked) {
        if(!customColumnsToSave.hasOwnProperty(field.value)) {
          onSetCustomColumnsToSave({...customColumnsToSave, [field.value]: field.label})
        }
      }else {
        if(customColumnsToSave.hasOwnProperty(field.value)) {
          let newCustomColumns = {...customColumnsToSave}
          delete newCustomColumns[field.value]
          onSetCustomColumnsToSave(newCustomColumns)
        }
      }
    }
  }

  // On cancel click
  const handleCancelClick = () => {
    setHideDropdown(true)
    setTimeout(() => setHideDropdown(false), 50)
    setSearch('')
    setFilteredVariables(variables)
  }

  // On search change
  const handleSearchChange = (e) => {
    const { value } = e.target
    setSearch(value)
    if(value.trim()) {
      let processedValue = value.trim().toLowerCase()
      let filtered = []
      variables.forEach(v => {
        if(v.variable.toLowerCase().includes(processedValue)) {
          filtered.push(v)
        }
      })
      setFilteredVariables(filtered)
    }else {
      setFilteredVariables(variables)
    }
  }

  // On variable select
  const handleVariableSelect = (e, v) => {
    e.preventDefault()
    setSelectedVariable(v)
    setSearch('')
    setFilteredVariables(variables)
    setHideVarsDropdown(true)
    setTimeout(() => setHideVarsDropdown(false), 50)
  }

  // On csv column mapping
  const handleCsvColumnMapping = (e, col, index) => {
    e.preventDefault()
    setSelectedCol(firstRowHeaders ? col : `${t('contacts.column')} ${index + 1}`)
    let fields = [...mappedFields]
    let fieldExists = mappedFields.find(f => f.key === field.value)
    if(fieldExists) {
      fields = mappedFields.map(f => f.key === field.value ? {...fieldExists, column: index, active: true} : f)
    }else {
      fields.push({ key: field.value, column: index, active: true })
    }
    onSetMappedFields(fields)
    document.body.click()
  }

  // On link variable
  const handleLinkVariable = () => {
    if(selectedVariable === t('contacts.select_variable')) return 

    let linkedVars = {...linkedVariables}
    linkedVars[field.value] = selectedVariable
    onSetLinkedVariables(linkedVars)
    console.log(linkedVars)
    document.body.click()
  }

  // On unlink variable
  const handleRemoveLinkedVariable = () => {
    let linkedVars = {...linkedVariables}
    delete linkedVars[field.value] 
    onSetLinkedVariables(linkedVars)
    setSelectedVariable(t('contacts.select_variable'))
    document.body.click()
  }

  // On custom label change
  const handleCustomLabelChange = (e) => {
    const { value } = e.target 
    setCustomLabel(value)
  }

  // On custom label save
  const handleCustomLabelSave = () => {
    const find = customCsvColumns.find(f => f.value === field.value)
    if(find) {
      find.label = customLabel.trim()
      onSetCustomColumnsToSave({ ...customColumnsToSave, [field.value]: customLabel.trim() })
      onSetCustomCsvColumns(customCsvColumns.map(f => f.value === field.value ? find : f))
    }
    document.body.click()
  }

  return (
    <div className={`field-mapping-v2 ${className || ''}`}>
      <div className="field-mapping-v2__main">
        <div className={`field-mapping-v2__main_body ${checked ? 'active' : ''}`}>
          <div className="switch-overlay" onClick={handleSwitchChange}></div>
          <Switch checked={checked} onChange={() => setChecked(!checked)} small />
          <p>{field.label}</p>
          {custom && (
            <div className="edit-custom-field">
              <ActionsDropdown
                trigger={<button className="edit-custom-field__btn"><span className="material-symbols-outlined">edit</span></button>}
                headTitle={t('contacts.change_field_name')}
                dropdownClass="edit-custom-field-dropdown"
                width={180}
              >
                <div>
                  <input type="text" value={customLabel} onChange={handleCustomLabelChange} />
                </div>
                <div>
                  <button className="btn btn--small" onClick={handleCustomLabelSave} disabled={!(customLabel.trim())}>{t('general.save')}</button>
                </div>
              </ActionsDropdown>
            </div>
          )}
        </div>
        <div className="field-mapping-v2__main_link-variable">
          <ActionsDropdown
            trigger={<a href="/#" onClick={(e) => e.preventDefault()}>{t('contacts.link_to_variable')}</a>}
            hide={hideDropdown}
            hideHeader
          >
            <div className="link-variable-section">
              <div className="link-variable-section__body">
                <ActionsDropdown
                  trigger={
                    <a href="/#" onClick={(e) => e.preventDefault()} className="trigger-btn">
                      <span className="text">{selectedVariable}</span> <span className="icon"><span className="material-symbols-outlined">arrow_drop_down</span></span>
                    </a>
                  }
                  hide={hideVarsDropdown}
                  hideHeader
                >
                  <div className="link-variable-search">
                    <input placeholder={t('dashboard.search')} value={search} onChange={handleSearchChange} />
                  </div>
                  <div className="link-variable-list u-custom-scrollbar">
                    <ul>
                      {filteredVariables.map((v, idx) => (
                        <li key={idx}>
                          <div className="link link--small">
                            <a href="/#" onClick={(e) => handleVariableSelect(e, v.variable)}>{v.variable}</a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </ActionsDropdown>
              </div>
              <div className="link-variable-section__foot">
                <button className="btn btn--small btn--outline cancel-btn" onClick={handleCancelClick}>{t('general.cancel')}</button>
                <button className="btn btn--small" onClick={handleLinkVariable} disabled={selectedVariable === t('contacts.select_variable')}>{t('contacts.link_variable')}</button>
                {linkedVariables[field.value] && <button className="btn btn--danger remove-btn" onClick={handleRemoveLinkedVariable}>{t('contacts.unlink_variable')}</button>}
              </div>
            </div>
          </ActionsDropdown>
        </div>
      </div>
      {checked && <div className="field-mapping-v2__csv">
        <div className="arrow">
          <span className="material-symbols-outlined">arrow_right_alt</span>
        </div>
        <ActionsDropdown
          trigger={<button className="group-btn">{selectedCol} <span className="icon"><span className="material-symbols-outlined">arrow_drop_down</span></span></button>}
          hideHeader
        >
          <ul>
            {columns.map((col, idx) => {
              return (
                <li key={idx}>
                  <div className="link link--small">
                    <a href="/#" onClick={(e) => handleCsvColumnMapping(e, col, idx)}>
                      {firstRowHeaders ? col : `${t('contacts.column')} ${idx + 1}`}
                    </a>
                  </div>
                </li>
              )
            })}
          </ul>
        </ActionsDropdown>
      </div>}
    </div>
  )
}

export default FieldMapping
import { useState, useContext, useRef, useEffect } from 'react'
import { Mail, Lock, Security } from '@material-ui/icons'
import { Link } from 'react-router-dom'

import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import Loader from '../../UI/Loader'
import Checkbox from '../../UI/Checkbox'
import ResponseLoader from '../../UI/ResponseLoader'
import { GlobalContext, NotificationContext, TeamContext, UserContext } from '../../../context'
import { useForm } from '../../../hooks'
import firebase from '../../../services/firebase'
import { errorMessage } from '../../../i18n'
import { free_sign_up } from '../../../services/auth'
import { saveSelectedTeamToLS } from '../../../utils'

const AuthModal = ({ onClose, mode = 'login', onOpenRegisterModal, onOpenLoginModal, setShowNotLoggedInModal }) => {
  const { t, validate, selectedLang } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { setUser } = useContext(UserContext)
  const { setSelectedTeam, fetchTeams } = useContext(TeamContext)
  const loginInitialData = {
    email: {
      email: true,
      value: ''
    },
    password: {
      required: true,
      value: ''
    }
  }
  const registerInitialData = {
    firstName: {
      required: true,
      value: ''
    },
    lastName: {
      required: true,
      value: ''
    },
    email: {
      email: true,
      required: true,
      value: ''
    },
    password: {
      required: true,
      minLength: 8,
      minLengthMessage: t('notification.password_validation'),
      value: ''
    },
    validationPassword: {
      required: true,
      matchWith: 'password',
      matchWithMessage: t('error.password_mismatch'),
      value: ''
    },
    terms: {
      required: true,
      value: false
    },
    privacy: {
      required: true,
      value: false
    }
  }
  const { formData, errors, setErrors, changeHandler } = useForm(loginInitialData, validate)
  const { formData: formDataRegister, errors: errorsRegister, setErrors: setErrorsRegister, changeHandler: changeHandlerRegister } = useForm(registerInitialData, validate)
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uiStatus, setUiStatus] = useState('login')
  const [multiFactorResolver, setMultiFactorResolver] = useState(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [verificationCodeExpired, setVerificationCodeExpired] = useState(false)
  const [phoneAuthProvider, setPhoneAuthProvider] = useState(null)
  const recaptchaVerifierRef = useRef(null)

  useEffect(() => {
    if(multiFactorResolver) {
      setupVerification()
    }
    // eslint-disable-next-line
  }, [multiFactorResolver])

  // Setup verification
  const setupVerification = async () => {
    setUiStatus('captcha')
    try {
      var phoneInfoOptions = {
        multiFactorHint: multiFactorResolver.hints[0],
        session: multiFactorResolver.session
      };
      let provider
      if(phoneAuthProvider) {
        provider = phoneAuthProvider
      } else {
        provider = new firebase.auth.PhoneAuthProvider()
      }
      setPhoneAuthProvider(provider)
      // Send SMS verification code.
      // provider.
      let vId = await provider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current)        
      // verificationId will be needed for enrollment completion.
      console.log('verificationId', vId)
      setVerificationId(vId)
      setUiStatus('verification_code')

    } catch(err) {
      console.log(err)
      setNotification({ msg: err.message || err.code,  type: 'danger' })
      setUiStatus('login')
    }    
  }

  // Setup captcha on mount
  useEffect(() => {
    setupCaptchaComponent()
  }, [])

  // Setup captcha
  const setupCaptchaComponent = async () => {
    recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal'
    })
    const widgetId = await recaptchaVerifierRef.current.render()    
    window.recaptchaWidgetId = widgetId
  }

  // Resend code
  const handleResendCode = () => {
    setVerificationCodeExpired(false)
    setUiStatus('captcha')
    return handleSubmit()
  }

  // Verify code
  const verifyCode = async () => {
    var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
    multiFactorResolver.resolveSignIn(multiFactorAssertion)
    .then(function(userCredential) {
      console.log(userCredential)
    }).catch(err => {
      console.log(err)
      setNotification({ msg: errorMessage(err, t), type: 'danger' })
      if(err.code === 'auth/code-expired') {
        setVerificationCodeExpired(true)
      }
    })
  }

  // On keep me logged in change
  const handleKeepMeLoggedIn = (e) => {
    setKeepMeLoggedIn(e.target.checked)
  }

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault()
    if(mode === 'login') {
      handleLogin()
    }else if(mode === 'register') {
      handleRegister()
    }
  }

  // On login
  const handleLogin = async () => {
    const formErrors = validate(formData, true)
    setErrors(formErrors)
    if(Object.keys(formErrors).length === 0) {
      setLoading(true)
      try {
        await firebase.auth().setPersistence(keepMeLoggedIn ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
        await firebase.auth().signInWithEmailAndPassword(formData.email.value, formData.password.value)
        setLoading(false)
        onClose()
        setShowNotLoggedInModal(false)
      } catch (err) {
        setLoading(false)
        console.log('sign in error', err)
        if(err.code === 'auth/multi-factor-auth-required') {
          setMultiFactorResolver(err.resolver)
        } else if(err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
          setNotification({ msg: t('auth.invalid_credentials'), type: 'danger' })
        }else if(err.code === 'auth/user-disabled') {
          setNotification({ msg: t('auth.user_disabled'), type: 'danger' })
        }else if(err.code === 'auth/too-many-requests') {
          setNotification({ msg: t('auth.too_many_requests'), type: 'danger' })
        } else {
          setNotification({ msg: errorMessage(err), type: 'danger' })
        }
      }
    }
  }

  // On register
  const handleRegister = async () => {
    const newErrors = validate(formDataRegister, true)
    setErrorsRegister(newErrors)

    if (Object.keys(newErrors).length > 0) {
      return
    }

    setLoading(true)

    try {
      const userData = {
        first_name: formDataRegister.firstName.value,
        last_name: formDataRegister.lastName.value,
        email: formDataRegister.email.value,
        terms: formDataRegister.terms.value,
        privacy: formDataRegister.privacy.value
      }
      const res = await free_sign_up(formDataRegister.email.value, formDataRegister.password.value, userData, selectedLang)
      if(!res.success) {
        console.log(res)
        setLoading(false)
        setNotification({ msg: errorMessage(null, t), type: 'danger' })
        return
      }
      await firebase.auth().signInWithEmailAndPassword(formDataRegister.email.value, formDataRegister.password.value)
      setUser({
        first_name: formDataRegister.firstName.value,
        last_name: formDataRegister.lastName.value,
        email: formDataRegister.email.value,
        terms: formDataRegister.terms.value,
        privacy: formDataRegister.privacy.value,
        last_read_notification_timestamp: Date.now(),
        id: res.id
      })
      if(res.teamId) {
        const { teams } = await fetchTeams()
        const teamFound = [...teams].find(tm => tm.id === res.teamId)
        if(teamFound) {
          setSelectedTeam(teamFound)
          saveSelectedTeamToLS(teamFound.id)
        }
      }
      setNotification({ msg: t('auth.free_account_created_successfully'), type: 'success' })
      onClose()
      setShowNotLoggedInModal(false)
    } catch (err) {
      setLoading(false)
      setNotification({ msg: errorMessage(err, t), type: 'danger' })
    }
  }

  // Sign in with google
  const handleSignInWithGoogle = async () => {
    
  }

  // Render login mode
  const renderLoginMode = () => {
    return (
      <>
        {uiStatus === 'login' && (
          <>
            <Input 
              name="email"
              value={formData.email.value}
              onChange={changeHandler}
              placeholder={t('auth.email')}
              error={errors.email}
              iconEl={<Mail />}
              whiteBackground
              iconSmall
              sizeMedium
            />
            <Input 
              type="password"
              name="password"
              value={formData.password.value}
              onChange={changeHandler}
              placeholder={t('auth.password')}
              error={errors.password}
              iconEl={<Lock />}
              whiteBackground
              iconSmall
              sizeMedium
              showEyeIcon
            />
            <Checkbox label={t('auth.keep_me_signed_in')} checked={keepMeLoggedIn} onChange={handleKeepMeLoggedIn} className="mb-10" />
          </>
        )}
        <div className={`${uiStatus !== 'captcha' ? 'visibility-hidden-no-height' : ''}`}>
          <span className="hint">{t('auth.two_factor_auth')}</span>
          <div className="recaptcha-container" ref={recaptchaVerifierRef} id="recaptcha-container"></div>
        </div>
        { uiStatus === 'verification_code' && (
          <div>
            <span className="hint">{t('auth.verification_code_sent')}</span>
            <div className="mt-10">
              <Input 
                type="text"
                name="verification_code"
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
                placeholder={t('auth.verification_code')}
                iconEl={<Security />}
                whiteBackground
                iconSmall
                sizeMedium
              />
            </div>
          </div>
        )}
      </>
    )
  }

  // Render submit button 
  const renderSubmitButton = () => {
    if(mode === 'login') {
      return (
        <>
          {uiStatus === 'login' && (
            <Button type="submit" text={!loading ? t('auth.login') : <Loader small normalWhite />} disabled={loading} primary fullWidth medium />
          )}
          { (uiStatus === 'verification_code'  && verificationCodeExpired) && (
            <div className="mb-10">
              <span className="hint error mr-10">{ t('auth.code_expired') }</span>
              <Button small primary text={t('auth.resend_code')}  onButtonClick={handleResendCode} />
            </div> 
          )}
          { uiStatus === 'verification_code' && (
            <Button onButtonClick={verifyCode} text={t('general.confirm')} disabled={loading} secondaryLight fullWidth medium />
          )}
        </>
      )
    }
    return (
      <Button
        text={!loading ? t('auth.free_registration') : <Loader small normalWhite />}
        type="submit"
        disabled={loading}
        fullWidth
        primary 
        medium
      />
    )
  }

  // Render register mode
  const renderRegisterMode = () => {
    return (
      <>
        <div className="form__group form__group--auth">
          <Input 
            name="firstName"
            value={formDataRegister.firstName.value}
            onChange={changeHandlerRegister}
            error={errorsRegister.firstName}
            placeholder={t('auth.first_name')}
            whiteBackground
            iconSmall
            sizeMedium
          />
          <Input 
            name="lastName"
            value={formDataRegister.lastName.value}
            onChange={changeHandlerRegister}
            error={errorsRegister.lastName}
            placeholder={t('auth.last_name')}
            whiteBackground
            iconSmall
            sizeMedium
          />
        </div>
        <Input
          name="email"
          value={formDataRegister.email.value}
          onChange={changeHandlerRegister}
          error={errorsRegister.email}
          placeholder={t('auth.email')}
          whiteBackground
          iconSmall
          sizeMedium
        />
        <Input
          name="password"
          value={formDataRegister.password.value}
          onChange={changeHandlerRegister}
          error={errorsRegister.password}
          placeholder={t('auth.password')}
          type="password"
          whiteBackground
          iconSmall
          sizeMedium
          showEyeIcon
        />
        <Input
          name="validationPassword"
          value={formDataRegister.validationPassword.value}
          onChange={changeHandlerRegister}
          error={errorsRegister.validationPassword}
          placeholder={t('auth.validation_password')}
          type="password"
          whiteBackground
          iconSmall
          sizeMedium
        />
        <div className="form__group form__group--auth">
          <Checkbox 
            name="terms"
            label={
              <>
                {t('auth.agree_to_terms')}
                &nbsp;
                <a href={"assets/CGU_FR_CARBON.pdf"} target="_blank" rel="noreferrer">{t('auth.agree_to_terms_link_text')}</a>
              </>
            }
            onChange={changeHandlerRegister}
            checked={formDataRegister.terms.value}
            error={errorsRegister.terms}
            />
        </div>
        <div className="form__group form__group--auth">
          <Checkbox 
            name="privacy"
            label={
              <>
                {t('auth.agree_to_privacy')}
                &nbsp;
                <a href={"assets/Politique_Vie_Privée_FR_CARBON.pdf"} target="_blank" rel="noreferrer">{t('auth.agree_to_privacy_link_text')}</a>
              </>
            }
            onChange={changeHandlerRegister}
            checked={formDataRegister.privacy.value}
            error={errorsRegister.privacy}
          />
        </div>
      </>
    )
  }

  // On open register modal
  const handleOpenRegisterModal = (e) => {
    e.preventDefault()
    onOpenRegisterModal()
  }

  // On open login modal
  const handleOpenLoginModal = (e) => {
    e.preventDefault()
    onOpenLoginModal()
  }

  // go to login page
  const handleGoToLogin = (e) => {
    e.preventDefault()
    setMultiFactorResolver(null)
    setUiStatus('login')
  }

  // Render auth note
  const renderAuthNote = () => {
    if(mode === 'login') {

      return (
        <div className="auth__note">
          <p>{t('auth.dont_have_account')} <a href="/#" className="text-link" onClick={handleOpenRegisterModal}>{t('auth.sign_up_here')}</a></p>
        </div>
      )
    }

    return (
      <div className="auth__note">
        <p>{t('auth.already_have_account')} <a href="/#" className="text-link" onClick={handleOpenLoginModal}>{t('auth.login_here')}</a></p>
      </div>
    )
  }

  return (
    <Modal onClose={onClose} className="auth-modal">
      <div className="auth">
        <div className="auth__box">
          <h2 className="heading-2">
            {mode === 'login' ? t('auth.login_to_carbon') : t('auth.create_free_account')}
          </h2>
          <div className="auth__box_main">
            {mode === 'login' && (
              <div className="auth__box_main-head">
                <p>&nbsp;</p>
                { uiStatus !== 'login'
                  ? (<a href="/#" className="text-link" onClick={handleGoToLogin}>{t('auth.login_2')}</a>) 
                  : (<Link to="/forgot-password" className="text-link">{t('auth.forgot_password')}</Link>)
                }
              </div>
            )}
            <form className="form" onSubmit={handleSubmit}>
              {mode === 'login' ? renderLoginMode() : renderRegisterMode()}
              {renderSubmitButton()}
            </form>
          </div>
          {renderAuthNote()}
        </div>
      </div>

      {loading && <ResponseLoader />}
    </Modal>
  )
}

export default AuthModal 
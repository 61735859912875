import React, { createContext, useContext } from 'react'
import { GlobalContext } from '../'

export const ConstantsContext = createContext()

const ConstantsState = ({ children }) => {
  const { t } = useContext(GlobalContext)
  const STATUS_LABELS = {
    'empty': '1', // to add at the top when sorting order is ASC, it wont be shown on page
    'ready': t('status.ready'),
    'draft': t('status.draft'),
    'in-progress': t('status.in_progress'),
    'closed': t('status.closed'),
    'expired': t('status.expired'),
    'canceled': t('status.canceled'),
    'validated': t('status.validated'),
    'to_validate': t('status.to_validate'),
  }
  const STATUS_LABELS_SHORT = {
    'draft': t('status.draft'),
    'to_validate': t('status.to_validate'),
    'validated': t('status.validated'),
    'ready': t('status.ready'),
  }
  const ACTION_LABELS = {
    'document_update': t('action.updated_document'),
    'document_delete': t('action.deleted_document'),
    'document_create': t('action.created_document'),
    'document_restore': t('action.restored_document'),
    'document_upload': t('action.uploaded_document'),
    'task_update': t('action.updated_task'),
    'task_delete': t('action.deleted_task'),
    'task_create': t('action.created_task'),
    'alert_update': t('action.updated_alert'),
    'alert_delete': t('action.deleted_alert'),
    'alert_create': t('action.created_alert'),
    'contact_update': t('action.updated_contact'),
    'contact_delete': t('action.deleted_contact'),
    'contact_create': t('action.created_contact'),
    'template_update': t('action.updated_template'),
    'template_delete': t('action.deleted_template'),
    'template_create': t('action.created_template'),
    'signature_create': t('action.created_signature'),
    'comment_create': t('action.created_comment')
  }
  const SIGNATURE_STATUSES = {
    'ok': t('status.signed'),
    'attente': t('status.not_signed')
  }
  const SIGNATURE_STATUS_PROVIDERS = {
    'COMPLETED': t('status.signed'),
    'SIGNING_PENDING': t('status.waiting'),
    'ARCHIVED': t('status.archived'),
    'DECLINED': t('status.declined'),
    'EXPIRED': t('status.expired_2'),
    'OPTED_OUT': t('status.opted_out'),
    // 'CANCELED': t('status.canceled_2'),
    // 'INACTIVE': t('status.inactive'),
    'DRAFT': t('status.draft'),
    // 'SENT': t('status.sent'),
  }
  const LANGUAGES = [
    { key: 'FR_French', value: t('lang.FR_French') },
    { key: 'EN_English', value: t('lang.EN_English') },
    { key: 'NL_Dutch', value: t('lang.NL_Dutch') },
    { key: 'AF_Afrikanns', value: t('lang.AF_Afrikanns') },
    { key: 'SQ_Albanian', value: t('lang.SQ_Albanian') },
    { key: 'AR_Arabic', value: t('lang.AR_Arabic') },
    { key: 'HY_Armenian', value: t('lang.HY_Armenian') },
    { key: 'EU_Basque', value: t('lang.EU_Basque') },
    { key: 'BN_Bengali', value: t('lang.BN_Bengali') },
    { key: 'BG_Bulgarian', value: t('lang.BG_Bulgarian') },
    { key: 'CA_Catalan ', value: t('lang.CA_Catalan') },
    { key: 'KM_Cambodian', value: t('lang.KM_Cambodian') },
    { key: 'ZH_Chinese_(Mandarin)', value: t('lang.ZH_Chinese_(Mandarin)') },
    { key: 'HR_Croatian', value: t('lang.HR_Croatian') },
    { key: 'CS_Czech', value: t('lang.CS_Czech') },
    { key: 'DA_Danish', value: t('lang.DA_Danish') },
    { key: 'ET_Estonian', value: t('lang.ET_Estonian') },
    { key: 'FJ_Fiji', value: t('lang.FJ_Fiji') },
    { key: 'FI_Finnish', value: t('lang.FI_Finnish') },
    { key: 'KA_Georgian', value: t('lang.KA_Georgian') },
    { key: 'DE_German', value: t('lang.DE_German') },
    { key: 'EL_Greek', value: t('lang.EL_Greek') },
    { key: 'GU_Gujarati', value: t('lang.GU_Gujarati') },
    { key: 'HE_Hebrew', value: t('lang.HE_Hebrew') },
    { key: 'HI_Hindi', value: t('lang.HI_Hindi') },
    { key: 'HU_Hungarian', value: t('lang.HU_Hungarian') },
    { key: 'IS_Icelandic', value: t('lang.IS_Icelandic') },
    { key: 'ID_Indonesian', value: t('lang.ID_Indonesian') },
    { key: 'GA_Irish', value: t('lang.GA_Irish') },
    { key: 'IT_Italian', value: t('lang.IT_Italian') },
    { key: 'JA_Japanese', value: t('lang.JA_Japanese') },
    { key: 'JW_Javanese', value: t('lang.JW_Javanese') },
    { key: 'KO_Korean', value: t('lang.KO_Korean') },
    { key: 'LA_Latin', value: t('lang.LA_Latin') },
    { key: 'LV_Latvian', value: t('lang.LV_Latvian') },
    { key: 'LT_Lithuanian', value: t('lang.LT_Lithuanian') },
    { key: 'MK_Macedonian', value: t('lang.MK_Macedonian') },
    { key: 'MS_Malay', value: t('lang.MS_Malay') },
    { key: 'ML_Malayalam', value: t('lang.ML_Malayalam') },
    { key: 'MT_Maltese', value: t('lang.MT_Maltese') },
    { key: 'MI_Maori', value: t('lang.MI_Maori') },
    { key: 'MR_Marathi', value: t('lang.MR_Marathi') },
    { key: 'MN_Mongolian', value: t('lang.MN_Mongolian') },
    { key: 'NE_Nepali', value: t('lang.NE_Nepali') },
    { key: 'NO_Norwegian', value: t('lang.NO_Norwegian') },
    { key: 'FA_Persian', value: t('lang.FA_Persian') },
    { key: 'PL_Polish', value: t('lang.PL_Polish') },
    { key: 'PT_Portuguese', value: t('lang.PT_Portuguese') },
    { key: 'PA_Punjabi', value: t('lang.PA_Punjabi') },
    { key: 'QU_Quechua', value: t('lang.QU_Quechua') },
    { key: 'RO_Romanian', value: t('lang.RO_Romanian') },
    { key: 'RU_Russian', value: t('lang.RU_Russian') },
    { key: 'SM_Samoan', value: t('lang.SM_Samoan') },
    { key: 'SR_Serbian', value: t('lang.SR_Serbian') },
    { key: 'SK_Slovak', value: t('lang.SK_Slovak') },
    { key: 'SL_Slovenian', value: t('lang.SL_Slovenian') },
    { key: 'ES_Spanish', value: t('lang.ES_Spanish') },
    { key: 'SW_Swahili', value: t('lang.SW_Swahili') },
    { key: 'SV_Swedish', value: t('lang.SV_Swedish') },
    { key: 'TA_Tamil', value: t('lang.TA_Tamil') },
    { key: 'TT_Tatar', value: t('lang.TT_Tatar') },
    { key: 'TE_Telugu', value: t('lang.TE_Telugu') },
    { key: 'TH_Thai', value: t('lang.TH_Thai') },
    { key: 'BO_Tibetan', value: t('lang.BO_Tibetan') },
    { key: 'TO_Tonga', value: t('lang.TO_Tonga') },
    { key: 'TR_Turkish', value: t('lang.TR_Turkish') },
    { key: 'UK_Ukranian', value: t('lang.UK_Ukranian') },
    { key: 'UR_Urdu', value: t('lang.UR_Urdu') },
    { key: 'UZ_Uzbek', value: t('lang.UZ_Uzbek') },
    { key: 'VI_Vietnamese', value: t('lang.VI_Vietnamese') },
    { key: 'CY_Welsh', value: t('lang.CY_Welsh') },
    { key: 'XH_Xhosa', value: t('lang.XH_Xhosa') },
  ];
  const DOCUMENT_TYPES_OBJ = {
    'family_law': t('categories.family_law'),
    'youth_law': t('categories.youth_law'),
    'lease_law': t('categories.lease_law'),
    'real_rights': t('categories.real_rights'),
    'insurance_law': t('categories.insurance_law'),
    'construction_law': t('categories.construction_law'),
    'judicial_law': t('categories.judicial_law'),
    'corporate_law': t('categories.corporate_law'),
    'gdpr': t('categories.gdpr'),
    'mediations': t('categories.mediations'),
    'trade_law': t('categories.trade_law'),
    'banking_and_financial_law': t('categories.banking_and_financial_law'),
    'competition_law': t('categories.competition_law'),
    'intellectual_rights': t('categories.intellectual_rights'),
    'social_right': t('categories.social_right'),
    'fiscal_law': t('categories.fiscal_law'),
    'criminal_law': t('categories.criminal_law'),
    'public_law': t('categories.public_law'),
    'administrative_law_and_town_planning': t('categories.administrative_law_and_town_planning'),
    'right_of_foreigners': t('categories.right_of_foreigners'),
    'international_law': t('categories.international_law'),
    'european_law': t('categories.european_law'),
    'law_of_new_technologies': t('categories.law_of_new_technologies'),
    'medical_law': t('categories.medical_law'),
    'sport_law': t('categories.sport_law'),
    'other': t('categories.other'),
  };
  const DOCUMENT_TYPES = [
    { key: 'family_law', value: t('categories.family_law') },
    { key: 'youth_law', value: t('categories.youth_law') },
    { key: 'lease_law', value: t('categories.lease_law') },
    { key: 'real_rights', value: t('categories.real_rights') },
    { key: 'insurance_law', value: t('categories.insurance_law') },
    { key: 'construction_law', value: t('categories.construction_law') },
    { key: 'judicial_law', value: t('categories.judicial_law') },
    { key: 'corporate_law', value: t('categories.corporate_law') },
    { key: 'gdpr', value: t('categories.gdpr') },
    { key: 'mediations', value: t('categories.mediations') },
    { key: 'trade_law', value: t('categories.trade_law') },
    { key: 'banking_and_financial_law', value: t('categories.banking_and_financial_law') },
    { key: 'competition_law', value: t('categories.competition_law') },
    { key: 'intellectual_rights', value: t('categories.intellectual_rights') },
    { key: 'social_right', value: t('categories.social_right') },
    { key: 'fiscal_law', value: t('categories.fiscal_law') },
    { key: 'criminal_law', value: t('categories.criminal_law') },
    { key: 'public_law', value: t('categories.public_law') },
    {
      key: 'administrative_law_and_town_planning',
      value: t('categories.administrative_law_and_town_planning'),
    },
    { key: 'right_of_foreigners', value: t('categories.right_of_foreigners') },
    { key: 'international_law', value: t('categories.international_law') },
    { key: 'european_law', value: t('categories.european_law') },
    { key: 'law_of_new_technologies', value: t('categories.law_of_new_technologies') },
    { key: 'medical_law', value: t('categories.medical_law') },
    { key: 'sport_law', value: t('categories.sport_law') },
    { key: 'other', value: t('categories.other') },
  ];
  const GROUP_OF_CONTACTS = [
    { value: 'notary', label: t('contacts.notary') },
    { value: 'client', label: t('contacts.client') },
  ]
  const DEFAULT_CONTACT_FIELDS = [
    { value: 'email', label: t('contacts.email') },
    { value: 'last_name', label: t('contacts.last_name') },
    { value: 'first_name', label: t('contacts.first_name') },
    { value: 'title', label: t('contacts.title') },
    { value: 'address', label: t('contacts.address') },
    { value: 'street_number', label: t('contacts.street_number') },
    { value: 'zip_code', label: t('contacts.postcode') },
    { value: 'city', label: t('contacts.city') },
    { value: 'country', label: t('contacts.country') },
    { value: 'phone_number', label: t('contacts.phone_number') },
    { value: 'dob', label: t('contacts.dob') },
    { value: 'nationality', label: t('contacts.nationality') },
    { value: 'website', label: t('contacts.website') },
  ]

  return (
    <ConstantsContext.Provider
      value={{
        STATUS_LABELS,
        STATUS_LABELS_SHORT,
        ACTION_LABELS,
        SIGNATURE_STATUSES,
        SIGNATURE_STATUS_PROVIDERS,
        LANGUAGES,
        DOCUMENT_TYPES_OBJ,
        DOCUMENT_TYPES,
        GROUP_OF_CONTACTS,
        DEFAULT_CONTACT_FIELDS
      }}
    >
      { children }
    </ConstantsContext.Provider>
  )
}

export default ConstantsState
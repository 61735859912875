import React, { useState, useRef, useContext, useEffect, useCallback } from 'react'
import BugReportIcon from '@material-ui/icons/BugReport'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import Modal from '../../UI/Modal'
import Textarea from '../../UI/Textarea'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import ResponseLoader from '../../UI/ResponseLoader'
import { NotificationContext, UserContext, GlobalContext } from '../../../context'
import { validateURL } from '../../../helpers/validate'
import { getFileData } from '../../../helpers/files'
import { base64toBlob, blobToFile } from '../../../utils'
import { get_file_from_external_url } from '../../../services/firestore'
import { bug_report } from '../../../services/functions'

const ReportABugModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { user } = useContext(UserContext)
  const [bugMsg, setBugMsg] = useState('')
  const [uploadFromView, setUploadFromView] = useState('computer')
  const [url, setUrl] = useState('')
  const [files, setFiles] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const fileEl = useRef()
  const dragAndDropEl = useRef()

  // Change view
  const changeViewHandler = (e, view) => {
    e.preventDefault()
    setUploadFromView(view)
    setFiles([])
    setUrl('')
  }

  // On file input change
  const fileChangeHandler = (e) => {
    const files = e.target.files 
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    let error = false
    Array.from(files).forEach(file => {
      if(!allowedTypes.includes(file.type)) {
        error = true
      }
    })
    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
    }
    setFiles(Array.from(files))
  }

  // On upload image button click
  const uploadImgBtnClickHandler = () => {
    if(fileEl.current) {
      fileEl.current.click()
    }
  }

  // On drag enter
  const dragEnterHandler = (e) => {
    e.preventDefault()
  }
  
  // On drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.remove('mouse-over')
  }
  
  // On drag over
  const dragOverHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.add('mouse-over')
 
  }

  // On drop
  const dropHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.remove('mouse-over')
    const files = e.dataTransfer.files
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    let error = false
    Array.from(files).forEach(file => {
      if(!allowedTypes.includes(file.type)) {
        error = true
      }
    })
    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
    }
    setFiles(Array.from(files))
    setFiles(Array.from(files))
  }

  

  // On submit
  const submitHandler = useCallback(async (e) => {
    e.preventDefault()

    if(bugMsg.trim() === '') {
      return 
    }

    // validate url if upload view is url
    if(uploadFromView === 'url' && url.trim() !== '' && !validateURL(url.trim())) {
      return setNotification({ msg: t('notification.invalid_url'), type: 'danger' })
    }

    let data = []

    setShowLoader(true)
    if(url.trim() !== '') {
      const res = await get_file_from_external_url({ url, getType: true })
      if(!res.success) {
        setUrl('')
        if(res.type === 'file-too-big') {
          return setNotification({ msg: t('notification.file_too_big'), type: 'danger' })
        }
        return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }

      const blob = base64toBlob(res.data)
      let fileName = `file-${Date.now()}`
      let fileType = ''
      let fileExt = ''
      if(res.file_type) {
        fileExt = res.file_type.ext
        fileName = `${fileName}.${fileExt}`
        fileType = res.file_type.mime
      }else {
        return setNotification({ msg: t('notification.no_file_extension'), type: 'danger' })
      }

      const allowedTypes = ['png', 'jpeg', 'jpg', 'gif']
      if(!allowedTypes.includes(fileExt)) {
        return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      }

      const file = blobToFile(blob, fileName)
      const fileData = await getFileData(file, fileType)
      data = [fileData]
    }else {
      // promises array for files
      let filesData = []
      files.forEach(file => filesData.push(getFileData(file)))
      data = await Promise.all(filesData)
    }

    let content = bugMsg.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')

    // email html template
    let emailHtml = `
      <div>${content}</div>
    `

    try {
      await bug_report(user.email, emailHtml, 'Carbon Bug Report', data.length > 0 ? data : [])
    } catch (err) {
      console.log(err)
    }
    setShowLoader(false)
    setNotification({ msg: t('notification.message_sent'), type: 'success' })
    onClose()
  }, [bugMsg, files, onClose, setNotification, t, uploadFromView, url, user])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        submitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [submitHandler])

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><BugReportIcon /> {t('support.report_bug')}</h3>
        </div>
        <div className="report-a-bug__body">
          <form onSubmit={submitHandler}>
            <Textarea label={t('support.describe_bug')} value={bugMsg} onChange={(e) => setBugMsg(e.target.value)} formEl />
            <div className="image-wrapper">
              <div className="image-wrapper__left">
                <p>{t('support.insert_image_from')}</p>
                <ul>
                  <li>
                    <a href="/#" className={uploadFromView === 'computer' ? 'active' : ''} onClick={(e) => changeViewHandler(e, 'computer')}>
                      {t('support.my_computer')}
                    </a>
                  </li>
                  <li>
                    <a href="/#" className={uploadFromView === 'url' ? 'active' : ''} onClick={(e) => changeViewHandler(e, 'url')}>
                      {t('support.from_url')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="image-wrapper__right">
                {uploadFromView === 'computer'
                  ?
                  <div 
                    className="image-wrapper__right_dnd" 
                    ref={dragAndDropEl}
                    onDragEnter={dragEnterHandler}
                    onDragLeave={dragLeaveHandler}
                    onDragOver={dragOverHandler}
                    onDrop={dropHandler}
                  >
                    <input type="file" className="hidden" onChange={fileChangeHandler} multiple ref={fileEl} />
                    <Button text={t('support.upload_images')} icon={<CloudUploadIcon />} onButtonClick={uploadImgBtnClickHandler} primary />
                    <p>{t('support.or_dnd_image')} {files.length > 0 && `(${files.length} ${t('support.images_selected')})`}</p>
                    {files.length > 0 && <p><a href="/#" onClick={(e) => { e.preventDefault(); setFiles([])}}>{t('support.remove_images')}</a></p>}
                  </div>
                  :
                  <div className="image-wrapper__right_url">
                    <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder={t('support.insert_url_here')} whiteBackground />
                  </div>
                }
              </div>
            </div>
              
            <div className="btn-wrapper">
              <Button type="submit" text={t('support.send_bug_report')} primary medium disabled={bugMsg.trim() === ''} />
            </div>
          </form>
        </div>
      </div>

      {showLoader && <ResponseLoader text={t('support.sending_message')} />}
    </Modal>
  )
} 

export default ReportABugModal
import React, { useState, useEffect, useContext } from 'react';
import ClearIcon from '@material-ui/icons/Clear';

import { GlobalContext } from '../../context'
import { validateEmail } from '../../helpers/validate';

const TagsInput = ({ label, id, name, placeholder, error, onChange, defaultTags, large, emailValidation, editLink, onEditLinkClick, showSuggestions, suggestions = [], thickBorder, invalid, className, onFocus, onBlur }) => {
  const { t } = useContext(GlobalContext)
  const [value, setValue] = useState('');
  const [tags, setTags] = useState(defaultTags ? defaultTags : []);
  const [isActive, setIsActive] = useState(false);
  const [suggestionsArr, setSuggestionsArr] = useState([...suggestions].filter(s => !tags.find(t => t.id === s.id)));

  useEffect(() => {
    if(showSuggestions) {
      onChange(name, defaultTags);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  // Change handler
  const changeHandler = (e) => {
    setValue(e.target.value);
    onChange(name, tags);
    if(showSuggestions) {
      setSuggestionsArr(prev => {
        if(e.target.value.trim() !== '') {
          return [...suggestions].filter(s => !tags.find(t => t.id === s.id) && s.name?.includes(e.target.value.trim()))
        }else {
          return [...suggestions].filter(s => !tags.find(t => t.id === s.id));
        }
      });
    }
  }

  // Remove tag
  const removeTag = (tag) => {
    const arr = tags.filter(t => showSuggestions ? t.name !== tag.name : t !== tag);
    setTags(arr);
    onChange(name, arr);
  }

  // Update tags
  const updateTagsHandler = (e) => {
    e.preventDefault();

    // Add tags if input is not empty and if input value is not comma
    if(e.target.value !== '' && e.target.value !== ',') {

      if(e.key === ',') {

        let newTag = showSuggestions ? { name: value.trim().split(',')[0] } : value.trim().split(',')[0];
        if(showSuggestions) {
          const findS = suggestions.find(s => s.name === newTag.name);
          if(findS) {
            newTag = findS;
          }
        }
        if(emailValidation) {
          let check = showSuggestions ? !tags.find(t => t.name === newTag.name) && newTag.name !== '' && validateEmail(newTag) : !tags.includes(newTag) && newTag !== '' && validateEmail(newTag);
          if(check) {
            const arr = [...tags, newTag];
            setTags(arr);
            onChange(name, arr);
          }
        }else {
          let check = showSuggestions ? !tags.find(t => t.name === newTag.name) && newTag.name !== '' : !tags.includes(newTag) && newTag !== '';
          if(check) {
            const arr = [...tags, newTag];
            setTags(arr);
            onChange(name, arr);
          }
        }
        setValue('');

      } else if(e.key === 'Enter') {

        let newTag = showSuggestions ? { name: value.trim() } : value.trim();
        if(showSuggestions) {
          const findS = suggestions.find(s => s.name === newTag.name);
          if(findS) {
            newTag = findS;
          }
        }
        if(emailValidation) {
          let check = showSuggestions ? !tags.find(t => t.name === newTag.name) && newTag.name !== '' && validateEmail(newTag) : !tags.includes(newTag) && newTag !== '' && validateEmail(newTag);
          if(check) {
            const arr = [...tags, newTag];
            setTags(arr);
            onChange(name, arr);
          }
        }else {
          let check = showSuggestions ? !tags.find(t => t.name === newTag.name) && newTag.name !== '' : !tags.includes(newTag) && newTag !== '';
          if(check) {
            const arr = [...tags, newTag];
            setTags(arr);
            onChange(name, arr);
          }
        }
        setValue('');

      }

    }

    // Remove tags if backspace is pressed
    // if(e.key === 'Backspace' && tags.length > 0 && value.length === 0) {
    //   const copyOfTags = [...tags];
    //   copyOfTags.pop();
    //   setTags(copyOfTags);
    //   onChange(name, copyOfTags);
    // }
  }

  const focusHandler = () => {
    setIsActive(true);
    if(onFocus) onFocus()
  }
  
  const blurHandler = () => {
    setIsActive(false);
    if(onBlur) onBlur()
  }

  // Suggestion click handler
  const suggestionClickHandler = (suggestion) => {
    onChange(name, [...tags, suggestion]);
    setTags(prev => ([...prev, suggestion]));
    setValue('');
    setSuggestionsArr(prev => [...prev].filter(s => !tags.find(t => t.id === s.id)));
  }

  let tagsClass = 'tags-input';

  if(large) {
    tagsClass += ' tags-input--large'; 
  }
  if(isActive) {
    tagsClass += ' active';
  }
  if(thickBorder) {
    tagsClass += ' tags-input--thick-border';
  }
  if(invalid) {
    tagsClass += ' tags-input--error';
  }
  if(className) {
    tagsClass += ` ${className}`;
  }

  return(
    <div className={tagsClass}>
      {label ? !editLink 
          ? <label htmlFor={id ? id : name}>{label}</label>
          : <div className="tags-input__label-wrap">
            <label htmlFor={id ? id : name}>{label}</label>
            <a href="#/" onClick={(e) => { e.preventDefault(); onEditLinkClick(); }}>({t('general.edit')})</a>
          </div>
        : null
      }
      <div className={isActive ? "tags-input__wrapper active" : "tags-input__wrapper"}>
        <div className="tags-input__tags">
          {tags.map((tag, i) => {
            if(typeof tag === 'string') {
              return <div key={i} className="tag">
                {tag} <span onClick={() => removeTag(tag)}><ClearIcon /></span>
              </div>
            }else {
              return <div key={i} className="tag">
                {tag.name} <span onClick={() => removeTag(tag)}><ClearIcon /></span>
              </div>
            }
          })}
          <input 
            type="text"
            placeholder={placeholder}
            name={name}
            id={id ? id : name}
            value={value}
            onChange={changeHandler}
            autoComplete="off"
            onKeyUp={updateTagsHandler}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />
        </div>
        {showSuggestions && suggestionsArr.length > 0 && value.trim() !== '' && <div className="suggestions">
          <ul className="suggestions__list">
            {suggestionsArr.map((s, i) => (
              <li className="suggestions__list_item" key={i} onClick={() => suggestionClickHandler(s)}>{s.name}</li>
            ))}
          </ul>
        </div>}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default TagsInput;
import React, { useState, useContext, useEffect } from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DoneIcon from '@material-ui/icons/Done';
import { ClickAwayListener } from '@material-ui/core';

import Button from './Button';
import Input from './Input';
import { DocumentsContext, GlobalContext } from '../../context';

const ContractPicker = ({ onDocumentSelected, label, thickBorder, error, selectedDocument, isDocSelected, setIsDocSelected, showIcon, noWrapper, btnStyle2 }) => {
  const { t } = useContext(GlobalContext)
  const [selectDocBtnText, setSelectDocBtnText] = useState(t('general.select_document'));
  const [showContractsDropdown, setShowContractsDropdown] = useState(false);
  const [search, setSearch] = useState('');
  const { documentsArr } = useContext(DocumentsContext);
  const [filteredDocumentsArr, setFilteredDocumentsArr] = useState(documentsArr ? documentsArr : []);
  const [selectedDoc, setSelectedDoc] = useState({});

  // Set selected document from outside
  useEffect(() => {
    if(isDocSelected) {
      setSelectDocBtnText(selectedDocument.name);
      setSelectedDoc(selectedDocument);
      setIsDocSelected(false);
    }
  }, [selectedDocument, isDocSelected, setIsDocSelected]);

  // On documents search
  const searchChangeHandler = (e) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    if(searchVal.trim() !== '') {
      setFilteredDocumentsArr(prevArr => {
        const filteredArr = [];
        prevArr.forEach(doc => {
          if(doc.name.toLowerCase().includes(searchVal.trim())) {
            filteredArr.push(doc);
          }
        });
        return filteredArr;
      });
    }else {
      setFilteredDocumentsArr(documentsArr);
    }
  }

  // On document select 
  const documentSelectHandler = (doc) => {
    if(selectedDoc.id === doc.id) {
      setSelectedDoc({});
      setSelectDocBtnText(t('general.select_document'));
      onDocumentSelected({});
    }else {
      setSelectedDoc(doc);
      setSelectDocBtnText(doc.name);
      onDocumentSelected(doc);
    }
    setShowContractsDropdown(false);
  }

  let contractPickerClass = 'contract-picker';

  if(showContractsDropdown) {
    contractPickerClass += ' active';
  }
  if(thickBorder) {
    contractPickerClass += ' contract-picker--thick-border';
  }
  if(error) {
    contractPickerClass += ' contract-picker--error';
  }
  if(noWrapper) {
    contractPickerClass += ' contract-picker--no-wrapper';
  }
  if(btnStyle2) {
    contractPickerClass += ' contract-picker--btn-style-2';
  }

  return(
    <div className={contractPickerClass}>
      <div className="contract-picker__label">{label}</div>
      <ClickAwayListener onClickAway={() => setShowContractsDropdown(false)}>
        <div className="contract-picker__field">
          <Button 
            icon={showIcon ? <DescriptionOutlinedIcon /> : false} 
            text={selectDocBtnText} 
            onButtonClick={() => setShowContractsDropdown(!showContractsDropdown)} 
            className={`${showContractsDropdown ? 'active' : ''} ${selectedDoc && Object.keys(selectedDoc).length > 0 ? 'selected' : ''}`} 
            small 
            primaryLight2
          />
          {showContractsDropdown && <div className="dropdown-el dropdown-el--contract-picker">
            <div className="dropdown-el--contract-picker_head">
              <Input value={search} onChange={(e) => searchChangeHandler(e)} placeholder={t('general.contract_name_placeholder')} transparent />
            </div>
            <div className="dropdown-el--contract-picker_body">
              <ul>
                {filteredDocumentsArr.map(doc => (
                  <li key={doc.id} className={doc.id === selectedDoc.id ? 'selected' : undefined} onClick={() => documentSelectHandler(doc)}>
                    {doc.name} {doc.id === selectedDoc.id && <DoneIcon />}
                  </li>
                ))}
              </ul>
            </div>
          </div>}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default ContractPicker;
import { useContext, useState } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import CustomTooltip from '../../UI/CustomTooltip'
import { CreateEditVariablesCategoryModal } from '../'
import { GlobalContext, VariablesContext } from '../../../context'

const VariablesSidebar = ({ setMainTitle }) => {
  const { t } = useContext(GlobalContext)
  const { selectedCategory, varsCategories, setVariablesSelectedFilters, setSelectedCategory } = useContext(VariablesContext)
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false)
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)
  const [categoryToEdit, setCategoryToEdit] = useState(null)

  // On category filter
  const handleCategoryFilter = (cat) => {
    if(!cat) {
      setSelectedCategory('all')
      setMainTitle(t('general.all'))
      setVariablesSelectedFilters(prev => ({ ...prev, category: 'all' }))
      return
    }
    setSelectedCategory(cat.id)
    setMainTitle(cat.display_name)
    setVariablesSelectedFilters(prev => ({ ...prev, category: cat.id }))
  }

  // On category create click
  const handleCategoryCreateClick = () => {
    setShowCreateCategoryModal(true)
  }

  // On create category modal close
  const handleCloseCreateCategoryModal = () => {
    setShowCreateCategoryModal(false)
  }

  // On category edit click
  const handleCategoryEditClick = (cat) => {
    setShowEditCategoryModal(true)
    setCategoryToEdit(cat)
    window.document.body.click()
  }

  // On edit category modal close
  const handleCloseEditCategoryModal = () => {
    setShowEditCategoryModal(false)
    setCategoryToEdit(null)
  } 

  return (
    <div className="collection-sidebar">
      <h1 className="heading-1">{t('dashboard.variables')}</h1>

      <section className="folders">
        <h5 className="heading-5">{t('dashboard.categories')}</h5>
        <ul className="filters-list">
          <li className={selectedCategory === 'all' ? 'filters-list__item active' : 'filters-list__item'}>
            <div className="item-inner">
              <p onClick={() => handleCategoryFilter(null)}>{t('general.all_categories')}</p> 
              <CustomTooltip content={t('dashboard.create_category')}>
                <div className="icon-right" onClick={handleCategoryCreateClick}>
                  <span className="material-symbols-outlined">add</span>
                </div>
              </CustomTooltip>
            </div>
          </li>
          {varsCategories.map((cat) => {
            return (
              <VariableCategory 
                key={cat.id}
                cat={cat}
                onCategoryEdit={handleCategoryEditClick}
                onCategoryFilter={handleCategoryFilter}
              />
            )
          })}
        </ul>
      </section>

      {showCreateCategoryModal && (
        <CreateEditVariablesCategoryModal onClose={handleCloseCreateCategoryModal} />
      )}
      {showEditCategoryModal && (
        <CreateEditVariablesCategoryModal onClose={handleCloseEditCategoryModal} mode="edit" data={categoryToEdit} />
      )}
    </div>
  )
}

const VariableCategory = ({ cat, onCategoryFilter, onCategoryEdit }) => {
  const { t } = useContext(GlobalContext)
  const { selectedCategory } = useContext(VariablesContext)
  const [showDropdown, setShowDropdown] = useState(false)

  // Toggle dropdown
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  // Close dropdown
  const handleCloseDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <li className={selectedCategory === cat.id ? "filters-list__item active" : "filters-list__item"} key={cat.id}>
      <ClickAwayListener onClickAway={handleCloseDropdown}>
        <div className="item-inner">
          <p onClick={() => onCategoryFilter(cat)}><span className="name">{cat.display_name}</span></p> 
          <div className="icon-right" onClick={handleToggleDropdown}>
            <span className="material-symbols-outlined">more_horiz</span>
          </div>
          {showDropdown && (
            <div className="dropdown category-actions-dropdown">
              <p onClick={() => onCategoryEdit(cat)}>
                <span><span className="material-symbols-outlined">edit</span></span> {t('dashboard.edit_category')}
              </p>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </li>
  )
}

export default VariablesSidebar
import axios from 'axios'
import { urlSuffixForEnvironment } from '../utils'
import { post_request } from './firestore'
import config from '../config.json'
import firebase from './firebase'
import 'firebase/functions'


// firebase.app().functions('europe-west1').useEmulator('localhost', 5001)

// const baseUrl = `http://localhost:5001/carbon-contract-management/europe-west1/api${urlSuffixForEnvironment(config.environment, config.staging)}`
const baseUrl = `https://europe-west1-carbon-contract-management.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment, config.staging)}`

const sendEmail = async (sender, recipient, subject, text, documentName, documentData, attachments) => {
  return await post_request(`${baseUrl}/send_document_by_email`, {
    sender,
    recipient,
    subject,
    text,
    documentName,
    documentData,
    attachments
  })
}

const sendDocumentByEmail = async (sender, recipient, subject, text, documentName, documentData, attachments) => {
  return await post_request(`${baseUrl}/send_document_by_email`, {
    sender, recipient, subject, text, documentName, documentData, attachments
  })
}

const sendSignatureRequest = async (data) => {
  return await post_request(`${baseUrl}/signature_request`, data);
}

const requestUploadAddress = async (teamId) => {
  const requestFunction = firebase.app().functions('europe-west1').httpsCallable("request_upload_address")
  const res = await requestFunction({
    environment: config.environment,
    team: teamId
  })
  return res.data
}

const teamInvite = async (data, lang) => {
  return await post_request(`${baseUrl}/team_invite`, data, '', lang);
}

const joinTeam = async (data) => {
  return await post_request(`${baseUrl}/join_team`, data);
}

const bug_report = async (from, emailHtml, subject, files) => {
  return await post_request(`${baseUrl}/bug_report`, { from, emailHtml, subject, files })
}

const send_feedback = async (from, emailHtml, subject) => {
  return await post_request(`${baseUrl}/send_feedback`, { from, emailHtml, subject })
}

const get_file_base64_data = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/file_base64`, data)
    return res.data
  } catch (err) {
    console.log('file base64 data error', err)
  }
}

const fetchLawstudioHeaders = async (team) => {
  const data = {}
  if(team) {
    data.team = team
  }
  const fetchFunction = firebase.app().functions('europe-west1').httpsCallable('fetchLawstudioHeaders')
  const res = await fetchFunction(data)
  return res.data
}

export {
  sendEmail,
  sendDocumentByEmail,
  sendSignatureRequest,
  requestUploadAddress,
  teamInvite,
  joinTeam,
  bug_report,
  send_feedback,
  get_file_base64_data,
  fetchLawstudioHeaders
} 
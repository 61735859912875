import React, { useContext } from 'react';

import DocumentSectionContent from './DocumentSectionContent';
import { areSectionConditionsMet } from '../../../utils';
import { GlobalContext } from '../../../context'

const DocumentSection = ({ type, content, items, variable, variables, data_type, question, options, tooltip, condition, conditions, values, onValuesChange, checkboxValues, onCheckboxValuesChange, index, varIndex, repeatableSectionId, onSetEditStartTime, onStoreEditTime, onContactsModalOpen, makeContentEditable, onLinkVarsWithProfileData, onSectionContentChanged, head, row, repeatableRowId }) => {
  const { t } = useContext(GlobalContext)
  let classes = 'variables opened'

  const duplicateSection = () => {
    let vals = values[repeatableSectionId]
    if(!vals) {
      vals = [{}]
    }
    
    vals.push({})
    onValuesChange(repeatableSectionId, vals)
  }

  const duplicatableValuesArray = () => {
    if(values[repeatableSectionId]) {
      return values[repeatableSectionId]
    } else {
      return [{}]
    }
  }

  return !areSectionConditionsMet({ condition, conditions }, values) ? null : (
    <div className={classes} data-index={`section-${index}`} style={{ whiteSpace: 'pre-line' }}>
      <div className="variables__body">
        { Boolean(repeatableSectionId) ? ( 
          <div>
            <span className="u-cursor--pointer" onClick={duplicateSection}>{t('dashboard.duplicate')}</span>
            { duplicatableValuesArray().map((item, ri) => {
              return (
                <DocumentSectionContent key={`repeatable_content_${repeatableSectionId}_${ri}`} values={values} onValuesChange={onValuesChange} checkboxValues={checkboxValues} onCheckboxValuesChange={onCheckboxValuesChange} type={type} content={content} bulletListItems={items} question={question} variable={variable} variables={variables} data_type={data_type} options={options} condition={condition} tooltip={tooltip} index={index} varIndex={varIndex} repeatableSectionId={repeatableSectionId} repeatableIndex={ri} onSetEditStartTime={onSetEditStartTime} onStoreEditTime={onStoreEditTime} onContactsModalOpen={onContactsModalOpen} makeContentEditable={makeContentEditable} onLinkVarsWithProfileData={onLinkVarsWithProfileData} onSectionContentChanged={onSectionContentChanged} head={head} row={row} repeatableRowId={repeatableRowId} />
              )
            })}
          </div>
        ) : (
          <DocumentSectionContent values={values} onValuesChange={onValuesChange} checkboxValues={checkboxValues} onCheckboxValuesChange={onCheckboxValuesChange} type={type} content={content} bulletListItems={items} question={question} variable={variable} variables={variables} data_type={data_type} options={options} condition={condition} tooltip={tooltip} index={index} varIndex={varIndex} onSetEditStartTime={onSetEditStartTime} onStoreEditTime={onStoreEditTime} onContactsModalOpen={onContactsModalOpen} makeContentEditable={makeContentEditable} onLinkVarsWithProfileData={onLinkVarsWithProfileData} onSectionContentChanged={onSectionContentChanged}  head={head} row={row} repeatableRowId={repeatableRowId} />
        ) }
      </div>
    </div>
  );
}

export default DocumentSection;
import React, { createContext, useReducer } from 'react'

import signatureTemplatesReducer from './signatureTemplatesReducer'
import { GET_SIGNATURE_TEMPLATES, SET_SIGNATURE_TEMPLATES, RESET_STATE } from '../types'
import { fetch_signature_templates, create_signature_template, update_signature_template, delete_signature_template } from '../../services/signature_templates'

export const SignatureTemplatesContext = createContext()

const SignatureTemplatesState = ({ children }) => {
  const initialState = {
    signatureTemplates: [],
    signatureTemplatesFetched: false
  }

  const [state, dispatch] = useReducer(signatureTemplatesReducer, initialState)

  // Fetch templates
  const fetch = async (teamId, onSuccess = () => {}) => {
    try {
      const res = await fetch_signature_templates(teamId)
      const arr = []
      for(let id in res) {
        const template = {...res[id]}
        template.id = id
        arr.push(template)
      }
      const sortedArr = [...arr].sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      });
      dispatch({
        type: GET_SIGNATURE_TEMPLATES,
        payload: sortedArr
      });
      onSuccess()
    }catch(err) {
      console.log(err)
    }
  }

  // Create template
  const create = async (data) => {
    try {
      const id = await create_signature_template(data)

      if(state.signatureTemplatesFetched) {
        const template = {...data, id: id}
        const templatesCopy = [...state.signatureTemplates]
        templatesCopy.unshift(template)
        dispatch({
          type: SET_SIGNATURE_TEMPLATES,
          payload: templatesCopy
        })
      }
      return id
    } catch (err) {
      console.log(err)
    }
  }

  // Update template
  const update = async (id, data) => {
    try {
      await update_signature_template(id, data)
      const templates = [...state.signatureTemplates]
      const findT = templates.find(t => t.id === id)
      const updatedT = {...findT, ...data}
      const updatedTemplates = templates.map(t => t.id === id ? updatedT : t)
      dispatch({
        type: SET_SIGNATURE_TEMPLATES,
        payload: updatedTemplates
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Delete template
  const deleteT = async (id) => {
    try {
      await delete_signature_template(id)
      const templates = [...state.signatureTemplates]
      const filtered = templates.filter(t => t.id !== id)
      dispatch({
        type: SET_SIGNATURE_TEMPLATES,
        payload: filtered
      });
    } catch (err) {
      console.log(err)
    }
  } 

  // Reset
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
      payload: initialState,
    })
  }

  return <SignatureTemplatesContext.Provider value={{
    signatureTemplates: state.signatureTemplates,
    signatureTemplatesFetched: state.signatureTemplatesFetched,
    fetchSignatureTemplates: fetch,
    createSignatureTemplate: create,
    updateSignatureTemplate: update,
    deleteSignatureTemplate: deleteT,
    resetSignatureTemplatesState: resetState,
  }}>
    {children}
  </SignatureTemplatesContext.Provider>
}

export default SignatureTemplatesState
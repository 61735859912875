import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons'

const Checkbox = ({ id, name, onChange, checked, checkedAll, label, error, disabled, className }) => {
  let cssClass = 'checkbox-v2'

  if(className) {
    cssClass += ` ${className}`
  }

  return(
    <div className={cssClass}>
      <label htmlFor={id}>
        <input type="checkbox" name={name} id={id} onChange={onChange} checked={checked} disabled={disabled} />
        <div className={checked ? "checkbox-v2__btn checked" : "checkbox-v2__btn unchecked"}>
          {checked ? checkedAll ? <IndeterminateCheckBox /> : <CheckBox /> : <CheckBoxOutlineBlank />}
        </div>
        {label && <div className="checkbox-v2__label">{label}</div>}
      </label>
      {error && <div className="checkbox-v2__error">{error}</div>}
    </div>
  )
}

export default Checkbox
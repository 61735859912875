import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../../context'

const SignaturesNotAvailable = () => {
  const { t } = useContext(GlobalContext)
  
  return (
    <div className="signatures-not-available">
      <h2>{t('dashboard.signatures_2')}</h2>
      <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. </p>
      <Link to="/" target="_blank" className="signatures-not-available__link">{t('general.to_immo_store')}</Link>
    </div>
  );
}

export default SignaturesNotAvailable;
import { useState, useContext } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';

import { GlobalContext } from '../../context';
registerLocale('fr', fr);

const CustomDatepicker = ({
  onDateChange,
  options,
  defaultOption,
  minDate,
  maxDate,
}) => {
  const { t } = useContext(GlobalContext);
  const [selectedDateLabel, setSelectedDateLabel] = useState(
    defaultOption.label
  );
  const [selectedDateValue, setSelectedDateValue] = useState(
    defaultOption.value
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const changeHandler = (date, label) => {
    setSelectedDateLabel(label);
    setSelectedDateValue(date);
    setShowDropdown(false);
    onDateChange([date]);
  };

  const dateRangeSubmitHandler = () => {
    if (startDate && endDate) {
      setSelectedDateLabel(
        `${moment(startDate).format('DD/MM/yyyy')}-${moment(endDate).format(
          'DD/MM/yyyy'
        )}`
      );
      setSelectedDateValue('date_range');
      onDateChange([startDate, endDate]);
      setShowDropdown(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className="custom-datepicker-v2">
        <div
          className="custom-datepicker-v2__selected btn btn--outline btn--small"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span className="material-symbols-outlined size-24">
            calendar_month
          </span>
          <p>{selectedDateLabel}</p>
        </div>
        {showDropdown && (
          <div className="dropdown-el custom-datepicker-v2__dropdown">
            <div className="custom-datepicker-v2__dropdown_head">
              <div className="datepicker-wrapper">
                <DatePicker
                  onChange={(date) => setStartDate(date)}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  popperModifiers={{ preventOverflow: { enabled: true } }}
                  popperPlacement="top-right"
                  dateFormat={'dd/MM/yyyy'}
                  minDate={minDate}
                  maxDate={maxDate}
                  locale={fr}
                  // onChangeRaw={(e) => e.preventDefault()}
                  className={startDate ? 'not-empty' : ''}
                  isClearable={startDate !== ''}
                />
                {!startDate && (
                  <div className="datepicker-wrapper__icon">
                    <span className="material-symbols-outlined size-24">
                      calendar_month
                    </span>
                  </div>
                )}
              </div>
              <div className="datepicker-wrapper">
                <DatePicker
                  onChange={(date) => setEndDate(date)}
                  selected={endDate}
                  minDate={startDate}
                  maxDate={maxDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  popperModifiers={{ preventOverflow: { enabled: true } }}
                  popperPlacement="top-right"
                  dateFormat={'dd/MM/yyyy'}
                  locale={fr}
                  // onChangeRaw={(e) => e.preventDefault()}
                  className={endDate ? 'not-empty' : ''}
                  isClearable={endDate !== ''}
                />
                {!endDate && (
                  <div className="datepicker-wrapper__icon">
                    <span className="material-symbols-outlined size-24">
                      calendar_month
                    </span>
                  </div>
                )}
              </div>
              <button
                className="btn"
                onClick={dateRangeSubmitHandler}
                disabled={
                  startDate === '' ||
                  endDate === '' ||
                  new Date(startDate).getTime() > new Date(endDate).getTime()
                }
              >
                {t('general.apply')}
              </button>
            </div>
            <div className="custom-datepicker-v2__dropdown_body">
              <ul>
                {options.map((opt, i) => (
                  <li
                    className={
                      selectedDateValue === opt.value ? 'active' : undefined
                    }
                    onClick={() => changeHandler(opt.value, opt.label)}
                    key={i}
                  >
                    {opt.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CustomDatepicker;

import { useContext, useState } from 'react'

import CustomTooltip from '../../UI/CustomTooltip'
import { Alert } from '../../new_ui'
import { FolderListItem, CreateEditFolderModal } from '../'
import { GlobalContext, DocumentsContext, FolderContext, NotificationContext } from '../../../context'
import { useTemplatesFoldersActions, useSingleTemplateActions } from '../../../hooks'
import { folderHasTemplates } from '../../../utils'

const TemplatesSidebar = ({ setSelectedTemplates, refreshTemplates, currentFolder, setCurrentFolder, resetFolders, setResetFolders }) => {
  const { t } = useContext(GlobalContext)
  const { templatesSelectedFilters, setTemplatesSelectedFilters, templates, templatesArr, templatesLoaded } = useContext(DocumentsContext)
  const { setSelectedFilter, folders, setParentFolder, foldersLoading } = useContext(FolderContext)
  const { setNotification } = useContext(NotificationContext)
  const { updateBreadcrumbs, moveTemplatesFolder, deleteTemplateFolder } = useTemplatesFoldersActions()
  const { moveTemplateToFolder } = useSingleTemplateActions()
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState(false)
  const [showDeleteFolderAlert, setShowDeleteFolderAlert] = useState(false)

  // On filter folders
  const handleFilterFolders = (f) => {
    if(!f && templatesSelectedFilters.folder === 'all') return 
    if(f && f.id === templatesSelectedFilters.folder) return 
    setTemplatesSelectedFilters(prev => ({...prev, folder: f ? f.id : 'all' }))
    setSelectedTemplates([])
    setSelectedFilter(f ? f.id : 'all')
    updateBreadcrumbs({ folder: f })
  }

  // On create folder btn click
  const handleCreateFolderBtnClick = () => {
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  // On create folder - from another folder
  const handleCreateFolderFromAnotherFolder = (e, folder = null) => {
    setParentFolder(folder)
    setShowCreateFolderModal(true)
  }

  // On open edit folder modal
  const handleOpenEditFolderModal = (folder) => {
    setShowEditFolderModal(true)
    setCurrentFolder(folder)
  }

  // On close edit folder modal
  const handleCloseEditFolderModal = () => {
    setShowEditFolderModal(false)
    setCurrentFolder(null)
  }

  // On open delete folder alert
  const handleOpenDeleteFolderAlert = (folder, reset) => {
    if(folderHasTemplates(templates, folder)) {
      return setNotification({ msg: t('folder.not_empty_templates', { folder: folder.name }), type: 'warning' })
    }
    setCurrentFolder(folder)
    setShowDeleteFolderAlert(true)
    if(reset) {
      setResetFolders(true)
    }
  }

  // On close delete folder alert
  const handleCloseDeleteFolderAlert = () => {
    setShowDeleteFolderAlert(false)
  }

  // On folder delete
  const handleFolderDelete = async () => {
    await deleteTemplateFolder({ currentFolder, setShowDeleteFolderAlert, setCurrentFolder, resetFolders, setResetFolders, setSelectedTitle: () => {}, filterNonDeletedTemplates: () => {} })
    refreshTemplates()
  }

  // On move folder to folder
  const handleMoveToFolder = async (selectedFolder) => {
    await moveTemplatesFolder({ selectedFolder })
    refreshTemplates()
  }

  // On move document to folder
  const handleMoveDocumentToFolder = async (template, folder) => {
    await moveTemplateToFolder({ template, folder, setMoved: () => {} })
    refreshTemplates()
  }

  // Drag over - all
  const handleAllDragOver = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.item-inner')
    if(!folderBox) return
    if(!folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover')
    }
  }

  // Drag leave - all
  const handleAllDragLeave = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.item-inner')
    if(!folderBox) return
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover')
    }
  }

  // On drop - all
  const handleDropToAll = async(e) => {
    const folderBox = e.target.closest('.item-inner')
    if(!folderBox) return
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover')
    }

    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null
    
    if(data) {
      await moveTemplatesFolder({ selectedFolder: null, tmpltFolderToMove: data })
      refreshTemplates()
      return
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null
    
    if(templateData) {
      await moveTemplateToFolder({ template: templateData, folder: null, setMoved: () => {} })  
      refreshTemplates()
    }
  }

  return (
    <div className="collection-sidebar">
      <h1 className="heading-1">{t('dashboard.templates')}</h1>

      <section className="folders">
        <h5 className="heading-5">{t('dashboard.folders')}</h5>
        <ul className="filters-list">
          <li className={`filters-list__item ${templatesSelectedFilters.folder === 'all' ? 'active' : ''}`}>
            <div className="item-inner u-truncate-text-wrapper" onDrop={handleDropToAll} onDragOver={handleAllDragOver} onDragLeave={handleAllDragLeave}>
              <p onClick={() => handleFilterFolders(null)} className="u-truncate-text">{t('general.all_folders')}</p> 
              <CustomTooltip content={t('folder.create')}>
                <div className="icon-right" onClick={handleCreateFolderBtnClick}>
                  <span className="material-symbols-outlined">create_new_folder</span>
                </div>
              </CustomTooltip>
            </div>
          </li>
          {folders.map(folder => {
            if(!folder.parentFolder) {
              return <FolderListItem 
                key={folder.id} 
                folder={folder} 
                onCreateFolder={handleCreateFolderFromAnotherFolder}
                onEdit={handleOpenEditFolderModal} 
                onDelete={handleOpenDeleteFolderAlert} 
                onFilter={handleFilterFolders}
                selectedFilter={templatesSelectedFilters.folder} 
                onMoveToFolder={handleMoveToFolder}
                folders={folders}
                foldersLoading={foldersLoading}
                collection={templatesArr}
                collectionLoading={templatesLoaded}
                // onMoveTemplateToFolder={onMoveTemplateToFolder}
                view="templates"
                onMoveDocumentToFolder={handleMoveDocumentToFolder}
                refreshDocs={refreshTemplates}
              />
            }
            return null
          })}
        </ul>
      </section>

      {showCreateFolderModal && (
        <CreateEditFolderModal 
          onClose={handleCloseCreateFolderModal} 
          view="templates" 
          setFolderCreated={() => {}}
          setCurrentFolder={setCurrentFolder}
          refreshCollection={refreshTemplates}
        />
      )}
      {showEditFolderModal && (
        <CreateEditFolderModal 
          onClose={handleCloseEditFolderModal} 
          view="templates" 
          mode="edit" 
          currentFolder={currentFolder} 
          setFolderCreated={() => {}}
          setCurrentFolder={setCurrentFolder}
          refreshCollection={refreshTemplates}
        />
      )}
      {showDeleteFolderAlert && <Alert 
        onClose={handleCloseDeleteFolderAlert}
        text={t('alert.delete_folder')}
        onSubmit={handleFolderDelete}
        deleteAlert
      />}
    </div>
  )
}

export default TemplatesSidebar
import React, { useState, useEffect } from 'react'
import ClearIcon from '@material-ui/icons/Clear'

import { validateEmail } from '../../helpers/validate'

const TagsInput = ({ label, id, name, placeholder, error, onChange, defaultTags, emailValidation, invalid, className, onFocus, onBlur }) => {
  const [value, setValue] = useState('')
  const [tags, setTags] = useState(defaultTags ? defaultTags : [])
  const [isActive, setIsActive] = useState(false)

  // Set default tags
  useEffect(() => {
    setTags(defaultTags)
  }, [defaultTags])

  // Change handler
  const changeHandler = (e) => {
    setValue(e.target.value)
    onChange(tags)
  }

  // Remove tag
  const removeTag = (tag) => {
    const arr = tags.filter(t => t !== tag)
    setTags(arr)
    onChange(arr)
  }

  // Update tags
  const updateTagsHandler = (e) => {
    e.preventDefault()

    // Add tags if input is not empty and if input value is not comma
    if(e.target.value !== '' && e.target.value !== ',') {

      if(e.key === ',') {

        let newTag = value.trim().split(',')[0]
        if(emailValidation) {
          let check = !tags.includes(newTag) && newTag !== '' && validateEmail(newTag)
          if(check) {
            const arr = [...tags, newTag]
            setTags(arr)
            onChange(arr)
          }
        }else {
          let check = !tags.includes(newTag) && newTag !== ''
          if(check) {
            const arr = [...tags, newTag]
            setTags(arr)
            onChange(arr)
          }
        }
        setValue('')

      } else if(e.key === 'Enter') {

        let newTag = value.trim()
        if(emailValidation) {
          let check = !tags.includes(newTag) && newTag !== '' && validateEmail(newTag)
          if(check) {
            const arr = [...tags, newTag]
            setTags(arr)
            onChange(arr)
          }
        }else {
          let check = !tags.includes(newTag) && newTag !== ''
          if(check) {
            const arr = [...tags, newTag]
            setTags(arr)
            onChange(arr)
          }
        }
        setValue('')

      }

    }

    // Remove tags if backspace is pressed
    // if(e.key === 'Backspace' && tags.length > 0 && value.length === 0) {
    //   const copyOfTags = [...tags];
    //   copyOfTags.pop();
    //   setTags(copyOfTags);
    //   onChange(copyOfTags);
    // }
  }

  const focusHandler = () => {
    setIsActive(true)
    if(onFocus) onFocus()
  }
  
  const blurHandler = () => {
    setIsActive(false)
    if(onBlur) onBlur()
  }

  let tagsClass = 'tags-input-v2'

  if(isActive) {
    tagsClass += ' active'
  }

  if(error || invalid) {
    tagsClass += ' tags-input-v2--error'
  }

  if(className) {
    tagsClass += ` ${className}`
  }

  return(
    <div className={tagsClass}>
      {label && <label htmlFor={id ? id : name} className="tags-input-v2__label">{label}</label>}
      <div className={isActive ? "tags-input-v2__wrapper active" : "tags-input-v2__wrapper"}>
        <div className="tags-input-v2__tags">
          {tags.map((tag, i) => {
            if(typeof tag === 'string') {
              return <div key={i} className="tag">
                {tag} <span onClick={() => removeTag(tag)}><ClearIcon /></span>
              </div>
            }else {
              return <div key={i} className="tag">
                {tag.name} <span onClick={() => removeTag(tag)}><ClearIcon /></span>
              </div>
            }
          })}
          <input 
            type="text"
            placeholder={placeholder}
            name={name}
            id={id ? id : name}
            value={value}
            onChange={changeHandler}
            autoComplete="off"
            onKeyUp={updateTagsHandler}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  )
}

export default TagsInput
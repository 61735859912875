import { useContext, useCallback } from 'react'
import { isIOS, isMobile } from 'react-device-detect';
import { saveAs } from 'file-saver'

import { LoaderContext, SignatureContext, GlobalContext, NotificationContext } from '../context'
import { delete_signature } from '../services/firestore'
import { get_onespan_pdf_doc_as_base64_data, get_package_status, get_package_audit, resend_signing_invitation } from '../services/onespan'
import { base64toBlob } from '../utils'

const useSingleSignatureActions = () => {
  const { deleteSignature, updateSignature } = useContext(SignatureContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText, setLoading } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useContext(GlobalContext)

  // Download signature
  const downloadSignature = useCallback(async ({ doc }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Preparing signature for download')
    const res = await get_onespan_pdf_doc_as_base64_data(doc.package_id, doc.document_id)

    if(res.success) {
      if(isIOS) {
        const blob = base64toBlob(res.data)
        const a = document.createElement('a')
        a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`)
      }else {
        const a = document.createElement("a")
        a.href = `data:application/pdf;base64,${res.data}`
        a.download = `${doc.title}.pdf`
        a.click()
      }
    }
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }, [setShowGlobalResponseLoader, setGlobalResponseLoaderText])

  // Delete single signature
  const deleteSingleSignature = async ({ signatureToDelete, setShowDeleteSingleSignatureAlert }) => {
    try {
      setShowGlobalResponseLoader(true)
      // setGlobalResponseLoaderText('Deleting signature')
      await delete_signature(signatureToDelete.id)
      deleteSignature([signatureToDelete.id])
      setShowDeleteSingleSignatureAlert(false)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Preview signature
  const previewSignature = useCallback(async ({ doc, setPreviewLoading, setShowPreviewDocModal, setIframeSrc, setPreviewError }) => {
    setPreviewLoading(true)
    if(!isMobile) {
      setShowPreviewDocModal(true)
      try {
        const res = await get_onespan_pdf_doc_as_base64_data(doc.package_id, doc.document_id)
        if(res.success) {
          setIframeSrc(`data:application/pdf;base64,${res.data}`)
        }else {
          setPreviewError(t('notification.something_went_wrong'))
        }
        setPreviewLoading(false)
      } catch (err) {
        setPreviewLoading(false)
        setPreviewError(t('notification.something_went_wrong'))
      }
    }else {
      downloadSignature({ doc })
    }
  }, [downloadSignature, t])

  // On get status
  const getSingleSignatureStatus = useCallback(async ({ signature, setShowSidePanel, setSingleStatusLoading, setActiveSignature }) => {
    setShowSidePanel(true)
    setSingleStatusLoading(true)
    try {
      const res = await get_package_status(signature.package_id)
      const auditRes = await get_package_audit(signature.package_id)
      if(res.success && auditRes.success) {
        const currentStatus = res.data.status
        const s = {...signature}
        s.users = []
        const auditConfirmedEvents = auditRes.data['audit-events'].filter(event => event.type === 'Confirm')
        const auditDeclinedEvents = auditRes.data['audit-events'].filter(event => event.type === 'Decline')
        if(signature.recipients && Array.isArray(signature.recipients)) {
          signature.recipients.forEach((recipient) => {
            const recipientEmail = recipient.email 
            const recipientConfirmedAudits = auditConfirmedEvents.filter(event => event['user-email'] === recipientEmail)
            const recipientDeclinedAudits = auditDeclinedEvents.filter(event => event['user-email'] === recipientEmail)
            let data = {
              email: recipientEmail,
              status: recipientConfirmedAudits.length === 2 
                ? 'COMPLETED' 
                : recipientConfirmedAudits.length === 1 && recipientConfirmedAudits[0].type === 'Confirm' && recipientConfirmedAudits[0].target !== 'Electronic Disclosures and Signatures Consent' ? 'COMPLETED' : 'SIGNING_PENDING'
            }
            if(recipientDeclinedAudits.length > 0) {
              data.status = 'DECLINED'
            }

            s.users.push(data) 
          })
        }
        setActiveSignature(s)
        if(signature.status !== currentStatus) {
          await updateSignature(s.id, { status: res.data.status, status_updated_at: Date.now() })
        }
      }else {
        throw new Error('');
      }
      setSingleStatusLoading(false);
    } catch (err) {
      console.log(err)
      setSingleStatusLoading(false)
      setActiveSignature(signature)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }, [setNotification, t, updateSignature])

  // Refresh status
  const refreshStatus = async ({ doc, setRefreshing, setRefreshed }) => {
    setRefreshing(true)
    setLoading(true)
    try {
      const res = await get_package_status(doc.package_id)
      const s = {...doc}
      if(res.success && doc.status !== res.data.status) {
        await updateSignature(s.id, { status: res.data.status, status_updated_at: Date.now() })
      }else {
        await updateSignature(s.id, { status_updated_at: Date.now() })
      }
      setRefreshing(false)
      setRefreshed(true)
    } catch (err) {
      console.log(err)
      setRefreshing(false)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    } finally {
      setLoading(false)
    }
  }

  // Resend signing invitation
  const resendSigningInvitation = async ({ roleId, packageId, signature, userEmail }) => {
    setShowGlobalResponseLoader(true)
    try {
      const data = {
        roleId,
        packageId
      }
      const res = await resend_signing_invitation(data)
      if(res.success) {
        let resent_invitations = []
        if(signature.resent_invitations) resent_invitations = [...signature.resent_invitations]
        resent_invitations.push({ date: Date.now(), user: userEmail })
        await updateSignature(signature.id, { resent_invitations })
        setShowGlobalResponseLoader(false)
        setNotification({ msg: t('notification.message_sent'), type: 'success' })
      }
    } catch (err) {
      console.log(err)
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }

  return {
    downloadSignature,
    deleteSingleSignature,
    previewSignature,
    getSingleSignatureStatus,
    refreshStatus,
    resendSigningInvitation,
  }
}

export default useSingleSignatureActions
import { useContext, useState } from 'react'

import { Alert } from '../../new_ui'
import { GlobalContext } from '../../../context'
import { useApprovalsActions } from '../../../hooks'

const ApprovalsActions = ({ allChecked, onAllCheckedChange, selectedItems, setSelectedItems, refreshApprovals, setAllChecked }) => {
  const { t } = useContext(GlobalContext)
  const { deleteApprovals } = useApprovalsActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  // On delete click
  const handleDeleteClick = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteApprovals({ selectedApprovals: selectedItems, setSelectedApprovals: setSelectedItems, setAllChecked, setShowDeleteApprovalsAlert: setShowDeleteAlert })
    refreshApprovals()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  return (
    <div className="list-view-v2__actions">
      <div className="checkbox">
        {!allChecked 
          ? <span className="material-symbols-outlined" onClick={onAllCheckedChange}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled" onClick={onAllCheckedChange}>check_box</span>
        }
      </div>
      <p className="text-style-1">{t('dashboard.num_of_selected_items', { number: selectedItems.length })}</p>
      <div className="buttons">
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDeleteClick}>
          <span className="material-symbols-outlined icon">delete</span> 
          <span className="text">{t('general.remove')}</span>
        </a>
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('approvals.delete_selected')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
    </div>
  )
}

export default ApprovalsActions
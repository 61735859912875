import React, { useState, useEffect, useContext } from 'react'
import { Check, Close, DeleteOutlined } from '@material-ui/icons'

import Modal from '../../UI/Modal'
import Input from '../../UI/Input'
import CustomSelect from '../../UI/CustomSelect'
import Button from '../../UI/Button'
import ResponseLoader from '../../UI/ResponseLoader'
import IconButton from '../../UI/IconButton'
import Alert from '../../UI/Alert'
import { NotariesContext, TeamContext, UserContext, NotificationContext, GlobalContext } from '../../../context'
import { validateMobileNumber, validateEmail } from '../../../helpers/validate'

const AddEditNotary = ({ onClose, mode = 'add', notary }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { createNotary, updateNotary, deleteNotary } = useContext(NotariesContext)
  const [roles, setRoles] = useState([
    { label: t('roles.master'), value: 'master', active: true }
  ])
  const [role, setRole] = useState('master')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [street, setStreet] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [resLoading, setResLoading] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const [showDeleteNotaryAlert, setShowDeleteNotaryAlert] = useState(false)

  // Prefill fields if notary set
  useEffect(() => {
    if(notary) {
      setRole(notary.role || '')
      setName(notary.name || '')
      setPhone(notary.phone || '')
      setEmail(notary.email || '')
      setStreet(notary.street || '')
      setZipCode(notary.postal_code || '')
      setCity(notary.city || '')
    }
  }, [notary])

  // On role change
  const rolesChangeHandler = (role) => {
    setRole(role)
    setRoles(prev => {
      const roles = [...prev]
      return roles.map((r) => r.value === role.value ? { ...r, active: true } : { ...r, active: false })
    });
  }

  // On form submit
  const submitHandler = async (e) => {
    e.preventDefault()
    let data = { 
      role: role.trim(), 
      name: name.trim(), 
      phone: phone.trim(), 
      email: email.trim(), 
      street: street.trim(), 
      postal_code: zipCode.trim(), 
      city: city.trim()
    }
    let failed = false
    
    // validate fields
    for(let field in data) {
      if(data[field].trim() === '') {
        return setNotification({ msg: t('notification.all_fields_required'), type: 'danger' })
      }
      if(field === 'email' && !validateEmail(data[field].trim())) {
        return setNotification({ msg: t('notification.invalid_email'), type: 'danger' })
      }
      if(field === 'phone' && !validateMobileNumber(data[field].trim())) {
        return setNotification({ msg: t('notification.invalid_phone'), type: 'danger' })
      }
    }

    setResLoading(true)

    if(mode === 'add') {
      // create new notary
      setResMessage(t('notaries.creating'))
      data.team = selectedTeam.id 
      data.meta = {
        created: Date.now(),
        updated: Date.now()
      }
      try {
        await createNotary(data, user.id)
        onClose()
      } catch (err) {
        failed = true
      }
    }else {
      // edit notary
      setResMessage(t('notaries.updating'))
      data.meta = {
        ...notary.meta,
        updated: Date.now()
      }
      try {
        await updateNotary(data, notary.id)
        onClose()
      } catch (err) {
        failed = true
      }
    }
    if(failed) {
      setResLoading(false)
    }
  }

  // On notary delete
  const deleteNotaryHandler = async () => {
    let failed = false
    if(!notary.id) return

    setResLoading(true)
    setResMessage(t('notaries.deleting'))
    try {
      await deleteNotary(notary.id)
      setNotification({ msg: t('notification.notary_deleted'), type: 'success' })
      onClose()
    } catch (err) {
      failed = true
    }
    if(failed) {
      setResLoading(false)
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="notaries-modal">
        <div className="notaries-modal__head notaries-modal__head--2">
          <h2>{mode === 'add' ? t('notaries.add') : t('notaries.modify')}</h2>
        </div>
        <div className="notaries-modal__body">
          <form className="form" onSubmit={submitHandler}>
            <div className="form__group">
              <CustomSelect 
                options={roles}
                onChange={rolesChangeHandler}
                active={roles.find(r => r.active)}
                formEl
              />
            </div>
            <div className="form__group">
              <Input value={name} name="name" onChange={(e) => setName(e.target.value)} dashboardModal label={t('general.name')} />
              <Input value={phone} name="phone" onChange={(e) => setPhone(e.target.value)} dashboardModal label={t('general.phone')} />
              <Input value={email} name="email" onChange={(e) => setEmail(e.target.value)} dashboardModal label={t('general.email')} />
            </div>
            <div className="form__group">
              <Input value={street} name="street" onChange={(e) => setStreet(e.target.value)} dashboardModal label={t('general.street')} />
              <Input value={zipCode} name="zipCode" onChange={(e) => setZipCode(e.target.value)} dashboardModal label={t('general.postal_code')} />
              <Input value={city} name="city" onChange={(e) => setCity(e.target.value)} dashboardModal label={t('general.city')} />
            </div>
            <div className="form__actions">
              {mode === 'add' && <Button icon={<Check />} primary text={t('notaries.add_2')} medium type="submit" />}
              {mode === 'edit' && <Button icon={<Check />} primary text={t('notaries.update')} medium type="submit" />}
              <Button icon={<Close />} text={t('general.cancel')} danger onButtonClick={onClose} medium />
              {mode === 'edit' && <IconButton icon={<DeleteOutlined />} danger medium onButtonClick={() => setShowDeleteNotaryAlert(true)} />}
            </div>
          </form>
        </div>
      </div>

      {resLoading && <ResponseLoader text={resMessage} />}
      {showDeleteNotaryAlert && <Alert 
        onClose={() => setShowDeleteNotaryAlert(false)}
        text={t('alert.delete_notary')}
        deleteAlert
        onSubmit={deleteNotaryHandler}
      />}
    </Modal>
  )
}

export default AddEditNotary
import { useContext } from 'react'
import { useDroppable } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy, useSortable } from "@dnd-kit/sortable"
import { ClickAwayListener } from '@material-ui/core'

import Loader from '../../UI/Loader'
import { WidgetPlaceholderAlerts, WidgetPlaceholderDocs, WidgetPlaceholderPeople, WidgetPlaceholderPrivateNotepad, WidgetPlaceholderRecentDocs, WidgetPlaceholderTasks } from '../../new_ui'
import { UserContext, GlobalContext, DocumentsContext, AlertContext, TaskContext } from '../../../context'
import { useWidgetActions } from '../../../hooks'

const CustomizeSidePanel = ({ onClose, show, itemsObj, activeId }) => {
  const { customizations, widgets } = useContext(UserContext)
  const { documentsLoaded, templatesLoaded } = useContext(DocumentsContext)
  const { tasksFetched } = useContext(TaskContext)
  const { alertsFetched } = useContext(AlertContext)
  const { t } = useContext(GlobalContext)
  const { bgColors, changeBackground } = useWidgetActions()
  const { setNodeRef } = useDroppable({ id: 'side_panel', disabled: true })

  // On click away
  const handleClickAway = (e) => {
    if(e.target.closest('.customize-btn')) return 
    onClose()
  }

  // On bg change
  const handleBgChange = async (c) => {
    await changeBackground({ c })
  }

  return (
    <SortableContext id="side_panel" items={widgets.side_panel} strategy={verticalListSortingStrategy}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`customize-side-panel ${show ? 'opened' : ''}`}>
          <div className="customize-side-panel__head">
            <h2 className="heading-2">{t('dashboard.customize_title')}</h2>
            <div className="icon" onClick={onClose}>
              <span className="material-symbols-outlined size-24">keyboard_tab</span>
            </div>
          </div>
          <div className="customize-side-panel__body u-custom-scrollbar">
            <section>
              <h4 className="heading-4">{t('dashboard.background')}</h4>
              <div className="backgrounds">
                {bgColors.map((color, idx) => (
                  <div 
                    className="backgrounds__box" 
                    key={idx} 
                    style={{backgroundColor: color, color: idx < 12 ? '#111' : '#fff', border: idx === 0 ? '1px solid rgba(218,218,218,0.3)' : 0}}
                    onClick={() => handleBgChange(color)}
                  >
                    {customizations.background === color && <span className="material-symbols-outlined size-24">done</span>}
                  </div>
                ))}
              </div>
            </section>
            <section>
              <h4 className="heading-4">{t('widgets.title')}</h4>
              <div className="info">
                <p className="text-style-2">{t('widgets.dnd_info')}</p>
              </div>
              {(!documentsLoaded || !templatesLoaded || !alertsFetched || !tasksFetched) ? (
                <div className="loader-wrapper">
                  <Loader normal primary small />
                </div>
              ) : (
                <div className="widgets" ref={setNodeRef}>
                  {widgets.side_panel.map((item, idx) => {
                    const itemObj = itemsObj.find(i => i.id === item)
                    return <Widget key={idx} item={itemObj} dragging={itemObj.id === activeId} />
                  })}
                </div>
              )}
            </section>
          </div>
        </div>
      </ClickAwayListener>
    </SortableContext>
  )
}

const Widget = ({ item, dragging }) => {
  const sortable = useSortable({ id: item.id })
  const { attributes, listeners, setNodeRef } = sortable

  return (
    <div className={`widget-box ${dragging ? 'active' : ''}`} ref={setNodeRef} {...attributes} {...listeners}>
      { item.id === 'item-alerts' && <WidgetPlaceholderAlerts /> }
      { item.id === 'item-contracts' && <WidgetPlaceholderDocs /> }
      { item.id === 'item-people' && <WidgetPlaceholderPeople /> }
      { item.id === 'item-private_notepad' && <WidgetPlaceholderPrivateNotepad /> }
      { item.id === 'item-recent' && <WidgetPlaceholderRecentDocs /> }
      { item.id === 'item-tasks' && <WidgetPlaceholderTasks /> }
    </div>
  )
}

export default CustomizeSidePanel
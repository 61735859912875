import React, { createContext, useState, useRef } from 'react'

import { SHOW_LABELS_LS, COLUMNS_WIDTHS, COLUMNS_WIDTHS_V2, CARBON_DOCS_COLUMNS_WIDTHS_V2_LS } from '../../constants'
import { currentLanguage } from '../../i18n'
import { getTeamDocsColumnWidthsData, getSelectedTeamFromLS } from '../../utils'
import { validateEmail } from '../../helpers/validate'

import fr from '../../i18n/fr-copy.json'
import nl from '../../i18n/nl-copy.json'
import en from '../../i18n/en-copy.json'
import es from '../../i18n/es-copy.json'
import it from '../../i18n/it-copy.json'

export const GlobalContext = createContext()

// const selectedTeam = getSelectedTeamFromLS()
// const columnsWidthsData = getTeamDocsColumnWidthsData()
// let defaultColumnsWidths = COLUMNS_WIDTHS
// if(columnsWidthsData && columnsWidthsData[selectedTeam]) {
//   defaultColumnsWidths = columnsWidthsData[selectedTeam]
// }

const source = {
  fr,
  nl,
  en,
  es,
  it
}

const GlobalState = ({ children }) => {
  const [oneDriveAuthToken, setOneDriveAuthToken] = useState(null)
  const [gDriveAuth, setGDriveAuth] = useState({})
  const [dropboxAccessToken, setDropboxAccessToken] = useState(null)
  const [dropboxCode, setDropboxCode] = useState(null)
  const [joiningTeamOption, setJoiningTeamOption] = useState({ default: true })
  const [docComments, setDocComments] = useState({})
  const [showLabelsName, setShowLabelsName] = useState(localStorage.getItem(SHOW_LABELS_LS) ? JSON.parse(localStorage.getItem(SHOW_LABELS_LS)) : false)
  const [newFeatures, setNewFeatures] = useState([])
  const [featuresFetched, setFeaturesFetched] = useState(false)
  // const [docsTableWidth, setDocsTableWidth] = useState('100%')
  const [docsTableWidthV2, setDocsTableWidthV2] = useState('100%')
  // const [docsTableColumnsWidths, setDocsTableColumnsWidths] = useState(defaultColumnsWidths)
  const [docsTableColumnsWidthsV2, setDocsTableColumnsWidthsV2] = useState(localStorage.getItem(CARBON_DOCS_COLUMNS_WIDTHS_V2_LS) ? JSON.parse(localStorage.getItem(CARBON_DOCS_COLUMNS_WIDTHS_V2_LS)) : COLUMNS_WIDTHS_V2)
  const [redirectToAfterAuthenticated, setRedirectToAfterAuthenticated] = useState(null)
  const [sidebarOpened, setSidebarOpened] = useState(true)
  const [sidebarOpenedMobile, setSidebarOpenedMobile] = useState(false)
  const [prevPaths, setPrevPaths] = useState([])
  const [selectedLang, setSelectedLang] = useState(currentLanguage())
  const unsubscribeDocs = useRef(null)
  const unsubscribeActions = useRef(null)
  const unsubscribeComments = useRef(null)
  const unsubscribeTeam = useRef(null)
  const unsubscribeDocLists = useRef(null)
  const unsubscribeTags = useRef(null)
  const unsubscribeSignatures = useRef(null)
  const unsubscribeTemplates = useRef(null)
  const unsubscribeTasks = useRef(null)
  const unsubscribeSubscription = useRef(null)
  const unsubscribeInvoices = useRef(null)

  const selectedTeamRef = useRef(null)

  // Unsubscribe listeners - real time data listeners
  const unsubscribeListeners = () => {
    if(unsubscribeDocs.current) {
      unsubscribeDocs.current()
    }
    if(unsubscribeActions.current) {
      unsubscribeActions.current()
    }
    if(unsubscribeComments.current) {
      unsubscribeComments.current()
    }
    if(unsubscribeTeam.current) {
      unsubscribeTeam.current()
    }
    if(unsubscribeDocLists.current) {
      unsubscribeDocLists.current()
    }
    if(unsubscribeTags.current) {
      unsubscribeTags.current()
    }
    if(unsubscribeSignatures.current) {
      unsubscribeSignatures.current()
    }
    if(unsubscribeTemplates.current) {
      unsubscribeTemplates.current()
    }
    if(unsubscribeTasks.current) {
      unsubscribeTasks.current()
    }
  }

  const unsubscribeUserListeners = () => {
    if(unsubscribeSubscription.current) {
      unsubscribeSubscription.current()
    }
    if(unsubscribeInvoices.current) {
      unsubscribeInvoices.current()
    }
  }

  // Reset state
  const resetState = () => {
    // const selectedTeam = getSelectedTeamFromLS()
    // const columnsWidthsData = getTeamDocsColumnWidthsData()
    // let defaultColumnsWidths = COLUMNS_WIDTHS
    // if(columnsWidthsData && columnsWidthsData[selectedTeam]) {
    //   defaultColumnsWidths = columnsWidthsData[selectedTeam]
    // }
    // setDocsTableColumnsWidths(defaultColumnsWidths)

    setOneDriveAuthToken(null)
    setGDriveAuth({})
    setDropboxAccessToken(null)
    setDropboxCode(null)
    setDocComments({})
    setNewFeatures([])
    setFeaturesFetched(false)
    // setDocsTableWidth('100%')
    setRedirectToAfterAuthenticated(null)
  }

  // Translate
  const t = (key, params) => {
    const language = selectedLang
    const components = key.split('.')
    if(!source[language]) {
      return `Missing translation for ${key}`
    }
    const section = source[language][components[0]]
    if(!section) {
      return `Missing translation for ${key}`
    }
    let value = section[components[1]]
    if(!value) {
      return `Missing translation for ${key}`
    }
    if(!!params) {
      for(let p in params) {
        value = value.replace(`{{${p}}}`, params[p])
      }
    }
    return value
  }

  // Validate function
  const validate = (fields, submit = false) => {
    let errors = {};
  
    for(let field in fields) {
      if(submit) {
        fields[field].touched = true;
      }
  
      const currentField = fields[field];
  
      if(currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
        errors[field] = currentField.requiredMessage ? currentField.requiredMessage :  t('error.field_required')
      }
  
      if(currentField.file && currentField.required && Object.keys(currentField.value).length === 0 && currentField.touched) {
        errors[field] = currentField.requiredMessage ? currentField.requiredMessage : t('error.field_required')
      }
      
      if(!errors[field] && currentField.email && !validateEmail(currentField.value) && currentField.touched) {
        errors[field] = currentField.emailMessage ? currentField.emailMessage :  t('error.invalid_email')
      }
  
  
      // if(!errors[field] && currentField.values && currentField.values.length > 0 && !validateEmail(currentField.value) && currentField.touched) {
      //   errors[field] = currentField.emailMessage ? currentField.emailMessage : 'Adresse électronique invalide !';
      // }
  
      if(!errors[field] && currentField.matchWith && currentField.value !== fields[currentField.matchWith]?.value && currentField.touched) {
        errors[field] = currentField.matchWithMessage ? currentField.matchWithMessage : t('error.field_mismatch');
      }
  
      if(!errors[field] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
        errors[field] = currentField.minLengthMessage ? currentField.minLengthMessage : t('error.min_length', { number: currentField.minLength });
      }
  
      if(!errors[field] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
        errors[field] = currentField.maxLengthMessage ? currentField.maxLengthMessage : t('error.max_length', { number: currentField.maxLength });
      }
  
      if(!errors[field] && currentField.file && currentField.touched && Object.keys(currentField.value).length > 0 && currentField.allowedTypes && !currentField.allowedTypes.includes(currentField.value[0].type.split('/')[1])) {
        errors[field] = currentField.allowedTypesMessage ? currentField.allowedTypesMessage : t('error.invalid_file_type')
      }
  
      if(!errors[field] && currentField.file && currentField.touched && Object.keys(currentField.value).length > 0 && currentField.maxFileSize && (currentField.maxFileSize * 1024) < Math.round(currentField.value[0].size)) {
        errors[field] = currentField.maxFileSizeMessage ? currentField.maxFileSizeMessage : t('error.file_too_large', { maxFileSize: currentField.maxFileSize})
      }
    }
  
    return errors;
  }

  return <GlobalContext.Provider value={{
    oneDriveAuthToken,
    setOneDriveAuthToken,
    gDriveAuth,
    setGDriveAuth,
    dropboxAccessToken,
    setDropboxAccessToken,
    dropboxCode,
    setDropboxCode,
    joiningTeamOption,
    setJoiningTeamOption,
    docComments,
    setDocComments,
    showLabelsName,
    setShowLabelsName,
    newFeatures,
    setNewFeatures,
    featuresFetched,
    setFeaturesFetched,
    // docsTableWidth,
    // setDocsTableWidth,
    // docsTableColumnsWidths,
    // setDocsTableColumnsWidths,
    docsTableWidthV2,
    setDocsTableWidthV2,
    docsTableColumnsWidthsV2,
    setDocsTableColumnsWidthsV2,
    redirectToAfterAuthenticated,
    setRedirectToAfterAuthenticated,
    sidebarOpened,
    setSidebarOpened,
    sidebarOpenedMobile,
    setSidebarOpenedMobile,
    prevPaths,
    setPrevPaths,
    unsubscribeDocs,
    unsubscribeActions,
    unsubscribeComments,
    unsubscribeTeam,
    unsubscribeDocLists,
    unsubscribeTags,
    unsubscribeSignatures,
    unsubscribeTemplates,
    unsubscribeTasks,
    unsubscribeListeners,
    resetGlobalState: resetState,
    selectedLang,
    setSelectedLang,
    t,
    validate,
    unsubscribeUserListeners,
    unsubscribeSubscription,
    unsubscribeInvoices,
    selectedTeamRef
  }}>
    {children}
  </GlobalContext.Provider>
}

export default GlobalState;
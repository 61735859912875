import React, { useContext, useRef, useState } from 'react'

import { Modal } from '../new_ui'
import ResponseLoader from '../UI/ResponseLoader'
import { GlobalContext } from '../../context'
import { useDocumentsActions } from '../../hooks'

const UploadDocumentsModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { uploadDocuments } = useDocumentsActions()
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const dropEl = useRef()
  const fileInput = useRef()

  const allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  // const formatFromType = {
  //   'application/pdf': 'pdf',
  //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx'
  // }

  // On file input change or on drop
  const changeHandler = async (e) => {
    await uploadDocuments(e, { allowedFileTypes, setUploading, setProgress, onClose })
  }

  // On drag enter
  const handleDragEnter = (e) => {
    e.preventDefault();
    dropEl.current.classList.add('active')
  }
  
  // On drag leave
  const handleDragLeave = (e) => {
    e.preventDefault();
    dropEl.current.classList.remove('active')
  }
  
  // On drag over
  const handleDragOver = (e) => {
    e.preventDefault()
  }
  
  // On drop
  const handleDrop = (e) => {
    e.preventDefault();
    changeHandler(e);
    dropEl.current.classList.remove('active')
  }

  return (
    <Modal onClose={onClose} hideFooter={true} title={t('general.download')} className="modal-v2--medium">
      <div className="upload-or-drop upload-or-drop-v2"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        ref={dropEl}
      >
        <p className="upload-or-drop__text-1">{t('general.upload_here_3')}</p>
        <div className="upload-or-drop__or">
          <div className="line"></div>
          <p>{t('general.or')}</p>
          <div className="line"></div>
        </div>
        <div className="btn-wrapper">
          <button className="btn btn--with-icon-left" onClick={() => fileInput.current.click()}>
            <span className="material-symbols-outlined size-24">file_upload</span> 
            {t('general.select_files')}
          </button>
          <input type="file" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={changeHandler} className="hidden" ref={fileInput} multiple />
        </div>
        <p className="upload-or-drop__note">{t('general.uploaded_to_app')}</p>
      </div>

      {uploading && <ResponseLoader progress={progress} />}
    </Modal>
  )
}

export default UploadDocumentsModal
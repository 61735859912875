import React, { createContext, useReducer, useState, useEffect, useRef } from 'react';

import { SET_USER, RESET_STATE, SET_MEMBERSHIP, SET_SUBSCRIPTION, SET_INVOICES } from '../types';
import userReducer from './userReducer';
import { DASHBOARD_WIDGETS } from '../../constants'

export const UserContext = createContext();

const UserState = ({ children }) => {
  const initialState = {
    user: {},
    partner: '',
    canUseSignatures: true,
    siteConfigsLoaded: true,
    membership: 'free',
    subscription: null,
    invoices: []
  }

  const [authMode, setAuthMode] = useState('');
  const [customizations, setCustomizations] = useState({
    background: '#F8F9FA'
  })
  const [widgets, setWidgets] = useState({
    main: ['item-tasks', 'item-alerts', 'item-recent', 'item-contracts'],
    side_panel: ['item-private_notepad', 'item-people']
  })
  const [widgetsInfo, setWidgetsInfo] = useState({
    'item-tasks': {
      size: 'half'
    },
    'item-alerts': {
      size: 'half'
    },
    'item-recent': {
      size: 'full'
    },
    'item-contracts': {
      size: 'full'
    },
    'item-private_notepad': {
      size: 'half'
    },
    'item-people': {
      size: 'half'
    },
  })
  const [userCustomizationsReady, setUserCustomizationsReady] = useState(false)
  const widgetsTimeout = useRef(null)
  const widgetsInfoTimeout = useRef(null)
  const [state, dispatch] = useReducer(userReducer, initialState);

  // Set widgets and other customizations when user is fetched
  useEffect(() => {
    if(state.user && Object.keys(state.user).length > 0 && !userCustomizationsReady) {
      let userCustomizations = state.user.customizations
      if(userCustomizations?.widgets) {
        let userWidgets = []
        let mainWidgets = []
        let sidePanelWidgets = []
        if(userCustomizations.widgets.main && Array.isArray(userCustomizations.widgets.main)) {
          userWidgets = [...userCustomizations.widgets.main]
          mainWidgets = [...userCustomizations.widgets.main]
        }
        if(userCustomizations.widgets.side_panel && Array.isArray(userCustomizations.widgets.side_panel)) {
          userWidgets = [...userWidgets, ...userCustomizations.widgets.side_panel]
          sidePanelWidgets = [...userCustomizations.widgets.side_panel]
        }
        let missingWidgets = []
        DASHBOARD_WIDGETS.forEach(w => {
          if(!userWidgets.includes(w)) {
            missingWidgets.push(w)
          }
        })
        if(missingWidgets.length > 0) {
          missingWidgets.forEach(w => sidePanelWidgets.push(w))
        }
        let widgetsObj = {
          main: mainWidgets,
          side_panel: sidePanelWidgets
        }
        setWidgets(widgetsObj)
      }
      if(userCustomizations?.widgets_info) {
        let widgetsInfoKeys = Object.keys(widgetsInfo)
        let infoObjKeys = Object.keys(userCustomizations.widgets_info)
        let infoObj = {...userCustomizations.widgets_info}
        widgetsInfoKeys.forEach(k => {
          if(!infoObjKeys.includes(k)) {
            infoObj[k] = widgetsInfo[k]
          }
        })
        setWidgetsInfo(infoObj)
      }
      if(userCustomizations?.background) {
        setCustomizations({
          ...customizations,
          background: userCustomizations.background
        })
      }
      setUserCustomizationsReady(true)
    }
    // eslint-disable-next-line
  }, [state.user, userCustomizationsReady, customizations])

  const setUser = (value) => {
    dispatch({
      type: SET_USER,
      payload: value
    });
  }

  // Reset state
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
      payload: initialState
    });
    setWidgets({
      main: ['item-tasks', 'item-alerts', 'item-recent', 'item-contracts'],
      side_panel: ['item-private_notepad', 'item-people']
    })
    setCustomizations({
      background: '#F8F9FA'
    })
    setWidgetsInfo({
      'item-tasks': {
        size: 'half'
      },
      'item-alerts': {
        size: 'half'
      },
      'item-recent': {
        size: 'full'
      },
      'item-contracts': {
        size: 'full'
      },
      'item-private_notepad': {
        size: 'half'
      },
      'item-people': {
        size: 'half'
      },
    })
    setUserCustomizationsReady(false)
    widgetsTimeout.current = null 
    widgetsInfoTimeout.current = null
  }

  // Reset state
  const resetStateOnTeamChange = () => {
    dispatch({
      type: RESET_STATE,
      payload: {
        ...state,
      }
    });
  }

  const setMembership = (value) => {
    dispatch({
      type: SET_MEMBERSHIP,
      payload: value
    })
  }

  const setSubscription = (value) => {
    dispatch({
      type: SET_SUBSCRIPTION,
      payload: value
    })
  }

  const setInvoices = (value) => {
    dispatch({
      type: SET_INVOICES,
      payload: value
    })
  }
  
  return <UserContext.Provider value={{
    user: state.user,
    partner: state.partner,
    canUseSignatures: state.canUseSignatures,
    siteConfigsLoaded: state.siteConfigsLoaded,
    authMode,
    membership: state.membership,
    subscription: state.subscription,
    invoices: state.invoices,
    customizations,
    widgets,
    widgetsInfo,
    widgetsTimeout,
    widgetsInfoTimeout,
    setUser,
    resetUserState: resetState,
    setAuthMode,
    setMembership,
    setSubscription,
    setInvoices,
    resetUserStateOnTeamChange: resetStateOnTeamChange,
    setCustomizations,
    setWidgets,
    setWidgetsInfo,
  }}>
    {children}
  </UserContext.Provider>
}

export default UserState;
import { useState, useEffect, useContext, memo } from 'react'
import { useHistory } from 'react-router-dom'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import { Alert, LetterCircle } from '../../new_ui'
import { GlobalContext, ApprovalsContext, TeamContext } from '../../../context'
import { useSingleApprovalActions } from '../../../hooks'

const ApprovalsRow = ({ id, doc, columns, selectedApprovals, setSelectedApprovals, allChecked, refreshApprovals }) => {
  const { t } = useContext(GlobalContext)
  const { setApprovalsSelectedFilters } = useContext(ApprovalsContext)
  const { getUserNameById } = useContext(TeamContext)
  const { deleteSingleApproval } = useSingleApprovalActions()
  const [checked, setChecked] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const history = useHistory()

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedApprovals.length === 0) {
      setChecked(false)
    }
  }, [selectedApprovals, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked, doc])

  // On select
  const handleSelect = () => {
    setChecked(!checked)
    if(checked) {
      setSelectedApprovals(prev => prev.filter(d => d.id !== id))
    }else {
      setSelectedApprovals(prev => [...prev, doc])
    }
  }

  // On edit click
  const handleEditClick = (e) => {
    e.preventDefault()
    history.push(`/approval/${doc.id}`)
  }

  // On delete click
  const handleDeleteClick = async (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteSingleApproval({ id: doc.id, refreshApprovals })
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // Render checkbox column
  const renderCheckboxColumn = () => {
    return (
      <div className={`list-view-table__cell checkbox`}>
        {!checked 
          ? <span className="material-symbols-outlined checkbox-icon" onClick={handleSelect}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled checkbox-icon" onClick={handleSelect}>check_box</span>
        }
        {doc.folderRow 
          ? <span className="material-symbols-outlined row-icon filled">folder</span>
          : <span className="material-symbols-outlined row-icon">description</span>
        }
      </div>
    )
  }

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined">more_horiz</span></button>}
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/" onClick={handleEditClick}>
              <span className="icon"><span className="material-symbols-outlined">edit</span></span>
              {t('general.edit')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleDeleteClick} className="delete">
              <span className="icon"><span className="material-symbols-outlined">delete</span></span>
              {t('general.remove')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render title column
  const renderTitleColumn = (col, i) => {
    return (
      <div 
        className={`list-view-table__cell u-truncate-text-wrapper title-col`} 
        key={i}
      >
        <p className={`text-style-1 u-truncate-text`}>{doc.name}</p>
        <div className="show-on-hover show-on-hover--2">
          {renderSingleDocActions()}
        </div>
      </div>
    )
  }

  // Render steps column
  const renderStepsColumn = (col, i) => {
    let content = (
      <div className="approvals-steps u-truncate-text">
        {doc.steps.map((step, idx) => {
          return (
            <div className="step" key={idx}>
              <p>{idx + 1}</p>
              {step.members.map((member) => {
                let name = getUserNameById(member)
                return <LetterCircle key={member} data={name} />
              })}
            </div>
          )
        })}
      </div>
    )

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text-wrapper">{content}</div>
      </div>
    )
  }

  // Render custom column
  const renderCustomColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text">{content}</div>
      </div>
    )
  }

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'name') {
      content = renderTitleColumn(col, i)
    }else if(col.value === 'sort_steps') {
      content = renderStepsColumn(col, i)
    }else if(col.value === 'helper') {
      content = renderHelperColumn(col, i)
    }else {
      content = renderCustomColumn(col, i)
    }

    return content
  }
  
  return (
    <>
      <div 
        className={`list-view-table__row ${checked ? 'selected' : ''}`}
      >
        {columns.filter(c => c.checked).map((col, i) => {
          return renderColumnContent(col, i)
        })} 
        {renderCheckboxColumn()}   
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_approval')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
    </>
  )
}

export default memo(ApprovalsRow)
import { useContext } from 'react';

import { AlertContext, ContactsContext, DocumentsContext, DocumentsFoldersContext, FolderContext, LoaderContext, SignatureContext, TagContext, TaskContext, UserContext, TeamContext, NotariesContext, VariablesContext, ContactGroupsContext, GlobalContext, SharedTemplatePagesContext, ApprovalsContext, SignatureTemplatesContext, ColumnsContext, SharedDocumentsContext } from '../context';
import firebase from '../services/firebase';

const useAuth = () => {
  const { resetAlertState } = useContext(AlertContext);
  const { resetContactState } = useContext(ContactsContext);
  const { resetDocState } = useContext(DocumentsContext);
  const { resetDocFoldersState } = useContext(DocumentsFoldersContext);
  const { resetFolderState } = useContext(FolderContext);
  const { resetLoaderState } = useContext(LoaderContext);
  const { resetSignatureState } = useContext(SignatureContext);
  const { resetTagState } = useContext(TagContext);
  const { resetTaskState } = useContext(TaskContext);
  const { resetUserState, setAuthMode, resetUserStateOnTeamChange } = useContext(UserContext);
  const { resetTeamState } = useContext(TeamContext);
  const { resetNotariesState } = useContext(NotariesContext)
  const { resetVarsState } = useContext(VariablesContext)
  const { resetContactGroupsState } = useContext(ContactGroupsContext)
  const { resetGlobalState } = useContext(GlobalContext)
  const { resetSharedTemplatePagesState } = useContext(SharedTemplatePagesContext)
  const { resetApprovalsState } = useContext(ApprovalsContext)
  const { resetSignatureTemplatesState } = useContext(SignatureTemplatesContext)
  const { resetColumnsState } = useContext(ColumnsContext)
  const { resetSharedDocumentsState } = useContext(SharedDocumentsContext)

  const logout = async () => {
    await firebase.auth().signOut();
    resetState();
  }

  const resetState = (mode = 'logout') => {
    if(mode === 'logout') {
      resetUserState();
      resetTeamState();
      resetLoaderState();
    }
    resetAlertState(mode);
    resetTaskState(mode);
    resetContactState(mode);
    resetDocState(mode);
    resetDocFoldersState(mode);
    resetFolderState(mode);
    resetSignatureState(mode);
    resetTagState(mode);
    resetNotariesState(mode)
    resetVarsState(mode)
    resetContactGroupsState(mode)
    resetSharedTemplatePagesState(mode)
    setAuthMode('');
    resetGlobalState()
    resetApprovalsState(mode)
    resetSignatureTemplatesState(mode)
    resetUserStateOnTeamChange()
    resetColumnsState()
    resetSharedDocumentsState()
  }

  return { logout, resetState };
} 

export default useAuth;
import React, { useState, useEffect, useRef, useContext, Fragment, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'qs'
import { isIOS } from 'react-device-detect'
import { saveAs } from 'file-saver'
import { Document, Page, pdfjs } from 'react-pdf';
import moment from 'moment';
import { LinkOff } from '@material-ui/icons'
import axios from 'axios'

import NoAccess from '../UI/NoAccess';
import SendByEmailModal from '../sections/document-detail/SendByEmailModal';
import SynthesisModal from '../sections/document-detail/SynthesisModal';
import HistoryModal from '../sections/document-detail/HistoryModal';
import HistoryModalV2 from '../sections/document-detail/HistoryModalV2';
import Modal from '../UI/Modal';
import { generate_document, convert_attachment, generate_document_for_shared_templates } from '../../services/lawstudioApi';
import { update_document, create_document, fetch_document_history, upload_file, get_file, create_tag, fetch_tags, fetch_document_comments, fetch_user } from '../../services/firestore';
import DocumentDetailSkeleton from '../sections/document-detail/DocumentDetailSkeleton';
import SignDocumentModalOnespan from '../sections/document-detail/SignDocumentModalOnespan'
import Alert from '../UI/Alert';
import OneDriveModal from '../sections/document-detail/OneDriveModal';
import GDriveModal from '../sections/document-detail/GDriveModal';
import DropboxModal from '../sections/document-detail/DropboxModal';
import { convertToTemplateObjWithUniqueVarIndexes, EVENT_TYPES, availableOn, areSectionConditionsMet, base64toBlob, formulaResult } from '../../utils';
import { LoaderContext, DocumentsContext, SignatureContext, TagContext, GlobalContext, UserContext, NotificationContext, TeamContext, VariablesContext } from '../../context';
import { get_onedrive_token } from '../../services/onedrive';
import { dropbox_get_token, dropboxConfig } from '../../services/dropbox';
import { gdriveConfig } from '../../services/gdrive';
import config from '../../config.json';
import { log_event } from '../../services/analytics';
import InjectModal from '../sections/document-detail/InjectModal'
import TemplateHistoryModal from '../sections/document-detail/TemplateHistoryModal'
import NotariesModal from '../sections/document-detail/NotariesModal'
import AddEditNotary from '../sections/document-detail/AddEditNotary'
import ShareModal from '../sections/document-detail/ShareModal'
import ContactsModal from '../sections/contacts/ContactsModal'
import DocumentDetailContent from '../sections/document-detail/DocumentDetailContent'
import DocumentDetailHeader from '../sections/document-detail/DocumentDetailHeader'
import DocumentDetailHeaderV2 from '../sections/document-detail/DocumentDetailHeaderV2'
import { get_shared_template, get_single_shared_template } from '../../services/shared_templates'
import { check_if_shared_template_page_disabled } from '../../services/shared_template_pages'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

let savedTimeout = null;
let scrollToItemTimeout = null;

const DocumentDetail = ({ isSharedTemplate = false, isSharedTemplateFromPage = false, setDocsAlreadyFetching, isV2 = false }) => {
  const { t, oneDriveAuthToken, setOneDriveAuthToken, gDriveAuth, setGDriveAuth, dropboxAccessToken, setDropboxAccessToken, dropboxCode } = useContext(GlobalContext);
  const statuses = [
    'empty',
    'draft',
    'to_validate',
    'validated',
    'ready',
    // 'in-progress',
    // 'closed',
    // 'expired',
    // 'canceled'
  ]
  const statusesForUploadedDoc = [
    'empty',
    'draft',
    'validated',
    'to_validate',
  ]
  const statusLabels = { // todo localize
    'empty': '',
    'ready': t('status.ready'),
    'draft': t('status.draft'),
    'validated': t('status.validated'),
    'to_validate': t('status.to_validate'),
    // 'in-progress': t('status.in_progress'),
    // 'closed': t('status.closed'),
    // 'expired': t('status.expired'),
    // 'canceled': t('status.canceled')
  }
  const statusLabelsForUploadedDoc = {
    'empty': '-',
    'draft': t('status.draft'),
    'validated': t('status.validated'),
    'to_validate': t('status.to_validate'),
  }
  const history = useHistory();
  const { documents, templates, templatesLoaded, updateDocument, documentsLoaded, fetchDocs, getSingleTemplate, getSingleDocument } = useContext(DocumentsContext)
  const { variablesFetched, fetchVariables } = useContext(VariablesContext)
  const [sidePanelOpened, setSidePanelOpened] = useState(true);
  const [view, setView] = useState('variables');
  const [showModal, setShowModal] = useState(false);
  const [showSynthesisModal, setShowSynthesisModal] = useState(false);
  const [historyModal, setHistoryModal] = useState({
    isOpen: false,
    isLoading: false,
    history: {}
  });
  const [templateHistoryModal, setTemplateHistoryModal] = useState({
    isOpen: false,
    isLoading: true,
    history: []
  })
  const [documentName, setDocumentName] = useState('');
  const [documentObject, setDocumentObject] = useState(null)
  const [documentId, setDocumentId] = useState('')
  const [templateId, setTemplateId] = useState('')
  const [templateObject, setTemplateObject] = useState(null)
  const [documentValues, setDocumentValues] = useState({})
  const [documentStatus, setDocumentStatus] = useState('draft')
  const [documentAttachments, setDocumentAttachments] = useState([])
  const { setLoading, loading } = useContext(LoaderContext);
  const [totalProgress, setTotalProgress] = useState(0);
  const [previewDocumentModal, setPreviewDocumentModal] = useState(false);
  const bodyWrapEl = useRef();
  const [showSignDocModal, setShowSignDocModal] = useState(false);
  const [showDocDeleteAlert, setShowDocDeleteAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusesOptions, setStatusesOptions] = useState(statuses.map((s) => ({ value: s, label: statusLabels[s], active: s === 'draft' })));
  const { signatures } = useContext(SignatureContext);
  const scrollingIndicator = useRef();
  const [activeVariable, setActiveVariable] = useState('');
  const { user, setUser } = useContext(UserContext)
  const [scrollingPosition, setScrollingPosition] = useState(0);
  const [pdfPreviewData, setPdfPreviewData] = useState(null);
  const [prevScrollPosition, setPrevScrollPosition] = useState(window.scrollY);
  const [docPages, setDocPages] = useState([]);
  const [variableEls, setVariableEls] = useState(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [updateVariableEls, setUpdateVariableEls] = useState(false);
  const [templateObjectWithUniqueVarIndexes, setTemplateObjectWithUniqueVarIndexes] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [scrollingToItem, setScrollingToItem] = useState(false);
  const [docInformations, setDocInformations] = useState({});
  const [docInformationsDefault, setDocInformationsDefault] = useState({});
  const [docDates, setDocDates] = useState({});
  const [docDatesDefault, setDocDatesDefault] = useState({});
  const [docTasks, setDocTasks] = useState([]);
  const { setNotification } = useContext(NotificationContext);
  const { state } = history.location;
  const [loaded, setLoaded] = useState(false);
  const { tags, setTags } = useContext(TagContext);
  const [previewModeUploaded, setPreviewModeUploaded] = useState(false);
  const [uploadedDocData, setUploadedDocData] = useState(null);
  const [uploadedDocPages, setUploadedDocPages] = useState([]);
  const [updateSection, setUpdateSection] = useState({
    dates: false,
    info: false,
    contacts: false,
    alert: false,
    tasks: false,
  });
  const [uploadingToCloud, setUploadingToCloud] = useState(false);
  const [showOneDriveModal, setShowOneDriveModal] = useState(false);
  const [showUploadToCloudPopover, setShowUploadToCloudPopover] = useState(false);
  const [googleAuthToken, setGoogleAuthToken] = useState(null);
  const [showGDriveModal, setShowGDriveModal] = useState(false);
  const [signedInToGoogle, setSignedInToGoogle] = useState(false);
  const [showDropboxModal, setShowDropboxModal] = useState(false);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext);
  const [docRestored, setDocRestored] = useState(false);

  const [comments, setComments] = useState(null)
  const [newComments, setNewComments] = useState(null)
  const [lastFetchedCommentTime, setLastFetchedCommentTime] = useState(0)
  const [lastFetchTime, setLastFetchTime] = useState(0)
  const [userDefaultCustomFields, setUserDefaultCustomFields] = useState([])
  const [userCustomFields, setUserCustomFields] = useState([])
  const [totalEditTime, setTotalEditTime] = useState(0)
  const [editStartTime, setEditStartTime] = useState(null)
  const [currentSessionEditTime, setCurrentSessionEditTime] = useState(0)
  const [windowVisibility, setWindowVisibility] = useState('visible')
  const [showInjectModal, setShowInjectModal] = useState(false)
  const [showNotariesModal, setShowNotariesModal] = useState(false)
  const [showAddEditNotaryModal, setShowAddEditNotaryModal] = useState(false)
  const [notaryMode, setNotaryMode] = useState('add')
  const [selectedNotary, setSelectedNotary] = useState(null)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showContactsModal, setShowContactsModal] = useState(false)
  const [isSharedTemplateDisabled, setIsSharedTemplateDisabled] = useState(false)
  const [isSharedTemplateLinkInvalid, setIsSharedTemplateLinkInvalid] = useState(false)
  const [currentContactVariable, setCurrentContactVariable] = useState(null)
  const [docCustomFieldsValues, setDocCustomFieldsValues] = useState({})
  const [docDefaultCustomFieldsValues, setDocDefaultCustomFieldsValues] = useState({})
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [collectionsFetched, setCollectionsFetched] = useState(false)
  const [singleDoc, setSingleDoc] = useState(false)
  const [makeContentEditable, setMakeContentEditable] = useState(false)
  const [docNotFound, setDocNotFound] = useState(false)
  const [sharedDoc, setSharedDoc] = useState(null)
  const [documentFormulas, setDocumentFormulas] = useState([])
  const [documentCheckboxValues, setDocumentCheckboxValues] = useState({})

  const params = useParams()

  const [partialContentChanges, setPartialContentChanges] = useState([])

  // Fetch document and template if they are not fetched
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!documentsLoaded) {        
        let path = history.location.pathname.substring(1, history.location.pathname.length)
        let components = path.split('/')
        if(components[0] === 'document-detail') {
          const docId = params.documentId
          const res = await getSingleDocument(docId, true)
          if(!res) return setDocNotFound(true)

          if(!templatesLoaded && !res.uploaded) {
            await getSingleTemplate(res.template, true)
          }
          setSingleDoc(true)
          setLoading(false)
          setLoaded(true)
        } else if(components[0] === 'template') {
          const tempId = params.templateId
          if(!templatesLoaded) {
            await getSingleTemplate(tempId, true)
          }
          setSingleDoc(true)
          setLoading(false)
          setLoaded(true)
        }
      }
      if(!variablesFetched) {
        fetchVariables(selectedTeam)
      }
      setCollectionsFetched(true)
    }
    if(!isSharedTemplate && !isSharedTemplateFromPage && selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, documents, templates, fetchingCollections, documentsLoaded, templatesLoaded, params, getSingleDocument, getSingleTemplate, isSharedTemplateFromPage, isSharedTemplate, setLoading, variablesFetched, fetchVariables, history.location.pathname])
  
  useEffect(() => {

    setLastFetchTime(moment().valueOf())
    const refreshIntervalLength = 5000
    let refreshInterval = setInterval(() => {
      setLastFetchTime(moment().valueOf())
    }, refreshIntervalLength)
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    if(!isSharedTemplate && !isSharedTemplateFromPage) {
      fetchComments(lastFetchedCommentTime)
      refreshUser()
    }
  }, [lastFetchTime, isSharedTemplate, isSharedTemplateFromPage])

  useEffect(() => {
    if(!newComments) {
      return
    }
    let c = comments
    if(!c) {
      c = []
    }
    let nc
    if(newComments.length > 0) {
      nc = [
        ...newComments,
        ...c
      ]
    } else {
      nc = c
    }
    setComments([...new Set(nc)])
  }, [newComments])

  useEffect(() => {
    if(comments && comments.length > 0) {
      setLastFetchedCommentTime(comments[0].created)
    }
  }, [comments])

  const fetchComments = async (last) => {
    if(!documentId) {
      return
    }
    let data = await fetch_document_comments(documentId, last)
    setNewComments(data)
    setLoaded(true)
  }

  const refreshUser = async () => {
    if(!user) {
      return
    }
    const u = await fetch_user(user.id)
    setUser({...u, id: user.id})
  }

  const newCommentsCount = useCallback(() => {
    if(!user || !user.unread_comments || !comments) {
      return 0
    }
    let count = 0
    for(let c of comments) {
      if(user.unread_comments.includes(`${documentId}.${c.id}`)) {
        count++
      }
    }
    return count
  }, [comments, documentId, user])

  // On mount/Cleanup
  useEffect(() => {
    if(config.environment === 'development' && !document.getElementById('google-api-script')) {
      const script = document.createElement('script');
      script.onload = onGapiLoad;
      script.src ="https://apis.google.com/js/api.js";
      script.id = 'google-api-script';
      document.body.appendChild(script);
    }

    return () => {
      if(savedTimeout) {
        clearTimeout(savedTimeout);
      }
      if(scrollToItemTimeout) {
        clearTimeout(scrollToItemTimeout);
      }
    }
  }, []);

  // Add scroll event
  useEffect(() => {
    window.addEventListener('scroll', detectElementScrollPosition);
    if(bodyWrapEl.current && !variableEls) {
      const docVariableEls = bodyWrapEl.current.querySelectorAll('.doc-variable-el');
      setVariableEls(docVariableEls);
    }

    return () => window.removeEventListener('scroll', detectElementScrollPosition);
    // eslint-disable-next-line
  }, [bodyWrapEl.current, variableEls]);

  // Update variable elements when condition is met / when multiselect head is clicked
  useEffect(() => {
    async function updateEls() {
      const docVariableEls = await bodyWrapEl.current.querySelectorAll('.doc-variable-el');
      setVariableEls(docVariableEls);
      setUpdateVariableEls(false);
    }
    if(updateVariableEls && bodyWrapEl.current) {
      updateEls();
    }
  }, [updateVariableEls]);

  // Set window.scrollY to scrollingPosition
  const detectElementScrollPosition = (e) => {
    if(!bodyWrapEl.current) {
      return
    }
    const scrollYPos = window.scrollY;
    setScrollingPosition(scrollYPos);
    // const previewEl = bodyWrapEl.current.querySelector('.preview-doc')
    // if(previewEl) {
    //   // console.log(window.scrollY)
    //   const pdfPages = previewEl.querySelector('[data-page-number]')
    //   if(pdfPages) {
    //     console.log(pdfPages.getBoundingClientRect().top, bodyWrapEl.current.offsetTop)
    //   }
    // }
  }

  useEffect(() => {
    if(scrollingPosition > 100) {
      setShowBackToTop(true);
    }else {
      setShowBackToTop(false);
    }

    const pageHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if(scrollingIndicator.current) {
      scrollingIndicator.current.style.width = `${(scrollingPosition / pageHeight) * 100}%`;
    }

    // eslint-disable-next-line
  }, [scrollingPosition, variableEls]);
  
  useEffect(() => {    

    const processData = async () => {
      // get document id from path
      let path = history.location.pathname.substring(1, history.location.pathname.length)
      const queryStringVariables = qs.parse(history.location.search, { ignoreQueryPrefix: true })

      let prefillValues = queryStringVariables
      prefillValuesWithAdminData(prefillValues)

      let components = path.split('/')
      if(components.length === 1 || components[0] === 'template' || components[0] === 'shared-template' || components[0] === 'shared-templates' || components[components.length - 1] === '') {
        if(isSharedTemplate) {
          let sharedTemplateId
          if(components[0] === 'shared-template') {
            sharedTemplateId = components[1]
            const template = await get_single_shared_template(sharedTemplateId)
            if(template) {
              setDocumentName(template.name)
              setTemplateId(sharedTemplateId)
              setTemplateObject(template)
              setDocumentObject({})
              setDocumentAttachments([])
              setDocumentValues(prefillValues)
              setDocumentCheckboxValues({})
              const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes({...template})
              setTemplateObjectWithUniqueVarIndexes(copyOfTemplate)
              setLoaded(true)
              setLoading(false)
              setIsSharedTemplateDisabled(template.disabled)
            }else {
              setIsSharedTemplateLinkInvalid(true)
            }
          }
        }else if(isSharedTemplateFromPage) {
          if(components[0] === 'shared-templates') {
            let sharedPageId = components[1]
            let sharedTemplateId = components[2]
            const isPageDisabled = await check_if_shared_template_page_disabled(sharedPageId)
            if(isPageDisabled) {
              setLoaded(true)
              setLoading(false)
              setIsSharedTemplateDisabled(true)
              setTemplateObject({})
              setDocumentObject({})
            }else {
              const template = await get_shared_template(sharedTemplateId)
              if(template) {
                setDocumentName(template.name)
                setTemplateId(sharedTemplateId)
                setTemplateObject(template)
                setDocumentObject({})
                setDocumentAttachments([])
                setDocumentValues(prefillValues)
                setDocumentCheckboxValues({})
                const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes({...template})
                setTemplateObjectWithUniqueVarIndexes(copyOfTemplate)
                setLoaded(true)
                setLoading(false)
                setIsSharedTemplateDisabled(template.disabled)
              }else {
                setIsSharedTemplateLinkInvalid(true)
              }
            }
          }
        }else {
          if(Object.keys(templates).length === 0) {
            return
          }
  
          // console.log('components', components)
          let templateId
          let templateValues = history.location.state?.tmplValues
          if(components[0] === 'template') {
            // opened using template id
            // console.log("opened using template id")
            templateId = components[1]
            // console.log("template id", templateId)
          } else {
            // no id
            // get template id
            if(!history.location.state) {
              // no template id, go back I guess
              return
            }
            templateId = history.location.state.templateId
          }
          if(!templateId) {
            // no template id, go back I guess
            return
          }
          let template = templates[templateId]
          if(!template) {
            // templates not loaded
            alert(t('alert.template_doesnt_exist'))
            if(!isV2) {
              history.push('/templates')
            }else {
              history.push('/templates-v2')
            }
            return
          }

          if(!template.sections) {
            template = await getSingleTemplate(templateId, true)
          }

          setDocumentName(template.name)
          setTemplateId(templateId)
          setTemplateObject(template)
          setDocumentObject({})
          setDocumentAttachments([])
          if(templateValues) {
            setDocumentValues({...prefillValues, ...templateValues})
          }else {
            setDocumentValues(prefillValues)
          }

          setDocumentCheckboxValues({})
          const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes({...template});
          setTemplateObjectWithUniqueVarIndexes(copyOfTemplate);
          setLoaded(true);
          setTemplateHistoryModal({ ...templateHistoryModal, history: template.versionNotes || [] })
        }

      } else {
        if(Object.keys(documents).length === 0) {
          return
        }
        // document id in path, display its data
        let docId = components[components.length - 1]
        let docObject = documents[docId]
        if(!docObject) {
          return
        }
        if(!docObject.uploaded && !singleDoc && templatesLoaded && Object.keys(templates).length === 0) {
          return
        }
        let template = templates[docObject.template]
        if(templatesLoaded && !template && !docObject.uploaded) {
          // templates not loaded
          if(!isV2) {
            history.push('/templates')
          }else {
            history.push('/templates-v2')
          }
          alert(t('alert.template_doesnt_exist'))
          return
        }
        if(!docObject.uploaded && !template?.sections) {
          template = await getSingleTemplate(docObject.template, true)
        }
        if(!template && !docObject.uploaded) {
          if(!isV2) {
            history.push('/templates')
          }else {
            history.push('/templates-v2')
          }
          alert(t('alert.template_doesnt_exist'))
          return
        }
        setDocumentId(docId)
        setDocumentObject(docObject)
        setDocumentValues(joinValues(docObject.values || {}, prefillValues))
        setDocumentCheckboxValues(docObject.checkboxValues || {})
        setDocumentName(docObject.name || '')
        setTemplateId(docObject.template)
        setTemplateObject(template)
        setDocumentStatus(docObject.status || 'draft')
        setDocumentAttachments(docObject.attachments || [])
        setDocInformations(docObject.info || {})
        setDocInformationsDefault(docObject.info || { currency: "euro", keywords: [] })
        setDocDates(docObject.dates || {})
        setDocDatesDefault(docObject.dates || {})
        setDocTasks(docObject.tasks || [])
        setTotalEditTime(docObject.total_edit_time || 0)
        let docStatuses = statuses
        let docStatusLabels = statusLabels
        setStatusesOptions(docStatuses.map((s) => ({ value: s, label: docStatusLabels[s], active: s === docObject.status })))
        setDocCustomFieldsValues(docObject.custom_fields_values || {})
        setDocDefaultCustomFieldsValues(docObject.custom_fields_values ? JSON.parse(JSON.stringify(docObject.custom_fields_values)) : {})

        setMakeContentEditable(docObject.content_editable || false)

        if(docObject.content_editable || makeContentEditable) {
          template = applyContentChangesToTemplate(template, buildContentChanges(partialContentChanges, template, docObject.content_changes || []))
        }

        if(!docObject.uploaded) {
          const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes({...template});
          setTemplateObjectWithUniqueVarIndexes(copyOfTemplate);
        }
        if(state && (state.openTasks || state.openDates || state.openContacts || state.openAlert || state.openAttachments || state.openTags || state.openComments)) {
          // set view 
          if(state.openTasks) {
            setView('tasks');
          }else if(state.openDates) {
            setView('dates');
          }else if(state.openContacts) {
            setView('contacts');
          }else if(state.openAlert) {
            setView('alert');
          }else if(state.openAttachments) {
            setView('appendices');
          }else if(state.openTags) {
            setView('tags');
          }else if(state.openComments) {
            setView('comments')
          }
        }else {
          setView(!docObject.uploaded ? view ? view : 'variables' : view !== 'variables' ? view : 'preview');
        }
        if(state && state.cloudUpload) {
          setUploadingToCloud(true);
        }
        setLoaded(true)
        if(!docObject.uploaded) {
          setTemplateHistoryModal({ ...templateHistoryModal, history: template.versionNotes || [] })
        }
      }
    }

    if(!isSharedTemplate && !isSharedTemplateFromPage && collectionsFetched) {
      processData()
    }else if(isSharedTemplate || isSharedTemplateFromPage){
      processData()
    }

    // eslint-disable-next-line
  }, [documents, templates, signatures, state, singleDoc, collectionsFetched]);

  useEffect(() => {
    if(history.location.state && history.location.state.refreshTemplate) {
      setDocumentId('')
    }

    // eslint-disable-next-line
  }, [history.location])

  // Set custom fields
  useEffect(() => {
    if(selectedTeam?.custom_fields) {
      setUserCustomFields(selectedTeam.custom_fields)
      setUserDefaultCustomFields(JSON.parse(JSON.stringify(selectedTeam.custom_fields)))
    }
  }, [selectedTeam])

  // Check if onedrive upload modal can be displayed
  // useEffect(() => {
  //   if(uploadingToCloud && oneDriveAuthToken) {
  //     setShowOneDriveModal(true);
  //   }
  // }, [uploadingToCloud, oneDriveAuthToken]);

  const prefillValuesWithAdminData = (values) => {
    if(!user || !user.manufacturer) {
      return
    }
    values.manufacturers_name = user.manufacturer.name || ''
    values.manufacturers_contact_firstname = user.firstname || ''
    values.manufacturers_contact_name = user.lastname || ''
    values.adresse = user.manufacturer.address || ''
    values.postal_code = user.manufacturer.postal_code || ''
    values.city = user.manufacturer.city || ''
    values.telephone = user.manufacturer.phone || ''
    values.email = user.manufacturer.email || ''
    values.manufacturers_description = user.manufacturer.description || ''
    values.admin_fonction = user.fonctions.length > 0 ? user.fonctions[0].name || '' : ''
  }

  const joinValues = (custom, prefill) => {
    let values = {...custom}
    for(let key in prefill) {
      if(!values[key]) {
        values[key] = prefill[key]
      }
    }
    return values
  }

  const buildContentChanges = useCallback((partialContentChanges, source, existingContentChanges) => {
    const contentChangesMap = {}
    const contentChanges = existingContentChanges || []
    // console.log('build content changes', partialContentChanges, contentChanges)
    for(let partialChange of partialContentChanges) {
      let key = `${partialChange.sectionIndex}_${partialChange.listIndex}`
      if(!contentChangesMap[key]) {
        let fullOriginalContent
        // const source = templateObjectWithUniqueVarIndexes
        let section = source.sections[partialChange.sectionIndex]
        for(let c of contentChanges) {
          if(c.sectionIndex === partialChange.sectionIndex && c.listIndex === partialChange.listIndex) {
            fullOriginalContent = c.content
            break
          }
        }
        if(!fullOriginalContent) {
          if(partialChange.listIndex !== -1) {
            fullOriginalContent = section.items[partialChange.listIndex].content
          } else {
            fullOriginalContent = section.content
          }
        }
        contentChangesMap[key] = {
          sectionIndex: partialChange.sectionIndex,
          listIndex: partialChange.listIndex,
          content: fullOriginalContent
        }
      }
      contentChangesMap[key] = mergePartialContentChange(contentChangesMap[key], partialChange)
    }
    for(let key in contentChangesMap) {
      let isNew = true
      for(let i in contentChanges) {
        if(contentChanges[i].sectionIndex === contentChangesMap[key].sectionIndex && contentChanges[i].listIndex === contentChangesMap[key].listIndex) {
          isNew = false
          contentChanges[i] = contentChangesMap[key] 
          break
        }
      }
      if(isNew) {
        contentChanges.push(contentChangesMap[key])
      }
    }
    return contentChanges
  }, [])

  // Update progress when component is mounted
  useEffect(() => {
    if(templateObject && Object.keys(documentValues).length > 0) {
      // console.log(progress())
      setTotalProgress(progress())
    }
  }, [documentValues]);

  useEffect(() => {
    if(!templateObject || !templateObject.sections) {
      return
    }
    let formulas = []
    for(let s of templateObject.sections) {
      if(s.type === 'text' || s.type.includes('heading')) {
        formulas.push(...findFormulasInSection(s))
      } else if(s.type === 'table') {
        for(let c of s.head) {
          formulas.push(...findFormulasInSection(c))
        }
        for(let c of s.row) {
          formulas.push(...findFormulasInSection(c))
        }
      }
    }
    setDocumentFormulas([...formulas])
  }, [templateObject])

  const findFormulasInSection = (section) => {
    if(!section.content) {
      return []
    }
    let formulaRegex = /\{f\(([^}]*)\}/g
    // let fieldRegex = /\{d\.([^}]*)\}|\{f\(([^}]*)\}/g
    let matches = section.content.match(formulaRegex)
    let formulas = []
    if(matches?.length > 0) {
      matches.forEach(m => {       
        formulas.push({ handle: m, repeatableId: section.repeatable_section_id })
      })
    }
    return formulas
  }

  const insertFormulaResults = useCallback((values) => {
    let results = {...values}
    for(let f of documentFormulas) {
      if(f.repeatableId) {
        if(!results[f.repeatableId]) {
          continue
        }
        for(let i in results[f.repeatableId]) {
          let { key, value } = formulaResult(documentValues, f.handle, f.repeatableId, i)
          results[f.repeatableId][key] = value
        }
      } else {
        let { key, value } = formulaResult(documentValues, f.handle)
        results[key] = value
      }
    }

    return results
  }, [documentFormulas, documentValues])

  const completeDocumentValues = useCallback(() => {
    let values = {
      ...documentValues
    }
    values = insertFormulaResults(values)
    return values
  }, [documentValues, insertFormulaResults])

  // added doc argument to make fetchDocumentData calls consistent regardless of which component they're passed from
  const fetchDocumentData = useCallback(async (doc, extension) => {
    let docData = await generate_document(templateObject.id, completeDocumentValues(), { convertTo: extension, checkboxValues: documentCheckboxValues }, makeContentEditable ? buildContentChanges(partialContentChanges, templateObjectWithUniqueVarIndexes, documentObject?.content_changes || []) : [], selectedTeam)
    if(docData.error) {
      return null
    }
    return docData
  }, [partialContentChanges, templateObjectWithUniqueVarIndexes, documentObject, buildContentChanges, documentCheckboxValues, selectedTeam, makeContentEditable, completeDocumentValues, templateObject?.id])

  // Document preview
  const fetchDocumentPreview = useCallback(async (action, extension = 'pdf') => {
    setLoading(true)
    if(!isSharedTemplate && !isSharedTemplateFromPage) {
      let events = [eventTypeForAction(action)]
      if(action === 'download')
      events.push(extension === 'docx' ? EVENT_TYPES.DOCUMENT_DOWNLOAD_DOCX : EVENT_TYPES.DOCUMENT_DOWNLOAD_PDF)
      log_event(events)
    }
    let documentData;
    try {
      if(!documentObject.uploaded) {
        if(isSharedTemplate || isSharedTemplateFromPage) {
          const data = await generate_document_for_shared_templates(templateObject.templateUrl, completeDocumentValues(), extension)
          documentData = data.data
        }else {
          documentData = await fetchDocumentData(documentObject, extension)
        }
      }else {
        if(documentObject.documentUrls.pdf) {
          const res = await get_file({ url: documentObject.documentUrls.pdf })
          documentData = res.data;
        }else {
          const res = await convert_attachment(documentObject.documentUrls.docx)
          documentData = res.data;
        }
      }
      setLoading(false)
      if(!documentData) {
        // todo display error?
        return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      }
      // download document
      if(action === 'download') {
        if(isIOS && extension === 'pdf') {
          const blob = base64toBlob(documentData)
          const a = document.createElement('a')
          a.onclick = saveAs(blob, `${documentName || 'document'}.pdf`)
        }else {
          const a = document.createElement("a")
          a.href = `data:${mimeTypeForExtension(extension)};base64,${documentData}` 
          a.download = `${documentName || 'document'}.${extension}`
          a.click()
        }
      } else if(action === 'preview') {
        let base64Data = `data:application/pdf;base64,${documentData}`
        // Watermark
        // const pdfDoc = await PDFDocument.load(base64Data)
  
        // const pages = pdfDoc.getPages()
  
        // for(let i = 0; i < pages.length; i++) {
        //   const page = pages[i]
        //   const { width, height } = page.getSize()
        //   const pngUrl = carbonLogo
    
        //   const pngImage = await pdfDoc.embedPng(pngUrl)
    
        //   page.drawImage(pngImage, {
        //     x: width / 2 - 50,
        //     y: height / 2 - 50,
        //     width: pngImage.width,
        //     height: pngImage.height,
        //     opacity: 0.5,
        //   })
        // }
  
        // const pdfBytes = await pdfDoc.saveAsBase64()
        // base64Data = `data:application/pdf;base64,${pdfBytes}`

        setPdfPreviewData(base64Data);
        // setIframeSrc(`data:application/pdf;base64,${documentData}`);
        setPreviewDocumentModal(true);
      }
      // store the latest generated document in storage. Todo check if values changed
      if(!isSharedTemplate && !isSharedTemplateFromPage && documentId && !documentObject.uploaded) {
        let uploadResult = await upload_file(documentData, `${documentId}`, extension, mimeTypeForExtension(extension), 'documents')
        if(!uploadResult.url) {
          return
        }
        let documentUpdates = {}   
        documentUpdates[`documentUrls.${extension}`] = uploadResult.url
        documentUpdates.last_modified_by = activeTeamMember.id
        documentUpdates.create_action = 'yes'
        documentUpdates.checkboxValues = documentCheckboxValues
        await updateDocument(documentUpdates, {...documentObject, id: documentId })
        // await update_document(documentId, documentUpdates, documentAttachments)
      }    
    } catch (err) {
      console.log(err);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoading(false);
    }
  }, [activeTeamMember, documentId, documentName, documentObject, fetchDocumentData, isSharedTemplate, isSharedTemplateFromPage, setLoading, setNotification, updateDocument, t])

  const shareClickHandler = useCallback(async () => {
    if(!documentId) {
      alert(t('alert.document_save_before_share'))
      return
    }
    setLoading(true)
    if(!documentObject.documentUrls) {
      await fetchDocumentPreview('share')
    }
    setLoading(false)
    setShowModal(true);
  }, [documentId, documentObject?.documentUrls, fetchDocumentPreview, setLoading])

  const synthesisClickHandler = useCallback(() => {
    setShowSynthesisModal(true);
  }, [])

  const historyClickHandler = useCallback(async () => {
    setHistoryModal({
      isOpen: true,
      isLoading: true,
      history: {}
    })
    let history = await fetch_document_history(documentId)
    setHistoryModal({
      isOpen: true,
      isLoading: false,
      history: history
    })
  }, [documentId])

  const hideHistoryModal = () => {
    setHistoryModal({
      isOpen: false,
      isLoading: false,
      history: {}
    })
  }

  const showTemplateHistoryModal = useCallback((e) => {
    e.preventDefault()
    setTemplateHistoryModal({
      ...templateHistoryModal,
      isOpen: true
    })
  }, [templateHistoryModal])

  const hideTemplateHistoryModal = () => {
    setTemplateHistoryModal({
      ...templateHistoryModal,
      isOpen: false,
      isLoading: true,
    })
  }

  const restoreVersion = (version) => {
    // console.log(version)
    let updates = {}
    setDocumentValues(version.values)
    setDocumentCheckboxValues(version.checkboxValues || {})
    setDocumentName(version.name)
    updates.values = version.values
    updates.name = version.name
    hideHistoryModal()
    setDocRestored(true)
    // setView(documentObject?.uploaded ? 'appendices' : 'variables')
  }

  const documentNameChangeHandler = useCallback(e => {
    setDocumentName(e.target.value);
  }, [])

  const mimeTypeForExtension = (extension) => {
    switch(extension) {
      case 'pdf':
        return 'application/pdf'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      default:
        return ''
    }
  }

  // On page change - only for uploaded documents
  const handleOnPageChange = useCallback((page) => {
    if(!bodyWrapEl.current) return 
    const element = bodyWrapEl.current.querySelector(`.preview-doc`)
    if(element) {
      const pdfPageEl = element.querySelector(`[data-page-number="${page + 1}"]`)
      if(pdfPageEl) {
        window.scrollTo({top: pdfPageEl.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"})
      }
    }
  }, [])

  // On preview page click
  const handlePreviewPageClick = useCallback((e, p) => {
    if(e.target && !e.target.closest('.document-detail-sp-preview')) return 
    handleOnPageChange(p.pageNumber - 1)
  }, [handleOnPageChange])

  const onDocumentLoadSuccess = useCallback((numPages, uploaded = false) => {
    const arr = [];
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page key={i} pageNumber={i} width={uploaded ? 1000 : 870} renderTextLayer={true} loading="" onClick={handlePreviewPageClick} id={`preview-page-${i}`} />);
    }
    if(uploaded) {
      setUploadedDocPages(arr);
    }else {
      setDocPages(arr);
    }
  }, [handlePreviewPageClick])

  // Set active pdf page 
  useEffect(() => {
    let observer
    if(bodyWrapEl.current && uploadedDocPages.length > 0) {
      // console.log('create observer for thumbs***')
      const previewEl = bodyWrapEl.current.querySelector('.preview-doc')
      if(previewEl) {
        const pdfPages = previewEl.querySelectorAll('[data-page-number]')
        setTimeout(() => {
          let firstThumb = document.querySelector(`.document-detail-sp-preview__thumbs .react-pdf__Page[data-page-number="1"]`)
          if(firstThumb) {
            firstThumb.classList.add('active')
          }
        }, 100)
        if(pdfPages.length > 0) {
          observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
              const thumb = document.querySelector(`.document-detail-sp-preview__thumbs .react-pdf__Page[data-page-number="${entry.target.getAttribute('data-page-number')}"]`)
              if(thumb) {
                if (entry.isIntersecting) {
                  if (!thumb.classList.contains('active')) {
                    thumb.classList.add('active')
                  }
                } else if (thumb.classList.contains('active')) {
                  thumb.classList.remove('active')
                }
              }
            });
          }, { threshold: 0.5 })
          pdfPages.forEach(image => {
            observer.observe(image)
          })
        }
      }
    }
    return () => {
      if(observer) {
        observer.disconnect()
      }
    }
  }, [uploadedDocPages, bodyWrapEl])

  const eventTypeForAction = (action) => {
    switch(action) {
      case 'preview':
        return EVENT_TYPES.DOCUMENT_PREVIEW
      case 'download':
        return EVENT_TYPES.DOCUMENT_DOWNLOAD
      default: 
        return ''
    }
  }

  // Get field names from type="text" content field
  const getVarKeysFromContent = (text) => {
    let source = text
    let fieldRegex = /\{d\.([^}]*)\}/g
    let fields = text.match(fieldRegex)
    let items = []
    for(let i in fields) {
      let components = source.split(fields[i])
      let handle = fields[i]
      items.push(handle.substring(3, handle.length - 1))
      source = components[1]
    }
    if(source) {
      items.push(source)
    }
    return items
  }

  const extractVariablesFromSections = useCallback((sections, active, full = false) => {
    let variables = []
    for(let i in sections) {
      let section = sections[i]
      if(active && (section.condition || section.conditions) && !areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesFromSections(section.sections)
        for(let i in vars) {
          variables.push(vars[i])
        }
      }
      if(section.type === 'question') {
        if(full) {
          variables.push({ variable: section.variable, type: section.data_type, options: section.options })
        } else {
          variables.push(section.variable)
        }
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {          
          variables.push(section.variables[vi].variable)
        }
      } else if(section.type === 'bullet_list') {
        for(let bi in section.items) {
          for(let vi in section.items[bi].variables) {
            variables.push(section.items[bi].variables[vi].variable)
          }
        }
      }
    }
    let uniqueVariables = [...new Set(variables)];
    return uniqueVariables
  }, [documentValues])

  const activeVariableKeys = useCallback(() => {
    return extractVariablesFromSections(templateObject.sections, true)
  }, [extractVariablesFromSections, templateObject])

  const extractVariablesWithTypesFromSections = useCallback((sections) => {
    let variablesWithTypes = []
    for(let i in sections) {
      let section = sections[i]
      if((section.condition || section.conditions) && !areSectionConditionsMet(section, documentValues)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesWithTypesFromSections(section.sections)
        for(let i in vars) {
          variablesWithTypes.push(vars[i])
        }
      }
      if(section.type === 'question') {
        variablesWithTypes.push({variable: section.variable, type: section.data_type})
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {
          variablesWithTypes.push({variable: section.variables[vi].variable, type: section.variables[vi].type})
        }
      }
    }
    let uniqueVariables = [...new Set(variablesWithTypes)];
    return uniqueVariables
  }, [documentValues])

  const extractVariableTypesFromSections = useCallback((sections) => {
    let variablesWithTypes = extractVariablesWithTypesFromSections(sections)
    let types = {}
    for(let i in variablesWithTypes) {
      types[variablesWithTypes[i].variable] = variablesWithTypes[i].type
    }
    return types
  }, [extractVariablesWithTypesFromSections])

  const progress = useCallback(() => {
    let p = 0;
    let variableKeys = activeVariableKeys()
    if(variableKeys) {
      for(let i in variableKeys) {
        if(documentValues[variableKeys[i]] !== undefined && (typeof documentValues[variableKeys[i]] !== 'string' || documentValues[variableKeys[i]] !== '')) {
          p += 1 / variableKeys.length
        }
      }
    }
    return Math.min(1, p)
  }, [activeVariableKeys, documentValues])

  // Check if custom fields values changed
  const didCustomFieldsValuesChanged = useMemo(() => {
    if(Object.keys(docDefaultCustomFieldsValues).length !== Object.keys(docCustomFieldsValues).length) {
      return true
    }else {
      for(let key in docDefaultCustomFieldsValues) {
        let defaultValue = docDefaultCustomFieldsValues[key]
        let currentValue = docCustomFieldsValues[key]
        if(!Array.isArray(defaultValue)) {
          if(defaultValue !== currentValue) {
            return true 
          }
        }else { // for multiselect field
          if(defaultValue.length !== currentValue.length) {
            return true
          } else {
            let changed = false
            for(let i = 0; i < currentValue.length; i++) {
              if(!defaultValue.includes(currentValue[i])) {
                changed = true 
                break
              }
            }
            if(changed) return true
          }
        }
      }
    }

    return false 
  }, [docDefaultCustomFieldsValues, docCustomFieldsValues])

  const getDocumentUpdates = useCallback(async () => {

    let documentUpdates = {}
    let tagsCreated = false
    let keywordsAdded = false
    const keywords = []

    const docInfo = {...docInformations}
    if(docInfo.tags) {
      delete docInfo.tags
    }
    if(docInfo.category) {
      delete docInfo.category
    }
    
    // Check if tags are added
    if(docInformations.tags && updateSection.info) {
      if(docInformations.tags.length > 0) {
        for(let i = 0; i < docInformations.tags.length; i++) {
          const tagId = docInformations.tags[i].id
          if(!tags.find(t => t.id === tagId)) {
            const res = await create_tag({ name: docInformations.tags[i].name, team: selectedTeam?.id })
            tagsCreated = true
            keywords.push({ name: docInformations.tags[i].name, id: res.taskId })
          }else {
            keywords.push({ name: docInformations.tags[i].name, id: tagId })
            keywordsAdded = true
          }
        }
      }else {
        docInfo.keywords = []
      }
    }
    
    // Check if new tags was created or if they exist for this document
    if(tagsCreated || keywordsAdded) {
      const res = await fetch_tags(selectedTeam?.id)
      const tagsArr = []
      for(let id in res) {
        const tag = {...res[id]}
        tag.id = id
        tagsArr.push(tag)
      }
      setTags(tagsArr)
      if(keywords.length > 0) {
        docInfo.keywords = [...keywords].map(k => k.id)
      }
    }
    
    // Set data for document
    if(documentObject.uploaded) {
      documentUpdates = { name: documentName, status: documentStatus }
    }else {
      documentUpdates = { values: documentValues, name: documentName, status: documentStatus, progress: progress() }
    }
    // Update only if values changed
    if(updateSection.dates) {
      documentUpdates.dates = docDates;
    }
    if(updateSection.info) {
      documentUpdates.info = docInfo;
    }

    // Add/edit total edit time
    if(currentSessionEditTime > 0) {
      documentUpdates.total_edit_time = totalEditTime + currentSessionEditTime
      setCurrentSessionEditTime(0)
    }

    // Save custom fields
    if(didCustomFieldsValuesChanged) {
      documentUpdates.custom_fields_values = docCustomFieldsValues
    }

    documentUpdates.content_changes = buildContentChanges(partialContentChanges, templateObjectWithUniqueVarIndexes, documentObject?.content_changes || [])
    documentUpdates.content_editable = makeContentEditable

    documentUpdates.checkboxValues = documentCheckboxValues

    return documentUpdates
  }, [currentSessionEditTime, didCustomFieldsValuesChanged, docCustomFieldsValues, docDates, docInformations, documentName, documentObject, documentStatus, documentValues, progress, selectedTeam, setTags, tags, totalEditTime, updateSection, makeContentEditable, partialContentChanges, templateObjectWithUniqueVarIndexes, buildContentChanges, documentCheckboxValues])

  // Save total edit time if current session edit time is not 0 and user leaves the page without saving
  useEffect(() => {
    const listener = history.listen(async (location) => {
      if(!isSharedTemplate && !isSharedTemplateFromPage && !makeContentEditable && location.pathname !== `/document-detail/${params.documentId}` && currentSessionEditTime > 0 && documentId) {
        let documentUpdates = await getDocumentUpdates()   
        if(!documentsLoaded  && location.pathname === '/documents') {
          updateDocument({ create_action: 'no', ...documentUpdates }, documentObject, true, false, true, selectedTeam?.id)
        }else {
          updateDocument({ create_action: 'no', ...documentUpdates }, documentObject)
        }
      }
    })

    return () => {
      listener()
    }
  }, [currentSessionEditTime, totalEditTime, documentObject, updateDocument, isSharedTemplate, isSharedTemplateFromPage, getDocumentUpdates, selectedTeam, fetchDocs, documentId, documentsLoaded, history, params, setDocsAlreadyFetching, makeContentEditable])

  // Run this before page refresh/tab close
  const onVisibilityChange = useCallback(async () => {
    if(!isSharedTemplate && !isSharedTemplateFromPage && !makeContentEditable && currentSessionEditTime > 0 && document.visibilityState === 'hidden') {
      // console.log('updated** **')
      let documentUpdates = await getDocumentUpdates()
      updateDocument({ create_action: 'no', ...documentUpdates }, documentObject)
      setCurrentSessionEditTime(0)
    }
    setWindowVisibility(document.visibilityState)
  }, [currentSessionEditTime, documentObject, updateDocument, isSharedTemplate, isSharedTemplateFromPage, getDocumentUpdates, makeContentEditable])

  // Before page refresh or tab close
  useEffect(() => {
    // window.addEventListener('unload', beforeUnload)
    document.addEventListener('visibilitychange', onVisibilityChange)
    
    return () => {
      // window.removeEventListener('unload', beforeUnload)
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [onVisibilityChange])

  // On document save
  const saveDocument = useCallback(async () => {
    setLoading(true)
    setSaving(true)
    let documentUpdates = await getDocumentUpdates()
    setPartialContentChanges([])

    // If document id is set update document
    if(documentId) {
      documentUpdates.last_modified_by = activeTeamMember.id
      documentUpdates.create_action = 'yes'
      // await update_document(documentId, documentUpdates, documentAttachments)
      await updateDocument(documentUpdates, {...documentObject, id: documentId, attachments: documentAttachments}, true, true)
      setSaved(true)
      savedTimeout = setTimeout(() => setSaved(false), 2000)
    } else { // if document id is not set create document
      documentUpdates.team = selectedTeam?.id;
      documentUpdates.template = templateId
      if(availableOn(['development'])) {
        documentUpdates.versioning = true // for history version 2
      }
      let response = await create_document(documentUpdates, documentAttachments)
      if(response.success) {
        setDocumentId(response.documentId)
        history.replace(`/document-detail/${response.documentId}`)
        await fetchDocs(selectedTeam?.id)
      }
    }
    setLoading(false)
    setSaving(false)
    setShowSynthesisModal(false)
    setView(view)
  }, [activeTeamMember, documentAttachments, documentId, documentObject, fetchDocs, getDocumentUpdates, history, selectedTeam, setLoading, updateDocument, view])

  const documentValuesChangeHandler = useCallback((variable, value) => {
    setDocumentValues({...documentValues, [variable]: value })
  }, [documentValues])

  const documentCheckboxChangeHandler = (id, value) => {
    setDocumentCheckboxValues({
      ...documentCheckboxValues,
      [id]: value
    })
  }

  const timeElapsed = useCallback((date) => {
    // todo localize
    let now = Date.now()
    let diff = now - date
    let val = diff / 1000
    if(val < 60) {
      return `${val.toFixed(0)} ${t('time.seconds')}`
    }
    val /= 60
    if(val < 60) {
      return `${val.toFixed(0)} ${t('time.minutes')}`
    }
    val /= 60
    if(val < 24) {
      return `${val.toFixed(0)} ${t('time.hours')}`
    }
    val /= 24
    return `${val.toFixed(0)} ${val === 1 ? t('time.day') : t('time.days')}`
  }, [])

  const statusOptions = () => {
    return statuses.map((status, statusIndex) => {
      return (
        <option key={`status_option_${statusIndex}`} value={status}>{ statusLabels[status] }</option>
      )
    })
  }

  const onAddAttachment = useCallback((att) => {
    let attachments = documentAttachments
    attachments.push(att)
    setDocumentAttachments([...attachments])
  }, [documentAttachments])

  const onDeleteAttachment = (attIndex) => {
    let attachments = documentAttachments
    attachments.splice(attIndex, 1)
    setDocumentAttachments([...attachments])
  }

  const onDeleteAttachmentByName = useCallback((name) => {
    const attachments = [...documentAttachments];
    const filteredAttachments = attachments.filter(a => a.name !== name);
    setDocumentAttachments(filteredAttachments)
  }, [documentAttachments])

  const onDeleteAttachmentsByName = (names) => {
    const attachments = [...documentAttachments];
    const filteredAttachments = attachments.filter(a => !names.includes(a.name));
    setDocumentAttachments(filteredAttachments);
  }

  // On variable click
  const variableItemClickHandler = useCallback(async (id) => {
    const element = bodyWrapEl.current.querySelector(`[data-id="${id}"]`);
    if(element) {
      const input = element.querySelector('input') || element.querySelector('textarea');
      if(input) {
        input.focus();
      }
      setScrollingToItem(true);
      window.scrollTo({top: element.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"});
      scrollToItemTimeout = setTimeout(() => setScrollingToItem(false), 1000);
    }
  }, [])
  
  // On section click
  const sectionClickHandler = useCallback((id) => {
    const element = bodyWrapEl.current.querySelector(`[data-index="${id}"]`);
    if(element) {
      setScrollingToItem(true);
      window.scrollTo({top: element.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"});
      scrollToItemTimeout = setTimeout(() => setScrollingToItem(false), 1000);
    }
  }, [])

  // Edit attachment
  const editAttachmentHandler = useCallback(async (docId, attachmentName, index) => {
    const docAttachments = [...documentAttachments];
    // console.log(docAttachments, index);
    const att = {...[...docAttachments][index]};
    att.name = attachmentName;
    docAttachments[index] = att;
    if(docId) {
      await updateDocument({ attachments: docAttachments }, { id: docId });
    }
    setDocumentAttachments(docAttachments);
  }, [documentAttachments, updateDocument])

  // Duplicate document
  const duplicateDocumentHandler = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true)
    let doc = {...documentObject}
    let docCopy = {
      name: t('general.copy_of', { document: doc.name }),
      progress: doc.progress,
      status: doc.status,
      template: doc.template,
      values: doc.values,
      attachments: doc.attachments ? doc.attachments : [],
      team: selectedTeam?.id
    }
    if(doc.uploaded) {
      delete docCopy.template;
      delete docCopy.progress;
      docCopy.uploaded = true
      docCopy.documentUrls = {}
      let documentData;
      let ext;
      if(doc.documentUrls.pdf) {
        const res = await get_file({ url: doc.documentUrls.pdf })
        documentData = res.data;
        ext = 'pdf';
      }else {
        const res = await convert_attachment(doc.documentUrls.docx)
        documentData = res.data;
        ext = 'docx';
      }
      const fileName = `${doc.name}-${Date.now()}`;
      let uploadResult = await upload_file(documentData, fileName, ext, mimeTypeForExtension(ext), 'documents')
      docCopy.documentUrls[ext] = uploadResult.url;
      docCopy.filePath = `documents/${fileName}`;
    }
    await create_document(docCopy)
    await fetchDocs(selectedTeam.id)
    setLoading(false)
    if(!isV2) {
      history.push('/documents')
    }else {
      history.push('/documents-v2')
    }
  }, [documentObject, fetchDocs, history, selectedTeam, setLoading])

  // Delete document
  const deleteDocumentHandler = async (e) => {
    e.preventDefault();
    setDeleting(true);
    setLoading(true);

    await update_document(documentObject.id, { 
      deleted: true, 
      archived: false, 
      deleteAfter: new Date(Date.now() + 60 * 60 * 24 * 30 * 1000).getTime(),
      last_modified_by: activeTeamMember.id,
      create_action: 'no'
    }, []);
  
    await fetchDocs(selectedTeam.id);

    setShowDocDeleteAlert(false);
    setDeleting(false);
    setLoading(false);
    if(!isV2) {
      history.push('/documents')
    }else {
      history.push('/documents-v2')
    }
  }

  const onAttachmentShareUpdate = (index, share) => {
    let da = documentAttachments
    da[index].share = share
    setDocumentAttachments([...da]);
  }

  const onAttachmentsShareSave = async () => {
    if(documentId) {
      update_document(documentId, { attachments: documentAttachments }, documentAttachments);
    }
  }

  // Status change
  const statusChangeHandler = useCallback((status) => {
    setDocumentStatus(status.value);
    setStatusesOptions(prev => {
      const statuses = [...prev];
      const newStatuses = statuses.map((s) => s.value === status.value ? { ...s, active: true } : { ...s, active: false });
      return newStatuses;
    });
  }, [])

  // Preview first page - only for uploaded documents
  const previewFirstPageHandler = async () => {
    let documentData;
    try {
      if(documentObject.documentUrls.pdf) {
        const res = await get_file({ url: documentObject.documentUrls.pdf })
        documentData = res.data;
      }else {
        const res = await convert_attachment(documentObject.documentUrls.docx)
        documentData = res.data;
      }
      setUploadedDocData(`data:application/pdf;base64,${documentData}`);
      if(!documentData) {
        return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      }   
    } catch (err) {
      console.log(err);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setUploadedDocData('e');
    }
  }

  // Show first page of uploaded document in a box
  useEffect(() => {
    if(documentObject && Object.keys(documentObject).length > 0 && documentObject.uploaded) {
      previewFirstPageHandler();
      setPreviewModeUploaded(true);
    }
    // eslint-disable-next-line
  }, [documentObject]);

  // Cloud upload click handler - one drive
  const oneDriveUploadClickHandler = useCallback(async () => {
    try {
      const token = await get_onedrive_token();
      if(token) {
        setOneDriveAuthToken(token);
        setShowOneDriveModal(true);
      }
      setShowUploadToCloudPopover(false);
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    }
  }, [setNotification, setOneDriveAuthToken])

  // Cloud upload click handler - google drive
  const gDriveUploadClickHandler = useCallback(async (e, data = null) => {
    const authData = data ? data : gDriveAuth;
    setShowUploadToCloudPopover(false);
    if(Object.keys(authData).length > 0) {
      const user = authData.currentUser.get();
      const isAuthorized = user.hasGrantedScopes('https://www.googleapis.com/auth/drive');
      // console.log(isAuthorized, signedInToGoogle)
      const isSignedIn = authData.isSignedIn.get();
      if(isAuthorized && isSignedIn) {
        // console.log('open modal and list all files')
        setGoogleAuthToken(user.getAuthResponse().access_token);
        setShowGDriveModal(true);
      }else {
        // console.log('not authorized')
        authData.signIn();
      }
    }
  }, [gDriveAuth])
  
  // On gapi load
  const onGapiLoad = () => {
    window.gapi.load('client:auth2', initGClient);
  }

  // Cloud upload click handler - google drive
  const initGClient = () => {
    try{
      window.gapi.client.init({
        'apiKey': gdriveConfig.apiKey,
        'clientId': gdriveConfig.clientId,
        'scope': gdriveConfig.scope,
        'discoveryDocs': gdriveConfig.discoveryDocs
      }).then(() => {
          const authInstance = window.gapi.auth2.getAuthInstance();
          // console.log(authInstance)
          setGDriveAuth(authInstance);
          if(authInstance.isSignedIn.get()) {
            setSignedInToGoogle(true);
          }else {
            setSignedInToGoogle(false);
          }
          authInstance.isSignedIn.listen(isSigned => {
            if(isSigned) {
              setGDriveAuth(authInstance);
              gDriveUploadClickHandler(null, authInstance);
            }
          });
      });
    }catch(e){
      console.log(e);
    }
  }

  // Check if dropbox upload modal can be displayed
  useEffect(() => {
    const getToken = async (code) => {
      try {
        const res = await dropbox_get_token({ code, redirect_uri: `${window.location.origin}/dashboard` });
        if(res.data.refresh_token && res.data.access_token) {
          localStorage.setItem('dbx_access', res.data.refresh_token);
          setDropboxAccessToken(res.data.access_token);
          setShowDropboxModal(true);
        }
      } catch (err) {
        console.log(err);
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      }
    }
    if(uploadingToCloud && dropboxCode) {
      getToken(dropboxCode);
    }
  }, [uploadingToCloud, dropboxCode, setDropboxAccessToken, setNotification]);

  // Dropbox upload click handler 
  const dropboxUploadClickHandler = useCallback(async () => {
    setShowUploadToCloudPopover(false);
    const token = localStorage.getItem('dbx_access');
    if(token) {
      try {
        const res = await dropbox_get_token({ using_refresh: true, refresh_token: token });
        if(res.data.access_token) {
          setDropboxAccessToken(res.data.access_token);
          setShowDropboxModal(true);
        }
      } catch (err) {
        console.log(err);
      }
    }else {
      const a = document.createElement('a');
      a.href= `https://www.dropbox.com/oauth2/authorize?client_id=${dropboxConfig.clientId}&redirect_uri=${window.location.origin}/dashboard&response_type=code&token_access_type=offline&state=${documentId}`;
      a.click();
    }
  }, [documentId, setDropboxAccessToken])

  // Store edit time
  const storeEditTime = useCallback(() => {
    if(editStartTime) {
      let time = currentSessionEditTime + (Date.now() - editStartTime)
      setCurrentSessionEditTime(time)
      setEditStartTime(null)
      // console.log('current session edit time:', time)
    }
  }, [currentSessionEditTime, editStartTime])

  // Get all variables
  const allVariables = useCallback((tempData, full = true, includeFooter = false) => {
    if(!tempData) {
      return null
    }
    let sections = tempData.sections
    if(includeFooter && tempData.footer) {
      sections.push({...tempData.footer, type: tempData.footer.type || 'text'})
    }
    return extractVariablesFromSections(sections, false, full)
  }, [extractVariablesFromSections])

  // On open add/edit notary modal
  const openAddEditNotaryModal = (m) => {
    setShowAddEditNotaryModal(true)
    setShowNotariesModal(false)
    setNotaryMode(m)
  }

  // On close add/edit notary modal
  const closeAddEditNotaryModal = () => {
    setShowAddEditNotaryModal(false)
    setShowNotariesModal(true)
    setSelectedNotary(null)
  }

  // On contact select
  const handleContactSelect = (contact) => {
    if(selectedTeam?.contacts_linked_variables && selectedTeam.contacts_linked_variables[contact.group]) {
      let linkedVars = selectedTeam.contacts_linked_variables[contact.group]
      let contactValues = {}
      let allVars = allVariables(templateObject)
      for(let key in linkedVars) {
        if(contact[key] && allVars.includes(linkedVars[key])) {
          contactValues[linkedVars[key]] = contact[key]
        }
      }
      setDocumentValues({...documentValues, ...contactValues})
    }
    setShowContactsModal(false)
  }

  // On contacts modal open
  const handleContactsModalOpen = useCallback((variable) => {
    setShowContactsModal(true)
    setCurrentContactVariable(variable)
  }, [])

  // On contacts modal close
  const handleContactsModalClose = () => {
    setShowContactsModal(false)
    setCurrentContactVariable(null)
  }

  // On contact select
  const handleLinkProfileFieldsAndVariables = useCallback(() => {
    if(user?.profile_linked_vars) {
      let docValues = {...documentValues}
      let linkedVars = user.profile_linked_vars
      let allVars = allVariables(templateObject)
      for(let key in linkedVars) {
        if(allVars.includes(linkedVars[key]) && user[key]) {
          docValues[linkedVars[key]] = user[key]
        }
      }
      setDocumentValues(docValues)
    }
  }, [allVariables, documentValues, templateObject, user])

  // Is loading
  const isLoading = () => {
    let isStillLoading = true

    if(!isSharedTemplate && !isSharedTemplateFromPage) {
      if(!loading && loaded && variablesFetched) {
        isStillLoading = false
      }
    }else {
      if(!loading && loaded) {
        isStillLoading = false
      }
    }
    
    return isStillLoading
  }

  // On make content editable
  const handleMakeContentEditable = useCallback(() => {
    // render original template or reapply changes when switching editable mode
    const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes(makeContentEditable ? {...templateObject} : applyContentChangesToTemplate(templateObject, buildContentChanges(partialContentChanges, templateObject, documentObject.content_changes || [])));
    setTemplateObjectWithUniqueVarIndexes(copyOfTemplate);

    setMakeContentEditable(!makeContentEditable)
  }, [makeContentEditable, templateObject, documentObject, partialContentChanges])

  const applyContentChangesToTemplate = (t, changes) => {
    let template = JSON.parse(JSON.stringify(t))
    for(let change of changes) {
      let applied = false
      for(let sIndex = 0; sIndex < template.sections.length; sIndex++) {
        if(sIndex === change.sectionIndex) {
          if(change.listIndex !== -1 && template.sections[sIndex].items) {
            for(let lIndex = 0; lIndex < template.sections[sIndex].items.length; lIndex++) {
              if(lIndex === change.listIndex) {
                template.sections[sIndex].items[lIndex].content = change.content
                applied = true
                break                
              }
            }
          } else if(change.listIndex === -1) {
            template.sections[sIndex].content = change.content
            applied = true
          }
        }
        if(applied) {
          break
        }
      }
    }
    return template
  }

  const onSectionContentChanged = (change) => {

    // change format
      // sectionIndex: index,
      // listIndex: listIndex,
      // editedContent,
      // originalContent

    // console.log('change', change)

    let pcc = [...partialContentChanges]
    
    pcc = addPartialContentChange(pcc, change)
    setPartialContentChanges([...pcc])
  }

  const mergePartialContentChange = (change, partialChange) => {
    if(change.content === partialChange.editedContent) {
      return change
    }
    change.content = change.content.replace(partialChange.originalContent, partialChange.editedContent)
    return change
  }

  const addPartialContentChange = (changes, change) => {
    let isNew = true
    for(let i in changes) {
      if(changes[i].sectionIndex === change.sectionIndex && changes[i].listIndex === change.listIndex && changes[i].originalContent === change.originalContent) {
        isNew = false
        changes[i].editedContent = change.editedContent
        break
      }
    }
    if(isNew) {
      changes.push(change)
    }
    return changes
  }

  // On return to documents
  const handleReturnToDocs = (e) => {
    e.preventDefault()
    if(!isV2) {
      history.push('/documents')
    }else {
      history.push('/documents-v2')
    }
  }
  
  // Render document content
  const renderDocumentContent = (uploaded = false) => {
    if(!documentObject) return
    const format = documentObject.uploadedFormat;
    const docUrls = documentObject.documentUrls;
    if(loading) {
      return <DocumentDetailSkeleton opened={sidePanelOpened} isSharedTemplate={isSharedTemplate || isSharedTemplateFromPage} />
    }
    return (
      <DocumentDetailContent 
        ref={bodyWrapEl}
        sidePanelOpened={sidePanelOpened}
        uploaded={uploaded}
        format={format}
        docUrls={docUrls} 
        documentName={documentName} 
        uploadedDocData={uploadedDocData} 
        onDocumentLoadSuccess={onDocumentLoadSuccess} 
        uploadedDocPages={uploadedDocPages} 
        templateObjectWithUniqueVarIndexes={templateObjectWithUniqueVarIndexes} 
        documentValues={documentValues} 
        documentValuesChangeHandler={documentValuesChangeHandler} 
        documentCheckboxValues={documentCheckboxValues}
        documentCheckboxValuesChangeHandler={documentCheckboxChangeHandler}
        setEditStartTime={setEditStartTime} 
        storeEditTime={storeEditTime} 
        handleContactsModalOpen={handleContactsModalOpen} 
        view={view} 
        setView={setView} 
        setSidePanelOpened={setSidePanelOpened} 
        documentAttachments={documentAttachments} 
        documentId={documentId} 
        showBackToTop={showBackToTop} 
        documentObject={documentObject} 
        docTasks={docTasks} 
        newCommentsCount={newCommentsCount} 
        isSharedTemplate={isSharedTemplate} 
        isSharedTemplateFromPage={isSharedTemplateFromPage} 
        onAddAttachment={onAddAttachment} 
        extractVariableTypesFromSections={extractVariableTypesFromSections} 
        templateObject={templateObject} 
        totalProgress={totalProgress} 
        variableItemClickHandler={variableItemClickHandler} 
        synthesisClickHandler={synthesisClickHandler} 
        activeVariable={activeVariable} 
        scrollingPosition={scrollingPosition} 
        setPrevScrollPosition={setPrevScrollPosition} 
        prevScrollPosition={prevScrollPosition} 
        sectionClickHandler={sectionClickHandler} 
        scrollingToItem={scrollingToItem} 
        editAttachmentHandler={editAttachmentHandler} 
        onDeleteAttachmentByName={onDeleteAttachmentByName} 
        docInformations={docInformations} 
        setDocInformations={setDocInformations} 
        docInformationsDefault={docInformationsDefault} 
        setUpdateSection={setUpdateSection} 
        docDates={docDates} 
        setDocDates={setDocDates} 
        docDatesDefault={docDatesDefault} 
        comments={comments} 
        setComments={setComments} 
        userCustomFields={userCustomFields} 
        setUserCustomFields={setUserCustomFields} 
        userDefaultCustomFields={userDefaultCustomFields} 
        docCustomFieldsValues={docCustomFieldsValues} 
        setDocCustomFieldsValues={setDocCustomFieldsValues} 
        didCustomFieldsValuesChanged={didCustomFieldsValuesChanged}
        makeContentEditable={makeContentEditable}
        onLinkVarsWithProfileData={handleLinkProfileFieldsAndVariables}
        onSectionContentChanged={onSectionContentChanged}
        onPageChange={handleOnPageChange}
      />
    )
  }

  if(docNotFound) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="icon"><LinkOff /></div>
          <h1>{t('general.doc_not_found')}</h1>
          <p><a href="/#" onClick={handleReturnToDocs}>{t('general.return_to_docs')}</a></p>
        </div>
      </div>
    )
  }
  
  if(activeTeamMember?.status === 'pending') {
    return <NoAccess message={t('team.no_access_text')} showBtn />;
  }

  if(isSharedTemplateDisabled) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="icon"><LinkOff /></div>
          <h1>{t('share_template.template_disabled')}</h1>
        </div>
      </div>
    )
  }

  if(isSharedTemplateLinkInvalid) {
    return (
      <div className="pdf-share pdf-share--2">
        <div className="pdf-share__not-found">
          <div className="icon"><LinkOff /></div>
          <h1>{t('share_template.link_not_valid')}</h1>
        </div>
      </div>
    )
  }

  return(
    <div className="document-detail">
      <DocumentDetailHeaderV2
        isSharedTemplate={isSharedTemplate} 
        isSharedTemplateFromPage={isSharedTemplateFromPage} 
        loaded={loaded} 
        documentName={documentName} 
        documentNameChangeHandler={documentNameChangeHandler} 
        storeEditTime={storeEditTime} 
        setEditStartTime={setEditStartTime} 
        statusesOptions={statusesOptions} 
        statusChangeHandler={statusChangeHandler} 
        documentObject={documentObject} 
        timeElapsed={timeElapsed} 
        showTemplateHistoryModal={showTemplateHistoryModal} 
        saving={saving} 
        saved={saved} 
        saveDocument={saveDocument} 
        setPreviewDocumentModal={setPreviewDocumentModal} 
        fetchDocumentPreview={fetchDocumentPreview} 
        uploadedDocData={uploadedDocData} 
        shareClickHandler={shareClickHandler} 
        setShowShareModal={setShowShareModal} 
        oneDriveUploadClickHandler={oneDriveUploadClickHandler} 
        gDriveUploadClickHandler={gDriveUploadClickHandler} 
        dropboxUploadClickHandler={dropboxUploadClickHandler} 
        documentId={documentId} 
        setShowInjectModal={setShowInjectModal} 
        setShowSignDocModal={setShowSignDocModal} 
        historyClickHandler={historyClickHandler} 
        synthesisClickHandler={synthesisClickHandler} 
        duplicateDocumentHandler={duplicateDocumentHandler} 
        setShowDocDeleteAlert={setShowDocDeleteAlert}
        currentSessionEditTime={currentSessionEditTime}
        onSetView={setView}
        onSetDocumentObject={setDocumentObject}
        onMakeContentEditable={handleMakeContentEditable}
        makeContentEditable={makeContentEditable}
        onLinkProfileFieldsAndVariables={handleLinkProfileFieldsAndVariables}
        templateObject={templateObject}
        sharedDoc={sharedDoc}
        setSharedDoc={setSharedDoc}
      />

      { !isLoading() ?
          templateId 
            ? renderDocumentContent()  
            : (isSharedTemplate || isSharedTemplateFromPage) ? renderDocumentContent() : renderDocumentContent(true)
        : <Fragment>
          <div className="overlay"></div>
          <DocumentDetailSkeleton opened={true} isSharedTemplate={isSharedTemplate || isSharedTemplateFromPage} />
        </Fragment>
      }

      {showModal && <SendByEmailModal 
        documentId={documentId} 
        documentName={documentName} 
        fetchDocumentData={fetchDocumentData} 
        onClose={() => setShowModal(false)} 
        attachments={documentAttachments} 
        doc={documentObject} 
        fetchDocuments={fetchDocs} 
        onAddAttachment={onAddAttachment}
        attachmentsNames={[...documentAttachments].map(a => a.name + '.' + a.format)} 
      />}
      {historyModal.isOpen && availableOn(['development']) && documentObject?.versioning ? <HistoryModalV2 
        loading={historyModal.isLoading} 
        history={historyModal.history} 
        onClose={hideHistoryModal} 
        onRestore={restoreVersion} 
        uploaded={documentObject?.uploaded}
      /> : historyModal.isOpen && <HistoryModal 
        loading={historyModal.isLoading} 
        history={historyModal.history} 
        onClose={hideHistoryModal} 
        onRestore={restoreVersion} 
        uploaded={documentObject?.uploaded}
      />}
      {showSynthesisModal && <SynthesisModal 
        onClose={() => setShowSynthesisModal(false)} 
        data={templateObject}
        getVarKeysFromContent={getVarKeysFromContent} 
        documentValues={documentValues}
        onSetDocumentValues={setDocumentValues}
        onSave={() => setShowSynthesisModal(false)}
        loading={loading}
        onSetEditStartTime={setEditStartTime}
        onStoreEditTime={storeEditTime}
      />}
      {previewDocumentModal && <Modal onClose={() => setPreviewDocumentModal(false)} className="modal--padding-sm">
        <Document file={previewModeUploaded ? uploadedDocData : pdfPreviewData} onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)} renderMode="canvas" loading={t('general.loading')}>
          {docPages}
        </Document>
      </Modal>}
      {showSignDocModal && <SignDocumentModalOnespan 
        onClose={() => setShowSignDocModal(false)} 
        onFetchDocumentData={fetchDocumentData}
        documentName={documentName}
        docId={documentId}
        documentObject={documentObject}
        docAttachments={documentAttachments}
        docSignatureRecipients={documentObject && documentObject.signatureRecipients ? documentObject.signatureRecipients : []}
        uploaded={documentObject.uploaded}
        documentUrls={documentObject.documentUrls}
        onAddAttachment={onAddAttachment}
        attachmentsNames={[...documentAttachments].map(a => a.name + '.' + a.format)} 
        onSetEditStartTime={setEditStartTime}
        onStoreEditTime={storeEditTime}
        windowVisibility={windowVisibility}
        editStartTime={editStartTime}
        selectedDocument={documentObject}
        templateObject={templateObject}
        documentValues={documentValues}
      />}
      {showDocDeleteAlert && <Alert 
        onClose={() => setShowDocDeleteAlert(false)}
        text={t('alert.delete_document')}
        onSubmit={deleteDocumentHandler}
        loading={deleting}
        deleteAlert
      />}
      {showOneDriveModal && <OneDriveModal 
        onClose={() => setShowOneDriveModal(false)} 
        token={oneDriveAuthToken} 
        doc={documentObject} 
        onFetchDocumentData={fetchDocumentData}
        documentAttachments={documentAttachments}
      />}
      {showGDriveModal && <GDriveModal 
        onClose={() => setShowGDriveModal(false)} 
        token={googleAuthToken} 
        doc={documentObject} 
        onFetchDocumentData={fetchDocumentData}
        documentAttachments={documentAttachments}
      />}
      {showDropboxModal && <DropboxModal 
        onClose={() => setShowDropboxModal(false)} 
        token={dropboxAccessToken} 
        doc={documentObject} 
        onFetchDocumentData={fetchDocumentData}
        documentAttachments={documentAttachments}
      />}

      {showInjectModal && <InjectModal 
        onClose={() => setShowInjectModal(false)} 
        doc={documentObject} 
        template={templateObject}
        allVariables={allVariables}
        onSetDocValues={setDocumentValues}
        refresh={true}
      />}

      {templateHistoryModal.isOpen && <TemplateHistoryModal 
        loading={templateHistoryModal.isLoading} 
        history={templateHistoryModal.history} 
        onClose={hideTemplateHistoryModal} 
        onSetHistory={setTemplateHistoryModal}
      />}

      {showNotariesModal && <NotariesModal 
        onClose={() => setShowNotariesModal(false)} 
        onOpenAddEditNotaryModal={openAddEditNotaryModal} 
        onSetSelectedNotary={setSelectedNotary}
      />}

      {showAddEditNotaryModal && <AddEditNotary onClose={closeAddEditNotaryModal} mode={notaryMode} notary={selectedNotary} />}
      {showShareModal && <ShareModal onClose={() => setShowShareModal(false)} doc={documentObject} sharedDoc={sharedDoc} setSharedDoc={setSharedDoc} />}
      {showContactsModal && <ContactsModal 
        onClose={handleContactsModalClose} 
        onContactSelect={handleContactSelect} 
        currentVariable={currentContactVariable}
      />}
    </div>
  );
}

export default DocumentDetail;
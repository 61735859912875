import { useContext, useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { AnimatedSearchBar, UploadDocumentsModal, TemplateSelectModal, CreateEditFolderModal, MoveSelectedItemsToFolderModal } from '../misc'
import { SupportMenu, NotificationsPanel } from '.'
import { Alert, LetterCircle } from '../new_ui'
import ActionsDropdown from '../UI/ActionsDropdown'
import CustomTooltip from '../UI/CustomTooltip'
import { GlobalContext, DocumentsFoldersContext, DocumentsContext, NotificationContext, FolderContext, UserContext } from '../../context'
import { useDocumentsFoldersActions, useDocumentsActions, useTemplatesFoldersActions, useTemplatesActions } from '../../hooks'
import { isOverflown, folderHasTemplates } from '../../utils'

const Header = ({ title, page, refreshCollection, hideSearch = false, hideCreateDoc = false }) => {
  const { t } = useContext(GlobalContext)
  const { docFolderBreadcrumbs, setDocParentFolder, docFolders } = useContext(DocumentsFoldersContext)
  const { setDocsSelectedFilters, currentFilter, documents, setTemplatesSelectedFilters, templates } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { folderBreadcrumbs, setParentFolder, folders } = useContext(FolderContext)
  const { user } = useContext(UserContext)
  const { updateBreadcrumbs, deleteDocumentFolder } = useDocumentsFoldersActions()
  const { moveDocumentsToFolder } = useDocumentsActions()
  const { moveTemplatesToFolder } = useTemplatesActions()
  const { updateBreadcrumbs: updateTemplatesBreadcrumbs, deleteTemplateFolder } = useTemplatesFoldersActions()
  const [showUploadDocsModal, setShowUploadDocsModal] = useState(false)
  const [showDocumentCreateModal, setShowDocumentCreateModal] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false)
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState(false)
  const [currentFolder, setCurrentFolder] = useState(null)
  const [showDeleteFolderAlert, setShowDeleteFolderAlert] = useState(false)
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)
  const [foldersToMove, setFoldersToMove] = useState([])
  const history = useHistory()

  // Check if breadcrumbs should be shown instead of title
  useEffect(() => {
    if(page === 'documents' && docFolderBreadcrumbs.length > 0) {
      setShowBreadcrumbs(true)
    }else if(page === 'templates' && folderBreadcrumbs.length > 0) {
      setShowBreadcrumbs(true)
    }else {
      setShowBreadcrumbs(false)
    }
  }, [docFolderBreadcrumbs, page, folderBreadcrumbs])

  // On open document create modal
  const handleOpenDocumentCreateModal = (e) => {
    e.preventDefault()
    setShowDocumentCreateModal(true)
  }

  // On open documents uploda modal
  const handleOpenDocumentsUploadModal = (e) => {
    e.preventDefault()
    setShowUploadDocsModal(true)
  }

  // On open notifications panel
  const handleOpenNotificationsPanel = () => {
    setShowNotifications(true)
  }

  // On close notifications panel
  const handleCloseNotificationsPanel = () => {
    setShowNotifications(false)
  }

  // On close upload documents modal
  const handleCloseUploadDocumentsModal = () => {
    setShowUploadDocsModal(false)
  }

  // On close upload documents modal
  const handleCloseDocumentCreateModal = () => {
    setShowDocumentCreateModal(false)
  }

  // On reset folders
  const handleResetFolders = () => {
    if(page === 'documents') {
      setDocsSelectedFilters(prev => ({ ...prev, folder: 'all' }))
      updateBreadcrumbs({ folder: null })
    }else if(page === 'templates') {
      setTemplatesSelectedFilters(prev => ({ ...prev, folder: 'all' }))
      updateTemplatesBreadcrumbs({ folder: null })
    }
  }

  // On go to folder
  const handleGoToFolder = (e, folder) => {
    e.preventDefault()
    if(page === 'documents') {
      setDocsSelectedFilters(prev => ({ ...prev, folder: folder.id }))
      updateBreadcrumbs({ folder })
    }else if(page === 'templates') {
      setTemplatesSelectedFilters(prev => ({ ...prev, folder: folder.id }))
      updateTemplatesBreadcrumbs({ folder })
    }
    window.document.body.click()
  }

  // On create new folder
  const handleCreateNewFolder = (e, folder) => {
    e.preventDefault()
    setShowCreateFolderModal(true)
    if(page === 'documents') {
      setDocParentFolder(folder)
    }else if(page === 'templates') {
      setParentFolder(folder)
    }
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
    setDocParentFolder(null)
  }

  // On edit folder
  const handleEditFolder = (e, folder) => {
    e.preventDefault()
    setCurrentFolder(folder)
    setShowEditFolderModal(true)
  }

  // On close edit folder modal
  const handleCloseEditFolderModal = () => {
    setShowEditFolderModal(false)
    setCurrentFolder(null)
  }

  // On move folder button click
  const handleMoveFolderClick = (e, folder) => {
    e.preventDefault()
    setFoldersToMove([folder])
    setShowMoveToFolderModal(true)
    setCurrentFolder(folder)
  }

  // On close move to folder modal
  const handleCloseMoveToFolderModal = () => {
    setFoldersToMove([])
    setShowMoveToFolderModal(false)
    setCurrentFolder(null)
  }

  // On move folder 
  const handleMoveFolder = async (moveTo, current) => {
    let parent = null
    if(page === 'documents') {
      if(moveTo) {
        parent = docFolders.find(f => f.id === moveTo.id)
      }
      await moveDocumentsToFolder({ moveTo, current, selectedDocuments: foldersToMove, setSelectedDocuments: setFoldersToMove, setShowMoveMultipleDocsToFolderModal: setShowMoveToFolderModal })
      setDocsSelectedFilters(prev => ({ ...prev, folder: parent ? parent.id : 'all' }))
      updateBreadcrumbs({ folder: parent || null })
    }else if(page === 'templates') {
      if(moveTo) {
        parent = folders.find(f => f.id === moveTo.id)
      }
      await moveTemplatesToFolder({ moveTo, current, selectedDocuments: foldersToMove, setSelectedDocuments: setFoldersToMove, setShowMoveMultipleDocsToFolderModal: setShowMoveToFolderModal })
      setTemplatesSelectedFilters(prev => ({ ...prev, folder: parent ? parent.id : 'all' }))
      updateTemplatesBreadcrumbs({ folder: parent || null })
    }
    refreshCollection()
  }

  // On delete folder
  const handleDeleteFolder = (e, folder) => {
    e.preventDefault()
    if(page === 'documents') {
      if(folderHasTemplates(documents, folder)) {
        return setNotification({ msg: t('folder.not_empty_templates', { folder: folder.name }), type: 'warning' })
      }
    }else if(page === 'templates') {
      if(folderHasTemplates(templates, folder)) {
        return setNotification({ msg: t('folder.not_empty_templates', { folder: folder.name }), type: 'warning' })
      }
    }
    setCurrentFolder(folder)
    setShowDeleteFolderAlert(true)
  }

  // On close delete folder alert
  const handleCloseDeleteFolderAlert = () => {
    setShowDeleteFolderAlert(false)
  }

  // On folder delete
  const handleFolderDelete = async () => {
    if(page === 'documents') {
      const parent = docFolders.find(f => f.id === currentFolder.parentFolder)
      await deleteDocumentFolder({ currentFolder, setShowDeleteFolderAlert, setCurrentFolder, resetFolders: false, setResetFolders: () => {} })
      setDocsSelectedFilters(prev => ({ ...prev, folder: parent ? parent.id : 'all' }))
      updateBreadcrumbs({ folder: parent || null })
    }else if(page === 'templates'){
      const parent = folders.find(f => f.id === currentFolder.parentFolder)
      await deleteTemplateFolder({ currentFolder, setShowDeleteFolderAlert, setCurrentFolder, resetFolders: false, setResetFolders: () => {} })
      setTemplatesSelectedFilters(prev => ({ ...prev, folder: parent ? parent.id : 'all' }))
      updateTemplatesBreadcrumbs({ folder: parent || null })
    }
    refreshCollection()
  }

  // Go to profile
  const handleGoToProfile = () => {
    history.push('/settings/my-profile')
  }

  // Render folder actions
  const renderFolderActions = (arr) => {
    return (
      <ActionsDropdown
        trigger={<span className="material-symbols-outlined arrow-down">arrow_drop_down</span>}
        hideHeader
        width={200}
        dropdownClass="actions-dropdown-v2"
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/#" onClick={(e) => handleCreateNewFolder(e, arr[arr.length - 1])}>
              <span className="icon"><span className="material-symbols-outlined">create_new_folder</span></span>
              {t('folder.new')}
            </a>
          </li>
          <li>
            <a href="/#" onClick={(e) => handleEditFolder(e, arr[arr.length - 1])}>
              <span className="icon"><span className="material-symbols-outlined">edit</span></span>
              {t('general.rename')}
            </a>
          </li>
          <li>
            <a href="/#" onClick={(e) => handleMoveFolderClick(e, arr[arr.length - 1])}>
              <span className="icon"><span className="material-symbols-outlined">subdirectory_arrow_left</span></span>
              {t('general.move')}
            </a>
          </li>
          <li>
            <a href="/#" className="delete" onClick={(e) => handleDeleteFolder(e, arr[arr.length - 1])}>
              <span className="icon"><span className="material-symbols-outlined">delete</span></span>
              {t('general.remove')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render breadcrumbs
  const renderBreadcrumbs = () => {
    let arr = page === 'documents' ? [...docFolderBreadcrumbs] : [...folderBreadcrumbs]
    let content = null 

    if(arr.length > 0) {
      let innerContent = null 
      if(arr.length === 1) {
        innerContent = (
          <li className="folder-breadcrumbs__item">
            <FolderName name={arr[0].name} />
            {renderFolderActions(arr)}
          </li>
        )
      }else {
        innerContent = (
          <>
            <li className="folder-breadcrumbs__item">
              <ActionsDropdown
                trigger={<span className="material-symbols-outlined more">more_horiz</span>}
                hideHeader
                width={200}
                dropdownClass="actions-dropdown-v2"
              >
                <ul className="default-list default-list--small">
                  {arr.slice(0, arr.length - 1).map((folder, idx) => {
                    return (
                      <li key={idx}>
                        <a href="/#" onClick={(e) => handleGoToFolder(e, folder)} className="u-truncate-text-wrapper">
                          <span className="icon">
                            <span className="material-symbols-outlined filled">folder</span>
                          </span>
                          <span className="u-truncate-text">{folder.name}</span>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </ActionsDropdown>
              <span className="material-symbols-outlined arrow-right">chevron_right</span>
            </li>
            <li className="folder-breadcrumbs__item">
              <FolderName name={arr[arr.length - 1].name} />
              {renderFolderActions(arr)}
            </li>
          </>
        )
      }
      content = (
        <ul className="folder-breadcrumbs">
          <li className="folder-breadcrumbs__item">
            <p onClick={handleResetFolders}>{t('general.all')}</p>
            <span className="material-symbols-outlined arrow-right">chevron_right</span>
          </li>
          {innerContent}
        </ul>
      )
    }

    return content
  }

  return (
    <header className="main-header">
      <div className="main-header__left main-header__col">
        {!showBreadcrumbs 
          ? <h1 className="heading-1 u-truncate-text">{title || t('dashboard.main_title')}</h1>
          : page === 'documents' 
            ? currentFilter === 'all' ? renderBreadcrumbs() : <h1 className="heading-1 u-truncate-text">{title}</h1>
            : page === 'templates' && 
              folderBreadcrumbs.length > 0 ? renderBreadcrumbs() : <h1 className="heading-1 u-truncate-text">{title}</h1>
        }
      </div>
      {!hideSearch && (
        <div className="main-header__center main-header__col">
          <AnimatedSearchBar className="animated-search-bar--v2" v2={true} />
        </div>
      )}
      <div className="main-header__right main-header__col">
        {!hideCreateDoc && (
          <ActionsDropdown
            trigger={
              <button className="btn btn--with-icon add-doc-btn">
                <span className="text">{t('dashboard.add_document')}</span> <span className="material-symbols-outlined size-24">expand_more</span>
              </button>
            }
            hideHeader
            width={300}
            dropdownClass="actions-dropdown-v2"
          >
            <ul className="default-list">
              <li>
                <a href="/#" onClick={handleOpenDocumentCreateModal}>
                  <span className="icon"><span className="material-symbols-outlined size-24">add</span></span> 
                  {t('dashboard.create_from_template')}
                </a>
              </li>
              <li>
                <a href="/#" onClick={handleOpenDocumentsUploadModal}>
                  <span className="icon"><span className="material-symbols-outlined size-24">file_upload</span></span> 
                  {t('dashboard.import_document')}
                </a>
              </li>
            </ul>
          </ActionsDropdown>
        )}
        <CustomTooltip content={t('dashboard.activities')} position="bottom">
          <button className="icon-btn" onClick={handleOpenNotificationsPanel}>
            <span className="material-symbols-outlined">notifications</span>
          </button>
        </CustomTooltip>
        <SupportMenu />
        <div className="user-thumb-wrapper" onClick={handleGoToProfile}>
          {user.image_url ? (
            <div className="user-thumb" style={{backgroundImage: `url(${user.image_url})`}}></div>
          ) : (
            <LetterCircle data={user.first_name} hideTooltip />
          )}
        </div>
      </div>

      <NotificationsPanel show={showNotifications} onClose={handleCloseNotificationsPanel} />
      {showUploadDocsModal && (
        <UploadDocumentsModal onClose={handleCloseUploadDocumentsModal} />
      )}
      {showDocumentCreateModal && (
        <TemplateSelectModal onClose={handleCloseDocumentCreateModal} />
      )}

      {showCreateFolderModal && (
        <CreateEditFolderModal 
          onClose={handleCloseCreateFolderModal} 
          setCurrentFolder={setCurrentFolder}
          setFolderCreated={() => {}}
          view={page}
          refreshCollection={refreshCollection}
        />
      )}

      {showEditFolderModal && (
        <CreateEditFolderModal 
          mode="edit"
          onClose={handleCloseEditFolderModal} 
          setCurrentFolder={setCurrentFolder}
          currentFolder={currentFolder}
          setFolderCreated={() => {}}
          view={page}
          refreshCollection={refreshCollection}
        />
      )}

      {showMoveToFolderModal && (
        <MoveSelectedItemsToFolderModal 
          onClose={handleCloseMoveToFolderModal}
          folders={page === 'documents' ? docFolders : folders}
          items={foldersToMove}
          currentFolder={currentFolder}
          onMove={handleMoveFolder}
        />
      )}

      {showDeleteFolderAlert && <Alert 
        onClose={handleCloseDeleteFolderAlert}
        text={t('alert.delete_folder')}
        onSubmit={handleFolderDelete}
        deleteAlert
      />}
    </header>
  )
}

const FolderName = ({ name }) => {
  const elRef = useRef()

  return (
    <CustomTooltip content={name} position="bottom" hideTooltip={!isOverflown(elRef.current)}>
      <p className="u-truncate-text" ref={elRef}>{name}</p>
    </CustomTooltip>
  )
}

export default Header
import { useContext, useState } from 'react'

import CustomTooltip from '../../UI/CustomTooltip'
import { Alert } from '../../new_ui'
import { MoveSelectedItemsToFolderModal, InjectModal } from '../'
import { GlobalContext, DocumentsContext, DocumentsFoldersContext } from '../../../context'
import { useDocumentsActions } from '../../../hooks'

const DocumentsActions = ({ allChecked, onAllCheckedChange, selectedDocuments, setSelectedDocuments, refreshDocs }) => {
  const { t } = useContext(GlobalContext)
  const { currentFilter, getTemplateById } = useContext(DocumentsContext)
  const { docFolders, docFoldersSelectedFilter } = useContext(DocumentsFoldersContext)
  const { downloadDocuments, duplicateDocuments, archiveDocuments, deleteDocuments, moveDocumentsToFolder, restoreDocuments, deleteDocumentsPermanently } = useDocumentsActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeletePermanentlyAlert, setShowDeletePermanentlyAlert] = useState(false)
  const [showMoveMultipleDocsToFolderModal, setShowMoveMultipleDocsToFolderModal] = useState(false)
  const [showInjectModal, setShowInjectModal] = useState(false)

  // On inject
  const handleInjectClick = (e) => {
    e.preventDefault()
    setShowInjectModal(true)
  } 

  // On inject modal close
  const handleCloseInjectModal = () => {
    setShowInjectModal(false)
  }

  // On duplicate
  const handleDuplicate = async (e) => {
    e.preventDefault()
    await duplicateDocuments({ selectedDocuments, setSelectedDocuments })
    refreshDocs()
  } 

  // On move click
  const handleMoveClick = (e) => {
    e.preventDefault()
    setShowMoveMultipleDocsToFolderModal(true)
  }

  // On move
  const handleMove = async (moveTo, current) => {
    await moveDocumentsToFolder({ moveTo, current, selectedDocuments, setSelectedDocuments, setShowMoveMultipleDocsToFolderModal })
    refreshDocs()
  }

  // On close move modal
  const handleCloseMoveModal = () => {
    setShowMoveMultipleDocsToFolderModal(false)
  }

  // On archive
  const handleArchive = async (e) => {
    e.preventDefault()
    await archiveDocuments({ selectedDocuments, setSelectedDocuments })
    refreshDocs()
  }

  // On download
  const handleDownload = async (e) => {
    e.preventDefault()
    await downloadDocuments({ selectedDocuments, setSelectedDocuments })
  }

  // On delete click
  const handleDeleteClick = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDeleteDocuments = async () => {
    await deleteDocuments({ selectedDocuments, setSelectedDocuments, setShowDeleteDocAlert: setShowDeleteAlert })
    refreshDocs()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On restore
  const handleRestore = async (e) => {
    e.preventDefault()
    await restoreDocuments({ selectedDocuments, setSelectedDocuments, setRestored: () => {} })
    refreshDocs()
  }

  // On delete permanently click
  const handleDeletePermanentlyClick = (e) => {
    e.preventDefault()
    setShowDeletePermanentlyAlert(true)
  }

  // On close delete permanently alert
  const handleCloseDeletePermanentlyAlert = () => {
    setShowDeletePermanentlyAlert(false)
  }

  // On delete documents permanently
  const handleDeleteDocumentsPermanently = async () => {
    await deleteDocumentsPermanently({ selectedDocuments, setSelectedDocuments })
    refreshDocs()
  }

  return (
    <div className="list-view-v2__actions">
      <div className="checkbox">
        {!allChecked 
          ? <span className="material-symbols-outlined" onClick={onAllCheckedChange}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled" onClick={onAllCheckedChange}>check_box</span>
        }
      </div>
      <p className="text-style-1">{t('dashboard.num_of_selected_items', { number: selectedDocuments.length })}</p>
      <div className="buttons">
        {currentFilter === 'all' && (
          <>
            {selectedDocuments.length === 1 && !selectedDocuments[0].uploaded && !selectedDocuments[0].folderRow && 
              <CustomTooltip content={t('inject.to_new_document')} position="bottom">
                <a href="/#" className="btn btn--primary-light btn--small" onClick={handleInjectClick}>
                  <span className="material-symbols-outlined icon">cached</span> 
                  <span className="text">{t('inject.title')}</span>
                </a>
              </CustomTooltip>
            }
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDuplicate}>
              <span className="material-symbols-outlined icon">content_copy</span> 
              <span className="text">{t('dashboard.duplicate')}</span>
            </a>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleMoveClick}>
              <span className="material-symbols-outlined icon">drive_file_move_outline</span> 
              <span className="text">{t('general.move')}</span>
            </a>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleArchive}>
              <span className="material-symbols-outlined icon">archive</span> 
              <span className="text">{t('general.archive')}</span>
            </a>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDownload}>
              <span className="material-symbols-outlined icon">download</span> 
              <span className="text">{t('general.download')}</span>
            </a>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDeleteClick}>
              <span className="material-symbols-outlined icon">delete</span> 
              <span className="text">{t('general.remove')}</span>
            </a>
          </>
        )}

        {currentFilter === 'archived' && (
          <>  
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleRestore}>
              <span className="material-symbols-outlined icon">restore</span> 
              <span className="text">{t('general.restore')}</span>
            </a>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDeleteClick}>
              <span className="material-symbols-outlined icon">delete</span> 
              <span className="text">{t('general.remove')}</span>
            </a>
          </>
        )}

        {currentFilter === 'deleted' && (
          <>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleRestore}>
              <span className="material-symbols-outlined icon">restore</span> 
              <span className="text">{t('general.restore')}</span>
            </a>
            <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDeletePermanentlyClick}>
              <span className="material-symbols-outlined icon">delete</span> 
              <span className="text">{t('general.delete_permanently')}</span>
            </a>
          </>
        )}
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_selected_documents')}
          deleteAlert={true}
          onSubmit={handleDeleteDocuments}
        />
      )}

      {showDeletePermanentlyAlert && (
        <Alert 
          onClose={handleCloseDeletePermanentlyAlert} 
          text={t('alert.delete_selected_documents')}
          deleteAlert={true}
          onSubmit={handleDeleteDocumentsPermanently}
        />
      )}

      {showMoveMultipleDocsToFolderModal && (
        <MoveSelectedItemsToFolderModal  
          onClose={handleCloseMoveModal} 
          folders={docFolders}
          items={selectedDocuments}
          onMove={handleMove}
          currentFolder={docFoldersSelectedFilter === 'all' ? null : [...docFolders].find(f => f.id === docFoldersSelectedFilter)}
        />
      )}

      {showInjectModal && (
        <InjectModal 
          onClose={handleCloseInjectModal} 
          doc={selectedDocuments[0]}
          template={getTemplateById(selectedDocuments[0]?.template)}
          refresh={false}
          // allVariables={() => {}} // TODO - when new document detail page is added update this
        />
      )}
    </div>
  )
}

export default DocumentsActions
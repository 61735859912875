import React, { createContext, useReducer, useState, useContext } from 'react';

import { SET_TEMPLATES, SET_DOCUMENTS, SET_FETCH_DOCUMENTS, SET_FILTERED_DOCUMENTS, SET_SHOW_FILTERED_DOCS, SET_CURRENT_FILTER, SET_ARCHIVED_DOCUMENTS, SET_DELETED_DOCUMENTS, SET_ACTIVE_FILTER_COLUMN, SET_DATE_FILTERS, SET_FILTERED_TEMPLATES, SET_TEMPLATES_LOADING, SET_ACTIVE_VARS_SECTION, SET_ACTIONS, SET_FETCH_ACTIONS, SET_TEMPLATES_ARR, SET_DOCUMENTS_ARR, RESET_STATE, SET_DOCUMENT_LISTS, SET_FAVORITE_DOCUMENTS } from '../types';
import documentsReducer from './documentsReducer';
import { update_template, fetch_templates, update_document, delete_template, create_document, fetch_documents, fetch_single_template, fetch_single_document, fetch_templates_by_ids } from '../../services/firestore';
import { update_approval_steps } from '../../services/approvals'
import { sortedArrayFromObject, sortArrayOfObjects, isCookieAccepted, sorterWithPathAndOrder, isObjectEmpty, numberOfValuesInObj, getTeamSortingData, getSelectedTeamFromLS, divideArrayToMultipleArrays } from '../../utils';
import { CARBON_SORT_LS, CONVERSION_RATES } from '../../constants';
import { GlobalContext } from '../'

export const DocumentsContext = createContext();

// Delete carbon sort from ls if cookies are not accepted
if(!isCookieAccepted()) {
  if(localStorage.getItem(CARBON_SORT_LS)) {
    localStorage.removeItem(CARBON_SORT_LS)
  }
}

const selectedTeam = getSelectedTeamFromLS()
const sortingData = getTeamSortingData()

let defaultSortDocs = { value: 'name', order: 'desc' }
let defaultSortTmplts = { value: 'name', order: 'desc' }
if(selectedTeam && sortingData && sortingData[selectedTeam]) {
  if(sortingData[selectedTeam].documents) {
    defaultSortDocs = { value: sortingData[selectedTeam].documents.value || 'name', order: sortingData[selectedTeam].documents.order || 'desc' }
  }
  if(sortingData[selectedTeam].templates) {
    defaultSortTmplts = { value: sortingData[selectedTeam].templates.value || 'name', order: sortingData[selectedTeam].templates.order || 'desc' }
  }
}

const DocumentsState = ({ children }) => {
  const { t } = useContext(GlobalContext)
  const initialState = {
    templates: {},
    documents: {},
    actions: {},
    actionsFetched: false,
    archivedDocuments: {},
    deletedDocuments: {},
    filteredDocuments: {},
    favoriteDocuments: {},
    fetchDocuments: () => {},
    fetchActions: () => {},
    showFilteredDocs: false,
    showFilteredTemplates: false,
    currentFilter: 'all',
    activeFilterColumn: '',
    dateFilters: { after: '', before: '' },
    filteredTemplates: {},
    templatesLoading: false,
    activeVarsSection: '',
    templatesLoaded: false,
    documentsLoaded: false,
    templatesArr: [],
    documentsArr: [],
    archivedDocsArr: [],
    deletedDocsArr: [],
    favoriteDocsArr: [],
    categories: [],
    tags: [],
    prices: [],
    pricesArr: [],
    totalAmount: 0,
    documentLists: null,
    documentListsArr: [],
    documentListsFetched: false
  }

  const [copiedAttachments, setCopiedAttachments] = useState([]);
  const [attachmentsFrom, setAttachmentsFrom] = useState(null);
  const [userFilter, setUserFilter] = useState('');
  const [title, setTitle] = useState(t('general.all'));
  const [docFilters, setDocFilters] = useState({
    owner: '',
    date_after: '',
    date_before: '',
    folder: '',
    status: '',
    name: ''
  });
  const [docsSort, setDocsSort] = useState(defaultSortDocs);
  const [templatesSort, setTemplatesSort] = useState(defaultSortTmplts);
  const [docNumbers, setDocNumbers] = useState({ active: 0, archived: 0, deleted: 0, favorites: 0 });
  const [documentsReset, setDocumentsReset] = useState(false)
  const [templatesReset, setTemplatesReset] = useState(false)
  const [documentsView, setDocumentsView] = useState('list')
  const [totalEditTime, setTotalEditTime] = useState(0)
  const [docsSelectedFilters, setDocsSelectedFilters] = useState({
    folder: 'all',
    search: ''
  })
  const [templatesSelectedFilters, setTemplatesSelectedFilters] = useState({
    folder: 'all',
    search: ''
  })
  const [state, dispatch] = useReducer(documentsReducer, initialState);

  // Fetch documents
  const fetchDocs = async (teamId) => {
    try {
      const res = await fetch_documents(teamId)
      // console.log('fetch documents result')
      if(res.error) {
        setDocuments({})
        throw new Error(res.error?.message || 'documents cannot be fetched')
      }
      setDocuments(res)
    } catch (err) {
      console.log('fetch documents error', err)
    }
  }

  const setTemplates = (value, fetched = true) => {
    dispatch({
      type: SET_TEMPLATES,
      payload: value,
      fetched
    });
    setTemplatesArr(value);
  }

  const setTemplatesArr = (tmplts) => {
    let tmpltsArr = sortedArrayFromObject(tmplts, sorterWithPathAndOrder('name', 'desc'))
    tmpltsArr.forEach((tmpl, index) => tmpl.index = index + 1)

    dispatch({
      type: SET_TEMPLATES_ARR,
      payload: tmpltsArr
    });
  }

  const setDocuments = (value, fetched = true) => {
    setDocumentsArr(value);
    dispatch({
      type: SET_DOCUMENTS,
      payload: value,
      fetched
    });
  }

  const setDocumentsArr = (docs) => {
    const copyOfDocuments = {...docs};
    let activeDocs = {}
    let archivedDocs = {}
    let deletedDocs = {}
    for(let key in copyOfDocuments) {
      if(!copyOfDocuments[key].archived && !copyOfDocuments[key].deleted) {
        activeDocs[key] = copyOfDocuments[key]
      }else if(copyOfDocuments[key].archived) {
        archivedDocs[key] = copyOfDocuments[key] 
      }else if(copyOfDocuments[key].deleted) {
        deletedDocs[key] = copyOfDocuments[key]
      }
    }
    let docsArr = sortedArrayFromObject(activeDocs, sorterWithPathAndOrder('name', 'desc'));
    let archivedDocsArr = sortedArrayFromObject(archivedDocs, sorterWithPathAndOrder('name', 'desc'))
    let deletedDocsArr = sortedArrayFromObject(deletedDocs, sorterWithPathAndOrder('name', 'desc'))
    setDocNumbers({
      active: docsArr.length,
      favorites: docsArr.filter(d => d.favorite).length,
      archived: archivedDocsArr.length,
      deleted: deletedDocsArr.length
    });
    const categories = [];
    const tags = [];
    const prices = [];
    const pricesArr = []
    let totalTime = 0

    docsArr.forEach((doc, idx) => {
      doc.index = idx + 1
      doc.sort_status = doc.status === 'empty' ? '1' : t(`status.${doc.status}`)
      doc.sort_template = doc.template ? getTemplateById(doc.template) ? 'yes' : 'no' : 'no'
      doc.sort_attachments = doc.attachments ? doc.attachments.length : 0
      doc.sort_tasks = doc.tasks ? doc.tasks.length : 0
      doc.sort_dates = doc.dates ? !isObjectEmpty(doc.dates) ? numberOfValuesInObj(doc.dates) : 0 : 0
      doc.sort_members = doc.members ? doc.members.length : 0
      doc.sort_alerts = doc.doc_alerts ? doc.doc_alerts.length : 0
      doc.sort_tags = doc.info?.keywords ? doc.info.keywords.length : 0
      if(doc.info && doc.info.categories) {
        doc.info.categories.forEach((category) => {
          if(category.includes('_') || category.toLowerCase() === 'mediations' || category.toLowerCase() === 'other') {
            const found = categories.find(c => c.name === category);
            if(found) {
              categories.splice(categories.indexOf(found), 1, { name: found.name, count: found.count + 1 });
            }else {
              categories.push({ name: category.toLowerCase(), count: 1});
            }
          }
        });
      }
      if(doc.info && doc.info.keywords) {
        doc.info.keywords.forEach(kw => {
          const found = tags.find(t => t.name === kw);
          if(found) {
            tags.splice(tags.indexOf(found), 1, { name: found.name, count: found.count + 1 });
          }else {
            tags.push({ name: kw, count: 1});
          }
        });
      }
      if(doc.info && doc.info.amount && doc.info.currency) {
        let euro = doc.info.currency === 'euro' ? +doc.info.amount : Math.round(CONVERSION_RATES[doc.info.currency].euro * +doc.info.amount)
        let dollar = doc.info.currency === 'dollar' ? +doc.info.amount : Math.round(CONVERSION_RATES[doc.info.currency].dollar * +doc.info.amount)
        let pound = doc.info.currency === 'pound' ? +doc.info.amount : Math.round(CONVERSION_RATES[doc.info.currency].pound * +doc.info.amount)

        prices.push({ 
          name: doc.name, 
          id: doc.id,
          price: {
            euro,
            dollar,
            pound,
          } 
        });
        if(pricesArr.length === 0) {
          pricesArr.push({ 
            euro,
            dollar,
            pound,
          })
        }else {
          let priceAlreadyAdded = pricesArr.find(p => p.euro === euro)
          if(!priceAlreadyAdded) {
            pricesArr.push({ 
              euro,
              dollar,
              pound,
            })
          }
        }
      }
      if(doc.uploaded) {
        doc.progress = -0.1
      }

      if(doc.custom_fields_values) {
        for(let key in doc.custom_fields_values) {
          if(Array.isArray(doc.custom_fields_values[key])) {
            doc[`sort_cf_${key}__arr`] = doc.custom_fields_values[key].length
          }else {
            doc[`sort_cf_${key}`] = doc.custom_fields_values[key]
          }
        }
      }

      if(doc.total_edit_time) {
        totalTime += doc.total_edit_time
      }
    });
    
    setTotalEditTime(totalTime)

    archivedDocsArr.forEach((doc, idx) => {
      doc.index = idx + 1
      doc.sort_status = doc.status === 'empty' ? '1' : t(`status.${doc.status}`)
      doc.sort_template = doc.template ? getTemplateById(doc.template) ? 'yes' : 'no' : 'no'
      doc.sort_attachments = doc.attachments ? doc.attachments.length : 0
      doc.sort_tasks = doc.tasks ? doc.tasks.length : 0
      doc.sort_dates = doc.dates ? !isObjectEmpty(doc.dates) ? numberOfValuesInObj(doc.dates) : 0 : 0
      doc.sort_members = doc.members ? doc.members.length : 0
      doc.sort_alerts = doc.doc_alerts ? doc.doc_alerts.length : 0
      doc.sort_tags = doc.info?.keywords ? doc.info.keywords.length : 0
    })

    deletedDocsArr.forEach((doc, idx) => {
      doc.index = idx + 1
      doc.sort_status = doc.status === 'empty' ? '1' : t(`status.${doc.status}`)
      doc.sort_template = doc.template ? getTemplateById(doc.template) ? 'yes' : 'no' : 'no'
      doc.sort_attachments = doc.attachments ? doc.attachments.length : 0
      doc.sort_tasks = doc.tasks ? doc.tasks.length : 0
      doc.sort_dates = doc.dates ? !isObjectEmpty(doc.dates) ? numberOfValuesInObj(doc.dates) : 0 : 0
      doc.sort_members = doc.members ? doc.members.length : 0
      doc.sort_alerts = doc.doc_alerts ? doc.doc_alerts.length : 0
      doc.sort_tags = doc.info?.keywords ? doc.info.keywords.length : 0
    })

    const totalAmount = prices.reduce((prev, current) => prev + current.price.euro, 0);
    sortArrayOfObjects(prices, 'price.euro', 'asc')
    dispatch({
      type: SET_DOCUMENTS_ARR,
      payload: {
        docs: docsArr,
        archivedDocs: archivedDocsArr,
        deletedDocs: deletedDocsArr,
        favoriteDocs: docsArr.filter(d => d.favorite),
        categories,
        tags,
        prices,
        totalAmount,
        pricesArr
      }
    });
  }

  const setFetchDocuments = (value) => {
    dispatch({
      type: SET_FETCH_DOCUMENTS,
      payload: value
    })
  } 

  const setActions = (value) => {
    dispatch({
      type: SET_ACTIONS,
      payload: value
    })
  }

  const setFetchActions = (value) => {
    dispatch({
      type: SET_FETCH_ACTIONS,
      payload: value
    })
  }

  const setArchivedDocuments = (value) => {
    dispatch({
      type: SET_ARCHIVED_DOCUMENTS,
      payload: value
    });
  }

  const setDeletedDocuments = (value) => {
    dispatch({
      type: SET_DELETED_DOCUMENTS,
      payload: value
    });
  }

  const setFavoriteDocuments = (value) => {
    dispatch({
      type: SET_FAVORITE_DOCUMENTS,
      payload: value
    });
  }

  const setFilteredDocuments = (value) => {
    dispatch({
      type: SET_FILTERED_DOCUMENTS,
      payload: value
    });
  }

  const setShowFilteredDocs = (value) => {
    dispatch({
      type: SET_SHOW_FILTERED_DOCS,
      payload: value
    });
  }

  const setCurrentFilter = (filter) => {
    dispatch({
      type: SET_CURRENT_FILTER,
      payload: filter
    });
  }

  const setActiveFilterColumn = (filter) => {
    dispatch({
      type: SET_ACTIVE_FILTER_COLUMN,
      payload: filter
    });
  }

  const setDateFilters = (value) => {
    dispatch({
      type: SET_DATE_FILTERS,
      payload: value
    });
  }

  const setFilteredTemplates = (value) => {
    dispatch({
      type: SET_FILTERED_TEMPLATES,
      payload: value
    });
  }

  const setTemplatesLoading = (value) => {
    dispatch({
      type: SET_TEMPLATES_LOADING,
      payload: value
    });
  }

  const setDocumentLists = (value) => {
    let arr = []
    if(value) {
      for(let key in value) {
        arr.push({
          ...value[key],
          id: key,
        })
      }
    }
    dispatch({
      type: SET_DOCUMENT_LISTS,
      payload: value,
      arr: sortArrayOfObjects(arr, 'name', 'desc')
    })
  }

  // Get templates
  const getTemplates = async (teamId) => {
    setTemplatesLoading(true);
    try {
      const templates = await fetch_templates(teamId);
      if(templates.error) {
        setTemplates({})
        setTemplatesLoading(false)
        throw new Error(templates.error?.message || 'documents cannot be fetched')
      }
      setTemplatesLoading(false);
      setTemplates(templates);
    }catch(err) {
      console.log(err);
      setTemplatesLoading(false);
    }
  }

  // Get templatesby ids
  const getTemplatesByIds = async (teamId, ids) => {
    try {
      const combinedArrays = divideArrayToMultipleArrays(ids)
      let promises = []
      let combinedObj = {}
      for(let i = 0; i < combinedArrays.length; i++) {
        let tmpltsIds = combinedArrays[i]
        promises.push(fetch_templates_by_ids(teamId, tmpltsIds))
      }
      const result = await Promise.all(promises)
      if(result && Object.keys(result).length > 0) {
        for(let key in result) {
          combinedObj = {...combinedObj, ...result[key]}
        }
      }
      
      let arr = []
      for(let id in combinedObj) {
        arr.push({ id, data: { ...combinedObj[id] } })
      }
      updateMultipleTemplates(arr)
      return arr
    }catch(err) {
      console.log(err)
    }
  }

  // Update templates
  const updateTemplate = async (data, id, single = true, loadTemplates = true) => {
    setTemplatesLoading(true);
    try {
      const res = await update_template(data, id)
      if(res.error) {
        return {
          success: false,
          message: res.error.message || ''
        }
      }
      if(single) {
        const t = {...state.templates};
        t[id] = {...t[id], ...data};
        setTemplates(t);
      }
      // if(loadTemplates) {
      //   await getTemplates();
      // }
      setTemplatesLoading(false);
      return {
        success: true
      }
    }catch(err) {
      console.log(err); 
      setTemplatesLoading(false);
    }
  } 

  // Update multiple templates
  const updateMultipleTemplates = (arr) => {
    const t = {...state.templates};
    arr.forEach(tmpl => {
      t[tmpl.id] = {...t[tmpl.id], ...tmpl.data};
    });
    setTemplates(t);
  }

  // Delete multiple templates
  const deleteMultipleTemplates = (arr) => {
    const tmplts = {...state.templates}
    arr.forEach(id => {
      if(tmplts[id]) {
        delete tmplts[id]
      }
    })
    setTemplates(tmplts)
  }

  // Delete template
  const deleteTemplate = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const tmplts = {...state.templates}
      await delete_template(id)
      delete tmplts[id]
      setTemplates(tmplts)
      onSuccess()
    } catch (err) {
      console.log('DELETE TEMPLATE err', err)
      onError(err)
    }
  }

  // Set active vars section
  const setActiveVarsSection = (section) => {
    dispatch({
      type: SET_ACTIVE_VARS_SECTION,
      payload: section
    });
  }

  // Get template by id
  const getTemplateById = (id) => {
    const template = [...state.templatesArr].find(t => t.id === id);
    return template;
  }

  // Create document
  const createDocument = async (data, onSuccess = () => {}, onError = () => {}, docsFetched = true) => {
    try {
      const res = await create_document(data)
      let doc = {...data, id: res.documentId}
      const docs = {...state.documents}
      docs[res.documentId] = doc
      setDocuments(docs, docsFetched)
      onSuccess(res.documentId)
    } catch (err) {
      console.log('create document error', err)
      onError(err)
    }
  }

  // Add multiple documents 
  const addMultipleDocuments = (arr) => {
    const docs = {...state.documents}
    for(let i = 0; i < arr.length; i++) {
      const doc = arr[i]
      docs[doc.id] = doc 
    }
    setDocuments(docs)
  }

  // Update document
  const updateDocument = async (data, doc, single = true, updateAtts = false, fetch = false, teamId = null) => {
    try {
      const id = doc.id;
      let updatedDoc;
      // if(updateAtts) {
      //   updatedDoc = await update_document(id, data, doc.attachments || []);
      // }else {
        updatedDoc = await update_document(id, data);
      // }
      if(single) {
        const docs = {...state.documents};
        docs[id] = {...docs[id], ...data, sort_alerts: docs[id].doc_alerts ? docs[id].doc_alerts.length : 0};
        docs[id].meta.updated = Date.now();
        setDocuments(docs, false);
      }
      if(fetch && teamId) {
        await fetchDocs(teamId)
      }
      return updatedDoc;
    }catch(err) {
      console.log(err); 
      setTemplatesLoading(false);
    }
  }

  // Update multiple documents
  const updateMultipleDocuments = (arr) => {
    const docs = {...state.documents};
    arr.forEach(doc => {
      docs[doc.id] = {...docs[doc.id], ...doc.data};
    });
    setDocuments(docs, false);
  }

  // Delete multiple documents
  const deleteMultipleDocuments = (arr) => {
    const docs = {...state.documents}
    let filtered = {}
    for(let key in docs) {
      if(!arr.includes(key)) {
        filtered[key] = docs[key]
      }
    }
    setDocuments(filtered)
  }

  // Get document by id
  const getDocumentById = (id) => {
    const doc = [...state.documentsArr].find(d => d.id === id);
    return doc;
  }

  // Reset doc filters
  const resetDocFilters = () => {
    setDocFilters({
      owner: '',
      date_after: '',
      date_before: '',
      folder: '',
      status: '',
      name: ''
    });
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {
      //   ...initialState, 
      //   templatesLoaded: true, 
      //   documentsLoaded: true, 
      //   templates: state.templates, 
      //   templatesArr: state.templatesArr,
      //   fetchDocuments: state.fetchDocuments,
      //   fetchActions: state.fetchActions
      // } : initialState
      payload: initialState
    });
    setUserFilter('');
    setCopiedAttachments([]);
    setAttachmentsFrom(null);
    setTitle('');
    resetDocFilters();
    setDocNumbers({ active: 0, archived: 0, deleted: 0, favorites: 0 })
    const selectedTeam = getSelectedTeamFromLS()
    const sortingData = getTeamSortingData()

    let defaultSortDocs = { value: 'name', order: 'desc' }
    let defaultSortTmplts = { value: 'name', order: 'desc' }
    if(selectedTeam && sortingData && sortingData[selectedTeam]) {
      if(sortingData[selectedTeam].documents) {
        defaultSortDocs = { value: sortingData[selectedTeam].documents.value || 'name', order: sortingData[selectedTeam].documents.order || 'desc' }
      }
      if(sortingData[selectedTeam].templates) {
        defaultSortTmplts = { value: sortingData[selectedTeam].templates.value || 'name', order: sortingData[selectedTeam].templates.order || 'desc' }
      }
    }
    setDocsSort(defaultSortDocs)
    setTemplatesSort(defaultSortTmplts)
    setDocumentsReset(true)
    setTemplatesReset(true)
    setDocumentsView('list')
  }

  // Get single template
  const getSingleTemplate = async (id, set = false) => {
    try {
      const res = await fetch_single_template(id)
      if(res && set) {
        setTemplates({ ...state.templates, [id]: res }, false)
      }
      return res
    } catch (err) {
      console.log(err)
    }
  }

  // Get single document
  const getSingleDocument = async (id, set = false) => {
    try {
      const res = await fetch_single_document(id)
      if(res && set) {
        setDocuments({ ...state.documents, [id]: res }, false)
      }
      return res
    } catch (err) {
      console.log(err)
    }
  }

  // Update approval steps
  const updateApprovalSteps = async (data, onSuccess = () => {}, onFail = () => {}, onError = () => {}) => {
    try {
      const res = await update_approval_steps(data)
      if(!res.success) {
        onFail(res.key)
        return
      }
      const id = data.document_id
      const docs = {...state.documents}
      docs[id] = {...docs[id], approval: res.data }
      setDocuments(docs, false)
      onSuccess()
    }catch (err) {
      console.log('update approval step err', err)
      onError(err)
    }
  }

  // Reset filters
  const resetDocsSelectedFilters = () => {
    setDocsSelectedFilters({
      folder: 'all',
      search: ''
    })
  }

  return <DocumentsContext.Provider value={{
    templates: state.templates,
    documents: state.documents,
    fetchDocuments: state.fetchDocuments,
    actions: state.actions,
    fetchActions: state.fetchActions,
    filteredDocuments: state.filteredDocuments,
    archivedDocuments: state.archivedDocuments,
    deletedDocuments: state.deletedDocuments,
    favoriteDocuments: state.favoriteDocuments,
    showFilteredDocs: state.showFilteredDocs,
    currentFilter: state.currentFilter,
    activeFilterColumn: state.activeFilterColumn,
    dateFilters: state.dateFilters,
    filteredTemplates: state.filteredTemplates,
    templatesLoading: state.templatesLoading,
    activeVarsSection: state.activeVarsSection,
    actionsFetched: state.actionsFetched,
    templatesLoaded: state.templatesLoaded,
    documentsLoaded: state.documentsLoaded,
    templatesArr: state.templatesArr,
    documentsArr: state.documentsArr,
    archivedDocsArr: state.archivedDocsArr,
    deletedDocsArr: state.deletedDocsArr,
    favoriteDocsArr: state.favoriteDocsArr,
    categories: state.categories,
    tags: state.tags,
    prices: state.prices,
    pricesArr: state.pricesArr,
    totalAmount: state.totalAmount,
    showFilteredTemplates: state.showFilteredTemplates,
    copiedAttachments,
    attachmentsFrom,
    userFilter,
    title,
    docFilters,
    docsSort,
    templatesSort,
    docNumbers,
    documentLists: state.documentLists,
    documentListsArr: state.documentListsArr,
    documentListsFetched: state.documentListsFetched,
    fetchDocs,
    setTemplates,
    setDocuments,
    setFetchDocuments,
    setFilteredDocuments,
    setArchivedDocuments,
    setDeletedDocuments,
    setFavoriteDocuments,
    setShowFilteredDocs,
    setCurrentFilter,
    setActiveFilterColumn,
    setDateFilters,
    setFilteredTemplates,
    setTemplatesLoading,
    getTemplates,
    updateTemplate,
    setActiveVarsSection,
    setActions,
    setFetchActions,
    getTemplateById,
    updateMultipleTemplates,
    deleteMultipleTemplates,
    updateDocument,
    updateMultipleDocuments,
    getDocumentById,
    resetDocState: resetState,
    setCopiedAttachments,
    setAttachmentsFrom,
    setUserFilter,
    setTitle,
    setDocFilters,
    resetDocFilters,
    setDocsSort,
    setTemplatesSort,
    setDocNumbers,
    setDocumentLists,
    deleteTemplate,
    createDocument,
    addMultipleDocuments,
    getSingleTemplate,
    getSingleDocument,
    updateApprovalSteps,
    documentsReset,
    setDocumentsReset,
    deleteMultipleDocuments,
    getTemplatesByIds,
    templatesReset,
    setTemplatesReset,
    documentsView,
    setDocumentsView,
    totalEditTime,
    docsSelectedFilters,
    setDocsSelectedFilters,
    resetDocsSelectedFilters,
    templatesSelectedFilters,
    setTemplatesSelectedFilters,
  }}>
    {children}
  </DocumentsContext.Provider>
}

export default DocumentsState;
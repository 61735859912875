import { GET_FOLDERS, SET_FOLDERS_LOADING, SET_FOLDERS, RESET_STATE } from '../types';

export default (state, action) => {
  switch(action.type) {
    case GET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
        foldersFetched: true,
        loading: false
      }
    case SET_FOLDERS:
      return {
        ...state,
        folders: action.payload
      }
    case SET_FOLDERS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}
import React, { useState, useRef, useEffect, memo, useContext } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';

import Button from '../../UI/Button';
import Input from '../../UI/Input';
import CustomTooltip from '../../UI/CustomTooltip';
import SPVariableListItem from '../../UI/SPVariableListItem';

import { areSectionConditionsMet, isOverflown } from '../../../utils';
import { GlobalContext } from '../../../context'

const DocumentDetailSPVariablesCopy = ({ variableTypes, totalProgress, onItemClick, documentValues, onSynthesisClicked, activeVariable, scrollingPosition, onSetScrollPosition, prevScrollPosition, templateData, onSectionClick, scrollingToItem, isSharedTemplate }) => {
  const [search, setSearch] = useState('');
  const [filteredVariables, setFilteredVariables] = useState([]);
  const spVariablesEl = useRef();
  const spVariablesBodyEl = useRef();
  const [variableSections, setVariableSections] = useState([]);
  const [prevActiveEl, setPrevActiveEl] = useState(null);
  const { t } = useContext(GlobalContext)

  useEffect(() => {

    const valueForVariable = (variable, repeatableId, repeatableIndex) => {
      if(repeatableId) {
        return documentValues[repeatableId]?.[repeatableIndex]?.[variable] || ""
      }
      return documentValues[variable] || ""
    }

    if(!templateData) {
      return;
    }
    const sections = [...templateData.sections];
    const sectionsHeadings = sections.filter(s => s.type.startsWith('heading'));
    const sectionsUpdated = [];
    if(sectionsHeadings.length === 0) {
      sectionsUpdated.push(sections);
    }else if(sections.indexOf(sectionsHeadings[0]) > 0) {
      sectionsUpdated.push(sections.slice(0, sections.indexOf(sectionsHeadings[0])));
    }
    sectionsHeadings.forEach((s, i, allArr) => {
      const sectionIndex = sections.indexOf(s);
      const arr = sections.indexOf(allArr[i+1]) !== -1 ? sections.slice(sectionIndex, sections.indexOf(allArr[i+1])) : sections.slice(sectionIndex);
      arr[0].sectionIndex = `section-${sectionIndex}`;
      sectionsUpdated.push(arr);
    });
    const sectionsArr = [];
    // const vars = [];

    sectionsUpdated.forEach((s) => {
      let obj = { title: '', fields: [], progress: 0, vars: [] };

      let idx = 0;
      for(let secField in s) {
        const f = s[secField];
        if(!areSectionConditionsMet(f, documentValues)) {
          idx++;
          continue
        }
        if(idx === 0 && f.type.startsWith('heading')) {
          const fields = f.content.match(/\{d\.([^}]*)\}/g);
          const title = fields ? fields.length > 0 ? f.content.replace(fields[0], '') : f.content : f.content;
          obj.title = title;
          obj.index = f.sectionIndex;
        }
        if(f.variables || f.variable){
          if(f.variable) {
            obj.fields.push(f);
          }else {
            f.variables.forEach((v) => {
              if(obj.fields.indexOf(f) === -1) {
                obj.fields.push(f);
              }
            });
          }
        }
        idx++;
      }

      let totalVars = 0;
      let totalValues = 0
      obj.fields.forEach((f) => {
        if(f.variable) {
          if(f.repeatable_section_id) {
            totalVars += (documentValues[f.repeatable_section_id] || []).length;
            (documentValues[f.repeatable_section_id] || []).forEach((d) => {
              if(d[f.variable]) {
                totalValues++;
              }
            });
          } else {
            totalVars++;
            if(valueForVariable(f.variable)) {
              totalValues++;
            }
          }
        }
        if(f.variables) {
          if(f.repeatable_section_id) {
            totalVars += (documentValues[f.repeatable_section_id] || []).length * f.variables.length;
            (documentValues[f.repeatable_section_id] || []).forEach((d) => {
              f.variables.forEach((v) => {
                if(d[v.variable]) {
                  totalValues++;
                }
              });
            });
          } else {
            totalVars += f.variables.length;
            f.variables.forEach((v) => {
              if(valueForVariable(v.variable)) {
                totalValues++;
              }
            });
          }
        }
      });
      obj.progress = (totalValues === 0 || totalVars === 0 ? 0 : totalValues / (totalVars / 100)).toFixed(0)
      sectionsArr.push(obj);
    });

    setVariableSections(sectionsArr);
    setFilteredVariables(sectionsArr);
    // eslint-disable-next-line
  }, [templateData, documentValues]);

  useEffect(() => {
    const spActiveVarEl = spVariablesBodyEl.current.querySelector(`[data-id="${activeVariable}"]`);
    if(spActiveVarEl && spActiveVarEl !== prevActiveEl && !scrollingToItem) {
      const wrapperTopPosition = spVariablesBodyEl.current.getBoundingClientRect().top;
      const varPosition = spActiveVarEl.getBoundingClientRect();
      setPrevActiveEl(spActiveVarEl);

      if(varPosition.top > wrapperTopPosition) {
        spVariablesBodyEl.current.scrollTo({ top: varPosition.top + spVariablesBodyEl.current.scrollTop - wrapperTopPosition });
      }else if (varPosition.top < wrapperTopPosition) {
        spVariablesBodyEl.current.scrollTo({ top: varPosition.top + spVariablesBodyEl.current.scrollTop - wrapperTopPosition });
      }
    }
    // eslint-disable-next-line
  }, [scrollingPosition]);

  const searchChangeHandler = (e) => {
    const value = e.target.value;
    setSearch(value);
    const copyVarSections = [...variableSections];
    // const filtered = [...variables].filter(v => v.includes(value.trim().toLowerCase()));
    const filtered = copyVarSections.map((varSection, i) => {
      const section = {...varSection};
      if(!section.title.toLowerCase().includes(value.trim().toLowerCase())) {
        section.title = '';
      }else {
        section.title = variableSections[i].title;
      }
      const sectionFields = []; 
      [...section.fields].forEach(f => {
        const sf = {...f};
        if(sf.variables) {
          const varsArr = [...sf.variables];
          const updatedVarsArr = [];
          varsArr.forEach(v => {
            if(v.variable.toLowerCase().includes(value.trim().toLowerCase())) {
              updatedVarsArr.push(v);
            }
          });
          if(updatedVarsArr.length > 0) {
            sf.variables = updatedVarsArr;
            sectionFields.push(sf);
          }
        }else {
          if(sf.variable.toLowerCase().includes(value.trim().toLowerCase())) {
            sectionFields.push(sf);
          }
        }
      });
      section.fields = sectionFields;
      return section;
    });
    setFilteredVariables(filtered);
  }

  return(
    <div className="document-detail-sp-variables" ref={spVariablesEl}>
      <div className="document-detail-sp-variables__head-search-wrapper">
        <div className="document-detail-sp-variables__head">
          <h4>Variables</h4>
          {!isSharedTemplate && <Button
            text={t('dashboard.see_summary')} 
            outlinePrimaryLight
            xSmall
            regular
            onButtonClick={onSynthesisClicked}
          />}
        </div>
        <div className="document-detail-sp-variables__progress">
          <div className="document-detail-sp-variables__progress_inner">
            <div style={{width: `${(totalProgress * 100).toFixed(0)}%`}}></div>
          </div>
          <p>{(totalProgress * 100).toFixed(0)}%</p>
        </div>
        <div className="document-detail-sp-variables__search">
          <Input 
            value={search}
            onChange={searchChangeHandler}
            name="search"
            placeholder="Rechercher"
            medium
            whiteBackground
          />
        </div>
      </div>
      <div className="document-detail-sp-variables__body u-custom-scrollbar" ref={spVariablesBodyEl}>
        {filteredVariables.map((varSection, i) => {
          return <SPVariableSection 
            key={i} 
            varSection={varSection} 
            onSectionClick={onSectionClick} 
            activeVariable={activeVariable} 
            documentValues={documentValues}
            onItemClick={onItemClick}
            variableTypes={variableTypes} 
          />
        })}
      </div>
    </div>
  );
}

const SPVariableSection = ({ varSection, onSectionClick, activeVariable, documentValues, onItemClick, variableTypes }) => {
  const [openVars, setOpenVars] = useState(false);
  let sectionClass = 'document-detail-sp-variables__body_section';

  const sectionClickHandler = (idx) => {
    onSectionClick(idx);
  }

  if(openVars) {
    sectionClass += ' document-detail-sp-variables__body_section--active';
  }

  return (
    <div className={sectionClass} data-index={varSection.index}>
      {varSection.title && <SPVariableHeading 
        onSectionClick={(idx) => sectionClickHandler(idx)} 
        varSection={varSection} 
        activeVariable={activeVariable} 
        index={varSection.index} 
        setOpenVars={setOpenVars}
        openVars={openVars}
      />}
      {openVars && varSection.fields.length > 0 && <ul>
          {varSection.fields.map((f, i) => {
            const output = [];
            if(f.variables) {
              f.variables.forEach((v, i) => {
                const variable = v.variable;
                if(f.repeatable_section_id) {
                  for(let rep_i in documentValues[f.repeatable_section_id]) {
                    output.push(<SPVariableListItem key={`${f.repeatable_section_id}-${rep_i}-${variable}-${i}`} value={documentValues[f.repeatable_section_id][rep_i][variable]} variable={variable} onItemClick={() => onItemClick(`${v.idx}-${rep_i}`)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={`${v.idx}-${rep_i}`} />)
                  }
                } else {
                  output.push(<SPVariableListItem key={`${variable}-${i}`} value={documentValues[variable]} variable={variable} onItemClick={() => onItemClick(v.idx)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={v.idx} />)
                }
              });
            }else {
              const variable = f.variable;
              if(f.repeatable_section_id) {
                for(let rep_i in documentValues[f.repeatable_section_id]) {
                  output.push(<SPVariableListItem key={`${f.repeatable_section_id}-${rep_i}-${variable}-${i}`} variable={variable} value={documentValues[variable]} onItemClick={() => onItemClick(`${f.idx}-${rep_i}`)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={`${f.idx}-${rep_i}`} />)
                }
              } else {
                output.push(<SPVariableListItem key={`${variable}-${i}`} variable={variable} value={documentValues[variable]} onItemClick={() => onItemClick(f.idx)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={f.idx} />)
              }
            }
            return output;
          })}
        </ul>
      }
    </div>
  );
}

const SPVariableHeading = ({ onSectionClick, varSection, activeVariable, index, setOpenVars, openVars }) => {
  const { t } = useContext(GlobalContext)
  const headingEl = useRef();
  let headingClass = '';

  if(index === activeVariable) {
    headingClass = 'active';
  }

  const output = (
    <h2 
      ref={headingEl} 
      onClick={() => onSectionClick(varSection.index)} 
      className={headingClass}
      data-id={index}
    >
      {varSection.title}
      {varSection.fields?.length > 0 && varSection.progress ? <span className="progress">{`${varSection.progress}%`}</span> : null}
      {varSection.fields?.length > 0 && varSection.progress ? <CustomTooltip content={!openVars ? t('general.view') : t('general.hide')}><span className="icon" onClick={() => setOpenVars(!openVars)}><ChevronRightIcon /></span></CustomTooltip> : null}
    </h2>
  );

  // return isOverflown(headingEl.current) ? <CustomTooltip content={varSection.title} fixed>{output}</CustomTooltip> : output;
  return output; 
}

export default memo(DocumentDetailSPVariablesCopy)
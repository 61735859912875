import React, { useState, useContext, useEffect, useCallback } from 'react' 
import MessageIcon from '@material-ui/icons/MessageOutlined'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined'
import SentimentNeutralIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined'

import Modal from '../../UI/Modal'
import Textarea from '../../UI/Textarea'
import Button from '../../UI/Button'
import ResponseLoader from '../../UI/ResponseLoader'
import { send_feedback } from '../../../services/functions'
import { NotificationContext, UserContext, GlobalContext } from '../../../context'

const GiveFeedbackModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { user } = useContext(UserContext)
  const [message, setMessage] = useState('')
  const [selectedRating, setSelectedRating] = useState('')
  const [showLoader, setShowLoader] = useState(false)

  // Change rating
  const changeRatingHandler = (e, rating) => {
    e.preventDefault()
    setSelectedRating(rating)
  }

  // Send feedback
  const submitHandler = useCallback(async (e) => {
    e.preventDefault()

    if(selectedRating === '') return 

    setShowLoader(true)
    let content = null
    if(message.trim() !== '') {
      content = message.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')
    }

    // email html template
    let emailTop = `
      <div style="background-color: #2663EB; color: #ffffff; margin-bottom: 20px; padding: 10px; font-size: 16px;">Rating: <span style="font-weight: bold;">${selectedRating}</span></div>
    `
    let emailHtml = emailTop

    if(content) {
      emailHtml += `
        <div>${content}</div>
      `
    }

    try {
      await send_feedback(user.email, emailHtml, 'Carbon App - User Feedback')
    } catch (err) {
      console.log(err)
    }
    setShowLoader(false)
    setNotification({ msg: t('support.feedback_sent'), type: 'success' })
    onClose()
  }, [message, onClose, selectedRating, setNotification, t, user])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        submitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [submitHandler])

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><MessageIcon /> {t('support.send_feedback')}</h3>
        </div>
        <div className="report-a-bug__body">
          <p>{t('support.how_would_you_rate_app')}</p>
          <form onSubmit={submitHandler}>
            <div className="emojis">
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'terrible')} className={selectedRating === 'terrible' ? 'active' : ''}>
                <SentimentVeryDissatisfiedIcon />
                <span>{t('support.terrible')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'bad')} className={selectedRating === 'bad' ? 'active' : ''}>
                <SentimentDissatisfiedIcon />
                <span>{t('support.bad')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'okay')} className={selectedRating === 'okay' ? 'active' : ''}>
                <SentimentNeutralIcon />
                <span>{t('support.okay')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'great')} className={selectedRating === 'great' ? 'active' : ''}>
                <SentimentSatisfiedIcon />
                <span>{t('support.great')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'excellent')} className={selectedRating === 'excellent' ? 'active' : ''}>
                <SentimentVerySatisfiedIcon />
                <span>{t('support.excellent')}</span>
              </a>
            </div>
            <Textarea value={message} onChange={(e) => setMessage(e.target.value)} formEl placeholder={t('support.leave_us_message')} />
            <div className="btn-wrapper">
              <Button type="submit" text={t('support.send_feedback')} primary medium disabled={selectedRating === ''} />
            </div>
          </form>
        </div>
      </div>

      {showLoader && <ResponseLoader text={t('support.sending_feedback')} />}
    </Modal>
  )
}

export default GiveFeedbackModal
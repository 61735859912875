import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { GlobalContext, LoaderContext, ApprovalsContext, TeamContext, NotificationContext, DocumentsContext } from '../context'

const useSingleApprovalActions = () => {
  const { t } = useContext(GlobalContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { createApproval, updateApproval, deleteApproval } = useContext(ApprovalsContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { setNotification } = useContext(NotificationContext)
  const { documentsArr, updateMultipleDocuments, updateDocument } = useContext(DocumentsContext)
  const history = useHistory()

  // Create
  const createSingleApproval = async ({ name, steps, description }) => {
    if(!name.trim()) {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }
    if(steps.length === 0) {
      return setNotification({ msg: t('approvals.steps_required'), type: 'danger' })
    }
    let stepError = false
    for(let i = 0; i < steps.length; i++) {
      if(steps[i].members.length === 0) {
        stepError = true 
        break
      }
    }
    if(stepError) {
      return setNotification({ msg: t('approvals.step_members_required'), type: 'danger' })
    }

    let data = {
      name: name.trim(),
      description: description.trim(),
      steps,
      meta: {
        created: Date.now(),
        updated: Date.now()
      },
      owner: activeTeamMember.id,
      team: selectedTeam?.id
    }

    setShowGlobalResponseLoader(true)
    createApproval(data, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('approvals.successfully_created'), type: 'success' })
      history.push('/approvals')
    }, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  // Update
  const updateSingleApproval = async ({ name, steps, description, approval }) => {
    if(!name.trim()) {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }
    if(steps.length === 0) {
      return setNotification({ msg: t('approvals.steps_required'), type: 'danger' })
    }
    let stepError = false
    for(let i = 0; i < steps.length; i++) {
      if(steps[i].members.length === 0) {
        stepError = true 
        break
      }
    }
    if(stepError) {
      return setNotification({ msg: t('approvals.step_members_required'), type: 'danger' })
    }

    let data = {
      name: name.trim(),
      description: description.trim(),
      steps,
      meta: {
        ...approval.meta,
        updated: Date.now()
      },
    }

    // update documents where this approval is used if anything changed
    const approvalDocs = documentsArr.filter(d => d.approval && d.approval.id === approval.id)
    let docsToUpdate = []
    let docPromises = []
    for(let i = 0; i < approvalDocs.length; i++) {
      let doc = approvalDocs[i]
      // console.log(doc)
      // console.log(steps)
      let docApproval = {...approvalDocs[i].approval}
      let approvalUpdated = false
      const stepsIds = steps.map(s => s.id)
      const docApprovalStepsIds = Object.keys(docApproval.steps)

      docApprovalStepsIds.forEach(stepId => {
        if(!stepsIds.includes(stepId) && docApproval.steps[stepId]) {
          delete docApproval.steps[stepId]
          approvalUpdated = true
        }
      })
      
      for(let j = 0; j < steps.length; j++) {
        let step = steps[j]
        let members = step.members
        let docApprovalStep = docApproval.steps[step.id]
        if(docApprovalStep) { // update step
          let stepObj = JSON.parse(JSON.stringify(docApprovalStep))
          if(stepObj.approved_by.length > 0) {
            // eslint-disable-next-line no-loop-func
            stepObj.approved_by.forEach(m => {
              if(!members.includes(m)) {
                stepObj.approved_by = stepObj.approved_by.filter(item => item !== m)
                approvalUpdated = true
              }
            })
          }
          if(stepObj.rejected_by.length > 0) {
            // eslint-disable-next-line no-loop-func
            stepObj.rejected_by.forEach(m => {
              if(!members.includes(m)) {
                stepObj.rejected_by = stepObj.rejected_by.filter(item => item !== m)
                approvalUpdated = true
              }
            })
          }
          if(stepObj.approved_by.length === 0 && stepObj.rejected_by.length === 0 && (docApprovalStep.approved_by.length !== 0 || docApprovalStep.rejected_by.length !== 0)) {
            stepObj.status = ''
            stepObj.step_approved = false
            stepObj.request_sent = false
            approvalUpdated = true
          }
          // if(step.from === 'everyone' && stepObj.from !== 'everyone' && stepObj.approved_by.length < step.members.length) {
          //   if(stepObj.status === 'approved') stepObj.status = ''
          //   stepObj.step_approved = false
          //   stepObj.request_sent = false
          //   approvalUpdated = true
          // }
          docApproval.steps[step.id] = stepObj
        }else { // add step
          docApproval.steps[step.id] = { request_sent: false, approved_by: [], rejected_by: [], step_approved: false, status: '' }
        }
      }

      if(approvalUpdated) {
        docsToUpdate.push({ data: { approval: docApproval }, id: doc.id })
        docPromises.push(updateDocument({ approval: docApproval }, doc, false))
      }
    }
    if(docsToUpdate.length > 0) {
      updateMultipleDocuments(docsToUpdate)
    }
    if(docPromises.length > 0) {
      setShowGlobalResponseLoader(true)
      await Promise.all(docPromises)
    }

    setShowGlobalResponseLoader(true)
    updateApproval(data, approval.id, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('approvals.successfully_updated'), type: 'success' })
      history.push('/approvals')
    }, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  // Delete single approval
  const deleteSingleApproval = async ({ id, refreshApprovals }) => {
    setShowGlobalResponseLoader(true)
    deleteApproval(id, () => {
      setShowGlobalResponseLoader(false)
      setNotification({ msg: t('approvals.successfully_deleted'), type: 'success' })
      refreshApprovals()
    }, () => {
      setShowGlobalResponseLoader()
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })
  }

  return {
    createSingleApproval,
    updateSingleApproval,
    deleteSingleApproval,
  }
}

export default useSingleApprovalActions
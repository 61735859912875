import { useContext, useRef } from 'react'

import { GlobalContext, ConstantsContext, SignatureContext, TeamContext, UserContext } from '../../../context'

const SignaturesSidebar = ({ creditsLeft, creditsLoading, onBuyMoreCredits, setMainTitle }) => {
  const { t } = useContext(GlobalContext)
  const { SIGNATURE_STATUS_PROVIDERS } = useContext(ConstantsContext)
  const { setSignaturesSelectedFilters } = useContext(SignatureContext)
  const { selectedTeam } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const statusListEl = useRef()

  // On status click
  const handleStatusClick = (status, e) => {
    const liEl = e.target.closest('li')
    const hasActiveClass = liEl.classList.contains('active')
    removeActiveClassFromStatus()
    if(hasActiveClass){
      e.target.closest('li').classList.remove('active')
      setSignaturesSelectedFilters(prev => ({ ...prev, status: 'all' }))
      setMainTitle(t('general.all'))
    }else {
      e.target.closest('li').classList.add('active')
      setSignaturesSelectedFilters(prev => ({ ...prev, status }))
      setMainTitle(SIGNATURE_STATUS_PROVIDERS[status])
    }
  }

  // Remove active class
  const removeActiveClassFromStatus = () => {
    statusListEl.current.querySelectorAll('li').forEach(li => li.classList.contains('active') && li.classList.remove('active'))
  }

  // Check if user is team owner
  const isTeamOwner = () => {
    if(!selectedTeam || !user) {
      return false
    }
    return selectedTeam.owner === user.id
  }

  // On buy more credits button click
  const handleBuyMoreCreditsClick = () => {
    onBuyMoreCredits()
  }

  return (
    <div className="collection-sidebar">
      <h1 className="heading-1">{t('dashboard.signatures_2')}</h1>

      <section className="statuses">
        <h5 className="heading-5">{t('general.statuses')}</h5>
        <ul ref={statusListEl}>
          {Object.entries(SIGNATURE_STATUS_PROVIDERS).map((status, index) => (
            <li key={index} className={status[0]} onClick={(e) => handleStatusClick(status[0], e)}><span></span> {status[1]}</li>
          ))}
        </ul>
      </section>

      <section className="credits">
        <div className="credits__num text-style-1">
          {t('general.credits_left')}: { creditsLoading && <div className="loader-v2 loader-v2--mini"></div> } { !creditsLoading && creditsLeft }
        </div>
        {isTeamOwner && (
          <button className="btn btn--full" onClick={handleBuyMoreCreditsClick}>{t('general.buy_more_credits')}</button>
        )}
      </section>
    </div>
  )
}

export default SignaturesSidebar
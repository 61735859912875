import axios from 'axios'
import config from '../config.json'
import { ENVIRONMENT_DEVELOPMENT } from '../constants'
import { fetchLawstudioHeaders } from './functions'
const urls = {
  development: 'https://dev-api.lawstud.io',
  production: 'https://api.lawstud.io'
}

const baseUrl = () => {
  // return 'http://localhost:8080'
  if(config.environment === ENVIRONMENT_DEVELOPMENT) {
    return urls.development
  } else {
    return urls.production
  }
}

const generate_document = async (templateId, data, options, contentChanges = [], team) => {
  let processedData = {}
  for(let key in data) {
    if(Array.isArray(data[key])) {
      if(data[key].length > 0 && typeof data[key][0] !== 'object') {
        processedData[key] = data[key].join('\n');
      } else {
        processedData[key] = data[key]
      }
    } else {
      processedData[key] = data[key]
    }
  }

  if(!options) {
    options = {
      convertTo: 'pdf'
    }
  }

  const headers = await fetchLawstudioHeaders(team)

  if(contentChanges) {
    options.contentChanges = contentChanges
  }
  
  // switched to generate document so we can track/limit usage per team

  const url = `${baseUrl()}/v2/generate_document`
  let response = await axios.post(url, {
    // template_url: templateUrl,
    template_id: templateId,
    // data: processedData,
    values: processedData,
    options: options
  }, {
    headers: {
      ...headers,
      "Content-Type": "application/json"
    }
  })
  if(response.data.error) {
    console.log("lawstudio api error")
    console.log(response.data)
    return response.data
  }
  return response.data.data.base64
}

const generate_document_for_shared_templates = async (templateUrl, data, extension) => {
  let processedData = {}
  for(let key in data) {
    if(Array.isArray(data[key])) {
      if(data[key].length > 0 && typeof data[key][0] !== 'object') {
        processedData[key] = data[key].join('\n');
      } else {
        processedData[key] = data[key]
      }
    } else {
      processedData[key] = data[key]
    }
  }
  console.log('generate document with data', processedData)
  const url = `${baseUrl()}/create_document_with_url`
  let response = await axios.post(url, {
    template_url: templateUrl,
    data: processedData,
    extension
  })
  if(response.data.error) {
    console.log("lawstudio api error")
    console.log(response.data)
  }
  return response.data
}

const convert_attachment = async (attachmentUrl) => {
  const url = `${baseUrl()}/convert_attachment`
  let response = await axios.post(url, {
    attachment_url: attachmentUrl
  })
  if(response.data.error) {
    console.log("lawstudio api error")
    console.log(response.data)
  }
  return response.data
}

export {
  generate_document,
  generate_document_for_shared_templates,
  convert_attachment
}
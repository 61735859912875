import { useState, useContext } from 'react'

import  { Modal, Input } from '../../new_ui'
import { GlobalContext } from '../../../context'

const RenameTeamModal = ({ onClose, team, rename }) => {
  const { t } = useContext(GlobalContext)
  const [name, setName] = useState(team.name ? team.name : '')
  const [nameError, setNameError] = useState('')

  // Name change
  const handleChange = (e) => {
    setName(e.target.value);
    if(nameError !== '') {
      setNameError('')
    }
  } 

  // Submit
  const handleSubmit = () => {
    if(name === team.name) return
    if(name.trim() === '') return setNameError(t('notificaion.required_field'))
    rename(name)
  }

  return (
    <Modal 
      onClose={onClose}
      title={t('team.name_field_label')}
      saveBtnText={t('general.rename')}
      className="invite-popup centered-head"
      onSave={handleSubmit}
    >
      <div className="invite-users">
        <Input value={name} onChange={handleChange} formEl thickBorder error={nameError} />
      </div>
    </Modal>
  )
}

export default RenameTeamModal
import { useContext, useState, useEffect } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Collapse, Dialog, IconButton } from '@material-ui/core'
import { Check, Help } from "@material-ui/icons"
import moment from 'moment'

import CustomTooltip from '../components/UI/CustomTooltip'
import { SettingsLayout } from '../layouts'
import { Input } from '../components/new_ui'
import { InvoicesList } from '../components/misc'
import { GlobalContext, UserContext, LoaderContext, NotificationContext } from '../context'
import { cancel_subscription, handlePaymentThatRequiresCustomerAction, upgradeMembership, fetch_upcoming_invoice, payInvoiceManually, fetch_invoice_detail, updatePaymentMethod } from '../services/billing'
import { validateEmail } from '../helpers/validate'
import { capitalizeWord } from '../utils'

import CardLogoVisa from '../assets/images/card_logo_visa.png'
import CardLogoMastercard from '../assets/images/card_logo_mastercard.png'

const Membership = () => {
  const { t } = useContext(GlobalContext)
  const { subscription, user, setUser, invoices } = useContext(UserContext)
  const { loading, setLoading } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const [cardValid, setCardValid] = useState(false)
  const [cardError, setCardError] = useState(null)
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const [upcomingInvoice, setUpcomingInvoice] = useState({
    isOpen: false,
    invoice: null,
    isLoading: false
  })
  const [checkout, setCheckout] = useState({
    isOpen: false,
    name: '',
    email: '',
    paymentMethod: null,
    useNewPaymentMethod: true,
    action: ''
  })
  const [cancel, setCancel] = useState({
    isOpen: false
  })
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if(subscription && loading) {
      setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription])

  // Set checkout
  useEffect(() => {
    if(!user) {
      return
    }
    if(!checkout.name) {
      setCheckout({
        ...checkout,
        name: `${user.first_name || ''}${user.first_name ? ' ' : ''}${user.last_name || ''}`,
        email: user.email,
        paymentMethod: user.payment_method,
        useNewPaymentMethod: !Boolean(user.payment_method)
      })
    }
  }, [user, checkout, setCheckout])

  // On payment cancel
  const onPaymentCancel = () => {
    closeCheckout()
  }

  // On cancel
  const onCancelSubmit = async () => {
    // TODO validate if user doesn't have any open invoices in current subscription
    setLoadingCancel(true)
    const cancelResponse = await cancel_subscription(subscription.subscription_id)
    console.log('cancel response', cancelResponse)
    // TODO show loading until change is applied
    setLoadingCancel(false)
    setCancel({
      ...cancel,
      isOpen: false
    })
  }

  // On payment submit
  const onCheckoutSubmit = async () => {
    console.log('on payment submit')
    
    setLoadingCheckout(true)
    const cardElement = elements.getElement(CardElement);

    if(checkout.action === 'update_payment_method') {
      console.log('handle update payment method')
      const updateResponse = await updatePaymentMethod(stripe, user, checkout.name, checkout.email, cardElement)
      console.log(updateResponse)
      setLoadingCheckout(false)
      return
    }

    if(checkout.invoice) {
      console.log('handle invoice payment')
      await handleInvoicePayment()
      setLoadingCheckout(false)
      return
    }


    if(checkout.useNewPaymentMethod && !cardElement) {
      console.log('card element error')
      setLoadingCheckout(false)
      return
    }


    const subscriptionResponse = await upgradeMembership(
      stripe,
      user,
      setUser,
      checkout.name,
      checkout.email,
      cardElement,
      checkout.paymentMethod?.id
    )

    console.log(subscriptionResponse)

    setLoadingCheckout(false)

    const { status, error } = subscriptionResponse

    if(error) {
      // todo apply invoice to checkout if subscription is already created
      setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
      if(status === 'incomplete' && subscriptionResponse.latest_invoice) {
        setCheckout({
          ...checkout,
          invoice: subscriptionResponse.latest_invoice
        })
      }
      console.log('subscription error')
      console.log(error)
      return
    }

    closeCheckout()
  }

  // Handle invoice action
  const handleInvoiceAction = async (invoice) => {
    setLoading(true)
    const invoiceDetailResponse = await fetch_invoice_detail(invoice.id)
    console.log(invoiceDetailResponse)
    if(!invoiceDetailResponse.invoice?.payment_intent?.payment_method) {
      setLoading(false)
      setCheckout({
        ...checkout,
        isOpen: true,
        invoice: invoiceDetailResponse.invoice,
        action: 'invoice'
      })
      return
    }
    const actionResponse = await handlePaymentThatRequiresCustomerAction(stripe, checkout.name, checkout.email, invoiceDetailResponse.invoice.payment_intent)
    if(actionResponse.error) {
      const { error } = actionResponse
      console.log('customer action error')
      console.log(error)
      // setNotification({ msg: t(`billing_error.${actionResponse.error.code}`), type: 'danger' })
      setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
    }
    setLoading(false)
  }

  // Handle invoice payment
  const handleInvoicePayment = async () => {
    const invoice = checkout.invoice || subscription.latest_invoice

    const cardElement = checkout.useNewPaymentMethod ? elements.getElement(CardElement) : null

    if(checkout.useNewPaymentMethod && !cardElement) {
      console.log('card element error')
      setLoadingCheckout(false)
      return
    }

    console.log('handle invoice', invoice)
    const paymentReponse = await payInvoiceManually(
      invoice,      
      stripe,
      user,
      setUser,
      checkout.name,
      checkout.email,
      cardElement,
      checkout.paymentMethod?.id
    )
    const { error } = paymentReponse
    console.log('payment response', paymentReponse)
    if(error) {
      // todo handle error
      if(error.code === 'invoice_payment_intent_requires_action') {
        const actionResponse = await handlePaymentThatRequiresCustomerAction(stripe, checkout.name, checkout.email, invoice.payment_intent, cardElement)
        console.log(actionResponse)
        if(actionResponse.error) {
          const { error } = actionResponse
          console.log('customer action error')
          console.log(error)
          // setNotification({ msg: t(`billing_error.${actionResponse.error.code}`), type: 'danger' })
          setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
          return
        }
      } else {
      setNotification({ msg: t(`billing_error.${error.code === 'card_declined' ? error.decline_code : error.code}`), type: 'danger' })
      console.log('payment error')
      console.log(error)
      
      return
      }
    }
    closeCheckout()
  }

  // Close checkout
  const closeCheckout = () => {
    setCheckout({
      ...checkout,
      isOpen: false
    })
  }

  // On card change
  const onCardChange = (event) => {
    setCardError(event.error)
    setCardValid(event.complete)
  }

  // Check if checkout is valid
  const isCheckoutValid = () => {
    if(!checkout.name.trim()) {
      return false
    }
    if(!validateEmail(checkout.email)) {
      return false
    }
    if(checkout.useNewPaymentMethod && !cardValid) {
      return false
    }
    return true
  }

  // Render date
  const renderDate = (date, type = 'date') => {
    date *= 1000
    switch (type) {
      case 'date':
        return moment(date).format('DD/MM/YYYY')
      case 'datetime':
        return moment(date).format('DD/MM/YYYY HH:mm')
      case 'time':
        return moment(date).format('HH:mm')
      default:
        return ''
    }
  }

  // View upcoming invoice
  const viewUpcomingInvoice = async () => {
    setUpcomingInvoice({
      isLoading: true,
      isOpen: true,
      invoice: null
    })
    const upcomingInvoice = await fetch_upcoming_invoice({ estimate: true })
    setUpcomingInvoice({
      isLoading: false,
      isOpen: true,
      invoice: upcomingInvoice.invoice
    })
  }

  // Close upcoming invoice
  const closeUpcomingInvoice = () => {
    setUpcomingInvoice({
      isLoading: false,
      isOpen: false,
      invoice: null
    })
  }

  // Print price
  const printPrice = (amount) => {
    if(!amount) {
      return ''
    }
    amount = amount / 100
    // print two decimal places if amount is not a whole number
    if(amount % 1 !== 0) {
      return `${(amount).toFixed(2)}€`
    }
    return `${amount}€`
  }

  // Prompt cancel
  const promptCancel = async () => {
    setCancel({
      ...cancel,
      isOpen: true,
      isLoading: true
    })
    const invoiceData = await fetch_upcoming_invoice({ usage_only: true })
    setCancel({
      ...cancel,
      isOpen: true,
      outstandingInvoice: invoiceData.invoice,
      isLoading: false
    })
  }

  // On cancel cancel
  const onCancelCancel = () => {
    setCancel({
      ...cancel,
      isOpen: false
    })
  }

  // Render upcoming invoice
  const renderUpcomingInvoice = () => {
    return (
      <Dialog scroll="body" className="checkout-dialog" open={upcomingInvoice.isOpen}>
        <IconButton className="checkout-close-button" onClick={closeUpcomingInvoice}>
          <span className="material-symbols-outlined">close</span>
        </IconButton>
        <div>
          <div className="upcoming-invoice-header">
            <h1>{ t('membership.upcoming_invoice') }</h1>
            { upcomingInvoice.invoice && <h4 className="mt-10">{ t('membership.will_be_billed', { date: renderDate(upcomingInvoice.invoice?.created, 'datetime') }) }</h4> }
          </div>
          
          <div className="upcoming-invoice-content">
            { upcomingInvoice.isLoading ? (
              <div className="row centered mt-20">
                <div className="loader-v2"></div>
              </div>
            ) : upcomingInvoice.invoice ?         
              renderInvoiceDetail(upcomingInvoice.invoice)
            : (
              <div className="upcoming-invoice-content">
                <p>{ t('membership.loading_error') }</p>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    )
  }

  // Render invoice detail
  const renderInvoiceDetail = (invoice) => {
    return (
      <div>
      <h4 className="row v-centered"><span className="material-symbols-outlined">arrow_right</span> { `${t('membership.estimate_description')}` }</h4>
        <h4 className="row v-centered"><span className="material-symbols-outlined">arrow_right</span> { `${t('membership.estimated_total')}` }</h4>
        <h3>{ `${printPrice(invoice.amount_due)}` }</h3>
        <h4 className="row v-centered"><span className="material-symbols-outlined">arrow_right</span> { `${t('membership.price_breakdown')}` }</h4>
        { invoice.lines.data.map((line, i) => line.quantity > 0 ? <div key={`invoice_detail_line_${i}`}>
            <p className="description-item">{ `${line.quantity} x ${line.price.nickname}: ${printPrice(line.amount)}` }</p>
          </div> : null
        )}
        {/* { JSON.stringify(invoice, null, 2) }  */}
      </div>
    )
  }

  // Render subscription section
  const renderSubscriptionSection = () => {
    if(!subscription) {
      return null
    }
    return (
      <div className="subscription-section">
        <div className={`subscription-section-header ${subscription.status}`}>
          <h1>{ t('membership.status_title', { status: t(`membership.status_title_${subscription.status}`) }) }</h1>
        </div>
        <div className="mt-20">
          {/* <h6 className="mt-5">{ `${t('membership.created')}: ${renderDate(subscription.created)}` }</h6> */}
          <h6 className="mt-5">{ `${t('membership.created')}: `}<span className="date">{ renderDate(subscription.created) }</span></h6>
          { subscription.status === 'active' && <div className="mt-5 row v-centered">
            <h6>{ `${t('membership.current_period')}: `}<span className="date">{ `${renderDate(subscription.current_period_start, 'date')} - ${renderDate(subscription.current_period_end, 'date')}` }</span> </h6>
            <button className="btn btn--primary-light ml-20" onClick={viewUpcomingInvoice}>{t('membership.view_upcoming_invoice')}</button>
          </div> }
        </div>
        <div className="mt-20">
          <h3>{ t('membership.subscription_invoices') }</h3>
          <InvoicesList
            invoices={(invoices).filter(invc => invc.subscription === subscription?.subscription_id)}
            onPayNow={(invoice) => setCheckout({ ...checkout, invoice: invoice, isOpen: true, action: 'invoice' })}
            onInvoiceAction={handleInvoiceAction} />
        </div>
        { renderPaymentMethod() }
        <div className="mt-30">
          <button className="btn btn--danger" onClick={promptCancel}>{t('membership.cancel')}</button>
        </div>
      </div>
    )
  }

  // Render upgrade section
  const renderUpgradeSection = () => {
    const featuresList = [{
        title: t('membership.feature_title1'),
        description: t('membership.feature_description1')
      }, {
        title: t('membership.feature_title2'),
        description: t('membership.feature_description2')
      }, {
        title: t('membership.feature_title3'),
        description: t('membership.feature_description3')
      }
    ]
    return (
      <div>
        <h1>{ t('membership.premium_title') }</h1>
        <div className="mt-30">
          <span>{ t('membership.premium_description') }</span>
        </div>
        <div className="mt-30 features-list">
          { featuresList.map((feat, i) => (
            <div className="row v-centered" key={`feature_${i}`}>
              <Check color="primary" />
              <span className="ml-10">{ feat.title }</span>
            </div>)
          )}
        </div>
        <div className="mt-50">
          <button className="btn btn--large" onClick={() => setCheckout({
            ...checkout,
            isOpen: true,
            invoice: null,
            action: 'subscription'
          })}>{t('membership.upgrade_now').toUpperCase()}</button>
        </div>
        <div className="mt-20 row v-centered">
          <span className="label-small">
            { t('membership.pricing_description', { amount_seat: 0.8, amount_flat: 25 }) }
          </span>
          <CustomTooltip position="bottom" content={t('membership.pricing_example', { price: 265 })}>
            <Help color="disabled" fontSize="small" className="ml-10" />
          </CustomTooltip>
        </div>     
      </div>
    )
  }

  // Render checkout
  const renderCheckout = () => {
    return (
      <Dialog scroll="body" className="checkout-dialog" open={checkout.isOpen}>
        <IconButton className="checkout-close-button" onClick={onPaymentCancel}>
          <span className="material-symbols-outlined">close</span>
        </IconButton>
        <div className="dialog-body">
          <div className="checkout-header">
            {checkout.action === 'update_payment_method' ? <h2>{ t('membership.update_payment_method') }</h2> : <h2>{ t('membership.checkout') }</h2> }
            {checkout.action !== 'update_payment_method' && <h1>{ t('membership.premium_title') }</h1> }
          </div>
          <div className="checkout-content">
            {checkout.action !== 'update_payment_method' && <p className="row v-centered"><span className="material-symbols-outlined">arrow_right</span> { `${t('membership.due_now')}: ${printPrice(checkout.invoice?.amount_due || 2500)}` }</p> }
            { (!checkout.invoice || checkout.invoice.billing_reason === 'subscription_create') && checkout.action !== 'update_payment_method' && <p className="mt-5 row v-centered"><span className="material-symbols-outlined">arrow_right</span>{ t('membership.checkout_subtitle') }</p> }
            <div className="mt-20">
              <Input 
                label={t('membership.full_name')} 
                disabled={loadingCheckout}
                value={checkout.name}
                invalid={!checkout.name}
                onChange={(e) => { setCheckout({...checkout, name: e.target.value })}}/>
              <Input 
                label={t('auth.email')} 
                disabled={loadingCheckout}
                value={checkout.email}
                invalid={!checkout.email}
                onChange={(e) => { setCheckout({...checkout, email: e.target.value })}}/>
            </div>
            <div className="form-input form-input--white-background form-input--form-el">
              <div className="form-input__inner">
                <div className="form-input__label-wrapper">
                  <label className={"form-input__label"}>{ t('membership.payment_method') }</label>
                </div>
                { checkout.useNewPaymentMethod || checkout.action === 'update_payment_method' ? (
                  <div>
                    <div className="card-input">
                      <CardElement
                        disabled={loadingCheckout}
                        onChange={onCardChange}
                        options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              color: '#000',
                              fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif',
                              fontSmoothing: 'antialiased'
                            },
                          }
                        }}/>
                    </div>
                    { Boolean(checkout.paymentMethod) && checkout.action !== 'update_payment_method' && (
                      <button className="btn btn--primary-light mt-10" onClick={() => { setCheckout({...checkout, useNewPaymentMethod: false }) }}>{t('membership.use_current')}</button>
                    ) }
                  </div>
                ) : (
                  <div>
                    <span>{ `${capitalizeWord(checkout.paymentMethod.brand)} **${checkout.paymentMethod.last4}` }</span>
                    <button className="btn btn--primary-light mt-10 ml-10" disabled={loadingCheckout} onClick={() => { setCheckout({...checkout, useNewPaymentMethod: true }) }}>{t('membership.use_new')}</button>
                  </div>
                )}
                { cardError && <div className="mt-5"><span className="card-error-label">{ cardError.message }</span></div> }
              </div>
            </div>
            <Collapse in={isCheckoutValid()}>
              <div className="mt-30">              
                <button className="btn btn--large btn--full" onClick={onCheckoutSubmit} disabled={!isCheckoutValid()}>
                  {loadingCheckout ? <div className="loader-v2"></div> : 
                    checkout.action === 'update_payment_method' ? t('general.submit') : t('membership.checkout_pay', { amount: printPrice(checkout.invoice?.amount_due || 2500) }).toUpperCase()
                  }
                </button>
              </div>
            </Collapse>
          </div>
        </div>
      </Dialog>
    )
  }

  // Render cancel prompt
  const renderCancelPrompt = () => {
    return (
      <Dialog scroll="body" className="checkout-dialog" open={cancel.isOpen}>
        <IconButton className="checkout-close-button" onClick={onCancelCancel}>
          <span className="material-symbols-outlined"></span>
        </IconButton>
        <div>
          <div className="cancel-header">
            {/* <h2>{ t('membership.cancel') }</h2> */}
            <h1>{ t('membership.cancel_confirmation') }</h1>
          </div>
          <div className="checkout-content">
            { cancel.isLoading ? (
              <div className="mt-20 row centered">
                <div className="loader-v2 loader-v2--gray"></div>
              </div>
            ) : cancel.outstandingInvoice ?         
              <>
                <p className="mt-5 row v-centered"><span className="material-symbols-outlined">arrow_right</span>{ t('membership.cancel_description') }</p>
                { cancel.outstandingInvoice?.amount ? <>
                  <p className="mt-5 row v-centered"><span className="material-symbols-outlined">arrow_right</span> { `${t('membership.current_usage')}: ${printPrice(cancel.outstandingInvoice?.amount)}` }</p>
                  <p className="mt-5 ml-30 hint">{ `${cancel.outstandingInvoice?.description}` }</p>
                </> : null }
                <div className="mt-30"> 
                  <button className="btn btn--large btn--full" onClick={onCancelSubmit}>
                    {loadingCancel ? <div className="loader-v2"></div> : (
                      t('general.confirm').toUpperCase()
                    )}
                  </button>
                </div>
              </>
            : null }
          </div>
        </div>
      </Dialog>
    )
  }

  // Print card expiration date
  const printCardExpDate = (card) => {
    if (!card || !card.exp_month || !card.exp_year) {
      return ''
    }
    let monthString = `${card.exp_month}`
    if (monthString.length === 1) {
      monthString = `0${monthString}`
    }
    let yearString = `${card.exp_year}`
    if (yearString.length > 2) {
      yearString = yearString.substring(2)
    }

    return `${monthString}/${yearString}`
  }

  // Card logo source
  const cardLogoSource = (brand) => {
    switch (brand) {
      case 'visa':
        return CardLogoVisa
      case 'mastercard':
        return CardLogoMastercard
      default:
        return ''
    }
  }

  const handleChangePaymentMethod = async () => {
    setCheckout({
      ...checkout,
      isOpen: true,
      action: 'update_payment_method',
    })
  }

  // Render payment method
  const renderPaymentMethod = () => {
    if( !user?.payment_method) {
      return null
    }

    return (
      <div>
        <h2 className="payment-method-header mt-20">{ t('membership.payment_method') }</h2>
        <div className="card-block">
          <div className="card-row">
            <img className="card-logo" alt="issuer_logo" src={cardLogoSource(user.payment_method.brand)} />
            <div className="card-info">
              <p className="card-title">{ `${capitalizeWord(user.payment_method.brand)} **** ${user.payment_method.last4}` }</p>
              <p className="card-expiry">{ `Expires ${printCardExpDate(user.payment_method)}` }</p>
            </div>
          </div>
          <div className="card-actions">
            <button className="btn btn--primary-light btn--small" onClick={handleChangePaymentMethod}>{t('membership.change_payment_method')}</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <SettingsLayout title={t('membership.title')}>
      <div className="settings-pages-wrapper">
        { subscription ? renderSubscriptionSection() : renderUpgradeSection() }
        { renderCheckout() }
        { renderCancelPrompt() }
        { renderUpcomingInvoice() }
      </div>
    </SettingsLayout>
  )
}

export default Membership
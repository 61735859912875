import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext, TeamContext } from '../context';
import { hasSufficientMembership } from '../utils';

const PrivateRoute = ({ authenticated, children, location, limited, ...rest}) => {

  const { membership } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)

  const isAvailable = ({type, requirement}) => {
    return hasSufficientMembership(type === 'team' ? selectedTeam?.membership : membership, requirement)
  }

  return (
    <Route
      render={() => authenticated === true
        ? (!limited || isAvailable(limited)) 
        ? children 
        : <Redirect to={{pathname: '/settings/membership', state: { origin: "team_add" }}} />
        : <Redirect to={{pathname: '/signin', state: { from: location }}} /> }
      {...rest}
    />
  )
}

export default PrivateRoute
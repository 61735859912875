import firebase from './firebase'
import { baseUrl, get_request } from './firestore'

const fetch_help_articles = async () => {
  return await get_request(`${baseUrl}/help_articles`)
}

const fetch_help_categories = async () => {
  return await get_request(`${baseUrl}/help_categories`)
}

const increment_article_read_count = async (id) => {
  const db = firebase.firestore()
  const increment = firebase.firestore.FieldValue.increment(1)
  const ref = db.collection('help_articles').doc(id)
  ref.update({ readCount: increment })
}

export {
  fetch_help_articles,
  fetch_help_categories,
  increment_article_read_count,
}
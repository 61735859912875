import React, { createContext, useReducer, useState } from 'react';

import taskReducer from './taskReducer';
import { GET_TASKS, SET_TASKS, RESET_STATE, SET_TASKS_OBJ } from '../types';
import { fetch_tasks, create_task, update_task, delete_task } from '../../services/firestore';

export const TaskContext = createContext();

let defaultSort = { value: 'name', order: 'asc' }

const TasksState = ({ children }) => {
  const initialState = {
    tasksObj: {},
    tasks: [],
    tasksFetched: false,
    completedTasks: [],
    notCompletedTasks: [],
  };

  const [lastCreatedList, setLastCreatedList] = useState(null)
  const [selectedAssignee, setSelectedAssignee] = useState(null)
  const [showTaskPopup, setShowTaskPopup] = useState(false)
  const [tasksSort, setTasksSort] = useState(defaultSort)
  const [state, dispatch] = useReducer(taskReducer, initialState);

  // Fetch tasks
  const fetchTasks = async (teamId) => {
    try {
      const res = await fetch_tasks(teamId, true);
      if(res.error) {
        dispatch({
          type: GET_TASKS,
          payload: {
            tasks: [],
            obj: {},
            completed: [],
            notCompleted: []
          }
        });
        throw new Error(res.error?.message || 'tasks cannot be fetched')
      }
      const arr = [];
      for(let id in res) {
        const task = {...res[id]};
        task.id = id;
        arr.push(task);
      }
      const completed = [...arr].filter(t => t.completed).sort((a,b) => {
        if(a.meta.created > b.meta.created) return -1;
        else if(a.meta.created < b.meta.created) return 1;
        return 0; 
      });
      const notCompleted = [...arr].filter(t => !t.completed).sort((a,b) => {
        if(a.meta.created > b.meta.created) return -1;
        else if(a.meta.created < b.meta.created) return 1;
        return 0; 
      });
      dispatch({
        type: GET_TASKS,
        payload: {
          tasks: arr,
          obj: res,
          completed,
          notCompleted
        }
      });
    }catch(err) {
      console.log('TASKS err', err);
    }
  }

  // Set tasks
  const setTasks = (tasks) => {
    const completed = [...tasks].filter(t => t.completed).sort((a,b) => {
      if(a.meta.created > b.meta.created) return -1;
      else if(a.meta.created < b.meta.created) return 1;
      return 0; 
    });
    const notCompleted = [...tasks].filter(t => !t.completed).sort((a,b) => {
      if(a.meta.created > b.meta.created) return -1;
      else if(a.meta.created < b.meta.created) return 1;
      return 0; 
    });
    dispatch({
      type: SET_TASKS,
      payload: {
        tasks,
        completed,
        notCompleted
      }
    });
  }

  // Set tags
  const setTasksObj = (value) => {
    let arr = []
    for(let id in value) {
      arr.push({...value[id], id})
    }
    setTasks(arr)
    dispatch({
      type: SET_TASKS_OBJ,
      payload: value
    })
  }

  // Create task 
  const createTask = async (data, teamId, onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await create_task(data);
      await fetchTasks(teamId);
      onSuccess(res.taskId);
    }catch(err) {
      console.log('CREATE TASK err', err);
      onError(err);
    }
  }

  // Update task 
  const updateTask = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const tasks = [...state.tasks];
      await update_task(data, id);
      const taskToUpdate = tasks.find(t => t.id === id);
      const updatedTask = {
        ...taskToUpdate,
        ...data
      };
      const updatedTasks = [...tasks].map(t => t.id === id ? updatedTask : t);
      setTasks(updatedTasks);
      onSuccess();
    }catch(err) {
      console.log('UPDATE TASK err', err);
      onError(err);
    }
  }

  // Delete task
  const deleteTask = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const tasks = [...state.tasks];
      await delete_task(id);
      const updatedTasks = [...tasks].filter(t => t.id !== id);
      setTasks(updatedTasks);
      onSuccess();
    } catch (err) {
      console.log('DELETE TASK err', err);
      onError(err);
    }
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, tasksFetched: true } : initialState,
      payload: initialState,
    });
    setLastCreatedList(null)
  }

  // Get task by id
  const getTaskById = (id) => {
    const task = [...state.tasks].find(t => t.id === id);
    return task;
  }

  return(
    <TaskContext.Provider value={{
      tasksObj: state.tasksObj,
      tasks: state.tasks,
      tasksFetched: state.tasksFetched,
      completedTasks: state.completedTasks,
      notCompletedTasks: state.notCompletedTasks,
      lastCreatedList,
      fetchTasks,
      createTask,
      updateTask,
      deleteTask,
      resetTaskState: resetState,
      getTaskById,
      setTasksObj,
      setLastCreatedList,
      selectedAssignee,
      setSelectedAssignee,
      showTaskPopup,
      setShowTaskPopup,
      tasksSort,
      setTasksSort,
    }}>
      {children}
    </TaskContext.Provider>
  );
};

export default TasksState;
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment'
import PropTypes from 'prop-types';
import HistoryIcon from '@material-ui/icons/History';

import Modal from '../../UI/Modal';
import HistoryBox from '../../UI/HistoryBox';
import LineLoader from '../../UI/LineLoader';
import Message from '../../UI/Message';
import { TagContext, GlobalContext } from '../../../context';
import { availableOn } from '../../../utils';

const dayFormat = 'DD/MM/YYYY'

const HistoryModal = ({ onClose, loading, history, onRestore, uploaded }) => {
  const { t } = useContext(GlobalContext)
  const [days, setDays] = useState([])
  const { getTagById } = useContext(TagContext)

  useEffect(() => {
    // process history into an array of days
    let daysMap = {}
    let daysArray = []
    for(let key in history) {
      let version = history[key]
      let dayValue = moment(version.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          versions: []
        }
      }
      daysMap[dayValue].versions.push(version)
    }
    for(let d in daysMap) {
      daysMap[d].versions.sort((a, b) => {
        if(a.created > b.created) return 1
        else if(a.created < b.created) return -1
        return 0
      })
      daysArray.push(daysMap[d])
    }
    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return 1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return -1
      return 0
    })
    setDays([...daysArray])

  }, [history])

  const isToday = (dayValue) => {
    return moment().format(dayFormat) === dayValue
  }

  const dayTitle = (day) => {
    if(isToday(day)) {
      return t('general.today')
    }
    return day
  }
  
  const renderTime = (time) => {
    return moment(time).format('HH[h]mm')
  }

  const versionVariablesArray = (variables) => {
    let varArr = []
    for(let key in variables) {
      varArr.push({ label: key, input: variables[key] })
    }
    return varArr
  }

  const getTagNameById = (id) => {
    const found = getTagById(id)
    return found ? found.name : id
  }

  const versionDataArray = (data, exclude = []) => {
    let dataArr = []
    for(let key in data) {
      if(!exclude.includes(key)) {
        let input
        if(Array.isArray(data[key])) {
          input = data[key].join(', ')
          if(key === 'keywords') {
            const keywords = []
            data[key].forEach(kw => keywords.push(getTagNameById(kw)))
            input = keywords.join(', ')
          }
          else if(key === 'categories') {
            const categories = []
            data[key].forEach(cat => categories.push(t(`categories.${cat}`)))
            input = categories.join(', ')
          }
        }else {
          if(data[key] === Object(data[key])) {
            if(key.includes('_date')) {
              input = data[key].timestamp
            }else {
              input = data[key]
            }
          }else {
            if(data[key] === 'yes' || data[key] === 'no') {
              input = t(`general.${data[key]}`)
            }else {
              input = data[key]
            }
          }
        }
        if(key.toLowerCase().includes('timestamp') || key.toLowerCase().includes('_date')) {
          dataArr.push({ label: key, input, timestamp: true })
        }else {
          dataArr.push({ label: key, input })
        }
      }
    }
    return dataArr
  }

  const renderDay = (day, dayIndex) => {
    // console.log(day)
    return (
      <div key={`day_${dayIndex}`} className="history__section">
        <h3>{ dayTitle(day.dayValue) }</h3>
        { day.versions.map((version, versionIndex) => {
          let numberOfEntries
          const alertData = versionDataArray(version.alert || {}, ['documentId', 'sent', 'date', 'id', 'team', 'selectedVal', 'owner'])
          const infoData = versionDataArray(version.info || {})
          const datesData = versionDataArray(version.dates || {})
          const contactsData = versionDataArray(version.contacts || {})
          const tasksData = versionDataArray(version.tasks || [])
          if(availableOn(['development'])) {
            numberOfEntries = Object.keys(version.values).length + alertData.length + infoData.length + datesData.length + contactsData.length + tasksData.length
          }else {
            numberOfEntries = Object.keys(version.values).length
          }
          
          return (
            <HistoryBox 
              key={`version_${dayIndex}_${versionIndex}`}
              time={renderTime(version.created)}
              progress={version.progress}
              numberOfEntries={numberOfEntries}
              onRestoreClick={() => onRestore(version) }
              inputsData={versionVariablesArray(version.values)}
              alertData={alertData}
              infoData={infoData}
              datesData={datesData}
              contactsData={contactsData}
              tasksData={tasksData}
              uploaded={uploaded}
            />
          )
        })}
      </div>
    )
  }

  return(
    <Modal onClose={onClose} bg2>
      <div className="history">
        <h2><HistoryIcon /> {t('dashboard.document_history')}</h2>

        { loading ? (
          <LineLoader />
        ) : (
          <div>
            { days.length > 0 ? days.map(renderDay) : <Message type="primary" msg={t('dashboard.no_history')} />}
          </div> ) }
      </div>
    </Modal>
  );
}

HistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default HistoryModal;
import { useState, useContext, useEffect, useCallback } from 'react'

import { Modal, Input, MemberSelect, DocumentSelect, DateInput } from '../../new_ui'
import { GlobalContext, DocumentsContext, TeamContext } from '../../../context'
import { useTaskActions } from '../../../hooks'

const CreateTaskModal = ({ onClose, refreshTasks }) => {
  const { t } = useContext(GlobalContext)
  const { getDocumentById } = useContext(DocumentsContext)
  const { getTeamMemberById } = useContext(TeamContext)
  const { createNewTask } = useTaskActions()
  const [taskName, setTaskName] = useState('')
  const [selectedDocument, setSelectedDocument] = useState('')
  const [taskAssignedTo, setTaskAssignedTo] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [taskNameError, setTaskNameError] = useState(false)

  // On name change
  const handleNameChange = (e) => {
    setTaskName(e.target.value)
  }

  // On member change
  const handleMemberChange = (value) => {
    setTaskAssignedTo(value)
  }

  // On document change
  const handleDocumentChange = (value) => {
    setSelectedDocument(value)
  }

  // On date change
  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  // On submit
  const handleSubmit = useCallback(async () => {
    await createNewTask({ taskName, selectedDocument: getDocumentById(selectedDocument), taskAssignedTo: getTeamMemberById(taskAssignedTo), selectedDate, onClose, setTaskNameError, refreshTasks })
  }, [createNewTask, onClose, selectedDocument, selectedDate, taskAssignedTo, taskName, refreshTasks, getDocumentById, getTeamMemberById])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return (
    <Modal 
      title={t('general.add_task')}
      className="centered-head"
      onClose={onClose}
      onSave={handleSubmit}
    >
      <div className="create-task">
        <div className="create-task__field">
          <Input value={taskName} onChange={handleNameChange} label={t('dashboard.task_name')} invalid={taskNameError} />
        </div>
        <div className="create-task__field-group">
          <MemberSelect label={t('approvals.assigned_to')} onChange={handleMemberChange} />
          <DateInput label={t('general.due_date')} onChange={handleDateChange} />
        </div>
        <div className="create-task__field">
          <DocumentSelect label={t('general.related_document')} onChange={handleDocumentChange} />
        </div>
      </div>
    </Modal>
  )
}

export default CreateTaskModal
import { useContext, useCallback } from 'react'

import { DocumentsContext, GlobalContext, LoaderContext, NotificationContext, UserContext, TeamContext, SharedTemplatePagesContext } from '../context'
import { shared_template_exists, create_shared_template } from '../services/shared_templates'

const useSingleTemplateActions = () => {
  const { updateTemplate, getSingleTemplate, deleteTemplate } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)
  const { singleSharedTemplatesFetched, createSingleSharedTemplate } = useContext(SharedTemplatePagesContext)
  const { t } = useContext(GlobalContext)

  // Move template to folder
  const moveTemplateToFolder = useCallback(async ({ template, folder, setMoved }) => {
    let tmplt = {...template}
    if(!template.folderId) {
      tmplt = {...template, folderId: []}
    }

    if(folder) {
      if(!tmplt.folderId.includes(folder.id)) {
        const foldersArr = []
        foldersArr.push(folder.id)
        setShowGlobalResponseLoader(true)
        const res = await updateTemplate({ folderId: foldersArr }, tmplt.id)
        setShowGlobalResponseLoader(false)
        if(res.success) {
          setNotification({ msg: t('folder.template_moved_to_folder', { template: tmplt.name, folder: folder.name }), type: 'default' })
        }else {
          setNotification({ msg: res.message || t('notification.something_went_wrong'), type: 'danger' })
        }
      } else {
        setNotification({ msg: t('folder.template_already_in', { template: tmplt.name, folder: folder.name }), type: 'warning' })
      }
      setMoved(true)
    }else if(tmplt.folderId.length > 0){
      setShowGlobalResponseLoader(true)
      const res = await updateTemplate({ folderId: [] }, tmplt.id)
      setShowGlobalResponseLoader(false)
      if(res.success) {
        setNotification({ msg: t('folder.template_moved_to_root', { template: tmplt.name }), type: 'default' })
      }else {
        setNotification({ msg: res.message || t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }, [setNotification, t, updateTemplate, setShowGlobalResponseLoader])

  // Download template
  const downloadTemplate = useCallback(async ({ tmpl }) => {
    const a = document.createElement("a")
    a.setAttribute('download', tmpl.name)
    a.setAttribute('href', `${tmpl.templateUrl}`)
    document.body.appendChild(a)
    a.click()
  }, [])

  // Share template 
  const shareTemplate = async ({ templateToShare, setTemplateSharedSuccessfully, setShowPageOrTemplateShareModal, setAlreadyShared, setSharedTemplateId, setSharedTemplate, setTemplateToShare }) => {
    if(!templateToShare) return 

    setShowGlobalResponseLoader(true)

    // Check if this templates was already shared - if yes, do not create new shared template
    const result = await shared_template_exists(templateToShare.id, user.id, selectedTeam.id)
    if(result.num !== 0) {
      setTemplateSharedSuccessfully(true)
      setShowPageOrTemplateShareModal(false)
      setAlreadyShared(true)
      setSharedTemplateId(result.id)
      setSharedTemplate(result.template)
      setShowGlobalResponseLoader(false)
      return
    }
    let tmplt = {...templateToShare}
    if(!templateToShare.sections) {
      const res = await getSingleTemplate(templateToShare.id, true)
      if(res) {
        tmplt = res
      }
    }

    let data = {
      name: tmplt.name,
      template_id: tmplt.id,
      sections: tmplt.sections,
      templateUrl: tmplt.templateUrl,
      meta: {
        created: Date.now(),
        updated: Date.now()
      },
      team: selectedTeam?.id,
      owner: user.id,
      disabled: false,
      single: true,
      renewed: false,
    }
    // console.log(data)
    let newTemplateId = null
    if(singleSharedTemplatesFetched) {
      await createSingleSharedTemplate(data, (id) => {
        newTemplateId = id
      }, (err) => {
        console.log(err)
        setShowGlobalResponseLoader(false)
      })
    }else {
      const id = await create_shared_template(data)
      newTemplateId = id
    }
    if(newTemplateId) {
      setSharedTemplateId(newTemplateId)
      setSharedTemplate({ ...data, newTemplateId })
      setTemplateSharedSuccessfully(true)
      setShowPageOrTemplateShareModal(false)
      setTemplateToShare(null)
      setShowGlobalResponseLoader(false)
    }
  }

  // Update template
  const updateSingleTemplate = async ({ data, id, setShowEditTemplateModal, setCurrentTemplate }) => {
    setShowGlobalResponseLoader(true)
    await updateTemplate(data, id)
    setShowEditTemplateModal(false)
    setCurrentTemplate(null)
    setShowGlobalResponseLoader(false)
  }

  // Delete template
  const deleteSingleTemplate = async ({ currentTemplate, setShowDeleteTemplateAlert, setCurrentTemplate }) => {
    setShowGlobalResponseLoader(true)
    await deleteTemplate(currentTemplate.id, () => {
      setShowDeleteTemplateAlert(false)
      setCurrentTemplate(null)
    })
    setShowGlobalResponseLoader(false)
  }

  return {
    moveTemplateToFolder,
    downloadTemplate,
    shareTemplate,
    updateSingleTemplate,
    deleteSingleTemplate,
  }
}

export default useSingleTemplateActions
import React, { useContext } from 'react'
import { useHistory  } from 'react-router'
import moment from 'moment'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext, GlobalContext } from '../../../context'

const WhatsNew = ({ v2 = false }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { whatsNewArticles, articlesFetched } = useContext(HelpContext)
  const history = useHistory()

  // Category click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/what-is-new/${id}`)
  }

  return (
    <SupportPageLayout title={t('support.whats_new')}>
      <div className="help-category">
        <div className="help-category__body">
          {!articlesFetched 
              ? <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div>
              : whatsNewArticles.length 
                ? <ul>
                    {whatsNewArticles.map((article, idx) => (
                      <li key={idx}>
                        <a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title[selectedLang] || 'Missing translation'}</a>
                        {article.date && <span>{moment(article.date).format('DD MMM YYYY @ HH:mm')}</span>}
                      </li>
                    ))}
                  </ul>
                : <div className="support-page__no-data"><p>{`Pas d'articles`}</p></div>
            }
        </div>
      </div>
    </SupportPageLayout>
  )
}

export default WhatsNew
import { useContext, useState, useEffect } from 'react'

import ActionsDropdown from '../UI/ActionsDropdown'
import { Input } from '.'
import { GlobalContext, DocumentsContext } from '../../context'

const DocumentSelect = ({ label, chooseText = '', value, onChange, invalid = false }) => {
  const { t } = useContext(GlobalContext)
  const { documentsArr } = useContext(DocumentsContext)
  const [filteredDocumentsArr, setFilteredDocumentsArr] = useState(documentsArr ? documentsArr : [])
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [search, setSearch] = useState('')

  // Set selected document if value is set
  useEffect(() => {
    if(value) {
      const selected = documentsArr.find(d => d.id === value)
      if(selected) {
        setSelectedDoc(selected)
      }
    }
  }, [value, documentsArr])

  // On document select
  const handleSelectDocument = (e, doc) => {
    e.preventDefault()
    setSelectedDoc(doc)
    onChange(doc.id)
    window.document.body.click()
  }

  // On search change
  const handleSearchChange = (e) => {
    const val = e.target.value
    setSearch(val)
    if(val.trim() !== '') {
      let filtered = []
      for(let i = 0; i < documentsArr.length; i++) {
        const doc = documentsArr[i]
        if(doc.name.toLowerCase().includes(val.trim())) {
          filtered.push(doc)
        }
      }
      setFilteredDocumentsArr(filtered)
    }else {
      setFilteredDocumentsArr(documentsArr)
    }
  }

  return (
    <div className={`document-select ${invalid ? 'error' : ''}`}>
      {label && <div className="document-select__label">{label}</div>}
      <ActionsDropdown
        trigger={
          <div className="document-select__selected">
            {!selectedDoc ? (
              <p className="u-truncate-text">{chooseText || t('general.select_document_related_to_task')}</p>
            ) : (
              <p className="u-truncate-text-wrapper">
                <span className="material-symbols-outlined size-24">description</span>
                <span className="u-truncate-text">{selectedDoc.name}</span>
              </p>
            )}
          </div>
        }
        hideHeader
        width={300}
        dropdownClass="actions-dropdown-v2 document-select-dropdown"
      >
        <div className="search-wrapper">
          <Input value={search} onChange={handleSearchChange} />
        </div>
        <ul className="default-list u-custom-scrollbar">
          {filteredDocumentsArr.map((doc, idx) => {
            return (
              <li key={idx}>
                <a href="/#" onClick={(e) => handleSelectDocument(e, doc)}>
                  <div className="doc-name u-truncate-text">{doc.name}</div>
                  {selectedDoc && selectedDoc.id === doc.id && <span className="material-symbols-outlined size-24">done</span>}
                </a>
              </li>
            )
          })}
        </ul>
      </ActionsDropdown>
    </div>
  )
}

export default DocumentSelect
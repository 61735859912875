import { useContext } from 'react'

import { NotificationContext, TeamContext, VariablesContext, GlobalContext, UserContext, LoaderContext } from '../context'
import { update_user, update_team } from '../services/firestore'

const useSingleVariableActions = () => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam, setSelectedTeam } = useContext(TeamContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { createVariable, variables, updateVariable, deleteVariable } = useContext(VariablesContext)
  const { user, setUser } = useContext(UserContext)

  // Create new variable
  const createNewVariable = async ({ variableKey, tooltip, selectedDataType, selectedCategory, displayName, question, selectOptions, onClose }) => {
    let success = false
    // validate
    if(variableKey.trim() === '' || tooltip.trim() === '' || selectedDataType === '' || selectedCategory === '') {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    // create data object and validate select options
    let var_data = {
      variable: variableKey.trim(),
      tooltip: tooltip.trim(),
      type: selectedDataType,
      category: selectedCategory,
      display_name: displayName.trim(),
      question: question ? question.trim() : '',
    }

    //   var_data.meta = {
    //     created: Date.now(),
    //     updated: Date.now()
    //   }

    if(selectedDataType === 'select' || selectedDataType === 'multiselect') {
      var_data.options = selectOptions
      let error = false 
      let values = []

      if(selectOptions.length === 0) {
        return setNotification({ msg: t('variables.add_one_option'), type: 'danger' })
      }

      for(let i = 0; i < selectOptions.length; i++) {
        values.push(selectOptions[i].value.trim())
        if(selectOptions[i].label.trim() === '' || selectOptions[i].value.trim() === '') {
          setNotification({ msg: t('variables.label_value_empty'), type: 'danger' })
          error = true
          break
        }
      }
      if(error) return

      if(values.length > 0) {
        const duplicates = values.some((item, index) => index !== values.indexOf(item))
        if(duplicates) {
          return setNotification({ msg: t('variables.unique_option_values'), type: 'danger' })
        }
      }
    }

    try {
      await createVariable(selectedTeam, var_data)
      success = true
      onClose()
    } catch (err) {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      console.log(err)
    }
    setShowGlobalResponseLoader(false)
    return success
  }

  // Update variable
  const updateSingleVariable = async ({ variableKey, tooltip, selectedDataType, selectedCategory, displayName, question, selectOptions, onClose, data }) => {
    let success = false
    // validate
    if(variableKey.trim() === '' || tooltip.trim() === '' || selectedDataType === '' || selectedCategory === '') {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    // create data object and validate select options
    let var_data = {
      variable: variableKey.trim(),
      tooltip: tooltip.trim(),
      type: selectedDataType,
      category: selectedCategory,
      display_name: displayName.trim(),
      question: question ? question.trim() : '',
    }

 
    //   var_data.meta = {
    //     created: data.meta?.created,
    //     updated: Date.now()
    //   }

    if(selectedDataType === 'select' || selectedDataType === 'multiselect') {
      var_data.options = selectOptions
      let error = false 
      let values = []

      if(selectOptions.length === 0) {
        return setNotification({ msg: t('variables.add_one_option'), type: 'danger' })
      }

      for(let i = 0; i < selectOptions.length; i++) {
        values.push(selectOptions[i].value.trim())
        if(selectOptions[i].label.trim() === '' || selectOptions[i].value.trim() === '') {
          setNotification({ msg: t('variables.label_value_empty'), type: 'danger' })
          error = true
          break
        }
      }
      if(error) return

      if(values.length > 0) {
        const duplicates = values.some((item, index) => index !== values.indexOf(item))
        if(duplicates) {
          return setNotification({ msg: t('variables.unique_option_values'), type: 'danger' })
        }
      }
    }

    const findVar = variables.find(v => v.id === data.id)
    if(findVar && findVar.variable !== var_data.variable) {
      let isVariableLinkedWithProfile = false 
      if(user && user.profile_linked_vars && Object.keys(user.profile_linked_vars).length > 0) {
        let profileLinkedVars = {...user.profile_linked_vars}
        let values = Object.values(profileLinkedVars)
        let keys = Object.keys(profileLinkedVars)
        for(let i = 0; i < values.length; i++) {
          if(data.variable === values[i]) {
            profileLinkedVars[keys[i]] = var_data.variable
            isVariableLinkedWithProfile = true
          }
        }
        if(isVariableLinkedWithProfile) { // update user profile_linked_vars prop
          setUser({ ...user, profile_linked_vars: profileLinkedVars })
          update_user({ profile_linked_vars: profileLinkedVars }, user.id)
        }
      }

      let isVariableLinkedWithContact = false 
      if(selectedTeam && selectedTeam.contacts_linked_variables && Object.keys(selectedTeam.contacts_linked_variables).length > 0) {
        let values = Object.values(selectedTeam.contacts_linked_variables)
        let keys = Object.keys(selectedTeam.contacts_linked_variables)
        let updates = JSON.parse(JSON.stringify(selectedTeam.contacts_linked_variables))
        values.forEach((vals, idx) => {
          if(vals) {
            let innnerValues = Object.values(vals)
            let innnerKeys = Object.keys(vals)
            let group = {...updates[keys[idx]]}
            innnerValues.forEach((val, i) => {
              if(data.variable === val) {
                group[innnerKeys[i]] = var_data.variable
                updates[keys[idx]] = group
                isVariableLinkedWithContact = true
              }
            })
          }
        })
        if(isVariableLinkedWithContact) { // update selectedTeam contacts_linked_variables prop
          setSelectedTeam({ ...selectedTeam, contacts_linked_variables: updates })
          update_team({ contacts_linked_variables: updates }, selectedTeam.id)
        }
      }
    }

    try {
      await updateVariable(selectedTeam, var_data, data.id)
      success = true
      onClose()
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setShowGlobalResponseLoader(false)
    return success
  }

  // Delete variable
  const deleteSingleVariable = async ({ data, onClose }) => {
    let isVariableLinkedWithProfile = false 
    if(user && user.profile_linked_vars && Object.keys(user.profile_linked_vars).length > 0) {
      let profileLinkedVars = {...user.profile_linked_vars}
      let values = Object.values(profileLinkedVars)
      let keys = Object.keys(profileLinkedVars)
      for(let i = 0; i < values.length; i++) {
        if(data.variable === values[i]) {
          delete profileLinkedVars[keys[i]]
          isVariableLinkedWithProfile = true
        }
      }
      if(isVariableLinkedWithProfile) { // update user profile_linked_vars prop
        setUser({ ...user, profile_linked_vars: profileLinkedVars })
        update_user({ profile_linked_vars: profileLinkedVars }, user.id)
      }
    }

    let isVariableLinkedWithContact = false 
    if(selectedTeam && selectedTeam.contacts_linked_variables && Object.keys(selectedTeam.contacts_linked_variables).length > 0) {
      let values = Object.values(selectedTeam.contacts_linked_variables)
      let keys = Object.keys(selectedTeam.contacts_linked_variables)
      let updates = JSON.parse(JSON.stringify(selectedTeam.contacts_linked_variables))
      values.forEach((vals, idx) => {
        if(vals) {
          let innnerValues = Object.values(vals)
          let innnerKeys = Object.keys(vals)
          let group = {...updates[keys[idx]]}
          innnerValues.forEach((val, i) => {
            if(data.variable === val) {
              delete group[innnerKeys[i]]
              updates[keys[idx]] = group
              isVariableLinkedWithContact = true
            }
          })
        }
      })
      if(isVariableLinkedWithContact) { // update selectedTeam contacts_linked_variables prop
        setSelectedTeam({ ...selectedTeam, contacts_linked_variables: updates })
        update_team({ contacts_linked_variables: updates }, selectedTeam.id)
      }
    }

    setShowGlobalResponseLoader(true)
    try {
      await deleteVariable(selectedTeam, data.id)
      onClose()
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setShowGlobalResponseLoader(false)
  }

  return {
    createNewVariable,
    updateSingleVariable,
    deleteSingleVariable,
  }
}

export default useSingleVariableActions
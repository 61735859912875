import { useContext, useState, useEffect, forwardRef } from 'react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import moment from 'moment'

import { WidgetDropdown } from '../'
import { GlobalContext, UserContext } from '../../../context'
import { update_user } from '../../../services/firestore'

import formatBold from '../../../assets/icons/format_bold.png'
import formatItalic from '../../../assets/icons/format_italic.png'
import formatUnderlined from '../../../assets/icons/format_underlined.png'
import strikethrough from '../../../assets/icons/strikethrough_s.png'
import listBulleted from '../../../assets/icons/format_list_bulleted.png'
import listNumbered from '../../../assets/icons/format_list_numbered.png'
import link from '../../../assets/icons/link.png'
import linkOff from '../../../assets/icons/link_off.png'
import emoji from '../../../assets/icons/emoji.png'

let privateNotepadTimeout = null

const PrivateNotepadWidget = forwardRef(({ style, dragging, isSorting, ...props }, ref) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { widgetsInfo, user, setUser } = useContext(UserContext)
  const [editorContent, setEditorContent] = useState(EditorState.createEmpty())
  const [mounted, setMounted] = useState(false)

  // Set editor content when user is set and component mounted
  useEffect(() => {
    if(user && user.private_notepad && !mounted) {
      setEditorContent(EditorState.createWithContent(convertFromRaw(user.private_notepad)))
      setMounted(true)
    }
  }, [user, mounted])

  // On editor change
  const handleEditorChange = (state) => {
    setEditorContent(state)
    const currentContent = editorContent.getCurrentContent()
    const newContent = state.getCurrentContent()
    if(currentContent === newContent) return 

    if(privateNotepadTimeout) {
      clearTimeout(privateNotepadTimeout)
    }
    
    privateNotepadTimeout = setTimeout(() => {
      // console.log('save user***')
      let content = convertToRaw(newContent)
      if(content) {
        setUser({...user, private_notepad: content})
        update_user({ private_notepad: content }, user.id)
      }
    }, 3000)
  }

  if(isSorting) {
    return (
      <div className={`widget widget--placeholder ${dragging ? 'widget--dragging' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4">{t('dashboard.private_notepad')}</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`widget no-overflow ${widgetsInfo['item-private_notepad'].size === 'half' ? 'widget--half' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4" {...props}>{t('dashboard.private_notepad')}</h4>
          <div className="widget__head_actions">
            <WidgetDropdown widget="item-private_notepad" />
          </div>
        </div>
        <div className="widget__body">
          <div className="private-notepad-widget-content">
            <Editor
              editorState={editorContent}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="custom-editor-wrapper"
              editorClassName="custom-editor u-custom-scrollbar"
              toolbarClassName="custom-editor-toolbar"
              locale={selectedLang}
              placeholder={t('dashboard.private_notepad_placeholder')}
              toolbar={{
                options: ['inline', 'list', 'link', 'emoji'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                  bold: { icon: formatBold, className: 'editor-btn' },
                  italic: { icon: formatItalic, className: 'editor-btn' },
                  underline: { icon: formatUnderlined, className: 'editor-btn' },
                  strikethrough: { icon: strikethrough, className: 'editor-btn' },
                },
                list: {
                  options: ['unordered', 'ordered'],
                  unordered: { icon: listBulleted, className: 'editor-btn' },
                  ordered: { icon: listNumbered, className: 'editor-btn' },
                },
                link: {
                  link: { icon: link, className: 'editor-btn' },
                  unlink: { icon: linkOff, className: 'editor-btn' },
                },
                emoji: {
                  icon: emoji,
                  className: 'editor-btn'
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
})

export default PrivateNotepadWidget 
import React, { useState, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import GroupIcon from '@material-ui/icons/Group';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuIcon from '@material-ui/icons/Menu';
import CheckIcon from '@material-ui/icons/Check';
import { ShareOutlined, List } from '@material-ui/icons'
import { MdApproval } from 'react-icons/md'

import Tooltip from '../UI/Tooltip';
import CustomTooltip from '../UI/CustomTooltip';
// import PageLoader from '../UI/PageLoader';
// import useAuth from '../../hooks/useAuth';
import { UserContext, TeamContext, GlobalContext } from '../../context';
import { availableOn } from '../../utils'
import config from '../../config.json'
import LetterCircle from '../UI/LetterCircle';

const SideMenu = ({ onShowLongMenu }) => {
  const { t } = useContext(GlobalContext)
  // const { logout } = useAuth();
  // const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { selectedTeam } = useContext(TeamContext)

  const menuIconClickHandler = (e) => {
    e.preventDefault();
    onShowLongMenu(true);
  }

  // const logoutHandler = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   logout();
  // }

  return(
    <div className="side-menu">
      <ul className="side-menu__list">
        <li className="menu">
          <CustomTooltip content={selectedTeam?.name || ' '} position="right">
            <Link to="/" onClick={menuIconClickHandler}>
              {/* <div className="logo">CA</div>   */}
              {selectedTeam?.logo_url 
                ? <div className="thumb" style={{backgroundImage: `url(${selectedTeam.logo_url})`}}></div>
                : <LetterCircle data={selectedTeam?.name || ''} hideTooltip />
              }
            </Link>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.main_title')} position="right">
            <div>
              <NavLink to="/dashboard" exact><i className="custom-icon-dashboard"></i></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.documents')} position="right">
            <div>
              <NavLink to="/documents" exact className="documents"><DescriptionIcon /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        {/* {config.environment === 'development' && <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.documents') + ' v2'} position="right">
            <div>
              <NavLink to="/documents-v2" exact className="documents"><DescriptionIcon /></NavLink>
            </div>
          </CustomTooltip>
        </li>} */}
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.templates')} position="right">
            <div>
              <NavLink to="/templates"><i className="custom-icon-template"></i></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.shared_templates')} position="right">
            <div>
              <NavLink to="/shared-templates"><ShareOutlined /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.alerts')} position="right">
            <div>
              <NavLink to="/calendar"><CalendarTodayIcon /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.tasks')} position="right">
            <div>
              <NavLink to="/tasks"><CheckIcon /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('general.contacts')} position="right">
            <div>
              <NavLink to="/contacts"><GroupIcon /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.signatures_2')} position="right">
            <div>
              <NavLink to="/signatures"><i className="custom-icon-create"></i></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('approvals.title')} position="right">
            <div>
              <NavLink to="/approvals" exact><MdApproval /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.variables')} position="right">
            <div>
              <NavLink to="/variables" exact><List /></NavLink>
            </div>
          </CustomTooltip>
        </li>
        <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.external_documents')} position="right">
            <div>
              <NavLink to="/external-documents" exact><span className="material-symbols-outlined size-24">edit_document</span></NavLink>
            </div>
          </CustomTooltip>
        </li>
        {/* <li className="tooltip-wrapper">
          <CustomTooltip content={t('dashboard.external_documents')} position="right">
            <div>
              <NavLink to="/external-documents" exact><span className="material-symbols-outlined size-24">edit_document</span></NavLink>
            </div>
          </CustomTooltip>
        </li> */}
        {/* <li className="tooltip-wrapper">
          <Tooltip text={t('dashboard.recommended_2')} position="right" />
          <NavLink to="/recommended" onClick={(e) => e.preventDefault()}><EmailOutlinedIcon /></NavLink>
        </li> */}
      </ul>

      {/* <div className="side-menu__bottom">
        <CustomTooltip content={t('dashboard.settings')} position="right">
          <div>
            <NavLink to="/settings/my-profile" className="tooltip-wrapper">
              <SettingsOutlinedIcon />
            </NavLink>
          </div>
        </CustomTooltip>
      </div> */}

      {/* {loading && <PageLoader />} */}
    </div>
  );
}

export default SideMenu;
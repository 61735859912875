import { GET_DOCUMENTS_FOLDERS, SET_DOCUMENTS_FOLDERS_LOADING, SET_DOCUMENTS_FOLDERS, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_DOCUMENTS_FOLDERS:
      return {
        ...state,
        folders: action.payload,
        foldersFetched: true,
        loading: false
      }
    case SET_DOCUMENTS_FOLDERS:
      return {
        ...state,
        folders: action.payload
      }
    case SET_DOCUMENTS_FOLDERS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react'
import { CreateNewFolder, ChevronRight, Folder } from '@material-ui/icons'

import Button from '../UI/Button'
import IconButton from '../UI/IconButton'
import Modal from '../UI/Modal'
import CreateEditFolderModal from './CreateEditFolderModal'

import { DocumentsFoldersContext, LoaderContext, TeamContext, DocumentsContext, GlobalContext } from '../../context'
import { getAllParentFolders, getFirstLevelSubfolders } from '../../utils'

const MoveDocToFolderModal = ({ onClose, doc, currentFolder }) => {
  const { t } = useContext(GlobalContext)
  const { docFolders, docFoldersFetched, fetchDocFolders, docFoldersLoading, setDocParentFolder, createDocFolder } = useContext(DocumentsFoldersContext)
  const { loading, setLoading } = useContext(LoaderContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { updateDocument } = useContext(DocumentsContext)
  const [activeFolder, setActiveFolder] = useState(currentFolder ? [...docFolders].find(f => f.id === currentFolder.parentFolder) : null)
  const [subfolders, setSubfolders] = useState(getFirstLevelSubfolders(docFolders, activeFolder))
  const [parentFolders, setParentFolders] = useState(getAllParentFolders(docFolders, currentFolder).reverse())
  const [showFolderModal, setShowFolderModal] = useState(false)
  const [folderCreated, setFolderCreated] = useState(false)
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fecth doc folder if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!docFoldersFetched) {
        // console.log('fetch doc folders')
        fetchDocFolders(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, docFoldersFetched, fetchDocFolders])

  // Set some state variables after folders are fetched
  useEffect(() => {
    if(docFoldersFetched) {
      let active = currentFolder ? [...docFolders].find(f => f.id === currentFolder.parentFolder) : null
      setActiveFolder(active)
      setSubfolders(getFirstLevelSubfolders(docFolders, active))
      setParentFolders(getAllParentFolders(docFolders, currentFolder).reverse())
    }
  }, [docFoldersFetched, docFolders, currentFolder])

  useEffect(() => {
    if(folderCreated) {
      setSubfolders(getFirstLevelSubfolders(docFolders, activeFolder))
      setFolderCreated(false)
    }
  }, [folderCreated, activeFolder, docFolders]);

  // On item(folder) click
  const itemClickHandler = (folder) => {
    setActiveFolder(folder)
    if(folder) {
      setParentFolders([...getAllParentFolders(docFolders, folder).reverse(), folder])
    }else {
      setParentFolders([])
    }
    setSubfolders(getFirstLevelSubfolders(docFolders, folder))
  }

  // On move
  const handleMoveDoc = useCallback(async () => {
    setLoading(true)
    if(activeFolder) {
      if(!doc.folderId?.includes(activeFolder.id)) {
        await updateDocument({ folderId: [activeFolder.id], last_modified_by: activeTeamMember.id, create_action: 'no' }, doc)
      }
    }else {
      if(doc.folderId?.length > 0) {
        await updateDocument({ folderId: [], last_modified_by: activeTeamMember.id, create_action: 'no' }, doc)
      }
    }
    setLoading(false)
  }, [activeFolder, activeTeamMember, doc, setLoading, updateDocument])

  // On create folder modal open
  const handleCreateFolderModalOpen = () => {
    if(activeFolder) {
      setDocParentFolder(activeFolder)
    }
    setShowFolderModal(true)
  }

  // On folder create
  const handleCreateFolder = async (data) => {
    await createDocFolder(data, selectedTeam?.id)
    setShowFolderModal(false)
    setFolderCreated(true)
  }

  // Breadcrumbs
  let breadcrumbsContent = null

  if(parentFolders.length > 0) {
    breadcrumbsContent = <Fragment>
      <li className="breadcrumbs__item" onClick={() => itemClickHandler(null)}><p>{t('general.all')}</p></li>
      {parentFolders.map((f, i, arr) => {
        if(i < arr.length - 1) {
          return <li className="breadcrumbs__item" key={i} onClick={() => itemClickHandler(f)}>
            <ChevronRight />
            <p>{f.name}</p>
          </li>
        }else {
          return <li className="breadcrumbs__item active" key={i}>
            <ChevronRight />
            {f.name}
          </li>
        }
      })}
    </Fragment>;
  }else {
    breadcrumbsContent = <li className="breadcrumbs__item active">{t('general.all')}</li>
  }

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter' && !showFolderModal) {
        handleMoveDoc(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleMoveDoc, showFolderModal])

  return(
    <Modal onClose={onClose} useEscBtn={!showFolderModal}>
      <div className="move-to-folder">
        <div className="move-to-folder__head">
          <h4>{t('general.move')} <strong>{doc.name}</strong> {t('general.to')}</h4>
          {/* <IconButton icon={<CloseIcon />} onButtonClick={onClose} dark medium round /> */}
        </div>
        <div className="move-to-folder__body">
          <div className="move-to-folder__body_top">
            <ul className="breadcrumbs">
              {breadcrumbsContent}
            </ul>
            <IconButton icon={<CreateNewFolder />} onButtonClick={handleCreateFolderModalOpen} medium />
          </div>
          <div className="move-to-folder__body_main">
            {[...subfolders].filter(f => currentFolder ? f.id !== currentFolder.id : f).map((f, i) => (
              <div className="folder-inner" key={i} onClick={() => itemClickHandler(f)}><Folder /> {f.name}</div>
            ))}
            {[...subfolders].filter(f => currentFolder ? f.id !== currentFolder.id : f).length === 0 && <div className="no-folders">
              <h3>{t('folder.no_folders')}</h3>
              <p>{t('folder.create')}</p>  
            </div>}
          </div>
        </div>
        <div className="move-to-folder__foot">
          <Button text={t('general.cancel')} onButtonClick={onClose} />
          <Button 
            text={loading ? t('general.moving') : t('general.move_here')} 
            onButtonClick={handleMoveDoc} 
            primary 
            disabled={loading || doc.folderId?.includes(activeFolder?.id) || (activeFolder === null && (!doc.folderId || doc.folderId.length === 0))}
          />
        </div>
      </div>

      {showFolderModal && <CreateEditFolderModal 
        onClose={() => setShowFolderModal(false)} 
        onCreate={handleCreateFolder}
        onEdit={() => {}}
        foldersLoading={docFoldersLoading}
        currentFolder={currentFolder}
        view="documents"
      />}
    </Modal>
  );
}

export default MoveDocToFolderModal
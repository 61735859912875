import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Input from '../UI/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import useForm from '../../hooks/useForm';
import firebase from '../../services/firebase';
import { NotificationContext, GlobalContext } from '../../context';

const ForgotPassword = () => {
  const { t, validate, selectedLang } = useContext(GlobalContext)
  const initialData = {
    email: {
      email: true,
      value: ''
    },
  }
  const { formData, errors, setErrors, changeHandler } = useForm(initialData, validate);
  const { setNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true, t);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        firebase.auth().languageCode = selectedLang;
        await firebase.auth().sendPasswordResetEmail(formData.email.value);
        setNotification({ msg: t('auth.password_reset_email'), type: 'default' });
        setLoading(false);
      }catch(err) { 
        console.log(err);
        if(err.code === 'auth/user-not-found') {
          setNotification({ msg: t('auth.email_not_found'), type: 'danger' });
        }else{
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        }
        setLoading(false);
      }
    }
  }

  return(
    <div className="auth">
      <div className="auth__box">
        <div className="auth__box_main">
          <div className="auth__box_main-head">
            <p>{t('auth.reset_password')}</p>
            <Link to="/signin" className="text-link">{t('auth.login_2')}</Link>
          </div>
          <form className="form" onSubmit={submitHandler}>
            <Input 
              name="email"
              value={formData.email.value}
              onChange={changeHandler}
              placeholder={t('auth.email')}
              error={errors.email}
              iconEl={<MailIcon />}
              whiteBackground
              iconSmall
              sizeMedium
            />
            <Button type="submit" text={!loading ? t('auth.reset_password') : <Loader small normalWhite />} disabled={loading} primary fullWidth medium />
          </form>
        </div>
        {/* <div className="auth__back">
          <Link to="/signin"><ChevronLeftIcon /> {t('auth.login_2')}</Link>
        </div> */}
        <div className="auth__note">
          <p>{t('auth.dont_have_account')} <Link to="/signup" className="text-link">{t('auth.sign_up_here')}</Link></p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
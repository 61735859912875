import { useContext } from 'react'

import { GlobalContext } from '../../context'

const WidgetPlaceholderTasks = () => {
  const { t } = useContext(GlobalContext)

  return (
    <div className="customize-panel-widget-placeholder">
      <div className="customize-panel-widget-placeholder__head">
        <p>{t('dashboard.tasks')}</p>
      </div>
      <div className="customize-panel-widget-placeholder__body">
        <ul className="list">
          <li><span className="material-symbols-outlined">check_circle</span> <div className="line line--4"></div></li>
          <li><span className="material-symbols-outlined">check_circle</span> <div className="line line--5"></div></li>
          <li><span className="material-symbols-outlined">check_circle</span> <div className="line line--3"></div></li>
        </ul>
      </div>
    </div>
  )
}

export default WidgetPlaceholderTasks
import React, { createContext, useReducer, useState, useCallback } from 'react';

import contactsReducer from './contactsReducer';
import { GET_CONTACTS, SET_CONTACTS, RESET_STATE } from '../types';
import { fetch_contacts, create_contact, update_contact, delete_contact } from '../../services/contacts';
import { sortArrayOfObjects, getTeamContactsColumnWidthsData, getTeamSortingData, getSelectedTeamFromLS } from '../../utils';
import { CONTACTS_COLUMNS_WIDTHS } from '../../constants'

export const ContactsContext = createContext();

const selectedTeam = getSelectedTeamFromLS()
const sortingData = getTeamSortingData()

let defaultSort = { value: 'last_name', order: 'desc' }
if(selectedTeam && sortingData && sortingData[selectedTeam] && sortingData[selectedTeam].contacts) {
  defaultSort = { value: sortingData[selectedTeam].contacts.value || 'last_name', order: sortingData[selectedTeam].contacts.order || 'desc' }
}

// const columnsWidthsData = getTeamContactsColumnWidthsData()
// let defaultColumnsWidths = CONTACTS_COLUMNS_WIDTHS
// if(columnsWidthsData && columnsWidthsData[selectedTeam]) {
//   defaultColumnsWidths = columnsWidthsData[selectedTeam]
// }

const ContactsState = ({ children }) => {
  const initialState = {
    contacts: [],
    contactsFetched: false,
    companies: [],
  };
  const [contactsSort, setContactsSort] = useState(defaultSort);
  const [showContactsImportModal, setShowContactsImportModal] = useState(false)
  const [contactsImportXls, setContactsImportXls] = useState(false)
  const [showContactsAddModal, setShowContactsAddModal] = useState(false)
  // const [contactsTableWidth, setContactsTableWidth] = useState('100%')
  // const [contactsTableColumnsWidths, setContactsTableColumnsWidths] = useState(defaultColumnsWidths)
  const [contactsReset, setContactsReset] = useState(false)
  const [contactsSelectedFilters, setContactsSelectedFilters] = useState({
    group: 'all',
    search: ''
  })
  const [state, dispatch] = useReducer(contactsReducer, initialState)

  // Fetch contacts
  const fetchContacts = useCallback(async (teamId) => {
    try {
      const res = await fetch_contacts(teamId);
      const arr = [];
      for(let id in res) {
        const contact = {...res[id]};
        contact.id = id;
        if(contact.group !== 'notary' || contact.group !== 'client') {
          arr.push(contact);
        }
      }
      dispatch({
        type: GET_CONTACTS,
        payload: {
          contacts: sortArrayOfObjects(arr, contactsSort.value, contactsSort.order),
        }
      });
    }catch(err) {
      console.log('CONTACTS FETCH err', err);
    }
  }, [contactsSort])

  // Set contacts
  const setContacts = (arr) => {
    dispatch({
      type: SET_CONTACTS,
      payload: arr
    });
  }

  // Create contact 
  const createContact = useCallback(async (data, teamId, onSuccess = () => {}, onError = () => {}) => {
    try {
      await create_contact(data);
      await fetchContacts(teamId);
      onSuccess();
    }catch(err) {
      console.log('CREATE CONTACT err', err);
      onError(err);
    }
  }, [fetchContacts])

  // Update contact 
  const updateContact = useCallback(async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const contacts = [...state.contacts];
      await update_contact(data, id);
      const toUpdate = contacts.find(c => c.id === id);
      const updatedObj = {
        ...toUpdate,
        ...data
      };
      const updatedArr = [...contacts].map(c => c.id === id ? updatedObj : c);
      setContacts(updatedArr)
      onSuccess();
    }catch(err) {
      console.log('UPDATE CONTACT err', err);
      onError(err);
    }
  }, [state.contacts])

  // Delete contact
  const deleteContact = useCallback(async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const contacts = [...state.contacts];
      await delete_contact(id);
      const updatedArr = [...contacts].filter(t => t.id !== id);
      setContacts(updatedArr);
      onSuccess();
    } catch (err) {
      console.log('DELETE CONTACT err', err);
      onError(err);
    }
  }, [state.contacts])

  // Delete multiple contacts
  const deleteMultipleContacts = (ids) => {
    const contacts = [...state.contacts]
    const updated = contacts.filter(c => !ids.includes(c.id))
    setContacts(updated)
  }

  // Get contact by id
  const getContactById = useCallback((id) => {
    const contacts = [...state.contacts];
    return contacts.find(t => t.id === id);
  }, [state.contacts])

  // Get contact by name
  const getContactByName = useCallback((name) => {
    const contacts = [...state.contacts];
    return contacts.find(t => t.name === name);
  }, [state.contacts])

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, contactsFetched: true } : initialState,
      payload: initialState,
    });
    const selectedTeam = getSelectedTeamFromLS()
    const sortingData = getTeamSortingData()
    
    let defaultSort = { value: 'last_name', order: 'desc' }
    if(selectedTeam && sortingData && sortingData[selectedTeam] && sortingData[selectedTeam].contacts) {
      defaultSort = { value: sortingData[selectedTeam].contacts.value || 'last_name', order: sortingData[selectedTeam].contacts.order || 'desc' }
    }
    
    // const columnsWidthsData = getTeamContactsColumnWidthsData()
    // let defaultColumnsWidths = CONTACTS_COLUMNS_WIDTHS
    // if(columnsWidthsData && columnsWidthsData[selectedTeam]) {
    //   defaultColumnsWidths = columnsWidthsData[selectedTeam]
    // }
    // setContactsTableColumnsWidths(defaultColumnsWidths)
    setContactsSort(defaultSort)
    setContactsReset(true)
  }

  return(
    <ContactsContext.Provider value={{
      contacts: state.contacts,
      companies: state.companies,
      contactsFetched: state.contactsFetched,
      contactsSort,
      fetchContacts,
      createContact,
      updateContact,
      deleteContact,
      getContactById,
      resetContactState: resetState,
      getContactByName,
      setContactsSort,
      showContactsImportModal,
      setShowContactsImportModal,
      showContactsAddModal,
      setShowContactsAddModal,
      // contactsTableWidth,
      // setContactsTableWidth,
      // contactsTableColumnsWidths,
      // setContactsTableColumnsWidths,
      contactsImportXls,
      setContactsImportXls,
      setContacts,
      contactsReset,
      setContactsReset,
      deleteMultipleContacts,
      contactsSelectedFilters,
      setContactsSelectedFilters,
    }}>
      {children}
    </ContactsContext.Provider>
  );
};

export default ContactsState;
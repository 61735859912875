import React, { useEffect, useState, useContext } from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Modal from '../../UI/Modal';
import Loader from '../../UI/Loader';
import Button from '../../UI/Button';
import ResponseLoader from '../../UI/ResponseLoader';
import { update_document, upload_file } from '../../../services/firestore';
import { gdrive_get_items, gdrive_upload_file } from '../../../services/gdrive';
import { NotificationContext, TeamContext, DocumentsContext, GlobalContext } from '../../../context';

const GDriveModal = ({ onClose, token, doc, onFetchDocumentData, documentAttachments }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext);
  const { activeTeamMember } = useContext(TeamContext);
  const { templates } = useContext(DocumentsContext)
  const [folders, setFolders] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [activeFolderId, setActiveFolderId] = useState('root');
  const [level, setLevel] = useState(0);
  const [nextPageToken, setNextPageToken] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState({});
  const [folderIds, setFolderIds] = useState(['root']);

  // Get items
  useEffect(() => {
    const getItems = async () => {
      try {
        const res = await gdrive_get_items({ token: token, folderId: 'root', redirect_uri: window.location.origin });
        setFolders(res.data.files);
        // console.log(res)
        setLoadingFolders(false);
        if(res.data.nextPageToken) {
          setNextPageToken(res.data.nextPageToken);
          setPages({ '2': res.data.nextPageToken });
        }
        // console.log(res);
      } catch (error) {
        console.log(error);
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        setLoadingFolders(false);
      }
    }
    getItems();
    // eslint-disable-next-line
  }, [token]);

  // Change folder on click
  const itemClickHandler = async (f) => {
    // console.log(f)
    setFolderIds(prev => [...prev, f.id])
    setLoadingFolders(true);
    setActiveFolderId(f.id);
    setLevel(prev => prev + 1);
    try {
      const res = await gdrive_get_items({ token: token, folderId: f.id, redirect_uri: window.location.origin });
      setFolders(res.data.files);
      setLoadingFolders(false);
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  // Download file on click
  const downloadFileHandler = async (f) => {
    const a = document.createElement("a"); 
    a.href = f.webContentLink;
    a.download = f.name;
    a.click(); 
  }

  // Upload document
  const uploadDocHandler = async () => {
    try {
      setUploading(true);
      // If document is created with template it maybe doesn't have documentUrls prop, use fetchDocumentData in this case
      let url, format, name, mimeType;
      if(!doc.documentUrls) {
        const documentData = await onFetchDocumentData(doc, 'pdf');
        let uploadResult = await upload_file(documentData, `${doc.id}`, 'pdf', 'application/pdf', 'documents')
        if(!uploadResult.url) {
          return
        }
        let documentUpdates = {}   
        documentUpdates[`documentUrls.pdf`] = uploadResult.url
        documentUpdates.last_modified_by = activeTeamMember.id
        documentUpdates.create_action = 'no'
        await update_document(doc.id, documentUpdates, documentAttachments)
        url = uploadResult.url;
        format = 'pdf';
        mimeType = 'application/pdf';
        name = `${doc.name}.${format}`;
      }else {
        url = doc.documentUrls.pdf ? doc.documentUrls.pdf : doc.documentUrls.docx;
        format = doc.documentUrls.pdf ? 'pdf' : 'docx';
        name = `${doc.name}.${format}`;
        mimeType = format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      const res = await gdrive_upload_file({ fileUrl: url, name, token, mimeType, folderId: activeFolderId, redirect_uri: window.location.origin });
      const fRes = await gdrive_get_items({ token: token, folderId: 'root', redirect_uri: window.location.origin });
      setFolders(fRes.data.files);
      setCurrentPage(1);
      setActiveFolderId('root');
      setLevel(0);
      setFolderIds(['root']);
      if(fRes.data.nextPageToken) {
        setNextPageToken(res.data.nextPageToken);
        setPages({ '2': res.data.nextPageToken });
      }
      setUploading(false);
      setNotification({ msg: t('notification.file_uploaded'), type: 'success' });
      // console.log(res);
    } catch (err) {
      console.log(err)
      setUploading(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    }
  }

  // Go back
  const goBackHandler = async () => {
    setLevel(prev => prev - 1);
    setLoadingFolders(true);
    try {
      const res = await gdrive_get_items({ token: token, folderId: folderIds[folderIds.length - 2], redirect_uri: window.location.origin });
      setActiveFolderId(level === 1 ? 'root' : folderIds[folderIds.length - 2]);
      setFolderIds(prev => prev.filter(id => id !== activeFolderId));
      setFolders(res.data.files);
      setLoadingFolders(false);
      if(level === 1) {
        setPages({});
        setCurrentPage(1);
        if(res.data.nextPageToken) {
          setNextPageToken(res.data.nextPageToken);
          setPages({ '2': res.data.nextPageToken });
        }
      }
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  // Go to next page
  const goToNextPageHandler = async () => {
    setLoadingFolders(true);
    try {
      const res = await gdrive_get_items({ token: token, folderId: 'root', nextPageToken, redirect_uri: window.location.origin });
      setCurrentPage(prev => prev + 1);
      setFolders(res.data.files);
      setLoadingFolders(false);
      if(res.data.nextPageToken) {
        setNextPageToken(res.data.nextPageToken);
        setPages(prev => ({
          ...prev,
          [`${currentPage + 2}`]: res.data.nextPageToken
        }))
      }else {
        setNextPageToken('');
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  // Go to prev page
  const goToPrevPageHandler = async () => {
    setLoadingFolders(true);
    try {
      const res = await gdrive_get_items({ token: token, folderId: 'root', nextPageToken: pages[`${currentPage - 1}`] ? pages[`${currentPage - 1}`] : null, redirect_uri: window.location.origin });
      setCurrentPage(prev => prev - 1);
      setFolders(res.data.files);
      setLoadingFolders(false);
      if(res.data.nextPageToken) {
        setNextPageToken(res.data.nextPageToken);
      }else {
        setNextPageToken('');
      }
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="move-to-folder">
        <div className="move-to-folder__head">
          <h4>{t('general.upload_document_to', { driveName: 'GoogleDrive' })}</h4>
        </div>
        <div className="move-to-folder__body">
          {level > 0 && !loadingFolders && <Button text={t('general.go_back')} onButtonClick={() => goBackHandler()} disabled={loadingFolders} />}
          <div className="move-to-folder__body_main">
            {!loadingFolders ? 
              <ul>
                {folders.map((f, i) => {
                  if(f.mimeType === 'application/vnd.google-apps.folder') {
                    return <li className="folder-inner" key={i} onClick={() => itemClickHandler(f)}><FolderIcon /> {f.name}</li>
                  }else {
                    return <li className="folder-inner" key={i} onClick={() => downloadFileHandler(f)}><DescriptionOutlinedIcon /> {f.name}</li>
                  }
                })}
              </ul> 
                :
              <div className="loader-wrapper"><Loader small normal /></div>
            }
            {level === 0 && (nextPageToken || currentPage > 1) ? <div className="move-to-folder__body_main-pagination">
              {currentPage > 1 && <Button text={t('general.prev_page')} outlinePrimaryLight onButtonClick={goToPrevPageHandler} disabled={loadingFolders} />}
              {nextPageToken && <Button text={t('general.next_page')} outlinePrimaryLight onButtonClick={goToNextPageHandler} disabled={loadingFolders} />}
            </div> : null}
          </div>
        </div>
        <div className="move-to-folder__foot">
          <Button text={t('general.cancel')} onButtonClick={onClose} />
          <Button 
            text={t('general.upload_here_2')}
            onButtonClick={uploadDocHandler} 
            primary 
            disabled={loadingFolders}
          />
        </div>
      </div>

      {uploading && <ResponseLoader text={t('general.uploading_file_to', { driveName: 'GoogleDrive' })} />}
    </Modal>
  );
}

export default GDriveModal;
const Textarea = ({ label, name, id, value, onChange, placeholder, error, className, style = {}, onFocus, onBlur }) => {
  let cssClasses = 'textarea-v2'

  if(className) {
    cssClasses += ` ${className}`
  }

  return(
    <div className={cssClasses}>
      {label && <label htmlFor={id ? id : name} className="textarea-v2__label">{label}</label>}
      <textarea 
        name={name} 
        id={id ? id : name} 
        className="textarea-v2__field"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
        onFocus={onFocus}
        onBlur={onBlur}
      ></textarea>
      {error && <div className="textarea__error">{error}</div>}
    </div>
  )
}


export default Textarea
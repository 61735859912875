// IMPORTANT: DO NOT USE CONSTANTS WHICH NEEDS TO BE TRANSLATED IN THIS FILE ANYMORE, USE ConstantsContext instead

export const SEARCH_HISTORY_LS_KEY = 'jurisur_search_history';

export const CONVERSION_RATES = {
  euro: {
    pound: 0.89,
    dollar: 1.22
  },
  dollar: {
    pound: 0.73,
    euro: 0.82
  },
  pound: {
    euro: 1.12,
    dollar: 1.37
  },
}

export const CARBON_COOKIE = 'carbon_cookie_consent';
export const CARBON_SORT_LS = 'carbon_sort'; // LS - local storage
export const CARBON_TEAM_SORT_LS = 'carbon_team_sort'; 
export const CARBON_COLUMNS_LS = 'carbon_columns';
export const CARBON_TEAM_COLUMNS_LS = 'carbon_team_columns';
export const CARBON_COLUMNS_V2_LS = 'carbon_columns_v2';
export const CARBON_COLUMNS_ORDER_LS = 'carbon_columns_order';
export const CARBON_TEAM_COLUMNS_ORDER_LS = 'carbon_team_columns_order';
export const CARBON_COLUMNS_ORDER_V2_LS = 'carbon_columns_order_v2';
export const CARBON_PAGINATION_LS = 'carbon_pagination'
export const CARBON_TEAM_PAGINATION_LS = 'carbon_team_pagination'
export const GA_ID = 'EC4V1350M7';

export const TAGS_COLORS = [
  '#2C80FA', '#17BFFF', '#1FDAD2', '#FF07C3', '#F92A60', '#FF702B', '#FDB400', '#1FC933', '#8C46FF', '#666666', 
]

export const KPI_SORT_LS = 'carbon_kpi_sort'
export const SHOW_LABELS_LS = 'carbon_show_labels'
export const HEADER_BG_LS = 'carbon_header_bg'

export const CARBON_DOCS_COLUMNS_WIDTHS_LS = 'carbon_docs_columns_widths'
export const CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS = 'carbon_team_docs_columns_widths'
export const CARBON_DOCS_COLUMNS_WIDTHS_V2_LS = 'carbon_docs_columns_widths_v2'
export const CARBON_CONTACTS_COLUMNS_WIDTHS_LS = 'carbon_contacts_columns_widths'
export const CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS = 'carbon_team_contacts_columns_widths'
export const CARBON_TEAM_VARS_COLUMNS_WIDTHS_LS = 'carbon_team_vars_columns_widths'
export const CARBON_TEAM_TEMPLATES_COLUMNS_WIDTHS_LS = 'carbon_team_templates_columns_widths'
export const CARBON_TEAM_SIGNATURES_COLUMNS_WIDTHS_LS = 'carbon_team_signatures_columns_widths'
export const CARBON_TEAM_APPROVALS_COLUMNS_WIDTHS_LS = 'carbon_team_approvals_columns_widths'
export const CARBON_TEAM_SHARED_DOCS_COLUMNS_WIDTHS_LS = 'carbon_team_shared_docs_columns_widths'
export const CARBON_SELECTED_TEAM_LS = 'carbon_selected_team'
export const COLUMNS_WIDTHS = {
  'name': 430,
  'progress': 120,
  'sort_template': 120,
  'status_label': 120,
  'user': 120,
  'sort_attachments': 120,
  'sort_tasks': 120,
  'sort_dates': 120,
  'sort_alerts': 120,
  'sort_tags': 120,
  'meta.created': 120,
  'meta.updated': 120,
  'actions': 120,
  'helper': 'auto'
}
export const COLUMNS_WIDTHS_V2 = {
  'checkbox_all': 53,
  'name': 430,
  'progress': 120,
  'sort_template': 100,
  'sort_status': 160,
  'user': 120,
  'sort_attachments': 100,
  'sort_tasks': 100,
  'sort_dates': 100,
  'sort_members': 100,
  'sort_alerts': 100,
  'sort_tags': 120,
  'meta.created': 120,
  'meta.updated': 120,
  'helper': 'auto'
}
export const CONTACTS_COLUMNS_WIDTHS = {
  'checkbox_col': 50,
  'first_name': 200,
  'last_name': 200,
  'email': 200,
  'title': 200,
  'address': 200,
  'street_number': 200,
  'zip_code': 200,
  'city': 200,
  'country': 200,
  'phone_number': 200,
  'dob': 200,
  'nationality': 200,
  'website': 200,
  'actions': 120,
  'helper': 'auto'
}
export const VARIABLES_COLUMNS_WIDTHS = {
  'checkbox_col': 50,
  'variable': 200,
  'tooltip': 200,
  'category_sort': 200,
  'question': 200,
  'meta.created': 120,
  'meta.updated': 120,
  'actions': 120,
  'helper': 'auto'
}

export const TEMPLATES_COLUMNS_WIDTHS = {
  'checkbox_col': 50,
  'name': 200,
  'meta.created': 120,
  'meta.updated': 120,
  'actions': 120,
  'helper': 'auto'
}

export const SIGNATURES_COLUMNS_WIDTHS = {
  'checkbox_col': 50,
  'title': 200,
  'recipients.email__arr': 200,
  'sentBy': 200,
  'createdAt': 200,
  'status_label': 200,
  'helper': 'auto'
}

export const APPROVALS_COLUMNS_WIDTHS = {
  'checkbox_col': 50,
  'name': 400,
  'description': 200,
  'sort_steps': 400,
  'helper': 'auto'
}

export const SHARED_DOCS_COLUMNS_WIDTHS = {
  'checkbox_col': 50,
  'name': 200,
  'created': 200,
  'updated_at': 200,
  'users': 200,
  'helper': 'auto'
}

export const DASHBOARD_WIDGETS = [
  'item-tasks',
  'item-alerts',
  'item-recent',
  'item-contracts',
  'item-private_notepad',
  'item-people',
]

export const perPageOptions = [5, 10, 15, 20, 50]

export const ENVIRONMENT_DEVELOPMENT = 'development'
export const ENVIRONMENT_PRODUCTION = 'production'
// Get file data
const getFileData = (file, fType = '') => new Promise((resolve, reject) => {
  if (file) {
    let reader = new FileReader()

    reader.onload = async (e) => {
      let components = file.name.split('.')
      const format = components[components.length - 1]
      components.splice(components.length - 1, 1)
      const name = components.join('.')
      const type = file.type || fType
      const data = { data: e.target.result, data_plain: e.target.result.split(',')[1], name: name, format: format, type: type, size: file.size }
      resolve(data)
    }
    reader.onerror = (err) => {
      console.log('reader on error', err)
      reject(err)
    }
    reader.readAsDataURL(file)
  }
})

export {
  getFileData
}
import { urlSuffixForEnvironment } from '../utils'
import config from '../config.json'
import { post_request } from './firestore'

// const baseUrl = `http://localhost:5001/carbon-contract-management/europe-west1/api${urlSuffixForEnvironment(config.environment, config.staging)}`
const baseUrl = `https://europe-west1-carbon-contract-management.cloudfunctions.net/api${urlSuffixForEnvironment(config.environment, config.staging)}`

const log_event = async (events) => {
  if(!events) {
    return
  }  
  return await post_request(`${baseUrl}/log_event`, { events })
}

export {
  log_event
}
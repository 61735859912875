import React, { useEffect, useState, useContext } from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Modal from '../../UI/Modal';
import Loader from '../../UI/Loader';
import Button from '../../UI/Button';
import ResponseLoader from '../../UI/ResponseLoader';
import { update_document, upload_file } from '../../../services/firestore';
import { onedrive_get_items, onedrive_upload, onedrive_get_folder_items, onedrive_get_folder } from '../../../services/onedrive';
import { NotificationContext, TeamContext, DocumentsContext, GlobalContext } from '../../../context';

const OneDriveModal = ({ onClose, token, doc, onFetchDocumentData, documentAttachments, type = 'document', onAttachmentUpload, onDocUpload }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext);
  const { activeTeamMember } = useContext(TeamContext);
  const { templates } = useContext(DocumentsContext)
  const [folders, setFolders] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [activeFolder, setActiveFolder] = useState(null);
  const [level, setLevel] = useState(0);

  // Get items
  useEffect(() => {
    const getItems = async () => {
      try {
        const res = await onedrive_get_items({ token: token });
        setFolders(res.data.value);
        setLoadingFolders(false);
        // console.log(res);
      } catch (error) {
        console.log(error);
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        setLoadingFolders(false);
      }
    }
    getItems();
    // eslint-disable-next-line
  }, [token]);

  // Chage folder on click
  const itemClickHandler = async (f) => {
    // console.log(f)
    setLoadingFolders(true);
    setActiveFolder(f);
    setLevel(prev => prev + 1);
    try {
      const res = await onedrive_get_folder_items({ token: token, folderId: f.id });
      setFolders(res.data.value);
      setLoadingFolders(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  // Download file on click
  const downloadFileHandler = async (f) => {
    if(type === 'attachment') {
      onAttachmentUpload(f)
    }else if(type === 'doc-upload') {
      onDocUpload(f)
    }else {
      const a = document.createElement("a")
      a.href = f['@microsoft.graph.downloadUrl']
      a.download = f.name
      a.click()
    }
  }

  // Upload document
  const uploadDocHandler = async () => {
    // console.log(activeFolder)
    try {
      setUploading(true);
      let url, format, name;
      if(!doc.documentUrls) {
        const documentData = await onFetchDocumentData(doc, 'pdf');
        let uploadResult = await upload_file(documentData, `${doc.id}`, 'pdf', 'application/pdf', 'documents')
        if(!uploadResult.url) {
          return
        }
        let documentUpdates = {}   
        documentUpdates[`documentUrls.pdf`] = uploadResult.url
        documentUpdates.last_modified_by = activeTeamMember.id
        documentUpdates.create_action = 'no'
        await update_document(doc.id, documentUpdates, documentAttachments)
        url = uploadResult.url;
        format = 'pdf';
        name = `${doc.name}.${format}`;
      }else {
        url = doc.documentUrls.pdf ? doc.documentUrls.pdf : doc.documentUrls.docx;
        format = doc.documentUrls.pdf ? 'pdf' : 'docx';
        name = `${doc.name}.${format}`;
      }
      await onedrive_upload({ fileUrl: url, fileName: name, token, folderId: activeFolder?.id });
      const fRes = activeFolder 
        ? await onedrive_get_folder_items({ token: token, folderId: activeFolder?.id }) : await onedrive_get_items({ token: token });
      setFolders(fRes.data.value);
      setUploading(false);
      setNotification({ msg: t('notification.file_uploaded'), type: 'success' });
    } catch (err) {
      console.log(err)
      setUploading(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    }
  }

  // Go back
  const goBackHandler = async () => {
    setLevel(prev => prev - 1);
    setLoadingFolders(true);
    try {
      const res = await onedrive_get_folder_items({ token: token, folderId: activeFolder.parentReference.id });
      const fRes = await onedrive_get_folder({ token: token, folderId: activeFolder.parentReference.id });
      setActiveFolder(fRes.data);
      setFolders(res.data.value);
      setLoadingFolders(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setLoadingFolders(false);
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="move-to-folder">
        <div className="move-to-folder__head">
          <h4>{t('general.upload_document_to', { driveName: 'OneDrive' })}</h4>
        </div>
        <div className="move-to-folder__body">
          {level > 0 && !loadingFolders && <Button text={t('general.go_back')} onButtonClick={() => goBackHandler()} disabled={loadingFolders} />}
          <div className="move-to-folder__body_main">
            {!loadingFolders ? 
              <ul>
                {folders.map((f, i) => {
                  if(f.folder) {
                    return <li className="folder-inner" key={i} onClick={() => itemClickHandler(f)}><FolderIcon /> {f.name}</li>
                  }else {
                    return <li className="folder-inner" key={i} onClick={() => downloadFileHandler(f)}><DescriptionOutlinedIcon /> {f.name}</li>
                  }
                })}
              </ul> 
                :
              <div className="loader-wrapper"><Loader small normal /></div>
            }
          </div>
        </div>
        <div className="move-to-folder__foot">
          <Button text={t('general.cancel')} onButtonClick={onClose} />
          {type === 'document' && <Button 
            text={t('general.upload_here_2')}
            onButtonClick={uploadDocHandler} 
            primary 
            disabled={loadingFolders}
          />}
        </div>
      </div>

      {uploading && <ResponseLoader text={t('general.uploading_file_to', { driveName: 'OneDrive' })} />}
    </Modal>
  );
}

export default OneDriveModal;
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { ArrowDropDown } from '@material-ui/icons'

import logoSmall from '../../assets/images/carbon_logo.svg';
import logoNew from '../../assets/images/carbon_logo_new.png'
import ActionsDropdown from '../UI/ActionsDropdown'

import { GlobalContext } from '../../context';
import firebase from '../../services/firebase';
import { appLanguages, isCookieAccepted } from '../../utils'
import { setLanguage } from '../../i18n'

const AuthLayout = ({ children }) => {
  const { setJoiningTeamOption, selectedLang, setSelectedLang, t } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const setOptions = async () => {
      try {
        if(location.state?.from && location.state?.from?.pathname === '/settings/team' && location.state.from.search !== '') {
          const qsVars = qs.parse(location.state.from.search, { ignoreQueryPrefix: true })
          let reg
          if(!qsVars['email']) {
            reg = []
          } else {
            reg = await firebase.auth().fetchSignInMethodsForEmail(qsVars['email'])
          }
          if(qsVars['join_team'] && qsVars['join_team'] === 'true') {
            const option = {
              default: false,
              redirect: '/settings/team',
              email: qsVars['email'] || '',
              team: qsVars['team'],
              invitationCode: qsVars['invitation_code'] || ''
            };
            setJoiningTeamOption(option);
            reg.length > 0 ? history.push('/signin') : history.push('/signup');
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    setOptions()
  }, [location, setJoiningTeamOption, history]);

  // On language change
  const handleLanguageChange = async (e, lang) => {
    e.preventDefault()
    let current = selectedLang
    if(lang === current) return 
    
    setSelectedLang(lang)
    if(isCookieAccepted()) {
      setLanguage(lang)
    }
    document.body.click()
  }

  return(
    <div className="authentication">
      <div className="authentication__language">
        <ActionsDropdown
          trigger={<button className="button button--primary-light language-selector">{selectedLang} <ArrowDropDown /></button>}
          dropdownClass="more-actions more-actions--2"
          width={60}
          hideHeader
          noPadding
        >
          <ul className="dropdown">
            { appLanguages.map((lang, li) =>
              <li key={`lang_option_${li}`}>
                <a href="/#" onClick={(e) => handleLanguageChange(e, lang.languageCode)}>{lang.labelShort.toUpperCase()}</a>
              </li>
            )}
          </ul>
        </ActionsDropdown> 
      </div>
      <div className="authentication__inner">
        <div className="authentication__head" onClick={() => window.location.href = 'https://getcarbon.ai/'}>
          <img src={logoNew} alt="Carbon"/>
          <p>Carbon</p>
        </div>
        <div className="authentication__body">
          {children}
        </div>
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node
}

export default AuthLayout;
import { useContext, useState  } from 'react'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { LetterCircle, Select, Option } from '../../new_ui'
import { GlobalContext, TeamContext } from '../../../context'

const ApprovalStepRow = ({ data, onStepDelete, num, onMembersAdd, onStepFromUpdate }) => {
  const { t } = useContext(GlobalContext)
  const { getUserNameById } = useContext(TeamContext)
  const [from, setFrom] = useState(data.from || 'anyone')

  // On from change
  const handleFromChange = (val) => {
    console.log(val)
    setFrom(val)
    onStepFromUpdate(data, val)
  }

  // On step remove
  const handleStepDelete = () => {
    onStepDelete(data)
  }

  // On members add
  const handleMembersAdd = () => {
    onMembersAdd(data)
  }

  return (
    <div className="approval-step-row">
      <div className="approval-step-col" data-label={t('approvals.step')}>{num}</div>
      <div className="approval-step-col" data-label={t('approvals.assigned_to')}>
        {data.members.length > 0 && <div className="members">
          {data.members.map((id) => {
            let name = getUserNameById(id)
            return <LetterCircle key={id} data={name} />
          })}
        </div>}
        <button className="icon-btn icon-btn--small icon-btn--light edit-members-btn" onClick={handleMembersAdd}>
          {data.members.length === 0 ? <span className="material-symbols-outlined weight-300">person_add</span> : <span className="material-symbols-outlined weight-300">edit</span>}
        </button>
      </div>
      <div className="approval-step-col approval-step-col--with-dropdown" data-label={t('approvals.approval_required')}>
        <Select 
          selected={from} 
          onChange={handleFromChange} 
          alignDropdownRight={true}
          className="select-v2--new select-v2--new-light select-v2--new-small"
          useSymbolDropdownIcon={true}
        >
          <Option value="anyone">{t('approvals.from_anyone')}</Option>
          <Option value="everyone">{t('approvals.from_everyone')}</Option>
        </Select>
        <button className="btn btn--danger-light btn--small remove-step-btn" onClick={handleStepDelete}>{t('general.delete')}</button>
      </div>
      {/* <div className="approval-step-col">
        <button className="btn btn--danger-light btn--small remove-step-btn" onClick={handleStepDelete}>{t('general.delete')}</button>
      </div> */}
    </div>
  )
}

export default ApprovalStepRow
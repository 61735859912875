import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ type, msg }) => {
  let msgClass = 'message';

  if(type === 'primary') {
    msgClass += ' message--primary';
  }

  if(type === 'success') {
    msgClass += ' message--success';
  }

  if(type === 'error') {
    msgClass += ' message--danger';
  }
  
  return(
    <div className={msgClass}>
      {msg}
    </div>
  );
}

Message.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'primary']).isRequired,
  msg: PropTypes.string.isRequired
}

export default Message;
import React from 'react';

import CustomTooltip from './CustomTooltip'

const LetterCircle = ({ data, hideTooltip, tooltipIcon, size }) => {
  let letter = ''
  if(data) {
    letter = data.slice(0, 1)
  }

  let circleClass = 'letter-circle';

  const set1 = ['a', 'h'];
  const set2 = ['f', 'j'];
  const set3 = ['g', 'k'];
  const set4 = ['d', 'w'];
  const set5 = ['v', 'e'];
  const set6 = ['s', 'z'];
  const set7 = ['m', 't'];
  const set8 = ['n', 'p'];
  const set9 = ['r', 'y'];
  const set10 = ['c', 'o'];
  const set11 = ['b', 'l'];
  const set12 = ['i', 'x'];
  const set13 = ['u'];

  if(set1.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--1';
  }else if(set2.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--2';
  }else if(set3.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--3';
  }else if(set4.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--4';
  }else if(set5.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--5';
  }else if(set6.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--6';
  }else if(set7.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--7';
  }else if(set8.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--8';
  }else if(set9.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--9';
  }else if(set10.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--10';
  }else if(set11.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--11';
  }else if(set12.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--12';
  }else if(set13.includes(letter.toLowerCase())) {
    circleClass += ' letter-circle--13';
  }

  const style = {}
  if(size) {
    style.width = size
    style.height = size
  }

  const element = (
    <div className={circleClass} style={style}>
      {letter}
    </div>
  )

  if(hideTooltip) {
    return element
  }

  return(
    <CustomTooltip content={tooltipIcon ? <>{data} {tooltipIcon}</> : data} spaceSm>
      { element }
    </CustomTooltip>
  );
}

export default LetterCircle;
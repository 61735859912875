import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ClickAwayListener } from '@material-ui/core';

import CustomTooltip from './CustomTooltip';

const CustomMultipleSelect = ({ options, onChange, active, formEl, label, className, dropdownClassToCheck, showArrow, disabled = false, multipleSelectedText, thickBorder, onFocus, onBlur }) => {
  const [selected, setSelected] = useState(
    active.length > 1 
      ? `${active.length} ${multipleSelectedText}` 
      : active.length === 1 ? active[0].label : '');
  const [selectedVal, setSelectedVal] = useState(
    active.length > 1 
      ? active.map(a => a.value) 
      : active.length === 1 ? [active[0].value] : []);
  const [showDropdown, setShowDropdown] = useState(false);
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setSelected(active.length > 1 
      ? `${active.length} ${multipleSelectedText}` 
      : active.length === 1 ? active[0].label : '');
    setSelectedVal(active.length > 1 
      ? active.map(a => a.value) 
      : active.length === 1 ? [active[0].value] : []);
  }, [active]);

  // On focus/blur - when dropdown is opened/closed
  useEffect(() => {
    if(showDropdown && onFocus && !focused) {
      onFocus()
      setFocused(true)
    }
    if(!showDropdown && onBlur && focused) {
      onBlur()
      setFocused(false)
    }
  }, [showDropdown, onFocus, onBlur, focused])

  // On option change
  const optionChangeHandler = (option) => {
    if(option.value === '') {
      const optionCopy = {...option};
      optionCopy.active = true;
      setSelected(option.label);
      setSelectedVal([]);
      onChange([], optionCopy);
    }else {
      if(option.active || active.find(opt => opt.value === option.value)) {
        const optionCopy = {...option};
        optionCopy.active = false;
        const activeArr = active.filter(o => o.value !== option.value);
        // setSelected(activeArr.length > 1 ? `${active.length} ${multipleSelectedText}` : active[0].label);
        setSelected(activeArr.length > 1 
          ? `${activeArr.length} ${multipleSelectedText}` 
          : activeArr.length === 1 ? activeArr[0].label : '');
        const arr = [...selectedVal].filter(v => v !== option.value);
        setSelectedVal(arr);
        onChange(arr, optionCopy);
      }else {
        const optionCopy = {...option};
        optionCopy.active = true;
        // setSelected(active ? active.length > 0 ? `${active.length} ${multipleSelectedText}` : option.label : '');
        setSelected(active.length > 1 
          ? `${active.length} ${multipleSelectedText}` 
          : active.length === 1 ? active[0].label : '');
        const arr = selectedVal ? [...selectedVal, optionCopy.value] : [optionCopy.value];
        setSelectedVal(arr);
        onChange(arr, optionCopy);
      }
    }
  }

  let selectClass = 'custom-select';

  if(formEl) {
    selectClass += ' custom-select--form';
  }
  if(disabled) {
    selectClass += ' custom-select--disabled';
  }
  if(className) {
    selectClass += ` ${className}`;
  }

  let selectedOptClass = 'custom-select__selected';

  if(showDropdown) {
    selectedOptClass += ' opened';
  }
  if(thickBorder) {
    selectedOptClass += ' custom-select__selected--thick-border';
  }

  return(
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className={selectClass}>
        {formEl && label && <div className="custom-select__label">{label}</div>}
        <div className={selectedOptClass} onClick={() => setShowDropdown(!showDropdown)}>
          {active.length === 1 
            ? <div className="text">{selected}</div> 
            : <CustomTooltip content={active.map((v, i) => (<span className="multi-option" key={i}>{v.label}</span>))}><div className="text">{selected}</div></CustomTooltip>
          }
          {formEl && <div className="icon"><KeyboardArrowDownIcon /></div>}
          {showArrow && <div className="custom-select__selected_arrow"><ArrowDropDownIcon /></div>}
        </div>
        {showDropdown && !disabled && <div className="dropdown-el select-dropdown">
          <ul>
            {options?.map((opt, i) => {
              let liClass = '';

              if(opt.active || active.find(o => opt.value === o.value)) {
                liClass = 'active';
              }
              if(opt.withBorder) {
                liClass += ' with-border';
              }

              return <li key={i} onClick={() => optionChangeHandler(opt)} className={liClass}>{opt.label}</li>
            }
            )}
          </ul>
        </div>}
      </div>
    </ClickAwayListener>
  );
}

export default CustomMultipleSelect;
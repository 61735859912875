import { GET_TEAMS, SET_TEAMS, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: action.payload,
        teamsFetched: true,
      }
    case SET_TEAMS:
      return {
        ...state,
        teams: action.payload
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
import { useContext, forwardRef, useCallback, useState, useEffect } from 'react'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { ContactsContext, ColumnsContext, GlobalContext, VariablesContext, TeamContext } from '../../../context'

const ResizableContactColumn = forwardRef(({ column, onMouseDown, onSort, idx }, ref) => {
  const { contactsSort, contactsSelectedFilters } = useContext(ContactsContext)
  const { contactsTableColumnsWidths } = useContext(ColumnsContext)
  const { t } = useContext(GlobalContext)
  const { variables, variablesFetched } = useContext(VariablesContext)
  const { selectedTeam, updateTeam } = useContext(TeamContext)
  const [hideDropdown, setHideDropdown] = useState(false)
  const [hideVarsDropdown, setHideVarsDropdown] = useState(false)
  const [showLabelInput, setShowLabelInput] = useState(false)
  const [columnLabel, setColumnLabel] = useState('')
  const [selectedVariable, setSelectedVariable] = useState(t('contacts.select_variable'))
  const [search, setSearch] = useState('')
  const [filteredVariables, setFilteredVariables] = useState([])
  const [linkedVariables, setLinkedVariables] = useState({})

  let columnStyle = {
    width: column.value === 'helper' ? '100%' : contactsTableColumnsWidths[column.value] || 200 
  }

  // Set selected variable
  useEffect(() => {
    if(selectedTeam && selectedTeam.contacts_linked_variables) {
      const vars = selectedTeam.contacts_linked_variables
      setLinkedVariables(vars)
      if(vars[contactsSelectedFilters.group] && vars[contactsSelectedFilters.group].hasOwnProperty(column.value)) {
        setSelectedVariable(vars[contactsSelectedFilters.group][column.value])
      }else {
        setSelectedVariable(t('contacts.select_variable'))
      }
    }
    if(selectedTeam && selectedTeam.contacts_custom_columns && selectedTeam.contacts_custom_columns.hasOwnProperty(column.value)) {
      setColumnLabel(column.label)
      setShowLabelInput(true)
    }
  }, [selectedTeam, contactsSelectedFilters, column, t])

  // Set filtered variables
  useEffect(() => {
    if(variablesFetched) {
      setFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
    }
  }, [variables, variablesFetched])

  // On search change
  const handleSearchChange = useCallback((e) => {
    const { value } = e.target
    setSearch(value)
    if(value.trim()) {
      let processedValue = value.trim().toLowerCase()
      let filtered = []
      variables.forEach(v => {
        if(v.variable.toLowerCase().includes(processedValue)) {
          filtered.push(v)
        }
      })
      setFilteredVariables(filtered)
    }else {
      setFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
    }
  }, [variables])

  // On variable select
  const handleVariableSelect = useCallback((e, v) => {
    e.preventDefault()
    setSelectedVariable(v)
    setSearch('')
    setFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
    setHideVarsDropdown(true)
    setTimeout(() => setHideVarsDropdown(false), 50)
  }, [variables])

  // On link variable
  const handleLinkVariable = useCallback(async () => {
    let linkedVars = {...linkedVariables}
    if(selectedVariable !== t('contacts.select_variable')) {
      linkedVars[contactsSelectedFilters.group] = { ...linkedVars[contactsSelectedFilters.group], [column.value]: selectedVariable }
    }
    // console.log(linkedVars)
    let teamObj = { contacts_linked_variables: linkedVars }
    if(showLabelInput && columnLabel.trim() && columnLabel.trim() !== column.label) {
      let customColumns = {...selectedTeam.contacts_custom_columns}
      customColumns[column.value] = columnLabel.trim() 
      teamObj.contacts_custom_columns = customColumns
    }
    await updateTeam(teamObj, selectedTeam.id, true)
    setLinkedVariables(linkedVars)
    document.body.click()
  }, [contactsSelectedFilters.group, column, columnLabel, linkedVariables, selectedTeam, selectedVariable, showLabelInput, updateTeam, t])

  // On unlink variable
  const handleRemoveLinkedVariable = useCallback(async () => {
    let linkedVars = {...linkedVariables}
    delete linkedVars[contactsSelectedFilters.group][column.value] 
    await updateTeam({ contacts_linked_variables: linkedVars }, selectedTeam.id, true)
    setLinkedVariables(linkedVars)
    // console.log(linkedVars)
    setSelectedVariable(t('contacts.select_variable'))
    document.body.click()
  }, [contactsSelectedFilters.group, column, linkedVariables, selectedTeam, updateTeam, t])

  // Check if button should be disabled
  const isButtonDisabled = useCallback(() => {
    let disabled = false
    if(showLabelInput) {
      if(columnLabel.trim() === column.label && selectedVariable === t('contacts.select_variable')) disabled = true
    }else {
      if(selectedVariable === t('contacts.select_variable')) disabled = true
    }
    if(!contactsSelectedFilters.group) disabled = true
    return disabled
  }, [contactsSelectedFilters.group, column, columnLabel, selectedVariable, showLabelInput, t])

  // On cancel click
  const handleCancelClick = useCallback(() => {
    setHideDropdown(true)
    setTimeout(() => setHideDropdown(false), 50)
    setSearch('')
    setFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
  }, [variables])

  // Render link column to variable
  const renderLinkColumnToVariable = useCallback((column) => {
    return (
      <div className="link-column-to-variable">
        <ActionsDropdown
          trigger={<button><span className="material-symbols-outlined">expand_more</span></button>}
          hide={hideDropdown}
          headTitle={column.label}
        >
          <div className="link-variable-section">
            <div className="link-variable-section__body">
              {showLabelInput && (
                <div className="edit-custom-field-wrapper">
                  <input type="text" value={columnLabel} onChange={(e) => setColumnLabel(e.target.value)} />
                </div>
              )}
              <ActionsDropdown
                trigger={
                  <a href="/#" onClick={(e) => e.preventDefault()} className="trigger-btn">
                    <span className="text">{selectedVariable}</span> 
                    <span className="icon"><span className="material-symbols-outlined">arrow_drop_down</span></span>
                  </a>
                }
                hideHeader
                hide={hideVarsDropdown}
              >
                <div className="link-variable-search">
                  <input placeholder={t('dashboard.search')} value={search} onChange={handleSearchChange} />
                </div>
                <div className="link-variable-list u-custom-scrollbar">
                  <ul>
                    {filteredVariables.map((v, idx) => (
                      <li key={idx}>
                        <div className="link link--small">
                          <a href="/#" onClick={(e) => handleVariableSelect(e, v.variable)}>{v.variable}</a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </ActionsDropdown>
            </div>
            <div className="link-variable-section__foot">
              <button className="btn btn--small btn--outline cancel-btn" onClick={handleCancelClick}>{t('general.cancel')}</button>
              <button className="btn btn--small" onClick={handleLinkVariable} disabled={isButtonDisabled()}>{t('contacts.link_variable')}</button>
              {linkedVariables[contactsSelectedFilters.group] && linkedVariables[contactsSelectedFilters.group][column.value] && (
                <button className="btn btn--danger btn--small remove-btn" onClick={handleRemoveLinkedVariable}>{t('contacts.unlink_variable')}</button>
              )}
            </div>
          </div>
        </ActionsDropdown>
      </div>
    )
  }, [contactsSelectedFilters.group, columnLabel, filteredVariables, handleLinkVariable, handleCancelClick, handleRemoveLinkedVariable, handleSearchChange, handleVariableSelect, hideDropdown, hideVarsDropdown, isButtonDisabled, linkedVariables, search, selectedVariable, showLabelInput, t])

  return (
    <div 
      className={`list-view-table__cell ${column.value === 'first_name' || column.value === 'helper' ? 'title-col filtered-out' : 'draggable-item'} contact-cell`} 
      key={idx} 
      style={columnStyle}
      ref={ref}
    >
      {column.value !== 'helper' ? (
        <p onClick={() => onSort(column.value)} className={contactsSort.value === column.value ? 'sort active-sort' : 'sort'}>
          <span className="material-symbols-outlined icon">{column.icon}</span>
          <span className="text">{column.label}</span>
          {contactsSort.value === column.value && (
            <>
              {contactsSort.order === 'desc' && <span className="material-symbols-outlined arrow">arrow_upward</span>}
              {contactsSort.order === 'asc' && <span className="material-symbols-outlined arrow">arrow_downward</span>}
            </>
          )}
        </p>
      ) : (
        <div>&nbsp;</div>
      )}
      {idx !== 0 && column.value !== 'helper' && <div className="resize-handle" onMouseDown={() => onMouseDown(idx, column.value)}></div>}
      {idx === 1 && <div className="resize-handle resize-handle--before" onMouseDown={() => onMouseDown(0, 'first_name')}></div>}
      {column.value !== 'helper' && renderLinkColumnToVariable(column)}
    </div>
  )
})

export default ResizableContactColumn
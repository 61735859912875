import firebase from 'firebase/app'
import config from '../config.json'
require("firebase/auth")
require("firebase/storage")
require("firebase/firestore")
require("firebase/messaging")

const firebaseConfig = {
  apiKey: "AIzaSyBZh1-nXeWwbZ01GLYOCIozGmR9x6WlITg",
  authDomain: "carbon-contract-management.firebaseapp.com",
  databaseURL: "https://carbon-contract-management.firebaseio.com",
  projectId: "carbon-contract-management",
  storageBucket: "carbon-contract-management.appspot.com",
  messagingSenderId: "138340818931",
  appId: "1:138340818931:web:7a1f412ea23e81ff73fc86",
  measurementId: "G-G9NW6VVGKY"
}

firebase.initializeApp(firebaseConfig)
export const firebaseEnvDoc = firebase.firestore().collection('environments').doc(config.environment)

export default firebase
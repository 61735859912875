import { useState, useEffect, useContext, memo } from 'react'
import moment from 'moment'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import { Alert } from '../../new_ui'
import { MoveSelectedItemsToFolderModal, EditTemplateModal } from '../'
import { GlobalContext, DocumentsContext, FolderContext } from '../../../context'
import { useTemplatesFoldersActions, useSingleTemplateActions, useTemplatesActions, useSingleDocumentActions } from '../../../hooks'
import { useHistory } from 'react-router-dom'

const TemplatesRow = ({ id, doc, columns, selectedTemplates, setSelectedTemplates, allChecked, refreshTemplates }) => {
  const { t } = useContext(GlobalContext)
  const { setTemplatesSelectedFilters } = useContext(DocumentsContext)
  const { setSelectedFilter, folders, selectedFilter } = useContext(FolderContext)
  const { moveTemplatesFolder, updateBreadcrumbs } = useTemplatesFoldersActions()
  const { moveTemplateToFolder, downloadTemplate, deleteSingleTemplate } = useSingleTemplateActions()
  const { moveTemplatesToFolder } = useTemplatesActions()
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const [checked, setChecked] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showMoveModal, setShowMoveModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(true)
  const history = useHistory()

  // Set isDefaultTemplate
  useEffect(() => {
    if(!doc) {
      setIsDefaultTemplate(true)
      return
    }
    setIsDefaultTemplate(doc.owner === 'carbon')
  }, [doc])

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedTemplates.length === 0) {
      setChecked(false)
    }
  }, [selectedTemplates, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked && doc.owner !== 'carbon') {
      setChecked(true)
    }
  }, [allChecked, doc])

  // On select
  const handleSelect = () => {
    setChecked(!checked)
    if(checked) {
      setSelectedTemplates(prev => prev.filter(d => d.id !== id))
    }else {
      setSelectedTemplates(prev => [...prev, doc])
    }
  }

  // Print date
  const printDate = (date) => {
    if(!date) return ''
    return moment(date).format('DD MMM YY')
  }

  // Print time
  const printTime = (date) => {
    if(!date) return ''
    return moment(date).format('HH:mm')
  }

  // On title click
  const handleTitleClick = () => {
    if(!doc.folderRow) return 
    setTemplatesSelectedFilters(prev => ({...prev, folder: doc.id }))
    setSelectedTemplates([])
    setSelectedFilter(doc.id)
    updateBreadcrumbs({ folder: doc })
  }

  // On download
  const handleDownload = async (e) => {
    e.preventDefault()
    await downloadTemplate({ tmpl: doc })
    window.document.body.click()
  }

  // On edit click
  const handleEditClick = (e) => {
    e.preventDefault()
    setShowEditModal(true)
  }

  // On edit modal close
  const handleCloseEditModal = async () => {
    setShowEditModal(false)
  }

  // On move click
  const handleMoveClick = async (e) => {
    e.preventDefault()
    setShowMoveModal(true)
  }

  // On close move modal
  const handleCloseMoveModal = () => {
    setShowMoveModal(false)
  }

  // On move
  const handleMove = async (moveTo, current) => {
    const moved = await moveTemplatesToFolder({ moveTo, current, selectedDocuments: [doc], setSelectedDocuments: setSelectedTemplates, setShowMoveMultipleDocsToFolderModal: setShowMoveModal })
    if(moved) {
      refreshTemplates()
    }
  }

  // On delete click
  const handleDeleteClick = async (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteSingleTemplate({ currentTemplate: doc, setShowDeleteTemplateAlert: setShowDeleteAlert, setCurrentTemplate: () => {} })
    refreshTemplates()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On create document
  const handleCreateDocument = async () => {
    // await createDocumentFromTemplate({ template: doc })
    history.push({
      pathname: `/template/${doc.id}`,
    })

  }

  // Render checkbox column
  const renderCheckboxColumn = () => {
    return (
      <div className={`list-view-table__cell checkbox ${isDefaultTemplate ? 'default-template' : ''}`}>
        {!checked 
          ? <span className="material-symbols-outlined checkbox-icon" onClick={handleSelect}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled checkbox-icon" onClick={handleSelect}>check_box</span>
        }
        {doc.folderRow 
          ? <span className="material-symbols-outlined row-icon filled">folder</span>
          : <span className="material-symbols-outlined row-icon">description</span>
        }
      </div>
    )
  }

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined">more_horiz</span></button>}
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/" onClick={handleDownload}>
              <span className="icon"><span className="material-symbols-outlined">download</span></span>
              {t('general.download')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleEditClick}>
              <span className="icon"><span className="material-symbols-outlined">edit</span></span>
              {t('general.rename')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleMoveClick}>
              <span className="icon"><span className="material-symbols-outlined">drive_file_move_outline</span></span>
              {t('general.move')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleDeleteClick} className="delete">
              <span className="icon"><span className="material-symbols-outlined">delete</span></span>
              {t('general.remove')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render title column
  const renderTitleColumn = (col, i) => {
    return (
      <div 
        className={`list-view-table__cell u-truncate-text-wrapper title-col ${isDefaultTemplate ? 'default-template' : ''}`} 
        key={i}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <p className={`text-style-1 u-truncate-text ${doc.folderRow ? 'title' : ''}`} onClick={handleTitleClick}>{doc.name}</p>
        {!doc.folderRow && <div className="show-on-hover show-on-hover--2">
          <button className="btn btn--small" onClick={handleCreateDocument}>{t('general.create_document')}</button>
          {!isDefaultTemplate && renderSingleDocActions()}
        </div>}
      </div>
    )
  }

  // Render date column
  const renderDateColumn = (col, i) => {
    const date = col.value === 'meta.created' ? printDate(doc.meta.created) : printDate(doc.meta.updated)
    const time = col.value === 'meta.created' ? printTime(doc.meta.created) : printTime(doc.meta.updated)
    const content = (
      <div className="u-truncate-text">
        <CustomTooltip content={<span>{time}</span>}>
          <span className="normal">{ date }</span>
        </CustomTooltip>
      </div>
    )

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper date-col" key={i}>
        { content }
      </div>  
    )
  }

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'name') {
      content = renderTitleColumn(col, i)
    }else if(col.value === 'meta.created') {
      content = renderDateColumn(col, i, t('dashboard.created_on'))
    }else if(col.value === 'meta.updated') {
      content = renderDateColumn(col, i, t('dashboard.updated_on'))
    }else if(col.value === 'helper') {
      content = renderHelperColumn(i)
    }

    return content
  }

  // Drag start
  const handleDragStart = (e) => {
    const div = document.createElement('div')
    if(doc.folderRow) {
      div.id = 'draggable-folder-el'
    }else {
      div.id = 'draggable-document-el'
    }
    div.className = 'draggable-ghost-el-v2'
    div.innerText = t('folder.move', { folder: doc.name })
    document.getElementById('root').appendChild(div)
    if(doc.folderRow) {
      e.dataTransfer.setData('folder', JSON.stringify(doc))
    }else {
      e.dataTransfer.setData('template', JSON.stringify(doc))
    }
    e.dataTransfer.setDragImage(div, 0, 0)
  }

  // Drag end
  const handleDragEnd = e => {
    if(doc.folderRow) {
      document.getElementById('draggable-folder-el').remove()
    }else {
      document.getElementById('draggable-document-el').remove()
    }
  }

  // Drag over
  const handleDragOver = (e) => {
    e.preventDefault()
    if(!doc.folderRow) return 
    const box = e.target.closest('.folder-row')
    if(!box) return 
    if(!box.classList.contains('hover')) {
      box.classList.add('hover')
    }
  } 
  
  // Drag Leave
  const handleDragLeave = (e) => {
    e.preventDefault()
    if(!doc.folderRow) return 
    const box = e.target.closest('.folder-row')
    if(!box) return 
    if(box.classList.contains('hover')) {
      box.classList.remove('hover')
    }
  }

  // On drop
  const handleDrop = async (e) => {
    if(!doc.folderRow) return 
    
    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null

    const folderBox = e.target.closest('.folder-row')
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover')
    }

    if(data) {
      await moveTemplatesFolder({ selectedFolder: data }) 
      refreshTemplates()
      return
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null;

    if(templateData) {
      await moveTemplateToFolder({ template: templateData, folder: doc, setMoved: () => {} })
      refreshTemplates()
    }
  }
  
  return (
    <>
      <div 
        className={`list-view-table__row ${checked ? 'selected' : ''} ${doc.folderRow ? 'folder-row' : ''}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {columns.filter(c => c.checked).map((col, i) => {
          return renderColumnContent(col, i)
        })} 
        {renderCheckboxColumn()}   
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_template')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}

      {showMoveModal && (
        <MoveSelectedItemsToFolderModal  
          onClose={handleCloseMoveModal} 
          folders={folders}
          items={[doc]}
          onMove={handleMove}
          currentFolder={selectedFilter === 'all' ? null : [...folders].find(f => f.id === selectedFilter)}
          view="templates"
        />
      )}

      {showEditModal && (
        <EditTemplateModal onClose={handleCloseEditModal} currentTemplate={doc} refreshTemplates={refreshTemplates} />
      )}
    </>
  )
}

export default memo(TemplatesRow)
import { useContext } from 'react'
import moment from 'moment'

import { GlobalContext } from '../../../context'

const InvoicesList = ({ invoices, onPayNow, onInvoiceAction }) => {
  const { t } = useContext(GlobalContext)

  // Render date
  const renderDate = (date, type = 'date') => {
    date *= 1000
    switch (type) {
      case 'date':
        return moment(date).format('DD/MM/YYYY')
      case 'datetime':
        return moment(date).format('DD/MM/YYYY HH:mm')
      case 'time':
        return moment(date).format('HH:mm')
      default:
        return ''
    }
  }

  // open url in new tab
  const openUrl = (url) => {
    window.open(url, '_blank')
  }

  // Render invoice
  const renderInvoice = (invoice) => {
    return (
      <div className="invoice-row" key={`invoice_${invoice.id}`}>
        <div className={`invoice-col invoice-col__status ${invoice.status}`}>
          { t(`membership.invoice_status_${invoice.status}`) }
        </div>
        <div className="invoice-col invoice-col__description">
          { t(`membership.invoice_${invoice.billing_reason}`) }
        </div>
        <div className="invoice-col invoice-col__number">
          { invoice.number || `${t('membership.invoice_draft')}` }
        </div>
        <div className="invoice-col invoice-col__date">
          { renderDate(invoice.created) }
        </div>
        <div className="invoice-col invoice-col__amount">
          { `${printPrice(invoice.amount_due)}` }
        </div>

        { renderInvoiceActions(invoice) }
      </div>
    )
  }

  // Render invoice actions
  const renderInvoiceActions = (invoice) => {
    const { status } = invoice
    let content = null
    if(status === 'draft') {
      content = invoice.next_payment_attempt && <p>{ t('membership.finalizing') }</p>
    } else if(status === 'open') {
      content = <div className="actions-col">
        { invoice.action_required ? <button className="btn" onClick={() => { onInvoiceAction(invoice)}}>{t('membership.action_required')}</button> :
        <button className="btn" onClick={() => { onPayNow(invoice)}}>{t('membership.pay_now')}</button> }
        { invoice.next_payment_attempt ? <p>{ t('membership.next_payment_attempt', { date: renderDate(invoice.next_payment_attempt) }) }</p> : null }
      </div>
    } else {
      content = (
        <>
          { invoice.hosted_invoice_url && <button className="btn btn--small btn--primary-light" onClick={() => openUrl(invoice.hosted_invoice_url)}>{t('membership.view_invoice')}</button> }
          { invoice.invoice_pdf && <button className="btn btn--small btn--primary-light" onClick={() => openUrl(invoice.invoice_pdf)}>{t('membership.download_invoice')}</button> }
        </>
      )
    }
    return (
      <div className="invoice-col invoice-col__actions">
        { content }
      </div>
    )
  }

  const printPrice = (amount) => {
    if(typeof amount !== 'number') {
      return ''
    }
    amount = amount / 100
    // print two decimal places if amount is not a whole number
    if(amount % 1 !== 0) {
      return `${(amount).toFixed(2)}€`
    }
    return `${amount}€`
  }


  return (
    <div>
      { invoices.sort((a,b) => {
        if(a.created > b.created) return -1
        if(a.created < b.created) return 1
        return 0
      }).map(renderInvoice) }
    </div>
  )
}

export default InvoicesList
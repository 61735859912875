import React, { useContext, useEffect, useRef, useState } from 'react' 
import { DeleteOutline, Done, InsertLinkOutlined, SearchOutlined } from '@material-ui/icons'
import QRCode from 'qrcode.react'

import Alert from '../../UI/Alert'
import Button from '../../UI/Button'
import CustomTooltip from '../../UI/CustomTooltip'
import IconButton from '../../UI/IconButton'
import Input from '../../UI/Input'
import Loader from '../../UI/Loader'
import Modal from '../../UI/Modal'
import Switch from '../../UI/Switch'
import TagsInput from '../../UI/TagsInput'
import Textarea from '../../UI/Textarea'
import { UserContext, DocumentsContext, NotificationContext, TeamContext, GlobalContext } from '../../../context'
import { email_template } from '../../../helpers/email_templates'
import { validateEmail } from '../../../helpers/validate'
import { update_team } from '../../../services/firestore'
import { sendDocumentByEmail } from '../../../services/functions'
import { create_shared_document, delete_shared_document, update_shared_document } from '../../../services/shared_documents'
import { checkIfArrayOfStringsChanged } from '../../../utils'

const ShareModal = ({ onClose, doc, sharedDoc, setSharedDoc }) => {
  const { t } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { getTemplateById, updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam, updateTeam } = useContext(TeamContext)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [emails, setEmails] = useState([])
  const [emailsError, setEmailsError] = useState(false)
  const [mode, setMode] = useState('invite')
  const [tab, setTab] = useState('url')
  const [allowComments, setAllowComments] = useState(true)
  const [email, setEmail] = useState('')
  const [showTextarea, setShowTextarea] = useState(false)
  const [note, setNote] = useState('')
  const [sharedDocId, setSharedDocId] = useState(doc.shared_document || null)
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [showDeleteLinkAlert, setShowDeleteLinkAlert] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [sendMailTo, setSendMailTo] = useState([])
  const [search, setSearch] = useState('')
  const updateTimeout = useRef(null)

  // Set users array when team is ready 
  useEffect(() => {
    if(selectedTeam) {
      let arr = selectedTeam.users.map((u) => ({ name: u.first_name || '', email: u.email, id: u.id }))
      if(selectedTeam.external_users) {
        selectedTeam.external_users.forEach(u => arr.push({ name: '', email: u, id: '' }))
      }
      let ownerIndex = -1
      for(let i = 0; i < arr.length; i++) {
        if(arr[i].id === user.id) {
          ownerIndex = i
          break
        }
      }
      if(ownerIndex !== -1) {
        let owner = arr[ownerIndex]
        arr.splice(ownerIndex, 1)
        arr.unshift({...owner, isOwner: true })
      }
      let filtered = []
      if(search.trim() !== '') {
        const val = search.trim().toLowerCase()
        arr.forEach(u => {
          if(u.name.toLowerCase().includes(val) || u.email.toLowerCase().includes(val)) {
            filtered.push(u)
          }
        })
        setFilteredUsers(filtered)
        return 
      }
      setFilteredUsers(arr)
    }
  }, [selectedTeam, search, user]) 

  // Tags input change handler
  const tagsChangeHandler = (name, tags) => {
    setEmails(tags)
    if(emailsError) {
      setEmailsError(false)
    }
  }

  // On share button click
  const shareDocumentHandler = async () => {
    if(!doc) return 

    const template = getTemplateById(doc.template)

    if(!doc.uploaded && !template) return 

    let users_emails = [...new Set([...sendMailTo, ...emails])]

    if(users_emails.length === 0) return setNotification({ msg: t('notification.add_emails'), type: 'danger' })

    // create shared document - if document does not have it already
    let shareId = doc.shared_document
    let tempSharedDoc
    if(!shareId || (shareId && !sharedDoc)) {
      console.log('create shared doc*** from share modal send')
      const { id, doc } = await createSharedDocument(template)
      shareId = id
      tempSharedDoc = doc
    }
    
    if(!shareId) return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })

    // send link to emails
    let subject = t('share_email.shared_a_doc', { name: user.first_name })
    let message = `
      <p>${t('share_email.head')},</p>
      <p>${t('share_email.text_share', { document: doc.name })}</p>
      <p>${t('share_email.text_2')}</p>
      <p>${t('share_email.foot')},</p>
    `
    let emailHtml = email_template(message, `${window.location.origin}/share/${shareId}`, t('share_email.check_document'), 'share-doc')
    try {
      await sendDocumentByEmail(user.email, users_emails, subject, emailHtml, doc.name)
      // update team - add external users if emails array not empty so that next time these emails are in the list of users 
      let external_users = []
      if(selectedTeam.external_users) external_users = [...selectedTeam.external_users]
      if(emails.length > 0) {
        external_users = [...new Set([...external_users, ...emails])]
        // console.log(selectedTeam.id)
      }
      
      if(checkIfArrayOfStringsChanged(selectedTeam.external_users || [], external_users)) {
        await update_team({ external_users: external_users }, selectedTeam?.id)
        updateTeam({ external_users: external_users }, selectedTeam?.id)
      }

      let sharedWith = []
      let sharedDocument = sharedDoc || tempSharedDoc
      if(sharedDocument.shared_with) {
        sharedWith = [...sharedDocument.shared_with]
      }
      sharedWith = [...new Set([...sharedWith, ...emails, ...sendMailTo])]
      // console.log(sharedWith)
      // console.log(sharedDocument.shared_with)
      if(checkIfArrayOfStringsChanged(sharedDocument.shared_with || [], sharedWith)) {
        await update_shared_document(shareId, { shared_with: sharedWith, updated_at: Date.now() })
      }

      setNotification({ msg: t('notification.message_sent'), type: 'success' })
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    } finally {
      onClose()
    }
  }

  // Create shared document
  const createSharedDocument = async (template) => {
    let data = {
      comments: [],
      comments_allowed: true,
      document: doc.id,
      owner: user.email,
      templateUrl: doc.uploaded ? doc.documentUrls.pdf || doc.documentUrls.docx : template.templateUrl,
      values: doc.values || {},
      created: Date.now(),
      updated_at: Date.now(),
      doc_uploaded: !!doc.uploaded,
      doc_format: doc.uploadedFormat || '',
      templateId: template?.id || null,
      name: doc.name || '',
      team: selectedTeam?.id,
      team_members: selectedTeam?.users_emails,
      shared_with: [],
    }

    const id = await create_shared_document(data)
    // update document - add shared_document prop so that next time user opens share modal shared document does not have to be created
    await updateDocument({ shared_document: id, create_action: 'no' }, doc)
    setSharedDocId(id)
    setSharedDoc(data)
    console.log(id)
    return { id, doc: data }
  }

  // On tab link click
  const tabLinkClickHandler = (e, current) => {
    e.preventDefault()
    setTab(current)
  }

  // Copy to clipboard handler
  const copyToClipboardHandler = async (e) => {
    e && e.preventDefault()
    if(isGenerating) return

    let shareId = doc.shared_document
    if(!shareId || (shareId && !sharedDoc)) {
      console.log('create shared doc*** from copy to clipboard')
      setIsGenerating(true)
      const template = getTemplateById(doc.template)
      const { id } = await createSharedDocument(template)
      shareId = id
    }

    if(!shareId) return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })

    return copyToClipboardHelper(shareId)
  }

  // Copy to clipboard helper
  const copyToClipboardHelper = (id) => {
    const input = document.createElement('textarea')
    input.innerHTML = `${window.location.origin}/share/${id}`
    document.body.appendChild(input)
    input.select()
    const result = document.execCommand('copy')
    document.body.removeChild(input)
    setIsCopiedToClipboard(true)
    setNotification({ msg: t('share_email.link_copied'), type: 'success' })
    setIsGenerating(false)
    setTimeout(() => {
      setIsCopiedToClipboard(false)
    }, 3000);
    return result
  }

  // Open in new window handler
  const openInNewWindowHandler = (e) => {
    e.preventDefault()
    let url = `${window.location.origin}/share/${sharedDocId}`
    window.open(url, '', 'width=960,height=960')
  }
  
  // Add personal note click handler
  const addPersonalNoteClickHandler = (e) => {
    e.preventDefault()
    setShowTextarea(true)
  }

  // On send button click
  const sendDocumentHandler = async () => {
    if(!doc) return 

    const template = getTemplateById(doc.template)

    if(!doc.uploaded && !template) return

    if(!email || !validateEmail(email.trim())) return setNotification({ msg: t('notification.invalid_email_2'), type: 'danger' })

    let shareId = doc.shared_document
    if(!shareId) return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })

    // send link to email
    let subject = t('share_email.shared_a_doc', { name: user.first_name })
    let message = `
      <p>${t('share_email.head')},</p>
      <p>${t('share_email.text_share', { document: doc.name })}</p>
      <p>${t('share_email.text_2')}</p>
      <p>${t('share_email.foot')},</p>
    `
    if(note) {
      message = `
      <p>${t('share_email.head')},</p>
      <p>${t('share_email.text_share', { document: doc.name })}</p>
      <p>${t('share_email.text_2')}</p>
      <p>${note.trim()}</p>
      <p>${t('share_email.foot')},</p>
    `
    }
    let emailHtml = email_template(message, `${window.location.origin}/share/${shareId}`, t('share_email.check_document'), 'share-doc')
    try {
      await sendDocumentByEmail(user.email, email, subject, emailHtml, doc.name)
      let external_users = []
      if(selectedTeam.external_users) external_users = [...selectedTeam.external_users]
      external_users = [...new Set([...external_users, email])]
      // console.log(selectedTeam.id)
      if(checkIfArrayOfStringsChanged(selectedTeam.external_users || [], external_users)) {
        await update_team({ external_users: external_users }, selectedTeam?.id)
        updateTeam({ external_users: external_users }, selectedTeam?.id)
      }

      let sharedWith = []
      if(sharedDoc.shared_with) {
        sharedWith = [...sharedDoc.shared_with]
      }
      sharedWith = [...new Set([...sharedWith, email])]
      // console.log(sharedWith)
      // console.log(sharedDoc.shared_with)
      if(checkIfArrayOfStringsChanged(sharedDoc.shared_with || [], sharedWith)) {
        await update_shared_document(shareId, { shared_with: sharedWith, updated_at: Date.now() })
      }

      // await sendEmail(user.email, email, subject, emailHtml, doc.name)
      setNotification({ msg: t('notification.message_sent'), type: 'success' })
    } catch (err) {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    } finally {
      onClose()
    }
  }

  // Link settings click handler
  const linkSettingsClickHandler = (e) => {
    e.preventDefault()
    setMode('share')
    if(!sharedDocId || (sharedDocId && !sharedDoc)) {
      console.log('create shared doc *** from link settings')
      const template = getTemplateById(doc.template)
      createSharedDocument(template)
    }else if(sharedDoc){
      setAllowComments(sharedDoc.comments_allowed)
    }
  }

  // Delete shared link
  const deleteSharedLinkHandler = async () => {
    setDeleting(true)
    try {
      await delete_shared_document(sharedDocId)
      await updateDocument({ shared_document: null, create_action: 'no' }, doc)
      setNotification({ msg: t('notification.link_deleted'), type: 'success' })
    } catch (err) {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }finally {
      setDeleting(false)
      setShowDeleteLinkAlert(false)
      onClose()
    }
  }

  // On allow comments change 
  const allowCommentsChangeHandler = (e) => {
    if(updateTimeout.current) {
      clearTimeout(updateTimeout.current)
    }
    setAllowComments(e.target.checked)
    updateTimeout.current = setTimeout(() => {
      update_shared_document(sharedDocId, { comments_allowed: e.target.checked, updated_at: Date.now() })
    }, 800)
  }

  // Update send mail to
  const updateSendMailTo = (userEmail) => {
    setSendMailTo(prev => {
      if(prev.includes(userEmail)) {
        return prev.filter(email => email !== userEmail)
      }else {
        return [...prev, userEmail]
      }
    })
  }

  // Search users
  const searchUsersHandler = (e) => {
    const { value } = e.target
    setSearch(value)
  }

  // On modal close
  const modalCloseHandler = () => {
    if(mode === 'invite') {
      onClose()
    }else {
      setMode('invite')
    }
  }

  // Render invite mode content
  const renderInviteModeContent = () => {
    return (
      <>
        <div className="share-modal__body">
          <div className="search-users">
            <SearchOutlined />
            <input type="text" value={search} onChange={searchUsersHandler} placeholder={t('share_email.search')} />
          </div>
          <div className="list-of-users u-custom-scrollbar">
            {filteredUsers.length > 0 ? filteredUsers.map((u, idx) => {
              let firstLetter 
              if(u.name) firstLetter = u.name.slice(0,1)
              else if(u.email) firstLetter = u.email.slice(0,1)

              let added = sendMailTo.includes(u.email)

              return (
                <div className={`user-box ${added ? 'user-box--added' : ''}`} key={idx}>
                  <div className="user-box__thumb">{firstLetter}</div>
                  <div className="user-box__info">
                    <p className="name">{u.name}</p>
                    <p className="email">{u.email}</p>
                  </div>
                  <div className="user-box__action">
                    {!u.isOwner ? <Button 
                      text={doc.owner === u.id ? added ? t('general.remove_2') : t('general.author') : added ? t('general.remove_2') : t('general.add')} 
                      onButtonClick={() => updateSendMailTo(u.email)} 
                      outlineLight 
                      small 
                    /> : <span className='text-style-4'>{t('general.author')}</span> }
                  </div>
                </div>
              )
            }) : (
              <div className="no-users">
                <div className="no-users__icon">
                  <SearchOutlined />
                </div>
                <div className="no-users__text">
                  <h3>{t('share_email.no_results')}</h3>
                  <p>{t('share_email.check_search')}</p>
                </div>
              </div>
            )}
          </div>
          <div className="share-by-email">
            <TagsInput 
              placeholder={t('share_email.add_email')}
              name="emails"
              onChange={tagsChangeHandler}
              emailValidation 
              defaultTags={emails}
              invalid={emailsError}
            />
          </div>
          <div className="btn-wrapper">
            <Button primary text={t('general.share')} onButtonClick={shareDocumentHandler} medium />
          </div>
        </div>
        <div className="share-modal__foot">
          <div className="share-modal__foot_left">
            <p>
              <InsertLinkOutlined />
              {t('share_email.share_via')}&nbsp;
              <CustomTooltip content={!sharedDocId ? t('share_email.generate_and_copy_to_clipboard') : t('share_email.copy_to_clipboard')}>
                <a href="/#" onClick={copyToClipboardHandler}>{t('share_email.public_link')}</a>
              </CustomTooltip>
            </p>
          </div>
          <div className="share-modal__foot_right">
            <a href="/#" onClick={linkSettingsClickHandler}>{t('share_email.link_settings')}</a>
          </div>
        </div>
      </>
    )
  }

  // Render share mode content
  const renderShareModeContent = () => {
    if(!sharedDocId) {
      return(
        <div className="share-modal__body">
          <div className="loader-wrapper">
            <Loader primary normal small />
          </div>
        </div>
      )
    }

    return (
      <div className="share-modal__body">
        <ul className="share-modal__tabs">
          <li className={tab === 'url' ? 'active' : ''}><a href="/#" onClick={(e) => tabLinkClickHandler(e, 'url')}>{t('share_email.url')}</a></li>
          <li className={tab === 'email' ? 'active' : ''}><a href="/#" onClick={(e) => tabLinkClickHandler(e, 'email')}>{t('auth.email')}</a></li>
          <li className={tab === 'qr' ? 'active' : ''}><a href="/#" onClick={(e) => tabLinkClickHandler(e, 'qr')}>{t('share_email.qr_code')}</a></li>
          <li className={tab === 'manage' ? 'active' : ''}><a href="/#" onClick={(e) => tabLinkClickHandler(e, 'manage')}>{t('share_email.manage')}</a></li>
        </ul>
        <div className="share-modal__tabs-content">
          {tab !== 'qr' && tab !== 'manage' && <ul className="share-modal__tabs-content_options">
            <li>
              <p>{t('share_email.allow_comments')}</p>
              <Switch onChange={allowCommentsChangeHandler} checked={allowComments} />
            </li>
          </ul>}
          {tab === 'url' && (
            <div className="content-wrapper">
              <p>{t('share_email.copy_url')}</p>
              <div className="content-wrapper__inner">
                <Input value={`${window.location.origin}/share/${sharedDocId}`} disabled formEl />
                <Button text={isCopiedToClipboard ? <span><Done /></span> : t('general.copy')} onButtonClick={copyToClipboardHandler} primary medium />
              </div>
              <p><a href="/#" onClick={openInNewWindowHandler}>{t('share_email.open_in_new_window')}</a></p>
            </div>
          )}
          {tab === 'email' && (
            <div className="content-wrapper">
              <p>{t('share_email.email_url')}</p>
              <div className="content-wrapper__inner">
                <Input value={email} onChange={(e) => setEmail(e.target.value)} formEl />
                <Button text={t('general.send_to')} onButtonClick={sendDocumentHandler} primary medium />
              </div>
              {!showTextarea && <p><a href="/#" onClick={addPersonalNoteClickHandler}>{t('share_email.add_personal_note')}</a></p>}
              {showTextarea && (
                <Textarea value={note} onChange={(e) => setNote(e.target.value)} placeholder={t('share_email.add_personal_note')} formEl />
              )}
            </div>
          )}
          {tab === 'qr' && (
            <div className="qr-wrapper">
              <p>{t('share_email.scan_qr_code')}</p>
              <QRCode 
                  value={`${window.location.origin}/share/${sharedDocId}`}
                  size={290} 
                  imageSettings={{ 
                    width: 290, 
                    height: 290,
                    src: `${window.location.origin}/share/${sharedDocId}`
                  }}
                />
            </div>
          )}
          {tab === 'manage' && (
            <div className="content-wrapper content-wrapper--2">
              <div className="action">
                <p>{t('share_email.delete_link')}</p>
                <IconButton icon={<DeleteOutline />} onButtonClick={() => setShowDeleteLinkAlert(true)} danger medium />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (  
    <Modal onClose={modalCloseHandler} medium3 noPadding>
      <div className="share-modal">
        <div className="share-modal__head">
          <h2>{t('share_email.title_2')}</h2>
        </div>
        {mode === 'invite' ? renderInviteModeContent() : renderShareModeContent()}
      </div>

      {showDeleteLinkAlert && <Alert 
        onClose={() => setShowDeleteLinkAlert(false)} 
        onSubmit={deleteSharedLinkHandler}
        text={t('alert.are_you_sure')}
        deleteAlert
        loading={deleting}
      />}
    </Modal>
  )
}

export default ShareModal
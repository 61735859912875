import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'react-cookies';
import { CARBON_COOKIE, CARBON_PAGINATION_LS, CARBON_SORT_LS, CARBON_SELECTED_TEAM_LS, CARBON_TEAM_PAGINATION_LS, CARBON_TEAM_COLUMNS_LS, CARBON_TEAM_COLUMNS_ORDER_LS, CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS, CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS, CARBON_TEAM_SORT_LS, CARBON_TEAM_VARS_COLUMNS_WIDTHS_LS, CARBON_TEAM_TEMPLATES_COLUMNS_WIDTHS_LS, CARBON_TEAM_SIGNATURES_COLUMNS_WIDTHS_LS, CARBON_TEAM_APPROVALS_COLUMNS_WIDTHS_LS, CARBON_TEAM_SHARED_DOCS_COLUMNS_WIDTHS_LS } from '../constants';
import config from '../config.json';

const sortedArrayFromObject = (obj, sorter) => {
  let arr = []
  for(let key in obj) {
    let item = obj[key]
    item.id = key
    arr.push(item)
  }
  if(sorter) {
    arr.sort(sorter)
  }
  return arr
}

const sorterWithPathAndOrder = (path, order) => (a,b) => {
  const splitted = path.split('.');
  let valA = a[splitted[0]] 
  let valB = b[splitted[0]]
  if(valA === undefined) valA = ''
  if(valB === undefined) valB = ''
  if(!valA && path.includes('__arr')) {
    valA = 0
  }
  if(!valB && path.includes('__arr')) {
    valB = 0
  }

  if(splitted.length === 1) {
    if(typeof valA === 'string' && typeof valB === 'string') {
      if(valA.toLowerCase() < valB.toLowerCase()) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA.toLowerCase() > valB.toLowerCase()) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }else {
      if(valA < valB) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA > valB) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }
  }else {
    if(splitted[1].includes('__arr')) {
      const newPath = splitted[1].split('__arr');
      if(valA[0][newPath[0]] < valB[0][newPath[0]]) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA[0][newPath[0]] > valB[0][newPath[0]]) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }else {
      if(valA[splitted[1]] < valB[splitted[1]]) return -1 * (order === 'asc' ? -1 : 1)
      else if(valA[splitted[1]] > valB[splitted[1]]) return 1 * (order === 'asc' ? -1 : 1)
      else return 0
    }
  }
}

const sortArrayOfObjects = (arr, value, order) => {
  if(!value) {
    return arr
  }
  const splitted = value.split('.');
  return arr.sort((a, b) => {
    let valA = splitted.length === 1 ? a[splitted[0]] : a[splitted[0]] ? a[splitted[0]][splitted[1]] : '';
    let valB = splitted.length === 1 ? b[splitted[0]] : b[splitted[0]] ? b[splitted[0]][splitted[1]] : '';
    
    if(valA === undefined) valA = ''
    if(valB === undefined) valB = ''
    if(typeof valA === 'string' && valB === 'string') {
      if(valA.toLowerCase() < valB.toLowerCase()) return -1 * (order === 'desc' ? 1 : -1)
      else if(valA.toLowerCase() > valB.toLowerCase()) return 1 * (order === 'desc' ? 1 : -1)
      else return 0
    }else {
      if(valA < valB) return -1 * (order === 'desc' ? 1 : -1)
      else if(valA > valB) return 1 * (order === 'desc' ? 1 : -1)
      else return 0
    }
  });
}

const getCreatedAtFromDocuments = (documents) => {
  const arr = [];
  for(let key in documents) {
    const value = documents[key].meta ? documents[key].meta.created : null;
    if(value) {
      const label = moment(value).format('MMMM YYYY');
      const start = moment(value).startOf('month').valueOf();
      const end = moment(value).endOf('month').valueOf();
      arr.push({ value, label, start, end });
    }
  }
  // return only unique objects
  return arr.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label))===i);
}

// const dateValueFormat = 'YYYY-MM-DD[T]HH:mmZZ'
const dateValueFormat = 'DD/MM/YYYY'
const dateTimeValueFormat = 'MMM DD, YYYY HH:mm'

const urlSuffixForEnvironment = (environment, staging) => {
  if(environment === 'production' && staging) {
    return '_stg'
  }
  switch(environment) {
    case 'development':
      return '_dev'
    default:
      return ''
  }
}

const getAllParentFolders = (allFolders, folder, folders = []) => {
  if(!folder) {
    return folders;
  }
  const parentFolderId = folder.parentFolder;
  if(parentFolderId) {
    const pF = [...allFolders].find(f => f.id === parentFolderId);
    return pF ? getAllParentFolders(allFolders, pF, [...folders, pF]) : [];
  }else {
    return folders;
  }
}

const folderHasSubfolders = (folders, folder) => {
  return !!folders.find(f => f.parentFolder === folder.id);
}

const folderHasTemplates = (templates, folder) => {
  const arr = [];
  for(let key in templates) {
    arr.push({ ...templates[key], id: key });
  }
  return !!arr.find(t => !t.deleted && t.folderId && t.folderId.includes(folder.id));
}

const getFirstLevelSubfolders = (folders, folder) => {
  return folder ? [...folders].filter(f => f.parentFolder === folder.id) : [...folders].filter(f => f.parentFolder === null || f.parentFolder === undefined);
}

const isOverflown = (el) => {
  if(el) {
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = el;
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  }else {
    return false;
  }
}

const convertToTemplateObjWithUniqueVarIndexes = (obj) => {
  const copyOfTemplate = {...obj};
  if(!copyOfTemplate.sections) return 
  const tmplSections = [...copyOfTemplate.sections];
  const updatedSections = tmplSections.map((s, i) => {
    const section = {...s};
    if(section.variable) {
      section.idx = `${section.variable}-${uuidv4()}`;
      return section;
    }else if(section.variables) {
      section.variables = [...section.variables].map((v => ({...v, idx: `${v.variable}-${uuidv4()}`})));
      return section;
    }else {
      return section;
    }
  });
  copyOfTemplate.sections = updatedSections;
  return copyOfTemplate;
}

// Get last X days array
const getLastXDaysArr = (numOfDays) => {
  return [...new Array(numOfDays)].map((d, idx) => moment().startOf("day").subtract(idx, "days")).reverse();
}

// Get days in month array
const getDaysInMonthArr = () => {
  let daysInMonth = moment().daysInMonth();
  const arrDays = [];

  while(daysInMonth) {
    const current = moment().date(daysInMonth);
    arrDays.push(current);
    daysInMonth--;
  }

  return arrDays.reverse();
}

// Get all months in year
const getAllMonths = () => {
  const months = moment.months();
  return [...months].map(function(m,i){ return moment().month(i) });
}

// Get dates between two dates
const getDatesBetween = function(startDate, endDate) {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).endOf('day');
  dates.unshift(currDate.clone());
  
  while(currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(moment(currDate.clone().toDate()));
  }
  
  return dates;
};

// Save cookie
const acceptCookies = (obj) => {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 730) // expire in 2 years
  cookie.save(
    CARBON_COOKIE, 
    JSON.stringify(obj), 
    { 
      path: '/', 
      expires: expires
    }
  );
}

// Check if cookie exist
const isCookieAccepted = () => {
  return cookie.load(CARBON_COOKIE);
}

// Read file
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader(); 
    reader.onload = () => {
      resolve(reader.result);
    }; 
    reader.onerror = reject; 
    reader.readAsArrayBuffer(file);
  })
}

// Convert base64 to blob
const base64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

// Blob to base64
const blobToBase64 = async (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

// Check if data changed TODO - check only for field that is active(changing), add new param fieldKey 
const didObjectChanged = (newObj, oldObj, exclude = [], innerExclude = []) => {
  if(Object.keys(newObj).length !== Object.keys(oldObj).length) {
    for(let key in newObj) {
      if(!exclude.includes(key)) {
        const innerVal = newObj[key];
        const oldInnerVal = oldObj[key];
        if(innerVal === Object(innerVal)) { // check if object
          if(!Array.isArray(innerVal)) { // if not array 
            for(let innerKey in innerVal) {
              if(!innerExclude.includes(`${innerVal}.${innerKey}`)) {
                if(oldInnerVal) {
                  if(innerVal[innerKey] !== oldInnerVal[innerKey]) {
                    // console.log('not equal, inner object values not equal');
                    return true;
                  }
                }else {
                  if(innerVal[innerKey] !== '') {
                    // console.log('not equal, inner object value not empty');
                    return true;
                  }
                }
              }
            }
          }else { // array
            // if(!exclude.includes(key)) {
              if(oldInnerVal && oldInnerVal.length > innerVal.length) {
                return true;
              }else {
                for(let i = 0; i < innerVal.length; i++) {
                  if(!oldInnerVal || !oldInnerVal.includes(innerVal[i]) || oldInnerVal.length !== innerVal.length) {
                    // console.log('equal, array is different')
                    return true;
                  }
                }
              }
            // }
          }
        }else {
          if(oldInnerVal && innerVal !== oldInnerVal) {
            return true;
          }else if(!oldInnerVal && innerVal !== '') {
            return true;
          }
        }
      }
    }
  }else {
    for(let key in newObj) {
      if(!exclude.includes(key)) {
        const innerVal = newObj[key];
        const oldInnerVal = oldObj[key];
        if(innerVal === Object(innerVal)) { // check if object
          if(!Array.isArray(innerVal)) { // if not array 
            for(let innerKey in innerVal) {
              if(!innerExclude.includes(`${key}.${innerKey}`)) {
                if(innerVal[innerKey] !== oldInnerVal[innerKey]) {
                  // console.log('equal, inner object values not equal');
                  return true;
                }
              }
            }
          }else {
            // if(!exclude.includes(key)) {
              if(oldInnerVal && oldInnerVal.length > innerVal.length) {
                return true;
              }else {
                for(let i = 0; i < innerVal.length; i++) {
                  if(!oldInnerVal || !oldInnerVal.includes(innerVal[i]) || oldInnerVal.length !== innerVal.length) {
                    // console.log('equal, array is different')
                    return true;
                  }
                }
              }
            // }
          }
        }else {
          if(oldInnerVal && innerVal !== oldInnerVal) {
            return true;
          }else if(!oldInnerVal && innerVal !== '') {
            return true;
          }
        }
      }
    }
  }

  return false;
}

// Blob to file
const blobToFile = (blob, name) => {
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return blob;
}

// Get file data
const getFileData = (file, callback) => {
  if (file) {
    var reader = new FileReader();

    reader.onload = (e) => {
      let components = file.name.split('.')
      const format = components[components.length - 1]
      components.splice(components.length - 1, 1)
      const name = components.join('.')
      const type = file.type
      callback({ data: e.target.result, name: name, format: format, type: type })
    }
    reader.onerror = (err) => {
      console.log('reader on error', err)
    }
    reader.readAsDataURL(file);
  }
}

// Filter documents helper
const filterDocumentsHelper = (data, filters, templateIncludes) => {
  const filtersToCheck = {};

  for(let key in filters) {
    if(filters[key] !== '' && key !== 'folder') {
      filtersToCheck[key] = filters[key];
    }
  }
  const filterKeys = Object.keys(filtersToCheck);
  // console.log(filterKeys, filtersToCheck)
  let filtered = {};

  if(filters.folder) {
    for(let key in data) {
      if(data[key].folderId && data[key].folderId.includes(filters.folder)) {
        if(filterKeys.length > 0) {
          for(let i = 0; i < filterKeys.length; i++) {
            const fKey = filterKeys[i];
            if(fKey === 'date_before') {
              if(data[key].meta.created < filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'date_after') {
              if(data[key].meta.created > filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'name') {
              let searchPhrase = filters[fKey];
              const searchedDocument = data[key];
              const docName = searchedDocument.name.toLowerCase();
              if(docName.includes(searchPhrase)) {
                filtered[key] = data[key];
              }else {
                let added = false
                for(let valueKey in searchedDocument.values) {
                  if(searchedDocument.values[valueKey].toLowerCase().includes(searchPhrase)) {
                    filtered[key] = data[key];
                    added = true
                    break
                  }
                }
                if(!added && templateIncludes(searchedDocument.template, searchPhrase)) {    
                  filtered[key] = data[key]   
                  added = true
                }
                if(!added && filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else {
              if(data[key][fKey] === filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }
          }
        }else {
          filtered[key] = data[key];
        }
      }
    }
  }else {
    for(let key in data) {
      if((data[key].folderId && data[key].folderId.length === 0) || data[key].folderId === undefined) {
        if(filterKeys.length > 0) {
          for(let i = 0; i < filterKeys.length; i++) {
            const fKey = filterKeys[i];
            if(fKey === 'date_before') {
              if(data[key].meta.created < filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'date_after') {
              if(data[key].meta.created > filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else if(fKey === 'name') {
              let searchPhrase = filters[fKey];
              const searchedDocument = data[key];
              const docName = searchedDocument.name.toLowerCase();
              if(docName.includes(searchPhrase)) {
                filtered[key] = data[key];
              }else {
                let added = false
                for(let valueKey in searchedDocument.values) {
                  if(typeof searchedDocument.values[valueKey] === 'string' && searchedDocument.values[valueKey].toLowerCase().includes(searchPhrase)) {
                    filtered[key] = data[key];
                    added = true
                    break
                  }
                }
                if(!added && templateIncludes(searchedDocument.template, searchPhrase)) {    
                  filtered[key] = data[key]   
                  added = true
                }
                if(!added && filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }else {
              if(data[key][fKey] === filters[fKey]) {
                filtered[key] = data[key];
              }else {
                if(filtered[key]) {
                  delete filtered[key];
                }
                break;
              }
            }
          }
        }else {
          filtered[key] = data[key];
        }
      }
    }
  }
  
  return filtered;
}

// Filter folders helper
const filterFoldersHelper = (data, filters) => {
  const filtersToCheck = {};

  for(let key in filters) {
    if(!!filters[key]) {
      filtersToCheck[key] = filters[key];
    }
  }
  if(!filters.parentFolder) {
    filtersToCheck.parentFolder = null;
  }
  const filterKeys = Object.keys(filtersToCheck);
  // console.log(filterKeys, filtersToCheck)
  let filtered = {};
  data.forEach(item => {
    if(filterKeys.length > 0) {
      for(let i = 0; i < filterKeys.length; i++) {
        const fKey = filterKeys[i];
        if(fKey === 'date_before') {
          if(item.meta.created < filters[fKey]) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }else if(fKey === 'date_after') {
          if(item.meta.created > filters[fKey]) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }else if(fKey === 'name') {
          if(item[fKey].includes(filters[fKey])) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }else {
          if(item[fKey] === filters[fKey]) {
            filtered[item.id] = item;
          }else {
            if(filtered[item.id]) {
              delete filtered[item.id];
            }
            break;
          }
        }
      }
    }else {
      filtered[item.id] = item;
    }
  });
  let arr = [];
  for(let key in filtered) {
    arr.push(filtered[key]);
  }
  
  return arr;
}

const availableOn = (arr) => {
  let environment = config.staging ? 'staging' : config.environment
  return arr && arr.includes(environment);
}

const areSectionConditionsMet = (section, values) => {
  if((!section.conditions || section.conditions?.length === 0) && !section.condition) {
    return true
  } else if(section.conditions?.length > 0) {
    for(let i in section.conditions) {
      let cond = section.conditions[i]
      if(!isConditionMet(cond, values)) {
        return false
      }
    }
    return true
  } else {
    return isConditionMet(section.condition, values)
  }
}

const isConditionMet = (cond, values) => {
  let targetValue = cond.value
  let sourceValue = values[cond.variable]
  if(typeof targetValue === 'number') {
    sourceValue = parseFloat(sourceValue)
  } 
  if(cond.relation === 'EQ') {
    return sourceValue === targetValue
  } else if(cond.relation === 'NE') {
    return sourceValue !== targetValue
  } else if(cond.relation === 'GT') {
    return sourceValue > targetValue
  } else if(cond.relation === 'LT') {
    return sourceValue < targetValue
  } else if(cond.relation === 'IN') {
    if(!sourceValue) {
      sourceValue = []
    }
    if(targetValue instanceof Array) {
      let checkedCount = 0
      for(let tv of targetValue) {
        if(sourceValue.includes(tv)) {
          checkedCount++
        }
      }
      return checkedCount === targetValue.length
    } else {
      return sourceValue.includes(targetValue)
    }
  }
}

const isObjectEmpty = (obj, exclude = []) => {
  let empty = true;
  let breakLoop = false;
  for(let key in obj) {
    if(!exclude.includes(key) && !breakLoop) {
      if(obj[key] === Object(obj[key])) {
        for(let innerKey in obj[key]) {
          if(obj[key][innerKey] && obj[key][innerKey] !== '') {
            empty = false;
            breakLoop = true;
          }
        }
      }else { 
        if(obj[key] && obj[key] !== '') {
          empty = false;
          breakLoop = true;
        }
      }
    }
  }
  return empty;
}

const numberOfValuesInObj = (obj, exclude = []) => {
  let number = 0;
  for(let key in obj) {
    if(!exclude.includes(key)) {
      if(obj[key] === Object(obj[key])) {
        let breakLoop = false;
        if(Array.isArray(obj[key])) {
          let arrLength = obj[key].length;
          number += arrLength;
        }else {
          for(let innerKey in obj[key]) {
            if(obj[key][innerKey] && obj[key][innerKey] !== '' && !breakLoop) {
              number++;
              breakLoop = true;
            }
          }
        }
      }
    }
  }
  return number;
}

const EVENT_TYPES = {
  DOCUMENT_CREATE: 'document_create',
  DOCUMENT_DELETE: 'document_delete',
  DOCUMENT_DOWNLOAD: 'document_download',
  DOCUMENT_DOWNLOAD_PDF: 'document_download_pdf',
  DOCUMENT_DOWNLOAD_DOCX: 'document_download_docx',
  DOCUMENT_PREVIEW: 'document_preview'
}

const checkIfTeamChanged = (newObj, oldObj) => {
  let changed = false 
  let specificKeys = ['cards_order', 'document_lists', 'users', 'users_emails']

  if(Object.keys(newObj).length !== Object.keys(oldObj).length) {
    return true
  }

  for(let key in newObj) {
    let val = newObj[key]
    let oldVal = oldObj[key]
    if(!oldObj.hasOwnProperty(key)) {
      changed = true
    }
    if(!changed) {
      if(!specificKeys.includes(key)) {
        if(val !== oldVal) {
          changed = true
        }
      }else {
        if(key === 'cards_order') {
          if(Object.keys(newObj[key]).length !== Object.keys(oldObj[key]).length) {
            changed = true 
          }else {
            for(let col in newObj[key]) {
              if(!oldObj[key][col]) {
                changed = true
              }else {
                if(newObj[key][col].order.length !== oldObj[key][col].order.length) {
                  changed = true
                }else {
                  for(let i = 0; i < newObj[key][col].order.length; i++) {
                    if(newObj[key][col].order[i] !== oldObj[key][col].order[i]) {
                      changed = true
                      break
                    }
                  }
                }
              }
            }
          }
        }else if(key === 'document_lists') {
          if(newObj[key].length !== oldObj[key].length) {
            changed = true 
          }else {
            for(let i = 0; i < newObj[key].length; i++) {
              if(newObj[key][i] !== oldObj[key][i]) {
                changed = true
                break
              }
            }
          }
        }else if(key === 'users_emails') {
          if(newObj[key].length !== oldObj[key].length) {
            changed = true 
          }else {
            for(let i = 0; i < newObj[key].length; i++) {
              if(!oldObj[key].includes(newObj[key][i])) {
                changed = true
                break
              }
            }
          }
        }
      }
    }
  }

  return changed
}

// Save pagination data to LS
const savePaginationDataToLS = (numOfItemsToShow, currentPage, all, type) => {
  const paginationLS = localStorage.getItem(CARBON_PAGINATION_LS)
  if(paginationLS) {
    const paginationObj = JSON.parse(paginationLS)
    let obj = {...paginationObj}
    obj[type] = { items: numOfItemsToShow, current: currentPage, all }
    localStorage.setItem(CARBON_PAGINATION_LS, JSON.stringify(obj))
  }else {
    let obj = {}
    obj[type] = { items: numOfItemsToShow, current: currentPage, all }
    localStorage.setItem(CARBON_PAGINATION_LS, JSON.stringify(obj))
  }
}

// Save team pagination data to LS
const saveTeamPaginationDataToLS = (teamId, numOfItemsToShow, currentPage, all, type) => {
  const lsKey = CARBON_TEAM_PAGINATION_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    if(obj[teamId]) {
      obj[teamId][type] = { items: numOfItemsToShow, current: currentPage, all }
    }else {
      obj[teamId] = {
        [type]: { items: numOfItemsToShow, current: currentPage, all }
      }
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: {
        [type]: { items: numOfItemsToShow, current: currentPage, all }
      }
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team columns data to LS - column names which whould be visible in the list view
const saveTeamColumnsDataToLS = (teamId, data, type) => {
  const lsKey = CARBON_TEAM_COLUMNS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    if(obj[teamId]) {
      obj[teamId][type] = data
    }else {
      obj[teamId] = {
        [type]: data
      }
    }
    if(data.length === 0) {
      if(obj[teamId] && obj[teamId][type]) delete obj[teamId][type] 
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }else {
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }else {
    if(data.length > 0) {
      let obj = {
        [teamId]: {
          [type]: data
        }
      }
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }
}

// Save team columns order data to LS 
const saveTeamColumnsOrderDataToLS = (teamId, data, type) => {
  const lsKey = CARBON_TEAM_COLUMNS_ORDER_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    if(obj[teamId]) {
      obj[teamId][type] = data
    }else {
      obj[teamId] = {
        [type]: data
      }
    }
    if(data.length === 0) {
      if(obj[teamId] && obj[teamId][type]) delete obj[teamId][type] 
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }else {
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }else {
    if(data.length > 0) {
      let obj = {
        [teamId]: {
          [type]: data
        }
      }
      localStorage.setItem(lsKey, JSON.stringify(obj))
    }
  }
}

// Save team documents column widths to LS
const saveTeamDocsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team contacts column widths to LS
const saveTeamContactsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team variables column widths to LS
const saveTeamVarsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_VARS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team templates column widths to LS
const saveTeamTemplatesColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_TEMPLATES_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team signatures column widths to LS
const saveTeamSignaturesColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_SIGNATURES_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team approvals column widths to LS
const saveTeamApprovalsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_APPROVALS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Save team shared documents column widths to LS
const saveTeamSharedDocumentsColumnWidthsToLS = (teamId, data) => {
  const lsKey = CARBON_TEAM_SHARED_DOCS_COLUMNS_WIDTHS_LS
  const dataLS = localStorage.getItem(lsKey)
  if(dataLS) {
    const dataObj = JSON.parse(dataLS)
    let obj = {...dataObj}
    obj[teamId] = data
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: data
    }
    localStorage.setItem(lsKey, JSON.stringify(obj))
  }
}

// Get carbon pagination data from LS
const getPaginationData = () => {
  const paginationLS = localStorage.getItem(CARBON_PAGINATION_LS)
  return JSON.parse(paginationLS)
}

// Get carbon pagination data from LS
const getTeamPaginationData = () => {
  const paginationLS = localStorage.getItem(CARBON_TEAM_PAGINATION_LS)
  return JSON.parse(paginationLS)
}

// Get carbon columns data from LS
const getTeamColumnsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_COLUMNS_LS)
  return JSON.parse(dataLS)
}

// Get carbon columns order data from LS
const getTeamColumnsOrderData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_COLUMNS_ORDER_LS)
  return JSON.parse(dataLS)
}

// Get carbon contacts columns widths data from LS
const getTeamContactsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon vars columns widths data from LS
const getTeamVarsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_VARS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon documents columns widths data from LS
const getTeamDocsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon templates columns widths data from LS
const getTeamTemplatesColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_TEMPLATES_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon signatures columns widths data from LS
const getTeamSignaturesColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_SIGNATURES_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon approvals columns widths data from LS
const getTeamApprovalsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_APPROVALS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Get carbon shared documents columns widths data from LS
const getTeamSharedDocumentsColumnWidthsData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_SHARED_DOCS_COLUMNS_WIDTHS_LS)
  return JSON.parse(dataLS)
}

// Remove carbon columns data from LS
const removeTeamColumnsData = () => {
  localStorage.removeItem(CARBON_TEAM_COLUMNS_LS)
}

// Remove carbon columns order data from LS
const removeTeamColumnsOrderData = () => {
  localStorage.removeItem(CARBON_TEAM_COLUMNS_ORDER_LS)
}

// Remove carbon contacts columns widts data from LS
const removeTeamContactsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamContactsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_CONTACTS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon contacts columns widts data from LS
const removeTeamVarsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamVarsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_VARS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon docs columns widts data from LS
const removeTeamDocsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamDocsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_DOCS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon templates columns widts data from LS
const removeTeamTemplatesColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamTemplatesColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_TEMPLATES_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon signatures columns widts data from LS
const removeTeamSignaturesColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamSignaturesColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_SIGNATURES_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon approvals columns widts data from LS
const removeTeamApprovalsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamApprovalsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_APPROVALS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Remove carbon shared documents columns widts data from LS
const removeTeamSharedDocumentsColumnWidthsData = (teamId) => {
  if(teamId) {
    const data = getTeamSharedDocumentsColumnWidthsData()
    if(data && data[teamId]) {
      let obj = {...data}
      delete obj[teamId] 
      localStorage.setItem(CARBON_TEAM_SHARED_DOCS_COLUMNS_WIDTHS_LS, JSON.stringify(obj))
    }
  }
}

// Save sort to LS
const saveSortingToLS = (value, order, type) => {
  const sortLS = localStorage.getItem(CARBON_SORT_LS)
  if(sortLS) {
    const sortingObj = JSON.parse(sortLS)
    let obj = {...sortingObj}
    obj[type] = { value, order }
    localStorage.setItem(CARBON_SORT_LS, JSON.stringify(obj))
  }else {
    let obj = {}
    obj[type] = { value, order }
    localStorage.setItem(CARBON_SORT_LS, JSON.stringify(obj))
  }
}

// Save sort to LS
const saveTeamSortingToLS = (teamId, value, order, type) => {
  if(!teamId) return 

  const sortLS = localStorage.getItem(CARBON_TEAM_SORT_LS)
  if(sortLS) {
    const sortingObj = JSON.parse(sortLS)
    let obj = {...sortingObj}
    if(obj[teamId]) {
      obj[teamId] = {
        ...obj[teamId],
        [type]: { value, order }
      }
    }else {
      obj[teamId] = {
        [type]: { value, order }
      }
    }
    localStorage.setItem(CARBON_TEAM_SORT_LS, JSON.stringify(obj))
  }else {
    let obj = {
      [teamId]: {
        [type]: { value, order }
      }
    }
    localStorage.setItem(CARBON_TEAM_SORT_LS, JSON.stringify(obj))
  }
}

// Get team sorting data
const getTeamSortingData = () => {
  const dataLS = localStorage.getItem(CARBON_TEAM_SORT_LS)
  return JSON.parse(dataLS)
}

// Save selected team to LS
const saveSelectedTeamToLS = (value) => {
  if(isCookieAccepted()) {
    localStorage.setItem(CARBON_SELECTED_TEAM_LS, value)
  }
}

// Get selected team from LS
const getSelectedTeamFromLS = () => {
  const dataLS = localStorage.getItem(CARBON_SELECTED_TEAM_LS)
  return dataLS
}

const hasSufficientMembership = (membership, requirement) => {
  const memberships = ['free', 'beta_partner', 'premium', 'partner']
  return memberships.indexOf(membership) >= memberships.indexOf(requirement)
}

// Get name from member id
const getNameFromMemberObj = (member) => {
  let name = ''
  if(member.first_name) name = member.first_name
  if(name && member.last_name) name = `${name} ${member.last_name}`
  if(!name && member.email) name = member.email
  return name
}

const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const capitalizeWord = (s) => {
  return `${s.substring(0, 1).toUpperCase()}${s.substring(1, s.length)}`
}

const resizedImageUrl = (url, size) => {
  if(!url.includes('https://firebasestorage.googleapis.com')) {
    return url
  }
  const components = url.split('?')
  const fileComponents = components[0].split('.')
  const extensionComponents = fileComponents.splice(fileComponents.length - 1, 1)
  let filePath = fileComponents.join('.')
  filePath += `_${size}x${size}`
  filePath += `.${extensionComponents[0]}`
  components[0] = filePath
  const resizeUrl = components.join('?')
  return resizeUrl
}

// Divide array into multiple arrays
const divideArrayToMultipleArrays = (arr, itemsPerEachArr = 10) => {
  if(!arr) return []
  if(arr && !Array.isArray(arr)) return []
  const arrCopy = [...arr]
  return new Array(Math.ceil(arrCopy.length / itemsPerEachArr)).fill().map(_ => arrCopy.splice(0, itemsPerEachArr))
}

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0')
}

// Convert milliseconds to hours and minutes
const convertMsToHM = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes

  minutes = minutes % 60

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24

  return `${padTo2Digits(hours)}h${padTo2Digits(minutes)}min`
}

// Convert bytes to sizes(kb, mb...)
const bytesToSize = (bytes) => {
  const units = ["byte", "kilobyte", "megabyte", "terabyte", "petabyte"]
  const unit = Math.floor(Math.log(bytes) / Math.log(1024))
  return new Intl.NumberFormat("en", {style: "unit", unit: units[unit], maximumFractionDigits: 1}).format(bytes / 1024 ** unit)
}

// Check if array of string changed
const checkIfArrayOfStringsChanged = (oldArr, newArr) => {
  let changed = false 
  if(!Array.isArray(oldArr) || !Array.isArray(newArr)) return false

  if(oldArr.length !== newArr.length) {
    changed = true
  }else {
    for(let i = 0; i < newArr.length; i++) {
      const s = newArr[i]
      if(!oldArr.includes(s)) {
        changed = true 
        break
      }
    }
  }

  return changed
}

// Formula helper functions
const formulaResult = (values, handle, repId, repIndex) => {

  let formula = handle.substring(3, handle.length - 2).replace(/\s/g,'')
  let { result, valid } = evaluateFormula(values, formula, repId, repIndex)  
  if(!valid) {
    return ''
  }
  const cleanedHandle = handle.substring(1, handle.length - 1).replace(/\./g, '')
  return { key: cleanedHandle, value: result }
}

const functionWords = ['sum', 'inWords']

const evaluateFormula = (values, formula, repId, repIndex) => {
  const parsed = parseFormula(formula)
  let functionIndex = -1

  for(let i = 0; i < parsed.length; i++) {
    if(functionWords.includes(parsed[i])) {
      functionIndex = i
      break
    }
  }

  while(functionIndex !== -1) {
    let body = functionBody(parsed, functionIndex + 2)
    let functionName = parsed[functionIndex]
    let val = ''
    if(functionName === 'sum') {
      val = evaluateSum(values, body.join(''), repId, repIndex)
    } else if(functionName === 'inwords') {
      val = evaluateInWords(values, body.join(''), repId, repIndex)
    }
    parsed.splice(functionIndex, body.length + 3, val)
    functionIndex = -1
    for(let i = 0; i < parsed.length; i++) {
      let component = parsed[i]
      if(functionWords.includes(component)) {
        functionIndex = i
        break
      }
    }
  }
  for(let i = 0; i < parsed.length; i++) {
    let dataValue = values[parsed[i]]
    if(dataValue) {
      parsed[i] = dataValue
    }
  }

  let result
  if(parsed.length === 1) {
    result = parsed[0]
  } else {
    result = evaluateExpression(parsed.join(' '))
  }

  return { result, valid: true }
}

const functionBody = (parsedFormula, startIndex) => {
  let openParentheses = 0
  let body = []
  for(let i = startIndex; i < parsedFormula.length; i++) {
    if(parsedFormula[i] === ')' && openParentheses === 0) {
      return body
    } else if(parsedFormula[i] === ')') {
      openParentheses--
    } else if(parsedFormula[i] === '(') {
      openParentheses++
    }
    body.push(parsedFormula[i])
  }
  return body
}

const operators = ['+', '-', '*', '/']
const parentheses = ['(', ')']

const parseFormula = (formula) => {
  let currentComponent = ''
  let components = []
  for(let i = 0; i < formula.length; i++) {
    let char = formula[i]
    if(operators.includes(char) || parentheses.includes(char)) {
      if(currentComponent) {
        components.push(currentComponent)
      }
      components.push(char)
      currentComponent = ''
      continue
    }
    currentComponent += formula[i]
  }
  if(currentComponent) {
    components.push(currentComponent)
  }
  return components
} 

const evaluateExpression = (string) => {
  const parts = string.split(' ')
  const parsed = []
  for(let p of parts) {
    let part = p.trim()
    if(part) {
      let number = parseFloat(part)
      if(!isNaN(number)) {
        parsed.push(number)
      } else {
        parsed.push(part)
      }
    }
  }
  
  while(parsed.includes('(') && parsed.includes(')')) {
    let block = []
    let blockStart
    let open = false
    for(let i = 0; i < parsed.length; i++) {
      let p = parsed[i]
      if(p === ')') {
        break
      }
      if(open) {
        block.push(p)
      }
      if(p === '(') {
        open = true
        blockStart = i
      }
    }
    let result = evaluateExpression(block.join(' '))
    parsed.splice(blockStart, block.length + 2, result)
  }

  while(parsed.includes('*') || parsed.includes('/')) {
    let operandA
    let operandB
    let operator
    let operationStart
    for(let i = 0; i < parsed.length; i++) {
      let p = parsed[i]
      if(p === '*' || p === '/') {
        operandA = parsed[i - 1]
        operandB = parsed[i + 1]
        operator = p
        operationStart = i - 1
        break
      }
    }
    let result = operator === '*' ? operandA * operandB : parseFloat(operandA / operandB)
    parsed.splice(operationStart, 3, result)
  }

  while(parsed.includes('+') || parsed.includes('-')) {
    let operandA
    let operandB
    let operator
    let operationStart
    for(let i = 0; i < parsed.length; i++) {
      let p = parsed[i]
      if(p === '+' || p === '-') {
        operandA = parsed[i - 1]
        operandB = parsed[i + 1]
        operator = p
        operationStart = i - 1
        break
      }
    }
    let result = operator === '+' ? operandA + operandB : operandA - operandB
    parsed.splice(operationStart, 3, result)
  }

  if(parsed.length === 1) {
    let number = parseFloat(parsed[0])
    if(isNaN(number)) {
      return ''
    }
    let stringToFixed = `${number.toFixed(2)}`
    let string = `${parseFloat(stringToFixed)}`
    if(string.length < stringToFixed.length) {
      return string
    } else {
      return stringToFixed
    }
  }
  return parsed.join(' ')
}

const evaluateSum = (values, body, repId, repIndex) => {
  let result = ''
  let [objectKey, propertyKey] = body.split('.')
  let data = values
  if(repId) {
    data = data[repId][repIndex]
  }
  let obj = data[objectKey]
  if(!obj) {
    return result
  }
  result = 0
  let isArea = false
  for(let i in obj) {
    let val = obj[i][propertyKey]
    if(!val) {
      continue
    }
    if(typeof val === 'number') {
      result += parseFloat(val)
    } else if(typeof val === 'string') {
      isArea = true
      if(isValueArea(val)) {
        let areaInCa = areaInCaUnits(val)
        result += areaInCa
      }
    }
  }
  if(isArea) {
    return printAreaValue(convertAreaToLargestUnits({
      ha: 0, a: 0, ca: result
    }))
  }
  return result
}

const isValueArea = (val) => {
  const areaValueRegex = /^(?:[0-9]+ha ?)?(?:[0-9]+a ?)?(?:[0-9]+ca)?$/
  return areaValueRegex.test(val)
}

const evaluateInWords = (values, body, repId, repIndex) => {
  if(body === '') {
    return ''
  }
  let number = parseFloat(body)
  if(isNaN(number)) {
    let { result, valid } = evaluateFormula(values, body, repId, repIndex)
    if(!valid) {
      return ''
    }
    number = parseFloat(result)
  }
  return numberToWordsFrench(number)
}

const numberToWordsFrench = (number) => {
  let result, iteration, iterationText, hundreds, hundredsText

  const numberString = `${number}`
  if(numberString.includes('.')) {
    let [integer, decimal] = numberString.split('.')
    let decimalInWords = ''
    // console.log("decimal", decimal)
    while(decimal[0] === '0') {
      decimalInWords += 'zéro '
      decimal = decimal.substring(1)
    }
    decimalInWords += numberToWordsFrench(parseInt(decimal))
    return `${numberToWordsFrench(parseInt(integer))} virgule ${decimalInWords}`
  }

  if(number === 0) {
    return 'zéro'
  }
  
  result = ''
  iteration = 1
  
  while(number > 0) {
    hundreds = number % 1000 
    number = Math.floor(number / 1000) 
    
    
    if(hundreds > 0) {
      // -- 3 digits to text
      hundredsText = getHundredsText(hundreds)
      if(iteration > 1) {
        // -- mille, million, …
        iterationText = getIterationText(iteration)
        hundredsText = hundredsText + iterationText
        if(hundredsText === "un mille ") {
          hundredsText = " mille "
        }
      }
      
      result = hundredsText + result
    }
    iteration = iteration + 1
  }
  
  return result.trim()
}

const getHundredsText = (number) => {
  let result, hundreds, tens
  
  result = ""
  
  hundreds = Math.floor(number / 100) 
  if(hundreds > 0) {
    number = number % 100
    if(hundreds > 1) {
      // -- deux cents, trois cents, …
      result = result + getLessThanTwentyText(hundreds) + " cents"
    } else {
      result = result + "cent"
    }
  }
  
  if(number > 0) {
    if(hundreds > 0) {
      // -- space after "cent(s)"
      result = result + " "
    }
    
    if(number < 20) {
      // -- < 20 ⇒ look up
      result = result + getLessThanTwentyText(number)
    } else {
      tens = Math.floor(number / 10) 
      number =  number % 10 
      
      switch(tens) {
        case 7:
          // -- soixante dix, soixante onze, …
          result = result + "soixante"
          number = number + 10
          break
        case 8:
          if(number > 0)
            result = result + "quatre-vingt"
          else
            result = result + "quatre-vingts"
          break
        case 9:
          // -- quatre-vingt dix, quatre-vingt onze, …
          result = result + "quatre-vingt"
          number = number + 10
          break
        default:
          // -- vingt, trente, quarante, …
          result = result + getTensText(tens)
      }
      
      if(number > 0) {
        if(number === 1 && tens !== 8)
          result = result + " et un"
        else
          result = result + "-" + getLessThanTwentyText(number)

      }
    }
  }
  
  return result
}

const getLessThanTwentyText = (number) => {
  switch(number) {
    case 1: return "un"
    case 2: return "deux"
    case 3: return "trois"
    case 4: return "quatre"
    case 5: return "cinq"
    case 6: return "six"
    case 7: return "sept"
    case 8: return "huit"
    case 9: return "neuf"
    case 10: return "dix"
    case 11: return "onze"
    case 12: return "douze"
    case 13: return "treize"
    case 14: return "quatorze"
    case 15: return "quinze"
    case 16: return "seize"
    case 17: return "dix-sept"
    case 18: return "dix-huit"
    case 19: return "dix-neuf"
    default: return ''
  }
}

const getTensText = (tens) => {
  switch(tens) {
    case 2: return "vingt"
    case 3: return "trente"
    case 4: return "quarante"
    case 5: return "cinquante"
    case 6: return "soixante"
    default: return ''
  }
}

const getIterationText = (iteration) => {
  switch(iteration) {
    case 1: return ""
    case 2: return " mille "
    case 3: return " million "
    case 4: return " milliard "
    case 5: return " billion "
    case 6: return " billiard "
    case 7: return " trillion "
    case 8: return " trilliard "
    default: return " ??? "
  }
}

// Get first letter of the string
const getFirstLetter = (text) => {
  if(typeof text !== 'string') return 
  return text.slice(0, 1)
}

const areaInCaUnits = (value) => {
  let parsed = parseAreaValue(value)
  return parsed.ha * 10000 + parsed.a * 100 + parsed.ca
}

const convertAreaToLargestUnits = (parsed) => {
  while(parsed.ca > 100) {
    parsed.a += Math.floor((parsed.ca) / 100)
    parsed.ca = parsed.ca % 100
  }
  while(parsed.a > 100) {
    parsed.ha += Math.floor((parsed.a) / 100)
    parsed.a = parsed.a % 100
  }
  return parsed
}

const parseAreaValue = (value) => {
  const parsed = {
    ha: 0,
    a: 0,
    ca: 0
  }
  if(!value) {
    return parsed
  }
  value = value.replace(/s/g, '')
  if(typeof value === 'number') {
    parsed.ca = value
    return convertAreaToLargestUnits(parsed)
  }

  let parsedNumber = parseInt(value)
  if(value === `${parsedNumber}`) {
    parsed.ca = parsedNumber
    return convertAreaToLargestUnits(parsed)
  }
  let components = value.split('a')

  for(let i = 0; i < components.length; i++) {
    if(components[i].includes('h')) {
      let val = parseInt(components[i].replace(/h/g, ''))
      parsed.ha = isNaN(val) ? 0 : val
    } else if(components[i].includes('c')) {
      let val = parseInt(components[i].replace(/c/g, ''))
      parsed.ca = isNaN(val) ? 0 : val
    } else if(components[i].length > 0) {
      let val = parseInt(components[i])
      parsed.a = isNaN(val) ? 0 : val
    }
  }

  return convertAreaToLargestUnits(parsed)
}

const printAreaValue = (parsedArea) => {

  let val = ''
  if(parsedArea.ha > 0) {
    val += `${parsedArea.ha}ha`
  }
  if(parsedArea.a > 0) {
    val += val.length > 0 ? ' ' : ''
    val += `${parsedArea.a}a`
  }
  if(parsedArea.ca > 0) {
    val += val.length > 0 ? ' ' : ''
    val += `${parsedArea.ca}ca`
  }
  return val
}

const appLanguages = [
  { languageCode: 'en', labelShort: 'en' },
  { languageCode: 'fr', labelShort: 'fr' },
  { languageCode: 'nl', labelShort: 'nl' },
  { languageCode: 'it', labelShort: 'it' },
  { languageCode: 'es', labelShort: 'es' }
]

export {
  sortedArrayFromObject,
  sorterWithPathAndOrder,
  getCreatedAtFromDocuments,
  dateValueFormat,
  dateTimeValueFormat,
  urlSuffixForEnvironment,
  getAllParentFolders,
  folderHasSubfolders,
  folderHasTemplates,
  getFirstLevelSubfolders,
  sortArrayOfObjects,
  isOverflown,
  convertToTemplateObjWithUniqueVarIndexes,
  getLastXDaysArr,
  getDaysInMonthArr,
  getAllMonths,
  getDatesBetween,
  acceptCookies,
  isCookieAccepted,
  base64toBlob,
  blobToBase64,
  readFileAsync,
  didObjectChanged,
  blobToFile,
  getFileData,
  filterDocumentsHelper,
  filterFoldersHelper,
  EVENT_TYPES,
  availableOn,
  areSectionConditionsMet,
  isObjectEmpty,
  numberOfValuesInObj,
  checkIfTeamChanged,
  savePaginationDataToLS,
  saveTeamPaginationDataToLS,
  saveTeamColumnsDataToLS,
  saveTeamColumnsOrderDataToLS,
  saveTeamContactsColumnWidthsToLS,
  saveTeamDocsColumnWidthsToLS,
  getPaginationData,
  getTeamPaginationData,
  getTeamColumnsData,
  getTeamColumnsOrderData,
  getTeamContactsColumnWidthsData,
  getTeamDocsColumnWidthsData,
  removeTeamColumnsData,
  removeTeamColumnsOrderData,
  removeTeamContactsColumnWidthsData,
  removeTeamDocsColumnWidthsData,
  saveSortingToLS,
  saveTeamSortingToLS,
  getTeamSortingData,
  saveSelectedTeamToLS,
  getSelectedTeamFromLS,
  hasSufficientMembership,
  getNameFromMemberObj,
  getBase64FromFile,
  capitalizeWord,
  getTeamVarsColumnWidthsData,
  removeTeamVarsColumnWidthsData,
  saveTeamVarsColumnWidthsToLS,
  resizedImageUrl,
  divideArrayToMultipleArrays,
  saveTeamTemplatesColumnWidthsToLS,
  getTeamTemplatesColumnWidthsData,
  removeTeamTemplatesColumnWidthsData,
  saveTeamSignaturesColumnWidthsToLS,
  getTeamSignaturesColumnWidthsData,
  removeTeamSignaturesColumnWidthsData,
  convertMsToHM,
  bytesToSize,
  getTeamApprovalsColumnWidthsData,
  removeTeamApprovalsColumnWidthsData,
  saveTeamApprovalsColumnWidthsToLS,
  checkIfArrayOfStringsChanged,
  getTeamSharedDocumentsColumnWidthsData,
  saveTeamSharedDocumentsColumnWidthsToLS,
  removeTeamSharedDocumentsColumnWidthsData,
  formulaResult,
  appLanguages
}

import { useContext, useState, useEffect, useRef } from 'react'

import CustomTooltip from '../components/UI/CustomTooltip'
import Switch from '../components/UI/Switch'
import { SettingsLayout } from '../layouts'
import { Input } from '../components/new_ui'
import { GlobalContext, UserContext, NotificationContext, LoaderContext } from '../context'
import firebase from '../services/firebase'

const SecuritySettings = () => {
  const { t } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [is2FAEnabled, setIs2FAEnabled] = useState(false)
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [valid2FANumber, setValid2FANumber] = useState('')
  const [email, setEmail] = useState('')
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [emailVerificationSent, setEmailVerificationSent] = useState(false)
  const recaptchaVerifierRef = useRef(null)

  useEffect(() => {
    setupPage()
    // eslint-disable-next-line
  }, [user])

  // Setup page
  const setupPage = async () => {
    if(!user) {
      return
    }
    const currentUser = firebase.auth().currentUser
    setPhoneNumber(user.phone || user.mobile || '')
    setEmail(currentUser.email)
    setIsEmailVerified(currentUser.emailVerified)
    setValid2FANumber(currentUser.multiFactor.enrolledFactors.length > 0 ? currentUser.multiFactor.enrolledFactors[0].displayName : '')
  }

  // On recaptcha solved
  const onSolvedRecaptcha = (e) => {
  }

  // On phone number change
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  // Check if phone number is valid
  const isPhoneNumberValid = () => {

  }

  // Verify email
  const verifyEmail = async () => {
    try {
      await firebase.auth().currentUser.sendEmailVerification()
      setEmailVerificationSent(true)
    } catch(err) {
      console.log('Send verification error', err)
    }
  }

  // Enroll
  const enroll = async () => {
    setShowCaptcha(true)
    recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      callback: onSolvedRecaptcha
    })
    const widgetId = await recaptchaVerifierRef.current.render()    
    window.recaptchaWidgetId = widgetId
    
    // setLoading(true)
    try {    
      const multiFactorSession = await firebase.auth().currentUser.multiFactor.getSession()
      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      // Send SMS verification code.
      let vId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current)        
      // verificationId will be needed for enrollment completion.
      console.log('verificationId', vId)
      setVerificationId(vId)
      setShowCaptcha(false)
      setPhoneVerificationSent(true)

    } catch(err) {
      console.log(err)
      setNotification({ msg: err.message || err.code,  type: 'danger' });

      // setLoading(false)
      setShowCaptcha(false)
    }    
  }

  // Confirm verification code
  const confirmVerificationCode = async () => {
    try {
      setShowGlobalResponseLoader(true)
      console.log('verificationId ', verificationId)
      const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
      console.log(cred)
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
      console.log(multiFactorAssertion)
      await firebase.auth().currentUser.multiFactor.enroll(multiFactorAssertion, phoneNumber);
      setValid2FANumber(phoneNumber)
      setShowGlobalResponseLoader(false)
    } catch(err) {
      setShowGlobalResponseLoader(false)
      console.log('verification error', err)
    }
  }

  // Disable 2FA
  const disable2FA = async () => {
    try {
      const options = firebase.auth().currentUser.multiFactor.enrolledFactors
      await firebase.auth().currentUser.multiFactor.unenroll(options[0])
      setValid2FANumber('')
    } catch(err) {
      console.log('disable 2fa error', err)
      setNotification({ msg: err.message || err.code,  type: 'danger' })
    }
  }

  // Render email verification
  const renderEmailVerification = () => {
    return (
      <div className="security-block">
        <h2 className="heading-2">{ isEmailVerified ? t('auth.email_verified') : t('auth.email_not_verified') }</h2>
        <div className="profile-form__group">
          <Input 
            name="email"
            label={t('general.email')}
            value={email}
            disabled
            type="email"
          />
        </div>
        { !isEmailVerified && <button className="btn" onClick={verifyEmail}>{ t('auth.verify') }</button> }
        { emailVerificationSent && <p className="hint">{ t('auth.verification_email_sent') }</p>}
      </div>
    )
  }

  // Render 2FA
  const render2FA = () => {
    return (
      <div className="security-block">
        <h2 className="heading-2">{ t('auth.2fa_title') }</h2>
        { Boolean(valid2FANumber) ? (
          <div>
            <h4>{ t('auth.2fa_enabled_with_number', { number: valid2FANumber }) }</h4>
            <div className="mt-10">
              <button className="btn btn--danger" onClick={disable2FA}>{ t('auth.disable') }</button>
            </div>
          </div>
        ) : (
          <div>
            <div className="switch-wrapper">
                <p className="text-style-1">{ t('auth.enable_2fa') }</p>
                <CustomTooltip hideTooltip={isEmailVerified} position="top" content={t('auth.email_verification_required')}>
                  <div>
                    <Switch disabled={!isEmailVerified} checked={is2FAEnabled} onChange={() => { setIs2FAEnabled(!is2FAEnabled) }} />
                  </div>
                </CustomTooltip>
              </div>
            { is2FAEnabled && (
              <div className="mt-10">
                <div className="profile-form__group">
                  <Input 
                    name="phone_number"
                    label={t('general.phone')}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    error={isPhoneNumberValid()}
                    type="tel"
                  />
                </div>
                <button className="btn" onClick={enroll}>{ t('auth.enable_2fa' )}</button>
                { phoneVerificationSent && <p className="hint">{ t('auth.verification_sms_sent') }</p>  }
                { phoneVerificationSent && <div className="profile-form__group row">
                  <Input 
                    name="sms_code"
                    label={t('auth.enter_verification_code')}
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    formEl
                    type="text"
                  />
                  <button className="btn" onClick={confirmVerificationCode}>{ t('general.confirm') }</button>
                </div> }
            </div> ) }
        </div> ) }
        <div className={`${!showCaptcha ? 'visibility-hidden' : ''}`}>
          <span>{ t('auth.complete_captcha') }</span>
          <div ref={recaptchaVerifierRef} id="recaptcha-container"></div>
        </div>
      </div>
    )
  }

  return (
    <SettingsLayout title={t('auth.security')}>
      <div className="profile-form">
        {user ? ( <div>
          { renderEmailVerification() }
          { render2FA() }
        </div> ) : <div className="loader-v2"></div>}
      </div>
    </SettingsLayout> 
  )
}

export default SecuritySettings 
import React, { useState, useRef, useContext, useEffect, useCallback } from 'react'

import { Modal, Textarea } from '../new_ui'
import { NotificationContext, GlobalContext } from '../../context'
import { useSupportActions } from '../../hooks'

const ReportABugModal = ({ onClose }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { reportBug } = useSupportActions()
  const [bugMsg, setBugMsg] = useState('')
  const [url, setUrl] = useState('')
  const [files, setFiles] = useState([])
  const [textareaFocused, setTextareaFocused] = useState(false)
  const fileEl = useRef()
  const dragAndDropEl = useRef()

  // On file input change
  const handleFileChange = (e) => {
    const files = e.target.files 
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    let error = false
    Array.from(files).forEach(file => {
      if(!allowedTypes.includes(file.type)) {
        error = true
      }
    })
    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
    }
    setFiles(Array.from(files))
  }

  // On upload image button click
  const uploadImgBtnClickHandler = () => {
    if(fileEl.current) {
      fileEl.current.click()
    }
  }

  // On drag enter
  const dragEnterHandler = (e) => {
    e.preventDefault()
  }
  
  // On drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.remove('mouse-over')
  }
  
  // On drag over
  const dragOverHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.add('mouse-over')
 
  }

  // On drop
  const dropHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.remove('mouse-over')
    const files = e.dataTransfer.files
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    let error = false
    Array.from(files).forEach(file => {
      if(!allowedTypes.includes(file.type)) {
        error = true
      }
    })
    if(error) {
      return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
    }
    setFiles(Array.from(files))
    setFiles(Array.from(files))
  }

  // On submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    await reportBug({ bugMsg, url, setUrl, files, onClose })
  }, [bugMsg, files, onClose, url, reportBug])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter' && !textareaFocused) {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit, textareaFocused])

  return (
    <Modal 
      onClose={onClose} 
      title={t('support.report_bug')} 
      titleIcon={<span className="material-symbols-outlined size-24">bug_report</span>} 
      onSave={handleSubmit} 
      saveBtnText={t('support.send_bug_report')}
    >
      <div className="report-a-bug-v2">
        <div className="report-a-bug-v2__body">
          <form onSubmit={handleSubmit}>
            <Textarea 
              label={t('support.describe_bug')} 
              value={bugMsg} 
              onChange={(e) => setBugMsg(e.target.value)} 
              onFocus={() => setTextareaFocused(true)}
              onBlur={() => setTextareaFocused(false)}
            />
            <div className="upload-section">
              <button type="button" className="btn btn--with-icon-left btn--full btn--fw-500 btn--with-letter-spacing" onClick={uploadImgBtnClickHandler}>
                <span className="material-symbols-outlined size--24">upload_file</span> 
                {t('support.upload_images')}
              </button>
              <div 
                className="upload-section__dnd" 
                ref={dragAndDropEl}
                onDragEnter={dragEnterHandler}
                onDragLeave={dragLeaveHandler}
                onDragOver={dragOverHandler}
                onDrop={dropHandler}
              >
                <input type="file" className="hidden" onChange={handleFileChange} multiple ref={fileEl} />
                <p>{t('support.or_dnd_image')} {files.length > 0 && `(${files.length} ${t('support.images_selected')})`}</p>
                {files.length > 0 && <p><a href="/#" onClick={(e) => { e.preventDefault(); setFiles([])}}>{t('support.remove_images')}</a></p>}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
} 

export default ReportABugModal
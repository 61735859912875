import { useContext, useEffect, useState, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { TasksView } from '../components/misc'
import { GlobalContext, TaskContext, TeamContext, DocumentsContext } from '../context'
import { sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'

const TasksPage = () => {
  const { t } = useContext(GlobalContext)
  const { notCompletedTasks, tasksFetched, fetchTasks, tasks, tasksSort, setTasksSort } = useContext(TaskContext)
  const { teamChanged, setTeamChanged, selectedTeam, getUserNameById } = useContext(TeamContext)
  const { documentsLoaded, fetchDocs, getDocumentById } = useContext(DocumentsContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [activeTasks, setActiveTasks] = useState([])
  const [currentTasks, setCurrentTasks] = useState([])
  const [ready, setReady] = useState(false)
  const [paginatedData, setPaginatedData] = useState([])

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch tasks if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!tasksFetched) {
        fetchTasks(teamId) 
      }
      if(!documentsLoaded) {
        fetchDocs(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, tasksFetched, fetchTasks, documentsLoaded, fetchDocs])

  // Set tasks
  const setupTasks = useCallback(async () => {
    let active = [...tasks]
    active = active.map(task => {
      let updatedTask = {...task}
      let doc = getDocumentById(task.relatedDocument)
      if(task.relatedDocument && doc) {
        updatedTask.sort_document = doc.name
      }else {
        updatedTask.sort_document = ''
      }
      let userName = getUserNameById(task.assigned_to)
      if(task.assigned_to && userName) {
        updatedTask.sort_user = userName
      }else {
        updatedTask.sort_user = ''
      }
      return updatedTask
    })
    let sorted = await sortArrayOfObjects(active, tasksSort.value, tasksSort.order)
    setActiveTasks([...sorted])
    setCurrentTasks([...sorted])
    setReady(true)
  }, [tasks, tasksSort, getDocumentById, getUserNameById])

  // Setup tasks when all data is fetched - only once, on component mount
  useEffect(() => {
    if(tasksFetched && documentsLoaded && !ready) {
      setupTasks()
    }
  }, [tasksFetched, setupTasks, ready, documentsLoaded])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
  }, [])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeTasks]

    let sorted = sortArrayOfObjects(filtered, tasksSort.value, tasksSort.order)
    setCurrentTasks([...sorted])
  }, [activeTasks, tasksSort])

  // Filter tasks when tasksSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = tasksSort.order === 'desc' ? 'asc' : 'desc'
    setTasksSort({ value: sort_by, order: currentOrder })
    // if(isCookieAccepted()) {
    //   saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'tasks')
    // }
  }

  return (
    <MainLayout
      title={t('dashboard.tasks')}
    >
      <TasksView 
        filteredTasks={currentTasks}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        ready={ready}
        refreshTasks={refreshCollection}
        onSort={handleSort}
      />
    </MainLayout>
  )
}

export default TasksPage
import { useContext  } from 'react'

import { FolderContext, LoaderContext, GlobalContext, NotificationContext, TeamContext, DocumentsContext } from '../context'
import { getAllParentFolders } from '../utils'

const useTemplatesFoldersActions = () => {
  const { updateFolder, folders, setFolderBreadcrumbs, folderToMove, createFolder, deleteFolder, folderBreadcrumbs, setSelectedFilter, setCurrentlyActiveFolder } = useContext(FolderContext)
  const { templates, setFilteredTemplates } = useContext(DocumentsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam } = useContext(TeamContext)
  const { t } = useContext(GlobalContext)

  // Move folder from modal
  const moveTemplatesFolderFromModal = async ({ current, active, setTmplFolderToMove, setFolderCreated }) => {
    if(!current.parentFolder && !active) {
      return setNotification({ msg: t('folder.already_in_root', { folder: current.name }), type: 'warning' })
    }
    if(active && current.parentFolder === active.id) {
      return setNotification({ msg: t('folder.already_in_folder', { folder1: current.name, folder2: active.name }), type: 'warning' })
    }
    
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Moving folder')

    if(active) {
      await updateFolder({ parentFolder: active.id }, current.id)
      setNotification({ msg: t('folder.moved_to_folder', { folder1: current.name, folder2: active.name }), type: 'default' })
      setTmplFolderToMove(prev => ({...prev, parentFolder: active.id}))
    }else {
      await updateFolder({ parentFolder: null }, current.id)
      setNotification({ msg: t('folder.moved_to_root', { folder: current.name }), type: 'default' })
      setTmplFolderToMove(prev => ({...prev, parentFolder: null}))
    }

    const parentFolders = getAllParentFolders(folders, {...current, parentFolder: active ? active.id : null})
    const reverseParentFolders = parentFolders.reverse();
    if(parentFolders.length > 0) {
      setFolderBreadcrumbs([...reverseParentFolders, {...current, parentFolder: active ? active.id : null}])
    }else {
      if(current) {
        setFolderBreadcrumbs([{...current, parentFolder: active ? active.id : null}])
      }else {
        setFolderBreadcrumbs([])
      }
    }

    setFolderCreated(true)
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Move templates folder
  const moveTemplatesFolder = async ({ selectedFolder, tmpltFolderToMove }) => {
    const parentFolders = getAllParentFolders(folders, selectedFolder)
    let toMove = tmpltFolderToMove || folderToMove
    if(selectedFolder) {
      if(toMove.id === selectedFolder.id) {
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: toMove.name, folder2: selectedFolder.name }), type: 'warning' })
      }
      if(toMove.parentFolder === selectedFolder.id) {
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: toMove.name, folder2: selectedFolder.name }), type: 'warning' })
      }
      if(parentFolders.find(f => f.id === toMove.id)) {
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: toMove.name, folder2: selectedFolder.name }), type: 'warning' })
      }
      
      setShowGlobalResponseLoader(true)
      // setGlobalResponseLoaderText('Moving folder')
      try {
        await updateFolder({ parentFolder: selectedFolder.id }, toMove.id)
        setNotification({ msg: t('folder.moved_to_folder', { folder1: toMove.name, folder2: selectedFolder.name }), type: 'default' })
      } catch (err) {
        console.log(err)
      }finally {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      }
      
    }else {
      if(toMove.parentFolder) {
        setShowGlobalResponseLoader(true)
        // setGlobalResponseLoaderText('Moving folder')
        try {
          await updateFolder({ parentFolder: null }, toMove.id)
          setNotification({ msg: t('folder.moved_to_root', { folder: toMove.name }), type: 'default' })
        } catch (err) {
          console.log(err)
        } finally {
          setShowGlobalResponseLoader(false)
          setGlobalResponseLoaderText('')
        }
      }else {
        setNotification({ msg: t('folder.unable_to_move_to_root', { folder: toMove.name }), type: 'warning' })
      }
    }
  }

  // Create template folder 
  const createTemplateFolder = async ({ data, setShowFolderModal, setFolderCreated }) => {
    try {
      await createFolder(data, selectedTeam?.id)
      setShowFolderModal(false)
      setFolderCreated(true)
    }catch(err) { 
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }

  // Update template folder 
  const updateTemplateFolder = async ({ data, id, setShowFolderModal, setCurrentFolder }) => {
    await updateFolder(data, id)
    setShowFolderModal(false)
    setCurrentFolder(null)
    if(folderBreadcrumbs.length > 0) {
      const breadcrumbs = [...folderBreadcrumbs]
      const findBreadcrumb = breadcrumbs.find((b) => b.id === id)
      if(findBreadcrumb) {
        findBreadcrumb.name = data.name
        setFolderBreadcrumbs(prev => [...prev].map(b => b.id === findBreadcrumb.id ? findBreadcrumb : b))
      }
    }
  }

  // Delete template folder 
  const deleteTemplateFolder = async ({ currentFolder, setShowDeleteFolderAlert, setCurrentFolder, resetFolders, setSelectedTitle, setResetFolders, filterNonDeletedTemplates }) => {
    setShowGlobalResponseLoader(true)
    await deleteFolder(currentFolder.id, false, selectedTeam?.id)
    setNotification({ msg: t('folder.deleted', { folder: currentFolder.name }), type: 'default' })
    setShowDeleteFolderAlert(false)
    setCurrentFolder(null)
    if(resetFolders) {
      setSelectedFilter('all')
      setSelectedTitle(t('general.all'))
      setFolderBreadcrumbs([])
      setResetFolders(false)
      const nonDeletedTemplates = filterNonDeletedTemplates({...templates})
      setFilteredTemplates(nonDeletedTemplates)
    }
    setShowGlobalResponseLoader(false)
  }

  // Update breadcrumbs
  const updateBreadcrumbs = ({ folder }) => {
    setCurrentlyActiveFolder(folder)
    const parentFolders = getAllParentFolders(folders, folder)
    const reverseParentFolders = parentFolders.reverse()
    if(parentFolders.length > 0) {
      setFolderBreadcrumbs([...reverseParentFolders, folder])
    }else {
      if(folder) {
        setFolderBreadcrumbs([folder])
      }else {
        setFolderBreadcrumbs([])
      }
    }
  }
  
  return {
    moveTemplatesFolderFromModal,
    moveTemplatesFolder,
    createTemplateFolder,
    updateTemplateFolder,
    deleteTemplateFolder,
    updateBreadcrumbs,
  }
}

export default useTemplatesFoldersActions
import React, { useState, useEffect, useContext, useCallback, useRef, memo } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CheckIcon from '@material-ui/icons/Check';

import CustomSelect from '../../UI/CustomSelect';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader';
import { dateValueFormat } from '../../../utils';
import { DocumentsContext, LoaderContext, NotificationContext, TeamContext, GlobalContext } from '../../../context';
import { didObjectChanged } from '../../../utils';

const DocumentDetailSPDates = ({ docDates, onSetDocDates, docDatesDefault, doc, onSetView, onSetUpdateSection, onSetEditStartTime, onStoreEditTime }) => {
  const { t } = useContext(GlobalContext)
  const [signatureDate, setSignatureDate] = useState(docDates.signature_date?.timestamp ? new Date(docDates.signature_date.timestamp) : '');
  const [contractDuration, setContractDuration] = useState(docDates.contract_duration || '')
  const [startDate, setStartDate] = useState(docDates.start_date?.timestamp ? new Date(docDates.start_date.timestamp) : '');
  const [endDate, setEndDate] = useState(docDates.end_date?.timestamp ? new Date(docDates.end_date.timestamp) : '');
  const [noticePeriod, setNoticePeriod] = useState(docDates.notice_period ? docDates.notice_period : '');
  const [tacitRenewal, setTacitRenewal] = useState([
    { value: '', label: `${t('general.yes')} / ${t('general.no')}`, active: !docDates.tacit_renewal },
    { value: 'yes', label: t('general.yes'), active: docDates.tacit_renewal === 'yes' },
    { value: 'no', label: t('general.no'), active: docDates.tacit_renewal === 'no' },
  ]);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const { updateDocument } = useContext(DocumentsContext);
  const { setLoadingOverlay } = useContext(LoaderContext);
  const { setNotification } = useContext(NotificationContext);
  const { activeTeamMember } = useContext(TeamContext);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const savedTimeout = useRef();

  // On mount/cleanup
  useEffect(() => {

    return () => {
      if(savedTimeout.current) {
        clearTimeout(savedTimeout.current);
      }
    }
  }, []);

  // Disable/enable save button and cancel button on data change
  useEffect(() => {
    if(didObjectChanged(docDates, docDatesDefault)) {
      setFieldsChanged(true);
      onSetUpdateSection(prev => ({
        ...prev,
        dates: true
      }));
    }else {
      setFieldsChanged(false);
      onSetUpdateSection(prev => ({
        ...prev,
        dates: false
      }));
    }
  }, [docDates, docDatesDefault, onSetUpdateSection]);

  // On fields change
  const changeHandler = (name, value, setValue) => {
    setValue(value);
    onSetDocDates(prev => ({
      ...prev,
      [name]: value,
    }));
  }

  // On date fields change
  const dateChangeHandler = (name, value, setValue) => {
    const date = value ? moment(value.valueOf()).format(dateValueFormat) : '';
    setValue(value);
    onSetDocDates(prev => ({
      ...prev,
      [name]: { value: date, timestamp: value ? value.valueOf() : '' }
    }));
  }

  // On select change
  const customSelectChangeHandler = (option, setOptions, name) => {
    onSetDocDates(prev => ({
      ...prev,
      [name]: option.value
    }));
    setOptions(prev => {
      const copyOfOptions = [...prev];
      const updatedOptions = copyOfOptions.map((opt) => opt.value === option.value ? { ...opt, active: true } : { ...opt, active: false});
      return updatedOptions;
    });
  }

  // On cancel - restore prev data
  const cancelClickHandler = () => {
    onSetDocDates(docDatesDefault);
    restoreData();
  }

  // Restore data
  const restoreData = () => {
    setSignatureDate(docDatesDefault.signature_date?.timestamp ? new Date(docDatesDefault.signature_date.timestamp) : '');
    setStartDate(docDatesDefault.start_date?.timestamp ? new Date(docDatesDefault.start_date.timestamp) : '');
    setEndDate(docDatesDefault.end_date?.timestamp ? new Date(docDatesDefault.end_date.timestamp) : '');
    setNoticePeriod(docDatesDefault.notice_period ? docDatesDefault.notice_period : '');
    setTacitRenewal([
      { value: '', label: `${t('general.yes')} / ${t('general.no')}`, active: !docDatesDefault.tacit_renewal },
      { value: 'yes', label: t('general.yes'), active: docDatesDefault.tacit_renewal === 'yes' },
      { value: 'no', label: t('general.no'), active: docDatesDefault.tacit_renewal === 'no' },
    ]);
  }

  // Save click handler
  const saveClickHandler = async () => {
    setSaving(true);
    setLoadingOverlay(true);
    try {
      await updateDocument({ dates: docDates, last_modified_by: activeTeamMember.id, create_action: 'yes' }, doc);
      setSaved(true)
      savedTimeout.current = setTimeout(() => setSaved(false), 2000)
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    }
    setLoadingOverlay(false);
    setSaving(false);
    onSetView('dates');
  }

  // On enter or escape save document or reset data 
  const onKeyDown = useCallback((e) => {
    if(e.key === 'Enter') {
      if(fieldsChanged) {
        saveClickHandler();
      }
    }else if(e.key === 'Escape') {
      if(fieldsChanged) {
        cancelClickHandler();
      }
    }
    // eslint-disable-next-line
  }, [fieldsChanged, docDates]);

  // Add event keydown event listener on component mount
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown]);  

  return(
    <div className="document-detail-sp-section">
      <h4 className="with-border">{t('dashboard.dates')}</h4>
      <div className="document-detail-sp-section__fields">
        <div className="fields-wrapper--dates">
          <div className="date-picker-field date-picker-field--thick-border">
            <div className="date-picker-field__label">{t('dashboard.signature')}</div>
            <DatePicker 
              selected={signatureDate} 
              onChange={(date) => dateChangeHandler('signature_date', date, setSignatureDate)} 
              // onChangeRaw={(e) => e.preventDefault()}
              isClearable={signatureDate !== ''}
              dateFormat={'dd/MM/yyyy'}
              locale="fr"
              popperModifiers={{preventOverflow: { enabled: true }}} 
              className={signatureDate ? 'hide-bg-image' : ''}
              portalId="modal-root"
              onCalendarOpen={() => onSetEditStartTime(Date.now())}
              onCalendarClose={onStoreEditTime}
            />
          </div>
        </div>
        <div className="fields-wrapper--fullwidth">
          <Input 
            value={contractDuration} 
            onChange={(e) => changeHandler('contract_duration', e.target.value, setContractDuration)} 
            label={t('general.contract_duration')}
            formEl
            thickBorder
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
          />
        </div>
        <div className="fields-wrapper--dates">
          <div className="date-picker-field date-picker-field--thick-border">
            <div className="date-picker-field__label">{t('dashboard.contract_start_date')}</div>
            <DatePicker 
              selected={startDate} 
              onChange={(date) => dateChangeHandler('start_date', date, setStartDate)} 
              // onChangeRaw={(e) => e.preventDefault()}
              isClearable={startDate !== ''}
              dateFormat={'dd/MM/yyyy'}
              locale="fr"
              popperModifiers={{preventOverflow: { enabled: true }}} 
              maxDate={endDate}
              className={startDate ? 'hide-bg-image' : ''}
              portalId="modal-root"
              onCalendarOpen={() => onSetEditStartTime(Date.now())}
              onCalendarClose={onStoreEditTime}
            />
          </div>
          <div className="date-picker-field date-picker-field--thick-border">
            <div className="date-picker-field__label">{t('dashboard.contract_end_date')}</div>
            <DatePicker 
              selected={endDate} 
              onChange={(date) => dateChangeHandler('end_date', date, setEndDate)} 
              // onChangeRaw={(e) => e.preventDefault()}
              isClearable={endDate !== ''}
              dateFormat={'dd/MM/yyyy'}
              locale="fr"
              popperModifiers={{preventOverflow: { enabled: true }}} 
              minDate={startDate || new Date()}
              className={endDate ? 'hide-bg-image' : ''}
              portalId="modal-root"
              onCalendarOpen={() => onSetEditStartTime(Date.now())}
              onCalendarClose={onStoreEditTime}
            />
          </div>
        </div>
        <div className="fields-wrapper--dates">
          <Input 
            value={noticePeriod} 
            onChange={(e) => changeHandler('notice_period', e.target.value, setNoticePeriod)} 
            type="number"
            label={t('dashboard.notice_period')}
            min={1}
            placeholder={t('time.days')}
            formEl
            thickBorder
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
          />
          <CustomSelect 
            label={t('dashboard.tacit_renewal')}
            options={tacitRenewal}
            active={tacitRenewal.find(t => t.active)}
            onChange={(opt) => customSelectChangeHandler(opt, setTacitRenewal, 'tacit_renewal')}
            formEl
            className="tacit-renewal"
            fullWidth
            thickBorder
            onFocus={() => onSetEditStartTime(Date.now())}
            onBlur={onStoreEditTime}
          />
        </div>
      </div>
      <div className="document-detail-sp-section__actions">
        <Button 
          text={saving ? <Loader mini normalWhite /> : saved ? <CheckIcon /> : t('general.save')} 
          primary 
          onButtonClick={saveClickHandler} 
          disabled={!fieldsChanged} 
        />
        {fieldsChanged && <Button text={t('general.cancel')} onButtonClick={cancelClickHandler} />}
      </div>
    </div>
  );
}

export default memo(DocumentDetailSPDates);
import { useContext, useState, useEffect, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

import { AllAlertsModal, CreateEditAlertModal, WidgetDropdown } from '../'
import { GlobalContext, AlertContext, UserContext } from '../../../context'

const AlertsWidget = forwardRef(({ style, dragging, isSorting, ...props }, ref) => {
  const { t } = useContext(GlobalContext)
  const { alerts, alertsFetched } = useContext(AlertContext)
  const { widgetsInfo } = useContext(UserContext)
  const [events, setEvents] = useState([])
  const [eventsAdded, setEventsAdded] = useState(false)
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false)
  const [showDateAlertsModal, setShowDateAlertsModal] = useState(false)
  const [selectedDateAlerts, setSelectedDateAlerts] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [showPanel, setShowPanel] = useState(false)
  const [selectedAlert, setSelectedAlert] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const history = useHistory()

  // Add events 
  useEffect(() => {
    if(alertsFetched && alerts) {
      const eventsArr = []
      alerts.forEach(a => eventsArr.push({ 
        title: a.title, 
        date: a.dateTimestamp ? moment.unix(a.dateTimestamp / 1000).format('YYYY-MM-DD') : '',
        time: a.dateTimestamp ? moment.unix(a.dateTimestamp / 1000).format('HH:mm') : '',
        dateTimestamp: a.dateTimestamp,
        documentId: a.documentId,
        display: 'background'
      }));
      setEvents(eventsArr)
      setEventsAdded(true)
    }
  }, [alerts, alertsFetched])

  // On date click
  const handleDateClick = (info) => {
    const dateAlerts = events.filter(event => event.date === info.dateStr)
    if(dateAlerts.length > 0) {
      handleOpenDateAlertsModal()
      setSelectedDateAlerts(dateAlerts)
      setSelectedDate(moment(info.dateStr).format('DD MMM YY'))
    }
  }

  // Open create alert modal
  const handleOpenCreateAlertModal = (e) => {
    e.preventDefault()
    setShowCreateAlertModal(true)
  }

  // Close create alert modal
  const handleCloseCreateAlertModal = () => {
    setShowCreateAlertModal(false)
  }

  // Open add alert modal
  const handleOpenDateAlertsModal = () => {
    setShowDateAlertsModal(true)
  }

  // Close add alert modal
  const handleCloseDateAlertsModal = () => {
    setShowDateAlertsModal(false)
    setSelectedDate('')
    setSelectedDateAlerts([])
  }

  // On title click
  const handleTitleClick = (docId) => {
    history.push(`/document-detail/${docId}`, { openAlert: true });
  }

  // On edit click
  const handleEditIconClick = (e, a) => {
    e.preventDefault()
    setShowEditModal(true)
    setSelectedAlert(a)
  }

  // On close edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedAlert(null)
  }

  // On open widget panel
  const handleOpenPanel = () => {
    setShowPanel(true)
  }

  // On close widget panel
  const handleClosePanel = () => {
    setShowPanel(false)
  }

  // Render alert
  const renderAlert = (a, idx) => {
    return (
      <li key={idx}>
        <p className={`u-truncate-text u-cursor--pointer`} onClick={() => handleTitleClick(a.documentId)}>{a.title}</p>
        <div className={`date hide-on-hover ${Date.now() > a.dateTimestamp ? 'already-passed' : ''}`}>
          {a.dateTimestamp && moment.unix(a.dateTimestamp / 1000).format('DD MMM YYYY')}
        </div>
        <div className="on-hover">
          <a href="/#" onClick={(e) => handleEditIconClick(e, a)}>
            <span className="material-symbols-outlined">edit</span>
          </a>
        </div>
      </li>
    )
  }

  if(isSorting) {
    return (
      <div className={`widget widget--placeholder ${dragging ? 'widget--dragging' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4">{t('dashboard.alerts')}</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`widget ${widgetsInfo['item-alerts'].size === 'half' ? 'widget--half' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4" {...props}>{t('dashboard.alerts')}</h4>
          <div className="widget__head_actions">
            <a href="/#" onClick={handleOpenCreateAlertModal}><span className="material-symbols-outlined size-24">add</span></a>
            <WidgetDropdown widget="item-alerts" />
          </div>
        </div>
        <div className="widget__body">
          <div className="alert-widget-content">
            <FullCalendar 
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              locale="fr"
              headerToolbar={{
                start: '',
                center: 'title',
                end: 'prev today next',
              }}
              buttonText={{
                today: t('general.today')
              }}
              firstDay={1}
              contentHeight={280}
              showNonCurrentDates={false}
              aspectRatio={1}
              events={events}
              dateClick={handleDateClick}
            />
          </div>
        </div>
        <div className="widget__foot">
          <button className="btn btn--primary-light btn--full" onClick={handleOpenPanel}>
            {t('dashboard.see_all_alerts')} 
            {/* <span className="material-symbols-outlined size-24">chevron_right</span> */}
          </button>
        </div>
        <div className={`widget__overlay ${showPanel ? 'show' : ''}`} onClick={handleClosePanel}></div>
        <div className={`widget__panel ${showPanel ? 'opened' : ''}`}>
          <div className="widget__panel_head">
            <h4 className="heading-4">{t('dashboard.alerts')}</h4>
            <div className="close" onClick={handleClosePanel}>
              <span className="material-symbols-outlined size-24">close</span>
            </div>
          </div>
          <div className="widget__panel_body u-custom-scrollbar">
            <ul className="select-list">
              {alerts.map((a, idx) => renderAlert(a, idx))}
            </ul>
          </div>
        </div>
      </div>

      {showDateAlertsModal && (
        <AllAlertsModal 
          onClose={handleCloseDateAlertsModal} 
          mode="events" 
          events={selectedDateAlerts} 
          currentDate={selectedDate} 
        />
      )}
      {showCreateAlertModal && (
        <CreateEditAlertModal 
          onClose={handleCloseCreateAlertModal}
        />
      )}
      {showEditModal && (
        <CreateEditAlertModal 
          onClose={handleCloseEditModal}
          selectedAlert={selectedAlert}
          mode="edit"
        />
      )}
    </>
  )
})

export default AlertsWidget 
import { useContext } from 'react'

import { NotificationContext, GlobalContext, VariablesContext, TeamContext, LoaderContext } from '../context'

const useSingleVariableCategoryActions = () => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam } = useContext(TeamContext)
  const { createCategory, updateCategory, deleteCategory } = useContext(VariablesContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  
  // Create category
  const createVariableCategory = async ({ name, description, onClose }) => {
    // validate
    if(name.trim() === '') {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    let category_data = {
      display_name: name.trim(),
      description: description ? description.trim() : ''
    }

    try {
      await createCategory(selectedTeam, category_data)
      onClose()
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    } 
    setShowGlobalResponseLoader(false)
  }

  // Update category
  const updateVariableCategory = async ({ name, description, categoryId, onClose }) => {
    // validate
    if(name.trim() === '') {
      return setNotification({ msg: t('notification.asterisk_fields_required'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    let category_data = {
      display_name: name.trim(),
      description: description ? description.trim() : ''
    }

    try {
      await updateCategory(selectedTeam, category_data, categoryId)
      onClose()
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    } 
    setShowGlobalResponseLoader(false)
  }
  
  // Delete category
  const deleteVariableCategory = async ({ categoryId, onClose }) => {
    setShowGlobalResponseLoader(true)
    try {
      await deleteCategory(selectedTeam, categoryId)
      onClose()
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setShowGlobalResponseLoader(false)
  }

  return {
    createVariableCategory,
    updateVariableCategory,
    deleteVariableCategory,
  }
}

export default useSingleVariableCategoryActions
import { useContext, useState, useEffect, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { ContactsSidebar, ContactsView } from '../components/misc'
import { GlobalContext, TeamContext, ContactsContext, ContactGroupsContext, VariablesContext } from '../context'
import { sortArrayOfObjects, isCookieAccepted, saveTeamSortingToLS } from '../utils'

const Contacts = () => {
  const { t } = useContext(GlobalContext)
  const { teamChanged, setTeamChanged, selectedTeam } = useContext(TeamContext)
  const { contacts, fetchContacts, contactsFetched, contactsSort, contactsSelectedFilters, setContactsSort, setContactsSelectedFilters } = useContext(ContactsContext)
  const { contactGroups, contactGroupsFetched, fetchContactGroups } = useContext(ContactGroupsContext)
  const { variablesFetched, fetchVariables } = useContext(VariablesContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [activeContacts, setActiveContacts] = useState([])
  const [currentContacts, setCurrentContacts] = useState([])
  const [ready, setReady] = useState(false)
  const [selectedContacts, setSelectedContacts] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [paginatedData, setPaginatedData] = useState([])
  const [mainTitle, setMainTitle] = useState(t('general.all'))

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch contacts, contact groups and variables if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!contactsFetched) {
        // console.log('fetch contacts')
        fetchContacts(teamId)
      }
      if(!contactGroupsFetched) {
        // console.log('fetch contact groups')
        fetchContactGroups(teamId)
      }
      if(!variablesFetched) {
        // console.log('fetch variables')
        fetchVariables(selectedTeam)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, contactsFetched, contactGroupsFetched, fetchContactGroups, fetchContacts, variablesFetched, fetchVariables])

  // Set active group when groups are fetched
  useEffect(() => {
    if(contactGroupsFetched && contactGroups.length > 0 && contactsSelectedFilters.group === 'all') {
      setContactsSelectedFilters(prev => ({...prev, group: contactGroups[0].id}))
      setMainTitle(contactGroups[0].name)
    }
  }, [contactGroups, contactGroupsFetched, contactsSelectedFilters, setContactsSelectedFilters])
  
  // Set contacts
  const setupContacts = useCallback(async () => {
    let active = [...contacts]
    let sorted = await sortArrayOfObjects(active, contactsSort.value, contactsSort.order)
    setActiveContacts([...sorted])
    setCurrentContacts([...sorted])
    setReady(true)
  }, [contacts, contactsSort])

  // Setup signatures when all data is fetched - only once, on component mount
  useEffect(() => {
    if(variablesFetched && contactGroupsFetched && contactsFetched && !ready) {
      setupContacts()
    }
  }, [variablesFetched, contactGroupsFetched, contactsFetched, ready, setupContacts])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
    setAllChecked(false)
    setSelectedContacts([])
  }, [])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeContacts]

    for(let key in contactsSelectedFilters) {
      if(key === 'search' && contactsSelectedFilters[key] !== '') {
        filtered = filtered.filter(c => c.first_name.toLowerCase().includes(contactsSelectedFilters[key].trim().toLowerCase()))
      }
      if(key === 'group' && contactsSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(c => c.group === contactsSelectedFilters[key])
      }
    }

    let sorted = sortArrayOfObjects(filtered, contactsSort.value, contactsSort.order)
    setCurrentContacts([...sorted])
  }, [activeContacts, contactsSelectedFilters, contactsSort])

  // Filter templates when templatesSelectedFilters or templatesSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = contactsSort.order === 'desc' ? 'asc' : 'desc'
    setContactsSort({ value: sort_by, order: currentOrder })
    if(isCookieAccepted()) {
      saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'contacts')
    }
  }

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedContacts([])
    }else {
      setSelectedContacts(paginatedData)
    }
  }

  return (
    <MainLayout
      title={mainTitle}
      page="contacts"
      showSidebarTrigger={true}
      sidebar={
        <ContactsSidebar 
          setMainTitle={setMainTitle}
          refreshContacts={refreshCollection}
        />
      }
    >
      <ContactsView 
        filteredContacts={currentContacts}
        onSort={handleSort}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
        allChecked={allChecked}
        onAllCheckedChange={handleAllChecked}
        setAllChecked={setAllChecked}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        refreshContacts={refreshCollection}
        ready={ready}
      />
    </MainLayout>
  )
}

export default Contacts
import React, { createContext, useState } from 'react'

import { CONTACTS_COLUMNS_WIDTHS, COLUMNS_WIDTHS, VARIABLES_COLUMNS_WIDTHS, TEMPLATES_COLUMNS_WIDTHS, SIGNATURES_COLUMNS_WIDTHS, APPROVALS_COLUMNS_WIDTHS, SHARED_DOCS_COLUMNS_WIDTHS } from '../../constants'
import { getTeamDocsColumnWidthsData, getSelectedTeamFromLS, getTeamContactsColumnWidthsData, getTeamVarsColumnWidthsData, getTeamTemplatesColumnWidthsData, getTeamSignaturesColumnWidthsData, getTeamApprovalsColumnWidthsData, getTeamSharedDocumentsColumnWidthsData } from '../../utils'

export const ColumnsContext = createContext()

const selectedTeam = getSelectedTeamFromLS()

const columnsWidthsData = getTeamDocsColumnWidthsData()
let defaultColumnsWidths = COLUMNS_WIDTHS
if(columnsWidthsData && columnsWidthsData[selectedTeam]) {
  defaultColumnsWidths = columnsWidthsData[selectedTeam]
}

const contactsColumnsWidthsData = getTeamContactsColumnWidthsData()
let defaultContactsColumnsWidths = CONTACTS_COLUMNS_WIDTHS
if(contactsColumnsWidthsData && contactsColumnsWidthsData[selectedTeam]) {
  defaultContactsColumnsWidths = contactsColumnsWidthsData[selectedTeam]
}

const varsColumnsWidthsData = getTeamVarsColumnWidthsData()
let defaultVarsColumnsWidths = VARIABLES_COLUMNS_WIDTHS
if(varsColumnsWidthsData && varsColumnsWidthsData[selectedTeam]) {
  defaultVarsColumnsWidths = varsColumnsWidthsData[selectedTeam]
}

const templatesColumnsWidthsData = getTeamTemplatesColumnWidthsData()
let defaultTemplatesColumnsWidths = TEMPLATES_COLUMNS_WIDTHS
if(templatesColumnsWidthsData && templatesColumnsWidthsData[selectedTeam]) {
  defaultTemplatesColumnsWidths = templatesColumnsWidthsData[selectedTeam]
}

const signaturesColumnsWidthsData = getTeamSignaturesColumnWidthsData()
let defaultSignaturesColumnsWidths = SIGNATURES_COLUMNS_WIDTHS
if(signaturesColumnsWidthsData && signaturesColumnsWidthsData[selectedTeam]) {
  defaultSignaturesColumnsWidths = signaturesColumnsWidthsData[selectedTeam]
}

const approvalsColumnsWidthsData = getTeamApprovalsColumnWidthsData()
let defaultApprovalsColumnsWidths = APPROVALS_COLUMNS_WIDTHS
if(approvalsColumnsWidthsData && approvalsColumnsWidthsData[selectedTeam]) {
  defaultApprovalsColumnsWidths = approvalsColumnsWidthsData[selectedTeam]
}

const sharedDocsColumnsWidthsData = getTeamSharedDocumentsColumnWidthsData()
let defaultSharedDocsColumnsWidths = SHARED_DOCS_COLUMNS_WIDTHS
if(sharedDocsColumnsWidthsData && sharedDocsColumnsWidthsData[selectedTeam]) {
  defaultSharedDocsColumnsWidths = sharedDocsColumnsWidthsData[selectedTeam]
}

const ColumnsState = ({ children }) => {
  const [docsTableWidth, setDocsTableWidth] = useState('100%')
  const [docsTableColumnsWidths, setDocsTableColumnsWidths] = useState(defaultColumnsWidths)
  const [contactsTableWidth, setContactsTableWidth] = useState('100%')
  const [contactsTableColumnsWidths, setContactsTableColumnsWidths] = useState(defaultContactsColumnsWidths)
  const [varsTableWidth, setVarsTableWidth] = useState('100%')
  const [varsTableColumnsWidths, setVarsTableColumnsWidths] = useState(defaultVarsColumnsWidths)
  const [templatesTableWidth, setTemplatesTableWidth] = useState('100%')
  const [templatesTableColumnsWidths, setTemplatesTableColumnsWidths] = useState(defaultTemplatesColumnsWidths)
  const [signaturesTableWidth, setSignaturesTableWidth] = useState('100%')
  const [signaturesTableColumnsWidths, setSignaturesTableColumnsWidths] = useState(defaultSignaturesColumnsWidths)
  const [approvalsTableWidth, setApprovalsTableWidth] = useState('100%')
  const [approvalsTableColumnsWidths, setApprovalsTableColumnsWidths] = useState(defaultApprovalsColumnsWidths)
  const [sharedDocsTableWidth, setSharedDocsTableWidth] = useState('100%')
  const [sharedDocsTableColumnsWidths, setSharedDocsTableColumnsWidths] = useState(defaultSharedDocsColumnsWidths)

  // Reset state
  const resetState = () => {
    setDocsTableWidth('100%')
    setDocsTableColumnsWidths(defaultColumnsWidths)
    setContactsTableWidth('100%')
    setContactsTableColumnsWidths(defaultContactsColumnsWidths)
    setVarsTableWidth('100%')
    setVarsTableColumnsWidths(defaultVarsColumnsWidths)
    setTemplatesTableWidth('100%')
    setTemplatesTableColumnsWidths(defaultTemplatesColumnsWidths)
    setSignaturesTableWidth('100%')
    setSignaturesTableColumnsWidths(defaultSignaturesColumnsWidths)
    setApprovalsTableWidth('100%')
    setApprovalsTableColumnsWidths(defaultApprovalsColumnsWidths)
  }

  return <ColumnsContext.Provider value={{
    docsTableWidth,
    setDocsTableWidth,
    docsTableColumnsWidths,
    setDocsTableColumnsWidths,
    contactsTableWidth, 
    setContactsTableWidth,
    contactsTableColumnsWidths,
    setContactsTableColumnsWidths,
    varsTableWidth, 
    setVarsTableWidth,
    varsTableColumnsWidths,
    setVarsTableColumnsWidths,
    templatesTableWidth,
    setTemplatesTableWidth,
    templatesTableColumnsWidths,
    setTemplatesTableColumnsWidths,
    signaturesTableWidth,
    setSignaturesTableWidth,
    signaturesTableColumnsWidths,
    setSignaturesTableColumnsWidths,
    approvalsTableWidth,
    setApprovalsTableWidth,
    approvalsTableColumnsWidths,
    setApprovalsTableColumnsWidths,
    sharedDocsTableWidth,
    setSharedDocsTableWidth,
    sharedDocsTableColumnsWidths,
    setSharedDocsTableColumnsWidths,
    resetColumnsState: resetState,
  }}>
    {children}
  </ColumnsContext.Provider>
}

export default ColumnsState
import { useEffect, useContext, useCallback, useState } from 'react'

import { Modal, Input } from '../new_ui'
import { FolderContext, DocumentsFoldersContext, NotificationContext, TeamContext, GlobalContext, LoaderContext } from '../../context'
import { useDocumentsFoldersActions, useTemplatesFoldersActions } from '../../hooks'

const CreateEditFolderModal = ({ onClose, mode = 'create', currentFolder, view = 'templates', setCurrentFolder, setFolderCreated, refreshCollection = () => {} }) => {
  const { t } = useContext(GlobalContext)
  const { parentFolder, setParentFolder } = useContext(FolderContext)
  const { docParentFolder, setDocParentFolder } = useContext(DocumentsFoldersContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam } = useContext(TeamContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { createDocumentFolder, updateDocumentFolder } = useDocumentsFoldersActions()
  const { createTemplateFolder, updateTemplateFolder } = useTemplatesFoldersActions()
  const [name, setName] = useState('')

  // Populate name field if in edit mode
  useEffect(() => {
    if(mode === 'edit' && currentFolder) {
      setName(currentFolder.name)
    }
    // eslint-disable-next-line
  }, [currentFolder])

  // On close
  const handleClose = () => {
    setParentFolder(null)
    onClose()
  }

  // On submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()

    if(name.trim() === '') {
      return setNotification({ msg: t('notification.name_is_required'), type: 'danger' })
    }

    const data = {
      name: name.trim(),
      team: selectedTeam?.id
    }

    
    setShowGlobalResponseLoader(true)
    if(view === 'documents') {
      if(mode === 'create') {
        data.parentFolder = docParentFolder ? docParentFolder.id : null
        try {
          await createDocumentFolder({ data, setShowFolderModal: onClose, setFolderCreated })
          setDocParentFolder(null)
          setNotification({ msg: t('folder.created', { folder: data.name }), type: 'success' })
          refreshCollection()
        } catch (err) {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        }
      }else if (mode === 'edit') {
        try {
          await updateDocumentFolder({ data, id: currentFolder.id, setShowFolderModal: onClose, setCurrentFolder })
          refreshCollection()
        } catch (err) {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        }
      }
    }else if(view === 'templates') {
      if(mode === 'create') {
        try {
          data.parentFolder = parentFolder ? parentFolder.id : null
          await createTemplateFolder({ data, setShowFolderModal: onClose, setFolderCreated })
          setParentFolder(null)
          setNotification({ msg: t('folder.created', { folder: data.name }), type: 'success' })
          refreshCollection()
        } catch (err) {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        }
      }else if(mode === 'edit') {
        try {
          await updateTemplateFolder({ data, id: currentFolder.id, setShowFolderModal: onClose, setCurrentFolder })
          refreshCollection()
        } catch (err) {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        }
      }
    }
    setShowGlobalResponseLoader(false)
  }, [currentFolder, docParentFolder, mode, parentFolder, selectedTeam, setDocParentFolder, setNotification, setParentFolder, t, view, name, createDocumentFolder, createTemplateFolder, onClose, updateDocumentFolder, updateTemplateFolder, setShowGlobalResponseLoader, setCurrentFolder, setFolderCreated, refreshCollection])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return(
    <Modal 
      onClose={handleClose} 
      title={mode === 'create' ? t('folder.create') : t('folder.update')}
      saveBtnText={t('general.save_changes')}
      onSave={handleSubmit}
      className="centered-head"
    >
      <div className="folder-modal">
        <Input 
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('folder.name_placeholder')}
        />
      </div>
    </Modal>
  )
}

export default CreateEditFolderModal
import { useState } from 'react'

const Input = ({ label, type, placeholder, value, onChange, name, error, id, disabled, className, invalid, infoText = '', labelDropdown = null, iconEl = null, onIconClick = () => {}, showEyeIcon = false }) => {
  const [showInputValue, setShowInputValue] = useState(false)

  // On change
  const handleChange = (e) => {
    onChange(e)
  }

  // On blur
  const handleBlur = (e) => {
    
  }

  // On focus
  const handleFocus = (e) => {
    
  }

  // On toggle input value
  const handleToggleInputValue = () => {
    setShowInputValue(!showInputValue)
  }

  // Input classes
  let inputClasses = 'input-v2'

  if(error || invalid) {
    inputClasses += ' error'
  }

  if(className) {
    inputClasses += ` ${className}`;
  }

  if(disabled) {
    inputClasses += ' disabled'
  }

  if(iconEl) {
    inputClasses += ' with-icon'
  }

  if(showEyeIcon) {
    inputClasses += ' with-eye-icon'
  }

  let inputType = type 
  if(type === 'password' && showInputValue) {
    inputType = 'text'
  }

  return(
    <div className={inputClasses}>
      {label && (
        <div className={`input-v2__label-wrapper ${labelDropdown ? 'with-dropdown' : ''}`}>
          <label htmlFor={id} className="input-v2__label">{label}</label>
          {labelDropdown && <div className="label-dropdown-wrapper">{labelDropdown}</div>}
        </div>
      )}
      <div className="input-v2__field-wrapper">
        {iconEl && <div className={`input-v2__icon`} onClick={onIconClick}>{iconEl}</div>}
        <input 
          id={id}
          name={name}
          type={inputType} 
          value={value} 
          onChange={handleChange} 
          onBlur={handleBlur} 
          onFocus={handleFocus} 
          placeholder={placeholder}
          className="input-v2__field" 
          disabled={disabled}
        />
        {showEyeIcon && type === 'password' && (
          <div className="input-v2__eye-icon" onClick={handleToggleInputValue}>
            {showInputValue ? <span className="material-symbols-outlined">visibility_off</span> : <span className="material-symbols-outlined">visibility</span>}
          </div>
        )}
      </div>
      {error && <div className="input-v2__error">{error}</div>}
      {infoText && <div className="input-v2__info">{infoText}</div>}
    </div>
  )
}

export default Input
import { useContext, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment'

import { MainLayout } from '../layouts'
import { CreateEditAlertModal } from '../components/misc'
import { GlobalContext, AlertContext, TeamContext } from '../context'

const AlertsPage = () => {
  const { t } = useContext(GlobalContext)
  const { alerts, alertsFetched, fetchAlerts } = useContext(AlertContext)
  const { selectedTeam, teamChanged, setTeamChanged } = useContext(TeamContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [events, setEvents] = useState([])
  const [eventsAdded, setEventsAdded] = useState(false)
  const [showView, setShowView] = useState(false)
  const [currentViewStart, setCurrentViewStart] = useState(0)
  const [currentViewEnd, setCurrentViewEnd] = useState(0)
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false)
  const [selectedTimestamp, setSelectedTimestamp] = useState(null)
  const history = useHistory()

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshEvents()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch alerts if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!alertsFetched) {
        // console.log('fetch alerts')
        fetchAlerts(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, alertsFetched, fetchAlerts])

  // Setup events 
  const setupEvents = useCallback(() => {
    const eventsArr = []
    alerts.forEach(a => eventsArr.push({ 
      title: a.title, 
      date: a.dateTimestamp ? moment.unix(a.dateTimestamp / 1000).format('YYYY-MM-DD') : '',
      documentId: a.documentId,
    }))
    setEvents(eventsArr)
    setEventsAdded(true)
  }, [alerts])

  // Set events
  useEffect(() => {
    if(alertsFetched && !eventsAdded) {
      setupEvents()
    }
  }, [setupEvents, alertsFetched, eventsAdded])

  // Refresh events
  const refreshEvents = () => {
    setEventsAdded(false)
  }

  // On list btn click
  const handleListBtnClick = () => {
    setShowView(!showView)
  }

  // On add btn click
  const handleAddBtnClick = () => {
    setShowCreateAlertModal(true)
  }

  // On close create alert modal
  const handleCloseCreateAlertModal = () => {
    setShowCreateAlertModal(false)
    setSelectedTimestamp(null)
  }

  // On plus click
  const handlePlusClick = (date, isToday) => {
    if(!date) return 
    if(isToday) {
      let hours = new Date().getHours() + 1
      let seconds = hours * 60 * 60
      setSelectedTimestamp((new Date(date).getTime() / 1000) + seconds)
    }else {
      setSelectedTimestamp((new Date(date).getTime() / 1000) + 28800) // 28800 is 8 hours in seconds, alert time can be set from 8-19 hours
    }
    setShowCreateAlertModal(true)
  }

  // On alert title click
  const handleTitleClick = (docId) => {
    history.push(`/document-detail/${docId}`, { openAlert: true })
  }

  // On set current date timestamps
  const handleSetCurrentDateTimestamps = (arg) => {
    setCurrentViewStart(new Date(arg.start).getTime())
    setCurrentViewEnd(new Date(arg.end).getTime())
  }

  // Render day cell content
  const renderDayCellContent = (args) => {
    let showAddIcon = true
    if(args.isToday) {
      let hours = new Date().getHours() + 1
      if(hours > 19) {
        showAddIcon = false
      }
    }
    return (
      <>
        <span className="num">{args.dayNumberText}</span>
        {!args.isOther && !args.isPast && showAddIcon && (
          <span className="plus" onClick={() => handlePlusClick(args.date, args.isToday)}>
            <span className="material-symbols-outlined">add</span>
          </span>
        )}
      </>
    )
  }

  // Render alert
  const renderAlert = (a, idx) => {
    return (
      <div key={idx} className="alerts-calendar__view_item">
        <p className={`text-style-5 u-cursor--pointer`} onClick={() => handleTitleClick(a.documentId)}>{a.title}</p>
        <div className={`date date-style-2 ${Date.now() > a.dateTimestamp ? 'already-passed' : ''}`}>
          <span>{a.dateTimestamp && moment.unix(a.dateTimestamp / 1000).format('DD MMM YYYY')}</span>
          {a.dateTimestamp && moment.unix(a.dateTimestamp / 1000).format('HH:mm')}
        </div>
      </div>
    )
  }

  return (
    <MainLayout
      title={t('dashboard.alerts')}
    >
      {alertsFetched && eventsAdded ? (
        <div className={`alerts-calendar ${showView ? 'with-view' : ''}`}>
          <FullCalendar 
            datesSet={handleSetCurrentDateTimestamps}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            locale="fr"
            customButtons={{
              listBtn: {
                text: <><span className="material-symbols-outlined">list_alt</span> Vue liste {showView && <span className="material-symbols-outlined close-icon">close</span>}</>,
                click: handleListBtnClick
              },
              addBtn: {
                text: <span className="material-symbols-outlined">add</span>,
                click: handleAddBtnClick
              }
            }}
            headerToolbar={{
              start: 'title',
              center: '',
              end: 'today prev next listBtn addBtn',
            }}
            buttonText={{
              today: t('general.today')
            }}
            firstDay={1}
            viewClassNames="fc-alerts-table-wrapper"
            dayCellContent={renderDayCellContent}
            events={events}
            eventClick={info => history.push(`/document-detail/${info.event.extendedProps.documentId}`, { openAlert: true })}
          />

          {showView && (
            <div className="alerts-calendar__view u-custom-scrollbar u-custom-scrollbar--3">
              {alerts.filter(a => a.dateTimestamp > currentViewStart && a.dateTimestamp < currentViewEnd).map((a, idx) => renderAlert(a, idx))}
            </div>
          )}
        </div>
      ) : (
        <div className="loader-wrapper"><div className="loader-v2"></div></div>
      )}

      {showCreateAlertModal && (
        <CreateEditAlertModal 
          onClose={handleCloseCreateAlertModal}
          selectedTimestamp={selectedTimestamp}
          refreshCollection={refreshEvents}
        />
      )}
    </MainLayout>
  )
}

export default AlertsPage
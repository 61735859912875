import { useState, useContext, useEffect, useCallback } from 'react'

import { Modal, Textarea } from '../../new_ui'
import { GlobalContext } from '../../../context'
import { useVariablesActions } from '../../../hooks'

const ImportVariablesModal = ({ onClose, refreshVariables }) => {
  const { t } = useContext(GlobalContext)
  const { importVariables } = useVariablesActions()
  const [data, setData] = useState('')

  // Create click handler
  const handleSave = useCallback(async () => {
    let success = false 
    success = await importVariables({ data, onClose })
    if(success) {
      refreshVariables()
    }
  }, [data, onClose, importVariables, refreshVariables])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSave(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSave])

  return (
    <Modal 
      onClose={onClose}
      title={t('dashboard.import_variables')}
      saveBtnText={t('dashboard.create')}
      onSave={handleSave}
    >
      <div className="create-variable">
        <div className="create-variable__body">
          <Textarea 
            value={data} 
            onChange={(e) => setData(e.target.value)} 
            className="import-textarea"
            placeholder={`${t('dashboard.enter_json_data')}
[
  {
    "variable": "var_test",
    "display_name": "Test variable",
    "tooltip": "Enter test variable value here",
    "question": "What is your test variable?",
    "type": "select",
    "category": "category_id",
    "options": [
      {
        "label": "Option A",
        "value": "a"
      },
      {
        "label": "Option B",
        "value": "b"
      }
    ]
  },
  {
    "variable": "var_info",
    "display_name": "..."
  }
]
            `} 
          />
        </div>
      </div>
    </Modal>
  )
} 

export default ImportVariablesModal
const email_template = (text, btn_url, btn_text, type = '') => {

  let textStyles = 'font-size:24px;font-weight:bold;line-height:29px;'

  if(type === 'share-doc') {
    textStyles = 'font-size:16px;font-weight:normal;line-height:21px;'
  }

  return (
    `
    <html>
      <head>
        <style>
          .carbon-table {
            font-family: Helvetica, sans-serif;
          }
      
          .carbon-main-wrapper {
            background-color:#fff;
            max-width:750px;
            width: 100%;
            margin:0 auto;
            padding:71px 0 82px;
            font-family:Helvetica;
          }
        </style>
      </head>
      <body>
        <table style="width:100%;">
          <tbody>
            <tr>
              <td style="text-align:center;">
                <table style="max-width:750px;width:100%;margin:auto;">
                  <tbody><tr>
                    <td>
                      <div class="carbon-main-wrapper">
                        <table style="width:100%;margin-bottom:30px;">
                          <tbody>
                            <tr>
                              <td style="text-align:center;">
                                <table style="margin:auto;">
                                  <tbody><tr>
                                    <td><img src="https://getcarbon.ai/assets/carbon_logo.png" alt="icon" style="margin-right:7px;vertical-align:middle;"></td>
                                    <td><span style="font-size:24px;font-weight:bold;color:#10203E;vertical-align:middle;height:34px;">Carbon</span></td>
                                  </tr>
                                </tbody></table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div style="border:1px solid #E2E6E8;border-radius:3px;padding:45px 60px 59px;margin-bottom:35px;">
                          <div style="${textStyles}text-align:center;color:#1E1E1E;margin-bottom:40px;">${text}</div>
                          <div style="margin-bottom:73px;text-align:center;">
                            <img src="https://getcarbon.ai/assets/documents_preview.png" alt="documents preview" style="display:inline-block;max-width:100%;">
                          </div>
                          <div style="text-align:center;">
                            <a href="${btn_url}" style="display:inline-block;border-radius:6px;box-shadow:3px 4px 4px 0 rgba(182,182,182,0.5);border-right:65px solid #42CA49;border-left:65px solid #42CA49;border-top:17px solid #42CA49;border-bottom:17px solid #42CA49;background-color:#42CA49;color:#fff;font-size:18px;line-height:22px;font-weight:bold;text-transform:uppercase;text-decoration:none;">${btn_text}</a>
                          </div>
                        </div>
                          <table class="carbon-table" style="padding: 20px 46px 24px;background:#F6F8F9;">
                            <tbody>
                              <tr>
                                <td style="font-size:13px;line-height:18px;color:#868890;text-align:left;"><span style="color:#4A4B50;">Carbon</span> vous permet de gérer vos documents jurdiques tout au long de leur cycle de vie en un seul flux rationalisé. Automatisez chaque étape de ce processus grâce aux multiples fonctionnalités disponibles dans Carbon.</td>
                              </tr>
                              <tr>
                                <td style="padding-top:15px">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td><img src="https://getcarbon.ai/assets/carbon_logo_2.png" alt="icon" style="margin-right:7px;vertical-align:middle;"></td>
                                        <td><span style="font-size:24px;font-weight:bold;color:#10203E;vertical-align:middle;height:34px;">Carbon</span></td>
                                      </tr>
                                    </tbody>
                                  </table> 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
    `
  )
}

export {
  email_template
}
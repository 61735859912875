import React, { useState, useContext } from 'react';

import Popup from '../../UI/Popup';
import Textarea from '../../UI/Textarea';
import { validateEmail } from '../../../helpers/validate';
import { teamInvite } from '../../../services/functions';
import { TeamContext, NotificationContext, UserContext, GlobalContext } from '../../../context';

const InviteUsersModal = ({ onClose, user, team, setLoading }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { fetchTeams, selectedTeam } = useContext(TeamContext);
  const { setNotification } = useContext(NotificationContext);
  const { membership } = useContext(UserContext);
  const [value, setValue] = useState('');

  const submitHandler = async () => {
    const emails = value.split('\n');
    const filtered = emails.filter(email => email !== ''); // remove empty values
    // Check if field empty
    if(filtered.length === 0) {
      return setNotification({ msg: t('notification.required_field'), type: 'danger' });
    }
    // Check if emails are valid
    let valid = true;
    for(let i = 0; i < filtered.length; i++) {
      if(!validateEmail(filtered[i])) {
        valid = false;
        break;
      }
    }
    // Check if membership is not premium, in this case only one user can be invited
    // if(selectedTeam?.membership === 'free' && (selectedTeam.users.length > 1 || filtered.length > 1)) {
    //   return setNotification({ msg: t('notification.limited_invitations'), type: 'danger' });
    // }
    // If any email is not valid show notification/error
    if(!valid) return setNotification({ msg: t('notification.invalid_email'), type: 'danger' });
    // Check if users with added email is already in team
    let alreadyTeamMember = '';
    for(let i = 0; i < filtered.length; i++) {
      if(team.users_emails.includes(filtered[i])) {
        alreadyTeamMember = filtered[i];
        break;
      }
    }
    if(alreadyTeamMember) return setNotification({ msg: t('notification.user_already_team_member', { email: alreadyTeamMember }), type: 'info' });
    
    // send message to valid email addresses
    if(filtered.length > 0) {
      try {
        setLoading(true);
        await teamInvite({ 
          teamId: team.id, 
          emails: filtered
        }, selectedLang);
        await fetchTeams(team.id, user, membership);
        setLoading(false);
        onClose();
        setNotification({ msg: t('notification.message_sent'), type: 'success' });
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <Popup 
      onClose={onClose}
      title={t('team.modal_title')}
      submitBtnText={t('general.send_to')}
      hideCancelBtn
      alignFootRight
      className="invite-popup"
      onSubmit={submitHandler}
    >
      <div className="invite-users">
        <p>{t('team.to')}</p>
        <Textarea value={value} onChange={(e) => setValue(e.target.value)} formEl thickBorder />
      </div>
    </Popup>
  );
}

export default InviteUsersModal;
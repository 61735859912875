import React, { useState, useContext, memo } from 'react'
import { FirstPage, ArrowLeft, ArrowRight, LastPage } from '@material-ui/icons'
import { Document } from 'react-pdf'

import IconButton from '../../UI/IconButton'
import Loader from '../../UI/Loader'

import { GlobalContext } from '../../../context'

const DocumentDetailSPPreview = ({ docPages = 1, onPageChange, uploadedDocData }) => { 
  const { t } = useContext(GlobalContext)
  const [selectedPage, setSelectedPage] = useState(0)

  // On show first page
  const handleShowFirstPage = () => {
    setSelectedPage(0)
    onPageChange(0)
  }

  // On show prev page
  const handleShowPrevPage = () => {
    setSelectedPage(selectedPage - 1)
    onPageChange(selectedPage - 1)
  }

  // On show next page
  const handleShowNextPage = () => {
    setSelectedPage(selectedPage + 1)
    onPageChange(selectedPage + 1)
  }

  // On show first page
  const handleShowLastPage = () => {
    setSelectedPage(docPages.length - 1)
    onPageChange(docPages.length - 1)
  }

  // On page change
  const handleSelectPageChange = (e) => {
    setSelectedPage(+e.target.value)
    onPageChange(+e.target.value)
  }

  return(
    <div className="document-detail-sp-section document-detail-sp-section--full-height">
      <h4 className="with-border">{t('dashboard.document')}</h4>
      {!true 
        ? (
          <div className="loader-wrapper loader-wrapper--no-padding">
            <Loader small normal />
          </div>
        ) : (
        <div className="document-detail-sp-preview">
          <div className="document-detail-sp-preview__pagination">
            <IconButton primaryLight medium icon={<FirstPage />} onButtonClick={handleShowFirstPage} disabled={selectedPage === 0} />
            <IconButton primaryLight medium icon={<ArrowLeft />} onButtonClick={handleShowPrevPage} disabled={selectedPage === 0} />
            <select onChange={handleSelectPageChange} value={selectedPage}>
              {Array.from(Array(docPages.length).keys()).map((_, idx) => (
                <option key={idx} value={idx}>{idx + 1}</option>
              ))}
            </select>
            <IconButton primaryLight medium icon={<ArrowRight />} onButtonClick={handleShowNextPage} disabled={selectedPage === docPages.length - 1} />
            <IconButton primaryLight medium icon={<LastPage />} onButtonClick={handleShowLastPage} disabled={selectedPage === docPages.length - 1} />
          </div>
          <div className="document-detail-sp-preview__thumbs u-custom-scrollbar">
            {uploadedDocData ? 
              <Document file={uploadedDocData} renderMode="canvas" loading=''>
                {docPages}
              </Document>
              : <div className="loader-wrapper"><Loader small normal /></div>
            }
          </div>
        </div>
        )}
    </div>
  );
}

export default memo(DocumentDetailSPPreview);
import { GET_TAGS, SET_TAGS, RESET_STATE, SET_TAGS_OBJ } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_TAGS:
      return {
        ...state,
        tags: action.payload,
        tagsFetched: true,
        tagsObj: action.obj,
      }
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      }
    case SET_TAGS_OBJ:
      return {
        ...state,
        tagsObj: action.payload,
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
import React, { useEffect, useState, useContext } from 'react'
import HistoryIcon from '@material-ui/icons/History'

import Modal from '../../UI/Modal'
import HistoryBox from '../../UI/HistoryBox'
import LineLoader from '../../UI/LineLoader'
import Message from '../../UI/Message'

import moment from 'moment'
import { GlobalContext } from '../../../context'

const dayFormat = 'DD/MM/YYYY'

const TemplateHistoryModal = ({ onClose, loading, history, onSetHistory }) => {
  const { t } = useContext(GlobalContext)
  const [days, setDays] = useState([])

  useEffect(() => {
    // process history into an array of days
    let daysMap = {}
    let daysArray = []
    for(let i = 0; i < history.length; i++) {
      let version = history[i]
      let dayValue = moment(version.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          versions: []
        }
      }
      daysMap[dayValue].versions.push(version)
    }
    for(let d in daysMap) {
      daysMap[d].versions.sort((a, b) => {
        if(a.created < b.created) return 1
        else if(a.created > b.created) return -1
        return 0
      })
      daysArray.push(daysMap[d])
    }
    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return 1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return -1
      return 0
    })
    setDays([...daysArray])
    setTimeout(() => onSetHistory(prev => ({ ...prev, isLoading: false })), 300)

  }, [history, onSetHistory])

  const isToday = (dayValue) => {
    return moment().format(dayFormat) === dayValue
  }

  const dayTitle = (day) => {
    if(isToday(day)) {
      return t('general.today')
    }
    return day
  }
  
  const renderTime = (time) => {
    return moment(time).format('HH[h]mm')
  }

  const renderDay = (day, dayIndex) => {
    return (
      <div key={`day_${dayIndex}`} className="history__section">
        <h3>{ dayTitle(day.dayValue) }</h3>
        { day.versions.map((version, versionIndex) => {
          return (
            <HistoryBox 
              key={`version_${dayIndex}_${versionIndex}`}
              time={renderTime(version.created)}
              description={version.content}
              collectionType="template"
            />
          )
        })}
      </div>
    )
  }

  return(
    <Modal onClose={onClose} bg2>
      <div className="history">
        <h2><HistoryIcon /> {t('template_history.title')}</h2>

        { loading ? (
          <LineLoader />
        ) : (
          <div>
            { days.length > 0 ? days.map(renderDay) : <Message type="primary" msg={t('template_history.no_history')} />}
          </div> ) }
      </div>
    </Modal>
  );
}

export default TemplateHistoryModal
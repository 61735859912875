import { GET_SHARED_TEMPLATE_PAGES, SET_SHARED_TEMPLATE_PAGES, GET_SINGLE_SHARED_TEMPLATES, SET_SINGLE_SHARED_TEMPLATES, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_SHARED_TEMPLATE_PAGES:
      return {
        ...state,
        sharedTemplatePages: action.payload,
        sharedTemplatePagesFetched: true,
      }
    case GET_SINGLE_SHARED_TEMPLATES:
      return {
        ...state,
        singleSharedTemplates: action.payload,
        singleSharedTemplatesFetched: true,
      }
    case SET_SHARED_TEMPLATE_PAGES:
      return {
        ...state,
        sharedTemplatePages: action.payload,
      }
    case SET_SINGLE_SHARED_TEMPLATES:
      return {
        ...state,
        singleSharedTemplates: action.payload,
      }
    case RESET_STATE:
      return action.payload
    default:
      return state
  }
}

export default reducer
import React, { useEffect, useContext } from 'react'
import { ReactSortable } from 'react-sortablejs'

import Modal from '../../UI/Modal'
import Button from '../../UI/Button'
import { GlobalContext } from '../../../context'

const OrderCustomersModal = ({ onClose, orderedSignatairesArr, onSetOrderedSignatairesArr, onSetEditStartTime, onStoreEditTime, windowVisibility, editStartTime }) => {
  const { t } = useContext(GlobalContext)

  // Store time when visibility changes to hidden and track it again when it is visible
  useEffect(() => {
    if(windowVisibility === 'hidden' && editStartTime) {
      onStoreEditTime()
    }else if(windowVisibility === 'visible' && !editStartTime) {
      onSetEditStartTime(Date.now())
    }
  }, [windowVisibility, editStartTime])

  // Close handler
  const closeHandler = () => {
    onStoreEditTime()
    onClose()
  }

  return (
    <Modal onClose={closeHandler} small>
      <div className="sortable-wrapper">
        <ReactSortable list={orderedSignatairesArr} setList={onSetOrderedSignatairesArr} animation={200} delayOnTouchStart={true} delay={2}>
          {orderedSignatairesArr.map((item, i) => (
            <div className="sortable-list-item" key={i}>{item.email.value}</div>
          ))}
        </ReactSortable>
        <Button text={t('general.confirm')} fullWidth primary medium onButtonClick={closeHandler} className="mt-20" />
      </div>
    </Modal>
  )
}

export default OrderCustomersModal
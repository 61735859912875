import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { setHours, setMinutes, isSameDay } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '../../UI/Modal';
import TagsInput from '../../UI/TagsInput';
// import CustomSelect from '../../UI/CustomSelect';
import Input from '../../UI/Input';
import Textarea from '../../UI/Textarea';
import Button from '../../UI/Button';
import ResponseLoader from '../../UI/ResponseLoader';
import ContractPicker from '../../UI/ContractPicker';
import CustomTimeInput from '../../UI/CustomTimeInput';
import { AlertContext, NotificationContext, UserContext, TeamContext, DocumentsContext, GlobalContext } from '../../../context';

const defaultExcludedTimes = [
  setHours(setMinutes(new Date(), 0), 0),
  setHours(setMinutes(new Date(), 0), 1),
  setHours(setMinutes(new Date(), 0), 2),
  setHours(setMinutes(new Date(), 0), 3),
  setHours(setMinutes(new Date(), 0), 4),
  setHours(setMinutes(new Date(), 0), 5),
  setHours(setMinutes(new Date(), 0), 6),
  setHours(setMinutes(new Date(), 0), 7),
  setHours(setMinutes(new Date(), 0), 20),
  setHours(setMinutes(new Date(), 0), 21),
  setHours(setMinutes(new Date(), 0), 22),
  setHours(setMinutes(new Date(), 0), 23),
]

const notExcludedTimes = [
  setHours(setMinutes(new Date(), 0), 8),
  setHours(setMinutes(new Date(), 0), 9),
  setHours(setMinutes(new Date(), 0), 10),
  setHours(setMinutes(new Date(), 0), 11),
  setHours(setMinutes(new Date(), 0), 12),
  setHours(setMinutes(new Date(), 0), 13),
  setHours(setMinutes(new Date(), 0), 14),
  setHours(setMinutes(new Date(), 0), 15),
  setHours(setMinutes(new Date(), 0), 16),
  setHours(setMinutes(new Date(), 0), 17),
  setHours(setMinutes(new Date(), 0), 18),
  setHours(setMinutes(new Date(), 0), 19),
]

const getExcludeTimesForDate = (date) => {
  let selectedDate = date
  if(!date) selectedDate = new Date()
  return notExcludedTimes.filter((time) => {
    if(isSameDay(selectedDate, time)) {
      const timestamp = new Date(time).getTime()
      return Date.now() > timestamp
    }
    return false
  })
}

const CreateAlertModal = ({ onClose, selectedAlert = null, setSelectedAlert, fromDocument = false, doc }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { user } = useContext(UserContext);
  const { createAlert, updateAlert } = useContext(AlertContext);
  const { setNotification } = useContext(NotificationContext);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext);
  const { updateDocument, getDocumentById, updateMultipleDocuments } = useContext(DocumentsContext);
  const [emails, setEmails] = useState(user ? [user.email] : []);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTime, setAlertTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [timeSelected, setTimeSelected] = useState(false);
  const [contractError, setContractError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [emailsError, setEmailsError] = useState(false);
  const [showAlertNotification, setShowAlertNotification] = useState(true);
  const [mode, setMode] = useState('create');
  const [isDocSelected, setIsDocSelected] = useState(false);
  const [excludedTimes, setExcludedTimes] = useState([...defaultExcludedTimes, ...getExcludeTimesForDate(alertTime)])
  const datepickerElWrapper = useRef();

  // const [alertOptions, setAlertOptions] = useState([
  //   { value: '', label: t('general.choose'), active: false, default: true },
  //   { value: 'custom', label: '', active: false, date: true },
  // ]);

  useEffect(() => {

    return () => {
      setSelectedAlert && setSelectedAlert(null)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(fromDocument) {
      setSelectedDocument(doc)
    }
  }, [fromDocument, doc])

  useEffect(() => {
    if(selectedAlert) {
      setMode('edit');
      setEmails(selectedAlert.emails);
      setAlertTitle(selectedAlert.title);
      setAlertTime(selectedAlert.dateTimestamp);
      setAlertMessage(selectedAlert.message);
      const relatedDoc = getDocumentById(selectedAlert.documentId);
      setSelectedDocument(relatedDoc || {});
      if(relatedDoc) {
        setIsDocSelected(true);
      }
    }
    // eslint-disable-next-line
  }, [selectedAlert]);

  // tags input change handler
  const tagsChangeHandler = (name, tags) => {
    setEmails(tags);
    if(emailsError) {
      setEmailsError(false);
    }
  }

  // On date fields change
  const dateChangeHandler = (date, setValue) => {
    // setTimeSelected(true);
    // if(!timeSelected) {
    //   date.setHours(9);
    // }
    if(new Date(date).getHours() === 0) {
      date.setHours(9);
    }
    if(moment(date).isValid()) {
      setValue(date);
      if(timeError) {
        setTimeError(false);
      }
    }
  }

  // Contract change handler
  const contractChangeHandler = (doc) => {
    setSelectedDocument(doc);
    if(alertTitle === '' || alertTitle.startsWith('Alerte:')) {
      setAlertTitle(t('doc_alert.default_title', { title: doc.name }));
    }
    if(contractError) {
      setContractError(false);
    }
  }

  // Input change handler 
  const inputChangeHandler = (e, setValue, error, setError) => {
    const val = e.target.value;
    setValue(val);
    if(error && val.trim() !== '') {
      setError(false);
    }
  }

  // Update or create alert
  const formSubmitHandler = useCallback(async (e) => {
    e.preventDefault();
    if(Object.keys(selectedDocument).length === 0) {
      setContractError(true);
      return setNotification({ msg: t('notification.select_a_contract'), type: 'danger' });
    }

    if(!alertTime || emails.length === 0) {
      if(!alertTime) setTimeError(true)
      if(emails.length === 0) setEmailsError(true)
      return setNotification({ msg: t('notification.fill_in_all_required_fields'), type: 'danger' });
    }

    // if(mode === 'create' && documentHaveAlert(selectedDocument.id)) {
    //   return setNotification({ msg: t('notification.document_already_have_alert'), type: 'danger' });
    // }

    const timestamp = moment(alertTime).clone().valueOf();
    if(timestamp <= Date.now()) {
      return setNotification({ msg: t('notification.time_in_past'), type: 'danger' });
    }

    // setLoading(true);
    if(mode === 'create') {
      let alertData = {
        date: moment(alertTime).format('DD/MM/YYYY HH:mm'), 
        dateTimestamp: timestamp, 
        selectedVal: 'custom',
        documentId: selectedDocument.id,
        sent: false,
        emails: emails,
        message: alertMessage.trim(),
        title: alertTitle ? alertTitle.trim() : t('doc_alert.default_title', { title: selectedDocument.name }),
        team: selectedTeam?.id,
        owner: activeTeamMember.id,
        lang: selectedLang
      };
      onClose();
      createAlert(alertData, async (alertId) => {
        if(!alertId) return 

        alertData.id = alertId;
        let docAlerts = selectedDocument.doc_alerts || []
        docAlerts.push(alertId)
        await updateDocument({ doc_alerts: docAlerts, last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDocument);
        setNotification({ msg: t('notification.alert_created'), type: 'success' });
      }, () => {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        // setLoading(false);
      });
    }else {
      let alertData = {
        date: moment(alertTime).format('DD/MM/YYYY HH:mm'), 
        dateTimestamp: timestamp, 
        documentId: selectedDocument.id,
        sent: selectedAlert.sent,
        emails: emails,
        message: alertMessage.trim(),
        title: alertTitle ? alertTitle.trim() : t('doc_alert.default_title', { title: selectedDocument.name }),
      };
      updateAlert(alertData, selectedAlert.id, async () => {
        // If new document then remove alert id from prev doc doc_alerts prop
        let docsToUpdate = []
        if(selectedAlert.documentId !== selectedDocument.id) {
          const prevDoc = getDocumentById(selectedAlert.documentId)
          if(prevDoc) {
            const docAlerts = prevDoc.doc_alerts || []
            if(docAlerts.includes(selectedAlert.id)) {
              const filteredAlerts = docAlerts.filter(alertId => alertId !== selectedAlert.id)
              docsToUpdate.push({ data: { doc_alerts: filteredAlerts, sort_alerts: filteredAlerts.length }, id: prevDoc.id })
              await updateDocument({ doc_alerts: filteredAlerts, last_modified_by: activeTeamMember.id, create_action: 'no' }, prevDoc, false)
            }
          }
        }
        const selectedDocAlerts = selectedDocument.doc_alerts || []
        if(!selectedDocAlerts.includes(selectedAlert.id)) {
          selectedDocAlerts.push(selectedAlert.id)
          docsToUpdate.push({ data: { doc_alerts: selectedDocAlerts, sort_alerts: selectedDocAlerts.length }, id: selectedDocument.id })
          await updateDocument({ doc_alerts: selectedDocAlerts, last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDocument, false);
        }
        if(docsToUpdate.length > 0) {
          updateMultipleDocuments(docsToUpdate)
        }
        setNotification({ msg: t('notification.alert_updated'), type: 'success' });
        onClose();
      }, () => {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        // setLoading(false);
      });
    }
  }, [activeTeamMember, alertMessage, alertTime, alertTitle, createAlert, emails, getDocumentById, mode, onClose, selectedAlert, selectedDocument, selectedLang, selectedTeam, setNotification, t, updateAlert, updateDocument, updateMultipleDocuments])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        formSubmitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [formSubmitHandler])

  return(
    <Modal onClose={onClose} medium>
      <div className="document-alerts-modal">
        <h2>{mode === 'create' ? t('dashboard.create_new_alert') : t('dashboard.edit_alert')}</h2>
        {showAlertNotification && <div className="document-alerts-modal__notification" onClick={() => setShowAlertNotification(false)}>
          <div className="document-alerts-modal__notification_close"><CloseIcon /></div>
          <p>{t('notification.alert_notification')}</p>
        </div>}
        <form onSubmit={formSubmitHandler} className="form">
          <div className="document-alerts-modal__fields">
            <div className="form__group form__group--2-cols">
              {!fromDocument && <ContractPicker 
                label={t('general.related_document') + ' *'} 
                onDocumentSelected={contractChangeHandler} 
                thickBorder
                error={contractError} 
                isDocSelected={isDocSelected}
                setIsDocSelected={setIsDocSelected}
                selectedDocument={selectedDocument}
              />}
              <div className={`date_picker date_picker--2 date_picker--thick-border ${timeError ? 'date_picker--error' : ''}`} ref={datepickerElWrapper}>
                <p className="date_picker__label">{t('general.alert_date')} *</p>
                <DatePicker
                  selected={alertTime}
                  onChange={date => dateChangeHandler(date, setAlertTime)}
                  dateFormat="d MMMM yyyy - HH'h'mm"
                  dropdownMode="select"
                  locale="fr"
                  // onChangeRaw={(e) => e.preventDefault()}
                  minDate={new Date()}
                  popperModifiers={{preventOverflow: { enabled: true }}} 
                  timeCaption={t('general.time')}
                  showTimeSelect
                  timeIntervals={60}
                  excludeTimes={[
                    ...defaultExcludedTimes,
                    ...excludedTimes
                  ]}
                  onSelect={(date) => {
                    setExcludedTimes(getExcludeTimesForDate(date))
                  }}
                  // customTimeInput={<CustomTimeInput setTimeSelected={setTimeSelected} />}
                />
              </div>
            </div>
            <TagsInput 
              label={t('general.recipients_emails_2') + ' *'} 
              name="emails"
              onChange={tagsChangeHandler}
              emailValidation 
              defaultTags={emails}
              thickBorder
              invalid={emailsError}
            />
            <Input 
              label={t('general.alert_title') + ` (${t('general.optional')})`}
              name="title"
              value={alertTitle}
              onChange={(e) => inputChangeHandler(e, setAlertTitle, titleError, setTitleError)}
              formElOptional 
              thickBorder
              invalid={titleError}
            />
            <Textarea 
              label={t('general.message') + ` (${t('general.optional')})`}
              name="message"
              value={alertMessage}
              onChange={(e) => setAlertMessage(e.target.value)}
              formElOptional
              thickBorder
            />
          </div>
          <div className="document-alerts-modal__actions">
            <Button text={t('general.save')} primary medium onButtonClick={formSubmitHandler} />
          </div>
        </form>
      </div>

      {loading && <ResponseLoader />}
    </Modal>
  );
}

export default CreateAlertModal;
import React, { createContext, useReducer, useState } from 'react';

import folderReducer from './documentsFoldersReducer';
import { GET_DOCUMENTS_FOLDERS, SET_DOCUMENTS_FOLDERS, SET_DOCUMENTS_FOLDERS_LOADING, RESET_STATE } from '../types';
import { fetch_documents_folders, create_documents_folder, update_documents_folder, delete_documents_folder } from '../../services/firestore';
import { sortArrayOfObjects } from '../../utils'

export const DocumentsFoldersContext = createContext();

const DocumentsFoldersState = ({ children }) => {
  const initialState = {
    loading: true,
    folders: [],
    foldersFetched: false
  }

  const [parentFolder, setParentFolder] = useState(null);
  const [folderToMove, setFolderToMove] = useState(null);
  const [folderBreadcrumbs, setFolderBreadcrumbs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [currentlyActiveDocFolder, setCurrentlyActiveDocFolder] = useState(null);
  const [docFoldersFilters, setDocFoldersFilters] = useState({
    owner: '',
    date_before: '',
    date_after: '',
    parentFolder: null,
    name: ''
  });
  const [state, dispatch] = useReducer(folderReducer, initialState);

  // Fetch folders
  const fetchFolders = async (teamId) => {
    try {
      const res = await fetch_documents_folders(teamId);
      if(res.error) {
        dispatch({
          type: GET_DOCUMENTS_FOLDERS,
          payload: []
        })
        throw new Error(res.error?.message || 'doc folders cannot be fetched')
      }
      const arr = [];
      for(let id in res) {
        const folder = {...res[id]};
        folder.id = id;
        folder.folderRow = true;
        folder.docFolder = true;
        arr.push(folder);
      }
      const sortedArr = [...arr].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: GET_DOCUMENTS_FOLDERS,
        payload: sortedArr
      });
    }catch(err) {
      console.log(err);
    }
  }

  // Create folder
  const createFolder = async (data) => {
    setFoldersLoading(true);
    try {
      const res = await create_documents_folder(data);
      const folders = [...state.folders]
      if(res.success) {
        folders.push({...data, id: res.folderId, meta: { created: Date.now(), updated: Date.now() }, docFolder: true, folderRow: true})
        setFolders(sortArrayOfObjects(folders, 'name', 'desc'))
        setFoldersLoading(false)
        // console.log({...data, id: res.folderId, meta: { created: Date.now(), updated: Date.now() }})
      }
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Set folders
  const setFolders = (value) => {
    dispatch({
      type: SET_DOCUMENTS_FOLDERS,
      payload: value
    });
  }

  // Update folder
  const updateFolder = async (data, folderId, single = true, fetch = false) => {
    setFoldersLoading(true);
    try {
      await update_documents_folder(data, folderId);
      if(single) {
        const folders = [...state.folders];
        let foundFolder = folders.find(f => f.id === folderId);
        foundFolder = {...foundFolder, ...data};
        const updatedFolders = folders.map(f => f.id === folderId ? foundFolder : f);
        setFolders(updatedFolders);
      }
      // if(!fetch) {
      //   await fetchFolders();
      // }
      setFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Update multiple folders
  const updateMultipleFolders = (arr) => {
    const folders = [...state.folders];
    const updatedFolders = [];
    arr.forEach(f => {
      const foundFolder = folders.find(fol => fol.id === f.id);
      let updatedFolder = {};
      if(foundFolder) {
        updatedFolder = {...foundFolder, ...f.data};
        updatedFolders.push(updatedFolder);
      }
    });
    const f = [...folders].map(fol => {
      const foundF = updatedFolders.find(f => f.id === fol.id);
      if(foundF) {
        return foundF;
      }else {
        return fol;
      }
    });
    setFolders(f);
  }

  // Delete folder
  const deleteFolder = async (folderId, fetch = false, teamId) => {
    setFoldersLoading(true);
    try {
      await delete_documents_folder(folderId);
      if(!fetch) {
        await fetchFolders(teamId);
      }
      setFoldersLoading(false);
    }catch(err) {
      console.log(err);
      setFoldersLoading(false); 
    }
  }

  // Delete multiple folders
  const deleteMultipleFolders = (arr) => {
    const folders = [...state.folders]
    const updatedFolders = folders.filter(f => !arr.includes(f.id))
    setFolders(updatedFolders)
  }

  // Set loading
  const setFoldersLoading = (value) => {
    dispatch({
      type: SET_DOCUMENTS_FOLDERS_LOADING,
      payload: value
    });
  }

  // Reset doc folders filters
  const resetDocFoldersFilters = () => {
    setDocFoldersFilters({
      owner: '',
      date_before: '',
      date_after: '',
      parentFolder: null,
      name: ''
    });
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, foldersFetched: true, loading: false } : initialState,
      payload: initialState,
    });
    setFolderBreadcrumbs([])
    setCurrentlyActiveDocFolder(null)
    setParentFolder(null)
    setFolderToMove(null)
    setSelectedFilter('all')
    resetDocFoldersFilters()
  }

  return <DocumentsFoldersContext.Provider value={{
    docFolders: state.folders,
    docFoldersLoading: state.loading,
    docFoldersFetched: state.foldersFetched,
    docParentFolder: parentFolder,
    docFolderToMove: folderToMove,
    docFolderBreadcrumbs: folderBreadcrumbs,
    docFoldersSelectedFilter: selectedFilter,
    currentlyActiveDocFolder,
    docFoldersFilters,
    setDocFoldersLoading: setFoldersLoading,
    fetchDocFolders: fetchFolders,
    createDocFolder: createFolder,
    updateDocFolder: updateFolder,
    deleteDocFolder: deleteFolder,
    setDocParentFolder: setParentFolder,
    setDocFolderToMove: setFolderToMove,
    setDocFolderBreadcrumbs: setFolderBreadcrumbs,
    setDocFoldersSelectedFilter: setSelectedFilter,
    updateMultipleDocFolders: updateMultipleFolders,
    deleteMultipleDocFolders: deleteMultipleFolders,
    setCurrentlyActiveDocFolder,
    resetDocFoldersState: resetState,
    setDocFoldersFilters,
    resetDocFoldersFilters,
  }}>
    {children}
  </DocumentsFoldersContext.Provider>
}

export default DocumentsFoldersState;
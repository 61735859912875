import React, { useState, useContext, useEffect, memo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import InsertPhotoIcon from '@material-ui/icons/InsertPhotoOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HttpIcon from '@material-ui/icons/Http';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { v4 as uuidv4} from 'uuid'

import Alert from '../../UI/Alert'
import CustomTooltip from '../../UI/CustomTooltip';
import Button from '../../UI/Button';
import ResponseLoader from '../../UI/ResponseLoader';
import EditAttachmentModal from './EditAttachmentModal'; 
import { NotificationContext, DocumentsContext, GlobalContext } from '../../../context';
import { get_file, get_file_from_external_url, delete_file, upload_file_with_progress } from '../../../services/firestore';
import { blobToFile, base64toBlob } from '../../../utils';
import { validateURL } from '../../../helpers/validate';

const DocumentDetailSPAttachments = ({ documentAttachments, onEditAttachment, onDeleteAttachmentByName, onSetShowUploadAttachments, onAddAttachment, docId, onSetEditStartTime, onStoreEditTime, setProgress, setUploading }) => {
  const { t } = useContext(GlobalContext)
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState({});
  const [updating, setUpdating] = useState(false);
  const [active, setActive] = useState(false);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { setCopiedAttachments, setAttachmentsFrom, copiedAttachments, attachmentsFrom, updateDocument } = useContext(DocumentsContext);
  const [attachmentsNames] = useState([...documentAttachments].map(a => a.name + '.' + a.format))
  const [showDeleteAttachmentAlert, setShowDeleteAttachmentAlert] = useState(false)
  const [attToDelete, setAttToDelete] = useState(null)

  // View attachment
  const viewAttachment = (att) => {
    if(att.data) {
      let data = att.data.split(',')[1]
      let mimeType = att.type
      var byteCharacters = atob(data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: mimeType + ';base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else if(att.url) {
      window.open(att.url, '_blank')
    }
  }

  // Edit attachment
  const editAttachmentClickHandler = (att, attIndex) => {
    setSelectedAttachment(att);
    setSelectedAttachmentIndex(attIndex);
    setShowEditModal(true);
  }

  const editAttachmentHandler = async (name, index) => {
    setUpdating(true);
    await onEditAttachment(docId, name, index);
    setUpdating(false);
    setShowEditModal(false);
  }

  // On file submit
  const getFileData = (file, fileType = '') => new Promise((resolve, reject) => {
    if (file) {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = file.name.split('.')
        const format = components[components.length - 1]
        components.splice(components.length - 1, 1)
        const name = components.join('.')
        const type = file.type ? file.type : fileType;
        // addFile({ data: e.target.result, name: name, format: format })
       
        // fileInputRef.current.value = ""
        // console.log('add file', { data: e.target.result, name: name, format: format })
        // onAddAttachment({ data: e.target.result, name: name, format: format, type: type })
        const data = { data: e.target.result, name: name, format: format, type: type }
        resolve(data)
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
        reject(err)
      }
      reader.readAsDataURL(file);
    } else {
      // no files
    }
  })

  // Upload attachment from url
  const uploadFileFromUrl = async (e) => {
    e.preventDefault();
    if(url.trim() === '') {
      return setNotification({ msg: t('notification.empty_url'), type: 'danger' });
    }
    if(!validateURL(url)) {
      return setNotification({ msg: t('notification.invalid_url'), type: 'danger' });
    }
    setLoading(true);
    try {
      const res = await get_file_from_external_url({ url, getType: true });
      if(!res.success) {
        setLoading(false);
        setUrl('');
        if(res.type === 'file-too-big') {
          return setNotification({ msg: t('notification.file_too_big'), type: 'danger' });
        }
        return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      }
      const blob = base64toBlob(res.data);
      let fileName = `file-${Date.now()}`;
      let fileType = '';
      let fileExt = '';
      if(res.file_type) {
        fileExt = res.file_type.ext;
        fileName = `${fileName}.${fileExt}`;
        fileType = res.file_type.mime;
      }else {
        setLoading(false);
        return setNotification({ msg: t('notification.no_file_extension'), type: 'danger' });
      }
      const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg', 'image/gif'];
      if(!allowedTypes.includes(fileType)) {
        setLoading(false);
        return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' });
      }
      const file = blobToFile(blob, fileName);
      // console.log(file)
      setLoading(false)
      setUploading(true)
      const data = await getFileData(file, fileType);
      const created = Date.now()
      let nameWithTimestamp = `${data.name}-${created}`
      let att = {
        name: data.name,
        att_path: `attachments/${nameWithTimestamp}.${data.format}`,
        created,
        format: data.format,
        type: data.type,
        id: uuidv4(),
        url: ''
      }

      const fileUrlRes = await upload_file_with_progress(file, nameWithTimestamp, att.format, att.type, 'attachments', (p) => {
        setProgress(Math.floor(p))
      })
      let docAttachments = [...documentAttachments]
      if(fileUrlRes.url) {
        att.url = fileUrlRes.url
        docAttachments.push(att)
        await updateDocument({ attachments: docAttachments }, { id: docId })
      }

      setUrl('');
      setUploading(false)
      setProgress(0)
    } catch (err) {
      console.log(err);
      setLoading(false);
      setUrl('');
      return setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    }
  }

  // Copy attachment click handler
  const copyAttachmentClickHandler = (att) => {
    setCopiedAttachments([att]);
    setAttachmentsFrom(docId);
    setNotification({ msg: t('notification.file_copied'), type: 'info' });
  }

  // Paste attachments
  const pasteAttachmentsHandler = () => {
    setLoading(true);
    const promises = [];
    const attachments = [...copiedAttachments];
    copiedAttachments.forEach(att => {
      if(att.url) {
        promises.push(get_file({ url: att.url }));
      }else {
        promises.push({ data: att.data.split(',')[1] });
      }
    });

    const errors = [];
    let docAttachments = [...documentAttachments]
    let mProgress = 0
    let total = 0
    
    Promise.all(promises).then(async (data) => {
      setLoading(false)
      setUploading(true)
      for(let i = 0; i < data.length; i++) {
        const item = data[i]
        const blob = base64toBlob(item.data);
        const fileName = `${attachments[i].name}.${attachments[i].format}`;
        if(attachmentsNames.includes(fileName)) {
          errors.push(fileName);
        }else {
          const file = blobToFile(blob, fileName);
          const attData = await getFileData(file, attachments[i].type);
          // console.log(attData)
          const created = Date.now()
          let nameWithTimestamp = `${attData.name}-${created}`
          let att = {
            name: attData.name,
            att_path: `attachments/${nameWithTimestamp}.${attData.format}`,
            created,
            format: attData.format,
            type: attData.type,
            id: uuidv4(),
            url: ''
          }
          // eslint-disable-next-line no-loop-func
          const fileUrlRes = await upload_file_with_progress(file, nameWithTimestamp, att.format, att.type, 'attachments', (p) => {
            let current = (Math.floor(p) / 100) * (100 / data.length)
            mProgress = total + current
            if(current >= Math.floor(100 / data.length)) {
              total += current
            }
            setProgress(Math.floor(mProgress))
          })
          
          if(fileUrlRes.url) {
            att.url = fileUrlRes.url
            docAttachments.push(att)
          }
        }
      }
      
      await updateDocument({ attachments: docAttachments }, { id: docId })
      setCopiedAttachments([]);
      setAttachmentsFrom(null);
      setUploading(false);
      if(errors.length > 0) {
        setNotification({ msg: t('notification.file_already_exist', { name: errors.join(',') }), type: 'info' });
      }

    }).catch(err => {
      console.log(err);
      setLoading(false);
      setUploading(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    });
  }

  // On set att to delete
  const handleSetAttToDelete = (att) => {
    setShowDeleteAttachmentAlert(true)
    setAttToDelete(att)
  }

  // On delete attachment
  const handleDeleteAttachment = async () => {
    if(!attToDelete) return 

    setLoading(true)
    try {
      let updatedAtts = documentAttachments.filter(a => a.id !== attToDelete.id)
      if(attToDelete.format !== 'pdf' && attToDelete.format !== 'docx') {
        const splitted = attToDelete.att_path.split('.')
        const path = [...splitted].slice(0, splitted.length - 1).join('.')
        const format = splitted[splitted.length - 1]
        const paths = [attToDelete.att_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        for(let i = 0; i < paths.length; i++) {
          await delete_file(paths[i])
        }
      }else {
        await delete_file(attToDelete.att_path)
      }
      await updateDocument({ attachments: updatedAtts }, { id: docId })
      setShowDeleteAttachmentAlert(false)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
    setAttToDelete(null)
  }

  return(
    <div className="document-detail-sp-section">
      <h4 className="with-border">
        {t('general.attachments')} 
        <CustomTooltip content={t('notification.attachment_too_big')} spaceSm><div className="icon-info"><InfoIcon /></div></CustomTooltip>
        <CustomTooltip content={t('dashboard.add_attachment')} position="left">
          <span className="icon" onClick={() => onSetShowUploadAttachments(true)}><AddIcon /></span>
        </CustomTooltip>
      </h4>
      <div className="document-detail-sp-section__attachments">
        {documentAttachments.map((att, i) => {
          let icon;

          if(att.type.includes('image')) {
            icon = <span className="img"><InsertPhotoIcon /></span>;
          }else if(att.type.includes('pdf')) {
            icon = <span className="pdf"><PictureAsPdfIcon /></span>;
          }else {
            icon = <span className="other"><i className="custom-icon-article-outlined"></i></span>;
          }

          return (<div className="document-detail-sp-section__attachments_att" key={i}>
            <div className="name"><p>{icon} {`${att.name}.${att.format}`}</p></div>
            <div className="actions">
              <CustomTooltip content={t('general.preview')} fixed>
                <span onClick={() => viewAttachment(att)}><RemoveRedEyeIcon /></span>
              </CustomTooltip>
              <CustomTooltip content={t('general.rename')} fixed>
                <span onClick={() => editAttachmentClickHandler(att, i)}><EditIcon /></span>
              </CustomTooltip>
              <CustomTooltip content={t('general.copy')} fixed>
                <span onClick={() => copyAttachmentClickHandler(att)}><FileCopyIcon /></span>
              </CustomTooltip>
              <CustomTooltip content={t('general.delete')} fixed>
                <span onClick={() => handleSetAttToDelete(att)}><DeleteIcon /></span>
              </CustomTooltip>
            </div>
          </div>);
        })}
      </div>
      {copiedAttachments.length > 0 && attachmentsFrom && attachmentsFrom !== docId && <div className="paste-attachments">
        <Button 
          icon={<i className="custom-icon-content-paste"></i>} 
          text={t('general.paste_attachments', { number: copiedAttachments.length })} 
          onButtonClick={pasteAttachmentsHandler} 
          outlinePrimary 
          medium
          fullWidth
        />
      </div>}
      <div className="add-attachment-from-url">
        <form onSubmit={uploadFileFromUrl} className={active ? 'active' : ''}>
          <div className="icon"><HttpIcon /></div>
          <input 
            type="text" 
            value={url} 
            onChange={(e) => setUrl(e.target.value)} 
            onFocus={() => { setActive(true); onSetEditStartTime(Date.now()) }} 
            onBlur={() => { url === '' && setActive(false); onStoreEditTime() }} 
            placeholder={active ? "https://" : t('general.add_attachment_from_url')} 
          />
          <Button type="submit" text={t('general.add')} primary />
        </form>
      </div>

      {showEditModal && <EditAttachmentModal 
        onClose={() => setShowEditModal(false)} 
        loading={updating} 
        attachment={selectedAttachment} 
        attachmentIndex={selectedAttachmentIndex}
        onEdit={editAttachmentHandler}
        onSetEditStartTime={onSetEditStartTime}
        onStoreEditTime={onStoreEditTime}
      />}

      {showDeleteAttachmentAlert && <Alert 
        onClose={() => setShowDeleteAttachmentAlert(false)}
        text={t('notification.delete_attachment_permanently')}
        onSubmit={handleDeleteAttachment}
        deleteAlert
      />}

      {loading && <ResponseLoader />}
    </div>
  );
}

export default memo(DocumentDetailSPAttachments);
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ authenticated, children, location, ...rest}) => {
  const prevPath = location?.state?.from?.pathname || '/documents'
  return (
    <Route
      render={() => !authenticated
        ? children 
        : <Redirect to={{pathname: '/dashboard' }} />}
      {...rest}
    />
  )
}

export default PublicRoute
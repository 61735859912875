import { useContext, useState } from 'react'

import { Alert } from '../../new_ui'
import { GlobalContext } from '../../../context'
import { useVariablesActions } from '../../../hooks'

const VariablesActions = ({ allChecked, onAllCheckedChange, selectedItems, setSelectedItems, refreshVariables }) => {
  const { t } = useContext(GlobalContext)
  const { deleteVariables, exportVariables } = useVariablesActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  // On export
  const handleExport = async (e) => {
    e.preventDefault()
    await exportVariables({ selectedVars: selectedItems, setSelectedVars: setSelectedItems })
    refreshVariables()
  }

  // On delete click
  const handleDeleteClick = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteVariables({ selectedVars: selectedItems, setSelectedVars: setSelectedItems, setShowDeleteVarsAlert: setShowDeleteAlert })
    refreshVariables()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  return (
    <div className="list-view-v2__actions">
      <div className="checkbox">
        {!allChecked 
          ? <span className="material-symbols-outlined" onClick={onAllCheckedChange}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled" onClick={onAllCheckedChange}>check_box</span>
        }
      </div>
      <p className="text-style-1">{t('dashboard.num_of_selected_items', { number: selectedItems.length })}</p>
      <div className="buttons">
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleExport}>
          <span className="material-symbols-outlined icon">download</span> 
          <span className="text">{t('dashboard.export_as_json')}</span>
        </a>
        <a href="/#" className="btn btn--primary-light btn--small" onClick={handleDeleteClick}>
          <span className="material-symbols-outlined icon">delete</span> 
          <span className="text">{t('general.remove')}</span>
        </a>
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_selected_variables')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
    </div>
  )
}

export default VariablesActions
import { useContext, useState } from 'react'

import CustomTooltip from '../components/UI/CustomTooltip'
import { SettingsLayout } from '../layouts'
import { InviteUsersModal } from '../components/misc'
import { LetterCircle } from '../components/new_ui'
import { GlobalContext, UserContext, TeamContext } from '../context'
import { hasSufficientMembership } from '../utils'

const Teams = () => {
  const { t } = useContext(GlobalContext)
  const { user } = useContext(UserContext)
  const { teams } = useContext(TeamContext)
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false)

  return (
    <SettingsLayout title={t('team.title')}>
      <div className="teams settings-pages-wrapper">
        <h4 className="heading-4">{t('team.your_teams_title')}</h4>
        <ul className="teams__list">
          {teams.map((tm, i) => (
            <li className="teams__list_item" key={i}>
              <div className="item-left">
                {tm.logo_url 
                  ? <div className="item-left__logo-box" style={{ backgroundImage: `url(${tm.logo_url})`}}></div>
                  : <LetterCircle data={tm.name} hideTooltip={true} useOnlyFirstLetter={true} />
                }
                <p className="item-left__title">{tm.name}</p>
              </div>
              <div className="item-right">
                <div className="item-right__users">
                  {tm.users.map((u, j) => {
                    return <CustomTooltip key={j} content={u.email}>
                      {u.image_url 
                        ? <div className="item-right__users_img"  style={{ backgroundImage: `url(${u.image_url})`}}></div>
                        : <LetterCircle data={u.first_name || u.email} />
                      }
                    </CustomTooltip>
                  })}
                </div>
                { tm.owner === user.id && 
                  <button className="btn" onClick={() => setShowInviteUsersModal(true)} disabled={!hasSufficientMembership(tm.membership, 'premium')}>{t('team.invite')}</button>
                  }
              </div>
            </li>
          ))}
        </ul>
      </div>

      {showInviteUsersModal && <InviteUsersModal 
        onClose={() => setShowInviteUsersModal(false)} 
      />}
    </SettingsLayout>
  )
}

export default Teams 
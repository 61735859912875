import firebase, { firebaseEnvDoc } from './firebase'

const COLLECTION = 'shared_template_pages'

// Fetch shared template pages
const fetch_shared_template_pages = async (teamId) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).get()
    let result = {}
    res.docs.forEach(doc => {
      result[doc.id] = doc.data()
    })
    return result
  } catch (err) {
    console.log('get shared template pages err', err)
  }
}

// Get shared template page by id
const get_shared_template_page_by_id = async (id, teamId, isPublic = false) => {
  try {
    let res
    if(isPublic) {
      res = await firebaseEnvDoc.collection(COLLECTION).where(firebase.firestore.FieldPath.documentId(), '==', id).get()
    }else {
      res = await firebaseEnvDoc.collection(COLLECTION).where('team', '==', teamId).where(firebase.firestore.FieldPath.documentId(), '==', id).get()
    }
    if(res.docs.length === 0) {
      return { exists: false }
    }
    return res.docs[0]
  } catch (err) {
    console.log('get shared template page by id err', err)
  }
}

// Check if shared template page is disabled
const check_if_shared_template_page_disabled = async (id) => {
  try {
    let res = await firebaseEnvDoc.collection(COLLECTION).where(firebase.firestore.FieldPath.documentId(), '==', id).where('share', '==', true).where('disabled', '==', false).get()
    if(res.docs.length === 0) {
      return true
    }
    return false
  } catch (err) {
    console.log('get shared template page by id err', err)
  }
}

// Create new shared template page
const create_shared_template_page = async (data) => {
  try {
    const res = await firebaseEnvDoc.collection(COLLECTION).add(data)
    return res.id
  } catch (err) {
    console.log('create shared template page error', err)
  }
}

// Delete shared template page
const delete_shared_template_page = async (id) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).delete()
  } catch (err) {
    console.log('delete shared template page error', err)
  }
}

// Update shared template page
const update_shared_template_page = async (id, data) => {
  try { 
    await firebaseEnvDoc.collection(COLLECTION).doc(id).update(data)
  } catch (err) {
    console.log('update shared template page error', err)
  }
}

export {
  fetch_shared_template_pages,
  get_shared_template_page_by_id,
  check_if_shared_template_page_disabled,
  create_shared_template_page,
  delete_shared_template_page,
  update_shared_template_page,
}
import { useState, useEffect, useContext, memo } from 'react'
import moment from 'moment'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import { Alert, LetterCircle } from '../../new_ui'
import { SignaturePreview, PreviewModal } from '../'
import { GlobalContext, ConstantsContext } from '../../../context'
import { useSingleSignatureActions } from '../../../hooks'

const SignaturesRow = ({ doc, id, selectedSignatures, setSelectedSignatures, allChecked, columns, refreshSignatures, onStatusClick }) => {
  const { t } = useContext(GlobalContext)
  const { SIGNATURE_STATUS_PROVIDERS } = useContext(ConstantsContext)
  const { downloadSignature, deleteSingleSignature, refreshStatus, previewSignature } = useSingleSignatureActions()
  const [checked, setChecked] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')
  const [previewLoading, setPreviewLoading] = useState(false)
  const [previewError, setPreviewError] = useState('')

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedSignatures.length === 0) {
      setChecked(false)
    }
  }, [selectedSignatures, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked, doc])

  // On select
  const handleSelect = () => {
    setChecked(!checked)
    if(checked) {
      setSelectedSignatures(prev => prev.filter(d => d.id !== id))
    }else {
      setSelectedSignatures(prev => [...prev, doc])
    }
  }

  // Print date
  const printDate = (date) => {
    if(!date) return ''
    return moment(date).format('DD MMM YY')
  }

  // Print time
  const printTime = (date) => {
    if(!date) return ''
    return moment(date).format('HH:mm')
  }

  // On download
  const handleDownload = async (e) => {
    e.preventDefault()
    await downloadSignature({ doc })
    window.document.body.click()
  }

  // On delete click
  const handleDeleteClick = async (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteSingleSignature({ signatureToDelete: doc, setShowDeleteSingleSignatureAlert: setShowDeleteAlert })
    refreshSignatures()
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On title click
  const handleTitleClick = async (e) => {
    e && e.preventDefault()
    setShowPreviewModal(true)
    await previewSignature({ doc, setPreviewLoading, setShowPreviewDocModal: setShowPreviewModal, setIframeSrc, setPreviewError })
  }

  // On close preview modal
  const handleClosePreviewModal = () => {
    setShowPreviewModal(false)
  }

  // On status refresh
  const handleRefreshStatus = async () => {
    await refreshStatus({ doc, setRefreshing, setRefreshed })
    refreshSignatures(doc)
  }

  // refresh icon class
  const getRefreshIconClass = () => {
    let refreshIconClass = 'refresh-wrapper'

    if(refreshing) {
      refreshIconClass = 'refresh-wrapper refreshing'
    }
    if(refreshed || doc.refreshed) {
      refreshIconClass = 'refresh-wrapper refreshed'
    }

    return refreshIconClass
  }

  // On status click
  const handleStatusClick = () => {
    onStatusClick(doc)
  }

  // Render checkbox column
  const renderCheckboxColumn = () => {
    return (
      <div className={`list-view-table__cell checkbox`}>
        {!checked 
          ? <span className="material-symbols-outlined checkbox-icon" onClick={handleSelect}>check_box_outline_blank</span>
          : <span className="material-symbols-outlined filled checkbox-icon" onClick={handleSelect}>check_box</span>
        }
        <span className="material-symbols-outlined row-icon">description</span>
      </div>
    )
  }

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined">more_horiz</span></button>}
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/" onClick={handleTitleClick}>
              <span className="icon"><span className="material-symbols-outlined">remove_red_eye</span></span>
              {t('general.preview')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleDownload}>
              <span className="icon"><span className="material-symbols-outlined">download</span></span>
              {t('general.download')}
            </a>
          </li>
          <li>
            <a href="/" onClick={handleDeleteClick} className="delete">
              <span className="icon"><span className="material-symbols-outlined">delete</span></span>
              {t('general.remove')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render title column
  const renderTitleColumn = (col, i) => {
    return (
      <div 
        className={`list-view-table__cell u-truncate-text-wrapper title-col`} 
        key={i}
      >
        <p className={`text-style-1 u-truncate-text title`} onClick={handleTitleClick}>{doc.title}</p>
        <div className="show-on-hover">
          {renderSingleDocActions()}
        </div>
      </div>
    )
  }

  // Render date column
  const renderDateColumn = (col, i) => {
    const date = printDate(doc.createdAt)
    const time = printTime(doc.createdAt)
    const content = (
      <div className="u-truncate-text">
        <CustomTooltip content={<span>{time}</span>}>
          <span className="normal">{ date }</span>
        </CustomTooltip>
      </div>
    )

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper date-col" key={i}>
        { content }
      </div>  
    )
  }

  // Render recipients column
  const renderRecipientsColumn = (col, i) => {
    let content = doc.recipients?.map((r, idx) => (
      <LetterCircle key={idx} data={r.email} />
    ))

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="letter-circle-wrapper u-truncate-text">
          { content }
        </div>
      </div>  
    )
  }

  // Render sentBy column
  const renderSentByColumn = (col, i) => {
    let content = <LetterCircle data={doc.sentBy} />

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="letter-circle-wrapper u-truncate-text">
          { content }
        </div>
      </div>  
    )
  }

  // Render status column
  const renderStatusColumn = (col, i) => {
    let status = ''
    if(doc.status === 'COMPLETED') {
      status = 'validated'
    }else if(doc.status === 'SIGNING_PENDING') {
      status = 'to_validate'
    }else {
      status = 'draft'
    }

    let content = (
      <div className="signature-status-wrapper u-truncate-text">
        <div className={`status-v2 ${status} u-cursor--pointer`} onClick={handleStatusClick}>{SIGNATURE_STATUS_PROVIDERS[doc.status]}</div>
        {doc.status_updated_at 
          ? (
          <CustomTooltip content={`${t('status.last_checked')} ${moment(doc.status_updated_at).format('DD MMM YYYY à HH[h]mm')}`}>
            <div className={getRefreshIconClass()} onClick={handleRefreshStatus}>
              <span className="material-symbols-outlined">refresh</span>
            </div>
          </CustomTooltip>
          ) : (
          <div className={getRefreshIconClass()} onClick={handleRefreshStatus}>
            <span className="material-symbols-outlined">refresh</span>
          </div>
        )}
      </div>
    )

    return (
      <div className="list-view-table__cell" key={i}>
        <div className="letter-circle-wrapper u-truncate-text-wrapper">
          { content }
        </div>
      </div>  
    )
  }

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell helper" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'checkbox_col') {
      content = renderCheckboxColumn(col, i)
    }else if(col.value === 'title'){ 
      content = renderTitleColumn(col, i)
    }else if(col.value === 'recipients.email__arr') {
      content = renderRecipientsColumn(col, i)
    }else if(col.value === 'sentBy') {
      content = renderSentByColumn(col, i)
    }else if(col.value === 'createdAt') {
      content = renderDateColumn(col, i)
    }else if(col.value === 'status_label') {
      content = renderStatusColumn(col, i)
    }else if(col.value === 'helper') {
      content = renderHelperColumn(col, i)
    }

    return content
  }

  return (
    <>
      <div 
        className={`list-view-table__row ${checked ? 'selected' : ''}`}
      >
        {columns.filter(c => c.checked).map((col, i) => {
          return renderColumnContent(col, i)
        })} 
        {renderCheckboxColumn()}   
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_signature')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}

      {showPreviewModal && (
        <PreviewModal 
          onClose={handleClosePreviewModal} 
          loading={previewLoading}
          error={previewError}
          data={iframeSrc}
          doc={doc}
          v2={true}
        />
      )}
      {/* {showPreviewModal && (
        <SignaturePreview 
          onClose={handleClosePreviewModal} 
          loading={previewLoading}
          error={previewError}
          iframeSrc={iframeSrc}
        />
      )} */}
    </>
  )
}

export default memo(SignaturesRow)
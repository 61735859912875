import React, { useState, useContext, useEffect, useCallback } from 'react'
import { RadioButtonChecked, RadioButtonUnchecked, ExpandMore, ExpandLess } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import Button from '../../UI/Button'
import Loader from '../../UI/Loader'
import Modal from '../../UI/Modal'

import { ApprovalsContext, TeamContext, DocumentsContext, NotificationContext, GlobalContext } from '../../../context'
import LetterCircle from '../../UI/LetterCircle'

const ChooseApprovalModal = ({ onClose, doc, onSetDocApproval, onSetApprovalLoading, onSetApprovalNotFound }) => {
  const { t } = useContext(GlobalContext)
  const { approvals, approvalsFetched, fetchApprovals, singleApprovalFetched, fetchOtherApprovals } = useContext(ApprovalsContext)
  const { updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const [selectedApproval, setSelectedApproval] = useState(null)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const history = useHistory()

  // Fetch approvals if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!approvalsFetched) {
        // console.log('fetch approvals')
        if(!singleApprovalFetched) {
          fetchApprovals(teamId)
        }else {
          fetchOtherApprovals(teamId)
        }
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, approvalsFetched, fetchApprovals, fetchOtherApprovals, singleApprovalFetched])

  // On save
  const handleSave = useCallback(async () => {
    if(!selectedApproval) return 
    
    try {
      let steps = {}
      if(selectedApproval?.steps) {
        selectedApproval.steps.forEach(step => {
          steps[step.id] = { request_sent: false, approved_by: [], rejected_by: [], step_approved: false, status: '' }
        })
      }
      await updateDocument({ approval: { id: selectedApproval?.id, steps, added_by: activeTeamMember.id }}, doc)
      const approval = approvals.find(a => a.id === selectedApproval?.id)
      onSetDocApproval(approval)
      onSetApprovalLoading(false)
      onSetApprovalNotFound(false)
      setNotification({ msg: t('approvals.added_to_document'), type: 'success' })
      onClose()
    }catch(err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }, [activeTeamMember, approvals, doc, onClose, onSetApprovalLoading, onSetApprovalNotFound, onSetDocApproval, selectedApproval, setNotification, t, updateDocument])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSave(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSave])

  return (
    <Modal onClose={onClose}>
      <div className="choose-approval-modal">
        <div className="choose-approval-modal__head">
          <h2>{t('approvals.set_approval')}</h2>
        </div>
        <div className="choose-approval-modal__body">
          {!approvalsFetched && (
            <div className="loader-wrapper"><Loader small normal primary /></div>
          )}
          {approvalsFetched && approvals.length > 0 && <ul>
            {approvals.map((approval) => (
              <Approval approval={approval} selectedApproval={selectedApproval} onSetSelectedApproval={setSelectedApproval} key={approval.id} />
            ))}
          </ul>}
          {approvalsFetched && approvals.length === 0 && (
            <div className="no-data">
              <p>{t('approvals.no_approvals')}</p>
              <Button text={t('approvals.create_new')} onButtonClick={() => history.push('/approvals/new')} primary medium />
            </div>
          )}
        </div>
        {approvalsFetched && approvals.length > 0 && <div className="choose-approval-modal__foot">
          <Button text={t('general.cancel')} transparent medium onButtonClick={onClose} />
          <Button text={t('general.save')} primary medium onButtonClick={handleSave} />
        </div>}
      </div>
    </Modal>
  )
} 

const Approval = ({ approval, selectedApproval, onSetSelectedApproval }) => {
  const { getUserNameById } = useContext(TeamContext)
  const [showSteps, setShowSteps] = useState(false)

  return (
    <li>
      <div className="visible">
        <div className="radio" onClick={() => onSetSelectedApproval(approval)}>
          {selectedApproval && selectedApproval.id === approval.id ? <RadioButtonChecked className="checked" /> : <RadioButtonUnchecked className="unchecked" />}
        </div>
        <div className="info">
          <p className="name">{approval.name}</p>
          {approval.description && <p className="desc">{approval.description}</p>}
        </div>
        <div className="expand-trigger" onClick={() => setShowSteps(!showSteps)}>{!showSteps ? <ExpandMore /> : <ExpandLess />}</div>
      </div>
      {showSteps && <div className="expand">
        <ul>
          {approval.steps.map((step, idx) => (
            <li key={idx}>
              <p className="step-num">{idx + 1}</p>
              <div className="members">
                {step.members.map((m, i) => {
                  let name = getUserNameById(m)
                  return <LetterCircle data={name} key={i} />
                })}
              </div>
            </li>
          ))}
        </ul>
      </div>}
    </li>
  )
}

export default ChooseApprovalModal
import { useContext, useState, useRef, useEffect } from 'react'
import { Search, Description, Person, CheckCircleOutline, Schedule, Close } from '@material-ui/icons'
import { ClickAwayListener } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { GlobalContext, TeamContext } from '../../context'
import { SEARCH_HISTORY_LS_KEY } from '../../constants'
import { hasSufficientMembership } from '../../utils'

const AnimatedSearchBar = ({ className = '', v2 = false }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const [isActive, setIsActive] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [suggestionsArr, setSuggestionsArr] = useState(localStorage.getItem(SEARCH_HISTORY_LS_KEY) ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY)) : [])
  const [searchValue, setSearchValue] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [inputPaddingLeft, setInputPaddingLeft] = useState(30)
  const categoryBtnRef = useRef()
  const formRef = useRef()
  const history = useHistory()
  const searchCategories = [
    { label: t('dashboard.documents'), key: 'documents', icon: <Description /> },
    { label: t('dashboard.templates'), key: 'templates', icon: <i className="custom-icon-template" /> },
    { label: t('dashboard.signatures_2'), key: 'signatures', icon: <i className="custom-icon-create" /> },
    { label: t('dashboard.tasks'), key: 'tasks', icon: <CheckCircleOutline /> },
    { label: t('dashboard.persons'), key: 'contacts', icon: <Person />  },
  ]

  // Set input padding left when selectedCategory is changed
  useEffect(() => {
    if(selectedCategory && categoryBtnRef.current) {
      const { width } = categoryBtnRef.current.getBoundingClientRect()
      setInputPaddingLeft(width + 2)
    }else if(inputPaddingLeft !== 30 && !selectedCategory) {
      setInputPaddingLeft(30)
    }
  }, [selectedCategory, inputPaddingLeft])

  // On focus
  const handleFocus = () => {
    setIsActive(true)
    setTimeout(() => {
      setShowDropdown(true)
    }, 300)
  }

  // On click away
  const handleClickAway = () => {
    setIsActive(false)
    setShowDropdown(false)
    setSearchValue('')
    setSelectedCategory(null)
  }

  // On close
  const handleClose = (e) => {
    e.preventDefault()
    handleClickAway()
  }

  // On change
  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault()
    if(!searchValue.trim()) {
      return
    }

    const suggestions = localStorage.getItem(SEARCH_HISTORY_LS_KEY) ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY)) : []
    if(searchValue !== '') {
      if(!suggestions.includes(searchValue)) {
        suggestions.unshift(searchValue)
      }else {
        const index = suggestions.indexOf(searchValue);
        suggestions.splice(index, 1)
        suggestions.unshift(searchValue)
      }
      localStorage.setItem(SEARCH_HISTORY_LS_KEY, JSON.stringify(suggestions.slice(0,6)))
    }
    setSuggestionsArr(suggestions)

    history.push(`/search?query=${searchValue}${selectedCategory ? `&area=${selectedCategory.key}` : ''}`)
  }

  // Delete search history - suggestions
  const handleDeleteSearchHistory = (e) => {
    e.preventDefault()
    localStorage.removeItem(SEARCH_HISTORY_LS_KEY)
    setSuggestionsArr([])
  }

  // On suggestion click
  const handleSearchTermClick = (e, term) => {
    e.preventDefault()
    setSearchValue(term)
    setTimeout(() => {
      if(formRef.current && formRef.current.querySelector('.submit-btn')) {
        formRef.current.querySelector('.submit-btn').click()
      }
    }, 50)
  }

  // On category click
  const handleCategoryClick = (cat) => {
    setSelectedCategory(cat)
  }

  // On remove category 
  const handleRemoveCategory = () => {
    setSelectedCategory(null)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`animated-search-bar ${isActive ? 'active' : ''} ${className}`}>
        <form onSubmit={handleSubmit} ref={formRef}>
          {!selectedCategory ? (
            <div className="animated-search-bar__icon">
              <Search />
            </div>
          ) : (
            <div className="animated-search-bar__category" ref={categoryBtnRef}>
              {v2 && <span className="icon"><Search /></span>}
              <button onClick={handleRemoveCategory} type="button">{selectedCategory.label} <Close /></button>
            </div>
          )}
          <input type="text" placeholder={`${t('dashboard.search')}...`} onFocus={handleFocus} value={searchValue} onChange={handleChange} style={ selectedCategory ? {paddingLeft: inputPaddingLeft} : {}} />
          {showDropdown && isActive && (
            <div className="animated-search-bar__actions">
              <a href="/" onClick={handleClose}><Close /></a>
              {!v2 ? (
                <button className="button button--primary submit-btn" type="submit">{t('dashboard.search_2')}</button>
              ) : (
                <button className="btn btn--small submit-btn" type="submit">{t('dashboard.search_2')}</button>
              )}
            </div>
          )}
        </form>

        {showDropdown && isActive && (
          <div className="animated-search-bar__dropdown">
            {!selectedCategory && (
              <div className="animated-search-bar__dropdown_categories">
                <p>{t('dashboard.looking_for')}</p>
                <div className="dropdown-categories-body">
                  {searchCategories.map((cat, ci) => (
                      <button key={ci} onClick={() => handleCategoryClick(cat)} className={v2 ? 'btn btn--outline' : ''}>{cat.icon}{cat.label}</button>
                    )
                  )}
                </div>
              </div>
            )}

            <div className="search-history">
              {suggestionsArr.length > 0 && (
                <p>{t('general.your_search_history')} <a href="/#" onClick={handleDeleteSearchHistory}>{t('general.delete_search_history')}</a></p>
              )}
              {suggestionsArr.length > 0 ? 
                <ul className="search-history__list">
                  {[...suggestionsArr].filter((h, i) => i < 6).map((h, i) => (
                    <li key={i}><Schedule /> <a href="/" onClick={(e) => handleSearchTermClick(e, h)}>{h}</a></li>
                  ))}
                </ul>
                :
                <p>{t('general.no_search_history')}</p>
              }
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default AnimatedSearchBar
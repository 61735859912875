import { useContext } from 'react' 

import { DocumentsFoldersContext, NotificationContext, GlobalContext, LoaderContext, TeamContext, DocumentsContext } from '../context'
import { getAllParentFolders } from '../utils'

const useDocumentsFoldersActions = () => {
  const { docFolders, updateDocFolder, createDocFolder, deleteDocFolder, docFolderBreadcrumbs, setDocFolderBreadcrumbs, setDocFoldersSelectedFilter, setCurrentlyActiveDocFolder } = useContext(DocumentsFoldersContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { selectedTeam } = useContext(TeamContext)
  const { documents, setFilteredDocuments } = useContext(DocumentsContext)
  const { t } = useContext(GlobalContext)

  // Move folder
  const moveDocumentsFolder = async ({ selectedFolder, docFolderToMove }) => {
    const parentFolders = getAllParentFolders(docFolders, selectedFolder)
    if(selectedFolder) {
      if(docFolderToMove.id === selectedFolder.id) {
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: docFolderToMove.name, folder2: selectedFolder.name }), type: 'warning' })
      }
      if(docFolderToMove.parentFolder === selectedFolder.id) {
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: docFolderToMove.name, folder2: selectedFolder.name }), type: 'warning' })
      }
      if(parentFolders.find(f => f.id === docFolderToMove.id)) {
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: docFolderToMove.name, folder2: selectedFolder.name }), type: 'warning' })
      }
      
      setShowGlobalResponseLoader(true)
      // setGlobalResponseLoaderText('Moving folder')
      try {
        const updated = await updateDocFolder({ parentFolder: selectedFolder.id, team: selectedTeam.id }, docFolderToMove.id)
        if(!updated) throw new Error('')
        setNotification({ msg: t('folder.moved_to_folder', { folder1: docFolderToMove.name, folder2: selectedFolder.name }), type: 'default' })
      } catch (err) {
        console.log(err)
      } finally {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      }
      
    }else {
      if(docFolderToMove.parentFolder) {
        setShowGlobalResponseLoader(true)
        // setGlobalResponseLoaderText('Moving folder')
        try {
          const updated = await updateDocFolder({ parentFolder: null, team: selectedTeam.id }, docFolderToMove.id)
          if(!updated) throw new Error('')
          setNotification({ msg: t('folder.moved_to_root', { folder: docFolderToMove.name }), type: 'default' })
        } catch (err) {
          console.log(err)
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        } finally {
          setShowGlobalResponseLoader(false)
          setGlobalResponseLoaderText('')
        }
      }else {
        setNotification({ msg: t('folder.unable_to_move_to_root', { folder: docFolderToMove.name }), type: 'warning' })
      }
    }
  }

  // Move folder from modal
  const moveFolderFromModal = async ({ current, active, setDocumentFolderToMove, setDocFolderBreadcrumbs, setShowMoveToFolderModal, setFolderCreated }) => {
    if(!current.parentFolder && !active) {
      return setNotification({ msg: t('folder.already_in_root', { folder: current.name }), type: 'warning' })
    }
    if(active && current.parentFolder === active.id) {
      return setNotification({ msg: t('folder.already_in_folder', { folder1: current.name, folder2: active.name }), type: 'warning' })
    }
    
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Moving folder')

    try {
      if(active) {
        const updated = await updateDocFolder({ parentFolder: active.id, team: selectedTeam.id }, current.id)
        if(!updated) throw new Error('')
        setNotification({ msg: t('folder.moved_to_folder', { folder1: current.name, folder2: active.name }), type: 'default' })
        setDocumentFolderToMove(prev => ({...prev, parentFolder: active.id}))
      }else {
        const updated = await updateDocFolder({ parentFolder: null, team: selectedTeam.id }, current.id)
        if(!updated) throw new Error('')
        setNotification({ msg: t('folder.moved_to_root', { folder: current.name }), type: 'default' })
        setDocumentFolderToMove(prev => ({...prev, parentFolder: null}))
      }
      
      const parentFolders = getAllParentFolders(docFolders, {...current, parentFolder: active ? active.id : null})
      const reverseParentFolders = parentFolders.reverse()
      if(parentFolders.length > 0) {
        setDocFolderBreadcrumbs([...reverseParentFolders, {...current, parentFolder: active ? active.id : null}])
      }else {
        if(current) {
          setDocFolderBreadcrumbs([{...current, parentFolder: active ? active.id : null}])
        }else {
          setDocFolderBreadcrumbs([])
        }
      }
  
      setShowMoveToFolderModal(false)
      setFolderCreated(true)
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // Create document folder
  const createDocumentFolder = async ({ data, setShowFolderModal, setFolderCreated }) => {
    await createDocFolder(data, selectedTeam?.id)
    setShowFolderModal(false)
    setFolderCreated(true)
  }

  // Update document folder
  const updateDocumentFolder = async ({ data, id, setShowFolderModal, setCurrentFolder  }) => {
    const updated = await updateDocFolder(data, id)
    if(updated) {
      setShowFolderModal(false)
      setCurrentFolder(null)
      if(docFolderBreadcrumbs.length > 0) {
        const breadcrumbs = [...docFolderBreadcrumbs]
        const findBreadcrumb = breadcrumbs.find((b) => b.id === id)
        if(findBreadcrumb) {
          findBreadcrumb.name = data.name
          setDocFolderBreadcrumbs(prev => [...prev].map(b => b.id === findBreadcrumb.id ? findBreadcrumb : b))
        }
      }
    }
  }

  // Delete document folder
  const deleteDocumentFolder = async ({ currentFolder, setShowDeleteFolderAlert, setCurrentFolder, resetFolders, setResetFolders }) => {
    setShowGlobalResponseLoader(true)
    await deleteDocFolder(currentFolder.id)
    setNotification({ msg: t('folder.deleted', { folder: currentFolder.name }), type: 'default' })
    setShowDeleteFolderAlert(false)
    setCurrentFolder(null)
    setShowGlobalResponseLoader(false)
    if(resetFolders) {
      setDocFoldersSelectedFilter('all')
      setDocFolderBreadcrumbs([])
      setResetFolders(false)
      const docs = {...documents}
      let filtered = {}
      for(let key in docs) {
        if(!docs[key].folderId || (docs[key].folderId && docs[key].folderId.length < 2)) {
          filtered[key] = docs[key]
        }
      }
      setFilteredDocuments(filtered)
    }
  }

  // Update breadcrumbs
  const updateBreadcrumbs = ({ folder }) => {
    setCurrentlyActiveDocFolder(folder)
    const parentFolders = getAllParentFolders(docFolders, folder)
    const reverseParentFolders = parentFolders.reverse()
    if(parentFolders.length > 0) {
      setDocFolderBreadcrumbs([...reverseParentFolders, folder])
    }else {
      if(folder) {
        setDocFolderBreadcrumbs([folder])
      }else {
        setDocFolderBreadcrumbs([])
      }
    }
  }

  return {
    moveDocumentsFolder,
    moveFolderFromModal,
    createDocumentFolder,
    updateDocumentFolder,
    deleteDocumentFolder,
    updateBreadcrumbs,
  }
} 

export default useDocumentsFoldersActions
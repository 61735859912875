import { useContext, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/fr';
import { useHistory } from 'react-router-dom';

import Loader from '../UI/Loader';
import { LetterCircle } from '../new_ui';
import {
  DocumentsContext,
  UserContext,
  TeamContext,
  GlobalContext,
  ConstantsContext,
} from '../../context';
import { sortArrayOfObjects } from '../../utils';
import { fetch_actions, update_user } from '../../services/firestore';
import firebase from '../../services/firebase';

const NotificationsPanel = ({ show, onClose, version = 'v1' }) => {
  const { t } = useContext(GlobalContext);
  const { ACTION_LABELS } = useContext(ConstantsContext);
  const { actions, setActions, getDocumentById, getTemplateById } =
    useContext(DocumentsContext);
  const { user, setUser } = useContext(UserContext);
  const { selectedTeam } = useContext(TeamContext);
  const [actionsArray, setActionsArray] = useState([]);
  const [actionsFetched, setActionsFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const notificationsEl = useRef();
  const notificationsInnerEl = useRef();
  const history = useHistory();

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // when notifications are visible
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      notificationsEl.current.style.zIndex = 1010;
      notificationsInnerEl.current.scrollTop = 0;
      if (!actionsFetched && version === 'v1') {
        setLoading(true);
        fetchActions();
      } else {
        // updateUserNotificationReadStatus()
        setTimeout(updateUserNotificationReadStatus, 3000);
      }
    } else {
      setActionsFetched(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [show, actionsFetched]);

  const updateUserNotificationReadStatus = async () => {
    if (!user) {
      console.log('no user');
      return;
    }
    let lastTimestamp = 0;
    for (let key in actions) {
      if (actions[key].timestamp > lastTimestamp) {
        lastTimestamp = actions[key].timestamp;
      }
    }
    await update_user(
      { last_read_notification_timestamp: lastTimestamp },
      user.id
    );
    setUser({ ...user, last_read_notification_timestamp: lastTimestamp });
  };

  const fetchActions = async () => {
    let actions = await fetch_actions(selectedTeam?.id);
    if (actions.error) {
      console.log('fetch actions error', actions.error);
      return;
    }
    setActions(actions);
    setActionsFetched(true);
    setLoading(false);
  };

  // set actions array
  useEffect(() => {
    let actsArray = [];
    for (let key in actions) {
      let action = actions[key];
      const actionType = action.action;
      let user_name = '[Removed User]';
      let user_image = '';
      let user_removed = true;
      if (actions[key].author === firebase.auth().currentUser.uid) {
        let name = '';
        if (user.first_name) name = user.first_name;
        if (name && user.last_name) name = `${name} ${user.last_name}`;
        if (!name && user.email) name = user.email;
        if (user.image_url) user_image = user.image_url;
        user_name = name;
        user_removed = false;
      } else {
        const findUser = selectedTeam.users.find(
          (u) => u.id === actions[key].author
        );
        if (findUser) {
          let name = '';
          if (findUser.first_name) name = findUser.first_name;
          if (name && findUser.last_name)
            name = `${name} ${findUser.last_name}`;
          if (!name && findUser.email) name = findUser.email;
          if (findUser.image_url) user_image = findUser.image_url;
          user_name = name;
          user_removed = false;
        }
      }
      if (action.docName) {
        if (actionType.includes('document_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'document',
            removed: user_removed,
            image: user_image,
          });
        } else if (actionType.includes('task_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'task',
            removed: user_removed,
            image: user_image,
          });
        } else if (actionType.includes('alert_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'alert',
            removed: user_removed,
            image: user_image,
          });
        } else if (actionType.includes('contact_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'contact',
            removed: user_removed,
            image: user_image,
          });
        } else if (actionType.includes('template_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'template',
            removed: user_removed,
            image: user_image,
          });
        } else if (actionType.includes('signature_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'signature',
            removed: user_removed,
            image: user_image,
          });
        } else if (actionType.includes('comment_')) {
          actsArray.push({
            ...actions[key],
            owner: user_name,
            type: 'comment',
            removed: user_removed,
            image: user_image,
          });
        }
      }
    }
    setActionsArray(sortArrayOfObjects(actsArray, 'timestamp', 'asc'));
  }, [actions, user]);

  // Open specific page when clicked on activity/notification
  const goTo = (e, docId, related, type = 'document') => {
    e.preventDefault();
    if (type === 'task') {
      history.push(`/document-detail/${related}`, { openTasks: true });
    } else if (type === 'alert') {
      history.push(`/document-detail/${related}`, { openAlert: true });
    } else if (type === 'contact') {
      history.push('/contacts');
    } else if (type === 'signature') {
      // do something
    } else if (type === 'comment') {
      // console.log('push comment')
      history.push(`/document-detail/${docId}`, { openComments: true });
      // history.push({
      //   pathname: `/document-detail/${docId}`,
      //   state: { openComments: true }
      // })
    } else {
      // console.log('push')
      history.push(`/document-detail/${docId}`);
    }
  };

  // On notifications close
  const notificationsCloseHandler = () => {
    onClose();
    setTimeout(() => {
      document.body.style.overflow = null;
      notificationsEl.current.style.zIndex = -1;
    }, 300);
  };

  const isNotificationNew = (action) => {
    if (!user) {
      return false;
    }
    if (
      isNaN(user.last_read_notification_timestamp) ||
      action.timestamp > user.last_read_notification_timestamp
    ) {
      return true;
    }
  };

  return ReactDOM.createPortal(
    <div
      className={
        show
          ? 'notifications notifications-v2 active'
          : 'notifications notifications-v2'
      }
      ref={notificationsEl}
    >
      <div
        className="notifications__overlay"
        onClick={notificationsCloseHandler}
      ></div>
      <div className="notifications__inner" ref={notificationsInnerEl}>
        <div className="notifications__inner_head">
          <span className="material-symbols-outlined filled">
            notifications
          </span>
          <h5>{t('dashboard.activities')}</h5>
        </div>
        {!loading ? (
          <ul className="notifications__list">
            {actionsArray.map((action, i) => {
              const actionType = action.action;
              let itemClass = '';
              let icon;
              if (actionType.includes('document_')) {
                itemClass = 'document-action';
                icon =
                  actionType === 'document_upload' ||
                  actionType === 'document_create' ? (
                    <span className="material-symbols-outlined">note_add</span>
                  ) : (
                    <span className="material-symbols-outlined">
                      description
                    </span>
                  );
              } else if (actionType.includes('task_')) {
                itemClass = 'task-action';
                icon = (
                  <span className="material-symbols-outlined">
                    check_circle
                  </span>
                );
              } else if (actionType.includes('alert_')) {
                itemClass = 'alert-action';
                icon = <span className="material-symbols-outlined">alarm</span>;
              } else if (actionType.includes('contact_')) {
                itemClass = 'contact-action';
                icon = (
                  <span className="material-symbols-outlined">
                    contact_page
                  </span>
                );
              } else if (actionType.includes('template_')) {
                itemClass = 'template-action';
                icon = <i className="custom-icon-template"></i>;
              } else if (actionType.includes('signature_')) {
                itemClass = 'signature-action';
                icon = <i className="custom-icon-create-outlined"></i>;
              } else if (actionType.includes('comment_')) {
                itemClass = 'comment-action';
                icon = <span className="material-symbols-outlined">chat</span>;
              }

              let actionLink = null;
              const aLink = (
                <a
                  href="#/"
                  onClick={(e) =>
                    goTo(e, action.document, action.relatedDoc, action.type)
                  }
                >
                  {action.docName}
                </a>
              );
              const aSpan = <span>{action.docName}</span>;
              if (actionType.includes('document_')) {
                if (getDocumentById(action.document)) {
                  actionLink = aLink;
                } else {
                  actionLink = aSpan;
                }
              } else if (actionType.includes('template_')) {
                if (getTemplateById(action.document)) {
                  actionLink = aLink;
                } else {
                  actionLink = aSpan;
                }
              } else {
                if (action.type === 'task' || action.type === 'alert') {
                  if (getDocumentById(action.relatedDoc)) {
                    actionLink = aLink;
                  } else {
                    actionLink = aSpan;
                  }
                } else if (action.type === 'comment') {
                  if (getDocumentById(action.document)) {
                    actionLink = aLink;
                  } else {
                    actionLink = aSpan;
                  }
                } else {
                  actionLink = aLink;
                }
              }

              return (
                <li key={i} className={itemClass}>
                  <div className="icon">
                    {icon}
                    {!action.removed && action.image ? (
                      <div
                        className="thumb"
                        style={{ backgroundImage: `url(${action.image})` }}
                      ></div>
                    ) : (
                      !action.removed &&
                      !action.image && (
                        <div className="thumb thumb--no-bg">
                          <LetterCircle data={action.owner} />
                        </div>
                      )
                    )}
                  </div>
                  {isNotificationNew(action) && <div className="badge" />}
                  <div className="text">
                    <p className="info">
                      <strong>{action.owner}</strong>{' '}
                      {ACTION_LABELS[action.action]} {actionLink}
                    </p>
                    <p className="date">
                      {moment(action.timestamp).year() ===
                      new Date().getFullYear()
                        ? moment(action.timestamp).locale('fr').calendar(null, {
                            sameElse: 'D MMM [à] HH:mm',
                          })
                        : moment(action.timestamp).locale('fr').calendar(null, {
                            sameElse: 'D MMM YYYY [à] HH:mm',
                          })}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="loader-wrapper loader-wrapper--no-padding">
            <Loader small normal />
          </div>
        )}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default NotificationsPanel;

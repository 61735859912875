import React, { createContext, useReducer } from 'react';

import tagsReducer from './tagsReducer';
import { GET_TAGS, SET_TAGS, RESET_STATE, SET_TAGS_OBJ } from '../types';
import { fetch_tags, create_tag, update_tag, delete_tag } from '../../services/firestore';
import { sortArrayOfObjects } from '../../utils'

export const TagContext = createContext();

const TagsState = ({ children }) => {
  const initialState = {
    tagsObj: {},
    tags: [],
    tagsFetched: false,
  };

  const [state, dispatch] = useReducer(tagsReducer, initialState);

  // Fetch tags
  const fetchTags = async (teamId) => {
    try {
      const res = await fetch_tags(teamId);
      const arr = [];
      for(let id in res) {
        const tag = {...res[id]};
        tag.id = id;
        arr.push(tag);
      }
      dispatch({
        type: GET_TAGS,
        payload: sortArrayOfObjects(arr, 'name', 'desc'),
        obj: res
      });
    }catch(err) {
      console.log('TAGS err', err);
    }
  }

  // Set tags
  const setTags = (tags) => {
    dispatch({
      type: SET_TAGS,
      payload: sortArrayOfObjects(tags, 'name', 'desc')
    });
  }

  // Set tags
  const setTagsObj = (value) => {
    let arr = []
    for(let id in value) {
      arr.push({...value[id], id})
    }
    setTags(arr)
    dispatch({
      type: SET_TAGS_OBJ,
      payload: value
    })
  }

  // Create tag 
  const createTag = async (data, teamId, onSuccess = () => {}, onError = () => {}) => {
    try {
      const id = await create_tag(data)
      const tags = [...state.tags]
      tags.push({ id, ...data })
      setTags(tags)
      onSuccess()
    }catch(err) {
      console.log('CREATE TAG err', err)
      onError(err)
    }
  }

  // Update tag 
  const updateTag = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const tags = [...state.tags];
      await update_tag(data, id);
      const tagToUpdate = tags.find(t => t.id === id);
      const updatedTag = {
        ...tagToUpdate,
        ...data
      };
      const updatedTags = [...tags].map(t => t.id === id ? updatedTag : t);
      setTags(updatedTags)
      onSuccess();
    }catch(err) {
      console.log('UPDATE TAG err', err);
      onError(err);
    }
  }

  // Delete tag
  const deleteTag = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const tags = [...state.tags];
      await delete_tag(id);
      const updatedTags = [...tags].filter(t => t.id !== id);
      setTags(updatedTags);
      onSuccess();
    } catch (err) {
      console.log('DELETE TAG err', err);
      onError(err);
    }
  }

  // Get tag by id
  const getTagById = (id) => {
    const tags = [...state.tags];
    return tags.find(t => t.id === id);
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, tagsFetched: true } : initialState,
      payload: initialState,
    });
  }

  return(
    <TagContext.Provider value={{
      tagsObj: state.tagsObj,
      tags: state.tags,
      tagsFetched: state.tagsFetched,
      fetchTags,
      createTag,
      updateTag,
      deleteTag,
      getTagById,
      setTags,
      setTagsObj,
      resetTagState: resetState,
    }}>
      {children}
    </TagContext.Provider>
  );
};

export default TagsState;
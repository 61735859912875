import React, { useState, useEffect, useContext, Fragment, useMemo } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { v4 as uuidv4 } from 'uuid'
import AddIcon from '@material-ui/icons/AddRounded'
import DeleteIcon from '@material-ui/icons/HighlightOff'
import RemoveIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import DragIndicatorIcon from '@material-ui/icons/DragIndicatorOutlined'
import SortByAlphaIcon from '@material-ui/icons/SortByAlphaOutlined'
import DateIcon from '@material-ui/icons/CalendarToday'
import TextFormatIcon from '@material-ui/icons/TextFormat'
import NotesIcon from '@material-ui/icons/NotesOutlined'
import TocIcon from '@material-ui/icons/TocOutlined'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircleOutlined'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { DeleteOutline } from '@material-ui/icons' 

import Alert from '../../../UI/Alert'
import Modal from '../../../UI/Modal'
import CustomSelect from '../../../UI/CustomSelect'
import Input from '../../../UI/Input'
import Button from '../../../UI/Button'
import Loader from '../../../UI/Loader'
import IconButton from '../../../UI/IconButton'
import ResponseLoader from '../../../UI/ResponseLoader'
import CustomTooltip from '../../../UI/CustomTooltip'

import { NotificationContext, TeamContext, DocumentsContext, GlobalContext } from '../../../../context'
import { sortArrayOfObjects } from '../../../../utils'
import { update_team } from '../../../../services/firestore'

const AddCustomFields = ({ onClose, section, doc, data, onSetData, customFieldsValues, onSetEditStartTime = () => {}, onStoreEditTime = () => {} }) => {
  const { t } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam, updateTeam } = useContext(TeamContext)
  const { updateDocument } = useContext(DocumentsContext)
  const [fields, setFields] = useState([])
  const [defaultFields, setDefaultFields] = useState([])
  const [loading, setLoading] = useState(true)
  const [showResponseLoader, setShowResponseLoader] = useState(false)
  const [errorField, setErrorField] = useState(null)
  const [showDeleteFieldAlert, setShowDeleteFieldAlert] = useState(false) 
  const [fieldToRemove, setFieldToRemove] = useState(null)

  useEffect(() => {
    if(data) {
      if(data.length === 0) {
        let defaultField = {
          id: uuidv4(),
          label: '',
          type: ''
        }
        setFields([defaultField])
        setDefaultFields([defaultField])
      }else {
        setFields(JSON.parse(JSON.stringify(data)))
        setDefaultFields(JSON.parse(JSON.stringify(data)))
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }, [data])

  // Add new field
  const addNewField = (e) => {
    e.preventDefault()
    let fieldData = {
      id: uuidv4(),
      label: '',
      type: ''
    }
    const newFields = [...fields] 
    newFields.push(fieldData)
    setFields(newFields)
  }

  // Remove field
  const removeField = (id) => {
    const field = fields.find(f => f.id === id)
    if(field) {
      if(!field.label && !field.type) {
        setFields(fields.filter(f => f.id !== id))
      }else {
        setShowDeleteFieldAlert(true)
        setFieldToRemove(id)
      }
    }
  }

  // Check if fields changed
  const didFieldsChanged = useMemo(() => {
    let changed = false 

    if(defaultFields.length !== fields.length) {
      changed = true
    } else {
      for(let i = 0; i < defaultFields.length; i++) {
        const field = defaultFields[i] 
        const findField = fields.find(f => f.id === field.id)
        if(field.id !== fields[i]?.id) {
          changed = true 
          break
        }
        if(!findField) {
          changed = true
          break
        }else {
          if(field.label !== findField.label) {
            if(!field.label && !findField.label) {
              continue
            }
            changed = true 
            break
          }
          if(field.type !== findField.type) {
            changed = true 
            break 
          }
          if(field.type === 'select' || field.type === 'multiselect') {
            if(field.options && findField.options) {
              if(field.options.length !== findField.options.length) {
                changed = true 
                break
              }else {
                for(let j = 0; j < field.options.length; j++) {
                  const f1 = field.options[j] 
                  const f2 = findField.options[j] 
                  if(f1.label !== f2.label) {
                    changed = true 
                    break
                  }
                  if(f1.value !== f2.value) {
                    changed = true 
                    break
                  }
                }
              }
            }
          }
        }
      }
    }
    
    return changed
  }, [defaultFields, fields])

  // Save fields
  const saveFields = async () => {
    // console.log(fields)
    let error = false 
    let errorMessage = ''
    for(let i = 0; i < fields.length; i++) {
      let field = fields[i] 
      if(field.label === '') {
        field.label = `${t('custom_fields.field')} ${i+1}`
      }
      if(field.type === '') {
        error = true 
        errorMessage = t('custom_fields.type_required')
        setErrorField(field)
        break 
      }
      if(field.type === 'select' || field.type === 'multiselect') {
        if(!field.options) {
          error = true 
          errorMessage = t('custom_fields.select_type_validation')
          setErrorField(field)
          break 
        }
        if(field.options.length === 0) {
          error = true
          errorMessage = t('custom_fields.options_validation')
          setErrorField(field)
          break
        }else {
          for(let j = 0; j < field.options.length; j++) {
            let opt = field.options[j] 
            if(opt.error) {
              error = true
              errorMessage = t('custom_fields.option_inputs_validation')
              setErrorField(field)
              break
            }
          }
          if(error) break
        }
      }
    }

    if(error) {
      return setNotification({ msg: errorMessage, type: 'danger' })
    }

    let cValues = {...customFieldsValues}
    let changed = false
    for(let key in customFieldsValues) {
      if(defaultFields.find(f => f.id === key) && !fields.find(f => f.id === key)) {
        delete cValues[key] 
        changed = true 
      }
    }
    
    setShowResponseLoader(true)
    try {
      await update_team({ custom_fields: fields }, selectedTeam?.id)
      updateTeam({ custom_fields: fields }, selectedTeam?.id)
      if(changed) { // delete document custom fields value if field removed
        await updateDocument({ custom_fields_values: cValues }, doc)
      }
      onSetData(fields)
      setShowResponseLoader(false)
      onClose()
    } catch (err) {
      console.log(err)
      setShowResponseLoader(false)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    } 
  }

  // On close delete field alert
  const handleCloseDeleteFieldAlert = () => {
    setShowDeleteFieldAlert(false)
    setFieldToRemove(null)
  }

  // On remove field
  const handleRemoveField = () => {
    if(!fieldToRemove) return 

    setFields(fields.filter(f => f.id !== fieldToRemove))
    handleCloseDeleteFieldAlert()
  }

  return (
    <Modal onClose={onClose} medium>
      {loading 
        ? <div className="loader-wrapper"><Loader primary normal small /></div>
        :
        <div className="add-custom-fields">
          <div className="add-custom-fields__head">
            <h2>{t('custom_fields.add_to_section')}</h2>
          </div>
          <div className="add-custom-fields__body">
            <div className="fields">
              <ReactSortable 
                list={fields} 
                setList={setFields} 
                animation={200} 
                delayOnTouchStart={true} 
                delay={2} 
                handle=".drag-handle"
                dragClass="dragged-field"
                ghostClass="selected-field"
              >
                {fields.map((field, idx) => {
                  return <Field 
                    index={idx + 1} 
                    section={section} 
                    key={field.id} 
                    field={field} 
                    onRemove={removeField} 
                    onSetFields={setFields} 
                    onSetEditStartTime={onSetEditStartTime}
                    onStoreEditTime={onStoreEditTime}
                    error={errorField && errorField.id === field.id}
                    onSetErrorField={setErrorField}
                    disableType={data.length > 0 && defaultFields.find(f => f.id === field.id)}
                  />
                })}
              </ReactSortable>
            </div>
            <div className="actions">
              <a href="/#" className="add-custom-fields__body_link add-field" onClick={addNewField}><AddIcon /> {t('custom_fields.add_new')}</a>
              <Button text={t('general.save')} onButtonClick={saveFields} primary disabled={!didFieldsChanged} />
            </div>
          </div>
        </div>
      }

      {showDeleteFieldAlert && <Alert 
        onClose={handleCloseDeleteFieldAlert}
        onSubmit={handleRemoveField}
        text={t('custom_fields.delete_field')}
        deleteAlert
      />}
      {showResponseLoader && <ResponseLoader />}
    </Modal>
  )
}

// Field component
const Field = ({ section, field, onRemove, onSetFields, index, onSetEditStartTime, onStoreEditTime, error, onSetErrorField, disableType }) => {
  const { t } = useContext(GlobalContext)
  const [fieldType, setFieldType] = useState([])
  const [selectedType, setSelectedType] = useState('')
  const [label, setLabel] = useState('')
  const [options, setOptions] = useState([])
  const [addedOption, setAddedOption] = useState('')
  const [showContent, setShowContent] = useState(true)
  const [optionToDelete, setOptionToDelete] = useState(null)
  const [showDeleteOptionAlert, setShowDeleteOptionAlert] = useState(false)

  // Show correct types based on section 
  useEffect(() => {
    if(section === 'dates') {
      setFieldType([
        { label: t('custom_fields.select_field_type'), value: '', active: true },
        { label: t('dashboard.date'), value: 'date', active: false, icon: <DateIcon /> },
      ])
    }else if(section === 'tags') {
      setFieldType([
        { label: t('custom_fields.select_field_type'), value: '', active: true },
        { label: t('custom_fields.text'), value: 'text', active: false, icon: <TextFormatIcon /> },
        { label: t('custom_fields.textarea'), value: 'textarea', active: false, icon: <NotesIcon /> },
        { label: t('custom_fields.select'), value: 'select', active: false, icon: <ArrowDropDownCircleIcon /> },
        { label: t('custom_fields.multiselect'), value: 'multiselect', active: false, icon: <TocIcon /> },
        { label: t('custom_fields.number'), value: 'number', active: false, icon: <i className="custom-icon-tag"></i> },
      ])
    }else if(section === 'contacts') {
      setFieldType([
        { label: t('custom_fields.select_field_type'), value: '', active: true },
        { label: t('custom_fields.text'), value: 'text', active: false, icon: <TextFormatIcon /> },
      ])
    }else {
      setFieldType([
        { label: t('custom_fields.select_field_type'), value: '', active: true },
        { label: t('dashboard.date'), value: 'date', active: false, icon: <DateIcon /> },
        { label: t('custom_fields.text'), value: 'text', active: false, icon: <TextFormatIcon /> },
        { label: t('custom_fields.textarea'), value: 'textarea', active: false, icon: <NotesIcon /> },
        { label: t('custom_fields.select'), value: 'select', active: false, icon: <ArrowDropDownCircleIcon /> },
        { label: t('custom_fields.multiselect'), value: 'multiselect', active: false, icon: <TocIcon /> },
        { label: t('custom_fields.number'), value: 'number', active: false, icon: <i className="custom-icon-tag"></i> },
      ])
    }
  }, [section])

  // Set fields values if field already saved
  useEffect(() => {
    if(field.label) {
      setLabel(field.label)
    }
    if(field.type) {
      setFieldType(prev => {
        return prev.map(f => f.value === field.type ? {...f, active: true} : {...f, active: false})
      })
      setSelectedType(field.type)
    }
    if(field.options) {
      setOptions(field.options)
    }
  }, [field])

  // On field type change
  const fieldTypeChangeHandler = (opt) => {
    if(opt.value && error) {
      onSetErrorField(null)
    }
    setSelectedType(opt.value)
    setFieldType(prev => prev.map(o => o.value === opt.value ? {...opt, active: true} : {...o, active: false}))
    onSetFields(prev => {
      const current = [...prev].find(f => f.id === field.id)
      current.type = opt.value
      if(opt.value === 'multiselect') {
        current.values = []
      }
      return prev.map(f => f.id === field.id ? current : f)
    })
  }

  // On label change
  const labelChangeHandler = (e) => {
    const { value } = e.target 
    setLabel(value)
    onSetFields(prev => {
      const current = prev.find(f => f.id === field.id)
      current.label = value.replace(/\s+/g, ' ').trim()
      // current.key = value.toLowerCase().replace(/\s+/g, ' ').trim().replace(/\s/g, '_')
      return prev.map(f => f.id === field.id ? current : f)
    })
  }

  // Add option handler
  const addOptionHandler = (e) => {
    e.preventDefault()
    const newOptions = [...options]
    let id = uuidv4()
    newOptions.push({ label: '', value: '', id })
    setOptions(newOptions)
    onSetFields(prev => {
      const current = prev.find(f => f.id === field.id)
      current.options = newOptions
      return prev.map(f => f.id === field.id ? current : f)
    })
    setAddedOption(id)
  }

  // Option save handler
  const optionSaveHandler = (label, id) => {
    const newOptions = JSON.parse(JSON.stringify(options))
    const option = newOptions.find(opt => opt.id === id)
    let error = false
    option.label = label.trim()
    option.value = processedLabel(label)
    if(label.trim() === '') {
      error = true
      option.error = t('notification.all_fields_required')
    }
    if(!error && options.find(o => o.id !== id && o.label === label.trim())) {
      error = true 
      option.error = t('notification.option_already_exists')
    }
    if(!error && option.error) {
      delete option.error
    }
    setOptions(newOptions)
    onSetFields(prev => {
      const current = prev.find(f => f.id === field.id)
      current.options = newOptions
      return prev.map(f => f.id === field.id ? current : f)
    })
  }

  // Process label
  const processedLabel = (label) => {
    if(!label || typeof label !== 'string') return '' 
    let processed = label.trim().toLowerCase().replaceAll(' ', '_')
    return processed
  }

  // Option remove handler 
  const optionRemoveHandler = (option) => {
    const { id, label } = option
    if(!label) {
      const newOptions = options.filter(o => o.id !== id)
      setOptions(newOptions)
      onSetFields(prev => {
        const current = prev.find(f => f.id === field.id)
        current.options = newOptions
        return prev.map(f => f.id === field.id ? current : f)
      })
    }else {
      setShowDeleteOptionAlert(true)
      setOptionToDelete(option)
    }
  }

  // On close delete option alert 
  const handleCloseDeleteOptionAlert = () => {
    setShowDeleteOptionAlert(false)
    setOptionToDelete(null)
  }

  // On option remove
  const handleRemoveOption = () => {
    if(!optionToDelete) return 

    const newOptions = options.filter(o => o.id !== optionToDelete.id)
    setOptions(newOptions)
    onSetFields(prev => {
      const current = prev.find(f => f.id === field.id)
      current.options = newOptions
      return prev.map(f => f.id === field.id ? current : f)
    })
    handleCloseDeleteOptionAlert()
  }

  // On sort change
  const sortChangeHandler = () => {
    onSetFields(prev => {
      const current = prev.find(f => f.id === field.id)
      current.options = options
      return prev.map(f => f.id === field.id ? current : f)
    })
  }

  // Sort options alphabetically
  const sortOptionsAlphabetically = (e) => {
    e.preventDefault()
    let newOptions = sortArrayOfObjects(options, 'label', 'desc')
    setOptions(newOptions)
    onSetFields(prev => {
      const current = prev.find(f => f.id === field.id)
      current.options = newOptions
      return prev.map(f => f.id === field.id ? current : f)
    })
  }

  const getFieldIcon = () => {
    if(field.type === 'date') {
      return <DateIcon />
    }else if(field.type === 'text') {
      return <TextFormatIcon />
    }else if(field.type === 'textarea') {
      return <NotesIcon />
    }else if(field.type === 'number') {
      return <i className="custom-icon-tag"></i>
    }else if(field.type === 'select') {
      return <ArrowDropDownCircleIcon />
    }else if(field.type === 'multiselect') {
      return <TocIcon />
    }
  }
  
  return (
    <div className={error ? "new-field error" : "new-field"}>
      <div className="drag-handle">
        <DragIndicatorIcon />
      </div>
      <div className="toggle-content" onClick={() => setShowContent(!showContent)}>
        {showContent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {!showContent 
        ? <p className="new-field__name" onClick={() => setShowContent(true)}>{getFieldIcon()} { field.label || `${t('custom_fields.field')} ${index}` }</p>
        :
        <Fragment>
          <Input 
            label={t('custom_fields.field_name')} 
            value={label} 
            onChange={labelChangeHandler} 
            formEl 
            // onFocus={() => onSetEditStartTime(Date.now())} 
            // onBlur={onStoreEditTime} 
          />
          <CustomSelect 
            label={t('custom_fields.select_field_type')}
            options={fieldType}
            onChange={fieldTypeChangeHandler}
            active={fieldType.find(opt => opt.active)}
            formEl
            className={selectedType === '' ? 'error' : ''}
            disabled={disableType}
            // onFocus={() => onSetEditStartTime(Date.now())} 
            // onBlur={onStoreEditTime}
          />
          {(selectedType === 'select' || selectedType === 'multiselect') && <Fragment>
            <div className="new-field__other-fields">
              {options.length > 0 && <div className="options-sort">
                <a href="/#" onClick={sortOptionsAlphabetically}><SortByAlphaIcon /> {t('custom_fields.sort_alphabetically')}</a>
              </div>}
              <div className="options">
                <ReactSortable 
                  list={options} 
                  setList={setOptions} 
                  animation={200} 
                  delayOnTouchStart={true} 
                  delay={2} 
                  onEnd={sortChangeHandler}
                  handle="svg.option-handle"
                >
                  {options.map(opt => (
                    <Option 
                      key={opt.id} 
                      option={opt} 
                      options={options}
                      onRemove={optionRemoveHandler} 
                      onSave={optionSaveHandler} 
                      addedOption={addedOption} 
                      onSetAddedOption={setAddedOption} 
                      onSetEditStartTime={onSetEditStartTime}
                      onStoreEditTime={onStoreEditTime}
                    />
                  ))}
                </ReactSortable>
              </div>
              <a href="/#" className="add" onClick={addOptionHandler}><AddIcon /> {t('custom_fields.add_option')}</a>
            </div>
          </Fragment>}
          <div className="new-field__actions">
            <CustomTooltip content={t('custom_fields.remove_field')}>
              <div>
                <IconButton icon={<DeleteOutline />} onButtonClick={() => onRemove(field.id)} danger />
              </div>
            </CustomTooltip>
            {/* <a href="/#" className="add-custom-fields__body_link remove-field" onClick={(e) => onRemove(e, field.id)}><DeleteIcon /> {t('custom_fields.remove_field')}</a> */}
          </div>
        </Fragment>
      }

      {showDeleteOptionAlert && <Alert 
        onClose={handleCloseDeleteOptionAlert}
        onSubmit={handleRemoveOption}
        text={t('custom_fields.delete_option')}
        deleteAlert
      />}
    </div>
  )
}

// Option component
const Option = ({ option, options, onRemove, onSave, addedOption, onSetAddedOption, onSetEditStartTime, onStoreEditTime }) => {
  const { t } = useContext(GlobalContext)
  const [label, setLabel] = useState(option.label || '')
  const [mode, setMode] = useState('view')

  // Show added option in edit mode
  useEffect(() => {
    if(addedOption === option.id) {
      setMode('edit')
      onSetAddedOption('')
    }
  }, [addedOption, option, onSetAddedOption])

  // Save option
  const saveOption = () => {
    onSave(label, option.id)
    if(label.trim() !== '' && !options.find(o => o.id !== option.id && label.trim() === o.label)) {
      setMode('view')
    }
  }

  // Remove option
  const removeOption = (e) => {
    e.preventDefault()
    onRemove(option)
  }

  // Change to edit mode
  const changeToEditMode = (e) => {
    e.preventDefault()
    setMode('edit')
  }

  return (
    <div className="select-option">
      {mode === 'view' 
        ? 
        <div className="view-mode">
          <DragIndicatorIcon className="option-handle" />
          <p>{option.label}</p>
          <a href="/#" onClick={changeToEditMode} className="add"><EditIcon /></a>
          <a href="/#" onClick={removeOption} className="remove"><RemoveIcon /></a>
        </div>
        : 
        <div className="edit-mode">
          <div className="inputs">
            <Input 
              label={t('custom_fields.label')} 
              small 
              value={label} 
              onChange={(e) => setLabel(e.target.value)} 
              // onFocus={() => onSetEditStartTime(Date.now())} 
              // onBlur={onStoreEditTime} 
            />
            {/* <Input 
              label={t('dashboard.value')} 
              small 
              value={value} 
              onChange={(e) => setValue(e.target.value)} 
              // onFocus={() => onSetEditStartTime(Date.now())} 
              // onBlur={onStoreEditTime} 
            /> */}
          </div>
          {option.error && <p className="u-error">{option.error}</p>}
          <div className="actions">
            <IconButton icon={<DeleteOutline />} onButtonClick={removeOption} danger small />
            <Button text={t('general.save')} primary small onButtonClick={saveOption} />
          </div>
        </div>
      }
    </div>
  )
}

export default AddCustomFields
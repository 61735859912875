import { useContext, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'

import ActionsDropdown from '../UI/ActionsDropdown'
import { Checkbox } from '../new_ui'
import { GlobalContext, TeamContext, ColumnsContext } from '../../context'
import { isCookieAccepted, saveTeamColumnsDataToLS, getTeamColumnsData, removeTeamColumnsData, getTeamColumnsOrderData, saveTeamColumnsOrderDataToLS, getTeamTemplatesColumnWidthsData, removeTeamTemplatesColumnWidthsData, getTeamDocsColumnWidthsData, removeTeamDocsColumnWidthsData, getTeamSignaturesColumnWidthsData, removeTeamSignaturesColumnWidthsData, getTeamVarsColumnWidthsData, removeTeamVarsColumnWidthsData, getTeamContactsColumnWidthsData, removeTeamContactsColumnWidthsData, getTeamApprovalsColumnWidthsData, removeTeamApprovalsColumnWidthsData, getTeamSharedDocumentsColumnWidthsData, removeTeamSharedDocumentsColumnWidthsData } from '../../utils'
import { TEMPLATES_COLUMNS_WIDTHS, COLUMNS_WIDTHS, SIGNATURES_COLUMNS_WIDTHS, VARIABLES_COLUMNS_WIDTHS, CONTACTS_COLUMNS_WIDTHS, APPROVALS_COLUMNS_WIDTHS, SHARED_DOCS_COLUMNS_WIDTHS } from '../../constants'

const ColumnsFilter = ({ columns, setColumns, onOrderSave, view, defaultFields, calculateTableWidth }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)
  const { setTemplatesTableColumnsWidths, setTemplatesTableWidth, setDocsTableColumnsWidths, setDocsTableWidth, setSignaturesTableColumnsWidths, setSignaturesTableWidth, setVarsTableColumnsWidths, setVarsTableWidth, setContactsTableColumnsWidths, setContactsTableWidth, setApprovalsTableWidth, setApprovalsTableColumnsWidths, setSharedDocsTableWidth, setSharedDocsTableColumnsWidths } = useContext(ColumnsContext)
  const [allHidden, setAllHidden] = useState(false)

  // On checkbox change
  const handleChange = (e, col) => {
    if(!e || !e.target) return 
    const { checked } = e.target 
    const updatedColumns = columns.map((c) => c.value === col ? { ...c, checked: checked } : c)
    setColumns(updatedColumns)
    
    // Save to LS
    if(isCookieAccepted()) {
      const dataArr = updatedColumns.filter(f => f.checked).map(f => f.value)
      saveTeamColumnsDataToLS(selectedTeam?.id, dataArr, view)
    }else {
      if(getTeamColumnsData()) {
        removeTeamColumnsData()
      }
    }
    
    calculateTableWidth()
  }

  // On reset columns
  const handleResetColumns = () => {
    setColumns(defaultFields)

    const teamColData = getTeamColumnsData()
    if(teamColData && teamColData[selectedTeam?.id]) {
      const columnsLS = teamColData[selectedTeam?.id][view]
      if(columnsLS) {
        saveTeamColumnsDataToLS(selectedTeam?.id, [], view)
      }
    }
    
    const teamColOrderData = getTeamColumnsOrderData()
    if(teamColOrderData && teamColOrderData[selectedTeam?.id]) {
      const columnsOrderLS = teamColOrderData[selectedTeam?.id][view]
      if(columnsOrderLS) {
        saveTeamColumnsOrderDataToLS(selectedTeam?.id, [], view)
      }
    }

    if(view === 'templates') {
      const templatesColsWidthsData = getTeamTemplatesColumnWidthsData()
      if(templatesColsWidthsData && templatesColsWidthsData[selectedTeam?.id]) {
        removeTeamTemplatesColumnWidthsData(selectedTeam?.id)
        setTemplatesTableColumnsWidths(TEMPLATES_COLUMNS_WIDTHS)
      }
      setTemplatesTableWidth('100%')
    }else if(view === 'documents') {
      const docsColsWidthsData = getTeamDocsColumnWidthsData()
      if(docsColsWidthsData && docsColsWidthsData[selectedTeam?.id]) {
        removeTeamDocsColumnWidthsData(selectedTeam?.id)
        setDocsTableColumnsWidths(COLUMNS_WIDTHS)
      }
      setDocsTableWidth('100%')
    }else if(view === 'signatures') {
      const signaturesColsWidthsData = getTeamSignaturesColumnWidthsData()
      if(signaturesColsWidthsData && signaturesColsWidthsData[selectedTeam?.id]) {
        removeTeamSignaturesColumnWidthsData(selectedTeam?.id)
        setSignaturesTableColumnsWidths(SIGNATURES_COLUMNS_WIDTHS)
      }
      setSignaturesTableWidth('100%')
    }else if(view === 'contacts') {
      const contactsColsWidthsData = getTeamContactsColumnWidthsData()
      if(contactsColsWidthsData && contactsColsWidthsData[selectedTeam?.id]) {
        removeTeamContactsColumnWidthsData(selectedTeam?.id)
        setContactsTableColumnsWidths(CONTACTS_COLUMNS_WIDTHS)
      }
      setContactsTableWidth('100%')
    }else if(view === 'variables') {
      const varsColsWidthsData = getTeamVarsColumnWidthsData()
      if(varsColsWidthsData && varsColsWidthsData[selectedTeam?.id]) {
        removeTeamVarsColumnWidthsData(selectedTeam?.id)
        setVarsTableColumnsWidths(VARIABLES_COLUMNS_WIDTHS)
      }
      setVarsTableWidth('100%')
    }else if(view === 'approvals') {
      const approvalsColsWidthsData = getTeamApprovalsColumnWidthsData()
      if(approvalsColsWidthsData && approvalsColsWidthsData[selectedTeam?.id]) {
        removeTeamApprovalsColumnWidthsData(selectedTeam?.id)
        setApprovalsTableColumnsWidths(APPROVALS_COLUMNS_WIDTHS)
      }
      setApprovalsTableWidth('100%')
    }else if(view === 'shared_documents') {
      const sharedDocsColsWidthsData = getTeamSharedDocumentsColumnWidthsData()
      if(sharedDocsColsWidthsData && sharedDocsColsWidthsData[selectedTeam?.id]) {
        removeTeamSharedDocumentsColumnWidthsData(selectedTeam?.id)
        setSharedDocsTableColumnsWidths(SHARED_DOCS_COLUMNS_WIDTHS)
      }
      setSharedDocsTableWidth('100%')
    }
    setAllHidden(false)
  }

  // On toggle columns 
  const handleToggleColumns = () => {
    let updatedColumns 
    if(!allHidden) {
      if(view === 'documents' || view === 'templates' || view === 'shared_documents') {
        updatedColumns = columns.map(c => c.value !== 'name' && c.value !== 'helper' ? {...c, checked: false} : c)
      }else if(view === 'signatures') {
        updatedColumns = columns.map(c => c.value !== 'title' && c.value !== 'helper' ? {...c, checked: false} : c)
      }else if(view === 'variables') {
        updatedColumns = columns.map(c => c.value !== 'variable' && c.value !== 'helper' ? {...c, checked: false} : c)
      }else if(view === 'contacts') {
        updatedColumns = columns.map(c => c.value !== 'first_name' && c.value !== 'helper' ? {...c, checked: false} : c)
      }else if(view === 'approvals') {
        updatedColumns = columns.map(c => c.value !== 'name' && c.value !== 'helper' ? {...c, checked: false} : c)
      }
      setColumns(updatedColumns)
      setAllHidden(true)
    }else {
      updatedColumns = columns.map(c => ({...c, checked: true}))
      // if(view === 'documents' || view === 'templates') {
      // }
      setColumns(updatedColumns)
      setAllHidden(false)
    }

    if(view === 'templates') {
      setTemplatesTableWidth('100%')
    }else if(view === 'documents') {
      setDocsTableWidth('100%')
    }else if(view === 'signatures') {
      setSignaturesTableWidth('100%')
    }else if(view === 'variables') {
      setVarsTableWidth('100%')
    }else if(view === 'contacts') {
      setContactsTableWidth('100%')
    }else if(view === 'approvals') {
      setApprovalsTableWidth('100%')
    }else if(view === 'shared_documents') {
      setSharedDocsTableWidth('100%')
    }

    // Save to LS
    if(isCookieAccepted()) {
      const dataArr = updatedColumns.filter(f => f.checked).map(f => f.value)
      saveTeamColumnsDataToLS(selectedTeam?.id, dataArr, view)
    }
  }

  return (
    <ActionsDropdown
      trigger={
        <button className="btn btn--small btn--outline btn--with-icon-left"><span className="material-symbols-outlined">tune</span> {t('dashboard.filters')}</button>
      }
      hideHeader
      dropdownClass="actions-dropdown-v2"
      width={200}
    >
      <div className="collection-columns-filter">
        <div className="collection-columns-filter__list">
          <ReactSortable 
            list={columns} 
            setList={setColumns} 
            animation={200} 
            delayOnTouchStart={true} 
            delay={2} 
            filter=".filtered-out"
            draggable=".draggable-item"
            onEnd={onOrderSave}
            handle=".handle"      
            className="sortable-row-items"  
          >
            {columns.map((column, idx) => {
              return (
                <Column key={idx} column={column} onChange={handleChange} view={view} />
              )
            })}
          </ReactSortable>
        </div>
        <div className="collection-columns-filter__actions">
          <button className="btn btn--outline btn--small btn--full" onClick={handleResetColumns}>
            <span className="material-symbols-outlined">restart_alt</span>{t('general.reset')}
          </button>
          <button className="btn btn--outline btn--small btn--full" onClick={handleToggleColumns}>
            <span className="material-symbols-outlined">list_alt</span>{allHidden ? t('general.show_all') : t('general.hide_all')}
          </button>
        </div>
      </div>
    </ActionsDropdown>
  )
}

const Column = ({ column, onChange, view }) => {
  let excluded = []
  if(view === 'templates' || view === 'documents' || view === 'approvals' || view === 'shared_documents') {
    excluded = ['name', 'helper']
  }else if(view === 'signatures') {
    excluded = ['title', 'helper']
  }else if(view === 'variables') {
    excluded = ['variable', 'helper']
  }else if(view === 'contacts') {
    excluded = ['first_name', 'helper']
  }

  return (
    <div className={`collection-columns-filter__list_item ${excluded.includes(column.value) ? 'filtered-out hidden' : 'draggable-item'} u-truncate-text-wrapper`}>
      <Checkbox className={'checkbox-v2--small'} checked={column.checked} onChange={(e) => onChange(e, column.value)} />
      <p className="u-truncate-text">{column.label}</p>
      <div className="handle">
        <span className="material-symbols-outlined weight-300">drag_indicator</span>
      </div>
    </div>
  )
}

export default ColumnsFilter
import { useContext } from 'react' 

import { NotificationContext, GlobalContext, SharedTemplatePagesContext, LoaderContext } from '../context'
import { delete_shared_template } from '../services/shared_templates'
import { delete_file } from '../services/firestore'

const useSharedTemplatePageActions = () => {
  const { renewSharedTemplatePageUrl, updateSharedTemplatePage, globalSharedPageTemplates, deleteSharedTemplatePage, setGlobalSharedPageTemplates } = useContext(SharedTemplatePagesContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { t } = useContext(GlobalContext)

  // Copy url
  const copySharedTemplatePageUrl = ({ page }) => {
    const input = document.createElement('textarea')
    input.innerHTML = `${window.location.origin}/shared-template-page/${page.id}`
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setNotification({ msg: t('share_email.link_copied'), type: 'info' })
    document.body.click()
  }

  // Renew page
  const renewSharedTemplatePage = async ({ page }) => {
    setShowGlobalResponseLoader(true)
    renewSharedTemplatePageUrl(page, () => {
      setNotification({ msg: t('share_template.page_renewed_successfully'), type: 'success' })
      setShowGlobalResponseLoader(false)
      document.body.click()
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  // Disable page
  const disableSharedTemplatePage = async ({ page }) => {
    setShowGlobalResponseLoader(true)
    updateSharedTemplatePage({ disabled: !page.disabled }, page.id, () => {
      if(!page.disabled) {
        setNotification({ msg: t('share_template.link_disabled'), type: 'info' })
      }else {
        setNotification({ msg: t('share_template.link_enabled'), type: 'info' })
      }
      document.body.click()
      setShowGlobalResponseLoader(false)
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  // Delete page
  const deleteSharedTmpltPage = async ({ pageToDelete, handleClosePageDeleteAlert }) => {
    if(!pageToDelete) return 

    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText(t('share_template.deleting_page'))
    deleteSharedTemplatePage(pageToDelete.id, async () => {
      try {
        if(pageToDelete.shared_templates) {
          let promises = []
          let templateIds = pageToDelete.shared_templates 
          if(Array.isArray(templateIds)) {
            templateIds.forEach(id => {
              promises.push(delete_shared_template(id))
            })
            setGlobalResponseLoaderText(t('share_template.deleting_templates'))
            await Promise.all(promises)
          }
        }
        let paths = [];
        // Delete logo
        if(pageToDelete.logo_path) {
          const splitted = pageToDelete.logo_path.split('.')
          const path = [...splitted].slice(0, splitted.length - 1).join('.')
          const format = splitted[splitted.length - 1]
          paths = [pageToDelete.logo_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        }
        if(paths.length > 0) {
          setGlobalResponseLoaderText(t('general.deleting_image'))
          for(let i = 0; i < paths.length; i++) {
            await delete_file(paths[i])
          }
        }
        if(globalSharedPageTemplates[pageToDelete.id]) {
          const globalSPT = {...globalSharedPageTemplates}
          delete globalSPT[pageToDelete.id]
          setGlobalSharedPageTemplates(globalSPT)
        }
        setNotification({ msg: t('share_template.page_successfully_deleted'), type: 'success' })
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        if(handleClosePageDeleteAlert) {
          handleClosePageDeleteAlert()
        }
      } catch(err) {
        console.log(err)
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        if(handleClosePageDeleteAlert) {
          handleClosePageDeleteAlert()
        }
      }
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    })
  }

  const updateSharedTemplatePageAlias = async ({ page, alias }) => {
    setShowGlobalResponseLoader(true)
    updateSharedTemplatePage({ alias }, page.id, () => {
      setNotification({ msg: t('share_template.alias_updated'), type: 'success' })
      setShowGlobalResponseLoader(false)
    }, (err) => {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  return {
    copySharedTemplatePageUrl,
    renewSharedTemplatePage,
    disableSharedTemplatePage,
    deleteSharedTmpltPage,
    updateSharedTemplatePageAlias
  }
}

export default useSharedTemplatePageActions
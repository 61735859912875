import React, { useContext, useState } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import Modal from '../UI/Modal';
import Alert from '../UI/Alert';
import ResponseLoader from '../UI/ResponseLoader';
import { TagContext, NotificationContext, DocumentsContext, TeamContext, GlobalContext } from '../../context';

const TagsModal = ({ onClose, doc, onSetDocInformations, onSetView }) => {
  const { t } = useContext(GlobalContext)
  const { tags, deleteTag } = useContext(TagContext);
  const { setNotification } = useContext(NotificationContext);
  const { updateDocument } = useContext(DocumentsContext);
  const { activeTeamMember } = useContext(TeamContext);
  const [showDeletetagAlert, setShowDeleteTagAlert] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deleting, setDeleting] = useState(false);

  // On delete icon click
  const deleteTagIconClickHandler = (tag) => {
    setSelectedTag(tag);
    setShowDeleteTagAlert(true);
  }

  // On alert submit - delete tag
  const deleteTagHandler = () => {
    setDeleting(true);
    deleteTag(selectedTag.id, () => {
      setDeleting(false);
      setNotification({ msg: t('notification.tag_deleted'), type: 'success' });
      setShowDeleteTagAlert(false);
      onSetDocInformations(async prev => {
        const data = {
          ...prev,
          keywords: [...prev.keywords].filter(k => k !== selectedTag.id)
        }
        if(data.tags) {
          delete data.tags;
        }
        await updateDocument({ info: data, last_modified_by: activeTeamMember.id }, doc);
        onSetView('tags');
        return data;
      });
    }, () => {
      setDeleting(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      setShowDeleteTagAlert(false);
    });
  }

  return (
    <Modal onClose={onClose} medium2>
      <div className="v2-modal">
        <div className="v2-modal__head">
          <h5>{t('dashboard.tags')}</h5>
        </div>
        <div className="v2-modal__body v2-modal__body--no-padding">
          <ul className="documents-list documents-list--tags">
            {tags.map((tag, i) => (
              <li className="documents-list__item" key={i}>
                <p className="tag">{tag.name}</p>
                <div className="delete-icon" onClick={() => deleteTagIconClickHandler(tag)}><DeleteOutlinedIcon /></div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {showDeletetagAlert && <Alert 
        text={t('alert.delete_tag')}
        onClose={() => setShowDeleteTagAlert(false)} 
        onSubmit={deleteTagHandler} 
      />}
      {deleting && <ResponseLoader />}
    </Modal>
  );
}

export default TagsModal;
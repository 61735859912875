import { useContext, useEffect, useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { FormLabel } from '@material-ui/core'

import { Modal, Input, Checkbox } from '../../new_ui'
import { LoaderContext, UserContext, GlobalContext } from '../../../context'
import { capitalizeWord, availableOn } from '../../../utils'
import { create_customer, create_payment_intent } from '../../../services/billing'
import config from '../../../config.json'

const BuySignatureCreditsModal = ({ onClose, onSuccess }) => {
  const { t } = useContext(GlobalContext)
  const { user, setUser } = useContext(UserContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [cardValid, setCardValid] = useState(false)
  const [cardError, setCardError] = useState(null)
  const [storePaymentMethod, setStorePaymentMethod] = useState(false)
  const [checkout, setCheckout] = useState({
    name: '',
    paymentMethod: null,
    useNewPaymentMethod: true
  })
  const stripe = useStripe()
  const elements = useElements()

  // Set checkout 
  useEffect(() => {
    if(!user) {
      return
    }
    if(!checkout.name) {
      setCheckout({
        ...checkout,
        name: `${user.first_name || ''}${user.first_name ? ' ' : ''}${user.last_name || ''}`,
        paymentMethod: user.payment_method,
        useNewPaymentMethod: !Boolean(user.payment_method)
      })
    }
  }, [user])

  // On payment submit
  const onPaymentSubmit = async () => {

    //   const plan = checkout.plan
  
    setShowGlobalResponseLoader(true)

    let customer_id = user.stripe_customer_id
    if(!customer_id) {
      const customerResponse = await create_customer()
      if(customerResponse.error) {
        console.log("create customer error")
        console.log(customerResponse.error)
        return 
      }
      customer_id = customerResponse.customer_id
      setUser({
        ...user,
        stripe_customer_id: customer_id
      })
    }

    const paymentIntentResponse = await create_payment_intent({
      customer_id,
      product: 'signature_100',
      store_payment_method: storePaymentMethod
    })
    if(paymentIntentResponse.error) {
      console.log("Create payment intent error")
      console.log(paymentIntentResponse.error)
      setShowGlobalResponseLoader(false)
      return 
    }
    const { client_secret } = paymentIntentResponse

  //   // todo use existing if present in user data

    let paymentMethodId
    if(checkout.useNewPaymentMethod) {
      const cardElement = elements.getElement(CardElement);

      if(!cardElement) {
        setShowGlobalResponseLoader(false)
        return
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: checkout.name
        }
      });

      if (error) {
        console.log('create payment method error', error);
        setShowGlobalResponseLoader(false)
        return 
      } 
      paymentMethodId = paymentMethod.id
    } else {
      paymentMethodId = checkout.paymentMethod.id
    }

    const paymentResponse = await stripe.confirmCardPayment(client_secret, {
      payment_method: paymentMethodId
    })

    if(paymentResponse.error) {
      console.log("payment error")
      console.log(paymentResponse.error)
      setShowGlobalResponseLoader(false)
      return
    }

    if(paymentResponse.paymentIntent.status === 'succeeded') {
      onSuccess(100)      
    }
    setShowGlobalResponseLoader(false)
  }

  // On card change
  const onCardChange = (event) => {
    setCardError(event.error)
    setCardValid(event.complete)
  }
    
  // Check if checkout is valid
  const isCheckoutValid = () => {
    if(!checkout.name.trim()) {
      return false
    }
    if(checkout.useNewPaymentMethod && !cardValid) {
      return false
    }
    return true
  }

  return (
    <Modal 
      onClose={onClose} 
      title={t('general.buy_more_credits')} 
      className="centered-head"
      saveBtnText={t('billing.confirm_purchase')}
      saveBtnDisabled={!isCheckoutValid()}
      onSave={onPaymentSubmit}
      hideFooter={config.environment === 'production'}
    >
      {availableOn(['development']) ? (
        <div className="buy-credits">
          <p className="buy-credits__subtitle text-style-1">Purchase 100 signature credits for €10.00</p>
          <Input 
            label={t('membership.full_name')}
            value={checkout.name}
            invalid={!checkout.name}
            onChange={(e) => setCheckout({...checkout, name: e.target.value })}
          />
          <p className="input-v2__label">{t('membership.payment_method')}</p>
          {checkout.useNewPaymentMethod ? (
            <div className="buy-credits__payment-method">
              <div className="card">
                <CardElement
                  onChange={onCardChange}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontWeight: '400',
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '0.9375rem',
                        fontSmoothing: 'antialiased'
                      }
                    }
                  }}/>
              </div>
              <div className="save-for-later">
                <Checkbox 
                  label={'Save payment method for later purchases'}
                  checked={storePaymentMethod}
                  onChange={e => setStorePaymentMethod(e.target.checked)}
                />
              </div>
              { Boolean(checkout.paymentMethod) && (
                <div className="use-current">
                  <button className="btn btn--small" onClick={() => setCheckout({...checkout, useNewPaymentMethod: false })}>{t('membership.use_current')}</button>
                </div>
              )}
            </div>
          ) : (
            <div className="buy-credits__use-new-payment">
              <p className="text-style-1">{ `${capitalizeWord(checkout.paymentMethod.brand)} **${checkout.paymentMethod.last4}` }</p>
              <button className="btn btn--small" onClick={() => setCheckout({...checkout, useNewPaymentMethod: true })}>
                {t('membership.use_new')}
              </button>
            </div>
          )}
          { cardError && <div className="payment-error">{ cardError.message }</div> }
        </div>
      ) : (
        <div className="buy-credits">
          <div className="buy-credits__coming-soon">
            <p className="text-style-3">{t('general.coming_soon')}</p>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default BuySignatureCreditsModal
import { useContext, useState, useEffect, memo } from 'react'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { GlobalContext } from '../../../context'

const LinkUserFieldToVariable = ({ fieldName, fieldKey, filteredVariables, linkedVariables, onLinkVariable, onRemoveLinkedVariable, onSetFilteredVariables, variables }) => {
  const { t } = useContext(GlobalContext)
  const [selectedVariable, setSelectedVariable] = useState(t('contacts.select_variable'))
  const [hideDropdown, setHideDropdown] = useState(false)
  const [hideVarsDropdown, setHideVarsDropdown] = useState(false)
  const [search, setSearch] = useState('')

  // Set selected variable
  useEffect(() => {
    if(linkedVariables[fieldKey]) {
      setSelectedVariable(linkedVariables[fieldKey])
    }
  }, [linkedVariables, fieldKey])

  // On cancel click
  const handleCancelClick = () => {
    setHideDropdown(true)
    setTimeout(() => setHideDropdown(false), 50)
    setSearch('')
    onSetFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
  }

  // On search change
  const handleSearchChange = (e) => {
    const { value } = e.target
    setSearch(value)
    if(value.trim()) {
      let processedValue = value.trim().toLowerCase()
      let filtered = []
      variables.forEach(v => {
        if(v.variable.toLowerCase().includes(processedValue)) {
          filtered.push(v)
        }
      })
      onSetFilteredVariables(filtered)
    }else {
      onSetFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
    }
  }

  // On variable select
  const handleVariableSelect = (e, v) => {
    e.preventDefault()
    setSelectedVariable(v)
    setSearch('')
    onSetFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
    setHideVarsDropdown(true)
    setTimeout(() => setHideVarsDropdown(false), 50)
  }

  // On link variable
  const handleLinkVariable = () => {
    onLinkVariable(fieldKey, selectedVariable)
  }

  // On remove linked variable
  const handleRemoveLinkedVariable = () => {
    onRemoveLinkedVariable(fieldKey)
    setSelectedVariable(t('contacts.select_variable'))
  }

  // Check if button should be disabled
  const isButtonDisabled = () => {
    let disabled = false
    if(selectedVariable === t('contacts.select_variable')) disabled = true
    return disabled
  }

  // On click away
  const handleClickAway = () => {
    if(search !== '') {
      setSearch('')
      onSetFilteredVariables(variables.filter((v) => v.type === 'string' || v.type === 'number' || v.type === 'date'))
    }
  }

  return (
    <div className="link-column-to-variable">
      <ActionsDropdown
        trigger={<button type="button" className="link-column-to-variable__btn"><span className="material-symbols-outlined">expand_more</span></button>}
        hide={hideDropdown}
        headTitle={fieldName}
        onClickAway={handleClickAway}
      >
        <div className="link-variable-section">
          <div className="link-variable-section__body">
            <ActionsDropdown
              trigger={
                <a href="/#" onClick={(e) => e.preventDefault()} className="trigger-btn">
                  <span className="text">{selectedVariable}</span> 
                  <span className="icon"><span className="material-symbols-outlined">arrow_drop_down</span></span>
                </a>
              }
              hideHeader
              hide={hideVarsDropdown}
            >
              <div className="link-variable-search">
                <input placeholder={t('dashboard.search')} value={search} onChange={handleSearchChange} />
              </div>
              <div className="link-variable-list u-custom-scrollbar">
                <ul>
                  {filteredVariables.map((v, idx) => (
                    <li key={idx}>
                      <div className="link link--small">
                        <a href="/#" onClick={(e) => handleVariableSelect(e, v.variable)}>{v.variable}</a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </ActionsDropdown>
          </div>
          <div className="link-variable-section__foot">
            <button type="button" className="btn btn--small btn--outline cancel-btn" onClick={handleCancelClick}>{t('general.cancel')}</button>
            <button type="button" className="btn btn--small" onClick={handleLinkVariable} disabled={isButtonDisabled()}>
              {t('contacts.link_variable')}
            </button>
            {linkedVariables && linkedVariables[fieldKey] && (
              <button type="button" className="btn btn--small btn--danger remove-btn" onClick={handleRemoveLinkedVariable}>
                {t('contacts.unlink_variable')}
              </button>
            )}
          </div>
        </div>
      </ActionsDropdown>
    </div>
  )
}

export default memo(LinkUserFieldToVariable)
import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WebfontLoader from '@dr-kobros/react-webfont-loader'

import Alert from './components/UI/Alert'
import Notification from './components/UI/Notification';
import { fetch_user, fetch_actions } from './services/firestore';
// import { createBrowserHistory } from 'history'
import { DocumentsContext, UserContext, LoaderContext, NotificationContext, TeamContext, GlobalContext, TaskContext } from './context';
import firebase from './services/firebase'
import PageLoader from './components/UI/PageLoader';
import ResponseLoader from './components/UI/ResponseLoader';
import useAuth from './hooks/useAuth';
import Cookies from './components/sections/Cookies';
import { isCookieAccepted, acceptCookies } from './utils';
import { GA_ID } from './constants';
import { addMessageListener, getToken } from './services/messaging';
import Routes from './routing'
import NewTaskPopup from './components/sections/dashboard/NewTaskPopup'
import { useRealTimeData } from './hooks';
import { createTheme, ThemeProvider } from '@material-ui/core';

const webFontConfig = {
  google: {
    families: ['Material+Symbols+Outlined', 'Roboto:300,400,500,700'],
  }
}

const App = () => {
  const { setActions } = useContext(DocumentsContext)
  const { setUser, membership, setMembership, setSubscription, setTeamMembership, user, setMembershipPlans } = useContext(UserContext) 
  const { setLoading } = useContext(LoaderContext)
  const { notification } = useContext(NotificationContext)
  const { fetchTeams, selectedTeam, fetchCollections, setFetchCollections, setActiveTeamMember } = useContext(TeamContext)
  const { t, setSelectedLang, unsubscribeSubscription, unsubscribeInvoices, unsubscribeUserListeners, unsubscribeTeam, selectedTeamRef } = useContext(GlobalContext)
  const { setSelectedAssignee, selectedAssignee, showTaskPopup, setShowTaskPopup } = useContext(TaskContext)
  // const [statusFetched, setStatusFetched] = useState(true);
  // const [authToken, setAuthToken] = useState(null)
  // const history = createBrowserHistory()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(true)
  const { logout } = useAuth()
  const [fetchedOnLogin, setFetchedOnLogin] = useState(false)
  const [teamChangeLoader, setTeamChangeLoader] = useState(false)
  const [cookiesChanged, setCookiesChanged] = useState(false)
  const [showCookies, setShowCookies] = useState(!isCookieAccepted())
  const [showTrackingCode, setShowTrackingCode] = useState(false)
  const [showOpenCloseTaskPopupAlert, setShowOpenCloseTaskPopupAlert] = useState(false)
  const [showGuides, setShowGuides] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [fadeLoading, setFadeLoading] = useState(false)
  const [fontLoaded, setFontLoaded] = useState(false)
  const { listenToSubscriptionChanges, listenToInvoicesChanges, listenToTeamChanges } = useRealTimeData()

  // Listen to auth state changes
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      setIsAuthenticated(user !== null)
      if(user) {
        try {
          unsubscribeSubscription.current = listenToSubscriptionChanges(user)
          unsubscribeInvoices.current = listenToInvoicesChanges(user)

          const loggedInUser = await fetch_user(user.uid)
          if(loggedInUser) {
            // console.log(loggedInUser)
            setUser({...loggedInUser, id: user.uid})
            if(loggedInUser.selected_language) {
              setSelectedLang(loggedInUser.selected_language)
            }
          } else {
            // const userData = {
            //   first_name: user.displayName ? user.displayName.split(' ')[0] : '',
            //   last_name: user.displayName ? user.displayName.split(' ')[1] : '',
            //   email: user.email,
            // };
            // const userRes = await create_user({ data: userData, id: user.uid })
            // if(userRes.success) {
            //   setUser({...userData, id: user.uid})
            //   onSignIn()
            // }
          }
          setAuthenticating(false)
          const { teamId, teams } = await fetchTeams(null, { email: user.email, id: user.uid }, membership)
          const activeMember = [...teams].find(tm => tm.id === teamId).users.find(u => u.email === user.email);
          setActiveTeamMember(activeMember || { status: 'pending'})
          await onSignIn(teamId)
        } catch (err) {
          console.log(err)
          await onSignOut()
        }
      } else {
        unsubscribeUserListeners()
        await onSignOut()
      }
      setAuthenticating(false)
    })
  }, [])

  const setupPushNotifications = async (teamId) => {
    let tokenResponse = await getToken()
    if(tokenResponse.error) {
      console.log(tokenResponse.error)
      if(tokenResponse.error.code === 'messaging/permission-blocked') {
        alert("Carbon cannot send you notifications")
        // todo display a nicer alert
      } 
      return
    }
    addMessageListener((payload) => {
      console.log("message received", payload)
      fetchActions(teamId)
    })
  }

  const fetchActions = async (teamId) => {
    let actions = await fetch_actions(teamId, null, null)
    if(actions.error) {
      console.log('fetch actions error', actions.error)
      return
    }
    setActions(actions)
    // setActionsFetched(true)
    // setLoading(false)
  }
  
  
  useEffect(() => {
    if(!selectedTeam) {
      return
    }
    if(selectedTeam.id !== selectedTeamRef.current && unsubscribeTeam.current) {
      // unsubscribe previous team changes
      unsubscribeTeam.current()
      // subscribe new team changes
      unsubscribeTeam.current = listenToTeamChanges()
      selectedTeamRef.current = selectedTeam.id
    }
  }, [selectedTeam, listenToTeamChanges, unsubscribeTeam, selectedTeamRef])

  const onSignIn = async (teamId) => {
    setLoading(true)
    
    setupPushNotifications(teamId)
    setLoading(false)
    setFetchedOnLogin(true)
  }

  const onSignOut = async () => {
    logout()
  }

  // Fetch collections when team change
  useEffect(() => {
    if(fetchedOnLogin && fetchCollections) {
      setFetchCollections(false)
    }
  }, [selectedTeam, fetchedOnLogin, fetchCollections, setFetchCollections])

  // On cookies update
  useEffect(() => {
    if(cookiesChanged) {
      setShowCookies(false)
    }
  }, [cookiesChanged])

  // Show tracking code if cookie tracking prop is set to true
  useEffect(() => {
    if(isCookieAccepted()?.tracking) {
      setShowTrackingCode(true)
    }
  }, [cookiesChanged])

  // On open close task popup alert
  const handleOpenCloseTaskPopupAlert = () => {
    setShowOpenCloseTaskPopupAlert(true)
  }

  // On open close task popup alert
  const handleCloseTaskPopupAlert = () => {
    setShowOpenCloseTaskPopupAlert(false)
  }

  // On close task popup
  const handleCloseTaskPopup = () => {
    setShowTaskPopup(false)
    setShowOpenCloseTaskPopupAlert(false)
    setSelectedAssignee(null)
  }

  // On guides skip
  const handleGuidesSkip = async () => {
    setShowGuides(false)
    setUser({...user, guides_skipped: true})
    // save user to db
  }

  // On fade out
  const handleFadeOut = () => {
    setFadeLoading(true)
    setTimeout(() => {
      setPageLoading(false)
    }, 500)
  }

  // On font status change
  const handleFontStatusChange = (font, variation, status) => {
    console.log(font, variation, status)
  }

  // On font status change
  const handleStatusChange = (status) => {
    if(status === 'active') {
      setFontLoaded(true)
    }
  }
  
  if(pageLoading) {
    if(!authenticating && !fadeLoading && fontLoaded) {
      handleFadeOut()
    }
    return (
      <WebfontLoader config={webFontConfig} onStatus={handleStatusChange} onFontStatus={handleFontStatusChange}>
        <PageLoader fadeOut={fadeLoading} />
      </WebfontLoader>
    )
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#006EFF',
        light: '#00A2FF',
        dark: '#0044CC',
      }
    }
  })
  

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          {/* <!-- Primary Meta Tags --> */}
          <title>Carbon</title>
          <meta name="title" content="Carbon" />
          <meta name="description" content={t('landing.intro_title')} />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://getcarbon.ai/" />
          <meta property="og:title" content="Carbon" />
          <meta property="og:description" content={t('landing.intro_title')} />
          <meta property="og:image" content="https://getcarbon.ai/assets/og_image.png" />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://getcarbon.ai/" />
          <meta property="twitter:title" content="Carbon" />
          <meta property="twitter:description" content={t('landing.intro_title')} />
          <meta property="twitter:image" content="https://getcarbon.ai/assets/og_image.png" />
          {showTrackingCode && <script async src={`https://www.googletagmanager.com/gtag/js?id=G-${GA_ID}/`}></script>}
          {showTrackingCode && <script>
            {
            `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-${GA_ID}');`
            }
          </script>}
        </Helmet>

        <ThemeProvider theme={theme}>
          <Routes isAuthenticated={isAuthenticated} />

          {notification.msg && <Notification />}
          {showCookies && <Cookies shouldAcceptCookies={acceptCookies} setCookiesChanged={setCookiesChanged} />}
          {teamChangeLoader && <ResponseLoader />}
          {showTaskPopup && <NewTaskPopup onClose={handleOpenCloseTaskPopupAlert} assignee={selectedAssignee} />}
          {showOpenCloseTaskPopupAlert && <Alert 
            text={t('alert.close_task_popup')}
            onClose={handleCloseTaskPopupAlert}
            deleteAlert
            onSubmit={handleCloseTaskPopup}
          />}
          </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

import React, { useEffect, useContext, useCallback } from 'react';

import Popup from '../UI/Popup';
import Input from '../UI/Input';
import useForm from '../../hooks/useForm';

import { FolderContext, DocumentsFoldersContext, NotificationContext, TeamContext, GlobalContext } from '../../context';

const CreateFolderModal = ({ onClose, mode = 'create', onCreate, onEdit, currentFolder, foldersLoading, view = 'templates' }) => {
  const { t, validate } = useContext(GlobalContext)
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);
  const { parentFolder, setParentFolder } = useContext(FolderContext);
  const { docParentFolder, setDocParentFolder } = useContext(DocumentsFoldersContext);
  const { setNotification } = useContext(NotificationContext);
  const { selectedTeam } = useContext(TeamContext);

  useEffect(() => {
    if(mode === 'edit') {
      setFormData(prev => ({
        ...prev,
        name: {
          ...prev.name,
          value: currentFolder.name
        }
      }));
    }
    // eslint-disable-next-line
  }, [currentFolder]);

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      const data = {
        name: formData.name.value.trim()
      }
      if(mode === 'create') {
        if(view === 'templates') {
          data.parentFolder = parentFolder ? parentFolder.id : null;
        }else{
          data.parentFolder = docParentFolder ? docParentFolder.id : null;
        }
        data.team = selectedTeam?.id;
        try {
          await onCreate(data);
          if(view === 'templates') {
            setParentFolder(null);
          } else {
            setDocParentFolder(null);
          } 
          setNotification({ msg: t('folder.created', { folder: data.name }), type: 'success' });
        } catch (err) { 
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
        }
      }else {
        onEdit(data, currentFolder.id);
      }
    }
  }, [currentFolder, docParentFolder, formData, mode, onCreate, onEdit, parentFolder, selectedTeam, setDocParentFolder, setErrors, setNotification, setParentFolder, t, validate, view])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        submitHandler(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [submitHandler])

  return(
    <Popup 
      onClose={() => { setParentFolder(null); onClose(); }} 
      title={mode === 'create' ? t('folder.create') : t('folder.update')}
      onCancel={() => { setParentFolder(null); onClose(); } }
      submitBtnText={foldersLoading ? mode === 'create' ? t('general.creating') : t('dashboard.updating') : t('general.save_changes')}
      submitBtnDisabled={foldersLoading}
      onSubmit={submitHandler}
    >
      <div className="folder-modal">
        <div className="form">
          <Input 
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            placeholder={t('folder.name_placeholder')}
            popupMode
          />
        </div>
      </div>
    </Popup>
  );
}

export default CreateFolderModal;
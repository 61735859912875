import { useContext } from 'react';

import { GlobalContext } from '../../context'

const ChartTooltip = ({ active, payload }) => {
  const { t } = useContext(GlobalContext)

  if (active) {
    return (
      <div className="chart-tooltip-v2">
        <p className="chart-tooltip-v2__text label">{payload && payload[0].payload.label}</p>
        <p className="chart-tooltip-v2__text">{`${t('dashboard.documents')}: ${payload && payload[0].payload.count}`}</p>
      </div>
    )
  }

  return null
}

export default ChartTooltip
import React, { useState, useContext, useRef, useEffect } from 'react';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ClickAwayListener } from '@material-ui/core';

import FoldersPopup from '../sections/FoldersPopup';
import CustomPopup from './CustomPopup';
import CustomTooltip from './CustomTooltip';

import { FolderContext, GlobalContext, DocumentsFoldersContext, NotificationContext, LoaderContext } from '../../context';
import { getAllParentFolders, folderHasSubfolders, folderHasTemplates, isOverflown } from '../../utils';


const FolderListItem = ({ folder, onCreateFolder, onEdit, onDelete, onFilter, selectedFilter, onOpenFolderModal, onMoveToFolder, folders, foldersLoading, templates, templatesLoading, onMoveTemplateToFolder, view = 'templates', onMoveDocumentToFolder, disableFolders }) => {
  const { t } = useContext(GlobalContext)
  const [showDropdown, setShowDropdown] = useState(false);
  const [makeDraggable, setMakeDraggable] = useState(false);
  const { updateFolder, setFolderToMove } = useContext(FolderContext);
  const { updateDocFolder, setDocFolderToMove } = useContext(DocumentsFoldersContext);
  const [showPopup, setShowPopup] = useState(false);
  const [arrowActive, setArrowActive] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const hasSubFolders = folderHasSubfolders(folders, folder);
  const subfolders = [...folders].filter(f => f.parentFolder === folder.id);
  const popupEl = useRef();
  const [populElPosition, setPopupElPosition] = useState(null);

  const [isDefaultFolder, setIsDefaultFolder] = useState(true)

  useEffect(() => {
    if(!folder) {
      setIsDefaultFolder(true)
      return
    }
    setIsDefaultFolder(folder.owner === 'carbon')
  }, [folder])

  useEffect(() => {
    if(folder.id === selectedFilter && !arrowActive && hasSubFolders) {
      setArrowActive(true);
    }
    // eslint-disable-next-line
  }, [selectedFilter]);

  useEffect(() => {
    if(!hasSubFolders && arrowActive) {
      setArrowActive(false);
    }
    // eslint-disable-next-line
  }, [hasSubFolders]);

  // New folder
  const newFolderClickHandler = (e, folder) => {
    onCreateFolder(e, folder);
    setShowDropdown(false);
  }

  // Edit folder
  const editClickHandler = () => {
    onEdit(folder); 
    setShowDropdown(false);
  }

  // Deplacer text click - show folders popup
  const moveClickHandler = () => {
    setShowPopup(!showPopup);
    // setShowDropdown(false);
    if(view === 'templates') {
      setFolderToMove(folder);
    }else {
      setDocFolderToMove(folder);
    }
  }

  // Delete
  const deleteClickHandler = () => {
    if(folderHasSubfolders(folders, folder)) {
      return setNotification({ msg: t('folder.not_empty', { folder: folder.name }), type: 'warning' });
    }
    if(folderHasTemplates(templates, folder)) {
      return setNotification({ msg: t('folder.not_empty_templates', { folder: folder.name }), type: 'warning' });
    }
    onDelete(folder, true); 
    setShowDropdown(false);
  }

  // Drag start
  const dragStartHandler = (e, folder) => {
    setShowPopup(false);
    const div = document.createElement('div');
    div.id = 'draggable-folder-el';
    div.className = 'draggable-ghost-el';
    div.innerText = t('folder.move', { folder: folder.name });
    document.getElementById('root').appendChild(div);
    e.dataTransfer.setData('folder', JSON.stringify(folder));
    e.dataTransfer.setDragImage(div, 0, 0);
  }
  
  // Drag over
  const dragOverHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(!folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover');
    }
    if(hasSubFolders && !arrowActive) {
      setArrowActive(true);
    }
  }

  // Drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
  }
  
  // Drop 
  const dropHandler = async (e, folder) => {
    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null;

    const folderBox = e.target.closest('.item-inner');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
    
    if(data) {
      const parentFolders = getAllParentFolders(folders, folder);
      const folderBox = e.target.closest('.item-inner');
      if(folderBox.classList.contains('hover')) {
        folderBox.classList.remove('hover');
      }

      if(folder.id === data.id) 
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: folder.name }), type: 'warning' });
      if(folder.parentFolder === data.id) 
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: folder.name }), type: 'warning' });
      if(data.parentFolder === folder.id) 
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: folder.name }), type: 'warning' });
      if(parentFolders.find(f => f.id === data.id)) 
        return setNotification({ msg: t('folder.unable_to_move_to_folder', { folder1: data.name, folder2: folder.name }), type: 'warning' });
  
      setLoading(true);
      // console.log(`Move ${data.name} to ${folder.name}`);
      if(view === 'templates') {
        await updateFolder({ parentFolder: folder.id }, data.id);
      }else {
        await updateDocFolder({ parentFolder: folder.id }, data.id);
      }
      setLoading(false);
      setNotification({ msg: t('folder.moved_to_folder', { folder1: data.name, folder2: folder.name }), type: 'default' }); 
      return;
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null;

    if(templateData) {
      return onMoveTemplateToFolder(templateData, folder);
    }

    const documentData = e.dataTransfer.getData('document') ? JSON.parse(e.dataTransfer.getData('document')) : null;

    if(documentData) {
      onMoveDocumentToFolder(documentData, folder);
    }
  }

  // Drag end 
  const dragEndHandler = (e) => {
    document.getElementById('draggable-folder-el').remove();
  }

  // Make draggable on mouse enter
  const handleMouseEnter = (e) => {
    setMakeDraggable(true);
  }

  // Disable draggable on mouse leave
  const handleMouseLeave = (e) => {
    setMakeDraggable(false);
  }

  // Arrow click - show subfolders menu
  const folderArrowClickHandler = () => {
    setArrowActive(!arrowActive);
  }

  const renderFoldersPopup = () => {
    return(
      <CustomPopup elPagePosition={populElPosition} fixed>
        <ClickAwayListener onClickAway={() => !showDropdown && setShowPopup(false)}>
          <div className="dropdown-wrapper">
            {showPopup && <div className="dropdown-el folders-dropdown">
              <FoldersPopup 
                ref={popupEl} 
                onCancel={() => setShowPopup(false)} 
                onOpenFolderModal={onOpenFolderModal} 
                onMoveToFolder={onMoveToFolder}
                folders={folders}
                foldersLoading={foldersLoading}
                loading={templatesLoading}
                data={templates}
                show={showPopup}
                onShow={setShowPopup}
                fromSidebar
                onMoveResourceToFolder={onMoveTemplateToFolder}
              />
            </div>}
          </div>
        </ClickAwayListener>
      </CustomPopup>
    );
  }

  return(
    <li 
      className={selectedFilter === folder.id ? 'filters__list_item active' : 'filters__list_item'} 
    >
      <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
        <div 
          className={hasSubFolders ? "item-inner" : "item-inner item-inner--no-subfolders"}
          draggable={!isDefaultFolder ? makeDraggable : null} 
          onDragStart={(e) => dragStartHandler(e, folder)}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={(e) => dropHandler(e, folder)}
          onDragEnd={dragEndHandler}
        >
          {hasSubFolders && <span className={arrowActive ? "icon-arrow active" : "icon-arrow"} onClick={folderArrowClickHandler}>
            <ArrowRightIcon className="right-icon" />
            <ArrowDropDownIcon className="drop-down-icon" />
          </span>}
          <p onClick={() => onFilter(folder)}>
            <span className="icon-left" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}><FolderIcon /></span> 
            <Name name={folder.name} />
          </p>
          {(!disableFolders && !isDefaultFolder) && <span 
            className="icon-right"
            onClick={(e) => {
              setPopupElPosition(e.currentTarget.getBoundingClientRect())
              setShowDropdown(!showDropdown)
            }} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <MoreHorizIcon />
          </span>}
          {showDropdown && <div className="dropdown">
            <p onClick={(e) => newFolderClickHandler(e, folder)} className="create"><span><CreateNewFolderIcon /></span> {t('folder.new')}</p>
            <p onClick={editClickHandler}><span><EditIcon /></span> {t('general.rename')}</p>
            <p onClick={moveClickHandler} className="move"><span><SubdirectoryArrowLeftIcon /></span> {t('general.move')}</p>
            <p onClick={deleteClickHandler} className="delete"><span><DeleteForeverIcon /></span> {t('general.delete')}</p>
          </div>}
        </div>
      </ClickAwayListener>
      {renderFoldersPopup()}
      {subfolders.length > 0 && arrowActive && <ul className="subfolder">
        {subfolders.map((f, i) => (
          <FolderListItem
            key={i} 
            folder={f}
            onCreateFolder={onCreateFolder}
            onEdit={onEdit}
            onDelete={onDelete}
            onFilter={onFilter}
            selectedFilter={selectedFilter}
            onOpenFolderModal={onOpenFolderModal}
            onMoveToFolder={onMoveToFolder}
            folders={folders}
            foldersLoading={foldersLoading}
            templates={templates}
            templatesLoading={templatesLoading}
            onMoveTemplateToFolder={onMoveTemplateToFolder}
            view={view}
            disableFolders={disableFolders}
            onMoveDocumentToFolder={onMoveDocumentToFolder}
          />
        ))}
      </ul>}
      {arrowActive && subfolders.length === 0 && <p className="no-folders">{t('dashboard.no_folders')}</p>}
    </li>
  );
}

const Name = ({ name }) => {
  const elRef = useRef()

  return (
    <CustomTooltip content={name} hideTooltip={!isOverflown(elRef.current)}>
      <span className="name" ref={elRef}>{name}</span>
    </CustomTooltip>
  )
}

export default FolderListItem;
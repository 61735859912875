import React, { useState, useContext } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import GroupIcon from '@material-ui/icons/Group';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ShuffleIcon from '@material-ui/icons/ShuffleRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import CheckIcon from '@material-ui/icons/Check';
import { ClickAwayListener } from '@material-ui/core';
import { ShareOutlined, List } from '@material-ui/icons';
import { MdApproval } from 'react-icons/md';

import useAuth from '../../hooks/useAuth';
import PageLoader from '../UI/PageLoader';
import ResponseLoader from '../UI/ResponseLoader';
import InviteUsersModal from './settings/InviteUsersModal';
import { UserContext, TeamContext, GlobalContext } from '../../context';
import { LimitedFeatureWrapper } from '../utils/LimitedFeatureWrapper';
import LetterCircle from '../UI/LetterCircle';
import {
  hasSufficientMembership,
  saveSelectedTeamToLS,
  availableOn,
} from '../../utils';
import config from '../../config.json';

const SideMenuLong = ({ active, onShowLongMenu }) => {
  const { t } = useContext(GlobalContext);
  const { logout, resetState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [showTeamDropdown, setShowTeamDropdown] = useState(false);
  const [showTeamInnerDropdown, setShowTeamInnerDropdown] = useState(false);
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
  const { user, teamMembership } = useContext(UserContext);
  const {
    teams,
    selectedTeam,
    setSelectedTeam,
    setFetchCollections,
    activeTeamMember,
    setActiveTeamMember,
    setTeamChanged,
  } = useContext(TeamContext);
  const history = useHistory();

  const menuIconClickHandler = (e) => {
    e.preventDefault();
    onShowLongMenu(false);
  };

  const logoutHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    logout();
  };

  const changeTeamHandler = (team) => {
    console.log('change team handler', team);

    setSelectedTeam(team);
    saveSelectedTeamToLS(team.id);
    setShowTeamDropdown(false);
    setShowTeamInnerDropdown(false);
    const activeMember = team.users.find(
      (u) => u.email === activeTeamMember.email
    );
    setActiveTeamMember({ ...activeTeamMember, ...activeMember });
    onShowLongMenu(false);
    resetState('team-change');
    setFetchCollections(true);
    setTeamChanged(true);
  };

  return (
    <div
      className={
        active
          ? 'side-menu side-menu--long active'
          : 'side-menu side-menu--long'
      }
    >
      <div
        className="side-menu__bg"
        onClick={() => onShowLongMenu(false)}
      ></div>
      <ul className="side-menu__list">
        <li className="menu">
          <Link to="/" onClick={menuIconClickHandler}>
            <div className="icon">
              {/* <div className="logo">CA</div> */}
              <MenuOpenIcon />
            </div>
          </Link>
          {selectedTeam && (
            <div className="menu-team">
              <ClickAwayListener onClickAway={() => setShowTeamDropdown(false)}>
                <div>
                  <p onClick={() => setShowTeamDropdown(!showTeamDropdown)}>
                    {selectedTeam?.name} <KeyboardArrowDownIcon />
                  </p>

                  {showTeamDropdown && (
                    <div className="dropdown-el default team-dropdown">
                      <div className="team-dropdown__head">
                        {selectedTeam.logo_url ? (
                          <div
                            className="team-dropdown__head_image-box"
                            style={{
                              backgroundImage: `url(${selectedTeam.logo_url})`,
                            }}
                          ></div>
                        ) : (
                          <LetterCircle data={selectedTeam.name} hideTooltip />
                        )}
                        <div className="team-dropdown__head_info-box">
                          <h5>{selectedTeam?.name}</h5>
                        </div>
                      </div>
                      <div className="team-dropdown__body">
                        <LimitedFeatureWrapper
                          requirement="premium"
                          type="team"
                        >
                          {user.id === selectedTeam.owner && (
                            <div
                              className="team-dropdown__link-item"
                              onClick={() => {
                                onShowLongMenu(false);
                                setShowTeamDropdown(false);
                                setShowInviteUsersModal(true);
                              }}
                            >
                              <PersonAddOutlinedIcon />
                              <p>{t('team.invite')}</p>
                            </div>
                          )}
                        </LimitedFeatureWrapper>
                        {/* <div 
                      className={`team-dropdown__link-item ${!hasSufficientMembership(teamMembership, 'premium') ? 'disabled' : ''}`} 
                      onClick={hasSufficientMembership(teamMembership, 'premium') ? () =>  {
                        onShowLongMenu(false);
                        setShowTeamDropdown(false); 
                        setShowInviteUsersModal(true);
                      } : () => {}}
                    >
                      <PersonAddOutlinedIcon />
                      <p>{t('team.invite')}</p>
                    </div> */}
                        <div
                          className="team-dropdown__link-item"
                          onClick={() => {
                            onShowLongMenu(false);
                            setShowTeamDropdown(false);
                            history.push('/settings/team', { mode: 'edit' });
                          }}
                        >
                          <SettingsOutlinedIcon />
                          <p>{t('team.settings')}</p>
                        </div>
                      </div>
                      <div className="team-dropdown__body team-dropdown__body--no-border-top">
                        <div
                          className="team-dropdown__link-item"
                          onMouseOver={() => setShowTeamInnerDropdown(true)}
                          onMouseLeave={() => setShowTeamInnerDropdown(false)}
                        >
                          <ShuffleIcon />
                          <p>{t('team.change')}</p>
                          <div className="icon-right">
                            <KeyboardArrowRightIcon />
                          </div>
                          {showTeamInnerDropdown && (
                            <div className="inner-dropdown">
                              <p className="inner-dropdown__title">
                                {t('team.other_teams')}
                              </p>
                              <ul className="inner-dropdown__list">
                                {teams
                                  .filter(
                                    (team) => team.id !== selectedTeam?.id
                                  )
                                  .map((team) => (
                                    <li
                                      key={team.id}
                                      onClick={() => changeTeamHandler(team)}
                                    >
                                      <div
                                        className="logo-box"
                                        style={
                                          team.logo_url
                                            ? {
                                                backgroundImage: `url(${team.logo_url})`,
                                              }
                                            : {}
                                        }
                                      ></div>
                                      <p>{team.name}</p>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <LimitedFeatureWrapper
                          type="account"
                          requirement="premium"
                        >
                          <div
                            className="team-dropdown__link-item"
                            onClick={() => {
                              onShowLongMenu(false);
                              setShowTeamDropdown(false);
                              history.push('/settings/add-team', {
                                mode: 'add',
                              });
                            }}
                          >
                            <AddIcon />
                            <p>{t('team.add')}</p>
                          </div>
                        </LimitedFeatureWrapper>
                      </div>
                      <div className="team-dropdown__foot">
                        <div
                          className="team-dropdown__link-item"
                          onClick={logoutHandler}
                        >
                          <i className="custom-icon-upload"></i>
                          <p>
                            {t('team.logout', { name: selectedTeam?.name })}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          )}
        </li>
        <li>
          <NavLink to="/dashboard" exact onClick={() => onShowLongMenu(false)}>
            <span className="icon">
              <i className="custom-icon-dashboard"></i>
            </span>
            <span className="text">{t('dashboard.main_title')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/documents"
            exact
            onClick={() => onShowLongMenu(false)}
            className="documents"
          >
            <span className="icon">
              <DescriptionIcon />
            </span>
            <span className="text">{t('dashboard.documents')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/templates" onClick={() => onShowLongMenu(false)}>
            <span className="icon">
              <i className="custom-icon-template"></i>
            </span>
            <span className="text">{t('dashboard.templates')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/shared-templates" onClick={() => onShowLongMenu(false)}>
            <span className="icon">
              <ShareOutlined />
            </span>
            <span className="text">{t('dashboard.shared_templates')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/calendar" onClick={() => onShowLongMenu(false)}>
            <span className="icon">
              <CalendarTodayIcon />
            </span>
            <span className="text">{t('dashboard.alerts')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/tasks" onClick={() => onShowLongMenu(false)}>
            <span className="icon">
              <CheckIcon />
            </span>
            <span className="text">{t('dashboard.tasks')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/contacts" onClick={() => onShowLongMenu(false)}>
            <span className="icon">
              <GroupIcon />
            </span>
            <span className="text">{t('general.contacts')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/signatures"
            onClick={(e) => {
              onShowLongMenu(false);
            }}
          >
            <span className="icon">
              <i className="custom-icon-create"></i>
            </span>
            <span className="text">{t('dashboard.signatures_2')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/approvals"
            exact
            onClick={(e) => {
              onShowLongMenu(false);
            }}
          >
            <span className="icon">
              <MdApproval />
            </span>
            <span className="text">{t('approvals.title')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/variables"
            exact
            onClick={(e) => {
              onShowLongMenu(false);
            }}
          >
            <span className="icon">
              <List />
            </span>
            <span className="text">{t('dashboard.variables')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/external-documents"
            exact
            onClick={(e) => {
              onShowLongMenu(false);
            }}
          >
            <span className="icon">
              <span className="material-symbols-outlined size-24">
                edit_document
              </span>
            </span>
            <span className="text">{t('dashboard.external_documents')}</span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/external-documents" exact onClick={(e) => {onShowLongMenu(false)}}>
            <span className="icon"><span className="material-symbols-outlined size-24">edit_document</span></span>
            <span className="text">{t('dashboard.external_documents')}</span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/recommended" onClick={(e) => {e.preventDefault(); onShowLongMenu(false)}}>
            <span className="icon"><EmailOutlinedIcon /></span>
            <span className="text">{t('dashboard.recommended_2')}</span>
          </NavLink>
        </li> */}
      </ul>

      {/* <div className="side-menu__bottom side-menu__bottom--long">
        <NavLink to="/settings/my-profile">
          <span className="icon">
            <SettingsOutlinedIcon />
          </span>
          <span className="text">{t('dashboard.settings')}</span>
        </NavLink>
      </div> */}

      {loading && <PageLoader />}
      {responseLoading && <ResponseLoader />}
      {showInviteUsersModal && (
        <InviteUsersModal
          onClose={() => setShowInviteUsersModal(false)}
          user={user}
          team={selectedTeam}
          setLoading={setResponseLoading}
        />
      )}
    </div>
  );
};

export default SideMenuLong;

import firebase from './firebase'

// Fetch features
const fetchFeatures = async () => {
  try {
    const snapshot = await firebase.firestore().collection('features').where('project', '==', 'carbon').where('show', '==', true).get()
    let features = []
    snapshot.forEach(doc => {
      features.push({ ...doc.data(), id: doc.id })
    })
    return features
  } catch(err) {
    return { error: err.response ? err.response.data : err }
  }
}

// Update feature
const updateFeature = async (id, votes) => {
  try {
    await firebase.firestore().doc(`features/${id}`).update({ votes })
    return {}
  } catch(err) {
    return { error: err.response ? err.response.data : err }
  }
}

export {
  fetchFeatures,
  updateFeature,
}
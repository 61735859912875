import React, { useContext, useState, forwardRef, useRef, useEffect, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowBackIos, InfoOutlined, Save, Check, RemoveRedEye, Publish, Share, CloudUpload, CachedOutlined, Edit, Restore, StarBorder, HistoryOutlined, ChatOutlined, Star, CheckCircle, Cancel, ArrowRight, PictureAsPdfOutlined, DescriptionOutlined } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import Alert from '../../UI/Alert'
import Button from '../../UI/Button'
import CustomSelect from '../../UI/CustomSelect'
import CustomTooltip from '../../UI/CustomTooltip'
import Input from '../../UI/Input'
import LineLoader from '../../UI/LineLoader'
import LetterCircle from '../../UI/LetterCircle'
import Loader from '../../UI/Loader'

import { SharedDocsCommentsModal } from '../../misc'
import ChooseApprovalModal from '../approvals/ChooseApprovalModal'
import MoveDocToFolderModal from '../MoveDocToFolderModal'

import { LoaderContext, GlobalContext, DocumentsContext, TeamContext, NotificationContext, ApprovalsContext, UserContext } from '../../../context'
import { availableOn } from '../../../utils'
import { send_approval_request } from '../../../services/approvals'
import { email_template } from '../../../helpers/email_templates'
import { firebaseEnvDoc } from '../../../services/firebase'

const DocumentDetailHeaderV2 = forwardRef(({ isSharedTemplate, isSharedTemplateFromPage, loaded, documentName, documentNameChangeHandler, storeEditTime, setEditStartTime, statusesOptions, statusChangeHandler, documentObject = {}, timeElapsed, showTemplateHistoryModal, saving, saved, saveDocument, setPreviewDocumentModal, fetchDocumentPreview, uploadedDocData, shareClickHandler, setShowShareModal, oneDriveUploadClickHandler, gDriveUploadClickHandler, dropboxUploadClickHandler, documentId, setShowInjectModal, setShowSignDocModal, historyClickHandler, synthesisClickHandler, duplicateDocumentHandler, setShowDocDeleteAlert, currentSessionEditTime, onSetView, onSetDocumentObject, onMakeContentEditable, makeContentEditable, templateObject, sharedDoc, setSharedDoc }, ref) => {
  const { loading } = useContext(LoaderContext)
  const { prevPaths, t } = useContext(GlobalContext)
  const { documentsLoaded, updateDocument, documents, setDocuments, updateApprovalSteps } = useContext(DocumentsContext)
  const { activeTeamMember, selectedTeam, getUserNameById } = useContext(TeamContext)
  const { setNotification } = useContext(NotificationContext)
  const { approvalsFetched, setSingleApprovalFetched, approvals, fetchApprovalById } = useContext(ApprovalsContext)
  const { user } = useContext(UserContext)
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)
  const [showChooseApprovalModal, setShowChooseApprovalModal] = useState(false)
  const [documentApproval, setDocumentApproval] = useState(null)
  const [approvalNotFound, setApprovalNotFound] = useState(false)
  const [approvalLoading, setApprovalLoading] = useState(true)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [showRemoveApprovalAlert, setShowRemoveApprovalAlert] = useState(false)
  const [removingApproval, setRemovingApproval] = useState(false)
  const [approved, setApproved] = useState(false)
  const [sendingRequest, setSendingRequest] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)
  const [activeMenu, setActiveMenu] = useState('')
  const [newSharedMessages, setNewSharedMessages] = useState(0)
  const [showSharedDocCommentsModal, setShowSharedDocCommentsModal] = useState(false)
  const history = useHistory()
  const inputWrapperRef = useRef()
  const unsubscribeListener = useRef(null)

  // Fetch doc approval if not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!approvalsFetched && documentObject.approval?.id) {
        // console.log('fetch single approval')
        const approval = await fetchApprovalById(documentObject.approval.id, teamId)
        if(approval) {
          setSingleApprovalFetched(documentObject.approval.id)
          setDocumentApproval({...approval, id: documentObject.approval.id})
        }else {
          setApprovalNotFound(true)
          setApproved(true)
        }
        setApprovalLoading(false)
      }else if(approvalsFetched && documentObject.approval?.id) {
        // console.log('get approval from state')
        const approval = approvals.find(a => a.id === documentObject.approval.id)
        if(approval) {
          setDocumentApproval(approval)
        }else {
          setApprovalNotFound(true)
          setApproved(true)
        }
        setApprovalLoading(false)
      }
    }
    if(selectedTeam && !fetchingCollections && documentObject) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, approvalsFetched, fetchApprovalById, approvals, setSingleApprovalFetched, documentObject, fetchingCollections])

  // Check if all steps are approved
  useEffect(() => {
    if(documentObject && documentObject.approval && Object.keys(documentObject.approval).length > 0 && approvals.find(a => a.id === documentObject.approval.id)) {
      let allStepsApproved = true 
      for(let key in documentObject.approval.steps) {
        if(!documentObject.approval.steps[key].step_approved) {
          allStepsApproved = false
          break
        }
      }
      setApproved(allStepsApproved)
    }
  }, [documentObject, approvals])

  // Unsubscribe shared document listener on unmount
  useEffect(() => {
    return () => {
      if(unsubscribeListener.current) {
        unsubscribeListener.current()
      }
    }
  }, [])

  // Get shared document with id
  useEffect(() => {
    if(documentObject && documentObject.shared_document && user) {
      if(!unsubscribeListener.current) {
        unsubscribeListener.current = firebaseEnvDoc.collection('shared_documents').doc(documentObject.shared_document).onSnapshot(snapshot => {
          console.log('shared doc live listener - from document header***')
          if(!snapshot.exists) {
            return 
          }
          const sharedDocId = documentObject.shared_document
          const docData = snapshot.data()
          let dateOfUpdate = 0
          const comments = docData.comments 
          // console.log('shared doc updated', docData)
  
          if(comments.length > 0) {
            let newMessages = 0
            for(let i = 0; i < comments.length; i++) {
              const comment = comments[i]
              const messages = comment.messages 
              if(user.last_read_shared_comments && user.last_read_shared_comments[sharedDocId] && user.last_read_shared_comments[sharedDocId][comment.id]) {
                dateOfUpdate = user.last_read_shared_comments[sharedDocId][comment.id]
              }
              if(messages && Array.isArray(messages)) {
                for(let j = 0; j < messages.length; j++) {
                  const msg = messages[j]
                  if(msg.author_email !== user.email && msg.created > dateOfUpdate) {
                    newMessages += 1
                  }
                }
              }
            }
            setNewSharedMessages(newMessages)
          }
          setSharedDoc(docData)
        })

      // if listener is already set and user is updated check if newMessages should be updated
      }else if(user.last_read_shared_comments && sharedDoc) {  
        const sharedDocId = documentObject.shared_document
        const docData = sharedDoc
        let dateOfUpdate = 0
        const comments = docData.comments 

        if(comments.length > 0) {
          let newMessages = 0
          for(let i = 0; i < comments.length; i++) {
            const comment = comments[i]
            const messages = comment.messages 
            if(user.last_read_shared_comments && user.last_read_shared_comments[sharedDocId] && user.last_read_shared_comments[sharedDocId][comment.id]) {
              dateOfUpdate = user.last_read_shared_comments[sharedDocId][comment.id]
            }
            if(messages && Array.isArray(messages)) {
              for(let j = 0; j < messages.length; j++) {
                const msg = messages[j]
                if(msg.author_email !== user.email && msg.created > dateOfUpdate) {
                  newMessages += 1
                }
              }
            }
          }
          setNewSharedMessages(newMessages)
        }
      }
    }
  }, [documentObject, user, sharedDoc])

  // On dropdown item click
  const handleHeaderActionClick = async (e, type) => {
    e.preventDefault()
    if(type === 'save') {
      saveDocument()
    }else if(type === 'preview') {
      handlePreviewDoc(e)
    }else if(type === 'share') {      
      setShowShareModal(true)
    }else if(type === 'content_editable') {
      onMakeContentEditable()
      window.document.body.click()
    }else if(type === 'rename') {
      document.body.click()
      if(inputWrapperRef.current) {
        let findEl = inputWrapperRef.current.querySelector('input')
        if(findEl) {
          findEl.focus()
        }
      }
    }else if(type === 'duplicate') {
      duplicateDocumentHandler(e)
    }else if(type === 'delete') {
      setShowDocDeleteAlert(true)
    }else if(type === 'template_history') {
      showTemplateHistoryModal(e)
    }else if(type === 'download_docx') {
      fetchDocumentPreview('download', 'docx')
    }else if(type === 'download_pdf') {
      fetchDocumentPreview('download', 'pdf')
    }else if(type === 'upload_gdrive') {
      gDriveUploadClickHandler()
    }else if(type === 'upload_onedrive') {
      oneDriveUploadClickHandler()
    }else if(type === 'upload_dropbox') {
      dropboxUploadClickHandler()
    }else if(type === 'synthesis') {
      synthesisClickHandler()
    }else if(type === 'inject') {
      setShowInjectModal(true)
    }else if(type === 'sign') {
      if(documentObject?.approval && Object.keys(documentObject.approval).length > 0) {
        if(approved) {
          setShowSignDocModal(true)
        }
      }else {
        setShowSignDocModal(true)
      }
    }else if(type === 'favorite') {
      await updateDocument({ favorite: documentObject.favorite ? !documentObject.favorite : true }, {...documentObject, id: documentId })
    }else if(type === 'history') {
      historyClickHandler()
    }else if(type === 'comments') {
      if(sharedDoc && !sharedDoc.comments_allowed) {
        // do not open comments modal because comments are not allowed on shared document
      }else {
        setShowSharedDocCommentsModal(true)
      }
    }else if(type === 'move') {
      setShowMoveToFolderModal(true)
    }else if(type === 'archive') {
      if(!documentObject?.archived) {
        await updateDocument({ archived: true, last_modified_by: activeTeamMember.id, create_action: 'yes' }, {...documentObject, id: documentId })
        setNotification({ msg: t('notification.document_archived'), type: 'success' })
      }
      document.body.click()
    }
  }

  // On preview
  const handlePreviewDoc = (e) => {
    e.preventDefault()
    if(uploadedDocData) {
      setPreviewDocumentModal(true)
    }else {
      fetchDocumentPreview('preview')
    }
  }

  // Go back
  const handleGoBack = () => {
    if(prevPaths.length === 2) {
      history.goBack()
      // history.push({
      //   pathname: prevPaths[1]
      // })
    }else {
      if(currentSessionEditTime > 0 && !documentsLoaded) {
        history.push({
          pathname: '/documents',
          state: { dont_fetch_documents: true }
        })
      }else {
        history.push({
          pathname: '/documents',
        })
      }
    }
  }

  // On approval modal open
  const handleChooseApprovalModalOpen = (e) => {
    e.preventDefault()
    setShowChooseApprovalModal(true)
  }

  // On approval modal close
  const handleChooseApprovalModalClose = () => {
    setShowChooseApprovalModal(false)
  }

  // On send request
  const handleSendRequest = async (e, step) => {
    e.preventDefault()
    let activeUserName = `${activeTeamMember.first_name} ${activeTeamMember.last_name}`

    setSendingRequest(true)

    let recipients = []
    step.members.forEach(m => {
      let member = selectedTeam.users.find(u => u.id === m)
      if(member && !documentObject?.approval.steps[step.id].approved_by.includes(m)) {
        recipients.push(member.email)
      }
    })

    let data = {
      recipients,
      from: '<noreply@getcarbon.ai>',
      email_html: email_template(
        t('approvals.request_email_text_2', { user: activeUserName, docName: `"${documentName}"` }), 
        `${window.location.origin}/document-detail/${documentId}`, 
        t('approvals.request_email_btn_text')
      ),
      subject: t('approvals.request_subject'),
      approval_id: documentObject?.approval.id,
      step: step.id,
      document_id: documentId
    }
    // console.log(data)

    try {
      const res = await send_approval_request(data)
      if(!res.success) {
        setNotification({ msg: t(`approvals.${res.key}`), type: 'info' })
        return
      }
      const docs = {...documents};
      let updatedApproval = {...documentObject.approval}
      updatedApproval.steps[step.id] = {
        ...updatedApproval.steps[step.id],
        request_sent: true
      }
      docs[documentId] = { ...docs[documentId], approval: updatedApproval }
      setDocuments(docs, false)
      setNotification({ msg: t('approvals.request_sent'), type: 'success' })
    } catch (err) {
      console.log(err)
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }

    setSendingRequest(false)
  }

  // Update steps
  const handleUpdateSteps = (e, step, status) => {
    e.preventDefault()
    
    let data = {
      approval_id: documentObject?.approval.id,
      document_id: documentId,
      step: step.id,
      approval_status: status 
    }
    // console.log(data)

    updateApprovalSteps(data, () => {
      setNotification({ msg: t('approvals.steps_updated'), type: 'success' })
    }, (key) => {
      setNotification({ msg: t(`approvals.${key}`), type: 'info' })
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    })

  }

  // On remove approval trigger click
  const handleRemoveApprovalFromDocTrigger = () => {
    setShowRemoveApprovalAlert(true)
  }

  // On remove approval
  const handleRemoveApprovalFromDoc = async () => {
    setRemovingApproval(true)
    try {
      await updateDocument({ approval: {}, last_modified_by: activeTeamMember.id, create_action: 'yes' }, {...documentObject, id: documentId })
      setNotification({ msg: t('approvals.removed_from_doc'), type: 'success' })
      setShowRemoveApprovalAlert(false)
    } catch (err) {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
    setRemovingApproval(false)
  }

  // On trigger click
  const handleTriggerClick = (menu) => {
    setMenuOpened(true)
    setActiveMenu(menu)
  }

  // On trigger mouse enter
  const handleTriggerMouseEnter = (menu) => {
    if(menuOpened && activeMenu !== menu) {
      setActiveMenu(menu)
    }
  }

  // On click away
  const handleClickAway = (e) => {
    if(menuOpened && e.target.className !== 'doc-menu-item') {
      setMenuOpened(false)
      setActiveMenu('')
    }
  }

  // On shared docs comments modal close
  const handleCloseSharedDocsCommentsModal = () => {
    setShowSharedDocCommentsModal(false)
  }

  // Render approval menu content
  const renderApprovalMenuContent = () => {
    if(!documentObject?.approval || Object.keys(documentObject.approval).length === 0) {
      return (
        <div className="approval-menu-content">
          <p><a href="/#" onClick={handleChooseApprovalModalOpen}>{t('approvals.set_approval')}</a></p>
        </div>
      )
    }

    if(approvalLoading) {
      return (
        <div className="approval-menu-content">
          <div className="loader-wrapper">
            <Loader small normal primary />
          </div>
        </div>
      )
    }

    if(approvalNotFound) {
      return (
        <div className="approval-menu-content">
          {/* <p>{t('approvals.doc_approval_not_found')}</p> */}
          <p><a href="/#" onClick={handleChooseApprovalModalOpen}>{t('approvals.set_approval')}</a></p>
        </div>
      )
    }

    if(!documentApproval) return

    let approvedSteps = 0
    for(let i = 0; i < documentApproval.steps.length; i++) {
      let step = documentObject?.approval?.steps[documentApproval.steps[i].id] 
      if(step && step.status) {
        approvedSteps += 1
      }
    }
    let currentStep = documentObject?.approval.steps[documentApproval.steps[approvedSteps]?.id] || {}

    let shouldShowApprove = false
    let alreadyApprovedByCurrentUser = false
    if(currentStep && currentStep.request_sent && !currentStep.status && documentApproval.steps[approvedSteps].members.includes(activeTeamMember.id) && !currentStep.approved_by.includes(activeTeamMember.id)) {
      shouldShowApprove = true
    }
    if(currentStep && currentStep.request_sent && !currentStep.status && currentStep.approved_by.includes(activeTeamMember.id)) {
      alreadyApprovedByCurrentUser = true
    }

    return (
      <div className="approval-menu-content">
        <div className="steps-approved">{t('approvals.approve')}: {t('approvals.step')} {approvedSteps}/{documentApproval.steps.length}</div>
        {shouldShowApprove && <div className="steps-approve-reject">
          <p>
            <a href="/#" onClick={(e) => handleUpdateSteps(e, documentApproval.steps[approvedSteps], 'approved')}>{t('approvals.approve')}</a>
          </p>
          <p>-</p>
          <p>
            <a href="/#" onClick={(e) => handleUpdateSteps(e, documentApproval.steps[approvedSteps], 'rejected')}>{t('approvals.reject')}</a>
          </p>
        </div>}
        {alreadyApprovedByCurrentUser && <div className="already-approved">
          <p>{t('approvals.already_approved_by_user')}</p>
        </div>}
        <ul className="steps">
          {documentApproval.steps.map((step, idx) => {
            if(!documentObject?.approval.steps[step.id]) return null
            return (
              <li key={step.id}>
                <div className="step-num">
                  {t('approvals.step')} {idx + 1}
                  {documentObject?.approval.steps[step.id].status === 'approved' && (
                    <CustomTooltip content={t('approvals.approved')}>
                      <div className="icon approved"><CheckCircle /></div>
                    </CustomTooltip>
                  )}
                  {documentObject?.approval.steps[step.id].status === 'rejected' && (
                    <CustomTooltip content={t('approvals.rejected')}>
                      <div className="icon rejected"><Cancel /></div>
                    </CustomTooltip>
                  )}
                </div>
                <div className="members">
                  {step.members.map((member) => {
                    let name = getUserNameById(member)
                    return <LetterCircle 
                      data={name} 
                      key={member} 
                      tooltipIcon={documentObject?.approval.steps[step.id].approved_by.includes(member) ? <CheckCircle className="icon" /> : null} 
                    />
                  })}
                  <p className="from">{step.from === 'anyone' ? t('general.anyone') : t('general.all')}</p>
                </div>
                {!documentObject?.approval.steps[step.id].status && idx === approvedSteps && (
                  <p>{!documentObject?.approval.steps[step.id].request_sent 
                    ? <a href="/#" onClick={(e) => handleSendRequest(e, step)}>{t('approvals.request')}</a> 
                    : <span>
                        {t('approvals.request_sent')} 
                        {!sendingRequest && <CustomTooltip content={t('approvals.send_request_again')}>
                          <i onClick={(e) => handleSendRequest(e, step)}><ArrowRight /></i>
                        </CustomTooltip>}
                      </span>
                    }
                  </p>
                )}
              </li>
            )
          })}
        </ul>

        {documentObject?.approval.added_by === activeTeamMember.id && <div className="approval-menu-content__actions">
          <Button text={t('approvals.remove_from_doc')} primaryLight2 onButtonClick={handleRemoveApprovalFromDocTrigger} />
        </div>}
      </div>
    )
  }

  return (
    <div className="document-detail__head">
      {loading || !loaded ? <LineLoader /> : null}
      <div className="scrolling-indicator" ref={ref}></div>
      <div className="container">
        <div className="document-detail__head_left">
          {!isSharedTemplate && !isSharedTemplateFromPage && <div className="document-detail__head_back" onClick={handleGoBack}>
            <ArrowBackIos />
          </div>}
          <div className="document-detail__head_main">
            <div className="document-detail__head_main-top">
              {(!isSharedTemplate && !isSharedTemplateFromPage)? <h1 className="title">
                <span className="transparent">{documentName}</span>
                <div className="input-wrapper" ref={inputWrapperRef}>
                  <Input
                    value={documentName}
                    placeholder={t('general.doc_title_placeholder')}
                    onChange={documentNameChangeHandler}
                    editOnHover
                    onFocus={() => setEditStartTime(Date.now())}
                    onBlur={storeEditTime}
                  />
                </div>
              </h1> : <h1 className="document-title">{documentName}</h1>}
              {(!isSharedTemplate && !isSharedTemplateFromPage) && (
                <>
                  <div className="status-wrap">
                    <div className="status-wrap__left">
                      <CustomSelect 
                        options={statusesOptions} 
                        onChange={statusChangeHandler} 
                        active={statusesOptions.find(s => s.active)} 
                        statusEl 
                        dropdownClassToCheck="status-select-dropdown"
                        onFocus={() => setEditStartTime(Date.now())}
                        onBlur={storeEditTime}
                        tooltip={t('dashboard.status')}
                        tooltipPosition="bottom"
                      />
                    </div>
                  </div>
                  <CustomTooltip content={t('general.favorite')} position="bottom">
                    <a href="#/" className="action-link" onClick={(e) => handleHeaderActionClick(e, 'favorite')}>
                      {documentObject?.favorite ? <Star className="active" /> : <StarBorder />}
                    </a>
                  </CustomTooltip>
                  { (documentObject && Object.keys(documentObject).length > 0) && <CustomTooltip content={t('dashboard.history')} position="bottom">
                    <a href="#/" className="action-link" onClick={(e) => handleHeaderActionClick(e, 'history')}><HistoryOutlined /></a>
                  </CustomTooltip> }
                </>
              )}
            </div>
            {(!isSharedTemplate && !isSharedTemplateFromPage) && <div className="document-detail__head_main-actions">
              <ul>
                <li>
                  <ActionsDropdown
                    trigger={<a href="#/" className="doc-menu-item" onMouseEnter={() => handleTriggerMouseEnter('file-menu')}>{t('general.file')}</a>}
                    headTitle={t('general.file')}
                    fixed={true}
                    hideClose={true}
                    menuId={'file-menu'}
                    onTriggerClick={() => handleTriggerClick('file-menu')}
                    show={menuOpened && activeMenu === 'file-menu'}
                    hide={menuOpened && activeMenu !== 'file-menu'}
                    onClickAway={handleClickAway}
                    dropdownClass="wrap-items"
                  >
                    <ul>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'save')}> {t('general.save')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'preview')}> {t('general.preview')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'share')}> {t('general.share')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="divider"></div>
                      </li>
                      <li>
                        <div className="link">
                          {!documentObject?.uploaded 
                            ? <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'content_editable')}> {makeContentEditable ? t('general.make_content_uneditable') : t('general.make_content_editable')}</a>
                            : <span>{makeContentEditable ? t('general.make_content_uneditable') : t('general.make_content_editable')}</span>}
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'rename')}> {t('general.rename')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'duplicate')}> {t('dashboard.duplicate')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'move')}> {t('general.move')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'archive')}> {t('general.archive')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'delete')}> {t('general.delete')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="divider"></div>
                      </li>
                      <li>
                        <div className="link">
                          {!documentObject?.uploaded 
                            ? <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'template_history')}> {t('template_history.title')}</a>
                            : <span>{t('template_history.title')}</span>}
                        </div>
                      </li>
                    </ul>
                  </ActionsDropdown>
                </li>
                <li>
                  <ActionsDropdown
                    trigger={<a href="#/" className="doc-menu-item" onMouseEnter={() => handleTriggerMouseEnter('export-menu')}>{t('general.export')}</a>}
                    headTitle={t('general.export')}
                    fixed={true}
                    hideClose={true}
                    menuId={'export-menu'}
                    onTriggerClick={() => handleTriggerClick('export-menu')}
                    show={menuOpened && activeMenu === 'export-menu'}
                    hide={menuOpened && activeMenu !== 'export-menu'}
                    onClickAway={handleClickAway}
                    dropdownClass="wrap-items"
                  >
                    <ul>
                      <li>
                        <div className="link">
                          {!documentObject?.uploaded 
                            ? <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'download_docx')}> {`Docx`}</a>
                            : !documentObject?.documentUrls?.docx 
                              ? <span>{`Docx`}</span>
                              : <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'download_docx')}> {`Docx`}</a>
                          }
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'download_pdf')}> {`PDF`}</a>
                        </div>
                      </li>
                      {/* <li>
                        <div className="divider"></div>
                      </li> */}
                      {/* <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'upload_gdrive')}> {`Google Drive`}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'upload_onedrive')}> {`One Drive`}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'upload_dropbox')}> {`Dropbox`}</a>
                        </div>
                      </li> */}
                    </ul>
                  </ActionsDropdown>
                </li>
                <li>
                  {!documentObject?.uploaded ? <ActionsDropdown
                    trigger={<a href="#/" className="doc-menu-item" onMouseEnter={() => handleTriggerMouseEnter('data-menu')}>{t('general.data')}</a>}
                    headTitle={t('general.data')}
                    fixed={true}
                    hideClose={true}
                    menuId={'data-menu'}
                    onTriggerClick={() => handleTriggerClick('data-menu')}
                    show={menuOpened && activeMenu === 'data-menu'}
                    hide={menuOpened && activeMenu !== 'data-menu'}
                    onClickAway={handleClickAway}
                    dropdownClass="wrap-items"
                  >
                    <ul>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'synthesis')}> {t('dashboard.synthesis')}</a>
                        </div>
                      </li>
                      <li>
                        <div className="link">
                          <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'inject')}> {t('inject.to_another_template')}</a>
                        </div>
                      </li>
                    </ul>
                  </ActionsDropdown>
                  : <span className="">{t('general.data')}</span>}
                </li>
                <li>
                  <ActionsDropdown
                    trigger={<a href="#/" className="doc-menu-item" onMouseEnter={() => handleTriggerMouseEnter('approvals-menu')}>{t('general.approval')}</a>}
                    headTitle={t('general.approval')}
                    fixed={true}
                    dropdownClass="approval-actions-menu wrap-items"
                    hideClose={true}
                    menuId={'approvals-menu'}
                    onTriggerClick={() => handleTriggerClick('approvals-menu')}
                    show={menuOpened && activeMenu === 'approvals-menu'}
                    hide={menuOpened && activeMenu !== 'approvals-menu'}
                    onClickAway={handleClickAway}
                  >
                    { renderApprovalMenuContent() }
                  </ActionsDropdown>
                </li>
                <li>
                  <a href="#/" className={documentObject?.approval && Object.keys(documentObject.approval).length > 0 && !approved ? 'disabled' : ''} onClick={(e) => handleHeaderActionClick(e, 'sign')}>{t('sign_document.sign')}</a>
                </li>
                <li>
                  <a href="#/" className={sharedDoc && !sharedDoc.comments_allowed ? 'disabled' : ''} onClick={(e) => handleHeaderActionClick(e, 'comments')}>
                    {t('general.comments')}
                    {documentObject?.shared_document && newSharedMessages > 0 && (
                      <span className="comments-badge">{newSharedMessages}</span>
                    )}
                  </a>
                </li>
              </ul>
            </div>}

            {/* {(isSharedTemplate || isSharedTemplateFromPage) && (
              <div className="document-detail__head_main-actions">
                <ul>
                  <li>
                    <ActionsDropdown
                      trigger={<a href="#/" className="doc-menu-item">{t('general.export')}</a>}
                      headTitle={t('general.export')}
                      fixed={true}
                      hideClose={true}
                      dropdownClass="wrap-items"
                    >
                      <ul>
                        <li>
                          <div className="link">
                            <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'download_docx')}> {`Docx`}</a>
                          </div>
                        </li>
                        <li>
                          <div className="link">
                            <a href="/#" onClick={(e) => handleHeaderActionClick(e, 'download_pdf')}> {`PDF`}</a>
                          </div>
                        </li>
                      </ul>
                    </ActionsDropdown>
                  </li>
                </ul>
              </div>
            )} */}
          </div>
        </div>
        
        <div className="document-detail__head_actions">
          {!documentObject?.uploaded && (
            <CustomTooltip content={t('general.preview')} position="bottom">
              <a href="/#" className="action-btn" onClick={handlePreviewDoc}><RemoveRedEye /></a>
            </CustomTooltip>
          )}
          {(isSharedTemplate || isSharedTemplateFromPage) && (
            <>
              <CustomTooltip content={`${t('general.download')} DOCX`} position="bottom">
                <a href="/#" className="action-btn" onClick={(e) => handleHeaderActionClick(e, 'download_docx')}><DescriptionOutlined /></a>
              </CustomTooltip>
              <CustomTooltip content={`${t('general.download')} PDF`} position="bottom">
                <a href="/#" className="action-btn" onClick={(e) => handleHeaderActionClick(e, 'download_pdf')}><PictureAsPdfOutlined /></a>
              </CustomTooltip>
            </>
          )}
          {/* {(!isSharedTemplate && !isSharedTemplateFromPage && teamMembership !== 'free') && (
            <CustomTooltip content={t('general.comments')} position="bottom">
              <a href="/#" className="action-btn" onClick={(e) => handleHeaderActionClick(e, 'comments')}><ChatOutlined /></a>
            </CustomTooltip>
          )} */}
          {(!isSharedTemplate && !isSharedTemplateFromPage) && <Button primary text={t('general.share')} onButtonClick={() => setShowShareModal(true)} className="share-btn" />}
        </div>
      </div>

      {showMoveToFolderModal && <MoveDocToFolderModal 
        onClose={() => setShowMoveToFolderModal(false)} 
        currentFolder={null}
        doc={{...documentObject, id: documentId}}
      />}
      {showChooseApprovalModal && <ChooseApprovalModal 
        onClose={handleChooseApprovalModalClose} 
        doc={{...documentObject, id: documentId}} 
        onSetDocApproval={setDocumentApproval}
        onSetApprovalLoading={setApprovalLoading}
        onSetApprovalNotFound={setApprovalNotFound}
      />}
      {showRemoveApprovalAlert && <Alert 
        onClose={() => setShowRemoveApprovalAlert(false)}
        text={t('approvals.remove_from_doc_alert')}
        onSubmit={handleRemoveApprovalFromDoc}
        loading={removingApproval}
        deleteAlert
      />}

      {showSharedDocCommentsModal && (
        <SharedDocsCommentsModal 
          onClose={handleCloseSharedDocsCommentsModal}
          sharedDocId={documentObject?.shared_document}
          sharedDocument={sharedDoc}
          doc={documentObject}
          template={templateObject}
          onSetDocumentObject={onSetDocumentObject}
          unsubscribeListener={unsubscribeListener}
        />
      )}
    </div>
  )
})

export default memo(DocumentDetailHeaderV2)
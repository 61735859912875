import React, { useContext, useEffect, Fragment } from 'react' 
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import SearchForm from '../sections/support/Search'
import { HelpContext, GlobalContext } from '../../context'

const SupportPageLayout = ({ title, children, withSidebar, hideCategories, showBackButton, v2 = false }) => {
  const { t } = useContext(GlobalContext)
  const { categoriesArr, selectedCategory, setSelectedCategory, articlesFetched, fetchHelpArticles, categoriesFetched, fetchHelpCategories } = useContext(HelpContext)
  const history = useHistory()
  let body = null 

  // Fetch articles if they are not fetched yet
  useEffect(() => {
    if(!articlesFetched) {
      fetchHelpArticles()
    }
  }, [articlesFetched, fetchHelpArticles])

  // Fetch categories if they are not fetched yet
  useEffect(() => {
    if(!categoriesFetched) {
      fetchHelpCategories()
    }
  }, [categoriesFetched, fetchHelpCategories])

  // Category click handler
  const categoryClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-category/${id}`)
    setSelectedCategory(id)
  }

  // Back button click handler
  const backButtonClickHandler = e => {
    e.preventDefault()
    history.push('/help')
  }

  // Body 
  if(withSidebar) {
    body = (
      <div className="support-page__body support-page__body--flex">
        <aside>
          <SearchForm type="small" v2={v2} />
          {!hideCategories && 
            <Fragment>
              <h4 onClick={() => history.push('/help')}>{t('dashboard.categories')}</h4>
              <ul>
                {categoriesArr.map((category, idx) => (
                  <li key={idx} className={selectedCategory === category.id ? 'active' : ''}>
                    <a href="/#" onClick={(e) => categoryClickHandler(e, category.id)}>{category.title?.en} <ChevronRightIcon /></a>
                  </li>
                ))}
              </ul>
            </Fragment>
          }
          {showBackButton && <a href="/#" onClick={backButtonClickHandler} className="back"><ChevronLeftIcon /> {t('support.back_to_help_center')}</a>}
        </aside>
        <main>
          { children }
        </main>
      </div>
    )
  }else {
    body = <div className="support-page__body">{children}</div>
  }

  return (
    <div className="support-page">
      <div className="support-page__container">
        <div className="support-page__head">
          <h1>{title || t('support.help_center')}</h1>
        </div>
        {body}
      </div>
    </div>
  )
}

export default SupportPageLayout
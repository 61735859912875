import { useContext } from 'react'

import { TaskContext } from '../../../context'

const ResizableTaskColumn = ({ column, onSort, idx }) => {
  const { tasksSort } = useContext(TaskContext)

  return (
    <div 
      className={`list-view-table__cell ${column.value === 'name' ? 'title-col' : ''} ${column.value === 'delete' ? 'delete-col' : ''} ${column.value === 'sort_user' || column.value === 'due_date' ? 'small-task-col' : ''}`} 
      key={idx} 
    >
      {column.value !== 'helper' && column.value !== 'delete' ? (
        <p onClick={() => onSort(column.value)} className={tasksSort.value === column.value ? 'sort active-sort' : 'sort'}>
          <span className="material-symbols-outlined icon">{column.icon}</span>
          <span className="text">{column.label}</span>
          {tasksSort.value === column.value && (
            <>
              {tasksSort.order === 'desc' && <span className="material-symbols-outlined arrow">arrow_upward</span>}
              {tasksSort.order === 'asc' && <span className="material-symbols-outlined arrow">arrow_downward</span>}
            </>
          )}
        </p>
      ) : (
        <div>&nbsp;</div>
      )}
    </div>
  )
}

export default ResizableTaskColumn
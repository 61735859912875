import React, { useState, useContext, useEffect, memo } from 'react'
import { Add, Delete } from '@material-ui/icons'
import moment from 'moment'

import Alert from '../../UI/Alert'
import CustomTooltip from '../../UI/CustomTooltip'
import Loader from '../../UI/Loader'
import ResponseLoader from '../../UI/ResponseLoader'
import CreateAlertModal from '../dashboard/CreateAlertModal'
import SingleAlert from './SingleAlert'

import { AlertContext, DocumentsContext, NotificationContext, TeamContext, GlobalContext } from '../../../context'

const DocumentDetailSPAlerts = ({ doc, onSetView, onSetEditStartTime, onStoreEditTime }) => {
  const { t } = useContext(GlobalContext)
  const { alerts, alertsFetched, deleteAlert, fetchAlerts } = useContext(AlertContext)
  const { updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { selectedTeam } = useContext(TeamContext)
  const [updating, setUpdating] = useState(false)
  const [mode, setMode] = useState('')
  const [showLoading, setShowLoading] = useState(true)
  const [documentAlerts, setDocumentAlerts] = useState([])
  const [showCreateAlertModal, setShowCreateAlertModal] = useState(false)
  const [selectedAlert, setSelectedAlert] = useState(null)
  const [showDeleteAert, setShowDeleteAlert] = useState(false)
  const [alertToDelete, setAlertToDelete] = useState(null)
  const [fetchingCollections, setFetchingCollections] = useState(false)

  // Fetch alerts if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!alertsFetched) {
        // console.log('fetch alerts')
        fetchAlerts(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, alertsFetched, fetchAlerts])

  // Get document alerts
  useEffect(() => {
    if(doc && alertsFetched) {
      const docAlerts = alerts.filter(a => a.documentId === doc.id)
      if(docAlerts.length === 0) {
        setMode('no-alerts')
      }else if(docAlerts.length === 1) {
        setMode('one-alert')
        setDocumentAlerts(docAlerts)
      }else if(docAlerts.length > 1) {
        setMode('list')
        setDocumentAlerts(docAlerts)
      }
      setShowLoading(false)
    }
  }, [alerts, doc, alertsFetched])

  // On add new alert link click
  const handleAddNewAlert = (e) => {
    e.preventDefault()
    setShowCreateAlertModal(true)
  }

  // On alert item(in list) click
  const handleAlertItemClick = (alert) => {
    setSelectedAlert(alert)
    setShowCreateAlertModal(true)
  }

  // On create/edit alert modal close
  const handleCloseAlertModal = () => {
    setShowCreateAlertModal(false)
    setSelectedAlert(null)
  }

  // On delete icon click
  const handleDeleteAlertIconClick = (e, alrt) => {
    e.preventDefault()
    setShowDeleteAlert(true)
    setAlertToDelete(alrt)
  } 

  // On delete icon click
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
    setAlertToDelete(null)
  } 

  // On delete alert
  const handleDeleteAlert = async () => {
    if(!alertToDelete) return 

    setUpdating(true)
    deleteAlert(alertToDelete.id, async () => {
      const relatedDocument = doc
      if(relatedDocument) {
        const docAlerts = relatedDocument.doc_alerts || []
        if(docAlerts.includes(alertToDelete.id)) {
          const filteredAlerts = docAlerts.filter(alertId => alertId !== alertToDelete.id)
          await updateDocument({ doc_alerts: filteredAlerts, create_action: 'no' }, relatedDocument)
        }
      }
      setShowDeleteAlert(false)
      setAlertToDelete(null)
      setNotification({ msg: t('notification.alert_deleted'), type: 'success' })
      setUpdating(false)
    }, () => {
      setShowDeleteAlert(false)
      setUpdating(false)
      setAlertToDelete(null)
      setNotification({ msg: 'notification.something_went_wrong', type: 'danger' })
    })
  }

  // Render empty fields
  const renderEmptyFields = () => {
    return (
      <SingleAlert 
        doc={doc}
        onSetUpdating={setUpdating}
        onSetEditStartTime={onSetEditStartTime}
        onStoreEditTime={onStoreEditTime}
        onSetView={onSetView}
      />
    )
  }

  // Render alert and button
  const renderAlertAndButton = () => {
    return (
      <div className="alerts-section">
        <SingleAlert 
          doc={doc}
          currentAlert={documentAlerts[0]}
          onSetUpdating={setUpdating}
          onSetEditStartTime={onSetEditStartTime}
          onStoreEditTime={onStoreEditTime}
          onSetView={onSetView}
        />

        <div className="alerts-section__add-new">
          <a href="/#" onClick={handleAddNewAlert}><Add /> {t('dashboard.add_alert')}</a>
        </div>
      </div>
    )
  }

  // Render list of alerts
  const renderListOfAlerts = () => {
    return (
      <div className="alerts-section">
        <ul>
          {documentAlerts.map((alert) => {
            return <li key={alert.id}>
              <p onClick={() => handleAlertItemClick(alert)}>{moment.unix(alert.dateTimestamp / 1000).format('DD/MM/YYYY HH:mm')}</p>
              <div className="hover-actions">
                <CustomTooltip content={t('general.remove')}>
                  <a href="#/" onClick={(e) => handleDeleteAlertIconClick(e, alert)}><Delete /></a>
                </CustomTooltip>
              </div>
            </li>
          })}
        </ul>

        {/* {selectedAlert && <SingleAlert 
          doc={doc}
          currentAlert={selectedAlert}
          onSetUpdating={setUpdating}
          onSetEditStartTime={onSetEditStartTime}
          onStoreEditTime={onStoreEditTime}
          onSetView={onSetView}
          onSetSelectedAlert={setSelectedAlert}
          inList={true}
        />} */}

        <div className="alerts-section__add-new">
          <a href="/#" onClick={handleAddNewAlert}><Add /> {t('dashboard.add_alert')}</a>
        </div>
      </div>
    )
  }

  if(showLoading) {
    return <div className="document-detail-sp-section">
      <h4 className="with-border">{t('dashboard.alerts')}</h4>
      <div className="loader-wrapper loader-wrapper--no-padding">
        <Loader small normal />
      </div>
    </div>
  }

  return(
    <div className="document-detail-sp-section">
      <h4 className="with-border">{t('dashboard.alerts')}</h4>

      {mode === 'no-alerts' && renderEmptyFields()}
      {mode === 'one-alert' && renderAlertAndButton()}
      {mode === 'list' && renderListOfAlerts()}

      {updating && <ResponseLoader />}
      {showCreateAlertModal && <CreateAlertModal onClose={handleCloseAlertModal} fromDocument={true} doc={doc} selectedAlert={selectedAlert} />}
      {showDeleteAert && <Alert 
        onClose={handleCloseDeleteAlert}
        onSubmit={handleDeleteAlert}
        text={t('alert.delete_alert')}
        deleteAlert
      />}
    </div>
  );
}

export default memo(DocumentDetailSPAlerts)
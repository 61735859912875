import { baseUrl, get_request, post_request, patch_request, delete_request } from './firestore'

const fetch_notaries = async (teamId) => {
  return await get_request(`${baseUrl}/notaries`, teamId)
}

const create_notary = async (data) => {
  return await post_request(`${baseUrl}/notaries`, data)
}

const update_notary = async (data, id) => {
  return await patch_request(`${baseUrl}/notaries/${id}`, data)
}

const delete_notary = async (id) => {
  return await delete_request(`${baseUrl}/notaries/${id}`)
}

export {
  fetch_notaries,
  create_notary,
  update_notary,
  delete_notary,
}
import { GET_VARIABLES, GET_VARIABLE_CATEGORIES, SET_VARIABLES, SET_VARIABLE_CATEGORIES, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_VARIABLES:
      return {
        ...state,
        variables: action.payload,
        variablesFetched: true
      }
    case GET_VARIABLE_CATEGORIES:
      return {
        ...state,
        varsCategories: action.payload,
        varsCategoriesFetched: true
      }
    case SET_VARIABLES:
      return {
        ...state,
        variables: action.payload
      }
    case SET_VARIABLE_CATEGORIES:
      return {
        ...state,
        varsCategories: action.payload
      }
    case RESET_STATE:
      return action.payload
    default:
      return state;
  }
}

export default reducer
import { useContext, useState, useEffect } from 'react'

import ActionsDropdown from '../../UI/ActionsDropdown'
import { ResizableTaskColumn, TasksRow, CreateTaskModal } from '../'
import { GlobalContext } from '../../../context'
import { perPageOptions } from '../../../constants'

const TasksView = ({ filteredTasks, paginatedData, refreshTasks, ready, setPaginatedData, onSort }) => {
  const { t } = useContext(GlobalContext)
  const [columns, setColumns] = useState([
    { label: t('general.name'), value: 'name', icon: 'text_fields', checked: true },
    { label: t('dashboard.document'), value: 'sort_document', icon: 'description', checked: true },
    { label: 'RESPONSABLE', value: 'sort_user', icon: 'person', checked: true },
    { label: 'ECHÉANCE', value: 'due_date', icon: 'calendar_month', checked: true },
    { label: '', value: 'delete', icon: 'calendar_month', checked: true },
  ])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [paginationReady, setPaginationReady] = useState(false)
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false)

  // Change paginated data arr when filtered arr changes
  useEffect(() => {
    let current = currentPage
    let sliceFrom = current === 1 ? 0 : (current - 1) * perPage
    let slicedData
    if(sliceFrom < filteredTasks.length) {
      slicedData = filteredTasks.slice(sliceFrom, sliceFrom + perPage)
    }else {
      slicedData = filteredTasks.slice(0, perPage)
    }
    setPaginatedData(slicedData)
    let all = Math.ceil(filteredTasks.length / perPage)
    setAllPages(all === 0 ? 1 : all)
    setTimeout(() => setPaginationReady(true), 200)
  }, [filteredTasks, perPage, currentPage, allPages, setPaginatedData])

  // On prev button click 
  const handlePrevButtonClick = (e) => {
    e.preventDefault()
    if(currentPage === 1) return 
    setCurrentPage(currentPage - 1)
    // if(isCookieAccepted()) {
    //   saveTeamPaginationDataToLS(selectedTeam?.id, perPage, currentPage - 1, allPages, 'variables')
    // }
  }

  // On next button click 
  const handleNextButtonClick = (e) => {
    e.preventDefault()
    if(currentPage === allPages) return 
    setCurrentPage(currentPage + 1)
    // if(isCookieAccepted()) {
    //   saveTeamPaginationDataToLS(selectedTeam?.id, perPage, currentPage + 1, allPages, 'variables')
    // }
  }

  // On go to specific page
  const handleGoToPage = (e, page) => {
    e.preventDefault()
    if(currentPage === page) return 
    setCurrentPage(page)
    // if(isCookieAccepted()) {
    //   saveTeamPaginationDataToLS(selectedTeam?.id, perPage, page, allPages, 'variables')
    // }
  }

  // Per page change
  const handlePerPageChange = (e, num) => {
    e.preventDefault()
    setPerPage(num)
    if(Math.ceil(filteredTasks.length / num) < currentPage) {
      setCurrentPage(1)
    }
    window.document.body.click()
    // if(isCookieAccepted()) {
    //   saveTeamPaginationDataToLS(selectedTeam?.id, num, currentPage, allPages, 'variables')
    // }
  }

  // On open create task modal
  const handleOpenCreateTaskModal = () => {
    setShowCreateTaskModal(true)
  }

  // On close create task modal
  const handleCloseCreateTaskModal = () => {
    setShowCreateTaskModal(false)
  }

  return (
    <div className="list-view-v2">
      <div className="list-view-v2__head">
        <div className="title-wrapper">
          <h4 className="heading-4">{t('dashboard.tasks')}</h4>
        </div>
        <div className="per-page per-page--tasks">
          <ActionsDropdown
            trigger={<a href="#/" className="text-style-4">{perPage} {t('general.per_page')} <span className="material-symbols-outlined">expand_more</span></a>}
            hideHeader
            dropdownClass="pagination-dropdown"
            width={120}
          >
            <ul>
              {perPageOptions.map((num, i) => (
                <li key={i}>
                  <div className="link">
                    <a href="/#" onClick={(e) => handlePerPageChange(e, num)}>{num}</a>
                  </div>
                </li>
              ))}
            </ul>
          </ActionsDropdown>
        </div>
        <div className="actions actions--tasks">
          <button className="icon-btn icon-btn--small" onClick={handleOpenCreateTaskModal}>
            <span className="material-symbols-outlined">add</span>
          </button>
        </div>
      </div>

      <div className="list-view-v2__body u-custom-scrollbar u-custom-scrollbar--3" style={{overflowX: 'auto'}}>
        <div className="list-view-table list-view-table--tasks">
          <div className="list-view-table__head">
            {columns.filter(c => c.checked).map((column, idx) => {
              return (
                <ResizableTaskColumn column={column} onSort={onSort} idx={idx} key={idx} />
              )
            })}
          </div>

          <div className="list-view-table__body">
            {(!ready || !paginationReady) && (
              <div className="loader-wrapper"><div className="loader-v2"></div></div>
            )}
            {ready && paginationReady && paginatedData.map((doc, idx) => {

              return (
                <TasksRow 
                  key={idx} 
                  doc={doc}
                  id={doc.id}
                  columns={columns}
                  refreshTasks={refreshTasks}
                />
              )
            })}
            {ready && paginationReady && paginatedData.length === 0 && (
              <div className="no-data">
                <p className="text-style-3">{t('folder.empty')}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="list-view-v2__foot">
        <div className="pagination-v2">
          <a href="/#" className="prev" onClick={handlePrevButtonClick}><span className="material-symbols-outlined weight-300">arrow_back</span></a>
          {Array(allPages).fill().map((_, idx) => {
            return (
              <a href="/#" key={idx} className={`${idx+1 === currentPage ? 'active' : ''}`} onClick={(e) => handleGoToPage(e, idx+1)}>
                {idx+1}
              </a>
            )
          })}
          <a href="/#" className="next" onClick={handleNextButtonClick}><span className="material-symbols-outlined weight-300">arrow_forward</span></a>
        </div>
      </div>

      {showCreateTaskModal && (
        <CreateTaskModal 
          onClose={handleCloseCreateTaskModal}
          refreshTasks={refreshTasks}
        />
      )}
    </div>
  )
}

export default TasksView
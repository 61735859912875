import firebase from './firebase'
import { baseUrl, post_request } from './firestore'

const getToken = (setTokenFound) => {
  return new Promise((resolve, reject) => {
    firebase.messaging().getToken({ vapidKey: 'BCTYBV2JBpMSCk5pwNgfjcW9ZpWwi6a4KAETMN8CQPSdK6bW95TGX3JaTsTJ3TPMNdnf2a-kfX4KVIRyJayBqVA'})
    .then(token => {
      if(token) {
        resolve(token)
        console.log('Token:', token)
        updateUserToken(token)
      } else {
        reject({ error: { message: 'No token' }})
        console.log('No token')
      }
    }).catch(err => {
      console.log('push notifications token error')
      console.log(JSON.stringify(err))
      reject({ error: err })
    })
  })  
}

const updateUserToken = (token) => {
  post_request(`${baseUrl}/user_web_push_token`, {
    token
  })
}

const addMessageListener = (onMessage) => {
  firebase.messaging().onMessage((payload) => {
    onMessage(payload)
  });
  
}

export {
  getToken,
  addMessageListener
}
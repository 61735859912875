const Template = () => {
  return (
    <span className="svg-icon">
      <svg width="16px" height="20px" viewBox="0 0 16 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <filter colorInterpolationFilters="auto" id="filter-1">
            <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.513725 0 0 0 0 0.639216 0 0 0 1.000000 0"></feColorMatrix>
          </filter>
        </defs>
        <g id="icn-template" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-3" transform="translate(0.000000, -0.000000)">
            <g filter="url(#filter-1)" id="request_page_FILL0_wght200_GRAD200_opsz24">
              <g>
                  <path d="M13.225,19.6 L2.275,19.6 C1.625,19.6 1.08333333,19.3833333 0.65,18.95 C0.216666667,18.5166667 0,17.975 0,17.325 L0,2.275 C0,1.625 0.216666667,1.08333333 0.65,0.65 C1.08333333,0.216666667 1.625,8.8817842e-16 2.275,8.8817842e-16 L9.625,8.8817842e-16 L15.5,5.875 L15.5,17.325 C15.5,17.975 15.2833333,18.5166667 14.85,18.95 C14.4166667,19.3833333 13.875,19.6 13.225,19.6 Z M13.225,17.95 C13.3916667,17.95 13.5376667,17.8873333 13.663,17.762 C13.7876667,17.6373333 13.85,17.4916667 13.85,17.325 L13.85,6.6 L8.9,1.65 L2.275,1.65 C2.10833333,1.65 1.96266667,1.71233333 1.838,1.837 C1.71266667,1.96233333 1.65,2.10833333 1.65,2.275 L1.65,17.325 C1.65,17.4916667 1.71266667,17.6373333 1.838,17.762 C1.96266667,17.8873333 2.10833333,17.95 2.275,17.95 L13.225,17.95 Z M1.65,17.95 C1.65,17.95 1.65,17.8873333 1.65,17.762 C1.65,17.6373333 1.65,17.4916667 1.65,17.325 L1.65,2.275 C1.65,2.10833333 1.65,1.96233333 1.65,1.837 C1.65,1.71233333 1.65,1.65 1.65,1.65 L1.65,6.6 L1.65,17.325 C1.65,17.4916667 1.65,17.6373333 1.65,17.762 C1.65,17.8873333 1.65,17.95 1.65,17.95 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
              </g>
            </g>
            <rect id="Rectangle" fill="#6E83A3" x="6" y="9" width="2" height="6"></rect>
            <rect id="Rectangle" fill="#6E83A3" x="4" y="7" width="6" height="2"></rect>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default Template
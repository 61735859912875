import React from 'react';
import ReactDOM from 'react-dom';
import 'material-symbols/outlined.css'
import App from './App';
import './style/index.scss';

import { AlertsState, ContactsState, DocumentsState, DocumentsFoldersState, FolderState, LoaderState, NotificationState, SignatureState, TasksState, TagsState, UserState, GlobalState, TeamState, VariablesState, HelpState, NotariesState, ContactGroupsState, SharedTemplatePagesState, ApprovalsState, SignatureTemplatesState, ConstantsState, ColumnsState, SharedDocumentsState } from './context';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '2ee48cc1a20f751a5d85dbd24ab54739',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GlobalState>
        <ConstantsState>
          <TeamState>
            <DocumentsState>
              <LoaderState>
                <FolderState>
                  <SignatureState>
                    <NotificationState>
                      <DocumentsFoldersState>
                        <UserState>
                          <TasksState>
                            <AlertsState>
                              <TagsState>
                                <ContactsState>
                                  <VariablesState>
                                    <HelpState>
                                      <NotariesState>
                                        <ContactGroupsState>
                                          <SharedTemplatePagesState>
                                            <ApprovalsState>
                                              <SignatureTemplatesState>
                                                <ColumnsState>
                                                  <SharedDocumentsState>
                                                    <App />
                                                  </SharedDocumentsState>
                                                </ColumnsState>
                                              </SignatureTemplatesState>
                                            </ApprovalsState>
                                          </SharedTemplatePagesState>
                                        </ContactGroupsState>
                                      </NotariesState>
                                    </HelpState>
                                  </VariablesState>
                                </ContactsState>
                              </TagsState>
                            </AlertsState>
                          </TasksState>
                        </UserState>
                      </DocumentsFoldersState>
                    </NotificationState>
                  </SignatureState>
                </FolderState>
              </LoaderState>
            </DocumentsState>
          </TeamState>
        </ConstantsState>
      </GlobalState>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
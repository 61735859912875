import { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../../context'
import fr from 'date-fns/locale/fr';
import DatePicker, { registerLocale } from 'react-datepicker'
registerLocale('fr', fr);


const DateInput = ({ label, onChange, value, withTime = false, dateFormat = 'dd/MM/yyyy', minDate = '', excludeTimes = [], onSelect = () => {}, invalid = false, maxDate = '', dropdownMode = '', showYearDropdown, showMonthDropdown }) => {
  const { t } = useContext(GlobalContext)
  const [date, setDate] = useState('')

  // Set date if value is set
  useEffect(() => {
    if(value) {
      setDate(value)
    }
  }, [value])

  // On date change
  const handleDateChange = (date) => {
    setDate(date)
    onChange(date)
  }

  return (
    <div className={`date-input ${invalid ? 'error' : ''}`}>
      {label && <div className="date-input__label">{label}</div>}
      <div className="date-input__field">
        <div className="date-input__field_icon">
          <span className="material-symbols-outlined size-24">calendar_month</span>
        </div>
        {!withTime ? (
          <DatePicker 
            onChange={handleDateChange}
            selected={date}
            popperModifiers={{preventOverflow: { enabled: true }}} 
            popperPlacement="top-right"
            dateFormat={dateFormat}
            // onChangeRaw={(e) => e.preventDefault()}
            className={date ? 'not-empty' : ''}
            isClearable={date !== ''}
            locale={fr}
            minDate={minDate}
            maxDate={maxDate}
            dropdownMode={dropdownMode}
            showYearDropdown={showYearDropdown}
            showMonthDropdown={showMonthDropdown}
          />
        ) : (
          <DatePicker 
            onChange={handleDateChange}
            selected={date}
            popperModifiers={{preventOverflow: { enabled: true }}} 
            popperPlacement="top-right"
            dateFormat={dateFormat}
            // onChangeRaw={(e) => e.preventDefault()}
            className={date ? 'not-empty' : ''}
            isClearable={date !== ''}
            locale={fr}
            minDate={minDate}
            maxDate={maxDate}
            dropdownMode="select"
            timeCaption={t('general.time')}
            showTimeSelect
            timeIntervals={60}
            excludeTimes={excludeTimes}
            onSelect={onSelect}
            showYearDropdown={showYearDropdown}
            showMonthDropdown={showMonthDropdown}
          />
        )}
      </div>
    </div>
  )
}

export default DateInput
import React, { useState, useContext, useEffect } from 'react';
import { Done, ExpandMore, DescriptionOutlined, ScheduleOutlined } from '@material-ui/icons'
import { ClickAwayListener } from '@material-ui/core';
import moment from 'moment'
import DatePicker from 'react-datepicker'

import ActionsDropdown from '../UI/ActionsDropdown'
import Input from '../UI/Input';
import LetterCircle from '../UI/LetterCircle'
import Button from '../UI/Button';
import { DocumentsContext, TaskContext, NotificationContext, TeamContext, GlobalContext } from '../../context';
import { sortedArrayFromObject, getNameFromMemberObj } from '../../utils';

const AddNewTask = ({ onCancel, doc = null, taskToEdit = null, onSetTaskToEdit, onFocus, onBlur, onSetView = () => {} }) => {
  const { t } = useContext(GlobalContext)
  const { documents, getDocumentById, updateDocument } = useContext(DocumentsContext);
  const { createTask, tasks, updateTask } = useContext(TaskContext);
  const { setNotification } = useContext(NotificationContext);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext);
  const [taskName, setTaskName] = useState('');
  const [showContratcsDropdown, setShowContratcsDropdown] = useState(false);
  const [search, setSearch] = useState('');
  const [documentsArray, setDocumentsArray] = useState([]);
  const [filteredDocumentsArr, setFilteredDocumentsArr] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(doc ? doc : {});
  const [creatingTask, setCreatingTask] = useState(false);
  const [updatingTask, setUpdatingTask] = useState(false);
  const [selectDocBtnText, setSelectDocBtnText] = useState(t('dashboard.contracts'));
  const [searchMembersValue, setSearchMembersValue] = useState('')
  const [members, setMembers] = useState([])
  const [filteredMembers, setFilteredMembers] = useState([])
  const [taskAssignedTo, setTaskAssignedTo] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')

  // Prefill data if taskToEdit is set 
  useEffect(() => {
    if(taskToEdit) {
      setTaskName(taskToEdit.name);
      const relatedDoc = getDocumentById(taskToEdit.relatedDocument);
      if(relatedDoc) {
        setSelectedDoc(relatedDoc);
        setSelectDocBtnText(relatedDoc.name);
      }
      if(taskToEdit.due_date) {
        setSelectedDate(new Date(taskToEdit.due_date))
      }
    }
  }, [taskToEdit, getDocumentById]);

  // Convert documents object to array
  useEffect(() => {
    if(documents) {
      const copyOfDocuments = {...documents};
      const filteredDocuments = {};
      for(let key in copyOfDocuments) {
        if(!copyOfDocuments[key].archived && !copyOfDocuments[key].deleted) {
          filteredDocuments[key] = copyOfDocuments[key];
        }
      }
      let docsArr = sortedArrayFromObject(filteredDocuments)
      setDocumentsArray(docsArr)
      setFilteredDocumentsArr(docsArr)
    }
  }, [documents]);

  // Add users to members arrays 
  useEffect(() => {
    if(selectedTeam) {
      const activeMembers = selectedTeam.users.filter(u => u.status === 'active')
      setMembers(activeMembers)
      setFilteredMembers(activeMembers)
      if(taskToEdit?.assigned_to) {
        const findMember = activeMembers.find(m => m.id === taskToEdit.assigned_to)
        if(findMember) {
          setTaskAssignedTo(findMember)
        }
      }
    }
  }, [selectedTeam, taskToEdit])

  // On documents search
  const searchChangeHandler = (e) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    if(searchVal.trim() !== '') {
      setFilteredDocumentsArr(prevArr => {
        const filteredArr = [];
        prevArr.forEach(doc => {
          if(doc.name.toLowerCase().includes(searchVal.trim())) {
            filteredArr.push(doc);
          }
        });
        return filteredArr;
      });
    }else {
      setFilteredDocumentsArr(documentsArray);
    }
  }

  // On document select 
  const documentSelectHandler = (doc) => {
    if(Object.keys(selectedDoc).length > 0 && selectedDoc.id === doc.id) {
      setSelectedDoc({});
      setSelectDocBtnText(t('dashboard.contracts'));
    }else {
      setSelectedDoc(doc);
      setSelectDocBtnText(doc.name);
    }
    setShowContratcsDropdown(false);
  }

  // Add new task
  const addNewTaskHandler = async () => {
    // Check if task with this name already exist for this document
    let selectedDocTasks;
    if(selectedDoc.id) selectedDocTasks = selectedDoc.tasks || [];
    const findTask = selectedDoc.id 
      ? selectedDocTasks.find(t => t.name.toLowerCase() === taskName.trim().toLowerCase())
      : tasks.find(t => t.name.toLowerCase() === taskName.trim().toLowerCase() && t.relatedDocument === null);
    if(findTask) {
      return selectedDoc.id 
        ? setNotification({ msg: t('notification.task_exists_for_selected_document'), type: 'warning' })
        : setNotification({ msg: t('notification.task_already_exists'), type: 'warning' });
    }

    const data = {
      relatedDocument: selectedDoc.id ? selectedDoc.id : null,
      name: taskName,
      completed: false,
      meta: {
        created: Date.now(),
        updated: Date.now()
      },
      team: selectedTeam?.id
    };
    if(taskAssignedTo) {
      data.assigned_to = taskAssignedTo.id 
    }
    if(selectedDate) {
      data.due_date = selectedDate
    }
    setCreatingTask(true);
    await createTask(data, selectedTeam?.id, async (taskId) => {
      if(selectedDoc.id) {
        data.id = taskId;
        await updateDocument({ tasks: selectedDoc.tasks ? [...selectedDoc.tasks, data] : [data], last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDoc);
      }
      setNotification({ msg: t('notification.task_created'), type: 'success' });
      onCancel();
      onSetView('tasks')
    }, (err) => {
      setCreatingTask(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    });
  }

  // Edit task
  const editTaskHandler = async () => {
    const data = {
      relatedDocument: selectedDoc.id ? selectedDoc.id : null,
      name: taskName,
    };
    if(taskAssignedTo) {
      data.assigned_to = taskAssignedTo.id 
    }
    if(selectedDate) {
      data.due_date = selectedDate
    }
    setUpdatingTask(true);
    await updateTask(data, taskToEdit.id, async () => {
      if(selectedDoc.id) {
        const docTasks = selectedDoc.tasks ? selectedDoc.tasks : [];
        const updatedTasks = docTasks.map(task => task.id === taskToEdit.id ? { ...taskToEdit, ...data } : task );
        await updateDocument({ tasks: docTasks.length === 0 ? [{ ...taskToEdit, ...data }] : updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDoc);
      }
      setNotification({ msg: t('notification.task_updated'), type: 'success' });
      onSetTaskToEdit(null);
      onCancel();
      onSetView('tasks')
    }, (err) => {
      setUpdatingTask(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    });
  }

  // On search member
  const handleSearchMember = (e) => {
    const value = e.target.value
    setSearchMembersValue(e.target.value)
    const newMembers = members.filter((m) => {
      const firstName = m.first_name || ''
      const lastName = m.last_name || ''
      const name = firstName + ' ' + lastName
      const email = m.email
      if(name.toLowerCase().includes(value.trim().toLowerCase()) || email.toLowerCase().includes(value.trim().toLowerCase())) {
        return m
      }else {
        return null
      }
    })
    setFilteredMembers(newMembers)
  }

  // On add remove member - assigned to
  const handleAssignTo = (member) => {
    if(taskAssignedTo && taskAssignedTo.id === member.id) {
      setTaskAssignedTo(null)
    }else {
      setTaskAssignedTo(member)
    }
    document.body.click()
  }

  // On date fields change
  const handleDateChange = async (date) => {
    setSelectedDate(date)
    document.body.click()
  }

  // On remove date
  const handleRemoveDate = async () => {
    setSelectedDate('')
    document.body.click()
  }

  return(
    <div className="add-new-task">
      <div className="add-new-task__body">
        <Input value={taskName} onChange={(e) => setTaskName(e.target.value)} transparent onFocus={onFocus} onBlur={onBlur} />
        {!doc && <ClickAwayListener onClickAway={() => setShowContratcsDropdown(false)}>
          <div className="add-new-task__body_btn-wrap">
            <Button text={selectDocBtnText} icon={<DescriptionOutlined />} onButtonClick={() => setShowContratcsDropdown(!showContratcsDropdown)} outlineLight className={showContratcsDropdown ? 'active' : ''} />
            {showContratcsDropdown && <div className="add-new-task__documents-dropdown">
              <div className="add-new-task__documents-dropdown_head">
                <Input value={search} onChange={(e) => searchChangeHandler(e)} placeholder={t('general.contract_name_placeholder')} transparent />
              </div>
              <div className="add-new-task__documents-dropdown_body">
                <ul>
                  {filteredDocumentsArr.map(doc => (
                    <li key={doc.id} className={doc.id === selectedDoc.id ? 'selected' : undefined} onClick={() => documentSelectHandler(doc)}>
                      {doc.name} {doc.id === selectedDoc.id && <Done />}
                    </li>
                  ))}
                </ul>
              </div>
            </div>}
          </div>
        </ClickAwayListener>}
      </div>
      <div className="add-new-task__member-and-date">
        <ActionsDropdown
          headTitle={t('general.assign')}
          trigger={
            <button className="button button--primary-light button--medium assign-btn">
              <span>{taskAssignedTo ? getNameFromMemberObj(taskAssignedTo) : t('general.assign_task_to')}</span> <ExpandMore />
            </button>
          }
          dropdownClass="add__dropdown"
        >
          <Input value={searchMembersValue} onChange={handleSearchMember} formEl thickBorder />
          <ul className="members-list u-custom-scrollbar">
            {filteredMembers.map((user, idx) => {
              let name = ''
              if(user.first_name) {
                name += user.first_name
              }
              if(user.last_name) {
                name += ` ${user.last_name}`
              }
              if(!name) {
                name = user.email
              }
              return (
                <li key={idx} onClick={() => handleAssignTo(user)}>
                  <div className="user-thumb" style={user.user_image ? {backgroundImage: `url(${user.user_image})`} : {}}>
                    {!user.user_image && <LetterCircle data={name} hideTooltip />}
                  </div>
                  <div className="user-name">{name}</div>
                  {taskAssignedTo && taskAssignedTo.id === user.id && <Done />}
                </li>
              )
            })}
          </ul>
        </ActionsDropdown>
        <ActionsDropdown
          headTitle={t('general.due_date')}
          trigger={
            <a href="/#" className="date-btn">
              <ScheduleOutlined />
              {selectedDate && <span>{moment(selectedDate.valueOf()).format(`DD MMM`)}{moment().year() !== moment(selectedDate.valueOf()).year() && `, ${moment(selectedDate.valueOf()).year()}`}</span>}
              {!selectedDate && <span>{t('general.due_date')}</span>}
            </a>
          }
        >
          <DatePicker 
            selected={selectedDate} 
            onChange={handleDateChange} 
            // onChangeRaw={(e) => e.preventDefault()}
            locale="fr"
            calendarClassName="calendar"
            minDate={Date.now()}
            inline
          />
          <Button text={t('general.remove_date')} fullWidth disabled={selectedDate === ''} onButtonClick={handleRemoveDate} primaryLight />
        </ActionsDropdown>
      </div>
      <div className="add-new-task__foot">
        <Button 
          text={!taskToEdit ? creatingTask ? t('general.creating_task') : t('general.add_task') : updatingTask ? t('dashboard.updating') : t('general.edit')} 
          onButtonClick={taskToEdit ? editTaskHandler : addNewTaskHandler} 
          disabled={taskName.trim() === '' || creatingTask || updatingTask} 
          primary 
        />
        <Button text={t('general.cancel')} transparent onButtonClick={() => { onSetTaskToEdit(null); onCancel(); }} />
      </div>
    </div>
  );
}

export default AddNewTask;
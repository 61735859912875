import { useContext, useState, useEffect, forwardRef } from 'react'

import { LetterCircle } from '../../new_ui'
import { WidgetDropdown } from '../'
import { GlobalContext, UserContext, TeamContext, TaskContext } from '../../../context'
import CustomTooltip from '../../UI/CustomTooltip'

const PeopleWidget = forwardRef(({ style, dragging, isSorting, ...props }, ref) => {
  const { widgetsInfo } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)
  const { setSelectedAssignee, setShowTaskPopup } = useContext(TaskContext)
  const { t } = useContext(GlobalContext)
  const [teamMembers, setTeamMembers] = useState([])

  // Set active team members
  useEffect(() => {
    if(selectedTeam && selectedTeam.users) {
      setTeamMembers(selectedTeam.users.filter(u => u.status === 'active'))
    }
  }, [selectedTeam])

  // On assign a task btn click
  const handleAssignATask = (tm) => {
    setSelectedAssignee(tm)
    setShowTaskPopup(true)
  }

  if(isSorting) {
    return (
      <div className={`widget widget--placeholder ${dragging ? 'widget--dragging' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4">{t('dashboard.people')}</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`widget ${widgetsInfo['item-people'].size === 'half' ? 'widget--half' : ''}`} ref={ref} style={style}>
        <div className="widget__head">
          <h4 className="heading-4" {...props}>{t('dashboard.people')}</h4>
          <div className="widget__head_actions">
            <WidgetDropdown widget="item-people" />
          </div>
        </div>
        <div className="widget__body">
          <div className="people-widget-content">
            <div className="people-widget-content__inner u-custom-scrollbar">
              <div className="team-members">
                {teamMembers.length > 0 ? teamMembers.map((tm, idx) => {
                  let data = '' 

                  if(tm.first_name) {
                    data = tm.first_name
                  }
                  if(data && tm.last_name) {
                    data += ` ${tm.last_name}`
                  }else {
                    data = tm.last_name
                  }
                  if(!data && tm.email) {
                    data = tm.email
                  }

                  return (
                    <div key={idx} className="team-members__box">
                      <div className="team-members__box_thumb">
                        <LetterCircle data={data} size={69} hideTooltip className="text-lg" />
                      </div>
                      <div className="team-members__box_info">
                        <CustomTooltip content={`${tm.first_name} ${tm.last_name}`}>
                          <p className="text-style-3">{tm.first_name} {tm.last_name}</p>
                        </CustomTooltip>
                        <button className="btn btn--small-3 btn--outline-2" onClick={() => handleAssignATask(tm)}>{t('general.assign_a_task')}</button>
                      </div>
                    </div>
                  )
                }) : <p className="text-style-1">{t('general.no_active_team_members')}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default PeopleWidget 
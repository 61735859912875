import { useContext } from 'react'
import moment from 'moment'

import { AlertContext, GlobalContext, NotificationContext, LoaderContext, TeamContext, DocumentsContext } from '../context'

const useAlertActions = () => {
  const { createAlert, updateAlert } = useContext(AlertContext)
  const { t, selectedLang } = useContext(GlobalContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { selectedTeam, activeTeamMember } = useContext(TeamContext)
  const { updateDocument, getDocumentById, updateMultipleDocuments } = useContext(DocumentsContext)

  // Create new alert
  const createNewAlert = async ({ selectedDocument, setContractError, alertTime, emails, setTimeError, setEmailsError, alertMessage, alertTitle, onClose, refreshCollection }) => {
    if(Object.keys(selectedDocument).length === 0) {
      setContractError(true)
      return setNotification({ msg: t('notification.select_a_contract'), type: 'danger' })
    }

    if(!alertTime || emails.length === 0) {
      if(!alertTime) setTimeError(true)
      if(emails.length === 0) setEmailsError(true)
      return setNotification({ msg: t('notification.fill_in_all_required_fields'), type: 'danger' })
    }

    const timestamp = moment(alertTime).clone().valueOf()
    if(timestamp <= Date.now()) {
      return setNotification({ msg: t('notification.time_in_past'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    let alertData = {
      date: moment(alertTime).format('DD/MM/YYYY HH:mm'), 
      dateTimestamp: timestamp, 
      selectedVal: 'custom',
      documentId: selectedDocument.id,
      sent: false,
      emails: emails,
      message: alertMessage.trim(),
      title: alertTitle ? alertTitle.trim() : t('doc_alert.default_title', { title: selectedDocument.name }),
      team: selectedTeam?.id,
      owner: activeTeamMember.id,
      lang: selectedLang
    }

    createAlert(alertData, async (alertId) => {
      if(!alertId) return 
      
      alertData.id = alertId
      let docAlerts = selectedDocument.doc_alerts || []
      docAlerts.push(alertId)
      await updateDocument({ doc_alerts: docAlerts, last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDocument)
      setNotification({ msg: t('notification.alert_created'), type: 'success' })
      setShowGlobalResponseLoader(false)
      refreshCollection && refreshCollection()
      onClose()
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  // Update alert
  const updateSingleAlert = async ({ alertTime, selectedDocument, selectedAlert, emails, alertMessage, alertTitle, onClose, setContractError, setTimeError, setEmailsError }) => {
    if(Object.keys(selectedDocument).length === 0) {
      setContractError(true)
      return setNotification({ msg: t('notification.select_a_contract'), type: 'danger' })
    }

    if(!alertTime || emails.length === 0) {
      if(!alertTime) setTimeError(true)
      if(emails.length === 0) setEmailsError(true)
      return setNotification({ msg: t('notification.fill_in_all_required_fields'), type: 'danger' })
    }

    const timestamp = moment(alertTime).clone().valueOf()
    if(timestamp <= Date.now()) {
      return setNotification({ msg: t('notification.time_in_past'), type: 'danger' })
    }

    let alertData = {
      date: moment(alertTime).format('DD/MM/YYYY HH:mm'), 
      dateTimestamp: timestamp, 
      documentId: selectedDocument.id,
      sent: selectedAlert.sent,
      emails: emails,
      message: alertMessage.trim(),
      title: alertTitle ? alertTitle.trim() : t('doc_alert.default_title', { title: selectedDocument.name }),
    }
    setShowGlobalResponseLoader(true)
    updateAlert(alertData, selectedAlert.id, async () => {
      // If new document then remove alert id from prev doc doc_alerts prop
      let docsToUpdate = []
      if(selectedAlert.documentId !== selectedDocument.id) {
        const prevDoc = getDocumentById(selectedAlert.documentId)
        if(prevDoc) {
          const docAlerts = prevDoc.doc_alerts || []
          if(docAlerts.includes(selectedAlert.id)) {
            const filteredAlerts = docAlerts.filter(alertId => alertId !== selectedAlert.id)
            docsToUpdate.push({ data: { doc_alerts: filteredAlerts, sort_alerts: filteredAlerts.length }, id: prevDoc.id })
            await updateDocument({ doc_alerts: filteredAlerts, last_modified_by: activeTeamMember.id, create_action: 'no' }, prevDoc, false)
          }
        }
      }
      const selectedDocAlerts = selectedDocument.doc_alerts || []
      if(!selectedDocAlerts.includes(selectedAlert.id)) {
        selectedDocAlerts.push(selectedAlert.id)
        docsToUpdate.push({ data: { doc_alerts: selectedDocAlerts, sort_alerts: selectedDocAlerts.length }, id: selectedDocument.id })
        await updateDocument({ doc_alerts: selectedDocAlerts, last_modified_by: activeTeamMember.id, create_action: 'no' }, selectedDocument, false)
      }
      if(docsToUpdate.length > 0) {
        updateMultipleDocuments(docsToUpdate)
      }
      setNotification({ msg: t('notification.alert_updated'), type: 'success' })
      onClose()
      setShowGlobalResponseLoader(false)
    }, () => {
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      setShowGlobalResponseLoader(false)
    })
  }

  return {
    createNewAlert,
    updateSingleAlert,
  }
}

export default useAlertActions
import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Document, Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';

import Modal from '../../UI/Modal';
import Loader from '../../UI/Loader';
import { Comment } from '../../pages/SharedPdf';
import {
  GlobalContext,
  NotificationContext,
  UserContext,
  DocumentsContext,
  TeamContext,
} from '../../../context';
import {
  generate_document_for_shared_templates,
  convert_attachment,
} from '../../../services/lawstudioApi';
import { get_file_base64_data } from '../../../services/functions';
import {
  update_shared_document,
  create_shared_document,
} from '../../../services/shared_documents';

const SharedDocsCommentsModal = ({
  onClose,
  sharedDocId,
  sharedDocument,
  doc,
  template,
  onSetDocumentObject,
  unsubscribeListener,
  fromListView = false,
}) => {
  const { t } = useContext(GlobalContext);
  const { setNotification } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const { updateDocument } = useContext(DocumentsContext);
  const { selectedTeam, teamMembers } = useContext(TeamContext);
  const [sharedDoc, setSharedDoc] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [comments, setComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const [shouldMove, setShouldMove] = useState(false);
  const [moving, setMoving] = useState(false);
  const [docCreated, setDocCreated] = useState(false);
  const [sharedWith, setSharedWith] = useState([]);
  const overlayRef = useRef(null);
  const updateTimeout = useRef(null);

  // Create shared document
  const createSharedDocument = useCallback(async () => {
    if (!selectedTeam) return;
    let data = {
      comments: [],
      comments_allowed: true,
      document: doc.id,
      owner: user.email,
      templateUrl: doc.uploaded
        ? doc.documentUrls.pdf || doc.documentUrls.docx
        : template.templateUrl,
      values: doc.values || {},
      created: Date.now(),
      updated_at: Date.now(),
      doc_uploaded: !!doc.uploaded,
      doc_format: doc.uploadedFormat || '',
      templateId: template?.id || null,
      name: doc.name || '',
      team: selectedTeam?.id,
      team_members: selectedTeam?.users_emails,
      shared_with: [],
    };

    try {
      const id = await create_shared_document(data);
      await updateDocument({ shared_document: id, create_action: 'no' }, doc);
      onSetDocumentObject({ ...doc, shared_document: id });
      if (selectedTeam?.users_emails) {
        let sharedWithArr = [];
        for (let i = 0; i < selectedTeam.users_emails.length; i++) {
          let foundUser = teamMembers.find(
            (u) => u.email === selectedTeam.users_emails[i]
          );
          if (foundUser) {
            sharedWithArr.push(foundUser);
          } else {
            sharedWithArr.push({
              email: selectedTeam.users_emails[i],
              id: '',
              name: selectedTeam.users_emails[i],
              image_url: '',
              search: selectedTeam.users_emails[i],
            });
          }
        }
        setSharedWith(sharedWithArr);
      }
      if (unsubscribeListener.current) {
        unsubscribeListener.current = null;
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    doc,
    updateDocument,
    user,
    template,
    onSetDocumentObject,
    unsubscribeListener,
    selectedTeam,
    teamMembers,
  ]);

  // If document is not shared yet, create shared doc
  useEffect(() => {
    if (!selectedTeam) return;
    if (
      (!sharedDocId || (sharedDocId && !sharedDocument)) &&
      !docCreated &&
      !fromListView
    ) {
      console.log('create new shared document***');
      createSharedDocument();
      setDocCreated(true);
    } else if (sharedDocument) {
      setSharedDoc(sharedDocument);
      setComments(sharedDocument.comments || []);
      let sharedWithEmails = [];
      if (sharedDocument.shared_with) {
        sharedWithEmails = [...sharedDocument.shared_with];
      }
      if (sharedDocument.team_members) {
        sharedWithEmails = [
          ...sharedWithEmails,
          ...sharedDocument.team_members,
        ];
      }
      let sharedWithArr = [];
      for (let i = 0; i < sharedWithEmails.length; i++) {
        let foundUser = teamMembers.find(
          (u) => u.email === sharedWithEmails[i]
        );
        if (foundUser) {
          sharedWithArr.push(foundUser);
        } else {
          sharedWithArr.push({
            email: sharedWithEmails[i],
            id: '',
            name: sharedWithEmails[i].split('@')[0],
            image_url: '',
            search: sharedWithEmails[i],
          });
        }
      }
      setSharedWith(sharedWithArr);
    }
  }, [
    sharedDocId,
    sharedDocument,
    // createSharedDocument,
    docCreated,
    fromListView,
    selectedTeam,
    teamMembers,
  ]);

  // Get pdf data when doc is ready
  useEffect(() => {
    const getPdfData = async () => {
      let documentData;
      const { templateUrl, doc_uploaded, doc_format } = sharedDoc;
      try {
        if (templateUrl) {
          if (!doc_uploaded) {
            const res = await generate_document_for_shared_templates(
              sharedDoc.templateUrl,
              sharedDoc.values || {},
              'pdf',
              sharedDoc.content_editable ? sharedDoc.content_changes || [] : []
            );
            documentData = res.data;
          } else {
            if (doc_format === 'pdf' || doc_format === '') {
              const res = await get_file_base64_data({ url: templateUrl });
              documentData = res.data;
            } else {
              const res = await convert_attachment(templateUrl);
              documentData = res.data;
            }
          }
        }
        setLoading(false);
        if (!documentData) {
          return setNotification({
            msg: t('notification.something_went_wrong'),
            type: 'danger',
          });
        }
        setData(`data:application/pdf;base64,${documentData}`);
      } catch (err) {
        console.log(err);
        setNotification({
          msg: t('notification.something_went_wrong'),
          type: 'danger',
        });
        setData(null);
      }
    };

    if (loading && sharedDoc && !data) {
      getPdfData();
    }
  }, [loading, data, setNotification, sharedDoc, t]);

  // On document/pdf load success
  const onDocumentLoadSuccess = (numPages) => {
    const arr = [];
    for (let i = 1; i < numPages + 1; i++) {
      arr.push(
        <Page
          key={i}
          pageNumber={i}
          width={870}
          renderTextLayer={true}
          loading=""
        />
      );
    }
    setPages(arr);
  };

  // On overlay click
  const captureOverlayClick = (e) => {
    if (e.target && e.target.classList.contains('overlay')) {
      const { left, top } = e.target.getBoundingClientRect();
      let id = uuidv4();
      let newComments = [...comments];
      let lastComment = newComments[newComments.length - 1];
      if (lastComment && lastComment.messages.length === 0) {
        newComments[newComments.length - 1] = {
          ...lastComment,
          top: e.pageY - top - window.scrollY,
          left: e.pageX - left - window.scrollX,
        };
        id = lastComment.id;
        setActiveComment(lastComment);
      } else {
        let newComment = {
          id,
          top: e.pageY - top - window.scrollY,
          left: e.pageX - left - window.scrollX,
          messages: [],
          author: user.email,
        };
        newComments.push(newComment);
        setActiveComment(newComment);
      }
      setComments(newComments);
    }
  };

  // On mouse move - only when there is active resizable column
  const mouseMoveHandler = useCallback(
    (e) => {
      if (activeComment !== null && shouldMove) {
        if (updateTimeout.current) {
          clearTimeout(updateTimeout.current);
        }
        const el = overlayRef.current;
        if (el) {
          const { left, top, width, height } = el.getBoundingClientRect();
          let commentsCopy = [...comments];
          const current = commentsCopy.find((c) => c.id === activeComment.id);
          if (current) {
            // top position
            let topPos;
            if (e.pageY - top - window.scrollY < 0) topPos = 0;
            else if (e.pageY - top - window.scrollY > height) topPos = height;
            else topPos = e.pageY - top - window.scrollY;
            current.top = topPos;

            // left position
            let leftPos;
            if (e.pageX - left - window.scrollX < 0) leftPos = 0;
            else if (e.pageX - left - window.scrollX > width) leftPos = width;
            else leftPos = e.pageX - left - window.scrollX;
            current.left = leftPos;
          }
          const updatedComments = commentsCopy.map((c) =>
            c.id === activeComment.id ? current : c
          );
          setComments(updatedComments);
          let updateInDb = true;
          updatedComments.forEach((c) => {
            if (c.messages.length === 0) updateInDb = false;
          });
          if (!updateInDb) return;
          updateTimeout.current = setTimeout(() => {
            let data = {
              comments: updatedComments,
              updated_at: Date.now(),
            };
            update_shared_document(sharedDocId, data);
          }, 1000);
        }
      }
    },
    [activeComment, shouldMove, comments, sharedDocId]
  );

  // Remove mouse event listeners
  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMoveHandler);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMoveHandler]);

  // On mouse up - reset active resizable column
  const mouseUpHandler = useCallback(() => {
    setShouldMove(false);
    setMoving(false);
    removeListeners();
  }, [removeListeners]);

  // On mouse down - set active resizable column
  const mouseDownHandler = (e, comment, idx) => {
    setShouldMove(true);
    setMoving(true);
    setActiveComment({ ...comment, index: idx });

    // if last comment is not clicked comment and last comment does not have any message remove it from comments array
    let lastComment = comments[comments.length - 1];
    if (
      lastComment &&
      lastComment.id !== comment.id &&
      lastComment.messages.length === 0
    ) {
      setComments(comments.filter((c) => c.id !== lastComment.id));
    }
  };

  // Add mouse event listeners
  useEffect(() => {
    if (activeComment !== null) {
      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
    }

    return () => {
      removeListeners();
    };
  }, [activeComment, mouseMoveHandler, mouseUpHandler, removeListeners]);

  // Refresh comments
  const refreshComments = () => {
    setComments(sharedDoc?.comments || []);
  };

  return (
    <Modal onClose={onClose} className="shared-docs-comments-modal">
      <div className="pdf-share">
        <div className={`pdf-share__body pdf-share__body--comments`}>
          <div className="preview">
            <div className="preview__inner">
              <div
                className="overlay"
                onClick={captureOverlayClick}
                ref={overlayRef}
              >
                {!loading &&
                  comments.map((comment, idx) => {
                    let newMessages = 0;
                    if (user && Object.keys(user).length > 0) {
                      let dateOfUpdate = 0;
                      if (
                        user.last_read_shared_comments &&
                        user.last_read_shared_comments[sharedDocId] &&
                        user.last_read_shared_comments[sharedDocId][comment.id]
                      ) {
                        dateOfUpdate =
                          user.last_read_shared_comments[sharedDocId][
                            comment.id
                          ];
                      }
                      const messages = comment.messages;
                      if (messages && Array.isArray(messages)) {
                        for (let j = 0; j < messages.length; j++) {
                          const msg = messages[j];
                          if (
                            msg.author_email !== user.email &&
                            msg.created > dateOfUpdate
                          ) {
                            newMessages += 1;
                          }
                        }
                      }
                    }

                    return (
                      <Comment
                        key={idx}
                        index={idx}
                        comment={comment}
                        comments={comments}
                        onMouseDown={mouseDownHandler}
                        active={activeComment?.id === comment.id && !moving}
                        onSetComments={setComments}
                        onSetActive={setActiveComment}
                        shareId={sharedDocId}
                        isAuthenticated={true}
                        onShowNotLoggedInModal={() => {}}
                        overlayEl={overlayRef.current}
                        newMessages={newMessages}
                        refreshComments={refreshComments}
                        sharedWith={sharedWith}
                      />
                    );
                  })}
              </div>

              {data ? (
                <Document
                  file={data}
                  onLoadSuccess={({ numPages }) =>
                    onDocumentLoadSuccess(numPages)
                  }
                  renderMode="canvas"
                  loading=""
                >
                  {pages}
                </Document>
              ) : (
                <div className="loader-wrapper">
                  <Loader small normal />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SharedDocsCommentsModal;

import { useContext } from 'react'
import { isIOS } from 'react-device-detect';
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { SignatureContext, LoaderContext, ConstantsContext, GlobalContext } from '../context'
import { delete_signature } from '../services/firestore'
import { get_onespan_pdf_doc_as_base64_data } from '../services/onespan'
import { base64toBlob } from '../utils'

const useSignaturesActions = () => {
  const { deleteSignature, signatures } = useContext(SignatureContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { SIGNATURE_STATUS_PROVIDERS } = useContext(ConstantsContext)
  const { t } = useContext(GlobalContext)

  // Delete signatures
  const deleteSignatures = async ({ selectedDocuments, setSelectedDocuments, setShowDeleteSignatureAlert }) => {
    const promises = []
    const ids = []

    for(let i = 0; i < selectedDocuments.length; i++) {
      const doc = selectedDocuments[i]
      promises.push(delete_signature(doc.id))
      ids.push(doc.id)
    }
    
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Deleting signatures')
    
    await Promise.all(promises)
      .then((data) => {
        let arr = []
        data.forEach((d, i) => {
          if(d.success) {
            arr.push(ids[i])
          }
        })
        if(arr.length > 0) {
          deleteSignature(arr)
        }
        setShowDeleteSignatureAlert(false)
        setSelectedDocuments([])
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      })
  }

  // Download signatures
  const downloadSignatures = async ({ selectedDocuments, setSelectedDocuments }) => {
    setShowGlobalResponseLoader(true)
    // setGlobalResponseLoaderText('Preparing signatures for download')

    if(selectedDocuments.length === 1) {
      const doc = selectedDocuments[0]
      const res = await get_onespan_pdf_doc_as_base64_data(doc.package_id, doc.document_id)
      if(res.success) {
        if(isIOS) {
          const blob = base64toBlob(res.data)
          const a = document.createElement('a')
          a.onclick = saveAs(blob, `${doc.title || 'document'}.pdf`)
        }else {
          const a = document.createElement("a")
          a.href = `data:application/pdf;base64,${res.data}`
          a.download = `${doc.title}.pdf`
          a.click()
        }
      }
    }else {
      const zip = new JSZip()
      let docNames = []
      for(let i in selectedDocuments) {
        const doc = selectedDocuments[i]
        const res = await get_onespan_pdf_doc_as_base64_data(doc.package_id, doc.document_id)
        if(res.success) {
          let name = `${doc.title}.pdf`
          zip.file(
            docNames.includes(name) ? `${doc.title}(${i}).pdf` : name, 
            res.data, 
            {base64: true}
          )
          docNames.push(`${doc.title}.pdf`)
        }
      }
      docNames = []

      zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, "signatures.zip")
      })
    }

    setSelectedDocuments([])
    setShowGlobalResponseLoader(false)
    setGlobalResponseLoaderText('')
  }

  // Filter signatures by status
  const filterSignaturesByStatus = ({ status, active, setSelectedDocuments, setSelectedTitle, setFilteredSignatures }) => {
    const s = [...signatures]

    if(active) {
      setSelectedTitle(t('general.all'))
      return setFilteredSignatures(signatures)
    }
    
    let filtered = []
    filtered = s.filter(s => s.status === status);

    setSelectedTitle(SIGNATURE_STATUS_PROVIDERS[status])
    setFilteredSignatures(filtered)
    setSelectedDocuments([])
  }

  return {
    deleteSignatures,
    downloadSignatures,
    filterSignaturesByStatus,
  }
}

export default useSignaturesActions
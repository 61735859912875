import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined'

import Tooltip from '../../UI/Tooltip';
import CustomTooltip from '../../UI/CustomTooltip';
import ChatIcon from '@material-ui/icons/Chat'
import { LimitedFeatureWrapper } from '../../utils/LimitedFeatureWrapper'
import { TeamContext, GlobalContext } from '../../../context'
import { availableOn } from '../../../utils'

const DocumentDetailSidePanelSmall = ({ onTriggerClick, onSetView, view, signatures, attachments, showBackToTop, docUploaded, tasks, commentsBadge, isSharedTemplate, numOfAlerts }) => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam } = useContext(TeamContext)

  const viewSetHandler = (view, e) => {
    e.preventDefault();
    onSetView(view);
  }

  return(
    <div className="side-panel side-panel--2">
      <div className="side-panel__menu">
        <div className="tooltip-wrapper">
          <CustomTooltip content={t('general.view')} position="left">
            <div className="trigger" onClick={onTriggerClick}>
              <i className="custom-icon-close-panel"></i>
            </div>
          </CustomTooltip>
        </div>

        <ul>
          {!docUploaded ? (
            <li onClick={onTriggerClick}>
              <div className="tooltip-wrapper">
                <CustomTooltip content={t('dashboard.variables')} position="left">
                  <div>
                    <Link 
                      to="/" 
                      onClick={(e) => viewSetHandler('variables', e)} 
                      className={view === 'variables' ? 'active' : ''}
                    >
                      <DescriptionOutlinedIcon />
                    </Link>
                  </div>
                </CustomTooltip>
              </div>
            </li>
          ) : (
            <li onClick={onTriggerClick}>
              <div className="tooltip-wrapper">
                <CustomTooltip content={t('dashboard.document')} position="left">
                  <div>
                    <Link 
                      to="/" 
                      onClick={(e) => viewSetHandler('preview', e)} 
                      className={view === 'preview' ? 'active' : ''}
                    >
                      <DescriptionOutlinedIcon />
                    </Link>
                  </div>
                </CustomTooltip>
              </div>
            </li>
          )}
          {!isSharedTemplate && <li onClick={onTriggerClick}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('dashboard.dates')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('dates', e)}
                    className={view === 'dates' ? 'active' : ''}
                  >
                    <CalendarTodayIcon />
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li onClick={onTriggerClick}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('general.alert')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('alert', e)}
                    className={view === 'alert' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <AlarmIcon />
                      {numOfAlerts && numOfAlerts > 0 ? <span className="badge">{numOfAlerts}</span> : null}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li onClick={onTriggerClick}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('dashboard.tags')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('tags', e)}
                    className={view === 'tags' ? 'active' : ''}
                  >
                    <LabelOutlinedIcon />
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li onClick={onTriggerClick}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('dashboard.tasks')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('tasks', e)}
                    className={view === 'tasks' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <CheckCircleOutlineIcon />
                      {tasks.length > 0 && <span className="badge">{tasks.length}</span>}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {!isSharedTemplate && <li onClick={onTriggerClick}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('general.appendices')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('appendices', e)}
                    className={view === 'appendices' ? 'active' : ''}
                  >
                    <span className="badge-wrap">
                      <AttachFileIcon />
                      {attachments.length > 0 && <span className="badge">{attachments.length}</span>}
                    </span>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {/* <li onClick={onTriggerClick}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('general.contacts')} position="left">
                <div>
                  <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('contacts', e)}
                    className={view === 'contacts' ? 'active' : ''}
                  >
                    <i className="custom-icon-contact-page"></i>
                  </Link>
                </div>
              </CustomTooltip>
            </div>
          </li> */}
          {/* <LimitedFeatureWrapper type="team" requirement="beta_partner"> */}
            {availableOn(['development']) && !isSharedTemplate && <li onClick={selectedTeam?.membership !== 'free' ? onTriggerClick : () => {}}>
              <div className="tooltip-wrapper">
                <CustomTooltip content={t('general.messages')} position="left">
                  <div>
                    {selectedTeam?.membership !== 'free' ? <Link 
                      to="/" 
                      onClick={(e) => viewSetHandler('comments', e)}
                      className={view === 'comments' ? 'active' : ''}
                    >
                      <ChatIcon />
                      {commentsBadge > 0 && <span className="badge">{commentsBadge}</span>}
                    </Link>
                    : <div className="disabled"><ChatIcon /></div>}
                  </div>
                </CustomTooltip>
              </div>
            </li>}
          {/* </LimitedFeatureWrapper> */}
          {!isSharedTemplate && <li onClick={selectedTeam?.membership !== 'free' ? onTriggerClick : () => {}}>
            <div className="tooltip-wrapper">
              <CustomTooltip content={t('custom_fields.title')} position="left">
                <div>
                  {selectedTeam?.membership !== 'free' ? <Link 
                    to="/" 
                    onClick={(e) => viewSetHandler('custom_fields', e)}
                    className={view === 'custom_fields' ? 'active' : ''}
                  >
                    <AssignmentIcon />
                  </Link>
                  : <div className="disabled"><AssignmentIcon /></div>}
                </div>
              </CustomTooltip>
            </div>
          </li>}
          {/* <li>
            <div className="tooltip-wrapper">
              <Link 
                to="/" 
                onClick={(e) => e.preventDefault()}
                className={view === '4' ? 'active' : ''}
              >
                <span className="badge-wrap">
                  <i className="custom-icon-create"></i>
                  {signatures.length > 0 && <span className="badge">{signatures.length}</span>}
                </span>
              </Link>
              <Tooltip text={t('dashboard.signatures')} position="left" />
            </div>
          </li>
          <li>
            <div className="tooltip-wrapper">
              <Link 
                to="/" 
                onClick={(e) => e.preventDefault()}
                className={view === '3' ? 'active' : ''}
              >
                <i className="custom-icon-mark-email-read"></i>
              </Link>
              <Tooltip text={t('dashboard.recommended')} position="left" />
            </div>
          </li> */}
        </ul>

        <div className={showBackToTop ? "back-to-top back-to-top--2 active" : "back-to-top back-to-top--2"} onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
          <CustomTooltip content={t('general.to_top')} position="left" fixed>
            <i className="custom-icon-arrow-circle-up"></i>
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
}

DocumentDetailSidePanelSmall.propTypes = {
  onTriggerClick: PropTypes.func.isRequired,
  onSetView: PropTypes.func,
  view: PropTypes.string
}

export default memo(DocumentDetailSidePanelSmall)
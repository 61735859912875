import { MainLayout } from './'
import { SettingsSidebar } from '../components/misc'

const SettingsLayout = ({ title, children }) => {
  return (
    <MainLayout
      title={title}
      hideSearch={true}
      hideCreateDoc={true}
      showSidebarTrigger={true}
      sidebar={
        <SettingsSidebar />
      }
      className="settings-layout"
    >
      {children}
    </MainLayout>
  )
}

export default SettingsLayout
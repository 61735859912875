import { useState, useContext, useEffect } from 'react' 
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import { MainLayout } from '../layouts'
import { ApprovalStepRow, StepMembersModal } from '../components/misc'
import { Alert, Input } from '../components/new_ui'
import { TeamContext, ApprovalsContext, GlobalContext, DocumentsContext } from '../context'
import { useSingleApprovalActions } from '../hooks'

const AddEditApproval = ({ edit = false }) => {
  const { t } = useContext(GlobalContext)
  const { approvalsFetched, fetchApprovals, singleApprovalFetched, fetchOtherApprovals, approvals } = useContext(ApprovalsContext)
  const { selectedTeam, teamChanged, setTeamChanged } = useContext(TeamContext)
  const { documentsLoaded, fetchDocs } = useContext(DocumentsContext)
  const { createSingleApproval, updateSingleApproval } = useSingleApprovalActions()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [steps, setSteps] = useState([
    {
      id: uuidV4(),
      members: [],
      from: 'anyone'
    }
  ])
  const [showDeleteStepAlert, setShowDeleteStepAlert] = useState(false)
  const [stepToDelete, setStepToDelete] = useState(null)
  const [showStepMembersModal, setShowStepMembersModal] = useState(false)
  const [currentStep, setCurrentStep] = useState(null)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [approval, setApproval] = useState({})
  const [approvalNotFound, setApprovalNotFound] = useState(false)
  const [fieldsPopulated, setFieldsPopulated] = useState(false)
  const history = useHistory()
  const params = useParams()

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
    }
  }, [teamChanged, setTeamChanged])

  // Fetch approvals if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!approvalsFetched) {
        // console.log('fetch approvals')
        if(!singleApprovalFetched) {
          fetchApprovals(teamId)
        }else {
          fetchOtherApprovals(teamId)
        }
      }
      if(edit && !documentsLoaded) {
        fetchDocs(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, approvalsFetched, fetchApprovals, singleApprovalFetched, fetchOtherApprovals, edit, documentsLoaded, fetchDocs])

  // Populate fields if in edit mode
  useEffect(() => {
    if(edit && params.id && approvalsFetched && !fieldsPopulated) {
      const approval = approvals.find(a => a.id === params.id)
      if(approval) {
        setApproval(approval)
        if(approval.name) setName(approval.name)
        if(approval.description) setDescription(approval.description)
        if(approval.steps) setSteps(approval.steps)
      }else {
        setApprovalNotFound(true)
      }
      setFieldsPopulated(true)
    }
  }, [edit, params, approvals, approvalsFetched, fieldsPopulated])

  // On step delete trigger click
  const handleStepDeleteTriggerClick = (step) => {
    setStepToDelete(step)
    setShowDeleteStepAlert(true)
  }

  // On step delete alert close
  const handleStepDeleteAlertClose = () => {
    setShowDeleteStepAlert(false)
    setStepToDelete(null)
  }

  // On step delete
  const handleDeleteStep = () => {
    setSteps(steps.filter(s => s.id !== stepToDelete.id))
    setStepToDelete(null)
    setShowDeleteStepAlert(false)
  }

  // On step add
  const handleAddNewStep = () => {
    let newStep = {
      id: uuidV4(),
      members: [],
      from: 'anyone',
    }
    setSteps([...steps, newStep])
  }

  // On members add
  const handleMembersAdd = (step) => {
    setCurrentStep(step)
    setShowStepMembersModal(true)
  }

  // On step members modal close
  const handleStepMembersModalClose = () => {
    setCurrentStep(null)
    setShowStepMembersModal(false)
  }

  // On save step members
  const handleSaveStepMembers = (step, members) => {
    setSteps(steps.map(s => s.id === step.id ? {...s, members} : s))
  }

  // On step from change
  const handleStepFromChange = (step, value) => {
    setSteps(steps.map(s => s.id === step.id ? {...s, from: value} : s))
  }

  // On cancel
  const handleCancel = () => {
    history.push('/approvals')
  }

  // On save
  const handleSave = async () => {
    if(!edit) { // create
      await createSingleApproval({ name, steps, description })
    }else { // edit 
      await updateSingleApproval({ name, steps, description, approval })
    }
  }

  if(edit && approvalNotFound) {
    return (
      <MainLayout>
        <div className="add-edit-approval">
          <div className="add-edit-approval__head">
            <h2 className="heading-2">{t('approvals.approval_not_found')}</h2>
          </div>
          <div className="add-edit-approval__foot">
            <button className="btn" onClick={handleCancel}>{t('general.cancel')}</button>
          </div>
        </div>
      </MainLayout>
    )
  }

  if(edit && (!approval || Object.keys(approval).length === 0)) {
    return (
      <MainLayout>
        <div className="add-edit-approval">
          <div className="loader-v2"></div>
        </div>
      </MainLayout>
    )
  }

  return (
    <MainLayout title={t('approvals.title')}>
      <div className="add-edit-approval">
        <section className="add-edit-approval__details">
          <h3 className="heading-3">Nom et description de l’approbation</h3>
          <div className="add-edit-approval__details_fields">
            <Input value={name} onChange={(e) => setName(e.target.value)} label={t('general.name')} />
            <Input value={description} onChange={(e) => setDescription(e.target.value)} label={t('general.description')} />
          </div>
        </section>
        <section className="add-edit-approval__steps">
          <h3 className="heading-3">{t('approvals.setup')}</h3>
          <div className="approval-steps">
            <div className="approval-steps__head">
              <div className="approval-step-row">
                <div className="approval-step-col">{t('approvals.step')}</div>
                <div className="approval-step-col">{t('approvals.assigned_to')}</div>
                <div className="approval-step-col">{t('approvals.approval_required')}</div>
                {/* <div className="approval-step-col">&nbsp;</div> */}
              </div>
            </div>
            <div className="approval-steps__body">
              {steps.map((step, idx) => (
                <ApprovalStepRow onStepDelete={handleStepDeleteTriggerClick} key={step.id} num={idx + 1} data={step} onMembersAdd={handleMembersAdd} onStepFromUpdate={handleStepFromChange} />
              ))}
            </div>
            <div className="approval-steps__add">
              <button className="btn btn--small btn--outline" onClick={handleAddNewStep}>
                <span className="material-symbols-outlined">add</span>
                {t('approvals.add_step')}
              </button>
            </div>
          </div>
        </section>

        <div className="add-edit-approval__actions">
          <button className="btn btn--gray" onClick={handleCancel}>{t('general.cancel')}</button>
          <button className="btn" onClick={handleSave}>{t('general.save')}</button>
        </div>
      </div>

      {showDeleteStepAlert && <Alert 
        onClose={handleStepDeleteAlertClose}
        text={t('approvals.delete_step')}
        onSubmit={handleDeleteStep}
        deleteAlert
      />}

      {showStepMembersModal && <StepMembersModal 
        onClose={handleStepMembersModalClose}
        currentStep={currentStep}
        onSaveSelectedStepMembers={handleSaveStepMembers}
      />}
    </MainLayout>
  )
}

export default AddEditApproval 
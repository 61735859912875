import React, { useContext } from 'react'

import Button from '../../UI/Button'
import { GlobalContext } from '../../../context'

const UpdateTemplateSection = ({ didSignatureTemplateChanged, updateSignatureTemplateHandler, setShowSaveUpdatedTemplateModal }) => {
  const { t } = useContext(GlobalContext)
  const changes = didSignatureTemplateChanged.changes
  const changesMade = [...changes.checkbox_positions, ...changes.signature_positions].length
  let changesMadeText = ''
  if(changes.checkbox_positions.length > 0) {
    changesMadeText += `${changes.checkbox_positions.length} ${t('signature_templates.checkboxes_modified')}`
  }
  if(changes.signature_positions.length > 0) {
    let text = `${changes.signature_positions.length} ${t('signature_templates.locations_modified')}`
    if(changesMadeText !== '') {
      changesMadeText += `, ${text}`
    }else {
      changesMadeText += `${text}`
    }
  }

  return (
    <div className="signature-modal-inner__section signature-modal__template">
      <p className="form__subtitle form__subtitle--3">{changesMade} {t('signature_templates.locations_added')} ({changesMadeText})</p>
      <div className="signature-modal__template_update">
        <Button text={t('signature_templates.update_existing')} primaryLight onButtonClick={updateSignatureTemplateHandler} />
        <Button text={t('signature_templates.create_new')} primaryLight onButtonClick={() => setShowSaveUpdatedTemplateModal(true)} />
      </div>
    </div>
  )
}

export default UpdateTemplateSection
import { GET_CONTACT_GROUPS, SET_CONTACT_GROUPS, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_CONTACT_GROUPS:
      return {
        ...state,
        contactGroups: action.payload,
        contactGroupsFetched: true,
      }
    case SET_CONTACT_GROUPS:
      return {
        ...state,
        contactGroups: action.payload,
      }
    case RESET_STATE:
      return action.payload
    default:
      return state
  }
}

export default reducer
import { useState, useContext, useEffect, useCallback } from 'react'

import { Modal, Input, Textarea } from '../../new_ui'
import { GlobalContext } from '../../../context'
import { useSingleVariableCategoryActions } from '../../../hooks'

const CreateEditCategoryModal = ({ onClose, mode = 'create', data }) => {
  const { t } = useContext(GlobalContext)
  const { createVariableCategory, updateVariableCategory, deleteVariableCategory } = useSingleVariableCategoryActions()
  const [categoryId, setCategoryId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  // Populate fields if mode is edit
  useEffect(() => {
    if(mode === 'edit' && data) {
      setName(data.display_name)
      setCategoryId(data.id)
      setDescription(data.description)
    }
  }, [mode, data])

  // On submit/save
  const handleSave = useCallback(async () => {
    if(mode === 'create') {
      await createVariableCategory({ name, description, onClose })
    }else {
      await updateVariableCategory({ name, description, categoryId, onClose })
    }
  }, [createVariableCategory, updateVariableCategory, mode, categoryId, description, name, onClose])

  // Delete click handler
  const handleDelete = async () => {
    await deleteVariableCategory({ categoryId, onClose })
  }

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSave(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSave])

  return (
    <Modal 
      onClose={onClose} 
      className="centered-head"
      title={mode === 'create' ? t('dashboard.create_category') : t('dashboard.edit_category')}
      saveBtnText={mode === 'create' ? t('dashboard.create') : t('general.edit')}
      onSave={handleSave}
    >
      <div className="create-variable">
        <div className="create-variable__body">
          <Input 
            value={categoryId} 
            // onChange={(e) => setCategoryId(e.target.value)} 
            label={t('dashboard.category_id')} 
            disabled={true} 
            infoText={t('dashboard.generated_automatically')} 
          />
          <Input 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            label={`${t('dashboard.category_label')}*`} 
          />
          <Textarea 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
            label={t('dashboard.category_description')} 
          />
        </div>

        <div className="create-variable__actions">
          {mode === 'edit' && (
            <button className="btn btn--danger" onClick={handleDelete}>{t('general.remove')}</button>
          )}
        </div>
      </div>
    </Modal>
  )
} 

export default CreateEditCategoryModal
import React, { createContext, useReducer, useState, useEffect } from 'react';

import teamReducer from './teamReducer';
import { GET_TEAMS, SET_TEAMS, RESET_STATE } from '../types';
import { create_team, fetch_teams, update_team } from '../../services/firestore';
import { sortArrayOfObjects, saveSelectedTeamToLS } from '../../utils';
import { CARBON_SELECTED_TEAM_LS } from '../../constants'

export const TeamContext = createContext();

const TeamState = ({ children }) => {
  const initialState = {
    teams: [],
    teamsFetched: false
  };

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [fetchCollections, setFetchCollections] = useState(null);
  const [teamMode, setTeamMode] = useState('add');
  const [teamStep, setTeamStep] = useState(1);
  const [activeTeamMember, setActiveTeamMember] = useState({});
  const [teamChanged, setTeamChanged] = useState(false)
  const [teamMembers, setTeamMembers] = useState([])
  const [state, dispatch] = useReducer(teamReducer, initialState);

  // Set team members on team change
  useEffect(() => {
    if(selectedTeam) {
      const activeUsers = selectedTeam.users.filter(u => u.status === 'active')
      let arr = []
      for(let user of activeUsers) {
        arr.push({
          email: user.email,
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          search: `${user.email} ${user.first_name} ${user.last_name}`.toLowerCase(),
          image_url: user.image_url || ''
        })
      }
      setTeamMembers(arr)
    }
  }, [selectedTeam])

  // Set teams
  const setTeams = (teams) => {
    dispatch({
      type: SET_TEAMS,
      payload: teams
    });
  }

  // Fetch teams
  const fetchTeams = async (id = null, user, userMembership) => {
    try {
      const res = await fetch_teams();
      // console.log('teams res', res)
      let sortedArr = [];
      sortedArr = sortArrayOfObjects(res, 'name', 'desc');
      // if(sortedArr.length === 0) {
      //   const teamData = {
      //     users: [{ email: user.email, role: 'admin', status: 'active'}],
      //     users_emails: [user.email],
      //     name: t('team.default'),
      //     created_at: Date.now(),
      //     type: 'default',
      //     membership: userMembership
      //   }
      //   const teamRes = await create_team(teamData);
      //   const defaultTeam = {...teamData, id: teamRes.id};
      //   sortedArr.push(defaultTeam);
      // }
      let teamId
      if(id) {
        const team = sortedArr.find(team => team.id === id)
        if(team) {
          setSelectedTeam(team);
          saveSelectedTeamToLS(team.id)
          teamId = team.id
        }else {
          setSelectedTeam(sortedArr[0]);
          saveSelectedTeamToLS(sortedArr[0].id)
          teamId = sortedArr[0].id
        }
      }else {
        const lsSelectedTeam = localStorage.getItem(CARBON_SELECTED_TEAM_LS)
        const teamFound = sortedArr.find(team => team.id === lsSelectedTeam)
        if(teamFound) {
          setSelectedTeam(teamFound)
          teamId = teamFound.id
        }else {
          setSelectedTeam(sortedArr[0]);
          saveSelectedTeamToLS(sortedArr[0].id)
          teamId = sortedArr[0].id
        }
      }
      dispatch({
        type: GET_TEAMS,
        payload: sortedArr
      });
      return {
        teamId: teamId,
        teams: sortedArr
      }
    }catch(err) {
      console.log('TEAMS fetch err', err);
    }
  }

  // Create task 
  const createTeam = async (body, onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await create_team(body);
      const teams = [...state.teams];
      const newTeam = { ...body, id: res.id };
      teams.push(newTeam);
      const sortedArr = sortArrayOfObjects(teams, 'name', 'asc');
      setTeams(sortedArr);
      onSuccess(newTeam);
    }catch(err) {
      console.log('CREATE TEAM err', err);
      onError(err);
    }
  }

  // Update team
  const updateTeam = async (data, id, updateInDb = false) => {
    const teams = [...state.teams];
    const teamToUpdate = teams.find(team => team.id === id);
    const updatedTeam = {...teamToUpdate, ...data};
    if(updateInDb) {
      await update_team(data, id)
    }
    setSelectedTeam(updatedTeam);
    const updatedTeams = teams.map(team => team.id === id ? updatedTeam : team);
    setTeams(updatedTeams);
  }

  // Reset state
  const resetState = () => {
    dispatch({
      type: RESET_STATE,
      payload: initialState
    });
    setSelectedTeam(null);
    setActiveTeamMember({});
  }

  // Get user name by id
  const getUserNameById = (id) => {
    if(!id) return '';
    if(selectedTeam) {
      const users = selectedTeam.users;
      const user = users.find(u => u.id === id);
      if(user) {
        let name = '';
        if(user.first_name) name = user.first_name;
        if(name && user.last_name) name = `${name} ${user.last_name}`;
        if(!name && user.email) name = user.email;
        return name;
      }
    }
    return '';
  }

  // Get user name by email
  const getUserNameByEmail = (email) => {
    if(!email) return ''
    if(selectedTeam) {
      const users = selectedTeam.users
      const user = users.find(u => u.email === email)
      if(user) {
        let name = ''
        if(user.first_name) name = user.first_name
        if(name && user.last_name) name = `${name} ${user.last_name}`
        if(!name && user.email) name = user.email
        return name
      }
    }
    return ''
  }

  // Get user name by id
  const getUserImageUrl = (id) => {
    if(!id) return ''
    if(selectedTeam) {
      const users = selectedTeam.users
      const user = users.find(u => u.id === id)
      if(user) {
        return user.image_url || ''
      }
    }
    return ''
  }

  // Get user name by id
  const getTeamMemberById = (id) => {
    if(!id) return {}
    if(selectedTeam) {
      const users = selectedTeam.users
      const user = users.find(u => u.id === id)
      if(user) {
        return user
      }
    }
    return {}
  }

  return(
    <TeamContext.Provider value={{
      teams: state.teams,
      teamsFetched: state.teamsFetched,
      selectedTeam,
      teamStep,
      teamMode,
      fetchCollections,
      activeTeamMember,
      createTeam,
      setSelectedTeam,
      fetchTeams,
      setTeamMode,
      setTeamStep,
      updateTeam,
      resetTeamState: resetState,
      setTeams,
      setFetchCollections,
      setActiveTeamMember,
      getUserNameById,
      getTeamMemberById,
      getUserImageUrl,
      getUserNameByEmail,
      teamChanged,
      setTeamChanged,
      teamMembers,
    }}>
      {children}
    </TeamContext.Provider>
  );
};

export default TeamState;
import { useContext, useEffect, useState, useRef } from 'react' 
import { CSVReader } from 'react-papaparse'
import XLSX from 'xlsx'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import Switch from '../../UI/Switch'
import { Modal } from '../../new_ui'
import { ContactBox, FieldMapping } from '../'
import { ContactsContext, ContactGroupsContext, NotificationContext, VariablesContext, TeamContext, GlobalContext, ConstantsContext } from '../../../context'
import { useContactsActions } from '../../../hooks'

const ContactsImportModal = ({ onClose, isXls, refreshContacts }) => {
  const { t } = useContext(GlobalContext)
  const { DEFAULT_CONTACT_FIELDS } = useContext(ConstantsContext)
  const { contacts, contactsSelectedFilters } = useContext(ContactsContext)
  const { contactGroups } = useContext(ContactGroupsContext)
  const { setNotification } = useContext(NotificationContext)
  const { activeTeamMember, selectedTeam } = useContext(TeamContext)
  const { fetchVariables, variablesFetched } = useContext(VariablesContext)
  const { importContacts } = useContactsActions()
  const [tempData, setTempData] = useState([])
  const [data, setData] = useState([])
  const [fileAdded, setFileAdded] = useState(false)
  const [groupLabel, setGroupLabel] = useState(t('contacts.select_group'))
  const [group, setGroup] = useState('')
  const [mergeWithLabel, setMergeWithLabel] = useState(t('contacts.choose_field'))
  const [mergeWith, setMergeWith] = useState('')
  const [mergeChecked, setMergeChecked] = useState(false)
  const [firstRowHeaders, setFirstRowHeaders] = useState(false)
  // const [skipBlankOrInvalid, setSkipBlankOrInvalid] = useState(false)
  const [mode, setMode] = useState(isXls ? 'xls' : 'upload')
  const [message, setMessage] = useState(null)
  const [csvColumns, setCsvColumns] = useState(null)
  const [mappedFields, setMappedFields] = useState([])
  const [resetMapping, setResetMapping] = useState(false)
  const [selectedContacts, setSelectedContacts] = useState([])
  const [matchedColumnsAdded, setMatchedColumnsAdded] = useState(false)
  const [customCsvColumns, setCustomCsvColumns] = useState([])
  const [originalCustomCsvColumns, setOriginalCustomCsvColumns] = useState([])
  const [linkedVariables, setLinkedVariables] = useState({})
  const [uploadDisabled, setUploadDisabled] = useState(false)
  const [uploadError, setUploadError] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [contactsToUpdate, setContactsToUpdate] = useState({})
  const [contactsToCreate, setContactsToCreate] = useState([])
  const [customColumnsToSave, setCustomColumnsToSave] = useState({})
  const [xlsFile, setXlsFile] = useState(null)
  const [xlsFileData, setXlsFileData] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [checkAllContacts, setCheckAllContacts] = useState(false)
  const xlsFileRef = useRef()

  // Get variables if they are not fetched yet
  useEffect(() => {
    if(!variablesFetched && selectedTeam?.id) {
      fetchVariables(selectedTeam)
    }
  }, [variablesFetched, fetchVariables, selectedTeam])

  // Set group and group label if activeGroup is set
  useEffect(() => {
    if(contactsSelectedFilters.group !== 'all') {
      const findGroup = contactGroups.find(g => g.id === contactsSelectedFilters.group)
      if(findGroup) {
        setGroup(findGroup.id)
        setGroupLabel(findGroup.name)
      }
    }
  }, [contactsSelectedFilters, contactGroups])

  // On drop
  const handleOnDrop = (data, fileInfo) => {
    const typeAccepted = ['text/csv', '.csv']
    if(!fileInfo.type || !typeAccepted.includes(fileInfo.type)) {
      setUploadError(t('contacts.only_csv_file_allowed'))
      setUploadDisabled(true)
    }
    setFileAdded(true)
    setTempData(data)
  }

  // On error
  const handleOnError = (err, file) => {
    console.log(err)
    console.log(file)
  }

  // On remove file
  const handleOnRemoveFile = (data) => {
    setFileAdded(false)
    setTempData([])
    setUploadDisabled(false)
    setUploadError('')
  }

  // On xls file drag enter
  const handleXlsDragEnter = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  // On xls file drag leave
  const handleXlsDragLeave = (e) => {
    e.preventDefault()
  }

  // On xls file drag 
  const handleXlsDragOver = (e) => {
    e.preventDefault()
  }

  // On xls file drop 
  const handleXlsDrop = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if(!file) return
    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xls']
    if(!file.type || !allowedTypes.includes(file.type)) {
      setNotification({ msg: t('contacts.only_xls_file_allowed'), type: 'danger' })
      return
    }
    
    const data = await file.arrayBuffer()
    const parsedFile = await XLSX.read(data, { type: 'array' })
    const firstSheet = parsedFile.Sheets[parsedFile.SheetNames[0]];
     
    // header: 1 instructs xlsx to create an 'array of arrays'
    const result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
    setXlsFile(file)
    setFileAdded(true)
    setXlsFileData(result)
    setIsDragging(false)
  }

  // Xls file parse
  const handleXlsFileParse = async (file) => {
    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xls']
    if(!file.type || !allowedTypes.includes(file.type)) {
      setNotification({ msg: t('contacts.only_xls_file_allowed'), type: 'danger' })
      return
    }
    
    const data = await file.arrayBuffer()
    const parsedFile = await XLSX.read(data, { type: 'array' })
    const firstSheet = parsedFile.Sheets[parsedFile.SheetNames[0]];
     
    // header: 1 instructs xlsx to create an 'array of arrays'
    const result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
    setXlsFile(file)
    setFileAdded(true)
    setXlsFileData(result)
  }

  // On file upload button click
  const handleFileUpload = () => {
    let processedData = []
    if(mode === 'xls') {
      processedData = xlsFileData
    }else {
      tempData.forEach(item => processedData.push(item.data))
    }
    if(processedData.length === 0) return 
    let columns = processedData[0]
    let matchedColumns = []
    let notMatchedColumns = []
    let allColumns = []
    let customColumns = []
    let teamContactsCustomColumns = selectedTeam.contacts_custom_columns
    if(teamContactsCustomColumns) {
      for(let key in teamContactsCustomColumns) {
        DEFAULT_CONTACT_FIELDS.push({ value: key, label: teamContactsCustomColumns[key] })
      }
    }
    // Check if columns from csv have a match in default columns or they are new and then set them to matchedColumns or customCsvColumns
    columns.forEach((col, idx) => {
      let stringCol = typeof col !== 'string' ? col + '' : col.trim()
      let processedCol = stringCol.toLowerCase().replace(' ', '_')
      if(DEFAULT_CONTACT_FIELDS.find(f => f.value === processedCol)) {
        matchedColumns.push({ key: processedCol, column: idx, active: true })
      }else {
        notMatchedColumns.push({ key: processedCol, column: idx, active: false })
        customColumns.push({ label: stringCol, value: processedCol })
      }
    })
    if(matchedColumns.length) {
      allColumns = [...matchedColumns]
    }
    if(notMatchedColumns.length) {
      allColumns = [...allColumns, ...notMatchedColumns]
    }
    if(allColumns.length) {
      // console.log(allColumns)
      setMappedFields(allColumns)
      setMatchedColumnsAdded(true)
      setTimeout(() => setMatchedColumnsAdded(false), 50)
    }
    if(customColumns.length) {
      setCustomCsvColumns(customColumns)
      setOriginalCustomCsvColumns(JSON.parse(JSON.stringify(customColumns)))
    }
    setData(processedData)
    setCsvColumns(columns)
    setTempData([])
    setFileAdded(false)
    setMode('map')
  }

  // On contacts save
  const handleSaveContacts = async () => {
    await importContacts({ contactsToCreate, contactsToUpdate, linkedVariables, group, customColumnsToSave, mappedFields, onClose, refreshContacts })
  }

  // Check how many contacts are new or how many changed and set contacts to create or contacts to update based on that and show info message
  const processContacts = (cntcts) => {
    const mappedContacts = cntcts || mappedFields

    if(!mappedContacts.find(c => c.active)) {
      setMessage(null)
      return
    }

    // If merge is checked and there is no mergeWith field selected show info message
    if(mergeChecked && !mergeWith) {
      setInfoMessage(t('contacts.select_merge_field'))
      setMessage(null)
      return
    }

    // If shouldn't show contacts show info messages
    if(!shouldShowContacts() && mappedContacts.length > 0) {
      // check if there is a mapped field which does not have a column
      let field = mappedContacts.find(f => f.active && f.column === undefined)
      if(field) {
        let key = field.key 
        let findField = DEFAULT_CONTACT_FIELDS.find(f => f.value === key)
        if(findField) {
          let msg = t('contacts.map_csv_column', { field: findField.label}) 
          setInfoMessage(msg)
        }
        setMessage(null)
        return
      }

      // check if should merge but merge with field is not checked or it does not have a value/column
      const find = mappedFields.find(f => f.key === mergeWith)
      if(mergeChecked && mergeWith && ((find && !find.active) || !find)) {
        setInfoMessage(t('contacts.map_field', { field: mergeWith }))
        setMessage(null)
        return
      }
    }

    let contactsArrToCreate = []
    const fieldsToSave = mappedContacts.filter(f => f.active)
    selectedContacts.forEach(c => {
      const contact = data[c]
      let obj = { group, team: selectedTeam.id, owner: activeTeamMember.id }
      fieldsToSave.forEach(field => {
        obj[field.key] = contact[field.column]
      })
      contactsArrToCreate.push(obj)
    })

    let contactsToMerge = []
    let contactsDidntChange = []
    let newContacts = []
    let contactsObj = {}
    if(mergeChecked && mergeWith) {
      contactsArrToCreate.forEach(c => {
        const find = contacts.find(cn => cn[mergeWith] === c[mergeWith]) 
        if(find) {
          if(didContactChanged(c, find)) {
            contactsToMerge.push(c)
            let updateC = {...c}
            if(updateC.owner) delete updateC.owner
            if(updateC.team) delete updateC.team
            contactsObj[find.id] = updateC
          }else {
            contactsDidntChange.push(c)
          }
        }
  
        if(!find) {
          newContacts.push(c)
        }
      })
    }else {
      newContacts = [...contactsArrToCreate]
    }
    // console.log(contactsToMerge, contactsToMerge.length, 'update')
    // console.log(contactsDidntChange, contactsDidntChange.length, 'not changed - do nothing')
    // console.log(newContacts, newContacts.length, 'new')
    let msg = ''
    if(contactsToMerge.length) {
      msg = `${contactsToMerge.length} ${contactsToMerge.length === 1 ? t('contacts.updated_based_on', { field: mergeWith }) : t('contacts.updated_based_on', { field: mergeWith })}`
    }
    if(contactsDidntChange.length) {
      msg += `${contactsDidntChange.length} ${contactsDidntChange.length === 1 ? t('contacts.record_not_changed') : t('contacts.records_not_changed')}`
    }
    if(newContacts.length) {
      msg += `${newContacts.length} ${t('contacts.new')} ${newContacts.length === 1 ? t('contacts.contact_will_be_created') : t('contacts.will_be_created')}`
    }
    // console.log(msg, newContacts, contactsToMerge)
    // console.log(contactsObj)
    setContactsToCreate(newContacts)
    setContactsToUpdate(contactsObj)
    setMessage(msg)
  }

  // Process contacts
  useEffect(() => {
    processContacts()
    // eslint-disable-next-line
  }, [selectedContacts, mergeWith, mappedFields, group, mergeChecked])

  // Check if contact changed
  const didContactChanged = (newContact, oldContact) => {
    let old = {...oldContact}
    let newC = {...newContact}
    if(old.owner) delete old.owner 
    if(old.id) delete old.id 
    if(newC.owner) delete newC.owner
    
    if(Object.keys(old).length !== Object.keys(newC).length) {
      let changed = false
      for(let key in newC) {
        if(!old.hasOwnProperty(key)) {
          changed = true
        }else if(newC[key] !== old[key]) {
          changed = true
        }
      }
      if(changed) {
        // console.log('contact changed, you can update it')
        return true
      }
    }else {
      let changed = false
      for(let key in newC) {
        if(!old.hasOwnProperty(key)) {
          changed = true
        }else if(newC[key] !== old[key]) {
          changed = true
        }
      }
      if(changed) {
        // console.log('contact changed, you can update it')
        return true
      }
    }
    // console.log('contact did not changed, no need to update it')
  }

  // On select group
  const handleGroupSelect = (e, g) => {
    e.preventDefault()
    setGroup(g.id)
    setGroupLabel(g.name)
    document.body.click()
  }

  // On select merged field
  const handleMergeFieldSelect = (e, f) => {
    e.preventDefault()
    setMergeWith(f.value)
    setMergeWithLabel(f.label)
    document.body.click()
  }

  // On reset field mappings
  const handleResetFieldMappings = (e) => {
    e.preventDefault()
    setMappedFields([])
    setLinkedVariables({})
    setCustomColumnsToSave({})
    setContactsToCreate([])
    setContactsToUpdate({})
    setMessage(null)
    setInfoMessage('')
    setSelectedContacts([])
    setCustomCsvColumns(originalCustomCsvColumns)
    setResetMapping(true)
    setTimeout(() => setResetMapping(false), 50)
  }

  // On first row headers switch change
  const handleFirstRowHeadersChange = () => {
    setFirstRowHeaders(!firstRowHeaders)
    if(checkAllContacts) {
      let arr = []
      for(let i = 0; i < data.length; i++) {
        if(!firstRowHeaders && i === 0) continue
        arr.push(i)
      }
      setSelectedContacts(arr)
    }
  }

  // Check if contacts should be shown
  const shouldShowContacts = () => {
    let show = true
    if(mappedFields.length === 0) show = false 
    if(show && mappedFields.find(f => f.active && f.column === undefined)) show = false 
    if(show && mergeChecked && !mergeWith) show = false 
    const find = mappedFields.find(f => f.key === mergeWith)
    if(show && mergeChecked && mergeWith && ((find && !find.active) || (!find))) show = false 
    let activeArr = mappedFields.map(f => f.active)
    if(show && activeArr.every(item => item === false)) {
      show = false
    }
    return show
  }

  // Check if button should be disabled
  const isDisabled = () => {
    return !group || mappedFields.length === 0 || !!mappedFields.find(f => f.active && f.column === undefined) || selectedContacts.length === 0 || (mergeChecked && !mergeWith) || (mergeChecked && mergeWith && !mappedFields.find(f => f.key === mergeWith || (f.key === mergeWith && !f.active))) || !mappedFields.find(f => f.active)
  }

  // On xls upload area click
  const handleOpenXlsFileInput = () => {
    if(xlsFileRef.current) {
      xlsFileRef.current.click()
    }
  }

  // On xls file remove
  const handleRemoveXlsFile = () => {
    setXlsFile(null)
    setFileAdded(false)
    setXlsFileData([])
  }

  // On check/uncheck all contacts
  const handleCheckAllContacts = () => {
    setCheckAllContacts(!checkAllContacts)
    if(!checkAllContacts) {
      let arr = []
      for(let i = 0; i < data.length; i++) {
        if(firstRowHeaders && i === 0) continue
        arr.push(i)
      }
      setSelectedContacts(arr)
    }else {
      setSelectedContacts([])
    }
  }

  // Render CSV upload
  const renderCSVUpload = () => {
    return (
      <div className={`csv-upload-section ${fileAdded ? 'csv-upload-section--csv-added' : ''}`}>
        {uploadError && <p>{uploadError}</p>}
        <CSVReader
          onDrop={handleOnDrop}
          onError={handleOnError}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
          noProgressBar={true}
          accept='text/csv,.csv'
          style={{
            dropArea: {
              borderColor: '#e5e5e5',
              borderRadius: 10,
              height: 300,
            },
            dropAreaActive: {
              borderColor: '#006EFF',
            },
            dropFile: {
              width: '100%',
              height: 'auto',
              borderRadius: 0,
              background: 'transparent',
              alignItems: 'flex-start',
            },
            fileSizeInfo: {
              color: '#aaa',
              backgroundColor: 'transparent',
              borderRadius: 0,
              lineHeight: 1.2,
              marginBottom: '0',
              padding: '0',
              order: 2,
              fontSize: 12
            },
            fileNameInfo: {
              color: '#34353B',
              backgroundColor: 'transparent',
              borderRadius: 0,
              fontSize: 16,
              lineHeight: 1.3,
              padding: '0',
              order: 1,
              marginBottom: 0
            },
            removeButton: {
              color: '#FA4040',
            },
          }}
        >
          <div className="upload-icon"><span className="material-symbols-outlined filled">draft</span></div>
          <p className="para-1">{t('contacts.select_file_to_upload')}</p>
          <p className="para-2">{t('contacts.dnd_file')}</p>
        </CSVReader>
      </div>
    )
  }

  // Render xls upload
  const renderXlsUpload = () => {
    let size = 0 
    if(xlsFile) {
      let unit = 'KB'
      size = xlsFile.size / Math.pow(1024, 1)
      if(size > 1024) {
        size = xlsFile.size / Math.pow(1024,2)
        unit = 'MB'
      }
      size = size.toFixed(2) + ' ' + unit
    }

    return (
      <div className="xls-upload">
        {!xlsFile ? (
          <div 
            className="xls-upload__area" 
            onClick={isDragging ? () => {} : handleOpenXlsFileInput}
            onDragEnter={handleXlsDragEnter}
            onDragLeave={handleXlsDragLeave}
            onDragOver={handleXlsDragOver}
            onDrop={handleXlsDrop}
          >
            <div className="upload-icon"><span className="material-symbols-outlined filled">draft</span></div>
            <p className="para-1">{t('contacts.select_file_to_upload')}</p>
            <p className="para-2">{t('contacts.dnd_file')}</p>
            <input type="file" onChange={(e) => handleXlsFileParse(e.target.files[0])} className="hidden" ref={xlsFileRef} accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/xls" />
          </div>
        ) : (
          <div className="xls-upload__info">
            <div className="xls-upload__info_file">
              <div className="xls-upload__info_file-left">
                <p>{xlsFile.name}</p>
                <p className="size">{size}</p>
              </div>
              <div className="xls-upload__info_file-right" onClick={handleRemoveXlsFile}>
                <span className="material-symbols-outlined">cancel</span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  // Render group of contacts
  const renderGroupOfContactsSection = () => {
    return (
      <section className="contact-import-section">
        <div className="contact-import-section__head">
          <h3>{t('contacts.group_of_contacts')}</h3>
        </div>
        <div className="contact-import-section__body">
          <ActionsDropdown
            trigger={<button className="group-btn">{groupLabel} <span className="icon"><span className="material-symbols-outlined">arrow_drop_down</span></span></button>}
            hideHeader
          >
            <ul>
              {contactGroups.map((group, idx) => (
                <li key={idx}>
                  <div className="link link--small">
                    <a href="/#" onClick={(e) => handleGroupSelect(e, group)}>{group.name}</a>
                  </div>
                </li>
              ))}
            </ul>
          </ActionsDropdown>
        </div>
      </section>
    )
  }

  // Render field mappings
  const renderFieldMappingsSection = () => {
    return (
      <section className="contact-import-section">
        <div className="contact-import-section__head">
          <h3>{t('contacts.field_mappings')}</h3>
          <CustomTooltip content={t('contacts.reset_fields')}>
            <a href="/#" onClick={handleResetFieldMappings}><span className="material-symbols-outlined">refresh</span> {t('contacts.reset')}</a>
          </CustomTooltip>
        </div>
        <div className="contact-import-section__body">
          {DEFAULT_CONTACT_FIELDS.map((field, idx) => {
            return <FieldMapping 
              key={idx} 
              field={field} 
              columns={csvColumns} 
              firstRowHeaders={firstRowHeaders} 
              mappedFields={mappedFields}
              onSetMappedFields={setMappedFields} 
              reset={resetMapping}
              matchedColumnsAdded={matchedColumnsAdded}
              linkedVariables={linkedVariables}
              onSetLinkedVariables={setLinkedVariables}
            />
          })}
          {customCsvColumns.length > 0 && <p className="added-from-csv">{t('contacts.added_from_file')}:</p>}
          {customCsvColumns.map((field, idx) => {
            return <FieldMapping 
              key={idx}
              field={field}
              columns={csvColumns}
              firstRowHeaders={firstRowHeaders} 
              mappedFields={mappedFields}
              onSetMappedFields={setMappedFields} 
              reset={resetMapping}
              matchedColumnsAdded={matchedColumnsAdded}
              linkedVariables={linkedVariables}
              onSetLinkedVariables={setLinkedVariables}
              className="custom-field"
              custom={true}
              customColumnsToSave={customColumnsToSave}
              onSetCustomColumnsToSave={setCustomColumnsToSave}
              customCsvColumns={customCsvColumns}
              onSetCustomCsvColumns={setCustomCsvColumns}
            />
          })}
        </div>
      </section>
    )
  }

  // Render settings
  const renderSettingsSection = () => {
    return (
      <section className="contact-import-section">
        <div className="contact-import-section__head">
          <h3>{t('contacts.settings')}</h3>
        </div>
        <div className="contact-import-section__body">
          <div className="settings-row" onClick={() => setMergeChecked(!mergeChecked)}>
            <Switch checked={mergeChecked} onChange={() => setMergeChecked(mergeChecked)} small />
            <p>{t('contacts.merge_with_existing')}</p>
          </div>
          {mergeChecked && <div className="settings-group">
            <p className="info">{t('contacts.rows_merged_if_val_matches')}</p>
            <p className="info">{t('contacts.unique_merge_field')}</p>
            <ActionsDropdown
              trigger={<button className="group-btn">{mergeWithLabel} <span className="icon"><span className="material-symbols-outlined">arrow_drop_down</span></span></button>}
              hideHeader
            >
              <ul>
                {DEFAULT_CONTACT_FIELDS.map((field, idx) => (
                  <li key={idx}>
                    <div className="link link--small">
                      <a href="/#" onClick={(e) => handleMergeFieldSelect(e, field)}>{field.label}</a>
                    </div>
                  </li>
                ))}
              </ul>
            </ActionsDropdown>
            {/* <div className="settings-row" onClick={() => setSkipBlankOrInvalid(!skipBlankOrInvalid)}>
              <Switch checked={skipBlankOrInvalid} onChange={() => setSkipBlankOrInvalid(skipBlankOrInvalid)} small />
              <p>Skip blank or invalid CSV values</p>
            </div> */}
          </div>}
          <div className="settings-row settings-row--last">
            <div className="switch-overlay" onClick={handleFirstRowHeadersChange}></div>
            <Switch checked={firstRowHeaders} onChange={() => setFirstRowHeaders(!firstRowHeaders)} small />
            <p>{t('contacts.first_row_headers', { format: isXls ? 'XLS' : 'CSV' })}</p>
          </div>
        </div>
      </section>
    )
  }

  return (
    <Modal 
      onClose={onClose}
      title={t('contacts.add')}
      hideFooter={true}
      className="modal-v2--large"
    >
      <div className="contacts-import-modal contacts-import-modal-v2">
        {/* CSV Upload */}
        {mode === 'upload' && renderCSVUpload()}

        {/* XLS Upload */}
        {mode === 'xls' && renderXlsUpload()}

        {/* Body */}
        {mode === 'map' && <div className="contacts-import-modal__body">
          <div className="contacts-import-modal__body_left u-custom-scrollbar">
            {/* Section - group of contacts */}
            {renderGroupOfContactsSection()}

            {/* Section - settings */}
            {renderSettingsSection()}

            {/* Section - field mappings */}
            {renderFieldMappingsSection()}
          </div>
          <div className="contacts-import-modal__body_right">
            {shouldShowContacts() && <div className="contacts-import-modal__body_right-head">
              {message && <div className="action-message">
                <p>{message}</p>
              </div>}
              <div className={checkAllContacts ? "check-all active" : "check-all"} onClick={handleCheckAllContacts}>
                {checkAllContacts ? <span className="material-symbols-outlined filled">check_box</span> : <span className="material-symbols-outlined">check_box_outline_blank</span>}
                <p>{t('contacts.check_all')}</p>
              </div>
            </div>}
            <div className="contacts-import-modal__body_right-inner u-custom-scrollbar">
              {!shouldShowContacts() ? (
                <div className="not-mapped">
                  {mappedFields.filter(f => f.active).length === 0 ? (
                    <>
                      <h3>{t('contacts.set_up_field_mappings')}</h3>
                      <p>{t('contacts.choose_how_to_map')}</p>
                    </>
                  ):(
                    <h3>{infoMessage}</h3>
                  )}
                </div>
              ) : (
                <>
                  {data.map((contact, idx) => {
                    if(firstRowHeaders && idx === 0) return null 
                    return <ContactBox 
                      contact={contact} 
                      key={idx} 
                      index={idx}
                      mappedFields={mappedFields} 
                      selectedContacts={selectedContacts} 
                      onSetSelectedContacts={setSelectedContacts} 
                    />
                  })}
                </>
              )}
            </div>
          </div>
        </div>}

        {/* Actions */}
        {(mode === 'map' || ((mode === 'upload' || mode === 'xls') && fileAdded)) && <div className="contacts-import-modal__actions">
          <div className="buttons">
            <button className="btn btn--outline cancel-btn" onClick={onClose}>{t('general.cancel')}</button>
            {(mode === 'upload' || mode === 'xls') && <button className="btn" onClick={handleFileUpload} disabled={uploadDisabled}>{t('contacts.upload')}</button>}
            {mode === 'map' && <button className="btn" onClick={handleSaveContacts} disabled={isDisabled()}>{t('contacts.save_changes')}</button>}
          </div>
        </div>}
      </div>
    </Modal>
  )
}

export default ContactsImportModal
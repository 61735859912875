import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isIOS } from 'react-device-detect';
import { saveAs } from 'file-saver';

import {
  LoaderContext,
  GlobalContext,
  TeamContext,
  DocumentsContext,
  NotificationContext,
  UserContext,
} from '../context';
import { get_file, delete_document } from '../services/firestore';
import { generate_document } from '../services/lawstudioApi';
import { base64toBlob } from '../utils';

const useSingleDocumentActions = () => {
  const {
    updateDocument,
    getTemplateById,
    deleteMultipleDocuments,
    createDocument,
  } = useContext(DocumentsContext);
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } =
    useContext(LoaderContext);
  const { selectedTeam } = useContext(TeamContext);
  const { setNotification } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const { t } = useContext(GlobalContext);
  const history = useHistory();

  // Fetch document data
  const fetchDocumentData = useCallback(
    async (document, extension) => {
      let doc = await generate_document(
        document.template,
        document.values,
        { convertTo: extension },
        document.content_editable ? document.content_changes : [],
        selectedTeam
      );
      if (doc.error) {
        return null;
      }
      return doc;
    },
    [selectedTeam]
  );

  // Duplicate
  const duplicateDocument = useCallback(
    async ({ doc }) => {
      setShowGlobalResponseLoader(true);
      // setGlobalResponseLoaderText('Duplicating document')

      try {
        let docCopy = {
          name: t('general.copy_of', { document: doc.name }),
          progress: doc.progress,
          status: doc.status,
          template: doc.template,
          values: doc.values,
          team: selectedTeam?.id,
        };
        await createDocument(docCopy);
      } catch (err) {
        console.log(err);
      } finally {
        setShowGlobalResponseLoader(false);
        setGlobalResponseLoaderText('');
      }
    },
    [
      createDocument,
      selectedTeam,
      setShowGlobalResponseLoader,
      setGlobalResponseLoaderText,
      t,
    ]
  );

  // Archive
  const archiveDocument = useCallback(
    async ({ doc }) => {
      setShowGlobalResponseLoader(true);
      // setGlobalResponseLoaderText('Archiving document')

      try {
        let data = {
          archived: true,
          last_modified_by: user.id,
          create_action: 'yes',
        };
        if (doc.deleted) {
          data.deleted = false;
          data.deleteAfter = null;
        }
        await updateDocument(data, doc);
      } catch (err) {
        console.log(err);
      } finally {
        setShowGlobalResponseLoader(false);
        setGlobalResponseLoaderText('');
      }
    },
    [
      setGlobalResponseLoaderText,
      setShowGlobalResponseLoader,
      user,
      updateDocument,
    ]
  );

  // Download
  const downloadDocument = useCallback(
    async ({ doc, type }) => {
      setShowGlobalResponseLoader(true);
      // setGlobalResponseLoaderText('Preparing document for download')
      try {
        if (doc.uploaded) {
          let href = '';
          let ext = '';
          if (type === 'pdf') {
            const res = await get_file({ url: doc.documentUrls.pdf });
            href = res.data;
            ext = 'pdf';
          }
          if (type === 'docx') {
            const res = await get_file({ url: doc.documentUrls.docx });
            href = res.data;
            ext = 'docx';
          }
          if (href) {
            if (isIOS) {
              const blob = base64toBlob(href);
              const a = document.createElement('a');
              a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`);
            } else {
              const a = document.createElement('a');
              a.href = `data:application/pdf;base64,${href}`;
              a.download = `${doc.name || 'document'}.${ext}`;
              a.click();
            }
          }
        } else {
          const tmplt = getTemplateById(doc.template);
          if (tmplt && Object.keys(tmplt).length > 0) {
            // const templateUrl = tmplt.templateUrl
            if(type === 'pdf'){
              let documentData = await fetchDocumentData(doc, 'pdf');
              if (isIOS) {
                const blob = base64toBlob(documentData);
                const a = document.createElement('a');
                a.onclick = saveAs(blob, `${doc.name || 'document'}.pdf`);
              } else {
                const a = document.createElement('a');
                a.href = `data:application/pdf;base64,${documentData}`;
                a.download = `${doc.name || 'document'}.pdf`;
                a.click();
              }
            }
            if(type === 'docx'){
              let documentData = await fetchDocumentData(doc, 'docx');
              if (isIOS) {
                const blob = base64toBlob(documentData);
                const a = document.createElement('a');
                a.onclick = saveAs(blob, `${doc.name || 'document'}.docx`);
              } else {
                const a = document.createElement('a');
                a.href = `data:application/pdf;base64,${documentData}`;
                a.download = `${doc.name || 'document'}.docx`;
                a.click();
              }
            }
          } else {
            setNotification({
              msg: `Impossible de télécharger le document, le document n'a pas de modèle.`,
              type: 'danger',
            });
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setShowGlobalResponseLoader(false);
        setGlobalResponseLoaderText('');
      }
    },
    [
      fetchDocumentData,
      getTemplateById,
      setGlobalResponseLoaderText,
      setShowGlobalResponseLoader,
      setNotification,
    ]
  );

  // Delete
  const deleteDocument = async ({
    doc,
    singleDocToDelete,
    setShowDeleteSingleDocAlert,
    setSingleDocToDelete,
  }) => {
    setShowGlobalResponseLoader(true);
    // setGlobalResponseLoaderText('Deleting document')

    let docToDelete;
    if (doc) {
      docToDelete = doc;
    } else {
      docToDelete = singleDocToDelete;
    }

    try {
      await updateDocument(
        {
          deleted: true,
          archived: false,
          deleteAfter: new Date(
            Date.now() + 60 * 60 * 24 * 30 * 1000
          ).getTime(),
          last_modified_by: user.id,
          create_action: 'no',
        },
        docToDelete
      );

      setShowDeleteSingleDocAlert(false);
      setSingleDocToDelete(null);
    } catch (err) {
      console.log(err);
    } finally {
      setShowGlobalResponseLoader(false);
      setGlobalResponseLoaderText('');
    }
  };

  // Restore
  const restoreDocument = useCallback(
    async ({ currentFilter, doc, setRestored }) => {
      setShowGlobalResponseLoader(true);
      // setGlobalResponseLoaderText('Restoring document')

      try {
        if (currentFilter === 'deleted') {
          await updateDocument(
            {
              deleted: false,
              deleteAfter: null,
              archived: false,
              last_modified_by: user.id,
              create_action: 'no',
            },
            doc
          );
          setRestored(true);
        } else if (currentFilter === 'archived') {
          await updateDocument(
            { archived: false, last_modified_by: user.id, create_action: 'no' },
            doc
          );
          setRestored(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setShowGlobalResponseLoader(false);
        setGlobalResponseLoaderText('');
      }
    },
    [
      setGlobalResponseLoaderText,
      setShowGlobalResponseLoader,
      user,
      updateDocument,
    ]
  );

  // Delete permanently
  const deleteDocumentPermanently = useCallback(
    async ({ doc }) => {
      if (!doc) return;

      setShowGlobalResponseLoader(true);
      // setGlobalResponseLoaderText('Deleting document permanently')

      try {
        await delete_document(doc.id);
        deleteMultipleDocuments([doc.id]);
        setNotification({
          msg: t('notification.document_deleted'),
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        setNotification({
          msg: t('notification.something_went_wrong'),
          type: 'danger',
        });
      } finally {
        setShowGlobalResponseLoader(false);
        setGlobalResponseLoaderText('');
      }
    },
    [
      deleteMultipleDocuments,
      setGlobalResponseLoaderText,
      setShowGlobalResponseLoader,
      setNotification,
      t,
    ]
  );

  // Move document to folder
  const moveDocumentToFolder = useCallback(
    async ({ document, folder, setSelectedDocuments }) => {
      let doc = { ...document };
      if (!document.folderId) {
        doc = { ...document, folderId: [] };
      }

      if (folder) {
        if (!doc.folderId.includes(folder.id)) {
          const foldersArr = [];
          foldersArr.push(folder.id);
          setShowGlobalResponseLoader(true);
          // setGlobalResponseLoaderText('Moving document to folder')
          await updateDocument(
            {
              folderId: foldersArr,
              last_modified_by: user.id,
              create_action: 'no',
            },
            doc
          );
          setShowGlobalResponseLoader(false);
          setGlobalResponseLoaderText('');
          setNotification({
            msg: t('folder.document_moved_to_folder', {
              doc: doc.name,
              folder: folder.name,
            }),
            type: 'default',
          });
        } else {
          setNotification({
            msg: t('folder.document_already_in', {
              doc: doc.name,
              folder: folder.name,
            }),
            type: 'warning',
          });
        }
      } else if (doc.folderId.length > 0) {
        setShowGlobalResponseLoader(true);
        await updateDocument(
          { folderId: [], last_modified_by: user.id, create_action: 'no' },
          doc
        );
        setShowGlobalResponseLoader(false);
        setNotification({
          msg: t('folder.document_moved_to_root', { doc: doc.name }),
          type: 'default',
        });
      }
      setSelectedDocuments([]);
    },
    [
      setGlobalResponseLoaderText,
      setShowGlobalResponseLoader,
      setNotification,
      user,
      t,
      updateDocument,
    ]
  );

  // Make document favorite
  const makeDocumentFavorite = useCallback(
    async ({ doc }) => {
      if (!doc.favorite || doc.archived || doc.deleted) {
        setShowGlobalResponseLoader(true);
        let data = {
          favorite: true,
          archived: false,
          last_modified_by: user.id,
          create_action: 'yes',
        };
        if (doc.deleted) {
          data.deleted = false;
          data.deleteAfter = null;
        }
        await updateDocument(data, doc);
        setShowGlobalResponseLoader(false);
      }
    },
    [setShowGlobalResponseLoader, updateDocument, user]
  );

  // Create document from template
  const createDocumentFromTemplate = async ({ template }) => {
    let data = {
      name: template.name,
      status: 'empty',
      doc_alerts: [],
      progress: 0,
      template: template.id,
      values: {},
      team: selectedTeam?.id,
    };
    setShowGlobalResponseLoader(true);
    createDocument(
      data,
      (id) => {
        setShowGlobalResponseLoader(false);
        history.push({
          pathname: `/document-detail/${id}`,
        });
      },
      () => {
        setShowGlobalResponseLoader(false);
      },
      false
    );
  };

  return {
    duplicateDocument,
    archiveDocument,
    downloadDocument,
    deleteDocument,
    restoreDocument,
    deleteDocumentPermanently,
    moveDocumentToFolder,
    makeDocumentFavorite,
    createDocumentFromTemplate,
  };
};

export default useSingleDocumentActions;

import React, { useState } from 'react';
import TimeField from 'react-simple-timefield';

// Use only in react-datepicker for customTimeInput prop
const CustomTimeInput = ({ date, value, onChange }) => {
  const [defaultTime, setDefaultTime] = useState(value ? value : '09:00');
  
  return (
    <TimeField
      value={defaultTime}
      className="form-input__field"
      onChange={e => {setDefaultTime(e.target.value); onChange(e.target.value)}}
      colon=":"
    />
  );
}

export default CustomTimeInput;
import React, { createContext, useReducer, useState } from 'react'

import notariesReducer from './notariesReducer'
import { GET_NOTARIES, SET_NOTARIES, RESET_STATE } from '../types'
import { fetch_notaries, create_notary, update_notary, delete_notary } from '../../services/notaries'
import { sortArrayOfObjects } from '../../utils'

export const NotariesContext = createContext()


const NotariesState = ({ children }) => {
  const initialState = {
    notaries: [],
    notariesFetched: false,
  };
  const [state, dispatch] = useReducer(notariesReducer, initialState)

  // Fetch all
  const fetchNotaries = async (teamId) => {
    try {
      const res = await fetch_notaries(teamId)
      const arr = []
      for(let id in res) {
        const item = {...res[id]}
        item.id = id
        arr.push(item)
      }
      dispatch({
        type: GET_NOTARIES,
        payload: sortArrayOfObjects(arr, 'name', 'desc'),
      })
    }catch(err) {
      console.log('NOTARIES FETCH err', err)
    }
  }

  // Set notaries
  const setNotaries = (arr) => {
    dispatch({
      type: SET_NOTARIES,
      payload: arr
    })
  }

  // Create   
  const createNotary = async (data, userId) => {
    try { 
      const res = await create_notary(data)
      const newNotary = {...data, id: res.id, owner: userId}
      const arr = [...state.notaries]
      arr.push(newNotary)
      setNotaries(sortArrayOfObjects(arr, 'name', 'desc'))
    } catch (err) {
      console.log('CREATE NOTARY error', err)
    }
  }

  // Update  
  const updateNotary = async (data, id) => {
    try { 
      await update_notary(data, id)
      const arr = state.notaries.map(notary => notary.id === id ? {...notary, ...data} : notary)
      setNotaries(sortArrayOfObjects(arr, 'name', 'desc'))
    } catch (err) {
      console.log('UPDATE NOTARY error', err)
    }
  } 

  // Delete  
  const deleteNotary = async (id) => {
    try { 
      await delete_notary(id)
      const arr = state.notaries.filter(notary => notary.id !== id)
      setNotaries(sortArrayOfObjects(arr, 'name', 'desc'))
    } catch (err) {
      console.log('DELETE NOTARY error', err)
    }
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, notariesFetched: true } : initialState,
      payload: initialState,
    })
  }

  return(
    <NotariesContext.Provider value={{
      notaries: state.notaries,
      notariesFetched: state.notariesFetched,
      fetchNotaries,
      createNotary,
      updateNotary,
      deleteNotary,
      resetNotariesState: resetState,
    }}>
      {children}
    </NotariesContext.Provider>
  );
};

export default NotariesState
import { useContext, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'
import qs from 'qs'

import { SettingsLayout } from '../layouts'
import { InviteUsersModal, RenameTeamModal } from '../components/misc'
import { LetterCircle, Alert } from '../components/new_ui'
import { GlobalContext, TeamContext, UserContext, NotificationContext } from '../context'
import { saveSelectedTeamToLS, sortArrayOfObjects, resizedImageUrl, getFileData, hasSufficientMembership } from '../utils'
import { useTeamActions } from '../hooks'

const Team = () => {
  const { t } = useContext(GlobalContext)
  const { selectedTeam, setSelectedTeam, teams, setActiveTeamMember, activeTeamMember } = useContext(TeamContext)
  const { user } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { uploadTeamImage, joinSingleTeam, isTeamAdmin, removeTeamImage, deleteUserFromTeam, resendTeamInvitation, deleteTeam, renameTeam } = useTeamActions()
  const [showInviteUsersModal, setShowInviteUsersModal] = useState()
  const [columns, setColumns] = useState([
    { label: t('general.name'), cssClass: 'col-1', value: 'name', order: 'asc', active: true, sort: true },
    { label: t('team.access'), cssClass: 'col-2', value: 'access', order: 'asc', active: false, sort: true },
    { label: t('dashboard.status'), cssClass: 'col-3', value: 'statusTranslated', order: 'asc', active: false, sort: true },
    { label: t('team.action'), cssClass: 'col-4', value: '', order: 'asc', active: false, sort: false },
  ])
  const [teamMembers, setTeamMembers] = useState([])
  const [showTeamHeadDropdown, setShowTeamHeadDropdown] = useState(false)
  const [showRenameTeamPopup, setShowRenameTeamPopup] = useState(false)
  const [showDeleteTeamAlert, setShowDeleteTeamAlert] = useState(false)
  const [apiCredentialsVisible, setApiCredentialsVisible] = useState(false)
  const fileInput = useRef()
  const location = useLocation()

  useEffect(() => {
    // hide api credentials when team is switched
    setApiCredentialsVisible(false)
  }, [selectedTeam])

  // Check if user is joining the team, if there are parameters in url
  useEffect(() => {
    if(location.search) {
      const qsVars = qs.parse(location.search, { ignoreQueryPrefix: true })
      if(qsVars['team'] && qsVars['email']) {
        const findTeam = teams.find(tm => tm.id === qsVars['team'])
        const findMember = findTeam?.users.find(u => u.email === qsVars['email'])
        if(findTeam && findMember && findMember.status === 'pending') {
          setSelectedTeam(findTeam)
          saveSelectedTeamToLS(findTeam.id)
          setActiveTeamMember({...activeTeamMember, ...findMember})
        }
      }
    }
    // eslint-disable-next-line
  }, [location, teams, setSelectedTeam])

  // Set team members 
  useEffect(() => {
    if(Object.keys(user).length > 0 && selectedTeam) {
      const arr = [...selectedTeam.users].map(u => ({
        name: u.email === user.email 
          ? `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}` 
          : `${u.first_name ? u.first_name : ''} ${u.last_name ? u.last_name : ''}`, 
        email: u.email, 
        access: u.role === 'admin' ? t('team.owner') : t('team.editor'), 
        owner: selectedTeam.owner === u.id,
        image: u.image_url ? resizedImageUrl(u.image_url, 128) : '',
        statusTranslated: t(`status.${u.status}`),
        status: u.status,
      }))
      const sortedArr = sortArrayOfObjects(arr, 'name', 'asc')
      setTeamMembers(sortedArr)
      // const activeMember = selectedTeam.users.find(u => u.email === user.email)
      // setActiveTeamMember(activeMember)
    }
  }, [user, selectedTeam, teams, t])

  // File change handler 
  const handleFileChange = (e) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/gif']
    const file = e.target.files[0]
    if(file) {
      if(!allowedTypes.includes(file.type)) {
        return setNotification({ msg: t('notification.invalid_file_type_2'), type: 'danger' })
      }
      getFileData(file, uploadTeamImage)
    }
  }

  // On sort
  const handleSort = (value, order) => {
    const sortedArr = sortArrayOfObjects(teamMembers, value, order === 'asc' ? 'desc' : 'asc')
    setTeamMembers(sortedArr)
    setColumns(prev => [...prev].map(col => col.value === value ? { ...col, order: order === 'asc' ? 'desc' : 'asc', active: true } : {...col, active: false}))
  }

  // On team join
  const handleJoinTeam = async () => {
    await joinSingleTeam()
  }

  // On image remove
  const handleRemoveImage = async (e) => {
    e.preventDefault()
    await removeTeamImage()
  }

  // On delete user from team
  const handleDeleteUserFromTeam = async (email) => {
    await deleteUserFromTeam(email)
  }

  // On resend team invitation
  const handleResendInvitation = async (email) => {
    await resendTeamInvitation(email)
  }

  // On team delete
  const handleTeamDelete = async () => {
    await deleteTeam({ setShowDeleteTeamAlert, setShowTeamHeadDropdown })
  }

  // On team rename
  const handleRenameTeam = async (name) => {
    await renameTeam({ name, setShowRenameTeamPopup })
  }

  // Render content - join mode
  const renderJoinTeamContent = () => {
    return (
      <div className="team">
        <div className="team__join">
          <button className="btn" onClick={handleJoinTeam}>{t('team.join')}</button>
        </div>
      </div>
    )
  }

  // Render content - edit mode
  const renderContent = () => {
    return (
      <div className="team">
        <div className="team__head">
          {isTeamAdmin() ? 
          <ClickAwayListener onClickAway={() => setShowTeamHeadDropdown(false)}>
            <div>
              <h3 
                className="team__subtitle" 
                onClick={() => setShowTeamHeadDropdown(!showTeamHeadDropdown)}
              >
                {selectedTeam?.name} <span className="material-symbols-outlined">keyboard_arrow_down</span>
              </h3>
              {showTeamHeadDropdown && <div className="dropdown-el default team__head_dropdown">
                <ul>
                  <li onClick={() => {setShowTeamHeadDropdown(false); setShowRenameTeamPopup(true)}}>
                    <span className="material-symbols-outlined">edit</span>
                    {t('team.rename')}
                  </li>
                  {selectedTeam?.type !== 'default' && <li className="delete" onClick={() => {setShowTeamHeadDropdown(false); setShowDeleteTeamAlert(true)}}>
                    <span className="material-symbols-outlined">delete</span>
                    {t('team.delete')}
                  </li>}
                </ul>
              </div>}
            </div>
          </ClickAwayListener> 
          :
          <h3 className="team__subtitle u-cursor--default">{selectedTeam?.name}</h3>
          }
        </div>
        <div className="form">
          <div className="form__group form__group--image">
            <div className={selectedTeam?.logo_url ? "image-box image-box--sm" : "image-box image-box--sm image-box--transparent-border"}
              style={selectedTeam?.logo_url ? { backgroundImage: `url(${selectedTeam.logo_url})`} : {}}
            >
              {!selectedTeam?.logo_url && <LetterCircle data={selectedTeam?.name} hideTooltip={true} useOnlyFirstLetter={true} />}
            </div>
            {isTeamAdmin() && <div className="actions">
              <input type="file" accept="image/png,image/jpg,image/jpeg" onChange={handleFileChange} className="hidden" ref={fileInput} />
              <button className="btn btn--outline btn--small" onClick={() => fileInput.current.click()}>{t('general.upload_image')}</button>
              {selectedTeam?.logo_url && <a href="#/" onClick={handleRemoveImage}>{t('general.delete_image')}</a>}
            </div>}
          </div>
        </div>
        <h2 className={!isTeamAdmin() ? "team__subtitle team__subtitle--mb-lg" : "team__subtitle"}>{t('team.members')}</h2>
        {isTeamAdmin() && <div className="team__add">
          <div className="team__add_left">
            <p>{t('team.workspace_note')}</p>
          </div>
          <div className="team__add_right">
            <button className="btn" onClick={() => setShowInviteUsersModal(true)} disabled={!hasSufficientMembership(selectedTeam?.membership, 'premium')}>
              <span className="material-symbols-outlined">person_add</span> 
              {t('team.invite')}
            </button>
          </div>
        </div>}
        <div className="team__table">
          <div className="team__table_head">
            {columns.map((col, i) => (
              <div className={`col ${col.cssClass}`} key={i}>
                <div className={col.active ? "sort active" : "sort"} onClick={() => col.sort ? handleSort(col.value, col.order) : () => {}}>
                  {col.label} {col.active ? col.order === 'asc' ? <span className="material-symbols-outlined">arrow_upward</span> : <span className="material-symbols-outlined">arrow_downward</span> : null}
                </div>
              </div>
            ))}
          </div>
          <div className="team__table_body">
            {teamMembers.map((member, i) => (
              <TeamRow 
                key={i} 
                name={member.name} 
                email={member.email} 
                access={member.access} 
                owner={!!member.owner} 
                image={member.image} 
                user={user}
                isTeamAdmin={isTeamAdmin()}
                status={member.status}
                statusTranslated={member.statusTranslated}
                onDeleteFromTeam={handleDeleteUserFromTeam}
                onResendInvitation={handleResendInvitation}
              />
            ))}
          </div>
          { Boolean(selectedTeam.lawstudio_api_key) && <div className="team__ls-credentials">
            <h5>{t("team.api_credentials_title")} <span className="note">{ t("team.api_credentials_note") }</span></h5>
            <p><span className="title">{t("team.api_key_label")}&nbsp;</span><span className={`value ${apiCredentialsVisible ? 'visible' : ''}`}>{ apiCredentialsVisible ? selectedTeam.lawstudio_api_key : "********" }</span></p>
            <p><span className="title">{t("team.api_secret_label")}&nbsp;</span><span className={`value ${apiCredentialsVisible ? 'visible' : ''}`}>{ apiCredentialsVisible ? selectedTeam.lawstudio_secret: "********" }</span></p>
            <button className="icon-btn show-button" onClick={() => { setApiCredentialsVisible(!apiCredentialsVisible) }}>
              { apiCredentialsVisible ? <span className="material-symbols-outlined">visibility_off</span> : <span className="material-symbols-outlined">visibility</span> }
            </button>
          </div> }
        </div>
      </div>
    );
  }

  return (
    <SettingsLayout title={t('team.title')}>
      <div className="settings-pages-wrapper">
        {user && Object.keys(user).length > 0 && Object.keys(activeTeamMember).length > 0
          ? activeTeamMember.status === 'pending' 
            ? renderJoinTeamContent() 
            : renderContent() 
          : <div className="loader-v2"></div>
        }

      {showDeleteTeamAlert && <Alert 
        onClose={() => setShowDeleteTeamAlert(false)} 
        onSubmit={handleTeamDelete}
        text={t('alert.delete_team')}
        deleteAlert
      />}

      {isTeamAdmin() && showInviteUsersModal && 
        <InviteUsersModal onClose={() => setShowInviteUsersModal(false)} />
      }

      {showRenameTeamPopup && <RenameTeamModal onClose={() => setShowRenameTeamPopup(false)} team={selectedTeam} rename={handleRenameTeam} />}
      </div>
    </SettingsLayout>
  )
}

const TeamRow = ({ name, email, access, owner, image, user, isTeamAdmin, status, statusTranslated, onDeleteFromTeam, onResendInvitation }) => {
  const { t } = useContext(GlobalContext)
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteFromTeamAlert, setShowDeleteFromTeamAlert] = useState(false);
  const [showResendInvitationAlert, setShowResendInvitationAlert] = useState(false);

  // Resend invitation click handler
  const deleteFromTeamClickHandler = async (email) => {
    await onDeleteFromTeam(email)
    setShowDeleteFromTeamAlert(false)
  }

  // Resend invitation click handler
  const resendInvitationClickHandler = async (email) => {
    await onResendInvitation(email)
    setShowResendInvitationAlert(false)
  }

  return (
    <div className="row">
      <div className="col col-1">
        <div className="user">
          {image 
            ? <div className="user__img" style={{ backgroundImage: `url(${image})`}}></div>
            : <LetterCircle data={name?.split(' ')[0] || email} hideTooltip />
          }
          <div className="user__info">
            <h4>{name} {email === user.email && `(${t('team.you_label')})`} </h4>
            <p>{email}</p>
          </div>
        </div>
      </div>
      <div className="col col-2">{access}</div>
      <div className="col col-3">{statusTranslated}</div>
      <div className="col col-4">
        {!owner && isTeamAdmin && <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <div className="wrapper">
            <span className="material-symbols-outlined" onClick={() => setShowDropdown(!showDropdown)}>more_horiz</span>
            {showDropdown && <ul className="dropdown-el default">
              {status === 'pending' && <li onClick={() => setShowResendInvitationAlert(true)}>{t('team.resend_invitation')}</li>}
              <li onClick={() => setShowDeleteFromTeamAlert(true)} className="delete">{t('general.delete')}</li>
            </ul>}
          </div>
        </ClickAwayListener>}
      </div>
      {showDeleteFromTeamAlert && <Alert 
        onClose={() => setShowDeleteFromTeamAlert(false)} 
        text={t('alert.delete_user_from_team')}
        onSubmit={() => deleteFromTeamClickHandler(email)}
        deleteAlert
      />}
      {showResendInvitationAlert && <Alert 
        onClose={() => setShowResendInvitationAlert(false)} 
        text={t('alert.resend_invitation')}
        onSubmit={() => resendInvitationClickHandler(email)}
      />}
    </div>
  )
}

export default Team 
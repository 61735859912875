import { useContext } from 'react'

import { SidePanel } from '../../new_ui'
import { ConstantsContext, GlobalContext } from '../../../context'
import { useSingleSignatureActions } from '../../../hooks'

const SignatureStatusSidePanel = ({ show, onClose, afterClose, title, signature, loading }) => {
  const { SIGNATURE_STATUS_PROVIDERS } = useContext(ConstantsContext)
  const { t } = useContext(GlobalContext)
  const { resendSigningInvitation } = useSingleSignatureActions()

  // On resend invitation
  const handleResendInvitation = async (user, index) => {
    const userFound = signature.recipients.find(u => u.email === user.email)
    let roleId = ''
    let packageId = signature.package_id
    if(userFound && userFound.roleId) {
      roleId = userFound.roleId 
    }else {
      roleId = `Signer${index+1}`
    }
    await resendSigningInvitation({ roleId, packageId, signature, userEmail: user.email })
  }

  return (
    <SidePanel 
      show={show} 
      onClose={onClose} 
      afterClose={afterClose} 
      title={title}
      icon={<span className="material-symbols-outlined">circle</span>}
    >
      {signature && !loading ? 
        (
          <>
            <p className="text-style-3">{signature.title}</p>
            <ul>
              {signature.users?.map((user, i) => {
                let status = ''
                if(user.status === 'COMPLETED') {
                  status = 'validated'
                }else if(user.status === 'SIGNING_PENDING') {
                  status = 'to_validate'
                }else {
                  status = 'draft'
                }

                return (
                  <li key={i}>
                    <span>{user.email}</span>
                    {user.status === 'SIGNING_PENDING' && (
                      <button className="btn btn--light-green btn--xs" onClick={() => handleResendInvitation(user, i)}>{t('team.resend_invitation')}</button>
                    )}
                    <div className={`status-v2 ${status}`}>{SIGNATURE_STATUS_PROVIDERS[user.status]}</div>
                  </li>
                )}
              )}
            </ul>
          </>
        ) : (
          <div className="loader-wrapper loader-wrapper--no-padding">
            <div className="loader-v2"></div>
          </div>
        )}
    </SidePanel>
  )
}

export default SignatureStatusSidePanel
import React, { createContext, useReducer } from 'react'

import contactGroupsReducer from './contactGroupsReducer'
import { GET_CONTACT_GROUPS, SET_CONTACT_GROUPS, RESET_STATE } from '../types'
import { fetch_contact_groups, create_contact_group, edit_contact_group, delete_contact_group } from '../../services/contact_groups'
import { sortArrayOfObjects } from '../../utils'

export const ContactGroupsContext = createContext()

const ContactGroupState = ({ children }) => {
  const initialState = {
    contactGroups: [],
    contactGroupsFetched: false,
  }
  const [state, dispatch] = useReducer(contactGroupsReducer, initialState)

  // Fetch groups
  const fetchContactGroups = async (teamId) => {
    try {
      const res = await fetch_contact_groups(teamId)
      const arr = []
      for(let id in res) {
        const group = {...res[id]}
        group.id = id
        arr.push(group)
      }
      dispatch({
        type: GET_CONTACT_GROUPS,
        payload: sortArrayOfObjects(arr, 'name', 'desc'),
      })
    }catch(err) {
      console.log('CONTACTS GROUP FETCH err', err)
    }
  }

  // Set groups
  const setContactGroups = (arr) => {
    dispatch({
      type: SET_CONTACT_GROUPS,
      payload: arr
    })
  }

  // Create group 
  const createContactGroup = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const id = await create_contact_group(data)
      let group = {...data, id}
      let newArr = [...state.contactGroups]
      newArr.push(group)
      setContactGroups(sortArrayOfObjects(newArr, 'name', 'desc'))
      onSuccess(id)
    }catch(err) {
      console.log('CREATE CONTACT GROUP err', err)
      onError(err)
    }
  }

  // Update group 
  const updateContactGroup = async (data, id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const groups = [...state.contactGroups]
      await edit_contact_group(id, data)
      const toUpdate = groups.find(g => g.id === id)
      const updatedObj = {
        ...toUpdate,
        ...data
      }
      const updatedArr = [...groups].map(g => g.id === id ? updatedObj : g)
      setContactGroups(sortArrayOfObjects(updatedArr, 'name', 'desc'))
      onSuccess()
    }catch(err) {
      console.log('UPDATE CONTACT GROUP err', err)
      onError(err)
    }
  }

  // Delete group
  const deleteContactGroup = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      const groups = [...state.contactGroups]
      await delete_contact_group(id)
      const updatedArr = [...groups].filter(g => g.id !== id)
      setContactGroups(updatedArr)
      onSuccess()
    } catch (err) {
      console.log('DELETE CONTACT GROUP err', err)
      onError(err)
    }
  }

  // Reset state
  const resetState = (mode = '') => {
    dispatch({
      type: RESET_STATE,
      // payload: mode === 'team-add' ? {...initialState, contactGroupsFetched: true } : initialState,
      payload: initialState
    })
  }

  return(
    <ContactGroupsContext.Provider value={{
      contactGroups: state.contactGroups,
      contactGroupsFetched: state.contactGroupsFetched,
      fetchContactGroups,
      createContactGroup,
      deleteContactGroup,
      updateContactGroup,
      resetContactGroupsState: resetState,
    }}>
      {children}
    </ContactGroupsContext.Provider>
  )
}

export default ContactGroupState
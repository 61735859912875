import { useContext, useEffect, useState } from 'react'
import { DndContext, MouseSensor, TouchSensor, DragOverlay, useSensor, useSensors, rectIntersection } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { LinearProgress } from '@material-ui/core'

import { WidgetInfoCalendar, WidgetInfoEuro, WidgetInfoTask, WidgetInfoTime } from '../assets/icon_components'
import { MainLayout } from '../layouts'
import { WidgetsContainer, DraggedWidget, CustomizeSidePanel } from '../components/misc'
import { GlobalContext, DocumentsContext, TaskContext, AlertContext, TagContext, TeamContext, UserContext } from '../context'
import { convertMsToHM } from '../utils'
import { useWidgetActions } from '../hooks'
import { update_user } from '../services/firestore'

const Dashboard = () => {
  const { t } = useContext(GlobalContext)
  const { documentsLoaded, fetchDocs, templatesLoaded, getTemplates, totalAmount, totalEditTime } = useContext(DocumentsContext)
  const { tasksFetched, fetchTasks, tasks } = useContext(TaskContext)
  const { alertsFetched, fetchAlerts, alerts } = useContext(AlertContext)
  const { tagsFetched, fetchTags } = useContext(TagContext)
  const { selectedTeam, teamChanged, setTeamChanged } = useContext(TeamContext)
  const { widgets, setWidgets, user, setUser, widgetsTimeout, widgetsInfo, customizations } = useContext(UserContext)
  const { didWidgetsOrderChanged } = useWidgetActions()
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [draggedItem, setDraggedItem] = useState(null)
  const [itemsObj] = useState([
    { id: 'item-contracts', view: 'contracts', title: 'dashboard.number_of_documents' },
    { id: 'item-alerts', view: 'alerts', title: 'dashboard.alerts' },
    { id: 'item-tasks', view: 'tasks', title: 'dashboard.tasks' },
    { id: 'item-recent', view: 'recent', title: 'dashboard.recent_documents' },
    { id: 'item-private_notepad', view: 'private-notepad', title: 'dashboard.private_notepad' },
    { id: 'item-people', view: 'people', title: 'dashboard.people' },
  ])
  const [isSorting, setIsSorting] = useState(false)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
    }
  }, [teamChanged, setTeamChanged])

  // Check if documents, tags, alerts & tasks are fetched, if not fetch them
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!documentsLoaded) {
        fetchDocs(teamId)
      }
      if(!tagsFetched) {
        // fetchTags(teamId)
      }
      if(!alertsFetched) {
        fetchAlerts(teamId)
      }
      if(!tasksFetched) {
        fetchTasks(teamId)
      }
      if(!templatesLoaded) {
        getTemplates(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [documentsLoaded, tagsFetched, tasksFetched, alertsFetched, selectedTeam, fetchingCollections, fetchDocs, fetchAlerts, fetchTags, fetchTasks, templatesLoaded, getTemplates])

  // Drag start
  const handleDragStart = (e) => {
    const id = e.active.id
    setActiveId(id)
    setDraggedItem(itemsObj.find(item => item.id === id))
    setIsSorting(true)
  }

  // Drag over
  const handleDragOver = (event) => {
    if(widgetsTimeout.current) {
      clearTimeout(widgetsTimeout.current)
    }

    const { active, over, delta } = event
    if(!over) return 
    
    const { id } = active
    const { id: overId } = over

    // Find the containers
    const activeContainer = findContainer(id)
    const overContainer = findContainer(overId)

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return
    }

    if(over.id.includes('item-') && overContainer === 'main') {
      const items = widgets.main 
      const sidePanelItems = widgets.side_panel
      const activeIndex = sidePanelItems.indexOf(active.id)
      const overIndex = items.indexOf(over.id)
      setWidgets(prev => {
        return {
          side_panel: prev.side_panel.filter((item) => item !== active.id),
          main: [
            ...prev.main.slice(0, overIndex),
            widgets.side_panel[activeIndex],
            ...prev.main.slice(overIndex, prev.main.length)
          ]
        }
      })
      return 
    }

    if(over.id.includes('item-')) return

    setWidgets((prev) => {
      const activeItems = prev[activeContainer]
      const overItems = prev[overContainer]

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id)
      const overIndex = overItems.indexOf(overId)

      let newIndex
      if (overId in prev) {
        newIndex = 0
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          delta.y > over.rect.top + over.rect.height

        const modifier = isBelowLastItem ? 1 : 0

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          widgets[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length)
        ]
      }
    })
  }

  // Find container
  const findContainer = (id) => {
    if (id in widgets) {
      return id
    }

    return Object.keys(widgets).find((key) => widgets[key].includes(id))
  }

  // Drag end
  const handleDragEnd = (event) => {
    const { active, over } = event
    if(!active || !over) {
      setActiveId(null)
      setDraggedItem(null)
      setIsSorting(false)
      return
    }
    const { id } = active
    const { id: overId } = over

    const activeContainer = findContainer(id)
    const overContainer = findContainer(overId)

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      setActiveId(null)
      setDraggedItem(null)
      setIsSorting(false)
      return
    }

    const activeIndex = widgets[activeContainer].indexOf(active.id)
    const overIndex = widgets[overContainer].indexOf(overId)

    let newWidgets = {...widgets}

    if (activeIndex !== overIndex) {
      newWidgets = {
        ...widgets,
        [overContainer]: arrayMove(widgets[overContainer], activeIndex, overIndex)
      }
    }
    setWidgets(newWidgets)

    if(widgetsTimeout.current) {
      clearTimeout(widgetsTimeout.current)
    }

    widgetsTimeout.current = setTimeout(() => {
      if(didWidgetsOrderChanged(newWidgets)) {
        let data = {
          customizations: {
            ...customizations,
            widgets: newWidgets,
            widgets_info: widgetsInfo
          }
        }
        
        update_user(data, user.id)
        setUser({...user, ...data})
      }
    }, 3000)

    setActiveId(null)
    setDraggedItem(null)
    setIsSorting(false)
  }

  // Drag cancel
  const handleDragCancel = () => {
    setActiveId(null)
    setDraggedItem(null)
  }

  // On open side panel
  const handleOpenSidePanel = () => {
    setShowSidePanel(true)
  }

  // On close side panel
  const handleCloseSidePanel = () => {
    setShowSidePanel(false)
  }

  return (
    <MainLayout>
      <div className="dashboard-page-v2">
        <div className="dashboard-page-v2__top">
          <div className="customize-btn-wrapper">
            <button className="btn btn--outline btn--with-icon-left btn--small customize-btn" onClick={handleOpenSidePanel}>
              <span className="material-symbols-outlined">dashboard_customize</span> {t('general.customize')}
            </button>
          </div>
          <div className="widgets-info">
            <div className="widgets-info__box widgets-info__box--1">
              <h6 className="heading-6">{t('dashboard.documents_values')}</h6>
              {(!documentsLoaded) 
                ? <LinearProgress className="widgets-info__box_placeholder" />
                : <p>{totalAmount.toLocaleString('en-US')}€</p>
              }
              <div className="widgets-info__box_icon">
                <WidgetInfoEuro />
              </div>
            </div>
            
            <div className="widgets-info__box widgets-info__box--2">
              <h6 className="heading-6">{t('general.total_time')}</h6>
              {(!documentsLoaded) 
                ? <LinearProgress className="widgets-info__box_placeholder" />
                : <p>{convertMsToHM(totalEditTime)}</p>
              }
              <div className="widgets-info__box_icon">
                <WidgetInfoTime />
              </div>
            </div>

            <div className="widgets-info__box widgets-info__box--3">
              <h6 className="heading-6">{t('dashboard.tasks')}</h6>
              {(!tasksFetched) 
                ? <LinearProgress className="widgets-info__box_placeholder" />
                : <p>{tasks.length}</p>
              }
              <div className="widgets-info__box_icon">
                <WidgetInfoTask />
              </div>
            </div>

            <div className="widgets-info__box widgets-info__box--4">
              <h6 className="heading-6">{t('dashboard.alerts')}</h6>
              {(!alertsFetched) 
                ? <LinearProgress className="widgets-info__box_placeholder" />
                : <p>{alerts.length}</p>
              }
              <div className="widgets-info__box_icon">
                <WidgetInfoCalendar />
              </div>
            </div>
          </div>
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={rectIntersection}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
        >
          <WidgetsContainer activeId={activeId} isSorting={isSorting} itemsObj={itemsObj} />
          <CustomizeSidePanel activeId={activeId} itemsObj={itemsObj} onClose={handleCloseSidePanel} show={showSidePanel} />

          <DragOverlay 
            dropAnimation={null} 
            style={{height: 100, width: '100%'}}
          >
            {activeId 
              ? <DraggedWidget item={draggedItem} />
              : null
            }
          </DragOverlay>
        </DndContext>
      </div>
    </MainLayout>
  )
}

export default Dashboard 
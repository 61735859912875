import CustomTooltip from "../UI/CustomTooltip"

const LetterCircle = ({ data, size, hideTooltip, tooltipIcon, className, useOnlyFirstLetter = false }) => {
  if(!data || typeof data !== 'string' ) return null

  let cssClass = 'letter-circle-v2'
  let letter = ''
  let firstLetter = ''
  let splitData = data.trim().split(' ')
  
  if(splitData.length === 1 || useOnlyFirstLetter) {
    letter = splitData[0].slice(0,1)
    firstLetter = splitData[0].slice(0,1)
  }else if(splitData.length > 1) {
    let letter1 = splitData[0].slice(0,1)
    firstLetter = splitData[0].slice(0,1)
    let letter2 = splitData[1].slice(0,1)
    letter = letter1 + letter2 
  }

  const style = {}
  if(size) {
    style.width = size
    style.height = size
  }

  const set1 = ['m', 't', 'r']
  const set2 = ['c', 'o', 'y']
  const set3 = ['g', 'k', 'f']
  const set4 = ['d', 'w', 'j']
  const set5 = ['v', 'e', 'b']
  const set6 = ['s', 'z', 'l']
  const set7 = ['a', 'h', 'i']
  const set8 = ['n', 'p']
  const set9 = ['u', 'x']

  if(set1.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--1'
  }else if(set2.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--2'
  }else if(set3.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--3'
  }else if(set4.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--4'
  }else if(set5.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--5'
  }else if(set6.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--6'
  }else if(set7.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--7'
  }else if(set8.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--8'
  }else if(set9.includes(firstLetter.toLowerCase())) {
    cssClass += ' letter-circle-v2--9'
  }

  if(className) {
    cssClass += ` ${className}`
  }

  const element = (
    <div className={cssClass} style={style}>
      {letter.trim()}
    </div>
  )

  if(hideTooltip) {
    return element
  }

  return(
    <CustomTooltip content={tooltipIcon ? <>{data} {tooltipIcon}</> : data} spaceSm>
      { element }
    </CustomTooltip>
  )
}

export default LetterCircle
import { baseUrl, get_request, post_request, patch_request, delete_request } from './firestore'

// fetch packages
const fetch_packages = async (params) => {
  let url = `${baseUrl}/onespan_packages`
  return await get_request(url, null, params)
}

// fetch single package
const fetch_single_package = async (id) => {
  return await get_request(`${baseUrl}/onespan_packages/${id}`)
}

// create package
const create_package = async (data) => {
  return await post_request(`${baseUrl}/onespan_packages`, data)
}

// get pdf document as base64 data
const get_onespan_pdf_doc_as_base64_data = async (package_id, document_id) => {
  return await get_request(`${baseUrl}/onespan_packages/pdf_base64/${package_id}/${document_id}`)
}

// get package status
const get_package_status = async (package_id) => {
  return await get_request(`${baseUrl}/onespan_packages/${package_id}/status`)
}

// get package audit
const get_package_audit = async (package_id) => {
  return await get_request(`${baseUrl}/onespan_packages/${package_id}/audit`)
}

// resend signing invitation
const resend_signing_invitation = async (data) => {
  return await post_request(`${baseUrl}/onespan_resend_invitation`, data)
}

export {
  fetch_packages,
  fetch_single_package,
  create_package,
  get_onespan_pdf_doc_as_base64_data,
  get_package_status,
  get_package_audit,
  resend_signing_invitation,
}
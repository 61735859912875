import React, { memo, useContext } from 'react'

import Switch from '../../UI/Switch'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import { GlobalContext } from '../../../context'

const SaveTemplateSection = ({ showSaveTemplate, setShowSaveTemplate, signatureTemplateName, signatureTemplateNameError, setSignatureTemplateName, setSignatureTemplateNameError, onSaveSignatureTemplate }) => {
  const { t } = useContext(GlobalContext)
  
  return (
    <div className="signature-modal-inner__section signature-modal__template">
      <p className="form__subtitle form__subtitle--2">{t('signature_templates.save_title')}</p>
      <div className="checkbox-wrapper">
        <Switch checked={showSaveTemplate} onChange={(e) => setShowSaveTemplate(e.target.checked)} />
      </div>
      {showSaveTemplate && <div className="signature-modal__template_save">
        <Input 
          value={signatureTemplateName}
          onChange={(e) => {
            if(signatureTemplateNameError) setSignatureTemplateNameError('')
            setSignatureTemplateName(e.target.value)
          }}
          placeholder={t('signature_templates.template_name')}
          formEl 
          error={signatureTemplateNameError}
        />
        <Button type="button" text={t('general.save')} primaryLight onButtonClick={onSaveSignatureTemplate} />
      </div>}
    </div>
  );
}

export default memo(SaveTemplateSection)
import { GET_ALERTS, SET_ALERTS, RESET_STATE } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case GET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
        alertsFetched: true,
      }
    case SET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      }
    case RESET_STATE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
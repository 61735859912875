import { useState, useContext, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { MainLayout } from '../layouts'
import { DocumentsSidebar, DocumentsView } from '../components/misc'
import { GlobalContext, DocumentsContext, DocumentsFoldersContext, TeamContext, ConstantsContext, TagContext } from '../context'
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, saveTeamSortingToLS, hasSufficientMembership, isCookieAccepted } from '../utils'

const Documents = () => {
  const { t } = useContext(GlobalContext)
  const { setFilteredDocuments, filteredDocuments, documentsLoaded, fetchDocs, templatesLoaded, getTemplates, documents, docsSelectedFilters, currentFilter, setCurrentFilter, docsSort, setDocsSort, getTemplateById } = useContext(DocumentsContext)
  const { docFoldersFetched, fetchDocFolders, docFolders, docFolderBreadcrumbs } = useContext(DocumentsFoldersContext)
  const { selectedTeam, getUserNameById, teamChanged, setTeamChanged } = useContext(TeamContext)
  const { STATUS_LABELS } = useContext(ConstantsContext)
  const { tagsFetched, fetchTags } = useContext(TagContext)
  const [fetchingCollections, setFetchingCollections] = useState(false)
  const [currentFolder, setCurrentFolder] = useState(null)
  const [folderCreated, setFolderCreated] = useState(false)
  const [resetFolders, setResetFolders] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [filteredFolders, setFilteredFolders] = useState([])
  const [paginatedData, setPaginatedData] = useState([])
  const [ready, setReady] = useState(false)
  const [activeDocs, setActiveDocs] = useState([])
  const [archivedDocs, setArchivedDocs] = useState([])
  const [deletedDocs, setDeletedDocs] = useState([])
  const [favoritesDocs, setFavoritesDocs] = useState([])
  const [currentDocs, setCurrentDocs] = useState([])
  const [mainTitle, setMainTitle] = useState(t('dashboard.mailbox'))
  const location = useLocation()

  // On team change set fetchingCollections to false to fetch collections again
  useEffect(() => {
    if(teamChanged) {
      setFetchingCollections(false)
      setTeamChanged(false)
      refreshCollection()
    }
    // eslint-disable-next-line
  }, [teamChanged, setTeamChanged])

  // Fetch documents and folders if they are not fetched yet
  useEffect(() => {
    const fetchCollections = async (teamId) => {
      if(!documentsLoaded && !location.state?.dont_fetch_documents) {
        // console.log('fetch documents')
        fetchDocs(teamId)
      }
      if(!docFoldersFetched) {
        // console.log('fetch doc folders')
        fetchDocFolders(teamId)
      }
      // needed because of Modele column and also when doc is clicked it first checks if doc has a template, and if it does not find a template by id it will show an alert popup and it will not redirect to a document detail page
      if(!templatesLoaded) { 
        // console.log('fetch templates')
        getTemplates(teamId)
      }
      if(!tagsFetched) {
        fetchTags(teamId)
      }
    }
    if(selectedTeam && !fetchingCollections) {
      setFetchingCollections(true)
      fetchCollections(selectedTeam.id)
    }
  }, [selectedTeam, fetchingCollections, documentsLoaded, fetchDocs, docFoldersFetched, fetchDocFolders, templatesLoaded, getTemplates, location, tagsFetched, fetchTags])

  // Set filtered documents
  useEffect(() => {
    setFilteredDocuments(documents)
    // eslint-disable-next-line
  }, [documents])

  // Set filtered folders
  useEffect(() => {
    if(docFoldersFetched) {
      setFilteredFolders(docFolders)
    }
  }, [docFoldersFetched, docFolders])

  // Set documents
  const setupDocuments = useCallback(async () => {
    let docs = {...filteredDocuments}
    let active = {}
    let archived = {}
    let deleted = {}
    let favorite = {}
    
    for(let key in docs) {
      if(!docs[key].archived && !docs[key].deleted) {
        active[key] = {...docs[key], status_label: STATUS_LABELS[docs[key].status]}
        // if(hasSufficientMembership(selectedTeam?.membership, 'beta_partner')) {
          active[key].user = getUserNameById(active[key].owner)
          active[key].sort_template = getTemplateById(docs[key].template) ? 'yes' : 'no'
        // }
      }else if(docs[key].archived) {
        archived[key] = docs[key]
        // if(hasSufficientMembership(selectedTeam?.membership, 'beta_partner')) {
          archived[key].user = getUserNameById(archived[key].owner)
          archived[key].sort_template = getTemplateById(docs[key].template) ? 'yes' : 'no'
        // }
      } else if(docs[key].deleted) {
        deleted[key] = docs[key]
        // if(hasSufficientMembership(selectedTeam?.membership, 'beta_partner')) {
          deleted[key].user = getUserNameById(deleted[key].owner)
          deleted[key].sort_template = getTemplateById(docs[key].template) ? 'yes' : 'no'
        // }
      } 
      if(docs[key].favorite && !docs[key].deleted && !docs[key].archived) {
        favorite[key] = docs[key]
        // if(hasSufficientMembership(selectedTeam?.membership, 'premium')) {
          favorite[key].user = getUserNameById(favorite[key].owner)
          favorite[key].sort_template = getTemplateById(docs[key].template) ? 'yes' : 'no'
        // }
      }
    }

    let activeDcmnts = await sortedArrayFromObject(active, sorterWithPathAndOrder(docsSort.value, docsSort.order))
    let archivedDcmnts = await sortedArrayFromObject(archived, sorterWithPathAndOrder(docsSort.value, docsSort.order))
    let deletedDcmnts = await sortedArrayFromObject(deleted, sorterWithPathAndOrder(docsSort.value, docsSort.order))
    let favoriteDcmnts = await sortedArrayFromObject(favorite, sorterWithPathAndOrder(docsSort.value, docsSort.order))
    
    let filteredFWithUser = filteredFolders.map(f => ({ ...f, user: getUserNameById(f.owner) }))
    setActiveDocs([...filteredFWithUser, ...activeDcmnts])
    let current = [...filteredFWithUser, ...activeDcmnts].filter((item) => {
      if(item.docFolder) {
        return item.parentFolder === undefined || item.parentFolder === null 
      }else {
        return !item.folderId || (item.folderId && item.folderId.length === 0)
      }
    })
    setCurrentDocs(current)
    setArchivedDocs(archivedDcmnts)
    setDeletedDocs(deletedDcmnts)
    setFavoritesDocs(favoriteDcmnts)
    setReady(true)
  }, [docsSort, STATUS_LABELS, filteredDocuments, filteredFolders, getUserNameById, getTemplateById])

  useEffect(() => {
    if(documentsLoaded && templatesLoaded && docFoldersFetched && !ready) {
      setupDocuments()
    }
  }, [documents, filteredDocuments, documentsLoaded, docFoldersFetched, docFolders, filteredFolders, currentFilter, docsSort, ready, templatesLoaded, setupDocuments])

  // Refresh collection
  const refreshCollection = useCallback(() => {
    setReady(false)
    setAllChecked(false)
    setSelectedDocuments([])
  }, [])

  // Filter
  const handleFilter = useCallback(() => {
    let filtered = []
    if(currentFilter === 'all') {
      filtered = [...activeDocs]
    }else if(currentFilter === 'archived') {
      filtered = [...archivedDocs]
    }else if(currentFilter === 'deleted') {
      filtered = [...deletedDocs]
    }else if(currentFilter === 'favorites') {
      filtered = [...favoritesDocs]
    }

    for(let key in docsSelectedFilters) {
      if(key === 'folder' && currentFilter === 'all' && docsSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => {
          if(d.folderRow) {
            return d.parentFolder === docsSelectedFilters[key]
          }else {
            return d.folderId && d.folderId.includes(docsSelectedFilters[key])
          }
        })
      }else if(key === 'folder' && currentFilter === 'all' && docsSelectedFilters[key] === 'all'){
        filtered = filtered.filter(d => {
          if(d.folderRow) {
            return d.parentFolder === undefined || d.parentFolder === null
          }else {
            return !d.folderId || (d.folderId && d.folderId.length === 0)
          }
        })
      }
      if(key === 'search' && docsSelectedFilters[key] !== '') {
        filtered = filtered.filter(d => d.name.toLowerCase().includes(docsSelectedFilters[key].trim().toLowerCase()))
      }
    }
    if(currentFilter === 'all') {
      let folders = filtered.filter(item => item.folderRow)
      let docs = filtered.filter(item => !item.folderRow)
      let sortedFolders = sortArrayOfObjects(folders, docsSort.value, docsSort.order)
      let sortedDocs = sortArrayOfObjects(docs, docsSort.value, docsSort.order)
      setCurrentDocs([...sortedFolders, ...sortedDocs])
    }else {
      setCurrentDocs(sortArrayOfObjects(filtered, docsSort.value, docsSort.order))
    }
  }, [activeDocs, docsSelectedFilters, archivedDocs, deletedDocs, currentFilter, docsSort, favoritesDocs])

  // Filter documents when docsSelectedFilters or docsSort change
  useEffect(() => {
    handleFilter()
  }, [handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    let currentOrder = docsSort.order === 'desc' ? 'asc' : 'desc'
    setDocsSort({ value: sort_by, order: currentOrder })
    if(isCookieAccepted()) {
      saveTeamSortingToLS(selectedTeam?.id, sort_by, currentOrder, 'documents')
    }
  }

  // On change current
  const handleChangeCurrentFilter = async (value, label) => {
    setCurrentFilter(value === 'active' ? 'all' : value)
    setSelectedDocuments([])
    setMainTitle(label)
  }

  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if(allChecked) {
      setSelectedDocuments([])
    }else {
      setSelectedDocuments(paginatedData)
    }
  }
  
  return (
    <MainLayout 
      title={mainTitle}
      page="documents"
      refreshCollection={refreshCollection}
      showSidebarTrigger={true}
      sidebar={
        <DocumentsSidebar 
          currentFolder={currentFolder} 
          setCurrentFolder={setCurrentFolder} 
          setFolderCreated={setFolderCreated} 
          resetFolders={resetFolders}
          setResetFolders={setResetFolders}
          onFilter={handleChangeCurrentFilter}
          setSelectedDocuments={setSelectedDocuments}
          refreshDocs={refreshCollection}
        />
      }
    >
      <DocumentsView 
        selectedDocuments={selectedDocuments} 
        onSetSelectedDocuments={setSelectedDocuments} 
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        onAllCheckedChange={handleAllChecked}
        filteredDocs={currentDocs}
        filteredFolders={filteredFolders}
        onSort={handleSort}
        paginatedData={paginatedData}
        setPaginatedData={setPaginatedData}
        refreshDocs={refreshCollection}
        ready={ready}
      />
    </MainLayout>
  )
}

export default Documents
import { useState, useContext, useEffect, useRef } from 'react'

import { Modal, Input, MemberSelect, DocumentSelect, DateInput } from '../../new_ui'
import { GlobalContext, DocumentsContext } from '../../../context'
import { useTaskActions } from '../../../hooks'

const EditTaskModal = ({ onClose, taskToEdit, refreshTasks }) => {
  const { t } = useContext(GlobalContext)
  const { getDocumentById, documents } = useContext(DocumentsContext)
  const { updateSingleTask } = useTaskActions()
  const [taskName, setTaskName] = useState('')
  const [selectedDocument, setSelectedDocument] = useState('')
  const [taskAssignedTo, setTaskAssignedTo] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const timeout = useRef()

  // Set task variables
  useEffect(() => {
    if(taskToEdit.name) {
      setTaskName(taskToEdit.name)
    }
    if(taskToEdit.assigned_to) {
      setTaskAssignedTo(taskToEdit.assigned_to)
    }
    if(taskToEdit.relatedDocument && getDocumentById(taskToEdit.relatedDocument)) {
      const doc = getDocumentById(taskToEdit.relatedDocument)
      if(doc && doc.id) {
        setSelectedDocument(doc.id)
      }
    }
    if(taskToEdit.due_date) {
      setSelectedDate(new Date(taskToEdit.due_date))
    }
    // eslint-disable-next-line
  }, [taskToEdit])

  // On name change
  const handleNameChange = async (e) => {
    const name = e.target.value
    setTaskName(name)
    if(timeout.current) {
      clearTimeout(timeout.current)
    }
    if(name.trim() === '') return

    timeout.current = setTimeout(async () => {
      let data = { name: name.trim() }
      await updateSingleTask({ data, task: taskToEdit, doc: documents[taskToEdit.relatedDocument] || {} })
      refreshTasks && refreshTasks()
    }, 2000)
  }

  // On member change
  const handleMemberChange = (value) => {
    setTaskAssignedTo(value)
    if(value === taskAssignedTo) return 

    if(timeout.current) {
      clearTimeout(timeout.current)
    }

    let data 
    if(taskAssignedTo === value) {
      data = { assigned_to: '' }
      setTaskAssignedTo('')
    }else {
      data = { assigned_to: value }
    }

    if(!data) return 

    timeout.current = setTimeout(async () => {
      await updateSingleTask({ data, task: taskToEdit, doc: documents[taskToEdit.relatedDocument] || {} })
      refreshTasks && refreshTasks()
    }, 2000)
  }

  // On document change
  const handleDocumentChange = (value) => {
    setSelectedDocument(value)
    if(value === selectedDocument) return 
    
    if(timeout.current) {
      clearTimeout(timeout.current)
    }
    
    let data = {
      relatedDocument: value !== '' ? value : null
    }
    timeout.current = setTimeout(async () => {
      await updateSingleTask({ data, task: taskToEdit, doc: documents[taskToEdit.relatedDocument] || {}, removeFromPrev: true })
      refreshTasks && refreshTasks()
    }, 2000)
  }

  // On date change
  const handleDateChange = (date) => {
    setSelectedDate(date)
    
    if(timeout.current) {
      clearTimeout(timeout.current)
    }

    let data = { due_date: date !== null ? date : '' }

    timeout.current = setTimeout(async () => {
      await updateSingleTask({ data, task: taskToEdit, doc: documents[taskToEdit.relatedDocument] || {} })
      refreshTasks && refreshTasks()
    }, 2000)
  }

  return (
    <Modal 
      className="centered-head"
      onClose={onClose}
      hideFooter={true}
      customHeader={<Input value={taskName} onChange={handleNameChange} className="transparent no-mb" />}
    >
      <div className="create-task">
        <div className="create-task__field-group">
          <MemberSelect label={t('approvals.assigned_to')} onChange={handleMemberChange} value={taskAssignedTo} />
          <DateInput label={t('general.due_date')} onChange={handleDateChange} value={selectedDate} />
        </div>
        <div className="create-task__field">
          <DocumentSelect label={t('general.related_document')} onChange={handleDocumentChange} value={selectedDocument} />
        </div>
      </div>
    </Modal>
  )
}

export default EditTaskModal
import { GET_NOTARIES, SET_NOTARIES, RESET_STATE } from '../types'

const reducer = (state, action) => {
  switch(action.type) {
    case GET_NOTARIES:
      return {
        ...state,
        notaries: action.payload,
        notariesFetched: true,
      }
    case SET_NOTARIES:
      return {
        ...state,
        notaries: action.payload,
      }
    case RESET_STATE:
      return action.payload
    default:
      return state
  }
}

export default reducer
import { useContext, useCallback } from 'react'

import { GlobalContext, LoaderContext, NotificationContext, UserContext, TeamContext } from '../context'
import { update_user, upload_file, delete_file } from '../services/firestore'
import { isCookieAccepted } from '../utils'
import { setLanguage } from '../i18n'
import { requestUploadAddress } from '../services/functions'
import firebase from '../services/firebase'

const useUserActions = () => {
  const { t, validate, selectedLang, setSelectedLang } = useContext(GlobalContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { user, setUser } = useContext(UserContext)
  const { selectedTeam } = useContext(TeamContext)

  // Save user
  const saveUser = useCallback(async ({ formData, setErrors }) => {
    const newErrors = validate(formData, true)
    setErrors(newErrors)
    
    if(Object.keys(newErrors).length === 0) {
      const values = {
        first_name: formData.first_name.value.trim(),
        last_name: formData.last_name.value.trim(),
        job_position: formData.job_position.value.trim(),
        phone: formData.phone.value.trim(),
        mobile: formData.mobile.value.trim()
      }
      if(isValueChanged(values, user)) {
        setShowGlobalResponseLoader(true)
        try {
          await update_user(values, user.id)
          setUser({ ...user, ...values })
          setNotification({ msg: t('notification.profile_updated'), type: 'success' })
        } catch (err) {
          console.log(err)
          setNotification({ msg: t('notification.something_went_wrong'), type: 'success' })
        }
        setShowGlobalResponseLoader(false)
      }else {
        setNotification({ msg: t('notification.no_changes_made'), type: 'info' })
      }
    }
  }, [setNotification, setShowGlobalResponseLoader, setUser, user, t, validate])

  // Upload user image
  const uploadUserImage = useCallback(async (image) => {
    setShowGlobalResponseLoader(true)
    try {
      let paths = []
      // Delete prev image first
      if(user.image_path) {
        const splitted = user.image_path.split('.')
        const path = [...splitted].slice(0, splitted.length - 1).join('.')
        const format = splitted[splitted.length - 1]
        paths = [user.image_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
        setGlobalResponseLoaderText(t('general.deleting_prev_image'))
      }
      if(paths.length > 0) {
        for(let i = 0; i < paths.length; i++) {
          await delete_file(paths[i])
        }
      }
      setGlobalResponseLoaderText(t('general.uploading_image'))
      const { data, name, format, type } = image
      const imageName = `${name}-${Date.now()}`
      const res = await upload_file(data, imageName, format, type, `users/${user.id}`)
      const imageUrl = res.url
      const imagePath = `users/${user.id}/${imageName}.${format}`
      await update_user({ image_url: imageUrl, image_path: imagePath }, user.id)
      setUser({ ...user, image_url: imageUrl, image_path: imagePath })
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    } catch (err) {
      console.log(err)
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
    }
  }, [setGlobalResponseLoaderText, setNotification, t, setShowGlobalResponseLoader, setUser, user])

  // Remove user image
  const removeUserImage = useCallback(async ({ setImageUrl }) => {
    if(user.image_path) {
      const splitted = user.image_path.split('.')
      const path = [...splitted].slice(0, splitted.length - 1).join('.')
      const format = splitted[splitted.length - 1]
      const paths = [user.image_path, `${path}_128x128.${format}`, `${path}_512x512.${format}`]
      setGlobalResponseLoaderText(t('general.deleting_image'))
      setShowGlobalResponseLoader(true)
      try {
        for(let i = 0; i < paths.length; i++) {
          await delete_file(paths[i])
        }
        await update_user({ image_url: '', image_path: '' }, user.id)
        setUser({ ...user, image_url: '', image_path: '' })
        setImageUrl('')
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
      } catch (err) {
        console.log(err)
        setShowGlobalResponseLoader(false)
        setGlobalResponseLoaderText('')
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
      }
    }
  }, [setGlobalResponseLoaderText, setNotification, setShowGlobalResponseLoader, setUser, t, user])

  // On language change
  const updateLanguage = useCallback(async ({ lang }) => {
    let current = selectedLang
    if(lang === current) return 
    
    setShowGlobalResponseLoader(true)
    setSelectedLang(lang)
    if(isCookieAccepted()) {
      setLanguage(lang)
    }
    setUser({ ...user, selected_language: lang })
    await update_user({ selected_language: lang }, user.id)
    document.body.click()
    setShowGlobalResponseLoader(false)
  }, [selectedLang, setShowGlobalResponseLoader, setSelectedLang, setUser, user])

  // Link variable to user field
  const linkVariableToUserField = useCallback(async ({ fieldKey, selected, linkedVariables, setLinkedVariables }) => {
    let linkedVars = {...linkedVariables}
    if(selected !== t('contacts.select_variable')) {
      linkedVars[fieldKey] = selected
    }
    // console.log(linkedVars)
    await update_user({ profile_linked_vars: linkedVars }, user.id)
    setUser({ ...user, profile_linked_vars: linkedVars })
    setLinkedVariables(linkedVars)
    document.body.click()
  }, [setUser, user, t])

  // Remove variable from user field
  const removeVariableFromUserField = useCallback(async ({ fieldKey, linkedVariables, setLinkedVariables }) => {
    let linkedVars = {...linkedVariables}
    delete linkedVars[fieldKey]
    await update_user({ profile_linked_vars: linkedVars }, user.id)
    setUser({ ...user, profile_linked_vars: linkedVars })
    setLinkedVariables(linkedVars)
    document.body.click()
  }, [setUser, user])

  // Check user email address
  const checkUserEmailAddress = async ({ setUploadAddress }) => {
    if(!selectedTeam) {
      return
    }

    if(!user) {
      setShowGlobalResponseLoader(true) 
    } else if(user && (!user.upload_addresses_map || !user.upload_addresses_map[selectedTeam.id])) {
      setShowGlobalResponseLoader(true)
      let addressResponse = await requestUploadAddress(selectedTeam.id)
      console.log('addressResponse')
      console.log(addressResponse)
      if(addressResponse.upload_addresses_map) {
        setUploadAddress(addressResponse.upload_addresses_map[selectedTeam.id])
        setUser({
          ...user,
          upload_addresses_map: addressResponse.upload_addresses_map
        })
      } else {
        // display error
        console.log(addressResponse)
      }
      setShowGlobalResponseLoader(false)
    } else {
      setShowGlobalResponseLoader(false)
    }
  }

  // Change user password
  const changeUserPassword = async ({ formData, setErrors, setFormData, initialData }) => {
    const newErrors = validate(formData, true)
    setErrors(newErrors)

    if(Object.keys(newErrors).length === 0) {
      setShowGlobalResponseLoader(true)
      try {
        const user = firebase.auth().currentUser
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, formData.old_password.value.trim())
        await user.reauthenticateWithCredential(credential)
        await user.updatePassword(formData.new_password.value)
        setNotification({ msg: t('notification.password_updated'), type: 'success' })
        setFormData(initialData)
      } catch (err) {
        console.log(err)
        if(err.code === 'auth/wrong-password') {
          setNotification({ msg: t('notification.invalid_password'), type: 'danger' })
        }else if(err.code === 'auth/too-many-requests'){
          setNotification({ msg: t('notification.too_many_requests'), type: 'danger' })
        }else if(err.code === 'auth/weak-password') {
          setNotification({ msg: t('notification.weak_password'), type: 'danger' })
        }else {
          setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' })
        }
      }
      setShowGlobalResponseLoader(false)
    }
  }

  // Check if any value is changed
  const isValueChanged = (values, compare) => {
    let changed = false
    for(let i in values) {
      if(compare[i] && compare[i] !== values[i]) {
        changed = true
        break
      }else if(!compare[i] && values[i] !== ''){
        changed = true
        break
      }
    }
    return changed
  }

  return {
    saveUser,
    uploadUserImage,
    removeUserImage,
    updateLanguage,
    linkVariableToUserField,
    removeVariableFromUserField,
    checkUserEmailAddress,
    changeUserPassword,
  }
}

export default useUserActions
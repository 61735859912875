import { useState, useContext, useEffect, useCallback } from 'react'

import { Modal, Input, Select, Option } from '../../new_ui'
import { VariablesContext, GlobalContext } from '../../../context'
import { useSingleVariableActions } from '../../../hooks'

const CreateEditVariableModal = ({ onClose, mode = 'create', data, lastSelectedCategory, onCategorySelected, refreshVariables = () => {} }) => {
  const { t } = useContext(GlobalContext)
  const { varsCategories } = useContext(VariablesContext)
  const { createNewVariable, updateSingleVariable } = useSingleVariableActions()
  const [variableKey, setVariableKey] = useState('')
  const [tooltip, setTooltip] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [question, setQuestion] = useState('')
  const [categories, setCategories] = useState([
    ...[...varsCategories].map((c) => ({ label: c.display_name, value: c.id, active: c.id === lastSelectedCategory })),
  ])
  const [selectedDataType, setSelectedDataType] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(lastSelectedCategory || '')
  const [selectOptions, setSelectOptions] = useState([])

  // Populate fields if mode is edit
  useEffect(() => {
    if(mode === 'edit' && data) {
      setVariableKey(data.variable)
      setTooltip(data.tooltip)
      if(data.display_name) {
        setDisplayName(data.display_name)
      }
      if(data.question) {
        setQuestion(data.question)
      }
      setSelectedDataType(data.type)
      setCategories(prev => prev.map(c => c.value === data.category ? {...c, active: true} : {...c, active: false}))
      setSelectedCategory(data.category)
      if(data.options) {
        setSelectOptions(data.options)
      }
    }
  }, [mode, data])

  useEffect(() => {
    let vk = displayName.normalize('NFD').replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/g, "").toLowerCase()

    setVariableKey(vk)
    setTooltip(displayName)
  }, [displayName])

  useEffect(() => {
    if(!onCategorySelected) {
      return
    }
    onCategorySelected(selectedCategory)
  }, [selectedCategory, onCategorySelected])

  // Input change handler
  const handleInputChange = (e, setValue) => {
    const { value } = e.target 
    setValue(value)
  }

  // Add new option
  const handleAddNewOption = () => {
    let arr = [...selectOptions]
    arr.push({ label: '', value: '' })
    setSelectOptions(arr)
  }

  // Select option change
  const handleSelectOptionChange = (e, prop, idx) => {
    let item = selectOptions.find((_, i) => i === idx)
    if(item) {
      item[prop] = e.target.value
    }
    let arr = selectOptions.map((opt, i) => i === idx ? item : opt)
    setSelectOptions(arr)
  } 

  // Delete select option
  const deleteSelectOption = (idx) => {
    setSelectOptions(prev => prev.filter((_, i) => i !== idx))
  }

  // On save
  const handleSave = useCallback(async () => {
    let succes = false
    if(mode === 'create') {
      succes = await createNewVariable({ variableKey, tooltip, selectedDataType, selectedCategory, displayName, question, selectOptions, onClose })
    }else if(mode === 'edit') {
      succes = await updateSingleVariable({ variableKey, tooltip, selectedDataType, selectedCategory, displayName, question, selectOptions, onClose, data })
    }
    if(succes) {
      refreshVariables()
    }
  }, [data, displayName, mode, onClose, question, selectedCategory, selectOptions, selectedDataType, tooltip, variableKey, createNewVariable, updateSingleVariable, refreshVariables])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSave(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSave])

  return (
    <Modal 
      onClose={onClose} 
      title={mode === 'create' ? t('dashboard.create_variable') : t('dashboard.edit_variable')}
      saveBtnText={mode === 'create' ? t('dashboard.create') : t('general.edit')}
      onSave={handleSave}
    >
      <div className="create-variable">
        <div className="create-variable__body">
          <Input value={displayName} onChange={(e) => handleInputChange(e, setDisplayName)} label={`${t('dashboard.display_name')}*`} />
          <Input value={variableKey} onChange={(e) => handleInputChange(e, setVariableKey)} label={`${t('dashboard.variable_key')}*`} />
          <Input value={tooltip} onChange={(e) => handleInputChange(e, setTooltip)} label={`${t('general.tooltip')}*`} />
          <Select 
            label={`${t('dashboard.choose_data_type')}*`}
            selected={selectedDataType} 
            onChange={setSelectedDataType} 
            alignDropdownRight={true}
            className="select-v2--new"
          >
            <Option value="" hide="true">{t('general.choose')}</Option>
            <Option value="string">{t('custom_fields.text')}</Option>
            <Option value="number">{t('custom_fields.number')}</Option>
            <Option value="date">{t('dashboard.date')}</Option>
            <Option value="select">{t('custom_fields.select')}</Option>
            <Option value="multiselect">{t('custom_fields.multiselect')}</Option>
            <Option value="textarea">{t('variables.multiline_text')}</Option>
          </Select>
          {(selectedDataType === 'select' || selectedDataType === 'multiselect') && 
            <div className="select-options">
              <button className="btn btn--small" onClick={handleAddNewOption}> 
                <span className="material-symbols-outlined">add</span>
                {t('custom_fields.add_option')}
              </button>
              {selectOptions.map((opt, i) => {
                return (
                  <div className="select-options__group" key={i}>
                    <Input 
                      placeholder={t('custom_fields.label')} 
                      value={opt.label}
                      onChange={(e) => handleSelectOptionChange(e, 'label', i)} 
                    />
                    <Input 
                      placeholder={t('dashboard.value')} 
                      value={opt.value} 
                      onChange={(e) => handleSelectOptionChange(e, 'value', i)} 
                    />
                    <button className="icon-btn icon-btn--danger" onClick={() => deleteSelectOption(i)}>
                      <span className="material-symbols-outlined">delete</span>
                    </button>
                  </div>
                )
              })}
            </div>
          }
          <Input 
            value={question} 
            onChange={(e) => handleInputChange(e, setQuestion)} 
            label={t('general.question')} 
          />
          <Select 
            label={`${t('dashboard.select_category')}*`} 
            selected={selectedCategory} 
            onChange={setSelectedCategory} 
            alignDropdownRight={true}
            className="select-v2--new"
          >
            <Option value="" hide="true">{t('general.select')}</Option>
            {categories.map((c, idx) => {

              return (
                <Option value={c.value} key={idx}>{c.label}</Option>
              )
            })}
          </Select>
        </div>
      </div>
    </Modal>
  )
} 

export default CreateEditVariableModal
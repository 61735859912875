import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AddIcon from '@material-ui/icons/Add';
import { ClickAwayListener } from '@material-ui/core';

import ActionsDropdown from '../UI/ActionsDropdown'
import LineLoader from '../UI/LineLoader';
import Button from '../UI/Button';
import CustomTooltip from '../UI/CustomTooltip'
import IconButton from '../UI/IconButton';
import Alert from '../UI/Alert';
import SearchBoxModal from '../UI/SearchBoxModal'
import NotificationsButton from '../UI/NotificationsButton';
import SearchBox from '../UI/SearchBox';
import LetterCircle from '../UI/LetterCircle'
import Footer from '../sections/Footer';
import SideMenu from '../sections/SideMenu';
import SideMenuLong from '../sections/SideMenuLong';
import Notifications from '../sections/dashboard/Notifications';
import UploadDocumentsModal from '../sections/dashboard/UploadDocumentsModal';
import DocumentCreateModal from '../sections/documents/DocumentCreateModal';
import CreateEditFolderModal from '../sections/CreateEditFolderModal';
import SupportMenu from '../sections/dashboard/SupportMenu'
import { LimitedFeatureWrapper } from '../utils/LimitedFeatureWrapper';
import { LoaderContext, FolderContext, TeamContext, UserContext, GlobalContext } from '../../context';
import { AnimatedSearchBar } from '../misc'

const DashboardLayoutV2 = ({ children, title, whiteBg = false, dashboard = false }) => {
  const { t } = useContext(GlobalContext)
  const { loading } = useContext(LoaderContext);
  const { folders, foldersLoading, deleteFolder, createFolder, updateFolder } = useContext(FolderContext);
  const { selectedTeam, activeTeamMember } = useContext(TeamContext);
  const { user, customizations } = useContext(UserContext)
  const [showLongMenu, setShowLongMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showDocumentsDropdown, setShowDocumentsDropdown] = useState(false);
  const [showUploadDocsModal, setShowUploadDocsModal] = useState(false);
  const [showDocumentCreateModal, setShowDocumentCreateModal] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [mode, setMode] = useState('create');
  const [currentFolder, setCurrentFolder] = useState(null);
  const [showDeleteFolderAlert, setShowDeleteFolderAlert] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [searchPosition, setSearchPosition] = useState({})

  const folderCreateClickHandler = async () => {
    setShowDocumentCreateModal(false);
    setMode('create');
    setCurrentFolder(null);
    setShowFolderModal(true);
  }

  const folderEditClickHandler = async (folder) => {
    setShowDocumentCreateModal(false)
    setCurrentFolder(folder);
    setMode('edit');
    setShowFolderModal(true);
  }

  const folderDeleteClickHandler = (folder) => {
    setShowDocumentCreateModal(false)
    setCurrentFolder(folder);
    setShowDeleteFolderAlert(true);
  }

  const deleteFolderHandler = async () => {
    await deleteFolder(currentFolder.id, false, selectedTeam?.id);
    setShowDeleteFolderAlert(false);
    setCurrentFolder(null);
  }

  const createFolderHandler = async (data) => {
    await createFolder(data, selectedTeam?.id);
    setShowFolderModal(false);
  }

  const updateFolderHandler = async (data, id) => {
    await updateFolder(data, id);
    setShowFolderModal(false);
    setCurrentFolder(null);
  }

  // On search trigger click
  const searchTriggerClickHandler = (e) => {
    const position = e.currentTarget.getBoundingClientRect()
    setSearchPosition(position) 
    setShowSearchModal(true)
  }

  return( 
    <div className="dashboard">
      {loading && <LineLoader />}
      {loading && <div className="overlay"></div>}
      <Notifications show={showNotifications} onClose={() => setShowNotifications(false)} />
      <div className="dashboard__content">
        <SideMenu onShowLongMenu={() => setShowLongMenu(true)} />
        <SideMenuLong onShowLongMenu={() => setShowLongMenu(false)} active={showLongMenu} />
        <div className="dashboard-page">
          <div className="dashboard-page__head">
            <h1>{title || t('dashboard.main_title')}</h1>

            {/* {activeTeamMember.status === 'active' && <LimitedFeatureWrapper type="team" requirement="beta_partner">
              <div className="search-trigger" onClick={searchTriggerClickHandler}>
                <SearchIcon />
                <p>{t('dashboard.search')}...</p>
              </div>
            </LimitedFeatureWrapper>} */}

            {activeTeamMember.status === 'active' && (
              <AnimatedSearchBar />
            )}

            {activeTeamMember.status === 'active' && <div className="dashboard-page__head_right">
              <ActionsDropdown
                hideHeader
                trigger={
                  <div className="dashboard-btn-wrapper">
                    <CustomTooltip content={t('dashboard.add_document')} position="left">
                      <div className="create documents-dropdown-wrapper">
                        <IconButton
                          icon={<AddIcon />}
                          onButtonClick={() => setShowDocumentsDropdown(!showDocumentsDropdown)}
                        />
                      </div>
                    </CustomTooltip>
                  </div>
                }
                dropdownClass="add-documents-dropdown"
                width={250}
              > 
                <ul className="add-documents-dropdown__menu">
                  <li>
                    <div className="link">
                      <a href="/#" onClick={(e) => {
                        e.preventDefault()
                        setShowDocumentsDropdown(false)
                        setShowDocumentCreateModal(true)
                      }}>
                        <span className="icon"><AddIcon /></span> {t('dashboard.create_from_template')}
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="link">
                      <a href="/#" onClick={(e) => {
                        e.preventDefault()
                        setShowDocumentsDropdown(false)
                        setShowUploadDocsModal(true)
                      }}>
                        <span className="icon icon-custom"><i className="custom-icon-upload"></i></span> {t('dashboard.import_document')}
                      </a>
                    </div>
                  </li>
                </ul>
              </ActionsDropdown>

              {/* Support menu */}
              <SupportMenu v2 />

              <CustomTooltip content={t('dashboard.activities')} position="bottom">
                <div className="dashboard-btn-wrapper">
                  <NotificationsButton onClick={() => setShowNotifications(true)} />
                </div>
              </CustomTooltip>
              {/* <IconButton icon={<SearchIcon />} onButtonClick={() => {}} light /> */}

              {/* User */}
              <CustomTooltip content={t('dashboard.profile')} position="bottom">
                <div className="dashboard-btn-wrapper">
                  <Link to="/settings/my-profile" className="username">
                    {user.image_url 
                      ? <span className="user-thumb" style={{backgroundImage: `url(${user.image_url})`}}></span>
                      : <LetterCircle data={user.first_name} hideTooltip />
                    }
                  </Link>
                </div>
              </CustomTooltip>
              
            </div>}
            
          </div>
          <div 
            className={whiteBg ? "dashboard-page__body dashboard-page__body--white-bg" : "dashboard-page__body"}
            style={dashboard ? { backgroundColor: customizations.background, paddingRight: 0 } : {}}
          >
            {children}
          </div>
        </div>
        {/* <Footer /> */}
      </div>

      {showUploadDocsModal && <UploadDocumentsModal onClose={() => setShowUploadDocsModal(false)} />}
      {showDocumentCreateModal && <DocumentCreateModal 
        onClose={() => setShowDocumentCreateModal(false)} 
        folders={folders}
        onFolderCreate={folderCreateClickHandler}
        onFolderDelete={folderDeleteClickHandler}
        onFolderEdit={folderEditClickHandler}
      />}
      {showDeleteFolderAlert && <Alert 
        onClose={() => setShowDeleteFolderAlert(false)}
        text={t('alert.delete_folder')}
        onSubmit={deleteFolderHandler}
        loading={foldersLoading}
        deleteAlert
      />}
      {showFolderModal && <CreateEditFolderModal 
        onClose={() => setShowFolderModal(false)} 
        onCreate={createFolderHandler}
        onEdit={updateFolderHandler}
        foldersLoading={foldersLoading}
        currentFolder={currentFolder}
        mode={mode}
      />}
      {showSearchModal && <SearchBoxModal 
        onClose={() => setShowSearchModal(false)} position={searchPosition} 
        setViewTitle={() => {}}
      />}
    </div>
  );
}

export default DashboardLayoutV2;
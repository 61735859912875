import { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Modal } from '../../new_ui'
import { CreateEditAlertModal } from '../'
import { GlobalContext, AlertContext } from '../../../context'

const AllAlertsModal = ({ onClose, mode = 'all', events = [], currentDate = '' }) => {
  const { t } = useContext(GlobalContext)
  const { alerts } = useContext(AlertContext)
  const [alertsArr, setAlertsArr] = useState([])
  const [selectedAlert, setSelectedAlert] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const history = useHistory()

  // Set alerts arr
  useEffect(() => {
    if(mode === 'all') {
      setAlertsArr(alerts)
    }else if(mode === 'events') {
      setAlertsArr(events)
    }
  }, [mode, alerts, events])

  // On title click
  const handleTitleClick = (documentId) => {
    history.push(`/document-detail/${documentId}`, { openAlert: true });
  }

  // On edit click
  const handleEditIconClick = (e, a) => {
    e.preventDefault()
    setShowEditModal(true)
    setSelectedAlert(a)
  }

  // On close edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedAlert(null)
  }

  // Render alert
  const renderAlert = (a, idx) => {
    return (
      <li key={idx}>
        <p className={`u-truncate-text u-cursor--pointer`} onClick={() => handleTitleClick(a.documentId)}>{a.title}</p>
        {mode === 'all' && <div className={`date hide-on-hover ${Date.now() > a.dateTimestamp ? 'already-passed' : ''}`}>
          {a.dateTimestamp && moment.unix(a.dateTimestamp / 1000).format('DD MMM YYYY')}
        </div>}
        {mode === 'events' && <div className={`date date--small ${Date.now() > a.dateTimestamp ? 'already-passed' : ''}`}>{a.time}</div>}
        {mode === 'all' && <div className="on-hover">
          <a href="/#" onClick={(e) => handleEditIconClick(e, a)}>
            <span className="material-symbols-outlined">edit</span>
          </a>
        </div>}
      </li>
    )
  }

  return (
    <>
      <Modal
        title={mode === 'all' ? t('dashboard.alerts') : currentDate}
        hideFooter={true}
        onClose={onClose}
        className="centered-head"
      >
        <ul className="select-list">
          {alertsArr.map((a, idx) => renderAlert(a, idx))}
        </ul>
      </Modal>
    
      {showEditModal && <CreateEditAlertModal onClose={handleCloseEditModal} selectedAlert={selectedAlert} mode="edit" />}
    </>
  )
}

export default AllAlertsModal
import { useState, useEffect, useContext, memo } from 'react'
import moment from 'moment'
import { DeleteOutline } from '@material-ui/icons'

import ActionsDropdown from '../../UI/ActionsDropdown'
import CustomTooltip from '../../UI/CustomTooltip'
import { EditTaskModal } from '../'
import { Alert, LetterCircle } from '../../new_ui'
import { GlobalContext, TaskContext } from '../../../context'
import { useTaskActions } from '../../../hooks'

const TasksRow = ({ doc, id, columns, refreshTasks }) => {
  const { t } = useContext(GlobalContext)
  const {  } = useContext(TaskContext)
  const { changeTaskStatus, deleteSingleTask } = useTaskActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showEditTaskModal, setShowEditTaskModal] = useState(false)

  // Print date
  const printDate = (date) => {
    if(!date) return ''
    return moment(date).format('DD MMM YY')
  }

  // Print time
  const printTime = (date) => {
    if(!date) return ''
    return moment(date).format('HH:mm')
  }

  // On delete click
  const handleDeleteClick = async (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On delete
  const handleDelete = async () => {
    await deleteSingleTask({ task: doc, refreshTasks })
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On edit click
  const handleEditClick = (e) => {
    e.preventDefault()
    setShowEditTaskModal(true)
  }

  // On close edit modal
  const handleCloseEditModal = () => {
    setShowEditTaskModal(false)
  }

  // On task status change - completed/not completed
  const handleTaskStatusChange = async () => {
    await changeTaskStatus({ task: doc })
    refreshTasks()
  }

  // Render single doc actions
  const renderSingleDocActions = () => {
    return (
      <ActionsDropdown
        trigger={<button className="icon-btn icon-btn--small"><span className="material-symbols-outlined">more_horiz</span></button>}
        dropdownClass="actions-dropdown-v2"
        hideHeader
        width={200}
      >
        <ul className="default-list default-list--small">
          <li>
            <a href="/" onClick={handleEditClick}>
              <span className="icon"><span className="material-symbols-outlined">edit</span></span>
              {t('general.edit')}
            </a>
          </li>
        </ul>
      </ActionsDropdown>
    )
  }

  // Render name column
  const renderNameColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div 
        className={`list-view-table__cell u-truncate-text-wrapper title-col`} 
        key={i}
      >
        <div className="task-name-wrapper u-truncate-text-wrapper">
          {!doc.completed && <div className="task-name-wrapper__check" onClick={handleTaskStatusChange}></div>}
          {doc.completed && <div className="task-name-wrapper__check" onClick={handleTaskStatusChange}><span className="material-symbols-outlined">done</span></div>}
          <p className={`text-style-1 u-truncate-text`}>{content}</p>
        </div>
        <div className="show-on-hover">
          {renderSingleDocActions()}
        </div>
      </div>
    )
  }

  // Render date column
  const renderDateColumn = (col, i) => {
    const date = printDate(doc.due_date)
    const time = printTime(doc.due_date)

    let dateClass = ''

    if(!doc.completed && doc.due_date) {
      if(moment(doc.due_date).isSame(new Date(new Date().setHours(0,0,0,0)).toString())) {
        dateClass = 'current-date'
      }else if(moment(doc.due_date).isBefore(new Date(new Date().setHours(0,0,0,0)).toString())) {
        dateClass = 'already-passed'
      }
    }else {
      if(doc.due_date) {
        dateClass = 'done'
      }
    }

    const content = (
      <div className={`u-truncate-text ${dateClass}`}>
        <span className="normal">{ date }</span>
        {/* <span className="time">{ time }</span> */}
      </div>
    )

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper date-col" key={i}>
        { content }
      </div>  
    )
  }

  // Render user column
  const renderUserColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper user-col" key={i}>
        <div className="text-smaller u-truncate-text">
          <LetterCircle data={content} />
        </div>
      </div>
    )
  }

  // Render custom column
  const renderDeleteColumn = (col, i) => {
    return (
      <div className="list-view-table__cell u-truncate-text-wrapper delete-col" key={i}>
        <div className="text-smaller u-truncate-text">
          <a href="/#" onClick={handleDeleteClick}>
            <DeleteOutline />
          </a>
        </div>
      </div>
    )
  }

  // Render custom column
  const renderCustomColumn = (col, i) => {
    let content = col.value ? doc[col.value] : ''

    return (
      <div className="list-view-table__cell u-truncate-text-wrapper" key={i}>
        <div className="text-smaller u-truncate-text">{content}</div>
      </div>
    )
  }

  // Render helper column
  const renderHelperColumn = (i) => {
    return (
      <div className="list-view-table__cell helper" key={i}>
        <span>&nbsp;</span>
      </div>
    )
  }

  // Render column content 
  const renderColumnContent = (col, i) => {
    let content = null

    if(col.value === 'name'){ 
      content = renderNameColumn(col, i)
    }else if(col.value === 'sort_user') {
      content = renderUserColumn(col, i)
    }else if(col.value === 'due_date') {
      content = renderDateColumn(col, i)
    }else if(col.value === 'delete') {
      content = renderDeleteColumn(col, i)
    }else if(col.value === 'helper') {
      content = renderHelperColumn(col, i)
    }else {
      content = renderCustomColumn(col, i)
    }

    return content
  }

  return (
    <>
      <div 
        className={`list-view-table__row ${doc.completed ? 'completed' : ''}`}
      >
        {columns.filter(c => c.checked).map((col, i) => {
          return renderColumnContent(col, i)
        })}  
      </div>

      {showDeleteAlert && (
        <Alert 
          onClose={handleCloseDeleteAlert} 
          text={t('alert.delete_task')}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}

      {showEditTaskModal && (
        <EditTaskModal 
          onClose={handleCloseEditModal}
          taskToEdit={doc}
          refreshTasks={refreshTasks}
        />
      )}
    </>
  )
}

export default memo(TasksRow)
import { useContext, forwardRef } from 'react'

import { DocumentsContext, ColumnsContext } from '../../../context'

const ResizableTemplateColumn = forwardRef(({ column, onMouseDown, onSort, idx }, ref) => {
  const { templatesSort } = useContext(DocumentsContext)
  const { templatesTableColumnsWidths } = useContext(ColumnsContext)

  let columnStyle = {
    width: column.value === 'helper' ? '100%' : templatesTableColumnsWidths[column.value] || 200 
  }

  return (
    <div 
      className={`list-view-table__cell ${column.value === 'name' || column.value === 'helper' ? 'title-col filtered-out' : 'draggable-item'}`} 
      key={idx} 
      style={columnStyle}
      ref={ref}
    >
      {column.value !== 'helper' ? (
        <p onClick={() => onSort(column.value)} className={templatesSort.value === column.value ? 'sort active-sort' : 'sort'}>
          <span className="material-symbols-outlined icon">{column.icon}</span>
          <span className="text">{column.label}</span>
          {templatesSort.value === column.value && (
            <>
              {templatesSort.order === 'desc' && <span className="material-symbols-outlined arrow">arrow_upward</span>}
              {templatesSort.order === 'asc' && <span className="material-symbols-outlined arrow">arrow_downward</span>}
            </>
          )}
        </p>
      ) : (
        <div>&nbsp;</div>
      )}
      {idx !== 0 && column.value !== 'helper' && <div className="resize-handle" onMouseDown={() => onMouseDown(idx, column.value)}></div>}
      {idx === 1 && <div className="resize-handle resize-handle--before" onMouseDown={() => onMouseDown(0, 'name')}></div>}
    </div>
  )
})

export default ResizableTemplateColumn
import React, { useContext } from 'react'

import Switch from '../../UI/Switch'
import CustomSelect from '../../UI/CustomSelect'
import { GlobalContext } from '../../../context'

const ChooseTemplateSection = ({ showChooseTemplates, chooseTemplateSwitchChangeHandler, signatureTemplatesOptions, templateSelectedHandler, activeSignatureTemplate, setShowSignatureTemplatesModal }) => {
  const { t } = useContext(GlobalContext)
  
  return (
    <div className="signature-modal-inner__section signature-modal__template">
      <p className="form__subtitle form__subtitle--2">{t('signature_templates.location_template_title')}</p>
      <div className="checkbox-wrapper">
        <Switch checked={showChooseTemplates} onChange={chooseTemplateSwitchChangeHandler} />
      </div>
      {showChooseTemplates && <div className="signature-modal__template_choose">
        <CustomSelect 
          options={signatureTemplatesOptions}
          onChange={(option) => templateSelectedHandler(option.value)}
          active={signatureTemplatesOptions.find(tmpl => tmpl.value === activeSignatureTemplate) || signatureTemplatesOptions[0]}
          formEl
        /> 
        <a href="/#" onClick={(e) => { e.preventDefault(); setShowSignatureTemplatesModal(true)}}>{t('general.edit')}</a>
      </div>}
    </div>
  )
}

export default ChooseTemplateSection
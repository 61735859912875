import React, { useState, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Loader from '../UI/Loader';
import CustomTooltip from '../UI/CustomTooltip';
import Alert from '../UI/Alert';
import AddNewTask from './AddNewTask';
import { TaskContext, NotificationContext, DocumentsContext, TeamContext, GlobalContext } from '../../context';
import { update_task } from '../../services/firestore';

const TasksList = ({ showAll, showCompletedTasks = false, docId = null, currentDocument = null, view = 'dashboard', tasks = null, onFocus = () => {}, onBlur = () => {}, onSetView = () => {}, fromSidePanel = false }) => {
  const { t } = useContext(GlobalContext)
  const { completedTasks, notCompletedTasks, deleteTask, updateTask } = useContext(TaskContext);
  const { setNotification } = useContext(NotificationContext);
  const { updateDocument, getDocumentById } = useContext(DocumentsContext);
  const { activeTeamMember } = useContext(TeamContext);
  const [showAddNewTask, setShowAddNewTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showDeleteTaskAlert, setShowDeleteTaskAlert] = useState(false);
  const [completingTask, setCompletingTask] = useState('');
  const [deletingTask, setDeletingTask] = useState(false);
  const history = useHistory();

  // On task view icon click
  const viewIconClickHandler = (e, task) => {
    e.preventDefault();
    history.push(`/document-detail/${task.relatedDocument}`, { openTasks: true });
  }

  // On task edit icon click
  const editIconClickHandler = (e, task) => {
    e.preventDefault();
    setSelectedTask(task);
    setShowAddNewTask(true);
  }

  // On task delete icon click
  const deleteIconClickHandler = (e, task) => {
    e.preventDefault();
    setSelectedTask(task);
    setShowDeleteTaskAlert(true);
  }

  // Complete task
  const completeTaskHandler = async (task, uncomplete = false) => {
    const { id, relatedDocument } = task;
    setCompletingTask(id);
    if(!uncomplete) {
      await updateTask({ completed: true }, id, async () => {
        const doc = getDocumentById(relatedDocument);
        if(doc && doc.tasks) {
          const updatedTasks = doc.tasks.map(task => task.id === id ? { ...task, completed: true } : task );
          await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, doc);
        }
        setNotification({ msg: t('notification.task_completed'), type: 'success' });
        setCompletingTask('');
      }, (err) => {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      });
    }else {
      await updateTask({ completed: false }, id, async () => {
        const doc = getDocumentById(relatedDocument);
        if(doc && doc.tasks) {
          const updatedTasks = doc.tasks.map(task => task.id === id ? { ...task, completed: false } : task );
          await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, doc);
        }
        setNotification({ msg: t('notification.task_updated'), type: 'success' });
        setCompletingTask('');
      }, (err) => {
        setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
      });
    }
  }

  // Delete task
  const deleteTaskHandler = async () => {
    setDeletingTask(true);
    // update before delete to show correct author in action - this update won't be shown in actions
    await update_task({ deleting: true, last_modified_by: activeTeamMember.id }, selectedTask.id);
    await deleteTask(selectedTask.id, async () => {
      const doc = getDocumentById(selectedTask.relatedDocument);
      if(doc && doc.tasks) {
        const updatedTasks = doc.tasks.filter(task => task.id !== selectedTask.id );
        await updateDocument({ tasks: updatedTasks, last_modified_by: activeTeamMember.id, create_action: 'no' }, doc);
      }
      setShowDeleteTaskAlert(false);
      setNotification({ msg: t('notification.task_deleted'), type: 'success' });
    }, (err) => {
      setShowDeleteTaskAlert(false);
      setNotification({ msg: t('notification.something_went_wrong'), type: 'danger' });
    });
    setSelectedTask(null);
    setDeletingTask(false);
  }

  const renderListItem = (task, idx, completed = false) => {
    return (
      <li className={completed ? 'tasks__item tasks__item--completed' : 'tasks__item'} key={idx}>
        {completingTask === task.id && <div className="loader-wrapper"><Loader primary mini normal /></div>}
        <div className="tasks__item_status" onClick={() => completeTaskHandler(task, completed)}><CheckCircleIcon /></div>
        <div className="tasks__item_name">
          {task.name}
          <div className="icons">
            {!docId && !!task.relatedDocument && <CustomTooltip content={t('general.preview')}>
              <a href="#/" onClick={(e) => viewIconClickHandler(e, task)}><RemoveRedEyeIcon /></a>
            </CustomTooltip>}
            <CustomTooltip content={t('general.edit')}>
              <a href="#/" onClick={(e) => editIconClickHandler(e, task)}><EditIcon /></a>
            </CustomTooltip>
            <CustomTooltip content={t('general.remove')}>
              <a href="#/" onClick={(e) => deleteIconClickHandler(e, task)}><DeleteIcon /></a>
            </CustomTooltip>
          </div>
        </div>
      </li>
    );
  }

  return(
    <Fragment>
      <ul className="tasks">
        {view === 'document' 
          ? docId 
            ? tasks.filter(t => !t.completed).map((task, idx) => renderListItem(task, idx))
            : null
          : showAll 
            ? !fromSidePanel ? notCompletedTasks.map((task, idx) => renderListItem(task, idx)) : null
            : !fromSidePanel ? [...notCompletedTasks].slice(0,5).map((task, idx) => renderListItem(task, idx)) : null
        }
        {/* {docId 
          ? [...notCompletedTasks].filter(t => t.relatedDocument === docId).map((task, idx) => renderListItem(task, idx))
          : showAll 
            ? [...notCompletedTasks].map((task, idx) => renderListItem(task, idx))
            : [...notCompletedTasks].slice(0,5).map((task, idx) => renderListItem(task, idx))
        } */}
      </ul>
      {!showAddNewTask 
        ? <div className="add-new-task-btn-wrapper">
          <a href="#/" onClick={(e) => { e.preventDefault(); setShowAddNewTask(true); }}><AddIcon /> {t('general.add_task')}</a>
        </div>
        : <AddNewTask 
          onCancel={() => setShowAddNewTask(false)} 
          taskToEdit={selectedTask} 
          onSetTaskToEdit={setSelectedTask} 
          doc={currentDocument}
          docTasks={tasks}
          onFocus={onFocus}
          onBlur={onBlur}
          onSetView={onSetView}
        />
      }
      {showCompletedTasks && <ul className="tasks tasks--completed">
        {docId ? tasks.filter(t => t.completed).map((t, i) => renderListItem(t, i, true))
          : !fromSidePanel ? [...completedTasks].map((t, i) => renderListItem(t, i, true)) : null}
      </ul>}

      {showDeleteTaskAlert && <Alert 
        text={t('alert.delete_task')}
        onClose={() => setShowDeleteTaskAlert(false)}
        onSubmit={deleteTaskHandler}
        loading={deletingTask}
        deleteAlert 
      />}
    </Fragment>
  );
}

export default TasksList;
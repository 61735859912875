import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext, GlobalContext } from '../../../context'

const HelpSearchResults = ({ v2 = false }) => {
  const { t, selectedLang } = useContext(GlobalContext)
  const { articlesArr } = useContext(HelpContext)
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()
  const history = useHistory()

  // Get search query from url and set it to searchTerm state variable and get search results using this query
  useEffect(() => {
    const qsVariables = qs.parse(location.search, { ignoreQueryPrefix: true })
    const query = qsVariables.query
    if(query) {
      setSearchTerm(query)
      let arr = []
      // Check if search term is inside article title or content
      articlesArr.forEach(article => {
        let added = false
        if(article.title[selectedLang] && article.title[selectedLang].toLowerCase().includes(query.toLowerCase())) {
          arr.push(article)
          added = true
        }
        if(!added && article.content[selectedLang]) {
          const blocks = article.content[selectedLang].blocks
          for(let i = 0; i < blocks.length; i++) {
            if(blocks[i].text && blocks[i].text.toLowerCase().includes(query.toLowerCase())) {
              arr.push(article)
              break
            }
          }
        }
      })
      setSearchResults(arr)
    }
  }, [location, articlesArr])

  // Article click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-article/${id}`)
  }

  return (
    <SupportPageLayout withSidebar hideCategories showBackButton>
      <div className="help-search-results">
        <div className="help-search-results__head">
          <h2>{t('support.search_results_for')}: "<span>{searchTerm}</span>"</h2>
          <p>{searchResults.length} {searchResults.length === 1 ? 'article' : 'articles'} {t('support.found')}</p>
        </div>
        <div className="help-search-results__body">
          <ul>
            {searchResults.map((article, idx) => {
              return (
                <li key={idx}>
                  <a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title[selectedLang] || 'Missing translation'}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      
    </SupportPageLayout>
  )
}

export default HelpSearchResults
import { useEffect, useContext, useCallback, useState } from 'react'

import { Modal, Input } from '../../new_ui'
import { GlobalContext } from '../../../context'
import { useSingleTemplateActions } from '../../../hooks'

const EditTemplateModal = ({ onClose, currentTemplate, refreshTemplates }) => {
  const { t } = useContext(GlobalContext)
  const { updateSingleTemplate } = useSingleTemplateActions()
  const [name, setName] = useState('')
  const [error, setError] = useState('')

  // Set name from selected template
  useEffect(() => {
    if(currentTemplate) {
      setName(currentTemplate.name)
    }
  }, [currentTemplate])

  // On submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    
    if(name.trim() === '') {
      setError('error')
      return 
    }

    const data = {
      name: name.trim()
    }
    await updateSingleTemplate({ data, id: currentTemplate.id, setShowEditTemplateModal: onClose, setCurrentTemplate: () => {} })
    refreshTemplates()
  }, [currentTemplate, name, onClose, updateSingleTemplate, refreshTemplates])

  // Submit when enter is pressed
  useEffect(() => {
    // On key press
    const handleKeyDown = (e) => {
      if(e.code === 'Enter') {
        handleSubmit(e)
      }
    }
  
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSubmit])

  return(
    <Modal 
      onClose={onClose} 
      title={t('general.rename_template')}
      className="centered-head" 
      onSave={handleSubmit}
    >
      <div className="folder-modal">
        <form onSubmit={handleSubmit} className="form">
          <Input 
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={error}
            placeholder={t('general.template_title_placeholder')}
          />
        </form>
      </div>
    </Modal>
  )
}

export default EditTemplateModal